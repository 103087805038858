import React, { useContext } from 'react';
import cx from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import styles from './WMICFieldAlert.module.scss';
import PropTypes from 'prop-types';

const WMICFieldAlert = (props) => {
    const { className, alertMessageId, noLabel } = props;
    const translator = useContext(TranslatorContext);

    const labelText = props.noLabel? '' :
        <div className={cx(styles.fieldLabel_left)}>
            <label>&nbsp;</label>
        </div>
    return (
        <div className={cx(styles.fieldAlert, className)}>
            {labelText}
            <div className={cx(styles.fieldControls_left)}>
                <i className="fa fa-fw fa-exclamation-circle"/>
                {translator({ "id": props.alertMessageId })}
            </div>
        </div>
    );
}

WMICFieldAlert.propTypes = {
    alertMessageId: PropTypes.object.isRequired
};

export default WMICFieldAlert;
