/* eslint-disable no-secrets/no-secrets */
import { defineMessages } from 'react-intl';

export default defineMessages({
    addedItem: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyChangeDiffComponent.AddedItem',
        defaultMessage: 'Added'
    },    
    removedItem: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyChangeDiffComponent.RemovedItem',
        defaultMessage: 'Removed'
    },
    policyChange: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyChangeDiffComponent.Policy change',
        defaultMessage: 'Policy change'
    },
    renewal: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyChangeDiffComponent.Renewal',
        defaultMessage: 'Renewal'
    },
    existingPolicy: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyChangeDiffComponent.Existing policy',
        defaultMessage: 'Existing policy' 
    },
    changed: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyChangeDiffComponent.Changed',
        defaultMessage: 'Changed'
    }
});
