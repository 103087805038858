import { defineMessages } from 'react-intl';

export default defineMessages({
    policyCoverages: {
        id: 'wmic-pe-capability-gateway-common-react.pages.WMICPAPolicyCoveragesPage.Policy Level Coverages',
        defaultMessage: 'Policy Level Coverages',
    },
    vehicleNumberHeader:{
        id: "wmic-pe-capability-policy-common-react.WMICPolicyWizard.pages.WMICPAPolicyVehicleCoveragesPage.vehicleNumberHeader",
        defaultMessage: "#"
    },
    vehicleTypeHeader:{
        id: "wmic-pe-capability-policy-common-react.WMICPolicyWizard.pages.WMICPAPolicyVehicleCoveragesPage.vehicleTypeHeader",
        defaultMessage: "Vehicle Type"
    },
    vehicleYearHeader:{
        id: "wmic-pe-capability-policy-common-react.WMICPolicyWizard.pages.WMICPAPolicyVehicleCoveragesPage.vehicleYearHeader",
        defaultMessage: "Year"
    },
    vehicleMakeModelHeader:{
        id: "wmic-pe-capability-policy-common-react.WMICPolicyWizard.pages.WMICPAPolicyVehicleCoveragesPage.vehicleMakeModelHeader",
        defaultMessage: "Make & Model"
    },
    vehicleMakeModelValue:{
        id: "wmic-pe-capability-policy-common-react.WMICPolicyWizard.pages.WMICPAPolicyVehicleCoveragesPage.vehicleMakeModelValue",
        defaultMessage: "{make} {model}"
    },
    vehicleVINHeader:{
        id: "wmic-pe-capability-policy-common-react.WMICPolicyWizard.pages.WMICPAPolicyVehicleCoveragesPage.vehicleVINHeader",
        defaultMessage: "VIN"
    },
    vehicleBusinessSegmentHeader:{
        id: "wmic-pe-capability-policy-common-react.WMICPolicyWizard.pages.WMICPAPolicyVehicleCoveragesPage.vehicleBusinessSegmentHeader",
        defaultMessage: "Business Segment"
    },
    vehicleAutoRiskHeader:{
        id: "wmic-pe-capability-policy-common-react.WMICPolicyWizard.pages.WMICPAPolicyVehicleCoveragesPage.autoRiskHeader",
        defaultMessage: "Auto Risk"
    }
});
