import React, { useContext } from 'react';

import { TranslatorContext } from '@jutro/locale';
import { WMICButton } from 'wmic-pe-components-platform-react';
import styles from './WMICFormButtons.module.scss';

function WMICFormButtons(props) {
    const { onCancel, onSave, isEditing, cancelLabel, saveLabel, disableSave, disableCancel, id } = props;
    const translator = useContext(TranslatorContext);

    const handleSave = () => {
        onSave();
    }

    const handleCancel = () => {
        onCancel();
    }

    return (
        (isEditing === undefined || isEditing) ?
            <div className={styles.editFormButtonsRow}>
                <div className={styles.editFormButtonsContainer}>
                    {onCancel &&
                        <WMICButton id={`${id}_CancelBtn`} type="secondary-outlined" onClick={handleCancel} disabled={disableCancel}>
                            {cancelLabel || translator({ id: "quoteandbind.ho.views.Cancel", defaultMessage: "Cancel" })}
                        </WMICButton>
                    }

                    {onSave &&
                        <WMICButton id={`${id}_SaveBtn`} type="secondary-filled" onClick={handleSave} disabled={disableSave}>
                            {saveLabel || translator({ id: "quoteandbind.ho.views.Save", defaultMessage: "Save" })}
                        </WMICButton>
                    }
                </div>
            </div>
            : null
    )
}

export default WMICFormButtons;
