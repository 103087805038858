import { defineMessages } from 'react-intl';

export default defineMessages({
    headerName: {
        id: "wmic-pe-capability-policy-common-react.WMICPolicyInsuredPage.Name Header",
        defaultMessage: "Name"
    },
    headerPNI: {
        id: "wmic-pe-capability-policy-common-react.WMICPolicyInsuredPage.Primary Named Insured Header",
        defaultMessage: "Primary Named Insured"
    },
    headerRelationship: {
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyInsuredPage.Relationship',
        defaultMessage: 'Relationship',
    },
    headerDOB: {
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyInsuredPage.Date Of Birth',
        defaultMessage: 'Date Of Birth',
    }
});