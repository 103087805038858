import { HOConstants, WMICRPCUtil } from 'wmic-pe-portals-utils-js';

export default {
    isCsaLabelTypeRequiredForDwellingTypeAndEffectiveDate : (ratingJurisdiction, rateAsOfDate, hoPolicyType) => {
        if (!ratingJurisdiction || !hoPolicyType) { return false }

        return hoPolicyType.code === HOConstants.homeownerPolicyType && WMICRPCUtil.getIsRPCEffectiveForRiskType(ratingJurisdiction.code, rateAsOfDate, hoPolicyType.code, '1553')
            ? false
            : WMICRPCUtil.getIsRPCEffectiveForRiskType(ratingJurisdiction.code, rateAsOfDate, hoPolicyType.code, '1411');

    }
}
