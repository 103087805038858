import { defineMessages } from 'react-intl';

export default defineMessages({
    vehicleInfo: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehiclesDetailView.policyVehicleInfo',
        defaultMessage: 'Vehicle Information'
    },
    vehicleUsage: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehiclesDetailView.policyVehicleUsage',
        defaultMessage: 'Usage'
    },
    vehicleOwnershipAssignment: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehiclesDetailView.policyVehicleOwnershipAssignment',
        defaultMessage: 'Ownership & Assignment'
    },
    vehicleAdditionalInfo: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehiclesDetailView.policyVehicleAdditionalInfo',
        defaultMessage: 'Additional Information'
    },
});
