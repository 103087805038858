import React, { useMemo, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { POLICY_DETAILS, CONSTANTS, WMICRichTextUtil, WMICDateTimeService } from 'wmic-pe-portals-utils-js';
import { PAConsentMessages } from 'wmic-pe-capability-gateway-quoteandbind-pa-react';
import { TranslatorContext } from '@jutro/locale';

import metadata from './WMICPolicyInsuredHOConsent.metadata.json5';

function WMICPolicyInsuredHOConsent(props) {
    const { selectedInsured } = props;
    const translator = useContext(TranslatorContext);

    const isPNI = useMemo(
        () => _.get(selectedInsured, 'isPrimaryInsured.value'),
        [selectedInsured]
    );
    
    const consentReceivedWithdrawn = useMemo(() => isPNI && _.get(selectedInsured, 'creditConsentReceived.value.code') === CONSTANTS.CREDIT_CONSENT_CODES.WITHDRAWN, [isPNI, selectedInsured])
    const consentReceivedYes = useMemo(() => isPNI && _.get(selectedInsured, 'creditConsentReceived.value.code') === POLICY_DETAILS.YES, [isPNI, selectedInsured])
    
    const overrideProps = {
        '@field': {
            parentNode: selectedInsured,
            readOnly: true,
        },
        creditConsentReceived: {
            value: _.upperFirst( _.get(selectedInsured, 'creditConsentReceived.value.code') ),
        },
        creditConsentDeclinedDate: {
            visible: consentReceivedWithdrawn
        },
        creditConsentWithdrawalForm:{
            visible: consentReceivedWithdrawn
        },
        creditConsentDate: {
            visible: consentReceivedYes,
        },
        creditConsentFormReceived:{
            visible: consentReceivedYes,
        },
        creditConsentDOB:{
            label: WMICRichTextUtil.translateRichText(translator(PAConsentMessages.dateOfBirthFor, {name: selectedInsured.value.firstName})),
            value: WMICDateTimeService.toMidnightDate(_.get(selectedInsured, "dateOfBirth.value")),
            visible: consentReceivedYes
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={selectedInsured}
            overrideProps={overrideProps}
        />
    );
}

WMICPolicyInsuredHOConsent.propTypes = {
    selectedInsured: PropTypes.any.isRequired
};

export default WMICPolicyInsuredHOConsent;
