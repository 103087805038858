import { defineMessages } from 'react-intl';

export default defineMessages({
    atLeastOnePrimaryHeating: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeating.Primary Heating Use",
        defaultMessage: "&lt;span&gt;At least &lt;b&gt;one Primary Heating Type&lt;/b&gt; is required&lt;/span&gt;"
    },
    removeHeater: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeating.RemoveHeater",
        defaultMessage: "Remove this heater?"
    },
    removeHeaterConfirmation: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeating.RemoveConfirmationMsg",
        defaultMessage: "Are you sure you want to remove this heater?"
    },
    addHeatingType: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeating.Add Heating Type",
        defaultMessage: "Add Heating Type"
    },
    heating: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeating.Heating",
        defaultMessage: "Heating"
    },
    heatingUse: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeating.Heating Use",
        defaultMessage: "Heating Use"
    },
    heatingType: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeating.Heating Type",
        defaultMessage: "Heating Type"
    },
    heatingPrimaryFuel: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeating.Heating Primary Fuel",
        defaultMessage: "Heating Primary Fuel"
    },
    heatingSecondaryFuel: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeating.Heating Secondary Fuel",
        defaultMessage: "Heating Secondary Fuel"
    }
});
