import React, {useState, useContext, useCallback} from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import {WMICIcon} from "wmic-pe-components-platform-react";
import { HOConstants, LINE_OF_BUSINESS } from 'wmic-pe-portals-utils-js';
import { TranslatorContext } from '@jutro/locale';
import _ from "lodash";
import WMICHOCoverageUtil from "wmic-pe-capability-gateway-common-ho-react/utils/WMICHOCoverageUtil";
import WMICHORiskUtil from "wmic-pe-capability-gateway-common-ho-react/utils/WMICHORiskUtil";
import WMICCoverageUtil from '../../Coverages/util/WMICCoverageUtil';
import WMICDwellingCoveragesTable from "../WMICDwellingCoveragesTable/WMICDwellingCoveragesTable"
import metadata from './WMICIterableCommonComponent.metadata.json5';
import messages from './WMICIterableCommonComponent.messages';


function WMICIterableDwellingComponent(props) {
    const { dwelling, dwellingCoverages, isPolicyChangeOrRenewal, jobVM } = props;
    const translator = useContext(TranslatorContext);
    const isHO = _.get(jobVM, 'lob.value.code') === LINE_OF_BUSINESS.HOME_OWNER;
    const [showCoveragesContainer, setShowCoveragesContainer] = useState( false);

    const categorizeCoverages = (dwellingCovs) => {
        const allCoverages = WMICHOCoverageUtil.categorizeDwellingCoverages(dwellingCovs, true);
        allCoverages.dwellingDiscountsApplied_WMIC = _.get(dwelling, 'additionalDetails.dwellingDiscountsApplied_WMIC');
        allCoverages.dwellingSurchargesApplied_WMIC = _.get(dwelling, 'additionalDetails.dwellingSurchargesApplied_WMIC');

        return allCoverages;
    };

    const getPackageCoverageTermValue = (patternCode, basedOnBoolean = true) => {
        return WMICHOCoverageUtil.getPackageCoverageTermValue(
            dwellingCoverages.coverages.value,
            patternCode,
            basedOnBoolean
        )
    }

    const formatAddress = (items, index, { path: property }) => {
        const address = _.get(items, property);
        return WMICHORiskUtil.formatDwellingLocation(address);
    };

    const grcValue = getPackageCoverageTermValue(HOConstants.packageCoverageGRCPatternCode);
    const singleInclusiveLimitValue = getPackageCoverageTermValue(HOConstants.packageCoverageSingleLimitPatternCode);

    const totalDwellingPremium = (item, index) => {
        const dwellingCoveragesTotal = _.get(dwellingCoverages, 'dwellingCoveragesTotal.value', 0);
        const dwellings = _.get(jobVM, 'dwellings.value', []);
        const changedDwelling = _.find(dwellings, (dw) => dw.dwelling.publicID === dwelling?.publicID?.value);
        const totalPremiumSum = _.get(changedDwelling, `totalPremiumSum.amount`, 0);
        return WMICCoverageUtil.formatPremiumAmount(isPolicyChangeOrRenewal && isHO ? totalPremiumSum : dwellingCoveragesTotal);
    }

    const renderToggle = useCallback(()=> {
        return <WMICIcon
            id="expandIcon"
            icon={showCoveragesContainer ? 'mi-expand-more' : 'mi-expand-less'}
            onClick={()=>setShowCoveragesContainer(!showCoveragesContainer)}
            size="large" />
    },[showCoveragesContainer]);

    const getFormattedType = (item, index, {path: property}) => {
        const name = _.get(item, `${property}.value.name`);
        return name ? translator({id: name}) : '';
    }

    const overrides = {
        '@field': {
            parentNode: dwelling,
        },
        riskHeader: {
            VMList: [dwelling],
            VMData: [
                {
                    renderCell: renderToggle,
                    columnProportion: 0.5,
                    isGutterCell: true
                },
                {
                    headerText: translator(messages.riskNumber),
                    path: 'yourHome.dwellingNumber_EXT.value',
                    columnProportion: 1
                },
                {
                    headerText: translator(messages.dwellingLocation),
                    path: 'yourHome.locationAddress.displayName.value',
                    renderCell: formatAddress,
                    columnProportion: 3
                },
                {
                    headerText: translator(messages.riskType),
                    path: 'rating.hoPolicyType',
                    renderCell: getFormattedType,
                    columnProportion: 2
                },
                {
                    headerText: translator(messages.coverageType),
                    path: 'yourHome.coverageType',
                    renderCell: getFormattedType,
                    columnProportion: 1.5
                },
                {
                    headerText: translator(messages.grc),
                    renderCell: ()=> grcValue,
                    columnProportion: 1

                },
                {
                    headerText: translator(messages.singleInclusiveLimit),
                    renderCell: ()=> singleInclusiveLimitValue,
                    columnProportion: 1
                },
                {
                    headerText: translator(isPolicyChangeOrRenewal && isHO ? messages.proratedPremium : messages.totalPremium),
                    path: 'totalPremium',
                    renderCell: totalDwellingPremium,
                    columnProportion: 2
                },
            ],
            readOnly: true
        },
        dwellingCoveragesTable: {
            visible: showCoveragesContainer,
            dwellingCoverages: categorizeCoverages(dwellingCoverages.value),
            dwelling,
            jobVM,
            isPolicyChangeOrRenewal
        },
        watercraftCoveragesCard: {
            visible: false
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICDwellingCoveragesTable
        }
    };

    return (
        <ViewModelForm
            model={dwelling}
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

WMICIterableDwellingComponent.propTypes = {
    value: PropTypes.shape({}).isRequired
};

export default WMICIterableDwellingComponent;
