import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { Loader, withModalContext } from '@jutro/components';
import { JobUtil } from 'wmic-portals-utils-js';
import { TranslatorContext } from '@jutro/locale';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { JobService, UserService, SubmissionService } from 'gw-capability-gateway';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import UnderwritingComponent from '../../Components/UnderwritingComponent/UnderwritingComponent';
import metadata from './Summary.metadata.json5';
import messages from './Summary.messages';
import gatewayMessages from '../../gateway.messages';
import styles from './Summary.module.scss';

class QuoteDetailsSummaryWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({}).isRequired,
        fromAccountLanding: PropTypes.shape({
            quoteDetailsData: PropTypes.shape({
                getQuoteSummaryCount: PropTypes.func,
                jobNumber: PropTypes.string.isRequired,
                loadQuoteSummary: PropTypes.shape({}),
                updateJobSummary: PropTypes.func
            })
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired
        }).isRequired
    };

    state = {
        showisContinueTransactionButton: false,
        canWithdraw: false,
        isLoading: true
    };

    componentDidMount = () => {
        this.canWithdraw();
        this.getShowisContinueTransaction();
    };

    getShowisContinueTransaction = async () => {
        const { authHeader } = this.props;
        const permissionDTO = {
            permission: 'createsubmission'
        };
        const showisContinueTransactionButton = await UserService.hasUserSystemPermission(
            permissionDTO,
            authHeader
        );
        this.setState({ showisContinueTransactionButton, isLoading: false });
    };

    canWithdraw = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            }
        } = this.props;
        if (!_.isEmpty(submission)) {
            const translator = this.context;
            if (
                submission.status !== translator('typekey.PolicyPeriodStatus.Bound')
                && !submission.policy.issued
                && !submission.closeDate
            ) {
                this.setState({ canWithdraw: true });
            }
        }
    };

    onContinueTransaction = () => {
        const { lobQuoteURL } = appConfig;
        const { history } = this.props;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            },
        } = this.props;
        const productCode = _.get(submission, 'productCode');
        const postalCode = _.get(
            submission,
            'policy.account.accountHolder.primaryAddress.postalCode'
        );
        if (submission.jobNumber > 0) {
            if (!_.isNil(lobQuoteURL[productCode])) {
                const nextLocation = {
                    quoteentry: {
                        postalCode: postalCode,
                        quoteID: submission.jobNumber
                    },
                    productCode
                };
                history.push(lobQuoteURL[productCode], nextLocation);
            } else if (!_.isNil(getApdLobQuoteUrl(productCode))) {
                const nextLocation = {
                    productCode,
                    accountId: submission.policy.account.publicID,
                    jobId: submission.publicID
                };
                history.push(getApdLobQuoteUrl(productCode), nextLocation);
            } else {
                JobUtil.openJobInXCenter(submission.jobNumber);
            }
        }
    };

    onWithdrawTransaction = () => {
        const translator = this.context;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            },
            authHeader
        } = this.props;
        this.props.modalContext.showAlert({
            title: messages.withdrawJob,
            message: `${translator(messages.sureWithDrawQuote)} ${submission.jobNumber}?`,
            status: 'warning',
            icon: 'mi-error-outline',
            confirmButtonText: messages.withdraw
        }).then(() => {
            JobService.withdrawJobByJobNumber(submission.jobNumber, authHeader).then(
                () => {
                    this.updateWithDrawQuote('Withdrawn', submission);
                },
                () => {
                    this.props.modalContext.showAlert({
                        title: gatewayMessages.modalError,
                        message: messages.failedWithdrawSubmission,
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).catch(_.noop);
                }
            );
        }, _.noop);
    };

    hasUwIssues = (uwIssues) => {
        return uwIssues.length > 0;
    };

    getNotificationContent = () => {
        const translator = this.context;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            }
        } = this.props;
        const { showisContinueTransactionButton } = this.state;
        const content = {};
        if (
            submission
            && !['Bound', 'Quoted', 'Withdrawn'].includes(submission.statusCode)
            && !this.hasUwIssues(submission.underwritingIssues)
        ) {
            content.infoMessageTitle = translator(messages.quoteSuccessfullyStarted);
            content.infoMessageDescription = showisContinueTransactionButton
                ? translator(messages.continiueQuoteQizard)
                : translator(messages.mayWithdrawQuote);
        }
        if (
            submission
            && submission.statusCode === 'Quoted'
            && !this.hasUwIssues(submission.underwritingIssues)
        ) {
            content.infoMessageTitle = translator(messages.premiumQuoteCalculated);
            content.infoMessageDescription = showisContinueTransactionButton
                ? translator(messages.changeQuoteWizardToBind)
                : translator(messages.mayWithdrawQuote);
        }
        if (submission && submission.statusCode === 'Withdrawn') {
            content.infoMessageTitle = translator(messages.thisQuoteHasBeenWithDrawn);
            content.infoMessageDescription = '';
        }
        if (submission && submission.statusCode === 'Bound') {
            content.infoMessageTitle = translator(messages.quoteHasBeenBound);
            content.infoMessageDescription = '';
        }
        return {
            infoMessageTitle: content.infoMessageTitle,
            infoMessageDescription: content.infoMessageDescription,
            withDrawContent: translator(messages.withdrawQuote),
            continueContent: translator(messages.continueQuote)
        };
    };

    getSubmissionToProceed = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            }
        } = this.props;
        const { canWithdraw, showisContinueTransactionButton } = this.state;
        if (
            submission
            && (submission.statusCode === 'Withdrawn' || submission.statusCode === 'Bound')
        ) {
            return {
                isContinueTransaction: false,
                isWithdrawTransaction: false
            };
        }
        return {
            isContinueTransaction:
                showisContinueTransactionButton && canWithdraw && !submission.isEditLocked,
            isWithdrawTransaction: canWithdraw
        };
    };

    getPolicyLinkVisible = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            }
        } = this.props;
        const policyNumber = _.get(submission, 'policy.latestPeriod.policyNumber');
        if (policyNumber) {
            return policyNumber !== 'Unassigned';
        }
        return false;
    };

    redirectToPolicy = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            }
        } = this.props;
        const policyNumber = _.get(submission, 'policy.latestPeriod.policyNumber');
        return (
            <Link to={`/policies/${policyNumber}/summary`} className={styles.removeLinkStyle}>
                {policyNumber}
            </Link>
        );
    };

    getJobStatus = (job) => {
        const translator = this.context;
        if (job.policy) {
            if (job.policy.issued) {
                return translator(gatewayMessages.issued);
            }
            if (job.status === 'Not-taken') {
                return translator(gatewayMessages.notTaken);
            }
        }
        return job.status;
    };

    getStatusInfo = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            }
        } = this.props;
        const translator = this.context;
        const submissionStatus = this.getJobStatus(submission);
        if (submissionStatus.toLowerCase() === 'bound' && submission.policy.issued) {
            return translator('gateway.directives.JobSummary.Issued');
        }
        return submissionStatus;
    };

    updateWithDrawQuote = (jobStatusCode, submission) => {
        const translator = this.context;
        const status = translator({
            id: `typekey.PolicyPeriodStatus.${jobStatusCode}`,
            defaultMessage: jobStatusCode
        });
        _.set(submission, 'status', status);
        _.set(submission, 'statusCode', jobStatusCode);
        this.updateJobSummary(submission);
    };

    updateJobSummary = (submission) => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { updateJobSummary, getQuoteSummaryCount }
            }
        } = this.props;

        if (updateJobSummary) {
            updateJobSummary(submission);
        }
        if (getQuoteSummaryCount) {
            getQuoteSummaryCount();
        }
    };

    render() {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            }
        } = this.props;

        const { isLoading } = this.state;
        if (_.isEmpty(submission)) {
            return <Loader loaded={!isLoading} />;
        }
        const overrides = {
            quoteNotification: {
                notificationContent: this.getNotificationContent(),
                transactionVisibleActions: this.getSubmissionToProceed()
            },
            underwritingTable: {
                job: submission,
                continueJob: this.getSubmissionToProceed(),
                jobService: SubmissionService,
                onUpdateJobSummary: this.updateJobSummary
            },
            policyInfoLink: {
                visible: this.getPolicyLinkVisible(),
                value: this.redirectToPolicy()
            },
            detailGridTotalColumn: {
                visible: submission.statusCode === 'Quoted'
            },
            producerCodeOfRecordOrgId: {
                value: `${_.get(
                    submission,
                    'policy.latestPeriod.producerCodeOfRecordOrg'
                )} (${_.get(submission, 'policy.latestPeriod.producerCodeOfRecord')})`
            },
            producerOfServiceId: {
                value: `${_.get(
                    submission,
                    'policy.latestPeriod.producerCodeOfServiceOrg'
                )} (${_.get(submission, 'policy.latestPeriod.producerCodeOfService')})`
            },
            summaryStatusdDataId: {
                value: this.getStatusInfo()
            }
        };
        const resolvers = {
            resolveComponentMap: {
                underwritingcomponent: UnderwritingComponent
            },
            resolveCallbackMap: {
                onContinueTransaction: this.onContinueTransaction,
                onWithdrawTransaction: this.onWithdrawTransaction,
                redirectToPolicy: this.redirectToPolicy
            }
        };
        const readValue = (id, path) => {
            return readViewModelValue(metadata.pageContent, submission, id, path, overrides);
        };
        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submission}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                resolveValue={readValue}
            />
        );
    }
}

const QuoteDetailsSummary = withModalContext(QuoteDetailsSummaryWithoutModalContext);

export default withAuthenticationContext(withRouter(QuoteDetailsSummary));
