import { defineMessages } from 'react-intl';

export default defineMessages({
    driverSummaryTitle: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-driver-info.driver-summary-title',
        defaultMessage: 'Driver Summary'
    },
    vehicleSummaryTitle: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-driver-info.vehicle-summary-title',
        defaultMessage: 'Vehicle Summary'
    },
    waiverPremiumIncluded: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-driver-info.waiver-premium-included',
        defaultMessage: 'Included'
    },
    policyLevelCoveragesTitle: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-driver-info.policy-level-coverages',
        defaultMessage: 'Policy Level Coverages'
    },
    description: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-driver-info.description',
        defaultMessage: 'Description'
    }
})
