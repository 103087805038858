import React from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import WMICPAPolicyVehicleInfo from '../WMICPAPolicyVehicleInfo/WMICPAPolicyVehicleInfo';
import WMICPAPolicyVehicleUsage from '../WMICPAPolicyVehicleUsage/WMICPAPolicyVehicleUsage';
import WMICPAPolicyVehicleOwnsershipAndAssignment from '../WMICPAPolicyVehicleOwnershipAndAssignment/WMICPAPolicyVehicleOwnsershipAndAssignment';
import WMICPAPolicyVehicleAdditionalInfo from '../WMICPAPolicyVehicleAdditionalInfo/WMICPAPolicyVehicleAdditionalInfo';

import metadata from './WMICPAPolicyVehiclesDetailView.metadata.json5';
import messages from './WMICPAPolicyVehiclesDetailView.messages';

function WMICPAPolicyVehiclesDetailView({ value: vehicleVM, policyVM }) 
{

    const commonAccordionContentProps = {
        vehicleVM,
        policyVM, 
        readOnly: true
    };

    const overrideProps = {
        policyVehicleInfoComponent:{
            ...commonAccordionContentProps,
        },
        policyAdditionalInfoAccordionComponent: {
            ...commonAccordionContentProps,
        },
        policyOwnershipAndAssignmentAccordionComponent: {
            ...commonAccordionContentProps,
        },
        policyVehiclesUsageComponent: {
            ...commonAccordionContentProps,
        },
    };

    const resolvers = {
        resolveComponentMap:{
            WMICPAPolicyVehicleInfo,
            WMICPAPolicyVehicleOwnsershipAndAssignment,
            WMICPAPolicyVehicleAdditionalInfo,
            WMICPAPolicyVehicleUsage
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyVM}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default WMICPAPolicyVehiclesDetailView;
