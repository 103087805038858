import React, { useContext, useState, useEffect } from 'react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';

import metadata from './WMICHOConstructionHeatingUpgradeComponent.metadata.json5';
import messages from './WMICHOConstructionHeatingUpgradeComponent.messages';

function WMICHOConstructionHeatingUpgradeComponent (props) {
    const {
        id,
        heatingUpgradeVM,
        setHeatingUpgradeVM,
        onValidate,
        showErrors: pShowErrors,
        readOnly: pReadOnly
    } = props;

    const [showErrors, setShowErrors] = useState(false);
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const viewModelService = useContext(ViewModelServiceContext);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => {
            if(onValidate){
                onValidate(true, id);
            }
        }
    }, [isComponentValid, onValidate, heatingUpgradeVM, id]);

    const overrideProps = {
        '@field': {
            parentNode: heatingUpgradeVM,
            readOnly: pReadOnly
        }
    }

    const writeValue = (value, path) => {
        if(isComponentValid) {
            const newHeatingUpgradeVM = viewModelService.clone(heatingUpgradeVM);
            _.set(newHeatingUpgradeVM, path, value);
            setHeatingUpgradeVM(newHeatingUpgradeVM);
        }
        else {
            setShowErrors(true);
        }
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={heatingUpgradeVM}
            onModelChange={setHeatingUpgradeVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            onValidationChange={setComponentValidation}
            showErrors={showErrors || pShowErrors}
        />
    );

}

export default WMICHOConstructionHeatingUpgradeComponent;