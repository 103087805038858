import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'

import metadata from './WMICAddPolicyComponent.metadata.json5';
import messages from './WMICAddPolicyComponent.messages';

function WMICAddPolicyComponent(props){
    const {
        id,
        selectedPolicy, 
        savePolicy,
        cancelPolicy,
        onValidate,
        carriersList,
        isEditMode,
        policyViewMode
    } = props;

    const [currentPolicy, updateCurrentPolicy] = useState(selectedPolicy);
    const [showErrors, updateShowErrors] = useState(false);
    const { refreshData } = useDataRefresh()

    const {onValidate: setComponentValidation, isComponentValid} = useValidation(id);
    
    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => {
            if(onValidate){
                onValidate(true, id)
            }
        }
    }, [isComponentValid, onValidate, currentPolicy, id]);

    useEffect(() => {
        updateCurrentPolicy(selectedPolicy);
    }, [selectedPolicy]);

    const updatePolicy = (data) => {
        refreshData();
        updateCurrentPolicy(data);
    };

    const handleSavePolicy = () => {
        if (isComponentValid) {
            savePolicy(currentPolicy);
        } else {
            updateShowErrors(true);
        }
    };

    const changeCarrierName = (value, path) => {
        _.set(currentPolicy, path, value);
        _.set(currentPolicy, 'carrierNameOther.value', undefined);
        updatePolicy(currentPolicy);
    }

    const overrideProps = {
        '@field': {
            parentNode: currentPolicy,
            readOnly: policyViewMode || !isEditMode,
        },
        carrierName: {
            availableValues: carriersList
        },
        WMICAddPolicyForm: {
            isEditing: !policyViewMode
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            handleSavePolicy,
            cancelPolicy,
            changeCarrierName
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentPolicy}
            overrideProps={overrideProps}
            onModelChange={updatePolicy}
            callbackMap={resolvers.resolveCallbackMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    )
}

WMICAddPolicyComponent.propTypes = {
    selectedPolicy: PropTypes.shape({}).isRequired,
    savePolicy: PropTypes.func.isRequired,
    cancelPolicy: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    carriersList: PropTypes.shape([]).isRequired,
    policyViewMode: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired
};

export default WMICAddPolicyComponent;