import { defineMessages } from 'react-intl';

export default defineMessages({
    MVRViolationsSuspensionsDate: {
        id: 'policy.pa.views.pa-drivers-details.mvr-add.violations-suspensions-date',
        defaultMessage: 'Violation/Suspension Date'
    },
    MVRConvictionReinstatementDate: {
        id: 'policy.pa.views.pa-drivers-details.mvr-add.conviction-reinstatement-date',
        defaultMessage: 'Conviction/Reinstatement Date'
    },
    MVRType: {
        id: 'policy.pa.views.pa-drivers-details.mvr-add.type',
        defaultMessage: 'Type'
    },
    MVRDescription: {
        id: 'policy.pa.views.pa-drivers-details.mvr-add.description',
        defaultMessage: 'Description'
    },
    mvrIncidentDescriptionOther: {
        id: 'policy.pa.views.pa-drivers-details.mvr.mvrIncidentDescriptionOther',
        defaultMessage: 'Other : {desc}'
    }
})