import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import metadata from './WMICPolicyHistoryDetailView.metadata.json5';
import messages from './WMICPolicyHistoryDetailView.messages';

function WMICPolicyHistoryDetailView(props){
    const { value : selectedPolicy } = props;

    const overrideProps = {
        '@field': {
            parentNode: selectedPolicy,
            readOnly: true
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={selectedPolicy}
            overrideProps={overrideProps}
        />
    )
}

WMICPolicyHistoryDetailView.propTypes = {
    selectedPolicy: PropTypes.shape({}).isRequired
};

export default WMICPolicyHistoryDetailView;