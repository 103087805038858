import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { ReadOnlyCoverageTable } from 'wmic-pe-capability-quoteandbind-common-react';
import metadata from "./WMICBuildingCoveragesTable.metadata.json5";
import messages from './WMICBuildingCoveragesTable.messages';
import styles from './WMICBuildingCoveragesTable.module.scss';

function WMICBuildingCoveragesTable(props) {
    const { buildingCoverages, isPolicyChangeOrRenewal, submissionVM } = props;
    const translator = useContext(TranslatorContext);

    const overrides = {
        '@field': {
            readOnly: true
        },
        cpBuildingCoverages: {
            visible: !_.isEmpty(buildingCoverages.buldingCoverages),
            coverageList: buildingCoverages.buldingCoverages,
            isPolicyChangeOrRenewal,
            jobVM: submissionVM,
            coverageHeaderText: `${translator(messages.buildingTitle, { displayName: buildingCoverages.buildingNumber })}`
        },
    };

    const resolvers = {
        resolveComponentMap: {
            ReadOnlyCoverageTable
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            model={buildingCoverages}
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICBuildingCoveragesTable.propTypes = {
    value: PropTypes.shape({}).isRequired
};

export default WMICBuildingCoveragesTable;
