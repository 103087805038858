import { defineMessages } from 'react-intl';

export default defineMessages({
    wmicPaInsuranceHistoryTitle: {
        id: 'quoteandbind.ho.views.insuranceHistory.Insurance History',
        defaultMessage: 'Insurance History'
    },
    policies: {
        id: 'quoteandbind.ho.views.insuranceHistory.Policies',
        defaultMessage: 'Policies'
    },
    lossHistoryTitle: {
        id: 'quoteandbind.ho.views.insuranceHistory.Loss History',
        defaultMessage: 'Loss History'
    },
    creditConsent: {
        id: 'quoteandbind.ho.views.insuranceHistory.Credit Consent',
        defaultMessage: 'Credit Consent'
    },
    brokerInfoTitle: {
        id: 'quoteandbind.ho.views.insuranceHistory.Broker Info',
        defaultMessage: 'Broker'
    },
    saveInsuranceHistory: {
        id: 'quoteandbind.ho.views.ho-insurance-history-details.saveInsuranceHistory',
        defaultMessage: 'Save Insurance History'
    },
});
