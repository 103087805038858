export default {
    isValidEmail: emailAddress => {
        const emailRegex = /^(.+)@(\S+)$/;
        const containsInvalidSequence = email => {
            const invalidSequences = ['@[', '@-', ' '];
            return invalidSequences.some(seq => email.includes(seq));
        };
        const containsOnlyUnderscores = email => email.split('@')[0].replaceAll('_', '').length === 0;

        if (emailAddress.endsWith('.') || emailAddress.startsWith("\"") || containsInvalidSequence(emailAddress) || containsOnlyUnderscores(emailAddress)) {
            return false;
        }

        return emailRegex.test(emailAddress);
    },
};
