import { defineMessages } from 'react-intl';

export default defineMessages({
    plumbingType: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICAddPlumbingMaterialComponent.Plumbing Type",
        defaultMessage: "Plumbing Type"
    },
    plumbingAdditionalDescription: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICAddPlumbingMaterialComponent.Describe Other",
        defaultMessage: "Describe Other"
    },
    plumbingMaterialPercentage: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICAddPlumbingMaterialComponent.Percentage",
        defaultMessage: "Percentage"
    }  
})