import { QuestionSetUtils } from '@xengage/gw-portals-util-js'

/**
 * This function will reset any previously selected answers if main question is selected "No"
 *  @param {Object} qsOrderedQuestions ordered question set data
 *  @param {boolean} value user selected value
 *  @param {string} key question code
 *  @param {object} answer answer to the question code
 */
export default {
    // Platform QuestionSetUtils functions
    cleanDependantQuestions: QuestionSetUtils.cleanDependantQuestions
};
