import { defineMessages } from 'react-intl';

export default defineMessages({
    breed: {
        id: 'quoteandbind.dogs-table.breed',
        defaultMessage: 'Breed (including any mix)'
    },
    describeOther: {
        id: 'quoteandbind.dogs-table.describeOther',
        defaultMessage: 'Describe other'
    },
    biteHistory: {
        id: 'quoteandbind.dogs-table.biteHistory',
        defaultMessage: 'Bite history'
    },
    remove: {
        id: 'quoteandbind.dogs-table.remove',
        defaultMessage: 'Remove'
    },
    addAnotherDog: {
        id: 'quoteandbind.dogs-table.addAnotherDog',
        defaultMessage: 'Add another dog'
    },
    selectBreed: {
        id: 'quoteandbind.dogs-table.selectBreed',
        defaultMessage: 'Select your dog\'s breed here. '
            + 'If it is a mix of breeds and you see one of those breeds listed here, please select it from the list. '
            + 'If your dog\'s breed is not listed, select "Other" and type in the name of the breed.'
    },
    breedValidationMessage: {
        id: 'quoteandbind.dogs-table.breedValidationMessage',
        defaultMessage: 'Please select the breed'
    },
    otherValidationMessage: {
        id: 'quoteandbind.dogs-table.otherValidationMessage',
        defaultMessage: 'Please describe the breed'
    },
    biteHistoryValidationMessage: {
        id: 'quoteandbind.dogs-table.biteHistoryValidationMessage',
        defaultMessage: 'Please specify bite history'
    },
});
