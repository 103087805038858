import React, { useContext, useCallback, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { POLICY_DETAILS,
         WMICRichTextUtil,
         JobType,
         CONSTANTS,
         WMICDateTimeService
} from 'wmic-pe-portals-utils-js';
import { PAConsentMessages } from 'wmic-pe-capability-gateway-quoteandbind-pa-react';
import metadata from './WMICCreditConsentPNIComponent.metadata.json5';
import '../WMICCreditConsent.messages';

function WMICCreditConsentPNIComponent(props) {
    const { id, jobVM, setJobVM, onValidateConsentMap, showErrors, readOnly } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);

    const consentDate = _.get(jobVM, 'baseData.creditConsentDate.value');
    const consentRcvd = _.get(jobVM, 'baseData.creditConsentReceived.value');
    const isCreditConsentReceived = _.get(consentRcvd, 'code', '') === POLICY_DETAILS.YES;
    const isCreditConsentNotReceived = _.get(consentRcvd, 'code', '') === POLICY_DETAILS.NO;
    const isPolicyChangeOrRenewal =
        _.get(jobVM, 'baseData.jobType.value.code') === JobType.POLICY_CHANGE ||
        _.get(jobVM, 'baseData.jobType.value.code') === JobType.RENEWAL;


    useEffect(() => {
        const currentValidateCreditConsentReceived = _.get(jobVM, "baseData.validateCreditConsentReceived.value");

        if(currentValidateCreditConsentReceived !== true) {
            writeValue(true, "baseData.validateCreditConsentReceived");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onValidate = useCallback((isValid) => {
        onValidateConsentMap(isValid, id);
    }, [id, onValidateConsentMap]);

    const writeValue = (value, path) => {
        const newConsentVM = viewModelService.clone(jobVM);

        _.set(jobVM, path, value);
        setJobVM(newConsentVM);
    }

    useEffect(() => {
        if (consentDate === undefined) {
            const currentDate = new Date();
            const todaysDate = { day: currentDate.getDate(), month: currentDate.getMonth(), year: currentDate.getFullYear() };
            _.set(jobVM, 'baseData.creditConsentDate.value', todaysDate)
            setJobVM(jobVM);
        }
    }, [consentDate, jobVM, setJobVM]);

    const setCreditConsentInfoPNI = useCallback((data) => {

        if (!_.isNil(consentRcvd)) {
            if (_.isNil(consentDate)) {
                _.set(jobVM, 'baseData.creditConsentDate.value', WMICDateTimeService.today());
                setJobVM(jobVM);
            }

            return consentRcvd.code === data;
        }

        return false;
    }, [consentDate, consentRcvd, jobVM, setJobVM]);

    // eslint-disable-next-line no-secrets/no-secrets
    const isPersonPNI = _.upperFirst(_.get(jobVM, 'baseData.primaryNamedInsured_WMIC.subtype.value', '')) === CONSTANTS.Person;

    const isConsentReceivedPNI = useMemo(() => isPersonPNI && setCreditConsentInfoPNI(POLICY_DETAILS.YES),
        [isPersonPNI, setCreditConsentInfoPNI]);

    const displayName = useMemo(() => _.get(jobVM, 'baseData.primaryNamedInsured_WMIC.displayName.value', ''), [jobVM]);

    const overrideProps = {
        '@field': {
            parentNode: jobVM,
            readOnly,
        },
        creditConsentHeading: {
            title: WMICRichTextUtil.translateRichText(translator(PAConsentMessages.consentFor, {name: displayName}))
        },
        creditConsentReceived: {
            label: WMICRichTextUtil.translateRichText(translator(PAConsentMessages.creditConsentReceivedFrom , {name: displayName})),
        },
        personalInfoConsentForm: {
            visible: isConsentReceivedPNI
        },
        creditConsentDate: {
            visible: isCreditConsentReceived
        },
        creditConsentDOB: {
            label: WMICRichTextUtil.translateRichText(translator(PAConsentMessages.dateOfBirthFor , {name: displayName})),
            visible: isCreditConsentReceived && !isPolicyChangeOrRenewal
        },
        withdrawalCreditConsentDate: {
            visible: isCreditConsentNotReceived
        },
        creditInfoWithdrawalConsent: {
            visible: isCreditConsentNotReceived
        }
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={jobVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );

}

export default WMICCreditConsentPNIComponent;
