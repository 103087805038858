import { defineMessages } from 'react-intl';

export default defineMessages({
    carrierName: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.policy.carrierName",
        defaultMessage: "Insurer"
    },
    carrierNameOther: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.policy.carrierNameOther",
        defaultMessage: "Describe Other"
    },
    carrierCountry: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.policy.carrierCountry",
        defaultMessage: "Country"
    },
    policyNumber: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.policy.policyNumber",
        defaultMessage: "Policy Number"
    },
    riskType: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.policy.riskType",
        defaultMessage: "Risk Type"
    },
    effectiveDate: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.policy.effectiveDate",
        defaultMessage: "Effective Date"
    },
    expirationDate: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.policy.expirationDate",
        defaultMessage: "Expiration Date"
    },
    isCommercial: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.driver-policy-history.policy.isCommercial",
        defaultMessage: "Is this a Commercial Policy?"
    }
});
