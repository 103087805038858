import { defineMessages } from 'react-intl';

export default defineMessages({
    vehicleHeader: {
        id: "wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleCoverages.WMICPAPolicyVehicleCoveragesHeading",
        defaultMessage: "#&lt;b&gt;{vehicleIndex}&lt;/b&gt; &lt;b&gt;{vehicleYear}&lt;/b&gt; &lt;b&gt;{vehicleMake}&lt;/b&gt; &lt;b&gt;{vehicleModel}&lt;/b&gt; &lt;b&gt;{vehicleVIN}&lt;/b&gt;"
    },
    liabilityProtectionHeader:{
        id: "wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleCoverages.liabilityProtectionHeader",
        defaultMessage: "Liability Protection"
    },
    physicalDamageHeader:{
        id: "wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleCoverages.physicalDamageHeader",
        defaultMessage: "Physical Damage"
    },
    driverEndorsements:{
        id: "wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleCoverages.Driver Endorsements",
        defaultMessage: "Driver Endorsements"
    },
    vehicleType:{
        id: "wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleCoverages.Vehicle Type",
        defaultMessage: "Vehicle Type"
    },
    vehicleBusinessSegment:{
        id: "wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleCoverages.Business Segment",
        defaultMessage: "Business Segment"
    },
    vehicleVIN:{
        id: "wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleCoverages.VIN",
        defaultMessage: "VIN"
    }
});
