/**
 * A component to enter an address after looking it up from the google api
 * The address view-model object is used as the input and that this component
 * is being rendered from a view-model form.
 *
 * A standard set of address fields is assumed.
 *
 * addressLine1
 * city
 * state - the typelist
 * postalCode
 * country - the typelist
 *
 * it also assumes that you have called the GoogleApiService.loadScript()
 * from your application index.js
 *
 */

/* eslint-disable react/prop-types */
/* eslint-disable no-plusplus */
import React, { useState, useCallback, useEffect } from 'react';
// eslint-disable-next-line no-unused-vars
import _, { conformsTo } from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { useTranslator } from '@jutro/locale';
import { MetadataContent } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { CONSTANTS, WMICApplicationUtil } from 'wmic-portals-utils-js';
import {
    getGoogleAddressDetails,
    getGoogleAddresses
} from '../google-api-service/GoogleApiService';
import {
    GOOGLE_MODEL_PATH,
    GOOGLE_MODEL_VALUE,
    setGoogleApiSearchValues,
    parseAddress,
    createGoogleAddressSearchValue
} from './AddressLookupUtility';
import metadata from './GoogleAddressLookupComponent.metadata.json5';
import styles from './GoogleAddressLookupComponent.module.scss';
import messages from './GoogleAddressLookupComponent.messages';
import TypeaheadComponent from '../typeahead-component/AddressLookupTypeaheadComponent';

function GoogleAddressLookupComponent(props) {
    const {
        id,
        model,
        path,
        onValueChange,
        mockDataKey,
        mockDataPath,
        displayMap,
        validator,
        extendedValidationFunction,
        extendedValidationErrorMessage,
        extendedValidationWarningMessage,
        extendedValidationErrorCode,
        label,
        labelPosition,
        secondaryLabel,
        tooltip,
        onValidationChange,
        setComponentValidation,
        showErrors,
        streetAddressPlaceHolder,
        setShowAddressErrors
    } = props;
    const isPE = WMICApplicationUtil.isPE();
    const addressVMPath = path.replace(`.${GOOGLE_MODEL_PATH}`, '');
    const addressVMParam = ((model && addressVMPath) ? _.get(model, addressVMPath) : undefined);
    const [addressVM, setAddressVM] = useState(addressVMParam);
    if (!addressVM && addressVMParam) {
        setAddressVM(addressVMParam);
    }
    const addressSearchPropertyVM = _.get(model, path);
    const currentMapObject = setGoogleApiSearchValues(addressVM, mockDataKey, mockDataPath);
    const [currentValidationState, setCurrentValidationState] = useState(null);
    const translator = useTranslator();
    const [nameOfClass, setNameOfClass] = useState('wmicWizardInputLabel wmicInactive');
    const [isOnFocus, setIsOnFocus] = useState(false); 

    /**
     * reflect the virtual field validity
     * @returns {bool} if the address is valid
     */
    const isAddressValid = useCallback((newExtendedError) => {
        if (newExtendedError) { return false; }
        if (!addressSearchPropertyVM) { return undefined; }
        if (!addressSearchPropertyVM.aspects) { return undefined; }
        if (extendedValidationErrorCode) {
            if (extendedValidationErrorCode === 'error') { return false; }
        }
        return (addressSearchPropertyVM.aspects.valid);
    }, [addressSearchPropertyVM, extendedValidationErrorCode]);

    /**
     * reflect the virtual field requiredness
     * @returns {bool} if the address is required
     */
    const isAddressRequired = () => {
        if (!addressSearchPropertyVM) { return undefined; }
        return addressSearchPropertyVM.aspects.required;
    };

    /**
     * reflect the virtual field validation message
     * @returns {string} validation message
     */
    const getValidationMessages = useCallback(() => {
        if (!addressVM || !addressVM.addressLine1 || !addressVM.addressLine1.aspects
            || addressVM.addressLine1.aspects.valid) {
            return undefined;
        }

        const msgs = [];
        if (!addressVM.addressLine1.aspects.valid) {
            msgs.push(addressVM.addressLine1.aspects.validationMessages);
        }
        return msgs.length > 0 ? msgs : undefined;
    }, [addressVM]);

    /**
     * reflect the virtual field validation message
     * @returns {string} validation message
     */
    const getValidationMessage = useCallback((newExtendedError) => {
        const msgs = getValidationMessages(newExtendedError);
        if (!msgs || msgs.length === 0) { return undefined; }
        return msgs.join(', ');
    }, [getValidationMessages]);

    // initialize the component validation
    if (currentValidationState === null && addressSearchPropertyVM) {
        if (setComponentValidation) {
            setComponentValidation(isAddressValid);
        }
        if (onValidationChange) {
            onValidationChange(isAddressValid(), path, getValidationMessage());
        }
        setCurrentValidationState(isAddressValid());
    }

    /**
     * the address lookup search field has been blanked out
     * clear all the related fields
     */
    const clearTypeAheadInput = () => {
        _.set(addressVM, 'addressLine1.value', undefined);
        _.set(addressVM, 'city.value', undefined);
        _.set(addressVM, 'state.value', undefined);
        _.set(addressVM, 'postalCode.value', undefined);

        _.set(addressVM, GOOGLE_MODEL_VALUE, undefined);
    };

    /**
     * function required by the TypeAheadComponent, the google api returns an array
     * of objects and the description is the readable part
     * @param {*} address string or object
     * @returns {string} address string
     */
    const renderItem = (address) => {
        if (typeof address === 'string') { return address; }
        return address.description;
    };

    const handleOnBlur = () => {
        setIsOnFocus(false);
    };

    const handleOnFocus = () => {
        setIsOnFocus(true);
    }

    useEffect(() => {
        if (isOnFocus) {
            setNameOfClass('wmicWizardInputLabel wmicActive');
        } else {
            setNameOfClass(`wmicWizardInputLabel ${_.isUndefined(props.value) ? 'wmicInactive' : 'wmicActiveNotFocused'}`);
        }
    }, [props, addressSearchPropertyVM, isOnFocus]);

    /**
     * Grabs addresses from GoogleApiService after any input
     * don't update the state as we want the lookup component to use the return
     *
     * @param {string} userInput
     * @returns {*} address items matching input
     */
    const searchItems = async (userInput) => {
        let addresses = [];
        // default is to use regex to ensure the userInput always starts with Numeric value.
        const validUserInput = validator ? validator.test(userInput) : userInput;
        if (validUserInput) {
            addresses = await getGoogleAddresses(userInput);
        }
        _.set(addressVM, GOOGLE_MODEL_VALUE, userInput);
        let keyIndex = 0; // the key reduces warning errors
        // eslint-disable-next-line no-param-reassign
        addresses.forEach((address) => { address.key = keyIndex++; });
        return addresses;
    };

    const saveAddressLine = useCallback((value) => {
        _.set(addressVM, 'addressLine1.value', value);
        setAddressVM(addressVM);
    }, [addressVM]);

    /**
     * populate fields by saving address to addressVM
     * set google api display field
     * set map address as well
     * @param {*} fullAddressDetails
     */
    const saveAddressDetails = useCallback(async (fullAddressDetails) => {
        const formattedAddress = fullAddressDetails[0].formatted_address;
        
        const county = fullAddressDetails[0].address_components.find((info) => {
            return info.types[0] === CONSTANTS.GOOGLE_ADDRESS_DETAILS.COUNTY;
        });
        const {
            addressLine1, city, state, zipCode, country
        } = parseAddress(formattedAddress);
        
        if(county) {
            _.set(addressVM, 'county.value', county.short_name);
        }
        _.set(addressVM, 'addressLine1.value', addressLine1);
        _.set(addressVM, 'city.value', city);
        _.set(addressVM, 'state.value', state);
        _.set(addressVM, 'postalCode.value', zipCode);
        _.set(addressVM, 'country.value', country);
        _.set(addressVM, 'displayName.value', formattedAddress);
        _.set(addressVM, GOOGLE_MODEL_VALUE, createGoogleAddressSearchValue(
            addressVM,
            mockDataKey,
            mockDataPath
        ));
        const newExtendedValidation = await extendedValidationFunction(addressVM);
        setAddressVM(addressVM);
        onValueChange(addressVM, addressVMPath);
        const newValidationState = isAddressValid(!newExtendedValidation);
        if (newValidationState !== currentValidationState) {
            onValidationChange(
                newValidationState,
                path,
                getValidationMessage(!newExtendedValidation)
            );
            setCurrentValidationState(newValidationState);
        }
        setShowAddressErrors(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressVM]);

    /**
     * Uses ID of selected item to get full address including zip code and aptNo if applicable
     * @param {*} address - full street address
     */
    const handleUserSelection = (address) => {
        clearTypeAheadInput();
        if (address !== messages.addressNotFound.defaultMessage) {
            getGoogleAddressDetails(address.place_id, (resp) => {
                saveAddressDetails(resp);
            });
        }
    };
    const clearAddressDetails = useCallback(async () => {
        clearTypeAheadInput();
        _.set(addressVM, GOOGLE_MODEL_VALUE, createGoogleAddressSearchValue(
            addressVM,
            mockDataKey,
            mockDataPath
        ));

        const newExtendedValidation = await extendedValidationFunction(addressVM);
        // const newExtendedValidation = true;
        setAddressVM(addressVM);
        onValueChange(addressVM, addressVMPath);
        const newValidationState = isAddressValid(!newExtendedValidation);
        if (newValidationState !== currentValidationState) {
            onValidationChange(
                newValidationState,
                path,
                getValidationMessage(!newExtendedValidation)
            );
            setCurrentValidationState(newValidationState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressVM]);

    if (!addressVM || !addressVM.value) {
        return (<></>);
    }

    /**
     * set the initial search value for the google api
     * don't update the state
     */
    const newAddressSearchValue = createGoogleAddressSearchValue(
        addressVM,
        mockDataKey,
        mockDataPath
    );
    if (_.get(addressVM, GOOGLE_MODEL_VALUE) !== newAddressSearchValue) {
        _.set(addressVM, GOOGLE_MODEL_VALUE, newAddressSearchValue);
        extendedValidationFunction(addressVM);
        setAddressVM(addressVM);
        onValueChange(addressVM, addressVMPath);
    }

    const overrideProps = {
        streetAddress: {
            key: `${id}-streetAddress`,
            value: addressSearchPropertyVM.value.split(',')[0],
            label: label,
            labelPosition: labelPosition,
            secondaryLabel: secondaryLabel,
            path: path,
            tooltip: tooltip,
            placeholder: streetAddressPlaceHolder === undefined ? translator(messages.enterAddress) : streetAddressPlaceHolder,
            required: isAddressRequired(),
            onClearTypeaheadInput: clearTypeAheadInput,
            validationMessages: getValidationMessages(),
            showErrors,
            labelClassName: nameOfClass,
            onFocus: handleOnFocus,
            onCancelSelection: saveAddressLine,
            className: styles.addressTypeAhead
        },
        googleMapArea: {
            key: `${id}-googleMapArea`,
            GOOGLE_MAPS_API_KEY: appConfig.credentials.GOOGLE_MAPS_API_KEY,
            value: currentMapObject,
            visible: (displayMap && (!!currentMapObject)),
            disabled: ((!displayMap) && (!currentMapObject))
        },
        showAddressError: {
            key: `${id}-showAddressError`,
            visible: extendedValidationErrorCode === 'error',
            messageProps: {
                error: extendedValidationErrorMessage
            },
        },
        addressValidation: {
            key: `${id}-addressValidation`,
            visible: extendedValidationErrorCode === 'warning',
            messageProps: {
                warning: extendedValidationWarningMessage,
            }
        },

    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            typeAheadSearchItems: searchItems,
            onHandleUserSelection: handleUserSelection,
            onRenderItem: renderItem,
            onClearTypeAheadInput: clearAddressDetails,
            onBlur: handleOnBlur,
        },
        resolveComponentMap: {
            typeaheadcomponent: TypeaheadComponent
        }
    };

    const renderContent = <MetadataContent
        uiProps={metadata.componentContent}
        overrideProps={overrideProps}
        {...resolvers} />;

    return (<>{renderContent}</>);
}
/**
 * component property types
 */
GoogleAddressLookupComponent.propTypes = {
    /**
     * root model input to the ViewModelForm
     */
    model: PropTypes.shape({}).isRequired,
    /**
     * path to the virtual google search entry text
     * this will be  created by the initializeAddressSearchViewModel call
     */
    path: PropTypes.string.isRequired,
    /**
     * this will be supplied by the ViewModelForm.
     */
    onValueChange: PropTypes.func.isRequired,
    /**
     * an optional map of the address can be displayed, if
     * true supplied it will enable the map.
     */
    displayMap: PropTypes.bool,
    /**
     * optional label for the google search/display field
     */
    label: PropTypes.string || PropTypes.shape({}),
    /**
     * optional secondary label for the google search/display field
     */
    secondaryLabel: PropTypes.string || PropTypes.shape({}),
    /**
     * optional help text which is available when you click the question mark.
     */
    tooltip: PropTypes.string || PropTypes.shape({}),
    /**
     * if you are using the address where quote and buy mock data is used
     * you might need the mockDataKey to indicate where the LOB mock data is
     */
    mockDataKey: PropTypes.string,
    /**
     * if you are using the address where quote and buy mock data is used
     * you might need the mockDataPath to indicate where the mock address is
     */
    mockDataPath: PropTypes.string,
    /**
     * an additional validation function to be run on address entry
     * it is expected that extendedValidationErrorCode will be
     * passed if extendedValidationFunction fails.
     */
    extendedValidationFunction: PropTypes.func,
    /**
     * the warning message for the extended validation
     */
    extendedValidationWarningMessage: PropTypes.shape({}),
    /**
     * the error message for the extended validation
     */
    extendedValidationErrorMessage: PropTypes.shape({}),
    /**
     * the error/warning indication.  valid values are 'warning' or 'error'
     * if the address is in error during initial execution it is expected
     * that this will be passed based on the input value of the address.
     */
    extendedValidationErrorCode: PropTypes.string,
    /**
     * regex validator applied to entered address search text
     * defaults to no filter at all, use /^\d./ for a leading digit
     */
    validator: PropTypes.instanceOf(RegExp),
    /**
     * default label position is left, you can set top if you would
     * like the label over the entry field
     */
    labelPosition: PropTypes.string,
    /**
     * this will be supplied by the ViewModelForm and is part of the
     * ootb validation framework.
     */
    onValidationChange: PropTypes.func,
    /**
     * this will be supplied by the ViewModelForm and is part of the
     * ootb validation framework.
     */
    setComponentValidation: PropTypes.func,
    /**
     * if you would like the current validation errors displayed.
     */
    showErrors: PropTypes.bool,
    streetAddressPlaceHolder: PropTypes.string
};

GoogleAddressLookupComponent.defaultProps = {
    displayMap: false,
    mockDataKey: undefined,
    mockDataPath: undefined,
    label: messages.streetAddress,
    secondaryLabel: undefined,
    tooltip: undefined,
    // default is an unrestricted search of google addresses
    validator: /./,
    extendedValidationFunction: _.noop,
    extendedValidationWarningMessage: {
        id: 'warning',
        defaultMessage: 'Address is not valid'
    },
    extendedValidationErrorMessage: {
        id: 'address.lookup.component.address.error',
        defaultMessage: 'We couldn\'t find your address.'
    },
    extendedValidationErrorCode: undefined,
    labelPosition: 'top',
    onValidationChange: undefined,
    setComponentValidation: undefined,
    showErrors: false,
    streetAddressPlaceHolder: undefined
};
export default GoogleAddressLookupComponent;
