import { defineMessages } from 'react-intl';

export default defineMessages({
    vehicleHeader: {
        id: 'wmic-pe-capability-gateway-common-pa-react.PAVehicleCoverages.Vehicle Header',
        defaultMessage: '#{vehicleNumber}: {year} {make} {model} ({vin})'
    },
    vehicleType: {
        id: 'wmic-pe-capability-gateway-common-pa-react.PAVehicleCoverages.Vehicle Type',
        defaultMessage: 'Vehicle Type'
    },
    businessSegment: {
        id: 'wmic-pe-capability-gateway-common-pa-react.PAVehicleCoverages.Business Segment',
        defaultMessage: 'Business Segment'
    },
    VIN: {
        id: 'wmic-pe-capability-gateway-common-pa-react.PAVehicleCoverages.VIN',
        defaultMessage: 'VIN'
    },
    proofOfFinancialResponibility: {
        id: 'wmic-pe-capability-gateway-common-pa-react.PAVehicleCoverages.Proof of Financial Responsibility',
        defaultMessage: 'Proof of Financial Responsibility'
    },
    tplMessage: {
        id: 'wmic-pe-capability-gateway-common-pa-react.PAVehicleCoverages.TPL',
        defaultMessage: 'Third Party Liability limits must be the same for all vehicles. Any changes will update all other vehicles.'
    }
});
