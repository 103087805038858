import { defineMessages } from 'react-intl';

export default defineMessages({
    policies: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalInsuranceHistory.Policies',
        defaultMessage: 'Policies'
    },
    lossHistoryTitle: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalInsuranceHistory.Loss History',
        defaultMessage: 'Loss History'
    },
    creditConsent: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalInsuranceHistory.Credit Consent',
        defaultMessage: 'Credit Consent'
    },
    brokerInfoTitle: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalInsuranceHistory.Broker',
        defaultMessage: 'Broker'
    },
    saveInsuranceHistory: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalInsuranceHistory.saveInsuranceHistory',
        defaultMessage: 'Save Insurance History'
    },
});