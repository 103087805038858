import { defineMessages } from 'react-intl';

export default defineMessages({
    tradeName: {
        id: 'quoteandbind.views.quote-effective-date.Trade Name Button',
        defaultMessage: 'Trade Name'
    },
    fName: {
        id: 'quoteandbind.views.quote-effective-date.trade-name.First Name',
        defaultMessage: 'First Name'
    },
    lName: {
        id: 'quoteandbind.views.quote-effective-date.trade-name.Last Name',
        defaultMessage: 'Last Name'
    },
    name: {
        id: 'quoteandbind.views.quote-effective-date.trade-name.Name',
        defaultMessage: 'Name'
    },
    companyName: {
        id: 'quoteandbind.views.quote-effective-date.trade-name.Company Name',
        defaultMessage: 'Company Name'
    },
    relationship: {
        id: 'quoteandbind.views.quote-effective-date.trade-name.Relationship',
        defaultMessage: 'Relationship'
    },
    removeTradeNameLong: {
        id: 'quoteandbind.views.quote-effective-date.trade-name.Remove Trade Name Long',
        defaultMessage: 'Are you sure you want to remove {displayName} trade name from the list?'
    },
    removeTradeNameShort: {
        id: 'quoteandbind.views.quote-effective-date.trade-name.Remove Trade Name Short',
        defaultMessage: 'Remove Trade Name'
    },
    address: {
        id: 'quoteandbind.views.quote-effective-date.trade-name.Address',
        defaultMessage: 'Address'
    },
    subtype: {
        id: 'quoteandbind.views.quote-effective-date.trade-name.Subtype',
        defaultMessage: 'Type'
    },
    businessType: {
        id: 'quoteandbind.views.quote-effective-date.trade-name.BusinessType',
        defaultMessage: 'Business Type'
    },
    locationAddress: {
        id: 'quoteandbind.views.quote-effective-date.Location Address',
        defaultMessage: 'Location Address same as Relationship?'
    }
})
