import { defineMessages } from 'react-intl';

export default defineMessages({
    qualifications: {
        id: 'quoteandbind.views.prequal-questions.Qualification',
        defaultMessage: 'Qualification'
    },
    qualificationSubtitle: {
        id: "wmic.quoteandbind.ho.views.qualifications.Let's answer a few questions to ensure we have a product that fits your needs.",
        defaultMessage: "Let's answer a few questions to ensure we have a product that fits your needs."
    },
    savingSubmission: {
        id: 'quoteandbind.views.prequal-questions.Saving submission',
        defaultMessage: 'Submitting qualifications...'
    }
});
