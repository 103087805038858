import _ from "lodash";
import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';

import { TranslatorContext } from '@jutro/locale';

import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import { OFFERING } from 'wmic-pe-portals-utils-js';
import WMICGLCoverageUtil from "wmic-pe-capability-gateway-quoteandbind-gl-react/util/WMICGLCoverageUtil";

import metadata from "./WMICLiablityCoverages.metadata.json5";
import messages from './WMICLiablityCoverages.messages';
import styles from './WMICLiablityCoverages.module.scss';

function WMICLiablityCoverages(props) {
    const { jobVM } = props;
    const translator = useContext(TranslatorContext);
    const generalLiabilityLine = jobVM.lobData.generalLiability;

    const getCategorizedCoverages = () => {
        const rawLineCoverages =  WMICGLCoverageUtil.getLiabilityLineCoverages(generalLiabilityLine);
        return WMICGLCoverageUtil.categorizeLiabilityLineCoverages(rawLineCoverages, true);
    };

    const getCategorizedCrimeCoverages = () => {
        const rawCrimeCoverages = WMICGLCoverageUtil.getCrimeLineCoverages(generalLiabilityLine);
        return WMICGLCoverageUtil.categorizeCrimeLineCoverages(rawCrimeCoverages, true)
    };

    const getCoverages = () =>  {
        const allCoverages = {
            displayCoverages: {
                categoryName: translator(messages.description),
                coverages: []
            }
        };

        switch(generalLiabilityLine.offerings.children[0].branchCode.value) {
            case OFFERING.NoEnhancement: {
                    const lineCoverages = getCategorizedCoverages();

                    allCoverages.displayCoverages.coverages = [
                        ...lineCoverages.commercialgeneralliability.coverages,
                    ];
                break;
            }
            case OFFERING.ContractorsChoice: {
                    const lineCoverages = getCategorizedCoverages();
                    const crimeCoverages = getCategorizedCrimeCoverages();

                    allCoverages.displayCoverages.coverages = [
                        ...lineCoverages.commercialgeneralliability.coverages,
                        {
                            isLabel: true,
                            name:  translator(messages.crimeTitle),
                            id: 'catch_all',
                            label: undefined
                        },
                        ...crimeCoverages.employeeDishonesty.coverages,
                        ...crimeCoverages.moneySecurities.coverages,
                        {
                            isLabel: true,
                            name:  translator(messages.catchAllClause),
                            id: 'catch_all',
                            label: translator(messages.seeQuoteDocument)
                        },
                    ];
                break;
            }
            default: {
                const lineCoverages = getCategorizedCoverages();
                const crimeCoverages = getCategorizedCrimeCoverages();

                allCoverages.displayCoverages.coverages = [
                    ...lineCoverages.commercialgeneralliability.coverages,
                    {
                        isLabel: true,
                        name:  translator(messages.cyber),
                        id: 'catch_all',
                        label: translator(messages.seeQuoteDocument)
                    },
                    {
                        isLabel: true,
                        name:  translator(messages.crimeTitle),
                        id: 'catch_all',
                        label: undefined
                    },
                    ...crimeCoverages.employeeDishonesty.coverages,
                    ...crimeCoverages.moneySecurities.coverages,
                    {
                        isLabel: true,
                        name:  translator(messages.legalExpense),
                        id: 'catch_all',
                        label: translator(messages.seeQuoteDocument)
                    },
                    {
                        isLabel: true,
                        name:  translator(messages.catchAllClause),
                        id: 'catch_all',
                        label: translator(messages.seeQuoteDocument)
                    },
                ];
                break;
            }
        }

        return allCoverages;
    };

    const coveragesToDisplay = getCoverages();

    const overrides = {
        '@field': {
            readOnly: true
        },
        liabilityCoverages: {
            visible: !_.isEmpty(coveragesToDisplay.displayCoverages.coverages),
            coverageList: coveragesToDisplay.displayCoverages.coverages,
            coverageHeaderText: coveragesToDisplay.displayCoverages.categoryName,
            flat: true,
            jobVM
        },
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            model={coveragesToDisplay}
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICLiablityCoverages.propTypes = {
    value: PropTypes.shape({
        generalLiabilityLine: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired
};

export default WMICLiablityCoverages;
