/* eslint-disable no-restricted-globals */
import React from 'react';
import WMICMessageModal from 'gw-capability-quoteandbind-common-react/components/WMICMessageModal/WMICMessageModal';

import { HOConstants, getGlobal } from 'wmic-pe-portals-utils-js';
import WMICHORiskUtil from 'wmic-pe-capability-gateway-common-ho-react/utils/WMICHORiskUtil';
import messages from './WMICValidationWarningUtil.messages';

function getCoverageTypeChangedToBasicMessage(dwelling, translator, viewModelService) {
    let message = '';
    let dwellingNumber = dwelling.yourHome.dwellingNumber_EXT;
    const dwellingAddress = WMICHORiskUtil.formatDwellingLocation(dwelling.yourHome.locationAddress.displayName);
    const hOPolicyTypeString = dwelling.rating.hoPolicyType.value ? dwelling.rating.hoPolicyType.value.code : dwelling.rating.hoPolicyType;
    const dwellingPolicyType = viewModelService.productMetadata.get('pc').types
        .getTypelist('HOPolicyType_HOE')
        .getCode(hOPolicyTypeString);

    if (dwellingNumber && (!isNaN(dwellingNumber.value) || dwellingNumber.value)) {
        dwellingNumber = isNaN(dwellingNumber) ? dwellingNumber.value : dwellingNumber;
        message = translator(messages.coverageTypeChangedToBasicForRisk,
            { riskNumber: dwellingNumber.value, riskType: dwellingPolicyType.code, riskAddress: dwellingAddress });
    } else {
        message = translator(messages.coverageTypeChangedToBasic);
    }

    return { issueMessage: message };
}

function updateWarningMessagesIfNecessary(dwellings, previousDwellings, validationWarningMessages) {
    dwellings.forEach((dwelling) => {
        const previousDwelling = previousDwellings.find((prevDwell) => prevDwell.publicID === dwelling.publicID);

        if (previousDwelling && previousDwelling.coverageType !== HOConstants.coverageTypeBasic
            && dwelling.doesCoverageTypeSetToBasicAndRatingMethodSetToStandard) {
            const validationWarningMessage = getCoverageTypeChangedToBasicMessage(dwelling);

            validationWarningMessages.push(validationWarningMessage);
        }
    });
}

function showValidationWarning(validationWarnings) {
    const showCustom = getGlobal('showCustom');

    if (validationWarnings && validationWarnings.length > 0) {
        const issues = validationWarnings.map((warning) => ({ message: warning.issueMessage }));

        showCustom(
            <WMICMessageModal
                title={messages.important}
                message={messages.dueToEligibilityChangesMade}
                iconClass="fa fa-fw fa-exclamation-triangle"
                messages={issues}
            />
        );
    }
}

export default {
    displayWarningMessagesIfNecessary(dwellings, previousDwellings, addQuoteValidationWarningsIfNecessary) {
        if (previousDwellings && previousDwellings.isSavingRisk) {
            const validationWarningMessages = [];

            updateWarningMessagesIfNecessary(dwellings, previousDwellings, validationWarningMessages);

            if (addQuoteValidationWarningsIfNecessary) {
                addQuoteValidationWarningsIfNecessary(validationWarningMessages);
            }

            showValidationWarning(validationWarningMessages);
            // eslint-disable-next-line no-param-reassign
            previousDwellings.isSavingRisk = false;
        }
    },

    getCoverageTypeChangedToBasicMessage(dwelling) {
        return getCoverageTypeChangedToBasicMessage(dwelling);
    },

    showValidationWarning(validationWarnings) {
        return showValidationWarning(validationWarnings);
    },

    showCoverageTypeChangedToBasic(dwelling, translator, viewModelService) {
        const issues = [getCoverageTypeChangedToBasicMessage(dwelling, translator, viewModelService)].map((warning) => ({ message: warning.issueMessage }));
        const showCustom = getGlobal('showCustom');
        
        showCustom(
            <WMICMessageModal
                title={messages.important}
                message={messages.dueToEligibilityChangesMade}
                iconClass="fa fa-fw fa-exclamation-triangle"
                messages={issues}
            />
        );
    }
};
