import { defineMessages } from 'react-intl';

export default defineMessages({
    coverages: {
        id: 'quoteandbind.ho.directives.HoOfferingSelection.dwellingCoverages.Coverages',
        defaultMessage: 'Coverages',
    },
    optionalProperty: {
        id: 'quoteandbind.ho.directives.HoOfferingSelection.dwellingCoverages.Optional Property Coverages',
        defaultMessage: 'Optional Property Coverages',
    },
    optionalLiability: {
        id: 'quoteandbind.ho.directives.HoOfferingSelection.dwellingCoverages.Optional Liability Coverages',
        defaultMessage: 'Optional Liability Coverages',
    },
    exclusionsConditions: {
        id: 'quoteandbind.ho.directives.HoOfferingSelection.dwellingCoverages.Exclusions & Conditions',
        defaultMessage: 'Exclusions & Conditions',
    },
    riskHeader: {
        id: 'quoteandbind.ho.views.ho-your-home.RiskNo2',
        defaultMessage: 'Risk #'
    },
    riskNumber: {
        id: 'quoteandbind.ho.views.ho-your-home.RiskNoHashtag',
        defaultMessage: '#'
    },
    primaryHeader: {
        id: 'quoteandbind.ho.views.ho-your-home.Primary2',
        defaultMessage: 'Primary'
    },
    addressHeader: {
        id: 'quoteandbind.ho.views.ho-your-home.Address2',
        defaultMessage: 'Address'
    },
    riskTypeHeader: {
        id: 'quoteandbind.ho.views.ho-your-home.Risk Type2',
        defaultMessage: 'Risk Type'
    },
    additionalInterests: {
        id: 'quoteandbind.ho.views.ho-your-home.dwellingCoverages.Additional Interests',
        defaultMessage: 'Additional Interests'
    },
    additionalCoverages: {
        id: 'quoteandbind.ho.views.ho-your-home.dwellingCoverages.Additional Coverages',
        defaultMessage: 'Additional Coverages'
    }
});
