import _ from 'lodash';


export const getMotorDescription = (motorVM) => {
    const motorValue = motorVM.value;

    const year = _.isUndefined(motorValue.equipmentInfo.year) ? '' : `${motorValue.equipmentInfo.year} `;
    const make = _.isUndefined(motorValue.equipmentInfo.make) ? '' : `${motorValue.equipmentInfo.make} `;
    const model = _.isUndefined(motorValue.equipmentInfo.model) ? '' : motorValue.equipmentInfo.model;
    return `${year}${make}${model}`;
}