import { defineMessages } from 'react-intl';

export default defineMessages({
    reselectVehicle: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.ReselectVehicle',
        defaultMessage: 'Return to <b>Vehicle Info</b> to reselect the vehicle. The VIN search has returned more than one vehicle.'
    },
    businessSegmentCannotBeUpdated: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.BusinessSegmentCannotBeUpdated',
        defaultMessage: 'Business segment cannot be updated at this time. Please try again later.'
    },
    businessSegment: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.vehicleBusinessSegment',
        defaultMessage: 'Business segment'
    },
    usage: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.vehicleUsage',
        defaultMessage: 'Usage'
    },
    primaryUse: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.PrimaryUse',
        defaultMessage: 'Primary Use'
    },
    mileageUnit: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.MileageUnit',
        defaultMessage: 'Mileage Unit'
    },
    businessUse: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.BusinessUse',
        defaultMessage: 'Business Use %'
    },
    usedForRideSharing: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.Is the vehicle used for ride-sharing',
        defaultMessage: 'Is the vehicle used for ride-sharing?'
    },
    transportationNetworkCompany: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.Transportation Network Company',
        defaultMessage: 'Transportation Network Company'
    },
    otherDescription: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.Other Description',
        defaultMessage: 'Other Description'
    },
    commutingMileage: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.CommutingMileage',
        defaultMessage: 'Commuting Mileage'
    },
    anualMileage: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.AnnualMileage',
        defaultMessage: 'Annual Mileage'
    },
    currentOdometerReading: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.CurrentOdometerReading',
        defaultMessage: 'Current Odometer Reading'
    },
    dateReadingTaken: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.DateReadingTaken',
        defaultMessage: 'Date Reading Taken'
    },
    usedOutsideOfCanada: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.UsedOutsideOfCanada',
        defaultMessage: 'Is the vehicle used outside Canada?'
    },
    howManyMonths: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.HowManyMonths',
        defaultMessage: 'How many months?'
    },
    details: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.Details',
        defaultMessage: 'Details'
    },
    usedOutsideOfProvince: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.UsedOutsideOfProvince',
        defaultMessage: 'Is the vehicle used outside of Province?'
    },
    howManyDaysPerYear: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.HowManyDaysPerYear',
        defaultMessage: 'How many days per year?'
    },
    reasons: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.Reasons',
        defaultMessage: 'Reasons'
    },
    daytimeParkingLocation: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.DaytimeParkingLocation',
        defaultMessage: 'Daytime Parking Location'
    },
    overnightParkingLocation: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.OvernightParkingLocation',
        defaultMessage: 'Overnight Parking Location'
    },
    garageLocation: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.GarageLocation',
        defaultMessage: 'Garage Location'
    },
    whereIsPrimaryLocation: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.WhereIsPrimaryLocation',
        defaultMessage: 'Where is the primary location for this vehicle?'
    },
    addLocation: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.Add Location',
        defaultMessage: 'Add Location'
    },
    whereVehicleRated: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.WhereVehicleRated',
        defaultMessage: 'Is this where the vehicle should be rated?'
    },
    estimatedReturnDate: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.EstimatedReturnDate',
        defaultMessage: 'Estimated Date of Return'
    },
    ratedGarageLocation: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.RatedGarageLocation',
        defaultMessage: 'Rated Garage Location'
    },
    addGarageLocation: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.Add Garage Location',
        defaultMessage: 'Add Location'
    },
    vehicleWinterTires: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.VehicleWinterTires',
        defaultMessage: 'Does this vehicle have winter tires?'
    },
    billOfSaleDate: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.BillOfSaleDate',
        defaultMessage: 'Bill of Sale'
    },
    risks: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.Risks',
        defaultMessage: 'Risks'
    },
    leasedToOthers: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.RentedLeasedToOthers',
        defaultMessage: 'Is unit rented or leased to others?'
    },
    usedForCommercialOrBusiness: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.UsedForCommercialOrBusiness',
        defaultMessage: 'Is unit used for commercial or business purposes?'
    },
    usedAsPrincipalResidence: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.UsedAsPrincipalResidence',
        defaultMessage: 'Is unit used as a principal residence?'
    },
    usedForCarSharing: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.usedForCarSharing',
        defaultMessage: 'Is vehicle Used for Car Sharing?'
    },
    carSharingCompany: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.carSharingCompany',
        defaultMessage: 'Car Sharing Company'
    },
    SharingOtherDescription: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsage.SharingOtherDescription',
        defaultMessage: 'Other Description'
    }
})