import React from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { CONSTANTS } from 'wmic-pe-portals-utils-js';
import { WMICAddressDetails } from 'wmic-pe-components-platform-react';
import metadata from './WMICPAPolicyAIDetailView.metadata.json5';

function WMICPAPolicyAIDetailView({ additionalInterestVM }) {
   
    const additionalInterestSubtype = _.get(additionalInterestVM, 'policyAdditionalInterest.subtype.value');

    const overrideProps = {
        '@field': {
            parentNode: additionalInterestVM,
            readOnly: true
        },
        policyNewAICompanyContainer: {
            visible: additionalInterestSubtype === CONSTANTS.Company
        },
        policyNewAIPersonContainer: {
            visible: additionalInterestSubtype === CONSTANTS.Person
        },
        addressLookup: {
            addressVM : _.get(additionalInterestVM, 'policyAdditionalInterest.primaryAddress'),
            readOnly: true
        },
    };

    const resolvers = {
        resolveComponentMap: {
            WMICAddressDetails
        },
    };

    return (
        <ViewModelForm
            model={additionalInterestVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}


export default WMICPAPolicyAIDetailView;
