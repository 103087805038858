import _ from 'lodash';
import { EMAIL_VALIDATION_STATUS } from "wmic-pe-portals-utils-js";
import { AccountService } from 'wmic-pe-capability-gateway-policy';

export default class WMICEmailUtil {
    
    static validateEmail = (accountEmail, confirmEmail, jobNumber, authHeader) => {
        if (accountEmail === confirmEmail) {
            return AccountService.validateEmail(accountEmail, jobNumber, authHeader)
        }

        return EMAIL_VALIDATION_STATUS.INVALID
    }
}