import { defineMessages } from 'react-intl';

export default defineMessages({
    epoiEnrolled_Yes: {
        id: "wmic.views.policy-detail-summary.epoiYes",
        defaultMessage: "Yes, send the EPOI to the customer via email"
    },
    epoiEnrolled_No: {
        id: "wmic.views.policy-detail-summary.epoiNo",
        defaultMessage: "No, send proof of insurance through standard mail"
    },
    eDelivery_WMICNo: {
        id: 'agent.views.policy-detail-summary.eDeliveryNo',
        defaultMessage:
            'No, send policy and billing documents to customers through standard mail',
    },
    eDelivery_WMICYes: {
        id: 'agent.views.policy-detail-summary.eDeliveryYes',
        defaultMessage:
            'Yes, send policy and billing documents to customers electronically via email',
    },
    emailAddress: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.contact.Email Address',
        defaultMessage: 'Email Address',
    },
    primaryPhone: {
        id: 'quoteandbind.views.quote-effective-date.Primary Phone',
        defaultMessage: 'Primary Phone',
    },
    noPhone: {
        id: 'quoteandbind.views.quote-effective-date.No Phone',
        defaultMessage: 'No phone',
    },
    invalidPhone: {
        id: 'quoteandbind.views.quote-effective-date.Invalid Phone Type',
        defaultMessage: 'Invalid Phone Type',
    },
    homePhone: {
        id: 'quoteandbind.views.quote-effective-date.Home Phone',
        defaultMessage: 'Home Phone',
    },
    workPhone: {
        id: 'quoteandbind.views.quote-effective-date.Work Phone',
        defaultMessage: 'Work Phone',
    },
    mobilePhone: {
        id: 'quoteandbind.views.quote-effective-date.Mobile Phone',
        defaultMessage: 'Mobile Phone',
    },
    policyMailingAddress: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.mailing.Policy Mailing Address',
        defaultMessage: 'Policy Mailing Address',
    },
    policyCountry: {
        id: 'platform.inputs.address-details.Country',
        defaultMessage: 'Country',
    },
    policyAddressLine1: {
        id: 'platform.inputs.address-details.Address Line 1',
        defaultMessage: 'Address Line 1',
    },
    policyAddressLine2: {
        id: 'platform.inputs.address-details.Address Line 2',
        defaultMessage: 'Address Line 2',
    },
    policyCity: {
        id: 'platform.inputs.address-details.City',
        defaultMessage: 'City',
    },
    policyState: {
        id: 'platform.inputs.address-details.State',
        defaultMessage: 'Province/Territory',
    },
    emailValid: {
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyContacts.Valid Email',
        defaultMessage: 'The email has been verified'
    },
    invalidtls: {
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyContacts.Invalid TLS',
        defaultMessage: 'The email does not comply with our security standards and cannot be used to receive documents.'
    },
    unavailable: {
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyContacts.Unavailable',
        defaultMessage: 'The verification could not be completed. Please continue and the email will be validated later.'
    },
    invalid: {
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyContacts.Invalid',
        defaultMessage: 'The email is invalid. A valid email will be required to bind the policy.'
    }
});
