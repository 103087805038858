import React, { useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale'; 
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { WatercraftContext } from 'wmic-pe-capability-gateway-quoteandbind-ho-react';
import { WMICRPCUtil, WMICRichTextUtil } from 'wmic-pe-portals-utils-js';
import metadata from './WMICHOEquipmentDetails.metadata.json5';
import messages from './WMICHOEquipmentDetails.messages.js'

function WMICHOEquipmentDetails({
    id,
    watercraftComponentVM,
    onValidate,
    showErrors,
    readOnly,
    baseData
}) {
    
    const {
        onValidate: setComponentValidation,
        isComponentValid,
    } = useValidation(id);
    const translator = useContext(TranslatorContext);
    const { updateWatercraftRiskView } = useContext(WatercraftContext);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
        // if component is unmounted, report as valid so it doesn't break the rest of the form
        return () => {
            if (onValidate) {
                onValidate(true, id);
            }
        }
    }, [id, onValidate, isComponentValid]);

    const shouldShowAtLeastOneRequiredToBindMessage = useCallback(() => {
        const isRPC1554Effective = WMICRPCUtil.getIsRPCEffective(_.get(baseData, "baseState.value.code"), _.get(baseData, "rateAsOfDate.value"), '1554');
        const serialNumberEmpty = !_.get(watercraftComponentVM, "equipmentInfo.serialNumber.value") 
                                || _.get(watercraftComponentVM, "equipmentInfo.serialNumber.value").trim() === "";
        const makeEmpty = !_.get(watercraftComponentVM, "equipmentInfo.make.value") 
                        || _.get(watercraftComponentVM, "equipmentInfo.make.value").trim() === "";
        const modelEmpty = !_.get(watercraftComponentVM, "equipmentInfo.model.value")
                         || _.get(watercraftComponentVM, "equipmentInfo.model.value").trim() === "";
        const descriptionEmpty = !_.get(watercraftComponentVM, "equipmentInfo.description.value") 
                               || _.get(watercraftComponentVM, "equipmentInfo.description.value").trim() === "";

        return isRPC1554Effective
        && serialNumberEmpty
        && makeEmpty
        && modelEmpty
        && descriptionEmpty;
    }, [baseData, watercraftComponentVM]);


    const overrideProps = {
        '@field': {
            readOnly,
            parentNode: watercraftComponentVM
        },
        warningOneOfMakeModelSerialNumberDescriptionRequiredToBind: {
            visible: shouldShowAtLeastOneRequiredToBindMessage()
        },
        warningOneOfMakeModelSerialNumberDescriptionRequiredToBindMessage: {
            content: WMICRichTextUtil.translateRichText(translator(messages.oneOfMakeModelSerialNumberDescriptionRequiredToBind)),
        }
    };

    return (
        <ViewModelForm
            model={watercraftComponentVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            onModelChange={updateWatercraftRiskView}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

WMICHOEquipmentDetails.propTypes = {
    equipment: PropTypes.shape({}).isRequired,
    updateWatercraftModel: PropTypes.func.isRequired,
};

export default WMICHOEquipmentDetails;
