import React, { useContext} from 'react';
import _ from 'lodash';
import { ToggleField } from '@jutro/components';
import htmlParser from 'html-react-parser';
import { TranslatorContext } from '@jutro/locale';

import { HOConstants } from 'wmic-pe-portals-utils-js';

export const isDefined = (value) => {
    return typeof value !== 'undefined';
}
// FUNCTIONS TO RENDER DATA LIST CELLS

export const renderPrimaryCell = (item, path) => {
    const isPrimary = _.get(item, `${path}.value`, '');
    const translator = useContext(TranslatorContext);

    return (
        <ToggleField
            readOnly
            availableValues={[
                {
                    code: true,
                    name: translator({
                        'id': 'platform.inputs.Yes',
                        'defaultMessage': 'Yes'
                    }),
                },
                {
                    code: false,
                    name: translator({
                        'id': 'platform.inputs.No',
                        'defaultMessage': 'No'
                    }),
                }
            ]}
            value={isPrimary}/>
    )
}

export const isResidenceTypeLaneway = (riskView) => 
    _.get(riskView, 'yourHome.residenceType.value.code') === HOConstants.residenceTypeLaneway;

export const getLanewayLocationDisplayName = (riskView) => {
    const addressLine2 = _.get(riskView, 'yourHome.locationAddress.addressLine2.value');
    const addressDisplayName = _.get(riskView, 'yourHome.locationAddress.displayName.value');
    if (addressLine2) {
        const [locationNum, addressDescription] = addressDisplayName.split(': ')
        return `${locationNum}: ${addressLine2}-${addressDescription}`;
    }
    return addressDisplayName
}

export const renderDwellingAddressCell = (riskView, translator) => {
    if (isResidenceTypeLaneway(riskView)) {
        const lanewayMessage = translator({
            id: 'quoteandbind.ho.views.ho-your-home.Laneway',
            defaultMessage: 'meta.Laneway'
        });
        return (
            <>
                <div>
                    <span>{htmlParser(lanewayMessage)}</span>
                </div>
                <span>{getLanewayLocationDisplayName(riskView)}</span>
            </>
        );
    }
    return <span>{riskView.yourHome.locationAddress.displayName.value}</span>;
}

export const renderDwellingRiskNumberCell = (item, path, submissionVM) => {
    const riskNumber = _.get(item, `${path}.value`, '');
    const showWarning = _.get(submissionVM, 'baseData.isFromBMS_WMIC.value')
        && submissionVM.lobData.homeowners.coverables.dwellings.findFirstElement(item.value).aspects.subtreeValid;
    return (
        <div>
            {showWarning && (
                <span className="ww-table-messaging">
                    <i className="fa fa-fw fa-exclamation-circle"/>
                </span>
            )}
            <span>{riskNumber}</span>
        </div>
    );
}

// HELPER FUNCTIONS
export const isEditMode = (riskView) => {
    return riskView && !(riskView.value.isUnderEditing === undefined) && riskView.value.isUnderEditing
}

export const presentDwellingRiskList = (submissionVM) => {
    return (submissionVM.lobData.homeowners.coverables.dwellings.length > 0);
}

export const presentWatercraftRiskList = (submissionVM) => {
    return (submissionVM.lobData.homeowners.coverables.watercrafts.length > 0);
}

export const isUpdateNotAllowed = (riskView, isReadOnlyUser) => {
    return riskView && (riskView.value.isUnderEditing || isReadOnlyUser);
}

export const isViewOnly = (riskView, isReadOnlyUser) => {
    return !isDefined(riskView) || !riskView.value.isUnderEditing || isReadOnlyUser;
}

export const isDwelling = (risk) => {
    return (risk.subtype === HOConstants.riskSubTypeDwelling);
}

export const isWatercraft = (risk) => {
    return (risk.subtype === HOConstants.riskSubTypeWatercraft);
}

// WIZARD PAGE FUNCTIONS

export const disableSaveButton = (risk, riskView) => {
    let disabled = false;
    if (isDwelling(risk)) {
        disabled = riskView.value.isAddingAdditionalInterest || riskView.value.isAddingLocation || riskView.value.isAddingPetOnPremise;
    } else {
        disabled = riskView.value.isAddingAdditionalInterest || riskView.value.isAddingLocation || riskView.value.isAddingBoatMotor;
    }
    return disabled;
};



export const showNextButton = (submissionVM, isReadOnlyUser) => {
    const savedRisks = [];
    const dwellings = submissionVM.lobData.homeowners.coverables.dwellings.value;
    const watercrafts = submissionVM.lobData.homeowners.coverables.watercrafts.value;

    if (isReadOnlyUser) {
        for (let i = 0; i < dwellings.length; i++) {
            if (isDefined(dwellings[i].publicID)) {
                savedRisks.push(dwellings[i].publicID);
            }
        }

        for (let i = 0; i < watercrafts.length; i++) {
            if (isDefined(watercrafts[i].publicID)) {
                savedRisks.push(watercrafts[i].publicID);
            }
        }

        if (savedRisks.length > 0) {
            const firstDwelling = dwellings[0];
            if (firstDwelling.construction
                && firstDwelling.construction.dwellingWings
                && firstDwelling.construction.dwellingWings.length > 0) {
                return isDefined(dwellings[0].construction.dwellingWings[0].constructionDetail)
                    && dwellings[0].construction.dwellingWings[0].constructionDetail != null;
            }
            return false;
        }
        return false;
    }
    return true;
};

export const disableNextButton = (submissionVM, dwellingRiskView, watercraftRiskView) => {
    return submissionVM.lobData.homeowners.coverables.dwellings.length === 0
        || _.get(dwellingRiskView, 'isUnderEditing', false)
        || _.get(watercraftRiskView, 'isUnderEditing', false);
}
