import React, { useContext } from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { JURISDICTIONS } from 'wmic-pe-portals-utils-js';
import { TranslatorContext } from '@jutro/locale';
import {
    WMICPolicyPaDriverPersonalInfoComponent,
    WMICPolicyPaLicenseInfoComponent,
    WMICPolicyPaMVRComponent,
    WMICPolicyPaDriverPolicyHistoryComponent,
    WMICPolicyPaCommercialDriverComponent
} from 'wmic-pe-capability-policy-common-react';
import { WMICDriveChangeTelematicsComponent } from 'wmic-pe-capability-gateway-common-pa-react';

import metadata from './WMICPolicyPaDriversDetailView.metadata.json5'
import messages from './WMICPolicyPaDriversDetailView.messages';

function WMICPolicyPaDriversDetailView(props) {
    const { value : selectedDriver } = props;
    const { wizardData : policyVM } = useContext(WizardContext);
    const translator = useContext(TranslatorContext);
    const baseState = _.get(policyVM, 'lobs.personalAuto.baseState.value.code');

    const getMVRTitle = () => {
        return baseState === JURISDICTIONS.QUEBEC ? translator(messages.mvrQCTabHeading) : translator(messages.mvrTabHeading)
    }

    const commonAccordionContentProps = {
        driverVM: selectedDriver,
        showErrors: false,
        policyVM,
        baseState,
        isReadOnlyUser: true
    };

    const overrideProps = {
        '@field': {
            parentNode: policyVM,
            showRequired: true
        },
        personalInfoAccordionComponent: {
            ...commonAccordionContentProps,
        },
        licenseInfoAccordionComponent: {
            ...commonAccordionContentProps,
        },
        mvrAccordion: {
            title: getMVRTitle()
        },
        mvrAccordionComponent: {
            ...commonAccordionContentProps
        },
        policyHistoryAccordionComponent: {
            ...commonAccordionContentProps
        },
        commercialDriverAccordionComponent: {
            ...commonAccordionContentProps,
            commercialDriverVM: selectedDriver.commercialDriver_WMIC
        },
        driveChangeTelematicsAccordion: {
            ...commonAccordionContentProps,
            visible: _.get(policyVM, 'policyVM.isDriveChangeAvailable_Ext.value', false)
        },
        driveChangeTelematicsAccordionComponent: {
            ...commonAccordionContentProps,
            hideButton: true
        },
    }

    const resolvers = {
        resolveComponentMap: {
            WMICPolicyPaDriverPersonalInfoComponent,
            WMICPolicyPaLicenseInfoComponent,
            WMICPolicyPaMVRComponent,
            WMICPolicyPaDriverPolicyHistoryComponent,
            WMICPolicyPaCommercialDriverComponent,
            WMICDriveChangeTelematicsComponent
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={policyVM}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default WMICPolicyPaDriversDetailView;
