import React, { useContext, useMemo } from 'react';
import _ from 'lodash';
import { WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import WMICPACoverageUtil from "wmic-pe-capability-gateway-common-pa-react/util/WMICPACoverageUtil";
import { ClausesComponentVM, WMICHeading } from 'wmic-pe-components-platform-react';

import messages from './WMICPAPolicyLevelCoverages.messages'
import metadata from './WMICPAPolicyLevelCoverages.metadata.json5';

function WMICPAPolicyLevelCoverages(props) {
    const { value : selectedVehicle } = props;
    const { wizardData: { policyVM } } = useContext(WizardContext);
    const translator = useContext(TranslatorContext);

    const lineCoverages = _.get(policyVM, `lobs.personalAuto.coverageDTOs.lineCoverages.value`);

    const getPolicyCoverageSections = () => {
        const categoryMap = ['policyLevelCoverages', 'motorcycleCoverages', 'commercialCoverages', 'optionalAccidentBenefitsCoverages'];
        const categorizedCoverages = WMICPACoverageUtil.categorizeLineCoverages(lineCoverages, translator);

        return categoryMap.map((categoryKey) => {
            const lineCoverage = _.get(categorizedCoverages, categoryKey);

            return (
                <>
                    {lineCoverage.coverages.length > 0
                    && lineCoverage.categoryID !== "policyLevelCoverages" &&
                        <WMICHeading
                            variant="h2"
                            underlined
                            bold
                            size="h2"
                            title={lineCoverage.categoryName} />}
                    <ClausesComponentVM
                        showAmount={false}
                        isEditable
                        readOnly
                        path={`${categoryKey}`}
                        value={lineCoverage.coverages}
                    />
                </>
            )
        });
    }

    const categorizeCoverages = useMemo(() => {
        const allCoverages = {
            policyLevelCoverages: [],
            ...selectedVehicle,
        };

        lineCoverages.forEach((coverage) => {
            if (coverage.category_WMIC.value === 'PALineAdditional_WMIC') {
                allCoverages.policyLevelCoverages.push(coverage.value);
            }
        });

        return allCoverages;
    }, [lineCoverages, selectedVehicle]);

    const overrideProps = {
        '@field': {
            readOnly: true,
        },
        policyLevelCoveragesContainer: {
            content: getPolicyCoverageSections()
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={categorizeCoverages}
            overrideProps={overrideProps}
        />
    );
}

export default WMICPAPolicyLevelCoverages;
