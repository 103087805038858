import React, {useState, useMemo, useContext, useCallback} from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import {WMICIcon} from "wmic-pe-components-platform-react";
import { TranslatorContext } from '@jutro/locale';
import _ from "lodash";
import { ReadOnlyCoverageTable } from 'wmic-pe-capability-quoteandbind-common-react'
import WMICHORiskUtil from "wmic-pe-capability-gateway-common-ho-react/utils/WMICHORiskUtil";
import WMICCoverageUtil from '../../Coverages/util/WMICCoverageUtil';
import metadata from './WMICIterableCommonComponent.metadata.json5';
import messages from './WMICIterableCommonComponent.messages';

import styles from './WMICIterableCommonComponent.module.scss';


function WMICIterableWatercraftComponent(props) {
    const { watercraft, watercraftCoverages, isPolicyChangeOrRenewal } = props;
    const translator = useContext(TranslatorContext);
    const [showCoveragesContainer, setShowCoveragesContainer] = useState( false);

    const totalWatercraftPremium = () => {
        const watercraftCoveragesTotal = _.get(watercraftCoverages, 'watercraftCoveragesTotal.value', 0);
        return WMICCoverageUtil.formatPremiumAmount(watercraftCoveragesTotal);
    }

    const renderToggle = useCallback(()=> {
        return <WMICIcon
            id = "expandIcon"
            icon = { showCoveragesContainer ? 'mi-expand-more' : 'mi-expand-less' }
            onClick = { () => setShowCoveragesContainer(!showCoveragesContainer) }
            size = "large" />
    },[showCoveragesContainer]);


    const overrides = {
        '@field': {
            parentNode: watercraft,
        },

        riskHeader: {
            VMList: [watercraft],
            VMData: [
                {
                    renderCell: renderToggle,
                    columnProportion: 0.5
                },
                {
                    headerText: translator(messages.riskNumber),
                    path: 'riskNumber.value',
                    columnProportion: 1
                },
                {
                    headerText: translator(messages.watercraftDescription),
                    renderCell: ()=> WMICHORiskUtil.getWaterCraftDescription(watercraft),
                    columnProportion: 3
                },
                {
                    headerText: translator(messages.riskType),
                    path: 'watercraftType.value.description',
                    columnProportion: 3
                },
                {
                    headerText: translator(messages.basicPremiumSubTotal),
                    renderCell: totalWatercraftPremium,
                    columnProportion: 1.5
                },
                {
                    headerText: translator(isPolicyChangeOrRenewal ? messages.proratedPremium : messages.totalPremium),
                    path: 'totalPremium',
                    renderCell: totalWatercraftPremium,
                    columnProportion: 1.5
                },
            ],
            readOnly: true
        },
        dwellingCoveragesTable: {
            visible: false
        },
        watercraftCoveragesCard: {
            visible: showCoveragesContainer,
        },
        watercraftCoveragesTable: {
            component: ReadOnlyCoverageTable,
            coverageList: watercraftCoverages.coverages.value
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            model={watercraft}
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICIterableWatercraftComponent.propTypes = {
    value: PropTypes.shape({}).isRequired
};

export default WMICIterableWatercraftComponent;
