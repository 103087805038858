import { useCallback, useEffect, useState } from 'react';
import { useDataFilter } from '@jutro/datatable';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import _ from 'lodash';
import constants from '../../constants';

export default function useAsyncFiltering(fetchData, filters, defaultConfig){

    const [data, setData] = useState([]);
    const [config, setConfig] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [totalDataCount, setTotalDataCount] = useState();
    const { authHeader } = useAuthentication();

    const initialConfig = {
        page: (defaultConfig && defaultConfig.page) ? defaultConfig.page  : 0,
        pageSize: (defaultConfig && defaultConfig.pageSize) ? defaultConfig.pageSize  : 20,
        producerCode: (defaultConfig && defaultConfig.producerCode) ? defaultConfig.producerCode  : constants.DEFAULT_PRODUCER_CODE,
    };

    // filters results on current page
    const [filteredData] = useDataFilter(data, filters, {});

    const handleConfigChange = useCallback((newConfig) => {
        setConfig(newConfig);
        const { page, pageSize, sorted, producerCode = constants.DEFAULT_PRODUCER_CODE, filters: configFilters } = newConfig;
        const queryParams = {
            producerCode,
            jobStatus: configFilters?.status || [],
            productCodes: configFilters?.productCode || [],
            orderByDescending: sorted ? sorted[0].desc : undefined
        };

        setIsLoading(true);

        fetchData(page, pageSize, queryParams, authHeader, configFilters).then(({rows, numberOfRows}) => {
            setTotalDataCount(numberOfRows);
            setData(rows);
            setIsLoading(false);

            if(newConfig.sorted) sortResults(newConfig, rows);
        })
    }, [authHeader, fetchData]);

    const sortResults = (newConfig, rows) => {
        const sortBy = newConfig.sorted[0];
        const rowsClone = _.cloneDeep(rows);

        if (sortBy.desc)
            rowsClone.sort((a, b) => b[sortBy.id].localeCompare(a[sortBy.id]))
        else
            rowsClone.sort((a, b) => a[sortBy.id].localeCompare(b[sortBy.id]))

        setData(rowsClone);
    }

    // load data on component mount
    useEffect(() => {
        if (!_.isEmpty(defaultConfig)) {
            handleConfigChange(initialConfig);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        isLoading,
        data: filteredData,
        config,
        handleConfigChange,
        totalDataCount: totalDataCount,
        numberOfRows: data.length,
    };
};
