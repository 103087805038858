import React, { useContext, useCallback, useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { WMICWizardChangeOrRenewalPage, useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { WMICRichTextUtil, LOBConstants, EMAIL_VALIDATION_STATUS, WMICAddressDetailsUtil, WMICUserAccessUtil, WMICLogger } from 'wmic-pe-portals-utils-js';
import { WMICAddressDetails } from 'wmic-pe-components-platform-react';
import { AccountService } from 'wmic-pe-capability-gateway-policy';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages} from 'wmic-pe-capability-gateway-policyrenewal-common-react';

import metadata from './WMICPolicyRenewalContactDetailsPage.metadata.json5';
import styles from './WMICPolicyRenewalContactDetailsPage.module.scss';
import messages from './WMICPolicyRenewalContactDetailsPage.messages';

const STATES_WITH_PREFERRED_LANGUAGE_SELECTION = ['NB', 'NS', 'PE', 'QC'];
const STATES_WITH_PREFERRED_LANGUAGE_SELECTION_EXCEPT_AUTO = ['BC', 'AB', 'SK', 'MB', 'ON', 'YT'];

function WMICPolicyRenewalContactDetailsPage(props) {
    const translator = useContext(TranslatorContext);
    const { wizardData: policyRenewalVM, updateWizardData, updateWizardSnapshot } = props;
    const {
        isComponentValid,
        registerInitialComponentValidation,
        onValidate,
        initialValidation
    } = useValidation('WMICPolicyRenewalContactDetailsPage');
    const { setWizardLoading } = useWizardModals();
    const { authUserData: currentUser, authHeader } = useAuthentication();
    const { RenewalService } = useDependencies('RenewalService');
    const [initialAddress, setInitialAddress] = useState();
    const [isAddressVisible, setAddressVisible] = useState(false);
    const emailAddress1Value = _.get(policyRenewalVM, 'baseData.primaryNamedInsured_WMIC.emailAddress1.value');
    const [originalEmailAddress, setOriginalEmailAddress] = useState(emailAddress1Value);
    const isPA = _.get(policyRenewalVM, 'lob.value.code') === LOBConstants.PA_LINE;
    const [showErrors, setShowErrors] = useState(false)
    const EMAIL_VALIDATION_PATH_VALUE = 'baseData.emailValidationStatus_WMIC.value'

    const canEditPolicyRenewal = useMemo(
        () => WMICUserAccessUtil.canEditPolicyRenewal(currentUser.roles),
        [currentUser]
    );

    useEffect(() => {
        registerInitialComponentValidation(() => !_.isEmpty(emailAddress1Value) && (_.get(policyRenewalVM, 'baseData.periodStatus.value.code') === 'Quoted'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (originalEmailAddress !== emailAddress1Value) {
            _.set(policyRenewalVM, EMAIL_VALIDATION_PATH_VALUE, EMAIL_VALIDATION_STATUS.DRAFT)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailAddress1Value, originalEmailAddress])

    const toggleAddressVisible = useCallback((addressWillBeVisible) => {
        if (addressWillBeVisible) {
            const policyAddress = _.get(policyRenewalVM, 'baseData.policyAddress.value');
            if (!_.isNil(policyAddress)) {
                setInitialAddress(policyAddress);
            }
        } else if (!_.isNil(initialAddress)) {
            _.set(policyRenewalVM, "baseData.policyAddress.value", initialAddress);
        }
        setAddressVisible(addressWillBeVisible);

    }, [initialAddress, setAddressVisible, policyRenewalVM]);

    const areAddressFieldsValid = useCallback(() => {
        const addressVM = _.get(policyRenewalVM, 'baseData.policyAddress')

        if (!addressVM) {
            return true;
        }

        return addressVM.aspects && addressVM.aspects.valid && addressVM.aspects.subtreeValid;
    }, [policyRenewalVM]);

    const handleSaveAddress = useCallback(async () => {
        if (areAddressFieldsValid()) {
            setWizardLoading(true, translator(messages.updatingContactDetails));
            try {
                const policyAddress = _.get(policyRenewalVM, "baseData.policyAddress");
                policyAddress.displayName = WMICAddressDetailsUtil.formatAddress(policyAddress);
                _.set(policyRenewalVM, "baseData.policyAddress", policyAddress);

                await RenewalService.saveRenewal([policyRenewalVM.value], authHeader);
                onCancelContactDetails();
            } catch (err) {
                WMICLogger.error("Save address failed", err);
                throw new Error(err);
            } finally {
                setWizardLoading(false);
            }
        }
    }, [areAddressFieldsValid, setWizardLoading, translator, policyRenewalVM, RenewalService, authHeader, onCancelContactDetails]);

    const validateEmail = useCallback(() => {
        if (emailAddress1Value === _.get(policyRenewalVM, 'baseData.primaryNamedInsured_WMIC.confirmEmailAddress.value')) {
            AccountService.validateEmail(emailAddress1Value, _.get(policyRenewalVM, 'jobID.value'), authHeader)
                .then((res) => {
                    _.set(policyRenewalVM, EMAIL_VALIDATION_PATH_VALUE, res.emailValidationStatus);
                    setOriginalEmailAddress(emailAddress1Value);
                    if (isPA) {
                        const drivers = _.get(policyRenewalVM, 'lobData.personalAuto.coverables.drivers.value');
                        const primaryNamedDriverIndex = drivers.findIndex((driver) => driver.isPrimaryInsured_WMIC === true);
                        if (primaryNamedDriverIndex !== -1) {
                            _.set(policyRenewalVM, `lobData.personalAuto.coverables.drivers.value[${primaryNamedDriverIndex}].emailAddress1`, emailAddress1Value);
                            _.set(policyRenewalVM, `lobData.personalAuto.coverables.drivers.value[${primaryNamedDriverIndex}].confirmDriverEmailAddress_WMIC`, emailAddress1Value);
                        }
                    }

                }).catch(() => {
                    _.set(policyRenewalVM, EMAIL_VALIDATION_PATH_VALUE, EMAIL_VALIDATION_STATUS.ERROR);
                });
        }
    }, [emailAddress1Value, policyRenewalVM, authHeader]);

    const getValidationStatus = useCallback(() => {
        // eslint-disable-next-line default-case
        switch (_.get(policyRenewalVM, `${EMAIL_VALIDATION_PATH_VALUE}.code`)) {
            case EMAIL_VALIDATION_STATUS.VALID:
                return messages.emailValid;
            case EMAIL_VALIDATION_STATUS.INVALID_TLS:
                return messages.invalidtls;
            case EMAIL_VALIDATION_STATUS.ERROR:
                return messages.unavailable;
            case EMAIL_VALIDATION_STATUS.INVALID:
                return messages.invalid;
        }
    }, [_.get(policyRenewalVM, `${EMAIL_VALIDATION_PATH_VALUE}.code`)])

    const onEditContactDetails = () => {
        _.set(policyRenewalVM, 'isEditingPolicyChangeContactDetails.value', true)
        setShowErrors(false)
        updateWizardData(policyRenewalVM)
        toggleAddressVisible(!isAddressVisible)
    }

    const onCancelContactDetails = useCallback(() => {
        _.set(policyRenewalVM, 'isEditingPolicyChangeContactDetails.value', false)
        updateWizardSnapshot(policyRenewalVM)
        updateWizardData(policyRenewalVM)
        toggleAddressVisible(false)
    }, [policyRenewalVM, toggleAddressVisible, updateWizardData, updateWizardSnapshot])

    const polDocPrefLangVisible = STATES_WITH_PREFERRED_LANGUAGE_SELECTION.includes(_.get(policyRenewalVM, 'baseData.baseState.value.code')) ||
        (!isPA && STATES_WITH_PREFERRED_LANGUAGE_SELECTION_EXCEPT_AUTO.includes(_.get(policyRenewalVM, 'baseData.baseState.value.code')));

    const overrideProps = {
        '@field': {
            parentNode: policyRenewalVM,
            showRequired: true,
            readOnly: !canEditPolicyRenewal,
        },
        // eslint-disable-next-line camelcase
        edeliveryEnrolled_WMIC: {
            visible: _.get(policyRenewalVM, 'baseData.edeliveryEnrolled_WMIC.aspects.ocular')
        },
        // eslint-disable-next-line camelcase
        polDocPrefLang_WMIC: {
            visible: polDocPrefLangVisible
        },
        epoiContainer: {
            visible: isPA
        },
        statusIcon: {
            icon: _.get(policyRenewalVM, 'baseData.isBrokerOwned_WMIC.value', false) ? "gw-info" : "mi-warning"
        },
        statusMessageBody: {
            content: _.get(policyRenewalVM, 'baseData.isBrokerOwned_WMIC.value', false) ? translator(messages.brokerDistributed) : WMICRichTextUtil.translateRichText(translator(messages.consent))
        },
        deliveryOfDocumentsContainer : {
            visible: _.get(policyRenewalVM, 'baseData.edeliveryEnrolled_WMIC.aspects.ocular')
        },
        addressContainer: {
            visible: isAddressVisible
        },
        editItalicize: {
            onClick: onEditContactDetails
        },
        policyDetailsPageAddressComponent: {
            addressVM: _.get(policyRenewalVM, 'baseData.policyAddress', {}),
            onValidate,
            showErrors,
            hideBorder: true,
            hideButtons: true,
            readOnlyCountry: true
        },
        addressEditForm: {
            onSave: handleSaveAddress,
            onCancel: onCancelContactDetails,
        },
        accountEmailContainer: {
            visible: _.get(policyRenewalVM, 'baseData.edeliveryEnrolled_WMIC.value', false) || _.get(policyRenewalVM, 'lobData.personalAuto.epoiEnrolled_WMIC.value', false)
        },
        confirmEmail: {
            visible: originalEmailAddress !== emailAddress1Value
        },
        emailValidationIcon: {
            visible: _.get(policyRenewalVM, `${EMAIL_VALIDATION_PATH_VALUE}.code`) === EMAIL_VALIDATION_STATUS.VALID
        },
        emailValidationStatus: {
            visible: !_.isNil(getValidationStatus()),
            content: translator(getValidationStatus()),
            className: cx(styles.gwAlert, {
                [styles.error]: _.get(policyRenewalVM, `${EMAIL_VALIDATION_PATH_VALUE}.code`) !== EMAIL_VALIDATION_STATUS.VALID
            })

        },
        editAddress: {
            visible: canEditPolicyRenewal,
        },
        verifyEmailButton: {
            visible: canEditPolicyRenewal,
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            WMICAddressDetails
        },
        resolveCallbackMap: {
            verifyEmail: validateEmail
        }
    };

    const onNext = useCallback(async () => {
        if(!isComponentValid){
            setShowErrors(true);
            return false;
        }

        try {
            setWizardLoading(true, translator(commonMessages.savingTransactionDetails));
            const newPolicyRenewalVM = _.cloneDeep(policyRenewalVM.value);

            policyRenewalVM.value = await RenewalService.saveRenewal(
                [newPolicyRenewalVM],
                authHeader
            );
            return policyRenewalVM;
        } finally {
            setWizardLoading(false);
        }
    }, [RenewalService, authHeader, isComponentValid, policyRenewalVM, setWizardLoading, translator]);

    return (
        <WMICWizardChangeOrRenewalPage
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
            isSkipping={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyRenewalVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                showErrors={showErrors}
            />
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICPolicyRenewalContactDetailsPage.propTypes = wizardProps;
export default WMICPolicyRenewalContactDetailsPage;
