import { defineMessages } from 'react-intl';

export default defineMessages({
    mvrTabHeading: {
        id: 'policy.pa.views.pa-drivers-details.mvr',
        defaultMessage: 'MVR'
    },
    mvrQCTabHeading: {
        id: 'policy.pa.views.pa-drivers-details.violations-suspensions',
        defaultMessage: 'Violations / Suspensions'
    }
});
