import React, { useContext } from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import WMICCPCoverageUtil from "wmic-pe-capability-gateway-quoteandbind-cp-react/util/WMICCPCoverageUtil";
import { WMICCPBuildingCoverages } from "wmic-pe-capability-gateway-quoteandbind-cp-react";

import messages from './WMICCPCoverageBuildings.messages';
import metadata from './WMICCPCoverageBuildings.metadata.json5';

const BUILDING_PATH = 'buildings';

function WMICCPCoverageBuildings(props) {
    const {
        locationIndex,
        locationVM,
        changeSubmissionAndSync,
        changeSubmission,
        syncCoverages,
        recalculate,
        resetQuote,
        onStaleQuoteBranchCode,
        onClauseChange,
        underwritingIssues,
        buyNow,
        quoteID,
        onValidate,
        jobVM,
        isBoundPolicy,
        readOnly
    } = props;

    const getCustomTerms = (clause) => {
        return null;
    }

    const commonOverrides = {
        quoteID,
        changeSubmissionAndSync,
        changeSubmission,
        onClauseChange,
        syncCoverages,
        recalculate,
        resetQuote,
        onStaleQuoteBranchCode,
        underwritingIssues,
        onValidate,
        getCustomTerms,
        jobVM,
        isBoundPolicy,
        readOnly
    }

    const lobDataPath = isBoundPolicy ? 'lobs' : 'lobData';
    const linePath = `${lobDataPath}.commercialProperty`;

    const _iterableProps = () => {
        const buildingOverrides = _.get(locationVM, `${BUILDING_PATH}.children`).map((building, index) => {
            const buildingCoverages = WMICCPCoverageUtil.getCoveragesForBuilding(
                _.get(jobVM, linePath),
                building.publicID.value
            );

            const buildingOverride = {
                [`coverageBuildingsIterableComponent${index}`]: {
                    'buildingVM': building,
                    'buildingIndex': locationIndex + index,
                    buildingCoverages,
                    locationVM,
                    ...commonOverrides
                }
            }

            return Object.assign({}, buildingOverride);
        })

        return Object.assign({}, ...buildingOverrides);
    }

    const overrideProps = {
        coverageBuildingsIterableAccordionContainer: {
            defaultOpenedId: `buildingAccordionCard${locationIndex}`,
            data: _.get(locationVM, `${BUILDING_PATH}.children`, [])
        },
        ..._iterableProps()
    };

    const resolvers = {
        resolveCallbackMap: {
            onBuyNow: buyNow,
            onStaleQuoteBranchCode,
            onRecalculate: recalculate,
            onResetQuote: resetQuote,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onChangeSubmission: changeSubmission,
            onSyncCoverages: syncCoverages,
        },
        resolveComponentMap: {
            WMICCPBuildingCoverages
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={locationVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default WMICCPCoverageBuildings;
