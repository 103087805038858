import { defineMessages } from 'react-intl';

export default defineMessages({
    vehicleType: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPVehicleExposures.vehicleType',
        defaultMessage: 'Vehicle Type'
    },
    vehicleExcessPolicy: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPVehicleExposures.vehicleExcessPolicy',
        defaultMessage: 'Excess Policy'
    },
    vehicleTypeDisplayHeading: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPVehicleExposures.vehicleTypeDisplayHeading',
        defaultMessage: 'Type'
    },
    wmicUnderlyingPoliciesRemoveVehicleExposureConfirmationMsg: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPVehicleExposures.RemoveVehicleExposureConfirmationMsg',
        defaultMessage: 'Are you sure you want to remove vehicle {vehicleNumber} from the list of vehicles?'
    },
    wmicUnderlyingPoliciesRemoveVehicleExposure: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPVehicleExposures.RemoveVehicleExposure',
        defaultMessage: 'Remove Vehicle?'
    },
    addVehicleButtonText: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPVehicleExposures.addVehicleButtonText',
        defaultMessage: 'Add Vehicle'
    },
    noExcessPolicyDisplayText: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPVehicleExposures.noExcessPolicyDisplayText',
        defaultMessage: 'None'
    },
    vehicleExposureTitle: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPVehicleExposures.VehicleDetails',
        defaultMessage: 'Vehicles'
    }

})