import { defineMessages } from 'react-intl';

export default defineMessages({
    number: {
        id: 'policy.cp.views.LocationsBuildings.Number',
        defaultMessage: '#',
    },
    name: {
        id: 'policy.cp.views.LocationsBuildings.Name',
        defaultMessage: 'Name',
    },
    address: {
        id: 'policy.cp.views.LocationsBuildings.Address',
        defaultMessage: 'Address',
    },
    primaryLocation: {
        id: 'policy.cp.views.LocationsBuildings.Primary Location',
        defaultMessage: 'Primary Location',
    },
    locationNumber: {
        id: 'policy.cp.views.LocationsBuildings.Location Number',
        defaultMessage: 'Location #',
    },
    interestType: {
        id: 'policy.cp.views.LocationsBuildings.InterestType',
        defaultMessage: 'Interest type',
    },
    buildingNumber: {
        id: 'policy.cp.views.LocationsBuildings.Building Number',
        defaultMessage: 'Building #{displayName}'
    },
});
