import { WMICLogger, JobType } from 'wmic-pe-portals-utils-js';
import { LoadSaveService } from 'wmic-pe-capability-gateway-quoteandbind';
import { GatewayEndorsementService } from 'wmic-pe-capability-gateway-policychange';
import { GatewayRenewalService } from 'wmic-pe-capability-gateway-policyrenewal';
import _ from "lodash";

const useFlexAndBrokerRecalculate = (flexOrBrokerModalVM) => {
    const jobType = _.get(flexOrBrokerModalVM, 'baseData.jobType.value.code');
    let saveAndRecalculate;
    let quoteID;

    switch (jobType) {
        case JobType.SUBMISSION:
            saveAndRecalculate = LoadSaveService.saveAndRecalculateFlexForSubmission;
            quoteID = _.get(flexOrBrokerModalVM, 'quoteID.value');
            break;
        case JobType.POLICY_CHANGE:
            saveAndRecalculate = GatewayEndorsementService.saveAndRecalculateFlexForPolicyChange;
            quoteID = _.get(flexOrBrokerModalVM, 'jobID.value');
            break;
        case JobType.RENEWAL:
            saveAndRecalculate = GatewayRenewalService.saveAndRecalculateFlexForRenewal;
            quoteID = _.get(flexOrBrokerModalVM, 'jobID.value');
            break;
        default:
            break;
    }

    if (!saveAndRecalculate || !quoteID) {
        WMICLogger.error('Error in Flex handleRecalc', 'No saveAndRecalculate or quoteID');
        return;
    }

    return {
        saveAndRecalculate,
        quoteID
    }
}

export default useFlexAndBrokerRecalculate;