import { defineMessages } from 'react-intl';

export default defineMessages({
    tabletAccordionTitle: {
        id: 'agent.views.policy-detail-summary.Policy Information Tablet',
        defaultMessage: 'Policy Information: {pageTitle}'
    },   
    policyInformation: {
        id: 'agent.views.policy-detail-summary.Policy Information',
        defaultMessage: 'Policy Information'
    }
});
