/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useContext } from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import _ from 'lodash';
import metadata from './WaterValvesDetails.metadata.json5';
import messages from './WaterValvesDetails.messages';

function WMICHOWaterValvesDetails(props) {
    const {
        id,
        riskView,
        updateDetails,
        showErrors,
        onValidate,
        isEditMode
    } = props;
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid])

    const overrideProps = {
        '@field': {            
            parentNode: riskView,
            readOnly: !isEditMode
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={riskView}
            overrideProps={overrideProps}
            onModelChange={updateDetails}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

WMICHOWaterValvesDetails.propTypes = {
};

export default WMICHOWaterValvesDetails;