/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React, { useContext, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { WMICRPCUtil , HOConstants } from 'wmic-pe-portals-utils-js';
import styles from './WMICHOPolicyDwellingPropertyDetails.module.scss';
import messages from './WMICHOPolicyDwellingPropertyDetails.messages';
import metadata from './WMICHOPolicyDwellingPropertyDetails.metadata.json5';

const RPC_NUMBER = '1411';
function WMICHOPolicyDwellingPropertyDetails(props) {
    const {
        riskView
    } = props;

    const overrideProps = {
        '@field': {
            parentNode: riskView,
            readOnly: true
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={riskView}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

WMICHOPolicyDwellingPropertyDetails.propTypes = {
    riskView: PropTypes.any.isRequired
};

export default WMICHOPolicyDwellingPropertyDetails;
