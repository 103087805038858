import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames'
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { JURISDICTIONS, PAConstants, WMICRichTextUtil, WMICRPCUtil } from 'wmic-pe-portals-utils-js';
import { CheckboxField } from '@jutro/components';
import messages from './WMICPolicyDriverEndorsementCheckbox.messages';

function WMICDriverEndorsementsCheckbox(props) {
    const {
        id,
        driverEndorsement,
        driver,
        selectedVehicleId,
        baseState,
        rateAsOfDate,
        paLobDataVM
    } = props;
    const translator = useContext(TranslatorContext);

    const checked = useMemo(() => {
        if (driverEndorsement.publicID === PAConstants.accidentWaiverCovPublicId && 
            _.find(_.get(paLobDataVM, 'endorsements_Ext.accidentWaiverEndorsements_WMIC.value'), {driverPublicID: driver.publicID})) {
            return true;
        }
        
        if (driverEndorsement.publicID === PAConstants.accidentWaiverMtcCovPublicId && 
            _.find(_.get(paLobDataVM, 'endorsements_Ext.accidentWaiverMtcEndorsements_WMIC.value'), {driverPublicID: driver.publicID})) {
            return true;
        }
        
        if (driverEndorsement.publicID === PAConstants.minorConvictionWaiver39bPublicId && 
            _.find(_.get(paLobDataVM, 'endorsements_Ext.minorConvictionWaiverEndorsements_WMIC.value'), {driverPublicID: driver.publicID})) {
            return true;
        }
        
        if (driverEndorsement.publicID === PAConstants.reducAmtForNamedCov_28A_PublicId &&
            _.find(_.get(paLobDataVM, 'endorsements_Ext.excludedDriverEndorsements_WMIC.value'), {driverPublicID: driver.publicID, vehiclePublicID: selectedVehicleId})) {
            return true;        
        }
        
        if (driverEndorsement.publicID === PAConstants.reducAmtForNamedCov_28_PublicId &&
            _.find(_.get(paLobDataVM, 'endorsements_Ext.reductionDriverEndorsements_WMIC.value'), {driverPublicID: driver.publicID, vehiclePublicID: selectedVehicleId})) {
            return true;        
        }
        
        if (driverEndorsement.publicID === PAConstants.article6ExcludedDriver_WMIC_PublicId &&
            _.find(_.get(paLobDataVM, 'endorsements_Ext.accidentWaiverMtcEndorsements_WMIC.value'), {driverPublicID: driver.publicID, vehiclePublicID: selectedVehicleId})) {
            return true;        
        }
        
        return false;
    }, [driver.publicID, driverEndorsement.publicID, paLobDataVM, selectedVehicleId]);

    const getConvictionLabel = () => {
      if (baseState === JURISDICTIONS.ONTARIO) {
          return messages.minorConvictionWaiverON;
      }
      if (WMICRPCUtil.getIsRPCEffective(baseState, rateAsOfDate, '1582')) {
          return messages.minorConvictionWaiverMaritimes;
      } 
      return messages.minorConvictionWaiver;
  };

  const getEndorsementLabel = () => {
    const covId = driverEndorsement.publicID
        let label;
        switch (covId) {
            case PAConstants.accidentWaiverCovPublicId:
            case PAConstants.accidentWaiverMtcCovPublicId:
                label = messages.accidentRatingWaiver;
                break;
            case PAConstants.reducAmtForNamedCov_28_PublicId:
                label = messages.reductionOfCoverage;
                break;
            case PAConstants.reducAmtForNamedCov_28A_PublicId:
                label = messages.excluded;
                break;
            case PAConstants.article6ExcludedDriver_WMIC_PublicId:
                label = messages.article6Excluded;
                break;
            case PAConstants.minorConvictionWaiver39bPublicId:
                label = getConvictionLabel();
                break;
            default:
                return null;
        }

        return WMICRichTextUtil.translateRichText(translator(label, {name: driver.displayName}));
    };

    return (
        <div className="gw-pb-4">
            <CheckboxField
                id={`${id}_checkbox`}
                className={cx(
                    'gw-pl-5',
                    'checkBoxReadOnly',
                    'wmicMasterInputCheckbox'
                )}
                labelClassName={cx('wmicMasterInputFieldLabel')}
                label={getEndorsementLabel()}
                showInlineLabel
                readOnly
                value={checked}
                path={`${driverEndorsement.publicID}`}
            />
        </div>
    );
}

WMICDriverEndorsementsCheckbox.propTypes = {
    id: PropTypes.string.isRequired,
    driverEndorsement: PropTypes.shape({}).isRequired,
    driver: PropTypes.shape({}).isRequired,
    selectedVehicleId: PropTypes.string,
    baseState: PropTypes.string.isRequired,
    rateAsOfDate: PropTypes.shape({}).isRequired,
    paLobDataVM: PropTypes.shape({}).isRequired
};

export default WMICDriverEndorsementsCheckbox;
