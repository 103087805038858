import React from 'react';
import { WMICLogger } from 'wmic-pe-portals-utils-js';

import _ from 'lodash';

const validatePageEntities = (baseEntityVM, arrayOfPaths) => {
    const invalidNodes = [];

    for (const path of arrayOfPaths) {
        const vmNode = _.get(baseEntityVM, path);

        if (vmNode && vmNode.aspects) {
            if (!(vmNode.aspects.valid && vmNode.aspects.subtreeValid)) {
                invalidNodes.push(failingPropertiesReducer(vmNode, '', []))
            }
        }
    }

    if (invalidNodes.length > 0) {
        // This will get compiled out in production but is invaluable in dev
        WMICLogger.debug(`%c${invalidNodes} invalidNodes`);
    }

    return invalidNodes.length === 0;
}

const failingPropertiesReducer = (currentVmNode, pathFromRoot, errorPathsArray) => {
    if (!currentVmNode || currentVmNode.aspects.subtreeValid) {
        return errorPathsArray;
    }

    const { valueType } = currentVmNode._metadataInfo;

    if (valueType.isPrimitive || (valueType.typeInfo && valueType.typeInfo.metaType.isTypelist)) {
        if (!currentVmNode.aspects.valid) {
            errorPathsArray.push(pathFromRoot);
        }
    } else if (valueType.isCollection) {
        currentVmNode.children.forEach((vmChildNode, idx) => failingPropertiesReducer(vmChildNode, `${pathFromRoot}[${idx}]`, errorPathsArray));
    } else if (valueType.isClass || !currentVmNode._parent) {
        const props = currentVmNode._metadataInfo.valueType.typeInfo.properties;

        // eslint-disable-next-line consistent-return
        props.forEach((prop) => {
            const childProp = currentVmNode[prop.jsProperty];

            if (!childProp && prop) {
                errorPathsArray.push(pathFromRoot);
            } else {
                return failingPropertiesReducer(childProp, `${pathFromRoot}.${prop.jsProperty}`, errorPathsArray);
            }
        });
    }

    return errorPathsArray;
}

const getAllChildPaths = (children) => {
    let paths = [];

    React.Children.forEach(children, (child) => {
        try {
            if(React.isValidElement(child)) {
                if(child.props && child.props.path) {
                    paths.push(child.props.path);
                } else if (child.props && child.props.children) {
                    paths = paths.concat(getAllChildPaths(child.props.children))
                }
            }
        } catch (e) {
            // invalid react elements will throw an error, we dont necessarily care about the exception here
            // we just dont want the execution to stop, hence we do nothing
        }
    })

    return paths;
}

const checkChildNodesValid = (children, parentNode) => {
    const childPaths = getAllChildPaths(children);

    for (const path of childPaths) {
        const vmNode = _.get(parentNode, path);

        if (vmNode && vmNode.aspects) {
            if (!(vmNode.aspects.valid && vmNode.aspects.subtreeValid)) {
                return false;
            }
        }
    }

    return true;
}

const getChildNodesValid = (children, parentNode) => {
    const failures = [];
    const childPaths = getAllChildPaths(children);

    for (const path of childPaths) {
        const vmNode = _.get(parentNode, path);

        if (vmNode && vmNode.aspects) {
            if (!(vmNode.aspects.valid && vmNode.aspects.subtreeValid)) {
                failures.push(failingPropertiesReducer(vmNode, '', []))
            }
        }
    }

    return failures;
}

const checkValidity = (entityVM, checkValidityPaths) => {
    if (checkValidityPaths && checkValidityPaths.length > 0) {
        // data is valid if there are no invalid paths
        return checkValidityPaths.filter((path) => {
            const vm = _.get(entityVM, path);

            return !vm.aspects.subtreeValid || !vm.aspects.valid;
        }).length === 0;
    }

    return (entityVM && entityVM.aspects.subtreeValid && entityVM.aspects.valid);
}

const hasDtoValidationErrors = (entityVM, flowStepId) => Array.isArray(entityVM?.dtoValidations_Ext?.value)
    && entityVM.dtoValidations_Ext.value.some((error) => !flowStepId || error.flowStepIds.indexOf(flowStepId) !== -1);

const hasNoDtoValidationErrors = entityVM => !hasDtoValidationErrors(entityVM);

export default {
    checkChildNodesValid,
    getChildNodesValid,
    validatePageEntities,
    checkValidity,
    hasDtoValidationErrors,
    hasNoDtoValidationErrors
}
