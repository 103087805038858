import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Flex, FlexItem } from '@jutro/layout';
import { Icon } from '@jutro/components';
import { WMICIconButton, WMICButton } from 'wmic-pe-components-platform-react';
import { TranslatorContext } from '@jutro/locale';
import styles from './WMICDataListCardSummary.module.scss';
import messages from './WMICDataListCardSummary.messages';

function WMICDataListCardSummary({
    clickable,
    isEditing,
    updateSelectedCardIndex,
    row,
    rowIndex,
    onClickAction,
    VMList,
    VMData,
    dataListTitle,
    dataListSubtitle,
    readOnly,
    onEditAction,
    onRemoveAction,
    isEditDisabled,
    canDelete,
    canEdit,
    isDeleteDisabled,
    selectAction,
    selectButtonMessage,
    confirmable,
    confirmed,
    id }) {
    const translator = useContext(TranslatorContext);

    const getCellDataFunction = (rowObj, column, index) => {
        const data = column.getData(rowObj, column.path, index);
        // eslint-disable-next-line no-nested-ternary
        return (_.isObject(data) && !!data.id) ? translator(data) : _.has(data, 'id') ? null : data;
    };

    const getCellData = (rowObj, column) => {
        const property = _.get(rowObj, column.path, '');
        const isVM = !!(_.isObject(property) && property.aspects);
        const val = isVM ? property.value : property;
        if (column.isTypekey || (isVM && val?.code)) {
            return translator({
                id: val.name,
                defaultMessage: val.code,
            });
        }

        if (typeof val === 'boolean') {
            if (val) {
                return translator({
                    id: 'platform.inputs.Yes',
                    defaultMessage: 'Yes',
                });
            }
            return translator({
                id: 'platform.inputs.No',
                defaultMessage: 'No',
            });
        }

        return val;
    };

    const getTitleData = (item) => {
        const data = dataListTitle.getData(item);
        return data.id ? translator(data) : data;
    };

    const setSelectedCard = (index) => {
        if (clickable && !isEditing ) {
            updateSelectedCardIndex(index)
        }
    };

    return (
        <div
            onClick={() => {setSelectedCard(rowIndex); if (clickable && onClickAction) { onClickAction(VMList[rowIndex], rowIndex) }}}
            onKeyDown={() => setSelectedCard(rowIndex)}
            role="row"
            tabIndex={0}
        >
            {dataListTitle &&
                <div className={cx(styles.listViewItemContent, 'gw-mb-4')}>
                    <p>
                        <strong>
                            {dataListTitle.getData ? getTitleData(row) : _.get(row, dataListTitle.path)}
                        </strong>
                    </p>
                    {dataListSubtitle &&
                        <p>
                            {dataListSubtitle.getData ? getTitleData(row) : _.get(row, dataListSubtitle.path)}
                        </p>
                    }
                </div>
            }
            <Flex
                className={cx(styles.listViewItemContent)}
                gap="none"
                id={`${rowIndex}_FlexWrapper`}
            >
                {VMData.map((column, columnIndex) => {
                    const itemId = `col${columnIndex}_row${rowIndex}`;
                    return (
                        <FlexItem
                            key={itemId}
                            className={ cx( styles.listViewColumn, column.isVerticallyCentered ? styles.listViewColumnVerticallyCentered : undefined) }
                            visible={!column.visibilityCondition || column.visibilityCondition(row)}
                        >
                            <div className={styles.listViewItemHeaderContainer} role="columnheader">
                                <p className={styles.listViewItemHeader}>{column.headerText}</p>
                            </div>
                            <div className={styles.listViewItemCellContainer} role="cell">
                                <p>{column.getData ? getCellDataFunction(row, column, rowIndex) : getCellData(row, column)}</p>
                            </div>
                        </FlexItem>
                    );
                })}
                {(!readOnly || confirmable) && (
                    <FlexItem
                        alignSelf="center"
                        className={styles.listViewButtonColumn}
                    >
                        <Flex
                            gap="small"
                            justifyContent="right"
                        >
                            {confirmable && (
                                <FlexItem
                                    alignSelf="center"
                                    className={styles.confirmableContainer}
                                    data-bmsi-confirmed={confirmed}
                                >
                                    <div className={cx(styles.confirmContainer, confirmed ? styles.hiddenConfirm : undefined)}>
                                        <Icon icon='mi-error' className={styles.actionIcon} />
                                        {translator(messages.confirmDefault)}
                                    </div>
                                    <div className={cx(styles.reviewedContainer, confirmed ? undefined : styles.hiddenReviewed)}>
                                        <Icon icon='mi-check-circle' className={styles.actionIcon} />
                                        {translator(messages.reviewed)}
                                    </div>
                                </FlexItem>
                            )}
                            {!readOnly && onEditAction && (
                                (canEdit && !canEdit(rowIndex, VMList[rowIndex]))
                                    ? <></>
                                    :
                                    <WMICIconButton
                                        id={`${id}_editBtn`}
                                        icon="edit"
                                        onAction={(event) => {
                                            event.stopPropagation();
                                            updateSelectedCardIndex(rowIndex)
                                            onEditAction(VMList[rowIndex], rowIndex);
                                        }}
                                        disabled={isEditDisabled || isEditing}
                                        aria-label={translator(messages.editActionAriaLabel)}
                                    />
                            )}
                            {!readOnly && onRemoveAction && (
                                (canDelete && !canDelete(rowIndex, VMList[rowIndex]))
                                    ? <></>
                                    :
                                    <WMICIconButton
                                        id={`${id}_deleteBtn`}
                                        icon='delete'
                                        onAction={(event) => {
                                            event.stopPropagation();
                                            onRemoveAction(VMList[rowIndex], rowIndex)}
                                        }
                                        disabled={isDeleteDisabled || isEditing}
                                        aria-label={translator(messages.removeActionAriaLabel)}
                                    />
                            )}
                            {!readOnly && selectAction &&
                                <WMICButton
                                    onClick={() => {selectAction(VMList[rowIndex])}}
                                >
                                    {selectButtonMessage}
                                </WMICButton>
                            }
                        </Flex>
                    </FlexItem>
                )}
            </Flex>
        </div>
    );
};

WMICDataListCardSummary.propTypes = {
    clickable: PropTypes.bool,
    isEditing: PropTypes.bool,
    row: PropTypes.any.isRequired,
    rowIndex: PropTypes.number.isRequired,
    updateSelectedCardIndex: PropTypes.func.isRequired,
    onClickAction: PropTypes.func,
    VMList: PropTypes.array.isRequired,
    VMData: PropTypes.arrayOf(
        PropTypes.shape({
            headerText: PropTypes.string,
            path: PropTypes.string,
            getData: PropTypes.func,
            isTypekey: PropTypes.bool,
            visibilityCondition: PropTypes.func,
            isVerticallyCentered: PropTypes.bool
        })
    ).isRequired,
    dataListTitle: PropTypes.any,
    dataListSubtitle: PropTypes.any,
    readOnly: PropTypes.bool,
    onEditAction: PropTypes.func,
    onRemoveAction: PropTypes.func,
    isEditDisabled: PropTypes.bool,
    canDelete: PropTypes.func,
    canEdit: PropTypes.func,
    isDeleteDisabled: PropTypes.bool,
    selectAction: PropTypes.func,
    selectButtonMessage: PropTypes.string,
    confirmable: PropTypes.bool,
    confirmed: PropTypes.bool
}

WMICDataListCardSummary.defaultProps = {
    clickable: true,
    isEditing: false,
    readOnly: false,
    confirmable: false,
    confirmed: false
};

export default WMICDataListCardSummary;
