import React, {useEffect, useState } from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WMICAddressDetails } from 'wmic-pe-components-platform-react';
import { CONSTANTS } from 'wmic-pe-portals-utils-js';

import metadata from './WMICCPAdditionalInterestDetailView.metadata.json5';
import messages from './WMICCPAdditionalInterestDetailView.messages';

function WMICCPAdditionalInterestDetailView(props) {
    const { value: additionalInterestVM, isEditing, updateModel, showErrorsAddlInt, jobVM, updateWizardData, isBoundPolicy } = props;
    const [dropdownOptions, setDropdownOptions] = useState()
    const [addressReadOnlyMode, setAddressReadOnlyMode] = useState(false)
    const [insuredList, setInsuredList] = useState()
    const {
        onValidate,
    } = useValidation('WMICCPAdditionalInterestDetailView');

    const getCode = (option) => {
        let code;

        if (option.locationAddress) {
            code = option.locationAddress.publicID;
        } else if(option.address) {
            code = option.address.publicID;
        } else {
            code = option.primaryAddress.publicID;
        }

        return code;
    };

    useEffect(() => {
        let namedInsuredList = [];
        const LOB_DATA_PATH = isBoundPolicy ? 'lobs' : 'lobData';
        const TRADE_NAMES_PATH = `${LOB_DATA_PATH}.commercialProperty.tradeNames.value`;

        if (jobVM.baseData) {
            namedInsuredList = [...jobVM.baseData.additionalNamedInsureds_WMIC.value, jobVM.baseData.primaryNamedInsured_WMIC.value];
        } else {
            namedInsuredList = [...jobVM.latestPeriod.namedInsureds.value];
        }

        if (_.get(additionalInterestVM, 'subType.value.code') === CONSTANTS.POLICY_ADDL_INTEREST && _.get(additionalInterestVM, 'detailType.value.code') === CONSTANTS.CP_BUILDING_ADDL_INTEREST) {
            namedInsuredList.push(..._.get(jobVM, TRADE_NAMES_PATH));
        }
        setInsuredList(namedInsuredList)

        const options = namedInsuredList.map(
            (option) => ({
                name: {
                    id: option.displayName ?? (option.companyName ?? `${option.firstName} ${option.lastName}`),
                    defaultMessage: option.displayName ?? (option.companyName ?? `${option.firstName} ${option.lastName}`)
                },
                code: getCode(option)
            })
        );
        setDropdownOptions(options)

        if (_.get(additionalInterestVM, 'linkedAddressID.value') !== undefined) {
            setAddressReadOnlyMode(true)
        }
    }, [additionalInterestVM]);

    const onValueChangeSetAddress = (value, path) => {
        if (value !== undefined) {
            const insured = insuredList.find((ins) => ins.locationAddress != null ? ins.locationAddress.publicID === value : ins.primaryAddress.publicID === value)
            let address
            if (insured.locationAddress == null) {
                address = insured.primaryAddress
            } else {
                address = insured.locationAddress
            }
            _.set(additionalInterestVM, path, value)
            _.set(additionalInterestVM, 'locationAddress', address)
            setAddressReadOnlyMode(true)
            updateWizardData(jobVM)
        } else {
            const additionalInterestaddress = {
                country: CONSTANTS.COUNTRY.CA,
                city: '',
                province: '',
                postalCode: '',
            };
            _.set(additionalInterestVM, path, value)
            _.set(additionalInterestVM, 'locationAddress', additionalInterestaddress)
            setAddressReadOnlyMode(false)
            updateWizardData(jobVM)
        }
    }

    const overrideProps = {
        '@field': {
            parentNode: additionalInterestVM,
            readOnly: !isEditing
        },
        additionalInterestContainer: {
            onValidate,
        },
        personAddressLookup: {
            addressVM: _.get(additionalInterestVM, 'locationAddress'),
            hideButtons: true,
            hideBorder: true,
            readOnly: !_.get(additionalInterestVM, 'isEditable.value') || !isEditing,
            postalCodeRequired: true,
            addressFieldsReadOnly: addressReadOnlyMode,
            showErrors: showErrorsAddlInt,
        },
        additionalInterestContainerCompany: {
            visible: _.get(additionalInterestVM, 'contactType.value.code') === _.lowerCase(CONSTANTS.Company),
            showErrors: showErrorsAddlInt
        },
        additionalInterestContainerPerson: {
            visible: _.get(additionalInterestVM, 'contactType.value.code') === _.lowerCase(CONSTANTS.Person),
            showErrors: showErrorsAddlInt
        },
        relationshipDropdown: {
            availableValues: dropdownOptions,
            onValueChange: onValueChangeSetAddress,
            readOnly: !_.get(additionalInterestVM, 'isEditable.value') || !isEditing,
            visible: _.get(additionalInterestVM, 'isEditable.value')
        },
        company: {
            readOnly: !_.get(additionalInterestVM, 'isEditable.value') || !isEditing
        },
        firstName: {
            readOnly: !_.get(additionalInterestVM, 'isEditable.value') || !isEditing
        },
        lastName: {
            readOnly: !_.get(additionalInterestVM, 'isEditable.value') || !isEditing
        },
        address: {
            visible: !(_.get(additionalInterestVM, 'subType.value.code') === CONSTANTS.POLICY_ADDL_INSURED)
        },
    }

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            WMICAddressDetails
        }
    };

    return <ViewModelForm
        uiProps={metadata.componentContent}
        model={additionalInterestVM}
        onModelChange={updateModel}
        onValidationChange={onValidate}
        showErrors={showErrorsAddlInt}
        overrideProps={overrideProps}
        componentMap={resolvers.resolveComponentMap}
    />
}

export default WMICCPAdditionalInterestDetailView;
