import { defineMessages } from 'react-intl';

export default defineMessages({
    propertyDetailsAccordion: {
        "id": "wmic-pe-capability-gateway-common-ho-react.WMICHODwellingDetailView.Property Details",
        "defaultMessage": "Property Details"
    },
    additionalDetailsAccordion: {
        "id": "wmic-pe-capability-gateway-common-ho-react.WMICHODwellingDetailView.Additional Details",
        "defaultMessage": "Additional Details"
    },
    protectionDetailsAccordion: {
        "id": "wmic-pe-capability-gateway-common-ho-react.WMICHODwellingDetailView.Protection Details",
        "defaultMessage": "Protection Details"
    },
    dwellingAdditionalInterestsAccordion: {
        "id": "wmic-pe-capability-gateway-common-ho-react.WMICHODwellingDetailView.Additional Interests",
        "defaultMessage": "Additional Interests"
    }
});
