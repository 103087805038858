/* eslint-disable max-len */
import { HOConstants } from 'wmic-pe-portals-utils-js';
import _ from "lodash";

function isDwelling(riskSubtype) {
    let returnVal = false;

    if (typeof riskSubtype.value !== 'undefined') {
        returnVal = (riskSubtype.value === HOConstants.riskSubTypeDwelling);
    } else {
        returnVal = (riskSubtype === HOConstants.riskSubTypeDwelling);
    }

    return returnVal;
}

function isWatercraft(riskSubtype) {
    let returnVal = false;

    if (typeof riskSubtype.value !== 'undefined') {
        returnVal = (riskSubtype.value === HOConstants.riskSubTypeWatercraft);
    } else {
        returnVal = (riskSubtype === HOConstants.riskSubTypeWatercraft);
    }

    return returnVal;
}

function formatPreviousDwellings(dwellings, isSavingRisk) {
    const previousDwellings = dwellings.map((dwelling) => {
        return {
            publicID: dwelling.publicID,
            coverageType: dwelling.coverageType,
            doesCoverageTypeSetToBasicAndRatingMethodSetToStandard: dwelling.doesCoverageTypeSetToBasicAndRatingMethodSetToStandard
        };
    });
    previousDwellings.isSavingRisk = isSavingRisk;
    return previousDwellings;
}

function formatDwellingLocation(dwellingLocation) {
    const location = dwellingLocation.value ? dwellingLocation.value : dwellingLocation;
    const colonIdx = location.indexOf(':');

    return ![-1, location.length - 1].includes(colonIdx) ? location.substring(colonIdx + 2) : location;
}

function getWaterCraftDescription(watercraftVM) {
    const watercraft = watercraftVM.value;
    const components = watercraft.watercraftComponents;
    let info = {};
    let isAccessory = false;
    if (watercraft.watercraftType === "BoatEquipmentTrailer") {
        info = _.find(components, {subtype: "HOWatercraftBoat_WMIC"});
        if (!info) {
            info = _.find(components, {subtype: "HOWatercraftTrailer_WMIC"});
            if (!info) {
                info = _.find(components, {subtype: "HOWatercraftMotor_WMIC"});
                if (!info) {
                    info = _.find(components, {subtype: "HOWatercraftAccessory_WMIC"});
                    isAccessory = true;
                }
            }
        }
    } else if (watercraft.watercraftType === "OutboardMotor") {
        info = _.find(components, {subtype: "HOWatercraftMotor_WMIC"});
    } else if (watercraft.watercraftType === "PersonalWatercraft") {
        info = _.find(components, {subtype: "HOWatercraftBoat_WMIC"});
    }

    let displayValue = '';
    if (info) {
        if (isAccessory) {
            displayValue += _.truncate(info.description, {length: 20});
        } else if (info) {
            displayValue += _.isUndefined(info.equipmentInfo.year) ? '' : `${info.equipmentInfo.year} `;
            displayValue += _.isUndefined(info.equipmentInfo.make) ? '' : `${info.equipmentInfo.make} `;
            displayValue += _.isUndefined(info.equipmentInfo.model) ? '' : info.equipmentInfo.model;
        }
    }
    return displayValue;
}


export default {
    isDwelling,
    isWatercraft,
    formatPreviousDwellings,
    formatDwellingLocation,
    getWaterCraftDescription
};
