import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
    defaultAvailableValuePropType,
    dataTypeShape,
    DATA_TYPE_STRING,
    availableValueObjectShape,
} from '@jutro/prop-types';
import { FieldComponent } from '@jutro/components';
import { getOptionCode } from '@jutro/components/widgets/inputs/availableValues';
import { RadioButton } from './RadioButton';
import { messages } from './RadioButtonField.messages';
import {
    createHandleRadioButtonKeyDown,
    getTabIndexForRadioGroup,
} from '@jutro/components/radiobuttonUtils';
import styles from './RadioButton.module.scss';

// default available values if prop not passed to component
const defaultAvailableValues = [
    {
        code: 'true',
        name: messages.yes,
    },
    {
        code: 'false',
        name: messages.no,
    },
];

/**
 * Renders a list of radio buttons. You specify the items using component props.
 * @typedef {typeof RadioButtonField.propTypes} RadioButtonFieldPropTypes
 * @extends FieldComponent<PropTypes.InferProps<RadioButtonFieldPropTypes>>
 *
 * @metadataType field
 */
export class RadioButtonField extends FieldComponent {
    static propTypes = {
        ...FieldComponent.propTypes,
        /**
         * Boolean or string value
         */
        value: defaultAvailableValuePropType,
        /**
         * Default value
         */
        defaultValue: defaultAvailableValuePropType,
        /**
         * The format of the value
         */
        dataType: dataTypeShape,
        /**
         * Array of choice objects to display; choice objects contain 'code' and 'name'; if not provided, default 'Yes'/'No' will be used
         */
        availableValues: PropTypes.arrayOf(availableValueObjectShape),
        /**
         * If true, the component will be oriented horizontally
         */
        isHorizontal: PropTypes.bool,
    };

    // Default props
    static defaultProps = {
        ...FieldComponent.defaultProps,
        ...{
            availableValues: defaultAvailableValues,
            dataType: DATA_TYPE_STRING,
            isHorizontal: false,
        },
    };

    static contextType = FieldComponent.contextType;

    ref = createRef(null);

    // explicitly defined due to docgen limitations
    render() {
        return super.render();
    }

    /**
     * Render label for this component. Override FieldComponent renderLabel
     *
     * @returns {React.ReactElement} JSX for the label
     */
    renderLabel(props) {
        return super.renderLabel({
            ...props,
            labelContainerClassName: styles.fieldLabelContainer,
        });
    }

    /**
     * Render the radio buttons for the specified options
     *
     * @param {Array<any>} options - options to render
     * @param {object} props
     * @returns {React.ReactElement} JSX for the radio buttons
     */
    renderRadioOptions(options, props) {
        const { id, disabled, required } = props;

        const selectedValue = this.getDataTypeAwareSelectedValue();

        return options.map((option, index) => {
            const optionCode = getOptionCode(option);
            const { subtitle, secondaryLabel } = option;

            return (
                <RadioButton
                    secondaryLabel={subtitle || secondaryLabel}
                    key={optionCode}
                    option={option}
                    id={id}
                    tabIndex={getTabIndexForRadioGroup(
                        option,
                        selectedValue,
                        index
                    )}
                    value={selectedValue}
                    onValueChange={this.handleAvailableValuesValueChange}
                    disabled={disabled}
                    required={required}
                    {...this.generateDataPathProperty()}
                />
            );
        });
    }

    /**
     * generates accessibility properties for the field component
     * @returns {object} set of applicable wai-aria tags
     */
    generateAccessibilityProperties() {
        const { translator } = this;
        const { label } = this.props;

        return {
            ...super.generateAccessibilityProperties(),
            'aria-label': translator(label),
        };
    }

    /**
     * Render control for this component.
     *
     * @param {object} breakpointProps - breakpoint-specific props
     * @returns {React.ReactElement} JSX for the control
     */
    renderControl(breakpointProps) {
        const { id, availableValues, controlClassName, isHorizontal } =
            breakpointProps;
        const triggerFocus = (el, value) => {
            el.querySelector(`div[data-value=${value}]`).focus();
        };
        const handleRadioButtonKeyDown =
            createHandleRadioButtonKeyDown(triggerFocus);
        const options = this.renderRadioOptions(
            availableValues,
            breakpointProps
        );

        const classes = cx(
            {
                [styles.horizontal]: isHorizontal,
            },
            controlClassName
        );
        const { onFocus, onBlur } = this.props;
        const control = (
            // eslint-disable-next-line jsx-a11y/interactive-supports-focus
            <div
                ref={this.ref}
                id={id}
                className={classes}
                role="radiogroup"
                onFocusCapture={e =>
                    !this.ref.current?.contains(e.relatedTarget) &&
                    onFocus &&
                    onFocus(e)
                }
                onBlurCapture={e =>
                    !this.ref.current?.contains(e.relatedTarget) &&
                    onBlur &&
                    onBlur(e)
                }
                onKeyDown={event =>
                    handleRadioButtonKeyDown(
                        event,
                        breakpointProps,
                        this.handleAvailableValuesValueChange
                    )
                }
                {...this.generateAccessibilityProperties()}
            >
                {options}
            </div>
        );

        return control;
    }
}
