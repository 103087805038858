/* eslint-disable no-secrets/no-secrets */
import { defineMessages } from 'react-intl';

export default defineMessages({
    PersonalAutoTitle: {
        id: 'wmic.agent.views.new-quote-account-search.auto-title',
        defaultMessage: 'Automobile Submission'
    },
    HomeownersTitle: {
        id: 'wmic.agent.views.new-quote-account-search.home-title',
        defaultMessage: 'Personal Property Submission'
    },
    PersonalUmbrellaTitle: {
        id: 'wmic.agent.views.new-quote-account-search.umbrella-title',
        defaultMessage: 'Personal Umbrella Submission'
    },
    CommercialPackageTitle: {
        id: 'wmic.agent.views.new-quote-account-search.commercial-small_business-title',
        defaultMessage: 'Commercial Lines Small Business Submission'
    },
    changeAccountHolderError: {
        id: "agent.views.account-detail.changeAccountHolder.error",
        defaultMessages: "Enrollment in digital documents delivery is preventing this change. The Primary Named Insured and Account Holder must be the same."
    },
    autoMobileLink: {
        id: 'agent.views.account-detail.changeAccountHolde.Personal Auto',
        defaultMessage: 'Automobile',
    },
    personalPropertyLink: {
        id: 'agent.views.account-detail.changeAccountHolde.Home Owners',
        defaultMessage: 'Personal Property',
    },
    personalUmbrellaLink: {
        id: 'agent.views.account-detail.changeAccountHolde.PersonalUmbrella',
        defaultMessage: 'Personal Umbrella',
    },
    backButton: {
        id: 'agent.views.account-detail.changeAccountHolde.Back',
        defaultMessage: 'Back',
    },
    insuredName: {
        id: 'gateway.directives.PolicySummary.Name',
        defaultMessage: 'Name'
    },
    insuredAddress: {
        id: 'gateway.directives.PolicySummary.Address',
        defaultMessage: 'Address'
    },
    applyChangesWarningMessage: {
        id: 'agent.views.account-detail.Policy update alert',
        defaultMessage: 'Changes made will only apply to the Account. Any open transactions will need to be resubmitted to receive these changes.'
    },
    alerts: {
        id: 'wmic-pe-capability-gateway-react.WMICAccountSummary.Alerts',
        defaultMessage: "Alerts"
    },
    jurisdictionError: {
        id: 'wmic-pe-capability-gateway-react.WMICAccountSummary.jurisdictionError',
        defaultMessage: "{productCode} policies are not yet available in your jurisdiction."
    },
    ok: {
        id: 'wmic-pe-capability-gateway-react.WMICAccountSummary.Ok',
        defaultMessage: "Ok"
    },
});

