import { defineMessages } from 'react-intl';

export default defineMessages({
    itemDescription: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOBusinessPropertyCov.Description',
        defaultMessage: 'Description'
    },
    itemCoverageLimit: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOBusinessPropertyCov.Coverage Limit',
        defaultMessage: 'Limit'
    },
    addItemButton: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOBusinessPropertyCov.Add Item',
        defaultMessage: 'Add Item'
    },
});