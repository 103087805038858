import { defineMessages } from 'react-intl';

export default defineMessages({
    detailsAccordionTitle: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionDetailView.Details',
        defaultMessage: 'Details'
    },
    roofElectricalPlumbingUpdatesAccordionTitle: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionDetailView.Roof Electrical Plumbing Updates',
        defaultMessage: 'Roof/Electrical/Plumbing Updates'
    },
    waterUpdatesAccordionTitle: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionDetailView.Water Updates',
        defaultMessage: 'Water Updates'
    },
    heatingUpdatesAccordionTitle: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionDetailView.Heating Updates',
        defaultMessage: 'Heating Updates'
    },
    validationKitchenError: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionDetailView.KitchensAndBaths.Validation.Modal.Error.Header',
        defaultMessage: 'Please address the following errors for kitchens / bathrooms :',
    },
    kitchenValidationError: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionDetailView.KitchensAndBaths.Validation.Total Number of Kitchens',
        defaultMessage: 'Total number of kitchens must be between {min} and {max}.',
    },
    fullBathroomsExcludingBasementValidationError: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionDetailView.KitchensAndBaths.Validation.Total Number of Full Bathrooms Excluding Basement',
        defaultMessage: 'Total number of full bathrooms (excluding basement) must be between {min} and {max}.',
    },
    halfBathroomsExcludingBasementValidationError: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionDetailView.KitchensAndBaths.Validation.Total Number of Half Bathrooms Excluding Basement',
        defaultMessage: 'Total number of half bathrooms (excluding basement) must be between {min} and {max}.',
    },
    threeQuarterBathroomsExcludingBasementValidationError: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionDetailView.KitchensAndBaths.Validation.Total Number of Three Quarter Bathrooms Excluding Basement',
        defaultMessage: 'Total number of three-quarter bathrooms (excluding basement) must be between {min} and {max}.',
    },
    otherBathroomsExcludingBasementValidationError: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionDetailView.KitchensAndBaths.Validation.Total Number of Other Bathrooms Excluding Basement',
        defaultMessage: 'Total number of other bathrooms (excluding basement) must be between {min} and {max}.',
    },
    fullBasementBathroomsValidationError: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionDetailView.KitchensAndBaths.Validation.Total Number of Full Basement Bathrooms',
        defaultMessage: 'Total number of full bathrooms (basement) must be between {min} and {max}.',
    },
    halfBasementBathroomsValidationError: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionDetailView.KitchensAndBaths.Validation.Total Number of Half Basement Bathrooms',
        defaultMessage: 'Total number of half bathrooms (basement) must be between {min} and {max}.',
    },
    threeQuarterBasementBathroomsValidationError: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionDetailView.KitchensAndBaths.Validation.Total Number of Three Quarter Basement Bathrooms',
        defaultMessage: 'Total number of three-quarter bathrooms (basement) must be between {min} and {max}.',
    },
    otherBasementBathroomsValidationError: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionDetailView.KitchensAndBaths.Validation.Total Number of Other Basement Bathrooms',
        defaultMessage: 'Total number of other bathrooms (basement) must be between {min} and {max}.',
    }

});
