import { defineMessages } from 'react-intl';

export default defineMessages({
    wizard: {
        id: 'quoteandbind.gl.wizard.step.Liability',
        defaultMessage: 'Liability'
    },
    title: {
        id: 'quoteandbind.gl.views.genearlliability.title',
        defaultMessage: 'Commercial General Liability'
    },
    name: {
        id: 'quoteandbind.gl.views.genearlliability.name',
        defaultMessage: 'Name'
    },
    numberOfDaysNotice: {
        id: 'quoteandbind.gl.views.genearlliability.number of days notice',
        defaultMessage: 'Number of Days Notice'
    },
    removeAdditionalInsuredShort: {
        id: 'quoteandbind.gl.views.genearlliability.remove additional insured short',
        defaultMessage: 'Remove Additional Insured'
    },
    removeAdditionalInsuredLong: {
        id: 'quoteandbind.gl.views.genearlliability.remove additional insured long',
        defaultMessage: 'Are you sure you want to remove Additional Insured {displayName} from the list?'
    },
    addAdditionalInsured: {
        id: 'quoteandbind.gl.views.genearlliability.add additional insured',
        defaultMessage: 'Add Additional Insured'
    },
    additionalInsured: {
        id: 'quoteandbind.gl.views.genearlliability.additional insured heading',
        defaultMessage: 'Additional Insured (Optional)'
    },
    liabilityDetails: {
        id: 'quoteandbind.gl.views.genearlliability.liability details heading',
        defaultMessage: 'Liability Details'
    },
    aiPerson: {
        id: 'quoteandbind.gl.views.genearlliability.AdditionalInterest.Person',
        defaultMessage: 'Person'
    },
    aiCompany: {
        id: 'quoteandbind.gl.views.genearlliability.AdditionalInterest.Company',
        defaultMessage: 'Company'
    }
});
