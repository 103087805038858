/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useMemo } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { Flex } from '@jutro/layout';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { CONSTANTS, JobType } from 'wmic-pe-portals-utils-js';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WMICDropdownMenuButton, WMICListView } from 'wmic-pe-components-platform-react';
import AdditionalInterestDetailComponent from './WMICHOCovAdditionalInterestDetailComponent/WMICHOCovAdditionalInterestDetailComponent';
import messages from './WMICHOCovAdditionalInterests.messages';
import styles from './WMICHOCovAdditionalInterests.module.scss';

const CATEGORY = 'HODwellingAddlInt_HOE';

const getExistingAIDisplayName = (existingAI) => {
    if (existingAI.policyAdditionalInterest.subtype.value === CONSTANTS.Person
        && (existingAI.policyAdditionalInterest.firstName.value
        || existingAI.policyAdditionalInterest.lastName.value)) {
        return `${existingAI.policyAdditionalInterest.firstName.value} ${existingAI.policyAdditionalInterest.lastName.value}`;
    }
    if (existingAI.policyAdditionalInterest.subtype.value === CONSTANTS.Company
        && existingAI.policyAdditionalInterest.contactName.value) {
        return existingAI.policyAdditionalInterest.contactName.value;
    }
    return '';
}

function WMICHOCovAdditionalInterests(props) {
    const {
        id,
        dwelling,
        onDwellingChange,
        jobVM,
        onValidate,
        isEditMode,
        showErrors,
        readOnly,
        canEdit,
        saveJob
    } = props;

    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);
    const { showConfirm, setWizardLoading } = useWizardModals();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { EndorsementService } = useDependencies('EndorsementService');
    const { RenewalService } = useDependencies('RenewalService');
    const { authHeader } = useAuthentication();
    const jobType = _.get(jobVM, 'baseData.jobType.value.code');

    const minDate = useMemo(() => {
        const currentDate = new Date();
        return {
            day: currentDate.getDate() - CONSTANTS.BOT_PARAM_CONFIG.MAXIMUM_LEGAL_AGE,
            month: currentDate.getMonth(),
            year: currentDate.getFullYear()
        };
    }, []);

    const maxDate = useMemo(() => {
        const currentDate = new Date();
        return {
            day: currentDate.getDate() - CONSTANTS.BOT_PARAM_CONFIG.MINIMUM_LEGAL_AGE,
            month: currentDate.getMonth(),
            year: currentDate.getFullYear()
        };
    }, []);

    const createNewInsured = useCallback((subType) => {
        const accountAddress = Object.assign({}, _.get(jobVM, 'baseData.accountAddresses_WMIC.value[0]', {}));

        const contact = {
            subtype: subType,
            minDOB: minDate,
            maxDOB: maxDate,
            primaryAddress: accountAddress
        };
        return contact;
    }, [maxDate, minDate, jobVM]);

    const updateJobVM = useCallback(async () => {
        let service;

        switch (jobType) {
            case JobType.SUBMISSION: 
                service = LoadSaveService;
                break;
            case JobType.POLICY_CHANGE:
                service = EndorsementService;
                break;
            case JobType.RENEWAL:
                service = RenewalService;
                break;
            default:
                break;
        }

        const updatedVM = await service.postOnChange(
            jobVM.value,
            authHeader
        );

        _.extend(jobVM.value, updatedVM);
    }, [LoadSaveService, EndorsementService, RenewalService, authHeader, jobType, jobVM]);

    const toCreate = useCallback(async (subtype) => {
        const contact = createNewInsured(subtype);

        const additionalInterest = {
            additionalInterestCategory: CATEGORY,
            additionalInterestType: 'ADDINSURED_WMIC',
            policyAdditionalInterest: viewModelService.create(
                contact,
                'pc',
                'wmic.edge.ca.capabilities.policycommon.accountcontact.dto.AccountContactDTO'
            ).value,
            mailSeparateNotices: true,
            validateDescriptionOfInterest: true,
            validateAdditionalInterestType: true
        };

        const newVM = viewModelService.create(
            additionalInterest,
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.AdditionalInterestDTO_WMIC'
        );

        _.set(newVM, 'additionalInterestType.value', 'ADDINSURED_WMIC');

        dwelling.additionalInterests.value.push(newVM.value);

        await updateJobVM();

        return newVM;
    }, [createNewInsured, viewModelService, dwelling, updateJobVM]);

    const onSaveAI = useCallback(async () => {
        onDwellingChange(dwelling);
        setWizardLoading(true);
        const updatedVM = await saveJob();
        _.extend(jobVM.value, updatedVM);
        setWizardLoading(false);
        return dwelling;
    }, [dwelling, onDwellingChange, jobVM, saveJob, setWizardLoading]);

    const onDeleteAI = useCallback(async (item, index) => {
        const response = await showConfirm({
            title: translator(messages.removeAdditionalInterestShort),
            message: translator(messages.removeAdditionalInterestLong)
        })

        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            dwelling.additionalInterests.value.splice(index, 1);
            dwelling.value.isUnderEditing = true;
            await onSaveAI();
            return true;
        }
    }, [dwelling, showConfirm, translator, onSaveAI]);

    const getExistingAIDescriptionOfInterest = (existingAI) => {
        const descriptionOfInterestMajestyOther = _.get(existingAI, 'descriptionOfInterestMajestyOther.value');

        if (descriptionOfInterestMajestyOther) {
            return descriptionOfInterestMajestyOther;
        }

        const descriptionOfInterestMajestyOptionName = _.get(existingAI, 'descriptionOfInterestMajestyOption.value.name');

        return translator({
            id: descriptionOfInterestMajestyOptionName,
            defaultMessage: descriptionOfInterestMajestyOptionName
        });
    };

    const renderAddAIButton = useCallback(({ isEditing, onClick }) => (
        <Flex className="gw-mb-6" justifyContent="left">
            <WMICDropdownMenuButton
                id="addNewAdditionalInterestButton"
                type="secondary"
                size="medium"
                buttonText={messages.addAdditionalInterest}
                alignRight
                menuClassName={styles.newAIDropdownMenuButton}
                onItemClick={onClick}
                items={[
                    { id: 'newPerson', text: translator(messages.aiPerson), icon: 'mi-person', code: CONSTANTS.Person },
                    { id: 'newCompany', text: translator(messages.aiCompany), icon: 'mi-home', code: CONSTANTS.Company },
                ]}
                disabled={isEditing}
            />
        </Flex>
    ), [translator]);

    const canEditLineItem = useCallback((index) => canEdit && 
        (
            dwelling.additionalInterests.children[index]?.value?.additionalInterestType === CONSTANTS.ADDITIONAL_INTEREST_TYPES.ADDITIONAL_INSURED ||
            dwelling.additionalInterests.children[index]?.value?.additionalInterestType === CONSTANTS.ADDITIONAL_INTEREST_TYPES.CERT_HOLDER
        ),
    [canEdit, dwelling.additionalInterests])

    return (
        <WMICListView
            id={id}
            clickable
            startOpen={false}
            readOnly={!isEditMode || readOnly}
            editEnabled
            canEdit={(index) => canEditLineItem(index)}
            value={_.get(dwelling, `additionalInterests.children`, [])}
            VMData={[
                {
                    headerText: translator({id: "endorsement.ho.directives.templates.ho-additional-interests-edit.Name", defaultMessage: "Name"}),
                    getData: getExistingAIDisplayName
                },
                {
                    headerText: translator({id: 'quoteandbind.ho.views.ho-your-home.Description of Interest', defaultMessage: 'Description of Interest'}),
                    getData: getExistingAIDescriptionOfInterest
                }
            ]}
            detailViewComponent={AdditionalInterestDetailComponent}
            detailViewComponentProps={{
                category: CATEGORY,
                jobVM
            }}
            renderAddButton={renderAddAIButton}
            onValidate={onValidate}
            toCreate={toCreate}
            toUndoCreate={async () => {
                const additionalInterests = _.get(dwelling.value, 'additionalInterests');
                additionalInterests.splice(additionalInterests.length-1, 1);
                _.set(dwelling.value, 'additionalInterests', additionalInterests);
                onDwellingChange(dwelling);
                await updateJobVM();
            }}
            onDelete={onDeleteAI}
            onSave={onSaveAI}
            showErrors={showErrors}
        />
    );
}

WMICHOCovAdditionalInterests.propTypes = {
    id: PropTypes.string.isRequired,
    onDwellingChange: PropTypes.func,
    jobVM: PropTypes.shape({}).isRequired,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    dwelling: PropTypes.shape({}),
    isEditMode: PropTypes.bool,
    canEdit: PropTypes.func
};

WMICHOCovAdditionalInterests.defaultProps = {
    onValidate: undefined,
    dwelling: {},
    onDwellingChange: undefined,
    isEditMode: false,
    showErrors: false,
    canEdit: () => true
};

export default WMICHOCovAdditionalInterests;
