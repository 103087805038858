import { WMICMathUtil } from 'wmic-pe-portals-utils-js';
import _ from 'lodash';
import  messages from './WMICCovTermUtil.messages';
import PAConstants from "wmic-pe-portals-utils-js/constants/WMICPAConstants";

const limitSuffixes = ['Lmt','Limit','LmtOpt'];
const deductibleSuffixes = ['Ded', 'Ded_CA', 'Deductible'];
const adjustmentSuffixes = ['LmtAdjustment','AddlLmt'];
const sewersCoverages = ["HO_GroundWaterSewerCov", "HO_GroundWaterSewerOverflowCov_WMIC", "HO_SewerBackupCov"];
const sewersCoverageLimitTerms = ["HO_GroundWaterSewerCovPolLmt", "HO_GroundWaterSewerOverflowPolLmt", "HO_SewerBackupCovPolLmtInd"];
const hiddenCoverageTerms = [PAConstants.heavyNumRentals];

function getTermValue(nameList, terms) {
    let foundTerm;
    nameList.some((item) => {
        foundTerm = terms && terms.find((term) => term.publicID === item);
        return foundTerm;
    });

    return  foundTerm? (foundTerm.chosenTermValue || foundTerm.amount || foundTerm.directValue) : '';
}

function getTermLimitList(coverage) {
    const limitCovTermList = limitSuffixes.map((suffix)=>`${coverage.publicID}${suffix}`);

    if (_.get(coverage, 'properties_WMIC')) {
        const limitCovTerm = _.get(coverage, 'properties_WMIC').find((prop) => prop.name === 'limitCovTerm');
        if (!_.isEmpty(limitCovTerm?.value_WMIC)) {
            limitCovTermList.push(limitCovTerm.value_WMIC);
        }
    }

    return limitCovTermList;
}

function getTermDeductibleList(coverage) {
    const deductibleCovTermList = deductibleSuffixes.map((suffix)=>`${coverage.publicID}${suffix}`);

    if (_.get(coverage, 'properties_WMIC')) {
        const deductibleCovTerm = _.get(coverage, 'properties_WMIC').find((prop) => prop.name === 'deductibleCovTerm');
        if (!_.isEmpty(deductibleCovTerm?.value_WMIC)) {
            deductibleCovTermList.push(deductibleCovTerm.value_WMIC);
        }
    }

    return deductibleCovTermList;
}

function getTermLimit(coverage, translator) {
    const limitCovTermList = getTermLimitList(coverage);

    if (sewersCoverages.includes(coverage.coverageUniqueID) && coverage.terms.find((term) => sewersCoverageLimitTerms.includes(term.patternCode))?.chosenTerm === "true") {
        return translator(messages.policyLimit);
    }
    return getTermValue(limitCovTermList, coverage.terms);
}

function getDeductibleLimit(coverage) {
    const deductibleCovTermList = getTermDeductibleList(coverage);
    return getTermValue(deductibleCovTermList, coverage.terms);
}

function isUsableCovTerm(coverage, covTerm){
    const hasValue = covTerm.chosenTermValue !== '';
    const isLimitValue = getTermLimitList(coverage).includes(covTerm.publicID);
    const isDeductibleValue = getTermDeductibleList(coverage).includes(covTerm.publicID);
    const isSewersCoverage = sewersCoverageLimitTerms.includes(covTerm.publicID);
    const isHiddenCoverageTerm = hiddenCoverageTerms.includes(covTerm.patternCode);
    return hasValue && !isLimitValue && !isDeductibleValue && !isSewersCoverage && !isHiddenCoverageTerm;
}

function hasAdjustmentValue(coverage) {
    const adjTermValue = getTermValue(adjustmentSuffixes.map((suffix)=> `${coverage.publicID}${suffix}`), coverage.terms);
    return  !_.isEmpty(adjTermValue);
}

function getTotalAdjustedLimit(coverage) {
    const limitValue = WMICMathUtil.cleanNumberString(getTermLimit(coverage));
    const adjustedValue = WMICMathUtil.cleanNumberString(getTermValue(adjustmentSuffixes.map((suffix)=> `${coverage.publicID}${suffix}`), coverage.terms));

    return WMICMathUtil.delimitNumbers(parseFloat(limitValue) + parseFloat(adjustedValue));
}


export default {
    getTermLimit,
    getDeductibleLimit,
    isUsableCovTerm,
    hasAdjustmentValue,
    getTotalAdjustedLimit
}
