import { getProxiedServiceUrl } from 'wmic-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class CommissionPolicyService {
    static getPolicyPeriods(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewaycommissionCommission'),
            'getPolicyPeriods', params, additionalHeaders);
    }

    static getCommissionsTransactionsByPolicyPeriod(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewaycommissionCommission'),
            'getCommissionsTransactionsByPolicyPeriod', params, additionalHeaders);
    }

    static getPolicyCommissionSummary(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewaycommissionCommission'),
            'getPolicyCommissionSummary', params, additionalHeaders);
    }
}
