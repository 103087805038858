import { defineMessages } from 'react-intl';

export default defineMessages({
    isABResidentLessThan3YrON: {
        id: 'policy.pa.views.pa-drivers-details.license-info.isABResidentLessThan3YrON',
        defaultMessage: 'Is the Driver new to Ontario?'
    },
    getStateLicThirtyDay: {
        id: 'policy.pa.views.pa-drivers-details.license-info.getStateLicThirtyDay',
        defaultMessage: 'Will the driver get an {baseState} driver\'s license within {days} days?'
    },
    driverClassType: {
        id: 'policy.pa.views.pa-drivers-details.license-info.driverClassType',
        defaultMessage: 'License class'
    },
    driverClassDateObtained: {
        id: 'policy.pa.views.pa-drivers-details.license-info.driverClassDateObtained',
        defaultMessage: 'Date obtained'
    }
});
