import { defineMessages } from 'react-intl';

export default defineMessages({
    MVRIsPredictor: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr-add.is-predictor',
        defaultMessage: 'MVR type is Predictor?'
    },
    MVRTypeSelect: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr-add.type.select',
        defaultMessage: 'MVR type'
    },
    MVRPredictorScore: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr-add.predictor-score',
        defaultMessage: 'Predictor score'
    },
    MVRDate: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr-add.mvr-date',
        defaultMessage: 'MVR Date'
    },
    MVRViolationsSuspensions: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr-add.violations-suspensions',
        defaultMessage: 'Violations / Suspensions'
    },
    MVRViolationsSuspensionsDate: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr-add.violations-suspensions-date',
        defaultMessage: 'Violation/Suspension Date'
    },
    MVRConvictionReinstatementDate: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr-add.conviction-reinstatement-date',
        defaultMessage: 'Conviction/Reinstatement Date'
    },
    MVRType: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr-add.type',
        defaultMessage: 'Type'
    },
    MVRDescription: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr-add.description',
        defaultMessage: 'Description'
    },
    MVRAddViolationSuspension: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr-add.add-violations-suspensions',
        defaultMessage: 'Add Violation / Suspension'
    },
    mvrIncidentDescriptionOther: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.mvrIncidentDescriptionOther',
        defaultMessage: 'Other : {desc}'
    },
    RemovingIncident: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.RemovingIncident',
        defaultMessage: 'Remove violation / suspension'
    },
    RemovingIncidentConfirm: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.RemovingIncidentConfirm',
        defaultMessage: 'Are you sure you want to remove the violation / suspension from the list?'
    },
    No: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.add-mvr.no',
        defaultMessage: 'No'
    },
    Yes: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.add-mvr.yes',
        defaultMessage: 'Yes'
    },
})