import React, { useContext, useMemo } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICWizardViewPage } from 'wmic-pe-portals-wizard-components-ui';
import { LOBConstants, CONSTANTS, EMAIL_VALIDATION_STATUS } from 'wmic-pe-portals-utils-js';
import { WMICAddressDetails, WMICPhoneDetails, } from 'wmic-pe-components-platform-react';

import cx from 'classnames';

import metadata from './WMICPolicyContacts.metadata.json5';
import styles from './WMICPolicyContacts.module.scss';
import messages from './WMICPolicyContacts.messages';

function WMICPolicyContacts(props) {
    const {
        wizardData: { policyVM },
    } = props;
    const translator = useContext(TranslatorContext);


    const phoneType = _.get(policyVM, 'account.accountHolder.primaryPhoneType.value.code' )
    const emailValidationStatus = _.get(policyVM, 'emailValidationStatus.value.code')

    const isPA = useMemo(() =>_.get(policyVM, 'product.productCode.value') === LOBConstants.PA, [policyVM]);
    const edeliveryEnrolled = _.get(policyVM, 'value.edeliveryEnrolled');

    const primaryPhoneType = useMemo(() => {
        switch (phoneType) {
            case CONSTANTS.PHONE_TYPES.HOME:
                return messages.homePhone.defaultMessage;
            case CONSTANTS.PHONE_TYPES.MOBILE:
                return messages.mobilePhone.defaultMessage;
            case CONSTANTS.PHONE_TYPES.WORK:
                return messages.workPhone.defaultMessage;
            case CONSTANTS.PHONE_TYPES.NO_PHONE_WMIC:
                return messages.noPhone.defaultMessage;
        }
    }, [phoneType]);

    const validationStatus = useMemo(() => {
        switch (emailValidationStatus) {
            case EMAIL_VALIDATION_STATUS.VALID:
                return messages.emailValid;
            case EMAIL_VALIDATION_STATUS.INVALID_TLS:
                return messages.invalidtls;
            case EMAIL_VALIDATION_STATUS.ERROR:
                return messages.unavailable;
            case EMAIL_VALIDATION_STATUS.INVALID:
                return messages.invalid;
            default:
                break;
        }
    }, [emailValidationStatus]);

    const overrideProps = {
        '@field': {
            parentNode: policyVM,
            showRequired: true,
        },
        primaryPhone: {
            value: primaryPhoneType,
            content: translator(messages.primaryPhone),
            readOnly: true,
        },
        policyEDeliveryAnswer: {
            title: edeliveryEnrolled ? translator(messages.eDelivery_WMICYes) : translator(messages.eDelivery_WMICNo),
            value: true,
        },
        policyPreferredLanguage: {
            // Visibility is subject to change depending on Wawanesa decision
        },
        policyEPOIContainer: {
            visible: isPA,
        },
        policyEPOIAnswer: {
            value: true,
            title: _.get(policyVM, 'lobs.personalAuto.epoienrolled.value') ? translator(messages.epoiEnrolled_Yes) : translator(messages.epoiEnrolled_No),
        },
        addressDetailsComponent: {
            addressVM: _.get( policyVM, 'latestPeriod.policyAddress', {} ),
            hideBorder: true,
            hideButtons: true,
            readOnly: true,
        },
        addressContactDetailsComponent: {
            hideBorder: true,
            hideButtons: true,
            readOnly: true
        },
        acctEmailValidation: {
            visible: edeliveryEnrolled
        },
        emailValidationIcon: {
            visible: emailValidationStatus === EMAIL_VALIDATION_STATUS.VALID,
        },
        emailValidationStatus: {
            visible: !_.isNil(validationStatus),
            content: translator(validationStatus),
            className: cx(styles.gwAlert, {
                [styles.error]:
                    emailValidationStatus !== EMAIL_VALIDATION_STATUS.VALID,
            }),
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            WMICAddressDetails,
            WMICPhoneDetails,
        },
    };

    return (
        <WMICWizardViewPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WMICWizardViewPage>
    );
}

WMICPolicyContacts.propTypes = wizardProps;
export default WMICPolicyContacts;
