import React, {
    useEffect, useState, useContext, useCallback
} from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { Loader } from '@jutro/components';
import { useWizardModals , WizardModalsProvider } from 'wmic-pe-portals-wizard-components-ui';
import { Wizard } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelServiceContext, withViewModelService } from '@xengage/gw-portals-viewmodel-react';

import { TranslatorContext } from '@jutro/locale';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/prefer-default-export
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
// eslint-disable-next-line import/no-unresolved
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { messages as commonMessages } from 'gw-capability-policychange-common-react';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { messages as commonRenewalMessages} from 'wmic-pe-capability-gateway-policyrenewal-common-react';
import { CONSTANTS, MODAL_CONSTANTS, WIZARD_STEP_PATHS, WMICValidationUtil } from 'wmic-pe-portals-utils-js';
import messages from './WMICPEPARenewalWizard.messages';
import wizardConfig from './config/pa-renewal-wizard-config.json5';
import wizardStepToFieldMapping from './config/pa-wizard-step-to-field-mapping.json5';
import styles from './WMICPEPARenewalWizard.module.scss';

function checkValidity(jobVM, extractVMObject, currentStep) {
    switch (currentStep.path) {
        case WIZARD_STEP_PATHS.COMMON.CHANGE_SUMMARY:
        case WIZARD_STEP_PATHS.COMMON.QUOTE:
            if (!_.get(jobVM, 'isEditingPage.value', false)) {
                return true;
            }
            break;
        default:
            break;
    }

    const checkValidityPathsForCurrentStep = _.get(currentStep, 'checkValidityPaths', []);

    // Cloning checkValidityPaths to avoid adding duplicates to array
    const checkValidityPaths = _.clone(checkValidityPathsForCurrentStep);

    return WMICValidationUtil.checkValidity(jobVM, checkValidityPaths);
}

function WMICPEPARenewalWizard() {
    const { steps } = wizardConfig;
    const [initialRenewal, setInitialRenewal] = useState(null);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isCustomStepsInvalidated, setIsCustomStepsInvalidated] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const history = useHistory();
    const location = useLocation();
    const { authHeader } = useAuthentication();
    const translator = useContext(TranslatorContext);
    const { RenewalService } = useDependencies('RenewalService');
    const { showConfirm, showWarning } = useWizardModals();

    useEffect(
        () => {
            if (!location.state) {
                history.push('/');

                return;
            }

            const {
                state: { policyNumber, selectedTerm }
            } = location;
            const { state } = location;

            if (!policyNumber) {
                return;
            }

            const errorModalBox = (errorMessage) => {
                showConfirm(errorMessage).then((results) => {
                    if (results === CONSTANTS.MODAL_RESULT.CANCEL || results === CONSTANTS.MODAL_RESULT.CLOSE) {
                        return _.noop();
                    }

                    setIsLoading(false);

                    return history.push(`/contactAgent/${policyNumber}/${selectedTerm}`);
                }, _.noop);
            };

            RenewalService.retrieve(state.renewalentry.jobID, authHeader)
                .then((response) => {
                    const renewal = viewModelService.create(response, 'pc', 'wmic.edge.ca.capabilities.renewal.dto.RenewalDataDTO');

                    steps.forEach((step) => _.set(step, 'stepToFieldMapping', wizardStepToFieldMapping[step.id]))
                    
                    setInitialRenewal(renewal);
                    setIsLoading(false);
                })
                .catch(() => {
                    errorModalBox({
                        title: commonMessages.unableToLoadDraftPolicy,
                        message: commonMessages.somethingWentWrong,
                        status: MODAL_CONSTANTS.STATUS.WARNING,
                        icon: MODAL_CONSTANTS.ICON.WARNING
                    });
                })
                .catch(() => {
                    errorModalBox({
                        title: commonMessages.unableToStartPolicy,
                        message: commonMessages.anErrorOccurred,
                        status: MODAL_CONSTANTS.STATUS.WARNING,
                        icon: MODAL_CONSTANTS.ICON.WARNING
                    });
                });
        },
        // Disabled so we don't rerun this function on every rerender
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleOnCancel = useCallback(({ wizardData: policyRenewalVM, wizardSnapshot, param: nextPath = '' }) => {
        const status = _.get(policyRenewalVM.value, 'status');
        const jobID = _.get(policyRenewalVM.value, 'jobID');

        let lastPathName = "";
        
        if (!_.isEmpty(nextPath)) {
            lastPathName = nextPath.replace(/\/?.*\//g, '');
        }

        if ((status === 'Bound' && !_.isNil(jobID)) || (!_.isEmpty(lastPathName) && (CONSTANTS.RENEWAL_SKIP_CANCEL_MODAL_URLS.includes(lastPathName))) || hasErrorOccurred) {
            return true;
        }

        return showConfirm({
            title: commonRenewalMessages.leavingThisPage,
            message: commonRenewalMessages.aboutToLeaveThisPage,
            status: MODAL_CONSTANTS.STATUS.WARNING,
            icon: MODAL_CONSTANTS.ICON.WARNING,
            cancelButtonText: platformMessages.cancelModel
        }).then((results) => {
            if (results === 'cancel') {
                return _.noop();
            }

            setIsLoading(true);

            const isValid = policyRenewalVM.aspects.subtreeValid && policyRenewalVM.aspects.valid;
            const payload = isValid ? policyRenewalVM.value : _.get(wizardSnapshot, 'value');

            const quoteStatus = _.get(wizardSnapshot, 'baseData.periodStatus.value.code')
                || _.get(policyRenewalVM.value, 'status');
            const isStatusQuoted = quoteStatus !== 'Quoted';
            const redirectLobPath = (!_.isEmpty(nextPath) && nextPath) || `/renewal/${jobID}/summary`;

            let exitPromise;

            if (isStatusQuoted) {
                exitPromise = RenewalService.saveWithNoValidationRuleCheck(payload, authHeader);
            } else {
                return history.push(redirectLobPath);
            }

            exitPromise.then(() => {
                history.push(redirectLobPath);
            }).catch(() => {
                showConfirm({
                    title: commonRenewalMessages.unableToDraftRenewal,
                    message: commonRenewalMessages.anErrorOccurred,
                    status: MODAL_CONSTANTS.STATUS.WARNING,
                    icon: MODAL_CONSTANTS.ICON.WARNING
                });
            }).finally(() => {
                setIsLoading(false);
            });

            return true;
        }, _.noop);
    }, [RenewalService, authHeader, hasErrorOccurred, history, showConfirm]);

    const handleError = useCallback(
        (error) => {
            const isQuotePage = _.includes(_.toLower(_.get(error, 'gwInfo.method')), 'quote');
            const jobIDFromWizard = _.get(error, 'gwInfo.params[0].jobID');
            const jobIDFromLocation = _.get(location, 'state.policyNumber');
            const termNumberFromLocation = _.get(location, 'state.selectedTerm')
            let redirectPath = `/contactAgent/${jobIDFromLocation}/${termNumberFromLocation}`;

            if (!_.isEmpty(jobIDFromWizard)) {
                redirectPath = `/renewal/${jobIDFromWizard}/summary`;
            }

            const state = {
                pathname: redirectPath,
                state: {
                    redirectPath: '/home',
                }
            };

            return showWarning({
                title: isQuotePage ? messages.saveQuoteError : messages.anErrorOccurredTitle,
                message: isQuotePage ? messages.saveQuoteErrorMessage : messages.anErrorOccurred,
                status: MODAL_CONSTANTS.STATUS.WARNING,
                icon: MODAL_CONSTANTS.ICON.WARNING,
                confirmButtonText: platformMessages.ok
            }).then(() => {
                setHasErrorOccurred(true);

                return <Redirect to={state} />;
            }, _.noop);
        },
        [location, showWarning]
    );

    function getTargetPageIndex(steps) {
        for (let stepIndex = 0; stepIndex < steps.length; stepIndex += 1) {
            if (steps[stepIndex].id === 'policyRenewalQuote') {
                return stepIndex;
            }
        }

        return undefined
    }

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    if (!initialRenewal) {
        return null;
    }

    return (
        <div className={styles.paPolicyChangeWizardContainer}>
            <ErrorBoundary onError={handleError}>
                <WizardModalsProvider defaultMessage={translator(messages.savingTransactionDetails)}>
                    <Wizard
                        maintainFurtherStepsVisitedSubmitted={{
                            flag: !isCustomStepsInvalidated,
                            targetStep: getTargetPageIndex(steps),
                        }}
                        setIsCustomStepsInvalidated={setIsCustomStepsInvalidated}
                        initialSteps={steps}
                        wizardTitle={translator(messages.progress)}
                        initialData={initialRenewal}
                        onCancel={handleOnCancel}
                        wizardStepToFieldMapping={wizardStepToFieldMapping}
                        onPreviousModalProps={{
                            title: commonRenewalMessages.wantToJump,
                            message: commonRenewalMessages.wantToJumpMessage,
                            status: MODAL_CONSTANTS.STATUS.WARNING,
                            icon: MODAL_CONSTANTS.ICON.WARNING,
                            confirmButtonText: platformMessages.yes,
                            cancelButtonText: platformMessages.no
                        }}
                        checkValidity={checkValidity}
                        skipCompletedSteps
                    />
                </WizardModalsProvider>
            </ErrorBoundary>
        </div>
    );
}

WMICPEPARenewalWizard.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            address: PropTypes.shape({}),
            policyNumber: PropTypes.string,
            requestType: PropTypes.string,
            policyDetails: PropTypes.shape({
                effective: PropTypes.instanceOf(Date)
            })
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired
};

export default withViewModelService(WMICPEPARenewalWizard);
