import React, { useState, useEffect, useContext } from 'react';
import { BreakpointTrackerContext } from '@jutro/layout';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { DeviceBreakpoint, Position } from 'wmic-pe-portals-utils-js';
import metadata from './WMICAddPayoutComponent.metadata.json5';
import messages from './WMICAddPayoutComponent.messages';

function WMICAddPayoutComponent(props){
    const {
        id,
        selectedPayout, 
        savePayout,
        cancelPayout,
        onValidate,
        currentPriorLoss,
    } = props;

    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const {onValidate: setComponentValidation, isComponentValid} = useValidation(id);

    const [currentPayout, updateCurrentPayout] = useState(selectedPayout);
    const { refreshData } = useDataRefresh();
    const [showErrors, updateShowErrors] = useState(false);

    useEffect(() => {
        updateCurrentPayout(selectedPayout);
    }, [selectedPayout])

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [isComponentValid, onValidate, currentPayout, id]);

    const updatePayout = (data) => {
        refreshData();
        updateCurrentPayout(data);
    };

    const handleSavePayout = () => {
        if(isComponentValid) {
            savePayout(currentPayout)
        } else {
            updateShowErrors(true)
        }
    };
    
    const resolvers = {
        resolveCallbackMap: {
            handleSavePayout,
            cancelPayout
        },
        resolveComponentMap: {},
        resolveClassNameMap: {}
    };

    const overrideProps = {
        '@field': {
            parentNode: currentPayout,
            readOnly: false
        },
        payoutStatus: {
            readOnly: true,
            value: translator({
                id: _.get(currentPriorLoss, 'status.value.name')
            }),
            labelPosition: breakpoint === DeviceBreakpoint.DESKTOP ? Position.LEFT :  Position.TOP,
        },
        payoutType: {
            readOnly: true,
            value: translator({
                id: _.get(currentPriorLoss, 'manualLossType.value.name')
            }),
            labelPosition: breakpoint === DeviceBreakpoint.DESKTOP ? Position.LEFT :  Position.TOP,
        }
    };


    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentPayout}
            overrideProps={overrideProps}
            onModelChange={updatePayout}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    )
}

WMICAddPayoutComponent.propTypes = {
    id: PropTypes.string.isRequired,
    selectedPayout: PropTypes.shape({}).isRequired,
    savePayout: PropTypes.func.isRequired,
    cancelPayout: PropTypes.func.isRequired,
    updateSelectedPayout: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired
};

export default WMICAddPayoutComponent;