import {defineMessages} from 'react-intl';

export default defineMessages({
    companyContactRequired: {
        id: "quoteandbind.ho.views.ho-your-home.CompanyContactDetailsNeedToBeAdded",
        defaultMessage: "Contact details for the company must be added to complete this entry."
    },
    searchForExistingCompany: {
        id: "wmic.GoldSearch.Search for an existing company",
        defaultMessage: "Search for an existing company"
    },
    searchInput: {
        id: "wmic.GoldSearch.Company Name",
        defaultMessage: "Company Name"
    },
    provinceSearchInput: {
        id: "wmic.GoldSearch.Province",
        defaultMessage: "Province/Territory"
    },
    citySearchInput: {
        id: "wmic.GoldSearch.City",
        defaultMessage: "City"
    },
    streetSearchInput: {
        id: "wmic.GoldSearch.Street",
        defaultMessage: "Street"
    },
    searchButton: {
        id: "quoteandbind.ho.views.ho-your-home.Search",
        defaultMessage: "Search"
    },
    pleaseProvideAdditionalDetailsText: {
        id: "wmic.GoldSearch.Too many results found. Please provide additional details above and search again.",
        defaultMessage: "Too many results found. Please provide additional details above and search again."
    },
    tooManyResults: {
        id: "wmic.GoldSearch.ContactManager.Error.TooManyResults",
        defaultMessage: "ContactManager returned too many results, please make your search criteria more specific"
    },
    loadingPlaceholder: {
        id: "wmic.quote-request.risk.Table Loading Text",
        defaultMessage: "Loading..."
    },
    selectMessage: {
        id: "wmic.GoldSearch.Select",
        defaultMessage: "Select"
    },
    addressMessage: {
        id: "wmic.GoldSearch.Address",
        defaultMessage: "Address"
    },
    postalCodeMessage: {
        id: "wmic.GoldSearch.Postal Code",
        defaultMessage: "Postal Code"
    },
    nameMessage: {
        id: "wmic.GoldSearch.Name",
        defaultMessage: "Name"
    },
    postalCodeInvalidMessage: {
        id: "wmic.GoldSearch.Postal Code Invalid",
        defaultMessage: "Postal Code Invalid"
    }
});
