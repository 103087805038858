import React, {useContext} from 'react';
import cx from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { Icon } from '@jutro/components';
import PropTypes from "prop-types";

import styles from './WMICPolicyMenuBar.module.scss';

// Created this component to replace the ootb StepProgressBar
// There is a bug in this component regarding the disabled state -> jira tracking number for bug: JUT-11567

const stepProgressBarPropTypes = {
    /**
     * The children elements to render inside
     */
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            /**
             * Text part for labeling the step
             */
            title: PropTypes.string,
            /**
             * Indicates if step marker should be displayed as currently active step.
             */
            active: PropTypes.bool,
            /**
             * Indicates if step marker should be displayed as visited.
             */
            visited: PropTypes.bool,
            /**
             * Indicates if step marker should be displayed as disabled.
             */
            disabled: PropTypes.bool,
            /**
             * Handler for click event on progress marker.
             */
            onClick: PropTypes.func,
        })
    ),
    /**
     * Class to override progress bar styles
     */
    className: PropTypes.string,
}

function WMICPolicyMenuBar(props) {
    const {
        steps,
        className
    } = props;

    const translator = useContext(TranslatorContext);

    const classes = cx(styles.stepProgressBar, styles.vertical, className);

    const getProgressStep = (stepProps, id, index) => {

        const { visited, active, title, onClick, disabled = false } = stepProps;

        const progressStepClasses = cx(styles.progressStep, className, {
            [styles.active]: active,
            [styles.visited]: visited,
            [styles.vertical]: true,
            [styles.pointerEvents]: disabled
        });

        const beforeConnectorClasses = cx({
            [styles.markersConnector]: index !== 0,
            [styles.beforeConnector]: index !== 0}
        );
        
        return (
            <div className={cx({[styles.progressStepDisabled]: disabled})}>
                <div
                    className={cx(progressStepClasses)}
                    onClick={!disabled ? onClick : undefined}
                    onKeyPress={(e) => e.key === 'Enter' && onClick?.(e)}
                    tabIndex={0}
                    role="link"
                    id={id}
                >  
                    <div className={styles.title}>
                        {title ? translator(title) : ' '}
                    </div>
                </div>
            </div>
        );
    }



    return (
        <nav className={classes}>
            {steps.map((stepProps, index) => {
                const id = `${stepProps.title}${index}`;
                return <>{getProgressStep(stepProps, id, index)}</>
            })}
        </nav>
    );
}

WMICPolicyMenuBar.propTypes = stepProgressBarPropTypes;


export default WMICPolicyMenuBar