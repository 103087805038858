import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { WMICLogger, CONSTANTS } from 'wmic-pe-portals-utils-js';
import axios from 'axios';
import _ from 'lodash';

export default class WMICDocumentUploadService {

    static sendAxios( file, metadataTemplate, onUploadProgress, additionalHeaders={}) {
        const endPointInfoForLogging = `WMICDocumentUpload metadataTemplate=${metadataTemplate}, additionalHeaders=${JSON.stringify(additionalHeaders)}`;
        
        WMICLogger.debug(`${endPointInfoForLogging} request=sent`);
        
        // From DocumentUploadService:5 and :22
        const documentMetadataTemplate = _.merge(
            {}, {
                docUID: '001',
                documentType: 'letter_received',
                securityType: 'unrestricted',
                status: 'approved'
            }, metadataTemplate
        );

        // From BaseTransportService:29
        const baseHeaders = {
            Accept: 'application/json',
            'Accept-Language': localStorage.getItem('selectedLanguage')
        };
        const newHeaders = Object.assign(baseHeaders, additionalHeaders);

        // From https://www.codingdeft.com/posts/react-upload-file-progress-bar/?utm_content=cmp-true#binding-the-form-with-backend-api
        const axiosInstance = axios.create({
            baseURL: getProxiedServiceUrl('gatewayUpload'),
        });

        const formData = new FormData()

        formData.append('document', JSON.stringify(documentMetadataTemplate));
        formData.append('file[]', file);

        return axiosInstance.post("", formData, {
            signal: file.abortController.signal,
            headers: newHeaders,
            onUploadProgress
        }).then(
            (response) => {
                WMICLogger.debug(`${endPointInfoForLogging} request=received`);

                return response.data.result;
            },
            (error) => {
                if (error.name === CONSTANTS.CANCELLED_ERROR) {
                    return
                }

                WMICLogger.error(`${endPointInfoForLogging} request=error, error=${JSON.stringify(error)}`);
                throw error;
            }
        );
    }

}