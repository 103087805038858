import React, { useContext, useMemo } from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import PropTypes from 'prop-types';
import { JobType } from 'wmic-pe-portals-utils-js';
import WMICQuoteUtil from 'wmic-pe-capability-quoteandbind-common-react/util/WMICQuoteUtil';
import metadata from './WMICQuoteAlertMessage.metadata.json5';
import messages from './WMICQuoteAlertMessage.messages';
import styles from './WMICQuoteAlertMessage.module.scss';

function WMICQuoteAlertMessage(props) {

    const { jobVM, isJobQuoted, underwritingIssues } = props;
    const translator = useContext(TranslatorContext);
    const jobType = _.get(jobVM, 'baseData.jobType.value.code', []);
    const jobTypeDisplayName = translator(messages[`jobType${jobType}`]);
    const uwIssues =
        JobType.POLICY_CHANGE === jobType
            ? underwritingIssues
            : _.get(jobVM, 'errorsAndWarnings.underwritingIssues.value', []);
    const quoteHasValidationIssues =_.get(jobVM, 'errorsAndWarnings.validationIssues.issues.value', []).length > 0;
    const hasIssuanceBlockingUWIssues = WMICQuoteUtil.hasIssuanceBlockingUWIssues(uwIssues);

    const hasBindingBlockingUWIssues = WMICQuoteUtil.isBindBlockingUWIssuePresent(uwIssues);
    const hasQuoteBlockingUWIssues = WMICQuoteUtil.isQuoteBlockingUWIssuePresent(uwIssues);
    const displayStatus = _.get(jobVM, 'displayStatus_WMIC.value');
    const alertHeadingMessage = useMemo(() => {
        if (['Quoted: Underwriter Approval Pending'].includes(_.get(jobVM, 'displayStatus_WMIC.value', ''))) {
            return messages.underwriterApprovalPending;
        }

        if (hasQuoteBlockingUWIssues) {
            return messages.quoteBlockedUwIssuesHeading;
        }

        if (hasIssuanceBlockingUWIssues && quoteHasValidationIssues) {
            return messages.uwAndValidationIssuesHeading;
        }

        if (quoteHasValidationIssues) {
            return messages.validationIssuesHeading;
        }

        if (hasIssuanceBlockingUWIssues || hasBindingBlockingUWIssues) {
            return messages.uwIssuesHeading;
        }

        return displayStatus || messages.noUwIssuesHeading;
    }, [jobVM, hasIssuanceBlockingUWIssues, quoteHasValidationIssues, displayStatus, hasQuoteBlockingUWIssues, hasBindingBlockingUWIssues]);

    const alertBodyMessage = hasIssuanceBlockingUWIssues || quoteHasValidationIssues || hasQuoteBlockingUWIssues || hasBindingBlockingUWIssues ? messages.uwOrValidationIssuesBody : messages.noUwIssuesBody;

    const overrides =  {
        alertContainer: {
            visible: isJobQuoted
        },
        alertIcon: {
            className: 'wmicQuoteAlertIcon',
            icon: hasQuoteBlockingUWIssues || hasIssuanceBlockingUWIssues || hasBindingBlockingUWIssues || quoteHasValidationIssues? 'mi-warning' : 'mi-check'
        },
        alertMessageHeading: {
            className: hasQuoteBlockingUWIssues || hasIssuanceBlockingUWIssues || hasBindingBlockingUWIssues || quoteHasValidationIssues? 'hasUwIssuesHeading' : '',
            content: translator(alertHeadingMessage)
        },
        alertMessageBody: {
            content: translator(alertBodyMessage, {jobType: jobTypeDisplayName})
        }
    }

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICQuoteAlertMessage.propTypes = {
    jobVM: PropTypes.shape({}).isRequired,
    isJobQuoted: PropTypes.bool
};

WMICQuoteAlertMessage.defaultProps = {
    isJobQuoted: true
};

export default WMICQuoteAlertMessage;
