import React, { useContext, useEffect, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import _ from 'lodash';
import {
    CONSTANTS,
    LINE_OF_BUSINESS,
    POLICY_DETAILS,
    JURISDICTIONS,
    WMICDateTimeService,
    WMICCreditConsentUtil,
    WMICRPCUtil,
    WMICJurisdictionUtil,
    AddressType,
    DeviceBreakpoint,
    Position,
    JobType
} from 'wmic-pe-portals-utils-js';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { WMICAddressDetails } from 'wmic-pe-components-platform-react';
import WMICAddressUtil from 'wmic-pe-capability-gateway-common-react/utils/WMICAddressUtil';

import metadata from './WMICNamedInsuredDetailView.metadata.json5';
import messages from './WMICNamedInsuredDetailView.messages';
import namedInsuredMessages from '../WMICNamedInsuredComponent.messages';

function WMICNamedInsuredDetailView(props) {
    const {
        id,
        value: selectedInsuredVM,
        updateModel,
        onValidate,
        jobVM,
        showErrors,
        relationshipOptions,
        isCommercialSmallBusiness,
        isEditing
    } = props;

    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { showInfo } = useWizardModals();
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const [aniAddressAsPniAddress, setAniAddressAsPniAddress] = useState(false);

    const isSubmission = _.get(jobVM, 'baseData.jobType.value.code') === JobType.SUBMISSION;
    const lob = _.get(jobVM, 'lob.value.code');
    const baseData = _.get(jobVM, 'baseData');
    const effectiveDate = _.get(jobVM, 'baseData.periodStartDate.value');
    const isANI = selectedInsuredVM?._dtoName === 'wmic.edge.ca.capabilities.policyjob.draft.dto.AdditionalNamedInsuredDTO_WMIC';
    const relationship = _.get(selectedInsuredVM, "relationshipToPrimaryInsured_WMIC.value");
    const isPA = () => lob === LINE_OF_BUSINESS.PERSONAL_AUTO;
    const isHO = () => lob === LINE_OF_BUSINESS.HOME_OWNER;
    const isRPCEffective = useCallback((...rpcNumbers) => {
        const effective = WMICRPCUtil.getIsRPCEffective(_.get(jobVM, 'baseData.baseState.value.code'), jobVM.baseData.rateAsOfDate.value, ...rpcNumbers);
        return effective;
    }, [jobVM]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => {
            if(onValidate){
                onValidate(true, id)
            }
        }
    }, [isComponentValid, onValidate, id]);

    useEffect(() => {
        setAniAddressAsPniAddress(getIsSameAddress());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const customRelationshipOptions = useMemo(() => {
        const customOptions = [...relationshipOptions];
        const relationshipOption = {
            ...relationship,
            name: {
                id: _.get(relationship, "name", ""),
                defaultMessage: _.get(relationship, "name", "")
            }
        }
        const relationshipOptionsCodes = relationshipOptions.map(a => a.code);
        if (!_.includes(relationshipOptionsCodes, relationshipOption.code)) {
            customOptions.push(relationshipOption);
        }
        return customOptions;
    }, [relationship, relationshipOptions]);

    const today = new Date();

    const minDOB = {
        year: today.getFullYear() - POLICY_DETAILS.MAXIMUM_LEGAL_AGE,
        month: today.getMonth(),
        day: today.getDate()
    };

    const maxDOB = {
        year: today.getFullYear() - POLICY_DETAILS.MINIMUM_LEGAL_AGE,
        month: today.getMonth(),
        day: today.getDate()
    };

    const isPersonANI = _.upperFirst(_.get(selectedInsuredVM.value, 'subtype')) === CONSTANTS.Person;

    const isCompany = _.upperFirst(_.get(selectedInsuredVM.value, 'subtype')) === CONSTANTS.Company;

    const copyPniAddressToAniAddress = useCallback((value) => {
        setAniAddressAsPniAddress(value);

        if (value) {
            const pniAddress = _.get(jobVM, 'baseData.primaryNamedInsured_WMIC.primaryAddress.value');

            _.set(selectedInsuredVM, 'locationAddress', pniAddress)
            _.set(selectedInsuredVM, 'locationAddress.addressType.value', isPersonANI ? AddressType.HOME : AddressType.BUSINESS)
            // clear the address only if it was the same as account holder's, otherwise keep it (needed for editing the address)
        } else if (getIsSameAddress()) {
            _.set(selectedInsuredVM, 'locationAddress', {
                country: CONSTANTS.COUNTRY.CA,
                city: '',
                state: '',
                postalCode: '',
            })
        }

        updateModel(selectedInsuredVM);
    }, [getIsSameAddress, isPersonANI, jobVM, selectedInsuredVM, updateModel]);

    const getIsSameAddress = useCallback(() => {
        const address1 = selectedInsuredVM?.locationAddress?.value;
        const address2 = jobVM?.baseData?.primaryNamedInsured_WMIC?.primaryAddress?.value;
        
        return WMICAddressUtil.isSameAddress(address1, address2);
    }
    , [jobVM, selectedInsuredVM]);

    const handleToggleChange = (value) => {
        _.set(selectedInsuredVM.value, 'togglePrimaryInsuredValue', value);
        updateModel(selectedInsuredVM);

        let additionalInfoMessage = messages.additionalInformationForPrimaryInsured;

        if (_.get(jobVM, 'baseData.additionalNamedInsureds_WMIC.value', []).length > 1) {
            additionalInfoMessage = messages.additionalInformationForPrimaryAndAdditionalInsured;
        }

        showInfo({
            title: messages.changePrimaryInsuredTitle,
            message: translator(additionalInfoMessage)
        })
    }

    const canChangeToPNI = !!_.get(selectedInsuredVM, 'contactPublicID.value')
        && _.get(jobVM, 'baseData.primaryNamedInsured_WMIC.subtype.value', '').toLowerCase() === _.get(selectedInsuredVM, 'subtype.value.code', '').toLowerCase();

    const changedCreditConsent = (value, path) => {
        _.set(selectedInsuredVM, path, value);
        if (_.get(selectedInsuredVM, 'creditConsentReceived.value')) {
            const required = _.get(selectedInsuredVM, 'creditConsentReceived.value.code') === CONSTANTS.CREDIT_CONSENT_CODES.YES;
            _.set(selectedInsuredVM, 'dateOfBirthIsRequired_WMIC.value', required);
        }
        updateModel(selectedInsuredVM);
    };

    const showCreditConsentInfoANI = () => {
        if (!WMICCreditConsentUtil.isAniCreditConsentable(selectedInsuredVM)) {
            return false;
        }
        if (isPA()
            && (WMICJurisdictionUtil.isBaseState(baseData, JURISDICTIONS.QUEBEC)
            || (isRPCEffective('1296') && !isRPCEffective('1576')))) {
            return isPersonANI;
        }
        return isHO() && isPersonANI && !WMICCreditConsentUtil.isHOCreditConsentTabVisibleOnInsuranceHistory(effectiveDate);
    };

    const isConsentNotReceivedAdditionalNamedInsured = () => isPersonANI && setCreditConsentInfoANI(CONSTANTS.CREDIT_CONSENT_CODES.NO);

    const setCreditConsentInfoANI = (data) => {
        const today = new Date();
        if (!_.isUndefined(_.get(selectedInsuredVM, 'creditConsentReceived.value'))) {
            if (_.isUndefined(_.get(selectedInsuredVM, 'creditConsentDate.value'))) {
                _.set(selectedInsuredVM, 'creditConsentDate.value', {
                        year: today.getFullYear(),
                        month: today.getMonth(),
                        day: today.getDate()
                    }
                );
            }
            return _.get(selectedInsuredVM, 'creditConsentReceived.value.code') === data;
        }
        return false;
    };

    const isCreditInfoWithdrawalConsentApplicable = () => {
        return isPersonANI && setCreditConsentInfoANI(CONSTANTS.CREDIT_CONSENT_CODES.NO);
    };

    const isConsentReceivedAdditionalNamedInsured = () => isPersonANI && setCreditConsentInfoANI(CONSTANTS.CREDIT_CONSENT_CODES.YES);

    const setEstateOf = () => {
        _.set(selectedInsuredVM, 'estateOf_WMIC.value', _.get(selectedInsuredVM, "estateOf_WMIC.value") === null ? false : _.get(selectedInsuredVM, "estateOf_WMIC.value"));
    };

    const overrideProps = {
        '@field': {
            parentNode: selectedInsuredVM,
            readOnly: !isEditing
        },
        WMICNamedInsuredDetailView: {
            visible: isCompany || isPersonANI
        },
        companyContainer: {
            visible: isCompany
        },
        personContainer: {
            visible: isPersonANI
        },
        relationshipPNI: {
            readOnly: true,
            visible: !isANI || _.get(selectedInsuredVM.value, 'togglePrimaryInsuredValue', false),
            labelPosition: breakpoint === DeviceBreakpoint.DESKTOP ? Position.LEFT : Position.TOP,
            value: translator(namedInsuredMessages.insured)
        },
        relationship: {
            readOnly: !isEditing || isCompany,
            availableValues: customRelationshipOptions,
            visible: !_.get(selectedInsuredVM.value, 'togglePrimaryInsuredValue', false)
        },
        companyNameNI: {
            path: isANI ? 'companyName' : 'contactName',
            visible: !isCommercialSmallBusiness
        },
        commercialSmallBusinessCompanyName: {
            visible: isCommercialSmallBusiness
        },
        WMICNamedInsuredDetailViewForm: {
            hideButtons: true
        },
        pniAddressTitle: {
            visible: !isANI
        },
        pniAddressLookup: {
            addressVM: _.get(selectedInsuredVM, 'primaryAddress'),
            hideButtons: true,
            hideBorder: true,
            // WMICAddressDetails doesn't pick up the readOnly value from @field, we need to pass it directly
            readOnly: !isEditing,
            visible: !isANI,
            showErrors,
            onValidate: setComponentValidation
        },
        aniAddressLookup: {
            addressVM: _.get(selectedInsuredVM, 'locationAddress'),
            hideButtons: true,
            hideBorder: true,
            readOnly: !isEditing || !_.get(selectedInsuredVM.value, 'togglePrimaryInsuredValue', false),
            visible: _.get(selectedInsuredVM.value, 'togglePrimaryInsuredValue', false) && _.get(selectedInsuredVM, 'locationAddress') !== undefined,
            showErrors,
            onValidate: setComponentValidation
        },
        companyAddressLookup: {
            addressVM: _.get(selectedInsuredVM, isANI ? 'locationAddress' : 'primaryAddress'),
            hideButtons: true,
            hideBorder: true,
            readOnly: !isEditing || (isANI && aniAddressAsPniAddress),
            showErrors,
            onValidate: setComponentValidation
        },
        dob: {
            maxDate: maxDOB,
            minDate: minDOB,
        },
        personPrimaryInsuredToggle: {
            labelPosition: breakpoint === DeviceBreakpoint.DESKTOP ? Position.LEFT : Position.TOP,
            visible: !isSubmission && (canChangeToPNI || !isANI),
            value: _.get(selectedInsuredVM.value, 'togglePrimaryInsuredValue', false),
            readOnly: !isEditing || !isANI
        },
        companyPrimaryInsuredToggle: {
            labelPosition: breakpoint === DeviceBreakpoint.DESKTOP ? Position.LEFT : Position.TOP,
            visible: !isSubmission && (canChangeToPNI || !isANI),
            value: _.get(selectedInsuredVM.value, 'togglePrimaryInsuredValue', false),
            readOnly: !isEditing || !isANI
        },
        sameAsPrimary: {
            visible: isANI,
            onValueChange: copyPniAddressToAniAddress,
            value: aniAddressAsPniAddress,
        },
        showCreditConsentContainer: {
            visible: showCreditConsentInfoANI()
        },
        declineDate: {
            visible: isConsentNotReceivedAdditionalNamedInsured()
        },
        creditInfoWithdrawalConsent: {
            visible: isCreditInfoWithdrawalConsentApplicable(_.get(selectedInsuredVM, 'creditConsentReceived.value'))
        },
        isConsentReceivedAdditionalContainer: {
            visible: isConsentReceivedAdditionalNamedInsured()
        },
        estate: {
            setValue: setEstateOf()
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            handleToggleChange,
            changedCreditConsent
        },
        resolveComponentMap: {
            WMICAddressDetails
        }
    };


    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={selectedInsuredVM}
            onModelChange={updateModel}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
            onValidationChange={setComponentValidation}
        />
    )
}

WMICNamedInsuredDetailView.propTypes = {
    value: PropTypes.shape({}).isRequired,
    updateModel: PropTypes.func,
    showErrors: PropTypes.bool,
    onValidate: PropTypes.func,
    isEditing: PropTypes.bool
};

WMICNamedInsuredDetailView.defaultProps = {
    updateModel: undefined,
    showErrors: false,
    onValidate: undefined,
    isEditing: false
};

export default WMICNamedInsuredDetailView;
