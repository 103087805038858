import React, { useMemo, useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import metadata from './WMICDTOError.metadata.json5'
import styles from './WMICDTOError.module.scss'
import messages from './WMICDTOError.messages'

function WMICDTOError(props) {
    const { dtoErrors } = props;
    const translator = useContext(TranslatorContext);

    const ErrorMessages = () => {
        return (
            <div className={styles.ErrorsMessagesContainer}>
                {dtoErrors.map(error => {
                    return  <div> 
                                <span className={styles.dtoErrorsHeading}> {error.displayName} </span> 
                                <ul>
                                    {error.validatedProperties.map(property => (<li>{property.propertyName} - {property.errorMessage}</li>))}
                                </ul> 
                            </div>})}
            </div>)
    };
    const overrideProps = {
        dtoErrorsContainer: {
            visible: dtoErrors?.length > 0
        },
        errorsContent: {
            data: dtoErrors
        }
    };

    const resolvers = {
        classNameMap: styles,
        callbackMap: {},
        componentMap: {
            ErrorMessages
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={dtoErrors}
            overrideProps={overrideProps}
            classNameMap={resolvers.classNameMap}
            componentMap={resolvers.componentMap}
        />
    );
}

WMICDTOError.propTypes = {
    dtoErrors: PropTypes.array.isRequired,
};

export default WMICDTOError;
