import { defineMessages } from 'react-intl';

export const messages = defineMessages({
    nodeUndefined: {
        id: 'wmic-pe-components-platform-react.WMICMasterInput.warnings.Node is undefined at path:',
        defaultMessage: 'Node is undefined at path: {path}',
    },
    invalidControlType: {
        id: 'wmic-pe-components-platform-react.WMICMasterInput.warnings.Invalid control type for path:',
        defaultMessage: 'INVALID CONTROL TYPE {types} for path: {path}. Consider adding a controlType to the props or check your view model!',
    },
    confirmDefault: {
        id: 'wmic-pe-components-platform-react.WMICMasterInput.warnings.Confirm Default',
        defaultMessage: 'Confirm Default'
    },
    reviewed: {
        id: 'wmic-pe-components-platform-react.WMICMasterInput.warnings.Reviewed',
        defaultMessage: 'Reviewed'
    },
    postOnChangeLoadingText: {
        id: "wmic-pe-components-platform-react.WMICMasterInput.loading.Retrieving data, please wait",
        defaultMessage: "Retrieving data, please wait"
    }
});
