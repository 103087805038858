import { useContext } from 'react';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';

/* This hook is used instead of viewModelService.clone() as there is a defect
where the context of the cloned VM is wiped out due to the _metadataInfo.externalContext being
empty */
export default function useViewModelClone() {
    const viewModelService = useContext(ViewModelServiceContext);

    function clone(data) {
        const cloneVM = viewModelService.clone(data);
        return viewModelService.changeContext(cloneVM, data.aspects.context());
    }
    
    return clone;
}