
export default () => {
    return {
        ocular: {
            get: () => {
                // assumption that any questions which make it this far should be visible
                return true;
            }
        }
    }
};
