import { useMemo, useCallback } from 'react';

const useAccordionValidation = (onValidate) => {
    const accordionValidMap = useMemo(() => ({}), [])

    const onValidateAccordion = useCallback((isValid, id) => {
        if (onValidate) {
            onValidate(isValid, id);
        }
        accordionValidMap[id] = isValid;
    }, [onValidate, accordionValidMap]);

    const isAccordionValid = useCallback((id) => {
        return accordionValidMap.hasOwnProperty(id) && accordionValidMap[id]
    }, [accordionValidMap]);

    return {
        onValidateAccordion,
        isAccordionValid
    }
}

export default useAccordionValidation;