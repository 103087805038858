import { defineMessages } from 'react-intl';

export default defineMessages({
    householdMemberTitle: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICHouseholdMemberDetailsComponent.householdMemberTitle',
        defaultMessage: 'Household Member'
    },
    driverInformationTitle: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICHouseholdMemberDetailsComponent.driverInformationTitle',
        defaultMessage: 'Driver Information'
    },
    legalHistoryTitle: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICHouseholdMemberDetailsComponent.legalHistoryTitle',
        defaultMessage: 'Legal History'
    },
    memberFirstName: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICHouseholdMemberDetailsComponent.memberFirstName',
        defaultMessage: 'First Name'
    },
    memberMiddleName: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICHouseholdMemberDetailsComponent.memberMiddleName',
        defaultMessage: 'Middle Name'
    },
    memberLastName: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICHouseholdMemberDetailsComponent.memberLastName',
        defaultMessage: 'Last Name'
    },
    memberDateOfBirth: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICHouseholdMemberDetailsComponent.memberDateOfBirth',
        defaultMessage: 'Date of Birth'
    },
    householdMembersEstateOf: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICHouseholdMemberDetailsComponent.Estate of',
        defaultMessage: 'Estate of'
    },
    householdMemberOccupation: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICHouseholdMemberDetailsComponent.householdMemberOccupation',
        defaultMessage: 'Occupation'
    },
    householdMembersLast3Years: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICHouseholdMemberDetailsComponent.Has the household member experienced any losses in the last 3 years',
        defaultMessage: 'Has the household member experienced any losses in the last 3 years?'
    },
    householdMembersRelationship: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICHouseholdMemberDetailsComponent.Relationship',
        defaultMessage: 'Relationship to Primary Named Insured'
    },
    memberLicensedToDrive: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICHouseholdMemberDetailsComponent.memberLicensedToDrive',
        defaultMessage: 'Is this household member licenced to drive vehicles?'
    },
    memberLicenseNumber: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICHouseholdMemberDetailsComponent.memberLicenseNumber',
        defaultMessage: 'License Number'
    },
    memberProvinceLicensedIssued: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICHouseholdMemberDetailsComponent.memberProvinceLicensedIssued',
        defaultMessage: 'Province where license was issued'
    },
    memberLastMVRCapturedDate: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICHouseholdMemberDetailsComponent.memberLastMVRCapturedDate',
        defaultMessage: 'Date Last MVR Captured'
    },
    memberExcludedDriver: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICHouseholdMemberDetailsComponent.memberExcludedDriver',
        defaultMessage: 'Excluded Driver'
    },
    memberOtherDescription: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICHouseholdMemberDetailsComponent.memberOtherDescription',
        defaultMessage: 'Describe Other'
    },
    memberHasLegalHistory: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICHouseholdMemberDetailsComponent.memberHasLegalHistory',
        defaultMessage: 'Has the household member experienced any convictions, suspensions, prohibitions or restrictions?'
    },
    addIncident: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICHouseholdMemberDetailsComponent.addIncident',
        defaultMessage: 'Add Incident'
    },
    incidentMsg: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICHouseholdMemberDetailsComponent.incidentMsg',
        defaultMessage: '&lt;span&gt;At least &lt;b&gt;one incident&lt;/b&gt; must be added.&lt;/span&gt;'
    }
});