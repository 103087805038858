import { useContext } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import  messages from './WMICIMCoverageUtil.messages.js';

const generateColumnData = (submissionVM) => {
    const lobOfferingPath = 'lobData.inlandMarine.offerings';
    const quoteOfferingPath = 'quoteData.offeredQuotes';

    const lobOfferings = _.get(submissionVM, `${lobOfferingPath}.value`);
    const quoteOfferings = _.get(submissionVM, `${quoteOfferingPath}.value`) || [];

    const columnData = lobOfferings
        .map((lobOffering, lobIndex) => {
            const quoteDataIndex = quoteOfferings.findIndex(
                (qdOffering) => qdOffering.branchCode === lobOffering.branchCode
            );
            const quoteData = quoteOfferings[quoteDataIndex];
            return {
                name: lobOffering.branchName,
                code: lobOffering.branchCode,
                quote: {
                    path: `${quoteOfferingPath}.children[${quoteDataIndex}]`,
                    data: quoteData
                },
                lob: {
                    path: `${lobOfferingPath}.children[${lobIndex}]`,
                    data: lobOffering
                }
            };
        })
        .filter(({ quote }) => !_.isUndefined(quote.data));
    return _.sortBy(columnData, ['code']);
};

function getLineCoverages(inlandMarineLine) {
    const lineCoverages = inlandMarineLine.offerings.children[0].coverages.lineCoverages.value

    return lineCoverages ?? [];
}

function getToolsCoverages(inlandMarineLine) {
    const toolsCoverages = inlandMarineLine.offerings.children[0].coverages.toolsCoverages.value

    return toolsCoverages ?? [];
}

function getEquipmentCoverages(inlandMarineLine) {
    const equipmentCoverages = inlandMarineLine.offerings.children[0].coverages.equipmentCoverages.value

    return equipmentCoverages ?? [];
}

const categorizeLineCoverages = (lineCoverages, onlySelected = false) => {
    const translator = useContext(TranslatorContext);
    const allCoverages = {
        installation: {
            categoryName: translator(messages.installation) ,
            coverages: []
        },
        machinery: {
            categoryName: translator(messages.machinery) ,
            coverages: []
        },
        tools: {
            categoryName: translator(messages.tools) ,
            coverages: []
        },
    };

    const filteredCoverages = onlySelected? _.filter(lineCoverages, {'selected': true}): lineCoverages;

    filteredCoverages.forEach((coverage) => {
        switch (coverage.coverageUniqueID) {
            case 'CP_IMInstallationCov_WMIC':
                allCoverages.installation.coverages.push(coverage);
                break;
            case 'CP_ToolsBlanketCov_WMIC':
                allCoverages.tools.coverages.push(coverage);
                break;
            case 'CPIMMCEquipPartBlanketCov_WMIC':
                allCoverages.machinery.coverages.push(coverage);
                break;
            default:
                break;
        }
    });

    return allCoverages;
}

export default {
    generateColumnData,
    getLineCoverages,
    getToolsCoverages,
    getEquipmentCoverages,
    categorizeLineCoverages,
};
