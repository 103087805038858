import { defineMessages } from 'react-intl';

export default defineMessages({
    mainWaterShutOffType: {
        id: "wmic-pe-capability-gateway-common-ho-react.WaterValvesDetails.Main Water Valve Shut Off Type",
        defaultMessage: "Main Water Valve Shut Off Type"
    },
    mainWaterShutOffSensors: {
        id: "wmic-pe-capability-gateway-common-ho-react.WaterValvesDetails.Number of Main Valve Shut Off Sensors",
        defaultMessage: "Number of Main Valve Shut Off Sensors"
    },
    description: {
        id: "wmic-pe-capability-gateway-common-ho-react.WaterValvesDetails.Description",
        defaultMessage: "Description"
    },
});