/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { TranslatorContext } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WMICAddressDetails, WMICContactDetails } from 'wmic-pe-components-platform-react';


import metadata from './WMICMonthlyAutomatedFormComponent.metadata.json5';
import messages from './WMICMonthlyAutomatedFormComponent.messages';
import styles from './WMICMonthlyAutomatedFormComponent.module.scss';


function WMICMonthlyAutomatedFormComponent(props) {
    const { id, declinedPolicy, onValidate, submissionVM, updateWizardData } = props;
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const translator = useContext(TranslatorContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();

    const [candidates, updateCandidates] = useState([]);
    const [efts, updateEfts] = useState([]);
    const [invalidSearchError, updateInvalidSearchError] = useState(false);
    const [countOfFailedSearch, updateCountOfFailedSearch] = useState(0);
    const [showErrors, updateShowErrors] = useState(false);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid])    
    
    useEffect(() => {
        const candidatesList = [];
        const autoRecurringPayerList = _.get(submissionVM, 'bindData.autoRecurringPayerContactCandidates_WMIC.value', []);
        for (let counter = 0; counter < autoRecurringPayerList.length; counter++) {
            const  candidate = autoRecurringPayerList[counter].autoRecurringPayer_WMIC
             || autoRecurringPayerList[counter];
            candidate.previousEfts = autoRecurringPayerList[counter].previousEfts || [];
            candidatesList.push(candidate);
        }
        updateCandidates(candidatesList);

        _.set(submissionVM, "bindData.paymentDetails.selectedEftDataIndex.value", "-1");
        _.set(submissionVM, "bindData.paymentDetails.emailAddress.value", submissionVM.baseData.accountHolder.emailAddress1);
        _.set(submissionVM, "bindData.paymentDetails.recieveEmailReceipt.value", false);
        updateWizardData(submissionVM);
    }, [])

    const getFirstDateOfMonthValues = () => {
        const days = [];
        for (let day = 1; day <= 27; day++) {
            days.push({
                code: day,
                name: day,
            });
        }
        return days;
    };
    
    const getAutoRecurringPayerValues = () => {
        const candidatesList = [];
        for(let i = 0; i < candidates.length; i++) {
            candidatesList.push({
                code: i,
                name: candidates[i].displayName
            });
        }
        candidatesList.push({
            code: -1,
            name: translator(messages.newPerson)
        });
        candidatesList.push({
            code: -2,
            name: translator(messages.newCompany)
        });
        return candidatesList;
    };    
    
    const getBankingInformationValues = () => {
        const bankingInfoList = [];
        for(let i = 0; i < efts.length; i++) {
            bankingInfoList.push({
                code: i,
                name: efts[i].maskedBankAccountNumber
            });
        }
        bankingInfoList.push({
            code: -1,
            name: translator(messages.newBankInformation)
        });
        return bankingInfoList;
    };

    const onPaymentContactChange = (value, path) => {
        _.set(submissionVM, path, value);
        if(value > -1) {
            const candidate = candidates[value];
            _.set(submissionVM, "bindData.paymentDetails.eftData.accountHolder_WMIC.value", candidate.displayName);
            _.set(submissionVM, "bindData.paymentDetails.autoRecurringPayer_WMIC.value", candidate);
            const previousEfts =_.get(candidate, 'previousEfts', []);
            updateEfts(previousEfts);
            if(previousEfts && previousEfts.length > 0) {
                onEftDataChange('0','bindData.paymentDetails.selectedEftDataIndex', previousEfts[0]);
            } else {
                onEftDataChange('-1','bindData.paymentDetails.selectedEftDataIndex');
            }
        } else {
            updateEfts([]);
            onEftDataChange('-1','bindData.paymentDetails.selectedEftDataIndex');
            value === '-1' ? createPersonContact() : createCompanyContact();
        }
        updateWizardData(submissionVM);
    }    
    
    const onEftDataChange = (newValue, path, preselectedEft) => {
        _.set(submissionVM, path, newValue);
        if(newValue) {
            if(newValue > -1) {
                let eft = efts[newValue];
                if(_.isNil(eft) && preselectedEft) {
                    eft = preselectedEft
                }
                _.set(submissionVM, 'bindData.paymentDetails.eftData.publicID.value', eft.publicID);
                _.set(submissionVM, 'bindData.paymentDetails.eftData.institutionNumber_WMIC.value', eft.institutionNumber_WMIC);
                _.set(submissionVM, 'bindData.paymentDetails.eftData.transitNumber_WMIC.value', eft.transitNumber_WMIC);
                _.set(submissionVM, 'bindData.paymentDetails.eftData.bankAccountNumber.value', eft.maskedBankAccountNumber);
                _.set(submissionVM, 'bindData.paymentDetails.eftData.bankName.value', eft.bankName);
                _.set(submissionVM, 'bindData.paymentDetails.eftData.accountHolder_WMIC.value', eft.accountHolder_WMIC);
            } else {
                _.set(submissionVM, 'bindData.paymentDetails.eftData.publicID.value', undefined);
                _.set(submissionVM, 'bindData.paymentDetails.eftData.institutionNumber_WMIC.value', '');
                _.set(submissionVM, 'bindData.paymentDetails.eftData.transitNumber_WMIC.value', '');
                _.set(submissionVM, 'bindData.paymentDetails.eftData.bankAccountNumber.value', '');
                _.set(submissionVM, 'bindData.paymentDetails.eftData.bankName.value', '');

                const candidate = candidates[_.get(submissionVM, 'bindData.paymentDetails.selectedAutoRecurringPayerIndex.value')];
                if(candidate) {
                    _.set(submissionVM, 'bindData.paymentDetails.eftData.accountHolder_WMIC.value', candidate.displayName);
                }
            }
        }
        updateWizardData(submissionVM);
    }

    const fetchInstitutionName = () => {
        const transitNumber = _.get(submissionVM, 'bindData.paymentDetails.eftData.transitNumber_WMIC');
        const financialInstitutionNumber = _.get(submissionVM, 'bindData.paymentDetails.eftData.institutionNumber_WMIC');
        const transitNumberValue = transitNumber.value;
        const financialInstitutionNumberValue = financialInstitutionNumber.value;
        if (transitNumber.aspects.valid && financialInstitutionNumber.aspects.valid) {
            if (transitNumberValue.toString().match(/[0-9]/g).length === 5 &&
                financialInstitutionNumberValue.toString().match(/[0-9]/g).length === 3) {
                _.set(submissionVM, 'bindData.paymentDetails.eftData.bankName.value', null);
                const fetchInstitutionNamePromise = LoadSaveService.fetchInstitutionName(transitNumberValue, financialInstitutionNumberValue, authHeader);
                fetchInstitutionNamePromise.then((response) => {
                    _.set(submissionVM, 'bindData.paymentDetails.eftData.bankName.value', response);
                    updateWizardData(submissionVM);
                    updateCountOfFailedSearch(0);
                    updateInvalidSearchError(false);
                }).catch(() => {
                    updateCountOfFailedSearch(countOfFailedSearch + 1);
                    updateInvalidSearchError(true);
                });
            }
        }
    };

    const getFinancialInstitutionErrorMessage = () => {
        if (invalidSearchError) {
            if (countOfFailedSearch === 1) {
                return [translator(messages.fiNameNotFoundFirstError)];
            }
            return [translator(messages.fiNameNotFoundSecondError)];
        }
        return [];
    };

    const createPersonContact = () => {
        const today = new Date();

        const autoRecurringPayerValue = {
            subtype: 'Person',
            primaryPhoneType: 'mobile',
            contactName: '',
            primaryAddress: {
                country: 'CA',
                city: '',
                state: '',
                postalCode: '',
                addressType: 'mailing_wmic'
            },
        };
        _.set(submissionVM, 'bindData.paymentDetails.autoRecurringPayer_WMIC.value', autoRecurringPayerValue);
        updateWizardData(submissionVM);
    };

    const createCompanyContact = () => {
        const autoRecurringPayerValue = {
            subtype: 'Company',
            contactName: '',
            primaryAddress: {
                country: 'CA',
                city: '',
                state: '',
                postalCode: '',
                addressType: 'mailing_wmic'
            }
        }
        _.set(submissionVM, 'bindData.paymentDetails.autoRecurringPayer_WMIC.value', autoRecurringPayerValue);
        updateWizardData(submissionVM);
    };

    const setPrimaryAddress = (addressVM) => {
        _.set(submissionVM, 'bindData.paymentDetails.autoRecurringPayer_WMIC.primaryAddress', addressVM.value);
        updateWizardData(submissionVM);
    };
    
    const setAutoRecurringPayer = (contactVM) => {
        _.set(submissionVM, 'bindData.paymentDetails.autoRecurringPayer_WMIC', contactVM.value);
        updateWizardData(submissionVM);
    };

    const updateBankAccountNumber = (value) => {
        _.set(submissionVM, 'bindData.paymentDetails.eftData.bankAccountNumber', value?.trim());
        updateWizardData(submissionVM);
    };

    const overrideProps = {
        '@field': {
            parentNode: submissionVM,
        },
        firstDateOfMonth: {
            availableValues: getFirstDateOfMonthValues()
        },
        selectedAutoRecurringPayerIndex: {
            required: _.get(submissionVM, 'bindData.paymentDetails.autoRecurringPayer_WMIC.aspects.required', false),
            availableValues: getAutoRecurringPayerValues(),
            onValueChange: onPaymentContactChange,
        },      
        selectedEftDataIndex: {
            required: _.get(submissionVM, 'bindData.paymentDetails.eftData.aspects.required', false),
            availableValues: getBankingInformationValues(),
            onValueChange: onEftDataChange
        },
        bankName: {
            disabled: true,
            validationMessages: getFinancialInstitutionErrorMessage(),
            showErrors: invalidSearchError
        },
        createContactContainer: {
            visible: _.get(submissionVM, 'bindData.paymentDetails.selectedAutoRecurringPayerIndex.value') < 0,
        },
        personalInfoHeading: {
            visible: _.get(submissionVM, 'bindData.paymentDetails.selectedAutoRecurringPayerIndex.value') === '-1',
        },
        companyInfoHeading: {
            visible: _.get(submissionVM, 'bindData.paymentDetails.selectedAutoRecurringPayerIndex.value') === '-2',
        },
        personContactDetailsComponent: {
            contactVM: _.get(submissionVM, 'bindData.paymentDetails.autoRecurringPayer_WMIC'),
            setContactVM: setAutoRecurringPayer,
            hideBorder: true, 
            hideButtons: true
        },
        homeAddressHeading: {
            visible: _.get(submissionVM, 'bindData.paymentDetails.selectedAutoRecurringPayerIndex.value') === '-1',
        },
        businessAddressHeading: {
            visible: _.get(submissionVM, 'bindData.paymentDetails.selectedAutoRecurringPayerIndex.value') === '-2',
        },
        primaryAddressDetailsComponent: {
            addressVM: _.get(submissionVM, 'bindData.paymentDetails.autoRecurringPayer_WMIC.primaryAddress'),
            setAddressVM: setPrimaryAddress,
            hideBorder: true, 
            hideButtons: true
        },
        institutionNumber: {
            disabled: _.get(submissionVM, 'bindData.paymentDetails.selectedEftDataIndex.value') !== '-1'
        },
        transitNumber: {
            disabled: _.get(submissionVM, 'bindData.paymentDetails.selectedEftDataIndex.value') !== '-1'
        },
        bankAccountNumber: {
            disabled: _.get(submissionVM, 'bindData.paymentDetails.selectedEftDataIndex.value') !== '-1'
        },
        accountHolder: {
            disabled: _.get(submissionVM, 'bindData.paymentDetails.selectedEftDataIndex.value') !== '-1'
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            fetchInstitutionName,
            updateBankAccountNumber
        },
        resolveClassNameMap: styles,
        resolveComponentMap: {
            WMICAddressDetails,
            WMICContactDetails
        },
    };

    return (
        <ViewModelForm
            model={submissionVM}
            onModelChange={updateWizardData}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

export default WMICMonthlyAutomatedFormComponent;
