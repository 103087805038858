import React, { useState, useCallback, useEffect } from 'react';
import { ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { WMICZipMaskUtil } from 'wmic-pe-portals-utils-js';
import { useValidation } from '@xengage/gw-portals-validation-react'
import PropTypes from 'prop-types';
import _ from 'lodash';

import messages from './WMICPhoneDetails.messages.js'
import metadata from './WMICPhoneDetails.metadata.json5';

const WMICPhoneDetails = (props) => {
    const {
        value,
        setAccountVM,
        onSave,
        onCancel,
        readOnly,
        hideFields,
        hideBorder, 
        hideButtons
    } = props;

    const { isComponentValid, onValidate } = useValidation('WMICAddressDetails');
    const [showErrors, setShowErrors] = useState(false);
    const [moreOverrideProps, setMoreOverrideProps] = useState({});
    const { refreshData } = useDataRefresh();
    
    useEffect(() => {

    }, []);

    useEffect(() => {
        if(!_.isEmpty(hideFields)) {
            const hiddenOverrides = {};
            hideFields.forEach((fieldName) => {
                hiddenOverrides[fieldName] = { visible: false};
            });
            setMoreOverrideProps({...moreOverrideProps, ...hiddenOverrides});
        }
    }, [hideFields]);

    const updateAccountVM = useCallback(
        (data) => {
            refreshData();
            setAccountVM(data);
        },
        [refreshData, setAccountVM]
    );

    const swapZipPlaceHolder = useCallback(() => {
        const country = _.get(value, 'country.value');
        const postalCode = _.get(value, 'postalCode');
        return WMICZipMaskUtil.getZipMask(country, postalCode);
    }, [value]);
    
    const handleSaveAccount = () => {
        onSave();
    }

    const handleCancelAccount = () => {
        onCancel();
    }

    const handleValidation = () => {
        setShowErrors(true);
    }

    const handleChangeZip = (value, path) => {
        const upperCaseValue = value.toUpperCase();
        _.set(value, path, upperCaseValue);
        updateAccountVM(value);
    };

    const writeValue = (value, path) => {
        _.set(value, path, value);
        updateAccountVM(value);
    }

    const overrideProps = {
        '@field': {
            parentNode: value,
            readOnly
        },
        contactDetailsEditFormContainer: {
            hideBorder, 
            hideButtons
        },
        emailAddressMessageCheckbox:{
            value: _.isUndefined(_.get(value, "confirmEmailAddress.value"))
        },
        customerDeclinedMessageContainer: {
            visible: readOnly
        },
        ...moreOverrideProps
    }

    const resolvers = {
        resolveCallbackMap: {
            onSaveAccount: isComponentValid ? handleSaveAccount : handleValidation,
            onCancelAccount: handleCancelAccount,
            onChangeZip: handleChangeZip
        }
    }

    return (
        <ViewModelForm
            model={value}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            onModelChange={updateAccountVM}
            callbackMap={resolvers.resolveCallbackMap}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
}

WMICPhoneDetails.propTypes = {
    value: PropTypes.object.isRequired,
    setAccountVM: PropTypes.func,
    hideFields: PropTypes.arrayOf(PropTypes.string)
};

export default WMICPhoneDetails;