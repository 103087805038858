import { defineMessages } from 'react-intl';

export default defineMessages({
    vehicleInfo: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-details.vehicleInfo',
        defaultMessage: 'Vehicle Information'
    },
    vehicleUsage: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-details.vehicleUsage',
        defaultMessage: 'Usage'
    },
    vehicleOwnershipAssignment: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-details.vehicleOwnershipAssignment',
        defaultMessage: 'Ownership & Assignment'
    },
    vehicleAdditionalInfo: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-details.vehicleAdditionalInfo',
        defaultMessage: 'Additional Information'
    },
});
