import openPolicyTransactions from "./openPolicyTransactions";
import openSubmissions from "./openSubmissions";
import recentlyIssuedPolicies from "./recentlyIssuedPolicies";
import recentlyViewedPolicies from "./recentlyViewedPolicies";
import upcomingRenewals from './upcomingRenewals';

export const readOnlyUserTableData = [
    recentlyViewedPolicies,
    recentlyIssuedPolicies,
    upcomingRenewals,
];

export default [
    openSubmissions,
    openPolicyTransactions,
    recentlyViewedPolicies,
    recentlyIssuedPolicies,
    upcomingRenewals,
];