import { defineMessages } from 'react-intl';

export default defineMessages({
    scheduledItemType: {
        id: "policyView.ho.views.ho-scheduled-item.Item Type",
        defaultMessage: "Type"
    },
    scheduledItemDescription: {
        id: "policyView.ho.views.ho-scheduled-item.Item Description",
        defaultMessage: "Description"
    },
    scheduledItemLimit: {
        id: "policyView.ho.views.ho-scheduled-item.Item Limit",
        defaultMessage: "Limit"
    },
});