import React, { useCallback } from 'react';

import { pageTemplateProps } from '@xengage/gw-portals-wizard-react';
import { WMICWizardSingleErrorComponent } from 'wmic-pe-portals-wizard-components-ui';
import PolicyWizardPageHeader from '../../components/PolicyWizardPageHeader/PolicyWizardPageHeader';
import WMICPolicyWizardPageTemplate from '../WMICPolicyWizardPageTemplate';

import styles from './WMICPolicyWizardPageWithTitleTemplate.module.scss';

function WMICPolicyWizardPageWithTitleTemplate(props) {
    const {
        wizardData,
        children,
        acknowledgeError,
        errorsForStep,
        isSideHeader,
        customValidationMessages,
        ...otherProps
    } = props;

    const renderWizardHeader = useCallback(
        (wizardDataProp) => {
            const getSubmissionVM = wizardDataProp.submissionVM || wizardDataProp;
            const { isEditButtonEnabled, doesScheduledRenewalExist, currentUser } = wizardDataProp;
            return (
                <PolicyWizardPageHeader
                    wizardSubmission={getSubmissionVM}
                    isSideHeader={isSideHeader}
                    isEditButtonEnabled={isEditButtonEnabled}
                    doesScheduledRenewalExist={doesScheduledRenewalExist}
                    currentUser={currentUser}
                    setHasLapseStateChangedFlag={wizardData.setHasLapseStateChangedFlag}
                    hasLapseStateChangedFlag={wizardData.hasLapseStateChangedFlag}
                    policyPeriods={wizardData.policyPeriods}
                />
            );
        },
        [isSideHeader, wizardData.hasLapseStateChangedFlag, wizardData.policyPeriods, wizardData.setHasLapseStateChangedFlag]
    );

    return (
        <div className={styles.wizardContainer}>
            <WMICPolicyWizardPageTemplate
                renderContextComponent={renderWizardHeader}
                showContextComponent={false}
                wizardData={wizardData}
                showWizardHeader
                {...otherProps}
            >
                <WMICWizardSingleErrorComponent
                    acknowledgeError={acknowledgeError}
                    issuesList={errorsForStep}
                    customValidationMessages={customValidationMessages}
                />
                {children}
            </WMICPolicyWizardPageTemplate>
        </div>
    );
}

WMICPolicyWizardPageWithTitleTemplate.propTypes = pageTemplateProps;
export default WMICPolicyWizardPageWithTitleTemplate;
