import { defineMessages } from 'react-intl';

export default defineMessages({
    addAdditionalInterest: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-interest.AddAdditionalInterest',
        defaultMessage: 'Add Additional Interest'
    },
    aiPerson: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-interest.Person',
        defaultMessage: 'Person'
    },
    aiCompany: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-interest.Company',
        defaultMessage: 'Company'
    },
    additionalInterestName: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-interest.Additional Interest Name',
        defaultMessage: 'Name'
    },
    additionalInterestType: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-interest.Additional Interest Type',
        defaultMessage: 'Additional Interest Type'
    },
    certificateRequired: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-interest.CertificateRequired',
        defaultMessage: 'Certificate Required'
    },
    contractNumber: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-interest.ContractNumber',
        defaultMessage: 'Contract Number'
    },
    companyContactDetails: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-interest.CompanyContactDetails',
        defaultMessage: 'Company Contact Details'
    },
    removeAdditionalInterestShort: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-interest.Remove Interest Short',
        defaultMessage: 'Remove Additional Interest'
    },
    removeAdditionalInterestLong: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-interest.Remove Interest Long',
        defaultMessage: 'Are you sure you want to remove the additional interest from the list?'
    },
    aiPersonalInfoTitle: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-interest.Personal Info',
        defaultMessage: 'Personal Info'
    },
    aiMailingAddressEqualPNI: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-interest.Same Mailing Address',
        defaultMessage: 'Mailing Address same as Home Address of Primary Named Insured?'
    }

    
});
