import React, {
    useEffect,
    useCallback,
    useState,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { CONSTANTS, WMICDateTimeService, WMICJurisdictionUtil, WMICDriverUtil, JURISDICTIONS } from 'wmic-pe-portals-utils-js';

// eslint-disable-next-line no-unused-vars
import messages from './WMICDriverPersonalInfoComponent.messages';
import metadata from './WMICDriverPersonalInfoComponent.metadata.json5';

const today = new Date();
const maxDate = {
    year: today.getFullYear(),
    month: today.getMonth(),
    day: today.getDate()
}

function WMICDriverPersonalInfoComponent(props) {
    const {
        id,
        driverVM,
        baseData,
        updateDriver,
        onValidate,
        isEditMode,
        showErrors,
        isReadOnlyUser,
        accountHolder
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);

    const [toggle, flip] = useState(false);
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    const INSURANCE_SUITE_METADATA_PC_TYPES = viewModelService.productMetadata.get('pc').types;
    const NO_MVR_REASON_TYPELIST = INSURANCE_SUITE_METADATA_PC_TYPES.getTypelist('NoMVRReason_WMIC');
    const NO_MVR_REASON_RESTRICTED = NO_MVR_REASON_TYPELIST.getCode('Restricted');
    const NO_MVR_REASON_EXCLUDED = NO_MVR_REASON_TYPELIST.getCode('Excluded_WMIC');
    const NO_MVR_REASON_UNASSIGNED = NO_MVR_REASON_TYPELIST.getCode('Unassigned');

    const JURISDICTION_OTHER = INSURANCE_SUITE_METADATA_PC_TYPES
        .getTypelist('Jurisdiction')
        .getCode('OTHER_WMIC');

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [isComponentValid, onValidate, id]);

    useEffect(() => {
        const dob = _.get(driverVM, 'dateOfBirth.value');
        const ageAsOfPolicyExpiryDate = _.get(driverVM, 'ageAsOfPolicyExpiryDate_WMIC.value');
        dob && !ageAsOfPolicyExpiryDate && setDateDiff(dob);
    }, [driverVM, setDateDiff]);

    const setDateDiff = useCallback((dateObj) => {
        if (dateObj !== undefined) {
            const policyEnd = _.get(baseData, 'periodEndDate.value');
            const dob = new Date(dateObj.year, dateObj.month, dateObj.day);
            const policyEndDate = new Date(policyEnd.year, policyEnd.month, policyEnd.day);
            const dateDiff = new Date(policyEndDate.getTime() - dob.getTime());
            _.set(driverVM, `ageAsOfPolicyExpiryDate_WMIC.value`, dateDiff.getUTCFullYear() - 1970);
            updateDriver(driverVM);
        }
    }, [baseData, driverVM, updateDriver]);

    const isNamedDriver = useCallback(() => {
        if (driverVM.person) {
            if (baseData.primaryNamedInsured_WMIC.subtype.value === CONSTANTS.Person
                && baseData.primaryNamedInsured_WMIC.publicID.value === driverVM.person.publicID.value) {
                return true;
            }
            if (_.find(baseData.additionalNamedInsureds_WMIC.value, namedInsured => namedInsured.contactPublicID === driverVM.person.publicID.value || namedInsured.publicID === driverVM.person.publicID.value)) {
                return true;
            }
        }

        return false;
    }, [driverVM, baseData]);
    
    const updateDriverData = useCallback(
        (data) => {
            flip(!toggle);
            updateDriver(data);
        },
        [toggle, updateDriver]
    );

    const onDOBchange = (birthDate) => {
        setDateDiff(birthDate);
        _.set(driverVM, 'dateOfBirth.value', birthDate);
        WMICDriverUtil.resetGoodStudentDiscountIfNeeded(driverVM, baseData);
        updateDriver(driverVM);
    }

    const onExcludedReasonChanged = (value, path) => {
        _.set(driverVM, path, value);
        _.set(driverVM, 'exclOtherReason_WMIC.value', undefined);
        updateDriver(driverVM);
    }

    const onDriverAssignmentChanged = (value, path) => {
        _.set(driverVM, path, value);
        _.set(driverVM, 'nonLicensedResident_WMIC.value', undefined);
        _.set(driverVM, 'residentOwnsVehicle_WMIC.value', undefined);
        _.set(driverVM, 'restrictedReason_WMIC.value', undefined);
        _.set(driverVM, 'restrictedOtherReason_WMIC.value', undefined);
        _.set(driverVM, 'restrictedDate_WMIC.value', undefined);
        _.set(driverVM, 'exclDriverReasons_WMIC.value', undefined);
        _.set(driverVM, 'exclOtherReason_WMIC.value', undefined);
        _.set(driverVM, 'driverClassHistory_WMIC.value', []);
        _.set(driverVM, 'licenseCountry.value', undefined);
        _.set(driverVM, 'getStateLicThirtyDay_wmic.value', undefined);
        _.set(driverVM, 'licenseState.value', undefined);
        _.set(driverVM, 'isABResidentLessThan3Yr_WMIC.value', undefined);
        _.set(driverVM, 'licenseExpiryDate_WMIC.value', undefined);
        _.set(driverVM, 'internationalPermitNumber_WMIC.value', undefined);
        _.set(driverVM, 'internationalPermitExpiryDate_WMIC.value', undefined);
        _.set(driverVM, 'experienceLetterReceived_WMIC.value', undefined);
        _.set(driverVM, 'experienceLetterDate_WMIC.value', undefined);
        _.set(driverVM, 'outsideLicCopyReceived_WMIC.value', undefined);
        _.set(driverVM, 'outsideRecordReceived_WMIC.value', undefined);
        _.set(driverVM, 'priorGridStep_WMIC.value', undefined);
        _.set(driverVM, 'gridRelevantDate_WMIC.value', undefined);
        _.set(driverVM, 'hasDriverCourse_wmic.value', undefined);
        _.set(driverVM, 'dateCompleteTrainingClass_wmic.value', undefined);
        _.set(driverVM, 'certificateIssuer_WMIC.value', undefined);
        _.set(driverVM, 'certificateIssuerDescribe_WMIC.value', undefined);
        _.set(driverVM, 'hasMotorCycleCourse_WMIC.value', undefined);
        _.set(driverVM, 'motorcycleCourseDate_WMIC.value', undefined);
        _.set(driverVM, 'hasDriverBeenSuspend_wmic.value', undefined);
        _.set(driverVM, 'isCurrentlyRestricted_WMIC.value', undefined);
        _.set(driverVM, 'convictedOfAutoFraud_WMIC.value', undefined);
        _.set(driverVM, 'fraudConvictionDate_WMIC.value', undefined);
        _.set(driverVM, 'uSCanadaYearsLicensed_WMIC.value', undefined);
        _.set(driverVM, 'excludedSince_WMIC.value', undefined);

        const driverAssignment = _.get(driverVM, 'assignment_WMIC.value.code');
        const primaryAddressState = _.get(accountHolder, 'person.primaryAddress.state', undefined);
        const prevInsurances = _.get(driverVM, 'policyHistory_WMIC.previousInsurances.value', [])

        switch (driverAssignment) {
            case CONSTANTS.ASSIGNMENT.RESTRICTED:
                _.set(driverVM, 'orderMVR_WMIC.value', false);
                _.set(driverVM, 'noMVRReason_WMIC.value', NO_MVR_REASON_RESTRICTED);
                _.set(driverVM, 'licenseState.value', JURISDICTION_OTHER);
                break;
            case CONSTANTS.ASSIGNMENT.EXCLUDED:
                _.set(driverVM, 'orderMVR_WMIC.value', false);
                _.set(driverVM, 'noMVRReason_WMIC.value', NO_MVR_REASON_EXCLUDED);
                _.set(driverVM, 'licenseState.value', primaryAddressState);
                break;
            case CONSTANTS.ASSIGNMENT.UNASSIGNED:
                _.set(driverVM, 'orderMVR_WMIC.value', false);
                _.set(driverVM, 'noMVRReason_WMIC.value', NO_MVR_REASON_UNASSIGNED);
                _.set(driverVM, 'licenseState.value', JURISDICTION_OTHER);
                break;
            default:
                _.set(driverVM, 'orderMVR_WMIC.value', undefined);
                _.set(driverVM, 'noMVRReason_WMIC.value', undefined);
                break;
        }

        WMICDriverUtil.removeNewlyAddedMvrs(driverVM);
        _.set(driverVM, 'doNotOrderMVRDetails_WMIC.value', undefined);

        _.each(prevInsurances, (prevInsurance) => {
            prevInsurance.isAssignedDriver = WMICDriverUtil.isAssignedDriver(driverVM);
        });

        updateDriver(driverVM);
    }

    const onRestrictedReasonChanged = (value, path) => {
        _.set(driverVM, path, value);
        _.set(driverVM, 'restrictedOtherReason_WMIC.value', undefined);
        updateDriver(driverVM);
    }

    const onEducationReasonChanged = (value, path) => {
        _.set(driverVM, path, value);
        _.set(driverVM, 'educationDesc_WMIC.value', undefined);
        updateDriver(driverVM);
    }

    const onOccupationReasonChanged = (value, path) => {
        _.set(driverVM, path, value);
        _.set(driverVM, 'occupationDesc_WMIC.value', undefined);
        updateDriver(driverVM);
    }

    const onUpdateDriverName = (value, path) => {
        _.set(driverVM, path, value);
        // Removing _WMIC because middleName has that prefix on the
        // Driver but NOT on the Person
        _.set(driverVM, `person.${path.replace('_WMIC', '')}`, value);
        updateDriver(driverVM);
    }

    const resolvers = {
        resolveCallbackMap: {
            onExcludedReasonChanged,
            onDriverAssignmentChanged,
            onRestrictedReasonChanged,
            onEducationReasonChanged,
            onOccupationReasonChanged,
            onUpdateDriverName
        }
    };

    const overrideProps = {
        '@field': {
            parentNode: driverVM,
            readOnly: !isEditMode || isReadOnlyUser
        },
        ageAsOfPolicyExpiryDate: {
            readOnly: true
        },
        qualifiesAsGoodDriver: {
            readOnly: true
        },
        dateOfBirth: {
            maxDate: WMICDateTimeService.getMaxDOB(),
            minDate: WMICDateTimeService.getMinDOB(),
            onValueChange: (value) => onDOBchange(value),
            value: _.get(driverVM, 'dateOfBirth.value')
        },
        creditConsentContainer: {
            visible: WMICJurisdictionUtil.isBaseState(baseData, JURISDICTIONS.QUEBEC)
        },
        restrictedDate: {
            maxDate
        },
        excludedSince: {
            maxDate
        },
        notNamedDriverContainer: {
            visible: !isNamedDriver()
        },
        namedDriverContainer: {
            visible: isNamedDriver()
        },
        driverFirstName: {
            readOnly: !_.get(driverVM, 'firstName.aspects.editable') || !isEditMode || isReadOnlyUser
        },
        driverMiddleName: {
            readOnly: !_.get(driverVM, 'middleName_WMIC.aspects.editable') || !isEditMode || isReadOnlyUser
        },
        driverLastName: {
            readOnly: !_.get(driverVM, 'lastName.aspects.editable') || !isEditMode || isReadOnlyUser
        },
        goodStudentContainer: {
            visible: WMICDriverUtil.isDriverEligibleForGoodStudentDiscount(baseData, driverVM)
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={driverVM}
            overrideProps={overrideProps}
            onModelChange={updateDriverData}
            callbackMap={resolvers.resolveCallbackMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

WMICDriverPersonalInfoComponent.propTypes = {
    id: PropTypes.string.isRequired,
    driverVM: PropTypes.shape({
        person: PropTypes.shape({
            publicID: PropTypes.shape({})
        })
    }).isRequired,
    baseData: PropTypes.shape({}).isRequired,
    updateDriver: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    showErrors: PropTypes.bool.isRequired,
    isReadOnlyUser: PropTypes.bool.isRequired,
};

export default WMICDriverPersonalInfoComponent;
