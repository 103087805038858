import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { JobType } from 'wmic-pe-portals-utils-js';
import { ReadOnlyCoverageTable } from 'wmic-pe-capability-quoteandbind-common-react';
import WMICIterableLocationComponent from "../WMICIterableLocationComponent/WMICIterableLocationComponent";
import metadata from './WMICLocationInfo.metadata.json5';
import messages from './WMICLocationInfo.messages';

function WMICLocationInfo(props) {
    const { cpDataVM, jobVM } = props;

    if (_.isEmpty(cpDataVM)) {
        return null;
    }

    const getLocationCoverages = (cpData, locationPublicID) => {
        const locationCoverages = cpDataVM.offerings.children[0].coverages.locationCoverages.children.find(
            (locationCoverage) => {
                return locationCoverage.value.publicID === locationPublicID;
            }
        );

        return locationCoverages ? locationCoverages.coverages : [];
    }

    const isPolicyChangeOrRenewal = _.get(jobVM, 'baseData.jobType.value.code') === JobType.POLICY_CHANGE;

    const _iterableProps = () => {
        const _locationOverrides = _.get(cpDataVM, 'coverables.locations.children').map((location, index) => {
            const _location = _.get(cpDataVM, `coverables.locations.children[${index}]`);
            const _buildings = _.get(cpDataVM, `coverables.locations.children[${index}].buildings`);
            const _locationCoverages = getLocationCoverages(cpDataVM, _location.publicID.value);

            const _locationOverride = {
                [`iterableLocationComponent${index}`]: {
                    jobVM: cpDataVM,
                    location: _location,
                    locationCoverages: _locationCoverages,
                    rateAsOfDate: cpDataVM.rateAsOfDate,
                    buildings: _buildings,
                    isPolicyChangeOrRenewal,
                    submissionVM: jobVM
                }
            };
            return Object.assign({}, _locationOverride);
        })

        return Object.assign({}, ..._locationOverrides);
    }

    function getCell(items, index, property) {
        return !_.isObject(items[property.id]) ? items[property.id] : null;
    }


    const overrides = {
        locationTableIterableContainer: {
            data: cpDataVM.coverables.locations.children,
        },
        ..._iterableProps()
    };

    const resolvers = {
        resolveCallbackMap: {
            getCellCoverage: getCell
        },
        resolveComponentMap: {
            WMICIterableLocationComponent,
            ReadOnlyCoverageTable
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={cpDataVM}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

WMICLocationInfo.propTypes = {
    value: PropTypes.shape({}).isRequired
};

export default WMICLocationInfo;
