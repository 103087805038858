import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { WatercraftContext } from 'wmic-pe-capability-gateway-quoteandbind-ho-react';
import { WMICDateTimeService, WMICRPCUtil } from 'wmic-pe-portals-utils-js';
import WMICHOConstants from 'wmic-pe-portals-utils-js/constants/WMICHOConstants';
import _ from 'lodash';
import metadata from './WMICHOBoatInfoDetails.metadata.json5';
import messages from './WMICHOBoatInfoDetails.messages.js'

function WMICHOBoatInfoDetails({ 
    id,
    boatData,
    onValidate,
    showErrors,
    boatTypeReadOnly,
    readOnly,
    baseData
}) {
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const [filteredBoatTypes, setFilteredBoatTypes] = useState();
    const viewModelService = useContext(ViewModelServiceContext);
    const { updateWatercraftRiskView } = useContext(WatercraftContext);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        // if component is unmounted, report as valid so it doesn't break the rest of the form
        return () => {
            if (onValidate) {
                onValidate(true, id);
            }
        }
    }, [id, onValidate, isComponentValid])

    useEffect(() => {
        const rateAsOfDate = WMICDateTimeService.localDate2Date(_.get(baseData, "rateAsOfDate.value"))
        const isRPC1554Effective = WMICRPCUtil.getIsRPCEffective(_.get(baseData, "baseState.value.code"), rateAsOfDate, '1554');
        const _boatTypes = _.get(boatData, "boatType.aspects.availableValues")
        const _filteredBoatTypes = isRPC1554Effective
            ? _boatTypes.filter((boatType) => WMICHOConstants.limitedWatercraftTypes.includes(boatType.code))
            : _boatTypes.filter((boatType) => boatType.code !== WMICHOConstants.personalWatercraft);

        const boatTypeKeys = _filteredBoatTypes.map((boatTypeKey) => {
            return {
                ...boatTypeKey,
                name: {
                    id: boatTypeKey.name,
                    defaultMessage: boatTypeKey.name
                }
            }
        });

        setFilteredBoatTypes(boatTypeKeys)
    }, [boatData, viewModelService.productMetadata, baseData])

    const overrideProps = {
        '@field': {
            readOnly,
            parentNode: boatData,
        },
        boatInfoBoatTypeSelect: {
            readOnly: readOnly || boatTypeReadOnly,
            ...(!(readOnly || boatTypeReadOnly) && { availableValues: filteredBoatTypes})
        },
    };


    return (
        <ViewModelForm
            model={boatData}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            onModelChange={updateWatercraftRiskView}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
};

WMICHOBoatInfoDetails.propTypes = {
    boatInfo: PropTypes.shape({}).isRequired,
    boatTypeReadOnly: PropTypes.bool,
    readOnly: PropTypes.bool,
};

WMICHOBoatInfoDetails.defaultProps = {
    boatTypeReadOnly: false,
    readOnly: false
};

export default WMICHOBoatInfoDetails;
