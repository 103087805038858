const _dtosNotToBeChecked = [
    'edge.time.LocalDateDTO',
    'wmic.edge.common.capabilities.currency.dto.AmountDTO',
    'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.dwelling.additionaldetails.RevenuePropertyQuestionnaireDTO_WMIC'
];

const _defaultedClasses = [
    'wmic.edge.ca.capabilities.policyjob.lob.personalauto.draft.dto.AccidentWaiverEndorsement_WMICDTO',
    'wmic.edge.ca.capabilities.policyjob.lob.AdditionalInterestDTO_WMIC',
    'wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.dto.VehicleAdditionalInterestsDTO_WMIC',
    'wmic.edge.ca.capabilities.policyjob.lob.common.draft.dto.insurancehistory.WawanesaPolicy_WMICDTO'
];

const propertiesNotToBeChecked = [
    'SelectedDriverPerVeh',
    'SelectedDriver'
];

const _propertiesToBeAutoReviewed = [
  'certificateIssuerDescribe_WMIC'
];

const checkDto = (nodeVM, info, checkSubtree, condition, checkDefaultedClasses, haltAtFirstOccurrence) => {
    const res = condition(nodeVM);

    if (haltAtFirstOccurrence && res) {
        return;
    }

    info.properties.forEach((property) => {
        if (nodeVM[property.jsProperty]) {
            checkSubtree(nodeVM[property.jsProperty], condition, checkDefaultedClasses, haltAtFirstOccurrence);
        }
    });
};

const checkArray = (node, condition, checkDefaultedClasses, haltAtFirstOccurrence) => {
    for (let i = 0; i < node.length; i++) {
        const element = node.getElement(i);

        switch (element._metadataInfo.valueType.kind) {
            case 'class':
            case 'collection':
                if (!element._metadataInfo.valueType.typeInfo.metaType.isTypelist) {
                    checkSubtree(element, condition, checkDefaultedClasses, haltAtFirstOccurrence);
                }
                break;
            default:
                break;
        }
    }
};

/**
 * Traverses a nodeVM and checks the condition against every node in the subtree
 * @param {object} nodeVM the root node
 * @param {function} condition the function to run for every node
 * @param {boolean} checkDefaultedClasses if true it checkes the defaulted classes
 * @param {boolean} haltAtFirstOccurrence if true it stops traversing the node when the condition returns true
 */
const checkSubtree = (nodeVM, condition, checkDefaultedClasses = false, haltAtFirstOccurrence = false) => {
    if (nodeVM?._metadataInfo && !nodeVM._metadataInfo.readOnly) {
        switch (nodeVM._metadataInfo.valueType.kind) {
            case 'class':
                const typeInfo = nodeVM._metadataInfo.valueType.typeInfo;

                if (typeInfo.metaType.isDto
                    && !_dtosNotToBeChecked.includes(typeInfo.typeName)
                    && (checkDefaultedClasses || !_defaultedClasses.includes(typeInfo.typeName))
                    && !propertiesNotToBeChecked.includes(nodeVM._metadataInfo.step.property)) {
                    checkDto(nodeVM, typeInfo, checkSubtree, condition, checkDefaultedClasses, haltAtFirstOccurrence);
                }
                break;
            case 'collection':
                checkArray(nodeVM, condition, checkDefaultedClasses, haltAtFirstOccurrence);
                break;
            default:
                break;
        }
    }
}

export default {
    checkSubtree,
    dtosNotToBeChecked: _dtosNotToBeChecked,
    propertiesToBeAutoReviewed: _propertiesToBeAutoReviewed
}
