import { defineMessages } from 'react-intl';

export default defineMessages({
    wmicPaQualificationTitle: {
        id: 'quoteandbind.pa.views.pa-qualifications.qualificationHeader',
        defaultMessage: 'Qualifications'
    },
    wmicPaReturnLater: {
        id: 'quoteandbind.pa.views.pa-qualifications.returnLater',
        defaultMessage: 'Return Later'
    }
});
