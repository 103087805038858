import React, { useState, useEffect, useContext } from 'react';
import {
    ModalNext,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '@jutro/components';

import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICButton } from 'wmic-pe-components-platform-react';
import { WMICProgressModal } from 'wmic-pe-capability-quoteandbind-common-react';
import { TranslatorContext } from '@jutro/locale';
import { useFlexAndBrokerRecalculate } from 'wmic-pe-capability-gateway-common-react';
import _ from 'lodash';

import messages from './WMICBrokerFeesModal.messages';
import metadata from './WMICBrokerFeesModal.metadata.json5';
import styles from './WMICBrokerFeesModal.module.scss';

const  WMICBrokerFeesModal = ({
    isOpen,
    onResolve,
    onReject,
    modalVM,
    authHeader,
    viewModelService,
    updateJobVM
}) => {
    const [ isChecked, setIsChecked ] = useState(_.get(modalVM, "baseData.waiveBrokerFee_WMIC.value", false));
    const [ isDirty, setIsDirty ] = useState(false);
    const [ isFetching, setIsFetching ] = useState(false);
    const translator = useContext(TranslatorContext);
    const { saveAndRecalculate, quoteID } = useFlexAndBrokerRecalculate(modalVM);

    useEffect(() => {
        setIsChecked(_.get(modalVM, "baseData.waiveBrokerFee_WMIC.value", false));
    }, [modalVM]);

    async function handleRecalc() {
        try {
            setIsFetching(true);

            const response = await saveAndRecalculate(
                quoteID,
                [],
                isChecked,
                authHeader
            )

            const newModalVM = viewModelService.clone(modalVM);
            _.extend(newModalVM, response);
            updateJobVM(newModalVM);
            onResolve();
        } catch(err) {
            onReject({
                error: err,
                quoteID,
                jobType: _.get(modalVM, 'baseData.jobType.value.code')
            });
        } finally {
            setIsFetching(false)
        }
    }

    const overrideProps = {
        '@field': {
            parentNode: modalVM
        },
        waveBrokerFees: {
            onValueChange: (value) => {
                setIsChecked(value)
                setIsDirty(true)
            },
            value: isChecked
        }
    };

    if (isFetching) {
        return <WMICProgressModal
            modalTitle={translator(messages.brokerSaving)}
            isOpen={isFetching}
        />
    }

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={onResolve}
            className={styles.brokerFeesModal}
        >
            <ModalHeader
                icon='gw-info-outline'
                title={messages.brokerFees}
                status='info'
                onClose={onResolve}
                ariaLabel={messages.closeBtnLabel}
            />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={modalVM}
                />
            </ModalBody>
            <ModalFooter className={styles.flexModalFooter}>
                <WMICButton type='primary-outlined' onClick={onResolve}>
                    {messages.cancelBtnLabel}
                </WMICButton>
                <WMICButton type='primary' onClick={handleRecalc} disabled={!isDirty}>
                    {messages.recalculateBtnLabel}
                </WMICButton>
            </ModalFooter>
        </ModalNext>
    )
}

export default WMICBrokerFeesModal;
