import { defineMessages } from 'react-intl';

export default defineMessages({
    personAddressHeading: {
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyWizard.components.WMICPolicyPaDriversDetailsView.personAddressHeading',
        defaultMessage: "Home Address"
    },
    companyAddressHeading: {
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyWizard.components.WMICPolicyPaDriversDetailsView.companyAddressHeading',
        defaultMessage: "Business Address"
    }
});