import { defineMessages } from 'react-intl';

export default defineMessages({
    MVROrdered: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.mvr-ordered',
        defaultMessage: 'MVR ordered?'
    },
    MVRReasonNotOrdered: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.reason-for-not-mvr',
        defaultMessage: 'Reason for not receiving the MVR?'
    },
    MVRDescription: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.description',
        defaultMessage: 'Description'
    },
    MVRPrimaryResidence: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.primary-residence-address',
        defaultMessage: 'MVR address matches Primary Residence address?'
    },
    MVRReasonNotMatch: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.reason-address-not-match',
        defaultMessage: 'Reason MVR address does not match?'
    },
    MVRDescribeOther: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.describe-other',
        defaultMessage: 'Describe other'
    },
    MVRViolationSuspensions: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.violations-suspensions',
        defaultMessage: 'MVR and Violations/Suspensions'
    },
    MVRMinimum: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.mvr-minimum',
        defaultMessage: '&lt;span&gt;Add a minimum of &lt;b&gt;one MVR&lt;/b&gt;&lt;/span&gt;'
    },
    MVRMoreAccurateQuote: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.more-accurate-quote',
        defaultMessage: 'To obtain a more accurate quote, add any violations or suspensions.  MVR is required to bind.'
    },
    MVRDate: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.date',
        defaultMessage: 'Date'
    },
    MVRType: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.type',
        defaultMessage: 'Type'
    },
    MVRPredictorScore: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.predictor-score',
        defaultMessage: 'Predictor Score'
    },
    MVRViolationsSuspensions: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.violations-suspensions-table',
        defaultMessage: 'Violations / Suspensions'
    },
    MVRAdd: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.add',
        defaultMessage: 'Add'
    },
    No: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.no',
        defaultMessage: 'No'
    },
    Yes: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.yes',
        defaultMessage: 'Yes'
    },

    RemovingMVRConfirm: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.RemovingMVRConfirm',
        defaultMessage: 'Are you sure you want to remove the MVR from the list?'
    },
    RemovingMVR: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.RemovingMVR',
        defaultMessage: 'Remove MVR'
    },
    yesIncidentLength: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.yesIncidentLength',
        defaultMessage: 'Yes ({ incidents })'
    },
    ChangeMVROrderNoConfirmationMsg: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.ChangeMVROrderNoConfirmationMsg',
        defaultMessage:'Changing the answer to "No" will remove all information for this report. Do you want to continue?'
    },
    ChangeMVROrderNo: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.ChangeMVROrderNo',
        defaultMessage:'MVR Ordered'
    },
    DuplicateMessage: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.DuplicateConviction',
        defaultMessage: 'Duplicate conviction {desc}, {date}'
    },
    MVRNotRequired: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.MVRNotRequired',
        defaultMessage: '&lt;span&gt;MVR is &lt;b&gt;not required&lt;/b&gt; for Foreign License or International Permit;&lt;/span&gt;'
    }
})