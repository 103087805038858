import { defineMessages } from 'react-intl';

export default defineMessages({
    number: {
        id: 'quoteandbind.cp.views.LocationsBuildings.LocationDetailView.Number',
        defaultMessage: '#',
    },
    name: {
        id: 'quoteandbind.cp.views.LocationsBuildings.LocationDetailView.Name',
        defaultMessage: 'Name',
    },
    locationTitle: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Location',
        defaultMessage: 'Location',
    },
    locationNumber: {
        id: 'quoteandbind.cp.views.LocationsBuildings.LocationDetailView.Location Number',
        defaultMessage: 'Location #',
    },
    removeBuildingLong: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Remove Building Long',
        defaultMessage: 'Are you sure you want to remove Building #{displayName} from the list?'
    },
    removeBuildingShort: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Remove Building Short',
        defaultMessage: 'Remove Building'
    },
    buildingHeading: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Building Heading',
        defaultMessage: 'Buildings'
    },
    addBuilding: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Add Building',
        defaultMessage: 'Add Building'
    },
    buildingNumber: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Building Number',
        defaultMessage: 'Building #{displayName}'
    },
    atLeastOneBuilding: {
        id: "quoteandbind.cp.views.LocationsBuildings.AtleastOneBuilding",
        defaultMessage: "&lt;span&gt;At least &lt;b&gt;one Building&lt;/b&gt; is required&lt;/span&gt;"
    },
    primaryBuilding: {
        id: 'quoteandbind.cp.views.LocationsBuildings.PrimaryBuilding',
        defaultMessage: 'Primary building',
    },
});
