import { defineMessages } from 'react-intl';

export default defineMessages({
    installation: {
        id: 'wmic.pe-capability-quoteandbind-gl-react.util.installation',
        defaultMessage: 'Installation'
    },
    machinery: {
        id: 'wmic.pe-capability-quoteandbind-gl-react.util.machinery',
        defaultMessage: 'Machinery and Equipment Blanket'
    },
    tools: {
        id: 'wmic.pe-capability-quoteandbind-gl-react.util.tools',
        defaultMessage: 'Tools Blanket'
    },
});
