import React, { useState } from 'react';
import { RadioButtonField } from './WMICRadioButtonField/RadioButtonField.jsx';
import cx from 'classnames';
import styles from './WMICCustomRadioButton.module.scss';

const WMICCustomRadioButton = (props) => {
    const { className, labelClassName } = props;
    const [ visited, setVisited ] = useState(false);

    return (
        <div 
            onBlur={() => setVisited(false)}
            onFocus={() => {
                document.getElementById(`${props.id  }hiddenField`).nextElementSibling.getElementsByTagName('input')[0].focus();
                setVisited(true);
            }}
        >
            <button className={styles.hiddenButton} id={`${props.id  }hiddenField`} tabIndex={visited? "-1": "0"}/>
            <RadioButtonField
                {...props}
                className={cx(styles.inputToggle, className)}
                labelClassName={cx(styles.label, labelClassName)}
            />
        </div>    
    );
};

WMICCustomRadioButton.propTypes = {
    ...RadioButtonField.propTypes
};

export default WMICCustomRadioButton;
