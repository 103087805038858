import { defineMessages } from 'react-intl';

export default defineMessages({
    materialType: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICAddExteriorWallComponent.Material Type",
        defaultMessage: "Material Type"
    },
    exteriorWallDescription: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICAddExteriorWallComponent.ExteriorWalls.Description",
        defaultMessage: "Description"
    },
    exteriorWallPercentage: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICAddExteriorWallComponent.Percentage",
        defaultMessage: "Percentage"
    },
    exteriorWallNumber: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICAddExteriorWallComponent.Number",
        defaultMessage: "Number"
    },
    describe: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICAddExteriorWallComponent.Describe Other",
        defaultMessage: "Describe"
    }
})
