import { defineMessages } from 'react-intl';

export default defineMessages({
    causeOfLoss: { 
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyLossHistoryPayout.causeOfLoss',
        defaultMessage: 'Cause of Loss'
    },
    payoutStatus: {
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyLossHistoryPayout.payoutStatus',
        defaultMessage: 'Status'
    },
    claimOpenReserves: { 
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyLossHistoryPayout.claimOpenReserves',
        defaultMessage: 'Remaining Reserves'
    },
    lossTotalPaid: { 
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyLossHistoryPayout.lossTotalPaid',
        defaultMessage: 'Paid Amount'
    },
    payoutReservesInformation: {
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyLossHistoryPayout.payoutReservesInformation',
        defaultMessage: 'Payout Reserves Information'
    }
});