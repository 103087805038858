import { defineMessages } from 'react-intl';

export default defineMessages({
    coveragesTitle: {
        id: 'wmic-pe-capability-gateway-policyrenewal-pa-react.WMICPAPolicyRenewalCoveragesPage.Coverages',
        defaultMessage: 'Coverages'
    },
    thirdPartyLiabilityWarning: {
        id: 'wmic-pe-capability-gateway-policyrenewal-pa-react.WMICPAPolicyRenewalCoveragesPage.Third Party Liability Applies to All vehicles',
        defaultMessage: 'Third party liability limits must be the same for all vehicles. Any change will update all other vehicles'
    }
});
