import { defineMessages } from 'react-intl';

export default defineMessages({

    dtoErrorKey: {
        id: 'agent.util.ErrorHandler.DTO Error',
        defaultMessage: 'The page you are trying to save contains an incorrectly formatted entry.  Please amend the entry details and click save.'
    },
    timeoutKey: {
        id: 'agent.util.ErrorHandler.Time Out',
        defaultMessage: 'A connectivity issue has caused the server to stop responding.'
    },
    serverErrorKey: {
        id: 'agent.util.ErrorHandler.Server error',
        defaultMessage: 'An issue occurred with the server.'
    },
    authenticationKey: {
        id: 'agent.util.ErrorHandler.authentication',
        defaultMessage: 'authentication'
    },
    technicalErrorKey: {
        id: 'agent.util.ErrorHandler.The page you are trying to save contains errors. Please try again or contact an underwriter for assistance.',
        defaultMessage: 'The page you are trying to save contains errors. Please try again or contact an underwriter for assistance.'
    },
    lockedErrorKey: {
        id: 'agent.util.ErrorHandler.lockedErrorKey',
        defaultMessage: 'Locked: Submission modified by underwriter. Please contact the commercial team to discuss any updates.'
    },
    paymentPlanErrorKey: {
        id: 'agent.directives.PaymentPlanChange.Error',
        defaultMessage: 'Payment Plan Change failed'
    },
    paymentPlanExistsErrorKey: {
        id: 'agent.directives.PaymentPlanChange.Error.Bank record already exists with this information',
        defaultMessage: 'Payment Plan Change Failed. Unable to make modification - a bank record already exists with this information.'
    },
    invalidSubmissionPostalCodeErrorKey: {
        id: 'agent.util.ErrorHandler.Postal Code error',
        defaultMessage: 'An invalid postal code is present on the associated account. Please edit the postal code to access this submission.'
    },
    negativeInvoiceStreamErrorKey: {
        id: 'agent.util.ErrorHandler.Negative Invoice Stream Error',
        defaultMessage: 'The payment plan cannot be changed at this time. Please submit all past due payments before making this change.'
    },
});
