import React from 'react';
import { useTranslator } from '@jutro/locale';

import headerLogo from 'wmic-qb-digital-theme-styles/images/Wawanesa_Insurance_blue.svg';
import messages from './WMICCustomHeader.messages';

function WMICCustomHeader() {
    const translator = useTranslator();
    return (
        <header className="ww-header">
            <div className="container ww-header-top">
                <h1 className="ww-logo">
                    <a href="https://wawanesa.com" className="wmicDisplayBlock">
                        <img alt={translator(messages.headerLogoAlt)} src={headerLogo} className="ww-portal-logo" />
                    </a>
                </h1>
            </div>
            <nav id="ww-nav">
                <div className="container">
                    <div className="ww-nav-bar" />
                </div>
            </nav>
        </header>
    );
}

export default WMICCustomHeader;
