import React, { useCallback, useContext, useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { TranslatorContext } from '@jutro/locale';
import { ViewModelServiceContext, ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { UISupportingInfoLookupService } from 'wmic-pe-capability-supportinginfo';
import { useWizardModals, useAccordionValidation, useDocumentTitle, WMICWizardSubmissionPage  } from 'wmic-pe-portals-wizard-components-ui';
import { WMICBrokerInfo, messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import { WMICInsuranceHistoryPoliciesComponent } from 'wmic-pe-capability-gateway-common-react';
import { WMICHOLossHistory, WMICHOCreditConsent } from 'wmic-pe-capability-gateway-common-ho-react';
import { WMICValidationUtil, WMICCreditConsentUtil, WMICLogger, FlowStepId, WMICVariousUtil } from 'wmic-pe-portals-utils-js';
import { WMICScrollToError } from 'wmic-pe-components-platform-react';
import { WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';

import metadata from './WMICHOInsuranceHistoryPage.metadata.json5';
import messages from './WMICHOInsuranceHistoryPage.messages';

const INSURANCE_HISTORY_PATH = 'lobData.homeowners.coverables.insuranceHistory_WMIC';
const BROKER_INFO_PATH = 'lobData.homeowners.coverables.insuranceHistory_WMIC.brokerInfo';

function WMICHOInsuranceHistoryPage(props) {
    const { wizardData: submissionVM, updateWizardData } = props;
    const { isComponentValid, onValidate, initialValidation } = useValidation('WMICHOInsuranceHistoryPage');
    const { onValidateAccordion, isAccordionValid } = useAccordionValidation(onValidate);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData } = useAuthentication();
    const { setWizardLoading } = useWizardModals();
    const { refreshData } = useDataRefresh();
    const [showErrors, setShowErrors] = useState(false);
    const [scrollToError, setScrollToError] = useState();
    const [carriersList, setCarrierList] = useState(false);
    const [insuranceHistoryVM, setInsuranceHistoryVM] = useState(_.get(submissionVM, "lobData.homeowners.coverables.insuranceHistory_WMIC"));
    const [insuranceHistoryPageDetails, setInsuranceHistoryPageDetails] = useState();
    const [loading, setLoading] = useState(true);
    const baseData = _.get(submissionVM, 'baseData');
    const lob = _.get(submissionVM, 'lob.value.code');

    useDocumentTitle("Insurance History", submissionVM);

    const isHOCreditConsentTabVisible = useMemo(() => {
        WMICCreditConsentUtil.isHOCreditConsentTabVisibleOnInsuranceHistory(new Date(baseData.rateAsOfDate.value), _.get(baseData, 'additionalNamedInsureds_WMIC.value'));
    }, [baseData]);

    useEffect(() => {
        if (isComponentValid) {
            setShowErrors(false);
        }
    }, [isComponentValid]);

    useEffect(() => {
        // Keeping this lookup here to reduce network payload size for performance
        UISupportingInfoLookupService.retrieveInsuranceCarriersList([baseData.baseState.value.code, baseData.periodStartDate.value], true, authHeader)
            .then((carriers) => {
                const updatedCarriers = carriers.map((item) => ({
                    ...item,
                    name: item.carrier,
                    code: item.carrier,
                }))

                setCarrierList(updatedCarriers);
            });

        const insuranceHistoryTmp = _.get(submissionVM, INSURANCE_HISTORY_PATH);

        // Create Prior Policy Info VM
        if (!insuranceHistoryTmp.priorPolicyInfo.value) {
            const newPriorPolicyInfo = viewModelService.create(
                {},
                'pc',
                'wmic.edge.ca.capabilities.policyjob.lob.common.draft.dto.insurancehistory.PriorPolicyInfo_WMICDTO',
                submissionVM.aspects.context()
            );

            _.set(insuranceHistoryTmp, 'priorPolicyInfo', newPriorPolicyInfo.value);
        }

        // Make sure we have a firstPolicyDate
        if (!insuranceHistoryTmp.priorPolicyInfo.firstPolicyDate.value) {
            _.set(insuranceHistoryTmp, 'priorPolicyInfo.firstPolicyDate',  submissionVM.baseData.periodStartDate.value)
        }

        // Create Prior Losses Array
        if (!insuranceHistoryTmp.priorLosses.value) {
            _.set(insuranceHistoryTmp, 'priorLosses', []);
        }

        // Create Broker Info VM
        let brokerInfo = _.get(submissionVM, BROKER_INFO_PATH);

        if (!brokerInfo.value) {
            brokerInfo = createBrokerInfo();

            _.set(insuranceHistoryTmp, 'brokerInfo', brokerInfo.value)
        }

        setInsuranceHistoryPageDetails(insuranceHistoryTmp);

        const currentInsuranceHistory = _.get(submissionVM, INSURANCE_HISTORY_PATH);

        if (!_.isEqual(currentInsuranceHistory, insuranceHistoryTmp)) {
            _.set(submissionVM, INSURANCE_HISTORY_PATH, insuranceHistoryTmp);
            updateWizardData(submissionVM);
        }

        setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        submissionVM.value.flowStepId_WMIC = FlowStepId.HO_INSURANCE_HISTORY;
    }, [submissionVM.value]);

    /**
     * Update submission and then navigate to quote summary screen
     */
    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            setScrollToError(Date.now());
            setShowErrors(true);
            return false;
        }

        try {
            setWizardLoading(true);

            const newSubmissionVM = viewModelService.clone(submissionVM);

            _.unset(newSubmissionVM.value, 'bindData');

            submissionVM.value = await LoadSaveService.updateDraftSubmissionWithLOBData(newSubmissionVM.value, authHeader)

            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.HO_INSURANCE_HISTORY)) {
                WMICVariousUtil.scrollToTop();
                return false;
            }

            return submissionVM;
        } catch (error) {
            WMICErrorHandler.processAsModal(error);

            return submissionVM;
        } finally {
            setWizardLoading(false);
        }
    }, [LoadSaveService, authHeader, isComponentValid, setWizardLoading, submissionVM, viewModelService]);


    const updateInsuranceHistoryVM = (historyVM) => {
        refreshData();
        setInsuranceHistoryVM(historyVM);

        const currentInsuranceHistory = _.get(submissionVM, "lobData.homeowners.coverables.insuranceHistory_WMIC.value");

        if (!_.isEqual(currentInsuranceHistory, historyVM.value)) {
            _.set(submissionVM, "lobData.homeowners.coverables.insuranceHistory_WMIC.value", historyVM.value);
            updateWizardData(submissionVM);
        }
    }

    const updateBrokerInfo = (brokerInfoVM) => {
        _.set(insuranceHistoryVM, 'brokerInfo.value', brokerInfoVM.value);
        updateInsuranceHistoryVM(insuranceHistoryVM);
    }

    const createBrokerInfo = () => {
        const brokerInfo  = viewModelService.create(
            {},
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.common.draft.dto.insurancehistory.BrokerInfo_WMICDTO'
        );

        return brokerInfo;
    }

    const createPolicyInfoVM = () => viewModelService.create(
            {},
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.common.draft.dto.insurancehistory.PriorPolicyInfo_WMICDTO',
            submissionVM.aspects.context()
        );

    const initInsuranceHistory = () => {
        const insuranceHistoryTmp = _.get(submissionVM, INSURANCE_HISTORY_PATH);
        const priorPolicyInfo = _.get(insuranceHistoryTmp, 'priorPolicyInfo');

        _.set(insuranceHistoryTmp, 'isAddingPriorLoss', false);
        _.set(insuranceHistoryTmp, 'isAddingOtherInsuranceCoPolicyRecord', false);
        _.set(insuranceHistoryTmp, 'isAddingOtherWawanesaPolicyRecord', false);
        _.set(insuranceHistoryTmp, 'isAddingDeclinedPolicyRecord', false);

        // creating VMs for priorPolicyInfo, priorLosses, brokerInfo will be below
        if (!priorPolicyInfo?.value) {
            const policyInfoVM = createPolicyInfoVM();

            _.set(insuranceHistoryTmp, 'priorPolicyInfo', policyInfoVM.value);
        }

        // Create Prior Losses Array
        if (!insuranceHistoryTmp.priorLosses.value) {
            _.set(insuranceHistoryTmp, 'priorLosses', []);
        }

        let brokerInfo = _.get(submissionVM, BROKER_INFO_PATH);

        if (!brokerInfo.value) {
            brokerInfo = createBrokerInfo();

            _.set(insuranceHistoryTmp, 'brokerInfo', brokerInfo.value)
        }

        _.set(submissionVM, INSURANCE_HISTORY_PATH, insuranceHistoryTmp);
        setInsuranceHistoryPageDetails(insuranceHistoryTmp);
        setLoading(false);

        updateInsuranceHistoryVM(insuranceHistoryTmp);
    }

    const onSaveInsuranceHistory = useCallback(async () => {
        if (!isComponentValid) {
            setScrollToError(Date.now());
            setShowErrors(true);
            return;
        }

        try {
            setShowErrors(false);
            setWizardLoading(true, 'Saving')

            submissionVM.value = await LoadSaveService.saveAndValidate_WMIC(
                submissionVM.value,
                authHeader
            );

            initInsuranceHistory();
            updateWizardData(submissionVM);

            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.HO_INSURANCE_HISTORY)) {
                WMICVariousUtil.scrollToTop();
            }

        } catch (err) {
            WMICLogger.error('Save Insurance History failed', err);
        } finally {
            setWizardLoading(false);
        }

    }, [submissionVM, translator, isComponentValid]);

    const commonAccordionProps = {
        showErrors,
    }
    // props for the accordion components
    const commonAccordionContentProps = {
        showErrors,
        onValidate: onValidateAccordion,
        jobVM: submissionVM,
        updateWizardData,
        authHeader,
        baseData,
        updateHistory: updateInsuranceHistoryVM,
        insuranceHistoryVM,
    };

    const overrideProps = {
        insuranceHistoryContainerButtons: {
            onSave: onSaveInsuranceHistory,
            saveLabel: translator(messages.saveInsuranceHistory),
        },
        policiesAccordion: {
            ...commonAccordionProps,
            isValid: isAccordionValid('policiesAccordionContent')
        },
        policiesAccordionContent: {
            ...commonAccordionContentProps,
            carriersList,
            jobVM: submissionVM
        },
        lossHistoryAccordion: {
            ...commonAccordionProps,
            isValid: isAccordionValid('lossHistoryAccordionContent')
        },
        lossHistoryAccordionContent: {
            ...commonAccordionContentProps,
            updateHistory: updateInsuranceHistoryVM,
            insuranceHistoryVM,
            carriersList
        },
        creditConsentAccordion: {
            ...commonAccordionProps,
            isValid: isAccordionValid('creditConsentAccordionContent'),
            visible: isHOCreditConsentTabVisible
        },
        creditConsentAccordionContent: {
            ...commonAccordionContentProps
        },
        brokerAccordion: {
            ...commonAccordionProps,
            isValid: isAccordionValid('brokerInfo')
        },
        brokerInfo: {
            ...commonAccordionContentProps,
            updateBrokerInfo,
            brokerInfo: _.get(insuranceHistoryPageDetails, 'brokerInfo'),
            authUserData,
            lob
        }
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap:{
            WMICInsuranceHistoryPoliciesComponent,
            WMICHOLossHistory,
            WMICHOCreditConsent,
            WMICBrokerInfo
        }
    };

    if (loading) {
        return null;
    }

    return (
        <WMICWizardSubmissionPage
            onNext={onNext}
            skipWhen={initialValidation}
            cancelLabel={translator(commonMessages.saveAndExit)}
            flowStepId={FlowStepId.HO_INSURANCE_HISTORY}
        >
            <WMICScrollToError counter={scrollToError} />
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                componentMap={resolvers.resolveComponentMap}
            />
        </WMICWizardSubmissionPage>
    );
}

WMICHOInsuranceHistoryPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    ...wizardProps
};

export default WMICHOInsuranceHistoryPage;
