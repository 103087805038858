import { defineMessages } from 'react-intl';

export default defineMessages({
    watercraftType: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOBoatInfoDetails.Watercraft Type',
        defaultMessage: 'Watercraft Type'
    },
    watercraftDescription: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOBoatInfoDetails.Description',
        defaultMessage: 'Description'
    },
    maxSpeedofWatercraft: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOBoatInfoDetails.Max Speed',
        defaultMessage: 'Max Speed of Watercraft (MPH)'
    },
    totalHorsepower: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOBoatInfoDetails.Total Horsepower',
        defaultMessage: 'Total Horsepower'
    },
    watercraftLength: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOBoatInfoDetails.Length',
        defaultMessage: 'Length (ft.)'
    },
    watercraftUse: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOBoatInfoDetails.Use',
        defaultMessage: 'Use'
    },
    marineSurveyReceived: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOBoatInfoDetails.Is Marine Survey Received',
        defaultMessage: 'Marine survey received?'
    },
    homemadeOrKitBoat: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOBoatInfoDetails.Home Made or Kit Boat',
        defaultMessage: 'Homemade or Kit Boat?'
    },
    cookingFacilities: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOBoatInfoDetails.Has Cooking Facilities',
        defaultMessage: 'Cooking Facilities'
    },
    principalOperatorDateOfBirths: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOBoatInfoDetails.Principal Operator Date Of Birth',
        defaultMessage: 'Principal Operator Date Of Birth'
    },
    hullConstruction: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOBoatInfoDetails.Hull Construction',
        defaultMessage: 'Hull Construction'
    },
    navigationPeriod: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOBoatInfoDetails.Navigation Period',
        defaultMessage: 'Navigation Period'
    },
    auxilaryInboardPower: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOBoatInfoDetails.Auxilary Inboard Power',
        defaultMessage: 'Auxilary Inboard Power'
    },
    watercraftValue: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOBoatInfoDetails.Value',
        defaultMessage: 'Value'
    }
})