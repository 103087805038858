import { defineMessages } from 'react-intl';

export default defineMessages({
    header: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.liability-coverages-table.rating-information.header',
        defaultMessage: 'Liability & Policy Level Coverages Summary'
    },
    description: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.liability-coverages-table.rating-information.discription',
        defaultMessage: 'Description'
    },
    cyber: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.liability-coverages-table.rating-information.cyber',
        defaultMessage: 'Cyber - Data Compromise Response Expenses'
    },
    crimeTitle: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.liability-coverages-table.rating-information.crimeTitle',
        defaultMessage: 'Crime:'
    },
    legalExpense: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.liability-coverages-table.rating-information.legalExpense',
        defaultMessage: 'Legal Expense'
    },
    catchAllClause: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.liability-coverages-table.rating-information.catchAllClause',
        defaultMessage: 'Enhancements including 25% Blanket By-Laws and Catch All Clause'
    },
    seeQuoteDocument: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.liability-coverages-table.rating-information.seeQuoteDocument',
        defaultMessage: 'See Quote Document'
    },
});
