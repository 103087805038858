import { defineMessages } from 'react-intl';

export default defineMessages({
    riskHeader: {
        id: 'policyView.ho.views.ho-your-home.RiskNo',
        defaultMessage: 'Risk #'
    },
    dwellingUseHeader: {
        id: 'policyView.ho.views.ho-your-home.Dwelling Use',
        defaultMessage: 'Dwelling Use'
    },
    addressHeader: {
        id: 'policyView.ho.views.ho-your-home.Address',
        defaultMessage: 'Address'
    },
    riskTypeHeader: {
        id: 'policyView.ho.views.ho-your-home.Risk Type',
        defaultMessage: 'Risk Type'
    },
    riskDescription: {
        id: 'policyView.ho.views.ho-your-home.Risk Description',
        defaultMessage: 'Description'
    },
    dwelingRisksHeading: {
        id: 'policyView.ho.views.ho-your-home.Dwelling Risks',
        defaultMessage: 'Dwelling Risks'
    },
    propertyDetailsAccordion: {
        id: 'policyview.ho.views.ho-your-home.Property Details',
        defaultMessage: 'Property Details'
    },
    additionalDetailsAccordion: {
        id: 'policyview.ho.views.ho-your-home.Additional Details',
        defaultMessage: 'Additional Details'
    },
    protectionDetailsAccordion: {
        id: 'policyview.ho.views.ho-your-home.Protection Details',
        defaultMessage: 'Protection Details'
    },
    dwellingAdditionalInterestsAccordion: {
        id: 'policyview.ho.views.ho-your-home.Additional Interests',
        defaultMessage: 'Additional Interests'
    },
    watercraftRisk: {
        id: 'policyview.ho.views.ho-your-home.Watercraft Risks',
        defaultMessage: 'Watercraft Risks'
    },
    watercraftDetailsAccordion: {
        id: 'policyview.ho.views.ho-your-home.Watercraft Details',
        defaultMessage: 'Watercraft Details',
    },
    watercraftAdditionalInterestsAccordion: {
        id: 'policyview.ho.views.ho-your-home.Additional Interests',
        defaultMessage: 'Additional Interests'
    }
});