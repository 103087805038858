import React, { useContext, useMemo, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { WizardPage, WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { CONSTANTS } from 'wmic-pe-portals-utils-js';
import _ from 'lodash'
import { TranslatorContext } from '@jutro/locale';
import messages from '../Wizard/common/WMICWizardMessages/WMICWizardMessages.messages';

const FUNC_ALWAYS_TRUE = () => true;
const WMICQuotePageRegex = /^(WMIC).*(QuotePage)$/;
let jumpedToFirst = false;

function WMICWizardSubmissionPage({ children, ...props }) {
    const { skipWhen : pSkipWhen, ...wizardProps } = props;
    const { steps, currentStep, currentStepIndex, jumpTo, isSkipping, wizardData : jobVM } = useContext(WizardContext);

    const history = useHistory();

    // eslint-disable-next-line no-secrets/no-secrets
    const isBMSI = _.get(jobVM, "isFromBMS_WMIC.value");
    const isLocked = _.get(jobVM, "isLocked.value");
    const quoteID = _.get(jobVM, "quoteID.value");
    const hasDefaultedPropertiesToReview = _.get(jobVM, "defaultedProperties_WMIC.value")?.filter((p) => p.propertyDisplayName && p.status !== CONSTANTS.REVIEWED).length > 0;

    const numberOfSteps = steps?.length || 0;
    const isLastPage = numberOfSteps-1 === currentStepIndex;
    const isStepBeforeQuotePage = (!isLastPage && isBMSI && (WMICQuotePageRegex.test(steps[currentStepIndex + 1].id)));
    const isStepQuotePage = (!isLastPage && isBMSI && (WMICQuotePageRegex.test(steps[currentStepIndex].id)));
    const translator = useContext(TranslatorContext);

    const backToFirst = useMemo(() => {
        if (isBMSI && isStepBeforeQuotePage && isSkipping && !jumpedToFirst && hasDefaultedPropertiesToReview) {
            return true;
        }

        return false;
    }, [isStepBeforeQuotePage, isSkipping, isBMSI, hasDefaultedPropertiesToReview])

    useEffect(() => {
        if (isLocked) {
            history.push(`/quotes/${quoteID}/summary`);
        }

        if (backToFirst) {
            jumpedToFirst = true;
            jumpTo(0);
        }
    }, [backToFirst, jumpTo, isLocked, quoteID, isStepBeforeQuotePage, history]);

    const skipWhenFunction = () => {
        if (isBMSI && hasDefaultedPropertiesToReview) {
            return isStepBeforeQuotePage || jumpedToFirst ? undefined : FUNC_ALWAYS_TRUE;
        }

        return _.isUndefined(pSkipWhen) ? undefined : pSkipWhen
    }

    return (
        <WizardPage
            skipWhen={skipWhenFunction()}
            previousLabel={translator(messages.back)}
            // Note that we still allow parent component to pass props down to the wizard page. These will take
            // precedence and override the defaults above.
            {...wizardProps}
        >
            {children}
        </WizardPage>
    )
}

export default WMICWizardSubmissionPage;
