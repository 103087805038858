import { LINE_OF_BUSINESS } from 'wmic-pe-portals-utils-js';

let currentLOB;

export default class WMICEDocsService {

    static init(lineOfBusiness) {
        currentLOB = Object.values(LINE_OF_BUSINESS).find((x)=> x === lineOfBusiness);

        if (!currentLOB) {
            throw new Error(`Incorrect line of business: ${lineOfBusiness}`);
        }
    }

    static needConfirmationEmail(EPOIEnrolled, EDeliveryEnrolled) {
        if(currentLOB === LINE_OF_BUSINESS.PERSONAL_AUTO){
            return (EPOIEnrolled || Boolean(EDeliveryEnrolled));
        }
        return !!EDeliveryEnrolled;
        
    }
}