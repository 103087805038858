import { defineMessages } from 'react-intl';

export default defineMessages({
    consentFor: {
        id: 'quoteandbind.views.credit-consent.ConsentFor',
        defaultMessage: 'Credit Consent for &lt;b&gt;{name}&lt;/b&gt;'
    },
    dateOfBirthFor: {
        id: 'quoteandbind.credit-consent.DateOfBirthFor',
        defaultMessage: 'Date of birth for &lt;b&gt;{name}&lt;/b&gt;'
    },
    creditConsentReceivedFrom: {
        id: 'quoteandbind.ho.views.insuranceHistory.Credit Consent Received From',
        defaultMessage: "Credit consent received from &lt;b&gt;{name}&lt;/b&gt;?"
    }
})
