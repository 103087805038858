import { defineMessages } from 'react-intl';

export default defineMessages({
    mvrTabHeading: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr',
        defaultMessage: 'MVR'
    },
    mvrQCTabHeading: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.violations-suspensions',
        defaultMessage: 'Violations / Suspensions'
    },
    personalInfoTabHeading: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personalInfo',
        defaultMessage: 'Personal Info'
    },
    licenseInfoTabHeading: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.licenseInfo',
        defaultMessage: 'License Info'
    },
    policyHistoryTabHeading: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.policyHistory',
        defaultMessage: 'Policy History'
    },
    commercialDriversTabHeading: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.commercialDrivers',
        defaultMessage: 'Commercial Drivers'
    },
    driveChangeTelematicsTabHeading: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.DriveChangeTelematics',
        defaultMessage: 'Drive Change (Telematics)'
    }
});
