import React, { useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { ModalNextContext } from '@jutro/components';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';

import { WMICLink } from 'wmic-pe-components-platform-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';
import { WMICLogger } from 'wmic-pe-portals-utils-js';
import WMICBrokerFeesModal from './WMICBrokerFeesModal/WMICBrokerFeesModal';
import messages from './WMICBrokerFeesComponent.messages';


const WMICBrokerFeesComponent = ({ jobVM, updateJobVM }) => {
    const { showModal } = useContext(ModalNextContext);
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const { setWizardLoading } = useWizardModals();

    const onClickFn = async () => {
        try {
            await showModal(
                <WMICBrokerFeesModal modalVM={jobVM} viewModelService={viewModelService} authHeader={authHeader} setWizardLoading={setWizardLoading} updateJobVM={updateJobVM} />
            );
        } catch({error, quoteID, jobType}) {
            WMICErrorHandler.processAsModal(error, quoteID, jobType);
            WMICLogger.error('Error in Broker Fee handleRecalc', error);
        }
    };

    return (
        <WMICLink
            type='text'
            icon='mi-open-in-browser'
            className="gw-ml-5"
            onClick={onClickFn}
        >
            {translator(messages.brokerFees)}
        </WMICLink>
    )
}

export default WMICBrokerFeesComponent;
