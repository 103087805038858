/* eslint-disable no-param-reassign */
/* eslint-disable no-secrets/no-secrets */
import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import _ from "lodash";
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { TranslatorContext } from '@jutro/locale';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { WMICWizardViewPage, useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { WMICLogger, SUITES, TRANSACTION_STATUS, PENDING_TRANSACTION_STATUS, RENEWAL_DISPLAY_STATUSES, WMICRichTextUtil, CONSTANTS, PRODUCT } from 'wmic-pe-portals-utils-js';
import { BillingService } from 'wmic-pe-capability-gateway-billing';
import { GatewayDocumentService } from 'wmic-pe-capability-gateway-document';
import WMICResendDocumentsModal from './WMICResendDocumentsModal/WMICResendDocumentsModal';
import metadata from './WMICPolicySummaryPage.metadata.json5';
import styles from './WMICPolicySummaryPage.module.scss';
import messages from './WMICPolicySummaryPage.messages';

const AGENCYBILL_CODE = 'AgencyBill';

function WMICPolicySummaryPage(props) {

    const {
        wizardData: { policyVM, renewalTerm, policyBillingSummaryVM }
    } = props;

    const location = useLocation();
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const { EndorsementService } = useDependencies('EndorsementService');
    const { showSuccess, showError, showCustom } = useWizardModals();
    const translator = useContext(TranslatorContext);

    const BROKERBILL_CODE = viewModelService.productMetadata.get('pc').types.getTypelist('BillingMethod').getCode('AgencyBill').code
    const isBrokerBillPolicy = _.get(policyBillingSummaryVM, "billingMethod.value.code") === BROKERBILL_CODE;
    const canceled = _.get(policyVM, "latestPeriod.canceled.value");
    const isDelinquent = _.get(policyBillingSummaryVM, "isDelinquent.value", false);
    const delinquencyNoticeDate = _.get(policyBillingSummaryVM, "delinquencyNoticeDate.value");
    const isEPOIEnrolled = _.get(policyVM, "lobs.personalAuto.epoienrolled.value", false);
    const isBrokerOwned = _.get(policyVM, "isBrokerOwned_WMIC.value", false);
    const isEDeliveryEnrolled = _.get(policyVM, "edeliveryEnrolled.value", false);
    const displayResendButton = isEPOIEnrolled || (!isBrokerOwned && isEDeliveryEnrolled);
    const showPolicyDocument = isEDeliveryEnrolled && !_.isEmpty(_.get(policyVM, "edeliveryDocument_WMIC.value"));
    const isFRCApplicable = _.get(policyVM, "latestPeriod.isFRCApplicable.value", false);
    const policyNumber = _.get(policyVM, 'policyNumber.value');
    const selectedTerm = parseInt(_.get(location, 'state.termNumber', '1'), 10);
    const transactions = _.get(policyVM, 'jobs.value', []);

    const [policyWording, updatePolicyWording] = useState();
    const [documentsLoaded, updateDocumentsLoaded] = useState(false);
    const [billingDocumentVisible, updateBillingDocumentVisible] = useState(false);
    const [policyDeclaration, updatePolicyDeclaration] = useState();
    const [identificationCard, updateIdentificationCard] = useState();
    const [billingDocument, updateBillingDocument] = useState();

    const pendingTransactions = useMemo(() => transactions.filter(
        (transaction) => PENDING_TRANSACTION_STATUS.includes(transaction.periodStatus) && transaction.periodStatus !== TRANSACTION_STATUS.RENEWING
    ), [transactions])

    const hasCanceling = useMemo(() => transactions.some((transaction) => (
        [TRANSACTION_STATUS.CANCELING, TRANSACTION_STATUS.CANCELING_FR].includes(transaction.status)
            && transaction.isBluePass
            && transaction.termNumber === selectedTerm
    )), [selectedTerm, transactions]);

    const handleBillingDocument = (document) => {
        if (!_.isEmpty(document)) {
            document.link = getDocumentDownloadLink(document, SUITES.BC);
            updateBillingDocument(document);
            updateBillingDocumentVisible(true);
            updateDocumentsLoaded(true);
        }
    }

    const handleCancellationMemoForm = (forms) => {
        const cancellationMemoForm = _.find(forms, (form) => form.formNumber === CONSTANTS.FORM_TYPES.CANCELLATION_MEMO);
        if (cancellationMemoForm && cancellationMemoForm.hasContent) {
            updateDocumentsLoaded(true);
        }
    }

    const handlePolicyDeclarationForm = (forms) => {
        const policyDeclarationForm = _.find(forms, (form) => [
            CONSTANTS.FORM_TYPES.PERSONAL_AUTO_POLICY_DECLARATION,
            CONSTANTS.FORM_TYPES.HOMEOWNERS_POLICY_DECLARATION,
            CONSTANTS.FORM_TYPES.PERSONAL_UMBRELLA_POLICY_DECLARATION,
            CONSTANTS.FORM_TYPES.COMMERCIAL_PACKAGE_POLICY_DECLARATION
        ].includes(form.formNumber));

        if (policyDeclarationForm && policyDeclarationForm.hasContent) {
            policyDeclarationForm.document.link = getDocumentDownloadLink(policyDeclarationForm.document, SUITES.PC);
            updatePolicyDeclaration(policyDeclarationForm.document);
        }
    }

    const handleIdentificationCardForm = (forms) => {
        const identificationCardForm = _.find(forms, (form) => form.formNumber === CONSTANTS.FORM_TYPES.IDENTIFICATION_CARD);
        if (identificationCardForm && identificationCardForm.hasContent) {
            identificationCardForm.document.link = getDocumentDownloadLink(identificationCardForm.document, SUITES.PC);
            updateIdentificationCard(identificationCardForm.document);
        }
    }

    const handlePolicyWordingDoc = () => {
        if (showPolicyDocument) {
            const policyWordingDoc = _.get(policyVM, "edeliveryDocument_WMIC.value");
            if (policyWordingDoc) {
                policyWordingDoc.link = getDocumentDownloadLink(policyWordingDoc, SUITES.PC);
                updatePolicyWording(policyWordingDoc);
            }
        }
    }

    useEffect(() => {
        const billingMethod = _.get(policyBillingSummaryVM, 'billingMethod.value.code');

        if (billingMethod !== AGENCYBILL_CODE) {
            const accountNumber = _.get(policyVM, 'account.accountNumber.value');

            BillingService.getDocumentForEDelivery(accountNumber, policyNumber, authHeader)
                .then(handleBillingDocument)
                .catch((err) => {
                    WMICLogger.error('WMICPolicySummaryPage getDocumentForEDelivery', err);
                });

            const forms = _.get(policyVM, "forms_WMIC.value", []);
            if (canceled) {
                handleCancellationMemoForm(forms);
            } else {
                handlePolicyDeclarationForm(forms);
                const productCode = _.get(policyVM, "product.productCode.value");
                if(productCode === PRODUCT.PERSONAL_AUTO) {
                    handleIdentificationCardForm(forms, productCode);
                }

                handlePolicyWordingDoc();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showResendDocumentsDialog = () => {
        showCustom(
            <WMICResendDocumentsModal
                policyVM={policyVM}
                authHeader={authHeader}
                isBrokerOwned={isBrokerOwned}
                isEDeliveryEnrolled={isEDeliveryEnrolled}
                isEPOIEnrolled={isEPOIEnrolled}
                productCode={_.get(policyVM, "product.productCode.value")}
                viewModelService={viewModelService}
            />
        ).catch(() => {
            _.noop();
        });
    };

    const getDocumentDownloadLink = (document, suite) => {
        const { publicID, sessionID } = document;
        const link = GatewayDocumentService.downloadDocument(publicID, sessionID, suite);

        return link;
    }

    const sendFRCDocuments = async () => {
        await EndorsementService.sendFRCDocuments(policyNumber, authHeader).then(async () => {
            showSuccess({
                title: translator(messages.sendFRCDocument),
                message: translator(messages.sendFRCDocumentSuccessful)
            });
        }).catch(async () => {
            showError({
                title: translator(commonMessages.genericError),
                message: translator(messages.sendFRCDocumentFailed)
            });
        });
    }

    const getPolicyStatus = () => {
        const latestPeriod = _.get(policyVM, "latestPeriod.value");

        if (!latestPeriod) {
            return '';
        }

        const policyDisplayStatus = _.get(latestPeriod, 'displayStatus');
        if (![CONSTANTS.IN_FORCE, CONSTANTS.IN_FORCE_FR].includes(policyDisplayStatus.toLowerCase()) || !latestPeriod.preRenewalDirectionExists) {
            return policyDisplayStatus;
        }

        return latestPeriod.brokerRequestedPreRenewalDirectionExists
            ? translator(messages.setToLapse, { policyDisplayStatus })
            : translator(messages.renewalUnderReview, { policyDisplayStatus });
    }

    const showRenewalMessage = useMemo(() => {
        const isWithinRenewalTime = !!renewalTerm
            && selectedTerm !== renewalTerm.termNumber
            && renewalTerm.timeUntilRenewalIssued > 0
            && renewalTerm.timeUntilRenewalIssued < 10;

        const hasValidRenewalStatus = Object.values(RENEWAL_DISPLAY_STATUSES).includes(renewalTerm?.renewalDisplayStatus);

        return isWithinRenewalTime && hasValidRenewalStatus;
    }, [renewalTerm, selectedTerm])

    const renewalMessageContent = useMemo(() => {
        if (renewalTerm?.renewalDisplayStatus === RENEWAL_DISPLAY_STATUSES.PENDING_UNDERWRITER_REVIEW) {
            return messages.renewalPendingReviewMessage
        }

        return messages.renewalEditingAvailableMessage
    }, [renewalTerm])

    const renewalMessageHeading = useMemo(() => renewalTerm?.timeUntilRenewalIssued === 1
        ? WMICRichTextUtil.translateRichText(translator(messages.renewalInProgressOneDay, {}))
        : WMICRichTextUtil.translateRichText(translator(messages.renewalInProgressDays, { numberOfDays: renewalTerm?.timeUntilRenewalIssued })), [renewalTerm, translator]);

    const overrideProps = {
        '@field': {
            parentNode: policyVM,
            readOnly: true
        },
        editEffectiveDateField: {
            visible: _.get(policyVM, "latestPeriod.editEffectiveDate_WMIC.value") !== undefined && !canceled
        },
        delinquencyNoticeDateField: {
            visible: delinquencyNoticeDate !== undefined && isDelinquent && !canceled
        },
        policyCostField: {
            visible: !isBrokerBillPolicy
        },
        brokerBillPolicyCostField: {
            visible: isBrokerBillPolicy
        },
        generatingDocumentsDiv: {
            visible: !documentsLoaded
        },
        resendDocumentButton: {
            visible: displayResendButton
        },
        documentsDistributedByBroker: {
            visible: isBrokerOwned
        },
        billingDocumentLink: {
            visible: billingDocumentVisible,
            href: _.get(billingDocument, 'link', undefined)
        },
        policyWordingLink: {
            visible: showPolicyDocument,
            href: _.get(policyWording, 'link', undefined)
        },
        policyDeclarationLink: {
            visible: !_.isNil(policyDeclaration) && !showPolicyDocument,
            href: _.get(policyDeclaration, 'link', undefined)
        },
        idCardLink: {
            visible: !_.isNil(policyDeclaration) && !showPolicyDocument && !_.isNil(identificationCard),
            href: _.get(identificationCard, 'link', undefined)
        },
        enrolledInEpoi: {
            visible: isEPOIEnrolled
        },
        sendCustomerPaperPinkSlipContainer: {
            visible: isFRCApplicable
        },
        sendCustomerPaperPinkSlipButton: {
            visible: false
        },
        sendCustomerPaperPinkSlipUnavailableMsg: {
            visible: true
        },
        outstandingPremiumOwingComponentContainer: {
            visible: isDelinquent && !canceled
        },
        statusComponentContainer: {
            visible: hasCanceling
        },
        displayStatusField: {
            value: getPolicyStatus()
        },
        policyCancelledStatusContainer: {
            visible: canceled
        },
        pendingTransactionStatusContainer: {
            visible: !canceled && !hasCanceling && pendingTransactions.length > 0
        },
        renewalStatusContainer: {
            visible: showRenewalMessage
        },
        renewalStatusMessageHeading: {
            content: renewalMessageHeading
        },
        renewalStatusMessageBody: {
            content: WMICRichTextUtil.translateRichText(translator(renewalMessageContent))
        }
    }

    const resolvers = {
        resolveCallbackMap: {
            showResendDocumentsDialog,
            sendFRCDocuments
        },
        resolveClassNameMap: styles,
    };

    return (
        <WMICWizardViewPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WMICWizardViewPage>
    );
}

WMICPolicySummaryPage.propTypes = wizardProps;
export default WMICPolicySummaryPage;
