import React, {
    useContext,
    useEffect,
    useState,
    useMemo
} from 'react';
import _ from 'lodash';
import appConfig from 'app-config';
import htmlParser from 'html-react-parser';
import { Loader } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { withRouter } from 'react-router-dom';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import {
    QUOTE_STATUS,
    UW_BLOCKING_POINT,
    PRODUCT,
    BOT_STATUS,
    WMICLogger,
    parseErrorMessage
} from 'wmic-pe-portals-utils-js';
import { WMICIcon, WMICTransactionDetailsHeader } from 'wmic-pe-components-platform-react';
import cx from 'classnames';
import '@xengage/gw-platform-translations';
import { WMICPremiumCosts } from 'wmic-pe-capability-gateway-common-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import metadata from './WMICRenewalDetailsPage.metadata.json5';
import messages from './WMICRenewalDetailsPage.messages';
import styles from './WMICRenewalDetailsPage.module.scss';
import {WMICErrorHandler} from "wmic-pe-capability-quoteandbind-common-react";

const WMICRenewalDetailsPage = (props) => {
    const { authHeader } = useAuthentication();
    const translator = useContext(TranslatorContext);
    const { RenewalService } = useDependencies('RenewalService');
    const [isLoading, setIsLoading] = useState(true);
    const [renewal, setRenewal] = useState();
    const [renewalVM, setRenewalVM] = useState();
    const [lobWithJobNumber, setLobWithJobNumber] = useState('');
    const [status, setStatus] = useState('');
    const [quotedWithUW, setQuotedWithUW] = useState(false);
    const [approvedUWIssues, setApprovedUWIssues] = useState();
    const [autoApprovedUWIssues, setautoApprovedUWIssues] = useState();
    const [unapprovedUWIssues, setUnapprovedUWIssues] = useState([]);
    const [isTransactionPendingWithUW, setIsTransactionPendingWithUW] = useState(false);
    const { history } = props;
    useEffect(() => {
        getJobByJobNumber();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const issueApproved = (issue) => issue.botStatus === BOT_STATUS.MANUAL_APPROVED_UW_ISSUE;
    
    const issueAutoApproved = (issue) => issue.botStatus === BOT_STATUS.AUTO_APPROVED_UW_ISSUE;
    
    const issueNotApproved = (issue) => ( issue.hasApprovalOrRejection === false || issue.currentBlockingPoint !== UW_BLOCKING_POINT.NON_BLOCKING ) && issue.botStatus === BOT_STATUS.MANUAL_APPROVED_UW_ISSUE;

    const quoteHasValidationIssues = renewal?.statusCode === QUOTE_STATUS.QUOTED && _.get(renewal, 'validationIssues.issues', []).length > 0;
    const quoteHasUWIssues = unapprovedUWIssues?.length > 0;

    useEffect(() => {
        const UWIssues = _.get(renewal, 'underwritingIssues', []);
        const approvedIssues = _.orderBy(UWIssues.filter((issue) => issueApproved(issue)), ['hasApprovalOrRejection']);
        const autoApprovedIssues = UWIssues.filter((issue) => issueAutoApproved(issue));
        const unapprovedIssues = _.orderBy(UWIssues.filter((issue) => issueNotApproved(issue)), ['hasApprovalOrRejection', 'publicID']);
        // eslint-disable-next-line no-unused-expressions
        unapprovedIssues.length > 0 && renewal?.status === QUOTE_STATUS.QUOTED && setQuotedWithUW(true);
        setApprovedUWIssues(approvedIssues);
        setUnapprovedUWIssues(unapprovedIssues);
        setautoApprovedUWIssues(autoApprovedIssues);
    }, [renewal]);

    const getJobByJobNumber = async () => {
        const {
            match: {
                params: { jobNumber }
            },
            viewModelService
        } = props;
        try {
            setIsLoading(true);
            const renewalJob = await RenewalService.findJobByJobNumber(jobNumber, authHeader);
            setLobWithJobNumber(
                translator(messages[_.camelCase(renewalJob.productCode)], {
                    jobNumber: renewalJob.jobNumber
                })
            );

            setStatus(renewalJob?.statusCode);
            setIsTransactionPendingWithUW(renewalJob?.isPendingWithUW ?? false);

            if (renewalJob) {
                const LOB = _.get(renewalJob,'productCode');
                let vmType = '';
                let renewalResult = {};

                // eslint-disable-next-line default-case
                switch (LOB) {
                    case PRODUCT.PERSONAL_AUTO:
                        vmType = 'wmic.edge.ca.capabilities.gateway.job.renewal.dto.PARenewalReviewDTO_WMIC';
                        renewalResult = await RenewalService.getPARenewalSummary_WMIC(jobNumber, authHeader);
                        break;
                    case PRODUCT.HOME_OWNER:
                        vmType = 'wmic.edge.ca.capabilities.gateway.job.renewal.dto.HORenewalReviewDTO_WMIC';
                        renewalResult = await RenewalService.getHORenewalSummary_WMIC(jobNumber, authHeader);
                        break;
                    case PRODUCT.PERSONAL_UMBRELLA:
                        vmType = 'wmic.edge.ca.capabilities.gateway.job.renewal.dto.PUPRenewalReviewDTO_WMIC';
                        renewalResult = await RenewalService.getPUPRenewalSummary_WMIC(jobNumber, authHeader);
                        break;
                }

                const renewalSummaryVM = viewModelService.create(renewalResult,'pc',vmType);
                setRenewalVM(renewalSummaryVM)
            }

            setRenewal(renewalJob);
        } catch (e) {
            WMICLogger.error('Error retrieving job', e);
            WMICErrorHandler.processAsModal(parseErrorMessage(e))
            history.push("/");
        } finally {
            setIsLoading(false);
        }
    }

    const handleContinueRenewal = () => {
        const { lobRenewalURL } = appConfig;

        const productCode = _.get(renewal, 'productCode');
        const postalCode = _.get(renewal, 'policy.account.accountHolder.primaryAddress.postalCode');
        const policyNumber = _.get(renewal, 'policy.policyNumber');
        const selectedTerm = _.get(renewal, 'latestPeriod.termNumber_WMIC');
        if (renewal.jobNumber > 0) {
            if (!_.isNil(lobRenewalURL[productCode])) {
                const nextLocation = {
                    policyNumber,
                    selectedTerm,
                    renewalentry: {
                        postalCode: postalCode,
                        jobID: renewal.jobNumber
                    },
                    productCode
                };
                history.push(lobRenewalURL[productCode], nextLocation);
            }
        }
    }

    const getManualUWItems = () => approvedUWIssues?.map((issue) => {
            const isDeclined = issue.hasApprovalOrRejection && issue.approvalBlockingPoint === UW_BLOCKING_POINT.REJECTED;
            const isApprovedOrRejectedUWI = issue.hasApprovalOrRejection === true && issue.currentBlockingPoint !== UW_BLOCKING_POINT.BLOCK_ISSUANCE;
            return(
                <li>
                    { isApprovedOrRejectedUWI ? 
                    <div className={cx(styles.uwBadge, isDeclined ? styles.uwUnapproved : styles.uwApproved)}>
                        <WMICIcon icon={isDeclined ? "mi-close" : "mi-check"} className={styles.uwApprovedItemsIcon}/>
                        <span>{isDeclined ? translator(messages.issueDeclined) : translator(messages.issueApproved)}</span>
                    </div> : null }
                    <span>{issue.longDescription}</span>
                </li>
            );
    })

    const getAutoApprovedItems = () => autoApprovedUWIssues?.map((issue) => (
            <li>
                <span>{issue.longDescription}</span>
            </li>
    ))

    const statusMessageHeading = useMemo(() => {
        const timeUntilRenewalIssued = _.get(renewal,'timeUntilRenewalIssued', 0 );

        let uwApprovalMessage = '';
        if (quoteHasUWIssues && quoteHasValidationIssues) {
            uwApprovalMessage = translator(messages.underwriterApprovalAndValidationNeeded);
        }
        else if (quoteHasUWIssues || isTransactionPendingWithUW) {
            uwApprovalMessage = translator(messages.underwriterApprovalNeeded);
        }
        else if (quoteHasValidationIssues) {
            uwApprovalMessage = translator(messages.validationNeeded);
        }

        const renewalInProgressMessage = translator(messages.renewalInProgress, {renewalStatus: translator(messages[`renewal${status}`]), uwApprovalNeeded: uwApprovalMessage})
        const renewalTimeMessage = timeUntilRenewalIssued === 1
            ? translator(messages.renewalInProgressOneDay, {})
            : translator(messages.renewalInProgressDays, { numberOfDays: timeUntilRenewalIssued})

        return htmlParser(`<span>${renewalInProgressMessage}<i>${renewalTimeMessage}</i></span>`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[status, renewal, unapprovedUWIssues, htmlParser, isTransactionPendingWithUW]);

    const statusMessageBody = useMemo(()=> {
        if (quoteHasUWIssues || quoteHasValidationIssues || isTransactionPendingWithUW) {
            return translator(messages.underwritingIssuesRaised);
        }

        if (status === QUOTE_STATUS.DRAFT) {
            return <div>{translator(messages.renewalDraftStatus)}</div>
        }

        const timeUntilRenewalIssued = _.get(renewal,'timeUntilRenewalIssued', 0 );
        const documentsReleaseMessage = translator(messages.renewalDocumentsReleased);
        const documentsDaysMessage = timeUntilRenewalIssued === 1
            ? translator(messages.renewalDocumentsReleasedOneDay, {})
            : translator(messages.renewalDocumentsReleasedDays, {numberOfDays: timeUntilRenewalIssued})

        return htmlParser(`<span>${documentsReleaseMessage} <b>${documentsDaysMessage}</b></span>`);
    }, [quoteHasUWIssues, quoteHasValidationIssues, status, renewal, translator, isTransactionPendingWithUW])

    const getCurrentPremiumData = () => ({
            totalBeforeTaxes: _.get(renewal, 'policy.latestPeriod.totalPremium'),
            taxes: _.get(renewal, 'policy.latestPeriod.taxes'),
            fees: _.get(renewal, 'policy.latestPeriod.fees'),
            total: _.get(renewal, 'policy.latestPeriod.totalCost')
        })

    const continueRenewalLabel = quoteHasUWIssues || quoteHasValidationIssues ? messages.editRenewalButton : messages.continueRenewalButton;

    const isActionButtonsContainerVisible = () => ![QUOTE_STATUS.WITHDRAWN, QUOTE_STATUS.DECLINED, QUOTE_STATUS.BOUND, QUOTE_STATUS.NOT_TAKEN].includes(renewal?.statusCode)

    const overrideProps = {
        statusIcon: {
            icon: quoteHasUWIssues || quoteHasValidationIssues ? 'mi-warning' : 'mi-check'
        },
        transactionDetailsHeader: {
            jobVM: renewal,
            lobWithJobNumber: lobWithJobNumber,
            history: history
        },
        statusMessageHeading: {
            content: statusMessageHeading
        },
        statusMessageBody: {
            content: statusMessageBody
        },
        premiumCostsCard: {
            visible: renewal?.statusCode === QUOTE_STATUS.QUOTED,
            premium: getCurrentPremiumData(),
            jobVM: renewal,
            policyChange: renewalVM
        },
        actionButtonsContainer: {
            visible: isActionButtonsContainerVisible()
        },
        UWIssuesRaisedContainer: {
            visible: unapprovedUWIssues?.length > 0
        },
        noOutstandingUWIssuesContainer: {
            visible: unapprovedUWIssues?.length === 0
        },
        autoApprovedUWIssuesContainer: {
            visible: autoApprovedUWIssues?.length > 0 
        },
        UWIssuesRaisedSubTitle: {
            title: translator(messages.uwMustReviewTitle, {count: unapprovedUWIssues?.length})
        },
        raisedUWIssuesItems: {
            content : getManualUWItems()
        },
        approvedUWIssuesItems: {
            content: getManualUWItems()
        },
        autoApprovedUWIssuesItems:{
            content: getAutoApprovedItems()   
        },
        underwritingIssuesCard: {
            visible: renewal?.statusCode === QUOTE_STATUS.QUOTED
        },
        continueRenewal: {
            content: continueRenewalLabel
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onContinueRenewal: handleContinueRenewal
        },
        resolveClassNameMap: styles,
        resolveComponentMap: {
            WMICPremiumCosts
        }
    };

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    if (_.isEmpty(renewal)) {
        return null;
    }

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};

export default withRouter(withViewModelService(WMICRenewalDetailsPage));
