import { defineMessages } from 'react-intl';

export default defineMessages({
    backToPolicyButton: {
        id: 'wmic-pe-components-platform-react.WMICTransactionDetailsHeader.backToPolicyButton',
        defaultMessage: 'Back to Policy'
    },
    editAccount: {
        id: 'wmic-pe-components-platform-react.WMICTransactionDetailsHeader.editAccount',
        defaultMessage: 'Edit account'
    },
});