import _ from 'lodash';
import React, { useContext } from 'react';

import { TranslatorContext } from '@jutro/locale';

import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import { WMICWizardViewPage } from 'wmic-pe-portals-wizard-components-ui';

import metadata from './WMICPolicyCPLocationsBuildingsPage.metadata.json5';
import messages from './WMICPolicyCPLocationsBuildingsPage.messages';
import { WMICLocationDetailView } from 'wmic-pe-capability-gateway-quoteandbind-cp-react';
const LOCATION_PATH = 'lobs.commercialProperty.coverables.locations';

function WMICPolicyCPLocationsBuildingsPage(props) {
    const {
        wizardData: { policyVM },
    } = props;

    const translator = useContext(TranslatorContext);

    const getName = (location) => {
        return `${translator(messages.locationNumber)}${location.locationNum.value}`
    };

    const getAddress = (location) => {
        const addressline = location.address.addressLine1.value
        const city = location.address.city.value
        const province = location.address.state?.value?.code
        return `${addressline}, ${city}, ${province}`
    };

    const overrideProps = {
        '@field': {
            parentNode: policyVM,
            showRequired: true
        },

        locationListView: {
            clickable: true,
            startOpen: false,
            readOnly: true,
            editEnabled: true,
            value: _.get(policyVM, `${LOCATION_PATH}.children`, []),
            VMData: [
                {
                    headerText: translator(messages.number),
                    path: 'locationNum'
                },
                {
                    headerText: translator(messages.name),
                    getData: getName
                },
                {
                    headerText: translator(messages.address),
                    getData: getAddress
                },
                {
                    headerText: translator(messages.primaryLocation),
                    path: 'isPrimary'
                },
            ],
            detailViewComponent: WMICLocationDetailView,
            detailViewComponentProps: {
                jobVM: policyVM,
                isBoundPolicy: true
            },
        },
    }

    // TODO: display readonly version of this page

    return (
        <WMICWizardViewPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyVM}
                overrideProps={overrideProps}
            />
        </WMICWizardViewPage>
    );
}

WMICPolicyCPLocationsBuildingsPage.propTypes = wizardProps;
export default WMICPolicyCPLocationsBuildingsPage;
