
export default {
    delimitNumbers: (str) => {
        return (`${str}`).replace(/\b(\d+)((\.\d+)*)\b/g, (a, b, c) => {
            const wholeNumber = (b.charAt(0) > 0 && !(c || '.').lastIndexOf('.') ? b.replace(/(\d)(?=(\d{3})+$)/g, '$1,') : b);
            if (c === '.000') {
                // Do not show .000, just show the whole number
                return wholeNumber;
            }
            return wholeNumber + c;
        });
    },
    roundUpToNearest: (toRound, interval) => {
        if (interval === 0 || toRound == null) return null;

        return (parseFloat(Math.ceil(toRound / interval)) * interval);
    },
    cleanNumberString: (numberAsString) => {
        return numberAsString.replace(/[^0-9.]/g, '');
    }
};
