/* eslint-disable no-unused-vars */
import React from 'react';
import _ from 'lodash';
import { ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';

import metadata from './WMICLastPaymentForm.metadata.json5';
import messages from './WMICLastPaymentForm.messages';

function WMICLastPaymentForm(props) {
    const { id, lastPaymentVM } = props;

    const overrideProps = {
        '@field': {
            parentNode: lastPaymentVM,
            readOnly: true,
            labelPosition: "top"
        }, 
    };

    return (
        <ViewModelForm
            model={lastPaymentVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
        />
    );
}

export default WMICLastPaymentForm;
