import { defineMessages } from 'react-intl';

export default defineMessages({
    enrollDriver: {
        id: 'wmic-pe-capability-gateway-common-pa-react.drive-change-telematics.Enroll driver in Drive Change',
        defaultMessage: 'Enroll driver in Drive Change? Wawanesa\'s telematics program'
    },
    enrollmentDetails: {
        id: 'wmic-pe-capability-gateway-common-pa-react.drive-change-telematics.Enrollment Details',
        defaultMessage: 'Enrollment Details'
    },
    driveChangeContactInfoMessage: {
        id: 'wmic-pe-capability-gateway-common-pa-react.drive-change-telematics.driveChangeContactInfoMessage',
        defaultMessage: 'Contact information for the driver must be added to bind the policy. This information will be used for app enrollment.'
    },
    driverEmailAddress: {
        id: 'wmic-pe-capability-gateway-common-pa-react.drive-change-telematics.emailAddress',
        defaultMessage: 'Driver\'s e-mail address'
    },
    driverConfirmEmailAddress: {
        id: 'wmic-pe-capability-gateway-common-pa-react.drive-change-telematics.confirmEmailAddress',
        defaultMessage: 'Confirm email address'
    },
    verifyEmailButton: {
        id: 'wmic-pe-capability-gateway-common-pa-react.drive-change-telematics.Verify Email',
        defaultMessage: 'Verify Email'
    },
    emailValid: {
        id: 'wmic-pe-capability-gateway-common-pa-react.drive-change-telematics.emailValid',
        defaultMessage: 'The email has been verified'
    },
    invalidtls: {
        id: 'wmic-pe-capability-gateway-common-pa-react.drive-change-telematics.invalidtls',
        defaultMessage: 'The email does not comply with our security standards and cannot be used to receive documents.'
    },
    unavailable: {
        id: 'wmic-pe-capability-gateway-common-pa-react.drive-change-telematics.unavailable',
        defaultMessage: 'The verification could not be completed. Please continue and the email will be verified later.'
    },
    invalid: {
        id: 'wmic-pe-capability-gateway-common-pa-react.drive-change-telematics.invalid',
        defaultMessage: 'The email is invalid. A valid email will be required to bind the policy.'
    },
    emptyEmail: {
        id: 'wmic-pe-capability-gateway-common-pa-react.drive-change-telematics.emptyEmail',
        defaultMessage: 'Email address is required for Preferred Distribution Method: Digital'
    },
    driversCellPhone: {
        id: 'wmic-pe-capability-gateway-common-pa-react.drive-change-telematics.driversCellPhone',
        defaultMessage: 'Driver\'s mobile phone'
    },
    previousInsurerDiscount: {
        id: 'wmic-pe-capability-gateway-common-pa-react.drive-change-telematics.previousInsurerDiscount',
        defaultMessage: 'The discount percentage received from their previous insurer'
    },
    activationDate: {
        id: 'wmic-pe-capability-gateway-common-pa-react.drive-change-telematics.activationDate',
        defaultMessage: 'Activation date'
    },
    enrollmentStatus: {
        id: 'wmic-pe-capability-gateway-common-pa-react.drive-change-telematics.enrollmentStatus',
        defaultMessage: 'Enrollment status'
    },
    lastUpdatedOn: {
        id: 'wmic-pe-capability-gateway-common-pa-react.drive-change-telematics.lastUpdatedOn',
        defaultMessage: 'Last updated on'
    },
    unenrollmentReason: {
        id: 'wmic-pe-capability-gateway-common-pa-react.drive-change-telematics.unenrollmentReason',
        defaultMessage: 'Unenrollment reason'
    },
    unenrollmentDate: {
        id: 'wmic-pe-capability-gateway-common-pa-react.drive-change-telematics.unenrollmentDate',
        defaultMessage: 'Unenrollment date'
    },
    totalKilometersDriven: {
        id: 'wmic-pe-capability-gateway-common-pa-react.drive-change-telematics.totalKilometersDriven',
        defaultMessage: 'Total kilometers driven'
    },
    driveChangeSafetyScore: {
        id: 'wmic-pe-capability-gateway-common-pa-react.drive-change-telematics.driveChangeSafetyScore',
        defaultMessage: 'Drive Change safety score'
    },
    drivingDetails: {
        id: 'wmic-pe-capability-gateway-common-pa-react.drive-change-telematics.drivingDetails',
        defaultMessage: 'Driving Details'
    },
    rewardPoints: {
        id: 'wmic-pe-capability-gateway-common-pa-react.drive-change-telematics.rewardPoints',
        defaultMessage: 'Reward Points'
    },
    availableToRedeem: {
        id: 'wmic-pe-capability-gateway-common-pa-react.drive-change-telematics.availableToRedeem',
        defaultMessage: 'Available to redeem'
    },
    pointsLastUpdatedOn: {
        id: 'wmic-pe-capability-gateway-common-pa-react.drive-change-telematics.PointsLastUpdatedOn',
        defaultMessage: 'Last updated on'
    }
})