import React, { useContext } from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import _ from "lodash";
import { TranslatorContext } from '@jutro/locale';
import { LINE_OF_BUSINESS } from 'wmic-pe-portals-utils-js';
import metadata from './ReadOnlyNonCoverageCostTable.metadata.json5';
import messages from './ReadOnlyNonCoverageCostTable.messages';
import styles from "./ReadOnlyNonCoverageCostTable.module.scss";
import WMICCoverageUtil from '../../Coverages/util/WMICCoverageUtil';
import { JobType } from 'wmic-pe-portals-utils-js';

function ReadOnlyNonCoverageCostTable(props) {
    const { nonCoverageCostList, jobVM, flat } = props;
    const translator = useContext(TranslatorContext);
    const lob = _.get(jobVM, 'lob.value.code');
    const isHO = lob === LINE_OF_BUSINESS.HOME_OWNER;
    const isPA = lob === LINE_OF_BUSINESS.PERSONAL_AUTO;
    const isPolicyChange = _.get(jobVM, 'baseData.jobType.value.code') === JobType.POLICY_CHANGE;

    const nonCoverageCostDataList = () => {
        const costList = [];

        nonCoverageCostList.forEach((cost) => {
            costList.push({
                name: cost.displayName,
                premium: WMICCoverageUtil.getPremiumAmount(cost),
                proratedPremium: WMICCoverageUtil.getProratedPremiumAmount(cost)
            });
        });

        return costList;
    }

    const getCostPremium = cost => {
        return cost.premium;
    };

    const getCostProratedPremium = cost => {
        return cost.proratedPremium;
    };

    const overrideProps = {
        nonCoverageCostTableCard: {
            flat,
            className: flat ? "flatNonCoverageCostTableCard" : "nonCoverageCostTableCard"
        },
        nonCoverageCosts: {
            VMList: nonCoverageCostDataList(),
            VMData: [
                {
                    headerText: translator(messages.nonCoverageCostHeaderText),
                    path: "name",
                    columnProportion: 6.4
                },
                {
                    headerText: translator(isPolicyChange ? messages.annualPremiumColumnHeading : messages.premiumColumnHeading),
                    path: "premium",
                    renderCell: getCostPremium
                },
                {
                    headerText: translator(messages.proratedPremium),
                    path: "proratedPremium",
                    renderCell: getCostProratedPremium,
                    visible: isPolicyChange && (isHO || isPA)
                }
            ],
            readOnly: true
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={nonCoverageCostDataList}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

export default ReadOnlyNonCoverageCostTable;
