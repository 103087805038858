import { defineMessages } from 'react-intl';

export default defineMessages({
    claimInformationTitle: {
        id: "wmic-pe-capability-policy-common-react.WMICHOPolicyLossHistoryDetailView.ClaimInformation",
        defaultMessage: "Claim Information"
    },
    manualLossType: {
        id: "wmic-pe-capability-policy-common-react.WMICHOPolicyLossHistoryDetailView.ManualLossType",
        defaultMessage: "Type of Loss"
    },
    claimDesc: {
        id: "wmic-pe-capability-policy-common-react.WMICHOPolicyLossHistoryDetailView.ClaimDescription",
        defaultMessage: "Claim Description"
    },
    catastropheIndicatorType: {
        id: "wmic-pe-capability-policy-common-react.WMICHOPolicyLossHistoryDetailView.CatastropheIndicatorType",
        defaultMessage: "Catastrophe Indicator"
    },
    policyInformationTitle: {
        id: "wmic-pe-capability-policy-common-react.WMICHOPolicyLossHistoryDetailView.PolicyInformation",
        defaultMessage: "Policy Information"
    },
    insuranceCompany: {
        id: "wmic-pe-capability-policy-common-react.WMICHOPolicyLossHistoryDetailView.Insurance Company",
        defaultMessage: "Insurance Company"
    }
})