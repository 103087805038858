import { defineMessages } from 'react-intl';

export default defineMessages({
    removeLossHistory: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOLossHistory.RemoveLossHistory",
        defaultMessage: "Remove prior loss?"
    },
    removeLossHistoryConfirmation: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOLossHistory.RemoveConfirmationMsg",
        defaultMessage: "Are you sure you want to remove this prior loss?"
    },
    addLossHistory: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOLossHistory.Add",
        defaultMessage: "Add Loss"
    },
    noPriorLossesMsg: {
        id:"wmic-pe-capability-gateway-common-ho-react.WMICHOLossHistory.NoLossHistory",
        defaultMessage:"No losses have been added"
    }
})