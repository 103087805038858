import React from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICPolicyLossHistoryPayout } from 'wmic-pe-capability-policy-common-react';

import metadata from './WMICHOPolicyLossHistoryDetailView.metadata.json5';
import './WMICHOPolicyLossHistoryDetailView.messages';

function WMICHOPolicyLossHistoryDetailView(props){
    const {
        value: currentPriorLoss,
    } = props;

    const overrideProps = {
        '@field': {
            parentNode: currentPriorLoss,
            readOnly: true
        },
        payoutComponent: {
            currentPriorLoss
        }
    }

    const resolvers = {
        resolveComponentMap: {
            WMICPolicyLossHistoryPayout
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentPriorLoss}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
        />
    )
}

WMICHOPolicyLossHistoryDetailView.propTypes = {
    value: PropTypes.shape({}),
    policyVM: PropTypes.shape({}),
    getDriver: PropTypes.func
};

export default WMICHOPolicyLossHistoryDetailView;