import { defineMessages } from 'react-intl';

export default defineMessages({
    withdrawalDayMonth: {
        id: 'agent.directives.PaymentPlanChange.BankAccountInfo.Withdrawal Day of Month',
        defaultMessage: 'Withdrawal Day of Month'
    },    
    financialInstitutionNumber: {
        id: 'agent.directives.PaymentPlanChange.BankAccountInfo.Financial Institution Number',
        defaultMessage: 'Financial Institution Number'
    },    
    branchTransit: {
        id: 'agent.directives.PaymentPlanChange.BankAccountInfo.Branch Transit',
        defaultMessage: 'Branch Transit'
    },    
    financialInstitutionName: {
        id: 'agent.directives.PaymentPlanChange.BankAccountInfo.Financial Institution Name',
        defaultMessage: 'Financial Institution Name'
    },    
    bankAccountNumber: {
        id: 'agent.directives.PaymentPlanChange.BankAccountInfo.Bank Account Number',
        defaultMessage: 'Bank Account Number'
    },    
    bankAccountOwnerName: {
        id: 'agent.directives.PaymentPlanChange.BankAccountInfo.Bank Account Owner Name',
        defaultMessage: 'Bank Account Owner Name'
    },
    fiNameNotFoundFirstError: {
        id: 'agent.directives.PaymentPlanChange.Financial Institution Name not found first message',
        defaultMessage: 'Financial Institution Name not found. Verify the Branch Transit and Financial Institution numbers and try again.'
    },
    fiNameNotFoundSecondError: {
        id: 'agent.directives.PaymentPlanChange.Financial Institution Name not found second message',
        defaultMessage: 'Financial Institution Name not found. It may be invalid or unavailable at this time. Please select a different payment plan to proceed.'
    },    
});
