import React, { useContext, useState, useEffect, useMemo } from 'react';
import { WizardPage, WizardContext } from 'wmic-pe-portals-custom-wizard-react';

const FUNC_ALWAYS_TRUE = () => true;


/*
 * Renders a "read only" / "view only" wizard page, by default with all navigation buttons hidden.
 * Built specifically for policy view, where navigation is performed exclusively via the sidebar.
 * 
 * NOTE: to enable all pages on the sidebar to be clickable, we need a hack: let the wizard skip (go through) all pages,
 *       and then force the wizard to go back to the first page. See below for a few notes on how this is accomplished.
 */
function WMICWizardViewPage({ children, ...props }) {
    
    const { steps, currentStepIndex, jumpTo, isSkipping } = useContext(WizardContext);
    const [backToFirst, setBackToFirst] = useState(false);
    
    const numberOfSteps = steps?.length || 0;
    const isLastPage = useMemo(() => numberOfSteps-1 === currentStepIndex, [currentStepIndex, numberOfSteps]);

    // This effect is triggered when page loads for the first time, and if it's the very last page in the wizard
    // then it signals that we want to go back to the first page. This is only done once, when 'isSkipping' is true.
    // After going back to the first page, the wizard is no longer skipping, so this effect will not do anything.
    useEffect(() => {
        if (isLastPage && isSkipping && !backToFirst) {
            setBackToFirst(true);
        }
    }, [backToFirst, isLastPage, isSkipping]);

    // Once we detect that we just 'skipped' to the last page for the first time, then we want to go back to the first page.
    useEffect(() => {
        if (backToFirst && !isSkipping) {
            jumpTo(0);
        }
    }, [backToFirst, isSkipping, jumpTo]);

    return (
        <WizardPage
            // skipWhen must always be provided, so the page can skip properly. However, last page should not skip,
            // otherwise wizard will never stop skipping.
            skipWhen={isLastPage? undefined : FUNC_ALWAYS_TRUE}
            showCancel={false}
            showPrevious={false}
            showNext={false}
            // Note that we still allow parent component to pass props down to the wizard page. These will take
            // precedence and override the defaults above.
            {...props}
        >
            {children}
        </WizardPage>
    )
}

export default WMICWizardViewPage;
