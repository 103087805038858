import { getProxiedServiceUrl } from 'wmic-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';
import JobService from './JobService';

export default class PolicyChangeService {
    static findJobByJobNumber(policyChangeNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayChangePolicy'),
            'findJobByJobNumber', [policyChangeNumber], additionalHeaders);
    }

    static getPolicyChangeSummary(policyChangeNumber, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayChangePolicy'),
            'getPolicyChangeSummary',
            [policyChangeNumber], additionalHeaders);
    }

    static getNotesForPolicyChange(policyChangeNumber, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayChangePolicy'),
            'getNotesForPolicyChange',
            policyChangeNumber, additionalHeaders);
    }

    static createNoteForPolicyChange(params, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayChangePolicy'),
            'createNoteForPolicyChange',
            params, additionalHeaders);
    }

    static getDocumentsForPolicyChange(policyChangeNumber, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayChangePolicy'),
            'getDocumentsForPolicyChange',
            [policyChangeNumber], additionalHeaders);
    }

    static getUWIssuesForPolicyChange(policyChangeNumber, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayChangePolicy'),
            'getUWIssuesForPolicyChange',
            [policyChangeNumber], additionalHeaders);
    }

    static referToUnderwriter(policyChangeNumber, noteBody, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayChangePolicy'),
            'referToUnderwriter',
            [policyChangeNumber, noteBody], additionalHeaders);
    }

    static withdrawPolicyChangeByPolicyChangeNumber(policyChangeNumber, additionalHeaders) {
        return JobService.withdrawJobByJobNumber(policyChangeNumber, additionalHeaders);
    }
}
