import messages from './WMICQuestionSetBulletPoint.messages';
import CONSTANTS from 'wmic-portals-utils-js/StringConstants';

const bulletPoints = {
    ISSpecialVehicle_wmic: [
        messages.highPerformance,
        messages.KITcars,
        messages.foreignExoticOrSportsCars,
        messages.antiqueOrClassicVehicle,
        messages.rightHandDriveVehicle,
        messages.miniOrPocketBike,
        messages.motardSupermotard,
        messages.offRoadMotocross,
        messages.allTerrainVehicles,
        messages.amphibiousVehicle,
        messages.allTerrainBuggy,
        messages.childSizeOrJuniorSnowVehicle
    ],
    ISSpecialVehicle_wmic_ON: [
        messages.KITcars,
        messages.antiqueOrClassicVehicle,
        messages.rightHandDriveVehicle,
        messages.miniOrPocketBike,
        messages.motardSupermotard,
        messages.offRoadMotocross,
        messages.allTerrainVehicles,
        messages.amphibiousVehicle,
        messages.allTerrainBuggy,
        messages.childSizeOrJuniorSnowVehicle
    ],
    ISSpecialVehicleFsra_Ext: [
        messages.KITcars,
        messages.antiqueOrClassicVehicle,
        messages.rightHandDriveVehicle,
        messages.miniOrPocketBike,
        messages.motardSupermotard,
        messages.offRoadMotocross,
        messages.allTerrainVehicles,
        messages.amphibiousVehicle,
        messages.allTerrainBuggy,
        messages.childSizeOrJuniorSnowVehicle
    ],
    BPASSPUPPrequalProfession_WMIC: [
        messages.actor,
        messages.author,
        messages.broadcaster,
        messages.journalist,
        messages.labourUnionLeaders,
        messages.newsmediaEditor,
        messages.politician,
        messages.professionalAthlete,
        messages.professionalEntertainer
    ],
    ISForUndesirableCommercialPurposes_wmic: [
        messages.highHazard,
        messages.longHaul,
        messages.usedOutsideCanada
    ],
    ISForUndesirableCommerPurposeFsra_Ext: [
        messages.highHazard,
        messages.longHaul,
        messages.usedOutsideCanada
    ],
    SingleWallTank: [
        messages.singleWalledReconditioned,
        messages.locatedUnderground,
        messages.uncertified,
        messages.notProfessionallyInstalled,
        messages.manufacturedByDTE
    ],
    BPASSCPPrequalPerformOperations_WMIC: [
        messages.cpAircraft,
        messages.cpAbestos,
        messages.cpBlasting,
        messages.cpSprayPainting,
        messages.cpWelding,
        messages.cpOilfield,
        messages.cpPileDriving,
        messages.cpStructuralDemolition,
        messages.cpUnderpinning,
        messages.cpWaterproofing,
        messages.cpWorkOver4Storeys
    ]
};


function isQuestionWithBulletPoints(code) {
    return CONSTANTS.PREQUAL_QUESTIONS_WITH_BULLET_POINTS.includes(code);
}

function getBulletPoints(code, translator) {
    return bulletPoints[code].map((element) => {
        return { message: translator(element) };
    });
}

export default {
    isQuestionWithBulletPoints: (code) => {
        return isQuestionWithBulletPoints(code);
    },
    getBulletPoints: (code, translator) => {
        return getBulletPoints(code, translator);
    }
};
