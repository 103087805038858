/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import _ from 'lodash';


const IMSubmissionLobDataExtension = function (submissionVM) {
    const lobData = _.get(submissionVM, 'lobData.inlandMarine');

    this.offerings = lobData.offerings || {};
    this.machineryEquipAddlInterests = lobData.machineryEquipAddlInterests || [];
    this.toolsAddlInterests = lobData.toolsAddlInterests || [];
};

export default IMSubmissionLobDataExtension;
