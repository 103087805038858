import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _, { noop } from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { LobIconUtil } from '@xengage/gw-portals-util-js'
import { WMICIcon, WMICIntlFormattedDateCell } from 'wmic-pe-components-platform-react';
import { ICON_NAMES, LOBConstants, PUP_LOB_CODES } from 'wmic-pe-portals-utils-js'
import { Currency as CurrencyField } from 'gw-components-platform-react';
import metadata from './WMICPUPCoverageInfo.metadata.json5';
import messages from './WMICPUPCoverageInfo.messages';

const UNDERLYING_POLICY_PATH = 'coverables.underlyingPolicies';

function WMICPUPCoverageInfo(props) {
    const translator = useContext(TranslatorContext);

    const { pupDataVM } = props;

    if (_.isEmpty(pupDataVM)) {
        return null;
    }

    const formatLOBCode = (code) => {
        if (code === PUP_LOB_CODES.PA_CODE) { return LOBConstants.PA };
        if (code === PUP_LOB_CODES.HO_CODE) { return LOBConstants.PP };
        return code;
    };

    const getPolicyCell = (item) => {
        const icon =
            item.policyType === PUP_LOB_CODES.PWL_CODE ? ICON_NAMES.ICON_SHIP :
                LobIconUtil.getMaterialIcon(formatLOBCode(item.policyType));

        return <span>
            <WMICIcon icon={icon}/>
            {translator(messages[item.policyType])} {item.policyNumber}
        </span>
    };

    const getRiskCell = (item) => {
        switch (item.policyType){
            case PUP_LOB_CODES.PA_CODE:
                if (item.exposures.length === 1) {
                    return translator(messages.wmicUnderlyingPoliciesPassengerVehicleRisk)
                }
                if (item.isExcessPolicy) {
                    return translator(messages.wmicUnderlyingPoliciesExcessPolicy)
                }
                return translator(messages.wmicUnderlyingPoliciesPassengerVehicleRisks, { number: item.exposures.length} )
            case PUP_LOB_CODES.HO_CODE:
                if (item.exposures.length === 1) {
                    return translator(messages.wmicUnderlyingPoliciesPersonalPropertyRisk)
                }
                return translator(messages.wmicUnderlyingPoliciesPersonalPropertyRisks, { number: item.exposures.length})
            case PUP_LOB_CODES.PWL_CODE:
                if (item.exposures.length === 1) {
                    return translator(messages.wmicUnderlyingPoliciesWatercraftRisk)
                }
                return translator(messages.wmicUnderlyingPoliciesWatercraftRisks, { number: item.exposures.length})
        }
    };

    const getChosenTermValue = (coverage, patternCode) => {
        const term = _.find(_.get(coverage, 'terms', []), {patternCode: patternCode});

        if(term) {
            return term.chosenTermValue;
        }
        return undefined;
    };

    const getFormattedDateCell = (date) => {
        return <span>
            <WMICIntlFormattedDateCell date={new Date(date.value.year, date.value.month, date.value.day)} />
        </span>
    };

    const getCoverageDescription = (item) => {
        const primaryLiabilityPatternCode = 'PUP_Primary_LiabilityCov_PUE';
        const coverageName = _.get(item, 'name.value', '');
        return coverageName.includes(primaryLiabilityPatternCode) ? translator(personalLiability) : coverageName;
    };

    const getFormattedAmountCell = (amount) => {
        return <CurrencyField
            value={amount}
            dataType="object"
            readOnly
            hideLabel
        />

    };

    const sharedHeaders = [{
            headerText: translator(messages.wmicUnderlyingPolicesPolicy),
            getData: (item) => getPolicyCell(item.value)
        },
        {
            headerText: translator(messages.wmicUnderlyingPoliciesInsurer),
            path: 'carrierNameDisplay'
        },
        {
            headerText: translator(messages.wmicUnderlyingPoliciesRisks),
            getData: (item) => getRiskCell(item.value)
        },
        {
            headerText: translator(messages.expires),
            getData: (item) => getFormattedDateCell(item.expirationDate)
        }
    ];

    const overrides = {
        HOunderlyingPoliciesDataList: {
            VMList: _.filter(_.get(pupDataVM, `${UNDERLYING_POLICY_PATH}.children`, []), policy => _.get(policy, 'value.policyType') === PUP_LOB_CODES.HO_CODE),
            VMData: [
                ...sharedHeaders
                ,
                {
                    headerText: translator(messages.personalLiabilityLimit),
                    getData: (item) => getChosenTermValue(_.get(item, 'coverages.value[0]'), 'PUP_ULHOLiabilityLimit_PUE')
                }
            ],
            readOnly: true,
            updateSelectedCardIndex: noop
        },
        underlyingPoliciesDataList: {
            VMList: _.filter(_.get(pupDataVM, `${UNDERLYING_POLICY_PATH}.children`, []), policy => _.get(policy, 'value.policyType') !== PUP_LOB_CODES.HO_CODE),
            VMData: [
                ...sharedHeaders
            ],
            readOnly: true,
            updateSelectedCardIndex: noop
        },
        coveragesDataList: {
            VMList: _.get(pupDataVM, 'coverables.liabilityCoverages.children', []),
            VMData: [
                {
                    headerText: translator(messages.description),
                    getData: (coverage) => getCoverageDescription(coverage)
                },
                {
                    headerText: translator(messages.deductible),
                    getData: (coverage) => getChosenTermValue(_.get(coverage, 'value'), 'PUP_SIR_PUE')
                },
                {
                    headerText: translator(messages.limit),
                    getData: (coverage) => getChosenTermValue(_.get(coverage, 'value'), 'PUP_LiabillityLimit_PUE')
                },
                {
                    headerText: translator(messages.premium),
                    getData: (coverage) => getFormattedAmountCell(_.get(coverage, 'amount.value'))
                }
            ],
            readOnly: true,
            updateSelectedCardIndex: noop
        }
    };

    const resolvers = {
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={pupDataVM}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

WMICPUPCoverageInfo.propTypes = {
    value: PropTypes.shape({}).isRequired
};

export default WMICPUPCoverageInfo;
