import React, { useContext, useState } from 'react';
import { ModalNext, ModalBody, ModalFooter, ModalHeader, DateField } from '@jutro/components';
import { WMICButton } from 'wmic-pe-components-platform-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { Flex } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import PropTypes from 'prop-types';

import { RenewalWarning } from 'wmic-pe-capability-gateway-policychange-common-react';
import messages from './PolicyChangeStartModal.messages';

function PolicyChangeStartModal(props) {
    const { isOpen, onResolve, minDate, maxDate, showRenewalWarning } = props;
    const [ effectiveDate, setEffectiveDate ] = useState(undefined);
    const [ showErrors, setShowErrors ] = useState(false);
    const translator = useContext(TranslatorContext);
    
    const { onValidate: setComponentValidation , isComponentValid} = useValidation("WMICPolicyChangeStartModal");

    const handlePolicyStart = () => {
        if(!isComponentValid){
            setShowErrors(true);
            return;
        }

        onResolve(effectiveDate)
    }

    return (
        <ModalNext isOpen={isOpen}>
            <Flex direction="column" id="modalRoot">
                <ModalHeader status="warning" title={translator(messages.policyChangeHeader)}/>
                <ModalBody>
                    { showRenewalWarning && <RenewalWarning /> }
                    <Flex direction="column" alignItems="space-evenly">
                        <DateField
                            id="policyChangeStartEffectiveDate"
                            value={effectiveDate}
                            label={translator(messages.dateLabel)}
                            className='wmicMasterInputInputField'
                            labelClassName='wmicMasterInputFieldLabel'
                            onValidationChange={setComponentValidation}
                            onValueChange={setEffectiveDate}
                            required
                            showRequired
                            showErrors= {showErrors}
                            dataType="date-time"
                            minDate= {minDate}
                            maxDate= {maxDate}
                        />
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    <WMICButton
                        id="bmsi-modal-cancel"
                        onClick={() => onResolve(false)}
                        type="primary-outlined"
                    >
                        {translator(messages.cancelButton)}
                    </WMICButton>
                    <WMICButton
                        id="bmsi-modal-confirm"
                        onClick={handlePolicyStart}
                        type="primary"
                    >
                        {translator(messages.okButton)}
                    </WMICButton>
                </ModalFooter>
            </Flex>
        </ModalNext>
    );
}

PolicyChangeStartModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired
};

export default PolicyChangeStartModal;
