import { validationFunctions, utils } from 'wmic-pe-portals-viewmodel-js';
import _ from "lodash";
import { LOADING } from 'wmic-pe-portals-utils-js';
import ocular from './Ocularness';
import editability from './Editability';
import exprDisplay from './ExprDisplay';
import availableValues from './AvailableValues';
import requiredness from './Requiredness';

const DISPLAY = 'wmic.edge.common.aspects.validation.dto.DisplayDTO';
const TOOLTIP = 'wmic.edge.common.aspects.validation.dto.TooltipDTO';
const POSTONCHANGE = 'wmic.edge.common.aspects.validation.dto.PostOnChangeDTO';

function getRadiantData(currentViewModelNode) {
    const parentNode = currentViewModelNode._parent;
    const lowercasePath = _.toLower(currentViewModelNode._accessorCode);
    return parentNode && parentNode.value.metaDataMap ? (parentNode.value.metaDataMap.find((item) => _.toLower(item.propName) === lowercasePath)) : undefined;
}

function getRadiantLabel(currentViewModelNode) {
    const radiantData = getRadiantData(currentViewModelNode);

    /* if no radiant data found for this field, default to visible. */
    return radiantData && radiantData.label !== undefined ? radiantData.label : undefined;
}

const vmQualifyingProperties = ['aspects', 'value'];

function isVMNode(maybeNode) {
    if (!maybeNode || !_.isObject(maybeNode)) {
        return false;
    }

    return vmQualifyingProperties.every((qp) => {
        return qp in maybeNode;
    });
}

const NONSENSE = Object.freeze({});

const compileAspectAccess = (() => {
    function getMyRadiantData(currentViewModelNode, propertyName) {
        const parentNode = currentViewModelNode;
        const radiantData = parentNode && parentNode.value.metaDataMap ? parentNode.value.metaDataMap.find((item) => _.toLower(item.propName) === _.toLower(propertyName)) : undefined;
        return radiantData;
    }
    /* Fetches a property from the object. */
    function getPropertyAspect(obj, propName, aspectName) {
        if (obj === NONSENSE || _.isUndefined(obj) || _.isNull(obj)) {
            return obj;
        }

        /*
         * Keep navigating VM nodes as much as possible.
         */
        if (isVMNode(obj)) {
        // if (_.isObject(obj)) {
            const radiantData = getMyRadiantData(obj, propName);
            // the second bit of the mask is the loading, so we strip it off because we don't care at this level
            // eslint-disable-next-line no-bitwise
            const aspectValue = radiantData && ((LOADING.PASSIVE | LOADING.ON) === parseInt(radiantData[`is${aspectName}`], 2));
            return aspectValue;
        }
        return null;
    }

    return (expr, buildContext) => {
        const baseEvaluator = buildContext.compile(expr.base);

        return (args) => {
            return getPropertyAspect(baseEvaluator(args), expr.propName, expr.aspectName);
        };
    };
})();

const propertyAspects = {
    getAspectProperties(currentViewModelNode, currentMetadataNode /* , ancestorChain*/) {
        return {
            tooltip: {
                get: () => {
                    const ret = currentMetadataNode.elementMetadata.get(TOOLTIP);
                    let tooltip = '';
                    if (ret.length > 0) {
                        tooltip = ret[0].helpText;
                    }
                    return tooltip;
                }
            },
            display: {
                get: () => {
                    const ret = currentMetadataNode.elementMetadata.get(DISPLAY);
                    const radiant = getRadiantLabel(currentViewModelNode);
                    let label;
                    if (ret.length > 0) {
                        label = ret[0].labelText;
                    }
                    if (radiant) {
                        label = radiant;
                    }
                    return label;
                }
            },
            ajaxChange: {
                get: () => {
                    const ret = currentMetadataNode.elementMetadata.get(POSTONCHANGE);
                    let pstOnChg = null;
                    if (ret.length > 0) {
                        pstOnChg = ret[0];
                    }
                    return pstOnChg;
                }
            }
        };
    }
}

const isInteger = (val) => {
    if ((undefined === val) || (val === null)) {
        return false;
    }
    return val % 1 === 0;
};




// Export Expression Types and Validation Functions

// Types
// Key should match the backend package name for your Expression Object
// Value should be your new Expression Language Type
export const types = {
    'edge.time.LocalDateDTO': {
        namespace: 'pc', type: validationFunctions.WMICLocalDateDTOValidationFunctions
    },
    'wmic.edge.ca.aspects.validation.ValidationFunctions_WMIC': {
        namespace: 'pc',
        type: validationFunctions.WMICValidationFunctions
    },
    'wmic.edge.ca.capabilities.helpers.BluePassConfigs_WMIC': {
        namespace: 'pc', type: validationFunctions.WMICBluepassConfigs
    },
    'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.dwelling.DwellingDTO_WMIC': {
        namespace: 'pc',
        type: validationFunctions.WMICDwellingDTOValidationFunctions
    },
    'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.wing.DWIGConstructionInfoDTO_WMIC': {
        namespace: 'pc',
        type: validationFunctions.WMICDWIGConstructionInfoDTOValidationFunctions
    },
    'wmic.edge.common.capabilities.locale.util.DateFormatter': {namespace: undefined, type: utils.DateFormatter},
    'wmic.edge.ca.capabilities.address.dto.AddressDTO': {
        namespace: 'pc',
        type: validationFunctions.WMICAddressDTOValidationFunctions
    },
    'wmic.edge.us.capabilities.quote.submission.base.dto.QuoteBaseDataDTO': {
        namespace: 'pc',
        type: validationFunctions.WMICQuoteBaseDataDTOValidationFunctions
    },
    'wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.dto.DriverDTO': {
        namespace: 'pc',
        type: validationFunctions.WMICDriverDTOValidationFunctions
    },
    'wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.util.DriverUtil_WMIC': {
        namespace: 'pc',
        type: validationFunctions.WMICDriverUtilValidationFunctions
    },
    'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.watercraft.WatercraftRiskDTO_WMIC': {
        namespace: 'pc',
        type: validationFunctions.WMICWatercraftRiskDTOValidatonFunctions
    },
    'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.dwelling.HeaterFuelDTO_WMIC': {
        namespace: 'pc',
        type: validationFunctions.WMICHeaterFuelValidationFunctions
    },
    'wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.dto.CommercialVehicleDTO_WMIC': {
        namespace: 'pc',
        type: validationFunctions.WMICCommercialVehicleDTOValidationFunctions
    },
    'wmic.edge.ca.capabilities.helpers.InsuranceHistoryHelper': {
        namespace: 'pc',
        type: validationFunctions.WMICInsuranceHistoryHelper
    },
    'wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.dto.VehicleDTO': {
        namespace: 'pc',
        type: validationFunctions.WMICVehicleDTOValidationFunctions
    },
    'wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.dto.VinSearchDTO': {
        namespace: 'pc',
        type: validationFunctions.WMICVinSearchDTOValidationFunctions
    },
    'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.dwelling.YourHomeDTO_WMIC': {
        namespace: 'pc',
        type: validationFunctions.WMICYourHomeValidationFunctions
    },
    'wmic.edge.ca.capabilities.helpers.CreditConsentHelper': {
        namespace: 'pc',
        type: validationFunctions.WMICCreditConsentValidationFunctions
    },
    'wmic.edge.ca.capabilities.quote.dto.FlexDTO_WMIC': {
        namespace: 'pc',
        type: validationFunctions.WMICFlexDTOValidationFunctions
    },
    'wmic.edge.ca.capabilities.policyjob.lob.common.draft.util.PhoneUtil_Ext': {
        namespace: 'pc',
        type: utils.PhoneUtil_Ext
    },
    'wmic.suite.validation.EmailAndFaxValidation': {
        namespace: 'pc',
        type: validationFunctions.EmailAndFaxValidation
    }
};

export const aspectFactory = {
    ocular: ocular.create,
    editable: editability.create,
    exprDisplay: exprDisplay.create,
    availableValues: availableValues.create,
    requiredness: requiredness.create,
    // simple aspects need to exist in the form of a function
    propertyAspects: () => propertyAspects
}

export const kindCompilers = {
    getaspect: compileAspectAccess
}

// Validations
// Function name should match backend validation function
export const validations = {
    isInteger,

};
export const validationMessagesToIgnore = [
    // validation messages that the view model surfaces that Jutro might duplicate

    // This field is required
    'displaykey.Edge.Web.Api.Model.NotNull',
    // Value entered must be a valid phone number
    'displaykey.Edge.Web.Api.Model.Phone'
]
