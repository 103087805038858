import React, { useEffect, useCallback } from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { Position, CONSTANTS } from "wmic-pe-portals-utils-js";
import _ from 'lodash';
import metadata from './WMICGLLiabilityDetails.metadata.json5';
import messages from './WMICGLLiabilityDetails.messages';

function WMICGLLiabilityDetails(props) {
    const {
        id,
        jobVM,
        onValidate,
        updateWizardData,
        isBoundPolicy,
        readOnly
    } = props;

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    const lobDataPath = isBoundPolicy ? 'lobs' : 'lobData';
    const policyDetailsPath = `${lobDataPath}.generalLiability.policyDetails`;
    const policyDetailsVM = _.get(jobVM, policyDetailsPath);

    const handleWorkSubcontractedToOthersChange = useCallback(
        (value, path) => {
            _.set(jobVM, `${policyDetailsPath}.${path}`, value);
            _.set(jobVM, `${policyDetailsPath}.subcontrReqToCarryLiabIns_WMIC`, CONSTANTS.YES_NO_WMIC_CODES.YES);
            updateWizardData(jobVM);
        },
        [jobVM, updateWizardData, policyDetailsPath]
    );

    const overrideProps = {
        '@field': {
            labelPosition: Position.LEFT,
            parentNode: policyDetailsVM,
            readOnly
        }
    }

    const resolvers = {
        resolveCallbackMap: {
            onWorkSubcontractedToOthersChange: handleWorkSubcontractedToOthersChange
        }
    };

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    const updatePolicyDetails = (value) => {
        _.set(jobVM, policyDetailsPath, value);
        updateWizardData(jobVM);
    };

    return (
        <ViewModelForm
            model={policyDetailsVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            onModelChange={updatePolicyDetails}
            onValidationChange={setComponentValidation}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

export default WMICGLLiabilityDetails;
