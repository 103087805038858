import { defineMessages } from 'react-intl';

export default defineMessages({
    addPet: {
        id: 'quoteandbind.ho.views.ho-your-home.PetAdd',
        defaultMessage: 'Add Pet'
    },
    number: {
        id: 'quoteandbind.ho.views.ho-your-home.PetNumber',
        defaultMessage: 'NUMBER'
    },
    type: {
        id: 'quoteandbind.ho.views.ho-your-home.PetType',
        defaultMessage: 'TYPE'
    },
    breed: {
        id: 'quoteandbind.ho.views.ho-your-home.PetBreed',
        defaultMessage: 'BREED'
    },
    description: {
        id: 'quoteandbind.ho.views.ho-your-home.PetTypeOther',
        defaultMessage: 'DESCRIPTION'
    },
    biteHistory: {
        id: 'quoteandbind.ho.views.ho-your-home.PetBiteHistory',
        defaultMessage: 'BITE HISTORY'
    },
    atLeastOnePetMsg: {
        id: 'quoteandbind.ho.views.ho-your-home.AtLeastOnePet',
        defaultMessage: 'At least one pet must be added.'
    }
});
