import { defineMessages } from 'react-intl';

export default defineMessages({
    vehicleOther: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistoryDetailView.vehicleOther',
        defaultMessage: 'Other'
    },
    claimInformation: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistoryDetailView.claimInformation',
        defaultMessage: 'Claim Information'
    },
    reportDate: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistoryDetailView.reportDate',
        defaultMessage: 'Report Date'
    },
    claimStatus: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistoryDetailView.claimStatus',
        defaultMessage: 'Claim Status'
    },
    manualLossType: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistoryDetailView.manualLossType',
        defaultMessage: 'Type of Loss'
    },
    claimDescription: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistoryDetailView.claimDescription',
        defaultMessage: 'Claim Description'
    },
    catastropheIndicatorType: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistoryDetailView.catastropheIndicatorType',
        defaultMessage: 'Catastrophe Indicator'
    },
    hitAndRun: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistoryDetailView.hitAndRun',
        defaultMessage: 'Hit and Run?'
    },
    policyInformation: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistoryDetailView.policyInformation',
        defaultMessage: 'Policy Information'
    },
    insuranceCompany: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistoryDetailView.insuranceCompany',
        defaultMessage: 'Insurance Company'
    },
    vehicleOperator: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistoryDetailView.vehicleOperator',
        defaultMessage: 'Vehicle Operator'
    },
    vehicleOperatorName: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistoryDetailView.vehicleOperatorName',
        defaultMessage: 'Name'
    },
    atFaultPercent: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistoryDetailView.atFaultPercent',
        defaultMessage: '% at Fault'
    },
    lossVehicle: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistoryDetailView.lossVehicle',
        defaultMessage: 'Loss Vehicle'
    },
    vehiclePublicID: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistoryDetailView.vehicle',
        defaultMessage: 'Vehicle'
    },
    vehicleBusinessSegment: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistoryDetailView.vehicleBusinessSegment',
        defaultMessage: 'Business Segment'
    },
    vehicleType: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistoryDetailView.vehicleType',
        defaultMessage: 'Vehicle Type'
    },
    vehicleYear: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistoryDetailView.vehicleYear',
        defaultMessage: 'Year'
    },
    vehicleMake: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistoryDetailView.vehicleMake',
        defaultMessage: 'Make'
    },
    vehicleModel: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistoryDetailView.vehicleModel',
        defaultMessage: 'Model'
    },
    vehicleVIN: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistoryDetailView.vehicleVIN',
        defaultMessage: 'VIN'
    },
    vehicleStatus: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistoryDetailView.vehicleStatus',
        defaultMessage: 'Vehicle Status'
    }
});