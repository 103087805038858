import { defineMessages } from 'react-intl';

export default defineMessages({
    causeOfLoss: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICAddPayoutComponent.CauseOfLoss",
        defaultMessage: "Cause of Loss"
    },
    payoutStatus: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICAddPayoutComponent.Status",
        defaultMessage: "Status"
    },
    claimOpenReserves: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICAddPayoutComponent.RemainingReserves",
        defaultMessage: "Remaining Reserves"
    },
    lossTotalPaid: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICAddPayoutComponent.PaidAmount",
        defaultMessage: "Paid Amount"
    }
})