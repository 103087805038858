/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext, useCallback } from 'react';
import _ from 'lodash';
import { ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { TranslatorContext } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WMICAddressDetails, WMICContactDetails } from 'wmic-pe-components-platform-react';

import WMICBankAccountForm from '../WMICBankAccountForm/WMICBankAccountForm';
import metadata from './WMICChangeMonthlyAutomatedForm.metadata.json5';
import messages from './WMICChangeMonthlyAutomatedForm.messages';

const NEW_PAYMENT_INSTRUMENT_ID = '-1';
const NEW_PERSON_CONTACT_ID = '-1';
const NEW_COMPANY_CONTACT_ID = '-2';

function WMICChangeMonthlyAutomatedForm(props) {
    const {
        id,
        onValidate,
        availablePaymentPlansWithInvoices,
        createPersonContact,
        createCompanyContact,
        viewModelService,
        eftVM,
        updateEftVM,
        newContactVM,
        updateNewContactVM,
        updateSelectedContactPublicId,
        selectedPayer,
        updateSelectedPayer,
        selectedContact,
        updateSelectedContact,
        showOnlyEftData
    } = props;
    const { onValidate: setComponentValidation, isComponentValid, disregardFieldValidation } = useValidation(id);
    const translator = useContext(TranslatorContext);
    const { refreshData } = useDataRefresh()
    const { authHeader } = useAuthentication();
    const [showErrors, updateShowErrors] = useState(false);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid])        
    
    const availablePaymentInstruments = () => {
        const paymentInstruments = _.get(availablePaymentPlansWithInvoices, "availableAutoRecurringPaymentInstruments", []);
        const paymentInstrumentsList =  paymentInstruments.map((instrument, index) => {
            return {
                code: instrument.publicID,
                name: instrument.displayName
            };
        });
        const newPaymentInstrument = {
            code: NEW_PAYMENT_INSTRUMENT_ID,
            name: translator(messages.newPaymentInstrument)
        };
        return [newPaymentInstrument ,...paymentInstrumentsList];
    };    
    
    const availableContacts = () => {
        const eligibleContacts = _.get(availablePaymentPlansWithInvoices, "eligibleContacts", []);
        const contactsList = [];
        for(let i = 0; i < eligibleContacts.length; i++) {
            contactsList.push({
                code: eligibleContacts[i].publicID,
                name: eligibleContacts[i].displayName
            });
        }
        contactsList.push({
            code: NEW_PERSON_CONTACT_ID,
            name: translator(messages.newPerson)
        });
        contactsList.push({
            code: NEW_COMPANY_CONTACT_ID,
            name: translator(messages.newCompany)
        });
        return contactsList;
    };
    
    const onSelectPayerChange = (value) => {
        if (value === NEW_PAYMENT_INSTRUMENT_ID) {
            const eftData = viewModelService.create(
                {
                    bankName: '',
                    bankAccountNumber: '',
                    transitNumber: '',
                    accountHolder: '',
                    institutionNumber: '',
                    withdrawalDayOfMonth: 1
                },
                'bc',
                'wmic.edge.ca.capabilities.billing.dto.PaymentPlanChangeEFTDataDTO'
            );
            updateEftVM(eftData);
        } else {
            disregardFieldValidation('bankAccountForm');
            updateNewContactVM(undefined);
        }
        updateSelectedContact(undefined);
        updateSelectedContactPublicId(undefined);
        updateSelectedPayer(value);
    }    
    
    const onSelectContactChange = useCallback((value) => {
        updateSelectedContact(value);
        if(value !== NEW_PERSON_CONTACT_ID && value !== NEW_COMPANY_CONTACT_ID) {
            const eligibleContacts = _.get(availablePaymentPlansWithInvoices, "eligibleContacts", []);
            const selectedContactObj = _.find(eligibleContacts, (contact) => contact.publicID === value);
            const selectedContactName = _.get(selectedContactObj, 'displayName', '');
            _.set(eftVM, "accountHolder.value", selectedContactName);
            updateNewContactVM(undefined);
            updateSelectedContactPublicId(value);
        } else {
            _.set(eftVM, "accountHolder.value", "");
            if (value === NEW_PERSON_CONTACT_ID) {
                createPersonContact()
            } else {
                createCompanyContact();
            }
            updateSelectedContactPublicId(undefined);
        }
        updateEftVM(eftVM);
    }, [availablePaymentPlansWithInvoices, createCompanyContact, createPersonContact, eftVM, updateEftVM, updateNewContactVM, updateSelectedContact, updateSelectedContactPublicId]) 

    const isNewPaymentInstrumentSelected = useCallback(() => selectedPayer === NEW_PAYMENT_INSTRUMENT_ID, [selectedPayer]);

    const onEftVMChange = (newVM) => {
        updateEftVM(newVM);
        refreshData();
    };

    const setContactVM = (contactVM) => {
        _.set(newContactVM, 'value', contactVM.value);
        updateNewContactVM(newContactVM);
        refreshData();
    };

    const setPrimaryAddress = (addressVM) => {
        _.set(newContactVM, 'primaryAddress', addressVM.value);
        updateNewContactVM(newContactVM);
        refreshData();
    };

    const overrideProps = {
        '@field': {
            parentNode: eftVM,
        },
        payerContainer: {
            visible: !showOnlyEftData
        },
        selectPayerField: {
            availableValues: availablePaymentInstruments(),
            value: selectedPayer
        },        
        selectContacField: {
            visible: isNewPaymentInstrumentSelected(),
            availableValues: availableContacts(),
            value: selectedContact
        },   
        bankAccountInfoContainer: {
            visible: (selectedPayer === NEW_PAYMENT_INSTRUMENT_ID && !_.isNil(selectedContact)) || showOnlyEftData,
        },
        bankAccountForm: {
            eftVM,
            updateEftVM: onEftVMChange,
            onValidate: setComponentValidation,
            authHeader,
            disableAccountOwnerName: showOnlyEftData
        },
        newContactContainer: {
            visible: !_.isNil(newContactVM)
        },
        personContactDetailsComponent: {
            onValidate: setComponentValidation,
            contactVM: newContactVM,
            setContactVM,
            hideBorder: true, 
            hideButtons: true,
            showContactInfo: false
        },
        primaryAddressDetailsComponent: {
            onValidate: setComponentValidation,
            addressVM: _.get(newContactVM, 'primaryAddress'),
            setAddressVM: setPrimaryAddress,
            hideBorder: true, 
            hideButtons: true
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            onSelectPayerChange,
            onSelectContactChange
        },
        resolveComponentMap: {
            WMICAddressDetails,
            WMICContactDetails,
            WMICBankAccountForm
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

WMICChangeMonthlyAutomatedForm.defaultProps = {
    showOnlyEftData: false
};
export default WMICChangeMonthlyAutomatedForm;
