import { defineMessages } from 'react-intl';

export default defineMessages({
    coverageTypeChangedToBasicForRisk: {
        id: 'quoteandbind.ho.views.ho-your-home.Coverage type changed to basic for risk',
        defaultMessage: 'Dwelling #{riskNumber}: {riskType}: at {riskAddress}: Coverage type has been modified to Basic.'
    },
    coverageTypeChangedToBasic: {
        id: 'quoteandbind.ho.views.ho-your-home.Coverage type changed to basic',
        defaultMessage: 'Coverage type has been modified to Basic.'
    },
    important: {
        id: 'quoteandbind.modal.Important',
        defaultMessage: 'Important'
    },
    dueToEligibilityChangesMade: {
        id: 'quoteandbind.modal.Due to eligibility requirements, the following changes have been made:',
        defaultMessage: 'Due to eligibility requirements, the following changes have been made:'
    },
    ok: {
        id: 'quoteandbind.modal.Ok',
        defaultMessage: 'Ok'
    }
});
