import React, { useEffect, useContext, useState } from 'react'
import _ from 'lodash'
import { ViewModelForm, useDataRefresh, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import {TranslatorContext} from '@jutro/locale';
import metadata from './WMICPUPVehicleExposureForm.metadata.json5';
import messages from '../WMICPUPVehicleExposures.messages.js';

function WMICPUPVehicleExposureForm(props) {
    const {
        id,
        exposureVM,
        setExposureVM,
        cancelExposure,
        saveExposure,
        isEditing,
        isParentUnderEditing,
        onValidate,
        showErrors,
        excessPolicyAvailableValues,
        onExcessPolicyChange,
        excessPolicyValue
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const { refreshData } = useDataRefresh();
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const [vehicleTypeAvailableValues, setvehicleTypeAvailableValues] = useState([]);
    const translator = useContext(TranslatorContext);

    useEffect(() => {
        const vehicleTypeDropdownAvailableValues = viewModelService.productMetadata.get('pc').types.getTypelist('PUPVehicleType_PUE').getFilter('BluePassAllowedVehicleTypes_WMIC').codes.map((item) => {
            const vehicleType = {
                code: item.code,
                name: translator({id: item.name})
            }
            return vehicleType;
        });
        setvehicleTypeAvailableValues(vehicleTypeDropdownAvailableValues);
    }, [translator, viewModelService.productMetadata]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [isComponentValid, onValidate, id]);

    const updateExposure = (exposure) => {
        setExposureVM(exposure);
        refreshData();
    };

    const overrideProps = {
        '@field': {
            parentNode: exposureVM,
            readOnly: !isEditing || !isParentUnderEditing
        },
        vehicleExposureContainerButtons: {
            onCancel: cancelExposure,
            onSave: saveExposure,
            isEditing,
        },
        vehicleExcessPolicy: {
            onValueChange: onExcessPolicyChange,
            availableValues: excessPolicyAvailableValues,
            defaultValue: excessPolicyAvailableValues[0]?.code,
            value: excessPolicyValue
        },
        vehicleType: {
            availableValues: vehicleTypeAvailableValues
        }
    }
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={exposureVM}
            onModelChange={updateExposure}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

export default WMICPUPVehicleExposureForm;