import { defineMessages } from 'react-intl';

export default defineMessages({
    causeOfLoss: {
        id: "quoteandbind.pa.directives.templates.insurance-history-loss-history-payout.CauseOfLoss",
        defaultMessage: "Cause of Loss"
    },
    payoutStatus: {
        id: "quoteandbind.pa.directives.templates.insurance-history-loss-history-payout.Status",
        defaultMessage: "Status"
    },
    claimOpenReserves: {
        id: "quoteandbind.pa.directives.templates.insurance-history-loss-history-payout.RemainingReserves",
        defaultMessage: "Remaining Reserves"
    },
    lossTotalPaid: {
        id: "quoteandbind.pa.directives.templates.insurance-history-loss-history-payout.PaidAmount",
        defaultMessage: "Paid Amount"
    }
})