import { useContext } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import  messages from './WMICCPCoverageUtil.messages';


function getCoveragesForLocation(commercialPropertyLine, locationPublicId) {
    const locationCoverages = commercialPropertyLine.offerings.children[0].coverages.locationCoverages.children.find(
        (locationCoverage) => {
            return locationCoverage.value.publicID === locationPublicId;
        }
    );

    return locationCoverages ?? [];
}

function getCoveragesForBuilding(commercialPropertyLine, buildingPublicId) {
    const buildingCoverages = commercialPropertyLine.offerings.children[0].coverages.buildingCoverages.children.find(
        (buildingCoverage) => {
            return buildingCoverage.value.publicID === buildingPublicId;
        }
    );

    return buildingCoverages ?? [];
}

function categorizeLocationCoverages(locationCoverages, onlySelected = false) {
    const translator = useContext(TranslatorContext);
    const allCoverages = {
        locationcoverages: {
            categoryID: 'locationcoverages',
            categoryName: translator(messages.businesIncome) ,
            coverages: []
        },
        additionallocationcoverages: {
            categoryID: 'additionallocationcoverages',
            categoryName: translator(messages.additionallocationcoverages) ,
            coverages: []
        }
    };

    const filteredCoverages = onlySelected? _.filter(locationCoverages.coverages, {'selected': true}): locationCoverages.coverages;

    filteredCoverages.forEach((coverage) => {
        switch (coverage.category_WMIC) {
            case 'CP_LocationStandardCov_WMIC':
                allCoverages.locationcoverages.coverages.push(coverage);

                if (coverage.publicID === "CP_BusinessIncome_Profits_ALSCov_WMIC") {
                    allCoverages.locationcoverages.categoryName = translator(messages.businesIncomeLoss);
                }
                break;
            case 'CP_LocationAdditionalCov_WMIC':
                allCoverages.additionallocationcoverages.coverages.push(coverage);
                break;
            default:
                break;
        }
    });

    return allCoverages;
}

function categorizeBuildingCoverages(buildingCoverages, onlySelected = false) {
    const translator = useContext(TranslatorContext);
    const allCoverages = {
        buildingCoverages: {
            categoryID: 'buildingCoverages',
            categoryName: translator(messages.building) ,
            coverages: []
        },
        buildingContents: {
            categoryID: 'buildingContents',
            categoryName: translator(messages.buildingContents) ,
            coverages: []
        },
        additionalBuildingCoverages: {
            categoryID: 'additionalBuildingCoverages',
            categoryName: translator(messages.additionalBuildingCoverages) ,
            coverages: []
        }
    };

    const filteredCoverages = onlySelected? _.filter(buildingCoverages.coverages, {'selected': true}): buildingCoverages.coverages;

    filteredCoverages.forEach((coverage) => {
        switch (coverage.category_WMIC) {
            case 'CPBldgCovCategory':
                switch(coverage.publicID) {
                    case "CP_BusinessContentsCov_WMIC":
                        allCoverages.buildingContents.coverages.push(coverage);
                        break;
                    default:
                        allCoverages.buildingCoverages.coverages.push(coverage);
                        break;
                }
                break;
            case 'CP_BuildingAdditionalCov_WMIC':
                allCoverages.additionalBuildingCoverages.coverages.push(coverage);
                break;
            default:
                break;
        }
    });

    return allCoverages;
}

function categorizePropertyLineCoverages(lineCoverages, onlySelected = false) {
    const translator = useContext(TranslatorContext);
    const allCoverages = {
        extraExpense: {
            categoryName: translator(messages.extraExpense) ,
            coverages: []
        },
        propertyOffPremise: {
            categoryName: translator(messages.propertyOffPremise) ,
            coverages: []
        },
    };

    const filteredCoverages = onlySelected? _.filter(lineCoverages, {'selected': true}): lineCoverages;

    filteredCoverages.forEach((coverage) => {
        switch (coverage.category_WMIC) {
            case 'CP_ExtraExpenseCov_WMIC':
                allCoverages.extraExpense.coverages.push(coverage);
                break;
            case 'CP_PrprtyPrmsIncldngCov_WMIC':
                allCoverages.propertyOffPremise.coverages.push(coverage);
                break;
            default:
                break;
        }
    });

    return allCoverages;
}

function generateColumnData (submissionVM) {
    const lobOfferingPath = 'lobData.commercialProperty.offerings';
    const quoteOfferingPath = 'quoteData.offeredQuotes';

    const lobOfferings = _.get(submissionVM, `${lobOfferingPath}.value`);
    const quoteOfferings = _.get(submissionVM, `${quoteOfferingPath}.value`) || [];

    const columnData = lobOfferings
        .map((lobOffering, lobIndex) => {
            const quoteDataIndex = quoteOfferings.findIndex(
                (qdOffering) => qdOffering.branchCode === lobOffering.branchCode
            );
            const quoteData = quoteOfferings[quoteDataIndex];

            return {
                name: lobOffering.branchName,
                code: lobOffering.branchCode,
                quote: {
                    path: `${quoteOfferingPath}.children[${quoteDataIndex}]`,
                    data: quoteData
                },
                lob: {
                    path: `${lobOfferingPath}.children[${lobIndex}]`,
                    data: lobOffering
                }
            };
        })
        .filter(({ quote }) => !_.isUndefined(quote.data));

    return _.sortBy(columnData, ['code']);
};

export default {
    categorizeLocationCoverages,
    categorizeBuildingCoverages,
    categorizePropertyLineCoverages,
    getCoveragesForLocation,
    getCoveragesForBuilding,
    generateColumnData
};
