import React, { useEffect, useContext, useState } from 'react'
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import {BreakpointTrackerContext} from '@jutro/layout';
import { DeviceBreakpoint, Position } from 'wmic-pe-portals-utils-js';

import metadata from './WMICVehicleAddDriverComponent.metadata.json5'

function WMICVehicleAddDriverComponent(props) {
    const {
        id,
        vehicleVM,
        saveDriver,
        cancelDriver,
        onValidate,
        isEditMode,
        isEditDriverMode,
        isReadOnlyUser,
        updateVehicle,
        availableDrivers,
        availableDriverTypes
    } = props

    const breakpoint = useContext(BreakpointTrackerContext);

    const {onValidate: setComponentValidation, isComponentValid} = useValidation(id);
    const [showErrors, updateShowErrors] = useState(false);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => {
            if (onValidate) {
                onValidate(true, id);
            }
        }
    }, [isComponentValid, onValidate, vehicleVM]);

    const handleSaveDriver = (currentDriver) => {
        if(isComponentValid) {
            saveDriver(currentDriver)
        } else {
            updateShowErrors(true)
        }
    };

    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === DeviceBreakpoint.DESKTOP ? Position.LEFT : Position.TOP,
            parentNode: vehicleVM,
            showRequired: true,
            readOnly: !isEditMode || isReadOnlyUser,
        },
        selectedDriver: {
            availableValues: availableDrivers,
            controlType: "dropdown",
            readOnly: isEditDriverMode
        },
        driverType: {
            availableValues: availableDriverTypes
        }
    }

    const resolvers = {
        resolveCallbackMap: {
            saveDriver: handleSaveDriver,
            cancelDriver
        }
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleVM}
            overrideProps={overrideProps}
            onModelChange={updateVehicle}
            callbackMap={resolvers.resolveCallbackMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    )
}

export default WMICVehicleAddDriverComponent;
