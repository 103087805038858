import { defineMessages } from 'react-intl';

export default defineMessages({
    liabilityProtection: {
        id: 'wmic-pe-capability-gateway-common-pa-react.util.liability-protection',
        defaultMessage: 'Liability Protection'
    },
    underageMaleDriver: {
        id: 'wmic-pe-capability-gateway-common-pa-react.util.underageMaleDriver',
        defaultMessage: 'Underage Male Driver'
    },
    underageGenderXOrFemaleDriver: {
        id: 'wmic-pe-capability-gateway-common-pa-react.util.underageGenderXOrFemaleDriver',
        defaultMessage: 'Underage Gender X or Female Driver'
    },
    physicalDamage: {
        id: 'wmic-pe-capability-gateway-common-pa-react.util.physical-damage',
        defaultMessage: 'Physical Damage'
    },
    vehicleEndorsements: {
        id: 'wmic-pe-capability-gateway-common-pa-react.util.vehicle-endorsements',
        defaultMessage: 'Vehicle Endorsements'
    },
    motorcycleCoverages: {
        id: 'wmic-pe-capability-gateway-common-pa-react.util.motorcycleCoverages',
        defaultMessage: 'Motorcycle Coverage'
    },
    commercialCoverages: {
        id: 'wmic-pe-capability-gateway-common-pa-react.util.Commercial Auto Line Coverages',
        defaultMessage: 'Commercial Auto Line Coverages'
    },
    optionalAccidentBenefitsCoverages: {
        id: 'wmic-pe-capability-gateway-common-pa-react.util.optionalAccidentBenefitsCoverages',
        defaultMessage: 'Optional Accident Benefits'
    }
});
