import { defineMessages } from 'react-intl';

export default defineMessages({
    addNamedInsured: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.addNamedInsured',
        defaultMessage: 'Add named insured'
    },
    nit: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.Named Insured Type',
        defaultMessage: 'Named Insured Type'
    },
    insured: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.Insured',
        defaultMessage: 'Insured'
    },
    primaryNamedInsured: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.Primary Named Insured',
        defaultMessage: 'Primary Named Insured'
    },
    primaryNamedInsuredYes: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.Yes',
        defaultMessage: 'Yes'
    },
    primaryNamedInsuredNo: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.No',
        defaultMessage: 'No'
    },
    person: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.Person',
        defaultMessage: 'Person'
    },
    pniRelation: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.Relationship to Primary Named Insured',
        defaultMessage: 'Relationship to Primary Named Insured'
    },
    fName: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.First Name',
        defaultMessage: 'First Name'
    },
    mName: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.Middle Name',
        defaultMessage: 'Middle Name'
    },
    lName: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.Last Name',
        defaultMessage: 'Last Name'
    },
    dob: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.Date of Birth',
        defaultMessage: 'Date of Birth'
    },
    estate: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.Estate of',
        defaultMessage: 'Estate of'
    },
    aniReceive: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.ANI Credit Consent Received',
        defaultMessage: 'Additional Named Insured Credit Consent Received'
    },
    credConsentDate: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.Credit Consent Date',
        defaultMessage: 'Credit Consent Date'
    },
    piConsentForm: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.Personal Information and Consent Form',
        defaultMessage: 'Consent Form Received?'
    },
    credDeclineDate: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.Credit Declined Date',
        defaultMessage: 'Date Declined or Withdrawn'
    },
    credWithdrawConsent: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.Credit Information Withdrawal of Consent',
        defaultMessage: 'Withdrawal of Consent Form Received?'
    },
    homeAddress: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.Home Address',
        defaultMessage: 'Home Address'
    },
    company: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.Company',
        defaultMessage: 'Company'
    },
    name: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.Name',
        defaultMessage: 'Name'
    },
    companyName: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.Company Name',
        defaultMessage: 'Company Name'
    },
    sameAdd: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.Same Address',
        defaultMessage: 'Location Address Same as Primary Named Insured?'
    },
    relationship: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.Relationship',
        defaultMessage: 'Relationship'
    },
    removeANILong: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.Remove Insured Long',
        defaultMessage: 'Are you sure you want to remove {displayName} additional named insured from the list?'
    },
    removeANIShort: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.Remove Insured Short',
        defaultMessage: 'Remove Insured'
    },
    address: {
        id: 'wmic-pe-capability-gateway-common-react.AdditionalInsuredComponent.Adress',
        defaultMessage: 'Address'
    }
})
