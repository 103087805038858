import { defineMessages } from 'react-intl';

export default defineMessages({
    householdMemberTitle: {
        id: 'quoteandbind.pup.views.householdMemberDetails.HouseholdMembers',
        defaultMessage: "Household Members"
    },
    wmicPUPHouseholdMembersTitle: {
        id: 'quoteandbind.pup.views.householdMemberDetails.AddMember',
        defaultMessage: 'Add Household Member'
    },
    wmicPUPHouseholdMembersPrimaryNamedInsured: {
        id: 'quoteandbind.views.quote-effective-date.PrimaryNamedInsured',
        defaultMessage: 'Primary Named Insured'
    },
    wmicPUPHouseholdMembersName: {
        id: 'quoteandbind.views.quote-effective-date.Name',
        defaultMessage: 'Name'
    },
    wmicPUPHouseholdMembersRelationship: {
        id: 'quoteandbind.views.quote-effective-date.Relationship',
        defaultMessage: 'Relationship'
    },
    wmicPUPHouseholdMembersDateOfBirth: {
        id: 'quoteandbind.pup.views.household.Date of Birth',
        defaultMessage: 'Date of Birth'
    },
    wmicPUPHouseholdMembersRemovingHouseholds: {
        id: 'quoteandbind.pup.views.household.RemovingHouseholds',
        defaultMessage: 'Are you sure you want to remove a household member?'
    },
    wmicPUPHouseholdMembersPrimaryNamedInsuredCannotBeRemoved: {
        id: 'quoteandbind.pup.views.household.PrimaryNamedInsuredCannotBeRemoved',
        defaultMessage: 'Primary Named Insured cannot be removed from household members.'
    }
});
