import React, { useContext, useCallback, useMemo, useState , useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { TranslatorContext } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { WMICRichTextUtil, CONSTANTS, ACTION_TYPES, WMICAddressDetailsUtil } from 'wmic-pe-portals-utils-js';
import WMICPUPWatercraftExposureForm from './WMICPUPWatercraftExposureForm/WMICPUPWatercraftExposureForm';

import metadata from './WMICPUPWatercraftExposures.metadata.json5';
import messages from './WMICPUPWatercraftExposures.messages.js'

function WMICPUPWatercraftExposures(props) {
    const {
        id,
        exposuresVM,
        isUnderEditing,
        jobVM,
        updateExposuresVM,
        showErrorsParent
    } = props;

    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { isComponentValid, onValidate } = useValidation(id);
    const { showConfirm } = useWizardModals();

    const [exposureVM, setExposureVM] = useState();
    const [exposureIndex, setExposureIndex] = useState(-1);
    const [accountAddressOptions, setAccountAddressOptions] = useState([]);
    const [backupExposure, setBackupExposure] = useState();
    const [exposureAction, setExposureAction] = useState(ACTION_TYPES.NONE);
    const [showErrors, setShowErrors] = useState(false);

    const addExposure = () => {
        const exposure = viewModelService.create(
            { isBeingAdded: true, isUnderEditing: true },
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.personalumbrella.coverables.dto.exposure.WatercraftExposureDTO_WMIC',
            jobVM.aspects.context()
        )

        setExposureAction(ACTION_TYPES.ADD);
        setExposureVM(exposure);
    };

    const cancelExposure = () => {
        if(exposureAction === ACTION_TYPES.ADD) {
            setExposureVM(undefined);
        } else {
            _.set(exposuresVM, `value.[${exposureIndex}].watercraftExposure`, backupExposure);
            updateExposuresVM(exposuresVM);

            _.set(exposureVM, 'value', backupExposure);
            setExposureVM(exposureVM);
        };
        setExposureAction(ACTION_TYPES.NONE);
        setShowErrors(false);
    };

    const saveExposure = () => {
        if (isComponentValid) {
            
            if(exposureAction === ACTION_TYPES.ADD) {
                _.set(exposureVM, 'value.watercraftNumber', exposuresVM.children.length + 1);
                exposuresVM.value.push({ watercraftExposure: exposureVM.value});
            }
            else {
                _.set(exposuresVM, `value.[${exposureIndex}].watercraftExposure`, exposureVM.value);
                updateExposuresVM(exposuresVM);
            }
            setExposureVM(undefined);
            setExposureAction(ACTION_TYPES.NONE);
        } else {
            setShowErrors(true);
        }
    };

    const removeExposure = async (exposure, index) => {
        const response = await showConfirm({
            title: translator(messages.wmicUnderlyingPoliciesRemoveWatercraftExposure),
            message: translator(messages.wmicUnderlyingPoliciesRemoveWatercraftExposureConfirmationMsg,
                {number: _.get(exposuresVM, `value[${index}].watercraftExposure.watercraftNumber`)})
        })

        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            if(exposureIndex === index) {
                setExposureVM(undefined);
            }

            exposuresVM.value.splice(index, 1);
            for(let i = index; i < exposuresVM.length; i++) {
                _.set(exposuresVM, `[${i}].watercraftExposure.watercraftNumber`, i + 1);
            }

            updateExposuresVM(exposuresVM);
            setShowErrors(false);
        }
    };

    const selectExposure = (index, edit) => {
        const exposure = exposuresVM.children[index].watercraftExposure

        if(edit) {
            setExposureAction(ACTION_TYPES.EDIT);
            setBackupExposure(_.cloneDeep(exposure.value));
        }

        setExposureIndex(index);
        setExposureVM(exposure);
    };

    const getWatercraftMotorTypeDisplay = useCallback((exposure) => {
        const watercraftMotorType = _.get(exposure, 'value.watercraftExposure.watercraftMotorType_WMIC');
        const watercraftMotorTypeDisplay = watercraftMotorType
            ? viewModelService.productMetadata
                  .get('pc')
                  .types.getTypelist('WatercraftMotorType_WMIC')
                  .getCode(watercraftMotorType).name
            : '';

        return translator({id: watercraftMotorTypeDisplay});
    }, [translator, viewModelService]);

    const overrideProps =  {
        requiredOneExposure: {
            visible: exposuresVM.children.length === 0
        },
        watercraftExposuresDataList: {
            VMList: exposuresVM.children,
            VMData: [
                {
                    headerText: translator(messages.wmicUnderlyingPoliciesMotorType),
                    getData: getWatercraftMotorTypeDisplay
                }
            ],
            onEditAction: (exposure, index) => selectExposure(index, true),
            onRemoveAction: removeExposure,
            updateSelectedCardIndex: (index) => selectExposure(index),
            selectedCardIndex: exposureIndex,
            isEditing: exposureAction === ACTION_TYPES.ADD || exposureAction === ACTION_TYPES.EDIT,
            readOnly: !isUnderEditing
        },
        addWatercraftExposureButton: {
            disabled: exposureAction === ACTION_TYPES.ADD || exposureAction === ACTION_TYPES.EDIT || !isUnderEditing
        },
        watercraftExposureForm: {
            visible: !_.isUndefined(exposureVM),
            exposureVM,
            setExposureVM,
            accountAddressOptions,
            setAccountAddressOptions,
            cancelExposure,
            saveExposure,
            isEditing: exposureAction === ACTION_TYPES.ADD || exposureAction === ACTION_TYPES.EDIT,
            isParentUnderEditing: isUnderEditing,
            showErrors: showErrors || showErrorsParent,
            onValidate
        },

    }

    const resolvers = {
        resolveCallbackMap: {
            addExposure
        },
        resolveComponentMap: {
            WMICPUPWatercraftExposureForm
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={exposuresVM}
            onValidationChange={onValidate}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors || showErrorsParent}
        />
    );
}

WMICPUPWatercraftExposures.propTypes = {
    exposuresVM: PropTypes.object.isRequired,
    accountAddresses: PropTypes.array.isRequired,
    hasWatercraft: PropTypes.bool.isRequired,
    isUnderEditing: PropTypes.bool.isRequired,
    jurisdiction: PropTypes.string.isRequired,
    readOnly: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool,
};

WMICPUPWatercraftExposures.defaultProps = {
};

export default WMICPUPWatercraftExposures;
