import { defineMessages } from 'react-intl';

export default defineMessages({
    wmicTLAMandatoryMsg: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Required To Bind",
        defaultMessage: "This is required to bind the policy"
    },
    wmicHomeTiedDownMandatoryMsg: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Required To Bind",
        defaultMessage: "This is required to bind the policy"
    },
    wmicFinishPercentMsg: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Finish percentage not 100",
        defaultMessage: "Finish Type Percentage does not add up to 100%",
    },
    wmicFramePercentMsg: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Frame percentage not 100",
        defaultMessage: "Frame Type Percentage does not add up to 100%",
    },
    frameTypeRequired: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.One Framing Type required",
        defaultMessage: "At least <b>one Framing Type</b> is required"
    },
    bathroomRequired: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.One Bathroom required",
        defaultMessage: "At least <b>one Bathroom</b> is required"
    },
    kitchenRequired: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.One Kitchen required",
        defaultMessage: "At least <b>one Kitchen</b> is required"
    },
    showKitchenBathMsg: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.one Kitchens and one Bathroom required",
        defaultMessage: "At least <b>one Kitchen</b> and <b>one Bathroom</b> are required!"
    },
    evaluatorType: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Evaluator Type",
        defaultMessage: "Evaluator Type"
    },
    EvaluatorDescription: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Describe Other",
        defaultMessage: "Describe Other"
    },
    dateEvaluated: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Date Evaluated",
        defaultMessage: "Date Evaluated"
    },
    estimatedReplacementCost: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Estimated Replacement Cost",
        defaultMessage: "Estimated Replacement Cost $"
    },
    foundationType: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Foundation Type",
        defaultMessage: "Foundation Type"
    },
    yearBuilt: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Year Built",
        defaultMessage: "Year Built"
    },
    livingArea: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Total Living Area Excluding Basement",
        defaultMessage: "Total Living Area (Excluding Basement)"
    },
    measurementUnit: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Measurement Unit",
        defaultMessage: "Measurement Unit"
    },
    mobileHomeInfoLength: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Length",
        defaultMessage: "Length"
    },
    mobileHomeInfoWidth: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Width",
        defaultMessage: "Width"
    },
    constructionStyle: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Style",
        defaultMessage: "Style"
    },
    mobileHomeInfoMake: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Make",
        defaultMessage: "Make"
    },
    mobileHomeInfoModel: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Model",
        defaultMessage: "Model"
    },
    mobileHomeInfoSerialNumber: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Serial Number",
        defaultMessage: "Serial Number"
    },
    mobileHomeInfoPurchasedNew: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Purchased New",
        defaultMessage: "Purchased New"
    },
    mobileHomeInfoFactoryBuilt: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Factory Built",
        defaultMessage: "Factory Built"
    },
    mobileHomeInfoHomeTiedDown: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Home Tied Down",
        defaultMessage: "Home Tied Down"
    },
    mobileHomeInfoSkirtType: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Type of Skirting",
        defaultMessage: "Type of Skirting"
    },
    storiesNumber: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Number of Stories_WMIC",
        defaultMessage: "Number of Stories"
    },
    storiesNumberInBuilding: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Number of Stories in Building",
        defaultMessage: "Number of Stories in Building"
    },
    unitsNumber: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Number of Units",
        defaultMessage: "Number of Units in Building"
    },
    constructionElevation: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.elevation",
        defaultMessage: "Is your Unit located at the Ground level, partially, or fully below ground level?"
    },
    constructionFloor: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.floor",
        defaultMessage: "What floor is your unit located on?"
    },
    mobileHomeInfoMobileHomePark: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Mobile Home Location Type",
        defaultMessage: "Mobile Home Location Type"
    },
    fullFoundation: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Full Foundation",
        defaultMessage: "Full Foundation"
    },
    kitchensBathTitle: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Kitchens and Baths",
        defaultMessage: "Kitchens & Baths"
    },
    addKitchenBathButton: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Add Kitchen/Bath",
        defaultMessage: "Add Kitchen/Bath"
    },
    kitchenDisplayName: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Kitchen",
        defaultMessage: "Kitchen"
    },
    bathDisplayName: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Bath",
        defaultMessage: "Bath"
    },
    removeKitchenBathConfirmationTitle: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.KitchensAndBaths.RemoveKitchenBath",
        defaultMessage: "Remove {kitchenBath}?",
    },
    removeKitchenBathConfirmationMessage: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.KitchensAndBaths.RemoveKitchenBathConfirmationMsg",
        defaultMessage: "Are you sure you want to remove this {kitchenBath}?"
    },
    exteriorWallsTitle: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Exterior Walls",
        defaultMessage: "Exterior Walls"
    },
    addExteriorWallButton: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Add Wall Type",
        defaultMessage: "Add Wall Type"
    },
    removeExteriorWall: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.RemoveExteriorWall",
        defaultMessage: 'Remove exterior wall?'
    },
    basementContainerTitle: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Basement",
        defaultMessage: "Basement"
    },
    basementTotalArea: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Total Area",
        defaultMessage: "Total Area"
    },
    basementPercentFinished: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Percentage Finished",
        defaultMessage: "Percentage Finished"
    },
    basementBedroomNumber: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Number of Bedrooms",
        defaultMessage: "Number of Bedrooms (Basement)"
    },
    basementWalkout: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICHOConstructionDetails.Walk-out Basement",
        defaultMessage: "Walk-out Basement"
    }
});
