import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { WMICDateTimeService } from 'wmic-pe-portals-utils-js';

import {TranslatorContext} from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import messages from './WMICPolicyPaLicenseInfoComponent.messages';
import metadata from './WMICPolicyPaLicenseInfoComponent.metadata.json5';

const DRIVER_PATHS = {
    DRIVER_CLASS_HISTORY: 'driverClassHistory_WMIC',
    DRIVER_CLASS_TYPE: 'driverClassType_WMIC',
    ASSIGNMENT: 'assignment_WMIC'
}

function WMICPolicyPaLicenseInfoComponent(props) {
    const { driverVM } = props;

    const translator = useContext(TranslatorContext);

    const displayDriverClassDate = (data) => {
        return data.dateLicensed?.value ? <span>{WMICDateTimeService.toMidnightDate(data.dateLicensed.value)}</span> : ''
    };

    const overrideProps = {
        '@field': {
            parentNode: driverVM,
            readOnly: true
        },
        driverClassHistory: {
            driverVM
        },
        driverClassHistoryListView: {
            VMData: [
                {
                    headerText: translator(messages.driverClassType),
                    path: DRIVER_PATHS.DRIVER_CLASS_TYPE
                },
                {
                    headerText: translator(messages.driverClassDateObtained),
                    getData: displayDriverClassDate
                }
            ],
            clickable: false,
            readOnly: true,
            renderAddButton: false,
            startOpen: false
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={driverVM}
            overrideProps={overrideProps}
        />
    );
}

WMICPolicyPaLicenseInfoComponent.propTypes = {
    driverVM: PropTypes.shape({
        person: PropTypes.shape({
            publicID: PropTypes.shape({})
        })
    }).isRequired
};

export default WMICPolicyPaLicenseInfoComponent;
