import React from 'react';
import _ from 'lodash'
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICWizardViewPage } from 'wmic-pe-portals-wizard-components-ui';

import WMICPAPolicyVehicleCoverages from '../../../../components/WMICPAPolicyVehicleCoverages/WMICPAPolicyVehicleCoverages';
import WMICPAPolicyLevelCoverages from '../../../../components/WMICPAPolicyLevelCoverages/WMICPAPolicyLevelCoverages';
import WMICPAPolicyDriverEndorsements from '../../../../components/WMICPAPolicyDriverEndorsements/WMICPAPolicyDriverEndorsements';

import messages from './WMICPAPolicyCoveragesPage.messages'
import metadata from './WMICPAPolicyCoveragesPage.metadata.json5';
import styles from './WMICPAPolicyCoveragesPage.modules.scss'

function WMICPAPolicyCoveragesPage(props) {
    const {
        wizardData: { policyVM },
    } = props;

    const _iterableProps = () => {
        const vehicleOverrides = _.get(policyVM, "lobs.personalAuto.vehicleDTOs.children", []).map((vehicle, index) => {
            const vehicleOverride = {
                [`vehicleCoveragesIterableComponent${index}`]: {
                    'selectedVehicle': vehicle,
                    'selectedVehicleIndex': index,
                }
            }

            return Object.assign({}, vehicleOverride)
        })

        return Object.assign({}, ...vehicleOverrides)
    }

    const overrideProps = {
        '@field': {
            parentNode: policyVM,
            readOnly: true
        },
        vehicleCoveragesDetailsAccordion: {
            data: _.get(policyVM, "lobs.personalAuto.vehicleDTOs.children", [])
        },
        policyLevelCoveragesComponent: {
            accountHolder: _.get(policyVM, "accountHolder.value"),
            policyVM
        },
        ..._iterableProps()
    };

    const resolvers = {
        resolveComponentMap: {
            WMICPAPolicyVehicleCoverages,
            WMICPAPolicyLevelCoverages,
            WMICPAPolicyDriverEndorsements
        },
        resolveClassNameMap: styles
    }

    return (
        <WMICWizardViewPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyVM}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveClassNameMap}
            />
        </WMICWizardViewPage>
    );
}

WMICPAPolicyCoveragesPage.propTypes = wizardProps;
export default WMICPAPolicyCoveragesPage;
