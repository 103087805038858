/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import { ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { DateUtil } from 'wmic-pe-portals-utils-js';
import metadata from './WMICAddDeclinedPolicyComponent.metadata.json5';

function WMICAddDeclinedPolicyComponent(props) {
    const { id, declinedPolicy, onValidate, saveDeclinedPolicy, cancelDeclinedPolicy, carriersList, showErrors: pShowErrors } = props;
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    const { refreshData } = useDataRefresh();
    const [currentDeclinedPolicy, updateCurrentDeclinedPolicy] = useState(declinedPolicy);
    const [showErrors, updateShowErrors] = useState(false);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
        return () => onValidate ? onValidate(true, id) : undefined 
    }, [id, onValidate, isComponentValid])

    const handleSaveDeclinedPolicy = useCallback(() => {
        if(isComponentValid) {
            saveDeclinedPolicy(currentDeclinedPolicy)
        } else {
            updateShowErrors(true)
        }
    }, [currentDeclinedPolicy, isComponentValid, saveDeclinedPolicy]);
    
    const updateDeclinedPolicy = (data) => {
        refreshData();
        updateCurrentDeclinedPolicy(data);
    };

    const getDeclineMaxDate = () => {
        return DateUtil.addYears(new Date(), 1);
    }

    const overrideProps = {
        '@field': {
            parentNode: declinedPolicy,
        },
        declineDate: {
            maxDate: getDeclineMaxDate()
        },
        carrierName: {
            availableValues: carriersList
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            handleSaveDeclinedPolicy,
            cancelDeclinedPolicy
        }
    };

    return (
        <ViewModelForm
            model={declinedPolicy}
            onModelChange={updateDeclinedPolicy}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors || pShowErrors}
        />
    );
}

export default WMICAddDeclinedPolicyComponent;
