import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import _ from 'lodash';

import metadata from './WMICHOBusinessPropertyCovDetailView.metadata.json5';
import '../WMICHOBusinessPropertyCov.messages';

function WMICHOBusinessPropertyCovDetailView(props) {
    const {
        id,
        itemVM,
        saveItem,
        cancelItem,
        onValidate: parentOnValidate,
        showErrors: parentShowErrors
    } = props;

    const { onValidate, isComponentValid } = useValidation(id);
    const [showErrors, updateShowErrors] = useState(false);
    const [currentItem, updateCurrentItem] = useState(itemVM);

    useEffect(()=> {
        if (parentOnValidate) {
            parentOnValidate(isComponentValid, id);
        }
        return () => parentOnValidate ? parentOnValidate(true, id) : undefined;
    }, [parentOnValidate, id, isComponentValid]);

    const handleSaveItem = () => {
        if (isComponentValid) {
            saveItem(currentItem)
        } else {
            updateShowErrors(true)
        }
    };

    const updateItem = (data) => {
        updateCurrentItem(_.clone(data));
    };

    const resolvers = {
        resolveCallbackMap: {
            handleSaveItem,
            cancelItem
        }
    };

    const overrideProps = {
        '@field': {
            parentNode: itemVM
        },
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={itemVM}
            overrideProps={overrideProps}
            onModelChange={updateItem}
            callbackMap={resolvers.resolveCallbackMap}
            onValidationChange={onValidate}
            showErrors={showErrors || parentShowErrors}
        />
    )
}

WMICHOBusinessPropertyCovDetailView.propTypes = {
    id: PropTypes.string.isRequired,
    businessVM: PropTypes.shape({}).isRequired,
    saveBusiness: PropTypes.func.isRequired,
    cancelBusiness: PropTypes.func.isRequired
};

export default WMICHOBusinessPropertyCovDetailView;

