import React from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import _ from "lodash";
import metadata from './WMICScheduledCoverages.metadata.json5';
import './WMICScheduledCoverages.messages';
import WMICCoverageUtil from "../../Coverages/util/WMICCoverageUtil";

function WMICScheduledCoverages(props) {
    const {scheduledItems, scheduledItemsAdjustments, jobVM, isPolicyChangeOrRenewal} = props;

    if (scheduledItems === undefined || scheduledItems.children.length === 0) {
        return null;
    }

    const scheduledItemsToCoverages = () => {
        const scheduledItemsArray = [];
        
        scheduledItems.children.forEach((item) => {
            scheduledItemsArray.push({
                name: isPolicyChangeOrRenewal ? _.get(item, 'name.value') : _.get(item, 'scheduledItemSubType.value.description'),
                deductible: _.get(item, 'deductible.value'),
                limit: _.get(item, 'limit.value'),
                premium: WMICCoverageUtil.formatPremiumAmount(isPolicyChangeOrRenewal ? _.get(item, 'annualPremium.amount.value') : _.get(item, 'premium.value')),
                scheduledItemTypeCode: isPolicyChangeOrRenewal ? _.get(item, 'coveragePatternCode.value') : _.get(item, 'scheduledItemTypeCode.value.code'),
                proRatedDifference: isPolicyChangeOrRenewal ? WMICCoverageUtil.formatPremiumAmount(_.get(item, 'proRatedDifference.amount.value')) : null,
            })
        })

        if (!isPolicyChangeOrRenewal && scheduledItemsAdjustments) {
            scheduledItemsAdjustments.forEach((item) => {
                scheduledItemsArray.push({
                    name: _.get(item, 'name.value'),
                    premium: WMICCoverageUtil.formatPremiumAmount(_.get(item, 'premium.value')),
                    scheduledItemTypeCode: _.get(item, 'scheduledItemTypeCode.value.code'),
                })
            })
        }

        return scheduledItemsArray;
    }

    const overrides = {
        scheduledCoverages: {
            coverageData: scheduledItemsToCoverages(),
            coverageHeaderText: 'Description',
            isPolicyChangeOrRenewal,
            jobVM
        },
    }

    return (
        <ViewModelForm
            model={scheduledItems}
            uiProps={metadata.componentContent}
            overrideProps={overrides}
        />
    );
}

WMICScheduledCoverages.propTypes = {
    coverageList: PropTypes.shape({}).isRequired
};

export default WMICScheduledCoverages;

