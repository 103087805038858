import _ from 'lodash';

const isSameAddress = (address1, address2) => {
    const compareAddress1 = _.clone(address1);
    const compareAddress2 = _.clone(address2);

    if (compareAddress1 && compareAddress2) {
        // we need to strip address type, publicID and isValidationOverridden_Ext to allow for checking the address equality
        compareAddress1.addressType = '';
        compareAddress1.publicID = '';
        compareAddress1.isValidationOverridden_Ext = false;
        compareAddress2.addressType = '';
        compareAddress2.publicID = '';
        compareAddress2.isValidationOverridden_Ext = false;
        
        return _.isEqual(compareAddress1, compareAddress2);
    } 
    
    return false;
};

export default {
    isSameAddress
};