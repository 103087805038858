import { defineMessages } from 'react-intl';

export default defineMessages({
    creditCheckUnsuccessful: {
        id: 'quoteandbind.modal.Credit Check Unsuccessful',
        defaultMessage: 'Credit Check Unsuccessful'
    },
    verifyDateOfBirthAndHomeAddress: {
        id: 'quoteandbind.modal.Before trying again, verify the date of birth and home address for:',
        defaultMessage: '&lt;span&gt;Before trying again, verify the &lt;b&gt;date of birth&lt;/b&gt; and &lt;b&gt;home address&lt;/b&gt; for:&lt;/span&gt; &lt;ul&gt;{insureds}&lt;/ul&gt;&lt;span&gt;If the applicant has recently moved, temporarily change the address to their previous residence.&lt;/span&gt;'
    },
    close: {
        id: 'platform.modal.ModalService.Close',
        defaultMessage: 'Close'
    }
});
