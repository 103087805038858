/* eslint-disable no-undef */
import { PRODUCT } from "wmic-pe-portals-utils-js";
import _ from "lodash";

const addUserDataToGTagManager = (userName, firstName, lastName, displayName, organization) => {
    if (window && window.dataLayer) {
        window.dataLayer.userName = userName;
        window.dataLayer.firstName = firstName;
        window.dataLayer.lastName = lastName;
        window.dataLayer.displayName = displayName;
        window.dataLayer.organization = organization;

        if (typeof decibelInsight === 'function' && typeof dataLayer === 'object') {
            decibelInsight('sendCustomDimension', 'BluePass UserName', dataLayer.userName);
            decibelInsight('sendCustomDimension', 'BluePass FirstName', dataLayer.firstName);
            decibelInsight('sendCustomDimension', 'BluePass LastName', dataLayer.lastName);
            decibelInsight('sendCustomDimension', 'BluePass DisplayName', dataLayer.displayName);
            decibelInsight('sendCustomDimension', 'BluePass Organization', dataLayer.organization);
        }
    }
};

const addPolicyDataToGTagManager = (submissionNumber, policyNumber, productCode, policyData) => {
    addJurisdictionToGTagManager(productCode, policyData);
    if (window && window.dataLayer) {
        window.dataLayer.submissionNumber = submissionNumber;
        window.dataLayer.policyNumber = policyNumber;
        window.dataLayer.productCode = productCode;

        if (typeof decibelInsight === 'function' && typeof dataLayer === 'object') {
            decibelInsight('sendCustomDimension', 'BluePass Submission Number', dataLayer.submissionNumber);
            decibelInsight('sendCustomDimension', 'BluePass Policy Number', dataLayer.policyNumber);
            decibelInsight('sendCustomDimension', 'BluePass Product Code', dataLayer.productCode);
        }
    }
};

const sendJurisdictionDataToGTagManager = (jurisdiction) => {
    if (window && window.dataLayer) {
        window.dataLayer.jurisdiction = jurisdiction;

        if (typeof decibelInsight === 'function' && typeof dataLayer === 'object') {
            decibelInsight('sendCustomDimension', 'BluePass Jurisdiction', dataLayer.jurisdiction);
        }
    }
};

const addJurisdictionToGTagManager = (lob, policyData) => {
    switch (lob) {
        case PRODUCT.PERSONAL_AUTO:
            const vehicleDTOs = _.get(policyData, 'lobs.personalAuto.vehicleDTOs');
            if (vehicleDTOs.length > 0) {
                vehicleDTOs.forEach((vehicle) => {
                    sendJurisdictionDataToGTagManager(vehicle.ratingJurisdiction);
                });
                return;
            }
            break;
        case PRODUCT.HOME_OWNER:
            const dwellingsDTOs = _.get(policyData, 'lobs.homeOwners.dwellingsDTO_WMIC');
            if (dwellingsDTOs.length > 0) {
                dwellingsDTOs.forEach((dwelling) => {
                    sendJurisdictionDataToGTagManager(dwelling.rating.ratingJurisdiction);
                });
                return;
            }
            break;
        default:
            break;
    }

    const accountBaseState = _.get(policyData, 'account.baseStateCode_WMIC');
    sendJurisdictionDataToGTagManager(accountBaseState);
};

export default {
    addUserDataToGTagManager,
    addPolicyDataToGTagManager
};
