import { defineMessages } from 'react-intl';

export default defineMessages({
    coverageTitle: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-info.coverage-title',
        defaultMessage: 'Coverage'
    },
    underlyingPolicyTitle: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-info.underlying-policy-title',
        defaultMessage: 'Underlying Policy'
    },
    wmicUnderlyingPoliciesRisks: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-info.Risks',
        defaultMessage: 'Risks'
    },
    wmicUnderlyingPoliciesInsurer: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-info.Insurer',
        defaultMessage: 'Insurer'
    },
    wmicUnderlyingPolicesPolicy: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-info.Policy',
        defaultMessage: 'Policy'
    },
    personalLiabilityLimit: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-info.Personal Liability Limit',
        defaultMessage: 'Personal Liability Limit'
    },
    HO: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-info.PersonalProperty',
        defaultMessage: 'Personal Property'
    },
    PA: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-info.Automobile',
        defaultMessage: 'Automobile'
    },
    PWL: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-info.Watercraft',
        defaultMessage: 'Watercraft'
    },
    wmicUnderlyingPoliciesPassengerVehicleRisk: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-info.PassengerVehicleRisk',
        defaultMessage: '1 Passenger Vehicle'
    },
    wmicUnderlyingPoliciesExcessPolicy: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-info.ExcessPolicy',
        defaultMessage: 'Excess Policy'
    },
    wmicUnderlyingPoliciesPassengerVehicleRisks: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-info.PassengerVehicleRisks',
        defaultMessage: '{number} Passenger Vehicles'
    },
    wmicUnderlyingPoliciesPersonalPropertyRisk: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-info.PersonalPropertyRisk',
        defaultMessage: '1 Personal Property'
    },
    wmicUnderlyingPoliciesPersonalPropertyRisks: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-info.PersonalPropertyRisks',
        defaultMessage: '{number} Personal Properties'
    },
    wmicUnderlyingPoliciesWatercraftRisk: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-info.WatercraftRisk',
        defaultMessage: '1 Watercraft'
    },
    wmicUnderlyingPoliciesWatercraftRisks: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-info.WatercraftRisks',
        defaultMessage: '{number} Watercraft'
    },
    expires: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-info.Expires',
        defaultMessage: 'Expires'
    },
    description: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-info.Description',
        defaultMessage: 'Description'
    },
    deductible: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-info.Deductible',
        defaultMessage: 'Deductible'
    },
    limit: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-info.Limit',
        defaultMessage: 'Limit'
    },
    premium: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-info.Premium',
        defaultMessage: 'Premium'
    },
    personalLiability: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-info.Personal Liability',
        defaultMessage: 'Personal Liability'
    }
})
