import React, {useContext} from 'react';
import {  withRouter, useHistory } from 'react-router-dom';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import {
    Tab
} from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import metadata from './HelpTabComponent.metadata.json5';
import styles from './HelpTabComponent.module.scss';
import TrainingMaterialsTab from './TrainingMaterials/trainingMaterials';
import messages from './HelpTabComponent.messages'

function HelpTabComponent() {
    const translator = useContext(TranslatorContext);

    const resolvers = {
        resolveClassNameMap: styles
    };

    const renderTabs = () => 
        <Tab id="trainingMaterialsTab" heading={translator(messages.trainingMaterialsTab)} className={styles.tabButton}>
            <TrainingMaterialsTab/>
        </Tab>

    const override = {
        helpPageTabs: {
            content: renderTabs(),
            defaultActiveTab: "trainingMaterialsTab"
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={override}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

export default withRouter(HelpTabComponent);
