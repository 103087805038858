import { defineMessages } from 'react-intl';

export default defineMessages({
    coveringType: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICAddRoofCoverageComponent.Covering Type",
        defaultMessage: "Covering Type"
    },
    roofAdditionalDescription: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICAddRoofCoverageComponent.Describe Other",
        defaultMessage: "Describe Other"
    },
    roofPercentage: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICAddRoofCoverageComponent.Percentage",
        defaultMessage: "Percentage"
    }   
})