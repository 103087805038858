import React from 'react'
import PropTypes from "prop-types";

import { Button } from "@jutro/components";

const ICON_TYPES = ['edit', 'delete']
const DEFAULT_CLASSNAME = 'iconButtonBackground'
const DEFAULT_ICON_CLASSNAME = 'iconStyle'

const WMICIconButton = ({icon, disabled, onAction, ...rest})  => {
    return (
        <Button
            icon={icon}
            disabled={disabled}
            className={DEFAULT_CLASSNAME}
            iconClassName={DEFAULT_ICON_CLASSNAME}
            onClick={onAction}
            {...rest}
        />
    );
};

WMICIconButton.propTypes = {
    icon: PropTypes.oneOf(ICON_TYPES),
    onAction: PropTypes.func
}

export default WMICIconButton;