import { defineMessages } from 'react-intl';

export default defineMessages({
    addAtLeastOnceVehicle: {
        id: 'quoteandbind.pa.views.pa-vehicle-details.AtLeastOneVehicle',
        defaultMessage: '&lt;span&gt;Add a minimum of &lt;b&gt;one Vehicle&lt;/b&gt;&lt;/span&gt;'
    },
    saveVehicleLabel: {
        id: 'quoteandbind.pa.views.pa-vehicle-details.Registervehicle',
        defaultMessage: 'Register Vehicle'
    },
    removeVehicleTitle: {
        id: 'quoteandbind.pa.views.pa-vehicle-details.removeVehicleTitle',
        defaultMessage: 'Remove Vehicle?'
    },
    removeVehicle: {
        id: 'quoteandbind.pa.views.pa-vehicle-details.removeVehicle',
        defaultMessage: 'Are you sure you want to remove {vehicle} from the list of vehicles?'
    },
    vinMatchMsg: {
        id: 'quoteandbind.pa.views.pa-vehicle-details.vinMatchMsg',
        defaultMessage: 'Sorry, but this vehicle\'s VIN matches that of {vehicle}. Please ensure that all VINs are unique.'
    },
    vinIsNotUnique: {
        id: 'quoteandbind.pa.views.pa-vehicle-details.vinIsNotUnique',
        defaultMessage: 'VIN is not unique'
    },
    actionRequired: {
        id: 'quoteandbind.pa.views.pa-vehicle-details.actionRequired',
        defaultMessage: 'Action required'
    },
    vinSearchRequired: {
        id: 'quoteandbind.pa.views.pa-vehicle-details.vinSearchRequired',
        defaultMessage: 'A vehicle search is required to continue.'
    },
    close: {
        id: 'quoteandbind.pa.views.pa-vehicle-details.close',
        defaultMessage: 'Close'
    },
    theftIdentifier: {
        id: 'quoteandbind.pa.views.pa-vehicle-details.theftIdentifier',
        defaultMessage: 'THEFT IDENTIFIER'
    },
    vehicleType: {
        id: 'quoteandbind.pa.views.pa-vehicle-details.vehicleType',
        defaultMessage: 'VEHICLE TYPE'
    },
    vehicleDescription: {
        id: 'quoteandbind.pa.views.pa-vehicle-details.vehicleDescription',
        defaultMessage: 'VEHICLE DESCRIPTION'
    },
    vehicleBusinessSegment: {
        id: 'quoteandbind.pa.views.pa-vehicle-details.vehicleBusinessSegment',
        defaultMessage: 'BUSINESS SEGMENT'
    },
    vehicleNumber: {
        id: 'quoteandbind.pa.views.pa-vehicle-details.vehicleNumber',
        defaultMessage: '#'
    },
    newVehicle: {
        id: 'quoteandbind.pa.views.pa-vehicle-details.newVehicle',
        defaultMessage: 'New Vehicle'
    },
    savingVehicle: {
        id: 'quoteandbind.pa.views.pa-vehicle-details.SavingVehicle',
        defaultMessage: 'Saving vehicle...'
    },
    snowVehicleCombinationError: {
        id: 'quoteandbind.pa.views.pa-vehicle-details.WrongVehiclesCombination.Snow Vehicle With PPV',
        defaultMessage: 'Vehicle Type: Snow Vehicle cannot be combined with any other vehicle type.'
    },
    snowMotorcycleVehicleCombinationError: {
        id: 'quoteandbind.pa.views.pa-vehicle-details.WrongVehiclesCombination.Snow And Motorcycle With PPV',
        defaultMessage: 'Vehicle Type: Snow Vehicle cannot be combined with any other vehicle type, Motorcycle cannot be combined with any other vehicle type.'
    },
    motorcycleVehicleCombinationError: {
        id: 'quoteandbind.pa.views.pa-vehicle-details.WrongVehiclesCombination.Motorcycle With PPV',
        defaultMessage: 'Vehicle Type: Motorcycle cannot be combined with any other vehicle type.'
    },
    snowMotorcycleVehicleCombination: {
        id: 'quoteandbind.pa.views.pa-vehicle-details.WrongVehiclesCombination.Snow Vehicle and Motorcycle Together',
        defaultMessage: 'Vehicle Type: Motorcycle cannot be combined with Snow Vehicle.'
    },
    errorVehicleCombinationError: {
        id: 'quoteandbind.pa.views.pa-vehicle-details.WrongVehiclesCombination.Error Description',
        defaultMessage: 'Error'
    }
});
