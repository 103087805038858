import { defineMessages } from 'react-intl';

export default defineMessages({
    paymentHeader: {
        id: 'portal.components.transactionWizardTitle.transactionWizardTitle.Policy Change - Homeowners',
        defaultMessage: 'Policy Change - Homeowners'
    },
    accountTitleHo: {
        id: 'endorsement.directives.templates.bank-account-details-quote.Account:',
        defaultMessage: 'Account:'
    },
    hoPolicyChangeCoverages: {
        id: 'endorsement.ho.directives.templates.ho-coverages.Coverages',
        defaultMessage: 'Coverages'
    },
    hoPolicyChangeValuables: {
        id: 'endorsement.ho.directives.templates.ho-valuables.Valuables',
        defaultMessage: 'Valuables'
    },
    hoPolicyChangeMortgagee: {
        id: 'endorsement.ho.directives.templates.ho-mortgagee.Mortgagee',
        defaultMessage: 'Mortgagee'
    },
    hoPolicyChange: {
        id: 'endorsement.ho.directives.templates.ho-policy-changes.Policy Changes',
        defaultMessage: 'Policy Changes'
    },
    hoPolicyChangeQuotes: {
        id: 'endorsement.common.views.history.Quote',
        defaultMessage: 'Quote'
    },
    hoPolicyChangePayments: {
        id: 'endorsement.ho.directives.templates.ho-coverages.Payments',
        defaultMessage: 'Payments'
    },
    anErrorOccurred: {
        id: 'gw-portals-policytransaction-pc-ho.wizard.error',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'gw-portals-policytransaction-pc-ho.wizard.error.title',
        defaultMessage: 'Error'
    },
    saveQuoteError: {
        id: 'gw-portals-policytransaction-pc-ho.Unable to quote',
        defaultMessage: 'Unable to quote'
    },
    saveQuoteErrorMessage: {
        id: 'gw-portals-policytransaction-pc-ho.An error occurred while attempting to quote.',
        defaultMessage: 'An error occurred while attempting to quote.'
    },
    unableToLoadDraftPolicy: {
        id: 'policychange.wizard.Unable to load draft policy change',
        defaultMessage: 'Unable to Load Draft Policy Change'
    },
    somethingWentWrong: {
        id: 'policychange.wizard.An error occurred while attempting to load the policy change.',
        defaultMessage: 'Something went wrong during this process. Please try again.'
    },
    cancel: {
        id: 'endorsement.common.view.Cancel',
        defaultMessage: 'Cancel'
    },
    savingTransactionDetails: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPEHOPolicyChangeWizard.Saving Transaction Details',
        defaultMessage: 'Saving Transaction Details'
    },
    transactionDetailsTitle: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.wizard-config.Transaction Details',
        defaultMessage: 'Transaction Details'
    },
    policyDetailsTitle: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.wizard-config.Policy Details',
        defaultMessage: 'Policy Details'
    },
    risksTitle: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.wizard-config.Risk',
        defaultMessage: 'Risk'
    },
    constructionTitle: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.wizard-config.Construction',
        defaultMessage: 'Construction'
    },
    coveragesTitle: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.wizard-config.Coverages',
        defaultMessage: 'Coverages'
    },
    scheduledItemsTitle: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.wizard-config.Scheduled Items',
        defaultMessage: 'Scheduled Items'
    },
    insuranceHistoryTitle: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.wizard-config.Insurance History',
        defaultMessage: 'Insurance History'
    },
    contactDetailsTitle: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.wizard-config.Contact Details',
        defaultMessage: 'Contact Details'
    },
    changeSummaryTitle: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.wizard-config.Change Summary',
        defaultMessage: 'Change Summary'
    },
    quoteTitle: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.wizard-config.Quote',
        defaultMessage: 'Quote'
    },
    progress: {
        id: 'endorsement.wizard.ho-policy-change.Progress',
        defaultMessage: 'Progress'
    }
});
