/* eslint-disable no-secrets/no-secrets */

import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { DropdownMenuButton, DropdownMenuLink } from '@jutro/components';
import { wizardProps, WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import {
    CONSTANTS,
    POLICY_DETAILS,
    WMICUserAccessUtil,
    WMICDriverUtil,
    WMICRichTextUtil,
    WMICVariousUtil,
    WMICLogger,
    MODAL_CONSTANTS,
    FlowStepId,
    WMICValidationUtil
} from 'wmic-pe-portals-utils-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { EntityUtil } from '@xengage/gw-portals-util-js'
import { WMICRequiredText } from 'wmic-pe-components-platform-react';
import { useWizardModals, WMICWizardSubmissionPage } from 'wmic-pe-portals-wizard-components-ui';
import { WMICDriverDetailView } from 'wmic-pe-capability-gateway-common-pa-react';

import { messages as quoteAndBindCommonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import metadata from './WMICDriversDetailsPage.metadata.json5';
import messages from './WMICDriversDetailsPage.messages';
import {WMICErrorHandler} from "wmic-pe-capability-quoteandbind-common-react";

const { INSURED_ROLES } = CONSTANTS;

const createDriver = (index, skeletonStruct, person, insuredRelation) => {
    const today = new Date();

    if (!person) {
        person = {
            tempID: EntityUtil.nextId()
        }
    }

    const driver = {
        person: { ...person },
        firstName: person.firstName,
        middleName: person.middleName,
        lastName: person.lastName,
        displayName: `${person.firstName} ${person.lastName}`,
        dateOfBirth: person.dateOfBirth,
        driverClassHistory_WMIC: [],
        getStateLicThirtyDay_WMIC: null,
        createTime: today,
        accountHolder: insuredRelation === INSURED_ROLES.PRIMARY_INSURED,
        mvrReports_WMIC: [],
        mvrReport_WMIC: {
            mvrIncidents_WMIC: []
        },
        licenseCountry: 'CA',
        policyHistory_WMIC: {
            previousInsurances: []
        },
        mvrAddressMatchesInfo: null,
        isPrimaryInsured_WMIC: insuredRelation === INSURED_ROLES.PRIMARY_INSURED,
        isAdditionalNamedInsured_WMIC: insuredRelation === INSURED_ROLES.ADDITIONAL_INSURED,
        emailAddress1 : person.emailAddress1,
        cellPhone : person.cellNumber
    };

    // this is the hookup for server aspects
    if (skeletonStruct.length > 0 && skeletonStruct[0].metaDataMap) {
        driver.metaDataMap = skeletonStruct[0].metaDataMap;
    }

    driver.tempID = EntityUtil.nextId();

    if (person) {
        if (person.publicID) {
            _.set(driver, 'person.publicID', person.publicID);
        }

        if (person.primaryAddress) {
            _.set(driver, 'licenseState', person.primaryAddress.state);
        }
    }

    return driver;
};

const ANIS_PATH = 'baseData.additionalNamedInsureds_WMIC';
const DRIVERS_PATH = 'lobData.personalAuto.coverables.drivers';

function WMICDriversDetailsPage(props) {
    const { wizardData: submissionVM, updateWizardData } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader, authUserData } = useAuthentication();

    const [isReadOnlyUser, setReadOnlyUser] = useState(true);
    const [additionalNamedInsureds, setAdditionalNamedInsureds] = useState([]);
    const [skeletonStruct, setSkeletonStruct] = useState({});
    const [showErrors, setShowErrors] = useState(false);
    const driversWithDuplicateLicence  = WMICDriverUtil.getDriversWithDuplicateLicense(_.get(submissionVM.value, DRIVERS_PATH));

    const { setWizardLoading, showConfirm, showError } = useWizardModals();

    const {
        onValidate, initialValidation, isComponentValid, registerInitialComponentValidation
    } = useValidation('paDriversDetailsPage');

    const { maintainFurtherStepsVisitedSubmitted, setIsCustomStepsInvalidated } = useContext(WizardContext);
    const isBMSi = _.get(submissionVM, "isFromBMS_WMIC.value");

    useEffect(() => {
        submissionVM.value.flowStepId_WMIC = FlowStepId.PA_DRIVERS;
    }, [submissionVM.value]);

    const initDriverData = useCallback(async () => {
        const driversTmp = _.get(submissionVM, DRIVERS_PATH);
        const additionalNamedInsuredsSub = _.get(submissionVM, `${ANIS_PATH}.value`);
        const additionalNamedInsuredsTmp = [];
        const skeletonStructure = await LoadSaveService.getSkeletonStructure(
            submissionVM.value,
            authHeader
        );
        // pull out driver specific structure
        const driverStruct = _.get(skeletonStructure, DRIVERS_PATH)

        setSkeletonStruct(driverStruct);

        if (additionalNamedInsuredsSub && !_.isEmpty(additionalNamedInsuredsSub)) {
            additionalNamedInsuredsSub.forEach((nameInsured) => {
                const additionalNameInsured = viewModelService.create(nameInsured, 'pc', 'wmic.edge.ca.capabilities.policyjob.draft.dto.AdditionalNamedInsuredDTO_WMIC');

                _.set(additionalNameInsured, 'isDriver', false);

                const aniSubType = _.get(additionalNameInsured, 'subtype.value.code');

                if (aniSubType === CONSTANTS.Person.toLowerCase()) {
                    const isDriver = driversTmp.value.some((driver) => _.get(additionalNameInsured, 'contactPublicID.value') === _.get(driver, 'person.publicID.value'));

                    _.set(additionalNameInsured, 'isDriver', isDriver);
                }

                additionalNamedInsuredsTmp.push(additionalNameInsured);
            });
            if (!_.isEmpty(additionalNamedInsuredsTmp)) {
                setAdditionalNamedInsureds(additionalNamedInsuredsTmp);
            }
        }

        const primaryNamedInsuredTmp = _.get(submissionVM, 'baseData.primaryNamedInsured_WMIC.value');

        const isDriver = driversTmp.value.some((driver) => _.get(primaryNamedInsuredTmp, 'contactPublicID') === _.get(driver, 'person.publicID'));

        _.set(primaryNamedInsuredTmp, 'isDriver', isDriver);

    }, [LoadSaveService, authHeader, submissionVM, viewModelService]);

    useEffect(() => {
        registerInitialComponentValidation(() => _.get(submissionVM, DRIVERS_PATH).value.length > 0);

        setReadOnlyUser(!WMICUserAccessUtil.canCreateSubmission(authUserData.roles));
        initDriverData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCreateDriver = useCallback((index, namedInsured, insuredRelation) => {
        if (isBMSi && maintainFurtherStepsVisitedSubmitted.flag) {
            maintainFurtherStepsVisitedSubmitted.flag = false;
            setIsCustomStepsInvalidated(true);
            updateWizardData(submissionVM);
        }

        const driver = createDriver(index, skeletonStruct, namedInsured, insuredRelation);
        const driversVM = _.get(submissionVM, DRIVERS_PATH);
        const { _xCenter, _dtoName } = driversVM;
        const driverVM = viewModelService.create(driver, _xCenter, _dtoName, submissionVM.aspects.context());
        const commercialDriverVM = viewModelService.create({}, 'pc', 'wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.dto.CommercialDriverDTO_WMIC').value;
        const isDriverPNI = submissionVM.baseData.primaryNamedInsured_WMIC.subtype.value === CONSTANTS.Person
            && submissionVM.baseData.primaryNamedInsured_WMIC.publicID.value === driverVM.person.publicID.value

        if (_.get(submissionVM, 'baseData.isDriveChangeAvailable_WMIC.value') === true) {
            const driveChangeVM = viewModelService.create(
                {}, 'pc', 'wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.dto.DriveChangeDTO_WMIC').value;

            if (isDriverPNI) {
                if (_.get(submissionVM, 'baseData.isDriveChangeEnrolledInOtherPoliciesForPNI_WMIC.value')) {
                _.set(driveChangeVM, 'isEnrolled.value', _.get(submissionVM, 'baseData.isDriveChangeEnrolledInOtherPoliciesForPNI_WMIC.value'))
                }

                if (_.get(submissionVM, 'baseData.isDriveChangePriorCarrierDiscountAvailableInOtherPoliciesForPNI_WMIC.value')) {
                    _.set(driveChangeVM, 'previousDiscountPercentage.value', _.get(submissionVM, 'baseData.isDriveChangePriorCarrierDiscountAvailableInOtherPoliciesForPNI_WMIC.value'))
                }
            }

            _.set(driverVM, 'driveChange_WMIC', driveChangeVM)
        }

        _.set(driverVM, 'commercialDriver_WMIC', commercialDriverVM);
        _.set(driverVM, 'policyPeriodEndDate_WMIC', submissionVM.baseData.periodEndDate.value);
        _.set(driverVM, 'licenseCountry_WMIC', driver.licenseCountry);
        _.set(driverVM, 'isExistingInPreviousPeriodDriver_WMIC', false)

        if (namedInsured) {
            _.set(driverVM, 'assignment_WMIC.value', _.find(
                driverVM.assignment_WMIC.aspects.availableValues,
                (assignmentType) => assignmentType.code === CONSTANTS.ASSIGNMENT.ASSIGNED));

            if (isDriverPNI) {
                driverVM.creditConsentReceived = submissionVM.baseData.creditConsentReceived.value;
                driverVM.creditConsentDate = submissionVM.baseData.creditConsentDate.value;
                driverVM.personalInfoConsentForm = (!_.isUndefined(submissionVM.baseData.personalInfoConsentForm.value) ?
                    submissionVM.baseData.personalInfoConsentForm.value : null);
                driverVM.creditInfoWithdrawalConsent = (!_.isUndefined(submissionVM.baseData.creditInfoWithdrawalConsent.value) ?
                    submissionVM.baseData.creditInfoWithdrawalConsent.value : null);
            }

            const ANI = _.find(submissionVM.baseData.additionalNamedInsureds_WMIC.value, namedInsuredParam => namedInsuredParam.contactPublicID === driverVM.person.publicID.value || namedInsuredParam.publicID === driverVM.person.publicID.value);

            if (ANI) {
                driverVM.creditConsentReceived = ANI.creditConsentReceived;
                driverVM.creditConsentDate = ANI.creditConsentDate;
                driverVM.personalInfoConsentForm = (!_.isUndefined(ANI.personalInfoConsentForm) ? ANI.personalInfoConsentForm : null);
                driverVM.creditInfoWithdrawalConsent = (!_.isUndefined(ANI.creditInfoWithdrawalConsent) ? ANI.creditInfoWithdrawalConsent : null);
                driverVM.person.publicID = ANI.contactPublicID;
            }

            if (submissionVM.baseData.primaryNamedInsured_WMIC.publicID.value === driverVM.person.publicID.value) {
                _.set(driverVM, 'priInsuredRelation_wmic.value', CONSTANTS.PRIMARY_INSURED_RELATIONSHIP.INSURED)
            } else {
                _.set(driverVM, 'priInsuredRelation_wmic.value', namedInsured.relationshipToPrimaryInsured_WMIC)
            }
        }

        const today = new Date();

        driverVM.minDOB = {
            year: today.getFullYear() - POLICY_DETAILS.MAXIMUM_LEGAL_AGE, month: today.getMonth(), day: today.getDate()
        };
        driverVM.maxDOB = {
            year: today.getFullYear() - POLICY_DETAILS.MINIMUM_LEGAL_AGE, month: today.getMonth(), day: today.getDate()
        };
        submissionVM.lobData.personalAuto.coverables.drivers.value.push(driverVM.value);

        return driverVM;
    }, [skeletonStruct, submissionVM, viewModelService]);

    const onCreateDriverWithModal = useCallback((index, namedInsured, insuredRelation) => showConfirm({
        title: quoteAndBindCommonMessages.revalidationRequiredTitle,
        message: quoteAndBindCommonMessages.revalidationRequiredBody,
        status: MODAL_CONSTANTS.STATUS.WARNING,
        icon: MODAL_CONSTANTS.ICON.ERROR,
        confirmButtonText: commonMessages.yes,
        cancelButtonText: commonMessages.cancelModel,
    }).then((results) => {
        if (
            results === CONSTANTS.MODAL_RESULT.CANCEL ||
            results === CONSTANTS.MODAL_RESULT.CLOSE
        ) {
            return false;
        }

        return onCreateDriver(index, namedInsured, insuredRelation);
    })
    , [onCreateDriver, showConfirm]);

    const saveSubmission = useCallback(async (newSubmission, onError = _.noop) => {
        _.unset(newSubmission, 'bindData');

        try {
            setWizardLoading(true, translator(messages.savingDriver));

            submissionVM.value = await LoadSaveService.updateDraftSubmission(
                newSubmission,
                authHeader
            );

            updateWizardData(submissionVM);
            setShowErrors(false);

            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.PA_DRIVERS)) {
                WMICVariousUtil.scrollToTop();
            }

            return true;
        } catch (error) {
            WMICLogger.error('Save driver failed', error);
            onError();

            return false;
        } finally {
            setWizardLoading(false);
        }
    }, [setWizardLoading, translator, LoadSaveService, authHeader, submissionVM, updateWizardData])

    const onSaveDriver = useCallback(() => saveSubmission(submissionVM.value), [saveSubmission, submissionVM]);

    const onSaveDriverMiddleware = useCallback((selectedDriver) => {
        if (driversWithDuplicateLicence ) {
            const otherDriverWithSameLicense = driversWithDuplicateLicence .find(d => d.publicID !== selectedDriver?.value?.publicID) || driversWithDuplicateLicence [0];

            showError({
                title: translator(messages.licenseNotUnique),
                message: translator(messages.licenceMatchMsg, {driver: otherDriverWithSameLicense.displayName})
            })
        }
    }, [driversWithDuplicateLicence , showError, translator]);

    const deleteDriver = useCallback(async (driverId) => {

        _.unset(submissionVM.value, 'bindData');

        try {
            setWizardLoading(true, translator(messages.removingDriver));

            const removeDriverResult = await LoadSaveService.removeDriver(
                submissionVM.value,
                driverId,
                authHeader
            );

            if (!_.isEmpty(removeDriverResult.errorMessages)) {
                showError({
                    title: translator(messages.removingDriverErrorModalTitle),
                    message: removeDriverResult.errorMessages.join('\n')
                });
                return false;
            }

            submissionVM.value = removeDriverResult.quoteData;

            updateWizardData(submissionVM);
            setShowErrors(false);

            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.PA_DRIVERS)) {
                WMICVariousUtil.scrollToTop();
            }

            return true;
        } catch (error) {
            WMICLogger.error('Remove driver failed', error);
            WMICErrorHandler.processAsModal(error, submissionVM.value.jobID, submissionVM.value.baseData.jobType);
            return false;
        } finally {
            setWizardLoading(false);
        }
    }, [setWizardLoading, translator, LoadSaveService, authHeader, showError, submissionVM, updateWizardData]);


    const onDeleteDriver = useCallback((driver, index) => {
        const modalMsg = translator(messages.removeDriver, { driver: driver.displayName?.value });

        showConfirm({
            title: messages.removeDriverTitle,
            message: modalMsg,
            confirmButtonText: commonMessages.yes,
            cancelButtonText: commonMessages.noModel
        }).then(async (response) => {
            if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
                return deleteDriver(driver.publicID?.value);
            }
        }).catch(_.noop);
    }, [showConfirm, translator, deleteDriver]);

    const onCancelDriver = () => {
        _.set(submissionVM, 'isEditingPage.value', false);
        updateWizardData(submissionVM);
    }

    const onNext = useCallback(async () => {
        if (!isComponentValid || !WMICDriverUtil.presentDriverList(submissionVM)) {
            setShowErrors(true);

            return false;
        }

        if (WMICUserAccessUtil.canCreateSubmission(authUserData.roles)) {
            try {
                setWizardLoading(true);

                submissionVM.value = await LoadSaveService.updateDraftSubmission(
                    submissionVM.value,
                    authHeader
                );

                updateWizardData(submissionVM);

                if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.PA_DRIVERS)) {
                    WMICVariousUtil.scrollToTop();
                    return false
                }

                return submissionVM;

            } finally {
                setWizardLoading(false);
            }
        }

        return false;
    }, [isComponentValid, authUserData.roles, setWizardLoading, LoadSaveService, submissionVM, authHeader, updateWizardData]);

    const renderAddDriverButton = useCallback(({ isEditing, onClick }) => {
        const drivers = _.get(submissionVM, `${DRIVERS_PATH}.value`, []);
        const pni = _.get(submissionVM, 'baseData.primaryNamedInsured_WMIC.value');

        return (<DropdownMenuButton
            id="addNewDriverButton"
            buttonText={translator({
                "id": "quoteandbind.pa.views.pa-drivers-details.addNewDriver",
                "defaultMessage": "meta.Add New Driver"
            })}
            icon="mi-add"
            disabled={isEditing} >
            {WMICDriverUtil.canContactBeAddedToDrivers(drivers, pni) && <DropdownMenuLink
                onClick={() => onClick(
                    drivers.length,
                    pni,
                    INSURED_ROLES.PRIMARY_INSURED)}>
                {pni.displayName}
            </DropdownMenuLink>}
            {additionalNamedInsureds.map((ani, index) => (
                WMICDriverUtil.canContactBeAddedToDrivers(drivers, ani.value) && <DropdownMenuLink
                    onClick={() => onClick(
                        drivers.length,
                        _.get(submissionVM, `${ANIS_PATH}.value[${index}]`),
                        INSURED_ROLES.ADDITIONAL_INSURED)}>
                    {ani.displayName.value}
                </DropdownMenuLink>
            ))}
            <DropdownMenuLink onClick={() => onClick(
                _.get(submissionVM, `${DRIVERS_PATH}.value`, []).length
            )}>{translator(messages.addNewDriver)}</DropdownMenuLink>
        </DropdownMenuButton>)
    }, [additionalNamedInsureds, submissionVM, translator]);

    const overrideProps = {
        // TODO: set visibility to !policyChange and usCanadaYearsLicensedTypeKey === undefined
        reenterMsgContainer: {
            visible: false
        },
        reenterMsg: {
            content: WMICRichTextUtil.translateRichText(translator(messages.reenterMsg, ''))
        },
        atLeastOneMsgContainer: {
            visible: _.isEmpty(_.get(submissionVM, `${DRIVERS_PATH}.value`, []))
        },
        atLeastOneMsg: {
            content: WMICRichTextUtil.translateRichText(translator(messages.atLeastOne))
        },
        driverListView: {
            VMList: _.get(submissionVM, `${DRIVERS_PATH}.children`, []),
            clickable: true,
            startOpen: true,
            readOnly: isReadOnlyUser,
            VMData: [{
                headerText: translator(messages.driverName), path: 'displayName'
            },
            {
                headerText: translator(messages.licenseNumber), path: 'licenseNumber'
            },
            {
                headerText: translator(messages.licenseProvince), path: 'licenseState', getData: (row, path) => _.get(row.value, path) || translator(messages.licenseProvinceOther)
            }],
            toCreate: isBMSi ? onCreateDriverWithModal : onCreateDriver,
            onSave: onSaveDriver,
            onSaveMiddleware: onSaveDriverMiddleware,
            onDelete: onDeleteDriver,
            onCancel: onCancelDriver,
            toUndoCreate: () => {
                const drivers = _.get(submissionVM.value, DRIVERS_PATH);

                drivers.splice(drivers.length - 1, 1);
                _.set(submissionVM.value, DRIVERS_PATH, drivers);
                updateWizardData(submissionVM);
            },
            detailViewComponent: WMICDriverDetailView,
            onValidate,
            renderAddButton: renderAddDriverButton,
            detailViewComponentProps: {
                jobVM: submissionVM
            }
        },
        requiredText: {
            visible: _.get(submissionVM, DRIVERS_PATH).length > 0
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICRequiredText
        }
    };

    return (
        <WMICWizardSubmissionPage
            onNext={onNext}
            skipWhen={initialValidation}
            disableNext={!isComponentValid}
            cancelLabel={translator(quoteAndBindCommonMessages.saveAndExit)}
            showRequired
            flowStepId={FlowStepId.PA_DRIVERS}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WMICWizardSubmissionPage>);
}

WMICDriversDetailsPage.propTypes = wizardProps;

export default WMICDriversDetailsPage;
