import { useState, useCallback, useEffect } from 'react';
import { useDebounce } from '@jutro/platform';

const useEditableData = (initialData = [{}], onDataChange) => {
    const [data, setData] = useState(initialData.length > 0 ? initialData : [{}]);

    const debouncedData = useDebounce(data, 250);

    useEffect(() => {
        setData(initialData);
    }, [initialData]);

    useEffect(() => {
        onDataChange(debouncedData);
    }, []);

    const addRow = useCallback(() => {
        setData([...data, {}]);
    }, [data]);

    const updateRow = (propName, propValue, index) => {
        const newData = [...data];
        const newRow = { ...newData[index], [propName]: propValue };
        newData.splice(index, 1, newRow);
        setData(newData);
    }

    const updateRows = (propNames, propValues, index) => {
        const newData = [...data];
        const newRow = { ...data[index] };
        propNames.forEach((value, i) => {
            newRow[value] = propValues[i];
        });
        newData.splice(index, 1, newRow);
        setData(newData);
    }

    const deleteRow = useCallback(
        (index) => {
            const newData = [...data];
            newData.splice(index, 1);
            setData(newData);
            return newData;
        },
        [data]
    );

    return {
        data,
        addRow,
        updateRow,
        updateRows,
        deleteRow
    };
};

export default useEditableData;
