import React, { useEffect, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

import { Wizard } from 'wmic-pe-portals-custom-wizard-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { ClauseService } from 'wmic-pe-capability-policycommon';
import { withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { LoadSaveService } from 'wmic-pe-capability-gateway-quoteandbind';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { Loader } from '@jutro/components';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { CONSTANTS, MODAL_CONSTANTS, WIZARD_STEP_PATHS, WMICLogger, WMICValidationUtil} from 'wmic-pe-portals-utils-js';
import { MockUpUtil } from '@xengage/gw-portals-util-js'
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { TranslatorContext } from '@jutro/locale';
import { WizardBMSIModal, messages as commonSubmissionMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import { WMICWizardHandler } from 'wmic-pe-capability-gateway-common-react';

import wizardStepToFieldMapping from './config/pup-wizard-step-to-field-mapping.json5';
import wizardConfig from './config/pup-wizard-config.json5';
import messages from './PEPUPWizard.messages';


const PATH_TO_MOCK_DATA = 'quote.pup';
const MOCK_DATA_TO_SET = ['bindData.contactPhone'];
const MOCK_DATA_TO_REMOVE = ['bindData.contactPhone'];

function checkValidity(submissionVM, extractVMObject, currentStep) {
    const checkValidityPathsForCurrentStep = _.get(currentStep, 'checkValidityPaths', []);

    // Cloning checkValidityPaths to avoid adding duplicates to array
    const checkValidityPaths = _.clone(checkValidityPathsForCurrentStep);

    // For Underlying Policies & Household Members step, only validate if user has started editing the page
    if (currentStep.path === WIZARD_STEP_PATHS.PUP.HOUSEHOLD_MEMBERS || currentStep.path === WIZARD_STEP_PATHS.PUP.UNDERLYING_POLICIES) {
        if (!_.get(submissionVM, 'isEditingPage.value', false)) {
            return true;
        }
    }

    return WMICValidationUtil.checkValidity(submissionVM, checkValidityPaths);
}

function PEPUPWizard(props) {
    const { steps, title } = wizardConfig;
    const { authHeader } = useAuthentication();
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const [showBMSIModal, setShowBMSIModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { viewModelService } = props;
    const { showConfirm, showError, showCustom, setDefaultMessage } = useWizardModals();
    const history  = useHistory();
    const location = useLocation();
    const translator = useContext(TranslatorContext);

    useEffect(
        () => {
            const viewModelContext = {
                AccountEmailRequired: false,
                DriverEmailRequired: true,
                AccountDOBRequired: false
            };
            if (!location.state) {
                history.push('/');
                return;
            }

            setDefaultMessage(translator(commonSubmissionMessages.savingQuoteSubmissionDetails));

            const { quoteentry } = location.state;
            const { postalCode, quoteID } = quoteentry;
            const requestData = {
                quoteID,
                postalCode
            };
            LoadSaveService.retrieveSubmission(requestData, authHeader)
                .then((response) => {
                    setShowBMSIModal(true);
                    return MockUpUtil.cleanUpMockedProperties(
                        response,
                        PATH_TO_MOCK_DATA,
                        ...MOCK_DATA_TO_REMOVE
                    );
                })
                .then((response) => {
                    response.persons = [response.baseData.accountHolder];
                    ClausesUtil.getDependentClausesFromServer(
                        ClauseService,
                        _.get(response, 'quoteID'),
                        authHeader
                    );
                    const submission = viewModelService.create(
                        response,
                        'pc',
                        'wmic.edge.ca.capabilities.quote.submission.dto.QuoteDataDTO',
                        viewModelContext
                    );

                    const fieldErrors = _.get(submission, 'errorsAndWarnings.validationIssues.fieldIssues.value');
                    if (fieldErrors) {
                        WMICLogger.error(fieldErrors);
                    }

                    steps.forEach((step) => _.set(step, 'stepToFieldMapping', wizardStepToFieldMapping[step.id]))
                    
                    setInitialSubmission(submission);
                    setIsLoading(false);
                });
        },
        // Disabled so we don't rerun this function on every rerender
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleCancel = useCallback(
        ({ wizardSnapshot, param: nextPath = '', wizardData }) => {
            const skipModal = CONSTANTS.SKIP_CANCEL_MODAL;
            const lastPathName = nextPath.replace(/\/?.*\//g, '');
            const isStatusBound = _.get(wizardSnapshot, 'baseData.periodStatus.value.code') === CONSTANTS.BOUND;
            if (isStatusBound || skipModal.includes(lastPathName) || hasErrorOccurred) {
                return true;
            }
            return showConfirm({
                title: commonMessages.wantToCancel,
                message: commonMessages.infoWillBeSavedAsDraft,
                status: MODAL_CONSTANTS.STATUS.WARNING,
                icon: MODAL_CONSTANTS.ICON.WARNING,
                confirmButtonText: commonMessages.yes,
                cancelButtonText: commonMessages.no
            }).then((results) => {
                if (results === CONSTANTS.MODAL_RESULT.CANCEL || results === CONSTANTS.MODAL_RESULT.CLOSE) {
                    return _.noop();
                }
                const isStatusQuoted = _.get(wizardSnapshot, 'baseData.periodStatus.value.code') === CONSTANTS.QUOTED;

                let serverCall = LoadSaveService.updateDraftSubmission;

                if (isStatusQuoted) {
                    serverCall = LoadSaveService.updateQuotedSubmission;
                }

                const isValid = wizardData.lobData.aspects.valid
                    && wizardData.lobData.aspects.subtreeValid;

                const payload = isValid
                    ? _.get(wizardData, 'value')
                    : _.get(wizardSnapshot, 'value');
                const payloadBeforeSave = _.omit(payload, 'bindData.paymentDetails');
                const dataToSave = MockUpUtil.setMockData(
                    payloadBeforeSave,
                    PATH_TO_MOCK_DATA,
                    ...MOCK_DATA_TO_SET
                );

                setIsLoading(true);

                const redirectPath = `/quotes/${dataToSave.quoteID}/summary`;
                serverCall(dataToSave, authHeader)
                    .then(() => {
                        history.push(nextPath || redirectPath);
                    })
                    .catch(() => {
                        showError({
                            title: messages.anErrorOccurredTitle,
                            message: messages.anErrorOccurred,
                            status: MODAL_CONSTANTS.STATUS.ERROR,
                            icon: MODAL_CONSTANTS.ICON.ERROR,
                            confirmButtonText: commonMessages.ok
                        }).catch(_.noop);
                        history.push(nextPath || redirectPath);
                    });
                return true;
            }, _.noop);
        },
        [authHeader, hasErrorOccurred, history, showConfirm, showError]
    );

    const handleError = useCallback((error) => {
        WMICWizardHandler.handleSubmissionError(error, location, history, setHasErrorOccurred);
    }, [location, history]);

    const handleKnockout = useCallback(({ knockOutErrors, wizardData }) => {
        const quoteID = _.get(wizardData, 'quoteID.value');
        history.push('/knockoutpage', {
            underwritingIssues: knockOutErrors,
            quoteID
        });
    }, [history]);

    const isBMSI = _.get(initialSubmission, "isFromBMS_WMIC.value");
    const validationIssues = _.get(initialSubmission, "errorsAndWarnings.validationIssues.issues.value");
    const defaultedProperties = _.get(initialSubmission, "defaultedProperties_WMIC.value")?.filter((p) => p.propertyDisplayName && p.status !== CONSTANTS.REVIEWED);

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    if (!initialSubmission) {
        return null;
    }

    if (showBMSIModal && isBMSI && !isLoading && (validationIssues?.length > 0 || defaultedProperties?.length > 0)) {
        showCustom(<WizardBMSIModal validationIssues={validationIssues} defaultedProperties={defaultedProperties}/>).then(() => {
            setShowBMSIModal(false);
        });
    }

    return (
        <ErrorBoundary onError={handleError}>
            <Wizard
                initialSteps={steps}
                wizardTitle={title}
                initialData={initialSubmission}
                onCancel={handleCancel}
                onKnockOut={handleKnockout}
                checkValidity={checkValidity}
                skipCompletedSteps
                wizardStepToFieldMapping={wizardStepToFieldMapping}
                onPreviousModalProps={{
                    title: commonSubmissionMessages.wantToJump,
                    message: commonSubmissionMessages.wantToJumpMessage,
                    status: MODAL_CONSTANTS.STATUS.WARNING,
                    icon: MODAL_CONSTANTS.ICON.WARNING,
                    confirmButtonText: commonMessages.yesModel,
                    cancelButtonText: commonMessages.cancelModel
                }}
            />
        </ErrorBoundary>
    );
}

PEPUPWizard.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired
};

export default withViewModelService(PEPUPWizard);
