import { PAConstants, WMICMetadataUtil } from 'wmic-pe-portals-utils-js';
import _ from "lodash";

const isCommerciallyRatedVehicleType = (businessSegmentType, vehicleType) => (
    businessSegmentType === PAConstants.vehicleBusinessSegmentCommercial &&
    ![
        PAConstants.snowVehicleType,
        PAConstants.atvVehicleType,
        PAConstants.motorcycleVehicleType,
        PAConstants.mopedVehicleType
    ].includes(vehicleType)
);

const vinSearchRequired = (vehicle) => {
    const hasVinOverride = vehicle.vinOverride_WMIC;
    const vinSearchAttempted = vehicle.vinSearchAttempted_WMIC;
    return !hasVinOverride && !vinSearchAttempted;
};

const updateVehicleList = async (jobVM, service, authHeader) => {
    const updatedVM = await service.postOnChange(
        jobVM.value,
        authHeader
    );

    const updatedVehicles = _.get(updatedVM, 'lobData.personalAuto.coverables.vehicles');
    const orderedVehicleList = _.orderBy(updatedVehicles, ['publicID'], ['asc']);
    _.set(jobVM.value, 'lobData.personalAuto.coverables.vehicles', orderedVehicleList);
};

const resetAntiTheftFields = (vehicleVM) => {
    const antiTheftDeviceEquipped = _.get(vehicleVM, 'antiTheftDeviceEquipped_WMIC.value');

    if (antiTheftDeviceEquipped === undefined) {
        return;
    }

    if (!WMICMetadataUtil.isVisible(vehicleVM, 'antiTheftDeviceEquipped_WMIC')) {
        _.set(vehicleVM, 'antiTheftDeviceEquipped_WMIC.value', null);
        _.set(vehicleVM, 'antiTheftDeviceMaker_Ext.value', null);
        _.set(vehicleVM, 'antiTheftDeviceInstallDate_Ext.value', null);
    } else if (!antiTheftDeviceEquipped ||
        !WMICMetadataUtil.isVisible(vehicleVM, 'antiTheftDeviceMaker_Ext')) {
        _.set(vehicleVM, 'antiTheftDeviceMaker_Ext.value', null);
        _.set(vehicleVM, 'antiTheftDeviceInstallDate_Ext.value', null);
    }

    if (!antiTheftDeviceEquipped ||
        !WMICMetadataUtil.isVisible(vehicleVM, 'antiTheftDeviceType_WMIC')) {
        _.set(vehicleVM, 'antiTheftDeviceType_WMIC.value', null);
        _.set(vehicleVM, 'antiTheftDesc_WMIC.value', null);
    }
};

export default {
    isCommerciallyRatedVehicleType,
    resetAntiTheftFields,
    updateVehicleList,
    vinSearchRequired
}

