import { defineMessages } from 'react-intl';

export default defineMessages({
    driverName: {
        id: 'policy.pa.views.pa-drivers-details.driverName',
        defaultMessage: 'Name'
    },
    licenseNumber: {
        id: 'policy.pa.views.pa-drivers-details.licenseNumber',
        defaultMessage: 'License number'
    },
    licenseProvince: {
        id: 'policy.pa.views.pa-drivers-details.licenseProvince',
        defaultMessage: 'License province'
    },
    licenseProvinceOther :{
        id: "policy.pa.views.pa-driver-license.Other",
        defaultMessage : 'Other'
    },
});
