import { defineMessages } from 'react-intl';

export default defineMessages({
    addDwelling: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.Add Dwelling',
        defaultMessage: 'Add Dwelling'
    },
    propertyDetails: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.Property Details',
        defaultMessage: 'Property Details'
    },
    protectionDetails: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.Protection Details',
        defaultMessage: 'Protection Details'
    },
    additionalInterests: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.Additional Interests',
        defaultMessage: 'Additional Interests'
    },
    risk: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.Risk',
        defaultMessage: 'Risk'
    },
    dwellingRisks: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.Dwelling Risks',
        defaultMessage: 'Dwelling Risks'
    },
    riskHeader: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.RiskNo',
        defaultMessage: 'Risk #'
    },
    dwellingHeader: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.Dwelling Use',
        defaultMessage: 'Dwelling Use'
    },
    addressHeader: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.Address',
        defaultMessage: 'Address'
    },
    riskTypeHeader: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.Risk Type',
        defaultMessage: 'Risk Type'
    },
    riskDescription: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.Risk Description',
        defaultMessage: 'Description'
    },
    watercraftRisks: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.Watercraft Risks',
        defaultMessage: 'Watercraft Risks'
    },
    addWatercraft: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.Add Watercraft',
        defaultMessage: 'Add Watercraft'
    },
    watercraftDetails: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.Watercraft Details',
        defaultMessage: 'Watercraft Details'
    },
    removeRiskTitle: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.Remove Risk Short',
        defaultMessage: 'Remove Risk'
    },
    removeRiskMessage: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.Remove Risk Long',
        defaultMessage: 'Are you sure you want to remove the risk from the list?'
    },
    confirmButtonText: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.Yes',
        defaultMessage: 'Yes'
    },
    laneway: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.Laneway',
        defaultMessage: 'Laneway'
    },
    saveRisk: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.Save Risk',
        defaultMessage: 'Save'
    },
    cancelEdit: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.Cancel Edit',
        defaultMessage: 'Cancel'
    },
    dwellingRequired: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.Minimum Dwelling Risk',
        defaultMessage: '&lt;span&gt;At least &lt;b&gt;one Dwelling Risk&lt;/b&gt; is required&lt;/span&gt;'
    },
    removingRiskWarning: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.Removing Risk Warning',
        defaultMessage: 'Are you sure you want to remove the risk from the list?'
    },
    removingRisk: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.Removing Risk',
        defaultMessage: 'Removing Risk'
    },
    savingRisk: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.Saving Risk',
        defaultMessage: 'Saving Risk'
    },
    additionalDetails: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalRiskPage.Additional Details',
        defaultMessage: 'Additional Details'
    }
});
