/* eslint-disable no-secrets/no-secrets */
import { defineMessages } from 'react-intl';

export default defineMessages({
    wmicUnderlyingPoliciesLocationDetails: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPLocationExposures.LocationDetails',
        defaultMessage: 'Property Details'
    },
    wmicUnderlyingPoliciesAtLeastOneLocation: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPLocationExposures.AtLeastOneLocation',
        defaultMessage: 'At least &lt;b&gt;one dwelling&lt;/b&gt; is required'
    },
    wmicUnderlyingPoliciesHasWatercraftRisks: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPLocationExposures.HasWatercraftRisks',
        defaultMessage: 'A separate underlying policy will be started for you to enter in watercraft risk(s) associated with this policy. Please enter the risk details there.'
    },
    wmicUnderlyingPoliciesAddNewLocation: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPLocationExposures.AddNewLocation',
        defaultMessage: 'Add Dwelling'
    },
    wmicUnderlyingPoliciesResidenceType: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPLocationExposures.ResidenceType',
        defaultMessage: 'Residence Type'
    },
    wmicUnderlyingPoliciesDwellingUsage: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPLocationExposures.DwellingUsage',
        defaultMessage: 'Dwelling Usage'
    },
    wmicUnderlyingPoliciesOccupancy: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPLocationExposures.Occupancy',
        defaultMessage: 'Occupancy'
    },
    wmicUnderlyingPoliciesAddressOfResidence: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPLocationExposures.AddressOfResidence',
        defaultMessage: 'Address of Residence'
    },
    wmicUnderlyingPoliciesAddressFormat: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPLocationExposures.AddressFormat',
        defaultMessage: '{number}: {addressLine1}, {city}, {state}'
    },
    wmicUnderlyingPoliciesAddress: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPLocationExposures.Address',
        defaultMessage: 'Address'
    },
    wmicUnderlyingPoliciesDwellingUse: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPLocationExposures.DwellingUse',
        defaultMessage: 'Dwelling Use'
    },
    wmicUnderlyingPoliciesRemoveLocationExposureConfirmationMsg: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPLocationExposures.RemoveLocationExposureConfirmationMsg',
        defaultMessage: 'Are you sure you want to remove {address} from the list of properties?'
    },
    wmicUnderlyingPoliciesRemoveLocationExposure: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPLocationExposures.RemoveLocationExposure',
        defaultMessage: 'Remove Property?'
    },
    wmicUnderlyingPoliciesAddLocation: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPLocationExposures.AddLocation',
        defaultMessage: 'Add Address of Residence'
    }
})