// eslint-disable-next-line import/no-extraneous-dependencies
import { defineMessages } from 'react-intl';

export default defineMessages({
    addressNotFound: {
        id: 'address.lookup.component.Address.We were unable to find your address. Please enter your address manually.',
        defaultMessage: 'We were unable to find your address. Please enter your address manually.'
    },
    streetAddress: {
        id: 'address.lookup.component.streetAddress',
        defaultMessage: 'Address Line 1'
    },
    addressLine1: {
        id: 'wmic.address.lookup.component.Address Line 1',
        defaultMessage: 'Address Line 1'
    },
    enterAddress: {
        id: 'wmic.address.lookup.component.Enter Address',
        defaultMessage: 'Enter Address'
    },
    enterMailingAddress: {
        id: 'wmic.address.lookup.component.Enter Mailing Address',
        defaultMessage: 'Enter mailing address'
    }
});
