import React, { useMemo } from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { LINE_OF_BUSINESS } from 'wmic-pe-portals-utils-js';
import PropTypes from 'prop-types';
import metadata from './WMICFlexAlertMessage.metadata.json5';
import './WMICFlexAlertMessage.messages';
import styles from './WMICFlexAlertMessage.module.scss';

function WMICFlexAlertMessage({jobVM}) {
    const lob = _.get(jobVM, 'lob.value.code');

    const hasFlexReset = useMemo(() => {
        let flexReset = false;
        if (lob === LINE_OF_BUSINESS.PERSONAL_AUTO ) {
            flexReset = _.get(jobVM, 'lobData.personalAuto.coverables.vehicles.value', []).some((vehicle) => _.get(vehicle, 'flexDTO.flexResetFlag_WMIC', false));
        } else if (lob === LINE_OF_BUSINESS.HOMEOWNERS) {
            flexReset = _.get(jobVM, 'lobData.homeowners.coverables.dwellings.value', []).some((dwelling) => _.get(dwelling, 'flexDTO.flexResetFlag_WMIC', false));
        }

        return flexReset;
    }, [jobVM, lob]);

    const overrides =  {
        flexAlertContainer: {
            visible: hasFlexReset
        },
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            classNameMap={styles}
        />
    );
}

WMICFlexAlertMessage.propTypes = {
    jobVM: PropTypes.shape({}).isRequired,
};

export default WMICFlexAlertMessage;
