import { defineMessages } from 'react-intl';

export default defineMessages({
    createNewQuote: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.Create a new Quote',
        defaultMessage: 'Create a new Quote'
    },
    oops: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.Oops',
        defaultMessage: 'Oops ...'
    },
    sorry: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.Sorry',
        defaultMessage: "We're Sorry"
    },
    QuoteNotFound: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.QuoteNotFound',
        defaultMessage: "Something isn't quite right! Please verify that you have entered the correct email, ZIP code, and quote number."
    },
    QuoteDeclined: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.QuoteDeclined',
        defaultMessage: 'We cannot retrieve quote {quoteNumber}, as it was previously declined.'
    },
    QuoteNotTaken: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.QuoteNotTaken',
        defaultMessage: 'We cannot retrieve quote {quoteNumber}, as it is no longer valid.'
    },
    QuoteNotValid: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.QuoteNotValid',
        defaultMessage: 'We cannot retrieve quote {quoteNumber}, as it is no longer valid.'
    },
    QuoteExpired: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.QuoteExpired',
        defaultMessage: 'Your quote is over 30 days old and has expired.'
    },
    retrievingSubmission: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.Retrieving submission...',
        defaultMessage: 'Retrieving your quote...'
    }
});