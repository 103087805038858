import { defineMessages } from 'react-intl';

export default defineMessages({
    referenceError: {
        id: 'pe-agent.util.ErrorHandler.Reference the error(s) below to help our teams resolve the issue',
        defaultMessage: 'Reference the error(s) below to help our teams resolve the issue'
    },
    underwriterShouldVerify: {
        id: 'pe-agent.util.ErrorHandler.Underwriter should verify the error in PC by quoting',
        defaultMessage: 'Underwriter should verify the error in PC by quoting'
    },
    message: {
        id: 'pe-agent.util.ErrorHandler.Message',
        defaultMessage: 'Message:'
    },
    quoteID: {
        id: 'pe-agent.util.ErrorHandler.Quote Id',
        defaultMessage: 'Quote Id:'
    },
    status: {
        id: 'pe-agent.util.ErrorHandler.Status',
        defaultMessage: 'Status:'
    },
    method: {
        id: 'pe-agent.util.ErrorHandler.Method',
        defaultMessage: 'Method:'
    },
    url: {
        id: 'pe-agent.util.ErrorHandler.Url',
        defaultMessage: 'Url:'
    },
    tryAgain: {
        id: 'pe-agent.util.ErrorHandler.Click here to try again',
        defaultMessage: 'Try Again'
    }
});
