import { getProxiedServiceUrl } from 'wmic-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class PolicyService {
    static createNewPolicyCancellationTransaction(
        policyNumber,
        cancellation,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicy'),
            'createPolicyCancellationTransaction',
            [policyNumber, cancellation],
            additionalHeaders
        );
    }

    static createNewPolicyChangeTransaction(policyChange, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'createPolicyChangeTransaction', [policyChange], additionalHeaders);
    }

    static getPolicy(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getPolicy', [policyNumber], additionalHeaders);
    }

    static getPolicyClaims(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getPolicyClaims', [policyNumber], additionalHeaders);
    }

    static getRecentlyIssuedPolicies(searchCriteria, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getTopRecentlyIssuedPolicies', [searchCriteria], additionalHeaders);
    }

    static getRecentlyViewedPolicies(searchCriteria, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getTopRecentlyViewedPolicies', [searchCriteria], additionalHeaders);
    }

    static getPolicySummariesForCurrentUser(additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getPolicySummariesForCurrentUser', [], additionalHeaders);
    }

    static getPolicyBillingSummaryInfo(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getPolicyBillingSummaryInfo', [policyNumber], additionalHeaders);
    }

    static addRecentlyViewedPolicy(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'addRecentlyViewedPolicy', [policyNumber], additionalHeaders);
    }

    static getNotesForPolicy(policyNumber, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getNotesForPolicy',
            [policyNumber], additionalHeaders);
    }

    static getDocumentsForPolicy(policyNumber, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getDocumentsForPolicy',
            [policyNumber], additionalHeaders);
    }

    static createNoteForPolicy(params, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'createNoteForPolicy',
            params, additionalHeaders);
    }

    static createNewPolicyRenewalTransaction(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'createPolicyRenewalTransaction', params, additionalHeaders);
    }

    static getPolicyTransactionsForPolicy(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getPolicyTransactionsForPolicy', [policyNumber], additionalHeaders);
    }

    static getPolicyPeriodJobType(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getPolicyPeriodJobType', params, additionalHeaders);
    }

    static getBOTPolicyStatusOptions(additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getBOTPolicyStatusOptions', [], additionalHeaders);
    }
}
