import { getProxiedServiceUrl } from 'wmic-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class CustomQuoteService {
    /**
     * Selects a quote
     * @param {String} quoteID the quote ID
     * @param {String} branchName the name of the selected quote
     * @param {String} sessionUUID the session UUID associated to the quoteID
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static setSelectedVersionOnSubmission(
        quoteID,
        branchName,
        sessionUUID,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayquote/customquote'),
            'setSelectedVersionOnSubmission',
            [quoteID, branchName, sessionUUID],
            additionalHeaders
        );
    }

    static updateCustomQuote(customQuoteDTO, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayquote/customquote'),
            'updateCustomQuote',
            [customQuoteDTO],
            additionalHeaders
        );
    }

    static updateCustomQuoteAndQuoteData(customQuoteDTO, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayquote/customquote'),
            'updateCustomQuoteAndQuoteData',
            [customQuoteDTO],
            additionalHeaders
        );
    }

    static updateCustomQuoteCoverages(customQuoteDTO, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayquote/customquote'),
            'updateCustomQuoteCoverages',
            [customQuoteDTO],
            additionalHeaders
        );
    }

    static updateCustomQuoteCoveragesAndQuoteData(customQuoteDTO, quoteDataDTO, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayquote/customquote'),
            'updateCustomQuoteCoveragesAndQuoteData',
            [customQuoteDTO, quoteDataDTO],
            additionalHeaders
        );
    }

    static forceUpdateCustomQuoteCoverages(customQuoteDTO, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayquote/customquote'),
            'forceUpdateCustomQuoteCoverages',
            [customQuoteDTO],
            additionalHeaders
        );
    }

    static forceUpdateCustomQuoteCoveragesAndQuoteData(customQuoteDTO, quoteDataDTO, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayquote/customquote'),
            'forceUpdateCustomQuoteCoveragesAndQuoteData',
            [customQuoteDTO, quoteDataDTO],
            additionalHeaders
        );
    }
}
