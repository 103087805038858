import { defineMessages } from 'react-intl';

export default defineMessages({
    warningRenewalIssued: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.FutureRenewalModal.Warning - Renewal Issued',
        defaultMessage: 'Warning - Renewal Issued'
    },
    aRenewalIssued: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.FutureRenewalModal.A renewal has been issued',
        defaultMessage: 'A renewal has been issued for this policy.'
    },
    changesBroughtForward: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.FutureRenewalModal.Changes will be brought forward',
        defaultMessage: 'Changes to this Policy will be bound and brought forward to renewal term. (Recommended)'
    },
    bindCurrentTermOnly: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.FutureRenewalModal.Bind for current term only',
        defaultMessage: 'Bind these changes for &lt;b&gt;current term only.&lt;/b&gt;'
    },
    currentAndRenewingTerm: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.FutureRenewalModal.Current and Renewing Term',
        defaultMessage: 'Current and Renewing Term'
    },
    currentTerm: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.FutureRenewalModal.Current Term',
        defaultMessage: 'Current Term'
    },
    cancel: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.FutureRenewalModal.Cancel',
        defaultMessage: 'Cancel'
    },
    or: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.FutureRenewalModal.or',
        defaultMessage: '&lt;b&gt;or&lt;/b&gt;'
    }
});
