/* eslint-disable prefer-template */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */

import _ from 'lodash';

const LANEWAY = 'Laneway_WMIC';
const HOMEOWNERS = 'HO3';
const REVENUE_PROPERTY = 'Basic_wmic';
const SEASONAL = 'Seasonal_wmic';
const DWELLING = 'dwelling';
import { HOConstants } from 'wmic-pe-portals-utils-js';

export default {
    dwellingCoverageLimitationOnly: (dwellingType, coverageLimitation) => {
        if (dwellingType) {
            const dwellingTypeCode = dwellingType.code;
            if ([REVENUE_PROPERTY, SEASONAL].includes(dwellingTypeCode)) {
                return coverageLimitation && coverageLimitation.code === DWELLING;
            }
            if (dwellingTypeCode === HOConstants.extensionPolicyType) {
                return false;
            }
        }
        return true;
    },
    isLanewayValid: (residenceType, associatedDwellingPublicID, dwellings) => {
        const lanewayPolicyTypes = [HOMEOWNERS, REVENUE_PROPERTY];
        let isLanewayValid = true;

        if (residenceType?.code === LANEWAY && dwellings) {
            isLanewayValid = _.some(dwellings, (dwelling) => (
                dwelling.yourHome.residenceType !== LANEWAY
                    && lanewayPolicyTypes.includes(dwelling.rating.hoPolicyType)
                    && (!dwelling.yourHome.associatedDwellingPublicID || dwelling.publicID === associatedDwellingPublicID)
            ));
        }
        return isLanewayValid;
    }
};
