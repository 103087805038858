import { defineMessages } from 'react-intl';

export default defineMessages({
    accountTitle: {
        id: 'pe-portal.components.transactionWizardTitle.transactionWizardTitle.Account:',
        defaultMessage: 'Account:'
    },
    wc7WorkersComp: {
        id: 'pe-portal.components.transactionWizardTitle.transactionWizardTitle.Workers Compensation Quote With QuoteID',
        defaultMessage: 'Workers\' Compensation Quote ({quoteNumber})'
    },
    commercialProperty: {
        id: 'pe-portal.components.transactionWizardTitle.transactionWizardTitle.Commercial Property Quote With QuoteID',
        defaultMessage: 'Commercial Property Quote ({quoteNumber})'
    },
    personalAuto: {
        id: 'pe-portal.components.transactionWizardTitle.transactionWizardTitle.PersonalAuto Quote With QuoteID',
        defaultMessage: 'Automobile Submission ({quoteNumber})'
    },
    personalUmbrella: {
        id: 'pe-portal.components.transactionWizardTitle.transactionWizardTitle.PersonalUmbrella Quote With QuoteID',
        defaultMessage: 'Personal Umbrella Submission ({quoteNumber})'
    },
    commercialPackage: {
        id: 'pe-portal.components.transactionWizardTitle.transactionWizardTitle.CommercialPackage Quote With QuoteID',
        defaultMessage: 'Commercial Lines Small Business Submission ({quoteNumber})'
    },
    businessOwners: {
        id: 'pe-portal.components.transactionWizardTitle.transactionWizardTitle.BusinessOwners Quote With QuoteID',
        defaultMessage: 'Business Owners Quote  ({quoteNumber})'
    },
    homeowners: {
        id: 'pe-portal.components.transactionWizardTitle.transactionWizardTitle.HomeOwners Quote With QuoteID',
        defaultMessage: 'Personal Property Submission ({quoteNumber})'
    },
    hopHomeowners: {
        id: 'pe-portal.components.transactionWizardTitle.transactionWizardTitle.HOPHomeOwners Quote With QuoteID',
        defaultMessage: 'Home Owners Quote ({quoteNumber})'
    },
    homeownersPolicyChange: {
        id: 'pe-endorsement.directives.templates.bank-account-details-quote.Policy Changes - Homeowners',
        defaultMessage: 'Personal Property Policy Change ({jobId})'
    },
    hopHomeownersPolicyChange: {
        id: 'pe-endorsement.directives.templates.bank-account-details-quote.Policy Changes - HOPHomeowners',
        defaultMessage: 'Personal Property Policy Change ({jobId})'
    },
    homeownersRenewal: {
        id: 'pe-portal.components.transactionWizardTitle.transactionWizardTitle.Policy Renewal - Homeowners',
        defaultMessage: 'Personal Property Renewal ({jobId})'
    },
    hopHomeownersRenewal: {
        id: 'pe-portal.components.transactionWizardTitle.transactionWizardTitle.Policy Renewal - HOPHomeowners',
        defaultMessage: 'Personal Property {quoteNumber} - Renewal {jobId}'
    },
    businessOwnersPolicyChange: {
        id: 'pe-endorsement.directives.templates.bop.Policy Changes - Business Owners',
        defaultMessage: 'Business Owners {quoteNumber} - Policy Change {jobId}'
    },
    businessOwnersRenewal: {
        id: 'pe-endorsement.directives.templates.bop.Policy Renewal - Business Owners',
        defaultMessage: 'Businessowners {quoteNumber} - Renewal {jobId}'
    },
    commercialPropertyPolicyChange: {
        id: 'pe-portal.components.transactionWizardTitle.transactionWizardTitle.Policy Changes - Commercial Property',
        defaultMessage: 'Commercial Property {quoteNumber} - Policy Change {jobId}'
    },
    commercialPropertyRenewal: {
        id: 'pe-portal.components.transactionWizardTitle.transactionWizardTitle.Policy Renewal - Commercial Property',
        defaultMessage: 'Commercial Property {quoteNumber} - Renewal {jobId}'
    },
    personalAutoPolicyChange: {
        id: 'pe-endorsement.directives.templates.pa.Policy Changes - Personal Auto',
        defaultMessage: 'Automobile Policy Change ({quoteNumber})'
    },
    personalAutoRenewal: {
        id: 'pe-portal.components.transactionWizardTitle.transactionWizardTitle.Policy Renewal - Personal Auto',
        defaultMessage: 'Automobile Renewal ({jobId})'
    },
    personalUmbrellaPolicyChange: {
        id: 'pe-endorsement.directives.templates.pup.Policy Changes - Personal Umbrella',
        defaultMessage: 'Personal Umbrella Policy Change ({quoteNumber})'
    },
    personalUmbrellaRenewal: {
        id: 'pe-portal.components.transactionWizardTitle.transactionWizardTitle.Policy Renewal - Personal Umbrella',
        defaultMessage: 'Personal Umbrella Renewal ({jobId})'
    },
    fnolNewClaim: {
        id: 'pe-fnol.common.views.fnol.New Claim',
        defaultMessage: 'New Claim'
    },
    editAccount: {
        id: 'pe-portal.components.transactionWizardTitle.transactionWizardTitle.Edit-account:',
        defaultMessage: 'Edit account'
    },
    termWithDates: {
        id: 'wmic-pe-portals-wizard-components-ui.WMICWizardPageHeader.Term (startDate) - (endDate)',
        defaultMessage: 'Term {periodStart} - {periodEnd}'
    },
    editAccountModalTitle: {
        id: 'wmic-pe-portals-wizard-components-ui.WMICWizardPageHeader.Edit Modal Title',
        defaultMessage: 'Leave this page?'
    },
    editAccountModalMessage: {
        id: 'wmic-pe-portals-wizard-components-ui.WMICWizardPageHeader.Edit Account Modal Message',
        defaultMessage: 'Changes not saved will be lost. Do you want to continue?'
    }
});
