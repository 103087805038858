/* eslint-disable no-secrets/no-secrets */
import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WMICWizardChangeOrRenewalPage, useDocumentTitle, useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import {
    WMICDateTimeService,
    WMICValidationUtil,
    CONSTANTS,
    WMICLogger,
    WMICUserAccessUtil,
} from 'wmic-pe-portals-utils-js';
import { WMICHouseholdMemberDetailsComponent, WMICHouseholdMemberUtil } from 'wmic-pe-capability-gateway-common-pup-react';

import { messages as commonMessages } from 'wmic-pe-capability-gateway-policyrenewal-common-react';
import metadata from './WMICPUPRenewalHouseholdMemberPage.metadata.json5';
import messages from './WMICPUPRenewalHouseholdMemberPage.messages';

const HOUSEHOLD_MEMBERS_PATH = 'lobData.personalUmbrella.coverables.householdMembers';

function WMICPUPRenewalHouseholdMemberPage(props) {
    const {
        wizardData: renewalVM,
        updateWizardData,
        viewModelService
    } = props;

    const translator = useContext(TranslatorContext);
    useDocumentTitle(translator(messages.householdMemberTitle), renewalVM);

    const { RenewalService: GatewayRenewalService } = useDependencies('RenewalService');

    const { onValidate, isComponentValid } = useValidation('WMICPUPRenewalHouseholdMemberPage');
    const { authUserData: currentUser, authHeader } = useAuthentication();
    const { showInfo, showConfirm, setWizardLoading } = useWizardModals();
    const [showErrors, setShowErrors] = useState(false);

    const canEditPolicyRenewal = useMemo(
        () => WMICUserAccessUtil.canEditPolicyRenewal(currentUser.roles),
        [currentUser]
    );

    useEffect(() => {
        _.set(renewalVM, 'isEditingPage.value', false);
    }, [renewalVM]);

    const onAddHouseholdMember = () => {
        const householdmember = WMICHouseholdMemberUtil.createANIHouseholdMember(renewalVM);
        const newHouseholdMember = viewModelService.create(householdmember,
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.personalumbrella.coverables.dto.householdmember.HouseholdMemberDTO_WMIC',
            renewalVM.aspects.context()
        );
        setShowErrors(false);
        renewalVM.lobData.personalUmbrella.coverables.householdMembers.value.push(newHouseholdMember.value);
        updateWizardData(renewalVM);
        _.set(renewalVM, 'isEditingPage.value', true);
        return newHouseholdMember;
    }

    const onUndoAddHouseholdMember = () => {
        const householdMembers = _.get(renewalVM.value, HOUSEHOLD_MEMBERS_PATH);
        householdMembers.splice(householdMembers.length - 1, 1);
        _.set(renewalVM.value, HOUSEHOLD_MEMBERS_PATH, householdMembers);
        updateWizardData(renewalVM);
        _.set(renewalVM, 'isEditingPage.value', false);
    }

    const onSaveHouseholdMember = async (member, index) => {
        const newRenewalVM = viewModelService.clone(renewalVM);

        if (!WMICHouseholdMemberUtil.isHouseholdMemberPNI(member, newRenewalVM)) {
            WMICHouseholdMemberUtil.clearOldPNI(newRenewalVM, viewModelService);
        }

        try {
            setWizardLoading(true, translator(messages.savingHouseholdMembers));
            newRenewalVM.value = await GatewayRenewalService.saveWithNoValidationRuleCheck(
                newRenewalVM.value,
                authHeader
            );

            updateWizardData(newRenewalVM);
            setShowErrors(false);
            _.set(renewalVM, 'isEditingPage.value', false);

            return true
        } catch (error) {
            WMICLogger.error('Save construction failed', error);
            return false;
        } finally {
            setWizardLoading(false);
        }
    };

    const onRemoveHouseholdMember =  (householdMember, index) => {
        if (householdMember?.isPNI?.value) {
            showInfo({
                title: messages.householdMemberTitle,
                message: messages.wmicPUPHouseholdMembersPrimaryNamedInsuredCannotBeRemoved
            });
            return _.noop();
        }
        showConfirm({
            title: messages.householdMemberTitle,
            message: messages.wmicPUPHouseholdMembersRemovingHouseholds,
        }).then((response) => {
            if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
                const allHouseholdMembers = _.get(renewalVM, `${HOUSEHOLD_MEMBERS_PATH}.value`, []);
                allHouseholdMembers.splice(index, 1);
                _.set(renewalVM, `${HOUSEHOLD_MEMBERS_PATH}.value`, allHouseholdMembers);
                updateWizardData(renewalVM);
            }
        });
        return _.noop();
    };

    const onEditHouseholdMember = () => {
        updateWizardData(renewalVM);
        _.set(renewalVM, 'isEditingPage.value', true);
    }

    const onCancelEditHouseholdMember = () => {
        _.set(renewalVM, 'isEditingPage.value', false);
        updateWizardData(renewalVM);
    }

    const getDisplayName = (member) => {
        if (!_.isUndefined(member?.person?.displayName)) {
            return member.person.displayName
        }
        return `${member.person.firstName} ${member.person.lastName}`;
    }

    const displayRelationship = (member) => {
        const relationshipTypeList = viewModelService.productMetadata.get('pc').types.getTypelist('ApplicantRelation_wmic');
        if (member?.isPNI) {
            return translator({id: relationshipTypeList.getCode('insured').name});
        }
        if (member?.relationshipToPrimaryInsured_WMIC) {
            return translator({id: relationshipTypeList.getCode(member.relationshipToPrimaryInsured_WMIC).name});
        }
        return '';
    };

    const displayDate = (data, path) => {
        const dateValue = _.get(data.value, path);
        return dateValue ? <span>{WMICDateTimeService.toMidnightDate(dateValue)}</span> : '';
    };

    const onNext = useCallback(async () => {
        if(!isComponentValid){
            setShowErrors(true);
            return false;
        }
        setWizardLoading(
            true,
            translator(
                commonMessages.savingTransactionDetails
            )
        );
        renewalVM.value = await GatewayRenewalService.saveRenewal(
            [renewalVM.value],
            authHeader
        );
        setWizardLoading(false);
        return renewalVM;
    }, [isComponentValid, setWizardLoading, translator, renewalVM, GatewayRenewalService, authHeader]);

    const overrideProps = {
        householdMembersListView: {
            detailViewComponent: WMICHouseholdMemberDetailsComponent,
            detailViewComponentProps: {
                jobVM: renewalVM
            },
            onValidate,
            value: _.get(renewalVM, `${HOUSEHOLD_MEMBERS_PATH}.children`, []),
            VMData: [
                {
                    headerText: translator(messages.wmicPUPHouseholdMembersName),
                    getData: (member) => getDisplayName(member.value)
                },
                {
                    headerText: translator(messages.wmicPUPHouseholdMembersRelationship),
                    getData: (member) => displayRelationship(member.value)
                },
                {
                    headerText: translator(messages.wmicPUPHouseholdMembersDateOfBirth),
                    getData: displayDate,
                    path: 'person.dateOfBirth'
                },
                {
                    headerText: translator(messages.wmicPUPHouseholdMembersPrimaryNamedInsured),
                    path: 'isPNI'
                }
            ],
            toCreate: onAddHouseholdMember,
            addButtonLabel: translator(messages.wmicPUPHouseholdMembersTitle),
            toUndoCreate: onUndoAddHouseholdMember,
            onSave: onSaveHouseholdMember,
            onDelete: onRemoveHouseholdMember,
            toEdit: onEditHouseholdMember,
            onCancel: onCancelEditHouseholdMember,
            readOnly: !canEditPolicyRenewal,
            showErrors
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICHouseholdMemberDetailsComponent
        }
    }

    return (
        <WMICWizardChangeOrRenewalPage
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICPUPRenewalHouseholdMemberPage.propTypes = wizardProps;

export default withViewModelService(WMICPUPRenewalHouseholdMemberPage);
