import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { WMICJsonRPCService as JsonRPCService } from 'wmic-pe-portals-transport-js';
import { WMICDocumentUploadService, WMICDocumentDownloadService } from 'wmic-pe-portals-document-js';

export default class GatewayDocumentService {
    static generateUploadToken(additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayDocument'), 'generateUploadToken', [], additionalHeaders);
    }

    static removeDocument(params, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayDocument'), 'removeDocument', [params], additionalHeaders);
    }

    static uploadDocumentAxios(file, documentMetadata, onUploadProgress, additionalHeaders) {
        return WMICDocumentUploadService.sendAxios(file, documentMetadata, onUploadProgress, additionalHeaders);
    }

    static downloadDocument(documentID, token, suite) {
        return WMICDocumentDownloadService.getDocumentLink(documentID, token, suite);
    }
}
