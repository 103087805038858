import { DocumentDownloadService } from '@xengage/gw-portals-document-js';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { WMICLogger, SUITES } from 'wmic-pe-portals-utils-js';

export default class WMICDocumentDownloadService {
    static getDocumentLink(documentID, token, suite) {
        const endPointInfoForLogging = `WMICDocumentDownload documentID=${documentID}, token=${token}`;

        let proxiedServiceUrl = getProxiedServiceUrl('policyDocument');

        switch (suite) {
            case SUITES.BC: {
                proxiedServiceUrl = getProxiedServiceUrl('billingDocument');
                break;
            }
            case SUITES.CC: {
                proxiedServiceUrl = getProxiedServiceUrl('claimDocument');
                break;
            }
            default: {
                proxiedServiceUrl = getProxiedServiceUrl('policyDocument');
                break;
            }
        }

        WMICLogger.debug(`${endPointInfoForLogging} request=sent to endpoint:${proxiedServiceUrl}`);
        try {
            const link = DocumentDownloadService.getDocumentLink(proxiedServiceUrl, documentID, token);
            WMICLogger.debug(`${endPointInfoForLogging} request=received with link:${link}`);
            return link;
        }
        catch(error) {
            WMICLogger.error(`${endPointInfoForLogging} request=error, error=${JSON.stringify(error)}`);
            throw error;
        }
    }
}
