/* eslint-disable camelcase */
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { WMICJsonRPCService } from 'wmic-pe-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return WMICJsonRPCService.send(getProxiedServiceUrl('gatewayRenewals'), method, data, additionalHeaders);
}

/**
 * This is the server responsible for dealing with the renewal backend operations
 *
 * @export
 * @class GatewayRenewalService
 */
export default class GatewayRenewalService {
    /**
     * Updates, saves and performs a quote on an existing submission.
     * Generates quotes for all available product offerings.
     *
     * @param {Object} jobNumber the renewal ID
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @param {Object} data the submission that will be saved
     * @returns {Promise} the promise from the backend call
     * @memberof RenewalService
     */
    static retrieve(jobNumber, additionalHeaders = {}) {
        return processSubmission('retrieve', [jobNumber], additionalHeaders);
    }

    static saveRenewal(data, additionalHeaders = {}) {
        return processSubmission('updateDraftRenewal', data, additionalHeaders);
    }

    static setQuoteToDraft(data, additionalHeaders = {}) {
        return processSubmission('setQuoteToDraft', data, additionalHeaders);
    }

    static updateCoverages(quoteID, sessionUUID, clausesToUpdate, additionalHeaders = {}) {
        return processSubmission('updateCoverages', [quoteID, clausesToUpdate], additionalHeaders);
    }


    static updateCoveragesAndRenewalData(sessionUUID, clausesToUpdate, quoteDataDTO, additionalHeaders = {}) {
        return processSubmission('updateCoveragesAndRenewalData', [clausesToUpdate, quoteDataDTO], additionalHeaders);
    }

    static generateQuote(data, additionalHeaders = {}) {
        return processSubmission('quote', data, additionalHeaders);
    }

    static isQuoted(data, additionalHeaders = {}) {
        return processSubmission('isQuoted', data, additionalHeaders);
    }

    static getSkeletonStructure(data, additionalHeaders = {}) {
        return processSubmission('getSkeletonStructure', [data], additionalHeaders);
    }

    static retrievePaymentPlans(data, additionalHeaders = {}) {
        return processSubmission('retrievePaymentPlans', data, additionalHeaders);
    }

    static bind_WMIC(data, additionalHeaders = {}) {
        return processSubmission('bind_WMIC', [data], additionalHeaders);
    }

    static updatePaymentPlan(data, additionalHeaders = {}) {
        return processSubmission('updatePaymentPlan', data, additionalHeaders);
    }

    static getPolicyDiffWithPrevious(data, additionalHeaders = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('policydiff'), 'getPolicyDiffWithPrevious', data, additionalHeaders);
    }

    static referToUnderwriter(renewalNumber, noteBody, additionalHeaders = {}) {
        return processSubmission('referToUnderwriter', [renewalNumber, noteBody], additionalHeaders);
    }

    // eslint-disable-next-line camelcase
    static getRenewalDiff_WMIC(jobNumber, additionalHeaders = {}) {
        return processSubmission('getRenewalDiff_WMIC', [jobNumber], additionalHeaders);
    }

    static postOnChange(policyRenewal, additionalHeaders = {}) {
        return processSubmission('postOnChange', [policyRenewal], additionalHeaders);
    }

    static validateRenewalForIssue(renewal, additionalHeaders = {}) {
        return processSubmission(
            'validateRenewalForIssue_WMIC',
            [renewal],
            additionalHeaders
        );
    }

    static saveWithNoValidationRuleCheck(renewal, additionalHeaders = {}) {
        return processSubmission(
            'saveWithNoValidationRuleCheck_WMIC',
            [renewal],
            additionalHeaders
        )
    }

    static findJobByJobNumber(renewalNumber, additionalHeaders = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('gatewayRenewal'),
            'findJobByJobNumber', [renewalNumber], additionalHeaders);
    }

    static getPARenewalSummary_WMIC(renewalNumber, additionalHeaders) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('gatewayRenewal'),
            'getPARenewalSummary_WMIC',
            [renewalNumber], additionalHeaders);
    }

    static getHORenewalSummary_WMIC(renewalNumber, additionalHeaders) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('gatewayRenewal'),
            'getHORenewalSummary_WMIC',
            [renewalNumber], additionalHeaders);
    }

    static getPUPRenewalSummary_WMIC(renewalNumber, additionalHeaders) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('gatewayRenewal'),
            'getPUPRenewalSummary_WMIC',
            [renewalNumber], additionalHeaders);
    }

    static removeDriver(jobId, driverId, additionalHeaders = {}) {
        return processSubmission(
            'removeDriver',
            [jobId, driverId],
            additionalHeaders
        );
    }

    static addPreRenewalDirection(lapseRenewal, additionalHeaders = {}) {
        return processSubmission('addPreRenewalDirection', [lapseRenewal], additionalHeaders);
    }

    static deletePreRenewalDirection(policyNumber, termNumber, reason, additionalHeaders = {}) {
        return processSubmission('deletePreRenewalDirection', [policyNumber, termNumber, reason], additionalHeaders);
    }

    static saveAndRecalculateFlexForRenewal (
        quoteID,
        flexInfos,
        waiveBrokerFee,
        additionalHeaders = {}
    ) {
        return processSubmission('saveAndRecalculateFlexForRenewal',
            [quoteID, flexInfos, waiveBrokerFee], additionalHeaders);
    }
};
