import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import { QuoteClauseTable, WMICAccordionCard } from 'wmic-pe-components-platform-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { Position, JobType } from "wmic-pe-portals-utils-js";
import { WMICHODesignatedGardenTractorLiability } from 'wmic-pe-capability-gateway-common-ho-react';
import { useAccordionValidation } from 'wmic-pe-portals-wizard-components-ui';
import WMICHOCoverageUtil from "wmic-pe-capability-gateway-common-ho-react/utils/WMICHOCoverageUtil";
import metadata from './WMICHOPolicyCoverages.metadata.json5';
import messages from './WMICHOPolicyCoverages.messages';

function WMICHOPolicyCoverages(props) {
    const {
        jobVM,
        lineCoverages,
        coverageColumnData,
        changeSubmissionAndSync,
        changeSubmission,
        syncCoverages,
        recalculate,
        resetQuote,
        onStaleQuoteBranchCode,
        underwritingIssues,
        quoteID,
        readOnly,
        onValidate,
        showErrors
    } = props;

    const { authHeader } = useAuthentication();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { EndorsementService } = useDependencies('EndorsementService');
    const { RenewalService } = useDependencies('RenewalService');

    const [isCoverageLoading, setIsCoverageLoading] = useState(false);
    const { onValidateAccordion, isAccordionValid } = useAccordionValidation(onValidate);
    const jobType = _.get(jobVM, 'baseData.jobType.value.code');

    const generateClauseData = () => coverageColumnData.map(({ lob, code }) => ({
                code,
                path: `${lob.path}.coverages.lineCoverages`,
                clauses: lineCoverages.value
            }))

    const saveJob = useCallback(async () => {
        let service;

        switch (jobType) {
            case JobType.SUBMISSION:
                service = LoadSaveService
                break;
            case JobType.POLICY_CHANGE:
                service = EndorsementService
                break;
            case JobType.RENEWAL:
                service = RenewalService
                break;
            default:
                break;
        }

        return service.saveWithNoValidationRuleCheck(
            jobVM.value,
            authHeader
        );
    }, [LoadSaveService, EndorsementService, RenewalService, jobType, jobVM, authHeader]);

    const updateJobAndCoverage = useCallback(async () => {
        setIsCoverageLoading(true);

        const result = await saveJob();

        _.extend(jobVM.value, result);
        setIsCoverageLoading(false);
    }, [jobVM, saveJob])

    const getCustomTerms = useCallback((clause, path) => {
        if (clause.publicID === 'HO_DesignatedGardenTractorExtentionCov' && clause.selected === true) {
            return [{
                component: WMICHODesignatedGardenTractorLiability,
                type: 'field',
                componentProps: {
                    jobVM,
                    gardenTractorListVM: _.get(jobVM, 'lobData.homeowners.coverables.designatedGardenTractorExtentionCovItems'),
                    path,
                    readOnly,
                    updateJobAndCoverage,
                    showErrors,
                    isLoading: isCoverageLoading,
                    id: clause.publicID
                }
            }];
        }
        return null;
    }, [jobVM, readOnly, updateJobAndCoverage, showErrors, isCoverageLoading]);

    const getPolicyCoverageAccordions = () => {
        const categoryMap = ['optionalPropertyCoverages', 'optionalLiabilityCoverages'];
        const categorizedCoverages = WMICHOCoverageUtil.categorizeLineCoverages(lineCoverages.value);

        return categoryMap.map((categoryKey) => {
            const lineCoverage = _.get(categorizedCoverages, categoryKey);
            const coverageTableData = [{
                data: lineCoverage.coverages,
                tableContent: generateClauseData()
            }]

            return (
                <WMICAccordionCard
                    title={lineCoverage.categoryName}
                    id={lineCoverage.categoryID}
                    isValid={isAccordionValid(`clauseTable-${lineCoverage.categoryID}`)}
                    showErrors={showErrors || showErrors}
                    chevron>
                    <QuoteClauseTable
                        id={`clauseTable-${lineCoverage.categoryID}`}
                        columnData={coverageColumnData}
                        tableData={coverageTableData}
                        quoteID={quoteID}
                        readOnly={readOnly}
                        onChangeSubmissionAndSync={changeSubmissionAndSync}
                        onChangeSubmission={changeSubmission}
                        onSyncCoverages={syncCoverages}
                        onRecalculate={recalculate}
                        onResetQuote={resetQuote}
                        onStaleQuoteBranchCode={onStaleQuoteBranchCode}
                        underwritingIssues={underwritingIssues}
                        filterUWIssuesInCustomOffering={false}
                        showTooltip={false}
                        getCustomTerms={getCustomTerms}
                        onValidate={onValidateAccordion}
                    />
                </WMICAccordionCard>
            )
        });
    }


    const overrideProps = {
        '@field': {
            labelPosition: Position.LEFT,
            parentNode: jobVM
        },
        policyLevelCoveragesAccordionCard: {
            content: getPolicyCoverageAccordions()
        }
    }

    return (
        <ViewModelForm
            model={jobVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
        />
    );
}
WMICHOPolicyCoverages.defaultProps = {
    onValidate: _.noop
}

export default WMICHOPolicyCoverages;
