import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import metadata from './WMICPUPCoverages.metadata.json5';

function WMICPupCoverages(props) {
    const {
        id,
        coverageVM,
        showErrors: parentShowErrors,
        updateCoverageData,
        onValidate: parentOnValidate,
        coveragePath,
        readOnly,
    } = props;

    const { onValidate, isComponentValid } = useValidation(id);

    useEffect(() => {
        if (parentOnValidate) {
            parentOnValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, parentOnValidate]);
    const overrideProps = {
        '@field': {
            parentNode: coverageVM
        },
        'pupCoverages': {
            path: coveragePath,
            readOnly
        }
    }

    const changeSubmissionAndSync = useCallback((value, changedPath) => {
        updateCoverageData(ClausesUtil.setClauseValue(coverageVM, value, changedPath));
    },[]);

    const resolvers = {
        resolveCallbackMap: {
            changeSubmissionAndSync,
            onValidate: parentOnValidate,
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={coverageVM}
            overrideProps={overrideProps}
            onModelChange={updateCoverageData}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
            showErrors={parentShowErrors}
        />
    );
}

WMICPupCoverages.propTypes = {
    id: PropTypes.string.isRequired,
    coverageVM: PropTypes.shape({}).isRequired,
    updateCoverageData: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired
};

export default WMICPupCoverages;
