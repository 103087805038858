import React, { Fragment, useState, useEffect } from 'react';
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useAuthentication } from 'wmic-digital-auth-react';
import { CONSTANTS } from 'wmic-portals-utils-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useModal } from '@jutro/components';
import { publish, JUTRO_TOPICS } from "@jutro/events";
import {
    WMICPolicyChangeStateService,
    AddressInfoModel,
    VehicleModel,
    AdditionalInterestInfoModel,
    FinanceLeasingModel,
    WMICControllerUtil
} from 'wmic-capability-policychange';
import { WMICButton } from 'wmic-components-platform-react';
import WMICUpdateFinanceFormComponent from './WMICUpdateFinanceFormComponent/WMICUpdateFinanceFormComponent';
import WMICUpdateFinancePAModal from './WMICUpdateFinancePAModal/WMICUpdateFinancePAModal';

import metadata from './WMICUpdateFinancePAComponent.metadata.json5';
import messages from './WMICUpdateFinancePAComponent.messages';
import styles from './WMICUpdateFinancePAComponent.module.scss';

function WMICUpdateFinancePAComponent(props) {
    const modalApi = useModal();
    const { policyVM, match, loadingState, setLoadingState } = props;
    const translator = useTranslator();
    const history = useHistory();
    const { authHeader, userInfo, authUserData  } = useAuthentication();
    const policyNumber = _.get(match, 'params.policyNumber');
    const termNumber = _.get(match, 'params.termNumber');
    const policyType = _.get(match, 'params.policyType');
    const accountHolder = _.get(policyVM, 'currentPeriod.contacts')
        .find((contact) => contact.contact.accountHolder === true);

    const [formActive, setFormActive] = useState(false);
    const [vehicleForm, setVehicleForm] = useState('');
    const [action, setAction] = useState('');

    const CHANGETYPE = CONSTANTS.POLICY_CHANGE_TYPE;

    // To resolve issues where screen readers don't announce content on page load for SPAs
    // Reference: https://www.gatsbyjs.com/blog/2019-07-11-user-testing-accessible-client-routing/
    useEffect(() => {
        const headerTag = document.getElementById('updateFinanceHeader');
        if (headerTag && !loadingState) {
            headerTag.focus({preventScroll: true});
        }
    }, [loadingState])

    const handleCancel = () => {
        history.push(`/account-policy-change/${policyNumber}/${termNumber}/auto/${CONSTANTS.POLICY_CHANGE_TYPE.SELECT_TYPE}`);
    };

    const handleCancelForm = () => {
        setAction('');
        setFormActive(false);
    };

    const handleAdd = () => {
        setAction(CHANGETYPE.FINANCE_LEASING_ADD);
        setFormActive(true);
    };

    const handleEdit = (vehicle) => {
        setAction(CHANGETYPE.FINANCE_LEASING_CHANGE);
        setVehicleForm(vehicle);
        setFormActive(true);
    };

    const handleSubmit = (data, type = action) => {
        const vehicle = _.get(policyVM, 'currentPeriod.lobs.personalAuto.vehicleDTOs').find((e) => {
            return `${e.year} ${e.make} ${e.model} (${e.vehicleNumber})` === data.vehicle;
        });

        const financeModel = new FinanceLeasingModel({
            changeType: action,
            vehicle: new VehicleModel({
                year: vehicle.year,
                make: vehicle.make,
                model: vehicle.model,
                vin: vehicle.vin
            }),
            companyInfo: new AdditionalInterestInfoModel({
                name: data.company,
                contractNumber: data.account,
                address: new AddressInfoModel({
                    street: data.street,
                    city: data.city,
                    state: data.state,
                    zip: data.zip
                })
            })
        });
        setLoadingState(true);

        publish(JUTRO_TOPICS.BUTTON_CLICKED, { trackButtonIdentifier: translator(messages.submitButtonTracking)});

        WMICPolicyChangeStateService.addChangeRequest('financial', type, {
            changeType: type,
            objectType: 'FinanceLeasingModel',
            changeModel: financeModel
        });
        WMICControllerUtil.handleSubmit({
            history: history,
            policyNumber: policyNumber,
            termNumber: termNumber,
            policyData: policyVM,
            policyType: policyType,
            accountInfo: accountHolder,
            authHeader: authHeader,
            authUserData: authUserData,
            userInfo: userInfo
        },
        translator);
    };

    const sendVerificationModalDataFormatter = (data) => {
        return (
            [
                {
                    key: translator(messages.updateFinanceVehicle),
                    value: `${data.year} ${data.make} ${data.model}`
                },
                {
                    key: translator(messages.updateFinanceVIN),
                    value: data.vin
                },
                {
                    key: translator(messages.updateFinanceCompanyName),
                    value: data.additionalInterests_WMIC[0].displayName
                },
                {
                    key: translator(messages.updateFinanceLoanAccount),
                    value: 'N/A'
                },
                {
                    key: translator(messages.updateFinanceStreet),
                    value: data.additionalInterests_WMIC[0].primaryAddress.addressLine1
                },
                {
                    key: translator(messages.updateFinanceCity),
                    value: data.additionalInterests_WMIC[0].primaryAddress.city
                },
                {
                    key: translator(messages.updateFinanceState),
                    value: data.additionalInterests_WMIC[0].primaryAddress.state
                },
                {
                    key: translator(messages.updateFinancePostalCode),
                    value: data.additionalInterests_WMIC[0].primaryAddress.postalCode
                },
            ]
        );
    };

    const removeModalDataFormatter = (data) => {
        return (
            [
                {
                    key: translator(messages.updateFinanceCompanyName),
                    value: data.additionalInterests_WMIC[0].displayName
                },
                {
                    key: translator(messages.updateFinanceVehicle),
                    value: `${data.year} ${data.make} ${data.model}`
                },
                {
                    key: translator(messages.updateFinanceVIN),
                    value: data.vin
                }
            ]
        );
    };

    const handleSendVerification = (vehicle) => {
        modalApi.showModal(<WMICUpdateFinancePAModal
            title={translator(messages.sendVerificationModalTitle)}
            subtitle={translator(messages.sendVerificationModalSubtitle)}
            data={sendVerificationModalDataFormatter(vehicle)}
            footerText={translator(messages.sendVerificationModalFooter)}
            primaryButtonMessage={translator(messages.sendVerificationModalSend)}
            secondaryButtonMessage={translator(messages.cancel)}
            onPrimaryAction={() => {
                handleSubmit(vehicle, CHANGETYPE.FINANCE_LEASING_SEND);
            }}
        />);
    };

    const handleRemove = (vehicle) => {
        modalApi.showModal(<WMICUpdateFinancePAModal
            title={translator(messages.removeModalTitle)}
            subtitle={translator(messages.removeModalSubtitle)}
            data={removeModalDataFormatter(vehicle)}
            footerText={translator(messages.sendVerificationModalFooter)}
            primaryButtonMessage={translator(messages.removeModalRemove)}
            secondaryButtonMessage={translator(messages.cancel)}
            onPrimaryAction={() => {
                handleSubmit(vehicle, CHANGETYPE.FINANCE_LEASING_DELETE);
            }}
        />);
    };

    const getUpdateFinanceTitle = () => {
        return (
            <Fragment>
                <i className={classNames('fa fa-file-text', styles['wmic-title-icon'])} />
                <h2 className={styles['wmic-title']}>{translator(messages.updateFinanceTitle)}</h2>
            </Fragment>
        );
    };

    const getFinanceCompanyTitle = (policy) => {
        const company = policy?.currentPeriod?.contacts?.find(
            (c) => c.contactRole === CONSTANTS.CONTACT_ROLES.ADDITIONAL_INTEREST
        );
        const res = company ? (
            <Fragment>
                <h3 className={styles['wmic-edit-title']}>{translator(messages.updateFinanceSubtitle)}</h3>
                <p className={styles['wmic-edit-subtitle']}>{translator(messages.updateFinanceSubtitle2)}</p>
            </Fragment>
        ) : (
            <h3 className={styles['wmic-edit-title-empty']}>{translator(messages.updateFinanceEmptyTitle)}</h3>
        );
        return res;
    };

    const getFinanceCompanies = (policy) => {
        const company = policy?.currentPeriod?.contacts?.find(
            (c) => c.contactRole === CONSTANTS.CONTACT_ROLES.ADDITIONAL_INTEREST
        );
        const res = (company) ? (
            _.get(policy, 'currentPeriod.lobs.personalAuto.vehicleDTOs').filter((vehicle) => {
                return (vehicle.additionalInterests_WMIC.length !== 0);
            }).map((vehicle) => {
                return (
                    <div key={vehicle.vin} className={styles['wmic-edit-row-child']}>
                        <p className={styles['ww-extra-minimal-padding']}>
                            <strong>{translator(messages.updateFinanceCompany)}</strong>
                            { `: ${vehicle.additionalInterests_WMIC[0].displayName}` }
                            <br />
                            <strong>{translator(messages.updateFinanceVehicle)}</strong>
                            { `: ${vehicle.year} ${vehicle.make} ${vehicle.model}` }
                        </p>
                        <div className={styles['wmic-edit-actions']}>
                            <WMICButton type="secondary" size="small" onClick={() => handleEdit(vehicle)}>
                                { translator(messages.edit) }
                            </WMICButton>
                            <WMICButton type="primary" size="small" onClick={() => handleRemove(vehicle)}>
                                { translator(messages.remove) }
                            </WMICButton>
                        </div>
                        <WMICButton type="secondary" size="small" onClick={() => handleSendVerification(vehicle)}>
                            { translator(messages.sendVerification) }
                        </WMICButton>
                    </div>
                );
            })
        ) : (
            <WMICButton type="secondary" className={styles.buttonPadding} onClick={handleAdd}>
                {translator(messages.updateFinanceAddNewCompany)}
            </WMICButton>
        );
        return res;
    };

    const getUpdateFinanceButtonSection = () => {
        return (
            <WMICButton type="outlined" onClick={handleCancel}>
                { translator(messages.cancel) }
            </WMICButton>
        );
    };

    const getUpdateFinanceFormSection = () => {
        return (
            <WMICUpdateFinanceFormComponent
                policyVM={policyVM}
                match={match}
                onCancel={handleCancelForm}
                vehicleData={vehicleForm}
                onSubmit={handleSubmit}
            />
        );
    };

    const overrideProps = {
        updateFinanceTitle: {
            content: getUpdateFinanceTitle()
        },
        updateFinanceSubpage: {
            visible: !formActive && !loadingState
        },
        updateFinanceCompanySectionTitle: {
            content: getFinanceCompanyTitle(policyVM),
        },
        updateFinanceCompanySectionList: {
            content: getFinanceCompanies(policyVM),
        },
        updateFinanceButtonSection: {
            content: getUpdateFinanceButtonSection(),
        },
        updateFinanceFormSection: {
            content: getUpdateFinanceFormSection(),
            visible: formActive && !loadingState
        },
        updateFinanceHeader: {
            visible: !loadingState
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };



    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            model={policyVM}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICUpdateFinancePAComponent.propTypes = {
    policyVM: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({}).isRequired
};

export default WMICUpdateFinancePAComponent;