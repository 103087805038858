import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import _ from 'lodash';
import { CONSTANTS, MVR_INCIDENT_CODE, MVR_ACTIONS, MVR_CONSTANTS, WMICJurisdictionUtil, WMICDateTimeService, WMICRPCUtil, JURISDICTIONS, MODAL_CONSTANTS } from 'wmic-pe-portals-utils-js';

import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { UISupportingInfoLookupService } from 'wmic-pe-capability-supportinginfo';

import WMICAddIncidentComponent from '../WMICAddIncidentComponent/WMICAddIncidentComponent';
import metadata from './WMICAddMVRComponent.metadata.json5';
import messages from './WMICAddMVRComponent.messages.js'
import styles from '../WMICMVRComponent.module.scss';

let convictionList;

function WMICAddMVRComponent(props){
    const {
        id,
        selectedMVR,
        saveMVR,
        cancelMVR,
        driverVM,
        baseData,
        updateSelectedMVR,
        MVRAction,
        updateDriver,
        minMvrDate,
        maxMvrDate,
        minDate,
        authHeader,
        calcDate,
        onValidate,
        isEditMode,
        duplicateMessage
    } = props;

    const translator = useContext(TranslatorContext);
    const ViewModelService = useContext(ViewModelServiceContext);
    const { showConfirm } = useWizardModals();
    const {onValidate: setComponentValidation, isComponentValid} = useValidation(id);

    const [currentMVR, updateCurrentMVR] = useState(selectedMVR);
    const [selectedIncident, updateSelectedIncident] = useState(undefined);
    const [toggle, forceRender] = useState(false);
    const [incidentAction, updateIncidentAction] = useState(MVR_ACTIONS.NONE);
    const [editingIndex, updateEditingIndex] = useState(-1);
    const [showErrors, updateShowErrors] = useState(false);

    const DRIVER_SUSPEND_REASON_TYPELIST = ViewModelService.productMetadata.get('pc').types.getTypelist('DriverSuspendReason_wmic');
    const PA_RESTRICTION_TYPELIST = ViewModelService.productMetadata.get('pc').types.getTypelist('PARestriction_WMIC');
    const MVR_REPORT_TYPE_TYPELIST = ViewModelService.productMetadata.get('pc').types.getTypelist('MVRReportType_WMIC');
    const MVR_SOURCE_MANUAL = ViewModelService.productMetadata.get('pc').types.getTypelist('MVRSource_WMIC').getCode('Manual');


    const baseState = _.get(baseData, 'baseState.value.code');

    useEffect(() => {
        updateCurrentMVR(selectedMVR);
    }, [selectedMVR])

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => {
            if(onValidate){
                onValidate(true, id)
            }
        }
    }, [isComponentValid, onValidate, currentMVR, id]);


    useEffect(() => {
        const mvrType = _.get(currentMVR, 'mvrType_WMIC.value');
        if (!mvrType && !determineIfMVRPredictorVisible()) {
            _.set(currentMVR, 'mvrType_WMIC?.value', MVR_CONSTANTS.FULL_REPORT);
            _.set(currentMVR, 'isMVRPredictor?.value', false);
            updateMVR(currentMVR);
        }
        UISupportingInfoLookupService.retrieveConvictionList(baseState, authHeader)
            .then((convictions) => {
                convictions.forEach((item) => {
                    item.name = item.description
                    item.code = item.publicID
                })
                convictionList = convictions;
            });
    }, [])


    const addNewIncident = () => {
        if(WMICJurisdictionUtil.isBaseState(baseData, JURISDICTIONS.QUEBEC) && selectedMVR === undefined) {
            const newMVR = ViewModelService.create(
                { isReadonly: false },
                'pc',
                'wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.dto.MVRReportDTO_WMIC',
                { OrderMVR: _.get(driverVM, 'orderMVR_WMIC.value'),  ...driverVM.aspects.context()}
            );
            _.set(newMVR, 'mvrIncidents_WMIC', []);
            _.set(driverVM, 'isAddingMVR', true);
            updateSelectedMVR(newMVR);
            updateDriver(driverVM);
            calcDate(currentMVR);
        };

        const newIncident = ViewModelService.create({},
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.dto.MVRIncidentDTO_WMIC',
            { IsMvrReportReadOnly:  !isEditMode, ...driverVM.aspects.context()}
            );

            updateSelectedIncident(newIncident);
        updateIncidentAction(MVR_ACTIONS.ADD);
    };

    const editIncident = (item, index) => {
        const selectedIncidentCopy = ViewModelService.create(
            _.cloneDeep(item.value),
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.dto.MVRIncidentDTO_WMIC',
            {IsMvrReportReadOnly: !isEditMode}
        );
        updateSelectedIncident(selectedIncidentCopy);
        updateEditingIndex(index);
        updateIncidentAction(MVR_ACTIONS.EDIT);
    };

    const saveIncident = () => {
        if(_.get(selectedIncident, 'convictionPublicID.value')) {
            const conviction = _.find(convictionList, (conv) => conv.publicID === _.get(selectedIncident, 'convictionPublicID.value'));
            _.set(selectedIncident, 'convictionDescription', conviction.description);
        }
        if (incidentAction === MVR_ACTIONS.EDIT) {
            _.get(selectedMVR,'mvrIncidents_WMIC.value').splice(editingIndex, 1, selectedIncident.value);
        } else if (incidentAction === MVR_ACTIONS.ADD) {
            const mvrIncidents = _.get(selectedMVR, ['mvrIncidents_WMIC', 'value'], []);
            mvrIncidents.push(selectedIncident.value);
            _.set(selectedMVR, ['mvrIncidents_WMIC', 'value'], mvrIncidents)
        } else {
            return;
        }

        if(WMICJurisdictionUtil.isBaseState(baseData, JURISDICTIONS.QUEBEC)) {
            const mvrReport = currentMVR.value;
            mvrReport.reportSource = MVR_SOURCE_MANUAL.code;

            if (!_.get(driverVM, 'mvrReports_WMIC.value')) {
                _.set(driverVM, 'mvrReports_WMIC.value', []);
            }

            if (incidentAction === MVR_ACTIONS.EDIT) {
                _.get(driverVM, 'mvrReports_WMIC.value').splice(0, 1, mvrReport);
            } else if (incidentAction === MVR_ACTIONS.ADD) {
                _.set(driverVM, 'mvrReports_WMIC.value', [mvrReport]);
            }

            _.set(driverVM, 'isAddingMVR', false);
            updateDriver(driverVM);
        }

        updateIncidentAction(MVR_ACTIONS.NONE);
        updateMVR(selectedMVR);
    };

    const removeIncident = (item) => {
        showConfirm({
            title: messages.RemovingIncident,
            message: messages.RemovingIncidentConfirm,
            status: MODAL_CONSTANTS.STATUS.WARNING,
            icon: MODAL_CONSTANTS.ICON.WARNING,
            confirmButtonText: messages.Yes,
            cancelButtonText: messages.No
        }).then((result)=> {
            if(result === CONSTANTS.MODAL_RESULT.CONFIRM) {
                const incidentList = _.get(currentMVR,'mvrIncidents_WMIC.value');
                const index = incidentList.indexOf(item.value);
                if (index>=0) incidentList.splice(index, 1);
                updateIncidentAction(MVR_ACTIONS.NONE);
                if (currentMVR) {
                    const mvrReportsArray = _.get(driverVM, 'mvrReports_WMIC.value');
                    const currentMVRReportIndex = mvrReportsArray.findIndex((elem) => elem.fixedId === currentMVR.fixedId.value);
                    if (currentMVRReportIndex !== -1) {
                        driverVM.mvrReports_WMIC.value[currentMVRReportIndex].mvrIncidents_WMIC.length = 0;
                        currentMVR.value.mvrIncidents_WMIC.forEach((elem) => driverVM.mvrReports_WMIC.value[currentMVRReportIndex].mvrIncidents_WMIC.push(elem));
                        updateDriver(driverVM);
                    }
                }
                updateMVR(currentMVR);
            }
        });
    };

    const cancelIncident = () => {
        updateIncidentAction(MVR_ACTIONS.NONE)

        if(WMICJurisdictionUtil.isBaseState(baseData, JURISDICTIONS.QUEBEC)) {
            _.set(driverVM, 'isAddingMVR.value', false);
            updateDriver(driverVM);
        }
    };

    const updateMVR = (data) => {
        forceRender(!toggle);
        updateCurrentMVR(data);
    };

    const handleSaveMVR = () => {
        if(isComponentValid) {
            saveMVR(currentMVR)
        } else {
            updateShowErrors(true)
        }
    };

    const isSuspensionIncident = (incident) => {
        return incident && incident.incidentType ? incident.incidentType === MVR_INCIDENT_CODE.SUSPENSION : false;
    };

    const isRestrictionIncident = (incident) => {
        return incident && incident.incidentType ? incident.incidentType === MVR_INCIDENT_CODE.RESTRICTION : false;
    };

    const displayIncidentViolationDate = (data) => {
        return data?.value?.violationDate ? <span>{WMICDateTimeService.toMidnightDate(data.value.violationDate)}</span> : '';
    };

    const displayIncidentConvictionDate = (data) => {
        return data?.value?.convictionDate ? <span>{WMICDateTimeService.toMidnightDate(data.value.convictionDate)}</span> : '';
    };

    const displayIncidentDescription = (data) => {
        const dataValue = data.value;
        const isReadOnly = _.get(currentMVR, 'value.isReadOnly')
        let description = '';

        if(isReadOnly) {
            description = dataValue.readOnlyIncidentDescription
        } else if (isSuspensionIncident(dataValue)) {
            if(dataValue.driverSuspendReason_wmic) {
                if (dataValue.driverSuspendReason_wmic === CONSTANTS.OTHER) {
                    description = translator(messages.mvrIncidentDescriptionOther, { desc: (dataValue.otherTypeDescribeOther || '')})
                } else {
                    description = translator({id: DRIVER_SUSPEND_REASON_TYPELIST.getCode(dataValue.driverSuspendReason_wmic).name});
                }
            }
        } else if (isRestrictionIncident(dataValue)) {
            if (dataValue.restrictionType) {
                if (dataValue.restrictionType === CONSTANTS.REASON.OTHER) {
                    description = translator(messages.mvrIncidentDescriptionOther, { desc: (dataValue.otherTypeDescribeOther || '')})
                } else {
                    description = translator({id: PA_RESTRICTION_TYPELIST.getCode(dataValue.restrictionType).name});
                }
            }
        } else {
            description = dataValue.convictionDescription;
        }
        return description || ''
    };

    const isMVRPredictorChanged = (value, path) => {
        _.set(currentMVR, path, value)
        let typeCode = MVR_CONSTANTS.PREDICTOR_ONLY;

        if (_.get(currentMVR, 'isMVRPredictor.value') === false) {
            _.set(currentMVR, 'mvrScore_WMIC.value', null);
            typeCode = MVR_CONSTANTS.FULL_REPORT;
        }

        _.set(currentMVR, 'mvrType_WMIC.value', MVR_REPORT_TYPE_TYPELIST.getCode(typeCode));
        updateMVR(currentMVR);
    };

    const onMVRTypeChanged = (value) => {
        _.set(currentMVR, 'isMVRPredictor.value', value !== MVR_CONSTANTS.FULL_REPORT);
        _.set(currentMVR, 'mvrType_WMIC.value', MVR_REPORT_TYPE_TYPELIST.getCode(value));
        updateMVR(currentMVR);
    };

    const isRPCEffectiveForBusSegment = ((rpcNumber) => {
        return WMICRPCUtil.getIsRPCEffectiveForBusSegment(
            baseState,
            _.get(baseData, 'rateAsOfDate.value'),
            null,
            rpcNumber);
    });

    const isBaseStateQC = () => {
        return WMICJurisdictionUtil.isBaseState(baseData, JURISDICTIONS.QUEBEC);
    };

    const determineIfMVRPredictorVisible = () => {
        return isBaseStateQC() && !isRPCEffectiveForBusSegment('1666');
    };

    const resolvers = {
        resolveCallbackMap: {
            handleSaveMVR,
            addNewIncident,
            cancelMVR,
            isMVRPredictorChanged,
            onMVRTypeChanged,
        },
        resolveComponentMap: {
            WMICAddIncidentComponent
        },
        resolveClassNameMap: styles
    };

    const overrideProps = {
        '@field': {
            parentNode: currentMVR,
            readOnly: MVRAction === MVR_ACTIONS.VIEW || !isEditMode,

        },
        isMVRPredictor: {
            visible: determineIfMVRPredictorVisible(),
        },
        mvrType_WMIC: {
            visible: !determineIfMVRPredictorVisible(),
        },
        addIncidentComponent: {
            visible: incidentAction !== MVR_ACTIONS.NONE,
            selectedIncident,
            cancelIncident,
            saveIncident,
            maxMvrDate,
            minDate,
            convictionList,
            onValidate,
            incidentAction
        },
        buttonContainer: {
            visible: MVRAction !== MVR_ACTIONS.VIEW
        },
        addViolationsButton: {
            disabled: MVRAction === MVR_ACTIONS.VIEW || incidentAction === MVR_ACTIONS.ADD || !isEditMode
        },
        mvrDate: {
            minDate:minMvrDate,
            maxDate:maxMvrDate
        },
        WMICAddMVRForm: {
            disableSave: incidentAction !== MVR_ACTIONS.NONE,
            disableCancel: incidentAction !== MVR_ACTIONS.NONE,
            hideButtons: MVRAction === MVR_ACTIONS.VIEW || WMICJurisdictionUtil.isBaseState(baseData, JURISDICTIONS.QUEBEC),
            hideBorder: WMICJurisdictionUtil.isBaseState(baseData, JURISDICTIONS.QUEBEC)
        },
        incidentDataList: {
            VMList: _.get(currentMVR, 'mvrIncidents_WMIC.children', []),
            VMData: [
                {
                    headerText: translator(messages.MVRViolationsSuspensionsDate),
                    getData: displayIncidentViolationDate
                },
                {
                    headerText: translator(messages.MVRConvictionReinstatementDate),
                    getData: displayIncidentConvictionDate
                },
                {
                    headerText: translator(messages.MVRType),
                    path: 'incidentType'
                },
                {
                    headerText: translator(messages.MVRDescription),
                    getData: displayIncidentDescription
                },
            ],
            onEditAction: editIncident,
            onRemoveAction: removeIncident,
            flatCards: true,
            clickable: false,
            selectedCardIndex: editingIndex,
            updateSelectedCardIndex: updateEditingIndex,
            isEditing: MVRAction === MVR_ACTIONS.VIEW || !isEditMode,
        },
        duplicateMessage: {
            content: duplicateMessage
        },
        duplicateMessageContainer: {
            visible: duplicateMessage.length > 0
        },
        mvrIncidentContainer: {
            visible: _.get(driverVM, 'mvrIncidentContainer.aspects.ocular')
        },
        ViolationsSuspensions: {
            visible: _.get(driverVM, 'mvrIncidentContainer.aspects.ocular')
        }
    };


    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentMVR}
            overrideProps={overrideProps}
            onModelChange={updateMVR}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    )
}

WMICAddMVRComponent.propTypes = {
    id: PropTypes.string.isRequired,
    selectedMVR: PropTypes.shape({}).isRequired,
    saveMVR: PropTypes.func.isRequired,
    cancelMVR: PropTypes.func.isRequired,
    driverVM: PropTypes.shape({
        person: PropTypes.shape({
            publicID: PropTypes.shape({})
        })
    }).isRequired,
    baseData: PropTypes.shape({}).isRequired,
    updateSelectedMVR: PropTypes.func.isRequired,
    MVRAction: PropTypes.string.isRequired,
    updateDriver: PropTypes.func.isRequired,
    minMvrDate: PropTypes.object.isRequired,
    maxMvrDate: PropTypes.object.isRequired,
    minDate: PropTypes.object.isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string.isRequired
    }).isRequired,
    calcDate: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired
};

export default WMICAddMVRComponent;
