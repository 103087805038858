/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable max-len */
import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import {
    LocalDateUtil,
    CONSTANTS,
    WMICVariousUtil,
    PAYMENT_METHOD
} from 'wmic-portals-utils-js';
import {
    WMICCustomInput,
    WMICCustomRadioButton,
    WMICAddressLookupComponent,
    WMICCustomDropdownSelect,
    WMICCustomTooltip,
    WMICTimezoneUtilService
} from 'gw-capability-quoteandbind-common-react';
import chequeImage from 'wmic-qb-digital-theme-styles/images/common/cheque-image.png';
import WMICPolicySummaryUtil from '../../../gw-capability-quoteandbind-ho-react/pages/WMICPolicySummary/WMICPolicySummaryUtil';

import metadata from './WMICBankAccountDetails.metadata.json5';
import messages from './WMICBankAccountDetails.messages';
import styles from './WMICBankAccountDetails.module.scss';

dayjs.extend(utc);

const PAYER_DROPDOWN_SELECT = 'payerDropdownSelect';
const DEFAULT_WITHDRAWAL_DAYS_BUFFER = 2;

const selectedPaymentMethodIsAutoRecurring = (submissionVM) => {
    const paymentMethod = _.get(submissionVM, 'bindData.paymentDetails.paymentMethod.value');
    return paymentMethod === PAYMENT_METHOD.AUTO_RECURRING || paymentMethod === PAYMENT_METHOD.AUTO_RECURRING_ACCOUNT_INFO;
};
const availableWithdrawalDateValues = _.range(1, 28).map((value) => {
    return {
        code: value,
        name: value
    };
});

const WMICBankAccountDetails = (props) => {
    const {
        submissionVM,
        updateWizardData,
        payer,
        updatePayer,
        selectedPaymentMethodIsWire,
        selectedPaymentMethodIsCreditCard,
        twelvePayDisabled,
        bankAccountOnly,
        showErrors,
        setFirstInstallmentIsDoubleAmount,
        twelvePayDisabledRegion,
        isOneIncFeatureFlagAvailable
    } = props;

    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const [shouldShowWithdrawalDateWarningMessage, setShowWithdrawalDateWarningMessage] = useState(false);
    const [firstWithdrawalDate, setFirstWithdrawalDate] = useState('');
    const emptyLabel = '.';
    const [labelContent, setLabelContent] = useState(emptyLabel);
    const [fieldTouched, setFieldTouched] = useState(false);
    const [nameOfLabelClass, setNameOfLabelClass] = useState('wmicWizardInputLabel wmicInactive wmicLabelInitClass');


    const hasAddlNamedInsured = (aSubmissionVM) => {
        return aSubmissionVM.value.baseData.hasAddlNamedInsured;
    };

    const hasDapPerson = (aSubmissionVM) => {
        return aSubmissionVM.value.bindData.hasDapForNotification_WMIC;
    };

    const populatePayerDropdown = useCallback((submission) => {
        const availablePayers = [];
        availablePayers.push({
            label: translator(messages.payer), value: '', selected: true, disabled: true
        });
        availablePayers.push({ label: `${submission.baseData.accountHolder.firstName.value} ${submission.baseData.accountHolder.lastName.value}`, value: CONSTANTS.PRIMARY_NAMED_INSURED_CAMEL_CASE });
        if (hasAddlNamedInsured(submission)) {
            availablePayers.push({ label: `${submission.value.baseData.aniperson_WMIC.firstName} ${submission.value.baseData.aniperson_WMIC.lastName}`, value: CONSTANTS.ADD_NAMED_INSURED_CAMEL_CASE });
        }
        if (hasDapPerson(submission)) {
            availablePayers.push({ label: `${submission.bindData.dapPerson_WMIC.firstName.value} ${submission.bindData.dapPerson_WMIC.lastName.value}`, value: CONSTANTS.DAP_PERSON_CAMEL_CASE });
        }
        // 'Other' option: this option was removed in the context of the change request RQB-1713
        // availablePayers.push({
        //     label: translator(messages.other), value: CONSTANTS.OTHER, selected: false, disabled: false
        // });
        return availablePayers;
    }, [translator]);

    const createPayerDropdown = useCallback(() => {
        const payers = populatePayerDropdown(submissionVM);
        return (
            <div className="wmicDisplayFlex">
                <div className="wmicWizardInput wmicInputPosition">
                    <label htmlFor={PAYER_DROPDOWN_SELECT} className={nameOfLabelClass}>
                        {labelContent}
                        <select
                            id={PAYER_DROPDOWN_SELECT}
                            onChange={(event) => {
                                const newPayer = event.target.value;
                                updatePayer(newPayer);
                                _.set(submissionVM, 'bindData.paymentDetails.payer.value', newPayer);
                                const newSubmissionVM = WMICPolicySummaryUtil.resetPayerData(newPayer, submissionVM, viewModelService);
                                updateWizardData(newSubmissionVM);
                            }}
                            value={payer}
                            disabled={twelvePayDisabled}
                            onFocus={() => {
                                setLabelContent(translator(messages.payer));
                                setNameOfLabelClass('wmicWizardInputLabel wmicActive');
                            }}
                            onBlur={() => {
                                setFieldTouched(true);
                                setNameOfLabelClass('wmicWizardInputLabel wmicInactive');
                            }}
                        >
                            {payers.map((p) => {
                                return <option value={p.value} disabled={p.disabled} selected={p.selected}>{p.label}</option>;
                            })}
                        </select>
                    </label>
                    {showErrors && (
                        <div className="inline-messages clear">
                            {!payer && fieldTouched && (
                                <span className="error-inline">{translator(messages.selectPayer)}</span>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }, [labelContent, nameOfLabelClass, payer, populatePayerDropdown, showErrors, submissionVM, translator, twelvePayDisabled, updatePayer, updateWizardData, viewModelService]);

    const getChequeImage = useCallback(() => {
        return (
            <div>
                <span>
                    <img alt="cheque-image" src={chequeImage} />
                </span>
            </div>
        );
    }, []);

    const writeValue = useCallback(
        (value, path) => {
            _.set(submissionVM, `${path}.value`, value);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const getDateWarningMessage = useCallback(() => {
        const dateWarningMessage = translator(
            messages.withdrawalMessage,
            { dueDate: firstWithdrawalDate }
        );
        return dateWarningMessage;
    }, [firstWithdrawalDate, translator]);

    const onWithdrawalDateChange = useCallback((value, path) => {
        writeValue(value, path);
        let shouldShowWarning = false;
        const periodStartDateTime = LocalDateUtil.toMidnightDate(_.get(submissionVM, 'baseData.periodStartDate.value'));
        const policyEffectiveDateMoment = dayjs(WMICTimezoneUtilService.getDateInPolicyLocationTimeZone(periodStartDateTime)).startOf('day');
        // find out what date the user chose
        const chosenWithdrawalDate = dayjs(policyEffectiveDateMoment).date(_.get(submissionVM, 'bindData.paymentDetails.bankAccountData.withdrawalDateOfMonth_WMIC.value'));

        // move it to the next month if it's before the policy effective date (can't take money before the date)
        if (chosenWithdrawalDate.isBefore(policyEffectiveDateMoment)) {
            chosenWithdrawalDate.add(1, 'months');
        }

        // If the withdrawal date is equal to or greater than the policy effective date and is less than 2 days from the current date
        // show the withdrawal message
        if (chosenWithdrawalDate.isSame(policyEffectiveDateMoment) || chosenWithdrawalDate.isAfter(policyEffectiveDateMoment)) {
            const chosenDate = chosenWithdrawalDate.clone().startOf('day');
            const currentDate = dayjs(WMICTimezoneUtilService.getDateInPolicyLocationTimeZone(new Date())).startOf('day');
            const daysDifference = Math.abs(chosenDate.diff(currentDate, 'days'));

            if (daysDifference < DEFAULT_WITHDRAWAL_DAYS_BUFFER) {
                setFirstWithdrawalDate(chosenWithdrawalDate.clone().add(1, 'months').format('MMM D'));
                shouldShowWarning = true;
            }
        }


        setShowWithdrawalDateWarningMessage(shouldShowWarning);
        setFirstInstallmentIsDoubleAmount(shouldShowWarning);
    }, [setFirstInstallmentIsDoubleAmount, submissionVM, writeValue]);

    const getDefaultWithdrawalDateOfMonth = () => {
        const periodStartDateTime = LocalDateUtil.toMidnightDate(_.get(submissionVM, 'baseData.periodStartDate.value'));
        let tempWithdrawalDateOfMonth = WMICTimezoneUtilService.getDateInPolicyLocationTimeZone(periodStartDateTime);
        tempWithdrawalDateOfMonth = dayjs(tempWithdrawalDateOfMonth).add(1, 'day').date();
        return tempWithdrawalDateOfMonth > 27 ? 1 : tempWithdrawalDateOfMonth;
    };

    const writeNumberOrDashValue = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM, path, String(value).replace(/[^\d-]/g, ''));
            updateWizardData(newSubmissionVM);
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    useEffect(() => {
        const bankABANumber = _.get(submissionVM, 'bindData.paymentDetails.bankAccountData.bankABANumber.value', null);
        const bankAccountNumber = _.get(submissionVM, 'bindData.paymentDetails.bankAccountData.bankAccountNumber.value', null);
        const newSubmissionVM = WMICPolicySummaryUtil.resetPayerData(payer, submissionVM, viewModelService);
        _.set(newSubmissionVM, 'bindData.paymentDetails.bankAccountData.value', _.get(submissionVM, 'bindData.paymentDetails.bankAccountData.value', {}));
        _.set(newSubmissionVM, 'bindData.paymentDetails.bankAccountData.bankABANumber.value', bankABANumber);
        _.set(newSubmissionVM, 'bindData.paymentDetails.bankAccountData.bankAccountNumber.value', bankAccountNumber);
        if (_.isUndefined(_.get(newSubmissionVM, 'bindData.paymentDetails.bankAccountData.withdrawalDateOfMonth_WMIC.value'))) {
            onWithdrawalDateChange(getDefaultWithdrawalDateOfMonth(), 'bindData.paymentDetails.bankAccountData.withdrawalDateOfMonth_WMIC');
        }
        
        updateWizardData(newSubmissionVM);
        // The above action only need to run once when the page is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const overrides = {
        payerDropdownSelectDiv: {
            visible: bankAccountOnly !== true || isOneIncFeatureFlagAvailable,
            content: createPayerDropdown()
        },
        bankAccountDetails: {
            visible: selectedPaymentMethodIsWire(submissionVM) || selectedPaymentMethodIsCreditCard(submissionVM)
                || (selectedPaymentMethodIsAutoRecurring(submissionVM) && !twelvePayDisabledRegion)
        },
        address: {
            visible: !isOneIncFeatureFlagAvailable,
            model: submissionVM,
            stateField: {
                readOnly: false
            },
            value: _.get(submissionVM, 'bindData.paymentDetails.bankAccountData.address_WMIC')
        },
        otherPayerDetails: {
            visible: !_.isUndefined(payer) && payer === CONSTANTS.OTHER && bankAccountOnly !== true
        },
        chequeImage: {
            visible: selectedPaymentMethodIsWire(submissionVM),
            content: getChequeImage()
        },
        payerContactInfoContainer: {
            visible: payer !== '' && bankAccountOnly !== true
        },
        pniEmailContainer: {
            visible: !_.isUndefined(payer) && payer === CONSTANTS.PRIMARY_NAMED_INSURED_CAMEL_CASE,
        },
        pniEmail: {
            placeholder: _.get(submissionVM, 'bindData.paymentDetails.bankAccountData.email_WMIC.value'),
            value: _.get(submissionVM, 'bindData.paymentDetails.bankAccountData.email_WMIC.value'),
            disabled: payer === CONSTANTS.PRIMARY_NAMED_INSURED_CAMEL_CASE
        },
        pniEmailTextHint: {
            className : styles.wmicEmailTooltipHint,
            content: WMICVariousUtil.getTextWithIcon('fa fa-question-circle wmicIconBig', translator(messages.emailUpdateHint))
        },
        addlInsuredEmailContainer: {
            visible: payer === CONSTANTS.ADD_NAMED_INSURED_CAMEL_CASE
        },
        addlInsuredPhoneContainer: {
            visible: selectedPaymentMethodIsAutoRecurring(submissionVM) && payer === CONSTANTS.ADD_NAMED_INSURED_CAMEL_CASE
        },
        bankAccountFields: {
            visible: !isOneIncFeatureFlagAvailable && (bankAccountOnly || (!selectedPaymentMethodIsAutoRecurring(submissionVM) && payer !== ''))
        },
        bankABANumber: {
            onValueChange: writeNumberOrDashValue
        },
        bankAccountNumber: {
            onValueChange: writeNumberOrDashValue
        },
        withdrawalDateContainer: {
            visible: payer !== '' && selectedPaymentMethodIsAutoRecurring(submissionVM) && bankAccountOnly !== true
        },
        withdrawalDate: {
            disabled: twelvePayDisabled,
            availableValues: availableWithdrawalDateValues,
            onValueChange: (value) => onWithdrawalDateChange(value, 'bindData.paymentDetails.bankAccountData.withdrawalDateOfMonth_WMIC'),
            value: submissionVM?.bindData.paymentDetails.bankAccountData?.withdrawalDateOfMonth_WMIC.value,
            alwaysShowPlaceholder: false
        },
        dateWarningMessage: {
            visible: shouldShowWithdrawalDateWarningMessage,
            className : styles.wmicDateTooltipHint,
            content: WMICVariousUtil.getTextWithIcon('fa fa-question-circle wmicIconBig', getDateWarningMessage())
        },
        firstName: {
            visible: !isOneIncFeatureFlagAvailable
        },
        lastName: {
            visible: !isOneIncFeatureFlagAvailable
        },
        phoneContainer: {
            visible: !isOneIncFeatureFlagAvailable
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            wmicInput: WMICCustomInput,
            wmicAddressLookupComponent: WMICAddressLookupComponent,
            wmicCustomRadioButton: WMICCustomRadioButton,
            wmicCustomTooltip: WMICCustomTooltip,
            wmicCustomDropdownSelect: WMICCustomDropdownSelect
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            overrideProps={overrides}
            model={submissionVM}
            onModelChange={updateWizardData}
        />
    );
};

WMICBankAccountDetails.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired,
    updateWizardData: PropTypes.func.isRequired,
    payer: PropTypes.string,
    updatePayer: PropTypes.func,
    selectedPaymentMethodIsWire: PropTypes.func,
    selectedPaymentMethodIsCreditCard: PropTypes.func,
    twelvePayDisabled: PropTypes.bool,
    bankAccountOnly: PropTypes.bool,
    showErrors: PropTypes.bool.isRequired,
    setFirstInstallmentIsDoubleAmount: PropTypes.func,
    twelvePayDisabledRegion: PropTypes.bool,
    isOneIncFeatureFlagAvailable: PropTypes.bool
};

WMICBankAccountDetails.defaultProps = {
    payer: undefined,
    updatePayer: _.noop,
    selectedPaymentMethodIsWire: _.noop,
    selectedPaymentMethodIsCreditCard: _.noop,
    twelvePayDisabled: false,
    bankAccountOnly: false,
    setFirstInstallmentIsDoubleAmount: _.noop,
    isOneIncFeatureFlagAvailable: true
};

export default WMICBankAccountDetails;