import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import WMICHOEquipmentDetails from '../WMICHOEquipmentDetails/WMICHOEquipmentDetails';
import metadata from './WMICHOMotorInfoDetails.metadata.json5';
import messages from './WMICHOMotorInfoDetails.messages.js'

function WMICHOMotorInfoDetails({
    id,
    value: motorData,
    onValidate,
    showErrors,
    readOnly,
    motorTypeReadOnly,
    updateModel: updateMotorData,
    baseData
}) {

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
        // if component is unmounted, report as valid so it doesn't break the rest of the form
        return () => {
            if (onValidate) {
                onValidate(true, id);
            }
        }
    }, [id, onValidate, isComponentValid]);

    const overrideProps = {
        motorType: {
            readOnly: motorTypeReadOnly,
        },
        '@field': {
            readOnly,
            parentNode: motorData,
        },
        wmichoEquipmentDetailsMotor: {
            watercraftComponentVM: motorData,
            readOnly,
            baseData,
            onValidate: setComponentValidation,
            showErrors
        },
    };

    const resolvers = {
        resolveComponentMap: {
            WMICHOEquipmentDetails,
        },
    };

    return (
        <ViewModelForm
            model={motorData}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            onModelChange={updateMotorData}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

WMICHOMotorInfoDetails.propTypes = {
    motorData: PropTypes.shape({}).isRequired,
    updateWatercraftModel: PropTypes.func.isRequired,
    motorTypeReadOnly: PropTypes.bool,
};

WMICHOMotorInfoDetails.defaultProps = {
    motorTypeReadOnly: false,
};

export default WMICHOMotorInfoDetails;
