import React, { useContext, useEffect, useState, useCallback } from 'react';
import _ from 'lodash';

import { ModalHeader, ModalBody, ModalFooter, ModalNext } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { Flex, FlexItem } from '@jutro/layout';

import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import { GatewayCancellationService } from 'wmic-pe-capability-gateway-policycancel';
import { WMICButton } from 'wmic-pe-components-platform-react';
import { CONSTANTS, WMICLogger, parseErrorMessage } from 'wmic-pe-portals-utils-js';
import { WMICErrorHandler, WMICProgressModal } from 'wmic-pe-capability-quoteandbind-common-react';

import metadata from './WMICPolicyCancelModal.metadata.json5';
import messages from './WMICPolicyCancelModal.messages';
import styles from './WMICPolicyCancelModal.module.scss';

const WMICPolicyCancelModal = (props) => {
    const { id, isOpen, policyVM, authHeader, initCancellation, onResolve, viewModelService, history } = props;

    const [cancellationVM, setCancellationVM] = useState();
    const [refundMethods, setRefundMethods] = useState([]);
    const [isEffectiveDateLocked, setIsEffectiveDateLocked] = useState(false);
    const [isRefundMethodLocked, setIsRefundMethodLocked] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const translator = useContext(TranslatorContext);
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    const reasonCodesTypelist = viewModelService.productMetadata.get('pc').types.getTypelist('ReasonCode');
    const availableCancellationReasonCodes = reasonCodesTypelist.codes.filter((reasonCode) => initCancellation.availableCancellationReasonCodes.includes(reasonCode.code));   

    // Initialize the Cancellation 
    useEffect(() => {
        initializeCancellation();
    }, [initializeCancellation]);

    const initializeCancellation = useCallback(() => {
        const initValuesForCancellationView = {
            effectiveDate: initCancellation.effectiveDate,
            lob: initCancellation.lob,
            // eslint-disable-next-line camelcase
            minimumEffectiveDate_WMIC: initCancellation.minimumEffectiveDate,
            // eslint-disable-next-line camelcase
            maximumEffectiveDate_WMIC: initCancellation.maximumEffectiveDate
        };
        const newCancellationVM = viewModelService.create(initValuesForCancellationView, 'pc', 'wmic.edge.ca.capabilities.gateway.job.cancellation.dto.CancellationDTO');
        setCancellationVM(newCancellationVM);
    }, [initCancellation, viewModelService]);

    // Maps the typelist values to { code, name } for dropdownselect fields
    const typelistMapper = (typecode) => {
        return {
            code: typecode.code,
            name: translator({
                id: typecode.name ?? typecode.description,
                defaultMessage: typecode.name ?? typecode.description
            })
        };
    }

    // Returns the label based on product type 
    const getPolicyNumberLabel = () => {
        switch (policyVM.product.productName.value) {
            case CONSTANTS.COVERAGE_NAMES.PERSONAL_PROPERTY:
                return translator(messages.personalPropertyPolicy);
            case CONSTANTS.COVERAGE_NAMES.PERSONAL_AUTOMOBILE:
                return translator(messages.personalAutomobilePolicy);
            case CONSTANTS.COVERAGE_NAMES.PERSONAL_UMBRELLA:
                return translator(messages.personalUmbrellaPolicy);
            default:
                return translator(messages.policy);
        }
    }

    // Filters valid refund methods from available methods
    const getRefundMethodsByCode = (methods) => {
        const availableRefundMethods = _.get(cancellationVM, 'cancellationRefundMethod.aspects.availableValues', []);
        return availableRefundMethods.filter((availableMethod) => methods.includes(availableMethod.code));
    }

    // Updates cancellation reason and calls method to get available cancellation options 
    const updateCancellationReason = (value) => {
        _.set(cancellationVM, 'cancelReasonCode', value);
        _.set(cancellationVM, 'cancelReasonDescription', '');

        const getAvailableCancellationOptionsPromise = getAvailableCancellationOptions(value);
        getAvailableCancellationOptionsPromise.then((cancellationOptions) => {
            updateCancellationOptions(cancellationOptions);
        }).catch((error) => {
            WMICErrorHandler.processAsModal(error);
        });
    }

    // Updates the various available cancellation options
    const updateCancellationOptions = (cancellationOptions) => {
        const effectiveDateLocked = cancellationOptions.isDefaultEffectiveDateLocked ?? false;
        setIsEffectiveDateLocked(effectiveDateLocked);

        const validRefundMethods = getRefundMethodsByCode(cancellationOptions.validRefundMethods);
        setRefundMethods(validRefundMethods);

        if (cancellationOptions.defaultRefundMethod) {
            const defaultRefundMethod = getRefundMethodsByCode([cancellationOptions.defaultRefundMethod])[0];
            _.set(cancellationVM, 'cancellationRefundMethod', defaultRefundMethod);
        } else {
            _.set(cancellationVM, 'cancellationRefundMethod', '');
            setIsRefundMethodLocked(false);
        }
    }

    // Updates modal VM
    const updateModalData = (newModalVM) => {
        setCancellationVM(viewModelService.clone(newModalVM));
    }

    // Handles modal cancel
    const handleCancel = () => {
        onResolve('Cancelled');
    }

    // Handles modal submit and calls method to create new policy cancel transation
    const handleSubmit = () => {
        if (isComponentValid) {
            setIsFetching(true);
            createPolicyCancellationTransaction().then(cancellation => {
                onResolve('Submitted');
                history.push(`/cancellation/${cancellation.jobNumber}/summary`);
            }).catch((error) => {
                WMICLogger.error('WMICPolicyCancelModal createPolicyCancellationTransaction', error);
                WMICErrorHandler.processAsModal(parseErrorMessage(error));
            }).finally(() => {
                setIsFetching(false);
            })
        }
    }

    // Display ProgressModal when submit is in progress
    if (isFetching) {
        return <WMICProgressModal
            modalTitle={translator(messages.submittingPolicyCancellation)}
            isOpen={isFetching}
        />
    }

    // Calls the GatewayCancellationService getAvailableCancellationOptions method
    const getAvailableCancellationOptions = (cancellationReason) => {
        return GatewayCancellationService.getAvailableCancellationOptions(policyVM.policyNumber.value, cancellationReason, authHeader);
    }

    // Calls the GatewayCancellationService createPolicyCancellationTransaction method
    const createPolicyCancellationTransaction = () => {
        return GatewayCancellationService.createPolicyCancellationTransaction(policyVM.policyNumber.value, cancellationVM.value , authHeader);
    }

    const overrideProps = {
        '@field': {
            parentNode: cancellationVM
        },
        policyDetailsContainer: {
            className: styles.policyDetail
        },
        policyNumberLabel: {
            content: getPolicyNumberLabel()
        },
        policyNumberValue: {
            content: policyVM.policyNumber.value
        },
        accountHolderDetails: {
            content: `${policyVM.account.accountHolder.displayName.value} - ${policyVM.account.accountHolder.primaryAddress.displayName.value}`
        },
        effectiveDate: {
            disabled: isEffectiveDateLocked
        },
        policyCancelReason: {
            availableValues: availableCancellationReasonCodes.map(typelistMapper),
            onValueChange: updateCancellationReason
        },
        policyCancelRefundMethod: {
            disabled: isRefundMethodLocked,
            availableValues: refundMethods.map(typelistMapper),
        }
    }

    const resolvers = {
        resolveClassNameMap: styles
    }

    return (
        <ModalNext isOpen={isOpen} className={styles.policyCancelModal}>
            <ModalHeader
                title={translator(messages.policyCancelTitle)}
            />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={cancellationVM}
                    overrideProps={overrideProps}
                    classNameMap={resolvers.resolveClassNameMap}
                    onModelChange={updateModalData}
                    onValidationChange={setComponentValidation}
                />
            </ModalBody>
            <ModalFooter>
                <Flex>
                    <FlexItem>
                        <WMICButton onClick={handleCancel} type='primary-outlined'>
                            {translator(messages.returnToPolicySummary)}
                        </WMICButton>
                    </FlexItem>
                    <FlexItem>
                        <WMICButton onClick={handleSubmit} type='primary' disabled={!isComponentValid}>
                            {translator(messages.submitPolicyCancellation)}
                        </WMICButton>
                    </FlexItem>
                </Flex>
            </ModalFooter>
        </ModalNext>
    );
};

export default WMICPolicyCancelModal;