import React, { useCallback, useState, useEffect } from 'react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { Position } from 'wmic-pe-portals-utils-js';
import WMICAdlIntGoldSearch from 'wmic-pe-capability-gateway-quoteandbind-ho-react/components/WMICAdlIntGoldSearch/WMICAdlIntGoldSearch';
import { WMICAddressDetails, WMICContactDetails } from 'wmic-pe-components-platform-react';
import { WMICHOCovAdditionalInterests } from 'wmic-pe-capability-gateway-common-ho-react';
import './WMICHODwellingCovAdditionalInterests.messages';
import metadata from './WMICHODwellingCovAdditionalInterests.metadata.json5';
import styles from './WMICHODwellingCovAdditionalInterests.module.scss';

function WMICHODwellingCovAdditionalInterests(props) {
    const {
        id,
        dwelling,
        isEditMode,
        onValidate,
        readOnly,
        jobVM,
        saveJob
    } = props;

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const { refreshData } = useDataRefresh();

    const [additionalInterestVM, setAdditionalInterestVM] = useState({ policyAdditionalInterest: {} });

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const updateDetailsData = useCallback(
        (data) => {
            setAdditionalInterestVM(data);
            refreshData();
        },
        [refreshData, additionalInterestVM]
    );

    const commonDwellingAccordionProps = {
        jobVM,
        onValidate: setComponentValidation,
        showErrors: isComponentValid,
        onDwellingChange: refreshData,
        readOnly: !isEditMode || readOnly,
        isEditMode: isEditMode,
        dwelling,
        saveJob
    }

    const overrideProps = {
        '@field': {
            labelPosition: Position.LEFT,
            parentNode: additionalInterestVM,
            readOnly: !isEditMode || readOnly
        },
        dwellingAdditionalInterestsAccordionContent: {
            ...commonDwellingAccordionProps,
            category: 'HODwellingAddlInt_HOE',
            canEdit: () => isEditMode
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICAdlIntGoldSearch,
            WMICAddressDetails,
            WMICHOCovAdditionalInterests,
            WMICContactDetails
        },
        resolveCallbackMap: {
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            model={additionalInterestVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            onModelChange={updateDetailsData}
            onValidationChange={setComponentValidation}
            showErrors={!isComponentValid}
        />
    );
}

WMICHODwellingCovAdditionalInterests.propTypes = {
    dwelling: PropTypes.shape({}),
    category: PropTypes.string.isRequired,
    isEditMode: PropTypes.bool.isRequired
};

WMICHODwellingCovAdditionalInterests.defaultProps = {
    dwelling: {}
};

export default WMICHODwellingCovAdditionalInterests;
