import { setComponentMapOverrides } from '@jutro/uiconfig';

import { WMICQualificationPage } from 'wmic-pe-capability-quoteandbind-common-react';
import WMICHOCoveragesPage from './pages/WMICHOCoveragesPage/WMICHOCoveragesPage';
import WMICHOQuotePage from './pages/WMICHOQuotePage/WMICHOQuotePage'

setComponentMapOverrides(
    {
        WMICHOQualificationPage: { component: 'WMICHOQualificationPage' },
        WMICHOCoveragesPage: { component: 'WMICHOCoveragesPage' },
        WMICHOQuotePage: { component: 'WMICHOQuotePage' },
    },
    {
        WMICHOQualificationPage: WMICQualificationPage,
        WMICHOCoveragesPage: WMICHOCoveragesPage,
        WMICHOQuotePage: WMICHOQuotePage,
    }
);

