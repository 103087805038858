import moment from 'moment';

import { LocalDateUtil, DateUtil } from '@xengage/gw-portals-util-js'

import { POLICY_DETAILS } from 'wmic-pe-portals-utils-js';

const DATE_OPTIONS = { year: 'numeric', month: 'short', day: 'numeric' };

export default class DateTimeService {
    static isDateDTOValid(dateDTO) {
        return !!dateDTO && moment([dateDTO.year, dateDTO.month, dateDTO.day]).isValid();
    };

    static localDate2Date(localDate) {
        return this.isDateDTOValid(localDate) ? new Date(localDate.year, localDate.month, localDate.day) : undefined;
    };

    static localDate2Format(localDate, fmtStr ='L') {
        const _localDate = this.localDate2Date(localDate);
        return moment(_localDate).format(fmtStr);
    }

    static toMidnightDate = (date) => date ? new Date(date.year, date.month, date.day).toLocaleDateString(localStorage.getItem('selectedLocale'), DATE_OPTIONS) : undefined;

    /**
         * Compares the dates of two Date objects minus dates.
         * @param {Date} date1 First date to check.
         * @param {Date} date2 Second date to check.
         * @returns {Number} -1 if date1 is before date2, 0 if they are equal, 1 if date1 is after date2.
         */
    static compareIgnoreTime = (date1, date2) => {
        const date1NoTime = new Date(date1).setHours(0, 0, 0, 0);
        const date2NoTime = new Date(date2).setHours(0, 0, 0, 0);

        if (date1NoTime > date2NoTime) {
            return 1;
        }
        if (date1NoTime < date2NoTime) {
            return -1;
        }
        return 0;
    };

    static getMaxDOB = () => {
        const today = new Date();
        const maxDOB = {
            year: today.getFullYear() - POLICY_DETAILS.MINIMUM_LEGAL_AGE,
            month: today.getMonth(),
            day: today.getDate()
        };
        return maxDOB;
    }

    static getMinDOB = () => {
        const today = new Date();
        const minDOB = {
            year: today.getFullYear() - POLICY_DETAILS.MAXIMUM_LEGAL_AGE,
            month: today.getMonth(),
            day: today.getDate()
        };
        return minDOB;
    }

    static daysInMonth = LocalDateUtil.daysInMonth; // Month is start from 0

    static daysInLocalDate = LocalDateUtil.daysInLocalDate;

    static today = LocalDateUtil.today;

    static dateYearsAgo = DateUtil.dateYearsAgo;
}
