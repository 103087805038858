import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { WMICJsonRPCService } from 'wmic-pe-portals-transport-js';

function processRequest(method, data, additionalHeaders) {
    return WMICJsonRPCService.send(getProxiedServiceUrl('dwellingInfoLookup'), method, data, additionalHeaders);
}

export default class DwellingInfoLookupService {
    static lookupResidenceTypeValues(baseCountry, hoPolicyType, additionalHeaders = {}) {
        return processRequest('lookupResidenceTypeValues', [baseCountry, hoPolicyType], additionalHeaders);
    }

    static lookupCoverageTypeValues(dto, additionalHeaders = {}) {
        return processRequest('lookupCoverageTypeValues', dto, additionalHeaders);
    }

    static lookupDwellingUsageValues(
        baseCountry,
        hoPolicyType,
        ratingJurisdiction,
        rateAsOfDate,
        additionalHeaders = {}
    ) {
        return processRequest('lookupDwellingUsageValues', 
            [baseCountry, hoPolicyType, ratingJurisdiction, rateAsOfDate], additionalHeaders);
    }

    static lookupDwellingOccupancyValues(dto, additionalHeaders = {}) {
        return processRequest('lookupDwellingOccupancyValues', dto, additionalHeaders);
    }

    static lookupAllowedResidenceTypes(dto, additionalHeaders = {}) {
        return processRequest('lookupAllowedResidenceTypes', dto, additionalHeaders);
    }

    static lookupDwellingMortgageeNumber(addInterests, additionalHeaders = {}) {
        return processRequest('lookupDwellingMortgageeNumber', [addInterests], additionalHeaders);
    }

    static lookupPolicyTypeFilterValues(baseState, date, additionalHeaders = {}) {
        return processRequest('lookupPolicyTypeFilterValues', [baseState, date], additionalHeaders);
    }
}
