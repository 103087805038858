import { defineMessages } from 'react-intl';

export default defineMessages({
    nonCoverageCostHeaderText: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.non-coverage-costs-table.header',
        defaultMessage: 'Surcharges'
    },
    premiumColumnHeading: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.non-coverage-costs-table.premium-heading',
        defaultMessage: 'Premium'
    },
    annualPremiumColumnHeading: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.non-coverage-costs-table.annual-premium-heading',
        defaultMessage: 'Annual Premium'
    },
    proratedPremium: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.non-coverage-costs-table.prorated-premium-heading',
        defaultMessage: 'Prorated Premium'
    }
});
