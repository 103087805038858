/* eslint-disable no-unused-vars */
import React, {
    useEffect, useState, useContext, useCallback, useMemo
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { CONSTANTS } from 'wmic-pe-portals-utils-js';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { WatercraftContext } from 'wmic-pe-capability-gateway-quoteandbind-ho-react';
import metadata from './WMICHOWatercraftBoatEquipmentTrailerDetails.metadata.json5';
import WMICHOBoatDetails from '../WMICHOBoatDetails/WMICHOBoatDetails';
import WMICHOTrailerInfoDetails from '../WMICHOTrailerInfoDetails/WMICHOTrailerInfoDetails';
import WMICHOMotorInfoDetails from '../WMICHOMotorInfoDetails/WMICHOMotorInfoDetails';
import WMICHOEquipmentDetails from '../WMICHOEquipmentDetails/WMICHOEquipmentDetails';
import WMICHOAccessoryInfoDetails from '../WMICHOAccessoryInfoDetails/WMICHOAccessoryInfoDetails';
import { getMotorDescription } from '../../WMICHOWatercraftUtil'
import styles from './WMICHOWatercraftBoatEquipmentDetails.module.scss';
import messages from './WMICHOWatercraftBoatEquipmentTrailerDetails.messages.js'

const getWatercraftComponent = (watercraftRiskView, subtype, getAll = false) => {
    const items = watercraftRiskView.watercraftComponents.filter((component) => {
        return component.subtype && component.subtype.value.code === subtype;
    });
    if (items && items.length > 0) {
        return getAll ? items : items[0];
    }
    return undefined;
}

const getBoat = (watercraftRiskView) => {
    const _boat = getWatercraftComponent(watercraftRiskView, "HOWatercraftBoat_WMIC");
    return _boat;
};

const getTrailer = (watercraftRiskView) => {
    return getWatercraftComponent(watercraftRiskView, "HOWatercraftTrailer_WMIC");
};

const getAccessory = (watercraftRiskView) => {
    return getWatercraftComponent(watercraftRiskView, "HOWatercraftAccessory_WMIC");
};

const getMotors = (watercraftRiskView) => {
    return getWatercraftComponent(watercraftRiskView, "HOWatercraftMotor_WMIC", true);
};

function WMICHOWatercraftBoatEquipmentDetails(props) {
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { updateWatercraftRiskView, watercraftRiskView, jobVM } = useContext(WatercraftContext);

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const { showConfirm } = useWizardModals();

    const {
        id,
        isEditMode,
        onValidate,
        showErrors
    } = props;
    const initialToggleData = () => ({
        insureBoatToggleValue: {
            value: Boolean(getBoat(watercraftRiskView)),
            subtype: 'HOWatercraftBoat_WMIC',
            createFn: createBoatComponent
        },
        insureTrailerToggleValue: {
            value: Boolean(getTrailer(watercraftRiskView)),
            subtype: 'HOWatercraftTrailer_WMIC',
            createFn: createTrailerComponent
        },
        insureAccessoryToggleValue: {
            value: Boolean(getAccessory(watercraftRiskView)),
            subtype: 'HOWatercraftAccessory_WMIC',
            createFn: createAccessoryComponent
        },
        insureMotorToggleValue: {
            value: Boolean(getMotors(watercraftRiskView)),
            subtype: 'HOWatercraftMotor_WMIC'
        }
    });
    const [toggleData, updateToggleData] = useState(initialToggleData);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => {
            if (onValidate) {
                onValidate(true, id);
            }
        }
    }, [id, onValidate, isComponentValid]);

    const createBoatComponent = useCallback(() => {
        const newBoat = {
            subtype: 'HOWatercraftBoat_WMIC',
            equipmentInfo: {
                boatInfo: {
                    boatType: 'Boat',
                    use: 'Pleasure'
                }
            }
        };
        return  viewModelService.create(newBoat, 'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.watercraft.HOWatercraftComponentDTO_WMIC',
            jobVM.aspects.context()
        );

    }, [jobVM?.aspects, viewModelService]);

    const createTrailerComponent = useCallback(() => {
        const newTrailer = {
            subtype: 'HOWatercraftTrailer_WMIC',
            equipmentInfo: {
            }
        };
        return  viewModelService.create(newTrailer, 'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.watercraft.HOWatercraftComponentDTO_WMIC',
            jobVM.aspects.context()
        );
    }, [jobVM?.aspects, viewModelService]);

    const createAccessoryComponent = useCallback(() => {
        const newAccessory = {
            subtype: 'HOWatercraftAccessory_WMIC',
        };
        return  viewModelService.create(newAccessory, 'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.watercraft.HOWatercraftComponentDTO_WMIC',
            jobVM.aspects.context()
        );
    }, [jobVM?.aspects, viewModelService]);

    const createMotorComponent = useCallback(() => {
        const motor = {
            subtype: "HOWatercraftMotor_WMIC",
            equipmentInfo: {
                motorInfo: {
                }
            }
        };
        const motorVM = viewModelService.create(motor, 'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.watercraft.HOWatercraftComponentDTO_WMIC',
            jobVM.aspects.context()
        );
        watercraftRiskView.value.isAddingBoatMotor = true;

        watercraftRiskView.watercraftComponents.value.push(motorVM.value);
        return motorVM;
    }, [jobVM?.aspects, viewModelService, watercraftRiskView]);

    useEffect(() => {
        const data = initialToggleData();
        updateToggleData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditMode]);

    const updateToggleValue = useCallback(
        (value, path) => {
            const previousToggleValue = _.get(toggleData, `${path}.value`)
            if (value !== previousToggleValue) {
                const data = {...toggleData};
                _.set(data, `${path}.value`, value);
                updateToggleData(data);

                const watercraftType = _.get(toggleData, `${path}.subtype`);
                if (value) {
                    const watercraftCreateFn = _.get(toggleData, `${path}.createFn`);
                    if (watercraftCreateFn) {
                        const newComponent = watercraftCreateFn();
                        if (newComponent) {
                            watercraftRiskView.watercraftComponents.pushElement(newComponent);
                            updateWatercraftRiskView(watercraftRiskView);
                        }
                    }
                } else {
                    const watercraftComponentIndex = _.findIndex(watercraftRiskView.watercraftComponents.value, {subtype: watercraftType});
                    if (watercraftComponentIndex !== -1) {
                        watercraftRiskView.watercraftComponents.value.splice(watercraftComponentIndex, 1);
                        updateWatercraftRiskView(watercraftRiskView);
                    }
                }
            }
        },
        [toggleData, updateWatercraftRiskView, watercraftRiskView]
    );

    const getBoatToggle = useCallback(
        () => {
            return _.get(toggleData, 'insureBoatToggleValue.value')
        },
        [toggleData],
    )

    const getTrailerToggle = useCallback(
        () => {
            return _.get(toggleData, 'insureTrailerToggleValue.value')
        },
        [toggleData],
    )

    const getAccessoryToggle = useCallback(
        () => {
            return _.get(toggleData, 'insureAccessoryToggleValue.value')
        },
        [toggleData],
    )

    const getMotorToggle = useCallback(
        () => {
            return _.get(toggleData, 'insureMotorToggleValue.value')
        },
        [toggleData],
    )

    const onSaveMotor = useCallback(() => {
        updateWatercraftRiskView(watercraftRiskView);
        return true;
    }, [updateWatercraftRiskView, watercraftRiskView]);

    const onRemoveMotor = useCallback((motor) => {
        return showConfirm({
            title: {
                id: "quoteandbind.ho.views.ho-your-home.watercraft.Remove Motor Short",
                defaultMessage: "Remove Motor"
            },
            message: {
                id: "quoteandbind.ho.views.ho-your-home.Remove Risk Long",
                defaultMessage: "Are you sure you want to remove the motor from the list?"
            }
        }).then((response) => {
            if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
                const indexToRemove = watercraftRiskView.watercraftComponents.children.findIndex((item) => item === motor);
                watercraftRiskView.watercraftComponents.value.splice(indexToRemove, 1);
                updateWatercraftRiskView(watercraftRiskView);
            }
        })

    }, [showConfirm, updateWatercraftRiskView, watercraftRiskView])

    const sharedDetailsProps = {
        watercraftRiskView,
        onValidate: setComponentValidation,
        showErrors,
        readOnly: !isEditMode
    }

    const getYesNoValues = () => {
        const values = [];
        values.push({
            "id": "true",
            "displayName": translator({
                id: 'quoteandbind.ho.views.ho-your-home.Yes',
                defaultMessage: 'Yes'
            })
        });
        values.push({
            "id": "false",
            "displayName": translator({
                id: 'quoteandbind.ho.views.ho-your-home.No',
                defaultMessage: 'No'
            })

        });
        return values;
    }

    const overrides = {
        '@field': {
            readOnly: !isEditMode,
            parentNode: watercraftRiskView,
        },
        insureBoatContainer: {
            visible: Boolean(getBoatToggle()),
            className: cx(styles.indentedContent, 'gw-mt-3')
        },
        insureTrailerContainer: {
            visible: Boolean(getTrailerToggle()),
            className: cx(styles.indentedContent, 'gw-mt-3')
        },
        insureAccessoryContainer: {
            visible: Boolean(getAccessoryToggle()),
            className: cx(styles.indentedContent, 'gw-mt-3')
        },
        insureMotorContainer: {
            visible: Boolean(getMotorToggle()),
            className: 'gw-mt-3'
        },
        wmichoBoatDetails: {
            baseData: _.get(jobVM, 'baseData'),
            boatData: getBoat(watercraftRiskView),
            ...sharedDetailsProps
        },
        wmichoTrailerInfoDetails: {
            baseData: _.get(jobVM, 'baseData'),
            trailerData: getTrailer(watercraftRiskView),
            ...sharedDetailsProps
        },
        wmichoAccessoryInfoDetails: {
            accessoryData: getAccessory(watercraftRiskView),
            ...sharedDetailsProps
        },
        insureBoatToggle: {
            value: getBoatToggle(),
            onValueChange: (value) => updateToggleValue(value, 'insureBoatToggleValue'),
            readOnly: !isEditMode,
            availableValues: getYesNoValues()
        },
        insureTrailerToggle: {
            value: getTrailerToggle(),
            onValueChange: (value) => updateToggleValue(value, 'insureTrailerToggleValue'),
            readOnly: !isEditMode,
            availableValues: getYesNoValues()
        },
        insureAccesoryToggle: {
            value: getAccessoryToggle(),
            onValueChange: (value) => updateToggleValue(value, 'insureAccessoryToggleValue'),
            readOnly: !isEditMode,
            availableValues: getYesNoValues()
        },
        insureMotorToggle: {
            value: getMotorToggle(),
            onValueChange: (value) => updateToggleValue(value, 'insureMotorToggleValue'),
            readOnly: !isEditMode,
            availableValues: getYesNoValues()
        },
        motorListView: {
            readOnly: !isEditMode,
            value: getMotors(watercraftRiskView),
            VMData: [
                {
                    headerText: translator({
                        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOMotorInfoDetails.outboardmotor.Motor Type',
                        defaultMessage: 'Motor Type'
                    }),
                    path: 'equipmentInfo.motorInfo.motorType'
                },
                {
                    headerText: translator({
                        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOAccessoryInfoDetails.Description',
                        defaultMessage: 'Description'
                    }),
                    path: 'description',
                    getData: getMotorDescription
                },
            ],
            detailViewComponent : WMICHOMotorInfoDetails,
            detailViewComponentProps: {
                baseData: _.get(jobVM, 'baseData'),
                watercraftRiskView: watercraftRiskView
            },
            onValidate : setComponentValidation,
            toCreate: createMotorComponent,
            toUndoCreate: () => {
                const motors = _.get(watercraftRiskView.value, 'watercraftComponents');
                motors.splice(motors.length-1, 1);
                _.set(watercraftRiskView.value, 'watercraftComponents', motors);
                updateWatercraftRiskView(watercraftRiskView);
            },
            onSave: onSaveMotor,
            onDelete: onRemoveMotor,
            showErrors: showErrors
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICHOBoatDetails,
            WMICHOEquipmentDetails,
            WMICHOTrailerInfoDetails,
            WMICHOAccessoryInfoDetails
        }
    };

    return (
        <ViewModelForm
            model={watercraftRiskView}
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            onModelChange={updateWatercraftRiskView}
            componentMap={resolvers.resolveComponentMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

WMICHOWatercraftBoatEquipmentDetails.propTypes = {
    isEditMode: PropTypes.bool.isRequired
};

WMICHOWatercraftBoatEquipmentDetails.defaultProps = {
};

export default WMICHOWatercraftBoatEquipmentDetails;
