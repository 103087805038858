import { defineMessages } from 'react-intl';

export default defineMessages({
    savingTransactionDetails: {
        id: 'wmic-pe-capability-gateway-policychange-pup-react.WMICPEPUPPolicyChangeWizard.Saving Transaction Details',
        defaultMessage: 'Saving Transaction Details'
    },
    addLocations: {
        id: 'wmic.endorsement.pup.directives.templates.pup-wizard.addLocations',
        defaultMessage: 'Add Locations'
    },
    chooseEffectiveDate: {
        id: 'wmic.endorsement.pup.directives.templates.pup-wizard.chooseEffectiveDate',
        defaultMessage: 'Choose Effective Date'
    },
    locations: {
        id: 'wmic.endorsement.pup.directives.templates.pup-wizard.locations',
        defaultMessage: 'Locations'
    },
    yourPolicies: {
        id: 'wmic.endorsement.pup.directives.templates.pup-wizard.yourPolicies',
        defaultMessage: 'Your Policies'
    },
    addPolicy: {
        id: 'wmic.endorsement.pup.directives.templates.pup-wizard.addPolicy',
        defaultMessage: 'Add Policy'
    },
    employerAffiliation: {
        id: 'wmic.endorsement.pup.directives.templates.pup-wizard.employerAffiliation',
        defaultMessage: 'Employer/Affiliation'
    },
    drivers: {
        id: 'wmic.endorsement.pup.directives.templates.pup-wizard.Drivers',
        defaultMessage: 'Drivers'
    },
    excludedDrivers: {
        id: 'wmic.endorsement.pup.directives.templates.pup-wizard.Excluded Drivers',
        defaultMessage: 'Excluded Drivers'
    },
    householdMembers: {
        id: 'wmic.endorsement.pup.directives.templates.pup-wizard.Household Members',
        defaultMessage: 'Household Members'
    },
    vehicles: {
        id: 'wmic.endorsement.pup.directives.templates.pup-wizard.Vehicles',
        defaultMessage: 'Vehicles'
    },
    watercraft: {
        id: 'wmic.endorsement.pup.directives.templates.pup-wizard.Watercraft',
        defaultMessage: 'Watercraft'
    },
    businessActivity: {
        id: 'wmic.endorsement.pup.directives.templates.pup-wizard.Business Activity',
        defaultMessage: 'Business Activity'
    },
    additionalPolicyInfo: {
        id: 'wmic.endorsement.pup.directives.templates.pup-wizard.Additional Policy Info',
        defaultMessage: 'Additional Policy Info'
    },
    wmicQuoteDetails: {
        id: 'wmic.endorsement.pup.directives.templates.pup-wizard.Quote Details',
        defaultMessage: 'Quote Details'
    },
    anErrorOccurred: {
        id: 'wmic.gw-portals-policytransaction-pc-ho.wizard.error',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'wmic.gw-portals-policytransaction-pc-ho.wizard.error.title',
        defaultMessage: 'Error'
    },
    saveQuoteError: {
        id: 'wmic.gw-portals-policytransaction-pc-ho.Unable to quote',
        defaultMessage: 'Unable to quote'
    },
    saveQuoteErrorMessage: {
        id: 'wmic.gw-portals-policytransaction-pc-ho.An error occurred while attempting to quote.',
        defaultMessage: 'An error occurred while attempting to quote.'
    },
    cancel: {
        id: 'wmic.endorsement.common.view.Cancel',
        defaultMessage: 'Cancel'
    },
    contactDetailsTitle: {
        id: 'wmic-pe-capability-gateway-policychange-pup-react.wizard-config.Contact Details',
        defaultMessage: 'Contact Details'
    },
    progress: {
        id: 'wmicendorsement.wizard.pup-policy-change.Progress',
        defaultMessage: 'Progress'
    },
    policyDetails: {
        id: 'wmic-pe-capability-gateway-policychange-pup-react.WMICPUPPolicyChangePolicyDetailsPage.Policy Details',
        defaultMessage: 'Policy Details'
    }
});
