import { defineMessages } from 'react-intl';

export default defineMessages({
    watercraftDetailsAccordion: {
        "id": "wmic-pe-capability-gateway-common-ho-react.WMICHOWatercraftDetailView.Watercraft Details",
        "defaultMessage": "Watercraft Details"
    },
    watercraftAdditionalInterestsAccordion: {
        "id": "wmic-pe-capability-gateway-common-ho-react.WMICHOWatercraftDetailView.Additional Interests",
        "defaultMessage": "Additional Interests"
    }
});
