import { defineMessages } from 'react-intl';

export default defineMessages({
    featureType: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICAddKitchenBathComponent.Feature Type",
        defaultMessage: "Feature Type"
    },
    kitchenBathDescription: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICAddKitchenBathComponent.Description",
        defaultMessage: "Description"
    },
    kitchenBathNumber: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICAddKitchenBathComponent.Number",
        defaultMessage: "Number"
    },
    describe: {
        id: "wmic-pe-capability-gateway-commmon-ho-react.WMICAddKitchenBathComponent.Describe Other",
        defaultMessage: "Describe"
    }

})
