/* eslint-disable max-len */
import React, { useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { WMICModal, WMICProgressModal } from 'gw-capability-quoteandbind-common-react';
import { ERROR_CODE, ESIGNATURE_STATUS, RETRIEVE_AVAILABLE_MESSAGE } from 'wmic-portals-utils-js';
import styles from './WMICRetrieveQuote.module.scss'

import messages from './WMICRetrieveQuote.messages';

function WMICRetrieveQuote(props) {
    const modalApi = useModal();
    const { retrieveSubmissionCallback, retrieveSubmissionRequest, paymentCallback, setIsRetrieving } = props;
    const history = useHistory();
    const translator = useTranslator();
    const modalStyle = styles.wmicRetrieveQuoteModal;


    const gotoContactUs = (quoteID, displayMode) => {
        const nextState = {
            pathname: `/contact-us/${displayMode}`,
            state: {
                quoteID: quoteID
            }
        };
        history.push(nextState);
    };

    const gotoConfirmation = (model) => {
        if (model.bindData && model.bindData.policyNumber) {
            const nextState = {
                pathname: '/confirmation',
                state: {
                    submission: model
                }
            };
            history.push(nextState);
        }
    };

    const gotoPolicyInProcess = (model) => {
        history.push({
            pathname: '/policyinprocess',
            search: `?quoteID=${model.quoteID}`
        });
    };

    const gotoWizard = (model) => {
        const nextState = {
            pathname: '/quote-ho',
            state: {
                submission: model
            }
        };
        history.push(nextState);
    };

    const canGoToAuthorizationPage = (submission) => {
        return _.get(submission, 'esignatureStatus_WMIC.esignatureDocumentStatusName') === ESIGNATURE_STATUS.PENDING_SIGNATURE;
    };

    const gotESignature = (submission) => {
        const esignStatus = _.get(submission, 'esignatureStatus_WMIC.esignatureDocumentStatusName');
        return esignStatus === ESIGNATURE_STATUS.SIGNED || esignStatus === ESIGNATURE_STATUS.OPTIMISTICALLY_SIGNED;
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        const retrieveSubmissionPromise = retrieveSubmissionCallback();
        modalApi.showModal(<WMICProgressModal modalTitle={translator(messages.retrievingSubmission)} promise={retrieveSubmissionPromise} />).then((draftSubmission) => {
            if (draftSubmission !== undefined
                && ((retrieveSubmissionRequest.orderID && !retrieveSubmissionRequest.quoteID)
                    || retrieveSubmissionRequest.quoteID && draftSubmission.quoteID === retrieveSubmissionRequest.quoteID)) {

                // if eSignature is Declined status redirect to paymentDetails
                if (draftSubmission.esignatureStatus_WMIC !== undefined && draftSubmission.esignatureStatus_WMIC.esignatureDocumentStatusName === ESIGNATURE_STATUS.DECLINED) {
                    draftSubmission.allowNavigationToPaymentDetails = true;
                }
                if (draftSubmission.bindData && draftSubmission.bindData.policyNumber) { // We have bound already, show confirmation page
                    gotoConfirmation(draftSubmission);
                } else if (draftSubmission.availabilityMessage !== undefined) {
                    const errorMessage = translator(messages[draftSubmission.availabilityMessage], { quoteNumber: draftSubmission.quoteID });
                    if (draftSubmission.availabilityMessage === RETRIEVE_AVAILABLE_MESSAGE.QUOTE_NOT_FOUND) {
                        modalApi.showModal(
                            <WMICModal
                                title={messages.oops}
                                message={errorMessage}
                                onAfterClose={setIsRetrieving(false)}
                                iconClass="error-text fa-3x fa fa-exclamation-circle"
                                modalStyle = {modalStyle}
                            />
                        ).then(() => {
                            scrollToTop();
                        });
                    } else if (draftSubmission.availabilityMessage === RETRIEVE_AVAILABLE_MESSAGE.QUOTE_DECLINED
                        || draftSubmission.availabilityMessage === RETRIEVE_AVAILABLE_MESSAGE.QUOTE_NOT_TAKEN
                        || draftSubmission.availabilityMessage === RETRIEVE_AVAILABLE_MESSAGE.QUOTE_NOT_VALID) {
                        modalApi.showModal(
                            <WMICModal
                                title={messages.oops}
                                message={errorMessage}
                                iconClass="error-text fa-3x fa fa-exclamation-circle"
                                actionBtnLabel={messages.createNewQuote}
                                modalStyle = {modalStyle}
                            />
                        ).then(() => {
                            history.push('/');
                        });
                    } else if (draftSubmission.availabilityMessage === RETRIEVE_AVAILABLE_MESSAGE.QUOTE_EXPIRED) {
                        modalApi.showModal(
                            <WMICModal
                                title={messages.oops}
                                message={errorMessage}
                                iconClass="error-text fa-3x fa fa-exclamation-circle"
                                actionBtnLabel={messages.createNewQuote}
                                modalStyle = {modalStyle}
                            />
                        ).then(() => {
                            history.push('/');
                        });
                    } else if (draftSubmission.availabilityMessage === RETRIEVE_AVAILABLE_MESSAGE.UNDERWRITING_ISSUE || draftSubmission.availabilityMessage === RETRIEVE_AVAILABLE_MESSAGE.UNDERWRITER_REVIEW) {
                        gotoContactUs(draftSubmission.quoteID, ERROR_CODE.UW_ISSUE);
                    }
                    else if (draftSubmission.availabilityMessage === RETRIEVE_AVAILABLE_MESSAGE.PORTAL_VALIDATION) {
                        gotoContactUs(draftSubmission.quoteID, ERROR_CODE.PORTAL_VALIDATION);
                    } else if (draftSubmission.availabilityMessage === 'Twelve pay bind issue') {
                        gotoPolicyInProcess(draftSubmission);
                    }
                } else {
                    if (paymentCallback) {
                        draftSubmission.allowNavigationToPaymentDetails = true;
                    } else if (canGoToAuthorizationPage(draftSubmission)) {
                        draftSubmission.allowNavigationToRecurringPaymentAuthorization = true;
                    } else if (gotESignature(draftSubmission)) {
                        draftSubmission.allowNavigationToRecurringPaymentDetails = true;
                    }
                    gotoWizard(draftSubmission);
                }
            } else {
                modalApi.showModal(
                    <WMICModal
                        title={messages.oops}
                        message={messages.QuoteNotFound}
                        onAfterClose={setIsRetrieving(false)}
                        iconClass="error-text fa-3x fa fa-exclamation-circle"
                        modalStyle = {modalStyle}
                    />
                ).then(() => {
                    scrollToTop();
                });
            }
        })
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div />;
}

WMICRetrieveQuote.propTypes = {
    retrieveSubmissionCallback: PropTypes.func.isRequired,
    retrieveSubmissionRequest: PropTypes.shape({
        quoteID: PropTypes.string,
        orderID: PropTypes.string
    }).isRequired,
    paymentCallback: PropTypes.func
};

WMICRetrieveQuote.defaultProps = {
    paymentCallback: undefined
};

export default WMICRetrieveQuote;