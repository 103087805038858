import React, {
    useState,
    useCallback,
    useContext,
    useRef,
    useEffect,
    useMemo
} from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { WMICButton } from 'wmic-pe-components-platform-react';
import { Badge, Collapse } from '@jutro/components';
import cx from 'classnames';
import CONSTANTS from 'wmic-pe-portals-utils-js/StringConstants';
import internalStyles from './WMICFilterBar.module.scss';
import { messages } from './WMICFilterBar.messages';
import { WMICFilterBarContent } from './WMICFilterBarContent';

const filterBarPropTypes = {
    /**
     * Content metadata or an array of metadata
     */
    uiProps: PropTypes.object,
    /**
     * Resolve class name to hashed class name
     */
    classNameMap: PropTypes.object,
    /**
     * Resolve component map
     */
    componentMap: PropTypes.object,
    /**
     * Resolve callback name to a callback function
     */
    callbackMap: PropTypes.object,
    /**
     * Callback for when filters are changed
     */
    onFiltersChange: PropTypes.func.isRequired,
    /**
     * Object with initial filters to be applied
     */
    initialFilters: PropTypes.object,
    /**
     * Name of search filter key in filter data. Can not change during component lifetime.
     */
    searchKey: PropTypes.string,
};

// eslint-disable-next-line valid-jsdoc
/**
 * Component for filters rendering, supplied through metadata
 *
 * @type {React.FC<PropTypes.InferProps<typeof filterBarPropTypes>>}
 *
 * @metadataType container
 */
export const WMICFilterBar = ({
    callbackMap,
    componentMap,
    initialFilters: initialFiltersProp,
    onFiltersChange: onFiltersChangeProp,
    uiProps: metadata,
    searchKey,
    styles,
    children,
    isGoldContactSearchComponent,
    filtersVisible
}) => {
    const {
        [searchKey]: initialSearch,
        ...initialFilters
    } = initialFiltersProp;

    const [filters, setFilters] = useState(initialFilters);
    const onFiltersChangeRef = useRef(null);
    const [isShown, setShown] = useState(false);
    const translator = useContext(TranslatorContext);

    initialFilters.status === null && delete initialFilters.status;
    initialFilters.productCode === null && delete initialFilters.productCode;
    const numberFiltersApplied = Object.keys(initialFilters).length;
    const isFiltersEmpty = numberFiltersApplied === 0;

    const toggleFilterBar = useCallback(() => {
        setShown(!isShown);
    }, [isShown]);

    useEffect(() => {
        // Do not call on first render
        if (onFiltersChangeRef.current) {
            const newFilters = { ...filters };
            onFiltersChangeRef.current(newFilters);
        }
    }, [filters]);

    useEffect(() => {
        onFiltersChangeRef.current = onFiltersChangeProp;
    }, [onFiltersChangeProp]);
    const filterBarContentProps = useMemo(() => {
        return {
            styles,
            metadata,
            onFiltersChange: setFilters,
            componentMap,
            callbackMap,
            isFiltersEmpty,
            initialFilters,
            isGoldContactSearchComponent,
            filtersVisible
        };
    }, [callbackMap, componentMap, filtersVisible, initialFilters, isFiltersEmpty, isGoldContactSearchComponent, metadata, styles]);

    const manageFiltersButtonClassNames = cx(
        internalStyles.manageFiltersButton,
        {
            [internalStyles.manageFiltersButtonSelected]: isShown,
        }
    );
    return (
        <React.Fragment>
            <div className={internalStyles.searchBar}>
                {
                    children &&
                    <div className={internalStyles.childrenContainer}>
                        {children}
                    </div>
                }
                {metadata && !isGoldContactSearchComponent && (
                    <WMICButton
                        id="manageFilters"
                        icon="mi-filter-list"
                        type="secondary-filled"
                        title={translator(messages.title)}
                        onClick={toggleFilterBar}
                        className={manageFiltersButtonClassNames}
                        aria-label={translator(messages.manageFilters)}
                    >
                        {!isFiltersEmpty && (
                            <Badge
                                id="filtersCount"
                                type="neutral"
                                value={numberFiltersApplied}
                                className={internalStyles.manageFiltersBadge}
                            />
                        )}
                    </WMICButton>
                )}
            </div>
            {metadata && (
                <Collapse isOpen={isShown || filtersVisible} className={internalStyles.filterCollapse}>
                    <WMICFilterBarContent {...filterBarContentProps} />
                </Collapse>
            )}
        </React.Fragment>
    );
};

WMICFilterBar.propTypes = filterBarPropTypes;

WMICFilterBar.defaultProps = {
    initialFilters: {},
    searchKey: CONSTANTS.DEFAULT_SEARCH_KEY,
};
