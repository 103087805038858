import { defineMessages } from 'react-intl';

export default defineMessages({
    extraExpense: {
        id: 'wmic.pe-capability-quoteandbind-gl-react.util.extraExpense',
        defaultMessage: 'Extra Expense'
    },
    propertyOffPremise: {
        id: 'wmic.pe-capability-quoteandbind-gl-react.util.propertyOffPremise',
        defaultMessage: 'Property Off-Premises Including In Transit'
    },
    commercialgeneralliability: {
        id: 'wmic.pe-capability-quoteandbind-gl-react.util.commercialgeneralliability',
        defaultMessage: 'Commercial General Liability'
    },
    ripandtearliability: {
        id: 'wmic.pe-capability-quoteandbind-gl-react.util.ripandtearliability',
        defaultMessage: 'Rip and Tear Liability'
    },
    veterinarians: {
        id: 'wmic.pe-capability-quoteandbind-gl-react.util.veterinarians',
        defaultMessage: 'Veterinarians Professional Services Liability'
    },
    employeeDishonesty: {
        id: 'wmic.pe-capability-quoteandbind-gl-react.util.employeeDishonesty',
        defaultMessage: 'Employee Dishonesty'
    },
    moneySecurities: {
        id: 'wmic.pe-capability-quoteandbind-gl-react.util.moneySecurities',
        defaultMessage: 'Money, Securities and Other Property'
    }
});
