import React from 'react';
import PropTypes from 'prop-types';
import {
    Icon,
    InputField
} from '@jutro/components';

import { WMICCustomRadioButton, WMICCustomDropdownSelect } from 'gw-capability-quoteandbind-common-react';
import { WMICButton } from 'wmic-components-platform-react'; 
import {
    Table,
    TableColumn
} from '@jutro/datatable';
import { WMICArrayUtil, CONSTANTS } from 'wmic-portals-utils-js';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import cx from 'classnames';
import styles from './WMICAnimalTable.module.scss';
import messages from './WMICAnimalTable.messages';
import useEditableData from './WMICuseEditableData';

const OTHER_CODE = CONSTANTS.DOG_TABLES.OTHER;

const hiddenPlaceholder = {
    code: undefined,
    name: ' ',
    isDisabled: true
};

const WMICAnimalTable = ({
    data: outerData,
    showErrors,
    onDataChange,
    disableRemoval
}) => {
    const translator = useTranslator();

    const {
        data,
        addRow,
        updateRow,
        updateRows,
        deleteRow
    } = useEditableData(outerData.value, onDataChange);

    const isRemovalDisabled = data.length === 1 || disableRemoval;

    const typelistMapper = (typecode) => {
        return {
            code: typecode.code,
            name: translator({
                id: typecode.name,
                defaultMessage: typecode.name
            })
        };
    };

    const setupBreeds = (breeds) => {
        const animalBreeds = breeds.map(typelistMapper);
        const sortedBreeds = animalBreeds.sort((a, b) => a.name.localeCompare(b.name));
        // add other to the end of the list
        const otherIndex = _.findIndex(sortedBreeds, (breed) => { return breed.code === 'Other'; });
        WMICArrayUtil.move(otherIndex, sortedBreeds.length - 1, sortedBreeds);
        return sortedBreeds.concat(hiddenPlaceholder);
    };

    const setBreedValues = async (animalBreed, index) => {
        const updatedData = data;
        updateRows(['animalType', 'animalBreed'], ['Dog', animalBreed], index);
        updatedData[index].animalType = 'Dog';
        updatedData[index].animalBreed = animalBreed;
        
        onDataChange(updatedData, index);
        return 0;
    }

    const updateBiteHistory = async (value, index) => {
        const updatedData = data;
        await updateRow('animalBiteHistory', value, index);
        updatedData[index].animalBiteHistory = value;
        onDataChange(updatedData, index);
        return 0;
    }

    const updateDescribeOther = async (value, index) => {  
        const updatedData = data; 
        await updateRow('describeOther', value, index);     
        updatedData[index].describeOther = value;
        onDataChange(updatedData, index);
        return 0;
    }

    const deleteAnimal = async (index) => { 
        const updatedData = await deleteRow(index);
        onDataChange(updatedData, index);
        return 0;
    }

    const addAnimal = async () => {
        addRow();
        onDataChange([...data, {}]);
    }

    return (
        <div className={styles.WMICAnimalTable}>
            <Table data={data} className={cx(styles.tablePadding, "gw-table dwelling-animals")}>
                <TableColumn
                    id="breed"
                    key={data.length.toString()}
                    header={translator(messages.breed)}
                    headerClassName={styles.headerWrapper}
                    cellClassName="breed-col"
                    phone={styles.headerWrapper}
                    renderCell={(row, index) => {
                        const { animalBreed } = row;
                        const isInvalid = showErrors && !animalBreed;
                        const allBreeds = _.get(outerData.children[0], 'animalBreed.aspects.availableValues', [])
                        const availableBreeds = _.filter(allBreeds, (breed) => !breed.retired)
                        return (
                            <div id={`breed-col-${index}`}>
                                <WMICCustomDropdownSelect
                                    id={`breed-select-${index}`}
                                    hideLabel
                                    value={animalBreed}
                                    onValueChange={(newValue) => {
                                        return setBreedValues(newValue, index);
                                    }}
                                    className={styles.dropdownField}
                                    availableValues={setupBreeds(availableBreeds)}
                                    placeholder={hiddenPlaceholder}
                                    alwaysShowPlaceholder={false}
                                />
                                <div className={cx(styles.otherFieldValidationMessage, isInvalid? styles.visible: null)}>{ translator(messages.breedValidationMessage) }</div>
                                <p className={styles.helperText}>
                                    <i className="fa fa-question-circle fa-custom-2x fa-pull-left" aria-hidden="true" />
                                    { translator(messages.selectBreed) }
                                </p>
                            </div>
                        )
                    }
                    } 
                />
                <TableColumn
                    id="other"
                    key={data.length.toString()}
                    header={ translator(messages.describeOther) }
                    textAlign="left"
                    cellClassName="table-col other-col"
                    renderCell={(row, index) => {
                        const { animalBreed, describeOther } = row;
                        const isOtherBreed = (animalBreed !== OTHER_CODE);
                        const isInvalid = (showErrors && !isOtherBreed && !describeOther || showErrors && !isOtherBreed && !describeOther?.length);
                        return  (
                            <div id={`other-col-${index}`}>
                                <div className="field">
                                    <InputField
                                        id='other-breed-input'
                                        value={describeOther}
                                        disabled={isOtherBreed}
                                        onValueChange={(newValue) => {
                                            return updateDescribeOther(newValue, index);
                                        }}
                                    />
                                    <div className={cx(styles.otherFieldValidationMessage, isInvalid? styles.visible: null)}>{ translator(messages.otherValidationMessage) }</div>
                                </div>
                            </div>
                        )
                    }
                    } 
                />
                <TableColumn
                    id="history"
                    key={data.length.toString()}
                    header={translator(messages.biteHistory)}
                    textAlign="left"
                    cellClassName="table-col history-col"
                    renderCell={(row, index) => {
                        const { animalBiteHistory } = row;
                        const isInvalid = showErrors && animalBiteHistory === undefined;
                        return (
                            <div id={`history-col-${index}`}>
                                <WMICCustomRadioButton
                                    id='bite-history-radio'
                                    className={styles.radioField}
                                    value={animalBiteHistory}
                                    onValueChange={(newValue) => {
                                        return updateBiteHistory(newValue, index);
                                    }}
                                />
                                <div className={cx(styles.otherFieldValidationMessage, isInvalid? styles.visible: null)}>{ translator(messages.biteHistoryValidationMessage) }</div>
                            </div>
                        )
                    }
                    } 

                />
                <TableColumn
                    id="remove"
                    key={data.length.toString()}
                    header={translator(messages.remove)}
                    cellClassName="table-col remove-col"
                    textAlign="left"
                    renderCell={(row, index) => (
                        <div id={`remove-col-${index}`}>
                            <WMICButton
                                id="delete"
                                key={data.length.toString()}
                                onClick={() => deleteAnimal(index)}
                                disabled={isRemovalDisabled}
                                className="delete-btn"
                                icon="cust-trash-alt-solid"
                            />
                        </div>
                    )} 
                />
            </Table>


            <div className="dog-table-container">
                <WMICButton id='addPetButton' onClick={addAnimal} className={`${styles.addButton} wmicLightButtonWithIcon`} type='filled' icon="cust-plus-square-solid">
                    {translator(messages.addAnotherDog)}
                </WMICButton>
            </div>
        </div>
    );
};

WMICAnimalTable.propTypes = {
    /**
     * Current data in table. If empty, one row will be added by default
     */
    data: PropTypes.arrayOf(
        PropTypes.shape({
            breed: PropTypes.string,
            otherBreed: PropTypes.string,
            biteHistory: PropTypes.bool
        })
    ),
    /**
     * Debounced callback called for every change (add, edit, delete) in table
     */
    onDataChange: PropTypes.func.isRequired,
    /**
     * If true, remove button will be disabled even if there are more than 1 item in the table
     */
    disableRemoval: PropTypes.bool
};

WMICAnimalTable.defaultProps = {
    data: [{}],
    disableRemoval: false
};

export default WMICAnimalTable;