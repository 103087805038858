import _ from 'lodash';

function convertMetadataSeparatorsToVisibilityLogic(metadata, jobVM, isPolicyView){
    const stringifiedMetadata = JSON.stringify(metadata)
    const regexQuery = /("id":\s*"[A-Za-z0-9]+Separator")/g

    const foundSeparators = stringifiedMetadata.match(regexQuery);
    const separatorDTONames = foundSeparators.map((separatorID) => separatorID.replace(/("id":\s*")|(Separator")/g, ""))
    const separatorOverrides = _.map(separatorDTONames, (path) => {
        const ocularASpect = _.get(jobVM, `${path}.aspects.ocular`)
        const visibilityCondition = (isPolicyView) ? !_.isUndefined(_.get(jobVM, `${path}.value`)) && ocularASpect : ocularASpect

        const separatorOverride = {
            [`${path}Separator`]: {
                'visible': visibilityCondition
            }
        }

        return Object.assign({}, separatorOverride)
    })

    return Object.assign({}, ...separatorOverrides)
}

export default { convertMetadataSeparatorsToVisibilityLogic }