import React, { useContext, useMemo } from 'react';
import _ from 'lodash';
import { WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICRichTextUtil, Position, DeviceBreakpoint, PAConstants } from 'wmic-pe-portals-utils-js';
import { TranslatorContext } from '@jutro/locale';
import WMICPACoverageUtil from "wmic-pe-capability-gateway-common-pa-react/util/WMICPACoverageUtil";
import { BreakpointTrackerContext } from '@jutro/layout';
import { WMICHeading, ClausesComponentVM } from 'wmic-pe-components-platform-react';
import { WMICPAPFRComponent } from 'wmic-pe-capability-gateway-common-pa-react'
import WMICPAPolicyDriverEndorsements from '../WMICPAPolicyDriverEndorsements/WMICPAPolicyDriverEndorsements';

import messages from './WMICPAPolicyVehicleCoverages.messages'
import metadata from './WMICPAPolicyVehicleCoverages.metadata.json5';

const categoryMap = ['liabilityProtection', 'underageMaleDriver', 'underageGenderXOrFemaleDriver', 'physicalDamage', 'additionalEndorsements'];

function WMICPAPolicyVehicleCoverages(props) {
    const { selectedVehicle, selectedVehicleIndex } = props;
    const translator = useContext(TranslatorContext);
    const { wizardData: { policyVM } } = useContext(WizardContext);

    const selectedVehicleValue = selectedVehicle.value;
    const breakpoint = useContext(BreakpointTrackerContext);
    const vehicleCoverages = useMemo(() => _.find(_.get(policyVM, `lobs.personalAuto.coverageDTOs.vehicleCoverages.value`), (coverage) => coverage.publicID === selectedVehicleValue.publicID), [selectedVehicleValue, policyVM]);

    const vehicleDriverEndorsements = useMemo(() => _.filter(_.get(policyVM, `lobs.personalAuto.coverageDTOs.driverEndorsements.value`), (endorsement) => WMICPACoverageUtil.getIsVehicleDriverEndorsement(endorsement.publicID)), [policyVM])

    const categorizeCoverages = useMemo(() => {

        const categorizedCoverages = {...WMICPACoverageUtil.categorizeVehicleCoverages(vehicleCoverages, translator), vehicleDriverEndorsements}

        const coverageSections = categoryMap.map((categoryKey) => {
            const vehicleCoverage = _.get(categorizedCoverages, categoryKey);

            return (
                <React.Fragment>
                    {vehicleCoverage.coverages.length > 0 && (
                        <WMICHeading
                            variant="h2"
                            underlined
                            bold
                            size="h2"
                            title={vehicleCoverage.categoryName}
                        />
                    )}
                    <ClausesComponentVM
                        showAmount={false}
                        isEditable
                        readOnly
                        path={`${categoryKey}.coverages`}
                        value={vehicleCoverage.coverages}
                    />
                    {(categoryKey === PAConstants.physicalDamageCoverages && WMICPACoverageUtil.isProofOfFinancialResponsibilityVisible(policyVM, true)) &&
                        <WMICPAPFRComponent
                            readOnly
                            VM={policyVM}
                            path="lobs.personalAuto.pfrexists_WMIC"
                        />
                    }
                </React.Fragment>
            );
        });

        return {categorizedCoverages, coverageSections};
    }, [vehicleCoverages, translator, vehicleDriverEndorsements, policyVM]);

    const labelPosition = useMemo(() => breakpoint === DeviceBreakpoint.DESKTOP ? Position.LEFT : Position.TOP, [breakpoint])

    const overrideProps = {
        '@field': {
            readOnly: true
        },
        coverageSectionsContainer: {
            content: categorizeCoverages.coverageSections
        },
        endorsementContainer: {
            visible: vehicleDriverEndorsements.length > 0
        },
        vehicleDriverEndorsements: {
            policyVM,
            selectedVehicleId: _.get(selectedVehicle, 'publicID.value')
        },
        vehicleType: {
            labelPosition,
            value: selectedVehicleValue.vehicleType
        },
        vehicleVin: {
            labelPosition,
            value: selectedVehicleValue.vin
        },
        vehicleBusinessSegment: {
            labelPosition,
            value: selectedVehicleValue.businessSegment
        },
        vehicleCoveragesAccordionCard: {
            title: WMICRichTextUtil.translateRichText(translator(messages.vehicleHeader, {vehicleIndex: selectedVehicleValue.vehicleNumber_Ext, vehicleYear: selectedVehicleValue.year, vehicleModel: selectedVehicleValue.model, vehicleMake: selectedVehicleValue.make, vehicleVIN: selectedVehicleValue.vin})),
            id: `vehicleCoveragesAccordionCard${selectedVehicleIndex}`
        }
    };

    const resolvers = {
        resolveComponentMap:{
            WMICPAPolicyDriverEndorsements
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={categorizeCoverages.categorizedCoverages}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default WMICPAPolicyVehicleCoverages;
