import { defineMessages } from 'react-intl';

export default defineMessages({
    pageTitle: {
        id: 'gateway.views.dashboard.title',
        defaultMessage: 'Dashboard'
    },
    openQuotesTile: {
        id: 'gateway.directives.GatewayDashboardDirective.gateway-dashboard.Open Quotes',
        defaultMessage: 'Open Quotes'
    },
    policyChangeTile: {
        id: 'gateway.directives.GatewayDashboardDirective.gateway-dashboard.Policy Changes',
        defaultMessage: 'Open Policy Changes'
    },
    cancellationsTile: {
        id: 'gateway.directives.GatewayDashboardDirective.gateway-dashboard.Cancellations',
        defaultMessage: 'Open Cancellations'
    },
    openRenewalsTile: {
        id: 'gateway.directives.GatewayDashboardDirective.gateway-dashboard.Open Renewals',
        defaultMessage: 'Open Renewals'
    },
    upComingActivities: {
        id: 'gateway.views.activities.Activities for the next 7 days',
        defaultMessage: 'Your Open Activities for the next 7 days'
    }
});
