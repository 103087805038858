import React, {
    useEffect, useState, useCallback, useMemo, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useLocation, withRouter, useHistory } from 'react-router-dom';
import { withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { PolicyService } from 'wmic-pe-capability-gateway-policy';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { Loader } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { Wizard } from 'wmic-pe-portals-custom-wizard-react';
import { WMICUserAccessUtil, WMICLogger, PERIOD_DISPLAY_STATUS, JobType, MODAL_CONSTANTS, PRODUCT, OFFERING, CONSTANTS, parseErrorMessage } from 'wmic-pe-portals-utils-js';
import { WMICGoogleTagManagerUtil } from 'wmic-pe-capability-gateway-common-react';
import { WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';
import { WizardModalsProvider, useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import wizardConfig from './config/policy-wizard-config.json5';
import messages from './WMICPolicyWizard.messages';

function WMICPolicyWizard(props) {
    const { steps, title } = wizardConfig;
    const { authUserData: currentUser, authHeader } = useAuthentication();
    const [policyData, setPolicyData] = useState(null);
    const [policyTerms, setPolicyTerms] = useState(null);
    const [renewalTerm, setRenewalTerm] = useState(null);
    const [hasLapseStateChangedFlag, setHasLapseStateChangedFlag] = useState(false);
    const [enableLoading, setEnableLoading] = useState(false);
    const [policyPeriods, setPolicyPeriods] = useState([]);
    const { viewModelService } = props;
    const { showError } = useWizardModals();
    const location = useLocation();
    const history  = useHistory();
    const translator = useContext(TranslatorContext);

    const {
        match: {
            params: {
                policyNumber,
                termNumber
            }
        },
    } = props;

    const selectedPolicyTermNumber = parseInt(_.get(location, 'state.termNumber', termNumber ?? '1'), 10);

    const canEditPolicyChange = useMemo(
        () => WMICUserAccessUtil.canEditPolicyChange(currentUser.roles),
        [currentUser]
    );

    const policyVM = useMemo(() => {
        if (policyData) {
            const PolicyViewModel = viewModelService.create(
                policyData,
                'pc',
                'wmic.edge.ca.capabilities.gateway.policy.dto.PolicyDTO'
            );
            setPolicyPeriods(policyData.periods);
            setEnableLoading(false);
            return PolicyViewModel;
        }

        return null;
    }, [policyData, viewModelService]);

    const doesScheduledRenewalExist = useMemo(() => {
        if (policyData && policyData.latestPeriod) {
            const { latestPeriod } = policyData;
            const policyDisplayStatus = latestPeriod.displayStatus;
            const hasScheduledRenewal =
                [
                    PERIOD_DISPLAY_STATUS.SCHEDULED,
                    PERIOD_DISPLAY_STATUS.SCHEDULED_FR,
                ].includes(policyDisplayStatus)
                && latestPeriod
                && (latestPeriod.transactionType === JobType.RENEWAL
                    || latestPeriod.transactionType === JobType.RENEWAL_FR);
            return hasScheduledRenewal;
        }

        return null;
    }, [policyData]);

    const isEditButtonEnabled = useMemo(() => {
        if (policyTerms) {
            const selectedTerm = _.find(
                policyTerms,
                (policyTerm) => policyTerm.termNumber === selectedPolicyTermNumber
            );

            const openRenewalTerm = selectedTerm.openRenewalExists
                ? _.sortBy(policyTerms, ['termNumber']).reverse()[0]
                : undefined;

            let isSelectedTermUpcomingRenewal = false;

            if (openRenewalTerm) {
                isSelectedTermUpcomingRenewal =
                    selectedTerm.termNumber === openRenewalTerm.termNumber;
            }

            setRenewalTerm(openRenewalTerm);
            
            if (policyData && policyData.latestPeriod) {
                const { latestPeriod } = policyData;
                const isPolicyExpired = [CONSTANTS.EXPIRED, CONSTANTS.EXPIRED_FR].includes(latestPeriod.displayStatus.toLowerCase())

                const isCommercialPackage = _.get(policyVM, "product.productCode.value") === PRODUCT.COMMERCIAL_PACKAGE

                return canEditPolicyChange && !isSelectedTermUpcomingRenewal && !isCommercialPackage && !isPolicyExpired;
            }
        }
        return null;
    }, [canEditPolicyChange, policyData, policyTerms, policyVM, selectedPolicyTermNumber])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        setEnableLoading(true);
        const policyDataResponse = await PolicyService.getPolicyWithTermNumber(
            policyNumber,
            termNumber,
            authHeader
        ).catch((e) => {
            WMICLogger.error('Error getting policy with a term number', e);
            const errorToDisplay = parseErrorMessage(e) ?? translator(messages.errorRetrievingPolicy);
            WMICErrorHandler.processAsModal(errorToDisplay);
            history.push("/");
        });
        setPolicyData(policyDataResponse);

        try{
            const policyTermsData = await PolicyService.getPolicyTerms(
                policyNumber,
                authHeader
            );
            setPolicyTerms(policyTermsData.policyTerms);
        } catch(e) {
            WMICLogger.error('Error getting policy terms', e);
            const errorToDisplay = parseErrorMessage(e) ?? translator(messages.errorRetrievingPolicy);
            WMICErrorHandler.processAsModal(errorToDisplay);
            history.push("/");
        }

        const lob = _.get(policyDataResponse, 'product.productCode');
        WMICGoogleTagManagerUtil.addPolicyDataToGTagManager(null, policyNumber, lob, policyDataResponse);

        setEnableLoading(false);
    }, [authHeader, policyNumber, termNumber, hasLapseStateChangedFlag, translator, history]);

    const handleError = useCallback((error) => {
        if (typeof error === 'object') {
            return WMICErrorHandler.processAsModal(error);
        }
        return showError({
            title: commonMessages.genericError,
            message: commonMessages.genericErrorMessage,
            status: MODAL_CONSTANTS.STATUS.ERROR,
            icon: MODAL_CONSTANTS.ICON.ERROR,
            confirmButtonText: commonMessages.ok
        });
    }, [showError]);

    const getLobSteps = useCallback(() => {
        // step.visited = true;
        // step.submitted = true;
        let branchCode = _.get(policyVM, 'lobs.generalLiability.offerings.children[0].branchCode.value');

        if (branchCode) {
            switch (branchCode) {
                case OFFERING.ContractorsChoice:
                case OFFERING.NoEnhancement:
                    break;
                default:
                    branchCode = 'default';
                    break;
            }
        }
        return steps.filter((step) => (step.lob === _.get(policyVM, "product.productCode.value") || step.lob === "all")
                && (!step.branchCode || step.branchCode.includes('all') || step.branchCode.includes(branchCode)));
    }, [policyVM, steps]);

    // todo: BPN-8033 find alternative to WMICProgressModal to lock down screen (because it interferes with error handling modal)
    const isLoading = !policyVM || isEditButtonEnabled === null || enableLoading;
    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    return (
        <ErrorBoundary onError={handleError}>
            <WizardModalsProvider>
                <Wizard
                    initialSteps={getLobSteps()}
                    wizardTitle={title}
                    initialData={{
                        policyVM,
                        isEditButtonEnabled,
                        selectedPolicyTermNumber,
                        doesScheduledRenewalExist,
                        currentUser,
                        setHasLapseStateChangedFlag,
                        hasLapseStateChangedFlag,
                        policyPeriods,
                        policyTerms,
                        renewalTerm
                    }}
                    skipCompletedSteps
                    maintainFurtherStepsVisitedSubmitted={{
                        flag: true, 
                        targetStep: steps.length,
                    }}
                />
            </WizardModalsProvider>
        </ErrorBoundary>
    );
}

WMICPolicyWizard.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            quoteentry: PropTypes.shape({
                postalCode: PropTypes.string,
                quoteID: PropTypes.string
            })
        })
    }).isRequired
};

export default withRouter(withViewModelService(WMICPolicyWizard));
