import { defineMessages } from 'react-intl';

export default defineMessages({
    pageTitle: {
        id: 'wmic.gateway.views.dashboard.title',
        defaultMessage: 'Dashboard'
    },
    autoMobileLink: {
        id: 'wmic.capabilities-react.gw-capabilities-gateway-react.LandingPage.policy-landing-menu-link.Personal Auto',
        defaultMessage: 'Automobile',
    },
    personalPropertyLink: {
        id: 'wmic.capabilities-react.gw-capabilities-gateway-react.LandingPage.policy-landing-menu-link.Home Owners',
        defaultMessage: 'Personal Property',
    },
    personalUmbrellaLink: {
        id: 'wmic.capabilities-react.gw-capabilities-gateway-react.LandingPage.policy-landing-menu-link.PersonalUmbrella',
        defaultMessage: 'Personal Umbrella',
    },
    commercialSmallBusinessLink: {
        id: 'wmic.capabilities-react.gw-capabilities-gateway-react.LandingPage.policy-landing-menu-link.CommercialSmallBusiness',
        defaultMessage: "Commercial Lines Small Business",
    },
    newSubmissionLink: {
        id: 'wmic.capabilities-react.gw-capabilities-gateway-react.LandingPage.policy-landing-menu-link.New submission',
        defaultMessage: 'New submission',
    },
    buttonText: {
        id: 'wmic.capabilities-react.gw-capabilities-gateway-react.LandingPage.policy-landing-menu-link.Start new submission',
        defaultMessage: 'Start new submission'
    },
    openPolicyTransactions: {
        id: 'policy.agent.views.policy-landing.Open Policy Transactions',
        defaultMessage: 'Open Policy Transactions'
    },
    upcomingRenewalsPoliciesTab: {
        id: 'policy.agent.views.policy-landing.Upcoming Renewals',
        defaultMessage: 'Upcoming Renewals'
    },
    openSubmissionsTab: {
        id: 'wmic.capabilities-react.gw-capabilities-gateway-react.LandingPage.Open Submissions',
        defaultMessage: 'Open Submissions'
    },
    openPolicyTransactionsTab: {
        id: 'wmic.capabilities-react.gw-capabilities-gateway-react.LandingPage.Open Policy Transactions',
        defaultMessage: 'Open Policy Transactions'
    },
    openRecentlyViewedPoliciesTab: {
        id: 'wmic.capabilities-react.gw-capabilities-gateway-react.LandingPage.Recently Viewed Policies',
        defaultMessage: 'Recently Viewed Policies'
    },
    openRecentlyIssuedPoliciesTab: {
        id: 'wmic.capabilities-react.gw-capabilities-gateway-react.LandingPage.Recently Issued Policies',
        defaultMessage: 'Recently Issued Policies'
    },
    policyCreated: {
        id: 'policy.agent.views.policy-landing.Created',
        defaultMessage: 'Created'
    },
    policyName: {
        id: 'policy.agent.views.policy-landing.Insured',
        defaultMessage: 'Insured'
    },
    policyNumber: {
        id: 'policy.agent.views.policy-landing.Policy Number',
        defaultMessage: 'Policy'
    },
    policyPolicy: {
        id: 'policy.agent.views.policy-landing.Transaction',
        defaultMessage: 'Policy'
    },
    policyStatus: {
        id: 'policy.agent.views.policy-landing.Status',
        defaultMessage: 'Status'
    },
    policyBrokerNumber: {
        id: 'policy.agent.views.policy-landing.Broker Number',
        defaultMessage: 'Broker'
    },
    submissionCreated: {
        id: 'submission.agent.views.policy-landing.Created',
        defaultMessage: 'Created'
    },
    submissionName: {
        id: 'submission.agent.views.policy-landing.Insured',
        defaultMessage: 'Insured'
    },
    submissionSubmission: {
        id: 'submission.agent.views.policy-landing.Submission Number',
        defaultMessage: 'Submission'
    },
    submissionStatus: {
        id: 'submission.agent.views.policy-landing.Status',
        defaultMessage: 'Status'
    },
    submissionFilterStatus: {
        id: 'submission.agent.views.policy-landing.Filter Status',
        defaultMessage: 'Filter Status'
    },
    submissionLineOfBusinessFilterStatus: {
        id: 'submission.agent.views.policy-landing.Filter Line of Business',
        defaultMessage: 'Filter Line of Business'
    },
    submissionBrokerNumber: {
        id: 'submission.agent.views.policy-landing.Broker Number',
        defaultMessage: 'Broker'
    },
    issuedName: {
        id: 'issued.agent.directives.PolicySummary.Client Name',
        defaultMessage: 'Name'
    },
    issuedAddress: {
        id: 'issued.agent.directives.PolicySummary.Policy Address',
        defaultMessage: 'Address'
    },
    issuedPolicy: {
        id: 'issued.agent.directives.PolicySummary.Policy Number',
        defaultMessage: 'Policy'
    },
    issuedBrokerNumber: {
        id: 'issued.agent.directives.PolicySummary.Broker #',
        defaultMessage: 'Broker'
    },
    viewedName: {
        id: 'viewed.agent.directives.PolicySummary.Client Name',
        defaultMessage: 'Name'
    },
    viewedAddress: {
        id: 'viewed.agent.directives.PolicySummary.Policy Address',
        defaultMessage: 'Address'
    },
    viewedPolicy: {
        id: 'viewed.agent.directives.PolicySummary.Policy Number',
        defaultMessage: 'Policy'
    },
    viewedBrokerNumber: {
        id: 'viewed.agent.directives.PolicySummary.Broker #',
        defaultMessage: 'Broker'
    },
    viewedPrior: {
        id: 'viewed.agent.directives.PolicySummary.Previous Policy Number',
        defaultMessage: 'Prior'
    },
    upcomingPolicy: {
        id: 'upcoming.agent.directives.PolicySummary.Policy Number',
        defaultMessage: 'Policy'
    },
    upcomingInsured: {
        id: 'upcoming.agent.directives.PolicySummary.Insured',
        defaultMessage: 'Insured'
    },
    upcomingRenewalDate: {
        id: 'upcoming.agent.directives.PolicySummary.Renewal Date',
        defaultMessage: 'Renewal Date'
    },
    upcomingStatus: {
        id: 'upcoming.agent.directives.PolicySummary.Status',
        defaultMessage: 'Status'
    },
    upcomingRemainingTime: {
        id: 'upcoming.agent.directives.PolicySummary.Remaining Time',
        defaultMessage: 'Remaining Time'
    },    
    accessRestrictsSavingChanges: {
        id: 'wmic-pe-capability-gateway-react.LandingPage.YourAccessRestricts',
        defaultMessage: 'Your access restricts you from saving any changes. Please contact your administrator.'
    },    
    dayUntilRenewalIssued: {
        id: 'wmic-pe-capability-gateway-react.LandingPage.Day until Renewal Issued',
        defaultMessage: '1 Day until Renewal Issued'
    },    
    daysUntilRenewalIssued: {
        id: 'wmic-pe-capability-gateway-react.LandingPage.Days until Renewal Issued',
        defaultMessage: '{numberOfDays} Days until Renewal Issued'
    },
    notAvailable: {
        id: 'wmic-pe-capability-gateway-react.LandingPage.Not Available',
        defaultMessage: 'Not Available'
    },
});
