import { defineMessages } from 'react-intl';

export default defineMessages({
    defaultFound: {
        id: 'agent.util.DefaultedValues.Found',
        defaultMessage: 'Defaulted Values Found'
    },
    correctedFields: {
        id: 'agent.util.DefaultedValues.CorrectFields',
        defaultMessage: 'The fields listed below must be reviewed and/or corrected before quoting this submission.'
    },
    additionalInterestDefault: {
        id: 'agent.util.DefaultedValues.AdditionalInterest',
        defaultMessage: 'Additional Interest'
    },
    accidentWaiverEndorsementDefault: {
        id: 'agent.util.DefaultedValues.AccidentWaiverEndorsement',
        defaultMessage: 'Accident Waiver Endorsement'
    },
    wawanesaPolicyDefault: {
        id: 'agent.util.DefaultedValues.WawanesaPolicy',
        defaultMessage: 'Wawanesa Policy'
    }
});
