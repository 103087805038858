import React from 'react';
import { DropdownMenuButton, DropdownMenuLink } from '@jutro/components';
import {  WMICIcon } from 'wmic-pe-components-platform-react';
import PropTypes from "prop-types";
import cx from 'classnames';
import { BUTTON_CONSTANTS } from 'wmic-pe-portals-utils-js';
import styles from './WMICDropdownMenuButton.module.scss';

const WMICDropdownMenuButton = ({
    type, className: passedClassName, size, children, items, onItemClick, ...props
}) => {
    const [buttonType, buttonModifier = BUTTON_CONSTANTS.BUTTON_DEFAULT_MODIFIER] = type.split('-');
    const buttonClass = `ww-btn-${buttonType}`;
    const icon = props.icon ? props.icon : BUTTON_CONSTANTS.DROPDOWN_BUTTON_DEFAULT_ICON;

    const renderContent = () => {
        if (items.length > 0)
            return items.map((item) =>
                <DropdownMenuLink className={styles.wmicDropdownLink} key={item.id} id={item.id} onClick={() => onItemClick(item.code)} >
                    { item.icon && <WMICIcon icon={item.icon}/> }
                    <span>{ item.text }</span>
                </DropdownMenuLink>
            )
        return children;
    }


    return (
        <DropdownMenuButton
            {...props}
            icon={icon}
            size={size}
            type={buttonModifier}
            className={cx(styles.wmicDropdownButton, buttonClass, passedClassName)}
        >
            { renderContent() }
        </DropdownMenuButton>
    );
};

WMICDropdownMenuButton.propTypes = {
    type: PropTypes.oneOf(BUTTON_CONSTANTS.BUTTON_ALLOWED_TYPES),
    size: PropTypes.string,
    onItemClick: PropTypes.func,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            code: PropTypes.string.isRequired,
            icon: PropTypes.string,
        })
    ),
};

WMICDropdownMenuButton.defaultProps = {
    type: BUTTON_CONSTANTS.BUTTON_DEFAULT_TYPE,
    size: BUTTON_CONSTANTS.BUTTON_DEFAULT_SIZE,
    onItemClick: null,
    items: [],
}

export default WMICDropdownMenuButton;
