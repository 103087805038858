import { defineMessages } from 'react-intl';

export default defineMessages({
    noContentMessage: {
        id: 'agent.views.policy-landing.noContent',
        defaultMessage: 'These documents are currently unavailable. For any questions, please contact your underwriter.'
    },
    brokerHelp: {
        id: 'agent.views.policy-landing.Broker Help',
        defaultMessage: 'Contact Broker Help when:'
    },
    unableToLogin: {
        id: 'agent.views.policy-landing.Unable to login',
        defaultMessage: 'Unable to login'
    },
    bluepassNotWorking: {
        id: 'agent.views.policy-landing.BluePass isnt working',
        defaultMessage: "BluePass isn't working"
    },
    frozenScreen: {
        id: 'agent.views.policy-landing.Frozen screen',
        defaultMessage: 'Frozen screen'
    },
    eDocs: {
        id: 'agent.views.policy-landing.Missing eDocs or downloads',
        defaultMessage: 'Missing eDocs or downloads'
    },
    brokerTollFree: {
        id: 'agent.views.policy-landing.Broker Help Toll Free',
        defaultMessage: 'Toll Free'
    },
    brokerTollFreeNumbers: {
        id: 'agent.views.policy-landing.Broker Help Toll Free Numbers',
        defaultMessage: '(877) 985-3976'
    },
    brokerEmail: {
        id: 'agent.views.policy-landing.Broker Help Email',
        defaultMessage: 'Email'
    },
    brokerEmailAddress: {
        id: 'agent.views.policy-landing.Broker Help Email Address',
        defaultMessage: 'brokerhelp@wawanesa.com'
    },
    billingInquiryFor: {
        id: 'agent.views.policy-landing.Billing Inquiry',
        defaultMessage: 'Contact Billing Inquiry for:'
    },
    refundInquiries: {
        id: 'agent.views.policy-landing.Billing/Payment/Refund inquiries',
        defaultMessage: 'Billing/Payment/Refund inquiries'
    },
    creditCardIssues: {
        id: 'agent.views.policy-landing.Credit card issues',
        defaultMessage: 'Credit card issues'
    },
    assistanceWithdrawalDateChange: {
        id: 'agent.views.policy-landing.Assistance with processing a withdrawal date change',
        defaultMessage: 'Assistance with processing a withdrawal date change'
    },
    assistanceUpdatingInfo: {
        id: 'agent.views.policy-landing.Assistance with updating bank information',
        defaultMessage: 'Assistance with updating bank information'
    },
    branchTollFreeNumber: {
        id: 'agent.views.policy-landing.Call the Branch Toll Free Number and press option #3',
        defaultMessage: 'Call the Branch Toll Free Number and press option #3'
    },
    britishColumbiaInquiry: {
        id: 'agent.views.policy-landing.Billing Inquiry British Columbia',
        defaultMessage: 'British Columbia'
    },
    britishColumbiaInquiryNumber: {
        id: 'agent.views.policy-landing.Billing Inquiry British Columbia Number',
        defaultMessage: '(800) 665-2778'
    },
    albertaYukonInquiry: {
        id: 'agent.views.policy-landing.Billing Inquiry Alberta and Yukon',
        defaultMessage: 'Alberta (& Yukon)'
    },
    albertaYukonInquiryNumber: {
        id: 'agent.views.policy-landing.Billing Inquiry Alberta and Yukon Number',
        defaultMessage: '(800) 625-7891'
    },
    ontarioInquiry: {
        id: 'agent.views.policy-landing.Billing Inquiry Ontario',
        defaultMessage: 'Ontario'
    },
    ontarioInquiryNumber: {
        id: 'agent.views.policy-landing.Billing Inquiry Ontario Number',
        defaultMessage: '(800) 268-7300'
    },
    centralInquiry: {
        id: 'agent.views.policy-landing.Billing Inquiry Central',
        defaultMessage: 'Central'
    },
    centralInquiryNumber: {
        id: 'agent.views.policy-landing.Billing Inquiry Central Number',
        defaultMessage: '(888) 824-6212'
    },
    quebecInquiry: {
        id: 'agent.views.policy-landing.Billing Inquiry Quebec',
        defaultMessage: 'Quebec'
    },
    quebecInquiryNumber: {
        id: 'agent.views.policy-landing.Billing Inquiry Quebec Number',
        defaultMessage: '(888) 933-9292'
    },
    maritimeInquiry: {
        id: 'agent.views.policy-landing.Billing Inquiry Maritime',
        defaultMessage: 'Maritime'
    },
    maritimeInquiryNumber: {
        id: 'agent.views.policy-landing.Billing Inquiry Maritime Number',
        defaultMessage: '(800) 361-2528'
    },
    contactUnderwriter: {
        id: 'agent.views.policy-landing.Underwriter Help',
        defaultMessage: 'Contact an Underwriter when:'
    },
    assistanceTransaction: {
        id: 'agent.views.policy-landing.Assistance completing a transaction',
        defaultMessage: 'Assistance completing a transaction'
    },
    coverageQuestions: {
        id: 'agent.views.policy-landing.Coverage questions',
        defaultMessage: 'Coverage questions'
    },
    britishColumbiaHelp: {
        id: 'agent.views.policy-landing.Underwriter Help British Columbia',
        defaultMessage: 'British Columbia'
    },
    britishColumbiaHelpNumber: {
        id: 'agent.views.policy-landing.Underwriter Help British Columbia Number',
        defaultMessage: '(800) 665-2778'
    },
    albertaYukonHelp: {
        id: 'agent.views.policy-landing.Underwriter Help Alberta and Yukon',
        defaultMessage: 'Alberta (& Yukon)'
    },
    albertaYukonHelpNumber: {
        id: 'agent.views.policy-landing.Underwriter Help Alberta and Yukon Number',
        defaultMessage: '(800) 625-7891'
    },
    centralHelp: {
        id: 'agent.views.policy-landing.Underwriter Help Central',
        defaultMessage: 'Central'
    },
    centralHelpNumber: {
        id: 'agent.views.policy-landing.Underwriter Help Central Number',
        defaultMessage: '(888) 824-6212'
    },
    ontarioHelp: {
        id: 'agent.views.policy-landing.Underwriter Help Ontario',
        defaultMessage: 'Ontario'
    },
    ontarioHelpNumber: {
        id: 'agent.views.policy-landing.Underwriter Help Ontario Number',
        defaultMessage: '(800) 268-7300'
    },
    maritimeHelp: {
        id: 'agent.views.policy-landing.Underwriter Help Maritime',
        defaultMessage: 'Maritime'
    },
    maritimeHelpNumber: {
        id: 'agent.views.policy-landing.Underwriter Help Maritime Number',
        defaultMessage: '(800) 361-2528'
    },
    quebecHelp: {
        id: 'agent.views.policy-landing.Underwriter Help Quebec',
        defaultMessage: 'Quebec'
    },
    quebecHelpNumber: {
        id: 'agent.views.policy-landing.Underwriter Help Quebec Number',
        defaultMessage: '(888) 933-9292'
    },
    forMoreInfo: {
        id: 'agent.views.policy-landing.Visit Broker Platform',
        defaultMessage: 'For more information, please visit'
    },
    portalLink: {
        id: 'agent.views.policy-landing.Visit Broker Platform Link',
        defaultMessage: 'https://login.brokerplatform.wawanesa.com'
    },
    portalLinkText: {
        id: 'agent.views.policy-landing.Visit Broker Platform Link Text',
        defaultMessage: ' Broker Platform'
    }
});
