import { defineMessages } from 'react-intl';

export default defineMessages({
    vehiclesTitle: {
        id: 'endorsement.pa.view.Vehicles',
        defaultMessage: 'Vehicles'
    },
    savingVehicle: {
        id: 'wmic-pe-capability-gateway-policychange-pa-react.WMICPCPAVehiclesPage.savingVehicle',
        defaultMessage: 'Saving Vehicle'
    },
    vinIsNotUnique: {
        id: 'wmic-pe-capability-gateway-policychange-pa-react.WMICPCPAVehiclesPage.vinIsNotUnique',
        defaultMessage: 'VIN is not unique'
    },
    vinMatchMsg: {
        id: 'wmic-pe-capability-gateway-policychange-pa-react.WMICPCPAVehiclesPage.vinMatchMsg',
        defaultMessage: 'Sorry, but this vehicle\'s VIN matches that of {vehicle}. Please ensure that all VINs are unique.'
    },
    actionRequired: {
        id: 'wmic-pe-capability-gateway-policychange-pa-react.WMICPCPAVehiclesPage.actionRequired',
        defaultMessage: 'Action required'
    },
    vinSearchRequired: {
        id: 'wmic-pe-capability-gateway-policychange-pa-react.WMICPCPAVehiclesPage.vinSearchRequired',
        defaultMessage: 'A vehicle search is required to continue.'
    },
    close: {
        id: 'wmic-pe-capability-gateway-policychange-pa-react.WMICPCPAVehiclesPage.close',
        defaultMessage: 'Close'
    },
    snowVehicleCombinationError: {
        id: 'wmic-pe-capability-gateway-policychange-pa-react.WMICPCPAVehiclesPage.Snow Vehicle With PPV',
        defaultMessage: 'Vehicle Type: Snow Vehicle cannot be combined with any other vehicle type.'
    },
    snowMotorcycleVehicleCombinationError: {
        id: 'wmic-pe-capability-gateway-policychange-pa-react.WMICPCPAVehiclesPage.Snow And Motorcycle With PPV',
        defaultMessage: 'Vehicle Type: Snow Vehicle cannot be combined with any other vehicle type, Motorcycle cannot be combined with any other vehicle type.'
    },
    motorcycleVehicleCombinationError: {
        id: 'wmic-pe-capability-gateway-policychange-pa-react.WMICPCPAVehiclesPage.Motorcycle With PPV',
        defaultMessage: 'Vehicle Type: Motorcycle cannot be combined with any other vehicle type.'
    },
    snowMotorcycleVehicleCombination: {
        id: 'wmic-pe-capability-gateway-policychange-pa-react.WMICPCPAVehiclesPage.Snow Vehicle and Motorcycle Together',
        defaultMessage: 'Vehicle Type: Motorcycle cannot be combined with Snow Vehicle.'
    },
    errorVehicleCombinationError: {
        id: 'wmic-pe-capability-gateway-policychange-pa-react.WMICPCPAVehiclesPage.Error Description',
        defaultMessage: 'Error'
    },
    removeVehicleTitle: {
        id: 'wmic-pe-capability-gateway-policychange-pa-react.WMICPCPAVehiclesPage.removeVehicleTitle',
        defaultMessage: 'Remove Vehicle?'
    },
    removeVehicle: {
        id: 'wmic-pe-capability-gateway-policychange-pa-react.WMICPCPAVehiclesPage.removeVehicle',
        defaultMessage: 'Are you sure you want to remove {vehicle} from the list of vehicles?'
    },
    vehicleNumber: {
        id: 'wmic-pe-capability-gateway-policychange-pa-react.WMICPCPAVehiclesPage.vehicleNumber',
        defaultMessage: '#'
    },
    theftIdentifier: {
        id: 'wmic-pe-capability-gateway-policychange-pa-react.WMICPCPAVehiclesPage.theftIdentifier',
        defaultMessage: 'THEFT IDENTIFIER'
    },
    vehicleType: {
        id: 'wmic-pe-capability-gateway-policychange-pa-react.WMICPCPAVehiclesPage.vehicleType',
        defaultMessage: 'VEHICLE TYPE'
    },
    vehicleDescription: {
        id: 'wmic-pe-capability-gateway-policychange-pa-react.WMICPCPAVehiclesPage.vehicleDescription',
        defaultMessage: 'VEHICLE DESCRIPTION'
    },
    vehicleBusinessSegment: {
        id: 'wmic-pe-capability-gateway-policychange-pa-react.WMICPCPAVehiclesPage.vehicleBusinessSegment',
        defaultMessage: 'BUSINESS SEGMENT'
    },
    saveVehicleFailed: {
        id: 'wmic-pe-capability-gateway-policychange-pa-react.WMICPCPAVehiclesPage.saveVehicleFailed',
        defaultMessage: 'Save vehicle failed'
    },
    newVehicle: {
        id: 'wmic-pe-capability-gateway-policychange-pa-react.WMICPCPAVehiclesPage.newVehicle',
        defaultMessage: 'New Vehicle'
    },
});
