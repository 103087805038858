/* eslint-disable security/detect-object-injection */
import React, {
    useContext
} from 'react';
import cx from 'classnames';
import _ from "lodash";
import { TranslatorContext } from '@jutro/locale';
import { WMICCard, WMICAccordionCard, WMICDataTable, WMICIcon } from 'wmic-pe-components-platform-react';
import {
    TableColumn
} from '@jutro/datatable';
import { withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { Accordion } from '@jutro/components';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { CONSTANTS, JobType } from 'wmic-pe-portals-utils-js';
import messages from './WMICPolicyChangeDiffComponent.messages';
import styles from './WMICPolicyChangeDiffComponent.module.scss';

function WMICPolicyChangeDiffComponent(props) {
    const {
        changeSummaryDiffItems,
        jobType,
    } = props;

    const translator = useContext(TranslatorContext);

    const generateRowObject = (item, level) => {
        let obj = {}

        if (item.nodeType === CONSTANTS.POLICYCHANGEDIFF_NODETYPE.TITLE) {
            obj = {
                level,
                title: item.label
            }
        } else if (item.nodeType === CONSTANTS.POLICYCHANGEDIFF_NODETYPE.CHANGE) {
            obj =  {
                level,
                title: item.label,
                value1: item.value1,
                value2: item.value2,
            }
        } else if ([CONSTANTS.POLICYCHANGEDIFF_NODETYPE.ADD, CONSTANTS.POLICYCHANGEDIFF_NODETYPE.REMOVE].includes(item.nodeType)) {
            obj =  {
                level,
                title: item.label,
                value2: item.nodeType,
            }
        }

        return obj;
    }

    const generateTableDataRec = (item, index = 1) => {
        let tableData = [];
        const itemChildren = _.get(item, 'children', []);

        for (let i = 0; i < itemChildren.length; i += 1) {
            tableData.push(generateRowObject(itemChildren[i], index));

            const childrenChanges = _.get(itemChildren[i], 'children', []);

            if (childrenChanges.length > 0) {
                tableData = [
                    ...tableData,
                    ...generateTableDataRec(itemChildren[i], index + 1),
                ];
            }
        }

        return tableData;
    };

    const getChangedCell = (row) => {
        switch(row.level) {
            case 1:
                return (
                    <b> {row.title} </b>
                )
            case 2:
                return (
                    <div className='gw-ml-2'> {row.title} </div>
                )
            case 3:
                return (
                    <div className='gw-ml-4'> {row.title} </div>
                )
            case 4:
                return (
                    <div className='gw-ml-8'> {row.title} </div>
                )
            default:
                return (
                    <div className='gw-ml-12'> {row.title} </div>
                )
        }
    };

    const getExistingPolicyCell = (row) => row.value1;

    const getPolicyChangeCell = (row) => {
        switch(row.value2) {
            case CONSTANTS.POLICYCHANGEDIFF_NODETYPE.ADD:
                return (
                    <div className={cx(styles.addContainer, styles.nodeContainer)}>
                        <WMICIcon
                            id = "expandIcon"
                            icon = "mi-done"
                            size = "large"
                            className={cx(styles.addColor, styles.nodeIcon)} />

                        <span className={cx(styles.addColor, styles.statusText)}> { translator(messages.addedItem) } </span>
                    </div>
                );
            case CONSTANTS.POLICYCHANGEDIFF_NODETYPE.REMOVE:
                return (
                    <div className={cx(styles.removeContainer, styles.nodeContainer)}>
                        <WMICIcon
                            id = "expandIcon"
                            icon = "mi-close"
                            size = "large"
                            className={cx(styles.removeColor, styles.nodeIcon)} />

                        <span className={cx(styles.removeColor, styles.nodeText)}> { translator(messages.removedItem) }</span>
                    </div>
                );
            default:
                return row.value2
        }
    };

    const generateCard = (item, index) => {
        const tableData = generateTableDataRec(item);

        return (
            <WMICCard className="gw-mb-4">
                <Accordion id={`policyChangeDiffAccordion${index}`}
                    showFrame={false}
                    closeOthers={false}
                    defaultOpenedId={`policyChangeDiffAccordionCard${index}`}
                >
                    <WMICAccordionCard
                        id={`policyChangeDiffAccordionCard${index}`}
                        title={item.label}
                        defaultOpenedId={`policyChangeDiffAccordionCard${index}`}
                        showFrame={false}
                        closeOthers={false}
                        boldFont={false}
                        chevron>
                        <WMICDataTable
                            data={tableData}
                            showPagination={false}
                            pShowSearch={false}
                            id={`policyChangeDiffDataTable${index}`}>
                            <TableColumn
                                id="changed"
                                header={translator(messages.changed)}
                                renderCell={getChangedCell}
                                sortable={false}
                                columnProportion={2}
                            />
                            <TableColumn
                                id="existingPolicy"
                                header={translator(messages.existingPolicy)}
                                renderCell={getExistingPolicyCell}
                                sortable={false}
                                columnProportion={1}
                            />
                            <TableColumn
                                id="policyChange"
                                header={
                                    jobType === JobType.RENEWAL
                                        ? translator(messages.renewal)
                                        : translator(messages.policyChange)
                                }
                                renderCell={getPolicyChangeCell}
                                sortable={false}
                                columnProportion={1}
                            />
                        </WMICDataTable>
                    </WMICAccordionCard>
                </Accordion>
            </WMICCard>
        )

    }

    const generateJsx = () => {
        const jsxArray = [];

        for (let i = 0; i < changeSummaryDiffItems.length; i += 1) {
            const itemCard = generateCard(changeSummaryDiffItems[i], i);

            jsxArray.push(itemCard);
        }

        return jsxArray;
    }

    return (
        <React.Fragment> { generateJsx() } </React.Fragment>
    );
}

WMICPolicyChangeDiffComponent.propTypes = wizardProps;

export default withViewModelService(WMICPolicyChangeDiffComponent);
