import { defineMessages } from 'react-intl';

export default defineMessages({
    policyAdditionalInterestsTitle: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleAdditionalInfo.Additional Interests',
        defaultMessage: 'Additional Interests'
    },
    policyCustomizationTitle: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleAdditionalInfo.Customization',
        defaultMessage: 'Customization'
    },
    policyCriorDamageTitle: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleAdditionalInfo.PriorDamage',
        defaultMessage: 'Prior Damage'
    },
    policyCustomizationTotal: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleAdditionalInfo.CustomizationTotal',
        defaultMessage: 'Total value of Customizations'
    },
    policyAddCustomizationButton: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleAdditionalInfo.AddCustomization',
        defaultMessage: 'Add Customization'
    },
    policyPriorUnrepairedDamageQuestion: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleAdditionalInfo.PriorUnrepairedDamage',
        defaultMessage: 'Does this vehicle have prior unrepaired damage?'
    },
    policySalvageVehicleQuestion: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleAdditionalInfo.IsTheVehicleASalvageVehicle?',
        defaultMessage: 'Is the vehicle a salvage vehicle (previous total loss)?'
    },
    policyWindshieldDamageQuestion: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleAdditionalInfo.DoesThisVehicleHaveAnyGlassDamage?',
        defaultMessage: 'Does this vehicle have any glass damage?'
    },
    policyWindshieldDamageType: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleAdditionalInfo.GlassDamageType',
        defaultMessage: 'Glass Damage Type'
    },
    policyWindshieldDamageDescription: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleAdditionalInfo.LocationAndSeverityOfGlassDamage',
        defaultMessage: 'Location and severity of glass damage'
    },
    policyWindshieldDamageConfirmation: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleAdditionalInfo.WillTheGlassDamageBeRepaired?',
        defaultMessage: 'Will the glass damage be repaired/replaced within 30 days?'
    },
    policyBodyDamageQuestion: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleAdditionalInfo.DoesThisVehicleHaveAnyBodyDamage?',
        defaultMessage: 'Does this vehicle have any body damage?'
    },
    policyBodyDamageDescription: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleAdditionalInfo.BodyDamageDescription',
        defaultMessage: 'Body Damage Description'
    },
    policyBodyDamageConfirmation: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleAdditionalInfo.WillTheBodyDamageBeRepaired?',
        defaultMessage: 'Will the body damage be repaired/replaced within 30 days?'
    },
    policyHailDamageQuestion: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleAdditionalInfo.DoesThisVehicleHaveAnyHailDamage?',
        defaultMessage: 'Does this vehicle have any hail damage?'
    },
    policyHailDamageDescription: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleAdditionalInfo.HailDamageDescription',
        defaultMessage: 'Hail Damage Description'
    },
    policyVandalismDamageQuestion: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleAdditionalInfo.DoesThisVehicleHaveAnyVandalismDamage?',
        defaultMessage: 'Does this vehicle have any vandalism damage?'
    },
    policyVandalismDamageDescription: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleAdditionalInfo.VandalismDamageDescription',
        defaultMessage: 'Vandalism Damage Description'
    },
    policyOtherDamageQuestion: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleAdditionalInfo.DoesThisVehicleHaveAnyOtherDamage?',
        defaultMessage: 'Does this vehicle have any other damage?'
    },
    policyOtherDamageDescription: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleAdditionalInfo.OtherDamageDescription',
        defaultMessage: 'Other Damage Description'
    },
    policyVehicleCustomizationType: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleAdditionalInfo.Type',
        defaultMessage: 'Type'
    },
    policyVehicleCustomizationDescription: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleAdditionalInfo.Description',
        defaultMessage: 'Description'
    },
    policyVehicleCustomizationValue: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleAdditionalInfo.Value',
        defaultMessage: 'Value'
    },
    policyVehicleCustomizationAttachedMachinery: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleAdditionalInfo.ExcludingOperationOfAttachedMachinery',
        defaultMessage: 'Excluding Operation Of Attached Machinery'
    },
});
