import _ from 'lodash';
import React, { useContext, useCallback } from 'react';

import { TranslatorContext } from '@jutro/locale';

import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import { WMICWizardViewPage } from 'wmic-pe-portals-wizard-components-ui';
import { WMICGLLiabilityCoverages, WMICGLLiabilityDetails } from 'wmic-pe-capability-gateway-quoteandbind-gl-react';
import { WMICCPAdditionalInterestDetailView } from "wmic-pe-capability-gateway-quoteandbind-cp-react"
import { CONSTANTS } from 'wmic-pe-portals-utils-js';

import metadata from './WMICGlPolicyLiabilityPage.metadata.json5';
import messages from './WMICGlPolicyLiabilityPage.messages';

function WMICGlPolicyLiabilityPage(props) {
    const {
        wizardData: { policyVM },
    } = props;

    const translator = useContext(TranslatorContext);

    const getName = useCallback((additionalInsured) => {
        if (additionalInsured.contactType.value.code === _.lowerCase(CONSTANTS.Person)) {
            const firstName = additionalInsured.firstName.value;
            const lastName = additionalInsured.lastName.value;
            return `${firstName} ${lastName}`;
        }
        if (additionalInsured.contactType.value.code === _.lowerCase(CONSTANTS.Company)) {
            const company = additionalInsured.companyName.value;
            return company;
        }
    }, []);

    const commonOverrides = {
        'readOnly': true,
    }

    const overrideProps = {
        '@field': {
            ...commonOverrides
        },
        liabilityCoverages: {
            ...commonOverrides,
            isBoundPolicy: true,
            jobVM: policyVM,
        },
        liabilityDetails: {
            ...commonOverrides,
            isBoundPolicy: true,
            jobVM: policyVM
        },
        additionalInsuredListView: {
            clickable: true,
            startOpen: false,
            readOnly: true,
            editEnabled: false,
            hideBorder: true,
            value: _.get(policyVM, `lobs.generalLiability.additionalInsureds.children`, []),
            VMData: [
                {
                    headerText: translator(messages.name),
                    getData: getName
                },
                {
                    headerText: translator(messages.numberOfDaysNotice),
                    path: 'numberOfDays'
                },
            ],
            detailViewComponent: WMICCPAdditionalInterestDetailView,
            detailViewComponentProps: {
                jobVM: policyVM
            },
        },
    };

    const resolvers = {
        resolveComponentMap: {
            WMICGLLiabilityCoverages,
            WMICGLLiabilityDetails
        }
    };

    return (
        <WMICWizardViewPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyVM}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
            />
        </WMICWizardViewPage>
    );
}

WMICGlPolicyLiabilityPage.propTypes = wizardProps;
export default WMICGlPolicyLiabilityPage;
