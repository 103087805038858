import { defineMessages } from 'react-intl';

export default defineMessages({
    ratingInformationDescription: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.dwelling-coverages-table.rating-information.description',
        defaultMessage: 'Description'
    },
    ratingInformationTitle: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.dwelling-coverages-table.rating-information.title',
        defaultMessage: 'Rating Information'
    },
    discountsApplied: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.dwelling-coverages-table.rating-information.discounts',
        defaultMessage: 'Discounts Applied'
    },
    surchargesApplied: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.dwelling-coverages-table.rating-information.surcharges',
        defaultMessage: 'Surcharges Applied'
    },
    none: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.dwelling-coverages-table.rating-information.none',
        defaultMessage: 'NONE'
    },
    disappearingDeductibleIncluded: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.dwelling-coverages-table.disappearing-deductible.included',
        defaultMessage: 'The Disappearing Deductible benefit is included.'
    },
    disappearingDeductibleReduced: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.dwelling-coverages-table.disappearing-deductible.reduced',
        defaultMessage: 'The policy deductible will be reduced to ${disappearingDeductibleValue}.'
    }
});
