/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { CONSTANTS } from 'wmic-portals-utils-js';
import _ from 'lodash';

const CONSTANTS_COV = {
    PACKAGE: 'Package',
    EARTHQUAKE: 'Earthquake',
    LIMIT: 'Limit',
    ADDL_LIVING_EXPENSES: 'Additional Living Expenses',
    SPECIAL_PERS_PROP_COV: 'HO_SpecialPersPropCov',
    PP_DEDUCTIBLE_PERCENT: 'Deductible % of Personal Property',
    RENTER_EQ_DED: 'HO_EarthquakeCovRentersDed',
    EQ_DED_OR: 'HO_EarthquakeCovDeductOR',
    ZERO: '0',
    PERSONAL_PROPERTY: 'Personal Property',
    PERSONAL_LIABILITY: 'Personal Liability',
    MEDICAL_PAYMENTS: 'Medical Payments'
};

function getDeductibleNameForCoverageByPublicID(publicID, messages, translator) {
    // CSS-679 : Specific Names for deductibles as per requirements.
    // There is no well defined pattern for deductible names.
    let toReturn = '';

    if (publicID === CONSTANTS.COVERAGE_NAMES.HO_PACKAGE) {
        toReturn = translator(messages.policyDeductible);
    } else if (publicID === CONSTANTS.COVERAGE_NAMES.HO_EARTHQUAKE) {
        toReturn = translator(messages.earthquakeDeductible);
    } else if (publicID === CONSTANTS.COVERAGE_NAMES.HO_HOMESYSTEMSPROTECTION) {
        toReturn = translator(messages.homeProtectionDeductible);
    } else if (publicID === CONSTANTS.COVERAGE_NAMES.HO_IDENTITYFRAUDEXPENSE) {
        toReturn = translator(messages.identityFraudDeductible);
    }

    return toReturn;
}

const createDataForDeductibleTable = (submissionVM, messages, translator) => {
    const baseCoverages = _.get(submissionVM,
        'lobData.homeowners.offerings.value[0].coverages.baseCoverages', []);

    const coveragesForTable = baseCoverages.filter((coverage) => {
        return coverage.name === CONSTANTS_COV.PACKAGE;
    });

    return coveragesForTable.map((coverage) => {
        const publicID = _.get(coverage, 'terms[0].coveragePublicID');
        const description = getDeductibleNameForCoverageByPublicID(publicID, messages, translator);
        const amount = _.get(coverage, 'terms[0].chosenTermValue');

        return {
            description: description,
            amount: amount
        };
    });
};

const createDataForSpecialLimitOfLiabilitiesCoveragesTable = (submissionVM, messages, translator) => {
    const cleanVal = (v) => v.includes('_wmic') ? v.replace('_wmic', '') : v;
    const SPECIAL_LIMIT_COVERAGES = [
        {type: 'Jewelry', orderPriority: 10, displayName: translator(messages.jewelryWatcherAndFurs)},
        {type: 'Money_wmic', orderPriority: 20, displayName: translator(messages.money)},
        {type: 'Firearms_wmic', orderPriority: 30, displayName: translator(messages.firearms)},
        {type: 'Electronics_wmic', orderPriority: 40, displayName: translator(messages.portableElectronicEquipment)},
        {type: 'Silverware3_wmic', orderPriority: 50, displayName: translator(messages.silverwareGoldwarePewterwareAndPlatinumware)},
        {type: 'Securities_wmic', orderPriority: 60, displayName: translator(messages.securities)}
    ];
    const specialLimitOfLiabilitiesCoverages = _.get(submissionVM, 'lobData.homeowners.offerings.value[0].coverages.specialLimitOfLiabilities', []);
    specialLimitOfLiabilitiesCoverages.sort((a, b) => (_.find(SPECIAL_LIMIT_COVERAGES, {type: a.type})?.orderPriority > _.find(SPECIAL_LIMIT_COVERAGES, {type: b.type})?.orderPriority) ? 1 : -1);

    return specialLimitOfLiabilitiesCoverages.map((cov) => {
        return {
            description: _.find(SPECIAL_LIMIT_COVERAGES, {type: cov.type})?.displayName,
            limit: cleanVal(cov.additionalLimit),
            premium: cov.premium?.amount
        };
    });
};

const getAdditionalCoverages = (submissionVM, messages, translator) => {    
    const isOR = _.get(submissionVM, 'baseData.policyAddress.state.value.code') === CONSTANTS.JURISDICTIONS.OR;
    const ADDITIONAL_COVERAGES = [
        {type: 'HO_CreditCardCov', orderPriority: 10, displayName: translator(messages.creditCard)},
        {type: 'HO_EarthquakeCov', orderPriority: 20, displayName: translator(isOR ? messages.earthquake : messages.limitedEarthquake), limitDisplayName: translator(isOR ? messages.included : messages.earthquakeCoverageLimit)},
        {type: 'HO_HomeSystemsProtection', orderPriority: 30},
        {type: 'HO_IdentityFraudExpense', orderPriority: 40},
        {type: 'HO_IncrLmtBusPropertyCov', orderPriority: 50, displayName: translator(messages.increasedLimitsOnBusinessProperty)},
        {type: 'HO_PersonalPropertyReplacementCostSettlementCov', orderPriority: 60, limitDisplayName: translator(messages.included)},
        {type: 'HO_SpecialPersPropCov', orderPriority: 0, limitDisplayName: translator(messages.included)}
    ];
    let additionalCoverages = _.get(submissionVM, 'lobData.homeowners.offerings.value[0].coverages.additionalCoverages', []);
    additionalCoverages = additionalCoverages.filter((cov) => cov.selected);
    additionalCoverages.sort((a, b) => (_.find(ADDITIONAL_COVERAGES, {type: a.publicID})?.orderPriority > _.find(ADDITIONAL_COVERAGES, {type: b.publicID})?.orderPriority) ? 1 : -1);

    return additionalCoverages.map((cov) => {
        const covProps = _.find(ADDITIONAL_COVERAGES, {type: cov.publicID});
        return {
            description: covProps?.displayName || cov.description || cov.publicID,
            limit: covProps?.limitDisplayName || cov.terms[0]?.chosenTermValue,
            premium: cov.amount?.amount
        };
    });
};

const createDataForCoveragesTable = (submissionVM, messages, translator) => {
    const baseCoverages = _.get(submissionVM,
        'lobData.homeowners.offerings.value[0].coverages.baseCoverages', []);
    const basePremium = _.get(submissionVM,
        'lobData.homeowners.offerings.value[0].coverages.basePremium.amount');
    const baseState = _.get(submissionVM,
        'baseData.policyAddress.state.value');

    const coveragesForTable = baseCoverages.filter((coverage) => {
        return coverage.name !== CONSTANTS_COV.PACKAGE;
    });

    coveragesForTable.forEach((elem) => {
        switch (elem.name) {
            case CONSTANTS_COV.PERSONAL_PROPERTY:
                elem.OrderPriority = 10;
                break;
            case  CONSTANTS_COV.ADDL_LIVING_EXPENSES:
                elem.OrderPriority = 20;
                break;
            case  CONSTANTS_COV.PERSONAL_LIABILITY:
                elem.OrderPriority = 30;
                break;
            case  CONSTANTS_COV.MEDICAL_PAYMENTS:
                elem.OrderPriority = 40;
                break;
            default:
                elem.OrderPriority = 50;
        }
    });

    coveragesForTable.sort((a, b) => (a.OrderPriority > b.OrderPriority) ? 1 : -1);

    const baseCoveragesResult =  coveragesForTable.map((coverage) => {
        let description = coverage.name;
        let limit = '';
        let premium = '';
        const termName = _.get(coverage, 'terms[0].name');
        const patternCode = _.get(coverage, 'terms[0].patternCode');
        const coverageAmount = _.get(coverage, 'amount.amount');

        if (coverage.name === CONSTANTS_COV.EARTHQUAKE
            && baseState === CONSTANTS.JURISDICTIONS.CA) {
            description = translator(messages.limitedEarthquake);
        }

        if (termName === CONSTANTS_COV.LIMIT) {
            limit = _.get(coverage, 'terms[0].chosenTermValue');
        } else if (coverage.publicID === CONSTANTS.COVERAGE_NAMES
            .HO_PERSONALPROPERTYREPLACEMENTCOSTSETTLEMENT
            || coverage.publicID === CONSTANTS_COV.SPECIAL_PERS_PROP_COV
            || (termName !== CONSTANTS_COV.LIMIT && patternCode === CONSTANTS_COV.EQ_DED_OR)) {
            limit = translator(messages.included);
        } else if (termName !== CONSTANTS_COV.LIMIT
            && coverage.publicID === CONSTANTS.COVERAGE_NAMES.HO_EARTHQUAKE
            && patternCode !== CONSTANTS_COV.EQ_DED_OR) {
            limit = translator(messages.earthquakeCoverageLimit);
        } else if (coverage.name === CONSTANTS_COV.ADDL_LIVING_EXPENSES
            && coverage.terms.length === 0) {
            limit = translator(messages.actualLoss);
        }

        if (coverage.amount === undefined
            && coverage.publicID === CONSTANTS.COVERAGE_NAMES.HO_PERSONALPROPERTY) {
            premium = basePremium;
        } else if ((coverage.amount === undefined
                && coverage.publicID !== CONSTANTS.COVERAGE_NAMES.HO_PERSONALPROPERTY)
            || coverageAmount === CONSTANTS_COV.ZERO
            || (coverage.terms.length === 0 && coverage.publicID
                    !== CONSTANTS.COVERAGE_NAMES.HO_PERSONALPROPERTYREPLACEMENTCOSTSETTLEMENT
                && coverage.publicID !== CONSTANTS_COV.SPECIAL_PERS_PROP_COV)) {
            premium = translator(messages.included);
        } else if (coverage.amount !== undefined && coverage.amount.amount !== CONSTANTS_COV.ZERO
        && (termName === CONSTANTS_COV.LIMIT
            || termName === CONSTANTS_COV.PP_DEDUCTIBLE_PERCENT
            || patternCode === CONSTANTS_COV.RENTER_EQ_DED)) {
            premium = coverageAmount;
        }

        return {
            description: description,
            limit: limit,
            premium: premium
        };
    });

    return baseCoveragesResult.concat(getAdditionalCoverages(submissionVM, messages, translator));
};

const resetPayerData = (payerPerson, aSubmissionVM, VMService) => {
    const checkingAccount = _.get(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.bankAccountType.aspects.availableValues[0]');
    if (payerPerson === CONSTANTS.PRIMARY_NAMED_INSURED_CAMEL_CASE) {
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.publicID.value', _.get(aSubmissionVM, 'baseData.accountHolder.publicID.value'));
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.firstName_WMIC.value', _.get(aSubmissionVM, 'baseData.accountHolder.firstName.value'));
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.lastName_WMIC.value', _.get(aSubmissionVM, 'baseData.accountHolder.lastName.value'));
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.email_WMIC.value', _.get(aSubmissionVM, 'baseData.accountHolder.emailAddress1.value'));
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.address_WMIC.value', _.get(aSubmissionVM, 'bindData.billingAddress.value'));
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.phoneNumber_WMIC.value', _.get(aSubmissionVM, 'bindData.contactPhone.value'));
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.bankABANumber.value', null);
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.bankAccountNumber.value', null);
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.bankAccountType.value', checkingAccount);
        _.set(aSubmissionVM, 'bindData.payerEmail_WMIC.value', _.get(aSubmissionVM, 'bindData.contactEmail.value'));
    } else if (payerPerson === CONSTANTS.ADD_NAMED_INSURED_CAMEL_CASE) {
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.publicID.value', _.get(aSubmissionVM, 'baseData.aniperson_WMIC.publicID.value'));
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.firstName_WMIC.value', _.get(aSubmissionVM, 'baseData.aniperson_WMIC.firstName.value'));
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.lastName_WMIC.value', _.get(aSubmissionVM, 'baseData.aniperson_WMIC.lastName.value'));
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.email_WMIC.value', _.get(aSubmissionVM, 'baseData.addlNamedInsuredEmailAddress1.value'));
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.address_WMIC.value', _.get(aSubmissionVM, 'baseData.addlNamedInsuredAddress.value') ? 
            _.get(aSubmissionVM, 'baseData.addlNamedInsuredAddress.value') : _.get(aSubmissionVM, 'bindData.billingAddress.value'));
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.phoneNumber_WMIC.value', _.get(aSubmissionVM, 'bindData.addlNamedInsuredPhone.value') ? 
            _.get(aSubmissionVM, 'bindData.addlNamedInsuredPhone.value') : _.get(aSubmissionVM, 'bindData.contactPhone.value'));
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.bankABANumber.value', null);
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.bankAccountNumber.value', null);
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.bankAccountType.value', checkingAccount);
        _.set(aSubmissionVM, 'bindData.addlNamedInsuredPhone.value', _.get(aSubmissionVM, 'baseData.aniperson_WMIC.homeNumber.value') ? 
            _.get(aSubmissionVM, 'baseData.aniperson_WMIC.homeNumber.value') : _.get(aSubmissionVM, 'bindData.contactPhone.value'));
        _.set(aSubmissionVM, 'bindData.payerIsANI_WMIC.value', true);
        _.set(aSubmissionVM, 'bindData.payerEmail_WMIC.value', _.get(aSubmissionVM, 'baseData.addlNamedInsuredEmailAddress1.value'));
    } else if (payerPerson === CONSTANTS.DAP_PERSON_CAMEL_CASE) {
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.publicID.value', _.get(aSubmissionVM, 'bindData.dapPerson_WMIC.publicID.value'));
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.firstName_WMIC.value', _.get(aSubmissionVM, 'bindData.dapPerson_WMIC.firstName.value'));
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.lastName_WMIC.value', _.get(aSubmissionVM, 'bindData.dapPerson_WMIC.lastName.value'));
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.email_WMIC.value', _.get(aSubmissionVM, 'bindData.dapPersonEmailAddress_WMIC.value'));
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.address_WMIC.value', _.get(aSubmissionVM, 'bindData.dapAddress_WMIC.value'));
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.phoneNumber_WMIC.value', _.get(aSubmissionVM, 'bindData.dapPerson_WMIC.contactPhone.value'));
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.bankABANumber.value', null);
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.bankAccountNumber.value', null);
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.bankAccountType.value', checkingAccount);
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.bankAccountType.value', checkingAccount);
        _.set(aSubmissionVM, 'bindData.payerEmail_WMIC.value', _.get(aSubmissionVM, 'bindData.dapPerson_WMIC.emailAddress1.value'));
    } else {
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.publicID.value', null);
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.firstName_WMIC.value', null);
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.lastName_WMIC.value', null);
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.email_WMIC.value', null);
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.phoneNumber_WMIC.value', null);
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.bankABANumber.value', null);
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.bankAccountNumber.value', null);
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.address_WMIC.value', {
            country: 'US'
        });
        _.set(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.bankAccountType.value', checkingAccount);
        _.set(aSubmissionVM, 'bindData.payerEmail_WMIC.value', null);
    }
    _.set(aSubmissionVM,
        'bindData.payerFullName_WMIC.value',
        `${_.get(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.firstName_WMIC.value')} ${_.get(aSubmissionVM, 'bindData.paymentDetails.bankAccountData.lastName_WMIC.value')}`);

    const newSubmissionVM = VMService.clone(aSubmissionVM);
    return newSubmissionVM;
};

export default {
    createDataForCoveragesTable,
    createDataForSpecialLimitOfLiabilitiesCoveragesTable,
    createDataForDeductibleTable,
    CONSTANTS_COV,
    resetPayerData
};