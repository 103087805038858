import React, { useContext, useCallback, useState, useEffect, useRef, useMemo } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { CurrencyValue } from '@jutro/components';
import { WMICUserAccessUtil } from 'wmic-pe-portals-utils-js';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { WMICScrollToError } from 'wmic-pe-components-platform-react';
import { WMICWizardChangeOrRenewalPage, useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { WMICHOPolicyCoverages, WMICHODwellingCoverages, WMICHOWatercraftCoverages } from 'wmic-pe-capability-gateway-common-ho-react';
import WMICHOCoverageUtil from 'wmic-pe-capability-gateway-common-ho-react/utils/WMICHOCoverageUtil';
import { messages as commonMessages } from 'wmic-pe-capability-gateway-policyrenewal-common-react';
import metadata from './WMICHOPolicyRenewalCoveragesPage.metadata.json5';
import messages from './WMICHOPolicyRenewalCoveragesPage.messages';

const DWELLINGS_PATH = 'lobData.homeowners.coverables.dwellings';
const WATERCRAFT_PATH = 'lobData.homeowners.coverables.watercrafts';

const structureCustomQuote = (policyRenewalVM, affectedQuote, clauses) =>
    // convert OfferingDTO to CustomQuotedDTO structure
     ({
        quote: affectedQuote,
        quoteID: policyRenewalVM.jobID.value,
        sessionUUID: policyRenewalVM.sessionUUID.value,
        periodStart: policyRenewalVM.baseData.periodStartDate.value,
        periodEnd: policyRenewalVM.baseData.periodEndDate.value,
        coverages: clauses.homeowners
    })
;

const getCustomQuote = (vm, lobPath, quotePath, lobName, filterChangedClauses = false) => {
    const lobOffering = _.get(vm, `${lobPath}.value`);
    const quoteOffering = _.get(vm, `${quotePath}.value`);

    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
        clausesToUpdate = ClausesUtil.structureClausesForServer(
            lobOffering.coverages,
            lobName,
            null
        );
    }

    return structureCustomQuote(vm, quoteOffering, clausesToUpdate);
};

const generateColumnData = (policyRenewalVM) => {
    const lobOfferingPath = 'lobData.homeowners.offerings';
    const quoteOfferingPath = 'quoteData.offeredQuotes';

    const lobOfferings = _.get(policyRenewalVM, `${lobOfferingPath}.value`);
    const quoteOfferings = _.get(policyRenewalVM, `${quoteOfferingPath}.value`) || [];

    return lobOfferings.map((lobOffering, lobIndex) => {
        const quoteDataIndex = quoteOfferings.findIndex(
            (qdOffering) => qdOffering.branchCode === lobOffering.branchCode
        );
        const quoteData = quoteOfferings[quoteDataIndex];
        return {
            name: lobOffering.branchName,
            code: lobOffering.branchCode,
            quote: {
                path: `${quoteOfferingPath}.children[${quoteDataIndex}]`,
                data: quoteData
            },
            lob: {
                path: `${lobOfferingPath}.children[${lobIndex}]`,
                data: lobOffering
            }
        };
    }).filter(({ quote }) => !_.isUndefined(quote.data));
};

const getSelectedRiskId = (policyRenewalVM, basePath) => {
    const riskPath = basePath.substring(0, basePath.indexOf('.coverages.children['));
    const riskVM = _.get(policyRenewalVM, `${riskPath}.value`);
    return _.get(riskVM, 'publicID');
}

function WMICHOPolicyRenewalCoveragesPage(props) {
    const [staleQuoteBranchCode, setStaleQuoteBranchCode] = useState(undefined);
    const [loadingClause, updateLoadingClause] = useState();
    const translator = useContext(TranslatorContext);
    const { RenewalService } = useDependencies('RenewalService');
    const {
        wizardData: policyRenewalVM,
        updateWizardData,
        underwritingIssues
    } = props;
    const { authUserData: currentUser, authHeader } = useAuthentication();

    const { onValidate, disregardFieldValidation, registerComponentValidation, initialValidation, isComponentValid } = useValidation('CoveragesPage');
    const viewModelService = useContext(ViewModelServiceContext);
    const { CustomQuoteService } = useDependencies('CustomQuoteService');
    const { setWizardLoading } = useWizardModals();
    const [initialPolicyRenewalVM, setInitialPolicyRenewalVM] = useState(undefined);
    const [showErrors, setShowErrors] = useState(false);
    const [scrollToError, setScrollToError] = useState();
    const initialized = useRef(false);

    const canEditPolicyRenewal = useMemo(
        () => WMICUserAccessUtil.canEditPolicyRenewal(currentUser.roles),
        [currentUser]
    );

    useEffect(() => {
        setInitialPolicyRenewalVM(viewModelService.clone(policyRenewalVM));
        initialized.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const writeValue = (value, path) => {
        _.set(policyRenewalVM, path, value);
        updateWizardData(policyRenewalVM);
    }

    const onUpdateCustomQuote = (_basePath, lobPath, quotePath) => {
        const lobName = ClausesUtil.getLobNameFromPath(lobPath);
        const customQuote = getCustomQuote(policyRenewalVM, lobPath, quotePath, lobName, true);
        return RenewalService.updateCoveragesAndRenewalData(
            [],
            { homeowners: customQuote.coverages },
            policyRenewalVM.value,
            authHeader
        ).then((response) => {
            const riskID = getSelectedRiskId(policyRenewalVM, _basePath);
            const dwellingCoverageIndex = _.get(policyRenewalVM, `${lobPath}.coverages.dwellingCoverages.value`).findIndex((dwellingCoverage) => dwellingCoverage.publicID === riskID);
            const watercraftCoverageIndex = _.get(policyRenewalVM, `${lobPath}.coverages.watercraftCoverages.value`).findIndex((watercraftCoverage) => watercraftCoverage.publicID === riskID);
            const newPolicyRenewalVM = viewModelService.clone(policyRenewalVM);
            newPolicyRenewalVM.value = response;

            const removedFieldsFromLineCoverages = ClausesUtil.getRemovedClausesID(
                policyRenewalVM,
                newPolicyRenewalVM,
                `${lobPath}.coverages.lineCoverages`
            );
            const removedFieldsFromDwellingCoverages = dwellingCoverageIndex >= 0 ? ClausesUtil.getRemovedClausesID(
                policyRenewalVM,
                newPolicyRenewalVM,
                `${lobPath}.coverages.dwellingCoverages.children[${dwellingCoverageIndex}].coverages`
            ): [];
            const removedFieldsFromWatercraftCoverages = watercraftCoverageIndex >= 0 ?
                ClausesUtil.getRemovedClausesID(
                    policyRenewalVM,
                    newPolicyRenewalVM,
                    `${lobPath}.coverages.watercraftCoverages.children[${watercraftCoverageIndex}].coverages`
                ): []

            const allRemovedFields = [
                ...removedFieldsFromLineCoverages,
                ...removedFieldsFromDwellingCoverages,
                ...removedFieldsFromWatercraftCoverages
            ];
            disregardFieldValidation(allRemovedFields);
            updateWizardData(newPolicyRenewalVM);
            updateLoadingClause(undefined);
        });
    };

    const changeSubmissionAndSync = (value, changedPath, lobPath, quotePath) => {
        changeSubmission(value, changedPath);
        return syncCoverages(value, changedPath, lobPath, quotePath);
    }

    const onNext = useCallback(async () => {
        try {
            if (!isComponentValid) {
                setShowErrors(true);
                setScrollToError(Date.now());
                return false;
            }

            setWizardLoading(true, translator(commonMessages.savingTransactionDetails));
            const newRenewal = _.cloneDeep(policyRenewalVM.value);
            policyRenewalVM.value = await RenewalService.saveRenewal([newRenewal], authHeader);

            return policyRenewalVM;
        } finally {
            setWizardLoading(false);
        }
    }, [RenewalService, authHeader, isComponentValid, policyRenewalVM, setWizardLoading, translator]);

    const convertCurrencyFormat = (basePremium) => {
        if (basePremium) {
            return (
                <div>
                    <span>{translator(messages.mainCoverages)}</span>
                    <span> : </span>
                    <CurrencyValue amount={basePremium.amount} currency={basePremium.currency} showFractions />
                </div>
            );
        }

        return (
            <div>
                <span>{translator(messages.mainCoverages)}</span>
            </div>
        )
    };
    const columnData = generateColumnData(policyRenewalVM);

    const syncCoverages = (value, changedPath, lobPath, quotePath) => {
        const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
        return onUpdateCustomQuote(basePath, lobPath, quotePath);
    }

    const onScheduleChange = (schedule, path) => {
        const lobOfferingPath = 'lobData.homeowners.offerings.children[0]';
        const quoteOfferingPath = 'quoteData.offeredQuotes.children[0]';
        writeValue(schedule, `${path}.value`);
        const offering = _.get(policyRenewalVM, `${lobOfferingPath}.value`);
        setStaleQuoteBranchCode(offering.branchCode);
        return onUpdateCustomQuote({}, lobOfferingPath, quoteOfferingPath);
    }

    const changeSubmission = (value, changedPath) => {
        updateWizardData(ClausesUtil.setClauseValue(policyRenewalVM, value, changedPath));
    }

    const recalculate = (lobPath, quotePath) => {
        const lobName = ClausesUtil.getLobNameFromPath(lobPath);
        const customQuote = getCustomQuote(policyRenewalVM, lobPath, quotePath, lobName);

        return CustomQuoteService.updateCustomQuoteAndQuoteData(customQuote, authHeader).then(
            (response) => {
                setStaleQuoteBranchCode(undefined);
                const newPolicyRenewalVM = viewModelService.clone(policyRenewalVM);
                newPolicyRenewalVM.value = response;
                updateWizardData(newPolicyRenewalVM);
                return response;
            }
        );
    }

    const resetQuote = (lobPath, quotePath) => {
        const lobName = ClausesUtil.getLobNameFromPath(lobPath);
        const customQuote = getCustomQuote(initialPolicyRenewalVM, lobPath, quotePath, lobName);

        return CustomQuoteService.forceUpdateCustomQuoteCoveragesAndQuoteData(customQuote, initialPolicyRenewalVM.value, authHeader).then(
            (response) => {
                const newPolicyRenewalVM = viewModelService.clone(policyRenewalVM);
                newPolicyRenewalVM.value = response;
                updateWizardData(newPolicyRenewalVM);
            }
        );
    }

    const onStaleQuoteBranchCode = useCallback(() => staleQuoteBranchCode, [staleQuoteBranchCode]);

    const commonOverrides = {
        'coverageColumnData': columnData,
        'quoteID': policyRenewalVM.jobID.value,
        'changeSubmissionAndSync': changeSubmissionAndSync,
        'changeSubmission': changeSubmission,
        'syncCoverages': syncCoverages,
        'recalculate': recalculate,
        'resetQuote': resetQuote,
        'onStaleQuoteBranchCode': onStaleQuoteBranchCode,
        'underwritingIssues': underwritingIssues,
        'onValidate': onValidate,
        'readOnly': !canEditPolicyRenewal
    }

    const _iterableProps = () => {
        const dwellingOverrides = _.get(policyRenewalVM, `${DWELLINGS_PATH}.children`).map((dwelling, index) => {
            const dwellingCoverages = WMICHOCoverageUtil.getDwellingCoveragesForRisk(_.get(policyRenewalVM, 'lobData.homeowners'), dwelling.publicID.value);
            const dwellingOverride = {
                [`dwellingCoveragesIterableComponent${index}`]: {
                    'dwellingVM': dwelling,
                    'dwellingCoverages': dwellingCoverages,
                    'dwellingIndex': index,
                    'baseData': policyRenewalVM.baseData,
                    jobVM: policyRenewalVM,
                    ...commonOverrides
                }
            }

            return Object.assign({}, dwellingOverride);
        })

        const watercraftOverrides = _.get(policyRenewalVM, `${WATERCRAFT_PATH}.children`).map((watercraft, index) => {
            const watercraftCoverages = WMICHOCoverageUtil.getWatercraftCoveragesForRisk(_.get(policyRenewalVM, 'lobData.homeowners'), watercraft.publicID.value);
            const watercraftOverride = {
                [`watercraftCoveragesIterableComponent${index}`]: {
                    'watercraftVM': watercraft,
                    'watercraftCoverages': watercraftCoverages,
                    'watercraftIndex': index,
                    ...commonOverrides
                }
            }
            return Object.assign({}, watercraftOverride);
        })

        return Object.assign({}, ...dwellingOverrides, ...watercraftOverrides);
    }

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            phoneWide: {
                labelPosition: 'top'
            }
        },
        dwellingCoveragesIterableAccordionContainer: {
            data: _.get(policyRenewalVM, `${DWELLINGS_PATH}.children`)
        },
        watercraftCoveragesIterableAccordionContainer: {
            visible: (_.get(policyRenewalVM, `${WATERCRAFT_PATH}.children`, []).length > 0),
            data: _.get(policyRenewalVM, `${WATERCRAFT_PATH}.children`)
        },
        hoPolicyRenewalBaseCoveragesId: {
            loadingClause,
            categoryDisplayName: convertCurrencyFormat(
                _.get(policyRenewalVM.value, 'lobData.homeowners.offerings[0].coverages.basePremium')
            )
        },
        hoPolicyRenewalAdditionalCoveragesId: {
            loadingClause,
            categoryDisplayName: translator(messages.additionalCoverages)
        },
        policyCoveragesContainer: {
            policyRenewalVM,
            lineCoverages: _.get(policyRenewalVM, 'lobData.homeowners.offerings.children[0].coverages.lineCoverages'),
            ...commonOverrides
        },
        ..._iterableProps()
    };

    const resolvers = {
        resolveCallbackMap: {
            onRecalculate: recalculate,
            onResetQuote: resetQuote,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onSyncCoverages: syncCoverages,
            onScheduleChange,
            onStaleQuoteBranchCode,
            onChangeClause: changeSubmission,
            onPrint: window.print,
            onValidate
        },
        resolveComponentMap: {
            WMICHODwellingCoverages,
            WMICHOPolicyCoverages,
            WMICHOWatercraftCoverages
        }

    };
    const isPageLoading = useCallback(() => _.isUndefined(loadingClause), [loadingClause]);

    useEffect(() => {
        registerComponentValidation(isPageLoading);
    }, [isPageLoading, registerComponentValidation]);

    return (
        <WMICWizardChangeOrRenewalPage
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
            isSkipping={initialValidation}
        >
            <WMICScrollToError counter={scrollToError}/>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyRenewalVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICHOPolicyRenewalCoveragesPage.propTypes = wizardProps;

export default withAuthenticationContext(WMICHOPolicyRenewalCoveragesPage);
