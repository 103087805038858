/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm , useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { CONSTANTS } from 'wmic-pe-portals-utils-js';
import { WMICAddressDetails } from 'wmic-pe-components-platform-react';
import WMICPAPolicyAIDetailView from './WMICPAPolicyAIDetailView/WMICPAPolicyAIDetailView';

import metadata from './WMICPAPolicyAdditionalInterests.metadata.json5';
import messages from './WMICPAPolicyAdditionalInterests.messages'


function WMICPAPolicyAdditionalInterests(props) {
    const {
        vehicleVM,
    } = props;

    const translator = useContext(TranslatorContext);
    const { refreshData } = useDataRefresh();
    const [additionalInterestVM, setAdditionalInterestVM] = useState({ policyAdditionalInterest: {} });
    
    const getExistingAIDisplayName = (existingAI) => {
        const additionalInterestSubType = existingAI.policyAdditionalInterest;
        if (additionalInterestSubType.subtype.value === CONSTANTS.Person
            && (additionalInterestSubType.firstName.value
            || additionalInterestSubType.lastName.value)) {
            return `${additionalInterestSubType.firstName.value} ${additionalInterestSubType.lastName.value}`;
        }
        if (additionalInterestSubType.subtype.value === CONSTANTS.Company
            && additionalInterestSubType.contactName.value) {
            return additionalInterestSubType.contactName.value;
        }
        return '';
    }

    const viewReadOnlyAdditionalInterest = (ai) => {
        setAdditionalInterestVM(ai);
        refreshData();
    }

    const overrideProps = {
        '@field': {
            parentNode: additionalInterestVM,
            readOnly: true
        },
        policyAdditionalInterestListView: {
            value: _.get(vehicleVM, `vehicleAdditionalInterests_WMIC.children`, []),
            clickable: true,
            startOpen: false,
            readOnly:true,
            detailViewComponent: WMICPAPolicyAIDetailView,
            onClickAction: viewReadOnlyAdditionalInterest,
            VMData: [
                {
                    headerText: translator(messages.policyAdditionalInterestName),
                    getData: getExistingAIDisplayName
                },
                {
                    headerText: translator(messages.policyAdditionalInterestType),
                    path: 'additionalInterestType'
                },
                {
                    headerText: translator(messages.policyCertificateRequired),
                    path: 'certificateRequired'
                }
            ],
            detailViewComponentProps: {
                additionalInterestVM
            },          
        },
    };

    const resolvers = {
        resolveComponentMap: {
            WMICAddressDetails
        },
    };

    return (
        <ViewModelForm
            model={vehicleVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
        />

    );
}


export default WMICPAPolicyAdditionalInterests;
