import React, {
    useCallback,
    useState,
    useEffect
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import cx from 'classnames';
import appConfig from 'app-config';
import WMICDotCmsService from "wmic-pe-capability-gateway/services/WMICDotCmsService";
import styles from './trainingMaterials.module.scss';
import metadata from './trainingMaterials.metadata.json5';
import ListIterableComponent from '../ListIterableComponent/ListIterableComponent';

function TrainingMaterialsTab() {
    const { serviceUrls } = appConfig;
    const [trainingMaterialsContent, setTrainingMaterialsContent] = useState([]);

    const isFrenchLocale = localStorage.getItem('selectedLocale') === 'fr-CA'
    const serviceUrl = isFrenchLocale? serviceUrls.dotcmstraining_fr :serviceUrls.dotcmstraining_en;

    useEffect(() => {
        WMICDotCmsService.getDotcmsContent(serviceUrl).then(data => {setTrainingMaterialsContent(data)} )
    },[serviceUrl])
    
    const getTrainingMaterialContentData = useCallback(() => {
        if (trainingMaterialsContent.length > 0) {
            const category = isFrenchLocale ? 'frenchCategory' : 'category'
            const groupedCategoryArray = _.groupBy(_.orderBy(trainingMaterialsContent, category), category);
            const iterableArray = Object.values(groupedCategoryArray);

            return iterableArray;
        }

        return [];
    }, [trainingMaterialsContent]);
 
    const getTrainingmaterialOverrides = useCallback(() => {
        const getVisibilityForSection = (dataArray) => {
            return _.every(dataArray, (data) => {
                return (data.path && data.fileName) || (data.videoUrl && data.name);
            });
        };
        // const overrides = [];
        const iterableData = getTrainingMaterialContentData();
        if (iterableData.length > 0){
            const overrides = iterableData.map(
                (data, index) => {
                    return {
                        [`listHeader${index}`]: {
                            title: isFrenchLocale ? data[0].frenchCategory : data[0].category,
                            visible: getVisibilityForSection(data)
                        },
                        [`listIterableComponentId${index}`]: {
                            contentData: data,
                            visible: getVisibilityForSection(data)
                        }
                    };
                }
            );
            return Object.assign({}, ...overrides);
        }
    }, [isFrenchLocale, getTrainingMaterialContentData]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            listiterablecomponent: ListIterableComponent
        }
    };
    const containerClass = cx('wmicSelectAllLinksModal', styles.trainingMaterial);

    const override = {
        trainingmaterialContentContainer: {
            data: trainingMaterialsContent
        },
        ...getTrainingmaterialOverrides(),
        
    };

    return (
        <div  className={containerClass} >
            <div
                contentLayout={{
                    component: 'grid'
                }}
            >
                <div>
                    <ViewModelForm
                        uiProps={metadata.componentContent}
                        overrideProps={override}
                        classNameMap={resolvers.resolveClassNameMap}
                        componentMap={resolvers.resolveComponentMap}
                    />
                </div>
            </div>
        </div>
    );
}


export default TrainingMaterialsTab;
