import { defineMessages } from 'react-intl';

export default defineMessages({
    claimInformation: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.claimInformation',
        defaultMessage: 'Claim Information'
    },
    reportDate: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.reportDate',
        defaultMessage: 'Report Date'
    },
    lossDate: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.lossDate',
        defaultMessage: 'Date of Loss'
    },
    claimNumber: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.claimNumber',
        defaultMessage: 'Claim Number'
    },
    claimStatus: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.claimStatus',
        defaultMessage: 'Claim Status'
    },
    manualLossType: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.manualLossType',
        defaultMessage: 'Type of Loss'
    },
    claimDescription: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.claimDescription',
        defaultMessage: 'Claim Description'
    },
    catastropheIndicatorType: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.catastropheIndicatorType',
        defaultMessage: 'Catastrophe Indicator'
    },
    hitAndRun: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.hitAndRun',
        defaultMessage: 'Hit and Run?'
    },
    policyInformation: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.policyInformation',
        defaultMessage: 'Policy Information'
    },
    insuranceCompany: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.insuranceCompany',
        defaultMessage: 'Insurance Company'
    },
    vehicleOperator: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.vehicleOperator',
        defaultMessage: 'Vehicle Operator'
    },
    vehicleOperatorName: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.vehicleOperatorName',
        defaultMessage: 'Name'
    },
    atFault: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.atFault',
        defaultMessage: 'At Fault'
    },
    atFaultPercent: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.atFaultPercent',
        defaultMessage: '% at Fault'
    },
    lossVehicle: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.lossVehicle',
        defaultMessage: 'Loss Vehicle'
    },
    vehiclePublicID: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.vehiclePublicID',
        defaultMessage: 'Vehicle'
    },
    vehicleBusinessSegment: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.vehicleBusinessSegment',
        defaultMessage: 'Business Segment'
    },
    vehicleType: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.vehicleType',
        defaultMessage: 'Vehicle Type'
    },
    vehicleYear: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.vehicleYear',
        defaultMessage: 'Year'
    },
    vehicleMake: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.vehicleMake',
        defaultMessage: 'Make'
    },
    vehicleModel: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.vehicleModel',
        defaultMessage: 'Model'
    },
    vehicleVIN: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.vehicleVIN',
        defaultMessage: 'VIN'
    },
    vehicleStatus: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.vehicleStatus',
        defaultMessage: 'Vehicle Status'
    },
    payoutReservesInformation: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.payoutReservesInformation',
        defaultMessage: 'Payout Reserves Information'
    },
    payoutStatus: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.payoutStatus',
        defaultMessage: 'Status'
    },
    causeOfLoss: { 
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.causeOfLoss',
        defaultMessage: 'Cause of Loss'
    },
    claimOpenReserves: { 
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.claimOpenReserves',
        defaultMessage: 'Remaining Reserves'
    },
    lossTotalPaid: { 
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.lossTotalPaid',
        defaultMessage: 'Paid Amount'
    },
    removePayoutMsg: { 
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.removePayoutMsg',
        defaultMessage: 'Are you sure you want to remove this payout/reserve?'
    },
    removePayoutTitle: { 
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.removePayoutTitle',
        defaultMessage: 'Remove payout/reserve?'
    },
    no: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.no',
        defaultMessage: 'No'
    },
    yes: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.yes',
        defaultMessage: 'Yes'
    },
    reportedDriver: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.add-history-loss.reportedDriver',
        defaultMessage: 'Reported Driver'
    },
});