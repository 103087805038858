import React, { useContext } from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';

const wmicRetrieveInfoContext = React.createContext(null);

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function withWMICRetrieveInfoContext(WrappedComponent) {
    function WithWMICRetrieveInfoContext(props) {
        const currentContext = useContext(wmicRetrieveInfoContext);
        return <WrappedComponent {...currentContext} {...props} />;
    }
    hoistNonReactStatic(WithWMICRetrieveInfoContext, WrappedComponent);
    WithWMICRetrieveInfoContext.displayName = `WithWMICRetrieveInfoContext(${getDisplayName(WrappedComponent)})`;
    return WithWMICRetrieveInfoContext;
}

export const WMICRetrieveInfoContext = wmicRetrieveInfoContext;