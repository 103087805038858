import { get, set } from 'lodash';

const globalSpace = 'wmic';

export function getGlobal(name, defaultValue=undefined) {
    return get(window, `${globalSpace}.${name}`, defaultValue);
}

export function setGlobal(name, value) {
    set(window, `${globalSpace}.${name}`, value);
}