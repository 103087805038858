import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { WatercraftContext } from 'wmic-pe-capability-gateway-quoteandbind-ho-react';
import _ from 'lodash';
import WMICHOEquipmentDetails from '../WMICHOEquipmentDetails/WMICHOEquipmentDetails';
import WMICHOBoatInfoDetails from "../WMICHOBoatInfoDetails/WMICHOBoatInfoDetails";
import metadata from './WMICHOBoatDetails.metadata.json5';
import messages from './WMICHOBoatDetails.messages';

function WMICHOBoatDetails({
    id,
    boatData,
    onValidate,
    showErrors,
    readOnly,
    boatTypeReadOnly,
    baseData
}) {
    const {
        onValidate: setComponentValidation,
        isComponentValid,
    } = useValidation(id);
    const { updateWatercraftRiskView } = useContext(WatercraftContext);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
        
        // if component is unmounted, report as valid so it doesn't break the rest of the form
        return () => {
            if (onValidate) {
                onValidate(true, id);
            }
        }
    }, [id, onValidate, isComponentValid]);

    const sharedDetailsProps = {
        onValidate: setComponentValidation,
        showErrors,
        readOnly
    }

    const overrideProps = {
        '@field': {
            readOnly,
            parentNode: boatData,
        },
        wmichoBoatInfoDetails: {
            boatData: _.get(boatData, 'equipmentInfo.boatInfo', {}),
            baseData,
            boatTypeReadOnly,
            ...sharedDetailsProps
        },
        wmichoEquipmentDetails: {
            watercraftComponentVM: boatData,
            baseData,
            ...sharedDetailsProps
        },
    };

    const resolvers = {
        resolveComponentMap: {
            WMICHOBoatInfoDetails,
            WMICHOEquipmentDetails,
        },
    };

    return (
        <ViewModelForm
            model={boatData}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            onModelChange={updateWatercraftRiskView}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

WMICHOBoatDetails.propTypes = {
    boatData: PropTypes.shape({}).isRequired,
    readOnly: PropTypes.bool,
};

WMICHOBoatDetails.defaultProps = {
    readOnly: false,
};

export default WMICHOBoatDetails;
