import React, { useContext, useEffect, useState } from 'react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import { HOConstants, CONSTANTS, WMICRPCUtil } from 'wmic-pe-portals-utils-js';
import WMICHOConstructionHeatingSurvey from './WMICHOConstructionHeatingSurvey/WMICHOConstructionHeatingSurvey';

import metadata from './WMICHOConstructionHeatingDetails.metadata.json5';
import messages from './WMICHOConstructionHeatingDetails.messages';

function WMICHOConstructionHeatingDetails(props) {
    const { jobVM, riskView, fuelSourceVM, setFuelSourceVM, id, showErrors: pShowErrors,
        onValidate, readOnly } = props;

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const viewModelService = useContext(ViewModelServiceContext);
    const [showErrors, setShowErrors] = useState(pShowErrors);
    const [chimneySystemCerts, setChimneySystemCerts] = useState([]);

    const isRPC1671Effective = () => WMICRPCUtil.getIsRPCEffective(
        _.get(riskView, 'rating.ratingJurisdiction.value.code'),
        _.get(jobVM, 'baseData.rateAsOfDate.value'),
        '1671');

    useEffect(()=> {
        setShowErrors(pShowErrors);
    }, [pShowErrors]);

    useEffect(() => {
        const rawChimneyCertCodes = viewModelService.productMetadata.get('pc').types.getTypelist('ChimneyCertification_WMIC').codes;
        const chimneyCertCodes = rawChimneyCertCodes.map((cert) => {
            return {
                ...cert,
                name: {
                    id: cert.name,
                    defaultMessage: cert.name
                }
            }
        });
        setChimneySystemCerts(chimneyCertCodes);

    }, [jobVM]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => onValidate ? onValidate(true, id) : undefined;
    }, [isComponentValid, onValidate, id]);

    const setPrimarySurveyVM = (model) => {
        const newFuelSourceVM = viewModelService.clone(fuelSourceVM);
        _.set(newFuelSourceVM, 'fuelSurvey.primarySurveyDetail', model.value);
        setFuelSourceVM(newFuelSourceVM);
    }

    const setSecondarySurveyVM = (model) => {
        const newFuelSourceVM = viewModelService.clone(fuelSourceVM);
        _.set(newFuelSourceVM, 'fuelSurvey.secondarySurveyDetail', model.value);
        setFuelSourceVM(newFuelSourceVM);
    }

    const writeValue = (value, path) => {
        const newFuelSourceVM = viewModelService.clone(fuelSourceVM);
        _.set(newFuelSourceVM, path, value);
        setFuelSourceVM(newFuelSourceVM);
    }

    const isFuelType = (type) => {
        return _.get(fuelSourceVM, 'fuelType.value.code') === type ;
    }

    const isTenantDwelling = () => {
        return _.get(riskView, 'rating.hoPolicyType.value.code') === HOConstants.rentersPolicyType;
    }

    const isPrimaryHeatingDetail = () => {
        return _.get(fuelSourceVM, 'fuelSurvey.primarySurveyDetail.isPrimary.value');
    }

    const isPrimaryOilTankLabelVisible = () => {
        return isRPC1671Effective() 
            ? (isFuelType(CONSTANTS.HEATING_FUEL_TYPES.OIL) && isPrimaryHeatingDetail() && !isTenantDwelling()) 
            : (isFuelType(CONSTANTS.HEATING_FUEL_TYPES.OIL) && isPrimaryHeatingDetail());
    }

    const isOilTankQuestionnaireRecdVisible = () => {
        return !isRPC1671Effective() && isFuelType(CONSTANTS.HEATING_FUEL_TYPES.OIL) && isPrimaryHeatingDetail();
    };

    const isHeatingSystemApprovalCertificationVisible = () =>  {
        return isRPC1671Effective() ? !isFuelType(CONSTANTS.HEATING_FUEL_TYPES.OIL) : true;
    }

    const isOilSectionVisible = () => {
        return isRPC1671Effective() 
            ? (isFuelType(CONSTANTS.HEATING_FUEL_TYPES.OIL) && !isTenantDwelling()) 
            : isFuelType(CONSTANTS.HEATING_FUEL_TYPES.OIL);
    }

    const commonSurveyDetailProps = {
        jobVM,
        showErrors,
        onValidate: setComponentValidation,
        readOnly,
        isRPC1671Effective,
        isHeatingSystemApprovalCertificationVisible,
        isOilSectionVisible
    }

    const overrideProps = {
        '@field': {
            parentNode: fuelSourceVM,
            readOnly
        },
        chimneyApprovalOrCertification: {
            availableValues: chimneySystemCerts,
            // readonly version of checkbox component doesn't display all the checkboxes, only selected ones as a list
            readOnly: false,
            disabled: readOnly
        },
        primaryOilTankHeading: {
            visible: isPrimaryOilTankLabelVisible()
        },
        secondaryOilTankHeading: {
            visible: isFuelType(CONSTANTS.HEATING_FUEL_TYPES.OIL) && _.get(fuelSourceVM, 'fuelSurvey.oilTankNumber.value') > 1
        },
        questionnaireRecd: {
            visible: isOilTankQuestionnaireRecdVisible()
        },
        primarySurveyDetailComponent: {
            surveyVM: fuelSourceVM.fuelSurvey.primarySurveyDetail,
            setSurveyVM: setPrimarySurveyVM,
            ...commonSurveyDetailProps
        },
        secondarySurveyDetailComponent: {
            surveyVM: fuelSourceVM.fuelSurvey.secondarySurveyDetail,
            setSurveyVM: setSecondarySurveyVM,
            visible: _.get(fuelSourceVM, 'fuelSurvey.oilTankNumber.value') > 1,
            ...commonSurveyDetailProps
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICHOConstructionHeatingSurvey
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={fuelSourceVM}
            overrideProps={overrideProps}
            onModelChange={setFuelSourceVM}
            onValueChange={writeValue}
            onValidationChange={setComponentValidation}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default WMICHOConstructionHeatingDetails;

