function getURL(item) {
    const url = item.jobNumber
        ? `/quotes/${item.jobNumber}/${item.postalCode}/summary`
        : `/policies/${item.policyNumber}/${item.effectiveTermNumber}/summary`;
    return url;
}

export default {
    getURL
};
