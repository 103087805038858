/* eslint-disable max-len */
// eslint-disable-next-line object-curly-newline
/* eslint-disable camelcase */
import React, { useContext, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { TranslatorContext } from '@jutro/locale';
import { wizardProps, WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WMICQuestionSetComponent, WMICRequiredText } from 'wmic-pe-components-platform-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { useWizardModals, useDocumentTitle, WMICWizardSubmissionPage } from 'wmic-pe-portals-wizard-components-ui';
import { FlowStepId, LOBConstants, OFFERING } from 'wmic-pe-portals-utils-js';

import { messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';

import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './WMICCPQualificationPage.metadata.json5';
import messages from './WMICCPQualificationPage.messages';

import contractorsChoiceWizardConfig from '../../config/cp-contractors-choice-wizard-config.json5';
import noEnhancementWizardConfig from '../../config/cp-no-enhancement-wizard-config.json5'
import defaultWizardConfig from '../../config/cp-default-wizard-config.json5'

const LOB_PATH = {
    CommercialPackage: 'lobData.commercialProperty.preQualQuestionSets.children[0].value'
};

const ANIS_PATH = 'baseData.additionalNamedInsureds_WMIC'

function WMICCPQualificationPage(props) {
    const translator = useContext(TranslatorContext);
    const { wizardData: submissionVM, updateWizardData, changeNextSteps } = props;
    const { steps, currentStepIndex } = useContext(WizardContext);
    const history = useHistory();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const {
        isComponentValid,
        initialValidation,
        onValidate
    } = useValidation('CPQualificationPage');
    const [showErrors, setShowErrors] = useState(false);
    const [currentOffering, setCurrentOffering] = useState(submissionVM.lobData.commercialProperty.offerings.children[0].branchCode.value);

    const lobCode = _.get(submissionVM.value, 'baseData.productCode');

    const { steps: contractorsChoiceWizardSteps } = contractorsChoiceWizardConfig;
    const { steps: noEnhancementWizardSteps } = noEnhancementWizardConfig;
    const { steps: defaultWizardSteps } = defaultWizardConfig;

    const { setWizardLoading } = useWizardModals();

    useDocumentTitle(translator(messages.documentTitle), submissionVM);

    const additionalNamedInsureds = _.get(submissionVM.value, ANIS_PATH)


    useEffect(() => {
        submissionVM.value.flowStepId_WMIC = FlowStepId.PRE_QUALIFICATION;
    }, [submissionVM.value]);

    const onNext = useCallback(async () => {
        if (isComponentValid) {
            try {
                setWizardLoading(true);
                _.unset(submissionVM.value, 'bindData');
                submissionVM.value.flowStepId_WMIC = FlowStepId.PRE_QUALIFICATION;

                const result = await LoadSaveService.updateDraftSubmission(
                    submissionVM.value,
                    authHeader
                );
                _.extend(submissionVM.value, result);

                _.set(submissionVM, `${ANIS_PATH}.value`, additionalNamedInsureds);
                const declinedMessages = submissionVM.value.declineMessages_WMIC;
                const quoteID = submissionVM.value.quoteID;

                if (declinedMessages && declinedMessages.length > 0 ) {
                    let parms = {
                        underwritingIssues: declinedMessages,
                        quoteID
                    };

                    if (lobCode === LOBConstants.CP) {
                        parms = {
                            underwritingIssues: declinedMessages,
                            quoteID,
                            lob: 'cp',
                            account: {
                                displayName: _.get(submissionVM.value, 'baseData.accountHolder.displayName'),
                                displayAddress: _.get(submissionVM.value, 'baseData.accountHolder.primaryAddress.displayName') || '',
                                accountNumber: _.get(submissionVM.value, 'baseData.accountNumber')
                            }
                        };
                    }

                    history.push('/knockoutpage', parms);

                    return false;
                }
                updateWizardData(submissionVM);
                return submissionVM;

            } finally {
                setWizardLoading(false);
            }
        }
        setShowErrors(true);
        return false;
    }, [submissionVM, LoadSaveService, authHeader, isComponentValid]);

    const overrideProps = {
        questionSets: {
            path: LOB_PATH[lobCode],
            showErrors: showErrors,
            baseState: submissionVM.baseData.baseState.value,
            lobCode
        },
        qualificationHeader: translator(messages.qualificationTitle)
    };

    const resolvers = {
        resolveComponentMap: { questionset: WMICQuestionSetComponent, WMICRequiredText },
        resolveCallbackMap: {
            onValidate: onValidate
        }
    };

    const updateWizardSteps = useCallback(() => {
        switch(currentOffering) {
            case OFFERING.ContractorsChoice:
                if(steps.length !== contractorsChoiceWizardSteps.length + 2) {
                    changeNextSteps(contractorsChoiceWizardSteps);
                }
                break;
            case OFFERING.NoEnhancement:
                if(steps.length !== noEnhancementWizardSteps.length + 2) {
                    changeNextSteps(noEnhancementWizardSteps);
                }
                break;
            default:
                if(steps.length !== defaultWizardSteps.length + 2) {
                    changeNextSteps(defaultWizardSteps);
                }
                break;
        }

        return submissionVM;
    }, [changeNextSteps, submissionVM, currentOffering, contractorsChoiceWizardSteps, noEnhancementWizardSteps, defaultWizardSteps, steps]);

    useEffect(() => {
        updateWizardSteps();
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionVM, currentOffering]);

    return (
        <WMICWizardSubmissionPage
            onNext={onNext}
            skipWhen={initialValidation}
            cancelLabel={commonMessages.saveAndExit}
            showMandatoryInfo
            flowStepId={FlowStepId.PRE_QUALIFICATION}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                showErrors={showErrors}
            />
        </WMICWizardSubmissionPage>
    );
}

WMICCPQualificationPage.propTypes = wizardProps;
export default WMICCPQualificationPage;
