import appConfig from 'app-config';
import { WMICLogger } from 'wmic-pe-portals-utils-js';

const { fnolLobsConfig } = appConfig;

/**
 * Module that returns the mapping of a given product.
 */

/**
 * @param {Strign} product to map
 * @returns {String} a mapping to the product path in the policy
 */
function getProductPath(product) {
    if (product === null || product === undefined) {
        WMICLogger.warn('Unable to get path for an empty product');
        return;
    }
    const path = _.findKey(fnolLobsConfig, i => i.includes(product));
    return path && path[0].toLowerCase() + path.slice(1) || path;
}

export default {
    getProductPath
};
