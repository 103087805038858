import { defineMessages } from 'react-intl';

export default defineMessages({
    equipmentYear: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOEquipmentDetails.Year',
        defaultMessage: 'Year'
    },
    equipmentMake: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOEquipmentDetails.Make',
        defaultMessage: 'Make'
    },
    equipmentModel: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOEquipmentDetails.Model',
        defaultMessage: 'Model'
    },
    equipmentSerialNumber: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOEquipmentDetails.Serial Number',
        defaultMessage: 'Serial Number'
    },
    equipmentDescription: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOEquipmentDetails.Description',
        defaultMessage: 'Description'
    },
    oneOfMakeModelSerialNumberDescriptionRequiredToBind: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOEquipmentDetails.One Required To Bind',
        defaultMessage: "&lt;span&gt;One of &lt;b&gt;Make, Model, Serial Number,&lt;/b&gt; or &lt;b&gt;Description&lt;/b&gt; is required to bind the policy&lt;/span&gt;"
    }
})
