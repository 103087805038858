import React, {
    useContext
} from 'react';
import _ from 'lodash';
import { TranslatorContext  } from '@jutro/locale';
import { ViewModelForm  } from '@xengage/gw-portals-viewmodel-react';
import {  Currency as CurrencyField } from 'gw-components-platform-react';
import WMICPAPolicyAdditionalInterests from './WMICPAPolicyAdditionalInterests/WMICPAPolicyAdditionalInterests';
import messages from './WMICPAPolicyVehicleAdditionalInfo.messages';
import metadata from './WMICPAPolicyVehicleAdditionalInfo.metadata.json5';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

function WMICPAPolicyVehicleAdditionalInfo(props) {
    const {
        vehicleVM,
    } = props;

    const translator = useContext(TranslatorContext);
    const resolvers = {
        resolveComponentMap: {
            WMICPAPolicyAdditionalInterests,
        }
    };

    // Todo: Move this to BE 
    const getCustomizationTotal = () => {
        let total = 0;
        
        if (_.get(vehicleVM,'customizations_WMIC.value',[])) {
            for (let customizationIndex = 0; customizationIndex < vehicleVM.customizations_WMIC.value.length; customizationIndex++) {
                total += Number(vehicleVM.customizations_WMIC.getElement(customizationIndex).customizationValue.value);
            }
        }
        return getCurrencyFormattedNumber(total);
    };

    const getFormattedCustomizationValue = (currAmount) => {
        const amount = _.get(currAmount, 'customizationValue.value');
        return getCurrencyFormattedNumber(amount);
    }

    const getCurrencyFormattedNumber = (item) => {
        return (
            <CurrencyField
                value={{
                    "amount": item
                }}
                dataType="object"
                readOnly
                hideLabel
            />
        );
    }; 

    const getDisplayValueForExcludingAttachedMachinery = (vehicleAdditionalCustomization)=> {
        return _.get(vehicleAdditionalCustomization, 'excludingOperationOfAttachedMachinery.value') ? commonMessages.yesModel : commonMessages.noModel;
    };

    const isExcludingOperationOfAttachedMachineryVisible = (vehicleAdditionalCustomization) => {
        return _.get(vehicleAdditionalCustomization, 'excludingOperationOfAttachedMachinery.aspects.ocular', false);
    };

    const overrideProps = {
        '@field': {
            parentNode: vehicleVM,
            readOnly: true
        },
        policyAdditionalInterests: {
            vehicleVM,
        },
        policyCustomizationListView: {
            VMData: [
                {
                    headerText: translator(messages.policyVehicleCustomizationType),
                    path: 'vehicleCustType_WMIC'
                },
                {
                    headerText: translator(messages.policyVehicleCustomizationDescription),
                    path: 'description'
                },
                {
                    headerText: translator(messages.policyVehicleCustomizationValue),
                    path: 'customizationValue',
                    getData: getFormattedCustomizationValue
                },
                {
                    headerText: translator(messages.policyVehicleCustomizationAttachedMachinery),
                    path: 'excludingOperationOfAttachedMachinery',
                    getData: getDisplayValueForExcludingAttachedMachinery,
                    visibilityCondition: isExcludingOperationOfAttachedMachineryVisible
                },
            ],
            clickable: false,
            readOnly: true,
            startOpen:false
        },
        policyCustomizationTotalAmount: {
            content: getCustomizationTotal()
        },
        policyCustomizationTotalLabel:{
            content:translator(messages.policyCustomizationTotal)
        },
        policyAdditionalInterestsSectionContainer:{
            visible: _.get(vehicleVM,'vehicleAdditionalInterests_WMIC.children',[]).length > 0
        }
        
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleVM}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default WMICPAPolicyVehicleAdditionalInfo;
