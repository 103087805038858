import { defineMessages } from 'react-intl';

export default defineMessages({
    homeAddress : {
        id : 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterestDetailComponent.Home Address',
        defaultMessage:  'Home Address'
    },
    dateOfBirth : {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterestDetailComponent.dateOfBirth',
        defaultMessage: 'Date of Birth'
    },
    contactDetails : {
        id : 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterestDetailComponent.Contact Details',
        defaultMessage: 'Contact Details'
    },
    primaryPhoneType : {
        id : 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterestDetailComponent.Primary Phone Type',
        defaultMessage: 'Primary phone'
    },
    emailAddress : {
        id : 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterestDetailComponent.Email Address',
        defaultMessage: 'Email Address'
    },
    selectCoverage : {
        id : 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterestDetailComponent.Select Coverage',
        defaultMessage: 'Select the coverage for the Additional Insured'
    },
    dwellingBuilding : {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterestDetailComponent.Dwelling Building',
        defaultMessage: '(A) Residence'
    },
    otherStructures : {
        id : 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterestDetailComponent.Other Structures',
        defaultMessage: '(B) Additional Buildings & Structures'
    },
    personalProperty : {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterestDetailComponent.Personal Property',
        defaultMessage: '(C) Personal Property'
    },
    lossOfUse : {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterestDetailComponent.Loss of Use',
        defaultMessage: '(D) Loss of Use'
    },
    legalLiability : {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterestDetailComponent.Legal Liability',
        defaultMessage: '(E) Liability'
    },
    additionalDetails : {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterestDetailComponent.Additional Details',
        defaultMessage: 'Additional Details'
    },
    mailSeparateNotices : {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterestDetailComponent.Mail Separate Notices',
        defaultMessage: 'Mail Separate Notices'
    },
    privacyDisclosure : {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterestDetailComponent.Privacy Disclosure',
        defaultMessage: 'Privacy Disclosure and Consent Form'
    },
    immediateFamilyMember : {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterestDetailComponent.Immediate Family Member',
        defaultMessage: 'Immediate Family Member - Mature/Senior Citizens Discount apply'
    },
    addNewCompany: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterestDetailComponent.AddNew',
        defaultMessage: 'Add a new Company'
    },
    descriptionOfInterest: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterestDetailComponent.Description of Interest',
        defaultMessage: 'Description of Interest'
    },
    cancelAndSearch: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterestDetailComponent.Cancel and Search Again',
        defaultMessage: 'Cancel & Search Again'
    },
    describeOtherInterest: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterestDetailComponent.Additional Interest Other Description',
        defaultMessage: 'Describe Other Interest'
    },
    crownEntity: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterestDetailComponent.Crown Entity',
        defaultMessage: 'Crown Entity'
    },
    personalInfo: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterestDetailComponent.Personal Info",
        defaultMessage: "Personal Info"
    }
});
