 
import React, { useEffect, useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { CONSTANTS, WMICFileUtil, WMICRPCUtil, HOConstants } from 'wmic-pe-portals-utils-js'
import { useWizardModals } from "wmic-pe-portals-wizard-components-ui";
import { TranslatorContext } from '@jutro/locale';
import WMICHOPetsTable from '../WMICHOPetsTable/WMICHOPetsTable';
import messages from './WMICHODwellingAdditionalDetails.messages.js'
import metadata from './WMICHODwellingAdditionalDetails.metadata.json5';
import styles from './WMICHODwellingAdditionalDetails.module.scss';

function WMICHODwellingAdditionalDetails(props) {
    const {
        id,
        riskView,
        readOnly,
        jobVM,
        isEditMode,
        onValidate,
        showErrors
    } = props;

    const { refreshData } = useDataRefresh();
    const { showConfirm } = useWizardModals();
    const translator = useContext(TranslatorContext);
    const {
        isComponentValid,
        onValidate: setComponentValidation,
        registerComponentValidation
    } = useValidation(id);
    const isPolicyView = jobVM?._dtoName === 'wmic.edge.ca.capabilities.gateway.policy.dto.PolicyDTO';

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    const checkMinimumPetsValid = useCallback(() => {
        const petsOnPremises = _.get(riskView, 'additionalDetails.petsOnPremises.value');
        const dwellingAnimals = _.get(riskView, 'additionalDetails.dwellingAnimals.value', []);
        return !petsOnPremises || dwellingAnimals.length > 0;
    }, []);

    useEffect(() => {
        registerComponentValidation(checkMinimumPetsValid);
    }, [registerComponentValidation, checkMinimumPetsValid]);

    const updateRiskView = () => {
        refreshData();
    };

    const getCurrentCoverage = (coveragePublicID) => {
        const coverages = _.get(jobVM, 'lobData.homeowners.offerings.value[0].coverages.dwellingCoverages[0].coverages');

        return _.find(coverages, { publicID: coveragePublicID });
    };

    const verifyDaycareOperation = async (value, path) => {
        _.set(riskView, path, value);

        if (isEditMode) {
            if (!_.isUndefined(riskView.additionalDetails.daycareOperation.value) && !_.get(riskView.additionalDetails.daycareOperation.value)) {
                const dwellingCoverage = getCurrentCoverage('HO_DaycareCov');

                if (!_.isUndefined(dwellingCoverage) && dwellingCoverage.selected) {
                    const response = await showConfirm({
                        title: translator(messages.removeAdditionalDetailsShort),
                        message: translator(messages.removeAdditionalDetailsLong),
                        confirmButton: commonMessages.yes,
                        cancelButtonText: commonMessages.no
                    });

                    if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
                        _.set(riskView, 'additionalDetails.daycareNumberOfChildren.value', null);
                        _.set(riskView, 'additionalDetails.daycareQuestionnaireReceived.value', null);
                    } else {
                        _.set(riskView, 'additionalDetails.daycareOperation.value', !_.get(riskView, 'additionalDetails.daycareOperation.value'));
                    }
                } else {
                    _.set(riskView, 'additionalDetails.daycareNumberOfChildren.value', null);
                    _.set(riskView, 'additionalDetails.daycareQuestionnaireReceived.value', null);
                }
            }
        }

        updateRiskView();
    };

    const verifyBedAndBreakfastOperation = async (value, path) => {
        _.set(riskView, path, value);

        if (isEditMode) {
            if (!_.isUndefined(riskView.additionalDetails.bedAndBreakfastOperation.value) && !_.get(riskView.additionalDetails.bedAndBreakfastOperation.value)) {
                const dwellingCoverage = getCurrentCoverage('HO_BnBCov');

                if (!_.isUndefined(dwellingCoverage) && dwellingCoverage.selected) {
                    const response = await showConfirm({
                        title: translator(messages.removeAdditionalDetailsShort),
                        message: translator(messages.removeAdditionalDetailsLong),
                        confirmButton: commonMessages.yes,
                        cancelButtonText: commonMessages.no
                    });

                    if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
                        _.set(riskView, 'additionalDetails.bedAndBreakfastCapacity.value', null);
                        _.set(riskView, 'additionalDetails.bedAndBreakfastMonths.value', null);
                    } else {
                        _.set(riskView, 'additionalDetails.bedAndBreakfastOperation.value', !_.get(riskView, 'additionalDetails.bedAndBreakfastOperation.value'));
                    }
                } else {
                    _.set(riskView, 'additionalDetails.bedAndBreakfastCapacity.value', null);
                    _.set(riskView, 'additionalDetails.bedAndBreakfastMonths.value', null);
                }
            }
        }

        updateRiskView();
    };

    const verifyIncidentalOfficeUse = async (value, path) => {
        if (isEditMode) {
            if (!value) { // if they are turning the feature off
                const response = await showConfirm({
                    title: translator(messages.removeAdditionalDetailsShort),
                    message: translator(messages.removeAdditionalDetailsLong),
                    confirmButton: commonMessages.yes,
                    cancelButtonText: commonMessages.no
                });

                if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
                    _.set(riskView, path, value);
                }
            } else {
                _.set(riskView, path, value);
            }
        }

        updateRiskView();
    };

    const verifyPetsOnPremises = (value, path) => {
        _.set(riskView, path, value);

        if (!_.get(riskView, 'additionalDetails.petsOnPremises.value')) {
            _.set(riskView, 'additionalDetails.dwellingAnimals.value', []);
        }

        updateRiskView();
    };

    const isShortTermRentalUseFieldRequiredToBind = useCallback((shortTermRentalField) => 
            _.isUndefined(_.get(shortTermRentalField, 'value'))
            && (WMICRPCUtil.getIsRPCEffectiveForRiskType(
                _.get(riskView, 'rating.ratingJurisdiction.value.code'),
                _.get(jobVM, 'baseData.rateAsOfDate.value'),
                _.get(riskView, 'rating.hoPolicyType.value.code'),
                '1838') ||
                WMICRPCUtil.getIsRPCEffectiveForRiskType(
                _.get(riskView, 'rating.ratingJurisdiction.value.code'),
                _.get(jobVM, 'baseData.rateAsOfDate.value'),
                _.get(riskView, 'rating.hoPolicyType.value.code'),
                '1554')),
            [jobVM, riskView]);

    const isShortTermRentalUseInvalidOnCurrentCoverageType = useCallback(() => 
        _.get(riskView, 'additionalDetails.shortTimeRentalUse.value')
        && WMICRPCUtil.getIsRPCEffectiveForRiskType(
            _.get(riskView, 'rating.ratingJurisdiction.value.code'),
            _.get(jobVM, 'baseData.rateAsOfDate.value'),
            _.get(riskView, 'rating.hoPolicyType.value.code'),
            '1554')
        && _.get(riskView, 'yourHome.coverageType.value.code') === HOConstants.coverageTypeBasic,
    [jobVM, riskView]);

    const policyViewOverrides = useMemo(() => {    
        const additionalDetails = _.get(riskView, 'additionalDetails')

        return WMICFileUtil.convertMetadataSeparatorsToVisibilityLogic(metadata.componentContent, additionalDetails, isPolicyView)
    }, [riskView.value, isPolicyView]);

    const requiredToBindMessage = useMemo(() => <p>{translator(messages.additionalDetailsRequiredToBind)}</p>, [translator])
    const shortTermRentaInvalidCoverageTypeMessage = useMemo(() => <p>{translator(messages.shortTermRentalUseInvalidCoverageWarningMessage)}</p>, [translator])

    const shortTermRentalMessage = (property) => isShortTermRentalUseFieldRequiredToBind(_.get(riskView, `additionalDetails.${property}`)) ? requiredToBindMessage : ''
    const shortTermRentalInvalidCoverageTypeWarningMessage = () => isShortTermRentalUseInvalidOnCurrentCoverageType() ? shortTermRentaInvalidCoverageTypeMessage : ''

    const overrideProps = {
        '@field': {
            parentNode: riskView,
            readOnly
        },
        petsTable: {
            petListVM: riskView.additionalDetails?.dwellingAnimals,
            updateParentRiskView: updateRiskView,
            visible: Boolean(_.get(riskView, 'additionalDetails.petsOnPremises.value')),
            onValidate: setComponentValidation,
            readOnly
        },
        incidentalOfficeUseSection: {
            visible: _.get(riskView, 'additionalDetails.incidentalOfficeUseSection.aspects.ocular')
        },
        shortTimeRentalUse: {
            secondaryLabel: shortTermRentalInvalidCoverageTypeWarningMessage()
        },
        shortTimeRentalUseQuestionnaireReceived: {
            secondaryLabel: shortTermRentalMessage('shortTimeRentalUseQuestionnaireReceived')
        },
        shortTimeRentalUseAdvertised: {
            secondaryLabel: shortTermRentalMessage('shortTimeRentalUseAdvertised')
        },
        shortTimeRentalUseAdvertisedThroughRepNetwork: {
            secondaryLabel: shortTermRentalMessage('isShortTermRentalUseAdvertisedThroughRepNetwork_WMIC')
        },
        shortTimeRentalUsePortionRented: {
            secondaryLabel: shortTermRentalMessage('shortTimeRentalUsePortionRented')
        },
        shortTimeRentalUseBikes: {
            secondaryLabel: shortTermRentalMessage('shortTimeRentalUseBikes')
        },
        shortTimeRentalUseWatercraft: {
            secondaryLabel: shortTermRentalMessage('shortTimeRentalUseWatercraft')
        },
        shortTimeRentalUseMotorizedVehicles: {
            secondaryLabel: shortTermRentalMessage('shortTimeRentalUseMotorizedVehicles')
        },
        shortTimeRentalUsePriorLosses: {
            secondaryLabel: shortTermRentalMessage('shortTimeRentalUsePriorLosses')
        },
        shortTimeRentalUsePriorLossesDescription: {
            secondaryLabel: shortTermRentalMessage('shortTimeRentalUsePriorLossesDescription')
        },
        ...policyViewOverrides
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            WMICHOPetsTable,
        },
        resolveCallbackMap: {
            verifyDaycareOperation,
            verifyBedAndBreakfastOperation,
            verifyIncidentalOfficeUse,
            verifyPetsOnPremises
        }
    };

    return (
        <ViewModelForm
            model={riskView}
            onModelChange={updateRiskView}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

WMICHODwellingAdditionalDetails.propTypes = {
    riskView: PropTypes.shape({}),
    jobVM: PropTypes.shape({}),
    isEditMode: PropTypes.bool.isRequired,
};

export default WMICHODwellingAdditionalDetails;
