import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICWizardViewPage } from 'wmic-pe-portals-wizard-components-ui';
import { TranslatorContext } from '@jutro/locale';
import { WMICDateTimeService } from 'wmic-pe-portals-utils-js';
import _ from 'lodash';
import WMICPolicyPupHouseholdMembersDetailView from './WMICPolicyPupHouseholdMembersDetailView/WMICPolicyPupHouseholdMembersDetailView';
import metadata from './WMICPolicyPupHouseholdMembers.metadata.json5';


const WMICPolicyPupHouseholdMembers = ({ wizardData: { policyVM } }) => {
    
    const translator = useContext(TranslatorContext);
    const householdMembersVM = _.get(policyVM, 'lobs.personalUmbrellaLine_PUE.coverables.householdMembers.children', []);

    const displayDate = useCallback((data, path) => {
        const dateValue = _.get(data.value, path);
        return dateValue ? <span>{WMICDateTimeService.toMidnightDate(dateValue)}</span> : '';
    }, []);

    const overrideProps = {
        WMICPolicyPupHouseholdMembersListView: {
            value: householdMembersVM,
            VMData: [
                {
                    headerText: translator({id: 'quoteandbind.views.quote-effective-date.Name', defaultMessage: 'meta.Name'}),
                    path: 'person.displayName'
                },
                {
                    headerText: translator({id: 'gateway.contacts.common.views.policy.roles.PolicyPriNamedInsured', defaultMessage: 'meta.Primary Named Insured'}),
                    path: 'isPNI'
                },
                {
                    headerText: translator({id: 'quoteandbind.views.quote-effective-date.Relationship', defaultMessage: 'meta.Relationship'}),
                    path: 'relationshipToPrimaryInsured_WMIC'
                },
                {
                    headerText: translator({id: 'quoteandbind.pup.views.household.Date of Birth', defaultMessage: 'meta.Date of Birth'}),
                    getData: displayDate,
                    path: 'person.dateOfBirth'
                }
            ],
            clickable: true,
            readOnly: true,
            detailViewComponent: WMICPolicyPupHouseholdMembersDetailView,
            detailViewComponentProps: {
                displayDate
            }
        }
    }

    return (
        <WMICWizardViewPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyVM}
                overrideProps={overrideProps}
            />
        </WMICWizardViewPage>
    );
}

WMICPolicyPupHouseholdMembers.propTypes = {
    wizardData: PropTypes.object.isRequired
}

export default WMICPolicyPupHouseholdMembers;