import { defineMessages } from 'react-intl';

export default defineMessages({
    policyCoverages: {
        id: 'wmic-pe-capability-gateway-common-pa-react.PAPolicyCoverages.Policy Level Coverages',
        defaultMessage: 'Policy Level Coverages',
    },
    liabilityProtectionPhysicalDamage: {
        id: 'wmic-pe-capability-gateway-common-pa-react.PAPolicyCoverages.Liability Protection Physical Damage same as',
        defaultMessage: 'Liability Protection, Physical Damage same as'
    },
})
