import { WMICDateTimeService, WMICLogger } from 'wmic-pe-portals-utils-js';
import UISupportingInfoLookupService from '../../capabilities/wmic-pe-capability-supportinginfo/services/UISupportingInfoLookupService';

function generateKey(asOf) {
    if (typeof asOf === 'object') {
        return JSON.stringify(asOf, Object.keys(asOf).sort());
    }
    return JSON.stringify(asOf);
}

export default {
    NO_PREVIOUS_INSURANCE: 'NO_PREVIOUS_INSURANCE',
    NO_PREVIOUS_INSURANCE_FR: 'NO_PREVIOUS_INSURANCE_FR',
    NUMBER_OF_TRIPS_VISIBLE: 'NUMBER_OF_TRIPS_VISIBLE',

    initialize: (asOf, authHeader) => {
        return new Promise((resolve, reject) => {

            if (!WMICDateTimeService.isDateDTOValid(asOf)) {
                resolve(true);
            }

            const cacheKey = `Constant Key: ${generateKey(asOf)}`;
            const cachedValue = sessionStorage.getItem(cacheKey);
            if (cachedValue) {
                resolve(true);
            } else {
                UISupportingInfoLookupService.retrieveConstants(asOf, authHeader)
                    .then(
                        (constantValues) => {
                            sessionStorage.setItem(cacheKey, JSON.stringify(constantValues));
                            resolve(true);
                        },
                        (error, status, headers) => reject([error, status, headers])
                    );
            };
        });
    },

    alreadyInitialized: (asOf) => {
        const cacheKey = `Constant Key: ${generateKey(asOf)}`;
        const cachedValue = sessionStorage.getItem(cacheKey);

        return !!cachedValue
    },

    getConstant: (constant, jurisdiction, asOf) => {
        if (constant && jurisdiction && asOf) {
            const key = `Constant Key: ${generateKey(asOf)}`;
            const map = JSON.parse(sessionStorage.getItem(key));
            if (map) {
                const jurisdictionToUse = jurisdiction.code || jurisdiction;

                if (map.hasOwnProperty(jurisdictionToUse)) {
                    const xMap = map[jurisdictionToUse];

                    if (xMap.hasOwnProperty(constant)) {
                        return xMap[constant];
                    }
                    WMICLogger.debug(`There is no constant named: ${constant} for key: ${key} and jurisdiction: ${jurisdictionToUse}`);
                } else {
                    WMICLogger.debug(`There are no constants for key: ${key} and jurisdiction: ${jurisdictionToUse}`);
                }
            } else {
                WMICLogger.error(`ConstantsUtil has not been initialized for ${key}`);
                throw `ConstantsUtil has not been initialized for ${key}`;
            }
        }

        return null;
    }
};
