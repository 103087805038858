import { defineMessages } from 'react-intl';

export default defineMessages({
    insureWatercraft: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOWatercraftBoatEquipmentTrailerDetails.Insure a Watercraft?',
        defaultMessage: 'Insure a Watercraft?'
    },
    insureTrailer: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOWatercraftBoatEquipmentTrailerDetails.Insure a trailer?',
        defaultMessage: 'Insure a trailer?'
    },
    insureAccessory: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOWatercraftBoatEquipmentTrailerDetails.Insure an accessory?',
        defaultMessage: 'Insure an accessory?'
    },
    insureMotor: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOWatercraftBoatEquipmentTrailerDetails.Insure a motor?',
        defaultMessage: 'Insure a motor?'
    },
    addMotor: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOWatercraftBoatEquipmentTrailerDetails.Add Motor',
        defaultMessage: 'Add Motor'
    }
})