
import _ from 'lodash';

import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { messages as platformMessages } from '@xengage/gw-platform-translations';

import { MODAL_CONSTANTS } from 'wmic-pe-portals-utils-js';
import { WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';
import messages from './WMICWizardHandler.messages';

export default class WMICWizardHandler {
    static handleSubmissionError(error, location, history, setHasErrorOccurred) {
        const quoteIDFromWizard = _.get(error, 'gwInfo.params[0].quoteID');
        const quoteIDFromLocation = _.get(location, 'state.quoteentry.quoteID');
        const quoteID = quoteIDFromWizard || quoteIDFromLocation;
        const redirectPath = `/quotes/${quoteID}/summary`;
        this.handleError(error, history, redirectPath, setHasErrorOccurred);
    }

    static handlePolicyChangeError(error, location, history, setHasErrorOccurred) {
        const jobIDFromWizard = _.get(error, 'gwInfo.params[0].jobID');
        const jobIDFromLocation = _.get(location, 'state.policyNumber');
        const termNumberFromLocation = _.get(location, 'state.selectedTerm')
        let redirectPath = `/contactAgent/${jobIDFromLocation}/${termNumberFromLocation}`;
        if (!_.isEmpty(jobIDFromWizard)) {
            redirectPath = `/change/${jobIDFromWizard}/summary`;
        }

        this.handleError(error, history, redirectPath, setHasErrorOccurred);
    }

    static handlePolicyChangeErrorRender(error, setIsRenderWizard) {
        if (WMICErrorHandler.getErrorCode(error) !== 601) {
            setIsRenderWizard(false);
        }
    }

    static handleError(error, history, redirectPath, setHasErrorOccurred) {
        const onSubmit = () => {
            setHasErrorOccurred(true);
            if (WMICErrorHandler.getErrorCode(error) !== 601) {
                history.push(redirectPath);
            }
        };

        if (typeof error === 'object') {
            return WMICErrorHandler.processAsModal(error).then(
                _.noop,
                onSubmit
            );
        }
        const isQuotePage = _.includes(_.toLower(_.get(error, 'gwInfo.method')), 'quote');
        const { showWarning } = useWizardModals();

        return showWarning({
            title: isQuotePage ? messages.saveQuoteError : messages.anErrorOccurredTitle,
            message: isQuotePage ? messages.saveQuoteErrorMessage : messages.anErrorOccurred,
            status: MODAL_CONSTANTS.STATUS.WARNING,
            icon: MODAL_CONSTANTS.ICON.WARNING,
            confirmButtonText: platformMessages.ok
        }).then(
            onSubmit,
            _.noop
        );
    }
}
