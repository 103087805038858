import { defineMessages } from 'react-intl';

export default defineMessages({
    MVRIViolationSuspensionType: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr-incident.Violation/Suspension',
        defaultMessage: 'Violation/Suspension Type'
    },
    MVRConvictionType: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr-incident.conviction-type',
        defaultMessage: 'Conviction Type'
    },
    MVRMinistryConvictionCode: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr-incident.ministry-conviction',
        defaultMessage: 'Ministry Conviction Code'
    },
    MVRReasonForSuspension: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr-incident.reason-for-suspension',
        defaultMessage: 'Reason for Suspension'
    },
    MVRReasonForRestriction: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr-incident.reason-for-restriction',
        defaultMessage: 'Reason for Restriction'
    },
    MVRIncidentDescription: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr-incident.description-type',
        defaultMessage: 'Description'
    },
    MVRIncidentTerm: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr-incident.term',
        defaultMessage: 'Term'
    },
    restrictionDateLabel: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.restrictionDateLabel',
        defaultMessage: 'Restriction Date'
    },
    prohibitionDateLabel: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.prohibitionDateLabel',
        defaultMessage: 'Prohibition Date'
    },
    offenceDateLabel: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.offenceDateLabel',
        defaultMessage: 'Offense Date'
    },
    suspensionDateLabel: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.suspensionDateLabel',
        defaultMessage: 'Suspension Date'
    },
    convictionDateLabel: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.convictionDateLabel',
        defaultMessage: 'Conviction Date'
    },
    reinstatementDateLabel: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.reinstatementDateLabel',
        defaultMessage: 'Reinstatement Date'
    },
})