import { defineMessages } from 'react-intl';

export default defineMessages({
    heatingSystemApprovalCert: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingSurvey.Heating System Approval/Certification",
        defaultMessage: "Heating System Approval/Certification"
    },
    oilTankCertLabel: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingSurvey.Does the oil tank have a certification label?",
        defaultMessage: "Does the oil tank have a certification label?"
    },
    make: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingSurvey.Make",
        defaultMessage: "Make"
    },
    yearManufactured: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingSurvey.Year Manufactured",
        defaultMessage: "Year Manufactured"
    },
    tankTypeAndWallConstruction: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingSurvey.Oil tank type and wall construction",
        defaultMessage: "Oil tank type and wall construction"
    },
    describe: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingSurvey.Describe Other",
        defaultMessage: "Describe Other"
    },
    location: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingSurvey.Location",
        defaultMessage: "Location"
    },
    oilTankSupport: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingSurvey.Oil Tank Support",
        defaultMessage: "Oil Tank Support"
    },
    professionallyInstalled: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingSurvey.Was the oil tank professionally installed?",
        defaultMessage: "Was the oil tank professionally installed?"
    },
    evidenceOfCorrosion: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingSurvey.Is there any rust, dents, leaks or evidence of corrosion or problems?",
        defaultMessage: "Is there any rust, dents, leaks or evidence of corrosion or problems?"
    },
    drainageSystemOrStormSewer: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingSurvey.Is the oil tank connected to any drainage system or storm sewer?",
        defaultMessage: "Is the oil tank connected to any drainage system or storm sewer?"
    },
    pipeConnectorsMetal: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingSurvey.Are all pipe connectors metal?",
        defaultMessage: "Are all pipe connectors metal?"
    },

});
