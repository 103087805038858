import { defineMessages } from 'react-intl';

export default defineMessages({
    wmicHorsePowerMandatoryMsg: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Required To Bind",
        defaultMessage: "This is required to bind the policy"
    },
    removeAIShort: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Remove Interest Short",
        defaultMessage: "Remove Additional Interest"
    },
    removeAILong: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Remove Interest Long",
        defaultMessage: "Are you sure you want to remove the additional interest from the list?"
    },
    scheduledItemTitle: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Scheduled Item",
        defaultMessage: "Scheduled Item"
    },
    scheduledItemType: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Item Type",
        defaultMessage: "Type"
    },
    scheduledItemDescription: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Item Description",
        defaultMessage: "Description"
    },
    scheduledItemLimit: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Item Limit",
        defaultMessage: "Limit"
    },
    siCoverageForm: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Item Coverage Form",
        defaultMessage: "Coverage Form"
    },
    siYear: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Item Year",
        defaultMessage: "Year"
    },
    siMake: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Item Make",
        defaultMessage: "Make"
    },
    siModel: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Item Model",
        defaultMessage: "Model"
    },
    siSerialNumber: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Item Serial Number",
        defaultMessage: "Serial Number"
    },
    siDeductible: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Item Deductible",
        defaultMessage: "Deductible"
    },
    siAppraisalDate: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Item Appraisal Date",
        defaultMessage: "Appraisal Date"
    },
    siBillOfSaleDate: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Item Bill of Sale Date",
        defaultMessage: "Bill of Sale Date"
    },
    siDeletionOfHailCoverage: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Item Deletion of Hail Coverage",
        defaultMessage: "Deletion of Hail Coverage"
    },
    siProffessionalUse: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Item Professional Use",
        defaultMessage: "Professional Use"
    },
    siFrequencyOfUse: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Item Frequency of Use",
        defaultMessage: "Frequency of Use"
    },
    siBreakageCover: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Item Breakage Cover",
        defaultMessage: "Breakage Cover"
    },
    siPremium: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Item Premium",
        defaultMessage: "Premium"
    },
    siOptLiabilityCoverages: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Optional Liability Coverages",
        defaultMessage: "Optional Liability Coverages"
    },
    siMotorizedVehicleLiability: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Motorized Vehicle Liability",
        defaultMessage: "Motorized Vehicle Liability"
    },
    siAdditionalInterests: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Additional Interests",
        defaultMessage: "Additional Interests"
    },
    siAddAdditionalInterest: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-your-home.Add Interest",
        defaultMessage: "Add Additional Interest"
    },
    siHorsepower: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Item Horsepower",
        defaultMessage: "Horsepower"
    },
    siOriginalPurchaser: {
        id: "wmic-pe-capability-gateway-common-ho-react.ho-scheduled-item.Item Original Purchaser",
        defaultMessage: "Original Purchaser"
    }
});
