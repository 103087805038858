import React from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICAddressDetails } from 'wmic-pe-components-platform-react';
import _ from 'lodash';
import WMICPAPolicyVehicleUsageCommercial from './WMICPAPolicyVehicleUsageCommercial/WMICPAPolicyVehicleUsageCommercial';
import metadata from './WMICPAPolicyVehicleUsage.metadata.json5';
import messages from './WMICPAPolicyVehicleUsage.messages';

function WMICPAPolicyVehicleUsage({
    policyVM,
    vehicleVM,
    reselectVehicle,
}) {

    const overrideProps = {
        '@field': {
            parentNode: vehicleVM,
            readOnly: true
        },
        policyCommercialVehicleComponentContainer: {
            visible: _.get(vehicleVM, 'commercialVehicle_WMIC.commercialVehicleContainer.aspects.ocular', false)
        },
        policyVehiclesUsageCommercial : {
            policyVM,
            vehicleVM,
            commercialVM: _.get(vehicleVM, 'commercialVehicle_WMIC'),
        },
        policyReselectVehicleContainer: {
            visible: reselectVehicle
        },
        policyRideSharingContainer: {
            visible: _.get(vehicleVM, 'rideSharingContainer.aspects.ocular', false)
        },
        policyAntiTheftDeviceEquipped: {
            visible: _.get(vehicleVM, 'showAntiTheftDeviceEquippedUsageContainer_Ext.aspects.ocular', false)
        },
        policyMotorHomeRiskContainer: {
            visible: _.get(vehicleVM, 'motorhomeRisksContainer.aspects.ocular', false)
        },
        policyPrimaryAddressDetailsComponent:{
            addressVM: _.get(vehicleVM, 'primaryLocation_WMIC', {}),
            hideBorder: true,
            hideButtons: true,
        },
        policyRatedGarageAddressDetailsComponent:{
            addressVM: _.get(vehicleVM, 'primaryLocation_WMIC', {}),
            hideBorder: true,
            hideButtons: true,
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICAddressDetails,
            WMICPAPolicyVehicleUsageCommercial
        },
    };

    return (
        <ViewModelForm
            model={vehicleVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
        />
    );

}

export default WMICPAPolicyVehicleUsage;
