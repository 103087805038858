import { defineMessages } from 'react-intl';

export default defineMessages({
    locationcoverages: {
        id: 'wmic.pe-capability-quoteandbind-cp-react.util.locationcoverages',
        defaultMessage: 'Location Coverages'
    },
    location: {
        id: 'wmic.pe-capability-quoteandbind-cp-react.util.location',
        defaultMessage: 'Location #{locationNumber}: {locationAddress}'
    }
});