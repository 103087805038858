import { defineMessages } from 'react-intl';

export default defineMessages({
    editActionAriaLabel: {
        id: "wmic.component.data-list-card-summary.Edit",
        defaultMessage: "Edit"
    },
    removeActionAriaLabel: {
        id: "wmic.component.data-list-card-summary.Remove",
        defaultMessage: "Remove"
    },
    confirmDefault: {
        id: "wmic.component.data-list-card-summary.Confirm Default",
        defaultMessage: "Confirm Default"
    },
    reviewed: {
        id: "wmic.component.data-list-card-summary.Reviewed",
        defaultMessage: "Reviewed"
    }
});