import { defineMessages } from 'react-intl';

export default defineMessages({
    boilerQuestionnaireReceived: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingDetails.Outdoor Central Boiler Questionnaire received?",
        defaultMessage: "Outdoor Central Boiler Questionnaire received?"
    },
    cordsOfWood: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingDetails.Cords of wood used every year",
        defaultMessage: "Cords of wood used every year"
    },
    bagsOfAnthracite: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingDetails.Bags of Anthracite used every year",
        defaultMessage: "Bags of Anthracite used every year"
    },
    bagsOfPellet: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingDetails.Bags of Pellet used every year",
        defaultMessage: "Bags of Pellet used every year"
    },
    chimneyType: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingDetails.Chimney Type",
        defaultMessage: "Chimney Type"
    },
    describe: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingDetails.Describe Other",
        defaultMessage: "Describe Other"
    },
    chimneyApprovals: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingDetails.Chimney Approvals Certifications",
        defaultMessage: "Chimney Approvals/Certifications"
    },
    fuelQuestionnaireReceived: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingDetails.Solid Fuel Heating Questionnaire received?",
        defaultMessage: "Solid Fuel Heating Questionnaire received?"
    },
    fuelPhotosReceived: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingDetails.Solid Fuel Heating Photos received?",
        defaultMessage: "Solid Fuel Heating Photos received?"
    },
    numOfOilTanks: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingDetails.Number of Oil Tanks",
        defaultMessage: "Number of Oil Tanks"
    },
    primaryOilTank: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingDetails.Primary Oil Tank",
        defaultMessage: "Primary Oil Tank"
    },
    oilTankQuestionnaireReceived: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingDetails.Oil Tank Questionnaire received?",
        defaultMessage: "Oil Tank Questionnaire received?"
    },
    oilTankPhotosReceived: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingDetails.Oil Tank Photos received?",
        defaultMessage: "Oil Tank Photos received?"
    },
    secondaryOilTank: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingDetails.Secondary Oil Tank",
        defaultMessage: "Secondary Oil Tank"
    }

});
