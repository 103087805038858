import _ from 'lodash';

import { useDependencies } from '@xengage/gw-portals-dependency-react';

function useGetSaveMethod(jobVM) {
    const { EndorsementService } = useDependencies('EndorsementService');

    const isEveryConstructionValid =  _.get(jobVM, 'lobData.homeowners.coverables.dwellings.children', []).every(
        (dwelling) => _.get(dwelling, 'construction.aspects.subtreeValid', false));

    return isEveryConstructionValid ? EndorsementService.saveEndorsement : EndorsementService.saveWithNoValidationRuleCheck;
}

export default useGetSaveMethod