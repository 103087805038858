export default {
    vinSizeIsValid: (vinOverride, year, vehicleType, vin, minSize, maxSize) => {
        const suiteMetadata = window.wmic.viewmodel.metadata;
        if (vehicleType.code === suiteMetadata.get('pc').types.getTypelist('VehicleType').getCode('trailer').code) {
            return true; // If this is a trailer, return true because this is not trailer VIN validation
        }
        if(vinOverride) {
            return true
        }
        if(vin.includes('?')) {
            return true;
        }
        if (year >= 1981 && (vin.toString().length < minSize || vin.toString().length > maxSize)) {
            return false;
        }
        return true;
    },
    vinTrailerSizeIsValid: (vehicleType, vin, minSize, maxSize) => {
        const suiteMetadata = window.wmic.viewmodel.metadata;
        if (vehicleType.code !== suiteMetadata.get('pc').types.getTypelist('VehicleType').getCode('trailer').code) {
            return true; // If this is not a trailer, return true because this is only for trailer VIN validation
        }
        if (vin.toString().length < minSize || vin.toString().length > maxSize) {
            return false;
        }
        return true;
    },
    isTrailerSubTypeHauledApplicable: (trailerSubType) => {
        return trailerSubType && trailerSubType.typelist.getFilter('HauledApplicable').allows(trailerSubType);
    }
}


