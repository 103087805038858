import _ from 'lodash';
import { PAConstants, CONSTANTS, JURISDICTIONS } from 'wmic-pe-portals-utils-js';
import  messages from './WMICPACoverageUtil.messages';

function getVehicleCoveragesForRisk(personalAutoData, riskPublicID) {
    const vehicleCoverages = personalAutoData.offerings.children[0].coverages.vehicleCoverages.children.find(
        (vehicleCoverage) => {
            return vehicleCoverage.value.publicID === riskPublicID;
        }
    );

    return vehicleCoverages || [];
}

function categorizeLineCoverages(lineCoverages, translator, onlySelected = false) {
    const allCoverages = {
        policyLevelCoverages: {
            categoryID:'policyLevelCoverages',
            // no label needed
            categoryName: "",
            coverages: []
        },
        motorcycleCoverages: {
            categoryID: 'motorcycleCoverages',
            categoryName: translator(messages.motorcycleCoverages),
            coverages: []
        },
        commercialCoverages: {
            categoryID: 'commercialCoverages',
            categoryName: translator(messages.commercialCoverages),
            coverages: []
        },
        optionalAccidentBenefitsCoverages: {
            categoryID: 'optionalAccidentBenefitsCoverages',
            categoryName: translator(messages.optionalAccidentBenefitsCoverages),
            coverages: []
        }
    }

    const filteredCoverages = onlySelected ? _.filter(lineCoverages, {'selected': true}): lineCoverages;

    
    filteredCoverages.forEach((coverage) => {
        switch (coverage.category_WMIC) {
            case 'PALiabilityProtection_wmic':
            case 'PALineAdditional_WMIC':
                allCoverages.policyLevelCoverages.coverages.push(coverage);
                break;
            case 'PALineMTCGrp_WMIC':
                allCoverages.motorcycleCoverages.coverages.push(coverage);
                break;
            case 'PALineCommercial_WMIC':
                allCoverages.commercialCoverages.coverages.push(coverage);
                break;
            case 'PAOptionalAccBenefits_WMIC':
                allCoverages.optionalAccidentBenefitsCoverages.coverages.push(coverage);
                break;
            default:
                break;
        }
    });

    return allCoverages;
}

function categorizeVehicleCoverages(vehicleCoverages, translator, onlySelected = false) {
    const allCoverages = {
        liabilityProtection: {
            categoryID: 'liabilityProtection',
            categoryName: translator(messages.liabilityProtection),
            coverages: []
        },
        underageMaleDriver: {
            categoryID: 'underageMaleDriver',
            categoryName: translator(messages.underageMaleDriver),
            coverages: []
        },
        underageGenderXOrFemaleDriver: {
            categoryID: 'underageGenderXOrFemaleDriver',
            categoryName: translator(messages.underageGenderXOrFemaleDriver),
            coverages: []
        },
        physicalDamage: {
            categoryID: 'physicalDamage',
            categoryName: translator(messages.physicalDamage),
            coverages: []
        },
        additionalEndorsements: {
            categoryID: 'additionalEndorsements',
            categoryName: translator(messages.vehicleEndorsements),
            coverages: []
        }
    };

    const filteredCoverages = onlySelected ? _.filter(vehicleCoverages.coverages, {'selected': true}): vehicleCoverages.coverages;

    filteredCoverages.forEach((coverage) => {
        switch (coverage.category_WMIC) {
            case 'PALiabilityProtection_wmic':
                allCoverages.liabilityProtection.coverages.push(coverage);
                break;
            case 'PACanadian06Coverages':
                allCoverages.underageMaleDriver.coverages.push(coverage);
                break;
            case 'PACanadian05Coverages':
                allCoverages.underageGenderXOrFemaleDriver.coverages.push(coverage);
                break;
            case 'PAPolicyHolderProperty_wmic':
                allCoverages.physicalDamage.coverages.push(coverage);
                break;
            case 'PAAdditional_wmic':
                allCoverages.additionalEndorsements.coverages.push(coverage);
                break;
            default:
                break;
        }
    });

    return allCoverages;
}

const getIsVehicleDriverEndorsement = (covId) => {
    switch (covId) {
        case PAConstants.reducAmtForNamedCov_28A_PublicId:
            return true;
        case PAConstants.reducAmtForNamedCov_28_PublicId:
            return true;
        case PAConstants.article6ExcludedDriver_WMIC_PublicId:
            return true;
        default: 
            return false;
    }
};

const isAssignmentExcludedOrAssigned = (policyDriver) => {
    return policyDriver.assignment_WMIC === CONSTANTS.ASSIGNMENT.EXCLUDED 
            || policyDriver.assignment_WMIC === CONSTANTS.ASSIGNMENT.ASSIGNED;
}

const isAssignmentRestrictedOrAssigned = (policyDriver) => {
    return policyDriver.assignment_WMIC === CONSTANTS.ASSIGNMENT.RESTRICTED 
            || policyDriver.assignment_WMIC === CONSTANTS.ASSIGNMENT.ASSIGNED;
}

const getCoverageTermLimit = (terms, limitPatternCode) => {
    let termLimit;

    if (terms) {
        termLimit = terms.find((term) => term.patternCode === limitPatternCode);
    }

    return termLimit;
}

const isProofOfFinancialResponsibilityVisible = (jobVM, isPolicyView) => {
    const path = isPolicyView ? `${PAConstants.PACoveragesPaths.POLICY_VIEW_VEHICLES_PATH}.value` : `${PAConstants.PACoveragesPaths.VEHICLES_PATH}.value`;
    const vehicles = _.get(jobVM, path, []);
    const pFRRatingJurisdictionEligible = [JURISDICTIONS.ONTARIO, JURISDICTIONS.NEW_BRUNSWICK, JURISDICTIONS.NOVA_SCOTIA, JURISDICTIONS.PRINCE_EDWARD_ISLAND];

    return vehicles.some(vehicle => vehicle.vehicleType === PAConstants.personalAutoVehicleType
            && pFRRatingJurisdictionEligible.includes(vehicle.ratingJurisdiction));
}

export default {
    getVehicleCoveragesForRisk,
    categorizeVehicleCoverages,
    categorizeLineCoverages,
    getIsVehicleDriverEndorsement,
    isAssignmentExcludedOrAssigned,
    isAssignmentRestrictedOrAssigned,
    getCoverageTermLimit,
    isProofOfFinancialResponsibilityVisible
};
