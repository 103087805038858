import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { Card } from '@jutro/components';
import styles from './WMICCard.module.scss';


function WMICCard({ children: content, title, id, flat, className }) {
    const translator = useContext(TranslatorContext);

    return (
        <Card id={`${id}-wmiccard`} className={cx(styles.wmicCard, {
            [styles.wmicCardRaised]: !flat,
        }, className)}>
            {title && <h2 className="gw-mb-6">{translator(title)}</h2>}
            {content}
        </Card>
    );
}

WMICCard.propTypes = {
    title: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    }),
    flat: PropTypes.bool
}

WMICCard.defaultProps = {
    flat: false
}

export default WMICCard;
