import React from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ClausesComponentVM } from 'wmic-pe-components-platform-react';

import metadata from './WMICPolicyPuCoverages.metadata.json5';

function WMICPolicyPuCoverages(props) {
    const {
        coverageVM,
        coveragePath,
        readOnly
    } = props;

    const overrideProps = {
        '@field': {
            parentNode: coverageVM
        },
        'pupCoverages': {
            path: coveragePath,
            readOnly
        }
    };

    const resolvers = {
        resolveComponentMap: {
            ClausesComponentVM
        }
    };


    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={coverageVM}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default WMICPolicyPuCoverages;
