import { defineMessages } from 'react-intl';

export default defineMessages({
    anErrorOccurred: {
        id: 'wmic.quoteandbind.cp.wizard.error.cancel',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'wmic.quoteandbind.cp.wizard.error.cancel.title',
        defaultMessage: 'Error'
    },
    saveQuoteError: {
        id: 'wmic.quoteandbind.cp.wizard.Unable to quote',
        defaultMessage: 'Unable to quote'
    },
    saveQuoteErrorMessage: {
        id: 'wmic.quoteandbind.cp.wizard.An error occurred while attempting to quote.',
        defaultMessage: 'An error occurred while attempting to quote.'
    }
});
