import React, { useMemo, useContext } from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import PropTypes from 'prop-types';
import { WMICWizardSingleErrorComponent } from 'wmic-pe-portals-wizard-components-ui';
import metadata from './WMICQuoteValidations.metadata.json5';

function WMICQuoteValidations(props) {
    const { isJobQuoted } = props;
    const wizardContext = useContext(WizardContext);
    const {
        stepsWithErrors
    } = wizardContext;
    const mappedValidationIssues = useMemo(() => {
        return Object.values(stepsWithErrors).flat();
    }, [stepsWithErrors]);

    const overrides =  {
        validationsContainer: {
            visible: isJobQuoted
        },
        validationsComp: {
            issuesList: mappedValidationIssues
        }
    }

    const resolvers = {
        resolveComponentMap: {
            WMICWizardSingleErrorComponent
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

WMICQuoteValidations.propTypes = {
    isJobQuoted: PropTypes.bool
};

WMICQuoteValidations.defaultProps = {
    isJobQuoted: true
};

export default WMICQuoteValidations;
