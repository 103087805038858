import { defineMessages } from 'react-intl';

export default defineMessages({
    policyAiPerson: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyAdditionalInterests.Person',
        defaultMessage: 'Person'
    },
    policyAiCompany: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyAdditionalInterests.Company',
        defaultMessage: 'Company'
    },
    policyAdditionalInterestName: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyAdditionalInterests.Additional Interest Name',
        defaultMessage: 'Name'
    },
    policyAdditionalInterestType: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyAdditionalInterests.Additional Interest Type',
        defaultMessage: 'Additional Interest Type'
    },
    policyCertificateRequired: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyAdditionalInterests.CertificateRequired',
        defaultMessage: 'Certificate Required'
    },
    policyContractNumber: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyAdditionalInterests.ContractNumber',
        defaultMessage: 'Contract Number'
    },
    policyCompanyContactDetails: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyAdditionalInterests.CompanyContactDetails',
        defaultMessage: 'Company Contact Details'
    },
    policyAIType: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyAdditionalInterests.Type',
        defaultMessage: 'Type'
    },
    policyFirstName: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyAdditionalInterests.FirstName',
        defaultMessage: 'First Name'
    },
    policyLastName: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyAdditionalInterests.LastName',
        defaultMessage: 'Last Name'
    },
    policyPersonalInfo: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyAdditionalInterests.PersonalInfo',
        defaultMessage: 'Personal Info'
    },
});
