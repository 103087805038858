import { defineMessages } from 'react-intl';

export default defineMessages({
    dwellingSummaryTitle: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.dwelling-info.summary-title',
        defaultMessage: 'Dwelling Summary'
    },
    watercraftSummaryTitle: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.dwelling-info.watercraft-summary-title',
        defaultMessage: 'Watercraft Summary'
    },
    allDwellingCoveragesTitle: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.dwelling-info.all-dwelling-coverages-title',
        defaultMessage: 'Coverage Applied to All Dwellings'
    },
    optionalPropertyCoverages: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.dwelling-info.optional-property-coverages',
        defaultMessage: 'Optional Property Coverages'
    },
    optionalLiabilityCoverages: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.dwelling-info.optional-liability-coverages',
        defaultMessage: 'Optional Liability Coverages'
    }
})
