/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useContext } from 'react';
// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
    ModalNext,
    ModalBody
} from '@jutro/components';
import { Flex } from '@jutro/layout';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { WMICButton, WMICModalHeader } from 'wmic-pe-components-platform-react';
import metadata from './openHelpModalPopup.metadata.json5';
import styles from './openHelpModalPopup.module.scss';
import headerMessages from '../WMICPageHeaderComponent.messages';
import messages from './openHelpModalPopup.messages';


function OpenHelpModalPopup(props) {
    const {
        actionBtnLabel,
        isOpen,
        onResolve,
    } = props;
    const translator = useContext(TranslatorContext);

    const handleSave = useCallback(() => {
        onResolve(actionBtnLabel);
    }, [actionBtnLabel, onResolve]);

    const getEmailContent = useCallback(() => {
        const emailLink = `mailto:${translator(messages.brokerEmailAddress)}`;
        return <a href={emailLink}>{translator(messages.brokerEmailAddress)}</a>;
    }, [translator]);

    const getBrokerPortalLink = useCallback(() => {
        return (
            <>
                <span className="gw-mr-1">{translator(messages.forMoreInfo)}</span>
                <a href={translator(messages.portalLink)} target="_blank">
                    {translator(messages.portalLinkText)}
                </a>
            </>
        );
    }, [translator]);

    const overrides = {
        brokerEmailContent: {
            content: getEmailContent()
        },
        brokerPortalNavigate: {
            content: getBrokerPortalLink()
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    const footerLayoutProps = {
        justifyContent: 'right',
        alignItems: 'middle',
        wrap: 'reverse'
    };

    const modalContainerClass = cx('wmicSelectAllLinksModal', styles.modalContainer);
    return (
        <ModalNext isOpen={isOpen} className={modalContainerClass}>
            <WMICModalHeader title={headerMessages.contactUs}/>
            <ModalBody>
                <div className={styles.modalBody}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        overrideProps={overrides}
                        classNameMap={resolvers.resolveClassNameMap}
                    />
                </div>
            </ModalBody>
            <Flex className={styles.modalFooter} {...footerLayoutProps}>
                <WMICButton onClick={handleSave} type="primary">
                    {actionBtnLabel}
                </WMICButton>
            </Flex>
        </ModalNext>
    );
}

OpenHelpModalPopup.propTypes = {
    actionBtnLabel: PropTypes.shape({}).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired,
};


export default OpenHelpModalPopup;
