import { defineMessages } from 'react-intl';

export default defineMessages({
    choose: {
        id: 'pe-platform.questionsets.question-set.-- Choose --',
        defaultMessage: '-- Choose --'
    },
    required: {
        id: 'pe-gw-portals-questionsets-js.answer.validation.This is a required field',
        defaultMessage: 'This is a required field'
    },
    invalidCharacter: {
        id: 'pe-gw-portals-questionsets-js.answer.validation.An invalid character was entered, such as a letter in a numeric field',
        defaultMessage: 'An invalid character was entered, such as a letter in a numeric field.'
    },
    headingPropertyQuestions: {
        id: 'quoteandbind.pup.views.qualifications.Property Questions',
        defaultMessage: 'Property Questions'
    },
    headingLiabilityQuestions: {
        id: 'quoteandbind.pup.views.qualifications.Liability Questions',
        defaultMessage: 'Liability Questions'
    },
    headingOcupationQuestions: {
        id: 'quoteandbind.pup.views.qualifications.Occupation Questions',
        defaultMessage: 'Occupation Questions'
    },
});
