import React, { useContext } from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { useAccordionValidation } from 'wmic-pe-portals-wizard-components-ui';
import WMICHODwellingPropertyDetails
    from '../WMICHODwellingPropertyDetails/WMICHODwellingPropertyDetails';
import WMICHODwellingAdditionalDetails
    from '../WMICHODwellingAdditionalDetails/WMICHODwellingAdditionalDetails';
import WMICHODwellingProtectionDetails
    from '../WMICHODwellingProtectionDetails/WMICHODwellingProtectionDetails';
import WMICHOAdditionalInterests from '../WMICHOAdditionalInterests/WMICHOAdditionalInterests';
import metadata from './WMICHODwellingDetailView.metadata.json5';
import './WMICHODwellingDetailView.messages';

function DwellingDetailView(props) {
    const { value, updateModel, isEditing, readOnly, showErrors, onValidate, selectedIndex } = props;

    const { wizardData : jobVM } = useContext(WizardContext);
    const { onValidateAccordion, isAccordionValid } = useAccordionValidation(onValidate);

    const commonDwellingAccordionProps = {
        jobVM,
        onValidate: onValidateAccordion,
        showErrors,
        onRiskViewChange: updateModel,
        readOnly: readOnly || !isEditing,
        isEditMode: isEditing,
        riskView: value,
        selectedIndex
    }
    const overrideProps = {
        propertyDetailsAccordion: {
            ...commonDwellingAccordionProps,
            isValid: isAccordionValid('propertyDetailsAccordionContent')
        },
        propertyDetailsAccordionContent: {
            ...commonDwellingAccordionProps
        },
        additionalDetailsAccordion: {
            ...commonDwellingAccordionProps,
            isValid: isAccordionValid('additionalDetailsAccordionContent')
        },
        additionalDetailsAccordionContent: {
            ...commonDwellingAccordionProps,
        },
        protectionDetailsAccordion: {
            ...commonDwellingAccordionProps,
            isValid: isAccordionValid('protectionDetailsAccordionContent')
        },
        protectionDetailsAccordionContent: {
            ...commonDwellingAccordionProps
        },
        dwellingAdditionalInterestsAccordion: {
            ...commonDwellingAccordionProps,
            isValid: isAccordionValid('dwellingAdditionalInterestsAccordionContent')
        },
        dwellingAdditionalInterestsAccordionContent: {
            ...commonDwellingAccordionProps,
            category: 'HODwellingAddlInt_HOE',
            canEdit: () => false
        },
    };

    const resolvers = {
        resolveCallbackMap: {},
        resolveComponentMap: {
            WMICHODwellingPropertyDetails,
            WMICHODwellingAdditionalDetails,
            WMICHODwellingProtectionDetails,
            WMICHOAdditionalInterests
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={value}
            overrideProps={overrideProps}
            onModelChange={updateModel}
            onValidationChange={onValidateAccordion}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    )
}

export default DwellingDetailView;
