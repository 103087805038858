import _ from 'lodash';
import React, { useContext } from 'react';

import { TranslatorContext } from '@jutro/locale';

import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import { WMICWizardViewPage } from 'wmic-pe-portals-wizard-components-ui';
import { WMICCPLocationCoverages } from 'wmic-pe-capability-gateway-quoteandbind-cp-react';
import { WMICGLPropertyLineCoverages } from 'wmic-pe-capability-gateway-quoteandbind-gl-react';

import WMICCPCoverageUtil from "wmic-pe-capability-gateway-quoteandbind-cp-react/util/WMICCPCoverageUtil";

import metadata from './WMICPolicyPropertyPage.metadata.json5';
import messages from './WMICPolicyPropertyPage.messages';

function WMICPolicyPropertyPage(props) {
    const {
        wizardData: { policyVM },
    } = props;

    const translator = useContext(TranslatorContext);
    const LOCATION_PATH = 'lobs.commercialProperty.coverables.locations';

    const commonOverrides = {
        'readOnly': true,
    }

    const iterableProps = () => {
        const locationOverrides = _.get(policyVM, `${LOCATION_PATH}.children`).map((location, index) => {
            const locationCoverages = WMICCPCoverageUtil.getCoveragesForLocation(
                _.get(policyVM, 'lobs.commercialProperty'),
                location.publicID.value
            );

            const locationOverride = {
                [`locationCoveragesIterableComponent${index}`]: {
                    'locationVM': location,
                    'locationIndex': index,
                    'baseData': policyVM.baseData,
                    locationCoverages,
                    jobVM: policyVM,
                    isBoundPolicy: true,
                    ...commonOverrides
                }
            }

            return {...locationOverride};
        })

        return Object.assign({}, ...locationOverrides);
    }

    const overrideProps = {
        '@field': {
            parentNode: policyVM,
            ...commonOverrides
        },
        locationCoveragesIterableAccordionContainer: {
            data: _.get(policyVM, `${LOCATION_PATH}.children`, [])
        },
        propertyLineCoverage: {
            ...commonOverrides,
            isBoundPolicy: true,
            jobVM: policyVM,
        },
        ...iterableProps()
    };

    const resolvers = {
        resolveComponentMap: {
            WMICCPLocationCoverages,
            WMICGLPropertyLineCoverages
        }
    };

    return (
        <WMICWizardViewPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyVM}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
            />
        </WMICWizardViewPage>
    );
}

WMICPolicyPropertyPage.propTypes = wizardProps;
export default WMICPolicyPropertyPage;
