/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { WMICDropdownSelect } from 'wmic-components-platform-react';
import _ from 'lodash';
import styles from './WMICCustomDropdownSelect.module.scss';

function WMICCustomDropdownSelect(props) {
    const [nameOfClass, setNameOfClass] = useState('wmicWizardInputLabel wmicInactive');
    const [isOnFocus, setIsOnFocus] = useState(false);

    useEffect(() => {
        if (isOnFocus) {
            setNameOfClass('wmicWizardInputLabel wmicActive');
        } else {
            setNameOfClass(`wmicWizardInputLabel ${_.isUndefined(props.value) ? 'wmicInactive' : 'wmicActiveNotFocused'}`);
        }
    }, [props, isOnFocus]);

    return (
        <WMICDropdownSelect
            {...props}
            onFocus={(value) => {
                setIsOnFocus(true);
                if (props.onFocus) {
                    props.onFocus(value);
                }
            }}
            onBlur={(value) => {
                setIsOnFocus(false);
                if (props.onBlur) {
                    props.onBlur(value);
                }
            }}
            labelClassName={`${props.labelClassName} ${nameOfClass}`}
            labelContainerClassName='wmicWizardLabelContainer customLabel'
            label={props.label}
            className={`${props.className} ${styles.wmicQbSelect}`}
        />
    );
}

export default WMICCustomDropdownSelect;