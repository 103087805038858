import { defineMessages } from 'react-intl';

export default defineMessages({
    accountTitle: {
        id: 'wmic.pe-portal.components.transactionWizardTitle.transactionWizardTitle.Account:',
        defaultMessage: 'Account:'
    },
    personalAuto: {
        id: 'wmic.pe-portal.components.transactionWizardTitle.transactionWizardTitle.PersonalAuto Policy',
        defaultMessage: 'Automobile ({policyNumber})'
    },
    personalUmbrella: {
        id: 'wmic.pe-portal.components.transactionWizardTitle.transactionWizardTitle.PersonalUmbrella Policy',
        defaultMessage: 'Personal Umbrella ({policyNumber})'
    },
    homeowners: {
        id: 'wmic.pe-portal.components.transactionWizardTitle.transactionWizardTitle.HomeOwners Policy',
        defaultMessage: 'Personal Property ({policyNumber})'
    },
    commercialPackage: {
        id: 'wmic.pe-portal.components.transactionWizardTitle.transactionWizardTitle.Commercial Lines Policy',
        defaultMessage: 'Commercial Lines Small Business ({policyNumber})'
    },
    editAccount: {
        id: 'wmic.pe-portal.components.transactionWizardTitle.transactionWizardTitle.Edit-account:',
        defaultMessage: 'Edit account'
    },
    editPolicy: {
        id: 'agent.views.policy-detail.Edit Policy',
        defaultMessage: 'Edit Policy'
    },
    changePolicy: {
        id: 'agent.views.policy-detail.Change Policy',
        defaultMessage: 'Change'
    },
    cancelPolicy: {
        id: 'agent.views.policy-detail.Cancel Policy',
        defaultMessage: 'Cancel'
    },
    lapsePolicy: {
        id: 'agent.views.policy-detail.Lapse Policy',
        defaultMessage: 'Lapse'
    },
    revertLapse: {
        id: 'agent.views.policy-detail.Revert Lapse',
        defaultMessage: 'Revert Lapse'
    },
    lapseReason: {
        id: 'wmic.pe-portal.components.LapseRenewal.Reason for Lapsed Renewal Description',
        defaultMessage: "Insured's Request - signed Lapse Renewal on file"
    },
    automobile: {
        id: 'wmic.pe-portal.components.LapseRenewal.Automobile',
        defaultMessage: "Automobile"
    },
    revertLapseReason: {
        id: 'wmic.pe-portal.components.LapseRenewal.Revert Lapse Reason',
        defaultMessage: "Broker Request"
    },
    termTitle:{
        id: 'agent.views.policy-detail.Term',
        defaultMessage: 'Term'
    },
    cancel: {
        id: 'agent.views.policy-detail.Cancel',
        defaultMessage: 'Cancel'
    },
    goToTransaction: {
        id: 'agent.views.policy-detail.Go to Transaction',
        defaultMessage: 'Go to Transaction'
    }
});
