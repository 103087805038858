/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelForm, ViewModelServiceContext, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { CONSTANTS, Position, WMICZipMaskUtil, WMICRPCUtil, PAConstants } from 'wmic-pe-portals-utils-js';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { WMICAddressDetails } from 'wmic-pe-components-platform-react';
import WMICAdditionalInterestUtil from "wmic-pe-portals-utils-js/WMICAdditionalInterestUtil";
import WMICAdlIntGoldSearch from '../../../../wmic-pe-capability-gateway-quoteandbind-ho-react/components/WMICAdlIntGoldSearch/WMICAdlIntGoldSearch';
import messages from './WMICPAAdditionalInterests.messages';
import metadata from './WMICPAAdditionalInterests.metadata.json5';
import styles from './WMICPAAdditionalInterests.module.scss';

function WMICPAAdditionalInterests(props) {
    const {
        id,
        vehicleVM,
        skeletonStruct,
        updateVehicle,
        baseData,
        showErrors,
        setShowErrors,
        onValidate,
        isEditMode,
    } = props;

    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const { refreshData } = useDataRefresh();
    const { showConfirm } = useWizardModals();

    const [additionalInterestVM, setAdditionalInterestVM] = useState({ policyAdditionalInterest: {} });
    const [isAddNewAIButtonActive, setAddNewAIButtonActive] = useState(isEditMode);
    const [newAISubtype, setNewAISubtype] = useState(undefined);
    const [addressEqualsPNI, setAddressEqualsPNI] = useState(false);
    const [isGoldSearchVisible, setGoldSearchVisible] = useState(newAISubtype === CONSTANTS.Company);
    const [isGoldListContactSelected, setIsGoldListContactSelected] = useState(false);
    const [isAICompanyVisible, setAICompanyVisible] = useState(false);
    const [isAddNewCompanyOpen, setAddNewCompanyOpen] = useState(false);
    const [isAddNewCompanyButtonVisible, setAddNewCompanyButtonVisible] = useState(false);
    const [isEditingExistingAI, setEditingExistingAI] = useState(false);
    const [selectedAdditionalInterestIndex, setSelectedAdditionalInterestIndex] = useState(-1);
    const [displayReadOnlyAI, setDisplayReadOnlyAI] = useState(false);
    const [additionalInterestTypes, setAdditionalInterestTypes] = useState([]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    useEffect(()=> {
        setAddNewAIButtonActive(isEditMode);
    }, [isEditMode])

    const currentDate = new Date();
    const minDate = {
        day: currentDate.getDate() - CONSTANTS.BOT_PARAM_CONFIG.MAXIMUM_LEGAL_AGE,
        month: currentDate.getMonth(),
        year: currentDate.getFullYear()
    };
    const maxDate = {
        day: currentDate.getDate() - CONSTANTS.BOT_PARAM_CONFIG.MINIMUM_LEGAL_AGE,
        month: currentDate.getMonth(),
        year: currentDate.getFullYear()
    };

    const createNewInsured = (subType) => {
        const primaryAddress =  {
            country: CONSTANTS.COUNTRY.CA,
            addressType: CONSTANTS.MAILING_ADDRESS
        };
        const contact = {
            subtype: subType,
            minDOB: minDate,
            maxDOB: maxDate,
            primaryAddress: primaryAddress
        };
        return contact;
    }

    const addAdditionalInterest = (item) => {
        setDisplayReadOnlyAI(false);
        setAddNewCompanyButtonVisible(false);
        setNewAISubtype(item);
        clearVM(item);
        setGoldSearchVisible(item === CONSTANTS.Company);
        setAddNewCompanyOpen(item === CONSTANTS.Company);
        setIsGoldListContactSelected(false);
        setAICompanyVisible(false);
        setAddNewAIButtonActive(false);
    };

    const clearVM = (subtype) => {
        const contact = createNewInsured(subtype);
        const additionalInterest = {
            additionalInterestCategory: 'PAVhcleAddlInterest',
            policyAdditionalInterest: viewModelService.create(
                contact,
                'pc',
                'wmic.edge.ca.capabilities.policycommon.accountcontact.dto.AccountContactDTO'
            ).value,
            mailSeparateNotice: false
        };
        // this is the hookup for server aspects
        if (skeletonStruct.length > 0 && !_.isUndefined(_.get(skeletonStruct[0], 'vehicleAdditionalInterests_WMIC[0].metaDataMap'))) {
            additionalInterest.metaDataMap = _.get(skeletonStruct[0], 'vehicleAdditionalInterests_WMIC[0].metaDataMap');
        }
        const newVM = viewModelService.create(
            additionalInterest,
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.dto.VehicleAdditionalInterestsDTO_WMIC'
        );
        setAdditionalInterestVM(newVM);
        getAdditionalInterestTypes(newVM);
    }

    const handleChangeAIType = (value, path) => {
        const newAdditionalInterestVM = viewModelService.clone(additionalInterestVM);
        _.set(newAdditionalInterestVM, path, value);
        WMICAdditionalInterestUtil.clearAdditionalInterestContactInfo(newAdditionalInterestVM);
        setAdditionalInterestVM(newAdditionalInterestVM);
        
        setGoldSearchVisible(newAISubtype === CONSTANTS.Company);
        setAICompanyVisible(false);
        setIsGoldListContactSelected(false);
        refreshData();
    }

    const handleAddressEqualsChange = (value) => {
        if (value) {
            const address = _.get(baseData, 'policyAddress.value');
            writeValue(address, 'policyAdditionalInterest.primaryAddress');
        }
        else {
            writeValue({
                country: CONSTANTS.COUNTRY.CA,
                addressType: CONSTANTS.MAILING_ADDRESS
            }, 'policyAdditionalInterest.primaryAddress');
        }
        setAddressEqualsPNI(value);
    }

    const handleAddNewCompanyClick = (value) => {
        setAICompanyVisible(true);
        setAddNewCompanyOpen(true);
        setGoldSearchVisible(false);
    }

    const handleCancelAddCompanyClick = (value) => {
        setAICompanyVisible(false);
        setAddNewCompanyOpen(false);
        setGoldSearchVisible(true);
        setIsGoldListContactSelected(false);
        clearContactInfo();
    }

    const isAdditionalInterestValid = (additionalInterest) => additionalInterest?.aspects?.valid && additionalInterest?.aspects?.subtreeValid

    const handleSaveAdditionalInterest = () => {
            if (!(isComponentValid && isAdditionalInterestValid(additionalInterestVM))) {
            setShowErrors(true);
            return;
        }

        const newAI = viewModelService.clone(additionalInterestVM);

        if (isEditingExistingAI) {
            _.set(vehicleVM, `vehicleAdditionalInterests_WMIC.children[${selectedAdditionalInterestIndex}].value`, newAI.value);
        }
        else {
            if (!vehicleVM.vehicleAdditionalInterests_WMIC.value) {
                vehicleVM.vehicleAdditionalInterests_WMIC.value = [];
            }
            vehicleVM.vehicleAdditionalInterests_WMIC.pushElement(newAI);
        }
        vehicleVM.isUnderEditing = true;
        updateVehicle(vehicleVM);
        clearVM(newAISubtype);
        setNewAISubtype("");
        setAddressEqualsPNI(false);
        setAddNewAIButtonActive(true);
        setEditingExistingAI(false);
    }

    const handleCancelAdditionalInterest = () => {
        setNewAISubtype("");
        setEditingExistingAI(false);
        clearVM(newAISubtype);
        setAddressEqualsPNI(false);
        setAddNewAIButtonActive(true);
        setAddNewCompanyButtonVisible(false);
    }

    const handleRemoveAdditionalInterest = async (aiData, aiIndex) => {
        const response = await showConfirm({
            title: translator(messages.removeAdditionalInterestShort),
            message: translator(messages.removeAdditionalInterestLong)
        })

        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            vehicleVM.vehicleAdditionalInterests_WMIC.value.splice(aiIndex, 1);
            vehicleVM.isUnderEditing = true;
            setDisplayReadOnlyAI(false);
            updateVehicle(vehicleVM);
            setAddNewAIButtonActive(true);
        }
    }

    const viewReadOnlyAdditionalInterest = (ai) => {
        setAdditionalInterestVM(ai);
        setAICompanyVisible(ai.policyAdditionalInterest.subtype.value === CONSTANTS.Company);
        setDisplayReadOnlyAI(true);
        refreshData();
    }

    const handleGoldContactSelected = (goldContactInfo) => {
        goldContactInfo.address.displayName = goldContactInfo.displayAddress;
        const newAdditionalInterestVM = viewModelService.clone(additionalInterestVM);
        _.set(newAdditionalInterestVM, 'policyAdditionalInterest.primaryAddress', goldContactInfo.address);
        _.set(newAdditionalInterestVM, 'policyAdditionalInterest.contactName', goldContactInfo.companyName);
        _.set(newAdditionalInterestVM, 'policyAdditionalInterest.displayName', goldContactInfo.companyName);
        _.set(newAdditionalInterestVM, 'policyAdditionalInterest.addressBookUID_WMIC', goldContactInfo.contactAddressBookUID)
        setAdditionalInterestVM(newAdditionalInterestVM);

        setAICompanyVisible(true);
        setAddNewCompanyOpen(true);
        setGoldSearchVisible(false);
        setAddressEqualsPNI(false);
        setIsGoldListContactSelected(true);
    }

    const handleGoldListSearched = () => {
        setAddNewCompanyButtonVisible(true);
    }

    const clearContactInfo = () => {
        const newAdditionalInterestVM = viewModelService.clone(additionalInterestVM);
        WMICAdditionalInterestUtil.clearAdditionalInterestContactInfo(newAdditionalInterestVM);
        setAdditionalInterestVM(newAdditionalInterestVM);
    }

    const getExistingAIDisplayName = (existingAI) => {
        if (existingAI.policyAdditionalInterest.subtype.value === CONSTANTS.Person
            && (existingAI.policyAdditionalInterest.firstName.value
            || existingAI.policyAdditionalInterest.lastName.value)) {
            return `${existingAI.policyAdditionalInterest.firstName.value} ${existingAI.policyAdditionalInterest.lastName.value}`;
        }
        if (existingAI.policyAdditionalInterest.subtype.value === CONSTANTS.Company
            && existingAI.policyAdditionalInterest.contactName.value) {
            return existingAI.policyAdditionalInterest.contactName.value;
        }
        return '';
    }

    const writeValue = (value, path) => {
        const newAdditionalInterestVM = viewModelService.clone(additionalInterestVM);
        _.set(newAdditionalInterestVM, path, value);
        setAdditionalInterestVM(newAdditionalInterestVM);
    }

    const isAddingNewAI = useMemo(() => !_.isEmpty(newAISubtype), [newAISubtype]);

    const swapZipPlaceHolder = useCallback(() => {
        const country = _.get(additionalInterestVM, 'policyAdditionalInterest.primaryAddress.country.value');
        const postalCode = _.get(additionalInterestVM, 'policyAdditionalInterest.primaryAddress.postalCode');
        return WMICZipMaskUtil.getZipMask(country, postalCode);
    }, [additionalInterestVM]);

    // TODO: Move this to BE if this component is converted to WMICListView
    const getAdditionalInterestTypes = useCallback((newVM) => {
        const isRPCEffective = WMICRPCUtil.getIsRPCEffective(_.get(vehicleVM, 'ratingJurisdiction.value.code'), _.get(baseData, 'rateAsOfDate.value'), '1698');
        let availableValues = _.get(newVM, 'additionalInterestType.aspects.availableValues', []);

        if (!isRPCEffective) {
            availableValues =
                _.get(newVM, 'additionalInterestType.aspects.availableValues', []).filter(
                    (option) =>![PAConstants.additionalInterestAssignee, CONSTANTS.ADDITIONAL_INTEREST_TYPES.MORTGAGEE].includes(option.code)
                );
        }

        const aiTypeKeys = availableValues.map((aiTypeKey) => ({
                ...aiTypeKey,
                name: {
                    id: aiTypeKey.name,
                    defaultMessage: aiTypeKey.name
                }
            }))

        setAdditionalInterestTypes(aiTypeKeys);
    }, [baseData, vehicleVM]);

    const overrideProps = {
        '@field': {
            parentNode: additionalInterestVM,
            readOnly: displayReadOnlyAI
        },
        companyName: {
            readOnly: displayReadOnlyAI || isGoldListContactSelected
        },
        newAIButton: {
            onItemClick: addAdditionalInterest,
            items: [
                { id: 'newPerson', text: translator(messages.aiPerson), icon: 'mi-person', code: CONSTANTS.Person },
                { id: 'newCompany', text: translator(messages.aiCompany), icon: 'mi-home', code: CONSTANTS.Company },
            ],
            disabled: !isAddNewAIButtonActive,
            visible: isEditMode
        },
        newAIFormWrapper: {
            visible: isAddingNewAI || displayReadOnlyAI
        },
        additionalInterestType: {
            onValueChange: handleChangeAIType,
            availableValues: additionalInterestTypes,
        },
        goldSearchContainer: {
            visible: isGoldSearchVisible && !isGoldListContactSelected
        },
        goldSearch: {
            additionalInterestVM,
            onGoldContactSelected: handleGoldContactSelected,
            onGoldListSearched: handleGoldListSearched
        },
        newAIPersonContainer: {
            visible: newAISubtype === CONSTANTS.Person || (displayReadOnlyAI && !isAICompanyVisible),
        },
        newAICompanyContainer: {
            visible: (newAISubtype === CONSTANTS.Company && !isGoldSearchVisible) || isAICompanyVisible || (displayReadOnlyAI && isAICompanyVisible),
        },
        addNewCompanyButton: {
            visible: isGoldSearchVisible && isAddNewCompanyButtonVisible
        },
        cancelAddCompanyButton: {
            visible: !isGoldSearchVisible && isAddNewCompanyOpen && !displayReadOnlyAI
        },
        personANIAddressEqualsPNIAddress: {
            value: addressEqualsPNI,
            visible: !displayReadOnlyAI
        },
        personAddressLookup: {
            addressVM : _.get(additionalInterestVM, 'policyAdditionalInterest.primaryAddress'),
            hideButtons : true,
            hideBorder : true,
            readOnly: addressEqualsPNI || displayReadOnlyAI,
            showErrors,
            onValidate: setComponentValidation
        },
        companyAddressLookup: {
            addressVM : _.get(additionalInterestVM, 'policyAdditionalInterest.primaryAddress'),
            hideButtons : true,
            hideBorder : true,
            readOnly: addressEqualsPNI || displayReadOnlyAI || isGoldListContactSelected,
            showErrors,
            onValidate: setComponentValidation
        },
        companyZip: {
            mask: swapZipPlaceHolder()
        },
        cancelAIButton: {
            visible: !displayReadOnlyAI
        },
        saveAIButton: {
            visible: !displayReadOnlyAI
        },
        additionalInterestDataList: {
            VMList: _.get(vehicleVM, `vehicleAdditionalInterests_WMIC.children`, []),
            VMData: [
                {
                    headerText: translator(messages.additionalInterestName),
                    getData: getExistingAIDisplayName
                },
                {
                    headerText: translator(messages.additionalInterestType),
                    path: 'additionalInterestType'
                },
                {
                    headerText: translator(messages.certificateRequired),
                    path: 'certificateRequired'
                }
            ],
            onRemoveAction: handleRemoveAdditionalInterest,
            selectedCardIndex: selectedAdditionalInterestIndex,
            updateSelectedCardIndex: (index) => setSelectedAdditionalInterestIndex(index),
            isEditing: isAddingNewAI,
            readOnly: !isEditMode,
            onClickAction: viewReadOnlyAdditionalInterest
        },
    };

    const resolvers = {
        resolveComponentMap: {
            WMICAdlIntGoldSearch,
            WMICAddressDetails
        },
        resolveCallbackMap: {
            onAddressEqualsChange: handleAddressEqualsChange,
            onAddNewCompanyClick: handleAddNewCompanyClick,
            onCancelAddCompanyClick: handleCancelAddCompanyClick,
            onSaveAdditionalInterest: handleSaveAdditionalInterest,
            onCancelAdditionalInterest: handleCancelAdditionalInterest
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            model={additionalInterestVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            onValueChange={writeValue}
            showErrors={showErrors}
            onValidationChange={setComponentValidation}
        />
    );
}

WMICPAAdditionalInterests.propTypes = {
    vehicleVM: PropTypes.shape({}),
    skeletonStruct: PropTypes.shape({}),
    category: PropTypes.string.isRequired,
    isEditMode: PropTypes.bool.isRequired
};

WMICPAAdditionalInterests.defaultProps = {
    vehicleVM: {},
    skeletonStruct: {}
};

export default WMICPAAdditionalInterests;
