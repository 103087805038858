import { defineMessages } from 'react-intl';

export default defineMessages({
    coveragesTitle: {
        id: 'wmic.endorsement.common.wizard.step.Coverages',
        defaultMessage: 'Coverages'
    },
    thirdPartyLiabilityWarning: {
        id: 'wmic.quoteandbind.pa.views.pa-coverage-term.Third Party Liability Applies to All vehicles',
        defaultMessage: 'Third party liability limits must be the same for all vehicles. Any change will update all other vehicles'
    }
});
