/* eslint-disable prefer-template */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
export default {
    isFuelTypeAvailable: (heaterType) => {
        const suiteMetadata = window.wmic.viewmodel.metadata;
        const fuelTypeTypelistCodes = suiteMetadata.get('pc').types.getTypelist('FuelType_WMIC').codes;
        return heaterType && fuelTypeTypelistCodes.some(
            (item) => item.belongsToCategory({code: heaterType.code, typelist: {name: 'HeaterType_WMIC'}}));
    }
};
