import { defineMessages } from 'react-intl';

export default defineMessages({
    additionalInterests : {
        id : 'wmic-pe-capability-gateway-common-ho-react.WMICHODwellingCovAdditionalInterests.Additional Interests',
        defaultMessage:  'Additional Interests'
    },

    descriptionOfInterest : {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHODwellingCovAdditionalInterests.Description of Interest',
        defaultMessage: 'Description of Interest'
    }
});
