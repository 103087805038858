/* eslint-disable no-secrets/no-secrets */
const WMICHOConstants = (() => ({
        homeownerOptionCoverageType: 'HODW_Optional_HOE',
        conditionAsCoverageType: 'HO_ConditionAsCoverage_WMIC',
        waiverOfDeductibleCovPublicID: 'HO_WaiverOfDeductibleCov',
        homeownerPolicyType: 'HO3',
        mobileHomeownerPolicyType: 'Mobile_wmic',
        rentersPolicyType: 'HO4',
        condominiumPolicyType: 'HO6',
        revenuePropertyPolicyType: 'Basic_wmic',
        seasonalPolicyType: 'Seasonal_wmic',
        extensionPolicyType: 'Personal_Property_Extensions_WMIC',
        csaLabelYesCode: 'yes',
        csaLabelNoCode: 'no',
        coverageTypeBroad: 'broad',
        coverageTypeBasic: 'basic',
        coverageTypeSpecial: 'special',
        coverageTypeHomeowner: 'homeowner',
        coverageTypeComprehensive: 'comprehensive',
        exteriorWallFinishType: 'exterior_wall_finish_type',
        exteriorWallFramingType: 'exterior_wall_framing_type',
        kitchensMaterialOrFeature: 'kitchens',
        bathroomsBasementMaterialOrFeature: 'bathrooms_basement',
        bathroomsExcludingBasementMaterialOrFeature: 'bathrooms_excluding_basement',
        riskSubTypeDwelling: 'dwelling',
        riskSubTypeWatercraft: 'watercraft',
        coverageNameDwellingA: 'Dwelling (A)',
        coverageNameOtherStructuresB: 'Other Structures (B)',
        coverageNameResidenceA: 'Residence (A)',
        coverageNameAdditionalBuildingsAndStructuresB: 'Additional Buildings & Structures (B)',
        coverageNamePersonalProperty: 'Personal Property',
        coverageNameAdditionalLivingExpenses: 'Additional Living Expenses',
        coverageNamePersonalLiability: 'Personal Liability',
        coverageNamePersonalLiabilityE: 'Liability (E)',
        coverageNameMedicalPayments: 'Medical Payments',
        coverageNameVoluntaryPaymentsforDamagetoProperty: 'Voluntary Payments for Damage to Property',
        additionalLivingExpenseCoveragePublicID: 'HO_AddlLivingExpCov',
        packageCoveragePublicID: 'HO_PackageCov',
        dwellingCoveragePublicID: 'HO_DwellingCov',
        otherStructuresCoveragePublicID: 'HO_OtherStructuresCov',
        personalPropertyCoveragePublicID: 'HO_PersonalPropertyCov',
        dwellingCoverageLimitPatternCode: 'HO_DwellingCovLmt',
        additionalLivingExpenseCoverageLimitPatternCode: 'HO_AddlLivingExpCovLmt',
        otherStructuresCoverageLimitPatternCode: 'HO_OtherStructuresCovLmt',
        personalPropertyCoverageLimitPatternCode: 'HO_PersonalPropertyCovLmt',
        personalPropertyCoverageReplacementCost: 'HO_PersonalPropertyCovReplacementCost',
        personalPropertyCoverageACVPatternCode: 'HO_PersonalPropertyCovACV',
        dwellingCoverageLimitAdjustmentPatternCode: 'HO_DwellingCovLmtAdjustment',
        otherStructuresCoverageLimitAdjustmentPatternCode: 'HO_OtherStructuresCovAddlLmt',
        personalPropertyCoverageLimitAdjustmentPatternCode: 'HO_PersonalPropertyCovAdjLmt',
        personalLiabilityCoverageLimitPatternCode: 'HO_PersonalLiabilityCovLmt',
        medicalPaymentsCoverageLimitPatternCode: 'HO_MedicalPaymentsCovLimit',
        voluntaryPayPropDamageCoverageLimitPatternCode: 'HO_VoluntaryPayPropDamageLimit',
        packageCoverageDeductiblePatternCode: 'HO_PackageCovDed_CA',
        packageRatingMethodPatternCode: 'HO_PackageRatingMethod',
        packageCoverageGRCPatternCode: 'HO_PackageCovGRC',
        packageCoverageSingleLimitPatternCode: 'HO_PackageCovSingleLimit',
        personalLiabilityCoveragePublicID: 'HO_PersonalLiabilityCov',
        medicalPaymentsCoveragePublicID: 'HO_MedicalPaymentsCov',
        voluntaryPayPropDamageCoveragePublicID: 'HO_VoluntaryPayPropDamage',
        hailWindstormCoveragePublicID: 'HO_HailWindCov',
        buildingBylawsCoveragePublicID: 'HO_BylawsCov',
        identityFraudCoveragePublicID: 'HO_IdentityFraudCov',
        creditCardCoveragePublicID: 'HO_CreditCardCov_CA',
        fireDepartmentChargesCoveragePublicID: 'HO_FireDepartmentChargesCov_WMIC',
        foodSpoilageCoveragePublicID: 'HO_FoodSpoilageCov',
        condominiumUnitOwnerLossAssessmentPublicID: 'HO_CondoUnitOwnersLossAssessmentCov',
        legalExpenseCoveragePublicID: 'HO_LegalExpenseCov',
        legalExpenseCoverageLimitPatternCode: 'HO_LegalExpenseCovLimit',
        condominiumUnitOwnerImprovementPublicID: 'HO_CondoUnitOwnersImprovementCov',
        condominiumUnitOwnerAdditionalCoveragePublicID: 'HO_CondoAdditionalCov',
        homeBasedBusinessCoveragePublicID: 'HO_HomeBusinessCov',
        additionalBuildingStructAllRiskCoveragePublicID: 'HO_AdditionalBuildingsAndStructuresAllRiskCov',
        contentsOffPremisesCovPublicID: 'HO_ContentsOffPremisesCov',
        homeBasedBusinessCoverageLimitPatternCode: 'HO_HomeBusinessCovLimit',
        waterDefenceCoveragePublicID: 'HO_WaterDefenceCov',
        hailWindstormCoverageDeductiblePatternCode: 'HO_HailWindCovDed',
        buildingBylawsCoverageLimitPatternCode: 'HO_BylawsCovLimit',
        buildingBylawsCoverageBCLimitPatternCode: 'HO_BylawsCovBCLimit',
        identityFraudCoverageDeductiblePatternCode: 'HO_IdentityFraudCovDed',
        identityFraudCoverageLimitPatternCode: 'HO_IdentityFraudCovLimit',
        fireDepartmentChargesCoverageLimitPatternCode: 'HO_FireDepartmentChargesCovLimit_WMIC',
        foodSpoilageCoverageLimitPatternCode: 'HO_FoodCovAdditionalLmt',
        condominiumUnitOwnerLossAssessmentCovLimitPatternCode: 'HO_CondoUnitOwnersLossAssessmentCovLmt',
        condominiumUnitOwnerImprovementCovLimitPatternCode: 'HO_CondoUnitOwnerImprovementCovLmt',
        condominiumUnitAdditionalCovLimitPatternCode: 'HO_CondoAdditionalCovLmt',
        businessPersonalPropertyCovPublicID: 'HO_BusinessPersPropCov',
        condoDeductibleCovPublicID: 'HO_CondoDeductibleCov',
        dwellingUnderConstructionCovPublicID: 'HO_DwellingUnderConstCov',
        earthquakeCovPublicID: 'HO_EarthquakeCov',
        glassBreakageCovPublicID: 'HO_GlassCov',
        homeEquipmentCovPublicID: 'HO_HomeEquipmentCov',
        overlandWaterCovPublicID: 'HO_OverlandWaterCov',
        rentalIncomeCovPublicID: 'HO_RentalIncomeCov',
        serviceLineCovPublicID: 'HO_ServiceLineCov',
        sewerBackupCovPublicID: 'HO_SewerBackupCov',
        shortTermRentalCovPublicID: 'HO_ShortTermRentalEndorsement_WMIC',
        additionalBuildingBasicCovPublicID: 'HO_AdditionalBuildingBasicCov',
        specialLimitsEnhancementCovPublicID: 'HO_SpecialLimitsEnhancement',
        waterDefenceCovPublicID: 'HO_WaterDefenceCov',
        personalCyberCovPublicID: 'HO_PersonalCyberCov_WMIC',
        replaceCostNoRebuildPublicID: 'HO_ReplaceCostNoRebuild',
        fuelOilOverFlowCovPublicID: 'HO_FuelOilOverFlowCov',
        ingroundPoolSpaCovPublicID: 'HO_IngroundPoolSpaCov',

        sewerBackupCovLimitPatternCode: 'HO_SewerBackupCovLimit',
        sewerBackupCovDedPatternCode: 'HO_SewewBackupCovDed',
        businessPersPropCovLimitPatternCode: 'HO_BusinessPersPropCovLmt',
        earthquakeCondoDeductible: 'HO_CondoDedEathquakeCov',
        earthquakeCondoAddlLimit: 'HO_EarthquakeCov_CondoAdlLimit',
        earthquakeCondoLossLimit: 'HO_EarthquakeCov_CondoLossLimit',
        earthquakeCondoUnitLimit: 'HO_EarthquakeCov_CondoUnitLimit',
        earthquakeCondoLossDed: 'HO_EarthquakeCov_CondoLossDed',
        earthquakeCovDedPatternCode: 'HO_EarthquakeCovDed_CA',
        earthquakeCondoAddlDed: 'HO_EarthquakeCov_CondoAddDed',
        earthquakeCondoUnitDed: 'HO_EartquakeCov_CondoUnitDed',
        earthquakeDwellingAInsuredDeductible: 'HO_EarthquakeCov_CovADed',
        earthquakePrivateStructuresBInsuredDeductible: 'HO_EathquakeCov_CovBDed',
        earthquakePersonalPropertyCInsuredDeductible: 'HO_EarthquakeCov_CovCDed',
        earthquakeLossofUseDInsuredDeductible: 'HO_EarthquakeCov_CovDDed',
        earthquakeDwellingAInsuredLimit: 'HO_EarthquakeCov_CovALimit',
        earthquakePrivateStructuresBInsuredLimit: 'HO_EarthquakeCov_CovBLimit',
        earthquakePersonalPropertyCInsuredLimit: 'HO_EarthquakeCov_CovCLimit',
        earthquakeLossofUseDInsuredLimit: 'HO_EarthquakeCov_CovDLimit',
        earthquakeLimitType: 'HO_EarthquakeCovLimitType',
        earthquakeDeductible: 'HO_EarthquakeCovDed_CA',
        glassCovDedPatternCode: 'HO_GlassCovDed',
        homeEquipmentCovLimitPatternCode: 'HO_HomeEquipmentCovLmt',
        homeEquipmentCovDedPatternCode: 'HO_HomeEquipmentCovDed',
        rentalIncomeCovLimitPatternCode: 'HO_RentalIncomeCovLimit',
        serviceLineCovInsLimitPatternCode: 'HO_ServiceLineCovInsLimit',
        shortTermRentalCovOptPatternCode: 'HO_ShortTermRentalCovOpt',
        shortTermRentalTheftDedPatternCode: 'HO_ShortTermRentalTheftDed',
        shortTermRentalCovPremPatternCode: 'HO_ShortTermRentalCovPrem',
        condoDeductibleCovLimitPatternCode: 'HO_CondoDeductibleCovLimit',
        petAccidentWordingCoveragePublicID: 'HO_PetAccidentWording',

        packageRatingMethodPreferred: 'preferred',
        packageRatingMethodStandard: 'standard',
        packageRatingMethod: 'Rating Method',

        residenceTypeBarelandCondo: 'BarelandCondo_WMIC',
        residenceTypeMobile: 'Mobile',
        residenceTypeManufactured: 'Manufactured_wmic',
        residenceTypeMiniHome: 'Minihome_wmic',
        residenceTypeMobileMiniHome: 'MobileMinihome_WMIC',
        residenceTypeLaneway: 'Laneway_WMIC',
        residenceTypeOther: 'Other_wmic',
        residenceTypeAptMercantile: 'AptMercantile_WMIC',
        residenceTypeDuplex: 'Duplex',
        residenceTypeTriplex: 'Triplex',
        residenceTypePlex4: 'Plex4_wmic',
        residenceTypePlex5: 'Plex5_wmic',
        residenceTypePlex6: 'Plex6_wmic',

        occupancyTypeTenant: 'tenant',
        occupancyTypeVacant: 'vacant',
        occupancyTypeUnderRenovation: 'underReno_WMIC',
        occupancyTypeOther: 'other_WMIC',
        occupancyTypeUnderConstruction: 'inconst',
        occupancyTypeImmediateFamily: 'immfam_wmic',

        coverageForOtherStructure: 'otherStructure',

        logFrameType: 'log',
        fireFollowingEarthquakeCovDed: 'HO_FireFollowingEarthquakeCovDed',
        sewerBackupCovPolLmtInd: 'HO_SewerBackupCovPolLmtInd',
        ho_AB: 'AB',
        ho_BC: 'BC',
        ho_SK: 'SK',
        ho_MB: 'MB',
        ho_ON: 'ON',
        ho_NB: 'NB',
        ho_NS: 'NS',
        ho_PE: 'PE',
        ho_YT: 'YT',
        ho_SEO: 'SEO',
        ho_NWO: 'NWO',
        ho_QC: 'QC',
        foundLowerPremium: 'found_lower_premium_wmic',
        condoDeductibleCovLimitChosenTerm: '75000.0000',
        condoDeductibleCovLimitChosenTermValue: '75,000',
        descInterestHerMajesty: 'DescriptionInterest_HerMajestyTheQueen',
        descInterestOther: 'DescriptionInterest_Other',
        descInterestValueAsHerMajesty: 'Her Majesty The Queen',
        descInterestValueAsOther: 'Other',
        condoDeductibleCovLimitDirectValue: '75000',
        cancellationReasonCodeFlatrewrite: 'flatrewrite',
        cancellationReasonCodeMidtermrewrite: 'midtermrewrite',
        cancellationReasonCodePlacedwithanotherbroker: 'placed_with_another_broker_wmic',
        cancellationReasonCodeCombined: 'combined_wmic',
        cancellationReasonCodeSeasonal: 'seasonal_WMIC',
        watercraftComponentTypeBoat: 'HOWatercraftBoat_WMIC',
        personalWatercraft: 'PersonalWatercraft',
        outboardMotor: 'OutboardMotor',
        boatEquipmentTrailer: 'BoatEquipmentTrailer',
        watercraftCovValuationType: 'HO_WatercraftCovValuationType',
        optionValued: 'HOValued_WMIC',
        seasonalDwelling: 'seas',
        ownerOccupied: 'owner',
        collectivelyOwnedLifeLeaseOrCooperativeProperty: 'HO_LifeLeasePropertyCov',
        lifeLeaseOrCooperativeDeductible: 'HO_LifeLeaseDeductibleCov',
        lifeLeaseOrCooperativeUnitOwnerImprovementsLimit: 'HO_LifeLeaseUnitOwnerImprovementsCovLimit',
        lifeLeaseOrCooperativeUnit: 'HO_LifeLeaseUnitCov',
        lifeLeaseOrCooperativeUnitOwnerImprovements: 'HO_LifeLeaseUnitOwnerImprovementsCov',
        lifeLeaseOrCooperativeExtensionOfLiability: 'HO_LifeLeaseLiabilityExtensionCov',
        lifeLeaseOrCooperativeDeductibleEarthquake: 'HO_TenantLifeLeaseDedEQCov',
        fireExplosionCovPublicID: 'HO_FireExpSmokFollEQ',
        fireExplosionDwellingAInsuredDeductible: 'HO_FireExpSmokFollEQ_CovADed',
        fireExplosionPrivateStructuresBInsuredLimit: 'HO_FireExpSmokFollEQ_CovBDed',
        fireExplosionLossofUseDInsuredDeductible: 'HO_FireExpSmokFollEQ_CovDDed',
        fireExplosionPersonalPropertyCInsuredDeductible: 'HO_FireExpSmokFollEQ_CovCDed',
        fullFoundationYesCode: 'Yes',
        fullFoundationNoCode: 'No',
        vacant: 'vacant',
        otherCode: 'other',
        yesCode: 'Yes',
        noCode: 'No',
        trueCode: 'true',
        falseCode: 'false',
        dwellingUsageTypeRental: 'rent',
        dwellingUsageTypePrimary: 'prim',
        bikesScootersATVsCode: 'MV_BikesScootsATV_CA_WMIC',
        saskLloydminsterFSA: 'S9V',
        limitedWatercraftTypes: ['Boat', 'Other', 'Sailboat'],
        electricBaseboardHeaterType: 'electricbaseboardnonportable',
        combinationFurnaceHeaterType: 'combinationfurnace',
        electricFuelType: 'electric',
        ADDITIONAL_INTEREST_CATEGORY: {
            DWELLING: 'HODwellingAddlInt_HOE',
            WATERCRAFT: 'HOWatercraftAddlInt_WMIC',
            LINE_ITEM: 'HOLineItemAddlInt_WMIC'
        }
    }))();

export default WMICHOConstants;
