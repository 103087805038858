/* eslint-disable max-len */
import React, {
    useContext, useCallback, useState, useEffect, useRef
} from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthentication } from 'wmic-digital-auth-react';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { LoadSaveService } from 'gw-capability-quoteandbind';
import { WMICRetrieveQuote } from 'gw-capability-quoteandbind-common-react';

function WMICQuickRetrieveQuotePage() {
    const { authHeader } = useAuthentication();
    const { search } = useLocation();
    const viewModelService = useRef(useContext(ViewModelServiceContext));

    const queryStrings = new URLSearchParams(search);
    const orderID = queryStrings.get('orderID');
    const [quoteRetrievalByOrder, updateQuoteRetrievalByOrder] = useState(undefined);

    useEffect(() => {
        const quoteRetrievalVM = viewModelService.current.create(
            { orderID: orderID },
            'pc',
            'wmic.edge.us.capabilities.quote.submission.dto.QuoteRetrievalByOrderDTO_WMIC'
        );
        const newQuoteRetrievalVM = viewModelService.current.clone(quoteRetrievalVM);
        updateQuoteRetrievalByOrder(newQuoteRetrievalVM);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const retrieveSubmission = useCallback(() => {
        return LoadSaveService.retrieveByOrder(quoteRetrievalByOrder.value, authHeader);
    }, [authHeader, quoteRetrievalByOrder]);

    if (!orderID || !quoteRetrievalByOrder) {
        return <></>;
    }

    return (
        <WMICRetrieveQuote
            retrieveSubmissionCallback={retrieveSubmission}
            retrieveSubmissionRequest={quoteRetrievalByOrder.value}
        />
    );
}

export default WMICQuickRetrieveQuotePage;