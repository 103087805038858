import { defineMessages } from 'react-intl';

export default defineMessages({
    resendDocuments: {
        id: 'agent.views.policy-detail-summary.Documents.Resend Documents',
        defaultMessage: 'Resend Documents'
    },
    selectTheDocuments: {
        id: 'agent.views.policy-detail-summary.Documents.Resend Documents Title',
        defaultMessage: 'Select the document(s) you wish to resend:'
    },
    policyDocuments: {
        id: 'agent.views.policy-detail-summary.Documents.Policy',
        defaultMessage: 'Policy Documents'
    },
    billingDocuments: {
        id: 'agent.views.policy-detail-summary.Documents.Billing',
        defaultMessage: 'Billing Documents'
    },
    epoi: {
        id: 'agent.views.policy-detail-summary.Documents.EPOI',
        defaultMessage: 'Electronic Proof of Insurance (EPOI)'
    },
    optedOut: {
        id: 'agent.views.policy-detail-summary.Documents.EPOI.Opted Out',
        defaultMessage: ' - Opted Out'
    },    
    verifyAccountEmail: {
        id: 'agent.views.policy-detail-summary.Documents.Verify the Account Email Address before resending documents.',
        defaultMessage: 'Verify the Account Email Address before resending documents.'
    },    
    accountEmailAddress: {
        id: 'agent.views.new-quote.Account Email Address',
        defaultMessage: 'Account Email Address'
    },     
    confirmEmailAddress: {
        id: 'agent.views.policy-detail-summary.Confirm email address',
        defaultMessage: 'Confirm email Address'
    },    
    invalidEmail: {
        id: 'agent.views.policy-detail-summary.Value must be a valid email address',
        defaultMessage: 'Value must be a valid email address'
    },    
    send: {
        id: 'agent.views.policy-detail-summary.Documents.Send',
        defaultMessage: 'Send'
    },    
    documentsSent: {
        id: 'agent.views.policy-detail-summary.Documents.Sent',
        defaultMessage: 'Selected documents have been sent. Your customer should receive a new email within 24 hours.'
    },    
    emailAddressDoesNotMatch: {
        id: 'agent.views.policy-detail-summary.Documents.The email address does not match',
        defaultMessage: 'The email address does not match'
    },
});