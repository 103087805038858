import { defineMessages } from 'react-intl';

export default defineMessages({
    industryCodeSupported: {
        id: "quoteandbind.views.quote-effective-date.operations.SupportedCode",
        defaultMessage: "Supported"
    },
    industryCodeUnsupported: {
        id: "quoteandbind.views.quote-effective-date.operations.UnSupportedCode",
        defaultMessage: "Unsupported"
    },
    search: {
        id: "quoteandbind.views.quote-effective-date.IndustryCodeSearch",
        defaultMessage: "Search"
    },
});