import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { TranslatorContext } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { JobUtil } from 'wmic-portals-utils-js';
import { PolicyService } from 'gw-capability-gateway';
import metadata from './ChangePolicy.metadata.json5';
import styles from './ChangePolicy.module.scss';
import messages from '../Cancellation/Cancellation.messages';
import './ChangePolicy.messages';


class ChangePolicy extends Component {
    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        onDoNotChangePolicy: PropTypes.func.isRequired,
        policyNumber: PropTypes.string.isRequired,
        policyData: PropTypes.shape({
            policyNumber: PropTypes.string.isRequired
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }).isRequired
    };

    static contextType = TranslatorContext;

    state = {
        changePolicySubmissionVM: {},
        submitted: true,
        startChangeTag: true
    };

    componentDidMount = () => {
        let { changePolicySubmissionVM } = this.state;
        const { policyData } = this.props;
        const model = {};
        changePolicySubmissionVM = this.createVM(model);
        const earliestChangeDate = new Date();
        earliestChangeDate.setDate(earliestChangeDate.getDate());
        earliestChangeDate.setHours(0, 0, 0, 0);
        _.set(changePolicySubmissionVM, 'earliestChangeDate', earliestChangeDate);
        _.set(changePolicySubmissionVM, 'effectiveDate.value', new Date());
        _.set(
            changePolicySubmissionVM,
            'actualExpirationDate',
            _.get(policyData, 'latestPeriod.expirationDate')
        );
        this.setState({
            changePolicySubmissionVM
        });
        this.validateEffectiveDate(changePolicySubmissionVM);
    };

    validateEffectiveDate = (policyChangeVM) => {
        const { policyData } = this.props;
        const { changePolicySubmissionVM } = this.state;
        const policySubmissionVM = _.isUndefined(policyChangeVM)
            ? changePolicySubmissionVM
            : policyChangeVM;

        const selectedEffectiveDate = _.get(policySubmissionVM, 'effectiveDate.value');
        const translator = this.context;
        const changeData = _.cloneDeep(policyData);
        const latestPeriodExpiryDate = moment(changeData.latestPeriod.expirationDate);
        const chosenEffectiveDate = moment(selectedEffectiveDate);
        const latestPeriodEffectiveDate = moment(changeData.latestPeriod.effectiveDate);
        const currentDate = moment(_.get(policySubmissionVM, 'earliestChangeDate'));
        if (
            chosenEffectiveDate.isBefore(latestPeriodEffectiveDate)
            || chosenEffectiveDate.isBefore(currentDate)
        ) {
            this.setState({
                startChangeTag: true,
                validationErrorMessage: translator(messages.selectAfterDate)
            });
            return;
        }
        if (chosenEffectiveDate.subtract(1, 'day').isAfter(latestPeriodExpiryDate)) {
            this.setState({
                startChangeTag: true,
                validationErrorMessage: translator(messages.selectBeforeDate)
            });
            return;
        }
        this.setState({
            startChangeTag: false,
            validationErrorMessage: ''
        });
    };

    writeValue = (value, path) => {
        const { changePolicySubmissionVM } = this.state;
        _.set(changePolicySubmissionVM, path, value);
        this.setState({ changePolicySubmissionVM });
    };

    createVM = (model) => {
        const { viewModelService } = this.props;
        return viewModelService.create(
            model,
            'pc',
            'edge.capabilities.gateway.job.policychange.dto.PolicyChangeDTO'
        );
    };

    onStartChangePolicy = async () => {
        const { authHeader, policyNumber } = this.props;
        const { changePolicySubmissionVM } = this.state;
        const dataTo = {
            description: _.get(changePolicySubmissionVM.value, 'description'),
            effectiveDate: _.get(changePolicySubmissionVM.value, 'effectiveDate'),
            policyNumber: policyNumber
        };
        await PolicyService.createNewPolicyChangeTransaction(dataTo, authHeader).then(
            (changeData) => {
                if (changeData) {
                    JobUtil.openJobInXCenter(changeData.jobNumber);
                }
            }
        );
    };

    onDoNotChangePolicy = () => {
        const { onDoNotChangePolicy } = this.props;
        if (onDoNotChangePolicy) {
            onDoNotChangePolicy();
        }
    };

    render() {
        const {
            submitted,
            changePolicySubmissionVM,
            startChangeTag,
            validationErrorMessage
        } = this.state;
        if (_.isEmpty(changePolicySubmissionVM)) {
            return null;
        }
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                onStartChangePolicy: this.onStartChangePolicy,
                onDoNotChangePolicy: this.onDoNotChangePolicy,
                validateEffectiveDate: this.validateEffectiveDate
            }
        };

        const overrides = {
            '@field': {
                showErrors: submitted,
                showOptional: true
            },
            startChange: {
                disabled: startChangeTag
            },
            effectiveDateId: {
                value:
                    _.get(changePolicySubmissionVM, 'effectiveDate.value')
                    || new Date(changePolicySubmissionVM.earliestChangeDate).toISOString(),
                minDate: new Date(changePolicySubmissionVM.earliestChangeDate),
                maxDate: new Date(changePolicySubmissionVM.actualExpirationDate),
                validationMessages:
                    validationErrorMessage !== '' ? [validationErrorMessage] : undefined,
                showErrors: true
            }
        };
        const readValue = (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                changePolicySubmissionVM,
                id,
                path,
                overrides
            );
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={changePolicySubmissionVM}
                onValueChange={this.writeValue}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                resolveValue={readValue}
            />
        );
    }
}

export default withViewModelService(ChangePolicy);
