/* eslint-disable class-methods-use-this */
/* eslint-disable valid-jsdoc */
/* eslint-disable no-console */
import appConfig from 'app-config';

const { loggingConfig } = appConfig;
/**
 * Logger class for the front-end BluePass application.
 *
 * Usage:
 *     import { WMICLogger } from 'wmic-pe-portals-utils-js';
 *     ....
 *     const log = WMICLogger.debug("event", exception)
 *
 * Six levels are defined:
 *   - event:  This type of logging event will always be logged, irrespective of configured level
 *   - error:  Indicates an error that has broken the user's flow
 *   - warn:   Indicates a warning that may impact operation of the system
 *   - info:   Indicates a higher level informational message, such as a user login
 *   - debug:  A lower level debug message, relevant to development and troubleshooting only
 *   - trace:  Indicates the trace of the execution; for example, entering/exiting a function
 *
 * Usage is as follows:  (functions are for each level):
 *   - log.trace('Entering PolicyLandingCtrl.init()');
 *   - log.debug('Producer code changed:  New value = ' + producerCode.value);
 *   - log.error('Error caught:', e);
 *   - log.event('Print button pressed');
 *   - if (log.isDebugEnabled()) {}
 *
 * Messages can be logged as:
 *   - String:    log.debug('this is a message to log');
 *   - Object:    log.debug($scope);
 *   - function:  log.debug(() => 'this can be used to log expensive statements: ' + someExpensiveFunction());
 *
 * @author Mike Clemens
 */
export default class WMICLogger {
    constructor() {
        this.config = this.getConfig();
        this.level = this.getLogLevel();
        this.writeToConsole = this.config.writeToConsole === true;
        this.writeToRemoteLogger = this.config.writeToRemoteLogger === true;
        this.remoteLoggerURL = this.config.remoteLoggerURL;
        this.traceInformation = this.config.traceInformation === true;
        this.authHeader = this.config.authHeader;
    }

    doLog(level, message, error, category = 'log') {
        if (level === WMICLogger.EVENT_LEVEL || this.level <= level) {
            const dateOfEvent = new Date();

            const event = {
                'logger': 'BluePassLogger',
                'logCategory': category,
                'level': level,
                'message': WMICLogger.formatMessage(message, error),
                'stackTrace': {
                    'value': WMICLogger.getStackTrace(error)
                },
                'pageUrl': WMICLogger.getUrl(window),
                'browser': WMICLogger.getBrowser(window),
                'networkSpeed': WMICLogger.getNetworkSpeed(navigator),
                'date': dateOfEvent.toISOString(),
            };

            const username = WMICLogger.getUsername(document);
            if (username) {
                event.username = username;
            }

            const policyNumber = WMICLogger.getNumber(document, '#policyNumber');
            if (policyNumber) {
                event.policyNumber = policyNumber;
            }

            const jobNumber = WMICLogger.getNumber(document, '#jobNumber');
            if (jobNumber) {
                event.jobNumber = jobNumber;
            }

            const accountNumber = WMICLogger.getNumber(document, '#accountNumber');
            if (accountNumber) {
                event.accountNumber = accountNumber;
            }
            
            if (this.traceInformation) {
                event.location = WMICLogger.getLocation();
            }

            if (this.writeToConsole) {
                WMICLogger.doLogToConsole(event);
            }

            if (this.writeToRemoteLogger) {
                WMICLogger.doLogToRemoteLogger(dateOfEvent, event);
            }
        }
    }
    

    static getInstance() {
        if (!this.instance) {
            this.instance = new WMICLogger();
        }
        
        return this.instance;
    }

    // constants

    static get LEVELS() { return ['all', 'trace', 'debug', 'info', 'warn', 'error', 'event']; }

    static get CATEGORIES() { return ['xhr', 'exception', 'log']; };

    static get EVENT_LEVEL() { return 6; }

    static get ERROR_LEVEL() { return 5; }

    static get WARN_LEVEL() { return 4; }

    static get INFO_LEVEL() { return 3; }

    static get DEBUG_LEVEL() { return 2; }

    static get TRACE_LEVEL() { return 1; }

    // log functions

    static event(message, error) { WMICLogger.getInstance().doLog(WMICLogger.EVENT_LEVEL, message, error); }
    
    static error(message, error) { WMICLogger.getInstance().doLog(WMICLogger.ERROR_LEVEL, message, error); }
    
    static warn(message, error) { WMICLogger.getInstance().doLog(WMICLogger.WARN_LEVEL, message, error); }
    
    static info(message, error) { WMICLogger.getInstance().doLog(WMICLogger.INFO_LEVEL, message, error); }
    
    static debug(message, error) { WMICLogger.getInstance().doLog(WMICLogger.DEBUG_LEVEL, message, error); }
    
    static trace(message, error) { WMICLogger.getInstance().doLog(WMICLogger.TRACE_LEVEL, message, error); }

    static xhrException(message, error) { WMICLogger.getInstance().doLog(WMICLogger.ERROR_LEVEL, message, error, 'xhr'); }
    
    static exception(message, error) { WMICLogger.getInstance().doLog(WMICLogger.ERROR_LEVEL, message, error, 'exception'); }
    
    static log(level, message, error) { WMICLogger.getInstance().doLog(level, message, error, 'exception'); }

    static sanitizeData(data) {
        const sanitizedData = {};

        const allowedFields = ['quoteID', 'policyNumber', 'jobNumber', 'accountNumber', 'jobID'];
        const getValueFromData = (recursiveData, propName) => {
            let value;
            Object.keys(recursiveData).some((k) => {
                if (k === propName) {
                    value = recursiveData[k];
                    return true;
                }
                if (recursiveData[k] && typeof recursiveData[k] === 'object') {
                    value = getValueFromData(recursiveData[k], propName);
                    return value !== undefined;
                }
                return false
            });
            return value;
        }

        if (data) {
            allowedFields.forEach((allowedField) => {sanitizedData[allowedField] = getValueFromData(data, allowedField)})
        }
        return sanitizedData;
    }

    static sanitizeError(error) {
        if (error?.gwInfo?.params) {
            const sanitizedError = { ...error };
            sanitizedError.gwInfo.params = this.sanitizeData(error.gwInfo.params);
            return sanitizedError;
        }
        return error;
    }

    static doLogToConsole(event) {
        const level = event.level < 1 || event.level > WMICLogger.LEVELS.length ? '?????' : WMICLogger.LEVELS[event.level];
        const location = event.location ? `${event.location}: ` : '';

        let textColor;

        switch(event.level) {
            case 2: 
                textColor = 'orange';
                break;
            case 5: 
                textColor = 'red'; 
                break;
            default: textColor = 'blue';

        }

        console.log(`%c${event.date} [${level}] ${location} ${event.message}`, `color:${textColor};`);
    }

    static doLogToRemoteLogger(dateOfEvent, event) {
        const message = {
            'event': event,
            'source': 'bluepass',
            'host': WMICLogger.getHost(window),
            'time': Math.floor(dateOfEvent / 1000)
        };

        const url = WMICLogger.getInstance().remoteLoggerURL;
        const authHeader = WMICLogger.getInstance().authHeader;
        
        new Promise(() => {
            const request = WMICLogger.createNewXMLHttpRequest();
            request.open('POST', url, true);
            request.setRequestHeader('Content-Type', 'application/json');
            request.setRequestHeader('Authorization', `Splunk ${authHeader}`);
            request.send(JSON.stringify(message));
        }).catch(() => {
            console.log('The remote logging has failed.');
        });
    }

    static createNewXMLHttpRequest() {
        return new XMLHttpRequest();
    }

    static getStackTrace(exception) {

        let stackTrace = null;

        if (exception instanceof Error) {
            stackTrace = exception.stack.split('\n');
        } else {
            try {
                throw new Error();
            } catch (e) {
                try {
                    // why 5, you ask?  the top of the stack will be:
                    //   - 0: Error message
                    //   - 1: _getLocation()
                    //   - 2: _doLog()
                    //   - 3: debug/info/warn/etc
                    //   - 4: logger method
                    //   - 5: method we want to trace
                    const stack = e.stack.split('\n');
                    if (stack.length <= 5) {
                        return null;
                    }

                    stackTrace = stack.slice(5);
                } catch (inner) {
                    // this is a generic catch to prevent any unforeseen issues with interrogating the stack trace; if any
                    // exception is thrown, then we will skip location tracing so as to not cause additional issues
                    stackTrace = null;
                }
            }
        }

        return stackTrace;
    }

    static getLocation() {
        try {
            throw new Error();
        } catch (e) {
            try {
                // why 5, you ask?  the top of the stack will be:
                //   - 0: Error message
                //   - 1: _getLocation()
                //   - 2: _doLog()
                //   - 3: debug/info/warn/etc
                //   - 4: logger method
                //   - 5: method we want to trace
                const stack = e.stack.split('\n');
                if (stack.length <= 5) {
                    return null;
                }

                const caller = stack[5].trim();
                return caller.startsWith('at ') ? caller.substring(3).trim() : caller;
            } catch (inner) {
                // this is a generic catch to prevent any unforeseen issues with interrogating the stack trace; if any
                // exception is thrown, then we will skip location tracing so as to not cause additional issues
                return null;
            }
        }
    }

    static getBrowser(windowArg) {
        return windowArg.navigator.userAgent;
    }

    static getUrl(windowArg) {
        return windowArg.location.pathname;
    }

    static getHost(windowArg) {
        return windowArg.location.hostname;
    }

    static getNetworkSpeed(navigatorArg) {
        return navigatorArg.connection ? `${navigatorArg.connection.downlink} Mbps` : 'Not Supported';
    }

    static getUsername(documentArg) {

        let text = null;
        const node = documentArg.querySelector('#username');
        if (node) {
            text = node.innerText;
        }

        return text;
    }

    static getNumber(documentArg, selector) {
        const node = documentArg.querySelector(selector);
        let number = null;

        if (node) {

            const text = node.innerText;
            if (text && !text.startsWith('{{') && !text.endsWith('}}')) {
                if (text.startsWith('(') && text.endsWith(')')) {
                    number = text.substring(1, text.length - 1);
                } else {
                    number = text;
                }
            }
        }

        return number;
    }

    // enabled functions

    static isEventEnabled() { return true; }
    
    static isErrorEnabled() { return WMICLogger.getInstance().level <= WMICLogger.ERROR_LEVEL; }
    
    static isWarnEnabled() { return WMICLogger.getInstance().level <= WMICLogger.WARN_LEVEL; }
    
    static isInfoEnabled() { return WMICLogger.getInstance().level <= WMICLogger.INFO_LEVEL; }
    
    static isDebugEnabled() { return WMICLogger.getInstance().level <= WMICLogger.DEBUG_LEVEL; }
    
    static isTraceEnabled() { return WMICLogger.getInstance().level <= WMICLogger.TRACE_LEVEL; }

    // helper functions

    /**
     * Formats the log message.
     *
     * @param message The message to log
     * @param error   The error being logged
     * @param initial Indicates whether this is the initial call to the function; used to prevent recursion
     * @returns The formatted log message
     */
    static formatMessage(message, error, initial) {
        let result;
        if (message === null || message === undefined) {
            result = '';
        } else if (typeof message === 'string') {
            result = message;
        } else if (typeof message === 'function') {
            if (initial === null || initial === undefined || initial) {
                try {
                    return WMICLogger.formatMessage(message(), error, false);
                } catch (e) {
                    // do nothing here; don't let an exception in logging break execution
                    result = '';
                }
            } else {
                result = '';
            }
        } else if (typeof message === 'object') {
            result = JSON.stringify(message);
        } else {
            result = `${message}`;
        }

        if (error !== null && error !== undefined && error instanceof Error) {
            if (result.length > 0) {
                result += '\n';
            }

            result += error.stack;
        }

        return result;
    }

    /**
     * Determines the log level currently configured for the application.
     *
     * @returns The current log level
     */
    getLogLevel() {
        const { level } = this.config;

        if (level === null || level === undefined) {
            WMICLogger.logLog(WMICLogger.WARN_LEVEL, 'No log level configured; defaulting to ERROR');
            return WMICLogger.ERROR_LEVEL;
        }

        const configuredLevel = level.toLowerCase();
        const levels = WMICLogger.LEVELS;
        for (let i = 0; i < levels.length; i++) {
            if (configuredLevel === levels[i]) {
                return i;
            }
        }

        WMICLogger.logLog(WMICLogger.WARN_LEVEL, `Invalid log level configured; default to ERROR: ${level}`);
        return WMICLogger.ERROR_LEVEL;
    }

    /**
     * Retrieves the logging section of the configuration.
     *
     * @returns The logging configuration
     */
    // eslint-disable-next-line class-methods-use-this
    getConfig() {
        return loggingConfig;
    }


    /**
     * Writes a log entry to the console.  This function will only ever be used when an exception occurs setting up the
     * logger.
     *
     * @param level   The level of the log message
     * @param message The message to log
     * @param error   The error to log
     */
    static logLog(level, message, error) {
        const formatted = WMICLogger.formatMessage(message, error);
        console.log(`LogLog [${WMICLogger.LEVELS[level]}] ${formatted}`);
    }
}