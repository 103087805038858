import React, { useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { ModalNextContext } from '@jutro/components';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';

import { WMICLink } from 'wmic-pe-components-platform-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';

import { WMICLogger } from 'wmic-pe-portals-utils-js';
import WMICFlexProgramModal from './WMICFlexProgramModal/WMICFlexProgramModal';
import messages from './WMICFlexProgramComponent.messages';


const WMICFlexProgramComponent = ({ jobVM, updateJobVM }) => {
    const { showModal } = useContext(ModalNextContext);
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();

    const onClickFn = async () => {
        try {
            await showModal(
                <WMICFlexProgramModal
                    modalVM={jobVM}
                    updateJobVM={updateJobVM}
                    viewModelService={viewModelService}
                    authHeader={authHeader}
                />
            );
        } catch({error, quoteID, jobType}) {
            WMICErrorHandler.processAsModal(error, quoteID, jobType)
            WMICLogger.error('Error in Flex handleRecalc', error);
        }
    };

    return (
        <WMICLink
            type='text'
            icon='mi-open-in-browser'
            className="gw-ml-5"
            onClick={onClickFn}
        >
            {translator(messages.flexProgram)}
        </WMICLink>
    )
}

export default WMICFlexProgramComponent;
