import { defineMessages } from 'react-intl';

export default defineMessages({
    changeSummaryTitle: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.WMICChangeSummaryPage.Change Summary',
        defaultMessage: 'Change Summary'
    },
    noChange: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.WMICChangeSummaryPage.NoChange',
        defaultMessage: 'No details on file to display'
    },
    nextButtonLabel: {
        id: 'wmic.pe-capability-policychange-common-react.platform.widgets.wizard.Quote',
        defaultMessage: 'Quote'
    },
    wizardQuoting: {
        id: 'wmic.pe-capability-policychange-common-react.platform.widgets.wizard.Quoting...',
        defaultMessage: 'Quoting...'
    }
});
