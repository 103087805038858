import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { PAConstants, WMICRPCUtil } from 'wmic-pe-portals-utils-js';
import { ReadOnlyCoverageTable } from 'wmic-pe-capability-quoteandbind-common-react';
import { ReadOnlyNonCoverageCostTable } from 'wmic-pe-capability-quoteandbind-common-react';
import metadata from "./WMICVehicleCoveragesTable.metadata.json5";
import messages from './WMICVehicleCoveragesTable.messages';
import styles from './WMICVehicleCoveragesTable.module.scss';

function WMICVehicleCoveragesTable(props) {
    const { vehicle, vehiclesCoverages, vehicleNonCoverageCosts, rateAsOfDate, isPolicyChangeOrRenewal, jobVM } = props;
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);

    const getNameByCode = (typeList, item, propertyName) => {
        const itemField = item[propertyName];
        if (itemField && itemField !== null) {
            const code = typeList.getCode(itemField);
            if (code && code !== null) {
                return code.description;
            }
        }
        return '';
    };

    const getDriverClassTypeList = (item, driverType, list) => {
        return item.drivers.filter((drivers) => drivers.driverType_WMIC.toLowerCase() === driverType)
            .map((driver) => driver.driverClassType_WMIC ? list.getCode(driver.driverClassType_WMIC).name : '');
    }

    const getInsuranceTypeListFromPC = (type) => {
        return viewModelService.productMetadata.get('pc').types.getTypelist(type);
    }

    const coverageComparisons = useMemo(() => {
        const vehicles = _.get(jobVM, 'vehicles.value', []);
        const changedVehicle = _.find(vehicles, (vh) => vh.vehicle.publicID === vehicle?.publicID?.value);
        return _.get(changedVehicle, 'coverageComparisons');
    }, [vehicle, jobVM]);

    const displayAppliedDiscounts = () => {
        vehiclesCoverages.appliedDiscounts = '';
        if(vehiclesCoverages.vehiclePrimaryDriverDiscounts &&  vehiclesCoverages.vehiclePrimaryDriverDiscounts.length) {
            vehiclesCoverages.appliedDiscounts = vehiclesCoverages.vehiclePrimaryDriverDiscounts.join(', ');
        }
    }

    const displayAppliedSurcharges = () => {
        vehiclesCoverages.surcharges = '';
        if(vehiclesCoverages.vehiclePrimaryDriverSurcharges && vehiclesCoverages.vehiclePrimaryDriverSurcharges.length) {
            vehiclesCoverages.surcharges = vehiclesCoverages.vehiclePrimaryDriverSurcharges.join(', ');
        }
    }

    const isRPC1912Effective = WMICRPCUtil.getIsRPCEffectiveForVehTypeAndBusSegment(_.get(jobVM, 'baseData.baseState.value.code'), _.get(jobVM, 'baseData.rateAsOfDate.value'),
        _.get(vehicle, 'vehicleType.value.code'), _.get(vehicle, 'businessSegment_WMIC.value.code'), '1912');

    const driverClassTypeList = getInsuranceTypeListFromPC('DriverClassType_WMIC');
    vehiclesCoverages.displayPrimaryUse = getNameByCode(getInsuranceTypeListFromPC('VehiclePrimaryUse'), vehiclesCoverages, 'primaryUse');
    vehiclesCoverages.primaryDriverClass = getDriverClassTypeList(vehiclesCoverages, 'principal', driverClassTypeList);
    vehiclesCoverages.occasionalDriverClass = getDriverClassTypeList(vehiclesCoverages, 'occasional', driverClassTypeList);
    vehiclesCoverages.displayVehicleType = getNameByCode(getInsuranceTypeListFromPC('VehicleType'), vehiclesCoverages, 'vehicleType');
    vehiclesCoverages.isTerritoryVisible = !(isRPC1912Effective || (WMICRPCUtil.getIsRPCEffective(PAConstants.postalCodeRatingJurisdiction, rateAsOfDate, '1555')
            && vehiclesCoverages.ratingJurisdiction === PAConstants.postalCodeRatingJurisdiction
            && PAConstants.postalCodeRatingVehicleTypes.includes(vehiclesCoverages.vehicleType)
            && vehiclesCoverages.businessSegment_WMIC === PAConstants.vehicleBusinessSegmentPersonal));
    vehiclesCoverages.isRatedGarageLocationVisible = isRPC1912Effective;
    displayAppliedDiscounts();
    displayAppliedSurcharges();

    const vehicleTerritoryCode = () => {
        return _.isEmpty(vehiclesCoverages.vehicleTerritoryCode_WMIC)?
            translator(messages.none) : vehiclesCoverages.vehicleTerritoryCode_WMIC.toString()
    }

    const annualMileage = () => {
        return _.isNil(vehiclesCoverages.annualMileage)?
            translator(messages.none) : vehiclesCoverages.annualMileage.toLocaleString()
    }

    const commutingMiles = () => {
        return _.isNil(vehiclesCoverages.commutingMiles)?
            translator(messages.none) : vehiclesCoverages.commutingMiles.toLocaleString()
    }

    const primaryUse = () => {
        return _.isEmpty(vehiclesCoverages.displayPrimaryUse)?
            translator(messages.none) : vehiclesCoverages.displayPrimaryUse.toString()
    }

    const vehicleRateGroup = () => {
        return _.isEmpty(vehiclesCoverages.vehicleRateGroup_WMIC)?
            translator(messages.none) : vehiclesCoverages.vehicleRateGroup_WMIC.toString()
    }

    const primaryDriverClass = () => {
        return _.isEmpty(vehiclesCoverages.primaryDriverClass)?
            translator(messages.none) : translator({id: vehiclesCoverages.primaryDriverClass})
    }

    const translateOccasionalDriverClass = (occasionalDriverClassNames) => {
        return occasionalDriverClassNames.map(occasionalDriverClassName => translator({id: occasionalDriverClassName})).join(', ');
    }

    const occasionalDriverClass = () => {
        return _.isEmpty(vehiclesCoverages.occasionalDriverClass)?
            translator(messages.none) : translateOccasionalDriverClass(vehiclesCoverages.occasionalDriverClass);
    }

    const drivingRecordPrimaryDriver = () => {
        return _.isNil(vehiclesCoverages.drivingRecordPrimaryDriver_WMIC)?
            translator(messages.none) : vehiclesCoverages.drivingRecordPrimaryDriver_WMIC.toString()
    }

    const drivingRecordOccasionalDriver = () => {
        return _.isNil(vehiclesCoverages.drivingRecordOccasionalDriver_WMIC)?
            translator(messages.none) : vehiclesCoverages.drivingRecordOccasionalDriver_WMIC.toString()
    }

    const vehicleDiscountsApplied = () => {
        return _.isEmpty(vehiclesCoverages.vehiclePrimaryDriverDiscounts)?
            translator(messages.none) : vehiclesCoverages.vehiclePrimaryDriverDiscounts.toString()
    }

    const vehicleSurchargesApplied = () => {
        return _.isEmpty(vehiclesCoverages.vehiclePrimaryDriverSurcharges_WMIC)?
            translator(messages.none) : vehiclesCoverages.vehiclePrimaryDriverSurcharges_WMIC[0]
    }

    const getVehicleClass = () => {
        return _.isEmpty(vehiclesCoverages.displayVehicleType)?
            translator(messages.none) : vehiclesCoverages.displayVehicleType.toString()
    }

    const garageLocationAddress = _.get(vehicle, 'garageLocation_WMIC.displayName.value');

    const ratingInformationData = () => {
        const ratingFields = [
            {
                name: translator(messages.annualMileage),
                description: annualMileage()
            },
            {
                name: translator(messages.commutingMiles),
                description: commutingMiles()
            },
            {
                name: translator(messages.primaryUse),
                description: primaryUse()
            },
            {
                name: translator(messages.vehicleRateGroup),
                description: vehicleRateGroup()
            },
            {
                name: translator(messages.primaryDriverClass),
                description: primaryDriverClass()
            },
            {
                name: translator(messages.occasionalDriverClass),
                description: occasionalDriverClass()
            }
        ]

        if (_.get(vehicle, 'isDriverRecordsVisible.aspects.ocular', true)) {
            ratingFields.push(
                {
                    name: translator(messages.drivingRecordPrimaryDriver),
                    description: drivingRecordPrimaryDriver()
                },
                {
                    name: translator(messages.drivingRecordOccasionalDriver),
                    description: drivingRecordOccasionalDriver()
                }
            );
        }

        ratingFields.push(
            {
                name: translator(messages.vehicleDiscountsApplied),
                description: vehicleDiscountsApplied()
            },
            {
                name: translator(messages.vehicleSurchargesApplied),
                description: vehicleSurchargesApplied()
            }
        );

        if (vehiclesCoverages.isTerritoryVisible) {
            ratingFields.push({
                name: translator(messages.vehicleTerritoryCode),
                description: vehicleTerritoryCode()
            })
        }

        if (vehiclesCoverages.isRatedGarageLocationVisible) {
            ratingFields.push({
                name: translator(messages.ratedGarageLocation),
                description: garageLocationAddress
            })
        }

        return ratingFields;
    };

    const overrides = {
        '@field': {
            readOnly: true
        },
        vehicleType: {
            value: getVehicleClass()
        },
        liabilityProtectionCoverages: {
            visible: !_.isEmpty(vehiclesCoverages.liabilityProtectionCoverages),
            coverageList: vehiclesCoverages.liabilityProtectionCoverages,
            isPolicyChangeOrRenewal,
            jobVM,
            coverageComparisons
        },
        underageMaleDriverCoverages: {
            visible: !_.isEmpty(vehiclesCoverages.underageMaleDriverCoverages),
            coverageList: vehiclesCoverages.underageMaleDriverCoverages,
            isPolicyChangeOrRenewal,
            jobVM,
            coverageComparisons
        },
        underageGenderXOrFemaleDriverCoverages: {
            visible: !_.isEmpty(vehiclesCoverages.underageGenderXOrFemaleDriverCoverages),
            coverageList: vehiclesCoverages.underageGenderXOrFemaleDriverCoverages,
            isPolicyChangeOrRenewal,
            jobVM,
            coverageComparisons
        },
        additionalCoverages: {
            visible: !_.isEmpty(vehiclesCoverages.additionalCoverages),
            coverageList: vehiclesCoverages.additionalCoverages,
            isPolicyChangeOrRenewal,
            jobVM,
            coverageComparisons
        },
        physicalDamageCoverages: {
            visible: !_.isEmpty(vehiclesCoverages.physicalDamageCoverages),
            coverageList: vehiclesCoverages.physicalDamageCoverages,
            isPolicyChangeOrRenewal,
            jobVM,
            coverageComparisons
        },
        policyLevelCoverages: {
            visible: !isPolicyChangeOrRenewal && !_.isEmpty(vehiclesCoverages.policyLevelCoverages),
            coverageList: vehiclesCoverages.policyLevelCoverages,
            isPolicyChangeOrRenewal,
            jobVM,
            coverageComparisons
        },
        nonCoverageCosts: {
            visible: true,
            nonCoverageCostList: vehicleNonCoverageCosts,
            jobVM
        },
        ratingInfo: {
            VMList: ratingInformationData(),
            VMData: [
                {
                    headerText: translator(messages.ratingInformationTitle),
                    path: "name"
                },
                {
                    headerText: translator(messages.ratingInformationDescription),
                    path: "description"
                }
            ],
            readOnly: true
        }
    };

    const resolvers = {
        resolveComponentMap: {
            ReadOnlyCoverageTable,
            ReadOnlyNonCoverageCostTable
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            model={vehiclesCoverages}
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICVehicleCoveragesTable.propTypes = {
    value: PropTypes.shape({
        scheduledItems: PropTypes.arrayOf(PropTypes.shape({})),
        sectionICoverages: PropTypes.arrayOf(PropTypes.shape({})),
        optionalLiabilityCoverages: PropTypes.arrayOf(PropTypes.shape({})),
        optionalPropertyCoverages: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired
};

export default WMICVehicleCoveragesTable;
