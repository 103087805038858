import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import WMICPolicyPupIncidentsDetailView from '../WMICPolicyPupIncidentsDetailView/WMICPolicyPupIncidentsDetailView';
import metadata from './WMICPolicyPupHouseholdMembersDetailView.metadata.json5';
import messages from './WMICPolicyPupHouseholdMembersDetailView.messages.js';

const WMICPolicyPupHouseholdMembersDetailView = ({ value: householdMemberVM, displayDate }) => {
    const translator = useContext(TranslatorContext);
    const incidentVMs = _.get(householdMemberVM, 'memberIncidents.children', []);

    const overrideProps = {
        '@field': {
            parentNode: householdMemberVM,
            readOnly: true
        },
        incidentListView: {
            value: incidentVMs,
            VMData: [
                {
                    headerText: translator({id: 'wmic.policy.householdMember.view.memberIncident.incidentType', defaultMessage: 'meta.Incident Type'}),
                    path: 'incidentType'
                },
                {
                    headerText: translator({id: 'wmic.policy.householdMember.view.memberIncident.incidentDateOccured', defaultMessage: 'meta.Date Occurred'}),
                    path: "incidentDate",
                    getData: displayDate
                },
                {
                    headerText: translator({id: 'wmic.policy.householdMember.view.memberIncident.incidentDescription', defaultMessage: 'meta.Description'}),
                    path: 'incidentDescription'
                }
            ],
            clickable: true,
            readOnly: true,
            detailViewComponent: WMICPolicyPupIncidentsDetailView
        }
    }

    return <ViewModelForm
            uiProps={metadata.pageContent}
            model={householdMemberVM}
            overrideProps={overrideProps}
        />
}

WMICPolicyPupHouseholdMembersDetailView.propTypes = {
    value: PropTypes.object.isRequired,
    displayDate: PropTypes.func.isRequired // used to display correct incident date
}

export default WMICPolicyPupHouseholdMembersDetailView;