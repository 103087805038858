import React, {useCallback, useEffect, useContext, useState, useMemo} from 'react';
import _ from 'lodash';
import {wizardProps} from 'wmic-pe-portals-custom-wizard-react';
import {useValidation} from '@xengage/gw-portals-validation-react'
import {ViewModelForm} from '@xengage/gw-portals-viewmodel-react';
import { useWizardModals, WMICWizardSubmissionPage } from 'wmic-pe-portals-wizard-components-ui';
import {useAuthentication} from '@xengage/gw-digital-auth-react';
import {TranslatorContext} from '@jutro/locale';
import htmlParser from 'html-react-parser';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import { WMICUserAccessUtil, WMICLogger, JURISDICTIONS, LINE_OF_BUSINESS } from 'wmic-pe-portals-utils-js';
import {LoadSaveService} from "wmic-pe-capability-gateway-quoteandbind";
import { WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';
import { PolicyService } from 'wmic-pe-capability-gateway-policy';
import WMICPaymentPlansList from './WMICPaymentPlansList/WMICPaymentPlansList';
import WMICMonthlyAutomatedForm from './WMICMonthlyAutomatedForm/WMICMonthlyAutomatedFormComponent';
import WMICUIIssuesModal from './WMICUIIssuesModal/WMICUIIssuesModal';

import metadata from './WMICPaymentPage.metadata.json5';
import messages from './WMICPaymentPage.messages';
import { messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import styles from './WMICPaymentPage.module.scss';

const DIRECTBILL_CODE = 'DirectBill';
const AGENCYBILL_CODE = 'AgencyBill';
const CREDITCARD_CODE = 'CREDITCARD';
const CHEQUE_CODE = 'CHEQUE';
const UNPAID_CODE = 'UNPAID';

function WMICPaymentPage(props) {
    const {wizardData: submissionVM, updateWizardData, history} = props;
    const translator = useContext(TranslatorContext);
    const {isComponentValid, onValidate, disregardFieldValidation} = useValidation('WMICPaymentPage');
    const { setWizardLoading, showError, showCustom } = useWizardModals();
    const { authUserData: currentUser, authHeader } = useAuthentication();

    const [monthlyAutomatedId, setMonthlyAutomatedId] = useState();
    const [monthlyAutomatedSelected, setMonthlyAutomatedSelected] = useState(false);
    const [paymentPlanSelected, setPaymentPlanSelected] = useState(false);
    const eDeliveryEnabled = _.get(submissionVM, 'edeliveryEnabled_WMIC.value', false);
    const eDeliveryEnrolled = _.get(submissionVM, 'edeliveryEnrolled_WMIC.value', false);
    const defaultFirstDateOfMonth = _.get(submissionVM, 'bindData.paymentDetails.firstDateOfMonth', 1);
    const isCP = _.get(submissionVM, 'lob.value.code') === LINE_OF_BUSINESS.GENERAL_LIABILITY;

    const eDeliveryMessagesVisible = () => {
        return eDeliveryEnabled
            && eDeliveryEnrolled
            && _.get(submissionVM, 'bindData.billingMethod.value.code') === AGENCYBILL_CODE;
    };

    const canBindPolicy = useMemo(
        () => WMICUserAccessUtil.canBindPolicy(currentUser.roles),
        [currentUser.roles]
    );

    useEffect(() => {
        const paymentPlans = _.get(submissionVM, "bindData.paymentPlans.value", []);
        const monthlyAutomated = _.find(paymentPlans, {isMontlyInstallments: true});
        if (monthlyAutomated) {
            setMonthlyAutomatedId(monthlyAutomated.billingId);
        }

        // We want to remove any defaulted payment plans when first loading the page
        _.set(submissionVM, "bindData.selectedPaymentPlan", null);
        setPaymentPlanSelected(false);
    }, []);

    const selectedPaymentPlanChanged = (selectedPaymentPlan) => {
        if (selectedPaymentPlan) {
            if (selectedPaymentPlan === monthlyAutomatedId) {
                _.set(submissionVM, "bindData.paymentDetails.paymentMethod.value", 'WIRE');
            } else {
                disregardFieldValidation("monthlyAutomatedForm");
                _.set(submissionVM, "bindData.paymentDetails.paymentMethod.value", null);
                _.set(submissionVM, "bindData.paymentDetails.firstDateOfMonth.value", defaultFirstDateOfMonth);
            }
            updateWizardData(submissionVM);
            setMonthlyAutomatedSelected(selectedPaymentPlan === monthlyAutomatedId);
            setPaymentPlanSelected(true);
        }
    };

    const getNextLabel = () => {
        const paymentMethod = _.get(submissionVM, 'bindData.paymentDetails.paymentMethod.value');
        return (paymentMethod === CREDITCARD_CODE) ? messages.submitPayment : messages.bindPolicy;
    };
    /**
     * Update submission and then navigate to quote summary screen
     */
    const onNext = useCallback(async () => {
        try {
            setWizardLoading(true);
            setDefaultPaymentDetailsInfo();

            const paymentMethod = _.get(submissionVM, 'bindData.paymentDetails.paymentMethod.value');
            if (paymentMethod === CREDITCARD_CODE) {
                await prebind();
            } else {
                await submitPolicy();
            }
            return submissionVM;
        } catch (error) {
            WMICLogger.error('WMICPaymentPage onNext', error);
            if (_.get(error, 'baseError', '').toLowerCase().includes('uwissue')) {
                const uwIssues = WMICErrorHandler.extractUWIssuesFromErrorMessage(error);
                showCustom(
                    <WMICUIIssuesModal
                        uwIssues={uwIssues}
                    />
                ).then(() => {
                    history.push(`/quotes/${submissionVM.quoteID.value}/summary`)
                }).catch(() => {
                    _.noop();
                });
            } else if (_.get(error, 'baseError', '').toLowerCase().includes('entityvalidationexception')) {
                WMICErrorHandler.processAsModal(error);
            } else {
                showError({
                    title: translator(messages.error),
                    message: translator(messages.bindingFailure),
                })
            }
            return false;
        } finally {
            setWizardLoading(false);
        }
    }, [submissionVM]);

    const setDefaultPaymentDetailsInfo = () => {
        submissionVM.bindData.paymentDetails.emailAddress.value = submissionVM.baseData.accountHolder.emailAddress1.value;
        submissionVM.bindData.paymentDetails.recieveEmailReceipt.value = false;
    };

    const prebind = () => {
        if (submissionVM.bindData.paymentDetails.autoRecurringPayer_WMIC) {
            submissionVM.bindData.paymentDetails.autoRecurringPayer_WMIC.value = null;
        }
        return LoadSaveService.prebindSubmission(submissionVM.value, authHeader)
            .then(proceedToCreditCardPayment);
    };


    const proceedToCreditCardPayment = () => {
        const selectedPaymentPlan = _.find(submissionVM.bindData.paymentPlans.value, (plan) => plan.billingId === submissionVM.bindData.selectedPaymentPlan.value);
        return LoadSaveService.retrieveCreditCardPaymentService(
            submissionVM.quoteID.value,
            selectedPaymentPlan.downPayment,
            submissionVM.bindData.paymentDetails.emailAddress.value,
            authHeader)
            .then((paymentControllerURL) => {
                if (window.location.hostname !== 'localhost') {
                    window.location.href = paymentControllerURL;
                } else {
                    mockCallReceiptpage();
                }
            });
    };

    const mockCallReceiptpage = () => {
        const submissionNumber = submissionVM.quoteID.value;
        const postalCode = submissionVM.baseData.accountHolder.primaryAddress.postalCode.value;
        const datetime = "2022-10-07 10:13:27";
        const name = "john smith";
        const refNumber = "63404216A0E2B3B734A01DF92AB52F38F16853C3";
        const authCode = "tst682";
        const mPan = "XXXXXXXXXXXX0009";
        const card = "Mastercard";
        const emailAddress = "Something@email.org";
        history.push(`/receipt/${submissionNumber}/${postalCode}/${datetime}/${name}/${refNumber}/${authCode}/${mPan}/${card}/${emailAddress}`);
    };

    const submitPolicy = () => {
        const selectedPaymentPlan = _.find(submissionVM.bindData.paymentPlans.value, (plan) => plan.billingId === submissionVM.bindData.selectedPaymentPlan.value);
        const submissionCopy = _.cloneDeep(submissionVM.value);
        if (submissionVM.bindData.billingMethod.value.code === AGENCYBILL_CODE) {
            initAgentBillPayment(submissionCopy);
        } else if (submissionVM.bindData.billingMethod.value.code === DIRECTBILL_CODE
            && selectedPaymentPlan.isMontlyInstallments === false
            && submissionVM.bindData.paymentDetails.paymentMethod.value === CHEQUE_CODE) {
            initDirectPayment(submissionCopy);
        }

        return LoadSaveService.bindSubmission(submissionCopy, authHeader)
            .then(async(submission) => {
                const policyNumber = submission.bindData.policyNumber;
                if (!_.isNil(policyNumber)) {
                    let retrPol = await getPolicyNumber(policyNumber);
                    let servicecounter = 1;
                    while (servicecounter < 5 && retrPol === false) {
                        retrPol = await getPolicyNumber(policyNumber);
                        servicecounter++;
                    }
                    if (retrPol !== false) {
                        history.push(
                            `/policies/${submission.bindData.policyNumber}/1/summary`
                        );
                    } else {
                        WMICLogger.error('Error getting policy number');
                        history.push('/home');
                    }
                } else {
                    submissionVM.value = submission;
                    const paymentMethod = _.get(submissionVM, 'bindData.paymentDetails.paymentMethod.value');
                    if (!paymentMethod) {
                        _.set(submissionVM, 'bindData.paymentDetails.value', { paymentMethod: UNPAID_CODE });
                    }
                    updateWizardData(submissionVM);
                }
            });
    };
    async function getPolicyNumber (policyNumber) {
        let returnPolicyNum;
        await PolicyService.getPolicyTerms(policyNumber, authHeader).
            then((response) => {
                returnPolicyNum = response.policyNumber;
            })
            .catch((e) => {
                returnPolicyNum = false;
            })
            return returnPolicyNum;
       };

    const initDirectPayment = (submission) => {
        _.set(submission, "bindData.paymentDetails", null);
    };

    const initAgentBillPayment = (submission) => {
        _.set(submission, "bindData.selectedPaymentPlan", null);
        _.set(submission, "bindData.paymentDetails", null);
        _.set(submission, "bindData.paymentPlans", []);
    };

    const overrideProps = {
        '@field': {
            parentNode: submissionVM,
        },
        paymentPlansContainer: {
            visible: _.get(submissionVM, 'bindData.billingMethod.value.code') === DIRECTBILL_CODE,
        },
        paymentPlansList: {
            quoteID: _.get(submissionVM, 'quoteID.value'),
            postalCode: _.get(submissionVM, 'baseData.accountHolder.primaryAddress.postalCode.value'),
            authHeader,
            selectedPaymentPlanChanged
        },
        electronicDocumentDistributionNotification: {
            visible: eDeliveryMessagesVisible(),
        },
        electronicDocumentDistributionNotificationContent: {
            content: htmlParser(translator(messages.electronicDocumentDistribution))
        },
        waivingBrokerFeeNotification: {
            visible: isCP
                && _.get(submissionVM, 'bindData.billingMethod.value.code') === AGENCYBILL_CODE
                && _.get(submissionVM, 'baseData.baseState.value.code') === JURISDICTIONS.QUEBEC
        },
        waivingBrokerFeeNotificationContent: {
            content: htmlParser(translator(messages.waivingBrokerFee))
        },
        paymentOptionsContainer: {
            visible: paymentPlanSelected && _.get(submissionVM, 'bindData.billingMethod.value.code') === DIRECTBILL_CODE
        },
        paymentMethod: {
            visible: !monthlyAutomatedSelected
        },
        creditCardLeavingNote: {
            visible: _.get(submissionVM, 'bindData.paymentDetails.paymentMethod.value') === CREDITCARD_CODE
        },
        chequeNote: {
            visible: _.get(submissionVM, 'bindData.paymentDetails.paymentMethod.value') === CHEQUE_CODE
        },
        monthlyAutomatedForm: {
            visible: monthlyAutomatedSelected,
            submissionVM,
            updateWizardData,
            onValidate
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICPaymentPlansList,
            WMICMonthlyAutomatedForm,
        },
        resolveClassNameMap: styles,
    };

    return (
        <WMICWizardSubmissionPage
            nextLabel={getNextLabel()}
            onNext={onNext}
            showNext={canBindPolicy}
            disableNext={!isComponentValid || (submissionVM?.bindData?.billingMethod?.value.code === DIRECTBILL_CODE && !paymentPlanSelected)}
            cancelLabel={translator(commonMessages.saveAndExit)}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WMICWizardSubmissionPage>
    );
}

WMICPaymentPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    ...wizardProps
};

export default withRouter(WMICPaymentPage);
