import { SHOW_ALL_PAGES } from '@jutro/datatable';
import { JobType } from 'wmic-pe-portals-utils-js';
import { AccountService } from 'wmic-pe-capability-gateway-policy';
import WMICTabsCommonUtil from '../components/WMICTabsCommonUtil';
import constants from '../../constants';
import messages from '../WMICLanding.messages'

const getPolicyNumberLink = (element, translator) => WMICTabsCommonUtil.getPolicyNumberLink(element, translator);

const getFormattedDateCell = (rowData) => WMICTabsCommonUtil.getFormattedDateCell(rowData.created);

const displayColumns = (translator) => [
    {
        id: "created",
        path: "created",
        textAlign: "left",
        headerClassName: "headerText",
        renderCell: getFormattedDateCell,
        header: {
            id: 'policy.agent.views.policy-landing.Created',
            defaultMessage: 'meta.Created'
        },
    },
    {
        id: "insured",
        textAlign: "left",
        columnProportion: 3,
        renderCell: WMICTabsCommonUtil.getFormattedNameAddressCell,
        header: {
            id: "policy.agent.views.policy-landing.Insured",
            defaultMessage: "meta.Insured"
        },
    },
    {
        icon: "lobIcon",
        id: "jobNumber",
        renderCell: (rowData) => getPolicyNumberLink(rowData, translator),
        textAlign: "left",
        header: {
            id: 'policy.agent.views.policy-landing.Policy Number',
            defaultMessage: 'meta.Policy'
        },
    },
    {
        id: "status",
        path: "status",
        textAlign: "left",
        header: {
            id: 'policy.agent.views.policy-landing.Status',
            defaultMessage: 'meta.Status'
        }
    },
    {
        id: "broker",
        path: "broker",
        textAlign: "left",
        header: {
            id: "policy.agent.views.policy-landing.Broker Number",
            defaultMessage: "meta.Broker"
        }
    }
];

const transformData = (data) => {
    let rowClickDestination = constants.DESTINATION_POLICIES;
    let jobNumber = `${data.policyNumber}/${data.termNumber}`;
    if (data.type === JobType.POLICY_CHANGE) {
        rowClickDestination = constants.DESTINATION_CHANGE;
        jobNumber = data.jobNumber;
    } else if (data.type === JobType.CANCELLATION) {
        rowClickDestination = constants.DESTINATION_CANCELLATION;
        jobNumber = data.jobNumber;
    }

    const policyDataToReturn = {
        jobNumber,
        created: data.createTime,
        insured: data.accountHolderName,
        policyAddress: data.policyAddress,
        status: data.policyDisplayStatus,
        broker: data.producerCodeOfRecord,
        policyLines: data.policyLines,
        productCode: data.product.productCode,
        policyNumber: data.policyNumber,
        rowClickDestination
    };

    return policyDataToReturn;
};

const loadDataAsyncSubmission = (page, pageSize, queryParams, authHeader) => {
    const calculatedPageSize = pageSize === SHOW_ALL_PAGES ? constants.PAGE_SIZE : pageSize;
    return AccountService.getJobsForCurrentUser(queryParams, constants.JOBS_CREATED_IN_LAST_DAYS, page, calculatedPageSize, authHeader).then((response) => {
        const simpleResponse = [];
        const {
            jobs
        } = response;

        jobs.forEach((policy) => {
            simpleResponse.push(transformData(policy));
        });

        return {
            rows: simpleResponse,
            numberOfRows: response.maxNumberOfResults,
        }
    });
}

export default {
    tabHeading: messages.openPolicyTransactionsTab,
    displayColumns,
    loadDataAsyncSubmission
}
