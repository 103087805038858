import React, { useContext } from 'react';
import _ from 'lodash';
import { MetadataContent } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { WMICRichTextUtil, JURISDICTIONS, WMICJurisdictionUtil } from 'wmic-pe-portals-utils-js';
import { TranslatorContext } from '@jutro/locale';

import metadata from './WMICPUPPrintableAgreement.metadata.json5';
import messages from './WMICPUPPrintableAgreement.messages';

function WMICPUPPrintableAgreement(props) {
    const { jobVM } = props;
    const translator = useContext(TranslatorContext);

    const renderRemarksLines = () => {
        const remarksArray = [...Array(10)].map((x, i) => i);
        return remarksArray.map((r) => <div id={`remarkLine${r}`}/>)
    }

    const overrideProps = {
        remarksContainer: {
            content: renderRemarksLines()
        },
        fullDisclosurePUPQuebecContainer: {
            visible: WMICJurisdictionUtil.isBaseState(_.get(jobVM, 'baseData'), JURISDICTIONS.QUEBEC)
        },
        fullDisclosurePUPnonQuebecContainer: {
            visible: !WMICJurisdictionUtil.isBaseState(_.get(jobVM, 'baseData'), JURISDICTIONS.QUEBEC)
        },
        personalConsentPUPQuebecContainer: {
            visible: WMICJurisdictionUtil.isBaseState(_.get(jobVM, 'baseData'), JURISDICTIONS.QUEBEC)
        },
        personalConsentPUPnonQuebecContainer: {
            visible: !WMICJurisdictionUtil.isBaseState(_.get(jobVM, 'baseData'), JURISDICTIONS.QUEBEC)
        },
        fullDisclosureExQuebec: {
            content: WMICRichTextUtil.translateRichText(translator(messages.fullDisclosureExQuebec))
        },
        fullDisclosureQuebec: {
            content: WMICRichTextUtil.translateRichText(translator(messages.fullDisclosureQuebec))
        },
        fullDisclosureAllProvinces: {
            content: WMICRichTextUtil.translateRichText(translator(messages.fullDisclosureAllProvinces))
        }
    };

    const resolvers = {};

    return <MetadataContent uiProps={metadata.componentContent} overrideProps={overrideProps} {...resolvers} />;
}

WMICPUPPrintableAgreement.propTypes = {
    jobVM: PropTypes.shape({}).isRequired
};

WMICPUPPrintableAgreement.defaultProps = {

};

export default WMICPUPPrintableAgreement;