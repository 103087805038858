import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { WMICRichTextUtil } from 'wmic-pe-portals-utils-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import _ from "lodash";
import metadata from "./WMICDwellingCoveragesTable.metadata.json5";
import messages from './WMICDwellingCoveragesTable.messages';
import styles from './WMICDwellingCoveragesTable.module.scss';

function WMICDwellingCoveragesTable(props) {
    const {dwelling, dwellingCoverages, jobVM, isPolicyChangeOrRenewal} = props;
    const disappearingDeductibleReducedValue = _.get(dwelling, 'disappearingDeductibleReducedValue.value');
    const translator = useContext(TranslatorContext);

    const appliedDiscounts = () => {
        return _.isEmpty(dwellingCoverages.dwellingDiscountsApplied_WMIC.value)?
            translator(messages.none) : dwellingCoverages.dwellingDiscountsApplied_WMIC.value.toString()
    }

    const appliedSurcharges = () => {
        return _.isEmpty(dwellingCoverages.dwellingSurchargesApplied_WMIC.value)?
            translator(messages.none) : dwellingCoverages.dwellingSurchargesApplied_WMIC.value.toString()
    }

    const generateScheduledItemsCoverageOverrides = () => {
        const overrides = dwellingCoverages.scheduledItems.coverages.map((coverage, cindex) => {
            return {
                [`scheduledItemsCoverageTerm${cindex}`]: {
                    label: coverage.description,
                    value: coverage.value
                }
            };
        });
        return Object.assign({}, ...overrides);
    };

    const ratingInformationData = useCallback(() => [
            {
                name: translator(messages.discountsApplied),
                description: appliedDiscounts()
            },
            {
                name: translator(messages.surchargesApplied),
                description: appliedSurcharges()
            }
        ], [dwellingCoverages]);

    const coverageComparisons = useMemo(() => {
        const dwellings = _.get(jobVM, 'dwellings.value', []);

        const changedDwelling = _.find(dwellings, (dw) => dw.dwelling.publicID === dwelling?.publicID?.value)
        
        return _.get(changedDwelling, 'coverageComparisons');
    }, [dwelling, jobVM]);

    const overrides = {
        '@field': {
            readOnly: true
        },
        disappearingDeductibleContainer: {
            visible: !_.isUndefined(disappearingDeductibleReducedValue)
        },
        disappearingDeductibleReduced: {
            visible: disappearingDeductibleReducedValue !== "",
            content: WMICRichTextUtil.translateRichText(translator(messages.disappearingDeductibleReduced, {disappearingDeductibleValue: disappearingDeductibleReducedValue}))
        },
        includedCoverages: {
            visible: !_.isEmpty(dwellingCoverages.includedCoverages.coverages),
            coverageList: dwellingCoverages.includedCoverages.coverages,
            coverageHeaderText: dwellingCoverages.includedCoverages.categoryName,
            isPolicyChangeOrRenewal,
            jobVM,
            coverageComparisons
        },
        optionalLiabilityCoverages: {
            visible: !_.isEmpty(dwellingCoverages.optionalLiabilityCoverages.coverages),
            coverageList: dwellingCoverages.optionalLiabilityCoverages.coverages,
            coverageHeaderText: dwellingCoverages.optionalLiabilityCoverages.categoryName,
            isPolicyChangeOrRenewal,
            jobVM,
            coverageComparisons
        },
        optionalPropertyCoverages: {
            visible: !_.isEmpty(dwellingCoverages.optionalPropertyCoverages.coverages),
            coverageList: dwellingCoverages.optionalPropertyCoverages.coverages,
            coverageHeaderText: dwellingCoverages.optionalPropertyCoverages.categoryName,
            isPolicyChangeOrRenewal,
            jobVM,
            coverageComparisons
        },
        exclusions: {
            visible: !_.isEmpty(dwellingCoverages.exclusions.coverages),
        },
        exclusionsContainer: {
            data: dwellingCoverages.exclusions.coverages
        },
        exclusionsTitle: {
            title: dwellingCoverages.exclusions.categoryName
        },
        conditions: {
            visible: !_.isEmpty(dwellingCoverages.conditions.coverages)
        },
        conditionsContainer: {
            data: dwellingCoverages.conditions.coverages
        },
        conditionsTitle: {
            title: dwellingCoverages.conditions.categoryName
        },
        scheduledItemsCoverables: {
            visible: !_.isEmpty(dwellingCoverages.scheduledItems.coverages)
        },
        additionalInterestContainer: {
            additionalInterestList: dwelling.additionalInterests
        },
        ratingInformationTable: {
            VMList: ratingInformationData(),
            VMData: [
                {
                    headerText: translator(messages.ratingInformationTitle),
                    path: "name",
                },
                {
                    headerText: translator(messages.ratingInformationDescription),
                    path: "description"
                }
            ],
            readOnly: true
        },
        ...generateScheduledItemsCoverageOverrides()
    };


    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            model={dwellingCoverages}
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICDwellingCoveragesTable.propTypes = {
    value: PropTypes.shape({
        scheduledItems: PropTypes.arrayOf(PropTypes.shape({})),
        sectionICoverages: PropTypes.arrayOf(PropTypes.shape({})),
        optionalLiabilityCoverages: PropTypes.arrayOf(PropTypes.shape({})),
        optionalPropertyCoverages: PropTypes.arrayOf(PropTypes.shape({})),
        conditions: PropTypes.arrayOf(PropTypes.shape({})),
        exclusions: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired
};

export default WMICDwellingCoveragesTable;
