import { defineMessages } from 'react-intl';

export default defineMessages({
    waterHeaterInfo: {
        id: "wmic-pe-capability-gateway-common-ho-react.WaterHeaterInfo.Water Heater Info",
        defaultMessage: "Water Heater Info"
    },
    type: {
        id: "wmic-pe-capability-gateway-common-ho-react.WaterHeaterInfo.Type",
        defaultMessage: "Type"
    },
    describe: {
        id: "wmic-pe-capability-gateway-common-ho-react.WaterHeaterInfo.Describe Other",
        defaultMessage: "Describe Other"
    },
    year: {
        id: "wmic-pe-capability-gateway-common-ho-react.WaterHeaterInfo.Year",
        defaultMessage: "Year"
    },
    fuelType: {
        id: "wmic-pe-capability-gateway-common-ho-react.WaterHeaterInfo.Fuel Type",
        defaultMessage: "Fuel Type"
    },
    professionallyInstalled: {
        id: "wmic-pe-capability-gateway-common-ho-react.WaterHeaterInfo.Professionally Installed",
        defaultMessage: "Professionally Installed"
    },
    approvals: {
        id: "wmic-pe-capability-gateway-common-ho-react.WaterHeaterInfo.Approvals",
        defaultMessage: "Approved by ULC, CSA, WH"
    }
    
})