import { defineMessages } from 'react-intl';

export default defineMessages({
    addDwelling: {
        id: 'quoteandbind.ho.views.ho-your-home.Add Dwelling',
        defaultMessage: 'Add Dwelling'
    },
    propertyDetails: {
        id: 'quoteandbind.ho.views.ho-your-home.Property Details',
        defaultMessage: 'Property Details'
    },
    protectionDetails: {
        id: 'quoteandbind.ho.views.ho-your-home.Protection Details',
        defaultMessage: 'Protection Details'
    },
    additionalInterests: {
        id: 'quoteandbind.ho.views.ho-your-home.Additional Interests',
        defaultMessage: 'Additional Interests'
    },
    risk: {
        id: 'quoteandbind.ho.views.ho-your-home.Risk',
        defaultMessage: 'Risk'
    },
    dwellingRisks: {
        id: 'quoteandbind.ho.views.ho-your-home.Dwelling Risks',
        defaultMessage: 'Dwelling Risks'
    },
    riskHeader: {
        id: 'quoteandbind.ho.views.ho-your-home.RiskNo',
        defaultMessage: 'Risk #'
    },
    dwellingHeader: {
        id: 'quoteandbind.ho.views.ho-your-home.Dwelling Use',
        defaultMessage: 'Dwelling Use'
    },
    addressHeader: {
        id: 'quoteandbind.ho.views.ho-your-home.Address',
        defaultMessage: 'Address'
    },
    riskTypeHeader: {
        id: 'quoteandbind.ho.views.ho-your-home.Risk Type',
        defaultMessage: 'Risk Type'
    },
    riskDescription: {
        id: 'quoteandbind.ho.views.ho-your-home.Risk Description',
        defaultMessage: 'Description'
    },
    watercraftRisks: {
        id: 'quoteandbind.ho.views.ho-your-home.Watercraft Risks',
        defaultMessage: 'Watercraft Risks'
    },
    addWatercraft: {
        id: 'quoteandbind.ho.views.ho-your-home.Add Watercraft',
        defaultMessage: 'Add Watercraft'
    },
    watercraftDetails: {
        id: 'quoteandbind.ho.views.ho-your-home.Watercraft Details',
        defaultMessage: 'Watercraft Details'
    },
    removeRiskTitle: {
        id: 'quoteandbind.ho.views.ho-your-home.Remove Risk Short',
        defaultMessage: 'Remove Risk'
    },
    removeRiskMessage: {
        id: 'quoteandbind.ho.views.ho-your-home.Remove Risk Long',
        defaultMessage: 'Are you sure you want to remove the risk from the list?'
    },
    confirmButtonText: {
        id: 'quoteandbind.ho.views.ho-your-home.Yes',
        defaultMessage: 'Yes'
    },
    laneway: {
        id: 'quoteandbind.ho.views.ho-your-home.Laneway',
        defaultMessage: 'Laneway'
    },
    saveRisk: {
        id: 'quoteandbind.ho.views.ho-your-home.Save Risk',
        defaultMessage: 'Save'
    },
    cancelEdit: {
        id: 'quoteandbind.ho.views.ho-your-home.Cancel Edit',
        defaultMessage: 'Cancel'
    },
    dwellingRequired: {
        id: 'quoteandbind.ho.views.ho-your-home.Minimum Dwelling Risk',
        defaultMessage: '&lt;span&gt;At least &lt;b&gt;one Dwelling Risk&lt;/b&gt; is required&lt;/span&gt;'
    },
    removingRiskWarning: {
        id: 'quoteandbind.ho.views.ho-your-home.Removing Risk Warning',
        defaultMessage: 'Are you sure you want to remove the risk from the list?'
    },
    removingRisk: {
        id: 'quoteandbind.ho.views.ho-your-home.Removing Risk',
        defaultMessage: 'Removing Risk'
    },
    savingRisk: {
        id: 'quoteandbind.ho.views.ho-your-home.Saving Risk',
        defaultMessage: 'Saving Risk'
    },
    additionalDetails: {
        id: 'quoteandbind.ho.views.ho-your-home.Additional Details',
        defaultMessage: 'Additional Details'
    },
    inlineNotification:{
        id: 'quoteandbind.ho.views.ho-your-home.Dwelling Risk Required',
        defaultMessage: 'A Dwelling Risk is required before proceeding'
    }
});
