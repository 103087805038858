/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import _ from 'lodash';


const CPSubmissionLobDataExtension = function (submissionVM) {
    const lobData = _.get(submissionVM, 'lobData.commercialProperty');

    if (lobData && _.isObject(lobData)) {
        this.preQualQuestionSets = lobData.preQualQuestionSets;
        this.coverables = lobData.coverables;
        this.coverages = lobData.coverages;
        this.liabilityCoverages = lobData.liabilityCoverages;
        this.linePublicID_WMIC = lobData.linePublicID_WMIC;

        this.accountOrgType = lobData.accountOrgType;
        this.busOpsDesc_Ext = lobData.busOpsDesc_Ext;
        this.isGeneralLiability = lobData.isGeneralLiability;
        this.yearBusinessStarted_Ext = lobData.yearBusinessStarted_Ext;
        this.industryCode = lobData.industryCode;
        this.tradeNames = lobData.tradeNames || [];
        this.offerings = lobData.offerings;
    } else {
        this.preQualQuestionSets = [];
        this.coverables = lobData.coverables || {};
        this.coverages = lobData.coverages || {};
        this.liabilityCoverages = lobData.liabilityCoverages || [];
    }
};

// TODO CP Remaining DataExtension - add remain functions and logic from v5 draft extention

export default CPSubmissionLobDataExtension;
