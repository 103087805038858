import { defineMessages } from 'react-intl';

export default defineMessages({
    wizard: {
        id: 'quoteandbind.cp.wizard.step.LocationBuildings',
        defaultMessage: 'Locations & Buildings',
    },
    title: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Title',
        defaultMessage: 'Locations & Buildings',
    },
    number: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Number',
        defaultMessage: '#',
    },
    name: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Name',
        defaultMessage: 'Name',
    },
    address: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Address',
        defaultMessage: 'Address',
    },
    primaryLocation: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Primary Location',
        defaultMessage: 'Primary location',
    },
    addLocation: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Add Location',
        defaultMessage: 'Add Location',
    },
    locationNumber: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Location Number',
        defaultMessage: 'Location #',
    },
    removeLocationLong: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Remove Location Long',
        defaultMessage: 'Are you sure you want to remove {displayName} location from the list?'
    },
    removeLocationShort: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Remove Location Short',
        defaultMessage: 'Remove Location'
    },
    error: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Error Title',
        defaultMessage: 'Error'
    },
    removePrimaryLocationError: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Remove Primary Location Error',
        defaultMessage: 'Primary location cannot be removed'
    },
    addBuilding: {
        id: 'quoteandbind.cp.views.LocationsBuildings.addBuilding',
        defaultMessage: 'Add Building'
    }
});
