/* eslint-disable no-secrets/no-secrets */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext, useCallback } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { WMICDateTimeService, LINE_OF_BUSINESS, PRODUCT, JobType } from "wmic-pe-portals-utils-js";
import { LoadSaveService } from 'wmic-pe-capability-gateway-quoteandbind';

import WMICAddDeclinedPolicyComponent from './WMICAddDeclinedPolicy/WMICAddDeclinedPolicyComponent';
import WMICAddInsuranceHistoryRecordComponent from './WMICAddInsuranceHistoryRecord/WMICAddInsuranceHistoryRecordComponent';
import WMICAddWawanesaPolicyComponent from './WMICAddWawanesaPolicy/WMICAddWawanesaPolicyComponent';
import metadata from './WMICInsuranceHistoryPoliciesComponent.metadata.json5';
import messages from './WMICInsuranceHistoryPoliciesComponent.messages';
import styles from './WMICInsuranceHistoryPoliciesComponent.module.scss';

const PA_COVERABLES_PATH = 'lobData.personalAuto.coverables';

function WMICInsuranceHistoryPoliciesComponent(props) {

    const {
        id,
        insuranceHistoryVM,
        onValidate,
        updateHistory,
        showErrors,
        carriersList,
        jobVM,
        readOnly,
        updateWizardData,
    } = props;

    const translator = useContext(TranslatorContext);
    const { authHeader } = useAuthentication();
    const ViewModelService = useContext(ViewModelServiceContext);
    const { onValidate: setComponentValidation, isComponentValid, registerComponentValidation } = useValidation(id);
    const quoteId = _.get(jobVM, 'quoteID.value', _.get(jobVM, 'jobID.value'));
    const lob = _.get(jobVM, 'lob.value.code', _.get(jobVM, 'product.value.productCode'));
    const isPolicyChangeOrRenewal =
        _.get(jobVM, 'baseData.jobType.value.code') === JobType.POLICY_CHANGE ||
        _.get(jobVM, 'baseData.jobType.value.code') === JobType.RENEWAL;
    const isPA = [LINE_OF_BUSINESS.PERSONAL_AUTO, PRODUCT.PERSONAL_AUTO].includes(lob);
    const isHO = [LINE_OF_BUSINESS.HOME_OWNER, PRODUCT.HOME_OWNER].includes(lob);
    const isCommercialPackage = [LINE_OF_BUSINESS.GENERAL_LIABILITY, PRODUCT.COMMERCIAL_PACKAGE].includes(lob);

    // DECLINED POLICY
    const [selectedDeclinedPol, updateSelectedDeclinedPol] = useState(undefined);
    const [editingDeclinedPolIndex, updateEditingDeclinedPolIndex] = useState(-1);
    const [addingDeclinedPol, setAddingDeclinedPol] = useState(false);
    // WAWANESA POLICY
    const [selectedWawanesaPolicy, updateSelectedWawanesaPolicy] = useState(undefined);
    const [editingWawanesaPolIndex, updateEditingWawanesaPolIndex] = useState(-1);
    const [addingWawanesaPol, setAddingWawanesaPol] = useState(false);
    // INSURANCE HISTORY RECORDS
    const [selectedInsuranceHistoryRecord, updateSelectedInsuranceHistoryRecord] = useState(undefined);
    const [editingInsuranceHistoryIndex, updateEditingInsuranceHistoryIndex] = useState(-1);
    const [addingInsuranceHistory, setAddingInsuranceHistory] = useState(false);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    useEffect(() => {
        registerComponentValidation(minimumDeclinedPolicyValidation);
    }, [_.get(insuranceHistoryVM, 'priorPolicyInfo.hasDeclinedInsurance.value')]);

    const checkWawanesaPolicyExists = useCallback((policyNumber) => {
        const relatedPoliciesList = _.get(insuranceHistoryVM, "relatedPolicies.value", []);
        const wawanesaPoliciesList = _.get(insuranceHistoryVM, "priorPolicyInfo.wawanesaPolicies.value", []);

        return relatedPoliciesList.some(relatedPolicy => relatedPolicy.policyNumber === policyNumber)
            || wawanesaPoliciesList.some(wawanesaPolicy => wawanesaPolicy.pmsOrPCPolicyNumber === policyNumber && !wawanesaPolicy.newPolicy);
    }, [insuranceHistoryVM]);

    const minimumDeclinedPolicyValidation = useCallback(() => {
        if(insuranceHistoryVM.priorPolicyInfo?.hasDeclinedInsurance.value) {
            return _.get(insuranceHistoryVM, 'priorPolicyInfo.declinedPolicies.value', []).length > 0
        }
        return true;
    }, [insuranceHistoryVM]);

    const initializePniIsDriver = () => {
        const paModel = _.get(jobVM, `${PA_COVERABLES_PATH}.value`, {});
        const pniDriver = _.filter(paModel.drivers, (driver) => driver.priInsuredRelation_wmic === 'insured');
        return pniDriver && pniDriver[0];
    };

    const isFirstPolicyDateVisible = () => {
        const pniIsDriver = isPA && initializePniIsDriver();
        return isHO || (isPA && !pniIsDriver);
    };

    // DECLINED POLICY
    const addEditDeclinedPolicy = useCallback((declinedPolicy, index) => {
        const declinedPolicyVM = ViewModelService.create(declinedPolicy, 'pc', 'wmic.edge.ca.capabilities.policyjob.lob.common.draft.dto.insurancehistory.DeclinedPolicy_WMICDTO');
        updateSelectedDeclinedPol(declinedPolicyVM);
        const declinedPoliciesList = _.get(insuranceHistoryVM, "priorPolicyInfo.declinedPolicies.value", [])
        if (index === -1) {
            declinedPoliciesList.push(declinedPolicyVM.value);
            updateEditingDeclinedPolIndex(declinedPoliciesList.length-1);
        }
        _.set(insuranceHistoryVM, "priorPolicyInfo.declinedPolicies.value", declinedPoliciesList)
        setAddingDeclinedPol(true);
    }, [ViewModelService, insuranceHistoryVM]);

    const newDeclinedPolicy = () => {
        addEditDeclinedPolicy({newPolicy: true}, -1);
    }

    const removeDeclinedPolicy = useCallback((declinedPolicy, index) => {
        const declinedPoliciesList = _.get(insuranceHistoryVM, "priorPolicyInfo.declinedPolicies.value", [])
        declinedPoliciesList.splice(index, 1);
        _.set(insuranceHistoryVM, "priorPolicyInfo.declinedPolicies.value", declinedPoliciesList)
        updateHistory(insuranceHistoryVM)
    }, [insuranceHistoryVM, updateHistory]);

    const editDeclinedPolicy = (declinedPolicy, index) => {
        addEditDeclinedPolicy(declinedPolicy.value, index);
    };

    const saveDeclinedPolicy = useCallback(() => {
        if (editingDeclinedPolIndex >= 0) {
            _.unset(selectedDeclinedPol, "value.newPolicy");
            const declinedPoliciesList = _.get(insuranceHistoryVM, "priorPolicyInfo.declinedPolicies.value", [])
            declinedPoliciesList[editingDeclinedPolIndex] = selectedDeclinedPol.value;
            _.set(insuranceHistoryVM, "priorPolicyInfo.declinedPolicies.value", declinedPoliciesList)
        }
        updateHistory(insuranceHistoryVM)
        updateEditingDeclinedPolIndex(-1);
        setAddingDeclinedPol(false);
    }, [editingDeclinedPolIndex, insuranceHistoryVM, selectedDeclinedPol, updateHistory]);

    const cancelDeclinedPolicy = useCallback(() => {
        if (_.get(selectedDeclinedPol, "value.newPolicy", false)) {
            removeDeclinedPolicy(selectedDeclinedPol, editingDeclinedPolIndex);
        }
        updateSelectedDeclinedPol(undefined);
        updateEditingDeclinedPolIndex(-1);
        setAddingDeclinedPol(false);
    }, [editingDeclinedPolIndex, removeDeclinedPolicy, selectedDeclinedPol]);

    const canEditAndDeleteDeclinedPolicy = useCallback((index, declinedPolicy) => {
        if (isPolicyChangeOrRenewal && (declinedPolicy.publicID && declinedPolicy.publicID.value !== undefined)) {
            return declinedPolicy.createdInPolicyChange.value
        }
        return true;
    }, [isPolicyChangeOrRenewal]);

    // WAWANESA POLICY
    const addEditWawanesaPolicy = useCallback((wawanesaPolicy, index) => {
        _.set(jobVM, 'isEditingPage.value', true);

        // To invalidate Quote step when adding or editng a policy
        updateWizardData(jobVM);

        const wawanesaPolicyVM = ViewModelService.create(wawanesaPolicy, 'pc', 'wmic.edge.ca.capabilities.policyjob.lob.common.draft.dto.insurancehistory.WawanesaPolicy_WMICDTO');
        updateSelectedWawanesaPolicy(wawanesaPolicyVM);
        const wawanesaPoliciesList = _.get(insuranceHistoryVM, "priorPolicyInfo.wawanesaPolicies.value", [])
        if (index === -1) {
            wawanesaPoliciesList.push(wawanesaPolicyVM.value);
            updateEditingWawanesaPolIndex(wawanesaPoliciesList.length-1);
        }
        _.set(insuranceHistoryVM, "priorPolicyInfo.wawanesaPolicies.value", wawanesaPoliciesList)
        setAddingWawanesaPol(true);
    }, [ViewModelService, insuranceHistoryVM, jobVM, updateWizardData]);

    const newWawanesaPolicy = () => {
        addEditWawanesaPolicy({newPolicy: true}, -1);
    }

    const removeWawanesaPolicy = useCallback((wawanesaPolicy, index) => {
        const wawanesaPoliciesList = _.get(insuranceHistoryVM, "priorPolicyInfo.wawanesaPolicies.value", [])
        wawanesaPoliciesList.splice(index, 1);
        _.set(insuranceHistoryVM, "priorPolicyInfo.wawanesaPolicies.value", wawanesaPoliciesList)
        updateHistory(insuranceHistoryVM)
    }, [insuranceHistoryVM, updateHistory]);

    const editWawanesaPolicy = (wawanesaPolicy, index) => {
        addEditWawanesaPolicy(wawanesaPolicy.value, index);
    };

    const saveWawanesaPolicy = useCallback(() => {
        if (editingWawanesaPolIndex >= 0) {
            _.unset(selectedWawanesaPolicy, "value.newPolicy");
            const wawanesaPoliciesList = _.get(insuranceHistoryVM, "priorPolicyInfo.wawanesaPolicies.value", [])
            wawanesaPoliciesList[editingWawanesaPolIndex] = selectedWawanesaPolicy.value;
            _.set(insuranceHistoryVM, "priorPolicyInfo.wawanesaPolicies.value", wawanesaPoliciesList)
        }
        updateHistory(insuranceHistoryVM)
        updateEditingWawanesaPolIndex(-1);
        setAddingWawanesaPol(false);
        _.set(jobVM, 'isEditingPage.value', false);
    }, [editingWawanesaPolIndex, insuranceHistoryVM, jobVM, selectedWawanesaPolicy, updateHistory]);

    const cancelWawanesaPolicy = useCallback(() => {
        if (_.get(selectedWawanesaPolicy, "value.newPolicy", false)) {
            removeWawanesaPolicy(selectedWawanesaPolicy, editingWawanesaPolIndex);
        }
        updateSelectedWawanesaPolicy(undefined);
        updateEditingWawanesaPolIndex(-1);
        setAddingWawanesaPol(false);
        _.set(jobVM, 'isEditingPage.value', false);
    }, [editingWawanesaPolIndex, jobVM, removeWawanesaPolicy, selectedWawanesaPolicy]);

    // INSURANCE HISTORY RECORDS
    const addEditInsuranceHistoryRecord = useCallback((insuranceHistoryRecord, index) => {
        _.set(jobVM, 'isEditingPage.value', true);
        updateWizardData(jobVM);

        const insuranceHistoryRecordVM = ViewModelService.create(
            insuranceHistoryRecord,
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.common.draft.dto.insurancehistory.InsuranceHistoryRecordDTO',
            jobVM.aspects.context());
        updateSelectedInsuranceHistoryRecord(insuranceHistoryRecordVM);
        const insuranceHistoryRecordsList = _.get(insuranceHistoryVM, "priorPolicyInfo.insuranceHistoryRecords.value", [])
        if (index === -1) {
            insuranceHistoryRecordsList.push(insuranceHistoryRecordVM.value);
            updateEditingInsuranceHistoryIndex(insuranceHistoryRecordsList.length-1);
        }
        _.set(insuranceHistoryVM, "priorPolicyInfo.insuranceHistoryRecords.value", insuranceHistoryRecordsList)
        setAddingInsuranceHistory(true);
    }, [ViewModelService, insuranceHistoryVM, jobVM, updateWizardData]);

    const newInsuranceHistoryRecord = () => {
        addEditInsuranceHistoryRecord({newPolicy: true}, -1);
    }

    const removeInsuranceHistoryRecord = useCallback((insuranceHistoryRecord, index) => {
        const insuranceHistoryRecordsList = _.get(insuranceHistoryVM, "priorPolicyInfo.insuranceHistoryRecords.value", [])
        insuranceHistoryRecordsList.splice(index, 1);
        _.set(insuranceHistoryVM, "priorPolicyInfo.insuranceHistoryRecords.value", insuranceHistoryRecordsList)
        updateHistory(insuranceHistoryVM)
    }, [insuranceHistoryVM, updateHistory]);

    const editInsuranceHistoryRecord = (insuranceHistoryRecord, index) => {
        addEditInsuranceHistoryRecord(insuranceHistoryRecord.value, index);
    };

    const saveInsuranceHistoryRecord = useCallback(() => {
        if (editingInsuranceHistoryIndex >= 0) {
            _.unset(selectedInsuranceHistoryRecord, "value.newPolicy");
            const insuranceHistoryRecordsList = _.get(insuranceHistoryVM, "priorPolicyInfo.insuranceHistoryRecords.value", [])
            insuranceHistoryRecordsList[editingInsuranceHistoryIndex] = selectedInsuranceHistoryRecord.value;
            _.set(insuranceHistoryVM, "priorPolicyInfo.insuranceHistoryRecords.value", insuranceHistoryRecordsList)
        }
        updateHistory(insuranceHistoryVM)
        updateEditingInsuranceHistoryIndex(-1);
        setAddingInsuranceHistory(false);
        _.set(jobVM, 'isEditingPage.value', false);
    }, [editingInsuranceHistoryIndex, insuranceHistoryVM, jobVM, selectedInsuranceHistoryRecord, updateHistory]);

    const cancelInsuranceHistoryRecord = useCallback(() => {
        if (_.get(selectedInsuranceHistoryRecord, "value.newPolicy", false)) {
            removeInsuranceHistoryRecord(selectedInsuranceHistoryRecord, editingInsuranceHistoryIndex);
        }
        updateSelectedInsuranceHistoryRecord(undefined);
        updateEditingInsuranceHistoryIndex(-1);
        setAddingInsuranceHistory(false);
        _.set(jobVM, 'isEditingPage.value', false);
    }, [editingInsuranceHistoryIndex, jobVM, removeInsuranceHistoryRecord, selectedInsuranceHistoryRecord]);

    const displayDate = (data, path) => {
        const dateValue = _.get(data.value, path);
        return dateValue ? <span>{WMICDateTimeService.toMidnightDate(dateValue)}</span> : '';
    };

    const hasDeclinedInsuranceChanged = useCallback((value, path) => {
        _.set(insuranceHistoryVM, path, value);
        if (!value) {
            _.set(insuranceHistoryVM, "priorPolicyInfo.declinedPolicies.value", []);
        }
        updateHistory(insuranceHistoryVM);
    }, [insuranceHistoryVM, updateHistory]);

    const commonValidationProps = {
        showErrors,
        onValidate: setComponentValidation
    }

    const overrideProps = {
        '@field': {
            parentNode: insuranceHistoryVM,
            readOnly
        },
        showOnePolicyRequiredMsg: {
            visible: _.get(insuranceHistoryVM, "priorPolicyInfo.declinedPolicies.value", []).length === 0 && _.get(insuranceHistoryVM, "priorPolicyInfo.hasDeclinedInsurance.value", false)
        },
        declinedPoliciesDataList: {
            VMList: _.get(insuranceHistoryVM, 'priorPolicyInfo.declinedPolicies.children', []),
            VMData: [
                {
                    headerText: translator(messages.date),
                    getData: displayDate,
                    path: 'declineDate',
                },
                {
                    headerText: translator(messages.insuranceCompany),
                    path: 'carrierName',
                },
                {
                    headerText: translator(messages.typeOfDeclination),
                    path: 'declineType',
                },
                {
                    headerText: translator(messages.reason),
                    path: 'declineReason'
                },
                {
                    headerText: translator(messages.describeReason),
                    path: 'underwritingReasonDesc'
                },
            ],
            onEditAction: editDeclinedPolicy,
            onRemoveAction: removeDeclinedPolicy,
            selectedCardIndex: editingDeclinedPolIndex,
            updateSelectedCardIndex: updateEditingDeclinedPolIndex,
            isEditing: addingDeclinedPol,
            readOnly,
            canEdit: canEditAndDeleteDeclinedPolicy,
            canDelete: canEditAndDeleteDeclinedPolicy
        },
        manipulateDeclinedPolicyForm: {
            visible: addingDeclinedPol,
            declinedPolicy: selectedDeclinedPol,
            updateSelectedDeclinedPol,
            saveDeclinedPolicy,
            cancelDeclinedPolicy,
            carriersList,
            ...commonValidationProps
        },
        addPolicyButton: {
            visible: _.get(insuranceHistoryVM, "priorPolicyInfo.hasDeclinedInsurance.value", false) && !readOnly,
            disabled: addingDeclinedPol || addingWawanesaPol || addingInsuranceHistory,
        },
        refusedCancelledDeclinedPoliciesHeaderId: {
            visible: !isCommercialPackage
        },
        // WAWANESA POLICY
        addAnyWawanesaPoliciesContainer: {
            visible: _.get(insuranceHistoryVM, 'priorPolicyInfo.wawanesaPolicies.value', []).length === 0 && !readOnly
        },
        relatedPoliciesDataList: {
            VMList: _.get(insuranceHistoryVM, 'relatedPolicies.children', []),
            VMData: [
                {
                    headerText: translator(messages.policyNumber),
                    path: 'policyNumber'
                },
                {
                    headerText: translator(messages.product),
                    path: 'productType'
                },
                {
                    headerText: translator(messages.riskType),
                    path: 'riskType'
                },
                {
                    headerText: translator(messages.status),
                    path: 'status'
                }
            ],
            readOnly: true,
            clickable: false
        },
        wawanesaPoliciesDataList: {
            VMList: _.get(insuranceHistoryVM, 'priorPolicyInfo.wawanesaPolicies.children', []),
            VMData: [

                {
                    headerText: translator(messages.policyNumber),
                    path: 'pmsOrPCPolicyNumber',
                },
                {
                    headerText: translator(messages.product),
                    path: 'productType',
                },
                {
                    headerText: translator(messages.riskType),
                    path: 'riskType',
                },
                {
                    //empty column added to match the alignment of the relatedPoliciesDataList view
                }
            ],
            onEditAction: editWawanesaPolicy,
            onRemoveAction: removeWawanesaPolicy,
            selectedCardIndex: editingWawanesaPolIndex,
            updateSelectedCardIndex: updateEditingWawanesaPolIndex,
            isEditing: addingWawanesaPol,
            readOnly
        },
        manipulateWawanesaPoliciesForm: {
            visible: addingWawanesaPol,
            policy: selectedWawanesaPolicy,
            updatePolicy: updateSelectedWawanesaPolicy,
            savePolicy: saveWawanesaPolicy,
            cancelPolicy: cancelWawanesaPolicy,
            checkWawanesaPolicyExists,
            quoteId,
            ...commonValidationProps
        },
        addWawanesaPolicyButton: {
            disabled: addingDeclinedPol || addingWawanesaPol || addingInsuranceHistory,
            visible: !readOnly
        },
        // INSURANCE HISTORY RECORDS
        insuranceHistoryRecordsDataList: {
            VMList: _.get(insuranceHistoryVM, 'priorPolicyInfo.insuranceHistoryRecords.children', []),
            VMData: [

                {
                    headerText: translator(messages.insuranceCompany),
                    path: 'carrierName',
                },
                {
                    headerText: translator(messages.policyNumber),
                    path: 'policyNumber',
                },
                {
                    headerText: translator(messages.effectiveDate),
                    getData: displayDate,
                    path: 'effectiveDate',
                },
                {
                    headerText: translator(messages.expirationDate),
                    getData: displayDate,
                    path: 'expirationDate',
                },
            ],
            onEditAction: editInsuranceHistoryRecord,
            onRemoveAction: removeInsuranceHistoryRecord,
            selectedCardIndex: editingInsuranceHistoryIndex,
            updateSelectedCardIndex: updateEditingInsuranceHistoryIndex,
            isEditing: addingInsuranceHistory,
            readOnly
        },
        manipulateInsuranceHistoryRecordForm: {
            visible: addingInsuranceHistory,
            insuranceHistoryRecord: selectedInsuranceHistoryRecord,
            updateSelectedInsuranceHistoryRecord,
            saveInsuranceHistoryRecord,
            cancelInsuranceHistoryRecord,
            carriersList,
            ...commonValidationProps
        },
        addInsuranceHistoryRecordButton: {
            disabled: addingDeclinedPol || addingWawanesaPol || addingInsuranceHistory,
            visible: !readOnly
        },
        otherLicensedDriverPrimaryContainer: {
            visible: _.get(insuranceHistoryVM, 'priorPolicyInfo.otherLicensedDriverContainer.aspects.ocular')
        },
        declinedPoliciesContainerId: {
            visible: _.get(insuranceHistoryVM, 'priorPolicyInfo.hasDeclinedInsurance.aspects.ocular')
        },
        hasDeclinedInsurance: {
            label: isPA ? translator(messages.hasDeclinedInsurancePA) : translator(messages.hasDeclinedInsuranceHO),
            readOnly: readOnly || isPolicyChangeOrRenewal
        },
        firstPolicyDate: {
            visible: isFirstPolicyDateVisible()
        },
        anyCauseForConcern: {
            readOnly: readOnly || isPolicyChangeOrRenewal
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            newDeclinedPolicy,
            hasDeclinedInsuranceChanged,
            newInsuranceHistoryRecord,
            newWawanesaPolicy
        },
        resolveComponentMap: {
            WMICAddDeclinedPolicyComponent,
            WMICAddInsuranceHistoryRecordComponent,
            WMICAddWawanesaPolicyComponent
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            model={insuranceHistoryVM}
            onModelChange={updateHistory}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}
WMICInsuranceHistoryPoliciesComponent.defaultProps = {
    readOnly: false
};

export default WMICInsuranceHistoryPoliciesComponent;
