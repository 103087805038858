import { defineMessages } from 'react-intl';

export default defineMessages({
    businesIncome: {
        id: 'wmic.pe-capability-quoteandbind-cp-react.util.businesIncome',
        defaultMessage: 'Business Income (Profits)'
    },
    businesIncomeLoss: {
        id: 'wmic.pe-capability-quoteandbind-cp-react.util.businesIncomeLoss',
        defaultMessage: 'Business Income (Profits) - Actual Loss Sustained'
    },
    additionallocationcoverages: {
        id: 'wmic.pe-capability-quoteandbind-cp-react.util.additionallocationcoverages',
        defaultMessage: 'Location Additional Coverages'
    },
    building: {
        id: 'wmic.pe-capability-quoteandbind-cp-react.util.building',
        defaultMessage: 'Building'
    },
    buildingContents: {
        id: 'wmic.pe-capability-quoteandbind-cp-react.util.businessContents',
        defaultMessage: 'Business Contents'
    },
    additionalBuildingCoverages: {
        id: 'wmic.pe-capability-quoteandbind-cp-react.util.buildingAdditionalCoverages',
        defaultMessage: 'Building Additional Coverages'
    }
});
