import { defineMessages } from 'react-intl';

export default defineMessages({
    incidentHeading: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPIncidentsDetailView.incidentHeading',
        defaultMessage: 'Incident'
    },
    incidentType: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPIncidentsDetailView.incidentType',
        defaultMessage: 'Incident Type'
    },
    incidentDescription: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPIncidentsDetailView.incidentDescription',
        defaultMessage: 'Description'
    },
    incidentDate: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPIncidentsDetailView.incidentDateOccurred',
        defaultMessage: 'Date Occurred'
    }

})
