import { defineMessages } from 'react-intl';

export default defineMessages({
    addDwelling: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.Add Dwelling',
        defaultMessage: 'Add Dwelling'
    },
    propertyDetails: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.Property Details',
        defaultMessage: 'Property Details'
    },
    protectionDetails: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.Protection Details',
        defaultMessage: 'Protection Details'
    },
    additionalInterests: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.Additional Interests',
        defaultMessage: 'Additional Interests'
    },
    risk: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.Risk',
        defaultMessage: 'Risk'
    },
    dwellingRisks: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.Dwelling Risks',
        defaultMessage: 'Dwelling Risks'
    },
    riskHeader: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.RiskNo',
        defaultMessage: 'Risk #'
    },
    dwellingHeader: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.Dwelling Use',
        defaultMessage: 'Dwelling Use'
    },
    addressHeader: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.Address',
        defaultMessage: 'Address'
    },
    riskTypeHeader: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.Risk Type',
        defaultMessage: 'Risk Type'
    },
    riskDescription: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.Risk Description',
        defaultMessage: 'Description'
    },
    watercraftRisks: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.Watercraft Risks',
        defaultMessage: 'Watercraft Risks'
    },
    addWatercraft: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.Add Watercraft',
        defaultMessage: 'Add Watercraft'
    },
    watercraftDetails: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.Watercraft Details',
        defaultMessage: 'Watercraft Details'
    },
    removeRiskTitle: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.Remove Risk Short',
        defaultMessage: 'Remove Risk'
    },
    removeRiskMessage: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.Remove Risk Long',
        defaultMessage: 'Are you sure you want to remove the risk from the list?'
    },
    confirmButtonText: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.Yes',
        defaultMessage: 'Yes'
    },
    laneway: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.Laneway',
        defaultMessage: 'Laneway'
    },
    saveRisk: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.Save Risk',
        defaultMessage: 'Save'
    },
    cancelEdit: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.Cancel Edit',
        defaultMessage: 'Cancel'
    },
    dwellingRequired: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.Minimum Dwelling Risk',
        defaultMessage: '&lt;span&gt;At least &lt;b&gt;one Dwelling Risk&lt;/b&gt; is required&lt;/span&gt;'
    },
    removingRiskWarning: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.Removing Risk Warning',
        defaultMessage: 'Are you sure you want to remove the risk from the list?'
    },
    removingRisk: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.Removing Risk',
        defaultMessage: 'Removing Risk'
    },
    savingRisk: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.Saving Risk',
        defaultMessage: 'Saving Risk'
    },
    additionalDetails: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICPolicyChangeHoRiskPage.Additional Details',
        defaultMessage: 'Additional Details'
    }
});
