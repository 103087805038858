import { defineMessages } from 'react-intl';

export default defineMessages({
    wizard: {
        id: 'wmic.pe-capability-quoteandbind-common-react.wizard.step.Contact Details',
        defaultMessage: 'Contact Details'
    },
    consent: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.contact.consent',
        defaultMessage: '&lt;p&gt;I am providing my express consent to The Wawanesa Mutual Insurance Company ("Wawanesa") for the purposes of receiving paperless policy and billing documents and communications relating to this policy via email (“eDelivery”).&lt;/p&gt;&lt;p&gt;I may withdraw my consent to eDelivery at any time by contacting my broker or Wawanesa. However, by withdrawing my consent I understand that I will no longer receive documents and communications relating to this policy via email.&lt;/p&gt;&lt;p&gt;I acknowledge that my broker&apos;s signature on this form indicates that they have shared and reviewed this form with me, and obtained my express consent in writing or verbally, to my enrollment in eDelivery for the above noted policy.&lt;/p&gt;&lt;p&gt;I further acknowledge that should I require a paper copy of the policy or billing documents, I can request such from my broker.&lt;/p&gt;&lt;p&gt;If I have questions or require a copy of the Personal Information Protection Policy, I can contact my broker or email Wawanesa directly at &lt;a href="mailto:privacy@wawanesa.com">privacy@wawanesa.com&lt;/a&gt;.&lt;/p&gt;'
    },
    emailValid: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.contact.emailValid',
        defaultMessage: 'The email has been verified'
    },
    invalidtls: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.contact.invalidtls',
        defaultMessage: 'The email does not comply with our security standards and cannot be used to receive documents.'
    },
    unavailable: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.contact.unavailable',
        defaultMessage: 'The verification could not be completed. Please continue and the email will be verified later.'
    },
    invalid: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.contact.invalid',
        defaultMessage: 'The email is invalid. A valid email will be required to bind the policy.'
    },
    brokerDistributed : {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.contact.Policy and billing documents will be distributed by the broker',
        defaultMessage: 'Policy and billing documents will be distributed by the broker'
    },
    wmicPaReturnLater: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.contact.returnLater',
        defaultMessage: 'Return Later'
    },
    polDocPrefLang_WMIC: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.contact.Policy documents preferred language',
        defaultMessage: "Policy documents preferred language"
    },
    ePOIHeading: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.contact.Electronic Proof of Insurance',
        defaultMessage: 'Electronic Proof of Insurance'
    },
    epoiEnrolled: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.contact.Would the customer like to receive and Electronic Proof of Insurance (EPOI) via email?',
        defaultMessage: 'Would the customer like to receive an Electronic Proof of Insurance (EPOI) via email?'
    },
    epoiEnrolledYes: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.contact.Yes',
        defaultMessage: 'Yes, send the EPOI to the customer via email'
    },
    epoiEnrolledNo: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.contact.No',
        defaultMessage: 'No, send proof of insurance through standard mail'
    },
    deliveryHeading: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.contact.Delivery of Documents',
        defaultMessage: 'Delivery of Documents'
    },
    statusMessageHeading: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.contact.Please share this consent with the customer: ',
        defaultMessage: 'Please share this consent with the customer: '
    },
    edeliveryEnrolled_WMIC: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.contact.Would the customer like to receive their policy and billing documents via email?',
        defaultMessage: 'Would the customer like to receive their policy and billing documents via email?'
    },
    edeliveryEnrolled_WMICYes: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.contact.edelivery.Yes',
        defaultMessage: 'Yes, send policy and billing documents to customers electronically via email'

    },
    edeliveryEnrolled_WMICNo: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.contact.edelivery.No',
        defaultMessage: 'No, send policy and billing documents to customers through standard mail'

    },
    policyContactHeading: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.contact.Policy Contact',
        defaultMessage: 'Policy Contact'
    },
    policyMailingAddress: {
        id: 'quoteandbind.views.quote-effective-date.Policy Mailing Address',
        defaultMessage: "Policy Mailing Address"
    },
    accountEmail: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.contact.Account email address',
        defaultMessage: 'Account email address'
    },
    confirmEmail: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.contact.Confirm email address',
        defaultMessage: 'Confirm email address'
    },
    verifyEmailButton: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.contact.Verify Email',
        defaultMessage: 'Verify Email'
    },
    updatingContactDetails: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.contact.UpdatingContactDetails',
        defaultMessage: 'Updating Contact Details...'
    },
    emptyEmail: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.contact.emptyEmail',
        defaultMessage: 'Email address is required for Preferred Distribution Method: Digital'
    }
});
