/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect } from 'react';
import { useDataRefresh, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import PropTypes from 'prop-types';
import metadata from './WMICHODwellingProtectionDetails.metadata.json5';
import messages from './WMICHODwellingProtectionDetails.messages'

function WMICHODwellingProtectionDetails(props) {
    const {
        id,
        riskView,
        readOnly,
        onRiskViewChange,
        onValidate
    } = props
    const { refreshData } = useDataRefresh();
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid])

    const updateRiskView = useCallback(
        (newData) => {
            refreshData()
            onRiskViewChange(newData);
        },
        [onRiskViewChange, refreshData]
    );

    const overrideProps = {
        '@field': {
            readOnly,
            parentNode: riskView
        }
    };

    return (
        <ViewModelForm
            model={riskView}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            onModelChange={updateRiskView}
            onValidationChange={setComponentValidation}
        />
    );
}

WMICHODwellingProtectionDetails.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    riskView: PropTypes.any.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    jobVM: PropTypes.any.isRequired,
    readOnly: PropTypes.bool.isRequired,
    onRiskViewChange: PropTypes.func.isRequired
};


export default WMICHODwellingProtectionDetails;
