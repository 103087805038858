import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import htmlParser, {
    domToReact
} from 'html-react-parser';
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import { ModalNext } from '@jutro/components';
import { WMICButton } from 'wmic-components-platform-react';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import styles from './WMICModal.module.scss';
import cx from 'classnames';

const emptyTranslation = {
    id: 'empty',
    defaultMessage: ''
};

function WMICModal(props) {
    const {
        title,
        message,
        parseMessage,
        description,
        iconClass,
        showCancelBtn,
        cancelBtnLabel,
        actionBtnLabel,
        actionBtnClass,
        onAfterClose,
        isOpen,
        onReject,
        onResolve,
        onCancel,
        jumpTo,
        shouldCloseOnOverlayClick,
        modalStyle
    } = props;
    const translator = useTranslator();

    useEffect(() => {
        document.body.classList.toggle('modalOpen', isOpen);
    }, [isOpen]);

    const handleClose = useCallback(() => {
        if (onCancel) {
            onCancel();
        }
        onReject();
    }, [onReject, onCancel]);

    const jumpToYourInfo = useCallback(() => {
        onReject();
        const index = 0;
        if (jumpTo) {
            jumpTo(index);
        }
    }, [jumpTo, onReject]);

    const replace = (node) => {
        if (node.type === 'tag' && node.name === 'a' && node.attribs && node.attribs.onclick === 'jumpToYourInfo') {
            return (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a role="link" tabIndex={0} onClick={jumpToYourInfo} onKeyPress={jumpToYourInfo}>{domToReact(node.children, replace)}</a>
            );
        }
    };

    return (
        <ModalNext overlayClassName={styles.WMICModal__overlay} isOpen={isOpen} onRequestClose={handleClose} onAfterClose={onAfterClose} className={cx(styles.WMICModal, modalStyle)} shouldCloseOnOverlayClick={false}>
            <div className="modal-header">
                <h3>{translator(title)}</h3>
            </div>
            <div className="modal-body">
                <div className="media-object">
                    {
                        iconClass && (
                            <i className={`${iconClass} media-object-figure`} />
                        )
                    }
                    <div className="media-object-body message">
                        {parseMessage ? (
                            <p>
                                {htmlParser(message, { replace })}
                            </p>
                        ) : (<p>{message}</p>)
                        }
                        {
                            description && (
                                <p className="description">{description}</p>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className={cx('modal-footer', styles.WMICModalFooterBtn)}>
                {showCancelBtn && (
                    <WMICButton
                        className="btn-cancel-modal"
                        onClick={handleClose}
                        type="outlined"
                    >
                        {translator(cancelBtnLabel)}
                    </WMICButton>
                )}

                <WMICButton
                    className={`${actionBtnClass}`}
                    onClick={onResolve}
                >
                    {translator(actionBtnLabel)}
                </WMICButton>
            </div>
        </ModalNext>
    );
}

WMICModal.propTypes = {
    title: PropTypes.shape({}),
    message: PropTypes.shape({}),
    parseMessage: PropTypes.bool,
    description: PropTypes.shape({}),
    iconClass: PropTypes.string,
    showCancelBtn: PropTypes.bool,
    cancelBtnLabel: PropTypes.shape({}),
    actionBtnLabel: PropTypes.shape({}),
    actionBtnClass: PropTypes.string,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func.isRequired,
    onAfterClose: PropTypes.func,
    jumpTo: PropTypes.func,
    onCancel: PropTypes.func
};

WMICModal.defaultProps = {
    title: emptyTranslation,
    message: emptyTranslation,
    parseMessage: false,
    iconClass: false,
    showCancelBtn: false,
    cancelBtnLabel: platformMessages.close,
    actionBtnLabel: platformMessages.ok,
    actionBtnClass: '',
    description: false,
    onAfterClose: _.noop,
    isOpen: false,
    onReject: _.noop,
    jumpTo: _.noop,
    onCancel: _.noop
};

export default WMICModal;
