import { CONSTANTS } from 'wmic-pe-portals-utils-js';
import WMICHOConstants from "wmic-pe-portals-utils-js/constants/WMICHOConstants";
import _ from "lodash";

export default class WMICAdditionalInterestUtil {
    static isAdditionalInterestTypeVisible = (addInterestType, riskVM) => {
        if (!riskVM) {
            return false;
        }

        const MORTGAGEE_LIMIT = 4;
        const additionalInterests = _.get(riskVM, 'additionalInterests.value', []);

        switch(addInterestType) {
            case CONSTANTS.ADDITIONAL_INTEREST_TYPES.MORTGAGEE:
                return _.get(riskVM, 'rating.hoPolicyType.value.code') !== WMICHOConstants.rentersPolicyType
                    && additionalInterests.filter(addInterest => addInterest.additionalInterestType === CONSTANTS.ADDITIONAL_INTEREST_TYPES.MORTGAGEE).length < MORTGAGEE_LIMIT;
            case CONSTANTS.ADDITIONAL_INTEREST_TYPES.CHATTEL_MORTGAGEE:
                return additionalInterests.filter(addInterest => addInterest.additionalInterestType === CONSTANTS.ADDITIONAL_INTEREST_TYPES.CHATTEL_MORTGAGEE).length < MORTGAGEE_LIMIT;
            default:
                return true;
        }
    }

    static getFilteredAdditionalInterestTypesForDwelling = (availableAddInterestTypes, riskVM) => {
        return availableAddInterestTypes.filter(
            (item) => WMICAdditionalInterestUtil.isAdditionalInterestTypeVisible(item.code, riskVM)
        );
    };

    static getFilteredAdditionalInterestTypes = (availableAddInterestTypes, riskVM) => {
        const filteredAiTypes = riskVM
        ? WMICAdditionalInterestUtil.getFilteredAdditionalInterestTypesForDwelling(availableAddInterestTypes, riskVM)
        : availableAddInterestTypes;

        return filteredAiTypes.map((aiTypeKey) =>
            ({
                ...aiTypeKey,
                name: {
                    id: aiTypeKey.name,
                    defaultMessage: aiTypeKey.name
                }
            })
        );
    };

    static clearAdditionalInterestContactInfo = (additionalInterestVM) => {
        _.set(additionalInterestVM, 'policyAdditionalInterest.primaryAddress.addressLine1', undefined);
        _.set(additionalInterestVM, 'policyAdditionalInterest.primaryAddress.addressLine2', undefined);
        _.set(additionalInterestVM, 'policyAdditionalInterest.primaryAddress.addressLine3', undefined);
        _.set(additionalInterestVM, 'policyAdditionalInterest.primaryAddress.city', undefined);
        _.set(additionalInterestVM, 'policyAdditionalInterest.primaryAddress.displayName', undefined);
        _.set(additionalInterestVM, 'policyAdditionalInterest.primaryAddress.postalCode', undefined);
        _.set(additionalInterestVM, 'policyAdditionalInterest.primaryAddress.province', undefined);
        _.set(additionalInterestVM, 'policyAdditionalInterest.primaryAddress.state', undefined);


        _.set(additionalInterestVM, 'policyAdditionalInterest.contactName', undefined);
        _.set(additionalInterestVM, 'policyAdditionalInterest.displayName', undefined);
        _.set(additionalInterestVM, 'policyAdditionalInterest.addressBookUID_WMIC', undefined)
    };

}
