import _ from 'lodash';
import React, { useContext, useCallback } from 'react';

import { TranslatorContext } from '@jutro/locale';

import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import { WMICWizardViewPage } from 'wmic-pe-portals-wizard-components-ui';
import { CONSTANTS } from 'wmic-pe-portals-utils-js';
import { WMICGLCrimeCoverages } from 'wmic-pe-capability-gateway-quoteandbind-gl-react';
import { WMICImCoverages } from 'wmic-pe-capability-gateway-quoteandbind-im-react';
import { WMICCPAdditionalInterestDetailView } from "wmic-pe-capability-gateway-quoteandbind-cp-react"

import metadata from './WMICPolicyGLAdditionalCoveragesPage.metadata.json5';
import messages from './WMICPolicyGLAdditionalCoveragesPage.messages';

function WMICPolicyGLAdditionalCoveragesPage(props) {
    const {
        wizardData: { policyVM },
    } = props;

    const translator = useContext(TranslatorContext);

    const commonOverrides = {
        'readOnly': true,
    }

    const getName = useCallback((additionalInterest) => {
        if (additionalInterest.contactType.value.code === _.lowerCase(CONSTANTS.Person)) {
            const firstName = additionalInterest.firstName.value
            const lastName = additionalInterest.lastName.value
            return `${firstName} ${lastName}`
        }
        if (additionalInterest.contactType.value.code === _.lowerCase(CONSTANTS.Company)) {
            const company = additionalInterest.companyName.value
            return `${company}`
        }
    }, []);

    const overrideProps = {
        '@field': {
            readOnly: true
        },
        crimeCoverages: {
            ...commonOverrides,
            isBoundPolicy: true,
            jobVM: policyVM,
        },
        inlandMarineCoverages: {
            ...commonOverrides,
            isBoundPolicy: true,
            jobVM: policyVM,
        },
        inlandMarineAdditionalInterestListView: {
            clickable: true,
            startOpen: false,
            readOnly: true,
            editEnabled: true,
            hideBorder: true,
            value: _.get(policyVM, `lobs.inlandMarine.machineryEquipAddlInterests.children`, []),
            VMData: [
                {
                    headerText: translator(messages.name),
                    getData: getName
                },
                {
                    headerText: translator(messages.interestType),
                    path: 'interestType'
                },
            ],
            detailViewComponent: WMICCPAdditionalInterestDetailView,
            detailViewComponentProps: {
                jobVM: policyVM,
            },
        },
        inlandMarineAdditionalInterestToolsListView: {
            clickable: true,
            startOpen: false,
            readOnly: true,
            editEnabled: false,
            hideBorder: true,
            value: _.get(policyVM, `lobs.inlandMarine.toolsAddlInterests.children`, []),
            VMData: [
                {
                    headerText: translator(messages.name),
                    getData: getName
                },
                {
                    headerText: translator(messages.interestType),
                    path: 'interestType'
                },
            ],
            detailViewComponent: WMICCPAdditionalInterestDetailView,
            detailViewComponentProps: {
                jobVM: policyVM,
            },
        },
    }


    const resolvers = {
        resolveComponentMap: {
            WMICGLCrimeCoverages,
            WMICImCoverages
        }
    };

    return (
        <WMICWizardViewPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyVM}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
            />
        </WMICWizardViewPage>
    );
}

WMICPolicyGLAdditionalCoveragesPage.propTypes = wizardProps;
export default WMICPolicyGLAdditionalCoveragesPage;
