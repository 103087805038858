import { setComponentMapOverrides } from '@jutro/uiconfig';
import { WMICQualificationPage } from 'wmic-pe-capability-quoteandbind-common-react';

import WMICPADriversDetailsPage from './pages/WMICDriversDetailsPage/WMICDriversDetailsPage';
import WMICPAVehiclesPage from './pages/WMICVehicles/WMICVehiclesPage';
import WMICPAQuotePage from './pages/WMICPAQuotePage/WMICPAQuotePage';
import WMICPACoveragesPage from './pages/WMICPACoveragesPage/WMICPACoveragesPage';

setComponentMapOverrides(
    {
        WMICPAQualificationPage: { component: 'WMICPAQualificationPage' },
        WMICPADriversDetailsPage: { component: 'WMICPADriversDetailsPage' },
        WMICPAVehiclesPage: { component: 'WMICPAVehiclesPage' },
        WMICPAQuotePage: { component: 'WMICPAQuotePage' },
        WMICPACoveragesPage: { component: 'WMICPACoveragesPage' }
    },
    {
        WMICPAQualificationPage: WMICQualificationPage,
        WMICPADriversDetailsPage,
        WMICPAVehiclesPage,
        WMICPAQuotePage,
        WMICPACoveragesPage
    }
);
