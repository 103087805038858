import React, { useContext, useEffect, useState } from 'react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';

import metadata from './WMICHOConstructionHeatingSurvey.metadata.json5';
import './WMICHOConstructionHeatingSurvey.messages';

function WMICHOConstructionHeatingSurvey(props) {
    const {
        surveyVM,
        setSurveyVM,
        id,
        showErrors: pShowErrors,
        jobVM,
        onValidate,
        readOnly,
        isRPC1671Effective,
        isHeatingSystemApprovalCertificationVisible,
        isOilSectionVisible
    } = props;

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const viewModelService = useContext(ViewModelServiceContext);
    const [showErrors, setShowErrors] = useState(pShowErrors);
    const [heatingSystemCerts, setHeatingSystemCerts] = useState([]);


    useEffect(()=> {
        setShowErrors(pShowErrors);
    }, [pShowErrors]);
    
    useEffect(() => {
        const rawHeatingCertCodes = viewModelService.productMetadata.get('pc').types.getTypelist('HeatingCertification_WMIC').codes;
        const heatingCertCodes = rawHeatingCertCodes.map((cert) => {
            return {
                ...cert,
                name: {
                    id: cert.name,
                    defaultMessage: cert.name
                }
            }
        });
        setHeatingSystemCerts(heatingCertCodes);
    }, [jobVM]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => onValidate ? onValidate(true, id) : undefined;
    }, [isComponentValid, onValidate, id]);

    const writeValue = (value, path) => {
        const newSurveyVM = viewModelService.clone(surveyVM);
        _.set(newSurveyVM, path, value);
        setSurveyVM(newSurveyVM);
    }

    const overrideProps = {
        '@field': {
            parentNode: surveyVM,
            readOnly
        },
        systemApprovalOrCertification: {
            availableValues: heatingSystemCerts,
            visible: isHeatingSystemApprovalCertificationVisible(),
            // readonly version of checkbox component doesn't display all the checkboxes, only selected ones as a list
            readOnly: false,
            disabled: readOnly
        },
        oilSection: {
            visible: isOilSectionVisible()
        },
        hasCertLabel: {
            visible: isRPC1671Effective()
        },
        drainageSystem: {
            visible: !isRPC1671Effective()
        },
        pipeConnectors: {
            visible: !isRPC1671Effective()
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={surveyVM}
            overrideProps={overrideProps}
            onModelChange={setSurveyVM}
            onValueChange={writeValue}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

export default WMICHOConstructionHeatingSurvey;

