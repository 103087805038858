import { defineMessages } from 'react-intl';

export default defineMessages({
    vehicleCustomizationType: {
        id: 'wmic-pe-capability-gateway-common-pa-react.add-customization.Type',
        defaultMessage: 'Type'
    },
    vehicleCustomizationDescription: {
        id: 'wmic-pe-capability-gateway-common-pa-react.add-customization.Description',
        defaultMessage: 'Description'
    },
    vehicleCustomizationValue: {
        id: 'wmic-pe-capability-gateway-common-pa-react.add-customization.Value',
        defaultMessage: 'Value'
    },
    vehicleCustomizationAttachedMachinery: {
        id: 'wmic-pe-capability-gateway-common-pa-react.add-customization.ExcludingOperationOfAttachedMachinery',
        defaultMessage: 'Excluding Operation Of Attached Machinery'
    },
    additionalCoverages: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-info.additionalCoverages',
        defaultMessage: 'Additional Coverages'
    },
    AttachedMachineryExclusion30A: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-info.AttachedMachineryExclusion30A',
        defaultMessage: '30(A) – Attached Machinery Exclusion – Section C Loss or Damage'
    },
    AttachedMachineryExclusion30B: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-info.AttachedMachineryExclusion30B',
        defaultMessage: '30(B) – Attached Machinery Exclusion – Third Party Liability/Accident Benefits'
    }
})
