import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslator } from '@jutro/locale';
import { useDataRefresh, ViewModelForm} from '@xengage/gw-portals-viewmodel-react';
import { CONSTANTS, WMICAddressDetailsUtil } from 'wmic-pe-portals-utils-js';
import { useValidation } from '@xengage/gw-portals-validation-react'
import PropTypes from 'prop-types';
import _ from 'lodash';
import metadata from './WMICAddressDetails.metadata.json5';
import styles from './WMICAddressDetails.module.scss'
import messages from './WMICAddressDetails.messages';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { AddressValidationUtil_Ext } from "wmic-pe-capability-address";
import { useViewModelClone } from 'wmic-pe-components-platform-react';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';

const WMICAddressDetails = (props) => {
    const {
        id,
        addressVM,
        onSave,
        onCancel,
        readOnly = false,
        hideFields,
        hideBorder,
        hideButtons,
        onValidate,
        showErrors: pShowErrors,
        readOnlyCountry
    } = props;

    const { isComponentValid, registerComponentValidation, onValidate: setComponentValidation } = useValidation('WMICAddressDetails');
    const [showErrors, setShowErrors] = useState(false);
    const [moreOverrideProps, setMoreOverrideProps] = useState({});
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const { refreshData } = useDataRefresh();
    const { setWizardLoading } = useWizardModals();
    const cloneViewModel = useViewModelClone();

    const isAddressValidationIncomplete = !readOnly
        && !!(!addressVM?.isValidationOverridden_Ext?.value)
        && !!(addressVM?.addrValidateStatus_Ext?.value)
        && (addressVM?.addrValidateStatus_Ext?.value?.code !== CONSTANTS.ADDRESS_VALIDATION_STATUS.UNAVAILABLE)
        && (addressVM?.addrValidateStatus_Ext?.value?.code !== CONSTANTS.ADDRESS_VALIDATION_STATUS.VALID)

    const validateTableForm = useCallback(() => {
        return addressVM?.aspects.valid && addressVM?.aspects.subtreeValid && !isAddressValidationIncomplete;
    }, [addressVM, isAddressValidationIncomplete]);

    useEffect(( ) => {
        registerComponentValidation(validateTableForm);
    }, [registerComponentValidation, validateTableForm]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid || readOnly, id);
        }

        return () => {
            if (onValidate) {
                onValidate(true, id)
            }
        }
    }, [isComponentValid, onValidate, id, readOnly]);

    useEffect(() => {
        const hiddenFields = _.isEmpty(hideFields) ? props.hideFields : hideFields;
        const hiddenOverrides = {};

        hiddenFields.forEach((fieldName) => {
            hiddenOverrides[fieldName] = { visible: false };
        });
        setMoreOverrideProps(mOverrideProps => ({ ...mOverrideProps, ...hiddenOverrides }));
    }, [hideFields, props.hideFields]);

    useEffect(() => {
        if (!readOnly) {
            const addressValidationStatus = addressVM?.addrValidateStatus_Ext?.value?.code;

            if (addressVM?.byPassAddressOverridde_Ext?.value !== undefined
                && (addressValidationStatus === CONSTANTS.ADDRESS_VALIDATION_STATUS.INVALID
                || addressValidationStatus === CONSTANTS.ADDRESS_VALIDATION_STATUS.SUGGESTED)) {
                addressVM.byPassAddressOverridde_Ext.value = false;
            }

            if (addressVM?.isValidationOverridden_Ext?.value !== undefined
                && addressValidationStatus !== CONSTANTS.ADDRESS_VALIDATION_STATUS.OVERRIDDEN) {
                addressVM.isValidationOverridden_Ext.value = undefined;
            }
        }
    }, [readOnly, addressVM?.addrValidateStatus_Ext?.value]);

    const handleSaveAddress = () => {
        if (isComponentValid) {
            onSave();
        } else {
            setShowErrors(true);
        }
    };

    const handleCancelAddress = () => {
        onCancel();
    }

    const getCountryData = useMemo(() => {
        const country = _.get(addressVM, 'country.value', {});
        const countryData = WMICAddressDetailsUtil.countriesAddressData;

        const hiddenFields = _.isEmpty(hideFields) ? props.hideFields : hideFields;

        if (country.code) {
            countryData[country.code].fields = countryData[
                country.code
            ].fields.filter(
                fieldData => !hiddenFields.includes(fieldData.path)
            );
            // Product bug: Country CANNOT be made to appear required
            // Ensures required fields on Address are the same as on the VM
            const requiredFields = [];
            Object.keys(addressVM).forEach(key => {
                if (addressVM[key]?.aspects?.required) {
                    requiredFields.push(key);
                }
            });
            countryData[country.code].requiredFields = requiredFields;
        }

        return countryData;
    }, [addressVM, hideFields, props.hideFields]);


    const writeValue = (addressValue, vmPath) => {
        if (vmPath) {
            _.set(addressVM, vmPath, addressValue);
        }
        else {
            // Product Bug: switching from CA to US  (or vice versa) with a postalCode set causes a crash
            // The OOTB validation functions on postal code are run against a postal code from the wrong country
            if ((addressVM.value?.country === "CA" && addressValue?.country === "US") || (addressVM.value?.country === "US" && addressValue?.country === "CA")) {
                addressValue.postalCode = undefined;
            }
            
            addressValue.addressType = addressVM.addressType?.value?.code;
            
            if (addressVM.value?.country === "CA") {
                addressValue.state = addressValue.province;
            }

            if (addressValue?.isValidationOverridden_Ext === true) {
                addressValue.isValidationOverridden_Ext = undefined;
            }

            addressValue.addrValidateStatus_Ext =  CONSTANTS.ADDRESS_VALIDATION_STATUS.NEW;

            _.set(addressVM, 'value', {});
            Object.keys(addressValue).forEach((key) => {
                _.set(addressVM, key, addressValue[key]);
            });
        }
        refreshData();
    }

    const onAddressValidation = () => ({
        // Product bug: Applying a mask to an input disables the built in pattern validation.
        // Verify we have a complete postal code before displaying the DTO pattern error.
        // Prevent the field from returning multiple errors (EX: incomplete & invalid)
        postalCode: (value) => {
            const invalidPostalCodeCharactersRegex = /[^a-zA-Z0-9\s]/; // Matches any character that is not a letter, number, or space
            const containsPlaceholders = invalidPostalCodeCharactersRegex.test(value);
            const isValid = addressVM.postalCode?.aspects?.valid;
            if (value && !containsPlaceholders && isValid === false) {
                return addressVM.postalCode?.aspects?.validationMessage;
            }
            return undefined;
        }
    });

    const onAddressValidationChange = (arg1, arg2) => {
        setComponentValidation(arg1 && _.isEmpty(arg2))
    }

    const handleValidateAddress = async () => {
        if (AddressValidationUtil_Ext.isAddressApplicableToBeValidated(addressVM)) {
            const clonedAddressVM = cloneViewModel(addressVM);
            setWizardLoading(true, translator(messages.loading));
            await AddressValidationUtil_Ext.validateAddress(addressVM, clonedAddressVM, authHeader);
            setWizardLoading(false);
            refreshData();
        }
    };

    const isOverrideAddressValidationVisible = useMemo(() => {
        return (readOnly && addressVM?.isValidationOverridden_Ext?.value)
        || (!readOnly &&
                (addressVM?.addrValidateStatus_Ext?.value?.code === CONSTANTS.ADDRESS_VALIDATION_STATUS.INVALID)
                || addressVM?.addrValidateStatus_Ext?.value?.code === CONSTANTS.ADDRESS_VALIDATION_STATUS.SUGGESTED);
    }, [readOnly, addressVM?.isValidationOverridden_Ext?.value, addressVM?.addrValidateStatus_Ext?.value]);

    const isInvalidAddressMessagingVisible = useMemo(() => {
        return !readOnly
        && !addressVM?.isValidationOverridden_Ext?.value
        && (addressVM?.addrValidateStatus_Ext?.value?.code === CONSTANTS.ADDRESS_VALIDATION_STATUS.INVALID
            || addressVM?.addrValidateStatus_Ext?.value?.code === CONSTANTS.ADDRESS_VALIDATION_STATUS.SUGGESTED);
    }, [readOnly, addressVM?.isValidationOverridden_Ext?.value, addressVM?.addrValidateStatus_Ext?.value]);

    const isValidateAddressMessagingVisible = useMemo(() => {
        return isAddressValidationIncomplete && !isOverrideAddressValidationVisible && (showErrors || pShowErrors);
    }, [isAddressValidationIncomplete, isOverrideAddressValidationVisible, showErrors, pShowErrors]);

    const overrideProps = {
        '@field': {
            parentNode: addressVM,
            readOnly
        },
        addressComponent: {
            defaultCountry: CONSTANTS.COUNTRY.CA,
            lookupLabel: translator(messages.lookupAddressLabel),
            lookupPlaceholder: translator(messages.lookupPlaceholder),
            value: addressVM?.value,
            countriesData: getCountryData,
            showErrors: showErrors || pShowErrors,
            onValidationChange: onAddressValidationChange,
            onValidationRules: onAddressValidation,
            countrySelectionType: readOnlyCountry ? 'readOnly' : 'selectable',
        },
        googleAddressLookupEditFormContainer: {
            hideBorder,
            hideButtons: hideButtons || readOnly,
            visible: !readOnly,
        },
        addressDetailsReadOnly: {
            visible: readOnly
        },
        overrideAddressValidation: {
            visible: isOverrideAddressValidationVisible
        },
        overrideAddressValidationReadOnly: {
            visible: isOverrideAddressValidationVisible
        },
        invalidAddressMessagingContainer: {
            visible: isInvalidAddressMessagingVisible
        },
        validateAddressMessagingContainer: {
            visible: isValidateAddressMessagingVisible
        },
        validateButton: {
            visible: isAddressValidationIncomplete
        },
        ...moreOverrideProps
    }

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onSaveAddress: handleSaveAddress,
            onCancelAddress: handleCancelAddress,
            onValidateAddress: handleValidateAddress
        }
    }

    return (
        <ViewModelForm
            model={addressVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors || pShowErrors}
        />
    );
}

WMICAddressDetails.propTypes = {
    addressVM: PropTypes.object.isRequired,
    hideFields: PropTypes.arrayOf(PropTypes.string)
};

WMICAddressDetails.defaultProps = {
    hideFields: ['addressLine3']
};

export default WMICAddressDetails;
