import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import { WMICBulletPointComponent } from 'wmic-pe-capability-quoteandbind-common-react';
import { CONSTANTS, JURISDICTIONS, WMICLogger } from 'wmic-pe-portals-utils-js';
import QuestionSetBulletPointUtil from './WMICQuestionSetBulletPointUtil';
import QuestionSetsViewModel from './WMICQuestionSetsViewModel';

/**
 * Retrieves questionSet from QuestionSets metadata
 * @param {string} qsName
 * @param {object} metadata
 * @throws Will throw an error if questionSet does not exist in QuestionSets metadata
 * @returns {object} - The single question from questionSets metadata
 */
function getQuestionSet(qsName, metadata) {
    const questionSet = metadata[qsName];

    if (!questionSet || !questionSet.orderedQuestions) {
        throw new Error(`There is no question set loaded with the name ${qsName}`);
    }

    return questionSet;
}

function shouldBeOmitted(questionsToOmit, code) {
    return questionsToOmit && questionsToOmit.includes(code);
}

export default class QuestionSetsParser {
    /**
     * @throws Will throw an error if questionSetsSubmissionValue is not provided
     * @param {object} questionSetsSubmissionValue
     * @param {object} metadata
     * @param {function} translator
     * @param {boolean} usesRadioButtons true if yes/no questions use radio buttons instead of toggle
     * @param {object} questionsToOmit contains an array of question code to filter questions
     * @param {map} headingsMap optional map of which questions should have a title prepended
     * @param {array} groupBySets optional array of sets by which to group questions
     * @param {string} baseState optional base satate of the policy
     */
    constructor (
        questionSetsSubmissionValue,
        metadata,
        translator,
        usesRadioButtons,
        questionsToOmit,
        headingsMap,
        groupBySets,
        baseState
    ) {
        this._viewModel = {};
        this.translator = translator;
        this.usesRadioButtons = false;
        this.headingsMap = headingsMap;
        this.groupBySets = groupBySets;
        this.baseState = baseState;

        if (usesRadioButtons === true) {
            this.usesRadioButtons = true;
        }

        if (
            !_.isObject(questionSetsSubmissionValue)
            && !_.isArray(questionSetsSubmissionValue)
        ) {
            throw new Error('A model object is required for storing answers');
        }

        if (_.isArray(questionSetsSubmissionValue)) {
            questionSetsSubmissionValue.forEach((submissionValue) => {
                this._createViewModel(submissionValue, metadata, questionsToOmit);
            });
        } else {
            this._createViewModel(questionSetsSubmissionValue, metadata, questionsToOmit);
        }
    }

    /**
     * Creates viewModel based on questionSet
     * @param {object} submissionValue - The submission value object
     * @param {string} submissionValue.code - QuestionSet code
     * @param {array} submissionValue.answers - QuestionSet available answers
     * @param {object} metadata
     * @returns {object} - viewModel
     */
    _createViewModel(submissionValue, metadata, questionsToOmit) {
        // if view model already created then just return that object
        if (submissionValue.aspects
            && submissionValue.questions
            && _.isArray(submissionValue.questions)) {
            return submissionValue;
        }

        const questionSet = getQuestionSet(submissionValue.code, metadata);

        const allQuestions = questionSet.orderedQuestions.sort((a, b) => a.order - b.order);
        const availableQuestions = _.filter(allQuestions, (question) =>
            submissionValue.answers[question.code] !== undefined && !shouldBeOmitted(questionsToOmit, question.code)
        );

        availableQuestions.forEach((question) => {
            const answer = submissionValue.answers[question.code];

            if (typeof answer === 'boolean') {
                // eslint-disable-next-line no-param-reassign
                submissionValue.answers[question.code] = answer ? 'true' : 'false';
            }

            if (question.questionType === CONSTANTS.QUESTION_TYPES.INTEGER && answer === null) {
                // eslint-disable-next-line no-param-reassign
                submissionValue.answers[question.code] = '';
            }

            this._viewModel[question.code] = new QuestionSetsViewModel(
                question, submissionValue, this.translator
            );

            const values = question.choices.map((choice) =>
                ({
                    code: choice.choiceCode,
                    name: this.translator({
                        id: choice.displayKey,
                        defaultMessage: choice.choiceCode
                    })
                })
            );

            _.extend(this._viewModel[question.code].aspects.availableValues, values);
        });
        this._viewModel = _.merge({}, this._viewModel, submissionValue);

        return this._viewModel;
    }

    /**
     * Returns the viewModel created via parser
     * @returns {ViewModel} - The viewModel type based on questionSets
     */
    get viewModel() {
        return this._viewModel;
    }

    /**
     * Returns presentation metadata based on viewModel and questionSets properties
     * @returns {object} - Presentation metadata used to render questionSets fields
     */
    get presentationMetadata() {
        const pageContent = [];

        _.forOwn(this._viewModel, (question, key) => {
            if (QuestionSetsParser.filterQuestionSets(key) && this._viewModel[key]) {
                const hasBullet = QuestionSetBulletPointUtil.isQuestionWithBulletPoints(key);
                const field = this._viewModel[key];

                const translation = this.translator({
                    id: field.label,
                    defaultMessage: field.label
                });

                let metadataContent = {};
                let titleContent = {};

                if (this.usesRadioButtons && question.question.questionType === CONSTANTS.QUESTION_TYPES.BOOLEAN) {
                    metadataContent = {
                        id: key,
                        type: 'field',
                        component: 'WMICMasterInput',
                        componentProps: {
                            label: translation,
                            className: 'control-group wmicWizardInput wmicDisplayBlock',
                            path: key,
                            parentNode: this._viewModel,
                            showRequired: true,
                            controlType: 'radio'
                        }
                    };
                } else {
                    if (this.headingsMap && this.headingsMap.has(key)) {
                        titleContent = {
                            id: `${key}Title`,
                            type: 'element',
                            component: 'WMICHeading',
                            content: this.headingsMap.get(key),
                            componentProps: {
                                variant: 'h2',
                                path: key, // used for identification by this.groupMetadataContent
                                underlined: true,
                                title: this.headingsMap.get(key)
                            }
                        };

                        this.headingsMap.delete(key);
                        pageContent.push(titleContent);
                    }

                    metadataContent = {
                        id: key,
                        type: 'field',
                        component: 'WMICMasterInput',
                        componentProps: {
                            label: translation,
                            className: cx('wmic-prequal-field', {'wmic-prequal-bullet-field': hasBullet}),
                            layout: 'reversed',
                            path: key,
                            showRequired: true,
                            parentNode: this._viewModel,
                            twoThirdsLabel: true,
                        }
                    };
                }

                if (field.aspects.inputCtrlType === 'typelist') {
                    metadataContent.componentProps.placeholder = {
                        id: 'platform.questionsets.question-set.-- Choose --',
                        defaultMessage: '-- Choose --'
                    };
                }

                if (hasBullet) {
                    const { translator, baseState } = this;
                    let bulletsKey = key;

                    if (key === 'ISSpecialVehicle_wmic' && baseState?.code === JURISDICTIONS.ONTARIO) {
                        bulletsKey = 'ISSpecialVehicle_wmic_ON'
                    }

                    const bulletPoints = QuestionSetBulletPointUtil.getBulletPoints(bulletsKey, translator);
                    const bulletContent = <WMICBulletPointComponent bulletPoints={bulletPoints} groupId={key}/>;

                    pageContent.push(metadataContent, bulletContent);
                } else {
                    pageContent.push(metadataContent);
                }
            }
        });

        return {
            content: [
                {
                    id: 'questionSetGridId',
                    type: 'container',
                    component: 'div',
                    content:this.groupBySets ? this.groupMetadataContent(pageContent) : pageContent,
                },
            ],
        };
    }

    groupMetadataContent(metadataContent) {
        // create an array of empty sets to populate with metadataContent
        const chunkedArray = Array.from({length: this.groupBySets.length}, () => new Set());

        // add metadataContent to the chunkedArray based on whether the id is found in a grouping set
        metadataContent.forEach((content) => {
            this.groupBySets.forEach((group, groupIndex) => {
                group.forEach((groupId) => {
                    if (_.get(content, 'componentProps.path', '').startsWith(groupId) ||
                    _.get(content, 'props.groupId', '').startsWith(groupId)) {
                        chunkedArray[groupIndex].add(content)
                    }
                })
            })
        })

        // flatten the chunks into a single array of metadata
        const newMetadataContent = chunkedArray.map((chunk) => ([...chunk])).flat();

        if (metadataContent.length !== newMetadataContent.length) {
            WMICLogger.error('%cItems are missing from grouped QuestionSet, please check the `groupBySets` param in WMICQUestionSetsParser initialisation.');
        }

        return newMetadataContent;
    }

    /**
     * Search for questions only, ignoring answers and code
     * @param {string} key
     * @returns {boolean}
     */
    static filterQuestionSets(key) {
        return key !== 'answers' && key !== 'code';
    }
}
