import React, { useCallback } from 'react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import metadata from './ListIterableComponent.metadata.json5';
import styles from './ListIterableComponent.module.scss';

const {
    serviceUrls
} = appConfig;
function ListIterableComponent(props) {
    const { contentData } = props;
    const generateListItemOverrides = useCallback(() => {
        const getLink = (data) => {
            return data.videoUrl ? data.videoUrl : `${serviceUrls.dotcmsHost}${data.path}${data.fileName}`;
        };
        if (contentData) {
            const overrides = contentData.map(
                (data, index) => {
                    return {
                        [`listItems${index}`]: {
                            visible: (data.path && data.fileName) || (data.videoUrl && data.name)
                        },
                        [`listItemVideo${index}`]: {
                            visible: (data.videoUrl === null) || (data.videoUrl === "")
                        }, 
                        [`listItemDoc${index}`]: {
                            visible: (data.videoUrl !== null) && (data.videoUrl !== "")
                        }, 
                        [`listLink${index}`]: {
                            content: data.name,
                            href: getLink(data)
                        }
                    };
                }
            );
    
            return Object.assign({}, ...overrides);
        }
        
    }, [contentData]);

    const overrides = {
        listItemContainer: {
            data: contentData
        },
        ...generateListItemOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

ListIterableComponent.propTypes = {
    contentData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ListIterableComponent;
