import { defineMessages } from 'react-intl';

export default defineMessages({
    wizard: {
        id: 'quoteandbind.cp.wizard.step.InsuranceHistory',
        defaultMessage: 'Insurance History'
    },
    title: {
        id: 'quoteandbind.cp.views.insuranceHistory.InsuranceHistory',
        defaultMessage: 'Insurance History'
    },
    nextButtonLabel: {
        id: 'platform.widgets.cp.wizard.Quote',
        defaultMessage: 'Quote'
    },
    policies: {
        id: 'quoteandbind.cp.views.insuranceHistory.Policies',
        defaultMessage: 'Policies'
    },
    lossHistoryTitle: {
        id: 'quoteandbind.cp.views.insuranceHistory.Loss History',
        defaultMessage: 'Loss History'
    },
    brokerInfoTitle: {
        id: 'quoteandbind.cp.views.insuranceHistory.Broker Info',
        defaultMessage: 'Broker'
    },
    anyUninsuredConcern: {
        id: 'quoteandbind.cp.views.insuranceHistory.AnyUninsuredConcern',
        defaultMessage: 'Has the applicant been uninsured at any time within the past 3 years?'
    },
    describeAnyUninsuredConcern: {
        id: 'quoteandbind.cp.views.insuranceHistory.DescribeAnyUninsuredConcern',
        defaultMessage: 'Describe'
    },
    saveInsuranceHistory: {
        id: 'quoteandbind.cp.views.cp-insurance-history-details.saveInsuranceHistory',
        defaultMessage: 'Save Insurance History'
    },
    savingInsuranceHistory: {
        id: 'quoteandbind.cp.views.cp-insurance-history-details.savingInsuranceHistory',
        defaultMessage: 'Saving Insurance History'
    }
});
