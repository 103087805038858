import WMICAddressDTOValidationFunctions from './aspects/_WMICAddressDTOValidationFunctions';
import WMICQuoteBaseDataDTOValidationFunctions from './aspects/_WMICQuoteBaseDataDTOValidationFunctions';
import WMICValidationFunctions from './aspects/_WMICValidationFunctions';
import WMICDwellingDTOValidationFunctions from './aspects/_WMICDwellingDTOValidationFunctions';
import WMICLocalDateDTOValidationFunctions from './aspects/_WMICLocalDateDTOValidationFunctions';
import WMICYourHomeValidationFunctions from './aspects/_YourHomeDTO_WMICValidationFunctions';
import WMICBluepassConfigs from './aspects/_WMICBluepassConfigs';
import WMICDWIGConstructionInfoDTOValidationFunctions from './aspects/_WMICDWIGConstructionInfoDTOValidationFunctions';
import WMICDriverDTOValidationFunctions from './aspects/_WMICDriverDTOValidationFunctions';
import WMICDriverUtilValidationFunctions from './aspects/_WMICDriverUtilValidationFunctions';
import WMICWatercraftRiskDTOValidatonFunctions from './aspects/_WMICWatercraftRiskDTOValidationFunctions';
import WMICHeaterFuelValidationFunctions from './aspects/_WMICHeaterFuelDTOValidationFunctions';
import WMICCommercialVehicleDTOValidationFunctions from './aspects/_WMICCommercialVehicleDTOValidationFunctions';
import WMICVehicleDTOValidationFunctions from './aspects/_WMICVehicleDTOValidationFunctions';
import WMICVinSearchDTOValidationFunctions from './aspects/_WMICVinSearchDTOValidationFunctions';
import WMICInsuranceHistoryHelper from './aspects/_WMICInsuranceHistoryHelper'
import WMICCreditConsentValidationFunctions from './aspects/_WMICCreditConsentValidationFunctions';
import WMICFlexDTOValidationFunctions from './aspects/_WMICFlexDTOValidationFunctions';
import EmailAndFaxValidation from './aspects/_EmailAndFaxValidation';

import DateFormatter from './util/DateFormatter';
import PhoneUtil_Ext from './util/PhoneUtil_Ext';

export const validationFunctions = {
    WMICAddressDTOValidationFunctions,
    WMICBluepassConfigs,
    WMICDWIGConstructionInfoDTOValidationFunctions,
    WMICDwellingDTOValidationFunctions,
    WMICLocalDateDTOValidationFunctions,
    WMICQuoteBaseDataDTOValidationFunctions,
    WMICValidationFunctions,
    WMICDriverDTOValidationFunctions,
    WMICWatercraftRiskDTOValidatonFunctions,
    WMICDriverUtilValidationFunctions,
    WMICHeaterFuelValidationFunctions,
    WMICCommercialVehicleDTOValidationFunctions,
    WMICVehicleDTOValidationFunctions,
    WMICYourHomeValidationFunctions,
    WMICVinSearchDTOValidationFunctions,
    WMICInsuranceHistoryHelper,
    WMICCreditConsentValidationFunctions,
    WMICFlexDTOValidationFunctions,
    EmailAndFaxValidation
};

export const utils = {
    DateFormatter,
    PhoneUtil_Ext
}
