import { setComponentMapOverrides } from '@jutro/uiconfig';
import 'gw-capability-policychange-common-react';

import { WMICPolicyRenewalQuote } from 'wmic-pe-capability-gateway-policyrenewal-common-react';
import WMICHOPolicyRenewalRisksPage from './pages/WMICHOPolicyRenewalRisks/WMICHOPolicyRenewalRisksPage';
import WMICHOPolicyRenewalConstructionPage from './pages/WMICHOPolicyRenewalConstruction/WMICHOPolicyRenewalConstructionPage';
import WMICHOPolicyRenewalCoveragesPage from './pages/WMICHOPolicyRenewalCoverages/WMICHOPolicyRenewalCoveragesPage';
import WMICHOPolicyRenewalScheduledItemsPage from './pages/WMICHOPolicyRenewalScheduledItems/WMICHOPolicyRenewalScheduledItemsPage';
import WMICHOPolicyRenewalInsuranceHistoryPage from './pages/WMICHOPolicyRenewalInsuranceHistory/WMICHOPolicyRenewalInsuranceHistoryPage';
import WMICHOPolicyRenewalPolicyDetailsPage from './pages/PolicyDetails/WMICHOPolicyRenewalPolicyDetailsPage'
import './WMICPEHORenewalWizard.messages';

setComponentMapOverrides(
    {
        WMICHOPolicyRenewalPolicyDetailsPage: { component: 'WMICHOPolicyRenewalPolicyDetailsPage' },
        WMICHOPolicyRenewalRisksPage: { component: 'WMICHOPolicyRenewalRisksPage' },
        WMICHOPolicyRenewalConstructionPage: { component: 'WMICHOPolicyRenewalConstructionPage' },
        WMICHOPolicyRenewalCoveragesPage: { component: 'WMICHOPolicyRenewalCoveragesPage' },
        WMICHOPolicyRenewalScheduledItemsPage: { component: 'WMICHOPolicyRenewalScheduledItemsPage' },
        WMICHOPolicyRenewalInsuranceHistoryPage: { component: 'WMICHOPolicyRenewalInsuranceHistoryPage' },
        WMICPolicyRenewalQuote: { component:'WMICPolicyRenewalQuote' }

    },
    {
        WMICHOPolicyRenewalPolicyDetailsPage,
        WMICHOPolicyRenewalConstructionPage,
        WMICHOPolicyRenewalRisksPage,
        WMICHOPolicyRenewalCoveragesPage,
        WMICHOPolicyRenewalScheduledItemsPage,
        WMICHOPolicyRenewalInsuranceHistoryPage,
        WMICPolicyRenewalQuote
    }
);
// eslint-disable-next-line import/prefer-default-export
export { default as WMICPEHORenewalWizard } from './WMICPEHORenewalWizard';
export { default as messages } from './WMICPEHORenewalWizard.messages';
