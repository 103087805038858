import { getProxiedServiceUrl } from 'wmic-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


export default class GatewayPaymentService {
    static makeDirectBillPayment(accountNumber, request,
        additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBillingDirectBillPayment'),
            'makeDirectBillPayment', [accountNumber, request], additionalHeaders);
    }
}
