import { defineMessages } from 'react-intl';

export default defineMessages({
    firstName: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.First Name',
        defaultMessage: 'First Name'
    },
    middleName: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Middle Name',
        defaultMessage: 'Middle Name'
    },
    lastName: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Last Name',
        defaultMessage: 'Last Name'
    },
    dateOfBirth: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Date of Birth',
        defaultMessage: 'Date of Birth'
    },
    ageAsOfPolicyExpiryDate: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Age As Of Policy Expiry Date',
        defaultMessage: 'Age As Of Policy ExpiryDate'
    },
    gender: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Gender',
        defaultMessage: 'Gender'
    },
    maritalStatus: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Marital Status',
        defaultMessage: 'Marital Status'
    },
    additionalInfoTitle: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Additional Info',
        defaultMessage: 'Additional Info'
    },
    awayAtSchool: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Away at school',
        defaultMessage: 'Away at school'
    },
    distanceFromHome: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Distance From Home',
        defaultMessage: 'Distance From Home'
    },
    goodStudentDiscountApplies: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Good Student Discount Applies',
        defaultMessage: 'Good Student Discount Applies?'
    },
    proofOfMarksReceived: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Proof of Marks Received',
        defaultMessage: 'Proof of Marks Received'
    },
    relationshipTpPriInsured: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Relationship to Primary named insured',
        defaultMessage: 'Relationship to Primary named insured'
    },
    highestLevelOfCompletedEducation: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Highest Level of Completed Education',
        defaultMessage: 'Highest Level of Completed Education'
    },
    description: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Description',
        defaultMessage: 'Description'
    },
    occupation: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Occupation',
        defaultMessage: 'Occupation'
    },
    assignment: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Assignment',
        defaultMessage: 'Assignment'
    },
    nonLicensed: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Does the driver have a license',
        defaultMessage: 'Does the driver have a license?'
    },
    ownsVehicle: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Does the driver own a vehicle',
        defaultMessage: 'Does the driver own a vehicle?'
    },
    restrictedReason: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Restricted Reason',
        defaultMessage: 'Restricted Reason'
    },
    restrictedDate: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Restricted Since Date',
        defaultMessage: 'Restricted Since Date'
    },
    otherRestrictedReason: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Other Restricted Reason',
        defaultMessage: 'Other Restricted Reason'
    },
    otherExclusionReason: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Other Exclusion Reason',
        defaultMessage: 'Other Exclusion Reason'
    },
    exclusionReason: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Exclusion Reason',
        defaultMessage: 'Exclusion Reason'
    },
    excludedSinceDate: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Excluded Since Date',
        defaultMessage: 'Excluded Since Date'
    },
    creditConsent: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Credit Consent',
        defaultMessage: 'Credit Consent'
    },
    creditConsentReceived: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Credit Consent Received',
        defaultMessage: 'Credit Consent Received'
    },
    creditConsentDate: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Credit Consent Date',
        defaultMessage: 'Credit Consent Date'
    },
    consentFormReceived: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Consent Form Received',
        defaultMessage: 'Consent Form Received'
    },
    withdrawalOfConsent: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Credit Information Withdrawal of Consent',
        defaultMessage: 'Withdrawal of Consent form received?'
    },
    qualifiesAsGoodDriver: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.personal-info.Good Driver Status',
        defaultMessage: 'Good Driver Status'
    },
});
