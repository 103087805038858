import _ from 'lodash';
import { CONSTANTS, JURISDICTIONS, LOBConstants, WMICCreditConsentUtil, WMICDateTimeService } from 'wmic-pe-portals-utils-js';

const personalAutoOrMotorhomeFound = (paDraftData) => {
    if(!_.isUndefined(paDraftData) && !_.isEmpty(paDraftData.vehicles)) {
        return paDraftData.vehicles.some((vehicle) => {
            return vehicle.businessSegment_WMIC === CONSTANTS.BUSINESS_SEGMENT_CODES.PERSONAL
            && (vehicle.vehicleType === CONSTANTS.VEHICLE_TYPES.AUTO || vehicle.vehicleType === CONSTANTS.VEHICLE_TYPES.MOTORHOME)
        })
    }
    return false;
}

const isCreditSectionVisible = (rateAsOfDate, jursidiction, lob) => {
    const jurisdictionCode = jursidiction.code;
    const PA_RPC1503_RATEBOOK_EFFDATE = new Date(2021, 6, 15);
    const PA_RPC1504_RATEBOOK_EFFDATE = new Date(2021, 3, 1);

    return !(lob.code === LOBConstants.PA_LINE
        && ((jurisdictionCode === JURISDICTIONS.ONTARIO)
            || (jurisdictionCode === JURISDICTIONS.PRINCE_EDWARD_ISLAND)
            || (jurisdictionCode === JURISDICTIONS.NOVA_SCOTIA && WMICDateTimeService.compareIgnoreTime(WMICDateTimeService.localDate2Date(rateAsOfDate), PA_RPC1504_RATEBOOK_EFFDATE) === -1)
            || (jurisdictionCode === JURISDICTIONS.NEW_BRUNSWICK && WMICDateTimeService.compareIgnoreTime(WMICDateTimeService.localDate2Date(rateAsOfDate), PA_RPC1503_RATEBOOK_EFFDATE) === -1)
        )
    );
}

const isCreditFieldsApplicable = (rateAsOfDate, jursidiction, lob) => {
    const isPersonalAuto = lob.code === LOBConstants.PA_LINE;
    const jurisdictionCode = jursidiction.code;
    return WMICCreditConsentUtil.isCreditFieldsApplicable(rateAsOfDate, jurisdictionCode, isPersonalAuto);
}

export default {
    personalAutoOrMotorhomeFound,
    isCreditSectionVisible,
    isCreditFieldsApplicable
}