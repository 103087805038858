/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { CONSTANTS, MODAL_CONSTANTS } from 'wmic-pe-portals-utils-js';
import { useWizardModals } from "wmic-pe-portals-wizard-components-ui";
import { PolicyUtilService, LoadSaveService } from 'wmic-pe-capability-gateway-quoteandbind';
import metadata from './WMICAddWawanesaPolicyComponent.metadata.json5';
import messages from './WMICAddWawanesaPolicyComponent.messages';
import styles from './WMICAddWawanesaPolicyComponent.module.scss';

const PERSONALAUTOLINE_CODE = "PersonalAutoLine";

function WMICAddWawanesaPolicyComponent(props) {
    const { id, policy, readOnly, onValidate, savePolicy, cancelPolicy, checkWawanesaPolicyExists, quoteId, showErrors: pShowErrors } = props;
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const translator = useContext(TranslatorContext);
    const { showError } = useWizardModals();
    const { authHeader } = useAuthentication();
    const { refreshData } = useDataRefresh();

    const [currentPolicy, updateCurrentPolicy] = useState(policy);
    const [showErrors, updateShowErrors] = useState(false);
    const [isPMSPolicyNumber, updateIsPMSPolicyNumber] = useState(false);
    const [invalidSearchError, updateInvalidSearchError] = useState(false);
    const [searchFailedError, updateSearchFailedError] = useState(false);
    const [isAutoProductType, updateIsAutoProductType] = useState(false);
    const [availableRiskTypes, updateAvailableRiskTypes] = useState([]);
    const [originalPolicyNumber, setOriginalPolicyNumber] = useState(_.get(policy, "pmsOrPCPolicyNumber.value"));

    const checkIsAutoProduct = (code) => code === PERSONALAUTOLINE_CODE;

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
        return () => onValidate ? onValidate(true, id) : undefined;
    }, [id, onValidate, isComponentValid]);
    
    useEffect(() => {
        const pMSPolicyNumber = _.get(policy, "pmsOrPCPolicyNumber.value") 
        const isPMSPolicy = getIsPMSPolicyNumber(pMSPolicyNumber);
        updateIsPMSPolicyNumber(isPMSPolicy);
        if (pMSPolicyNumber) {
            const productType = _.get(policy, "productType.value");
            if(productType) {
                getRiskValuesByProduct(productType.code, !pMSPolicyNumber).then((riskTypesFound) => {
                    setAvailableRiskTypes(riskTypesFound);
                });
                const isAutoProduct = checkIsAutoProduct(productType.code);
                updateIsAutoProductType(isAutoProduct);
            }
        }
    }, [])

    const checkPolicyExists = useCallback((policyNumber) => {
        let policyAlreadyExists;

        if (checkWawanesaPolicyExists(policyNumber)) {
            // If we are editing this policy, and the policy number hasn't changed,
            // this policy number already exists, allow the user to continue
            if (originalPolicyNumber) {
                if (originalPolicyNumber === policyNumber) {
                    policyAlreadyExists = false;
                }
            }
            if (policyAlreadyExists === undefined) {
                // There is already a policy (PMS or PC) with this policy number
                // Do not allow the user to add this policy
                showError({
                    message: translator(messages.policyAlreadyAdded, { policyNumber }),
                    status: MODAL_CONSTANTS.STATUS.ERROR,
                    icon: MODAL_CONSTANTS.ICON.ERROR,
                });
                policyAlreadyExists = true;
            }
        } else {
            policyAlreadyExists = false;
        }
        return policyAlreadyExists;
    }, [checkWawanesaPolicyExists, originalPolicyNumber, showError, translator]);

    const handleSavePolicy = useCallback(() => {
        const pmsOrPCPolicyNumber = _.get(policy, "pmsOrPCPolicyNumber.value");
        if(isComponentValid && !checkPolicyExists(pmsOrPCPolicyNumber)) {
            const isPMSPolicy = getIsPMSPolicyNumber(pmsOrPCPolicyNumber);
            _.set(policy, "pmsPolicyNumber.value", isPMSPolicy ? pmsOrPCPolicyNumber : null);
            _.set(policy, "policyNumber.value", !isPMSPolicy ? pmsOrPCPolicyNumber : null);
            savePolicy(currentPolicy)
        } else {
            updateShowErrors(true)
        }
    }, [checkPolicyExists, currentPolicy, isComponentValid, policy, savePolicy]);
    
    const updatePolicy = useCallback((data) => {
        refreshData();
        updateCurrentPolicy(data);
    }, [refreshData]);

    const getIsPMSPolicyNumber = (policyNumber) => {
        let pMSPolicyNumber = false;
        if (policyNumber && policyNumber.trim().length === 7) {
            pMSPolicyNumber = true;
        }
        return pMSPolicyNumber;
    };

    const isPossiblePCPolicyNumber = (policyNumber) => {
        let possiblePCPolicyNumber = false;
        if (policyNumber && policyNumber.trim().length >= 8) {
            possiblePCPolicyNumber = true;
        }
        return possiblePCPolicyNumber;
    };

    const setAvailableRiskTypes = useCallback((riskTypesFound) => {
        const riskTypesList = [];

        _.each(riskTypesFound, (option, index) => {
            riskTypesList.push({code: option, name: option});
        });

        const defaultOptionLabel = translator(riskTypesList.length <= 1 ? messages.none : messages.pleaseSelect);

        const containsNoneOption = _.find(riskTypesList, (item) => { return item.code === "" });
        if (!containsNoneOption) {
            riskTypesList.unshift(
                {
                    code: "",
                    name: defaultOptionLabel
                }
            );
        } else {
            containsNoneOption.name = defaultOptionLabel;
        }
        updateAvailableRiskTypes(riskTypesList)
    }, [translator]);

    const getRiskValuesByProduct = useCallback((productType, isPCPolicy) => {
        const getRiskTypesPromise = PolicyUtilService.getRiskValuesByProduct(
            productType, isPCPolicy, authHeader
        );
        return getRiskTypesPromise;
    }, [authHeader]);

    const policyNumberOnSearch = useCallback(() => {
        updateSearchFailedError(false);
        const policyNumber = _.get(policy, "pmsOrPCPolicyNumber.value");
        if (!checkPolicyExists(policyNumber)) {
            let invalidSearch = false;
            let searchFailed = false;
            let pMSPolicyNumber = false;
            if (getIsPMSPolicyNumber(policyNumber)) {
                pMSPolicyNumber = true;
            } else if (isPossiblePCPolicyNumber(policyNumber)) {
                const retrieveOtherInforceWawanesaPolicyPromise = LoadSaveService.retrieveOtherInforceWawanesaPolicy(policyNumber, quoteId, authHeader);
                retrieveOtherInforceWawanesaPolicyPromise.then((response) => {
                    if (response === undefined || _.isEmpty(response)) {
                        searchFailed = true;
                    } else {
                        getRiskValuesByProduct(response.productType, !pMSPolicyNumber)
                            .then((riskTypesFound) => {
                                setAvailableRiskTypes(riskTypesFound);
                                _.set(policy, "productType.value", response.productType);
                                _.set(policy, "riskType.value", response.riskType);
                                updatePolicy(policy);
                            });
                    }
                })
                    .catch((error) => {
                        if (error.message === CONSTANTS.POLICY_NUMBER_MATCHES_CURRENT_POLICY) {
                            showError({
                                message: translator(messages.policyMatchesCurrentPolicy),
                                status: 'error',
                            });
                        } else {
                            searchFailed = true;
                        }
                    })
                    .finally(() => {
                        updateSearchFailedError(searchFailed);
                    });
            } else {
                invalidSearch = true;
            }
            updateInvalidSearchError(invalidSearch);
            updateIsPMSPolicyNumber(pMSPolicyNumber);
            if (policyNumber) {
                const productType = _.get(policy, "productType.value");
                if(productType) {
                    getRiskValuesByProduct(productType.code, !pMSPolicyNumber).then((riskTypesFound) => {
                        setAvailableRiskTypes(riskTypesFound);
                    });
                }
            }
        }
    }, [authHeader, checkPolicyExists, getRiskValuesByProduct, policy, quoteId, setAvailableRiskTypes, updatePolicy]);

    const onProductTypeChange = useCallback((value) => {
        let isAutoProduct = false;
        _.set(policy, "productType.value", value);
        if (value) {
            getRiskValuesByProduct(value, isPMSPolicyNumber).then((riskTypesFound) => {
                setAvailableRiskTypes(riskTypesFound);
            });

            isAutoProduct = checkIsAutoProduct(value);
        }
        _.set(policy, "riskType.value", "");
        _.unset(policy.value, "multiVehicleDiscountType");
        updatePolicy(policy);
        updateIsAutoProductType(isAutoProduct);
    }, [getRiskValuesByProduct, isPMSPolicyNumber, policy, setAvailableRiskTypes, updatePolicy]);

    const getPolicyNumberErrorMessage = () => {
        if (invalidSearchError) {
            return [translator(messages.invalidSearchError)];
        }
        if (searchFailedError) {
            return [translator(messages.policyNotFoundError)];
        }
        return [];
    };

    const overrideProps = {
        '@field': {
            parentNode: policy,
        },
        pmsOrPCPolicyNumber: {
            validationMessages: getPolicyNumberErrorMessage(),
        },
        invalidSearchErrorMessage: {
            visible: invalidSearchError
        },
        policyNotFoundErrorMessage: {
            visible: searchFailedError
        },
        pmsPolicyEnteredContainer: {
            visible: isPMSPolicyNumber
        },
        productType: {
            disabled: !isPMSPolicyNumber,
            onValueChange: onProductTypeChange
        },
        riskType: {
            visible: !isPMSPolicyNumber,
            disabled: !isPMSPolicyNumber
        },
        riskTypeDropdownselect: {
            visible: isPMSPolicyNumber,
            availableValues: availableRiskTypes
        },
        multiVehicleDiscountType: {
            visible: isPMSPolicyNumber && isAutoProductType 
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleSavePolicy,
            cancelPolicy,
            policyNumberOnSearch
        }
    };

    return (
        <ViewModelForm
            model={policy}
            onModelChange={updatePolicy}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors || pShowErrors}
        />
    );
}

export default WMICAddWawanesaPolicyComponent;
