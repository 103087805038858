import { UW_BLOCKING_POINT , BOT_STATUS } from 'wmic-pe-portals-utils-js';
import _ from 'lodash';

const isBlockingUWIssuePresent = (submissionVM) => {
    const underwritingIssues = _.get(submissionVM, 'errorsAndWarnings.underwritingIssues.value');
    return hasIssuanceBlockingUWIssues(underwritingIssues);
};

const hasIssuanceBlockingUWIssues = (underwritingIssues) => {
    if (underwritingIssues) {
        const blockingUnderwritingIssues = underwritingIssues.filter((underwritingIssue) => ( 
            (underwritingIssue.botStatus === BOT_STATUS.MANUAL_APPROVED_UW_ISSUE) && 
            (underwritingIssue.currentBlockingPoint === UW_BLOCKING_POINT.BLOCK_ISSUANCE) || 
            (underwritingIssue.currentBlockingPoint === UW_BLOCKING_POINT.REJECTED) ));
        return blockingUnderwritingIssues.length > 0;
    }
    return false;
};

const isBindBlockingUWIssuePresent = (underwritingIssues) => _.some(underwritingIssues, (underwritingIssue) => (underwritingIssue.currentBlockingPoint === UW_BLOCKING_POINT.BLOCK_BIND) )

const hasValidationErrors = (submissionVM) => {
    const issues = _.get(submissionVM, 'errorsAndWarnings.validationIssues.issues.value');
    return issues ? !!issues.find((e) => e.type === 'error') : false;
}

const isQuoteBlockingUWIssuePresent = (underwritingIssues) =>  _.some(underwritingIssues, (underwritingIssue) => underwritingIssue.currentBlockingPoint === UW_BLOCKING_POINT.BLOCK_QUOTE)

export default {
    hasIssuanceBlockingUWIssues,
    isBlockingUWIssuePresent,
    isQuoteBlockingUWIssuePresent,
    isBindBlockingUWIssuePresent,
    hasValidationErrors
}
