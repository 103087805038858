import { defineMessages } from 'react-intl';

export default defineMessages({
    attentionHeader: {
        id: 'pe-portal.wizard.components.WizardBMSIModal.Attention',
        defaultMessage: 'Attention'
    },
    okButton: {
        id: 'pe-portal.wizard.components.WizardBMSIModal.OK',
        defaultMessage: 'OK'
    },
    errorHeader: {
        id: "pe-portal.wizard.components.WizardBMSIModal.There is incorrect or missing information in this policy.",
        defaultMessage: "There is &lt;strong&gt;incorrect &lt;/strong&gt; or &lt;strong&gt;missing information&lt;/strong&gt; in this policy.",
    },
    errorBody:{
        id: "pe-portal.wizard.components.WizardBMSIModal.errorMessageBody",
        defaultMessage: "The affected fields must be corrected before quoting this submission."
    },
    defaultHeader: {
        id: "pe-portal.wizard.components.WizardBMSIModal.Defaulted values have been set in this submission.",
        defaultMessage: "&lt;strong&gt;Defaulted values&lt;/strong&gt; have been set in this submission",
    },
    defaultBody: {
        id: "pe-portal.wizard.components.WizardBMSIModal.defaultMessageBody",
        defaultMessage: "Defaulted values &lt;strong&gt;must be confirmed&lt;/strong&gt; before quoting this submission.",
    },
});
