import { defineMessages } from 'react-intl';

export default defineMessages({
    creditConsentHeading: {
        id: 'wmic-pe-capability-gateway-common-react.WMICCreditConsent.Credit Consent',
        defaultMessage: 'Credit Consent'
    },
    creditConsentForPNIHeading: {
        id: 'wmic-pe-capability-gateway-common-react.WMICCreditConsent.Credit Consent For',
        defaultMessage: "Credit Consent for &lt;b&gt;{name}&lt;/b&gt;"
    },
    personalInfoConsentForm: {
        id: "wmic-pe-capability-gateway-common-react.WMICCreditConsent.Personal Information and Consent Form",
        defaultMessage: "Consent form received?"
    },
    creditConsentDate: {
        id: "wmic-pe-capability-gateway-common-react.WMICCreditConsent.CreditConsentDate",
        defaultMessage: "Date consent provided"
    },
    creditInfoWithdrawalConsent: {
        id: "wmic-pe-capability-gateway-common-react.WMICCreditConsent.Credit Information Withdrawal of Consent",
        defaultMessage: "Withdrawal of Consent form received?"
    },
    withdrawalCreditConsentDate: {
        id: "wmic-pe-capability-gateway-common-react.WMICCreditConsent.CreditDeclinedDate",
        defaultMessage: "Date Declined"
    }
});