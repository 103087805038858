import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import WMICDwellingInfo from '../HO/WMICDwellingInfo/WMICDwellingInfo';
import WMICVehiclesDriversInfo from '../PA/WMICVehiclesDriversInfo/WMICVehiclesDriversInfo';
import WMICPUPCoverageInfo from "../PUP/WMICPUPCoverageInfo/WMICPUPCoverageInfo";
import WMICLiabilityCoverages from "../GL/WMICLiabilityCoverages/WMICLiablityCoverages"
import WMICLocationInfo from '../CP/WMICLocationInfo/WMICLocationInfo';
import metadata from './WMICCoverages.metadata.json5';

function WMICCoverages(props) {
    const { lobData, jobVM, isPolicyChangeOrRenewal } = props;

    if (_.isEmpty(lobData?.value)) {
        return null;
    }

    const overrides = {
        vehiclesDriversInfoComponent: {
            visible: !_.isEmpty(lobData?.value?.personalAuto),
            paDataVM: lobData.personalAuto,
            jobVM
        },
        homeOwnersInfoComponent: {
            visible: !_.isEmpty(lobData?.value?.homeowners),
            homeOwnersData: lobData.homeowners,
            isPolicyChangeOrRenewal,
            jobVM
        },
        personalUmbrellaInfoComponent: {
            visible: !_.isEmpty(lobData?.value?.personalUmbrella),
            pupDataVM: lobData.personalUmbrella,
            jobVM
        },
        liabilityCoveragesComponent: {
            visible: !_.isEmpty(lobData?.value?.generalLiability),
            jobVM
        },
        genericCoveragesComponent: {
            visible:
                _.isEmpty(lobData?.value?.homeOwners)
                && _.isEmpty(lobData?.value?.personalAuto)
                && !_.isEmpty(lobData?.value?.personalUmbrella)
        },
        locationBuildingInfoComponent: {
            visible: !_.isEmpty(lobData?.value?.commercialProperty) && !lobData.commercialProperty.isGeneralLiability.value,
            cpDataVM: lobData.commercialProperty,
            jobVM
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICDwellingInfo,
            WMICVehiclesDriversInfo,
            WMICPUPCoverageInfo,
            WMICLiabilityCoverages,
            WMICLocationInfo
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={lobData}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

WMICCoverages.propTypes = {
    value: PropTypes.shape({})
};

WMICCoverages.defaultProps = {
    value: {
        lobs: {
            homeOwners: {},
            personalAuto: {},
            personalUmbrella: {},
            generalLiability: {},
            commercialProperty: {},
            coverables: []
        }
    }
};

export default WMICCoverages;
