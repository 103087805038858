import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { WatercraftContext } from 'wmic-pe-capability-gateway-quoteandbind-ho-react';
import metadata from './WMICHOAccessoryInfoDetails.metadata.json5';
import messages from './WMICHOAccessoryInfoDetails.messages';

function WMICHOAccessoryInfoDetails({
    id,
    accessoryData,
    onValidate,
    readOnly,
    showErrors
}) {
    const {
        onValidate: setComponentValidation,
        isComponentValid
    } = useValidation(id);
    const { updateWatercraftRiskView } = useContext(WatercraftContext);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => {
            if (onValidate) {
                onValidate(true, id);
            }
        }
    }, [id, onValidate, isComponentValid]);

    const overrideProps = {
        '@field': {
            readOnly,
            parentNode: accessoryData
        }
    };

    return (
        <ViewModelForm
            model={accessoryData}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            onModelChange={updateWatercraftRiskView}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
};

WMICHOAccessoryInfoDetails.propTypes = {
    accessoryData: PropTypes.shape({}).isRequired,
    readOnly: PropTypes.bool
};

WMICHOAccessoryInfoDetails.defaultProps = {
    readOnly: false
};

export default WMICHOAccessoryInfoDetails;
