import React, { Component } from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { Icon } from '@jutro/components';
import _ from 'lodash';
import {
    TranslatorContext, withIntl
} from '@jutro/locale';
import { MetadataContent } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { AccountService } from 'gw-capability-gateway-policy';
import { PolicyService } from 'gw-capability-gateway';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { DatatableUtil, LobIconUtil } from 'wmic-portals-utils-js';
import {
    FieldLinkComponent,
    Currency as CurrencyField
} from 'gw-components-platform-react';
import { messages as platformMessages} from '@xengage/gw-platform-translations';
import { SelectProducerCode, withProducerContext } from 'gw-gateway-common-react';
import PolicyAdvanceFilter from '../Policy/AdvancedFilter/AdvancedPolicyFilter';
import metadata from './Policies.metadata.json5';
import styles from './Policies.module.scss';
import messages from './Policies.messages';

const { gatewayParamConfig = {} } = appConfig;

const availableAccountID = ['quotes', 'renewal', 'change', 'cancellation'];
const availableAdvancedFilterID = ['Quotes', 'Renewal', 'Change', 'Cancellation'];

const tableHeadings = {
    recentlyViewedTile: messages.recentlyViewedPolicies,
    recentlyIssuedTile: messages.recentlyIssuedPolicyForPastXDays,
    delinquentTile: messages.delinquentPolicies,
    Policies: messages.policies,
    Quotes: messages.policyLandingQuotes,
    Renewal: messages.policyLandingRenewal,
    Change: messages.policyLandingChange,
    Cancellation: messages.policyLandingCancellation,
    quotes: messages.openQuotes,
    renewal: messages.openRenewals,
    change: messages.openChanges,
    cancellation: messages.openCancellations
};
const currentViews = ['Quotes', 'Renewal', 'Change', 'Cancellation'];
class Policies extends Component {
    static propTypes = {
        match: PropTypes.shape({
            path: PropTypes.string,
            params: PropTypes.shape({
                PolicyNumber: PropTypes.string
            })
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        location: PropTypes.shape({
            state: PropTypes.string
        }).isRequired,
        authHeader: PropTypes.shape({}).isRequired,
        intl: PropTypes.func.isRequired,
        producerCode: PropTypes.string.isRequired
    };

    static contextType = TranslatorContext;

    state = {
        toggleFilter: true,
        currentView: '',
        policyData: [],
        recentlyIssuedResponse: [],
        delinquentData: [],
        accountData: [],
        quotesCount: 0,
        renewalCount: 0,
        changeCount: 0,
        cancellationCount: 0,
        tableData: [],
        accountTableData: [],
        toggleNoDataMsg: false,
        policyLandingLoader: true,
        allDelinquentPolicies: [],
        policyNumberVisible: true
    };

    componentDidMount() {
        const { location } = this.props;
        const { state } = location;
        if (state) {
            const { selectedTile } = state;
            const isPolicyNumberVisible = selectedTile !== 'quotes';
            this.setState({
                currentView: selectedTile !== undefined ? selectedTile : 'recentlyViewedTile',
                policyNumberVisible: isPolicyNumberVisible
            });
        } else {
            this.setState({ currentView: 'recentlyViewedTile' });
        }
        this.getResponseRecentlyIssuedPolicies();
    }

    getResponse = (currentView, selectedProducerCode) => {
        const { policyData } = this.state;
        const policyItems = [];
        this.setState({
            tableData: [],
            accountTableData: []
        });
        policyData.forEach((policyDetails) => {
            let tableDataList;

            if (
                currentView === 'recentlyViewedTile'
                || currentView === 'recentlyIssuedTile'
                || currentView === 'Policies'
            ) {
                if (selectedProducerCode === 'all') {
                    tableDataList = policyDetails;
                } else {
                    tableDataList = policyDetails.producerCodeOfService === selectedProducerCode;
                }
            } else if (currentView === 'delinquentTile') {
                if (selectedProducerCode === 'all') {
                    tableDataList = policyDetails.delinquent;
                } else {
                    tableDataList = policyDetails.delinquent
                        && policyDetails.producerCodeOfService === selectedProducerCode;
                }
            }
            if (tableDataList) {
                const policy = {
                    productCode: policyDetails.product.productCode,
                    insuredName: policyDetails.primaryInsuredName,
                    displayStatus: policyDetails.displayStatus,
                    accountNumber: policyDetails.accountNumber,
                    policyNumber: policyDetails.policyNumber,
                    account: policyDetails.accountNumber,
                    accountHolderName: policyDetails.accountHolderName,
                    primaryInsuredName: policyDetails.primaryInsuredName,
                    premium: policyDetails.premium,
                    effectiveDate: policyDetails.effective,
                    expirationDate: policyDetails.expiration
                };
                policyItems.push(policy);
            }
            return true;
        });
        this.setState({
            tableData: policyItems || [],
            toggleNoDataMsg: policyItems.length === 0
        });
    };

    sortJobNumberByDesc = (current, next) => {
        return next.jobNumber - current.jobNumber;
    };

    getAccountResponseData = (producerCode) => {
        const { accountData, currentView, toggleFilter } = this.state;
        const { intl } = this.props;
        const accountItems = [];
        let tableDataList;
        let accountItem;
        this.setState({
            accountTableData: [],
            tableData: []
        });
        accountData.forEach((accountDetails) => {
            const {
                openSubmissions,
                openPolicyChanges,
                openRenewals,
                openCancellations
            } = accountDetails;
            if (this.parseCase(currentView) === 'Quotes' && toggleFilter) {
                tableDataList = openSubmissions;
            } else if (this.parseCase(currentView) === 'Renewal' && toggleFilter) {
                tableDataList = openRenewals;
            } else if (this.parseCase(currentView) === 'Change' && toggleFilter) {
                tableDataList = openPolicyChanges;
            } else if (this.parseCase(currentView) === 'Cancellation' && toggleFilter) {
                tableDataList = openCancellations;
            } else if (currentViews.indexOf(this.parseCase(currentView)) >= 0 && !toggleFilter) {
                tableDataList = accountDetails;
            }

            if (_.isArray(tableDataList)) {
                tableDataList.forEach((quoteItems) => {
                    const effectiveDate = new Date(quoteItems.createTime);
                    const currentDate = new Date();
                    let producerCodeCondition;
                    if (producerCode === 'all') {
                        producerCodeCondition = effectiveDate <= currentDate
                            && effectiveDate
                                >= currentDate.setDate(
                                    currentDate.getDate()
                                        - gatewayParamConfig.jobsCreatedInLastXDays
                                );
                    } else {
                        producerCodeCondition = quoteItems.producerCodeOfService === producerCode
                            && effectiveDate <= currentDate
                            && effectiveDate
                                >= currentDate.setDate(
                                    currentDate.getDate()
                                        - gatewayParamConfig.jobsCreatedInLastXDays
                                );
                    }
                    if (producerCodeCondition) {
                        accountItem = {
                            productCode: quoteItems.product.productCode,
                            premium: quoteItems.totalPremium,
                            jobStatus: quoteItems.status,
                            jobNumber: quoteItems.jobNumber,
                            accountNumber: quoteItems.accountNumber,
                            accountHolderName: quoteItems.accountHolderName,
                            policyNumber: quoteItems.policyNumber,
                            account: quoteItems.accountNumber,
                            created: intl.formatDate(new Date(quoteItems.createTime), { year: 'numeric', month: 'short', day: 'numeric' })
                        };
                        accountItems.push(accountItem);
                    }
                });
            } else {
                if (!_.isEmpty(tableDataList)) {
                    accountItem = {
                        productCode: tableDataList.product ? tableDataList.product.productCode : '',
                        premium: tableDataList.amount,
                        jobStatus: tableDataList.status,
                        jobNumber: tableDataList.jobNumber,
                        accountNumber: tableDataList.accountNumber,
                        accountHolderName: tableDataList.accountHolderName,
                        policyNumber: tableDataList.policyNumber,
                        account: tableDataList.accountNumber,
                        created:tableDataList.createTime
                    };
                }
                accountItems.push(accountItem);
            }
        });
        accountItems.sort(this.sortJobNumberByDesc);
        this.setState({
            accountTableData: accountItems,
            toggleNoDataMsg: accountItems.length === 0
        });
    };

    getTileValue = (producerCode) => {
        const { accountData } = this.state;
        const cancellationArray = [];
        const changesArray = [];
        const renewalsArray = [];
        const quotesArray = [];
        accountData.forEach((accountDetails) => {
            if (accountDetails.openSubmissions) {
                accountDetails.openSubmissions.forEach((items) => {
                    const effectiveDate = new Date(items.createTime);
                    const currentDate = new Date();
                    if (
                        producerCode === 'all'
                        && effectiveDate <= currentDate
                        && effectiveDate
                            >= currentDate.setDate(
                                currentDate.getDate() - gatewayParamConfig.jobsCreatedInLastXDays
                            )
                    ) {
                        quotesArray.push(items);
                    } else if (
                        items.producerCodeOfService === producerCode
                        && effectiveDate <= currentDate
                        && effectiveDate
                            >= currentDate.setDate(
                                currentDate.getDate() - gatewayParamConfig.jobsCreatedInLastXDays
                            )
                    ) {
                        quotesArray.push(items);
                    }
                    return true;
                });
            }
            if (accountDetails.openRenewals) {
                accountDetails.openRenewals.forEach((items) => {
                    const effectiveDate = new Date(items.createTime);
                    const currentDate = new Date();
                    if (
                        producerCode === 'all'
                        && effectiveDate <= currentDate
                        && effectiveDate
                            >= currentDate.setDate(
                                currentDate.getDate() - gatewayParamConfig.jobsCreatedInLastXDays
                            )
                    ) {
                        renewalsArray.push(items);
                    } else if (
                        items.producerCodeOfService === producerCode
                        && effectiveDate <= currentDate
                        && effectiveDate
                            >= currentDate.setDate(
                                currentDate.getDate() - gatewayParamConfig.jobsCreatedInLastXDays
                            )
                    ) {
                        renewalsArray.push(items);
                    }
                    return true;
                });
            }
            if (accountDetails.openPolicyChanges) {
                accountDetails.openPolicyChanges.forEach((items) => {
                    const effectiveDate = new Date(items.createTime);
                    const currentDate = new Date();
                    if (
                        producerCode === 'all'
                        && effectiveDate <= currentDate
                        && effectiveDate
                            >= currentDate.setDate(
                                currentDate.getDate() - gatewayParamConfig.jobsCreatedInLastXDays
                            )
                    ) {
                        changesArray.push(items);
                    } else if (
                        items.producerCodeOfService === producerCode
                        && effectiveDate <= currentDate
                        && effectiveDate
                            >= currentDate.setDate(
                                currentDate.getDate() - gatewayParamConfig.jobsCreatedInLastXDays
                            )
                    ) {
                        changesArray.push(items);
                    }
                    return true;
                });
            }
            if (accountDetails.openCancellations) {
                accountDetails.openCancellations.forEach((items) => {
                    const effectiveDate = new Date(items.createTime);
                    const currentDate = new Date();
                    if (
                        producerCode === 'all'
                        && effectiveDate <= currentDate
                        && effectiveDate
                            >= currentDate.setDate(
                                currentDate.getDate() - gatewayParamConfig.jobsCreatedInLastXDays
                            )
                    ) {
                        cancellationArray.push(items);
                    } else if (
                        items.producerCodeOfService === producerCode
                        && effectiveDate <= currentDate
                        && effectiveDate
                            >= currentDate.setDate(
                                currentDate.getDate() - gatewayParamConfig.jobsCreatedInLastXDays
                            )
                    ) {
                        cancellationArray.push(items);
                    }
                    return true;
                });
            }
            return true;
        });
        this.setState({
            quotesCount: quotesArray.length,
            renewalCount: renewalsArray.length,
            changeCount: changesArray.length,
            cancellationCount: cancellationArray.length
        });
    };

    getActiveTileValue = (currentView) => {
        const { [`${currentView}Count`]: value } = this.state;
        return value;
    };

    onChangeAdvanceFilter = (value) => {
        const { producerCode: selectedProducerCode } = this.props;
        this.setState(
            {
                currentView: value.currentView,
                policyData: value.data,
                accountData: value.data
            },
            () => this.triggerGetResponse(value.currentView, selectedProducerCode)
        );
    };

    parseCase = (id) => {
        return _.upperFirst(id);
    };

    triggerGetResponse = (id, producerCode) => {
        const checkTableDataType = currentViews.indexOf(this.parseCase(id)) >= 0;
        if (checkTableDataType) {
            this.getAccountResponseData(producerCode);
        } else {
            this.getResponse(id, producerCode);
        }
        this.getTileValue(producerCode);
    };

    handleOnClick = async (id) => {
        const {
            recentlyIssuedResponse,
            delinquentData,
            policyResponse
        } = this.state;
        const { producerCode: selectedProducerCode } = this.props;
        const { authHeader } = this.props;
        let setPolicyDataResponse;
        if (delinquentData.length === 0) {
            await PolicyService.getPolicySummariesForCurrentUser(authHeader).then(
                (delinquentResponse) => {
                    this.setState({ delinquentData: delinquentResponse });
                    setPolicyDataResponse = delinquentResponse;
                }
            );
        } else {
            setPolicyDataResponse = delinquentData;
        }
        this.setState({ allDelinquentPolicies: setPolicyDataResponse });
        if (id === 'recentlyViewedTile') {
            setPolicyDataResponse = policyResponse;
        } else if (id === 'recentlyIssuedTile') {
            setPolicyDataResponse = recentlyIssuedResponse;
        }

        this.setState(
            {
                currentView: id,
                policyData: setPolicyDataResponse
            },
            () => {
                this.triggerGetResponse(id, selectedProducerCode);
            }
        );
    };

    handleValueChange = (producerCode) => {
        const { currentView } = this.state;
        this.triggerGetResponse(currentView, producerCode);
    };

    getCurrencyCell = (item, index, { id: property }) => {
        const translator = this.context;
        const totalPremiumCurrencyId = `totalPremium_${index}`;
        return (
            <span title={translator(messages.premium)}>
                <CurrencyField
                    id={totalPremiumCurrencyId}
                    value={item[property]}
                    dataType="object"
                    readOnly
                    hideLabel
                    showOptional={false}
                />
            </span>
        );
    };

    getCell = (item, index, { id: property }) => {
        const translator = this.context;
        let toolTipMessage = '';

        switch (property) {
            case 'created':
                toolTipMessage = translator(messages.created);
                break;
            case 'jobStatus':
                toolTipMessage = translator(messages.jobStatus);
                break;
            case 'effectiveDate':
                toolTipMessage = translator(messages.effectiveDate);
                break;
            case 'expirationDate':
                toolTipMessage = translator(messages.expirationDate);
                break;
            default:
                toolTipMessage = '';
        }
        return (
            <span title={toolTipMessage}>
                {item[property]}
            </span>
        );
    };

    getProductImage = (item) => {
        const icon = LobIconUtil.getFontIcon(item.productCode);
        const translator = this.context;
        const productCode = _.lowerFirst(item.productCode);
        return (
            <Icon
                icon={icon}
                title={translator(platformMessages[productCode]) || platformMessages[productCode]}
            />
        );
    };

    getLink = (item, index, { id: property }) => {
        const { currentView } = this.state;
        const translator = this.context;
        let toolTipMessage = '';
        let redirectRoute = '';
        switch (property) {
            case 'policyNumber':
                toolTipMessage = translator(messages.policyNumber);
                redirectRoute = 'policies';
                break;
            case 'jobNumber':
                toolTipMessage = translator(messages.jobNumber);
                redirectRoute = currentView;
                break;
            default:
                toolTipMessage = '';
        }
        return (
            <FieldLinkComponent
                id={`policy${item[property]}`}
                to={`/${redirectRoute}/${item[property]}/summary`}
                className={styles.removeLinkStyle}
                title={translator(toolTipMessage)}
                value={item[property]}
            />
        );
    };

    getAccountNumberLink = (item, index, { id: property }) => {
        const { accountHolderName } = item;
        const translator = this.context;
        let toolTipMessage = '';
        let redirectRoute = '';
        switch (property) {
            case 'account':
            case 'accountNumber':
                toolTipMessage = translator(messages.account);
                redirectRoute = 'accounts';
                break;
            default:
                toolTipMessage = '';
        }
        return (
            <FieldLinkComponent
                id={`account${item[property]}`}
                to={`/${redirectRoute}/${item[property]}/summary`}
                className={styles.removeLinkStyle}
                title={translator(toolTipMessage)}
                value={accountHolderName}
            />
        );
    };

    handleFilterClick = async () => {
        const {
            toggleFilter,
            currentView,
            currentJobsResponse,
            policyResponse,
            delinquentData
        } = this.state;
        const { authHeader, producerCode: selectedProducerCode } = this.props;
        this.setState({
            policyLandingLoader: true
        });
        if (toggleFilter) {
            let delinquentResponse;
            if (delinquentData.length === 0) {
                delinquentResponse = await PolicyService.getPolicySummariesForCurrentUser(
                    authHeader
                );
                this.setState({ delinquentData: delinquentResponse });
            }
            this.setState({
                toggleFilter: !toggleFilter,
                policyLandingLoader: false,
                allDelinquentPolicies: delinquentData || delinquentResponse
            });
            this.triggerGetResponse(currentView, selectedProducerCode);
        } else {
            if (currentView === 'Policies') {
                this.setState({
                    currentView: 'recentlyViewedTile',
                    policyLandingLoader: false
                });
            }
            const currentViewCondition = availableAdvancedFilterID.includes(currentView);
            if (currentViewCondition) {
                this.setState({
                    currentView: _.lowerFirst(currentView),
                    policyLandingLoader: false
                });
            }
            this.setState(
                {
                    toggleFilter: !toggleFilter,
                    policyData: policyResponse,
                    accountData: currentJobsResponse,
                    policyLandingLoader: false
                },
                () => this.triggerGetResponse(currentView, selectedProducerCode)
            );
        }
    };

    getResponseRecentlyIssuedPolicies = async () => {
        const { authHeader, producerCode } = this.props;
        const RecentlyViewedPolicies = PolicyService.getRecentlyViewedPolicies(authHeader).then(
            (policyResponse) => {
                this.setState({
                    policyData: policyResponse,
                    policyResponse: policyResponse
                });
                this.getResponse('recentlyViewedTile', producerCode);
            }
        );
        const RecentlyIssuedPolicies = PolicyService.getRecentlyIssuedPolicies(
            gatewayParamConfig.policiesCreatedInLastXDays,
            authHeader
        ).then((policyIssuedResponse) => {
            this.setState({ recentlyIssuedResponse: policyIssuedResponse });
        });
        const AccountJobsForCurrentUser = AccountService.getAccountJobsForCurrentUser(
            gatewayParamConfig.jobsCreatedInLastXDays,
            authHeader
        ).then((currentJobsResponse) => {
            this.setState({
                accountData: currentJobsResponse,
                currentJobsResponse: currentJobsResponse
            });
            this.getTileValue('all');
        });
        const PolicySummariesForCurrentUser = PolicyService.getPolicySummariesForCurrentUser(
            authHeader
        ).then((delinquentResponse) => {
            this.setState({
                delinquentData: delinquentResponse
            });
        });

        Promise.all([
            RecentlyViewedPolicies,
            RecentlyIssuedPolicies,
            AccountJobsForCurrentUser,
            PolicySummariesForCurrentUser
        ])
            .then(() => {
                this.setState({
                    policyLandingLoader: false
                });
            })
            .catch(() => {
                this.setState({
                    policyLandingLoader: false
                });
            });
    };

    getViewedInfo = () => {
        const { currentView } = this.state;
        const translator = this.context;
        const availableAdvanceFilterID = [
            'recentlyViewedTile',
            'delinquentTile',
            'Policies',
            'Quotes',
            'Renewal',
            'Change',
            'Cancellation'
        ];
        const checkAdvanceFilterID = availableAdvanceFilterID.some((val) => val === currentView);
        if (checkAdvanceFilterID) {
            return translator(tableHeadings[currentView]);
        }
        if (currentView === 'recentlyIssuedTile') {
            return translator(messages.recentlyIssuedPolicyForPastXDays, {
                value: gatewayParamConfig.policiesCreatedInLastXDays
            });
        }
        const checkAccountID = availableAccountID.some((val) => val === currentView);
        if (checkAccountID) {
            const getCurrentViewMessage = translator(tableHeadings[currentView]);
            return translator(messages.accountTableHeading, {
                currentView: getCurrentViewMessage,
                value: gatewayParamConfig.jobsCreatedInLastXDays
            });
        }
        return true;
    };

    render() {
        const {
            cancellationCount,
            changeCount,
            quotesCount,
            renewalCount,
            toggleFilter,
            tableData,
            toggleNoDataMsg,
            accountTableData,
            currentView,
            accountData,
            policyLandingLoader,
            policyNumberVisible,
            allDelinquentPolicies
        } = this.state;
        const overrides = {
            policyViewedInfo: {
                content: this.getViewedInfo()
            },
            policyLandingLoader: {
                loaded: !policyLandingLoader
            },
            policyContentContainer: {
                visible: !policyLandingLoader
            },
            tableContainer: {
                className: cx(styles.gwPoliciesContainerSection, {
                    [styles.gwPolicyInfoQuickTable]: toggleFilter,
                    [styles.gwPolicyInfoAdvancedTable]: !toggleFilter
                })
            },
            recentlyViewedPoliciesTableGrid: {
                data: tableData,
                visible: tableData.length > 0
            },
            noDataMessageContainer: {
                visible: toggleNoDataMsg
            },
            openPolicyNumberTitle: {
                visible: policyNumberVisible
            },
            policyTable: {
                data: accountTableData,
                visible: accountTableData.length > 0
            },
            policyPageTileContainer: {
                visible: toggleFilter
            },
            policyAdvancedFilter: {
                visible: !toggleFilter,
                policies: allDelinquentPolicies,
                accounts: accountData,
                clickedTile: currentView
            },
            quotes: {
                value: quotesCount
            },
            renewal: {
                value: renewalCount
            },
            change: {
                value: changeCount
            },
            cancellation: {
                value: cancellationCount
            },
            advanceFilterLink: {
                visible: toggleFilter
            },
            quickFilterLink: {
                visible: !toggleFilter
            },
            [currentView]: {
                active: true,
                value: this.getActiveTileValue(currentView)
            }
        };

        const resolvers = {
            resolveComponentMap: {
                advancedfilter: PolicyAdvanceFilter,
                selectproducercode: SelectProducerCode
            },
            resolveCallbackMap: {
                getCell: this.getCell,
                getProductImage: this.getProductImage,
                getLink: this.getLink,
                getAccountNumberLink: this.getAccountNumberLink,
                getCurrencyCell: this.getCurrencyCell,
                handleOnClick: this.handleOnClick,
                handleValueChange: this.handleValueChange,
                handleFilterClick: this.handleFilterClick,
                onFilterValueChange: this.onChangeAdvanceFilter,
                sortDate: DatatableUtil.sortDate,
                sortString: DatatableUtil.sortString,
                sortNumber: DatatableUtil.sortNumber,
                sortCurrency: DatatableUtil.sortCurrency
            },
            resolveClassNameMap: styles
        };

        const policyLandingPage = <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
        return policyLandingPage;
    }
}
export const PoliciesPage = Policies;
export default withRouter(withIntl(withAuthenticationContext(withProducerContext(Policies))));
