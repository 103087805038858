import React, { useContext } from 'react';
import _ from 'lodash';
import { WMICCard, WMICAccordionCard } from 'wmic-pe-components-platform-react';
import { TranslatorContext } from '@jutro/locale';
import { Position } from 'wmic-pe-portals-utils-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAccordionValidation } from 'wmic-pe-portals-wizard-components-ui';
import WMICHORiskUtil from "wmic-pe-capability-gateway-common-ho-react/utils/WMICHORiskUtil";
import messages from './WMICHOWatercraftCoverages.messages';
import metadata from './WMICHOWatercraftCoverages.metadata.json5';


function WMICHOWatercraftCoverages(props) {
    const {
        watercraftIndex,
        watercraftVM,
        watercraftCoverages,
        coverageColumnData,
        changeSubmissionAndSync,
        changeSubmission,
        syncCoverages,
        recalculate,
        resetQuote,
        onStaleQuoteBranchCode,
        underwritingIssues,
        quoteID,
        onValidate,
        readOnly,
        showErrors
    } = props;

    const translator = useContext(TranslatorContext);
    const { onValidateAccordion, isAccordionValid } = useAccordionValidation(onValidate);

    const formatWatercraftHeader = () => {
        const watercraftDescription = WMICHORiskUtil.getWaterCraftDescription(watercraftVM);
        const watercraftNumber = _.get(watercraftVM, 'riskNumber.value', "");
        return translator(messages.watercraftHeader, {riskID: watercraftNumber, riskDescription: watercraftDescription})
    };

    function generateClauseData() {
        return coverageColumnData.map(({ lob, code }) => {
            return {
                code: code,
                path: `${lob.path}.coverages.watercraftCoverages.children[${watercraftIndex}].coverages`,
                clauses: _.get(watercraftCoverages, 'coverages.value')
            };
        });
    }

    const getWatercraftCoverageTableData = () => {
        return [{
            data: _.get(watercraftCoverages, 'coverages.value'),
            tableContent: generateClauseData()
        }]
    }

    const overrideProps = {
        '@field': {
            labelPosition: Position.LEFT,
            parentNode: watercraftVM
        },
        watercraftCoveragesTable: {
            quoteID,
            readOnly: readOnly,
            columnData: coverageColumnData,
            tableData: getWatercraftCoverageTableData(),
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onChangeSubmission: changeSubmission,
            onSyncCoverages: syncCoverages,
            onRecalculate: recalculate,
            onResetQuote: resetQuote,
            onValidate: onValidateAccordion,
            onStaleQuoteBranchCode,
            underwritingIssues,
            filterUWIssuesInCustomOffering: false
        }
    }

    return (
        <WMICCard className="gw-mb-4">
            <WMICAccordionCard
                id={'watercraftCoveragesAccordionCard' + watercraftIndex}
                title={formatWatercraftHeader()}
                chevron
                chevronAlignment='left'
                headerVariant='h2'
                headerSize='h2'
                className='gw-mb-0'
                cardBodyClassName='gw-mx-2'
                isValid={isAccordionValid(`clauseTable-watercraftCoveragesAccordionCard${watercraftIndex}`)}
                showErrors={showErrors}
            >
                <ViewModelForm
                    model={watercraftVM}
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                />
            </WMICAccordionCard>
        </WMICCard>
    );
}

export default WMICHOWatercraftCoverages;
