import _ from 'lodash';
import { Augment } from '@xengage/gw-portals-viewmodel-utils-js';
import { LOADING } from "wmic-pe-portals-utils-js";

const METADATA_CLASS = 'wmic.edge.common.aspects.validation.dto.VisibilityRuleDTO';
const NULLABILITY_CLASS = 'wmic.edge.common.aspects.validation.dto.NullabilityRuleDTO';


/** Creates a new "Field is required" aspect. */
function create(expressionLanguage) {
    function getAllRules(compilationContext, node, nodeMetadata, ancestorChain) {
        const rules = Augment.collectRules(compilationContext, node, nodeMetadata, ancestorChain, METADATA_CLASS);
        rules.concat(Augment.collectRules(compilationContext, node, nodeMetadata, ancestorChain, NULLABILITY_CLASS));
        return rules;
    }

    function compileRule(compilationContext, rule) {
        const visibilityExpr = compilationContext.compile(rule.data.expression);

        return {
            'shouldApply': rule.shouldApply,
            'status': visibilityExpr
        };
    }

    function combineRules(rules) {
        return {
            'ocular': (v, pv, ctx) => {
                return rules.every((rule) => {
                    // console.log(`Ocular for ${v._accessorCode} should Apply ${rule.shouldApply()} and status ${rule.status(v, pv, ctx)} of ${rule.status(v, pv, ctx) === 'VISIBLE'}`)
                    return rule.shouldApply() && rule.status(v, pv, ctx) === 'VISIBLE';
                });
            },
        };
    }

    function getRadiantData(currentViewModelNode) {
        const parentNode = currentViewModelNode._parent;
        const lowercasePath = _.toLower(currentViewModelNode._accessorCode)
        const radiantData = parentNode && parentNode.value.metaDataMap ? parentNode.value.metaDataMap.find((item) => _.toLower(item.propName) === lowercasePath) : undefined;
        return radiantData && radiantData.hasOwnProperty('isVisible') ? radiantData : undefined;
    }

    function isRadiantVisible(currentViewModelNode) {
        const radiantData = getRadiantData(currentViewModelNode);

        /* if no radiant data found for this field, default to visible. */
        // eslint-disable-next-line no-bitwise
        return radiantData && radiantData.isVisible !== undefined ?
            (LOADING.ACTIVE | LOADING.ON) === parseInt(radiantData.isVisible, 2) : true;
    }

    function isRadiantNullWhenBlank(currentViewModelNode) {
        const radiantData = getRadiantData(currentViewModelNode);

        /* if no radiant data found for this field, default to true. */
        return radiantData && radiantData.isNullWhenBlank !== undefined ? radiantData.isNullWhenBlank : true;
    }

    return {
        getAspectProperties: (currentViewModelNode, currentMetadataNode, ancestorChain) => {
            const compilationContext = expressionLanguage.getCompilationContext(currentMetadataNode.xCenter);
            const rules = getAllRules(compilationContext, currentViewModelNode, currentMetadataNode, ancestorChain);
            const compiledRules = rules.map(_.partial(compileRule, compilationContext));
            const descriptor = combineRules(compiledRules);
            return {
                ocular: {
                    get: () => {
                        return isRadiantVisible(currentViewModelNode) && descriptor.ocular(
                            currentViewModelNode,
                            ancestorChain.parent,
                            currentViewModelNode.aspects.context);
                    }
                },
                detailOf: {
                    get: () => {
                        const ret = currentMetadataNode.elementMetadata.get(METADATA_CLASS);
                        if (ret.length > 0) {
                            return ret[0].detailOf;
                        }
                    }
                },
                ocularPresent: {
                    get: () => {
                        const ret = currentMetadataNode.elementMetadata.get(METADATA_CLASS);
                        let ocularPresent = false;
                        const radiantData = getRadiantData(currentViewModelNode);
                        // eslint-disable-next-line no-bitwise
                        const present = (radiantData && 'isVisible' in radiantData)
                        if (ret.length > 0 || present) {
                            ocularPresent = true;
                        }
                        return ocularPresent;
                    }
                },
                nullWhenBlank: {
                    get: () => {
                        const ret = currentMetadataNode.elementMetadata.get(NULLABILITY_CLASS);
                        // if Radiant either says it is, or is not supported, then check the annotation
                        if (isRadiantNullWhenBlank(currentViewModelNode)) {
                            return ret.length === 0 ? true : ret[0].expression.value;
                        }
                        return false;
                    }
                }
            };
        }
    };
}

export default {
    create
};
