import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { Loader } from '@jutro/components';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import WMICHOBusinessPropertyCovDetailView from './WMICHOBusinessPropertyCovDetailView/WMICHOBusinessPropertyCovDetailView';

import messages from './WMICHOBusinessPropertyCov.messages';
import metadata from './WMICHOBusinessPropertyCov.metadata.json5';

function WMICHOBusinessPropertyCov(props) {
    const {
        id,
        dwellingVM,
        dwellingCoverage,
        path,
        onUpdateJobAndCoverage,
        readOnly,
        isLoading,
        onValidationChange: parentOnValidate,
        showErrors: parentShowErrors
    } = props;

    const translator = useContext(TranslatorContext);
    const ViewModelService = useContext(ViewModelServiceContext);
    const { onValidate, isComponentValid } = useValidation(id);
    const [showErrors, updateShowErrors] = useState(false);
    const [editingIndex, updateEditingIndex] = useState(-1);
    const [selectedItem, updateSelectedItem] = useState(undefined);
    const [addingItem, setAddingItem] = useState(false);
    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);

    useEffect(()=> {
        if (parentOnValidate) {
            parentOnValidate(isComponentValid, id)
        }
        return () => parentOnValidate ? parentOnValidate(true, id) : undefined;
    }, [parentOnValidate, id, isComponentValid]);

    useEffect(() => {
        if (_.get(dwellingVM, 'businessPropCovItems.value', []).length === 0) {
            newItem();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateJobAndCoverage = () => {
        const items = _.get(dwellingVM, 'businessPropCovItems.value');
        const limitAmount = _.sum(items.map((b) => b.coverageLimit));

        const limitTerm = _.find(dwellingCoverage.terms, (t) => t.patternCode === 'HO_BusinessPersPropCovLmt');
        limitTerm.directValue = limitAmount;

        onUpdateJobAndCoverage(limitAmount, `${path}.terms[0].directValue`);
    }

    const addEditItem = (itemVM, index) => {
        updateSelectedItem(itemVM);

        setSelectedItemIndex(index);
        setAddingItem(true);
    }

    const newItem = () => {
        const newItemVM = ViewModelService.create(
            {}, 
            'pc', 
            'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.dwelling.BusinessPropCovItemDTO_WMIC',
            dwellingVM.aspects.context()
        );
        addEditItem(newItemVM, -1);
    }

    const editItem = (itemVM, index) => {
        addEditItem(itemVM, index);
    };

    const removeItem = (businessCov, index) => {
        dwellingVM.businessPropCovItems.value.splice(index, 1);

        updateJobAndCoverage();
    }

    const cancelItem = () => {
        updateSelectedItem(undefined);
        setSelectedItemIndex(-1);
        setAddingItem(false);
    };

    const saveItem = () => {
        if (selectedItemIndex >= 0) {
            dwellingVM.businessPropCovItems.value[selectedItemIndex] = selectedItem.value;
        } else {
            dwellingVM.businessPropCovItems.value.push(selectedItem.value);
        }
        updateJobAndCoverage()

        setSelectedItemIndex(-1);
        setAddingItem(false);
    };

    const overrideProps = {
        '@field': {
            parentNode: dwellingVM,
            readOnly
        },
        businessPropertyCovList: {
            VMList: _.get(dwellingVM, 'businessPropCovItems.children', []),
            VMData: [
                {
                    headerText: translator(messages.itemDescription),
                    path: 'description',
                },
                {
                    headerText: translator(messages.itemCoverageLimit),
                    path: 'coverageLimit'
                },
            ],
            onEditAction: editItem,
            onRemoveAction: removeItem,
            flatCards: true,
            clickable: false,
            selectedCardIndex: editingIndex,
            updateSelectedCardIndex: updateEditingIndex,
            isEditing: addingItem,
            isDeleteDisabled: _.get(dwellingVM, 'businessPropCovItems.value', []).length <= 1,
            readOnly,
        },
        addBusinessPropertyCovButton: {
            visible: !readOnly && !addingItem
        },
        businessPropertyForm: {
            visible: addingItem,
            itemVM: selectedItem,
            saveItem,
            cancelItem,
            onValidate,
            showErrors: showErrors || parentShowErrors
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            newItem
        },
        resolveComponentMap: {
            WMICHOBusinessPropertyCovDetailView
        }
    };

    return (
        <Loader loaded={!isLoading}>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={dwellingVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors || parentShowErrors}
            />
        </Loader>
    );
}

export default WMICHOBusinessPropertyCov;

