import { setComponentMapOverrides } from '@jutro/uiconfig';
import 'gw-capability-policychange-common-react';
import WMICPUPPolicyChangePolicyDetailsPage from './pages/PolicyDetails/WMICPUPPolicyChangePolicyDetailsPage';
import WMICPUPPolicyChangeUnderlyingPoliciesPage from './pages/UnderlyingPolicies/WMICPUPPolicyChangeUnderlyingPoliciesPage';
import WMICPUPPolicyChangeHouseholdMemberPage from './pages/HouseholdMember/WMICPUPPolicyChangeHouseholdMemberPage';
import './WMICPEPUPPolicyChangeWizard.messages';

setComponentMapOverrides(
    {
        WMICPUPPolicyChangePolicyDetailsPage: { component: 'WMICPUPPolicyChangePolicyDetailsPage' },
        WMICPUPPolicyChangeUnderlyingPoliciesPage: { component: 'WMICPUPPolicyChangeUnderlyingPoliciesPage' },
        WMICPUPPolicyChangeHouseholdMemberPage: { component: 'WMICPUPPolicyChangeHouseholdMemberPage' },
    },
    {
        WMICPUPPolicyChangePolicyDetailsPage,
        WMICPUPPolicyChangeUnderlyingPoliciesPage,
        WMICPUPPolicyChangeHouseholdMemberPage,
    }
);
// eslint-disable-next-line import/prefer-default-export
export { default as WMICPEPUPPolicyChangeWizard } from './WMICPEPUPPolicyChangeWizard';
export { default as WMICPUPPolicyChangePolicyDetailsPage } from './pages/PolicyDetails/WMICPUPPolicyChangePolicyDetailsPage';
export { default as WMICPUPPolicyChangeUnderlyingPoliciesPage } from './pages/UnderlyingPolicies/WMICPUPPolicyChangeUnderlyingPoliciesPage';
export { default as WMICPUPPolicyChangeHouseholdMemberPage } from './pages/HouseholdMember/WMICPUPPolicyChangeHouseholdMemberPage';
