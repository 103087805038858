import _ from 'lodash';
import { CONSTANTS, WMICRPCUtil, WMICDateTimeService } from "wmic-pe-portals-utils-js";
import moment from 'moment';

function getAssignedCode() {
    const suiteMetadata = window.wmic.viewmodel.metadata;

    return suiteMetadata.get('pc').types.getTypelist('PDAssignment_WMIC').getCode('Assigned').code;
}

function getYearsLicensedWMICTypelist() {
    const suiteMetadata = window.wmic.viewmodel.metadata;

    return suiteMetadata.get('pc').types.getTypelist('YearsLicensed_WMIC')
}

function getReciprocalLicenses() {
    const suiteMetadata = window.wmic.viewmodel.metadata;

    return suiteMetadata.get('pc').types.getTypelist('Country').getFilter('ReciprocalLicenses');
}

function getClass7EquivalentLCFilter() {
    const suiteMetadata = window.wmic.viewmodel.metadata;

    return suiteMetadata.get('pc').types.getTypelist('DriverClassType_WMIC').getFilter('Class7EquivalentLC');
}

export default class WMICDriverUtil {
    static ASSIGNED_CODE;

    static YEARSLICENSED_WMIC_TYPELIST;

    static RECIPROCAL_LICENSES;

    static CLASS_7_EQUIVALENT_LC_FILTER;

    static MAX_YEARS_LICENSED_FOR_GOOD_STUDENT = 9;

    static MAX_ELIGIBLE_AGE_FOR_GOOD_STUDENT = 25;

    static isDomesticDriverLicense = (driver) => driver && driver.licenseCountry_WMIC && driver.licenseCountry_WMIC.value && [CONSTANTS.COUNTRY.CA, CONSTANTS.COUNTRY.US].includes(driver.licenseCountry_WMIC.value.code);

    static presentDriverList = (jobVM) => _.get(jobVM, 'lobData.personalAuto.coverables.drivers.value', []).length > 0;

    static isAssignedDriver = (driver) => {
        if (!WMICDriverUtil.ASSIGNED_CODE && window.wmic) {
            WMICDriverUtil.ASSIGNED_CODE = getAssignedCode();
        }

        return _.get(driver, 'assignment_WMIC.value.code') === WMICDriverUtil.ASSIGNED_CODE;
    };

    static getYearsLicensedWMICTypelist = (jurisdiction, rateAsOfDate) => {
        if(!WMICDriverUtil.YEARSLICENSED_WMIC_TYPELIST && window.wmic) {
            WMICDriverUtil.YEARSLICENSED_WMIC_TYPELIST = getYearsLicensedWMICTypelist()
        }

        return WMICRPCUtil.getIsRPCEffective(jurisdiction, rateAsOfDate, '1224', '1380', '1421', '1543')
            ? WMICDriverUtil.YEARSLICENSED_WMIC_TYPELIST.getFilter('UpTo9Years').codes
            : WMICDriverUtil.YEARSLICENSED_WMIC_TYPELIST.getFilter('UpTo6Years').codes;
    }

    static removeNewlyAddedMvrs = (driver) => {
        if (driver?.mvrReports_WMIC?.value?.length > 0) {
            for (let i = driver.mvrReports_WMIC.value.length - 1; i >= 0; i-=1) {
                if (driver.mvrReports_WMIC.value[i].isReadOnly !== true) {
                    driver.mvrReports_WMIC.value.splice(i, 1);
                }
            }
        }
    }

    static yearsLicensedLessThanThreeYears = (driver, policyEffectiveDate) => {
        let numOfDriverClassHistories = 0;

        if(driver.driverClassHistory_WMIC.value !== undefined){
            numOfDriverClassHistories = driver.driverClassHistory_WMIC.value.length;
        }

        for(let i = 0; i < numOfDriverClassHistories; i+=1) {
            if(WMICDriverUtil.yearsLicensed(driver, driver.driverClassHistory_WMIC.value[i], policyEffectiveDate) < 3) {
                return true;
            }
        }

        return false;
    };

    static yearsLicensed = (driver, driverClassHistory, policyEffectiveDate) => {
        const ratedDate = WMICDriverUtil.getRatedDate(driverClassHistory);

        return Math.floor(WMICDriverUtil.deriveMonthlyExperienceFromClassHistory(driver, driverClassHistory, policyEffectiveDate, ratedDate)/12);

    };

    static getRatedDate = (driverClassHistory) => {
        if(driverClassHistory.overrideDate === undefined){
            return driverClassHistory.dateLicensed;
        }

        return driverClassHistory.overrideDate;
    };

    static isDriverEligibleForGoodStudentDiscount = (baseData, driver) => {

        const baseState = _.get(baseData, 'baseState.value.code');

        switch (baseState) {
            case 'NS':
                return WMICRPCUtil.getIsRPCEffective(baseData.baseState.value.code, baseData.rateAsOfDate.value, '1784')
                    ? false
                    : WMICDriverUtil.getDriverYearsLicensedByRatedClass(baseState, driver, baseData.rateAsOfDate) < WMICDriverUtil.MAX_YEARS_LICENSED_FOR_GOOD_STUDENT;
            case 'NB':
                return !WMICRPCUtil.getIsRPCEffective(baseData.baseState.value.code, baseData.rateAsOfDate.value, '1788')
                        && WMICDriverUtil.getDriverYearsLicensedByRatedClass(baseState, driver, baseData.rateAsOfDate) < WMICDriverUtil.MAX_YEARS_LICENSED_FOR_GOOD_STUDENT;
            case 'ON':
                return  WMICRPCUtil.getIsRPCEffective(baseData.baseState.value.code, baseData.rateAsOfDate.value, '1912')
                    ? false
                    : driver.ageAsOfPolicyExpiryDate_WMIC.value < WMICDriverUtil.MAX_ELIGIBLE_AGE_FOR_GOOD_STUDENT;
            default:
                return false;
        }
    };


    static getDriverYearsLicensedByRatedClass = (baseState, driver, policyEffectiveDate) => {

        const driverClassHistory = _.get(driver, 'driverClassHistory_WMIC.value');

        if (!driverClassHistory || driverClassHistory.length === 0) {
            return 0;
        }

        return WMICDriverUtil.getYearsLicensedWMICByRateDriverClass(driver, policyEffectiveDate, baseState);
    };

    static getYearsLicensedWMICByRateDriverClass = (driver, policyEffectiveDate, baseState) => {
        const ratedDriverClass = WMICDriverUtil.getRateDriverClass(driver.driverClassHistory_WMIC.value, baseState);

        return ratedDriverClass === null ? 0 : WMICDriverUtil.yearsLicensed(driver, ratedDriverClass, policyEffectiveDate);
    };

    static getRateDriverClass = (driverClasses, baseState) => {
        switch (baseState) {
            case 'NB':
                return WMICDriverUtil.getDriverClassNB(driverClasses);
            case 'NS':
                return WMICDriverUtil.getDriverClassNS(driverClasses);
            default:
                return null;
        }
    };

    static getDriverClassNB = (driverClasses) => {

        let ratedDriverClass;

        if (driverClasses.some(driverClass => driverClass.driverClassType_WMIC === '7L2')) {
            ratedDriverClass = driverClasses.find(driverClass => driverClass.driverClassType_WMIC === '7L2');
        } else if (driverClasses.some(driverClass => driverClass.driverClassType_WMIC === '5')) {
            ratedDriverClass = driverClasses.find(driverClass => driverClass.driverClassType_WMIC === '5');
        } else {
            const validDriverClasses = ['1', '2', '3', '4'];

            ratedDriverClass = WMICDriverUtil.getTheEarliestValidDriverClassFromClassHistory(validDriverClasses, driverClasses);
        }

        return ratedDriverClass;
    };

    static getDriverClassNS = (driverClasses) => {

        let ratedDriverClass;
        const validGDLClasses = ['5R', '5N'];

        if (driverClasses.some(driverClass => validGDLClasses.includes(driverClass.driverClassType_WMIC))) {
            ratedDriverClass = WMICDriverUtil.getTheEarliestValidDriverClassFromClassHistory(validGDLClasses, driverClasses);
        } else if (driverClasses.some(driverClass => driverClass.driverClassType_WMIC === '5')) {
            ratedDriverClass = driverClasses.find(driverClass => driverClass.driverClassType_WMIC === '5');
        } else {
            const validDriverClasses = ['1', '2', '3', '4'];

            ratedDriverClass = WMICDriverUtil.getTheEarliestValidDriverClassFromClassHistory(validDriverClasses, driverClasses);
        }

        return ratedDriverClass;
    };

    static getTheEarliestValidDriverClassFromClassHistory = (validDriverClasses, driverClasses) => {

        const validDriverClassesInHistory = WMICDriverUtil.getValidDriverClassesFromClassHistory(validDriverClasses, driverClasses);

        if (validDriverClassesInHistory.length > 0) {
            WMICDriverUtil.sortDriverClassHistoriesByDateLicensedAsc(validDriverClassesInHistory);

            return validDriverClassesInHistory[0];
        }

        return null;
    };

    static getValidDriverClassesFromClassHistory = (validDriverClasses, driverClasses) => driverClasses.filter(driverClass => validDriverClasses.includes(driverClass.driverClassType_WMIC));

    static sortDriverClassHistoriesByDateLicensedAsc = (arrayToSort) => {
        arrayToSort.sort((a, b) =>
        WMICDateTimeService.localDate2Date(a.dateLicensed) - WMICDateTimeService.localDate2Date(b.dateLicensed)
        );
    };

    static resetGoodStudentDiscountIfNeeded = (driver, baseData) => {
        if (!WMICDriverUtil.isDriverEligibleForGoodStudentDiscount(baseData, driver)) {
            driver.isGoodStudent_WMIC.value = null;
        }
    };

    static deriveMonthlyExperienceFromClassHistory = (driver, driverClassHistory, policyEffectiveDate, startDate) => {
        if(!WMICDriverUtil.CLASS_7_EQUIVALENT_LC_FILTER && window.wmic) {
            WMICDriverUtil.CLASS_7_EQUIVALENT_LC_FILTER = getClass7EquivalentLCFilter()
        }

        let experience = 0;

        if(!WMICDriverUtil.CLASS_7_EQUIVALENT_LC_FILTER.allows(driverClassHistory.driverClassType_WMIC)){
            const policyDriver = driver;

            if(!WMICDriverUtil.driverHasNonDomesticLicense(policyDriver)){
                let endDate;

                if (policyEffectiveDate !== undefined && policyEffectiveDate.value){
                    endDate = policyEffectiveDate.value;
                }

                if(endDate !== undefined){
                    experience = WMICDriverUtil.fullMonthsSince(startDate, endDate);
                }
            }
        }

        return experience;
    };

    static driverHasNonDomesticLicense = (policyDriver) => {
        const licenseCountry = policyDriver.licenseCountry === undefined? undefined: policyDriver.licenseCountry;

        return WMICDriverUtil.getNonDomesticDriverLicenseTypeByCountry(licenseCountry) !== undefined;
    };

    static fullMonthsSince = (startDate, endDate) => {
        let result = 0;

        if(startDate !== undefined && endDate !== undefined){
            const startDateMoment = moment([startDate.year, startDate.month, startDate.day]);
            const endDateMoment = moment([endDate.year, endDate.month, endDate.day]);

            result = endDateMoment.diff(startDateMoment, 'months', true);
        }

        return result < 0 ? 0 : result;
    };

    static getNonDomesticDriverLicenseTypeByCountry = (licenseCountry) => {
        if(!WMICDriverUtil.RECIPROCAL_LICENSES && window.wmic) {
            WMICDriverUtil.RECIPROCAL_LICENSES = getReciprocalLicenses()
        }

        const domesticCountries = [CONSTANTS.COUNTRY.CA, CONSTANTS.COUNTRY.US];
        let resultLicenseType;

        if(licenseCountry !== undefined && licenseCountry.value !== undefined && licenseCountry.value.code !== undefined){
            if(WMICDriverUtil.RECIPROCAL_LICENSES.allows(licenseCountry.value)){
                resultLicenseType = CONSTANTS.LICENSE_TYPES.FOREIGN_LICENSE;
            } else if(!domesticCountries.includes(licenseCountry.value.code)){
                resultLicenseType = CONSTANTS.LICENSE_TYPES.INTERNATIONAL_DRIVER_PERMIT;
            }
        }

        return resultLicenseType;
    };

    static getDriversWithDuplicateLicense = (driversList) => {
        const groupedDriversBylicense = _.groupBy(driversList.filter((d) => d.licenseNumber), (driver) => driver.licenseNumber);

        return _.find(groupedDriversBylicense, lNumber => lNumber.length > 1);
    }

    static canContactBeAddedToDrivers = (drivers, contact) => {
        if (contact.subtype === CONSTANTS.Company || contact.subtype.value === CONSTANTS.Company) {
            return false;
        }

        return _.isEmpty(drivers) || drivers.every(driver => {
            const publicID = contact.contactPublicID
                ? (contact.contactPublicID.value ?? contact.contactPublicID)
                : contact.publicID;

            return driver.person ? driver.person.publicID !== publicID : driver.publicID !== publicID;
        });
    };

}
