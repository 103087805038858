import { defineMessages } from 'react-intl';

export default defineMessages({
    scheduledItemsTitle: {
        id: 'endorsement.common.view.Scheduled Items',
        defaultMessage: 'Scheduled Items'
    },
    scheduledItemsOptional: {
        id: 'quoteandbind.ho.views.policyChange.ho-scheduled-item.Scheduled Items are optional',
        defaultMessage: 'Scheduled Items are optional'
    },
    scheduledItemType: {
        id: "quoteandbind.ho.views.policyChange.ho-scheduled-item.Item Type",
        defaultMessage: "Type"
    },
    scheduledItemDescription: {
        id: "quoteandbind.ho.views.policyChange.ho-scheduled-item.Item Description",
        defaultMessage: "Description"
    },
    scheduledItemLimit: {
        id: "quoteandbind.ho.views.policyChange.ho-scheduled-item.Item Limit",
        defaultMessage: "Limit"
    },
    removeScheduledItemLong: {
        id: "quoteandbind.ho.views.policyChange.ho-scheduled-item.Remove Item Long",
        defaultMessage: "Are you sure you want to remove the item from the list?"
    },
    removeScheduledItemShort: {
        id: "quoteandbind.ho.views.policyChange.ho-scheduled-item.Remove Item Short",
        defaultMessage: "Remove Item"
    }
});
