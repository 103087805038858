import React, { useContext } from 'react';
import { ModalNext, ModalBody, ModalFooter, ModalHeader } from '@jutro/components';
import { WMICButton } from 'wmic-pe-components-platform-react';
import { Flex } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import { WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import messages from './WMICRevertLapseModal.messages';

function WMICRevertLapseModal(props) {
    const { isOpen, onResolve, setHasLapseStateChangedFlag, RenewalService, lapseData, reason, authHeader } = props;
    const translator = useContext(TranslatorContext);

    const handleCancel = () => {
        onResolve('Cancelled');
    }

    const handleSubmit = () => {
        RenewalService.deletePreRenewalDirection(lapseData.policyNumber, lapseData.termNumber, reason, authHeader)
            .then(() => {
                onResolve('Submitted');
                setHasLapseStateChangedFlag((prev) => !prev);
            })
            .catch((e) => {
                if(typeof e === 'object') {
                    WMICErrorHandler.processAsModal(e).then(
                        _.noop
                    );
                }
            })
    }

    return (
        <ModalNext isOpen={isOpen}>
            <Flex direction="column" id="modalRoot">
                <ModalHeader status="info" title={translator(messages.revertLapseModalTitle)} />
                <ModalBody>
                    <div>
                        {translator(messages.revertLapseModalBody)}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <WMICButton
                        id="lapse-modal-cancel"
                        onClick={handleCancel}
                        type="primary-outlined"
                    >
                        {translator(messages.cancel)}
                    </WMICButton>
                    <WMICButton
                        id="lapse-modal-confirm"
                        onClick={handleSubmit}
                        type="primary"
                    >
                        {translator(messages.revertLapse)}
                    </WMICButton>
                </ModalFooter>
            </Flex>
        </ModalNext>
    );
}

WMICRevertLapseModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired
};

export default WMICRevertLapseModal;
