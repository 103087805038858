import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { withTracking } from '@xengage/gw-portals-tracking-react';
import styles from './AddressLookupTypeaheadComponent.module.scss';

class AddressLookupTypeaheadDropdown extends Component {
    /**
     * @static
     * @memberof AddressLookupTypeaheadDropdown
     * @prop {Array} items the items to render in the dropdown
     * @prop {number} maxResults the maximum number of items to display
     * @prop {function(item)} onItemSelected a function invoked when the user selects an entry
     * @prop {function()} onCancelSelection a function invoked when the userù
     *                                      cancels the selection (e.g. hits escape)
     * @prop {string} renderAs a path to a property in *item* that is used to
     *                                      render the result or a function that, given an item,
     *                                      returns a string
     * @prop {function(item): string} onRenderItem a function used to get the string to be used
     *                                      when rendering an item. If **renderAs** is provided
     *                                      this is **ignored**
     * @prop {function} setupRef a callback used to get the DOM reference of the dropdown
     */
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        maxResults: PropTypes.number,
        onItemSelected: PropTypes.func.isRequired,
        onCancelSelection: PropTypes.func.isRequired,
        renderAs: PropTypes.string,
        onRenderItem: PropTypes.func,
        setupRef: PropTypes.func,
        id: PropTypes.string.isRequired
    };

    static defaultProps = {
        maxResults: undefined,
        renderAs: undefined,
        onRenderItem: undefined,
        setupRef: undefined
    };

    state = {
        activeItem: undefined
    };

    chooseActiveItem = () => {
        const { onItemSelected, items } = this.props;
        const { activeItem } = this.state;
        if (!_.isNil(activeItem) && !_.isEmpty(items) && _.isFunction(onItemSelected)) {
            onItemSelected(items[activeItem]);
        }
    };

    cancelSelection = () => {
        const { onCancelSelection } = this.props;
        this.setState({
            activeItem: undefined
        });
        onCancelSelection();
    };

    selectNextItem = () => {
        const { items } = this.props;
        const { activeItem } = this.state;
        if (_.isNumber(activeItem) && activeItem < _.size(items) - 1) {
            this.setState({
                activeItem: activeItem + 1
            });
        }
    };

    selectPreviousItem = () => {
        const { activeItem } = this.state;
        if (_.isNumber(activeItem) && activeItem > 0) {
            this.setState({
                activeItem: activeItem - 1
            });
        }
    };

    selectItemOnHover = (index) => {
        this.setState({
            activeItem: index
        });
    };

    handleOnFocus = () => {
        // select first item on focus
        this.setState({
            activeItem: 0
        });
    };

    handleKeyDown = (event) => {
        if (event.key !== 'Tab') {
            // allow the user to select another
            event.preventDefault();
        }

        switch (event.key) {
            case 'ArrowUp':
                this.selectPreviousItem();
                break;
            case 'ArrowDown':
                this.selectNextItem();
                break;
            case 'Enter':
            case 'Tab':
                this.chooseActiveItem();
                this.props.onBlur();
                break;
            case 'Escape':
                this.cancelSelection();
                break;
            default: // do nothing
        }
    };

    setupDropdownRef = (ref) => {
        const { setupRef } = this.props;
        if (!_.isNil(setupRef) && _.isFunction(setupRef)) {
            setupRef(ref);
        }
    };

    renderDropdownElement = (item, index) => {
        const { renderAs, onItemSelected, onRenderItem } = this.props;
        const { activeItem } = this.state;
        let renderableItem;
        if (!_.isNil(renderAs)) {
            renderableItem = _.get(item, renderAs);
        } else if (!_.isNil(onRenderItem)) {
            renderableItem = onRenderItem(item);
        } else {
            renderableItem = item;
        }

        const isSelected = index === activeItem;

        const entryClassName = classNames(styles.gwTypeaheadMatch, {
            [styles.gwTypeaheadMatchActive]: isSelected
        });
        return (
            <li
                role="option"
                key={index}
                aria-selected={isSelected}
                onClick={() => onItemSelected(item)}
                onMouseEnter={() => this.selectItemOnHover(index)}
                className={entryClassName}
                onKeyDown={this.handleKeyDown}
            >
                {renderableItem}
            </li>
        );
    };

    render() {
        const { items, maxResults, id } = this.props;

        if (_.isEmpty(items)) {
            return null;
        }

        const listClassName = classNames('gw-dropdown-menu', styles.gwTypeaheadResults);
        const itemsToRender = _(items)
            .take(maxResults || Infinity)
            .value()
            .map(this.renderDropdownElement);

        return !_.isEmpty(itemsToRender) && (
            <div className="gw-open">
                <ul
                    id={`${id}_typeaheadDropdownList`}
                    role="listbox"
                    ref={this.setupDropdownRef}
                    onFocus={this.handleOnFocus}
                    tabIndex={0}
                    className={listClassName}
                    onKeyDown={this.handleKeyDown}
                >
                    {itemsToRender}
                </ul>
            </div>
        );
    }
}

export default withTracking(AddressLookupTypeaheadDropdown);
