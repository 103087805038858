import React, { useEffect, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
    ViewModelServiceContext,
    ViewModelForm,
    useDataRefresh
} from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'

import { TranslatorContext } from '@jutro/locale';

import { ACTION_TYPES } from 'wmic-pe-portals-utils-js';

import WMICHOPetsTableEdit from './WMICHOPetsTableEdit/WMICHOPetsTableEdit';
import metadata from './WMICHOPetsTable.metadata.json5';
import messages from './WMICHOPetsTable.messages';

const WMICHOPetsTable = ({ id, petListVM, updateParentRiskView, onValidate, readOnly, visible }) => {
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { refreshData } = useDataRefresh();

    const [petAction, setPetAction] = useState(ACTION_TYPES.NONE);
    const [currentPetEdit, setCurrentPetEdit] = useState({});
    const [petBackup, setPetBackup] = useState({});

    const [editingIndex, updateEditingIndex] = useState(-1);
    const [showErrors, setShowErrors] = useState(false);

    const {
        isComponentValid,
        onValidate: setComponentValidation
    } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    const onAddPet = useCallback(() => {
        const dwellingAnimal = viewModelService.create(
            {},
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.dwelling.additionaldetails.DwellingAnimalDTO_WMIC'
        );
        setCurrentPetEdit(dwellingAnimal);
        setPetAction(ACTION_TYPES.ADD);
    }, [viewModelService]);

    const onSavePet = useCallback(() => {
        if(isComponentValid){
            if (petAction === ACTION_TYPES.EDIT) {
                petListVM.setElement(editingIndex, currentPetEdit.value);
            } else if (petAction === ACTION_TYPES.ADD){
                petListVM.pushElement(currentPetEdit);

            }
            setPetAction(ACTION_TYPES.NONE);
            setShowErrors(false);
        } else {
            setShowErrors(true);
        }
        updateParentRiskView();
    }, [currentPetEdit, editingIndex, petAction, isComponentValid, petListVM]);

    const onCancelPet = useCallback(() => {
        if(petAction === ACTION_TYPES.EDIT) {
            petListVM.setElement(editingIndex, petBackup);
        }
        setPetAction(ACTION_TYPES.NONE);
        setShowErrors(false);
    }, [petAction, petListVM, setPetAction, editingIndex, petBackup]);

    const onEditPet = useCallback((data, index) => {
        updateEditingIndex(index);
        const item = petListVM.getElement(index)
        setPetBackup(_.cloneDeep(item.value));
        setPetAction(ACTION_TYPES.EDIT);
        setCurrentPetEdit(item);
    }, [petListVM]);

    const onDeletePet = useCallback((data, index) => {
        petListVM.value.splice(index, 1);
        setPetAction(ACTION_TYPES.NONE);
        refreshData();
        updateParentRiskView();
    }, [petListVM, refreshData]);

    const getAnimalType = (data) => {
        const animalTypeCode = viewModelService.productMetadata.get('pc').types
            .getTypelist('AnimalType_HOE').getCode(data.animalType);
        return translator({id: animalTypeCode.name})
    }

    const overrideProps = {
        '@field': {
            parentNode: petListVM,
        },
        hoPetListContainer: {
            VMList: petListVM.value,
            VMData: [
                {
                    headerText: translator(messages.number),
                    path: 'animalCount_WMIC'
                },
                {
                    headerText: translator(messages.type),
                    getData: getAnimalType
                },
                {
                    headerText: translator(messages.breed),
                    path: 'animalBreed',
                },
                {
                    headerText: translator(messages.description),
                    path: 'describeOther',
                },
                {
                    headerText: translator(messages.biteHistory),
                    path: 'animalBiteHistory',
                },
            ],
            updateSelectedCardIndex: updateEditingIndex,
            onEditAction: onEditPet,
            onRemoveAction: onDeletePet,
            clickable: false,
            isDeleteDisabled: [ACTION_TYPES.EDIT, ACTION_TYPES.ADD].includes(petAction),
            readOnly
        },
        hoAddPetBtn: {
            disabled: petAction !== ACTION_TYPES.NONE,
            visible
        },
        hoPetsTableEdit: {
            petVM: currentPetEdit,
            onDataSave: onSavePet,
            onDataCancel: onCancelPet,
            showErrors: showErrors,
            visible: petAction !== ACTION_TYPES.NONE,
            onValidate: setComponentValidation
        },
        atLeastOnePetMsg: {
            visible: !_.get(petListVM, 'value.length')
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            onAddPet
        },
        resolveComponentMap: {
            WMICHOPetsTableEdit
        },
    };

    return (
        <ViewModelForm
            model={petListVM}
            onModelChange={() => refreshData()}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
};

WMICHOPetsTable.propTypes = {
    id: PropTypes.string.isRequired,
    petListVM: PropTypes.array.isRequired,
    updateParentRiskView: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired
};

WMICHOPetsTable.defaultProps = {
    data: [{}],
    disableRemoval: false,
};

export default WMICHOPetsTable;
