import { defineMessages } from 'react-intl';

export default defineMessages({
    policyStatus: {
        id: 'agent.views.policy-detail-summary.Policy Status',
        defaultMessage: 'Policy Status'
    },
    nonPaymentOfPremiumStrikes: {
        id: 'agent.views.policy-detail.Non-Payment of Premium Strikes',
        defaultMessage: 'Non-Payment of Premium Strikes'
    },
    policyDetailsEffectiveDate: {
        id: 'agent.views.policy-detail.Policy Details Effective Date',
        defaultMessage: 'Policy Details Effective Date'
    },
    pendingCancellationOn: {
        id: 'agent.views.policy-detail.Pending Cancellation on',
        defaultMessage: 'Pending Cancellation on'
    },
    policyCost: {
        id: 'agent.views.policy-detail.Policy Cost',
        defaultMessage: 'Policy Cost'
    },
    documents: {
        id: 'agent.views.policy-detail.Documents',
        defaultMessage: 'Documents'
    },
    documentsDistributedByBroker: {
        id: 'quoteandbind.views.payment-details.Documents are distributed by the Broker',
        defaultMessage: 'Documents are distributed by the Broker'
    },
    documentsAreGenerating: {
        id: 'agent.views.policy-detail.Documents are generating.',
        defaultMessage: 'Documents are generating.'
    },
    thisMayTakeAWhile: {
        id: 'agent.views.policy-detail.This may take a while.',
        defaultMessage: 'This may take a while.'
    },
    billingPdf: {
        id: 'agent.views.policy-detail.Billing.pdf',
        defaultMessage: 'Billing.pdf'
    },
    policyWordingPdf: {
        id: 'agent.views.policy-detail.Policy Wording.pdf',
        defaultMessage: 'Policy Wording.pdf'
    },
    policyDeclarationPdf: {
        id: 'agent.views.policy-detail.Policy Declaration.pdf',
        defaultMessage: 'Policy Declaration.pdf'
    },
    iDCardPdf: {
        id: 'agent.views.policy-detail.ID CARD.pdf',
        defaultMessage: 'ID CARD.pdf'
    },
    resendDocuments: {
        id: 'agent.views.policy-detail-summary.Resend Documents',
        defaultMessage: 'Resend Documents'
    },
    brokerInfo: {
        id: 'agent.views.account-detail.Broker Info',
        defaultMessage: 'Broker Info'
    },
    account: {
        id: 'agent.views.new-quote.Account',
        defaultMessage: 'Account'
    },
    brokerName: {
        id: 'agent.views.policy-detail-summary.Broker Name',
        defaultMessage: 'Broker Name'
    },
    wawanesaBranch: {
        id: 'agent.views.policy-detail-summary.Wawanesa Branch',
        defaultMessage: 'Wawanesa Branch'
    },
    wawanesaRegion: {
        id: 'agent.views.policy-detail-summary.Wawanesa Region',
        defaultMessage: 'Wawanesa Region'
    },
    enrolledInEpoi: {
        id: 'agent.views.policy-detail-summary.Enrolled In Electronic Proof of Insurance',
        defaultMessage: 'Enrolled in EPOI'
    },
    setToLapse: {
        id: "wmic.pe-portal.pages.WMICPolicySummary.Set to Lapse",
        defaultMessage: "{policyDisplayStatus} - Set to Lapse"
    },
    renewalUnderReview: {
        id: "wmic.pe-portal.pages.WMICPolicySummary.Renewal Under Review",
        defaultMessage: "{policyDisplayStatus} - Renewal Under Review"
    },
    sendCustomerPaperPinkSlip: {
        "id": "agent.views.policy-detail.Send Customer Paper Pink Slip",
        "defaultMessage": "Send Customer Paper Pink Slip"
    },
    sendCustomerPaperPinkSlipUnavailable: {
        "id": "agent.views.policy-detail-summary.Currently Unavailable",
        "defaultMessage": "Currently Unavailable"
    },
    send: {
        "id": "agent.views.policy-detail.Send",
        "defaultMessage": "Send"
    },
    sendFRCDocument: {
        "id": "agent.views.policy-detail-summary.Documents.Send FRC Document",
        "defaultMessage": "Send FRC Document"
    },
    sendFRCDocumentSuccessful: {
        "id": "agent.views.policy-detail-summary.Documents.FRC Sent",
        "defaultMessage": "Selected document has been sent. Your customer will receive a copy in the mail shortly."
    },
    sendFRCDocumentFailed: {
        "id": "agent.views.policy-detail.summary.FRCDocumentError",
        "defaultMessage": "Could not Send FRC Document"
    },
    outstandingPremiumOwingTitle: {
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyWizard.This policy has outstanding premiums owing',
        defaultMessage: 'This policy has outstanding premiums owing.'
    },
    pendingPolicyCancellationTitle: {
        id: 'agent.views.policy-detail.Pending Policy Cancellation',
        defaultMessage: 'Pending Policy Cancellation'
    },
    pendingPolicyCancellationBody: {
        id: 'agent.views.policy-detail.The policy will be canceled by an underwriter. Policy changes cannot be completed.',
        defaultMessage: 'The policy will be canceled by an underwriter. Policy changes cannot be completed.'
    },
    policyCancelledStatusMessageHeading: {
        id: "agent.views.policy-detail.Cancelled Policy",
        defaultMessage: "Cancelled Policy"
    },
    policyCancelledStatusMessageBody: {
        id: "agent.views.policy-detail.Policy Change cannot be completed on cancelled policies",
        defaultMessage: "Policy Change cannot be completed on cancelled policies."
    },
    pendingTransactionStatusMessageHeading: {
        id: "agent.views.policy-detail.Pending Policy Transaction",
        defaultMessage: "Pending Policy Transaction"
    },
    pendingTransactionStatusMessageBody: {
        id: "agent.views.policy-detail.There is a pending policy transaction in Transactions Tab. Please complete or withdraw the pending policy transaction prior to creating a new request",
        defaultMessage: "There is a pending policy transaction in Transactions Tab. Please complete or withdraw the pending policy transaction prior to creating a new request."
    },
    renewalInProgressDays: {
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyWizard.Renewal In Progress Days Until Issue',
        defaultMessage: '&lt;span&gt;Renewal In-Progress - &lt;i&gt;{numberOfDays} Days Until Issued&lt;/i&gt;&lt;/span&gt;'
    },
    renewalInProgressOneDay: {
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyWizard.Renewal In Progress One Day Until Issue',
        defaultMessage: '&lt;span&gt;Renewal In-Progress - &lt;i&gt;1 Day Until Issued&lt;/i&gt;&lt;/span&gt;'
    },
    renewalEditingAvailableMessage: {
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyWizard.Editing renewal available',
        defaultMessage: '&lt;span&gt;Editing is now available and can be accessed through the &lt;b&gt;Transactions&lt;/b&gt; tab&lt;/span&gt;'
    },
    renewalPendingReviewMessage: {
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyWizard.Pending underwriting review renewal',
        defaultMessage: '&lt;span&gt;Editing will be available once underwriting completes their review.&lt;/span&gt;'
    }
});
