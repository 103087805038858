import { defineMessages } from 'react-intl';

export default defineMessages({
    name: {
        id: 'quoteandbind.gl.views.genearlliability.policyview.name',
        defaultMessage: 'Name'
    },
    numberOfDaysNotice: {
        id: 'quoteandbind.gl.views.genearlliability.policyview.number of days notice',
        defaultMessage: 'Number of Days Notice'
    },
});
