import React from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './WMICPolicyPupIncidentsDetailView.metadata.json5';
import messages from './WMICPolicyPupIncidentsDetailView.messages.js';

const WMICPolicyPupIncidentsDetailView = ({ value: incidentVM }) => {

    const overrideProps = {
        '@field': {
            parentNode: incidentVM,
            readOnly: true
        },
    }

    return <ViewModelForm
            uiProps={metadata.pageContent}
            model={incidentVM}
            overrideProps={overrideProps}
        />
}

WMICPolicyPupIncidentsDetailView.propTypes = {
    value: PropTypes.object.isRequired
}

export default WMICPolicyPupIncidentsDetailView;