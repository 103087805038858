import messages from './Models.messages';

export default class VehicleModel {
    constructor({
        year,
        make,
        model,
        vin,
        primaryUse,
        annualMileage,
        annualMileageUnder12000Toggle,
        fixedId,
        vehicleType,
        camperAttached,
        shell,
        vehicleNumber,
        displayName,
        primaryDriver,
        occasionalDrivers,
        primaryDriverName,
        occasionalDriversNames
    }) {
        this.year = year;
        this.make = make;
        this.model = model;
        this.vin = vin;
        this.primaryUse = primaryUse;
        this.annualMileage = annualMileage;
        this.annualMileageUnder12000Toggle = annualMileageUnder12000Toggle;
        this.fixedId = fixedId;
        this.vehicleType = vehicleType;
        this.camperAttached = camperAttached;
        this.shell = shell;
        this.vehicleNumber = vehicleNumber;
        this.displayName = displayName;
        this.primaryDriver = primaryDriver;
        this.occasionalDrivers = occasionalDrivers;
        this.primaryDriverName = primaryDriverName;
        this.occasionalDriversNames = occasionalDriversNames;
    }

    toString() {
        if (!this.valid()) {
            throw new Error(messages.yearMakeModelVinRequired);
        }

        return `Year: ${this.year}
Make: ${this.make}
Model: ${this.model}
VIN: ${this.vin}`;
    }

    toFullString() {
        if (!this.valid()) {
            throw new Error(messages.yearMakeModelVinRequired);
        }

        return `Vehicle Year: ${this.year}
Vehicle Make: ${this.make}
Vehicle Model: ${this.model}
VIN Number: ${this.vin}`;
    }

    toStringWithAddParams() {
        if (!this.valid()) {
            throw new Error(messages.yearMakeModelVinRequired);
        }

        return `---
Vehicle Year: ${this.year}
Vehicle Make: ${this.make}
Vehicle Model: ${this.model}
VIN Number: ${this.vin}
Primary Use: ${this.primaryUse}
Annual Mileage: ${this.annualMileage}
Primary Driver: ${this.primaryDriverName}
Occasional Drivers: ${this.occasionalDriversNames}
`;
    }

    toHTML() {
        return `<span>
<b>Vehicle:</b> ${this.year} ${this.make} ${this.model}<br/>
<b>VIN Number:</b> ${this.vin}<br/>
</span>`;
    }

    valid() {
        const required = [this.year, this.make, this.model, this.vin];
        return required.every((v) => v);
    }
}
