import _ from 'lodash';

const HOSubmissionLobDataExtension = function (submission) {
    const lobData = _.get(submission, 'lobData.homeowners');

    if (lobData && _.isObject(lobData)) {
        this.preQualQuestionSets = lobData.preQualQuestionSets;
        this.coverables = lobData.coverables;
        this.offerings = lobData.offerings;
    } else {
        this.preQualQuestionSets = [];
        this.coverables = lobData.coverables || [];
        this.offerings = lobData.offerings || [];
    }
};

export default HOSubmissionLobDataExtension;
