import { defineMessages } from 'react-intl';

export default defineMessages({
    waterMitigation: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOWaterUpdates.WaterMitigation",
        defaultMessage: "Water Mitigation"
    },
    addMitigationType: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOWaterUpdates.Mitigation Type",
        defaultMessage: "Add Mitigation Type"
    }
    
})