import { defineMessages } from 'react-intl';

export default defineMessages({
    wizard: {
        id: 'quoteandbind.cp.wizard.step.Property',
        defaultMessage: 'Property'
    },
    title: {
        id: 'quoteandbind.cp.views.property.title',
        defaultMessage: 'Property'
    }
});
