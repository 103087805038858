import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import _ from "lodash";
import { TranslatorContext } from '@jutro/locale';
import { LINE_OF_BUSINESS } from 'wmic-pe-portals-utils-js';
import metadata from './ReadOnlyCoverageTable.metadata.json5';
import messages from './ReadOnlyCoverageTable.messages';
import WMICCovTermUtil from '../util/WMICCovTermUtil';
import WMICCoverageUtil from '../util/WMICCoverageUtil';
import styles from "./ReadOnlyCoverageTable.module.scss";

function ReadOnlyCoverageTable(props) {
    const { id, coverageList, coverageData, coverageHeaderText, isPolicyChangeOrRenewal, jobVM, flat, coverageComparisons } = props;
    const translator = useContext(TranslatorContext);
    const lob = _.get(jobVM, 'lob.value.code');
    const isHO = lob === LINE_OF_BUSINESS.HOME_OWNER;
    const isPA = lob === LINE_OF_BUSINESS.PERSONAL_AUTO;
    const isGL = lob === LINE_OF_BUSINESS.GENERAL_LIABILITY;

    const coverageDataList = () => {

        if (!_.isEmpty(coverageData)) {
            return coverageData
        }
        const covList = [];

        coverageList.forEach((coverage) => {
            if(coverage.isLabel) {
                covList.push({
                    name: coverage.name,
                    deductible: undefined,
                    limit: coverage.label,
                    premium: undefined,
                    coverageUniqueID: coverage.id
                });
            } else {
                const needsTotalRow = WMICCovTermUtil.hasAdjustmentValue(coverage);

                covList.push({
                    name: coverage.name,
                    deductible: WMICCovTermUtil.getDeductibleLimit(coverage),
                    limit: WMICCovTermUtil.getTermLimit(coverage, translator),
                    premium: WMICCoverageUtil.getPremiumAmount(coverage),
                    coverageUniqueID: coverage.coverageUniqueID
                });

                coverage.terms.filter((covTerm) => WMICCovTermUtil.isUsableCovTerm(coverage, covTerm) && covTerm.visible)
                .forEach((covTerm) => {
                    covList.push({
                        name: covTerm.name,
                        deductible: '',
                        limit: covTerm.chosenTermValue,
                            premium: '',
                            isTerm: true

                        });
                    });

                    if (needsTotalRow) {
                    covList.push({
                        name: translator(messages.totalInsuredLimitLabel),
                        deductible: '',
                        limit: WMICCovTermUtil.getTotalAdjustedLimit(coverage),
                        premium: '',
                        isTerm: true
                    });
                }
            }
        });

        return covList;
    }

    const getCoverageHeaderText = useCallback(() => {
        return coverageHeaderText || _.get(coverageList[0], 'category_DisplayName_WMIC')

    }, [coverageList, coverageHeaderText]);

    const formatNameCell = (items, index, { path: property }) => {
        const isTermCell = _.get(items, 'isTerm', false);
        return  isTermCell?
            <div className={styles.indentCell}>{_.get(items, property)}</div>
            : _.get(items, property);
    };

    const getCoveragePremium = useCallback((items) => {
        const changedCov = _.find(coverageComparisons, {coveragePatternCode: items.coverageUniqueID});

        const premium = changedCov ? WMICCoverageUtil.formatPremiumAmount(changedCov.annualPremium?.amount) : items.premium;
        return premium;
    }, [coverageComparisons]);

    const getCoverageProRatedDifference = useCallback((items) => {
        const changedCov = _.find(coverageComparisons, {coveragePatternCode: items.coverageUniqueID});

        return changedCov ?  WMICCoverageUtil.formatPremiumAmount(changedCov.proRatedDifference?.amount) : null
    }, [coverageComparisons]);

    const overrideProps = {
        coverageTableCard: {
            flat,
            className: flat ? "flatCoverageTableCard" : "coverageTableCard"
        },
        coverageTerms: {
            VMList: coverageDataList(),
            VMData: [
                {
                    headerText: getCoverageHeaderText(),
                    path: "name",
                    columnProportion: 4,
                    renderCell: formatNameCell
                },
                {
                    headerText: translator(messages.deductibleColumnHeading),
                    path: "deductible",
                },
                {
                    headerText: translator(messages.limitColumnHeading),
                    columnProportion: isGL ? 1.5 : 1,
                    path: "limit"
                },
                {
                    headerText: translator(isPolicyChangeOrRenewal && (isHO || isPA) ? messages.annualPremiumColumnHeading : messages.premiumColumnHeading),
                    path: "premium",
                    renderCell: getCoveragePremium,
                    visible: !isGL
                },
                {
                    headerText: translator(messages.proratedDifference),
                    path: "proRatedDifference",
                    renderCell: getCoverageProRatedDifference,
                    visible: isPolicyChangeOrRenewal && (isHO || isPA)
                }
            ],
            readOnly: true
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={coverageDataList}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

ReadOnlyCoverageTable.propTypes = {
    coverageList: PropTypes.shape({}).isRequired
};

ReadOnlyCoverageTable.defaultProps = {
    isPolicyChangeOrRenewal : false
}

export default ReadOnlyCoverageTable;
