import { SHOW_ALL_PAGES } from '@jutro/datatable';
import { AccountService } from 'wmic-pe-capability-gateway-policy';
import WMICTabsCommonUtil from '../components/WMICTabsCommonUtil';
import constants from '../../constants';
import messages from '../WMICLanding.messages';

const getSubmissionNumberLink = (rowData, index, metaData, translator) =>
    metaData && WMICTabsCommonUtil.getSubmissionNumberLink(rowData, index, metaData.id, translator);

const getFormattedDateCell = (rowData) => WMICTabsCommonUtil.getFormattedDateCell(rowData.created);

const displayColumns = (translator) =>
    [
        {

            id: "created",
            path: "created",
            textAlign: "left",
            renderCell: getFormattedDateCell,
            header: {
                id: "submission.agent.views.policy-landing.Created",
                defaultMessage: "meta.Created"
            }
        },
        {
            id: "insured",
            textAlign: "left",
            columnProportion: 3,
            renderCell: WMICTabsCommonUtil.getFormattedNameAddressCell,
            header: {
                id: "submission.agent.views.policy-landing.Insured",
                defaultMessage: "meta.Insured"
            }
        },
        {
            id: "jobNumber",
            renderCell: (rowData, index, columnId) => getSubmissionNumberLink(rowData, index, columnId, translator),
            textAlign: "left",
            header: {
                id: 'submission.agent.views.policy-landing.Submission Number',
                defaultMessage: 'meta.Submission'
            },
        },
        {
            id: "status",
            path: "status",
            textAlign: "left",
            header: {
                id: 'submission.agent.views.policy-landing.Status',
                defaultMessage: 'meta.Status'
            }
        },
        {
            id: "broker",
            path: "broker",
            textAlign: "left",
            header: {
                id: "submission.agent.views.policy-landing.Broker Number",
                defaultMessage: "meta.Broker"
            }
        }
    ];

const transformData = (data) => {
    const submissionDataToReturn = {
        created: data.createTime,
        jobNumber: data.jobNumber,
        insured: data.accountHolderName,
        policyAddress: data.policyAddress,
        status: data.policyDisplayStatus,
        broker: data.producerCodeOfService,
        policyLines: data.policyLines,
        productCode: data.product.productCode,
        rowClickDestination: constants.DESTINATION_QUOTES
    };
    return submissionDataToReturn;
};

const loadDataAsyncSubmission = (page, pageSize, queryParams, authHeader) => {
    const calculatedPageSize = pageSize === SHOW_ALL_PAGES ? constants.PAGE_SIZE : pageSize;
    return AccountService.getAccountJobsForCurrentUser(queryParams, constants.JOBS_CREATED_IN_LAST_DAYS,
        page, calculatedPageSize, authHeader).then((response) => {
        const simpleResponse = [];
        const {
            jobs
        } = response;
        if (jobs) {
            jobs.forEach((submission) => {
                simpleResponse.push(transformData(submission));
            });
        }

        return {
            rows: simpleResponse,
            numberOfRows: response.maxNumberOfResults,
        }
    });
}

export default {
    tabHeading: messages.openSubmissionsTab,
    displayColumns,
    loadDataAsyncSubmission
}
