import CONSTANTS from './StringConstants';

const parseErrorMessage = error => {
    if (error?.baseError && error?.baseError?.includes(CONSTANTS.ERROR_MESSAGE)) {
        const errorSplit = error.baseError.split(CONSTANTS.ERROR_MESSAGE);
        return errorSplit[1] ?? errorSplit[0];
    }
    return error;
};

export default parseErrorMessage;
