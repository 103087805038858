import { defineMessages } from 'react-intl';

export default defineMessages({
    riskNumber: {
        id: "policyView.ho.views.construction.RiskTable.Risk Number",
        defaultMessage: "Risk #"
    },
    riskPrimary: {
        id: "policyView.ho.views.construction.RiskTable.Primary",
        defaultMessage: "Primary"
    },
    riskDescription: {
        id: "policyView.ho.views.construction.RiskTable.Description",
        defaultMessage: "Description"
    },
    riskType: {
        id: "policyView.ho.views.construction.RiskTable.Risk Type",
        defaultMessage: "Risk Type"
    },
    details: {
        id: "wmic-pe-cabability-policy-common-react.WMICPolicyHoConstructionPage.Details",
        defaultMessage: "Details"
    },
    roofElectricalPlumbingUpdates: {
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyHoConstructionPage.Roof Electrical Plumbing Updates',
        defaultMessage: 'Roof/Electrical/Plumbing Updates'
    },
    waterUpdates: {
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyHoConstructionPage.Water Updates',
        defaultMessage: 'Water Updates'
    },
    heatingUpdates: {
        id: "wmic-pe-capability-policy-common-react.WMICPolicyHoConstructionPage.Heating Updates",
        defaultMessage: "Heating Updates"  
    }
});