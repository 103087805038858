import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import WMICPolicyHistoryDetailView from './WMICPolicyHistoryDetailView/WMICPolicyHistoryDetailView';

import metadata from './WMICPolicyPADriverPolicyHistoryComponent.metadata.json5';
import messages from './WMICPolicyPADriverPolicyHistoryComponent.messages.js';

function WMICPolicyPADriverPolicyHistoryComponent(props) {
    const { driverVM } = props;

    const translator = useContext(TranslatorContext);

    const claimsHistoryLetterOfExperienceHeader = useMemo(() => {
        const autoPlusLabelCondition = _.get(driverVM, `autoPlusLabelCondition_WMIC.aspects.ocular`)
        return translator(autoPlusLabelCondition ? messages.claimHistoryReportHeading : messages.autoPlusHeading);
    }, [driverVM, translator])

    const claimsHistoryLetterOfExperienceDateLabel = useMemo(() => {
        const autoPlusLabelCondition = _.get(driverVM, `autoPlusLabelCondition_WMIC.aspects.ocular`)
        return translator(autoPlusLabelCondition ? messages.claimHistoryReportDate : messages.autoPlusDate);
    }, [driverVM, translator])

    const overrideProps = {
        '@field': {
            parentNode: driverVM,
            readOnly: true
        },
        policyListView: {
            VMData: [
                {
                    headerText: translator(messages.insurerName),
                    path: 'carrierName'
                },
                {
                    headerText: translator(messages.policy),
                    path: 'policyNumber'
                },
                {
                    headerText: translator(messages.riskType),
                    path: 'riskType'
                }
            ],
            clickable: true,
            readOnly: true,
            detailViewComponent: WMICPolicyHistoryDetailView,
            renderAddButton: false,
            startOpen: false
        },
        autoPlusHeading: {
            title: claimsHistoryLetterOfExperienceHeader
        },
        dateAutoPlusOrderedField: {
            label: claimsHistoryLetterOfExperienceDateLabel
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICPolicyHistoryDetailView
        }
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={driverVM}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

WMICPolicyPADriverPolicyHistoryComponent.propTypes = {
    driverVM: PropTypes.shape({}).isRequired
};

export default WMICPolicyPADriverPolicyHistoryComponent;
