import React, { useContext, useEffect } from 'react'
import _ from 'lodash'

import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'

import { TranslatorContext } from '@jutro/locale';

import {CONSTANTS, PUP_LOB_CODES} from 'wmic-pe-portals-utils-js'
import WMICPUPLocationExposures from '../WMICPUPLocationExposures/WMICPUPLocationExposures';
import WMICPUPVehicleExposures from '../WMICPUPVehicleExposures/WMICPUPVehicleExposures';
import WMICPUPWatercraftExposures from '../WMICPUPWatercraftExposures/WMICPUPWatercraftExposures';

import metadata from './WMICPUPUnderlyingPoliciesComponent.metadata.json5';
import messages from './WMICPUPUnderlyingPoliciesComponent.messages.js'

function WMICPUPUnderlyingPoliciesComponent(props) {
    const {
        id,
        underlyingPolicyVM,
        updateUnderlyingPolicy,
        jobVM,
        saveLiabilityCoverage,
        cancelUnderlyingPolicy,
        isReadOnlyUser,
        isExistingPolicy,
        onValidate,
        showErrors,
        copyHomeownerToWatercraft,
        copyCoverageValues,
        watercraftInsureList,
        carrierList,
        autoAddedWatercraft,
    } = props;

    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const translator = useContext(TranslatorContext);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => onValidate(true, id);
    }, [isComponentValid, onValidate, id]);

    const getUnderlyingPoliciesTitle = () => {
        const policyType = _.get(underlyingPolicyVM, 'policyType.value.code')
        switch (policyType) {
            case PUP_LOB_CODES.PA_CODE:
                return translator(messages.wmicUnderlyingPoliciesAutomobilePolicy);
            case PUP_LOB_CODES.HO_CODE:
                return translator(messages.wmicUnderlyingPoliciesHomeownersPolicy);
            case PUP_LOB_CODES.PWL_CODE:
                return translator(messages.wmicUnderlyingPoliciesWatercraftPolicy);
            default:
                return null;
        }
    }

    const isExposureVisible = (policyType) => policyType === underlyingPolicyVM.policyType.value.code

    const getPolicyNumberOrPlate = () => 
        _.get(underlyingPolicyVM, 'policyType.value.code') === PUP_LOB_CODES.PA_CODE 
            ? translator(messages.wmicUnderlyingPoliciesPolicyPlateNumber)
            : translator(messages.wmicUnderlyingPoliciesPolicyNumber)

    const watercraftCarrierNameChanged = (selectedInsurer) => {
        if(selectedInsurer.underlyingPolicy) {
            copyHomeownerToWatercraft(underlyingPolicyVM, selectedInsurer.underlyingPolicy);
            copyCoverageValues(selectedInsurer.underlyingPolicy, underlyingPolicyVM.value);
            _.set(underlyingPolicyVM, 'isSameAs.value', true)
        }
        else {
            _.set(underlyingPolicyVM, 'carrierName.value', selectedInsurer.carrier.name)
            _.set(underlyingPolicyVM, 'isSameAs.value', false)
        }
        updateUnderlyingPolicy(underlyingPolicyVM);

    };

    const carrierNameChanged = (value, path) => {
        _.set(underlyingPolicyVM, path, value)
        _.set(underlyingPolicyVM, 'carrierNameOther.value', undefined);
        if (underlyingPolicyVM.policyType.value.code === PUP_LOB_CODES.PWL_CODE) {
            _.set(underlyingPolicyVM, 'selectedInsurer', _.find(watercraftInsureList, (item) => item.code === value)
            )
            watercraftCarrierNameChanged(underlyingPolicyVM.selectedInsurer);
        }
        updateUnderlyingPolicy(underlyingPolicyVM)
    }

    const updateExposuresVM = (exposures) => {
        _.set(underlyingPolicyVM, 'exposures', exposures);
        updateUnderlyingPolicy(underlyingPolicyVM);
    };

    const exposureProps = {
        exposuresVM: underlyingPolicyVM.exposures,
        accountAddresses: jobVM.baseData.accountAddresses_WMIC.value,
        hasWatercraft: underlyingPolicyVM.hasWatercraft,
        isUnderEditing: underlyingPolicyVM.isUnderEditing,
        readOnly: isReadOnlyUser || (underlyingPolicyVM && underlyingPolicyVM.policyType.value.code !== PUP_LOB_CODES.PWL_CODE && isExistingPolicy) || !underlyingPolicyVM.isUnderEditing,
        jurisdiction: jobVM.baseData.baseState.value.code,
        underlyingPolicies: jobVM.lobData.personalUmbrella.coverables.underlyingPolicies,
        jobVM,
        updateExposuresVM,
        showErrorsParent: showErrors
    }

    const overrideProps = {
        '@field':{
            parentNode: underlyingPolicyVM,
            readOnly: !underlyingPolicyVM.isUnderEditing
        },
        underlyingPoliciesTitle: {
            title: getUnderlyingPoliciesTitle()
        },
        underlyingPoliciesRequiredMsgContainer: {
            visible: !!autoAddedWatercraft
        },
        underlyingPoliciesRequiredMsg: {
            content: translator(messages.wmicUnderlyingPoliciesHomeownersAutoAddedWatercraftPolicy, {policyNumber: underlyingPolicyVM.policyNumber.value})
        },
        vehicleExposures: {
            ...exposureProps,
            visible: isExposureVisible(PUP_LOB_CODES.PA_CODE) && !_.get(underlyingPolicyVM, 'isExcessPolicy.value', false),
        },
        watercraftExposures: {
            ...exposureProps,
            visible: isExposureVisible(PUP_LOB_CODES.PWL_CODE)
        },
        locationExposures: {
            ...exposureProps,
            visible: isExposureVisible(PUP_LOB_CODES.HO_CODE)
        },
        carrierName: {
            availableValues: _.get(underlyingPolicyVM, 'policyType.value.code') === PUP_LOB_CODES.PWL_CODE ? watercraftInsureList :carrierList,
            readOnly: _.get(underlyingPolicyVM, 'isSameAs.value', false) || !underlyingPolicyVM.isUnderEditing || isExistingPolicy
        },
        carrierNameOther: {
            readOnly: _.get(underlyingPolicyVM, 'isSameAs.value', false) || !underlyingPolicyVM.isUnderEditing || isExistingPolicy,
            visible: _.get(underlyingPolicyVM, 'carrierName.value') === CONSTANTS.REASON.OTHER
        },
        underlyingPoliciesContainerButtons: {
            onCancel: cancelUnderlyingPolicy,
            onSave: saveLiabilityCoverage,
            isEditing: underlyingPolicyVM.isUnderEditing
        },
        policyNumberOrPlate: {
            label: getPolicyNumberOrPlate(),
            readOnly: _.get(underlyingPolicyVM, 'isSameAs.value', false) || !underlyingPolicyVM.isUnderEditing || isExistingPolicy
        },
        effectiveDate: {
            readOnly: _.get(underlyingPolicyVM, 'isSameAs.value', false) || !underlyingPolicyVM.isUnderEditing || isExistingPolicy
        },
        expirationDate: {
            readOnly: _.get(underlyingPolicyVM, 'isSameAs.value', false) || !underlyingPolicyVM.isUnderEditing || isExistingPolicy
        },
        isExcessPolicyRequiredMsgContainer: {
            visible: _.get(underlyingPolicyVM, 'isExcessPolicy.value', false)
        },
        pupCoverages: {
            coverageVM: underlyingPolicyVM,
            updateCoverageData: updateUnderlyingPolicy,
            coveragePath: 'coverages',
            readOnly: !underlyingPolicyVM.isUnderEditing,
            onValidate: setComponentValidation,
            showErrors,
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICPUPLocationExposures,
            WMICPUPVehicleExposures,
            WMICPUPWatercraftExposures
        },
        resolveCallbackMap: {
            carrierNameChanged
        }
    }

    return <ViewModelForm
        uiProps={metadata.componentContent}
        model={underlyingPolicyVM}
        onModelChange={updateUnderlyingPolicy}
        overrideProps={overrideProps}
        onValidationChange={setComponentValidation}
        componentMap={resolvers.resolveComponentMap}
        callbackMap={resolvers.resolveCallbackMap}
        showErrors={showErrors}
    />
}

export default WMICPUPUnderlyingPoliciesComponent;
