import React, {
    useCallback,
    useEffect,
    useContext,
    useState
} from 'react';
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardContext } from '@xengage/gw-portals-wizard-react';
import { TranslatorContext } from '@jutro/locale';
import metadata from './WMICGLBusinessOperationDetailView.metadata.json5';
import messages from './WMICGLBusinessOperationDetailView.messages.js';
import { UISupportingInfoLookupService } from 'wmic-pe-capability-supportinginfo';

function WMICGLBusinessOperationDetailView(props) {
    const { value: exposureVM, isEditing, updateModel, updateWizardData, authHeader, showErrors, isBound = false, submissionVM } = props;
    const { onValidate } = useValidation('WMICGLBusinessOperationDetailView');
    const industryCodeValue = _.get(exposureVM, 'industryCode.value');
    const translator = useContext(TranslatorContext);
    useEffect(() => {
        const selectedIndustryCode = _.get(exposureVM, 'industryCode.value');
        if (!_.isEmpty(selectedIndustryCode)) {
            setIndustryCode(mapIndustryCode(selectedIndustryCode));
        }
        if (!isBound && !submissionVM.aspects.subtreeValid) {
            const businesOperation = _.get(exposureVM, 'businessOperation.value');
            const industryCode = _.get(exposureVM, 'industryCode.code.value');
            if (businesOperation === undefined && industryCode !== undefined) {
                const selectedIndustryCode = _.get(exposureVM, 'industryCode.value');
                if (!_.isEmpty(selectedIndustryCode)) {
                    setIndustryCode(mapIndustryCode(selectedIndustryCode));
                    updateWizardData(submissionVM);
                }
            }
        }
    })

    const setIndustryCode = (selectedIndustryCode) => {
        if (selectedIndustryCode) {
            _.set(exposureVM, 'industryCode.value', selectedIndustryCode.industryCode);
        } else {
            _.set(exposureVM, 'industryCode.value', {});
        }
        
        _.set(exposureVM, 'businessOperation.value', selectedIndustryCode);
    };

    const updateIndustryCode = useCallback((selectedIndustryCode) => {
        setIndustryCode(selectedIndustryCode);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const mapIndustryCode = (industryCodeDTO) => {
        const autoDeclined = industryCodeDTO.smallBusinessAutoDecline_WMIC;
        return {
            displayName: `${industryCodeDTO.code} - ${industryCodeDTO.classification}`,
            id: industryCodeDTO.publicId,
            code: industryCodeDTO.code,
            type: autoDeclined === true ? 'unsupported' : 'supported',
            industryCode: industryCodeDTO
        };
    };

    const searchIndustryCodes = useCallback(
        async (filter) => {
            let results = [];

            if (filter.length >= 3) {
                const jobNumber = _.get(submissionVM, 'quoteID.value');
                const industryCodes = await UISupportingInfoLookupService.retrieveIndustryCodes(jobNumber, filter, filter, true, authHeader);
                results = industryCodes.map(mapIndustryCode);
            }
            return results;
        },
        [authHeader, submissionVM]
    );

    const overrideProps = {
        '@field': {
            parentNode: exposureVM,
        },
        basis: {
            readOnly: !isEditing,
            showErrors: showErrors
        },
        WMICGLBusinessOperationDetailView: {
            onValidate,
            showErrors: showErrors
        },
        primaryBusinessOperation: {
            required: _.get(exposureVM, 'industryCode.aspects.required'),
            readOnly: !isEditing,
            availableValues: [
                mapIndustryCode(industryCodeValue)
            ],
            optionTypes: [
                {
                    className: "ww_industrycode_supported",
                    displayName: translator(messages.industryCodeSupported),
                    type: "supported",
                    icon: "gw-check"
                },
                {
                    className: "ww_industrycode_unsupported",
                    displayName: translator(messages.industryCodeUnsupported),
                    type: "unsupported",
                    icon: "gw-clear"
                },
            ]
        },

    }

    const resolvers = {
        resolveCallbackMap: {
            searchIndustryCodes,
            updateIndustryCode
        },
    };

    return <ViewModelForm
        callbackMap={resolvers.resolveCallbackMap}
        uiProps={metadata.componentContent}
        model={exposureVM}
        onModelChange={updateModel}
        overrideProps={overrideProps}

    />
}

export default WMICGLBusinessOperationDetailView;
