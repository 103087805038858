import _ from 'lodash';
import {HOConstants} from "wmic-pe-portals-utils-js";

// FUNCTIONS TO RENDER DATA LIST CELLS
export const renderDescriptionWithOther = (item, path, translator) => {
    if (item.value.hasOwnProperty(path)) {
        const descriptionNode = _.get(item, path);
        const selectedOptionCode = _.get(descriptionNode, 'value.code');
        if (selectedOptionCode) {
            const selectedOption = _.find(descriptionNode.aspects.availableValues, {'code': selectedOptionCode});
            if (selectedOption) {
                return selectedOptionCode === 'other' 
                    ? item.additionalDescription.value 
                    : translator({id: selectedOption.name, defaultMessage: selectedOption.value});
            }
        }
    }
    return '';
}

// KITCHEN & BATH
export const isKitchen = (kitchenBath) => kitchenBath.materialOrFeature.toLowerCase() === HOConstants.kitchensMaterialOrFeature;

export const isBath = (kitchenBath) => [HOConstants.bathroomsBasementMaterialOrFeature,
    HOConstants.bathroomsExcludingBasementMaterialOrFeature].includes(kitchenBath.materialOrFeature.toLowerCase());

export const getKitchenBathDisplayName = (kitchenBath, messages, translator) => {
    if (isKitchen(kitchenBath)) {
        return translator(messages.kitchenDisplayName)
    } 
    
    if (isBath(kitchenBath)) {
        return translator(messages.bathDisplayName)
    }
    return '';
}
