import { defineMessages } from 'react-intl';

export default defineMessages({
    locationType: {
        id: 'wmic.capabilities-react.gw-capability-producerengage-common-react.WMICHODwellingProtectionDetails.Location Type',
        defaultMessage: 'Location Type'
    },
    describeOther: {
        id: 'wmic.capabilities-react.gw-capability-producerengage-common-react.WMICHODwellingProtectionDetails.Describe Other',
        defaultMessage: 'Describe Other'
    },
    burglarAlarm: {
        id: 'wmic.capabilities-react.gw-capability-producerengage-common-react.WMICHODwellingProtectionDetails.Burglar Alarm',
        defaultMessage: 'Burglar Alarm'
    },
    fireAlarm: {
        id: 'wmic.capabilities-react.gw-capability-producerengage-common-react.WMICHODwellingProtectionDetails.Fire Alarm',
        defaultMessage: 'Fire Alarm'
    },
    smokeDetector: {
        id: 'wmic.capabilities-react.gw-capability-producerengage-common-react.WMICHODwellingProtectionDetails.Smoke Detector',
        defaultMessage: 'Smoke Detector'
    },
    smokeDetectorType: {
        id: 'wmic.capabilities-react.gw-capability-producerengage-common-react.WMICHODwellingProtectionDetails.Smoke Detector Type',
        defaultMessage: 'Smoke Detector Type'
    },
    numberOfDetectors: {
        id: 'wmic.capabilities-react.gw-capability-producerengage-common-react.WMICHODwellingProtectionDetails.Number Of Detectors',
        defaultMessage: 'Number Of Detectors'
    },
    alarmType: {
        id: 'wmic.capabilities-react.gw-capability-producerengage-common-react.WMICHODwellingProtectionDetails.Alarm Type',
        defaultMessage: 'Alarm Type'
    },
    alarmCertificate: {
        id: 'wmic.capabilities-react.gw-capability-producerengage-common-react.WMICHODwellingProtectionDetails.Alarm Certificate',
        defaultMessage: 'Has alarm certificate been received?'
    },
    alarmCertificateDate: {
        id: 'wmic.capabilities-react.gw-capability-producerengage-common-react.WMICHODwellingProtectionDetails.Alarm Installation Date',
        defaultMessage: 'Alarm Installation Date'
    },
    leakDetectionMonitorSystem: {
        id: 'wmic.capabilities-react.gw-capability-producerengage-common-react.WMICHODwellingProtectionDetails.Water Leak Detection Monitoring System',
        defaultMessage: 'Water Leak Detection Monitoring System'
    },
    companyName: {
        id: 'wmic.capabilities-react.gw-capability-producerengage-common-react.WMICHOWatercraftBoatEquipmentTrailerDetails.Company Name',
        defaultMessage: 'Company Name'
    },
    leakDetectionPlaceholdedr: {
        id: "wmic.capabilities-react.gw-capability-producerengage-common-react.WMICHODwellingProtectionDetails.None",
        defaultMessage: "None"
    }
});
