import { defineMessages } from 'react-intl';

export default defineMessages({
    riskNumber: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.dwelling-component.risk-number',
        defaultMessage: 'Risk #'
    },
    dwellingLocation: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.dwelling-component.dwelling-location',
        defaultMessage: 'Dwelling Location'
    },
    riskType: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.dwelling-component.risk-type',
        defaultMessage: 'Risk Type'
    },
    coverageType: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.dwelling-component.coverage-type',
        defaultMessage: 'Coverage Type'
    },
    watercraftDescription: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.dwelling-component.watercraft-description',
        defaultMessage: 'Watercraft Description'
    },
    basicPremiumSubTotal: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.dwelling-component.basic-premium-subtotal',
        defaultMessage: 'Basic Premium SubTotal'
    },
    additionalPremiumSubTotal: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.dwelling-component.additional-premium-subtotal',
        defaultMessage: 'Additional Premium SubTotal'
    },
    grc: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.dwelling-component.grc',
        defaultMessage: 'GRC'
    },
    singleInclusiveLimit: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.dwelling-component.single-inclusive-limit',
        defaultMessage: 'Single Inclusive Limit'
    },
    totalPremium: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.dwelling-component.total-premium',
        defaultMessage: 'Total Premium'
    },
    proratedPremium: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.dwelling-component.prorated-premium',
        defaultMessage: 'Prorated Premium'
    },
})
