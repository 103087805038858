import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import metadata from './WMICPolicyLossHistoryPayout.metadata.json5';
import messages from './WMICPolicyLossHistoryPayout.messages';

function WMICPolicyLossHistoryPayout(props){
    const {
        currentPriorLoss
    } = props;

    const translator = useContext(TranslatorContext);

    const getTypeDisplayName = data => data?.type?.value ?? "";

    const getStatusDisplayName = (data) => {
        const priorLossStatus = _.get(currentPriorLoss, 'priorLoss.status.value');

        if (data.status) {
            return data.status.value
        }

        if (priorLossStatus) {
            return priorLossStatus
        }

        return "";
    };

    const getClaimOpenReservesDisplayName = (data) => {
        const claimOpenReserves = _.get(data, 'claimOpenReserves.value', {currency: 'cad', amount: 0});

        return (
            <CurrencyField 
                value={claimOpenReserves}
                dataType="object"
                readOnly
                hideLabel
                showFractions
            />
        )
    }

    const getLossTotalPaidDisplayName = (data) => {
        const lossTotalPaid = _.get(data, 'lossTotalPaid.value', {currency: 'cad', amount: 0})
        
        return (
            <CurrencyField 
                value={lossTotalPaid}
                dataType="object"
                readOnly
                hideLabel
                showFractions
            />
        )
    }

    const overrideProps = {
        '@field': {
            parentNode: currentPriorLoss,
            readOnly: true
        },
        payoutDataList: {
            VMData: [
                {
                    headerText: translator(messages.causeOfLoss),
                    getData: getTypeDisplayName
                },
                {
                    headerText: translator(messages.payoutStatus),
                    getData: getStatusDisplayName
                },
                {
                    headerText: translator(messages.claimOpenReserves),
                    getData: getClaimOpenReservesDisplayName
                },
                {
                    headerText: translator(messages.lossTotalPaid),
                    getData: getLossTotalPaidDisplayName
                }
            ],
            clickable: false,
            readOnly: true,
        }
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentPriorLoss}
            overrideProps={overrideProps}
        />
    )
}

WMICPolicyLossHistoryPayout.propTypes = {
    currentPriorLoss: PropTypes.shape({}).isRequired
};

export default WMICPolicyLossHistoryPayout;