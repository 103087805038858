import { defineMessages } from 'react-intl';

export default defineMessages({
    accidentWaiverEndorsement: {
        id: 'wmic-pe-capability-policy-common-react.DriverEndorsementsCoverages.Accident Waiver Endorsement',
        defaultMessage: 'Accident Waiver Endorsement'
    },
    accidentWaiverEndorsementMotorcycle: {
        id: 'wmic-pe-capability-policy-common-react.DriverEndorsementsCoverages.Accident Waiver Endorsement Motorcycle',
        defaultMessage: 'Accident Waiver Endorsement - Motorcycle'
    },
    minorConvictionWaiverON: {
        id: 'wmic-pe-capability-policy-common-react.DriverEndorsementsCoverages.TrafficSafetyConvictionWaiver.Ontario',
        defaultMessage: 'Minor Conviction Waiver'
    },
    minorConvictionWaiverMaritimes: {
        id: 'wmic-pe-capability-policy-common-react.DriverEndorsementsCoverages.TrafficSafetyConvictionWaiver.Maritimes',
        defaultMessage: 'Minor Conviction Waiver Endorsement'
    },
    trafficSafetyConvictionWaiver: {
        id: 'wmic-pe-capability-policy-common-react.DriverEndorsementsCoverages.TrafficSafetyConvictionWaiver',
        defaultMessage: 'Traffic Safety Conviction Waiver'
    }
})