import { defineMessages } from 'react-intl';

export default defineMessages({
    wawanesaInsurePrimaryPolicy: {
        id: "wmic-pe-capability-gateway-common-react.WMICInsuranceHistoryPoliciesComponent.Does Wawanesa insure the primary residence?",
        defaultMessage: "Does Wawanesa insure the primary residence?"
    },
    otherLicensedDriverPrimary: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.Any people in the household or business who are licensed to drive but not listed on the policy",
        defaultMessage: "Are there any people in the household or business who are licensed to drive but not listed on the policy?"
    },
    otherLicensedDriverReason: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.Reason people are not listed on the policy?",
        defaultMessage: "Reason people are not listed on the policy?"
    },
    otherLicensedDriverDescribe: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.Describe other",
        defaultMessage: "Describe other"
    },
    firstPolicyDate: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.Date insurance first obtained",
        defaultMessage: "Since what date has the customer been continuously insured?"
    },
    refusedCancelledDeclinedPoliciesHeaderId: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.Refused to Renew, Cancelled or Declined Policies",
        defaultMessage: "Refused to Renew, Cancelled or Declined Policies"
    },
    hasDeclinedInsuranceHO: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.personal property declined insurance",
        defaultMessage: "Has any Insurance Company refused to renew, cancelled, or declined to provide insurance in the past 5 years"
    },
    hasDeclinedInsurancePA: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.personal auto declined insurance",
        defaultMessage: "Has any Insurance Company refused to renew, cancelled, or declined to provide insurance in the past 3 years?"
    },
    showOnePolicyRequiredMsg: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.At least one Policy is required",
        defaultMessage: "At least <b>one Policy</b> is required"
    },
    addPolicyButton: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.Add Policy",
        defaultMessage: "Add Policy"
    },
    date: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.Date",
        defaultMessage: "Date"
    },
    insuranceCompany: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.Insurance Company",
        defaultMessage: "Insurance Company"
    },
    typeOfDeclination: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.Type of Declination",
        defaultMessage: "Type of Declination"
    },
    reason: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.Reason",
        defaultMessage: "Reason"
    },
    describeReason: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.Describe Reason",
        defaultMessage: "Describe Reason"
    },
    insuranceHistoryRecordsHeaderId: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.Other Insurance Company Policies",
        defaultMessage: "Other Insurance Company Policies"
    },
    policyNumber: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.Policy Number",
        defaultMessage: "Policy Number"
    },
    effectiveDate: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.Effective Date",
        defaultMessage: "Effective Date"
    },
    expirationDate: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.Expiration Date",
        defaultMessage: "Expiration Date"
    },
    anyCauseForConcern: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.AnyCauseForConcern",
        defaultMessage: "Does the applicant have any knowledge or information of any fact, circumstance, or situation which could reasonably give rise to a claim which would fall within the scope of the proposed insurance?"
    },
    causeForConcern: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.CauseForConcern",
        defaultMessage: "Provide Details"
    },
    wawanesaPoliciesContainerHeaderId: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.Related Wawanesa Policies",
        defaultMessage: "Related Wawanesa Policies"
    },
    product: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.Product",
        defaultMessage: "Product"
    },
    riskType: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.Risk Type",
        defaultMessage: "Risk Type"
    },
    status: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.Status",
        defaultMessage: "Status"
    },
    addAnyWawanesaPoliciesText: {
        id: "wmic-pe-capability-gateway-common-react.templates.WMICInsuranceHistoryPoliciesComponent.Add any policies currently held",
        defaultMessage: "Add any policies currently held"
    },
    // riskPrimary: {
    //     id: "quoteandbind.ho.views.construction.RiskTable.Primary",
    //     defaultMessage: "Primary"
    // },
    // riskDescription: {
    //     id: "quoteandbind.ho.views.construction.RiskTable.Description",
    //     defaultMessage: "Description"
    // },
});
