import React from 'react';
import _ from 'lodash';
import {
    QuoteClauseTable,
    WMICHeading,
    ClausesComponentVM
} from 'wmic-pe-components-platform-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { Position } from 'wmic-pe-portals-utils-js';

import WMICIMCoverageUtil from 'wmic-pe-capability-gateway-quoteandbind-im-react/util/WMICIMCoverageUtil';
import metadata from './WMICImCoverages.metadata.json5';

function WMICImCoverages(props) {
    const {
        changeSubmissionAndSync,
        changeSubmission,
        syncCoverages,
        recalculate,
        resetQuote,
        onStaleQuoteBranchCode,
        underwritingIssues,
        quoteID,
        jobVM,
        onValidate,
        isBoundPolicy,
        readOnly,
    } = props;

    const lobDataPath = isBoundPolicy ? 'lobs' : 'lobData';
    const coveragePath = `${lobDataPath}.inlandMarine`;

    const lineCoverages = WMICIMCoverageUtil.getLineCoverages(
        _.get(jobVM, coveragePath)
    );

    const toolsCoverages = WMICIMCoverageUtil.getToolsCoverages(
        _.get(jobVM, coveragePath)
    );

    const equipmentCoverages = WMICIMCoverageUtil.getEquipmentCoverages(
        _.get(jobVM, coveragePath)
    );

    const generateClauseData = (coverageColumnData, categoryKey) => {
        switch (categoryKey) {
            case 'installation':
                return coverageColumnData.map(({ lob, code }) => {
                    return {
                        code: code,
                        path: `${lob.path}.coverages.lineCoverages`,
                        clauses: lineCoverages,
                    };
                });
            case 'machinery':
                return coverageColumnData.map(({ lob, code }) => {
                    return {
                        code: code,
                        path: `${lob.path}.coverages.equipmentCoverages`,
                        clauses: equipmentCoverages,
                    };
                });
            case 'tools':
                return coverageColumnData.map(({ lob, code }) => {
                    return {
                        code: code,
                        path: `${lob.path}.coverages.toolsCoverages`,
                        clauses: toolsCoverages,
                    };
                });
            default:
                return null;
        }
    };

    const getInlandMarineCoverages = () => {
        // only show these categories
        const categoryMap = ['installation', 'machinery', 'tools'];

        const inlandMarineCoverages = [
            ...lineCoverages,
            ...toolsCoverages,
            ...equipmentCoverages,
        ];

        const categorizedCoverages = WMICIMCoverageUtil.categorizeLineCoverages(inlandMarineCoverages);
        const coverageAccordions = categoryMap.map((categoryKey) => {
            const lineCoverage = _.get(categorizedCoverages, categoryKey);

            if (lineCoverage.coverages.length === 0) {
                return <></>;
            }

            if(!isBoundPolicy) {

                const coverageColumnData = WMICIMCoverageUtil.generateColumnData(jobVM);

                const coverageTableData = [{
                    data: lineCoverage.coverages,
                    tableContent: generateClauseData(coverageColumnData, categoryKey),
                }];

                return (
                    <>
                        <WMICHeading
                            variant="h2"
                            underlined
                            bold
                            size="h2"
                            title={lineCoverage.categoryName}
                        />
                        <QuoteClauseTable
                            columnData={coverageColumnData}
                            tableData={coverageTableData}
                            quoteID={quoteID}
                            readOnly={readOnly}
                            onChangeSubmissionAndSync={changeSubmissionAndSync}
                            onChangeSubmission={changeSubmission}
                            onSyncCoverages={syncCoverages}
                            onRecalculate={recalculate}
                            onResetQuote={resetQuote}
                            onStaleQuoteBranchCode={onStaleQuoteBranchCode}
                            underwritingIssues={underwritingIssues}
                            filterUWIssuesInCustomOffering={false}
                            onValidate={onValidate}
                            showTooltip={false}
                        />
                    </>
                );
            }

            return (
                <>
                    <WMICHeading
                        variant="h2"
                        underlined
                        bold
                        size="h2"
                        title={lineCoverage.categoryName}
                    />
                    <ClausesComponentVM
                        showAmount={false}
                        isEditable
                        readOnly
                        path={`${categoryKey}.coverages`}
                        value={lineCoverage.coverages}
                    />
                </>
            );
        });

        return coverageAccordions;
    };

    const overrideProps = {
        '@field': {
            labelPosition: Position.LEFT,
            parentNode: jobVM,
        },
        inlandMarineCoveragesAccordions: {
            content: getInlandMarineCoverages(),
        },
    };

    return (
        <div id="inlandMarineContainer">
            <ViewModelForm
                model={jobVM}
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
            />
        </div>
    );
}

export default WMICImCoverages;
