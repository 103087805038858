import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { WMICJsonRPCService } from 'wmic-pe-portals-transport-js';


export default class PolicyUtilService {

    static getRiskValuesByProduct(productType, isPCPolicy, additionalHeaders = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('policyutil'),
            'getRiskValuesByProduct', [productType, isPCPolicy], additionalHeaders);
    }

}
