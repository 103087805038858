import { defineMessages } from 'react-intl';

export default defineMessages({
    yes: {
        id: 'quoteandbind.util.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'quoteandbind.util.no',
        defaultMessage: 'No'
    },
    standard: {
        id: 'quoteandbind.util.standard',
        defaultMessage: 'Standard'
    },
    includedCoverages: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCoverageUtil.included-coverages',
        defaultMessage: 'Included Coverages'
    },
    optionalPropertyCoverages: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCoverageUtil.optional-property-coverages',
        defaultMessage: 'Optional Property Coverages'
    },
    optionalLiabilityCoverages: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCoverageUtil.optional-liability-coverages',
        defaultMessage: 'Optional Liability Coverages'
    },
    conditions: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCoverageUtil.conditions',
        defaultMessage: 'Conditions'
    },
    exclusions: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCoverageUtil.exclusions',
        defaultMessage: 'Exclusions'
    },
    scheduledItems: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCoverageUtil.scheduled-items',
        defaultMessage: 'Scheduled Items'
    }
});
