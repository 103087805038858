import { defineMessages } from 'react-intl';

export default defineMessages({
    accessoryDescription: {
        "id": "wmic-pe-capability-gateway-common-ho-react.WMICHOAccessoryInfoDetails.Description",
        "defaultMessage": "Description"
    },
    accessoryMonetaryValue: {
        "id": "wmic-pe-capability-gateway-common-ho-react.WMICHOAccessoryInfoDetails.Value",
        "defaultMessage": "Value"
    }
});
