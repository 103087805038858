import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { InputField, Link, Button } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import styles from './ProductZipCode.module.scss';

function translateMessages(translator, messagesToTranslate = []) {
    return messagesToTranslate.map((message) => translator(message));
}

class ProductZipCode extends Component {
    static propTypes = {
        id: PropTypes.string,
        wizardLink: PropTypes.string,
        className: PropTypes.string,
        productCode: PropTypes.string,
        onTogglePageState: PropTypes.func,
        onEnterProductFlow: PropTypes.func,
        startQuoteMessage: PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        }),
        zipCodePlaceholder: PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        }),
        retreiveQuoteMessage: PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        }),
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired
    };

    static defaultProps = {
        id: undefined,
        wizardLink: undefined,
        className: undefined,
        productCode: undefined,
        startQuoteMessage: undefined,
        zipCodePlaceholder: undefined,
        onTogglePageState: undefined,
        onEnterProductFlow: undefined,
        retreiveQuoteMessage: undefined
    };

    state = {
        submissionVM: {},
        showErrors: false
    };

    componentDidMount() {
        const { viewModelService, productCode } = this.props;
        const vm = viewModelService.create(
            {
                effectiveDate: new Date(),
                productCode
            },
            'pc',
            'edge.capabilities.policycommon.availability.dto.PostalCodeAvailabilityRequestDTO'
        );
        this.setState({ submissionVM: vm });
    }

    writeValue = (value, path) => {
        const { submissionVM } = this.state;
        _.set(submissionVM, path, value);
        this.setState({ submissionVM });
    };

    handleEnterFlow = () => {
        const { submissionVM } = this.state;
        const isValid = submissionVM.aspects.valid && submissionVM.aspects.subtreeValid;
        const { wizardLink, onEnterProductFlow } = this.props;

        this.setState({ showErrors: true });

        if (onEnterProductFlow && isValid) {
            onEnterProductFlow(submissionVM.value, wizardLink);
        }
    };

    renderStartQuoteButton = (translator) => {
        const { id, startQuoteMessage } = this.props;

        return (
            <Button
                id={`${id}_start_quote`}
                className={styles.startQuote}
                onClick={this.handleEnterFlow}
            >
                {translator(startQuoteMessage)}
            </Button>
        );
    };

    handleSubmit = (evt) => {
        evt.preventDefault();
        this.handleEnterFlow();
    };

    handleChangeInProductZipCode = () => {
        const { onTogglePageState } = this.props;
        if (_.isFunction(onTogglePageState)) {
            onTogglePageState();
        }
    };

    render() {
        const { submissionVM, showErrors } = this.state;
        const {
            id, className, zipCodePlaceholder, retreiveQuoteMessage
        } = this.props;

        const validationMessages = _.get(submissionVM, 'postalCode.aspects.validationMessages', []);
        const formStyles = classNames(className, styles.productInputContainer);

        return (
            <TranslatorContext.Consumer>
                {(translator) => (
                    <form onSubmit={this.handleSubmit} className={formStyles}>
                        <InputField
                            id={`${id}_input`}
                            controlClassName={styles.postalCode}
                            className={styles.postalCodeContainer}
                            path="postalCode"
                            value={_.get(submissionVM, 'postalCode.value')}
                            onValueChange={this.writeValue}
                            placeholder={translator(zipCodePlaceholder)}
                            layout="full-width"
                            showErrors={showErrors}
                            validationMessages={translateMessages(translator, validationMessages)}
                            hideLabel
                            required
                        />
                        {this.renderStartQuoteButton(translator)}
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link
                            id={`${id}_retrieve_quote`}
                            onClick={this.handleChangeInProductZipCode}
                            className={styles.retrieveQuote}
                        >
                            {translator(retreiveQuoteMessage)}
                        </Link>
                    </form>
                )}
            </TranslatorContext.Consumer>
        );
    }
}

export default withViewModelService(ProductZipCode);
