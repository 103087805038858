import React, { useContext, useMemo } from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import { WMICDateTimeService, JobType, LINE_OF_BUSINESS } from 'wmic-pe-portals-utils-js';
import { TranslatorContext } from '@jutro/locale';
import { WMICCoverages } from 'wmic-pe-capability-quoteandbind-common-react';
import metadata from './WMICQuoteSummary.metadata.json5';
import messages from './WMICQuoteSummary.messages';


function WMICQuoteSummary(props) {
    const { jobVM } = props;
    const translator = useContext(TranslatorContext);

    const periodStart = WMICDateTimeService.localDate2Format(_.get(jobVM, 'baseData.periodStartDate.value'), 'LL');
    const periodEnd = WMICDateTimeService.localDate2Format(_.get(jobVM, 'baseData.periodEndDate.value'), 'LL');
    const isPolicyChangeOrRenewal =
    _.get(jobVM, 'baseData.jobType.value.code') === JobType.POLICY_CHANGE ||
    _.get(jobVM, 'baseData.jobType.value.code') === JobType.RENEWAL;
    const isGL = _.get(jobVM, 'lob.value.code') === LINE_OF_BUSINESS.GENERAL_LIABILITY;

    const quoteStatus = useMemo(() => {
        const displayQuoteStatus = _.get(jobVM, 'displayStatus_WMIC.value', '');

        return (displayQuoteStatus.toLowerCase() === 'bound') ?  translator(messages.issuedStatus) :  displayQuoteStatus
    }, [jobVM, translator]);

    const brokerText = useMemo(() => {
        const brokerName = _.get(jobVM, 'baseData.brokerName_WMIC.value');
        const brokerCode = _.get(jobVM, 'baseData.producerCode_WMIC.value');

        return `${brokerName} (${brokerCode})`;
    }, [jobVM]);

    const getAdditionalNamedInsured = () => {
        const anis = _.get(jobVM, 'baseData.additionalNamedInsureds_WMIC.value');

        if (_.isEmpty(anis)) {
            return undefined;
        }

        let aniDisplayName = '';

        anis.forEach((ani, index) => {
            aniDisplayName += index === anis.length - 1 ? ani.displayName : `${ani.displayName}, `;
        });

        return aniDisplayName;
    }

    const getEPOIEnrolledValue = () => {
        const epoiEnrolled = _.get(jobVM, 'lobData.personalAuto.epoiEnrolled_WMIC.value');

        if (_.isUndefined(epoiEnrolled)) {
            return undefined;
        }

        return epoiEnrolled
            ? translator(messages.epoiEnrolledYes)
            : translator(messages.epoiEnrolledNo);
    }

    const overrideProps =  {
        '@field': {
            parentNode: jobVM,
            readOnly: true
        },
        policyNumber: {
            visible: isPolicyChangeOrRenewal,
            value: _.get(jobVM, 'policyNumber.value')
        },
        policyTerm: {
            value: `${periodStart} - ${periodEnd}`
        },
        policyStatus: {
            value: quoteStatus
        },
        policyChangeEffectiveDate: {
            visible: isPolicyChangeOrRenewal,
        },
        broker: {
            value: brokerText
        },
        ani: {
            visible: isPolicyChangeOrRenewal,
            value: getAdditionalNamedInsured()
        },
        epoiEnrolled: {
            visible: !isGL,
            value: getEPOIEnrolledValue()
        },
        coveragesSection: {
            lobData: _.get(jobVM, 'lobData'),
            jobVM,
            isPolicyChangeOrRenewal
        }
    }

    const resolvers = {
        resolveComponentMap: {
            WMICCoverages
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={jobVM}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

WMICQuoteSummary.propTypes = {
};

WMICQuoteSummary.defaultProps = {
};

export default WMICQuoteSummary;
