/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import _ from 'lodash';


const GLSubmissionLobDataExtension = function (submissionVM) {
    const lobData = _.get(submissionVM, 'lobData.generalLiability');
    // TODO - investigate whether this is actually needed..if so, cleanup the if/else
    if (lobData && _.isObject(lobData)) {

        this.exposures = lobData.exposures;
        this.additionalInsureds = lobData.additionalInsureds;
        this.offerings = lobData.offerings;
        this.policyDetails = lobData.policyDetails;
    } else {
        this.coverables = lobData.coverables || {};
        this.additionalInsureds = lobData.additionalInsureds || [];
        this.exposures = lobData.exposures || {};
        this.policyDetails = lobData.policyDetails || {};
    }
};

export default GLSubmissionLobDataExtension;
