import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { Submission } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import { WMICJsonRPCService } from 'wmic-pe-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return WMICJsonRPCService.send(getProxiedServiceUrl('gatewayquote/quote'), method, data, additionalHeaders).then(
        (response) => {
            return new Submission(response);
        }
    );
}

/**
 * This is the server responsible for dealing with the Quote and Buy backend operations
 *
 * @export
 * @class LoadSaveService
 */
export default class LoadSaveService {

    static updateDraftSubmissionWithLOBData(data, additionalHeaders = {}) {
        return processSubmission('updateDraftSubmissionWithLOBData', [data], additionalHeaders);
    }

    /**
     * Updates, saves and performs a quote on an existing submission.
     * Generates quotes for all available product offerings.
     *
     * @param {Object} data tge submission that will be saved
     * @returns {Promise} the promise from the backend call
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @memberof LoadSaveService
     */
    static saveAndQuoteSubmission(data, additionalHeaders = {}) {
        return processSubmission('saveAndQuote', [data], additionalHeaders);
    }

    /**
     * Retrieves a submission.
     *
     * @param {Object} data the retrieval payload (QuoteRetrievalDTO)
     * @returns {Promise} the promise from the backend call
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @memberof LoadSaveService
     */
    static retrieveSubmission(data, additionalHeaders = {}) {
        return processSubmission('retrieve', [data], additionalHeaders);
    }

    /**
     * Updates a quoted submission with LOB Data.
     *
     * @param {Object} data the submission to be saved as draft
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static updateQuotedSubmissionWithLOBData(data, additionalHeaders = {}) {
        return processSubmission('updateQuotedSubmissionWithLOBData', [data], additionalHeaders);
    }

    /**
     * Fills submission with default data
     *
     * @param {Object} data the submission to be filled with default data
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    // eslint-disable-next-line camelcase
    static createDefaultAnswers_WMIC(data, additionalHeaders = {}) {
        return processSubmission('createDefaultAnswers', [data], additionalHeaders);
    }

    /**
     * Retrieves a submission.
     *
     * @param {string} quoteID quoteID of submission that needs to be found
     * @param {string} sessionUUID current session id
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static retrievePaymentPlans(quoteID, sessionUUID, additionalHeaders = {}) {
        return processSubmission('retrievePaymentPlans', [quoteID, sessionUUID], additionalHeaders);
    }

    /**
     * Binds the submission.
     *
     * @param {Object} data the submission to be bound
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static bindSubmission(data, additionalHeaders = {}) {
        return processSubmission('bind', [data], additionalHeaders);
    }

    /**
     * Updates an existing draft submission.
     *
     * @param {Object} data the submission to be saved as draft
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static updateDraftSubmission(data, additionalHeaders = {}) {
        return processSubmission('updateDraftSubmission', [data], additionalHeaders);
    }

    /**
     * Updates a quoted submission.
     *
     * @param {Object} data the submission to be saved as draft
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static updateQuotedSubmission(data, additionalHeaders = {}) {
        return processSubmission('updateQuotedSubmission', [data], additionalHeaders);
    }

    /**
     * Updates coverages.
     *
     * @param {Object} quoteID quoteID
     * @param {Object} sessionUUID sessionUUID
     * @param {Object} lobCoverages the coverages to be saved
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */

    static updateCoverages(quoteID, sessionUUID, lobCoverages, additionalHeaders = {}) {
        return processSubmission(
            'updateCoverages',
            [quoteID, sessionUUID, lobCoverages],
            additionalHeaders
        );
    }

    /**
     * Approve Underwriting Issue.
     *
     * @param {Object} quoteID quoteID
     * @param {Object} uwIssue uwIssue
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static approveUnderwritingIssue(quoteID, uwIssue, additionalHeaders = {}) {
        return processSubmission(
            'approveUnderwritingIssue',
            [quoteID, uwIssue],
            additionalHeaders
        );
    }

    /**
     * Validate submission.
     *
     * @param {Object} sessionUUID sessionUUID
     * @param {string} jobNumber jobNumber
     * @param {string} flowStepId flowStepId
     * @param {Object} validationLevel flowStepId
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static validate(sessionUUID, jobNumber, flowStepId, validationLevel, additionalHeaders = {}) {
        return processSubmission(
            'validate',
            [sessionUUID, jobNumber, flowStepId, validationLevel],
            additionalHeaders
        );
    }

    static saveWithNoValidationRuleCheck(submission, additionalHeaders = {}) {
        return processSubmission(
            'saveWithNoValidationRuleCheck_WMIC',
            [submission],
            additionalHeaders
        );
    }

    static getSkeletonStructure(data, additionalHeaders = {}) {
        return processSubmission('getSkeletonStructure', [data], additionalHeaders);
    }

    static postOnChange(submission, additionalHeaders = {}) {
        return processSubmission(
            'postOnChange',
            [submission],
            additionalHeaders
        )
    }

    static syncInitialHOOfferings(submission, additionalHeaders = {}) {
        return processSubmission(
            'syncInitialHOOfferings_WMIC',
            [submission],
            additionalHeaders
        );
    }

    static removeDriver(quoteDataDTO, driverId, additionalHeaders = {}) {
        return processSubmission(
            'removeDriver',
            [quoteDataDTO, driverId],
            additionalHeaders
        );
    }

    static saveAndValidate_WMIC(quoteDataDTO, additionalHeaders = {}) {
        return processSubmission(
            'saveAndValidate_WMIC',
            [quoteDataDTO],
            additionalHeaders
        )
    }

    static saveAndSyncInsurance_WMIC(quoteDataDTO, additionalHeaders = {}) {
        return processSubmission(
            'saveAndSyncInsurance_WMIC',
            [quoteDataDTO],
            additionalHeaders
        )
    }

    static saveAndSyncPACoverages_WMIC(quoteDataDTO, additionalHeaders = {}) {
        return processSubmission(
            'syncInitialPAOfferings_WMIC',
            [quoteDataDTO],
            additionalHeaders
        )
    }

    static prebindSubmission(quoteDataDTO, additionalHeaders = {}) {
        return processSubmission(
            'prebind',
            [quoteDataDTO],
            additionalHeaders
        )
    }

    static editQuotedSubmission(quoteDataDTO, additionalHeaders = {}) {
        return processSubmission(
            'editQuotedSubmission_WMIC',
            [quoteDataDTO],
            additionalHeaders
        )
    }

    static getPolicyNumberForSubmission(submissionNumber, additionalHeaders = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('gatewayquote/quote'),
            'getPolicyNumberForSubmission_Ext', [submissionNumber], additionalHeaders);
    }

    static getBindDataForSubmission(submissionNumber, additionalHeaders = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('gatewayquote/quote'),
            'getBindDataForSubmission_Ext', [submissionNumber], additionalHeaders);
    }

    static retrieveOtherInforceWawanesaPolicy(policyNumber, jobNumber, additionalHeaders = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('gatewayquote/quote'),
            'retrieveOtherInforceWawanesaPolicy', [policyNumber, jobNumber], additionalHeaders);
    }

    static retrievePaymentPreview(paymentPreviewRetrievalDTO, additionalHeaders = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('gatewayquote/quote'),
            'retrievePaymentPreview', [paymentPreviewRetrievalDTO], additionalHeaders);
    }

    static fetchInstitutionName(transitNumber, financialInstitutionNumber, additionalHeaders = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('gatewayquote/quote'),
            'fetchInstitutionName_WMIC', [transitNumber, financialInstitutionNumber], additionalHeaders);
    }

    static retrieveCreditCardPaymentService(submissionNumber, amountDTO, email, additionalHeaders = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('paymenthandler'),
            'getVirtualTerminalURL', [submissionNumber, amountDTO, email], additionalHeaders);
    }

    static getPolicyVirtualTerminalURL(policyNumber, termNumber, amountDTO, email, additionalHeaders = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('paymenthandler'),
            'getPolicyVirtualTerminalURL', [policyNumber, termNumber, amountDTO, email], additionalHeaders);
    }

    static orderFCSAForDrivers(quoteID, driverPublicIDs, additionalHeaders = {}) {
        return processSubmission(
            'orderFCSA',
            [quoteID, driverPublicIDs],
            additionalHeaders
        );
    }

    static validateSubmissionForIssue(quoteDataDTO, additionalHeaders = {}) {
        return processSubmission(
            'validateSubmissionForIssue_WMIC',
            [quoteDataDTO],
            additionalHeaders
        );
    }

    static saveAndRecalculateFlexForSubmission(
        quoteID,
        flexInfos,
        waiveBrokerFee,
        additionalHeaders = {}
    ) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('gatewayquote/quote'), 'saveAndRecalculateFlexForSubmission',
            [quoteID, flexInfos, waiveBrokerFee], additionalHeaders);
    }
}
