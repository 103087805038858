import { defineMessages } from 'react-intl';

export default defineMessages({
    thirdPartyWarning: {
        id: 'agent.directives.PolicyBillingSummaryDirective.policy-billing-summary.Third Party Warning',
        defaultMessage: 'You are now leaving this website. You will be entering a third party website, which is not owned or operated by The Wawanesa Mutual Insurance Company. Although we carefully select the web links we place on our websites, the content, products and information contained on third party websites are not owned or controlled by The Wawanesa Mutual Insurance Company.'
    },    
    enterThePaymentAmount: {
        id: 'agent.directives.PolicyBillingSummaryDirective.policy-billing-summary.Enter Payment Amount',
        defaultMessage: 'Enter the payment amount:'
    },    
    currencyRangeError: {
        id: 'agent.directives.PolicyBillingSummaryDirective.policy-billing-summary.CurrencyRange',
        defaultMessage: 'Value must be greater than 0.'
    },
});