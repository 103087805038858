import { defineMessages } from 'react-intl';

export default defineMessages({
    errorsFound: {
        id: 'agent.util.Errors.Found',
        defaultMessage: 'Errors Found'
    },
    correctFieldBeforeQuotings: {
        id: 'agent.util.Errors.CorrectFieldsBeforeQuoting',
        defaultMessage: 'The fields listed below must be corrected before quoting.'
    }

});
