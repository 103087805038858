import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { withModalContext } from '@jutro/components';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { PolicyChangeService } from 'gw-capability-gateway';
import { GatewayDocumentService } from 'gw-capability-gateway-document';
import { isCapabilityEnabled } from '@xengage/gw-portals-config-js';
import { DocumentsPage } from 'gw-capability-document-react';
import { DependencyProvider } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { PageHeaderComponent } from '../Components/FormattedHeaderComponent/FormattedHeaderComponent';
import CommonRoutes from '../Common/CommonRouting';
import messages from './Summary/Summary.messages';
import metadata from './Endorsement.metadata.json5';
import pageRouting from '../Common/Endorsement-config.json5';

const { capabilitiesConfig } = appConfig;

const DocumentPageService = {
    getDocuments: PolicyChangeService.getDocumentsForPolicyChange,
    generateUploadToken: GatewayDocumentService.generateUploadToken,
    uploadDocument: GatewayDocumentService.uploadDocument,
    removeDocument: GatewayDocumentService.removeDocument,
    downloadDocument: GatewayDocumentService.downloadDocument
};

function getRoutes() {
    const documentRoute = {
        path: '/documents',
        component: DocumentsPage
    };
    if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'document' })) {
        return [...pageRouting, documentRoute];
    }
    return pageRouting;
}

class EndorsementWithoutModalContext extends Component {
    static propTypes = {
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }).isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                jobNumber: PropTypes.string
            }),
            url: PropTypes.string
        }).isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired
        }).isRequired
    };

    state = {
        currentView: 'summary',
        policyChangeSummaryCount: {},
        policyChange: {}
    };

    componentDidMount() {
        this.loadPolicyChangeSummaryCount();
        this.getJobByJobNumber();
    }

    handleTilesOnClick = (id) => {
        this.setState({ currentView: id });
    };

    loadPolicyChangeSummaryCount = () => {
        const {
            authHeader,
            match: {
                params: { jobNumber }
            },
            location: { pathname }
        } = this.props;

        const routePath = pathname && (pathname.trim().split('/') || []);
        const selectedPageFromPath = routePath[routePath.length - 1];
        const activeTile = selectedPageFromPath || 'summary';

        PolicyChangeService.getPolicyChangeSummary(jobNumber, authHeader).then((tilesData) => {
            this.setState({
                currentView: activeTile,
                policyChangeSummaryCount: tilesData
            });
        });
    };

    updatePolicyChangeSummary = (policyChange) => {
        this.setState({ policyChange });
    };

    getJobByJobNumber = async () => {
        const {
            history,
            authHeader,
            match: {
                params: { jobNumber }
            }
        } = this.props;
        try {
            const policyChange = await PolicyChangeService.findJobByJobNumber(
                jobNumber,
                authHeader
            );
            if (!policyChange.statusCode) {
                this.props.modalContext.showAlert({
                    title: messages.error,
                    message: messages.details,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
                history.push('/policies');
            }
            this.updatePolicyChangeSummary(policyChange);
        } catch {
            this.props.modalContext.showAlert({
                title: messages.error,
                message: messages.details,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(_.noop);
            history.push('/policies');
        }
    };

    render() {
        const { currentView, policyChangeSummaryCount, policyChange } = this.state;

        if (_.isEmpty(policyChange)) {
            return null;
        }

        const policyChangeVM = {
            policyChange,
            policyChangeSummaryCount
        };
        const overrides = {
            [currentView]: {
                active: true
            }
        };
        const resolvers = {
            resolveCallbackMap: {
                handleTilesOnClick: this.handleTilesOnClick
            },
            resolveComponentMap: {
                pageheadercomponent: PageHeaderComponent
            }
        };

        const { match: { url, params: { jobNumber } } } = this.props;

        const quoteData = {
            jobNumber: jobNumber,
            loadPolicyChangeSummaryCount: this.loadPolicyChangeSummaryCount,
            loadPolicyChangeSummary: policyChange,
            updateJobSummary: this.updatePolicyChangeSummary
        };

        return (
            <DependencyProvider value={{ DocumentPageService }}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={policyChangeVM}
                    overrideProps={overrides}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
                <CommonRoutes
                    steps={getRoutes()}
                    setDocumentCount={this.loadPolicyChangeSummaryCount}
                    jobNumber={jobNumber}
                    basePath={url}
                    quoteDetailsData={quoteData}
                />
            </DependencyProvider>
        );
    }
}

const Endorsement = withModalContext(EndorsementWithoutModalContext);

export default withRouter(withAuthenticationContext(Endorsement));
