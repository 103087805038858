import { defineMessages } from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'pe-portal.wizard.components.WMICLapseRenewalModal.Cancel',
        defaultMessage: 'Cancel'
    },
    revertLapse: {
        id: 'wmic.pe-portal.components.LapseRenewal.Revert Lapse',
        defaultMessage: 'Revert Lapse'
    },
    revertLapseModalTitle: {
        id: 'wmic.pe-portal.components.LapseRenewal.Revert Lapsed Renewal',
        defaultMessage: "Revert Lapsed Renewal"
    },
    revertLapseModalBody: {
        id: 'wmic.pe-portal.components.LapseRenewal.Revert Lapsed Renewal Modal Body',
        defaultMessage: "This policy will lapse at the end of the policy term. You may withdraw the lapse by clicking below."
    }
});
