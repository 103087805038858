import React, { useCallback, useContext, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { GatewayDocumentService } from 'wmic-pe-capability-gateway-document';
import { TranslatorContext } from '@jutro/locale';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { CONSTANTS, MODAL_CONSTANTS, WMICLogger } from 'wmic-pe-portals-utils-js';
import { WMICCard } from 'wmic-pe-components-platform-react';
import FileUploadViewerComponent from './FileUploadViewerComponent/FileUploadViewerComponent';
import metadata from './WMICDocuments.metadata.json5';
import messages from './WMICDocuments.messages';
import styles from './WMICDocuments.module.scss';


function WMICDocuments(props) {
    const EMPTY_FILE_ERROR_MSG = 'Zero size file detected';

    const translator = useContext(TranslatorContext);
    const { showConfirm, showError } = useWizardModals();
    const [deletedFiles, setDeletedFiles] = useState({});
    const {
        value: { documentDTOs, policyNumber, jobNumber, canUploadDocument },
        documents,
        updateDocuments,
        maxNumberOfFiles,
        maxFileSizeKB,
        noDocumentMessage
    } = props;
    const { authHeader } = useAuthentication();
    const getUploadToken = useCallback(async () => {
        try {
            const uploadTokenID = await GatewayDocumentService.generateUploadToken(authHeader);

            return uploadTokenID;
        } catch (e) {
            return showError({
                title: commonMessages.errorUploadTitle,
                message: commonMessages.errorGenerateUploadToken,
            });
        }
    }, [authHeader, showError]);

    const onFileClear = useCallback((fileName) => {
        // Remove deleted record if the file has been "cleared" from FileUploadField
        setDeletedFiles((oldFiles) => {
            const newDeletedFiles = {...oldFiles};
            delete newDeletedFiles[fileName];
            return newDeletedFiles;
        });
    }, []);

    const deleteDocument = useCallback(
        (item) => {
            if (!item.canDelete) {
                return false;
            }

            const { publicID } = item;

            return showConfirm({
                title: messages.removeDocument,
                message: translator(messages.confirmRemoveDocument, {documentName: item.name}),
            }).then(async (results) => {
                if (results === CONSTANTS.MODAL_RESULT.CANCEL || results === CONSTANTS.MODAL_RESULT.CLOSE) {
                    return _.noop();
                }

                try {
                    const isDocumentDeleted = await GatewayDocumentService.removeDocument(
                        publicID,
                        authHeader
                    );

                    if (isDocumentDeleted) {
                        const newDocuments = _.filter(documents, (doc) => doc.publicID !== publicID);

                        updateDocuments(newDocuments);
                        // memorize which files got deleted, so errors can be shown in FileUploadField
                        setDeletedFiles((oldFiles) => ({...oldFiles, [item.name]: true}));
                    }
                } catch (documentDeletionError) {
                    showError({
                        title: messages.documentDeletionFailed,
                        message: messages.documentCannotDeleted,
                        status: MODAL_CONSTANTS.STATUS.ERROR,
                        icon: MODAL_CONSTANTS.ICON.ERROR,
                        confirmButtonText: commonMessages.ok
                    });
                }

                return true;
            }, _.noop);
        },
        [showConfirm, translator, authHeader, documents, updateDocuments, showError]
    );

    const getDocumentDownloadLink = useCallback(
        (item) => {
            const { publicID, sessionID } = item;

            return GatewayDocumentService.downloadDocument(publicID, sessionID);
        },
        []
    );

    const uploadDocument = useCallback(
        async (item, progress, fileProps) => {
            const documentMetaDataTemplate = {
                docUID: '001',
                documentType: 'broker_upload',
                securityType: 'unrestricted',
                status: 'complete_wmic',
                policyNumber,
                jobNumber,
                name: item.name,
                mimeType: item.type,
                SessionID: await getUploadToken()
            };

            try {
                fileProps.total[item.name] = item.size;

                progress(item.name, 0);

                const recentUploadedDoc = await GatewayDocumentService.uploadDocumentAxios(
                    item,
                    documentMetaDataTemplate,
                    data => {
                        progress(item.name, data.loaded)
                    },
                    authHeader
                );

                if (!_.isEmpty(recentUploadedDoc)) {
                    updateDocuments([...documents, recentUploadedDoc]);
                }

                return {};
            } catch (error) {
                WMICLogger.error(`request=uploadDocument, error=${JSON.stringify(error)}`);

                const errorMessage = _.get(error, 'response.data.error.message', '').includes(EMPTY_FILE_ERROR_MSG)
                    ? messages.emptyFile
                    : messages.documentCannotUploaded;

                return {error, message: translator(errorMessage)};
            }
        },
        [policyNumber, jobNumber, getUploadToken, authHeader, updateDocuments, documents, translator]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrides =  {
        documentFileUploadComponent: {
            data: {
                isReadOnly: !canUploadDocument,
                documents: _.clone(documents)
            },
            getDownloadLink: getDocumentDownloadLink,
            maxNumberOfFiles,
            maxFileSizeKB,
            noDocumentMessage,
            deletedFiles,
            onFileClear
        }
    }

    const resolvers = {
        resolveComponentMap: {
            documentscomponent: FileUploadViewerComponent,
            WMICCard
        },
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onDocumentDelete: deleteDocument,
            onDocumentUpload: uploadDocument
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.componentContent,
                documentDTOs,
                id,
                path,
                overrides
            ),
        [documentDTOs, overrides]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={documentDTOs}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            resolveValue={readValue}
        />
    );
}

WMICDocuments.propTypes = {
    value: PropTypes.shape({
        documentDTOs: PropTypes.arrayOf(PropTypes.shape({})),
        policyNumber: PropTypes.string,
        jobNumber: PropTypes.string,
        canUploadDocument: PropTypes.bool
    }),
    documents: PropTypes.shape([]).isRequired,
    updateDocuments: PropTypes.func.isRequired,
    maxNumberOfFiles: PropTypes.number,
    maxFileSizeKB: PropTypes.number,
};

WMICDocuments.defaultProps = {
    value: {
        documentDTOs: [],
        policyNumber: '',
        jobNumber: '',
        canUploadDocument: false
    },
    maxNumberOfFiles: CONSTANTS.DOCUMENT_DEFAULTS.MAX_NUM_OF_FILES,
    maxFileSizeKB: CONSTANTS.DOCUMENT_DEFAULTS.MAX_FILE_SIZE_KB,
};

export default WMICDocuments;
