import { defineMessages } from 'react-intl';

export default defineMessages({
    wizard: {
        id: 'quoteandbind.cp.wizard.step.Qualifications',
        defaultMessage: 'Qualifications'
    },
    qualificationTitle: {
        id: 'quoteandbind.cp.views.qualificationHeader',
        defaultMessage: 'Qualifications'
    },
    documentTitle: {
        id: 'quoteandbind.cp.views.documentTitle',
        defaultMessage: 'Qualifications Questions'
    },
    returnLater: {
        id: 'quoteandbind.cp.views.returnLater',
        defaultMessage: 'Return Later'
    }
});
