import React, { useState , useEffect , useContext} from 'react';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICWizardViewPage } from 'wmic-pe-portals-wizard-components-ui';
import { DocumentService } from 'wmic-pe-capability-gateway-policycommon';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { TranslatorContext } from '@jutro/locale';
import { WMICDocuments } from 'wmic-pe-capability-gateway-common-react';
import { WMICLogger, WMICUserAccessUtil } from 'wmic-pe-portals-utils-js';
import metadata from './WMICPolicyDocuments.metadata.json5';
import messages from './WMICPolicyDocuments.messages';

function WMICPolicyDocuments(props) {

    const { authUserData, authHeader} = useAuthentication();
    const [canUploadDocuments, setCanUploadDocuments] = useState(true);
    const [userDocuments, setUserDocuments] = useState([]);
    const translator = useContext(TranslatorContext);

    const {
        wizardData: { policyVM },
    } = props;

    const policyNumber = _.get(policyVM, "policyNumber.value");

    useEffect(() => {
        setCanUploadDocuments(WMICUserAccessUtil.canUploadDocument(authUserData.roles));

        DocumentService.getDocumentsForPolicyNumber(policyNumber, authHeader).then((documents) => {
            if (userDocuments.length !== documents.length){
                setUserDocuments(documents);
            }
        })
        .catch((err) => {
            WMICLogger.error('WMICPolicyDocumentsPage getDocumentsForPolicyNumber', err)
        });
    },[policyNumber, authUserData, authHeader, userDocuments])

    const overrideProps = {
        policyDocumentsUpload: {
            documents: userDocuments,
            updateDocuments: setUserDocuments,
            value: {
                documentDTOs: userDocuments,
                canUploadDocument: canUploadDocuments,
                policyNumber
            },
            noDocumentMessage: translator(messages.emptyDocumentMessage)
        }
    }

    const resolvers = {
        componentMap: {
            WMICDocuments
        }
    };

    return (
        <WMICWizardViewPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyVM}
                overrideProps={overrideProps}
                componentMap={resolvers.componentMap}
            />
        </WMICWizardViewPage>
    );
}

WMICPolicyDocuments.propTypes = wizardProps;
export default WMICPolicyDocuments;
