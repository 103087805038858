import React from 'react';
import PropTypes from 'prop-types';
import { Flex, FlexItem } from '@jutro/layout';
import { WMICCard } from 'wmic-pe-components-platform-react';
import { InputField } from '@jutro/components';
import styles from './WMICDataBlocks.module.scss';
import _ from "lodash";

function WMICDataBlocks(props) {
    const {
        VMList,
        VMData
    } = props;

    if (_.isEmpty(VMList) || _.isEmpty(VMData)) {
        return null;
    }

    const generateGutterCell = (dataItem, dataIndex) => {
        const gutterCellData = VMData.find((cellData) => cellData.isGutterCell);
        return gutterCellData? buildCellContent(dataItem, dataIndex, gutterCellData) : null;
    }

    const buildCellContent = (dataItem, dataIndex, dataField) =>{
        const inputValue = dataField.renderCell ?
            dataField.renderCell(dataItem, dataIndex, {path: dataField.path}) : _.get(dataItem, dataField.path)

        return (<FlexItem className={styles.flexBlockItem}>
            <InputField
                readOnly
                label={dataField.headerText}
                value={inputValue}
            />
        </FlexItem>)
    }

    const generateMainCells = (dataItem, dataIndex) => {
        return VMData.filter((cellData) => !cellData.isGutterCell).map((dataField) => {
            return buildCellContent(dataItem, dataIndex, dataField);
        })
    }

    const generateFlexRows = () => {
       return  VMList.map((dataItem, dataIndex) => {
           return <Flex className={styles.flexBox}>
               <FlexItem className={styles.gutterContainer}>{generateGutterCell(dataItem, dataIndex)}</FlexItem>
               <FlexItem className={styles.flexBlockContainer}>{generateMainCells(dataItem, dataIndex)}</FlexItem>
           </Flex>
        })
    }

    return VMList !== undefined && VMList.length > 0?
        <WMICCard className={styles.flexBlockCard}>
           {generateFlexRows()}
        </WMICCard>
        : null;
}

WMICDataBlocks.propTypes = {
    VMList: PropTypes.array.isRequired,
    VMData: PropTypes.arrayOf(
        PropTypes.shape({
            headerText: PropTypes.string,
            path: PropTypes.string,
        })
    ).isRequired
};

export default WMICDataBlocks;
