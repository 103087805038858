import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class GatewayPolicyChangeService {
    static findJobByJobNumber(policyChangeNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayChangePolicy'),
            'findJobByJobNumber', [policyChangeNumber], additionalHeaders);
    }

    static getPAPolicyChangeSummary_WMIC(policyChangeNumber, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayChangePolicy'),
            'getPAPolicyChangeSummary_WMIC',
            [policyChangeNumber], additionalHeaders);
    }

    static getHOPolicyChangeSummary_WMIC(policyChangeNumber, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayChangePolicy'),
            'getHOPolicyChangeSummary_WMIC',
            [policyChangeNumber], additionalHeaders);
    }

    static getPUPPolicyChangeSummary_WMIC(policyChangeNumber, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayChangePolicy'),
            'getPUPPolicyChangeSummary_WMIC',
            [policyChangeNumber], additionalHeaders);
    }

    static referToUnderwriter(renewalNumber, noteBody, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayChangePolicy'),
            'referToUnderwriter',
            [renewalNumber, noteBody], additionalHeaders);
    }

}
