import { defineMessages } from 'react-intl';

export default defineMessages({
    wmicUnderlyingPoliciesHomeownersPolicy: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPUnderlyingPoliciesComponent.HomeownersPolicy',
        defaultMessage: 'Personal Property Policy'
    },
    wmicUnderlyingPoliciesAutomobilePolicy: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPUnderlyingPoliciesComponent.AutomobilePolicy',
        defaultMessage: 'Automobile Policy'
    },
    wmicUnderlyingPoliciesWatercraftPolicy: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPUnderlyingPoliciesComponent.WatercraftPolicy',
        defaultMessage: 'Watercraft Policy'
    },
    wmicUnderlyingPoliciesCarrierName: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPUnderlyingPoliciesComponent.CarrierName',
        defaultMessage: 'Insurer'
    },
    
    wmicUnderlyingPoliciesCarrierNameOther: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPUnderlyingPoliciesComponent.CarrierNameOther',
        defaultMessage: 'Describe Other'
    },
    wmicUnderlyingPoliciesPolicyNumber: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPUnderlyingPoliciesComponent.PolicyNumber',
        defaultMessage: 'Policy Number'
    },
    wmicUnderlyingPoliciesPolicyPlateNumber: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPUnderlyingPoliciesComponent.PolicyPlateNumber',
        defaultMessage: 'Policy / Plate Number'
    },
    wmicUnderlyingPoliciesEffectiveDate: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPUnderlyingPoliciesComponent.EffectiveDate',
        defaultMessage: 'Effective Date'
    },
    wmicUnderlyingPoliciesExpirationDate: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPUnderlyingPoliciesComponent.ExpirationDate',
        defaultMessage: 'Expiration Date'
    },
    wmicUnderlyingPoliciesHomeownersCoverageRestrictions: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPUnderlyingPoliciesComponent.HomeownersCoverageRestrictions',
        defaultMessage: 'Does this policy have coverage restrictions added to the standard wording?'
    },
    wmicUnderlyingPoliciesHomeownersCoverageRestrictionsDetails: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPUnderlyingPoliciesComponent.CoverageRestrictionsDetails',
        defaultMessage: 'Details'
    },
    wmicUnderlyingPoliciesHomeownerscoverageDetails: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPUnderlyingPoliciesComponent.coverageDetails',
        defaultMessage: 'Coverage Details'
    },
    wmicUnderlyingPoliciesHomeownersIsExcessPolicy: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPUnderlyingPoliciesComponent.IsExcessPolicy',
        defaultMessage: 'Is this an Excess Policy?'
    },
    wmicUnderlyingPoliciesHomeownersExcessPolicyMustbeAssociated: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPUnderlyingPoliciesComponent.ExcessPolicyMustbeAssociated',
        defaultMessage: 'This Excess Policy must be associated to a vehicle on the primary underlying policy'
    },
    wmicUnderlyingPoliciesHomeownersAutoAddedWatercraftPolicy: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPUnderlyingPoliciesComponent.AutoAddedWatercraftPolicy',
        defaultMessage: 'This underlying policy was created for the watercraft risks associated with Wawanesa Policy #{policyNumber}.  Please add the watercraft details here.'
    }
});