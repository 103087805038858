import { defineMessages } from 'react-intl';

export default defineMessages({
    lapseRenewal: {
        id: 'wmic.pe-portal.components.LapseRenewal.Lapse Renewal',
        defaultMessage: 'Lapse Renewal'
    },
    cancel: {
        id: 'wmic.pe-portal.components.LapseRenewal.Cancel',
        defaultMessage: 'Cancel'
    },
    submit: {
        id: 'wmic.pe-portal.components.LapseRenewal.Submit',
        defaultMessage: 'Submit'
    },
    insured: {
        id: 'wmic.pe-portal.components.LapseRenewal.Insured',
        defaultMessage: 'Insured'
    },
    policy: {
        id: 'wmic.pe-portal.components.LapseRenewal.Policy',
        defaultMessage: 'Policy'
    },
    mailingAddress: {
        id: 'wmic.pe-portal.components.LapseRenewal.Mailing Address',
        defaultMessage: 'Mailing Address'
    },
    lapseReason: {
        id: 'wmic.pe-portal.components.LapseRenewal.Reason for Lapsed Renewal',
        defaultMessage: 'Reason for Lapsed Renewal'
    }
});
