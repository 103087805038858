import { defineMessages } from 'react-intl';

export default defineMessages({
    postalCode: {
        id: 'wmic-pe-capability-gateway-react.AccountDetails.ZIP Code',
        defaultMessage: 'Postal code'
    },
    estateOf: {
        id: 'wmic-pe-capability-gateway-react.AccountDetails.Estate of',
        defaultMessage: 'Estate Of'
    }
});