import { defineMessages } from 'react-intl';

export default defineMessages({
    policyNumber: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICAddPriorLossComponent.Policy Number",
        defaultMessage: "Policy #"
    },
    claimStatus: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICAddPriorLossComponent.ClaimStatus",
        defaultMessage: "Claim Status"
    },
    lossDate: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICAddPriorLossComponent.LossDate",
        defaultMessage: "Date of Loss"
    },
    removePayout: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICAddPriorLossComponent.RemovePayout",
        defaultMessage: "Remove payout/reserve?"
    },
    removePayoutConfirmation: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICAddPriorLossComponent.RemoveConfirmationMsg",
        defaultMessage: "Are you sure you want to remove this payout/reserve?"
    },
    claimInformationTitle: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICAddPriorLossComponent.ClaimInformation",
        defaultMessage: "Claim Information"
    },
    manualLossType: {
        id:"wmic-pe-capability-gateway-common-ho-react.WMICAddPriorLossComponent.ManualLossType",
        defaultMessage: "Type of Loss"
    },
    claimDesc: {
        id:"wmic-pe-capability-gateway-common-ho-react.WMICAddPriorLossComponent.ClaimDescription",
        defaultMessage: "Claim Description"
    },
    catastropheIndicatorType: {
        id:"wmic-pe-capability-gateway-common-ho-react.WMICAddPriorLossComponent.CatastropheIndicatorType",
        defaultMessage: "Catastrophe Indicator"
    },
    policyInformationTitle: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICAddPriorLossComponent.PolicyInformation",
        defaultMessage: "Policy Information"
    },
    insuranceCompany: {
        id:"wmic-pe-capability-gateway-common-ho-react.WMICAddPriorLossComponent.Insurance Company",
        defaultMessage: "Insurance Company"
    },
    payoutReservesInformationTitle: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICAddPriorLossComponent-payout.PayoutReservesInformation",
        defaultMessage: "Payout Reserves Information"
    },
    addPayoutButton: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICAddPriorLossComponent-payout.Add",
        defaultMessage: "Add Payout"
    }
})