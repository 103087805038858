import { WMICJsonRPCService } from 'wmic-pe-portals-transport-js';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { WMICDocumentUploadService } from 'wmic-pe-portals-document-js';

export default class DocumentService {

    static removeDocument(publicID, additionalHeaders = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('gatewayDocument'), 'removeDocument', [publicID], additionalHeaders);
    }

    static getDocumentsForPolicyNumber(policyNumber, additionalHeaders = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('gatewayDocument'), 'getDocumentsForPolicyNumber_WMIC', [policyNumber], additionalHeaders)
    }

    static getDocumentsForJobNumber(jobNumber, additionalHeaders = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('gatewayDocument'), 'getDocumentsForJobNumber_WMIC', [jobNumber], additionalHeaders)
    }

    static uploadDocument(document, documentMetadata, authHeader = {}) {
        return WMICDocumentUploadService.send(getProxiedServiceUrl('gatewayUpload'), document, documentMetadata, authHeader);
    }

    static generateQuoteDocument(jobNumber, additionalHeaders = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('gatewayDocument'), 'generateQuoteDocument', [jobNumber], additionalHeaders)
    }

    static retrieveQuoteDocument(documentPublicID, additionalHeaders = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('gatewayDocument'), 'retrieveQuoteDocument', [documentPublicID], additionalHeaders)
    }

}
