import { defineMessages } from 'react-intl';

export default defineMessages({
    policyCancelTitle: {
        id: 'wmic-pe-capability-gateway-common-react.Cancel Policy',
        defaultMessage: 'Cancel Policy'
    },    
    personalPropertyPolicy: {
        id: 'wmic-pe-capability-gateway-common-react.Personal Property',
        defaultMessage: 'Personal Property # '
    },
    personalAutomobilePolicy: {
        id: 'wmic-pe-capability-gateway-common-react.Automobile',
        defaultMessage: 'Automobile # '
    },
    personalUmbrellaPolicy: {
        id: 'wmic-pe-capability-gateway-common-react.Personal Umbrella',
        defaultMessage: 'Personal Umbrella # '
    },
    policy: {
        id: 'wmic-pe-capability-gateway-common-react.Policy',
        defaultMessage: 'Policy # '
    },
    effectiveDate: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyCancelModal.Effective Date',
        defaultMessage: 'Effective Date'
    },
    cancelReason: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyCancelModal.Reason',
        defaultMessage: 'Reason'
    },
    cancelReasonDescription: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyCancelModal.Reason Description',
        defaultMessage: 'Reason Description'
    },
    refundMethod: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyCancelModal.Refund Method',
        defaultMessage: 'Refund Method'
    },
    returnToPolicySummary: {
        id: 'wmic-pe-capability-gateway-common-react.Return to Policy Summary',
        defaultMessage: 'Return to Policy Summary'
    },
    submitPolicyCancellation: {
        id: 'wmic-pe-capability-gateway-common-react.Submit',
        defaultMessage: 'Submit'
    },
    submittingPolicyCancellation: {
        id: 'wmic-pe-capability-gateway-common-react.Submitting Policy Cancellation',
        defaultMessage: 'Submitting Policy Cancellation'
    }
});