/* eslint-disable no-secrets/no-secrets */
import { defineMessages } from 'react-intl';

export default defineMessages({
    wmicUnderlyingPoliciesWatercraftDetails: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPWatercraftExposures.WatercraftDetails',
        defaultMessage: 'Watercraft'
    },
    wmicUnderlyingPoliciesRemoveWatercraftExposureConfirmationMsg: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPWatercraftExposures.RemoveWatercraftExposureConfirmationMsg',
        defaultMessage: 'Are you sure you want to remove watercraft {number} from the list of watercrafts?'
    },
    wmicUnderlyingPoliciesRemoveWatercraftExposure: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPWatercraftExposures.RemoveWatercraftExposure',
        defaultMessage: 'Remove Watercraft?'
    },
    wmicAtLeastOneWatercraft: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPWatercraftExposures.AtLeastOneWatercraft',
        defaultMessage: 'At least &lt;b&gt;one watercraft&lt;/b&gt; is required'
    },
    wmicUnderlyingPoliciesMotorType: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPWatercraftExposures.MotorType',
        defaultMessage: 'Motor Type'
    },
    wmicAddWatercraft: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPWatercraftExposures.AddNewWatercraft',
        defaultMessage: 'Add Watercraft'
    },
    wmicMotorType: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPWatercraftExposures.WatercraftMotorType',
        defaultMessage: 'Motor Type'
    },
    wmicLength: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPWatercraftExposures.Length',
        defaultMessage: 'Length'
    },
    wmicHorsePower: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPWatercraftExposures.HorsePower',
        defaultMessage: 'Horse Power'
    },
    wmicMaxSpeed: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPWatercraftExposures.MaxSpeed',
        defaultMessage: 'Max Speed'
    }
})
