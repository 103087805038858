import { WMICVehicleUtil } from 'wmic-pe-portals-utils-js'
import { PAConstants } from 'wmic-pe-portals-utils-js';

export default {
    isCommerciallyRatedVehicleType: (businessSegmentType, vehicleType) => {
        return WMICVehicleUtil.isCommerciallyRatedVehicleType(businessSegmentType.code, vehicleType.code);
    },
    hasExtraHeavyGrossVehicleWeight: (driverPublicId, vehicles) => {
        let numOfDrivers = 0;
        let drivers;
        let vehicle;

        // if no vehicles
        if(vehicles === undefined || vehicles.length === 0){
            return false;
        }

        for(let i = 0; i < vehicles.length; i++){
            vehicle = vehicles[i];
            drivers = vehicles[i].drivers;
            numOfDrivers = (drivers !== undefined) ? drivers.length : 0;

            if(vehicle.businessSegment_WMIC === PAConstants.vehicleBusinessSegmentCommercial && vehicle.commercialVehicle_WMIC &&
                (vehicle.commercialVehicle_WMIC.grossVehicleWeight === PAConstants.commercialVehicleWeight.ExtraHeavy ||
                    vehicle.commercialVehicle_WMIC.grossVehicleWeight === PAConstants.commercialVehicleWeight.ExtraHeavy)){
                for(let j = 0; j < numOfDrivers; j++){
                    if(drivers[j].driverPublicID === driverPublicId) {
                        return true;
                    }
                }
            }
        }

        return false;
    }
}
