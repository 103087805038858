import { start } from '@jutro/app';
import {
    BrowserRouter
} from 'react-router-dom';
import { initDefaultGA, initDefaultMixpanel } from '@jutro/events';
import  { BrandingService } from '@xengage/gw-portals-branding-js'
import { LocaleService } from 'gw-portals-i18n-react';
import { loadGoogleApiScript } from 'wmic-pe-ext-google-tools';

import 'regenerator-runtime';

 
import appConfig from 'app-config';

import App from './app/App';

const AUTH_TYPE_INTERNAL = 'internal';
const QUERY_PARAMETER_AUD = 'aud';
const AUTH_STORAGE_KEY = 'okta-auth-type';
if (window.self === window.top) { 
    const theBody = document.getElementsByTagName('body')[0];

    theBody.style.display = 'block';
} else { 
    const {host, protocol} = new URL(window.top.location)
    const root = `${protocol}//${host}`
    const rootPath = process.env.PUBLIC_PATH

    window.top.location = new URL(rootPath, root);
}

const { trackingConfig = {}, authentication = {} } = appConfig;

const gaTrackingId = trackingConfig.GA_TRACKING_ID;
const mixpanelTrackingId = trackingConfig.MIXPANEL_TRACKING_ID;

if (gaTrackingId) {
    initDefaultGA(trackingConfig);
}

if (mixpanelTrackingId) {
    initDefaultMixpanel(trackingConfig);
}

const authTypeLocal = localStorage.getItem(AUTH_STORAGE_KEY);
const authTypeParam = new URL(window.location).searchParams.get(QUERY_PARAMETER_AUD);
const isInternalUser = authTypeParam === AUTH_TYPE_INTERNAL || authTypeLocal === AUTH_TYPE_INTERNAL;
if (isInternalUser) {
    localStorage.setItem(AUTH_STORAGE_KEY, AUTH_TYPE_INTERNAL);
    authentication.servers.okta.JUTRO_AUTH_IDP = appConfig.wmicAuthenticationConfig.authIdpInternal;
}
const config = {
    ...authentication.servers.okta,
    ...appConfig.credentials,
    localeSettings: { ...appConfig.localeSettings },
    ...appConfig.wmicJutroEnvVars
};

start(App, {
    rootId: 'root',
    messageLoader: LocaleService.loadMessages,
    coreMessageLoader: LocaleService.loadCoreMessages,
    refreshOnLanguageOrLocaleChange: true,
    routerBasename: '/producer-engage',
    config: [config],
    themeConfig: BrandingService.getBrandingTheme(),
    onInit: () => {
        LocaleService.register();
        loadGoogleApiScript()
    },
    Router: BrowserRouter,
    appName: {
        id: 'digital.appName',
        defaultMessage: 'BluePass'
    },
    appDescription: 'ProducerEngage'
});
