/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { LOBConstants } from 'wmic-pe-portals-utils-js';
import PolicyLineExtensionConfiguration from './PolicyLineExtensionConfiguration';

function Submission(data) {
    _.extend(this, data);

    const isCommercial = data?.baseData?.productCode === LOBConstants.CP;

    if (data) {
        PolicyLineExtensionConfiguration.mixinLobDraftDataExtensions(this);
        this.persons = [];

        if (isCommercial && this.edeliveryEnrolled_WMIC === undefined) {
            this.edeliveryEnrolled_WMIC = false;
        }
    }
}

export default Submission;
