import { defineMessages } from 'react-intl';

export default defineMessages({
    heatingUpgrades: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingUpgradeComponent.Heating Upgrades",
        defaultMessage: "Heating Upgrades"
    },
    year: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHOConstructionHeatingUpgradeComponent.Year",
        defaultMessage: "Year"
    },
    
});
