import React, { useState, useContext, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { QuoteClauseTable, WMICCard, WMICAccordionCard } from 'wmic-pe-components-platform-react';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { Position, HOConstants, JobType } from "wmic-pe-portals-utils-js";
import { WMICHODwellingCovAdditionalInterests, WMICHOHomeBasedBusinessCov, WMICHOBusinessPropertyCov, WMICHODisappearingDeductible } from 'wmic-pe-capability-gateway-common-ho-react';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import WMICHORiskUtil from "wmic-pe-capability-gateway-common-ho-react/utils/WMICHORiskUtil";
import WMICHOCoverageUtil from "wmic-pe-capability-gateway-common-ho-react/utils/WMICHOCoverageUtil";
import { useAccordionValidation } from 'wmic-pe-portals-wizard-components-ui';

import messages from './WMICHODwellingCoverages.messages';
import metadata from './WMICHODwellingCoverages.metadata.json5';
import styles from './WMICHODwellingCoverages.module.scss';

function WMICHODwellingCoverages(props) {
    const {
        dwellingIndex,
        dwellingVM,
        dwellingCoverages,
        baseData,
        coverageColumnData,
        changeSubmissionAndSync,
        changeSubmission,
        syncCoverages,
        recalculate,
        resetQuote,
        onStaleQuoteBranchCode,
        underwritingIssues,
        quoteID,
        onValidate: parentOnValidate,
        jobVM,
        readOnly,
        showErrors
    } = props;

    const translator = useContext(TranslatorContext);
    const { authHeader } = useAuthentication();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { EndorsementService } = useDependencies('EndorsementService');
    const { RenewalService } = useDependencies('RenewalService');
    const { onValidate, isComponentValid } = useValidation('WMICHODwellingCoverages');
    const [isCoverageLoading, setIsCoverageLoading] = useState(false);
    const [packageCoverageTermDeductible, setPackageCoverageTermDeductible] = useState(undefined);
    const { onValidateAccordion, isAccordionValid } = useAccordionValidation(onValidate);
    const jobType = _.get(jobVM, 'baseData.jobType.value.code');

    const formatDwellingHeader = () => {
        const address = _.get(dwellingVM, 'yourHome.locationAddress.displayName.value');
        const dwellingNumber = _.get(dwellingVM, 'yourHome.dwellingNumber_EXT.value', "");
        return translator(messages.dwellingHeader, {riskID: dwellingNumber, riskLocation: WMICHORiskUtil.formatDwellingLocation(address)})
    };

    useEffect(()=> {
        if (parentOnValidate) {
            parentOnValidate(isComponentValid, 'WMICHODwellingCoverages')
        }

        return () => parentOnValidate ? parentOnValidate(true, 'WMICHODwellingCoverages') : undefined
    }, [parentOnValidate, isComponentValid]);

    useEffect(() => {
        const coverages = _.get(dwellingCoverages, 'coverages.value')

        coverages.forEach((coverage) => {
            if (coverage.publicID === HOConstants.packageCoveragePublicID) {
                updatePackageCoverage(coverage)
            }
        })
    }, [dwellingCoverages]);


    const updatePackageCoverage = (packageCoverage) => {
        const { terms } = packageCoverage;

        terms.forEach((term) => {
            if (term.patternCode === HOConstants.packageCoverageDeductiblePatternCode) {
                setPackageCoverageTermDeductible(term)
            }
        })
    };

    function generateClauseData() {
        return coverageColumnData.map(({ lob, code }) => ({
            code,
                path: `${lob.path}.coverages.dwellingCoverages.children[${dwellingIndex}].coverages`,
                clauses: _.get(dwellingCoverages, 'coverages.value')
        }));
    }

    const getCustomTerms = useCallback((clause, path) => {
        if (clause.publicID === 'HO_HomeBusinessCov' && clause.selected === true) {
            return [{
                component: WMICHOHomeBasedBusinessCov,
                type: 'field',
                componentProps: {
                    dwellingVM,
                    dwellingCoverage: clause,
                    path,
                    readOnly,
                    onUpdateJobAndCoverage: updateJobAndCoverage,
                    showErrors: showErrors || showErrors,
                    isLoading: isCoverageLoading,
                    id: clause.publicID
                }
            }];
        } 

        if (clause.publicID === HOConstants.packageCoveragePublicID) {
            return [{
                component: WMICHODisappearingDeductible,
                type: 'element',
                componentProps: {
                    id: clause.publicID,
                    disappearingDeductibleReducedValue: _.get(dwellingVM, 'disappearingDeductibleReducedValue.value'),
                    baseData
                }
            }];
        }
        
        if (clause.publicID === HOConstants.businessPersonalPropertyCovPublicID && clause.selected === true) {
            return [{
                component: WMICHOBusinessPropertyCov,
                type: 'field',
                componentProps: {
                    dwellingVM,
                    dwellingCoverage: clause,
                    path,
                    readOnly,
                    onUpdateJobAndCoverage: updateJobAndCoverage,
                    showErrors: showErrors || showErrors,
                    isLoading: isCoverageLoading,
                    id: clause.publicID
                }
            }];
        }

        if (clause.selected
            && clause.publicID === HOConstants.shortTermRentalCovPublicID) {

            return [{
                component: CurrencyField,
                id: 'packageTermDeductible',
                type: 'field',
                componentProps: {
                    dataType: 'number',
                    value: packageCoverageTermDeductible?.chosenTermValue.replace(/,/g, ''),
                    label: translator(messages.deductibleLabel),
                    className: 'wmicMasterInputInputField',
                    labelClassName: 'wmicMasterInputFieldLabel',
                    showFractions: false,
                    layout: 'full-width',
                    required: true,
                    readOnly: true
                }
            }]
        }

        return null;
    }, [dwellingVM, readOnly, updateJobAndCoverage, showErrors, isCoverageLoading, packageCoverageTermDeductible?.chosenTermValue, translator]);

    const saveJob = useCallback(async () => {
        let service;
        let payload = jobVM.value;

        switch (jobType) {
            case JobType.SUBMISSION: 
                service = LoadSaveService
                break;
            case JobType.POLICY_CHANGE:
                service = EndorsementService
                payload = [payload];
                break;
            case JobType.RENEWAL:
                service = RenewalService
                break;
            default:
                break;
        }

        return service.saveWithNoValidationRuleCheck(
            payload,
            authHeader
        );
    }, [LoadSaveService, EndorsementService, RenewalService, jobType, jobVM, authHeader]);

    const onChangeSubmissionAndSync = useCallback(async (value, changedPath, lobPath, quotePath) => {
        const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
        const cov = _.get(jobVM, basePath);

        if (!value && [HOConstants.businessPersonalPropertyCovPublicID, HOConstants.homeBasedBusinessCoveragePublicID].includes(cov.publicID)) {
            switch (cov.publicID) {
                case HOConstants.businessPersonalPropertyCovPublicID:
                    dwellingVM.businessPropCovItems.value = [];
                    break;
                case HOConstants.homeBasedBusinessCoveragePublicID:
                    dwellingVM.homeBasedBusinessCovItems.value = [];
                    break;
                default:
                    break;
            }

            const result = await saveJob();

            _.extend(jobVM.value, result);
        }
        
        return changeSubmissionAndSync(value, changedPath, lobPath, quotePath);
    }, [changeSubmissionAndSync, dwellingVM.businessPropCovItems, dwellingVM.homeBasedBusinessCovItems, jobVM, saveJob]);

    const updateJobAndCoverage = useCallback(async (limit, path) => {
        setIsCoverageLoading(true);

        const result = await saveJob();

        _.extend(jobVM.value, result);

        changeSubmission(limit, path);
        await syncCoverages(limit, path, 'lobData.homeowners.offerings.children[0]', 'quoteData.offeredQuotes.children[0]');

        setIsCoverageLoading(false);
    }, [changeSubmission, jobVM, syncCoverages, saveJob])

    const getDwellingCoverageAccordions = () => {
        // only show these categories
        const categoryMap = ['includedCoverages', 'optionalPropertyCoverages', 'optionalLiabilityCoverages'];

        const categorizedCoverages = WMICHOCoverageUtil.categorizeDwellingCoverages(dwellingCoverages.value);
        const coverageAccordions = categoryMap.map((categoryKey) => {
            const dwellingCoverage = _.get(categorizedCoverages, categoryKey);
            const coverageTableData = [{
                data: dwellingCoverage.coverages,
                tableContent: generateClauseData()
            }]

            return (
                <WMICAccordionCard
                    title={dwellingCoverage.categoryName}
                    id={dwellingCoverage.categoryID}
                    isValid={isAccordionValid(`clauseTable-${dwellingCoverage.categoryID}`)}
                    showErrors={showErrors || showErrors}
                    chevron>
                    <QuoteClauseTable
                        id={`clauseTable-${dwellingCoverage.categoryID}`}
                        columnData={coverageColumnData}
                        tableData={coverageTableData}
                        quoteID={quoteID}
                        readOnly={readOnly}
                        onChangeSubmissionAndSync={onChangeSubmissionAndSync}
                        onChangeSubmission={changeSubmission}
                        onSyncCoverages={syncCoverages}
                        onRecalculate={recalculate}
                        onResetQuote={resetQuote}
                        onStaleQuoteBranchCode={onStaleQuoteBranchCode}
                        underwritingIssues={underwritingIssues}
                        filterUWIssuesInCustomOffering={false}
                        showTooltip={false}
                        getCustomTerms={getCustomTerms}
                        onValidate={onValidateAccordion}
                        showErrors={showErrors}
                    />
                </WMICAccordionCard>
            )
        });

        coverageAccordions.push(
            (<WMICHODwellingCovAdditionalInterests
                id="hoCovAdditionalInterests"
                dwelling={dwellingVM}
                draftData={baseData}
                isEditMode
                readOnly={readOnly}
                jobVM={jobVM}
                saveJob={saveJob}
            />)
        );

        return coverageAccordions;
    }

    const overrideProps = {
        '@field': {
            labelPosition: Position.LEFT,
            parentNode: dwellingVM
        },
        dwellingCoveragesAccordions: {
            content: getDwellingCoverageAccordions()
        }
    }

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <WMICCard className="gw-mb-4">
            <WMICAccordionCard
                id={`dwellingCoveragesAccordionCard${dwellingIndex}`}
                title={formatDwellingHeader()}
                chevron
                chevronAlignment='left'
                headerVariant='h2'
                headerSize='h2'
                className='gw-mb-0'
                cardBodyClassName='gw-mx-2'
            >
                <ViewModelForm
                    model={dwellingVM}
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    classNameMap={resolvers.resolveClassNameMap}
                    onValidationChange={onValidate}
                    showErrors={showErrors || showErrors}
                />
            </WMICAccordionCard>
        </WMICCard>
    );
}

export default WMICHODwellingCoverages;
