/* eslint-disable no-useless-return */
/* eslint-disable react/prop-types */
import React, {
    useContext,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalNext,
    CheckboxField,
    InputField,
    Container,
    Icon
} from '@jutro/components';
import cx from 'classnames';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { TranslatorContext } from '@jutro/locale';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { WMICRequiredText, WMICButton } from 'wmic-pe-components-platform-react';
import { PolicyService } from 'wmic-pe-capability-gateway-policy';
import { BillingService } from 'wmic-pe-capability-gateway-billing';
import { WMICLogger } from 'wmic-pe-portals-utils-js';
import messages from './WMICResendDocumentsModal.messages';
import styles from './WMICResendDocumentsModal.module.scss';

const WMICResendDocumentsModal = ({
    isOpen,
    onResolve,
    policyVM,
    authHeader,
    isBrokerOwned,
    isEDeliveryEnrolled,
    isEPOIEnrolled,
    productCode,
    viewModelService
}) => {
    const translator = useContext(TranslatorContext);
    const { isComponentValid, onValidate } = useValidation('WMICResendDocumentsModal');
    const isPolicyBillingSelectionsNotApplicable = isBrokerOwned || !isEDeliveryEnrolled;
    const initialCheckboxOptions = [
        {
            code: "policy",
            visible: true,
            selected: isPolicyBillingSelectionsNotApplicable,
            disabled: isPolicyBillingSelectionsNotApplicable,
            optedOut: isPolicyBillingSelectionsNotApplicable,
            name: messages.policyDocuments,
            subtitle: messages.optedOut,
        },
        {
            code: "billing",
            visible: true,
            selected: isPolicyBillingSelectionsNotApplicable,
            disabled: isPolicyBillingSelectionsNotApplicable,
            optedOut: isPolicyBillingSelectionsNotApplicable,
            name: messages.billingDocuments,
            subtitle: messages.optedOut,
        },
        {
            code: "epoi",
            visible: productCode === 'PersonalAuto',
            selected: false,
            disabled: !isEPOIEnrolled,
            optedOut: !isEPOIEnrolled,
            name: messages.epoi,
            subtitle: messages.optedOut,
        }
    ];

    const saveSelectedDocuments = (checkboxOpts) => {
        const selectedDocs = [];
        for (let i = 0; i < checkboxOpts.length ; ++i) {
            if (checkboxOpts[i].selected) {
                selectedDocs.push(checkboxOpts[i].code);
            }
        }
        return selectedDocs;
    };

    const [checkboxOptions, updateCheckboxOptions] = useState(initialCheckboxOptions);
    const [selectedDocuments, updateSelectedDocuments] = useState(saveSelectedDocuments(initialCheckboxOptions));
    const [policyVMcopy, updatePolicyVMcopy] = useState(policyVM);
    const [sendButtonDisabled, updateSendButtonDisabled] = useState(false);
    const [successMessageVisible, updateSuccessMessageVisible] = useState(false);
    const accountEmailAddress = _.get(policyVM, "account.accountHolder.emailAddress1.value");

    const handleClose = () => {
        onResolve();
    };

    const writeCheckboxValue = (value, path) => {
        const checboxOptionsCopy = [...checkboxOptions];
        checboxOptionsCopy[path].selected = value;
        updateCheckboxOptions(checboxOptionsCopy);
        updateSelectedDocuments(saveSelectedDocuments(checboxOptionsCopy))
    };    
    
    const writeValue = (value, path) => {
        const newPolicyVM = viewModelService.clone(policyVMcopy);
        _.set(newPolicyVM, path, value);
        updatePolicyVMcopy(newPolicyVM);
    };    
    
    const confirmEmailIsInvalid = () => {
        const email = _.get(policyVMcopy, 'accountEmailAddressProxy_WMIC.value');
        const confirmEmail = _.get(policyVMcopy, 'confirmAccountEmailAddress_WMIC.value');
        return email !== confirmEmail;
    };       
    
    const showConfirmEmailAddressField = () => {
        const accountEmailAddressProxy = _.get(policyVMcopy, 'accountEmailAddressProxy_WMIC.value');
        return accountEmailAddressProxy && accountEmailAddress !== accountEmailAddressProxy;
    };    
    
    const requestEPOICall = () => {
        const email = _.get(policyVMcopy, 'accountEmailAddressProxy_WMIC');

        if (email && email.aspects && (!email.aspects.valid || !email.value)) {
            return;
        }

        if(selectedDocuments.length === 0) {
            return;
        }
        updateSendButtonDisabled(true);
        const accountNumber = _.get(policyVM, 'account.accountNumber.value');
        const policyNumber = _.get(policyVM, "policyNumber.value");
        const promiseArray = [PolicyService.resendRequestedDocuments(policyNumber, email.value, selectedDocuments, authHeader)];
        const isBillingDocsRequested = selectedDocuments.some((doc) => doc === 'billing');
        if (isBillingDocsRequested) {
            const billingPromise = BillingService.resendBillingDocuments(accountNumber, policyNumber, email.value, authHeader);
            promiseArray.push(billingPromise);
        }
        Promise.all(promiseArray).then(() => {
            updateSendButtonDisabled(false);
            updateSuccessMessageVisible(true);
        }).catch((e) => {
            WMICLogger.error('Request EPOI failed', e);
        });;
      
    };

    const commonEmailInputProps = {
        labelPosition: "left",
        onValueChange: writeValue,
        required: true,
        showRequired: true,
        showError: true,
        className: cx('wmicMasterInputInputField'),
        labelClassName: cx('wmicMasterInputFieldLabel'),
        onValidationChange: onValidate
    };

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={handleClose}
        >
            <ModalHeader
                title={translator(messages.resendDocuments)}
            />
            <ModalBody>
                { !successMessageVisible &&
                    <div>
                        <p className={styles.selectDocumentsText}> { translator(messages.selectTheDocuments)} </p>

                        {checkboxOptions.map((option, index) => {
                            const fieldId = option.code;
                            if (option.visible) {
                                return (
                                    <div key={fieldId}>
                                        <CheckboxField
                                            id={`status${fieldId}`}
                                            value={option.selected}
                                            path={index}
                                            showInlineLabel
                                            label={translator(option.name)}
                                            showOptional={false}
                                            secondaryLabel={option.optedOut ? option.subtitle : ''}
                                            onValueChange={writeCheckboxValue}
                                            disabled={option.disabled}
                                            secondaryLabelClassName={styles.checkboxSecondaryLabel}
                                            className={styles.documentCheckbox}
                                        />
                                    </div>
                                );
                            }
                        })}

                        <Container message={messages.verifyAccountEmail} className={styles.wmicStatusContainerWrapper}>
                            <Container className={styles.wmicStatusContainer}>
                                <Icon icon="gw-info" className={styles.wmicStatusIcon}/>
                                <p>{translator(messages.verifyAccountEmail)}</p>
                            </Container>
                        </Container>
                    
                        <InputField
                            id="accountEmailAddressProxy"
                            label={messages.accountEmailAddress}
                            path="accountEmailAddressProxy_WMIC"
                            value={_.get(policyVMcopy, 'accountEmailAddressProxy_WMIC.value')}
                            validator={{
                                pattern: '[a-z0-9]+@[a-z]+\\.[a-z]{2,3}',
                                message: translator(messages.invalidEmail)
                            }}
                            {...commonEmailInputProps}
                        />
                        <InputField
                            id="confirmAccountEmailAddress"
                            label={messages.confirmEmailAddress}
                            path="confirmAccountEmailAddress_WMIC"
                            value={_.get(policyVMcopy, 'confirmAccountEmailAddress_WMIC.value')}
                            visible={showConfirmEmailAddressField()}
                            {...(confirmEmailIsInvalid() && {
                                validationMessages: [
                                    translator(messages.emailAddressDoesNotMatch)
                                ]
                            })}
                            {...commonEmailInputProps}
                        />
                    </div>
                }
                { successMessageVisible &&
                    <div>
                        <p className={styles.selectDocumentsText}> { translator(messages.documentsSent) } </p>
                    </div> 
                }

            </ModalBody>
            <ModalFooter>
                <div>
                    <WMICButton
                        onClick={handleClose}
                    >
                        {translator(commonMessages.close)}
                    </WMICButton>
                    {!successMessageVisible && 
                        <WMICButton
                            onClick={requestEPOICall}
                            type="primary"
                            disabled={!isComponentValid || sendButtonDisabled}
                            className={styles.sendButton}
                        >
                            {translator(messages.send)}
                        </WMICButton>
                    }

                </div>
            </ModalFooter>
        </ModalNext>
    );
};

WMICResendDocumentsModal.propTypes = {
    policyVM: PropTypes.shape({}).isRequired,
    authHeader: PropTypes.shape({}).isRequired,
};

export default WMICResendDocumentsModal;