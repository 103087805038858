import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { WMICJsonRPCService as JsonRPCService } from 'wmic-pe-portals-transport-js';

export default class PolicyService {   
    static getPolicyWithTermNumber(policyNumber, termNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getPolicyWithTermNumber', [policyNumber, termNumber], additionalHeaders);
    }
    
    static resendRequestedDocuments(policyNumber, accountEmail, selectedDocuments, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'resendRequestedDocuments', [policyNumber, accountEmail, selectedDocuments], additionalHeaders);
    }

    static getPolicyTerms(termNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicy'),
            'getPolicyTerms', [termNumber], additionalHeaders);
    }
}