import { defineMessages } from 'react-intl';

export default defineMessages({
    policyReselectVehicle: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.ReselectVehicle',
        defaultMessage: 'Return to <b>Vehicle Info</b> to reselect the vehicle. The VIN search has returned more than one vehicle.'
    },
    policyBusinessSegmentCannotBeUpdated: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.BusinessSegmentCannotBeUpdated',
        defaultMessage: 'Business segment cannot be updated at this time. Please try again later.'
    },
    policyBusinessSegment: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.vehicleBusinessSegment',
        defaultMessage: 'Business segment'
    },
    policyUsage: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.vehicleUsage',
        defaultMessage: 'Usage'
    },
    policyPrimaryUse: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.PrimaryUse',
        defaultMessage: 'Primary Use'
    },
    policyMileageUnit: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.MileageUnit',
        defaultMessage: 'Mileage Unit'
    },
    policyBusinessUse: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.BusinessUse',
        defaultMessage: 'Business Use %'
    },
    policyUsedForRideSharing: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.Is the vehicle used for ride-sharing',
        defaultMessage: 'Is the vehicle used for ride-sharing?'
    },
    policyTransportationNetworkCompany: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.Transportation Network Company',
        defaultMessage: 'Transportation Network Company'
    },
    policyOtherDescription: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.Other Description',
        defaultMessage: 'Other Description'
    },
    policyCommutingMileage: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.CommutingMileage',
        defaultMessage: 'Commuting Mileage'
    },
    policyAnualMileage: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.AnnualMileage',
        defaultMessage: 'Annual Mileage'
    },
    policyCurrentOdometerReading: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.CurrentOdometerReading',
        defaultMessage: 'Current Odometer Reading'
    },
    policyDateReadingTaken: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.DateReadingTaken',
        defaultMessage: 'Date Reading Taken'
    },
    policyUsedOutsideOfCanada: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.UsedOutsideOfCanada',
        defaultMessage: 'Is the vehicle used outside Canada?'
    },
    policyHowManyMonths: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.HowManyMonths',
        defaultMessage: 'How many months?'
    },
    policyDetails: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.Details',
        defaultMessage: 'Details'
    },
    policyUsedOutsideOfProvince: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.UsedOutsideOfProvince',
        defaultMessage: 'Is the vehicle used outside of Province?'
    },
    policyHowManyDaysPerYear: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.HowManyDaysPerYear',
        defaultMessage: 'How many days per year?'
    },
    policyReasons: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.Reasons',
        defaultMessage: 'Reasons'
    },
    policyDaytimeParkingLocation: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.DaytimeParkingLocation',
        defaultMessage: 'Daytime Parking Location'
    },
    policyOvernightParkingLocation: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.OvernightParkingLocation',
        defaultMessage: 'Overnight Parking Location'
    },
    policyGarageLocation: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.GarageLocation',
        defaultMessage: 'Garage Location'
    },
    policyWhereIsPrimaryLocation: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.WhereIsPrimaryLocation',
        defaultMessage: 'Where is the primary location for this vehicle?'
    },
    policyAddLocation: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.Add Location',
        defaultMessage: 'Add Location'
    },
    policyWhereVehicleRated: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.WhereVehicleRated',
        defaultMessage: 'Is this where the vehicle should be rated?'
    },
    policyEstimatedReturnDate: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.EstimatedReturnDate',
        defaultMessage: 'Estimated Date of Return'
    },
    policyRatedGarageLocation: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.RatedGarageLocation',
        defaultMessage: 'Rated Garage Location'
    },
    policyAddGarageLocation: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.Add Garage Location',
        defaultMessage: 'Add Location'
    },
    policyVehicleWinterTires: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.VehicleWinterTires',
        defaultMessage: 'Does this vehicle have winter tires?'
    },
    policyBillOfSaleDate: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.BillOfSaleDate',
        defaultMessage: 'Bill of Sale'
    },
    policyRisks: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.Risks',
        defaultMessage: 'Risks'
    },
    policyLeasedToOthers: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.RentedLeasedToOthers',
        defaultMessage: 'Is unit rented or leased to others?'
    },
    policyUsedForCommercialOrBusiness: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.UsedForCommercialOrBusiness',
        defaultMessage: 'Is unit used for commercial or business purposes?'
    },
    policyUsedAsPrincipalResidence: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleUsage.UsedAsPrincipalResidence',
        defaultMessage: 'Is unit used as a principal residence?'
    }
})