import { defineMessages } from 'react-intl';

export default defineMessages({
    landingSearch: {
        id: 'agent.views.policy-landing.Search',
        defaultMessage: 'Search'
    },
    buttontextForSearch: {
        id: 'agent.views.new-quote-account-search.Search',
        defaultMessage: 'Search'
    },
    clearButtonText: {
        id: 'agent.views.new-quote-account-search.Clear',
        defaultMessage: 'Clear'
    },
    placeholderForPolicyNumber: {
        id: 'agent.views.policy-landing.PolicyNumber',
        defaultMessage: 'Policy Number'
    },
    placeholderForSubmissionNumber: {
        id: 'agent.views.policy-landing.SubmissionNumber',
        defaultMessage: 'Submission Number'
    },
    placeholderFirstName: {
        id: 'agent.views.policy-landing.FirstName',
        defaultMessage: 'First Name'
    },
    placeholderLastName: {
        id: 'agent.views.policy-landing.LastName',
        defaultMessage: 'Last Name'
    },
    placeholderCompanyName: {
        id: 'agent.views.policy-landing.CompanyName',
        defaultMessage: 'Company Names'
    },
    nameHeader: {
        id: 'agent.directives.PolicySummary.Client Name',
        defaultMessage: 'NAME'
    },
    jobNumberHeader: {
        id: 'agent.directives.PolicySummary.Job Number',
        defaultMessage: 'SUBMISSION'
    },
    policyNumberHeader: {
        id: 'agent.directives.PolicySummary.Policy Number',
        defaultMessage: 'POLICY'
    },
    statusHeader: {
        id: 'agent.directives.PolicySummary.Status',
        defaultMessage: 'STATUS'
    },
    errorModalMessage: {
        id: 'agent.views.modal.Error.Data',
        defaultMessage: 'Error retrieving information, Please contact your Administrator'
    },
    autoMobileLink: {
        id: 'agent.views.policy-landing-menu-link.Personal Auto',
        defaultMessage: 'Automobile',
    },
    personalPropertyLink: {
        id: 'agent.views.policy-landing-menu-link.Home Owners',
        defaultMessage: 'Personal Property',
    },
    personalUmbrellaLink: {
        id: 'agent.views.policy-landing-menu-link.PersonalUmbrella',
        defaultMessage: 'Personal Umbrella',
    },
    newSubmissionLink: {
        id: 'agent.views.policy-landing-menu-link.New Submission',
        defaultMessage: 'New Submission',
    },
    searchLoader: {
        id: 'agent.views.modal.Loading',
        defaultMessage: 'Loading...'
    }
});
