import React, { useEffect, useMemo, useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames'
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { JURISDICTIONS, PAConstants, WMICRichTextUtil, WMICRPCUtil } from 'wmic-pe-portals-utils-js';
import { CheckboxField, Loader } from '@jutro/components';
import messages from './WMICDriverEndorsementsCheckbox.messages';

function WMICDriverEndorsementsCheckbox(props) {
    const {
        id,
        driverEndorsement,
        driver,
        selectedVehicleId,
        baseState,
        rateAsOfDate,
        paLobDataVM,
        handleChange,
        readOnly,
        hideUnchecked
    } = props;
    const translator = useContext(TranslatorContext);
    const [isLoading, setIsLoading] = useState(false);
    const [checked, setChecked] = useState();
    const [disabled, setDisabled] = useState();

    useEffect(() => {
        setChecked(getIsChecked());
        setDisabled(getIsDisabled());
    }, [selectedVehicleId]);

    const getConvictionLabel = useCallback(() => {
        if (baseState === JURISDICTIONS.ONTARIO) {
            return messages.minorConvictionWaiverON;
        }
        if (WMICRPCUtil.getIsRPCEffective(baseState, rateAsOfDate, '1582')) {
            return messages.minorConvictionWaiverMaritimes;
        } 
        return messages.minorConvictionWaiver;
    },[baseState, rateAsOfDate]);

    const getEndorsementLabel = useCallback((covId) => {
        let label;
        switch (covId) {
            case PAConstants.accidentWaiverCovPublicId:
            case PAConstants.accidentWaiverMtcCovPublicId:
                label = messages.accidentRatingWaiver;
                break;
            case PAConstants.reducAmtForNamedCov_28_PublicId:
                label = messages.reductionOfCoverage;
                break;
            case PAConstants.reducAmtForNamedCov_28A_PublicId:
                label = messages.excluded;
                break;
            case PAConstants.article6ExcludedDriver_WMIC_PublicId:
                label = messages.article6Excluded;
                break;
            case PAConstants.minorConvictionWaiver39bPublicId:
                label = getConvictionLabel();
                break;
            default:
                return null;
        }

        return WMICRichTextUtil.translateRichText(translator(label, {name: driver.displayName}));
    }, [getConvictionLabel, translator]);

    const getIsChecked = () => {
        if (driverEndorsement.publicID === PAConstants.accidentWaiverCovPublicId && 
            _.find(_.get(paLobDataVM, 'endorsements_Ext.accidentWaiverEndorsements_WMIC.value'), {driverPublicID: driver.publicID})) {
            return true;
        } else if (driverEndorsement.publicID === PAConstants.accidentWaiverMtcCovPublicId && 
            _.find(_.get(paLobDataVM, 'endorsements_Ext.accidentWaiverMtcEndorsements_WMIC.value'), {driverPublicID: driver.publicID})) {
            return true;
        } else if (driverEndorsement.publicID === PAConstants.minorConvictionWaiver39bPublicId && 
            _.find(_.get(paLobDataVM, 'endorsements_Ext.minorConvictionWaiverEndorsements_WMIC.value'), {driverPublicID: driver.publicID})) {
            return true;
        } else if (driverEndorsement.publicID === PAConstants.reducAmtForNamedCov_28A_PublicId &&
            _.find(_.get(paLobDataVM, 'endorsements_Ext.excludedDriverEndorsements_WMIC.value'), {driverPublicID: driver.publicID, vehiclePublicID: selectedVehicleId})) {
            return true;        
        } else if (driverEndorsement.publicID === PAConstants.reducAmtForNamedCov_28_PublicId &&
            _.find(_.get(paLobDataVM, 'endorsements_Ext.reductionDriverEndorsements_WMIC.value'), { driverPublicID: driver.publicID })) {
            return true;        
        } else if (driverEndorsement.publicID === PAConstants.article6ExcludedDriver_WMIC_PublicId &&
            _.find(_.get(paLobDataVM, 'endorsements_Ext.accidentWaiverMtcEndorsements_WMIC.value'), {driverPublicID: driver.publicID, vehiclePublicID: selectedVehicleId})) {
            return true;        
        }
        else {
            return false;
        }
    };

    const getIsDisabled = () => {
        if (driverEndorsement.publicID === PAConstants.reducAmtForNamedCov_28_PublicId &&
            _.find(_.get(paLobDataVM, 'endorsements_Ext.reductionDriverEndorsements_WMIC.value'), { driverPublicID: driver.publicID })) {
            return true
        }
    }

    return (
        <React.Fragment>
            {isLoading ? (
                <Loader />
            ) : hideUnchecked && !checked ? null : (
                <div className="gw-pb-4">
                    <CheckboxField
                        id={`${id}_checkbox`}
                        className={cx('wmicMasterInputCheckbox', 'gw-pl-5')}
                        labelClassName={cx('wmicMasterInputFieldLabel')}
                        label={getEndorsementLabel(driverEndorsement.publicID)}
                        showInlineLabel
                        onValueChange={(value, path) =>
                            handleChange(
                                value,
                                path,
                                driver,
                                setChecked,
                                setIsLoading
                            )
                        }
                        value={checked}
                        disabled={disabled}
                        path={`${driverEndorsement.publicID}`}
                        readOnly={readOnly}
                    />
                </div>
            )}
        </React.Fragment>
    );
}

WMICDriverEndorsementsCheckbox.propTypes = {
    id: PropTypes.string.isRequired,
    driverEndorsement: PropTypes.shape({}).isRequired,
    driver: PropTypes.shape({}).isRequired,
    selectedVehicleId: PropTypes.string,
    baseState: PropTypes.string.isRequired,
    rateAsOfDate: PropTypes.shape({}).isRequired,
    paLobDataVM: PropTypes.shape({}).isRequired,
    handleChange: PropTypes.func.isRequired,
};

export default WMICDriverEndorsementsCheckbox;
