import { defineMessages } from 'react-intl';

export default defineMessages({
    householdMemberTitle: {
        id: 'wmic.policy.householdMember.view.householdMemberTitle',
        defaultMessage: 'Household Member'
    },
    driverInformationTitle: {
        id: 'wmic.policy.householdMember.view.driverInformationTitle',
        defaultMessage: 'Driver Information'
    },
    legalHistoryTitle: {
        id: 'wmic.policy.householdMember.view.legalHistoryTitle',
        defaultMessage: 'Legal History'
    },
    memberFirstName: {
        id: 'wmic.policy.householdMember.view.memberFirstName',
        defaultMessage: 'First Name'
    },
    memberMiddleName: {
        id: 'wmic.policy.householdMember.view.memberMiddleName',
        defaultMessage: 'Middle Name'
    },
    memberLastName: {
        id: 'wmic.policy.householdMember.view.memberLastName',
        defaultMessage: 'Last Name'
    },
    memberDateOfBirth: {
        id: 'wmic.policy.householdMember.view.memberDateOfBirth',
        defaultMessage: 'Date of Birth'
    },
    householdMembersEstateOf: {
        id: 'wmic.policy.householdMember.view.Estate of',
        defaultMessage: 'Estate of'
    },
    householdMemberOccupation: {
        id: 'wmic.policy.householdMember.view.householdMemberOccupation',
        defaultMessage: 'Occupation'
    },
    householdMembersLast3Years: {
        id: 'wmic.policy.householdMember.view.Has the household member experienced any losses in the last 3 years',
        defaultMessage: 'Has the household member experienced any losses in the last 3 years?'
    },
    householdMembersRelationship: {
        id: 'wmic.policy.householdMember.view.Relationship',
        defaultMessage: 'Relationship to Primary Named Insured'
    },
    memberLicensedToDrive: {
        id: 'wmic.policy.householdMember.view.memberLicensedToDrive',
        defaultMessage: 'Is this household member licenced to drive vehicles?'
    },
    memberProvinceLicensedIssued: {
        id: 'wmic.policy.householdMember.view.memberProvinceLicensedIssued',
        defaultMessage: 'Province where license was issued'
    },
    memberLastMVRCapturedDate: {
        id: 'wmic.policy.householdMember.view.memberLastMVRCapturedDate',
        defaultMessage: 'Date Last MVR Captured'
    },
    memberExcludedDriver: {
        id: 'wmic.policy.householdMember.view.memberExcludedDriver',
        defaultMessage: 'Excluded Driver'
    },
    memberOtherDescription: {
        id: 'wmic.policy.householdMember.view.memberOtherDescription',
        defaultMessage: 'Describe Other'
    },
    memberHasLegalHistory: {
        id: 'wmic.policy.householdMember.view.memberHasLegalHistory',
        defaultMessage: 'Has the household member experienced any convictions, suspensions, prohibitions or restrictions?'
    },
    postalCode: {
        id: 'wmic.policy.householdMember.view.ZIP Code',
        defaultMessage: 'Postal Code'
    },
    incidentType: {
        id: 'wmic.policy.householdMember.view.memberIncident.incidentType',
        defaultMessage: 'Incident Type'
    },
    dateOcurred: {
        id: 'wmic.policy.householdMember.view.memberIncident.incidentDateOccured',
        defaultMessage: 'Date Occurred'
    },
    description: {
        id: 'wmic.policy.householdMember.view.memberIncident.incidentDescription',
        defaultMessage: 'Description'
    }

});