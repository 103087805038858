import React, { useContext, useState, useCallback, useEffect } from 'react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { CONSTANTS } from 'wmic-pe-portals-utils-js';
import PropTypes from 'prop-types';
import _ from 'lodash';

import metadata from './WMICContactDetails.metadata.json5';
import messages from './WMICContactDetails.messages';

const WMICContactDetails = (props) => {
    const {
        contactVM,
        setContactVM,
        onSave,
        onCancel,
        readOnly,
        hideBorder, 
        hideButtons,
        showContactInfo
    } = props;

    const viewModelService = useContext(ViewModelServiceContext);
    const { isComponentValid, onValidate } = useValidation('WMICContactDetails');
    const [showErrors, setShowErrors] = useState(false);
    const contactTypeValue = _.get(contactVM, 'subtype.value');
 
    const handleSaveContact = () => {
        onSave();
    }

    const handleCancelContact = () => {
        onCancel();
    }

    const handleValidation = () => {
        setShowErrors(true);
    }

    const writeValue = (value, path) => {
        _.set(contactVM, path, value);
        setContactVM(contactVM);
    }

    const overrideProps = {
        '@field': {
            parentNode: contactVM,
            readOnly
        },
        contactDetailsFormContainer: {
            hideBorder, 
            hideButtons
        },
        personInputs: {
            visible: contactTypeValue === CONSTANTS.Person
        },
        companyInputs: {
            visible: contactTypeValue === CONSTANTS.Company
        },
        personContactInfoContainer: {
            visible: showContactInfo
        },
        companyContactInfoContainer: {
            visible: showContactInfo
        },
        dateOfBirth: {
            visible: !_.isNil(_.get(contactVM, "dateOfBirth"))
        },        
        companyContactName: {
            visible: !_.isNil(_.get(contactVM, "contactName"))
        },
        companyName: {
            visible: !_.isNil(_.get(contactVM, "companyName"))
        }
    }

    const resolvers = {
        resolveComponentMap: { 
        },
        resolveCallbackMap: {
            onSaveContact: isComponentValid ? handleSaveContact : handleValidation,
            onCancelContact: handleCancelContact,
        },
        resolveClassNameMap: { }
    }

    return (
        <ViewModelForm
            model={contactVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
}

WMICContactDetails.propTypes = {
    contactVM: PropTypes.object.isRequired,
    setContactVM: PropTypes.func,
};

WMICContactDetails.defaultProps = {
    readOnly: false,
    hideBorder: true, 
    hideButtons: true,
    showContactInfo: true
}

export default WMICContactDetails;