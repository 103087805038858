/* eslint-disable no-secrets/no-secrets */
import { defineMessages } from 'react-intl';

export default defineMessages({
    newQuoteTitle: {
        id: 'agent.views.new-quote.New Quote: New Account Details',
        defaultMessage: 'New Submission: New Account Details'
    },
    accountHolder: {
        id: 'agent.views.new-quote.Account Holder',
        defaultMessage: 'Account Holder'
    },
    descOfBusiness: {
        id: 'agent.views.new-quote.Description of Business',
        defaultMessage: 'Description of business'
    },
    email: {
        id: 'agent.views.new-quote.Email Address',
        defaultMessage: 'Email address'
    },
    confirmEmail: {
        id: 'agent.views.new-quote.Confirm email address',
        defaultMessage: 'Confirm email address'
    },
    dob: {
        id: 'agent.views.new-quote.BirthDate',
        defaultMessage: 'Date of Birth'
    },
    estateOf: {
        id: 'agent.views.new-quote.Estate Of',
        defaultMessage: 'Estate of'
    },
    contact: {
        id: 'agent.views.new-quote.Contact',
        defaultMessage: 'Contact'
    },
    prefLang: {
        id: 'agent.views.new-quote.Preferred Language',
        defaultMessage: 'Preferred Language'
    },
    home: {
        id: 'agent.views.new-quote.Home Address',
        defaultMessage: 'Home Address'
    },
    broker: {
        id: 'agent.views.new-quote.Broker Info',
        defaultMessage: 'Broker'
    },
    availableCodes: {
        id: 'agent.views.new-quote.Available Broker Codes',
        defaultMessage: 'Available Broker Codes'
    },
    next: {
        id: 'agent.views.new-quote.Next',
        defaultMessage: 'Next'
    },
    phoneType: {
        id: 'agent.views.new-quote.Primary Phone Type',
        defaultMessage: 'Primary phone'
    },
    homePhone: {
        id: 'agent.directives.contactphone-details.Home Phone',
        defaultMessage: 'Home phone'
    },
    workPhone: {
        id: 'agent.directives.contactphone-details.Work Phone',
        defaultMessage: 'Work phone'
    },
    ext: {
        id: 'agent.directives.contactphone-details.Office Phone Extension',
        defaultMessage: 'Ext. (optional)'
    },
    cellPhone: {
        id: 'agent.directives.contactphone-details.Cell Phone',
        defaultMessage: 'Mobile phone'
    },
    officePhone: {
        id: 'agent.directives.contactphone-details.Office Phone',
        defaultMessage: 'Office phone'
    },
    country: {
        id: 'agent.directives.contactphone-details.Country',
        defaultMessage: 'Country'
    },
    brokerCode: {
        id: 'agent.views.new-quote.Broker Code',
        defaultMessage: 'Broker code'
    },
    brokerName: {
        id: 'agent.views.new-quote.Broker Name',
        defaultMessage: 'Broker name'
    },
    branch: {
        id: 'agent.views.new-quote.Wawanesa Branch',
        defaultMessage: 'Wawanesa Branch'
    },
    region: {
        id: 'agent.views.new-quote.Wawanesa Region',
        defaultMessage: 'Wawanesa Region'
    },
    busDesc: {
        id: 'agent.views.new-quote.Description of Business',
        defaultMessage: 'Description of business'
    },
    compName: {
        id: 'agent.views.new-quote.Company Name',
        defaultMessage: 'Company Name'
    },
    busAddr: {
        id: 'agent.views.new-quote.Business Address',
        defaultMessage: 'Business Address'
    },
    middleName: {
        id: 'agent.views.new-quote.middleName',
        defaultMessage: 'Middle Name'
    },
    newAccount: {
        id: 'agent.views.new-quote.newAccount',
        defaultMessage: 'New Account'
    },
    searchAgain: {
        id: 'agent.views.new-quote.search-again',
        defaultMessage: 'Search again'
    },
    alerts: {
        id: 'wmic-pe-capability-gateway-react.WMICNewQuotePage.Alerts',
        defaultMessage: "Alerts"
    },
    jurisdictionError: {
        id: 'wmic-pe-capability-gateway-react.WMICNewQuotePage.jurisdictionError',
        defaultMessage: "{productCode} policies are not yet available in your jurisdiction."
    },
    ok: {
        id: 'wmic-pe-capability-gateway-react.WMICNewQuotePage.Ok',
        defaultMessage: "Ok"
    },
    noSearchResultsMsg: {
        id: 'agent.views.new-quote.noSearchResultsMsg',
        defaultMessage: 'No results found. Please create a new account or search again.'
    },
});
