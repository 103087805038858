import React, { useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { Flex, FlexItem } from '@jutro/layout';
import { Icon } from '@jutro/components';
import { WMICRichTextUtil } from 'wmic-pe-portals-utils-js';
import cx from 'classnames';
import messages from './RenewalWarning.messages';
import styles from './RenewalWarning.module.scss';

const RenewalWarning = () => {
    const translator = useContext(TranslatorContext);

    return (
        <Flex
            gap="none"
            alignItems="center"
            className={styles.warningContainer}
        >
            <FlexItem className={cx(styles.warningIconContainer, 'gw-mr-1')}>
                <Icon icon="gw-error" className={styles.warningIcon} />
            </FlexItem>
            <FlexItem>
                <div>
                    {WMICRichTextUtil.translateRichText(
                        translator(messages.renewalWarning)
                    )}
                </div>
            </FlexItem>
        </Flex>
    );
};

export default RenewalWarning;
