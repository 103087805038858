/* eslint-disable padding-line-between-statements */
/* eslint-disable no-restricted-syntax */
import { Augment } from '@xengage/gw-portals-viewmodel-utils-js';
import _ from "lodash";
import { LOADING } from "wmic-pe-portals-utils-js";

const METADATA_CLASS = 'edge.aspects.validation.dto.VisibilityAndRequirednessRuleDTO';
/**
 * Creates a new "Field is required" aspect.
 * @param {Object} expressionLanguage
 * @returns {Object}
 */
function create(expressionLanguage) {
    function getAllRules(compilationContext, node, nodeMetadata, ancestorChain) {
        return Augment.collectRules(compilationContext, node, nodeMetadata, ancestorChain, METADATA_CLASS);
    }
    function compileRule(compilationContext, rule) {
        const visibilityExpr = compilationContext.compile(rule.data.expression);
        const messageExpr = compilationContext.compile(rule.data.message);
        return {
            shouldApply: rule.shouldApply,
            status: visibilityExpr,
            message: messageExpr
        };
    }
    function getRadiantData(currentViewModelNode) {
        const parentNode = currentViewModelNode._parent;
        const lowercasePath = _.toLower(currentViewModelNode._accessorCode)
        const radiantData = parentNode && parentNode.value.metaDataMap ? parentNode.value.metaDataMap.find((item) => _.toLower(item.propName) === lowercasePath) : undefined;
        return radiantData && radiantData.hasOwnProperty('isRequired') ? radiantData : undefined;
    }
    function isRadiantVisible(currentViewModelNode) {
        const radiantData = getRadiantData(currentViewModelNode);
        /* if no radiant data found for this field, default to visible. */
        return radiantData && radiantData.isVisible !== undefined ?
            // eslint-disable-next-line no-bitwise
            (LOADING.ACTIVE | LOADING.ON) === parseInt(radiantData.isVisible, 2) : true;
    }
    function isRadiantRequired(currentViewModelNode) {
        const radiantData = getRadiantData(currentViewModelNode);
        /* if no radiant data found for this field, default to not required. */
        return radiantData && radiantData.isRequired !== undefined ?
            // eslint-disable-next-line no-bitwise
            (LOADING.ACTIVE | LOADING.ON) === parseInt(radiantData.isRequired, 2) : false;
    }
    function combineRules(rules) {
        return {
            visible: (v, pv, ctx) => {
                for (const rule of rules) {
                    // console.log(`Visible for ${v._accessorCode} should Apply ${rule.shouldApply()} and status ${rule.status(v, pv, ctx)}`)
                    if (rule.shouldApply() && rule.status(v, pv, ctx) === 'NOT_SET') {
                        return false;
                    }
                }
                return true;
            },
            required: (v, pv, ctx) => {
                if (rules === undefined || rules.length === 0) {
                    return undefined;
                }
                for (const rule of rules) {
                    if (rule.shouldApply() && rule.status(v, pv, ctx) === 'REQUIRED') {
                        return true;
                    }
                }
                return false;
            },
            requirednessMessages: (v, pv, ctx) => {
                const messages = [];
                for (const rule of rules) {
                    if (rule.shouldApply() && rule.status(v, pv, ctx) === 'REQUIRED' && rule.message(v, pv, ctx)) {
                        messages.push(rule.message(v, pv, ctx));
                    }
                }
                return messages;
            },
            anyMessages: (v, pv, ctx) => {
                const messages = [];
                for (const rule of rules) {
                    messages.push(rule.message(v, pv, ctx));
                }
                return messages;
            }
        };
    }
    return {
        getAspectProperties: (currentViewModelNode, currentMetadataNode, ancestorChain) => {
            const compilationContext = expressionLanguage.getCompilationContext(currentMetadataNode.xCenter);
            const rules = getAllRules(compilationContext, currentViewModelNode, currentMetadataNode, ancestorChain);
            const compiledRules = new Array(rules.length);
            for (let i = 0; i < rules.length; i++) {
                compiledRules[i] = compileRule(compilationContext, rules[i]);
            }
            const descriptor = combineRules(compiledRules);
            const isRequired = () => {
                const aspect = descriptor.required(
                    currentViewModelNode,
                    ancestorChain.parent,
                    currentViewModelNode.aspects.context
                );
                return getRadiantData(currentViewModelNode) !==  undefined
                    ? isRadiantRequired(currentViewModelNode) && (aspect === undefined || aspect)
                    : aspect;
            };

            return {
                visible: {
                    get: () => {
                        return isRadiantVisible(currentViewModelNode) && descriptor.visible(
                            currentViewModelNode,
                            ancestorChain.parent,
                            currentViewModelNode.aspects.context);
                    }
                },
                required: {
                    get: isRequired
                },
                _requirednessMessages: {
                    get: () => {
                        return isRequired() ? descriptor.requirednessMessages(
                            currentViewModelNode,
                            ancestorChain.parent,
                            currentViewModelNode.aspects.context) : [];
                    }
                },
                anyMessages: {
                    get: () => {
                        return descriptor.anyMessages(
                            currentViewModelNode,
                            ancestorChain.parent,
                            currentViewModelNode.aspects.context);
                    }
                }
            };
        }
    };
}
export default {
    create
};
