import React, { useContext} from 'react';
import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { WMICCard, WMICIcon } from 'wmic-pe-components-platform-react'
import { BreakpointTrackerContext, Flex } from '@jutro/layout';
import { Accordion, AccordionCard, StepProgressBar } from '@jutro/components';
import WMICPolicyMenuBar from './WMICPolicyMenuBar/WMICPolicyMenuBar'

import styles from './WMICPolicyWizardSidebar.module.scss';
import messages from './WMICPolicyWizardSidebar.messages'

function jumpRenderModal(index, jumpTo, steps, step, wizardContext, customMessageModal) {
    // const { wizardData, wizardSnapshot, onPageJump } = wizardContext;
    // const modalMessages = {
    //     title: !_.isEmpty(customMessageModal)
    //         ? customMessageModal.title
    //         : commonMessages.wantToJump,
    //     message: !_.isEmpty(customMessageModal)
    //         ? customMessageModal.message
    //         : commonMessages.wantToJumpMessage,
    //     messageProps: {
    //         ok: commonMessages.yes,
    //         close: commonMessages.close
    //     }
    // };
    // onPageJump({
    //     wizardData,
    //     wizardSnapshot,
    //     modalMessages,
    //     index
    // });
    jumpTo(index);
}

function renderSteps(
    translator,
    steps,
    currentStepIndex,
    stepsWithErrors,
    jumpTo,
    wizardContext,
    customMessageModal
) {
    return (
        <WMICPolicyMenuBar
            steps={modifySteps(steps, currentStepIndex, stepsWithErrors, jumpTo, wizardContext, customMessageModal, translator)}
        />
    );
}

const modifySteps = (steps, currentStepIndex, stepsWithErrors, jumpTo, wizardContext, customMessageModal, translator) => {
    steps.forEach((step, index) => {
        step.visited = true;
        step.submitted = true;
        step.disabled = false;
        step.active = index === currentStepIndex;
        step.onClick = () => {
            !step.active &&
                jumpRenderModal(
                    index,
                    jumpTo,
                    steps,
                    step,
                    wizardContext,
                    customMessageModal
                )
        };
    })
    return steps
}


function WMICPolicyWizardSidebar(props) {
    const wizardContext = useContext(WizardContext);
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const isTablet = ['phone', 'phoneWide', 'tablet'].includes(breakpoint)

    const {
        steps, currentStepIndex, jumpTo, stepsWithErrors, wizardTitle, currentStep, wizardData: policyVM
    } = wizardContext;

    const getTitle = (isOpen) =>{
        return (
            <Flex alignItems="middle">
                <WMICIcon
                    icon={isOpen ? "mi-close" : "mi-menu"}
                    id="accordionTitleIcon"
                    size="large"
                    className={cx("gw-mr-2", styles.accordionTitleIcon)}
                />
                <span> { translator(messages.tabletAccordionTitle, { pageTitle : translator(currentStep.title)})} </span>
            </Flex>
        );
    }


    const { customMessageModal } = props;
    return isTablet ? (
        <WMICCard className={cx("gw-mb-5", "gw-p-0")}>
            <Accordion
                id="accordion"
                showFrame={false}>
                <AccordionCard
                    cardBodyClassName={styles.accordionCardTablet}
                    id='wizardTabletAccordionCard'
                    renderHeader={(isOpen) => {
                        return getTitle(isOpen);
                    }}
                    chevron={false}
                >
                    <div role="navigation" aria-label={translator(wizardTitle)}>
                        <ul className={styles.stepsNav}>
                            {renderSteps(
                                translator,
                                steps,
                                currentStepIndex,
                                stepsWithErrors,
                                jumpTo,
                                wizardContext,
                                customMessageModal
                            )}
                        </ul>
                    </div>
                </AccordionCard>
            </Accordion>
        </WMICCard>
    ) : (
        <div className={styles.wizardSidebarWrapper}>
            <h2 className={styles.sideBarTitle}>{translator(messages.policyInformation)}</h2>
            <div role="navigation" aria-label={translator(wizardTitle)}>
                <ul className={styles.stepsNav}>

                    {renderSteps(
                        translator,
                        steps,
                        currentStepIndex,
                        stepsWithErrors,
                        jumpTo,
                        wizardContext,
                        customMessageModal
                    )}
                </ul>
            </div>
        </div>
    );
}

WMICPolicyWizardSidebar.propTypes = {
    customMessageModal: PropTypes.shape({})
};
WMICPolicyWizardSidebar.defaultProps = {
    customMessageModal: {}
};
export default WMICPolicyWizardSidebar;
