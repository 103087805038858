import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { PAConstants, CONSTANTS } from 'wmic-pe-portals-utils-js';
import WMICPACoverageUtil from "wmic-pe-capability-gateway-common-pa-react/util/WMICPACoverageUtil";
import WMICPolicyDriverEndorsementsCheckbox from './WMICPolicyDriverEndorsementCheckbox/WMICPolicyDriverEndorsementCheckbox';

function WMICPolicyDriverEndorsementComponent(props) {
    const {
        id,
        policyVM,
        driverEndorsement,
        baseState,
        rateAsOfDate,
        selectedVehicleId
    } = props;

    const paLobDataVM = _.get(policyVM, 'lobs.personalAuto');
    const policyDrivers = _.get(policyVM, 'lobs.personalAuto.driverDTOs.value');

    const drivers = useMemo(() => {
        switch (driverEndorsement.publicID) {
            case PAConstants.article6ExcludedDriver_WMIC_PublicId:
                return policyDrivers.filter((policyDriver) => policyDriver.assignment_WMIC === CONSTANTS.ASSIGNMENT.RESTRICTED);
            case PAConstants.reducAmtForNamedCov_28A_PublicId:
                return policyDrivers.filter((policyDriver) => WMICPACoverageUtil.isAssignmentExcludedOrAssigned(policyDriver));
            case PAConstants.reducAmtForNamedCov_28_PublicId:
                return policyDrivers.filter((policyDriver) => WMICPACoverageUtil.isAssignmentRestrictedOrAssigned(policyDriver));
            default:
                return policyDrivers;
        }
    }, [driverEndorsement, policyDrivers]);

    return (
        <>
            {drivers && drivers.map((driver) => {
                return (
                    <WMICPolicyDriverEndorsementsCheckbox 
                        id={id}
                        driverEndorsement={driverEndorsement}
                        driver={driver}
                        baseState={baseState}
                        rateAsOfDate={rateAsOfDate}
                        selectedVehicleId={selectedVehicleId}
                        paLobDataVM={paLobDataVM}
                    />
                )
            })}
        </>
    );
}

WMICPolicyDriverEndorsementComponent.propTypes = {
    id: PropTypes.string.isRequired,
    jobVM: PropTypes.shape({}).isRequired,
    driverEndorsement: PropTypes.shape({}).isRequired,
    baseState: PropTypes.shape({}).isRequired,
    rateAsOfDate: PropTypes.shape({}).isRequired,
    selectedVehicleId: PropTypes.string
};

export default WMICPolicyDriverEndorsementComponent;