/* eslint-disable no-secrets/no-secrets */
import React, {
    useEffect,
    useState,
    useContext,
    useMemo,
    useCallback
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { WMICVehicleService, PAConstants, WMICRichTextUtil, CONSTANTS } from 'wmic-pe-portals-utils-js';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICVehicleAddDriverComponent } from 'wmic-pe-capability-gateway-common-pa-react';
import { TranslatorContext } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { EntityUtil } from '@xengage/gw-portals-util-js'
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';

import metadata from './WMICVehicleOwnershipAndAssignmentComponent.metadata.json5';
import messages from './WMICVehicleOwnershipAndAssignmentComponent.messages';
import styles from './WMICVehicleOwnershipAndAssignmentComponent.module.scss';

function WMICVehicleOwnershipAndAssignmentComponent(props) {
    const {
        id,
        assignedDrivers,
        vehicleVM,
        onValidate,
        updateVehicle,
        showErrors,
        isEditMode,
        isReadOnlyUser,
        primaryNamedInsured,
        additionalNamedInsureds
    } = props;

    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);

    const [editingDriverIndex, updateEditingDriverIndex] = useState(-1);
    const [editingRegOwnerIndex, updateEditingRegOwnerIndex] = useState(-1);
    const [isEditDriverMode, setEditingDriverMode] = useState(false);
    const [availableDrivers, setAvailableDrivers] = useState([]);
    const [availableDriverTypes, setAvailableDriverTypes] = useState([]);
    const [addingDriver, setAddingDriver] = useState(false);
    const [isPrincipalDriverPresent, setIsPrincipalDriverPresent] = useState();
    const [isRegisteredOwnerPresent, setIsRegisteredOwnerPresent] = useState();
    const { showConfirm } = useWizardModals();

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    useEffect(() => {
        const allDrivers = _.get(vehicleVM, 'drivers.children');
        setIsPrincipalDriverPresent(allDrivers.some((driver) => driver.driverType_WMIC?.value?.code === CONSTANTS.DRIVER_TYPE_WMIC.PRINCIPAL));

        const registeredOwners = _.get(vehicleVM, 'registeredOwners_WMIC.children', []);
        setIsRegisteredOwnerPresent(registeredOwners.length > 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_.get(vehicleVM, 'drivers.children'), _.get(vehicleVM, 'registeredOwners_WMIC.children'), isEditDriverMode]);

    const typelistMapper = useCallback((typecode) => ({
            code: typecode.code,
            name: translator({
                id: typecode.name,
                defaultMessage: typecode.name
            })
        }), [translator]);

    const ownershipTypeAvailableTypes = _.get(vehicleVM, 'registeredOwnerType_WMIC.aspects.availableValues', [])
        .filter((item) => item.code !== PAConstants.dataMigrationNull_WMIC)
        .map(typelistMapper);

    const vehicleType = _.get(vehicleVM, 'vehicleType.value.code');
    useEffect(() => {
        if (onValidate) {
            if (vehicleType === PAConstants.trailerVehicleType) {
                onValidate(isComponentValid && isRegisteredOwnerPresent, id);
            } else {
                onValidate(isComponentValid && isPrincipalDriverPresent && isRegisteredOwnerPresent, id);
            }
        }
    }, [isComponentValid, onValidate, isPrincipalDriverPresent, isRegisteredOwnerPresent, vehicleType, id]);

    useEffect(() => {
        refreshAvailableDrivers();
    }, [refreshAvailableDrivers, vehicleVM]);

    const refreshAvailableDrivers = useCallback(() => {
        const drivers = [];
        const driversAlreadyAdded = _.get(vehicleVM, 'drivers.value');
        _.each(assignedDrivers, (driver) => {
            if (!WMICVehicleService.driverAlreadyAdded(driver, driversAlreadyAdded)) {
                drivers.push({
                    code: driver.publicID,
                    name: driver.displayName
                });
            }
        });
        setAvailableDrivers(drivers);
    }, [assignedDrivers, vehicleVM]);

    const isCommercialVehicle = () => {
        const businessSegment = _.get(vehicleVM, 'businessSegment_WMIC.value');

        return businessSegment?.code === PAConstants.vehicleBusinessSegmentCommercial;
    };

    const hasPrincipalDriver = () => {
        const drivers = _.get(vehicleVM, 'drivers.value');
        if (drivers) {
            return drivers.some((driver) =>
                driver.driverType_WMIC === PAConstants.principalDriverType
            );
        }
        return false;
    };

    const refreshAvailableDriverTypes = (driverToEdit) => {
        const principalTypeCode = PAConstants.principalDriverType;
        const isEditingPrincipalDriver = driverToEdit && driverToEdit.driverType_WMIC === principalTypeCode;
        let includePrincipalDriver = true;
        const drivers = _.get(vehicleVM, 'drivers.value');
        if (!isEditingPrincipalDriver) {
            _.each(drivers, (driver) => {
                if (driver.driverType_WMIC === principalTypeCode) {
                    includePrincipalDriver = false;
                }
            });
        }

        const availableTypes = [];
        _.each(vehicleVM.driverType_WMIC.aspects.availableValues, (availableValue) => {
            if (includePrincipalDriver || availableValue.code !== principalTypeCode) {
                availableTypes.push({
                    code: availableValue.code,
                    name: translator({
                        id: availableValue.name,
                        defaultMessage: availableValue.name
                    })
                });
            }
        });
        setAvailableDriverTypes(availableTypes)
    };

    const removeRegistredOwner = (registeredOwnerToRemove, index) => {
        const registeredOwners = _.get(vehicleVM, 'registeredOwners_WMIC.value');
        registeredOwners.splice(index, 1);

        updateVehicle(vehicleVM);
    };

    const addRegisteredOwner = useCallback((namedInsured) => {
        const newRegisteredOwner = viewModelService.create(
            {},
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.dto.VehicleRegOwnerDTO_WMIC',
            vehicleVM.aspects.context()
        );
        let driver;
        let relationshipToPNI;
        if (!_.get(vehicleVM, 'registeredOwners_WMIC.value')) {
            _.set(vehicleVM, 'registeredOwners_WMIC.value', []);
        }

        newRegisteredOwner.tempID = _.get(vehicleVM, 'registeredOwners_WMIC.value').length;
        newRegisteredOwner.policyContactPublicID.value =
            namedInsured.contactPublicID ? namedInsured.contactPublicID.value : namedInsured.publicID.value;

        newRegisteredOwner.contactName.value = namedInsured.displayName.value;
        newRegisteredOwner.contactIsDriver.value = namedInsured.isDriver;

        if (newRegisteredOwner.policyContactPublicID && newRegisteredOwner.policyContactPublicID.value) {
            driver = assignedDrivers.find((currDriver) => currDriver.person.publicID === newRegisteredOwner.policyContactPublicID.value);
        }

        if (driver && driver.priInsuredRelation_wmic) {
            newRegisteredOwner.priInsuredRelation_wmic.value = driver.priInsuredRelation_wmic;
        } else {
            relationshipToPNI = namedInsured.relationshipToPrimaryInsured_WMIC.value ?
                namedInsured.relationshipToPrimaryInsured_WMIC.value.code : namedInsured.relationshipToPrimaryInsured_WMIC;
            newRegisteredOwner.priInsuredRelation_wmic.value = relationshipToPNI;
        }

        newRegisteredOwner.priInsuredRelationName_wmic.value = translator({ id: `${newRegisteredOwner.priInsuredRelation_wmic.value.name}` });
        _.get(vehicleVM, 'registeredOwners_WMIC.value').push(newRegisteredOwner.value);
        updateVehicle(vehicleVM)
    }, [assignedDrivers, translator, updateVehicle, vehicleVM, viewModelService]);

    const editDriver = (driverToEdit, driverIndex) => {
        const drivers = _.get(vehicleVM, 'drivers.value');
        if (driverToEdit && drivers) {
            refreshAvailableDrivers();
            refreshAvailableDriverTypes(driverToEdit.value);
            let isContainToken = false;
            const selectedDriver = _.find(assignedDrivers, { publicID: driverToEdit.driverPublicID_WMIC.value });
            updateEditingDriverIndex(driverIndex);
            setEditingDriverMode(true);

            availableDrivers.forEach((tmpToken) => {
                if (tmpToken.publicID === selectedDriver.publicID) {
                    isContainToken = true;
                }
            });

            if (!isContainToken) {
                setAvailableDrivers((arr) => [...arr, {
                    code: selectedDriver.publicID,
                    name: selectedDriver.displayName
                }]);
            }

            _.set(vehicleVM, 'selectedDriverPerVeh_WMIC.value', selectedDriver.publicID);
            _.set(vehicleVM, 'percentUsed_WMIC.value', driverToEdit.percentUsed_WMIC.value);
            _.set(vehicleVM, 'driverType_WMIC.value', driverToEdit.driverType_WMIC.value);
            setAddingDriver(true);
            _.set(vehicleVM, 'isAddingDriver.value', true);
            updateVehicle(vehicleVM);
        }
    }

    const removeDriver = async (driverToRemove, index) => {
        const response = await showConfirm({
            title: messages.removeDriverTitle,
            message: translator(messages.removeDriverMsg, { driver: _.get(driverToRemove, 'driverName_WMIC.value') })
        })

        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            const drivers = _.get(vehicleVM, 'drivers.value');
            drivers.splice(index, 1);

            refreshAvailableDrivers();
            updateVehicle(vehicleVM);
        }
    }

    const resetDriverForm = () => {
        _.set(vehicleVM, `selectedDriverPerVeh_WMIC.value`, undefined);
        _.set(vehicleVM, `driverType_WMIC.value`, undefined);
        _.set(vehicleVM, `percentUsed_WMIC.value`, undefined);

        updateVehicle(vehicleVM);
    }

    const addDriver = () => {
        setAddingDriver(true);
        _.set(vehicleVM, 'isAddingDriver.value', true);
        refreshAvailableDrivers();
        refreshAvailableDriverTypes();
        resetDriverForm();
    }

    const saveDriver = () => {
        const drivers = _.get(vehicleVM, 'drivers.value', []);
        const percentUsed = _.get(vehicleVM, 'percentUsed_WMIC.value');
        const driverType = _.get(vehicleVM, 'driverType_WMIC.value.code');
        const driverTypeName = _.get(vehicleVM, 'driverType_WMIC.value.name');
        const selectedDriverPerVeh = _.get(vehicleVM, 'selectedDriverPerVeh_WMIC');

        const selectedDriver = _.find(assignedDrivers, { publicID: selectedDriverPerVeh.value });

        if (editingDriverIndex !== -1 && isEditDriverMode) {
            _.set(vehicleVM, `drivers.value[${editingDriverIndex}].percentUsed_WMIC`, percentUsed);
            _.set(vehicleVM, `drivers.value[${editingDriverIndex}].driverType_WMIC`, driverType);
            _.set(vehicleVM, `drivers.value[${editingDriverIndex}].driverTypeName_WMIC`, driverTypeName);
            _.set(vehicleVM, `drivers.value[${editingDriverIndex}].driverPublicID_WMIC`, selectedDriver?.publicID);
            _.set(vehicleVM, `drivers.value[${editingDriverIndex}].driverName_WMIC`, selectedDriver?.displayName);
        } else {
            const newDriver = {
                tempID: EntityUtil.nextId()
            };

            newDriver.percentUsed_WMIC = percentUsed;
            newDriver.driverType_WMIC = driverType;
            newDriver.driverTypeName_WMIC = driverTypeName;
            newDriver.driverName_WMIC = selectedDriver?.displayName;
            newDriver.driverPublicID_WMIC = selectedDriver?.publicID;
            drivers.push(newDriver);
            _.set(vehicleVM, 'drivers.value', drivers);
        }
        setAddingDriver(false);
        _.set(vehicleVM, 'isAddingDriver.value', false);

        refreshAvailableDrivers();
        updateEditingDriverIndex(-1);
        setEditingDriverMode(false);
        updateVehicle(vehicleVM);
    }

    const cancelDriver = () => {
        setAddingDriver(false);
        _.set(vehicleVM, 'isAddingDriver.value', false);
        setEditingDriverMode(false);
        updateEditingDriverIndex(-1);
        refreshAvailableDrivers();
        updateVehicle(vehicleVM);
    }

    const registeredOwnerAlreadyAdded = useCallback((namedInsured) => {
        let result = false;
        const registeredOwners = _.get(vehicleVM, 'registeredOwners_WMIC.value')
        const policyContactPublicID = _.get(namedInsured, 'contactPublicID') ?
            _.get(namedInsured, 'contactPublicID.value') : _.get(namedInsured, 'publicID.value');
        if (registeredOwners && registeredOwners.length > 0) {
            result = registeredOwners.some((registeredOwner) => {
                const regOwnerPublicID = registeredOwner.contactPublicID ?
                    registeredOwner.contactPublicID : registeredOwner.policyContactPublicID;
                return regOwnerPublicID === policyContactPublicID;
            });
        }

        return result;
    }, [vehicleVM]);

    const vehicleOverrides = useMemo(() => {
        let overrides = [];
        overrides = additionalNamedInsureds.map((driver, index) => ({
                [`dropdownLink${index}`]: {
                    onClick: () => addRegisteredOwner(driver),
                    content: translator(messages.ANI, { insured: driver.displayName.value }),
                    visible: !registeredOwnerAlreadyAdded(driver)
                }
            }));
        return Object.assign({}, ...overrides);
    }, [additionalNamedInsureds, translator, registeredOwnerAlreadyAdded, addRegisteredOwner]);

    const overrideProps = {
        '@field': {
            parentNode: vehicleVM,
            readOnly: !isEditMode || isReadOnlyUser
        },
        ownershipType: {
            availableValues: ownershipTypeAvailableTypes
        },
        registredOwnersDataList: {
            VMList: _.get(vehicleVM, 'registeredOwners_WMIC.children', []),
            VMData: [
                {
                    headerText: translator(messages.registredOwnerName),
                    path: 'contactName'
                },
                {
                    headerText: translator(messages.relationshipToInsured),
                    path: 'priInsuredRelationName_wmic'
                }
            ],
            onRemoveAction: removeRegistredOwner,
            updateSelectedCardIndex: updateEditingRegOwnerIndex,
            selectedCardIndex: editingRegOwnerIndex,
            isEditing: !isEditMode,
            readOnly: isReadOnlyUser || !isEditMode,
        },
        registredOwnersInfoMsgContainer: {
            visible: _.get(vehicleVM, 'registeredOwners_WMIC.value', []).length === 0
        },
        registredOwnersInfoMsg: {
            content: WMICRichTextUtil.translateRichText(translator(messages.registredOwnersInfoMsg)),
        },
        commercialDriverWarningMsgContainer: {
            visible: isCommercialVehicle() && isEditMode
        },
        commercialDriverWarningMsg: {
            content: WMICRichTextUtil.translateRichText(translator(messages.commercialDriverWarningMsg))
        },
        driversInfoMsgContainer: {
            visible: !hasPrincipalDriver()
        },
        driversInfoMsg: {
            content: WMICRichTextUtil.translateRichText(translator(messages.driversInfoMsg))
        },
        registredOwnersSectionContainer: {
            className: _.get(vehicleVM, 'vehicleType.value.code') === PAConstants.trailerVehicleType ? styles.registeredOwnerDropdown : 'gw-mb-4'
        },
        addRegistredOwner: {
            disabled: _.get(vehicleVM, 'registeredOwners_WMIC.value', []).length === additionalNamedInsureds.length + 1,
            visible: !isReadOnlyUser && isEditMode
        },
        addRegistredOwnerPIDropdownLink: {
            content: translator(messages.PNI, { insured: _.get(primaryNamedInsured, 'displayName.value') }),
            onClick: () => {
                addRegisteredOwner(primaryNamedInsured)
            },
            visible: !registeredOwnerAlreadyAdded(primaryNamedInsured)
        },
        addRegistredOwnerAIDropdownLink: {
            data: additionalNamedInsureds
        },
        driversDataList: {
            VMList: _.get(vehicleVM, 'drivers.children', []),
            VMData: [
                {
                    headerText: translator(messages.driver),
                    path: 'driverName_WMIC'
                },
                {
                    headerText: translator(messages.percentOfUse),
                    path: 'percentUsed_WMIC'
                },
                {
                    headerText: translator(messages.driverType),
                    path: 'driverType_WMIC'
                }
            ],
            onEditAction: editDriver,
            onRemoveAction: removeDriver,
            isEditing: !isEditMode || isEditDriverMode,
            readOnly: isReadOnlyUser || !isEditMode,
            updateSelectedCardIndex: updateEditingDriverIndex,
            selectedCardIndex: editingDriverIndex
        },
        vehicleAddDriverForm: {
            vehicleVM,
            visible: addingDriver,
            saveDriver,
            cancelDriver,
            updateVehicle,
            isEditMode,
            isEditDriverMode,
            availableDrivers,
            isReadOnlyUser,
            availableDriverTypes,
            onValidate: setComponentValidation
        },
        driverAddButton: {
            disabled: availableDrivers.length === 0 || isEditDriverMode,
            visible: !isReadOnlyUser && isEditMode
        },
        driversSectionContainer: {
            visible: _.get(vehicleVM, 'vehicleType.value.code') !== PAConstants.trailerVehicleType
        },
        ...vehicleOverrides
    };

    const resolvers = {
        resolveCallbackMap: {
            addDriver
        },
        resolveComponentMap: {
            WMICVehicleAddDriverComponent
        },
        resolveClassNameMap: styles
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleVM}
            overrideProps={overrideProps}
            onModelChange={updateVehicle}
            onValidationChange={setComponentValidation}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

WMICVehicleOwnershipAndAssignmentComponent.propTypes = {
    id: PropTypes.string.isRequired,
    vehicleVM: PropTypes.shape({}).isRequired,
    updateVehicle: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isReadOnlyUser: PropTypes.bool.isRequired,
    showErrors: PropTypes.bool.isRequired,
};

export default WMICVehicleOwnershipAndAssignmentComponent;
