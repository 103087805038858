import { defineMessages } from 'react-intl';

export default defineMessages({
    premiumCostsLabel: {
        id: 'wmic-pe-capability-gateway-common-react.PremiumCosts.Premium Costs',
        defaultMessage: 'Premium Costs'
    },
    currentCostsLabel: {
        id: 'wmic-pe-capability-gateway-common-react.PremiumCosts.Current Costs',
        defaultMessage: 'Current Costs'
    },
    premiumLabel: {
        id: 'wmic-pe-capability-gateway-common-react.PremiumCosts.Premium',
        defaultMessage: 'Premium'
    },
    vehicleCoverageLabel: {
        id: 'wmic-pe-capability-gateway-common-react.PremiumCosts.Vehicle Coverages',
        defaultMessage: 'Vehicle Coverages'
    },
    driverCoverageLabel: {
        id: 'wmic-pe-capability-gateway-common-react.PremiumCosts.Driver Coverages',
        defaultMessage: 'Driver Coverages'
    },
    policyCoverageLabel: {
        id: 'wmic-pe-capability-gateway-common-react.PremiumCosts.Policy Coverages',
        defaultMessage: 'Policy Coverages'
    },
    locationCoverageLabel: {
        id: 'wmic-pe-capability-gateway-common-react.PremiumCosts.Location Coverages',
        defaultMessage: 'Location Coverages'
    },
    watercraftCoverageLabel: {
        id: 'wmic-pe-capability-gateway-common-react.PremiumCosts.Watercraft Coverages',
        defaultMessage: 'Watercraft Coverages'
    },
    propertyCoverageLabel: {
        id: 'wmic-pe-capability-gateway-common-react.PremiumCosts.Property Coverages',
        defaultMessage: 'Property Coverages'
    },
    liabilityCoverageLabel: {
        id: 'wmic-pe-capability-gateway-common-react.PremiumCosts.Liability Coverages',
        defaultMessage: 'Liability Coverages'
    },
    inlandMarineCoverageLabel: {
        id: 'wmic-pe-capability-gateway-common-react.PremiumCosts.Inland Marine Coverages',
        defaultMessage: 'Inland Marine Coverages'
    },
    taxesLabel: {
        id: 'wmic-pe-capability-gateway-common-react.PremiumCosts.Taxes',
        defaultMessage: 'Taxes'
    },
    feesLabel: {
        id: 'wmic-pe-capability-gateway-common-react.PremiumCosts.Fees',
        defaultMessage: 'Fees'
    },
    totalCostLabel: {
        id: 'wmic-pe-capability-gateway-common-react.PremiumCosts.Total cost',
        defaultMessage: 'Total cost'
    },
    currentHeader: {
        id: 'wmic-pe-capability-gateway-common-react.PremiumCosts.Current Header',
        defaultMessage: 'Current'
    },
    newHeader: {
        id: 'wmic-pe-capability-gateway-common-react.PremiumCosts.New Header',
        defaultMessage: 'New'
    },
    changeInCostNote: {
        id: 'wmic-pe-capability-gateway-common-react.PremiumCosts.Change in cost...',
        defaultMessage: '* Change in cost of {changeInCostAmount}'
    },
    quoteValiddays: {
        id: 'wmic-pe-capability-gateway-common-react.PremiumCosts.Quote is valid for 90 days',
        defaultMessage: 'Quote is valid for 90 days'
    }
});
