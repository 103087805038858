import PropTypes from 'prop-types';

/**
 * Properties used to specify a label.
 * 
 * @typedef {Object} Label
 * @memberof module:gw-portals-wizard-react
 * 
 * @property {String} id - label id
 * @property {String} defaultMessage - label text
 */

/**
 * Properties used to specify a wizard step.
 * 
 * @typedef {Object} WizardStepProps
 * @memberof module:gw-portals-wizard-react
 * 
 * @property {String|Label} [title] - The step title.
 * @property {String} [path] - The step path.
 * @property {ReactComponent} [component] - The step component.
 */
export const wizardStepProps = {
    title: PropTypes.oneOfType([PropTypes.shape({
        id: PropTypes.string
    }), PropTypes.string]),
    path: PropTypes.string,
    component: PropTypes.elementType,
};

/**
 * Properties used to specify a wizard step instance.
 * 
 * @typedef {Object} StepInWizard
 * @extends {WizardStepProps}
 * @memberof module:gw-portals-wizard-react
 * 
 * @property {Boolean} [visited] - If the step was already visited.
 * @property {Boolean} [submitted] - If the step was submitted.
 */
const stepInWizard = PropTypes.shape({
    ...wizardStepProps,
    visited: PropTypes.bool,
    submitted: PropTypes.bool
});

/**
 * Properties used to specify a wizard.
 * 
 * @typedef {Object} WizardProps
 * @memberof module:gw-portals-wizard-react
 * 
 * @property {Label} [wizardTitle] - the title for this wizard
 * @property {Array.<StepInWizard>} [steps] - the steps that will compose this wizard
 * @property {Number} [currentStepIndex] - index of the current step
 * @property {Function} [changeNextSteps] - Function to call when needed to change the next steps (Should give the next steps as params)
 * @property {StepInWizard} [currentStep] - the current step
 * @property {Boolean} [isSkipping] - When the wizard is in the process of skipping steps
 * @property {Function} [stopSkipping] - Callback to manually stop skipping. 
 * @property {Function} [goNext] - Callback that will be called when the wizard next button is pushed. 
 * @property {Function} [goPrevious] - Callback that will be called when the wizard previous button is pushed. 
 * @property {Function} [markStepSubmitted] - Callback to mark a step as submitted
 * @property {Function} [jumpTo] - Callback to jump to a specific step by index
 * @property {Function} [cancel] - Callback that will be called when the wizard is canceled. 
 * @property {Function} [finish] - Callback that will be called when the wizard is finished. 
 * @property {*} [wizardData] - the data to pass to the wizard 
 * @property {*} [wizardSnapshot] - the last valid state of wizard data
 * @property {Function} [updateWizardData] - Function to update wizard data
 * @property {Function} [updateWizardSnapshot] - Function to update wizard snashot data
 * @property {Boolean} [hasNewErrors] - whether the wizard has new errors or not
 * @property {Array.<Object>} [errorsForStep] - list of errors for the current step
 * @property {Object} [stepsWithErrors] - collection of steps that contains errors
 * @property {Array.<Object>} [underwritingIssues] - list of underwriting issues
 * @property {Function} [acknowledgeError] - Callback to dismiss an error
 * @property {Function} [reportErrors] - Function to report new errors
 */
export const wizardProps = {
    // wizard details
    wizardTitle: PropTypes.shape({
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired
    }),
    // step props
    steps: PropTypes.arrayOf(stepInWizard),
    currentStepIndex: PropTypes.number,
    changeNextSteps: PropTypes.func,
    currentStep: stepInWizard,
    // transition props
    isSkipping: PropTypes.bool,
    stopSkipping: PropTypes.func,
    goNext: PropTypes.func,
    goPrevious: PropTypes.func,
    markStepSubmitted: PropTypes.func,
    jumpTo: PropTypes.func,
    cancel: PropTypes.func,
    finish: PropTypes.func,
    // wizard data props
    wizardData: PropTypes.any,
    wizardSnapshot: PropTypes.any,
    updateWizardData: PropTypes.func,
    updateWizardSnapshot: PropTypes.func,
    // wizard error props
    hasNewErrors: PropTypes.bool,
    errorsForStep: PropTypes.arrayOf(PropTypes.shape({})),
    stepsWithErrors: PropTypes.shape({}),
    underwritingIssues: PropTypes.arrayOf(PropTypes.shape({})),
    acknowledgeError: PropTypes.func,
    reportErrors: PropTypes.func
};

/**
 * Properties used to specify a page template.
 * 
 * @typedef {Object} PageTemplateProps
 * @memberof module:gw-portals-wizard-react
 * 
 * @property {*} [wizardData] - data to be passed to the wizard
 * @property {Function} onNext - callback to be called when changing to the next step
 * @property {Boolean} disableNext - when to disable the next button
 * @property {Boolean} [isLoadingNext] - if the next step is been loaded
 * @property {Boolean} showNext - when to show the next button
 * @property {String|Label} nextLabel - label used in the next button
 * @property {Function} onPrevious - callback to be called when changing to the previous step
 * @property {Boolean} disablePrevious - when to disable the previous button
 * @property {Boolean} [isLoadingPrevious] - if the previous step is been loaded
 * @property {Boolean} showPrevious - when to show the previous button
 * @property {String|Label} previousLabel - label used in the previous button
 * @property {Function} onCancel - callback to be called when cancelling
 * @property {Boolean} disableCancel - when to disable the cancel button
 * @property {Boolean} [isLoadingCancel] - if the cancel is been loaded
 * @property {Boolean} showCancel - when to show the cancel button
 * @property {String|Label} cancelLabel - label used in the cancel button
 * @property {ReactNode} [children] - children element to be used
 */
export const pageTemplateProps = {
    wizardData: PropTypes.any,

    onNext: PropTypes.func.isRequired,
    disableNext: PropTypes.bool.isRequired,
    isLoadingNext: PropTypes.bool,
    showNext: PropTypes.bool.isRequired,
    nextLabel: PropTypes.oneOfType([PropTypes.shape({
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired
    }), PropTypes.string]).isRequired,

    onPrevious: PropTypes.func.isRequired,
    disablePrevious: PropTypes.bool.isRequired,
    isLoadingPrevious: PropTypes.bool,
    showPrevious: PropTypes.bool.isRequired,
    previousLabel: PropTypes.oneOfType([PropTypes.shape({
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired
    }), PropTypes.string]).isRequired,

    onCancel: PropTypes.func.isRequired,
    disableCancel: PropTypes.bool.isRequired,
    isLoadingCancel: PropTypes.bool,
    showCancel: PropTypes.bool.isRequired,
    cancelLabel: PropTypes.oneOfType([PropTypes.shape({
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired
    }), PropTypes.string]).isRequired,

    children: PropTypes.node
};
