import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { withValidation, validationPropTypes } from '@xengage/gw-portals-validation-react'
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

import { CONSTANTS, LINE_OF_BUSINESS } from 'wmic-pe-portals-utils-js';
import { WMICAddressDetails, WMICDropdownMenuButton } from 'wmic-pe-components-platform-react';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { withModalContext } from '@jutro/components';

import { withTranslator } from '@jutro/locale';
import { BreakpointTrackerContext, Flex } from '@jutro/layout';
import WMICAddressUtil from 'wmic-pe-capability-gateway-common-react/utils/WMICAddressUtil';


import metadata from './WMICTradeNameComponent.metadata.json5';
import styles from './WMICTradeNameComponent.module.scss';
import messages from './WMICTradeNameComponent.messages.js';

const TRADENAME_PATH = 'lobData.commercialProperty.tradeNames';

class WMICTradeNameComponent extends Component {
    static contextType = BreakpointTrackerContext;

    state = {
        tradeNameVM: undefined,
        showErrors: false,
        dropdownOptions: [],
        relationshipDropdownOptions: [],
        namedInsuredList: undefined,
        currentTradeNameIndex: -1,
        isEditing: false,
        isViewing: false,
        isSameAddress: false
    };

    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        submissionVM: PropTypes.object.isRequired,
        updateWizardData: PropTypes.func.isRequired,
        lob: PropTypes.string.isRequired,
        ...validationPropTypes
    };


    componentDidMount = () => {
        this.initializeVM();
        const {
            id, onValidate, isComponentValid, registerComponentValidation
        } = this.props;

        registerComponentValidation(this.getIsComponentValid);

        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    };

    componentDidUpdate = () => {        
        const { isComponentValid, onValidate, hasValidationChanged, id } = this.props;

        this.setDynamicDropdowns();
        if (hasValidationChanged && onValidate) {
            onValidate(isComponentValid, id);
        }
    };

    isSameAddress = (tradeNameVM) => {
        const tradeNameVMToUse = tradeNameVM || this.state.tradeNameVM;
        const { submissionVM } = this.props;
        const address1 = tradeNameVMToUse?.locationAddress?.value;
        const address2 = submissionVM?.baseData?.primaryNamedInsured_WMIC?.primaryAddress?.value;

        return WMICAddressUtil.isSameAddress(address1, address2);
    };

    initializeVM = () => {
        const { translator } = this.props;
        let { tradeNameVM, dropdownOptions } = this.state;
        tradeNameVM = this.createVM({});

        this.setDynamicDropdowns();

        dropdownOptions = tradeNameVM.subtype.aspects.availableValues.map(
            (option) => {
                let icon;
                switch (_.upperFirst(option.code)) {
                    case CONSTANTS.Person:
                        icon = 'mi-person';
                        break;
                    case CONSTANTS.Company:
                        icon = 'mi-home';
                        break;
                    default:
                        break;
                };
                return { id: `tradeNameDropdownOption-${option.code}`, text: translator({ id: option.name, defaultMessage: option.code }), code: option.code, icon };
            }
        );
        dropdownOptions = dropdownOptions.sort((a, b) => {
            if (b.code < a.code) {
                return -1;
            }
            if (b.code > a.code) {
                return 1;
            }
            return 0;
        });
        this.setState({ dropdownOptions });
    };

    setDynamicDropdowns = () => {
        const { translator, submissionVM } = this.props;
        let { relationshipDropdownOptions: oldRelationshipDropdownOptions, namedInsuredList: oldNamedInsuredList } = this.state;

        const namedInsuredList = [...submissionVM.baseData.additionalNamedInsureds_WMIC.value, submissionVM.baseData.primaryNamedInsured_WMIC.value];
        if (!_.isEqual(oldNamedInsuredList, namedInsuredList)) {
            this.setState({ namedInsuredList });
        }
        
        const relationshipDropdownOptions = namedInsuredList.map(
            (option) => ({ name: translator({ id: option.displayName, defaultMessage: option.displayName }), code: (option.contactPublicID == null ? option.publicID : option.contactPublicID) })
        );
        if (!_.isEqual(oldRelationshipDropdownOptions, relationshipDropdownOptions)) {
            this.setState({ relationshipDropdownOptions });
        }
    };

    addOrUpdateTradeName = (newTradeNameVM) => {
        const { submissionVM, updateWizardData, lob } = this.props;
        const { currentTradeNameIndex } = this.state;
        const displayName = `${newTradeNameVM.value.firstName} ${newTradeNameVM.value.lastName}`
        if (_.isNil(submissionVM.lobData.commercialProperty.tradeNames.value)) {
            _.set(submissionVM, `${TRADENAME_PATH}.value`, []);
        }
        const currentNamedInsureds = _.get(submissionVM, `${TRADENAME_PATH}.value`);
        if (currentTradeNameIndex === -1) {
            currentNamedInsureds.push(newTradeNameVM.value)
        } else {
            currentNamedInsureds[currentTradeNameIndex] = newTradeNameVM.value;
        }

        _.set(submissionVM, `${TRADENAME_PATH}.value`, currentNamedInsureds);
        _.set(submissionVM, `${TRADENAME_PATH}.value[${currentNamedInsureds.length - 1}].displayName`, displayName)

        this.setState({ tradeNameVM: undefined, currentTradeNameIndex: -1 });
        updateWizardData(submissionVM);
    };

    deleteTradeName = (item, index) => {
        const { translator } = this.props;
        const { modalContext } = this.props;
        const name = item.subtype.value.code === CONSTANTS.Company.toLowerCase() ? item.companyName.value : item.firstName.value;

        modalContext.showConfirm({
            title: translator(messages.removeTradeNameShort),
            message: translator(messages.removeTradeNameLong, { displayName: name }),
            status: 'warning',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.yes,
            cancelButtonText: commonMessages.no
        }).then((result) => {
            if (result === CONSTANTS.MODAL_RESULT.CONFIRM) {
                const { submissionVM, updateWizardData } = this.props;
                const allTradeNames = _.get(submissionVM, `${TRADENAME_PATH}.value`);
                allTradeNames.splice(index, 1);
                _.set(submissionVM, `${TRADENAME_PATH}.value`, allTradeNames);
                updateWizardData(submissionVM);
            }
        })
    };

    editTradeName = (tradename, index) => {
        const { submissionVM } = this.props;
        this.setState({ isEditing: true, isViewing: false }, () => {
            const tradeNameToEdit = _.get(submissionVM, `${TRADENAME_PATH}.children[${index}]`, [])
            const { locationAddress, ...personAniObj } = tradeNameToEdit.value;
            const newTradeNameObj = tradeNameToEdit.value;
            const tradeNameVM = this.createVM(newTradeNameObj);
            this.setState({ tradeNameVM, isSameAddress: this.isSameAddress(tradeNameVM) });
        });
    };

    viewTradeName = (tradename, index) => {
        const { submissionVM } = this.props;
        this.setState({ isViewing: true }, () => {
            const tradeNameToEdit = _.get(submissionVM, `${TRADENAME_PATH}.children[${index}]`, [])
            const { locationAddress, ...personAniObj } = tradeNameToEdit.value;
            const newTradeNameObj = tradeNameToEdit.value;
            const tradeNameVM = this.createVM(newTradeNameObj);
            this.setState({ tradeNameVM, isSameAddress: this.isSameAddress(tradeNameVM) });
        });
    };

    areFieldsValid = () => {
        const { tradeNameVM } = this.state;

        if (!tradeNameVM) {
            return true;
        }

        return tradeNameVM.aspects && tradeNameVM.aspects.valid && tradeNameVM.aspects.subtreeValid;
    };

    getIsComponentValid = () => {
        const { isEditing } = this.state;
        return !isEditing && this.areFieldsValid()
    }

    createVM = (model) => {
        const { viewModelService, submissionVM } = this.props;

        return viewModelService.create(
            model,
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.commercialproperty.dto.TradeNameDTO',
            submissionVM.aspects.context()
        );
    };

    writeValue = (value, path) => {
        const { tradeNameVM } = this.state;
        _.set(tradeNameVM, path, value);
        this.setState({ tradeNameVM });
    };

    handleDropdownSelect = (dropdownOption) => {
        const tradeNameAddress = {
            country: CONSTANTS.COUNTRY.CA,
            city: '',
            state: '',
            postalCode: '',
        };

        const tradeNameVM = this.createVM({
            'subtype': _.lowerCase(dropdownOption),
            'locationAddress': tradeNameAddress
        });
        const isSameAddress = this.isSameAddress(tradeNameVM);
        this.setState({ showErrors: false, isEditing: true, isViewing: false, tradeNameVM, isSameAddress, currentTradeNameIndex: -1 });
    }

    saveNewTradeName = () => {
        const { tradeNameVM } = this.state;

        this.setState({ isEditing: false, isViewing: false }, () => {
            this.addOrUpdateTradeName(tradeNameVM);
        })
    }

    handleEditFormCancel = () => {
        this.setState({ showErrors: false, isEditing: false, tradeNameVM: undefined, currentTradeNameIndex: -1 });
    };

    handleValidation = () => {
        this.setState({ showErrors: true });
    };

    setTradeNameIndex = (index) => {
        this.setState({ currentTradeNameIndex: index })
    };

    setIsEditing = (value) => {
        this.setState({ isEditing: value })
    };

    isPersonTradeName = () => {
        const { tradeNameVM } = this.state;
        return _.upperFirst(_.get(tradeNameVM?.value, 'subtype')) === CONSTANTS.Person;
    };

    isCompanyTradeName = () => {
        const { tradeNameVM } = this.state;
        return _.upperFirst(_.get(tradeNameVM?.value, 'subtype')) === CONSTANTS.Company;
    };

    isCommercialSmallBusiness = () => {
        const { lob } = this.props;
        return lob === LINE_OF_BUSINESS.GENERAL_LIABILITY;
    }

    getTradeNameDisplayName = (tradeNameVM) => {
        const tradename = tradeNameVM.value;
        const tradeNameType = _.upperFirst(_.get(tradename, 'subtype'));
        if (tradeNameType === CONSTANTS.Company) {
            return _.get(tradename, 'companyName', '');
        }
        if (tradeNameType === CONSTANTS.Person) {
            const firstName = _.get(tradename, 'firstName', '');
            const lastName = _.get(tradename, 'lastName', '');
            return `${firstName} ${lastName}`;
        }
    };

    getAddress = (tradeNameVM) => {
        return _.get(tradeNameVM.value, 'locationAddress.addressLine1', '');
    };

    render() {
        const { setComponentValidation, submissionVM, translator, authHeader, onValidate } = this.props;
        const {
            tradeNameVM,
            showErrors,
            currentTradeNameIndex,
            isEditing,
            isViewing
        } = this.state;

        const copyNamedInsuredAddressToTradeNameAddress = (value, path) => {
            _.set(tradeNameVM, path, value);
            if (value) {
                const id = _.get(tradeNameVM, 'relationshipId')

                if (id.value != null) {
                    const { namedInsuredList } = this.state
                    const namedInsured = namedInsuredList.find((obj) => (obj.contactPublicID == null ? obj.publicID : obj.contactPublicID) === id.value)

                    const namedInsureAddress = namedInsured.locationAddress == null ? namedInsured.primaryAddress : namedInsured.locationAddress;

                    _.set(tradeNameVM, 'locationAddress', namedInsureAddress)
                }
            } else {
                _.set(tradeNameVM, 'locationAddress', {
                    country: CONSTANTS.COUNTRY.CA,
                    city: '',
                    state: '',
                    postalCode: '',
                })
            }
            this.setState({ tradeNameVM });
            this.setState({ isSameAddress: this.isSameAddress(tradeNameVM) });
        };

        const tradeNameContent =
             <Flex className="gw-mb-6" justifyContent="left">
                <WMICDropdownMenuButton
                    id="newTradeNameDropdown"
                    type="secondary"
                    size="medium"
                    buttonText={translator(messages.tradeName)}
                    alignRight
                    onItemClick={this.handleDropdownSelect}
                    items={this.state.dropdownOptions}
                    disabled={isEditing}
                />
            </Flex>;

        const resolvers = {
            resolveCallbackMap: {
                saveForm: this.areFieldsValid()
                    ? this.saveNewTradeName
                    : this.handleValidation
            },
            resolveClassNameMap: styles,
            resolveComponentMap: {
                WMICAddressDetails
            }
        };

        const overrideProps = {
            '@field': {
                parentNode: tradeNameVM,
                readOnly: isViewing
            },
            newTradeNameInfoContainer: {
                visible: this.isCompanyTradeName() || this.isPersonTradeName()
            },
            companyContainer: {
                visible: this.isCompanyTradeName(),
                onCancel: this.handleEditFormCancel,
                hideButtons: isViewing,
                readOnly: isViewing
            },
            personContainer: {
                visible: this.isPersonTradeName(),
                onCancel: this.handleEditFormCancel,
                hideButtons: isViewing,
                readOnly: isViewing
            },
            name: {
                visible: !this.isCommercialSmallBusiness()
            },
            companyName: {
                visible: this.isCommercialSmallBusiness()
            },
            tradeDetailCards: {
                VMList: _.get(submissionVM, `${TRADENAME_PATH}.children`, []),
                VMData: [
                    {
                        headerText: translator(messages.name),
                        getData: this.getTradeNameDisplayName
                    },
                    {
                        headerText: translator(messages.subtype),
                        path: 'subtype',
                        isTypekey: true
                    },
                    {
                        headerText: translator(messages.address),
                        getData: this.getAddress
                    },
                ],
                onEditAction: this.editTradeName,
                onRemoveAction: this.deleteTradeName,
                onClickAction: this.viewTradeName,
                selectedCardIndex: currentTradeNameIndex,
                updateSelectedCardIndex: this.setTradeNameIndex,
                isEditing,
                flatCards: true,
                clickable: !isEditing
            },
            tradeNameFlexContainer: { 
                content: tradeNameContent
            },
            tradeNameAddressLookup: {
                addressVM: _.get(tradeNameVM, 'locationAddress'),
                hideButtons: true,
                hideBorder: true,
                showErrors,
                onValidate: setComponentValidation,
                readOnly: this.state.isSameAddress || isViewing,
                readOnlyCountry: true
            },
            sameAddressAsRelationship: {
                onValueChange: copyNamedInsuredAddressToTradeNameAddress,
                value: this.isSameAddress()
            },
            sameAddressAsRelationshipPerson: {
                onValueChange: copyNamedInsuredAddressToTradeNameAddress,
                value: this.isSameAddress()
            },
            relationshipDropdown: {
                availableValues: this.state.relationshipDropdownOptions,
            },
        };

        const readValue = (id, path) => {
            return readViewModelValue(metadata.componentContent, tradeNameVM, id, path, overrideProps);
        };

        return (
            <ViewModelForm
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
                resolveValue={readValue}
                model={tradeNameVM}
                onValueChange={this.writeValue}
                showErrors={showErrors}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={setComponentValidation}
            />
        );
    }
}

export default withModalContext(withValidation(withViewModelService(withTranslator(WMICTradeNameComponent))));
