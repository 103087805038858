import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import SingleClauseComponentVM from '../Clauses/SingleClauseComponentVM';

function QuoteClauseTableCell({
    clauseCellUniqueID,
    clauses,
    code,
    loadingClause,
    onChangeSubmission,
    onSyncCoverages,
    onChangeSubmissionAndSync,
    onValidate,
    path,
    readOnly,
    getCustomTerms,
    showTooltip,
    showErrors
}) {
    const clauseIndex = clauses.findIndex((aClause) =>
        clauseCellUniqueID.coverageUniqueID && clauseCellUniqueID.coverageUniqueID.split('__')[0] === aClause.publicID
    );
    const clause = clauses[clauseIndex];
    const isQuoteLoading = _.get(loadingClause, 'quoteCode') === code && _.isUndefined(loadingClause.clauseID);
    const isClauseLoading = _.get(loadingClause, 'clauseID') === clauseCellUniqueID.coverageUniqueID
        && _.get(loadingClause, 'quoteCode') === code;

    if (clauseIndex !== -1) {
        return (
            <SingleClauseComponentVM
                value={clause}
                path={`${path}.children[${clauseIndex}]`}
                onChangeClause={onChangeSubmission}
                onSyncCoverages={onSyncCoverages}
                onChangeSubmissionAndSync={onChangeSubmissionAndSync}
                loadingClause={isClauseLoading || isQuoteLoading}
                idPrefix={code}
                labelTop
                labelPosition="top"
                onValidate={onValidate}
                showAmount={false}
                readOnly={readOnly}
                getCustomTerms={getCustomTerms}
                showTooltip={showTooltip}
                showErrors={showErrors}
            />
        );
    }
    return null;
}

QuoteClauseTableCell.propTypes = {
    clauseCellUniqueID: PropTypes.string.isRequired,
    clauses: PropTypes.array,
    code: PropTypes.string.isRequired,
    loadingClause: PropTypes.object.isRequired,
    onChangeSubmission: PropTypes.func.isRequired,
    onSyncCoverages: PropTypes.func.isRequired,
    onChangeSubmissionAndSync: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    showTooltip: PropTypes.bool
};

QuoteClauseTableCell.defaultProps = {
    clause: [],
    readOnly: false
};

export default QuoteClauseTableCell;
