import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { WMICJsonRPCService as JsonRPCService } from 'wmic-pe-portals-transport-js';

export default class AccountService {
    static getPotentialExistingAccounts(accountSearchCritera, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'getPotentialExistingAccounts', [accountSearchCritera], additionalHeaders);
    }

    static getRecentlyViewedAccountsLimitedBy(recentAccountSearchCriteria, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'getRecentlyViewedAccountsLimitedBy', [recentAccountSearchCriteria], additionalHeaders);
    }

    static getRecentlyCreatedAccountsLimitedBy(recentAccountSearchCriteria, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'getRecentlyCreatedAccountsLimitedBy', [recentAccountSearchCriteria], additionalHeaders);
    }

    static getAccount(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'getAccount', [accountNumber], additionalHeaders);
    }

    static getAccountDetails(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'getAccountDetails', [accountNumber], additionalHeaders);
    }

    static getAccountBillingData(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'getAccountBillingData', [accountNumber], additionalHeaders);
    }

    static addRecentlyViewedAccounts(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'addRecentlyViewedAccounts', [accountNumber], additionalHeaders);
    }

    static getRecentlyViewedAccounts(additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'getRecentlyViewedAccounts', [], additionalHeaders);
    }

    static getAvailableProductsForAccount(newSubmission, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'getAvailableProductsForAccount', [newSubmission], additionalHeaders);
    }

    static getAccountClaims(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'getAccountClaims', [accountNumber], additionalHeaders);
    }

    static getAccountsForCurrentUser(
        createdInLastXDays, queryOptions, queryParameters, additionalHeaders = {}
    ) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'getAccountsForCurrentUser', [createdInLastXDays, queryOptions, queryParameters], additionalHeaders);
    }

    static getPersonalAccountsForCurrentUser(
        createdInLastXDays, queryOptions, queryParameters, additionalHeaders = {}
    ) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'getPersonalAccountsForCurrentUser', [createdInLastXDays, queryOptions, queryParameters], additionalHeaders);
    }

    static getCommercialAccountsForCurrentUser(
        createdInLastXDays, queryOptions, queryParameters, additionalHeaders = {}
    ) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'getCommercialAccountsForCurrentUser', [createdInLastXDays, queryOptions, queryParameters], additionalHeaders);
    }

    static getAccountsForProducerCode(
        createdInLastXDays, queryOptions, queryParameters, additionalHeaders = {}
    ) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'getAccountsForProducerCode', [createdInLastXDays, queryOptions, queryParameters], additionalHeaders);
    }

    static getPersonalAccountsForProducerCode(
        createdInLastXDays, queryOptions, queryParameters, additionalHeaders = {}
    ) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'getPersonalAccountsForProducerCode', [createdInLastXDays, queryOptions, queryParameters], additionalHeaders);
    }

    static getCommercialAccountsForProducerCode(
        createdInLastXDays, queryOptions, queryParameters, additionalHeaders = {}
    ) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'getCommercialAccountsForProducerCode', [createdInLastXDays, queryOptions, queryParameters], additionalHeaders);
    }

    static getOpenPolicyTransactionsForAccount(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'getOpenPolicyTransactionsForAccount', [accountNumber], additionalHeaders);
    }

    static getOrCreateAccount(account, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'getOrCreateAccount', [account], additionalHeaders);
    }

    static newAccountAndSubmission(account, productCode, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'newAccountAndSubmission', [account, productCode], additionalHeaders);
    }

    static getAccountJobsSummariesForCurrentUser(createdInLastXDays, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'getAccountJobsSummariesForCurrentUser', [createdInLastXDays], additionalHeaders);
    }

    static updateAccountDetails(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'updateAccountDetails', [accountNumber], additionalHeaders);
    }

    static getAccountJobsForCurrentUser(queryParams,
        createdInLastXDays, page, size, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'getAccountJobsForCurrentUser_WMIC', [queryParams, createdInLastXDays, page, size], additionalHeaders);
    }

    static getJobsForCurrentUser(queryParams,
        createdInLastXDays, page, size, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'getJobsForCurrentUser', [queryParams, createdInLastXDays, page, size], additionalHeaders);
    }

    static getAccountRenewalJobsForCurrentUser(queryParams,
        page, size, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'getAccountRenewalJobsForCurrentUser', [queryParams, page, size], additionalHeaders);
    }

    static accountSearchAccessibleForCurrentUser_WMIC(accountSearchCriteria,
        additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'accountSearchAccessibleForCurrentUser', [accountSearchCriteria], additionalHeaders);
    }

    static changeAccountHolderTo(accountNumber, publicIdForNewAccountHolderContact,
        additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'changeAccountHolderTo', [accountNumber, publicIdForNewAccountHolderContact], additionalHeaders);
    }

    static validateEmail(email, jobNumber,
        additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
            'validateEmail', [email, jobNumber], additionalHeaders);
    }

    static validateEmailWithoutSettingEmailValidationStatus (email,
        additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayAccount'),
        'validateEmailWithoutSettingEmailValidationStatus', [email], additionalHeaders);
    }
}
