import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { TranslatorContext } from '@jutro/locale';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { CancellationService, PolicyService } from 'gw-capability-gateway';
import metadata from './Cancellation.metadata.json5';
import styles from './Cancellation.module.scss';
import messages from './Cancellation.messages';

class Cancellation extends Component {
    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        onDoNotCancel: PropTypes.func.isRequired,
        policyNumber: PropTypes.string.isRequired,
        policyData: PropTypes.shape({
            policyNumber: PropTypes.string.isRequired
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }).isRequired
    };

    static contextType = TranslatorContext;

    state = {
        cancellationSubmissionVM: {},
        submitted: false,
        enableRefundMethod: true,
        enableEffectiveDate: true,
        refundMethodsData: [],
        effectiveDateData: '',
        startCancellationTag: true,
        validationErrorMessage: '',
        showError: false
    };

    componentDidMount = () => {
        let { cancellationSubmissionVM } = this.state;
        const { policyData } = this.props;
        const model = {};
        cancellationSubmissionVM = this.createVM(model);
        cancellationSubmissionVM.cancellationSource = _.find(
            cancellationSubmissionVM.cancellationSource.aspects.availableValues,
            {
                code: 'insured'
            }
        );
        const earliestCancellationDate = new Date(_.get(policyData, 'latestPeriod.effectiveDate'));
        earliestCancellationDate.setDate(earliestCancellationDate.getDate() + 1);
        earliestCancellationDate.setHours(0, 0, 0, 0);
        _.set(cancellationSubmissionVM, 'earliestCancellationDate', earliestCancellationDate);
        _.set(
            cancellationSubmissionVM,
            'actualExpirationDate',
            _.get(policyData, 'latestPeriod.expirationDate')
        );
        this.setState({
            cancellationSubmissionVM
        });
    };

    validateEffectiveDate = () => {
        const { policyData } = this.props;
        const { cancellationSubmissionVM } = this.state;
        const selectedEffectiveDate = _.get(cancellationSubmissionVM, 'effectiveDate.value');
        const translator = this.context;
        const cancellation = _.cloneDeep(policyData);
        const latestPeriodExpiryDate = moment(cancellation.latestPeriod.expirationDate);
        const chosenEffectiveDate = moment(selectedEffectiveDate);
        const latestPeriodEffectiveDate = moment(cancellation.latestPeriod.effectiveDate);
        if (chosenEffectiveDate.isBefore(latestPeriodEffectiveDate)) {
            this.setState({
                startCancellationTag: true,
                showError: true,
                validationErrorMessage: translator(messages.selectAfterDate)
            });
            return;
        }
        if (chosenEffectiveDate.subtract(1, 'day').isAfter(latestPeriodExpiryDate)) {
            this.setState({
                startCancellationTag: true,
                showError: true,
                validationErrorMessage: translator(messages.selectBeforeDate)
            });
            return;
        }
        this.setState({
            startCancellationTag: false,
            validationErrorMessage: '',
            showError: false
        });
    };

    writeValue = (value, path) => {
        const { cancellationSubmissionVM } = this.state;
        if (path === 'effectiveDate') {
            this.setState({ showError: false });
            if (value) {
                let formatedDate;
                if (value.year) {
                    formatedDate = new Date(value.year, value.month, value.day);
                    formatedDate.setDate(formatedDate.getDate() + 1);
                } else {
                    formatedDate = new Date(value);
                }
                formatedDate = formatedDate.toISOString();
                this.setState({
                    effectiveDateData: formatedDate
                });
                _.set(cancellationSubmissionVM, path, formatedDate);
            }
        } else {
            _.set(cancellationSubmissionVM, path, value);
        }
        this.setState({ cancellationSubmissionVM });
        if (path === 'cancelReasonCode') {
            this.getRefundMethod();
        }
        if (path === 'cancellationRefundMethod') {
            this.getEffectiveDate();
        }
    };

    createVM = (model) => {
        const { viewModelService } = this.props;
        return viewModelService.create(
            model,
            'pc',
            'edge.capabilities.gateway.job.cancellation.dto.CancellationDTO'
        );
    };

    onStartCancel = async () => {
        const { history } = this.props;
        const { authHeader, policyData } = this.props;
        const { policyNumber } = policyData;
        const { cancellationSubmissionVM, effectiveDateData } = this.state;
        const isSameDay = moment(_.get(cancellationSubmissionVM.value, 'effectiveDate')).isSame(
            effectiveDateData,
            'day'
        );
        const effectiveDate = isSameDay
            ? _.get(cancellationSubmissionVM.value, 'effectiveDate')
            : moment(_.get(cancellationSubmissionVM.value, 'effectiveDate'))
                .utc()
                .subtract(1, 'days');
        const effectiveDatetoDate = new Date(effectiveDate);
        const dataTo = {
            cancellationSource: 'insured',
            isTempCancellation: true,
            cancelReasonCode: _.get(cancellationSubmissionVM.value, 'cancelReasonCode'),
            cancellationRefundMethod: _.get(
                cancellationSubmissionVM.value,
                'cancellationRefundMethod'
            ),
            description: _.get(cancellationSubmissionVM, 'cancellationTextArea'),
            effectiveDate: {
                day: effectiveDatetoDate.getDate(),
                month: effectiveDatetoDate.getMonth(),
                year: effectiveDatetoDate.getFullYear()
            }
        };
        await PolicyService.createNewPolicyCancellationTransaction(
            policyNumber,
            dataTo,
            authHeader
        ).then((effectiveDateResponse) => {
            history.push(
                `/cancellation/${effectiveDateResponse.jobNumber}/summary`,
                effectiveDateResponse
            );
        });
    };

    onDoNotCancel = () => {
        const { onDoNotCancel } = this.props;
        if (onDoNotCancel) {
            onDoNotCancel();
        }
    };

    getRefundMethod = () => {
        const { authHeader, policyData } = this.props;
        const { cancellationSubmissionVM } = this.state;
        const { policyNumber } = policyData;
        const dataTo = {
            cancellationSource: 'insured',
            isTempCancellation: true,
            cancelReasonCode: _.get(cancellationSubmissionVM.value, 'cancelReasonCode')
        };
        CancellationService.getRefundMethods(policyNumber, dataTo, authHeader).then(
            (policyIssuedResponse) => {
                this.setState({
                    enableRefundMethod: false,
                    refundMethodsData: policyIssuedResponse
                });
            }
        );
    };

    getEffectiveDate = () => {
        const { authHeader, policyData } = this.props;
        const { cancellationSubmissionVM } = this.state;
        const { policyNumber } = policyData;
        const dataTo = {
            cancellationSource: 'insured',
            isTempCancellation: true,
            cancelReasonCode: _.get(cancellationSubmissionVM.value, 'cancelReasonCode'),
            cancellationRefundMethod: _.get(
                cancellationSubmissionVM.value,
                'cancellationRefundMethod'
            )
        };
        CancellationService.getEffectiveDateForCancellation(policyNumber, dataTo, authHeader).then(
            (effectiveDateResponse) => {
                this.writeValue(effectiveDateResponse, 'effectiveDate.value');
                this.setState({
                    enableEffectiveDate: false,
                    effectiveDateData: effectiveDateResponse,
                    startCancellationTag: false
                });
            }
        );
    };

    getMinData = (cancellationDate) => {
        const formatedDate = new Date(cancellationDate);
        formatedDate.setDate(formatedDate.getDate() - 1);
        return formatedDate;
    };

    displayRefundValues = (refundAvailableValues) => {
        const translator = this.context;
        return refundAvailableValues.map((values) => {
            return {
                code: values.code,
                name: translator({
                    id: values.name,
                    defaultMessage: values.name
                })
            };
        });
    };

    render() {
        const {
            submitted,
            cancellationSubmissionVM,
            enableRefundMethod,
            enableEffectiveDate,
            showError,
            refundMethodsData,
            effectiveDateData,
            startCancellationTag,
            validationErrorMessage
        } = this.state;
        if (_.isEmpty(cancellationSubmissionVM)) {
            return null;
        }
        const translator = this.context;
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                onStartCancel: this.onStartCancel,
                onDoNotCancel: this.onDoNotCancel,
                getEffectiveDate: this.getEffectiveDate,
                getRefundMethod: this.getRefundMethod,
                validateEffectiveDate: this.validateEffectiveDate
            }
        };

        const overrides = {
            '@field': {
                showErrors: submitted,
                showOptional: true
            },
            refundMethodId: {
                disabled: enableRefundMethod,
                availableValues: this.displayRefundValues(
                    _.filter(
                        cancellationSubmissionVM.cancellationRefundMethod.aspects.availableValues,
                        (x) => {
                            return _.indexOf(refundMethodsData, x.code) !== -1;
                        }
                    )
                )
            },
            effectiveDateId: {
                disabled: enableEffectiveDate,
                value: effectiveDateData,
                minDate: this.getMinData(cancellationSubmissionVM.earliestCancellationDate),
                maxDate: new Date(cancellationSubmissionVM.actualExpirationDate),
                className: showError && styles.cancellationMessage
            },
            infoNotification: {
                message: (
                    <div>{translator(messages.customerCancellationsUnderwriter)}</div>
                )
            },
            startCancel: {
                disabled: startCancellationTag
            },
            errorMessageEffectiveDate: {
                content: validationErrorMessage,
                visible: startCancellationTag
            }
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={cancellationSubmissionVM}
                onValueChange={this.writeValue}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        );
    }
}

export default withViewModelService(withRouter(Cancellation));
