import { useContext } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import  messages from './WMICGLCoverageUtil.messages.js';

const generateColumnData = (submissionVM) => {
    const lobOfferingPath = 'lobData.generalLiability.offerings';
    const quoteOfferingPath = 'quoteData.offeredQuotes';

    const lobOfferings = _.get(submissionVM, `${lobOfferingPath}.value`);
    const quoteOfferings = _.get(submissionVM, `${quoteOfferingPath}.value`) || [];

    const columnData = lobOfferings
        .map((lobOffering, lobIndex) => {
            const quoteDataIndex = quoteOfferings.findIndex(
                (qdOffering) => qdOffering.branchCode === lobOffering.branchCode
            );
            const quoteData = quoteOfferings[quoteDataIndex];

            return {
                name: lobOffering.branchName,
                code: lobOffering.branchCode,
                quote: {
                    path: `${quoteOfferingPath}.children[${quoteDataIndex}]`,
                    data: quoteData
                },
                lob: {
                    path: `${lobOfferingPath}.children[${lobIndex}]`,
                    data: lobOffering
                }
            };
        })
        .filter(({ quote }) => !_.isUndefined(quote.data));

    return _.sortBy(columnData, ['code']);
};

function getPropertyLineCoverages(generalLiabilityLine) {
    const lineCoverages = generalLiabilityLine.offerings.children[0].coverages.propertyLineCoverages.value

    return lineCoverages ?? [];
}

function getLiabilityLineCoverages(generalLiabilityLine) {
    const lineCoverages = generalLiabilityLine.offerings.children[0].coverages.commercialGeneralLiabilityCoverages.value

    return lineCoverages ?? [];
}

function getCrimeLineCoverages(generalLiabilityLine) {
    const lineCoverages = generalLiabilityLine.offerings.children[0].coverages.commercialCrimeCoverages.value

    return lineCoverages ?? [];
}

const categorizePropertyLineCoverages = (lineCoverages, onlySelected = false) => {
    const translator = useContext(TranslatorContext);
    const allCoverages = {
        extraExpense: {
            categoryID: 'extraExpense',
            categoryName: translator(messages.extraExpense) ,
            coverages: []
        },
        propertyOffPremise: {
            categoryID: 'propertyOffPremise',
            categoryName: translator(messages.propertyOffPremise) ,
            coverages: []
        },
    };

    const filteredCoverages = onlySelected? _.filter(lineCoverages, {'selected': true}): lineCoverages;

    filteredCoverages.forEach((coverage) => {
        switch (coverage.coverageUniqueID) {
            case 'CP_ExtraExpenseCov_WMIC':
                allCoverages.extraExpense.coverages.push(coverage);
                break;
            case 'CP_PrprtyPrmsIncldngCov_WMIC':
                allCoverages.propertyOffPremise.coverages.push(coverage);
                break;
            default:
                break;
        }
    });

    return allCoverages;
}

const categorizeLiabilityLineCoverages = (lineCoverages, onlySelected = false) => {
    const translator = useContext(TranslatorContext);
    const allCoverages = {
        commercialgeneralliability: {
            categoryName: translator(messages.commercialgeneralliability) ,
            coverages: []
        },
        ripandtear: {
            categoryName: translator(messages.ripandtearliability) ,
            coverages: []
        },
        veterinarians: {
            categoryName: translator(messages.veterinarians) ,
            coverages: []
        },
    };

    const filteredCoverages = onlySelected? _.filter(lineCoverages, {'selected': true}): lineCoverages;

    filteredCoverages.forEach((coverage) => {
        switch (coverage.coverageUniqueID) {
            case 'CP_BodilyInjuryPropertyDamageCov_WMIC':
                allCoverages.commercialgeneralliability.coverages.push(coverage);
                break;
            case 'CP_RipandTearLiabilityCov_WMIC':
                allCoverages.ripandtear.coverages.push(coverage);
                break;
            case 'CP_VeterinariansProfessionalServicesCov_WMIC':
                allCoverages.veterinarians.coverages.push(coverage);
                break;
            default:
                break;
        }
    });

    return allCoverages;
}

const categorizeCrimeLineCoverages = (lineCoverages, onlySelected = false) => {
    const translator = useContext(TranslatorContext);
    const allCoverages = {
        employeeDishonesty: {
            categoryName: translator(messages.employeeDishonesty) ,
            coverages: []
        },
        moneySecurities: {
            categoryName: translator(messages.moneySecurities) ,
            coverages: []
        },
    };

    const filteredCoverages = onlySelected? _.filter(lineCoverages, {'selected': true}): lineCoverages;

    filteredCoverages.forEach((coverage) => {
        switch (coverage.coverageUniqueID) {
            case 'CP_EmployeeDishonestyCov_WMIC':
                allCoverages.employeeDishonesty.coverages.push(coverage);
                break;
            case 'CP_MoneySecuritiesAndOtherPropertyCov_WMIC':
                allCoverages.moneySecurities.coverages.push(coverage);
                break;
            default:
                break;
        }
    });

    return allCoverages;
}

export default {
    getPropertyLineCoverages,
    getLiabilityLineCoverages,
    getCrimeLineCoverages,
    generateColumnData,
    categorizePropertyLineCoverages,
    categorizeLiabilityLineCoverages,
    categorizeCrimeLineCoverages
};
