import { defineMessages } from 'react-intl';

export default defineMessages({
    wizard: {
        id: 'quoteandbind.gl.wizard.step.AdditionalCoverages',
        defaultMessage: 'Additional Coverages'
    },
    title: {
        id: 'quoteandbind.gl.views.additionalcoverages.title',
        defaultMessage: 'Additional Coverages'
    },
    crime: {
        id: 'quoteandbind.gl.views.additionalcoverages.crime',
        defaultMessage: "Crime"
    },
    inlandMarine: {
        id: 'quoteandbind.im.views.additionalcoverages.inlandMarine',
        defaultMessage: "Inland Marine"
    },
    additionalInterestMachineryEquipment: {
        id: 'quoteandbind.im.views.additionalcoverages.additionalInterestMachineryEquipment',
        defaultMessage: "Additional Interest (Optional) - Machinery and Equipment Blanket"
    },
    additionalInterestTools: {
        id: 'quoteandbind.im.views.additionalcoverages.additionalInterestTools',
        defaultMessage: "Additional Interest (Optional) - Tools Blanket"
    },
    name: {
        id: 'quoteandbind.im.views.additionalcoverages.name',
        defaultMessage: "Name"
    },
    interestType: {
        id: 'quoteandbind.im.views.additionalcoverages.interestType',
        defaultMessage: "Interest Type"
    },
    addAdditionalInterest: {
        id: 'quoteandbind.im.views.additionalcoverages.addAdditionalInterest',
        defaultMessage: "Add Additional Interest"
    },
    removeAdditionalInterestShort: {
        id: 'quoteandbind.im.views.additionalcoverages.removeAdditionalInterestShortME',
        defaultMessage: 'Remove Additional Interest'
    },
    removeAdditionalInterestLong: {
        id: 'quoteandbind.im.views.additionalcoverages.removeAdditionalInterestLongME',
        defaultMessage: 'Are you sure you want to remove Additional Interest {displayName} from the list?'
    },
    person: {
        id: 'quoteandbind.im.views.additionalcoverages.person',
        defaultMessage: 'Person'
    },
    company: {
        id: 'quoteandbind.im.views.additionalcoverages.company',
        defaultMessage: 'Company'
    },
});
