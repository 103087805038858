import { defineMessages } from 'react-intl';

export default defineMessages({
    enterAddress: {
        id: 'wmic.quoteandbind.views.contact-details.Begin entering your address',
        defaultMessage: 'Begin entering your address',
    },
    search: {
        id: 'wmic.quoteandbind.views.contact-details.Search',
        defaultMessage: 'Search',
    },
    enterManualAddress: {
        id: 'wmic.platform.widgets.findaddress.components.FindAddress.Enter your address manually',
        defaultMessage: 'Enter your address manually',
    },
    backToAddressLookup: {
        id: 'wmic.platform.widgets.findaddress.components.FindAddress.Back to Address Search',
        defaultMessage: 'Back to Address Search',
    },
    addressLine1: {
        id: 'wmic.platform.widgets.findaddress.components.Address Line 1',
        defaultMessage: 'Address Line 1'
    },
    emailHint: {
        id: 'quoteandbind.views.contact-details.State Hint',
        defaultMessage: 'Renters coverage is currently only available for residents of California.'
    }
});
