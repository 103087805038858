import { defineMessages } from 'react-intl';

export default defineMessages({
    businessType: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOHomeBasedBusinessCov.Business Type',
        defaultMessage: 'Type'
    },
    businessDescription: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOHomeBasedBusinessCov.Business Description',
        defaultMessage: 'Description'
    },
    businessCoverageLimit: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOHomeBasedBusinessCov.Business Coverage Limit',
        defaultMessage: 'Limit'
    },
    businessAnnualGrossReceipts: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOHomeBasedBusinessCov.Business Annual Gross Receipts',
        defaultMessage: 'Annual Gross Receipts'
    },
    addBusinessButton: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOHomeBasedBusinessCov.Add Business',
        defaultMessage: 'Add Business'
    },
    removeBusinessConfirmationTitle: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOHomeBasedBusinessCov.RemoveBusinessConfirmationTitle',
        defaultMessage: 'Remove- {business}?',
    },
    removeBusinessConfirmationMessage: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOHomeBasedBusinessCov.RemoveBusinessConfirmationMsg',
        defaultMessage: 'Are you sure you want to remove business- {business}?'
    },
    oneBusinessRequired: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOHomeBasedBusinessCov.One Business required',
        defaultMessage: 'One Business required'
    },
});
