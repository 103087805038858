import React, { useContext } from 'react';
import _ from 'lodash';
import { Accordion } from '@jutro/components'
import { QuoteClauseTable, WMICCard, WMICAccordionCard, WMICHeading, ClausesComponentVM } from 'wmic-pe-components-platform-react';

import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import {Position} from "wmic-pe-portals-utils-js";
import { WMICCPCoverageBuildings } from "wmic-pe-capability-gateway-quoteandbind-cp-react";
import { useAccordionValidation } from 'wmic-pe-portals-wizard-components-ui';

import WMICCPCoverageUtil from "wmic-pe-capability-gateway-quoteandbind-cp-react/util/WMICCPCoverageUtil";

import messages from './WMICCPLocationCoverages.messages';
import metadata from './WMICCPLocationCoverages.metadata.json5';

function WMICCPLocationCoverages(props) {
    const translator = useContext(TranslatorContext);
    const {
        locationIndex,
        locationVM,
        locationCoverages,
        baseData,
        changeSubmissionAndSync,
        changeSubmission,
        syncCoverages,
        recalculate,
        resetQuote,
        onStaleQuoteBranchCode,
        underwritingIssues,
        buyNow,
        quoteID,
        onClauseChange,
        onValidate,
        jobVM,
        isBoundPolicy,
        readOnly
    } = props;

    const { isAccordionValid } = useAccordionValidation(onValidate);
    const generateClauseData = (coverageColumnData) => {
        return coverageColumnData.map(({ lob, code }) => {
            return {
                code: code,
                path: `${lob.path}.coverages.locationCoverages.children[${locationIndex}].coverages`,
                clauses: _.get(locationCoverages, 'coverages.value')
            };
        });
    };

    const getLocationCoverages = () => {
        // only show these categories
        const categoryMap = ['locationcoverages', 'additionallocationcoverages'];

        const categorizedCoverages = WMICCPCoverageUtil.categorizeLocationCoverages(locationCoverages.value);
        const coverageAccordions = categoryMap.map((categoryKey) => {
            const locationCoverage = _.get(categorizedCoverages, categoryKey);

            if (locationCoverage.coverages.length === 0) {
                return (<></>);
            }

            if (!isBoundPolicy) {
                const coverageColumnData = WMICCPCoverageUtil.generateColumnData(jobVM);
                const coverageTableData = [{
                    data: locationCoverage.coverages,
                    tableContent: generateClauseData(coverageColumnData)
                }];

                return (<>
                    <WMICHeading
                        variant="h2"
                        underlined
                        bold
                        size="h2"
                        title={locationCoverage.categoryName}
                        isValid={isAccordionValid(`clauseTable-${locationCoverage.categoryID}`)}/>
                    <QuoteClauseTable
                        id={`clauseTable-${locationCoverage.categoryID}`}
                        columnData={coverageColumnData}
                        tableData={coverageTableData}
                        quoteID={quoteID}
                        readOnly={readOnly}
                        onChangeSubmissionAndSync={changeSubmissionAndSync}
                        onChangeSubmission={changeSubmission}
                        onSyncCoverages={syncCoverages}
                        onRecalculate={recalculate}
                        onResetQuote={resetQuote}
                        onStaleQuoteBranchCode={onStaleQuoteBranchCode}
                        underwritingIssues={underwritingIssues}
                        filterUWIssuesInCustomOffering={false}
                        onValidate={onValidate}
                        showTooltip={false}
                    />
                </>)
            }

            return (
                <>
                    <WMICHeading
                        variant="h2"
                        underlined
                        bold
                        size="h2"
                        title={locationCoverage.categoryName}
                    />
                    <ClausesComponentVM
                        showAmount={false}
                        isEditable
                        readOnly
                        path={`${categoryKey}.coverages`}
                        value={locationCoverage.coverages}
                    />
                </>
            );
        });

        return coverageAccordions;
    }

    const overrideProps = {
        '@field': {
            labelPosition: Position.LEFT,
            parentNode: locationVM
        },
        locationCoveragesAccordions: {
            content: getLocationCoverages()
        }
    }

    const coverageBuildingsProps = {
        locationVM,
        locationIndex,
        baseData,
        changeSubmissionAndSync,
        changeSubmission,
        syncCoverages,
        recalculate,
        resetQuote,
        onStaleQuoteBranchCode,
        //onBuildingClauseChange,
        underwritingIssues,
        buyNow,
        quoteID,
        onValidate,
        jobVM,
        isBoundPolicy,
        readOnly
    }

    return (
        <WMICCard className="gw-mb-4">
            <WMICAccordionCard
                id={`locationAccordionCard${locationIndex}`}
                title={`${translator(messages.location, {locationNumber: locationVM.locationNum.value, locationAddress: locationVM.displayName.value})}`}
                chevron
                chevronAlignment='left'
                headerVariant='h2'
                headerSize='h2'
                className='gw-mb-0'
                cardBodyClassName='gw-mx-2'
            >
                <Accordion id={`locationCoveragesAccordian${locationIndex}`}
                    showFrame={false}
                    closeOthers={false}
                    defaultOpenedId={`locationCoveragesAccordianCard${locationIndex}`}
                >
                    <WMICCPCoverageBuildings
                        id={`locationCoverageBuildings${locationIndex}`}
                        {...coverageBuildingsProps}/>
                    <WMICAccordionCard
                        id={`locationCoveragesAccordianCard${locationIndex}`}
                        title={messages.locationcoverages}
                        chevron
                        chevronAlignment='left'
                        headerVariant='h2'
                        headerSize='h2'
                        className='gw-mb-0'
                        cardBodyClassName='gw-mx-2'
                    >
                        <ViewModelForm
                            model={locationVM}
                            uiProps={metadata.componentContent}
                            overrideProps={overrideProps}
                        />
                    </WMICAccordionCard>
                </Accordion>
            </WMICAccordionCard>
        </WMICCard>
    );
}

export default WMICCPLocationCoverages;
