import { defineMessages } from 'react-intl';

export default defineMessages({
    driverLicenseStatus: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Daycare Operation',
        defaultMessage: 'Daycare Operation'
    },
    daycareNumberOfChildren: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Daycare Number of Children',
        defaultMessage: 'Number of Children'
    },
    daycareQuestionnaireReceived: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Daycare Questionnaire Received',
        defaultMessage: 'Home Daycare questionnaire received?'
    },
    bedAndBreakfastOperation: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Bed and Breakfast Operation',
        defaultMessage: 'Bed and Breakfast Operation'
    },
    bedAndBreakfastCapacity: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Bed and Breakfast Number of Guests',
        defaultMessage: 'Number of Guests'
    },
    bedAndBreakfastMonths: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Bed and Breakfast Number of Months',
        defaultMessage: 'Number of Months per Year'
    },
    incidentalOfficeUse: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Incidental Office Use',
        defaultMessage: 'Incidental Office Use'
    },
    incidentalOfficeNatureOfBusiness: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Incidental Office Nature of Business',
        defaultMessage: 'Nature Of Business'
    },
    incidentalOfficeNumberOfClients: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Incidental Office Number of Clients',
        defaultMessage: 'Number of Clients Visiting Home Each Month'
    },
    incidentalOfficeNumberOfEmployeesWorkingFromHome: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Incidental Office Number of Employees',
        defaultMessage: 'Number Of Employees Working From Home'
    },
    incidentalOfficeWhereOperatedFrom: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Incidental Office Where',
        defaultMessage: 'Where on Premises is the Business Operated from'
    },
    incidentalOfficeInsuredPrimaryOccupation: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Incidental Office Insured Primary Occupation',
        defaultMessage: 'Is this the Insured\'s Primary Occupation'
    },
    incidentalOfficeCommercialPolicyInForce: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Incidental Office Commercial Policy in Force',
        defaultMessage: 'Is a Commercial Policy in Force?'
    },
    incidentalOfficeCompanyName: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Incidental Office Company Name',
        defaultMessage: 'Company Name'
    },
    incidentalOfficePolicyNumber: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Incidental Office Policy Number',
        defaultMessage: 'Policy Number'
    },
    incidentalOfficeQuestionnaireReceived: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Incidental Office Use Questionnaire Received',
        defaultMessage: 'Incidental Office Use Questionnaire Received?'
    },
    homeBasedBusinessWMIC: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Home Based Business Operation',
        defaultMessage: 'Home Based Business Operation'
    },
    shortTimeRentalUse: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Short Term Rental Use',
        defaultMessage: 'Short Term Rental Use'
    },
    shortTermRentalUseInvalidCoverageWarningMessage: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Short Term Rental Use Invalid Coverage Warning Message',
        defaultMessage: 'Short Term Rental use is selected "Yes" with a coverage type of Basic. Short Term Rental Endorsement is only available on Broad or Comprehensive coverage types.'
    },
    shortTimeRentalUseQuestionnaireReceived: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Short Term Rental Use Questionnaire Received',
        defaultMessage: 'Short Term Rental Questionnaire Received?'
    },
    shortTimeRentalUseAdvertised: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Short Term Rental Use Advertisement',
        defaultMessage: 'How do you advertise?'
    },
    shortTimeRentalUsePortionRented: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Short Term Rental Use Portion Rented',
        defaultMessage: 'Portion of home being rented?'
    },
    shortTimeRentalUseBikes: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Short Term Rental Use Bikes',
        defaultMessage: 'Do you include access or use of bikes?'
    },
    shortTimeRentalUseWatercraft: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Short Term Rental Use Watercraft',
        defaultMessage: 'Do you include access or use of watercraft?'
    },
    shortTimeRentalUseMotorizedVehicles: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Short Term Rental Use Motorized Vehicles',
        defaultMessage: 'Do you include access or use of motorized vehicles?'
    },
    shortTimeRentalUsePriorLosses: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Short Term Rental Use Prior Losses',
        defaultMessage: 'Have there been any prior losses while renting on a short term basis?'
    },
    description: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Short Term Rental Use Prior Losses Description',
        defaultMessage: 'Description'
    },
    trampolineOnThePremises: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Trampoline',
        defaultMessage: 'Trampoline On The Premises'
    },
    swimmingPool: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Swimming Pool',
        defaultMessage: 'Swimming Pool'
    },
    swimmingPoolFenced: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Swimming Pool Fenced',
        defaultMessage: 'Is the Pool Fenced'
    },
    swimmingPoolDivingBoard: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Swimming Pool Diving Board',
        defaultMessage: 'Is there a Diving Board?'
    },
    swimmingPoolType: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Swimming Pool Type',
        defaultMessage: 'Swimming Pool Type'
    },
    swimmingPoolYearBuilt: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Swimming Pool Year Built',
        defaultMessage: 'Year Built'
    },
    hotTub: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Hot Tub',
        defaultMessage: 'Hot Tub'
    },
    hotTubLocation: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Hot Tub Location',
        defaultMessage: 'Hot Tub Location'
    },
    petsOnPremises: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Pets on Premises',
        defaultMessage: 'Pets On Premises'
    },
    hoAddPetButtonContainer: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Pet Add',
        defaultMessage: 'Add Pet'
    },
    swimmingPoolTypeOther: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Swimming Pool Type Description',
        defaultMessage: 'Description'
    },
    removeAdditionalDetailsShort: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Remove Additional Details Short',
        defaultMessage: 'Remove Additional Detail'
    },
    removeAdditionalDetailsLong: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Remove Additional Details Long',
        defaultMessage: 'You are going to remove an additional detail associated to this risk. The coverage applied to the risk will also be removed. Do you wish to continue?'
    },
    seasonalCondoQuestionnaireReceived: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Seasonal Condo Questionnaire Received',
        defaultMessage: 'Seasonal Condo Questionnaire Received'
    },
    seasonalCondoQuestionnaireReceivedDate: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Seasonal Condo Questionnaire Received Date',
        defaultMessage: 'Seasonal Condo Questionnaire Received Date'
    },
    rentalCondoQuestionnaireReceived: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Rental Condo Questionnaire Received',
        defaultMessage: 'Rental Condo Questionnaire Received'
    },
    rentalCondoQuestionnaireReceivedDate: {
        id: 'wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Rental Condo Questionnaire Received Date',
        defaultMessage: 'Rental Condo Questionnaire Received Date'
    },
    homeBasedBusinessRequireProfessionalLiability: {
        id: "wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Home Based Business Require Professional Liability",
        defaultMessage: "Does the Home Based Business require a Professional Liability ( E&O) policy"
    },
    professionalLiabilityInPlace: {
        id: "wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Professional Liability in Place",
        defaultMessage: "Does the insured have a professional liability policy in place?"
    },
    salesOutsideCanada: {
        id: "wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Sales Outside Canada",
        defaultMessage: "Are there any sales outside of Canada?"
    },
    shortTermRentalAdvisedThroughRepNetwork: {
        id: "wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Short Term Rental Use Advertised Through Reputable Network",
        defaultMessage: "Is the short-term rental advertised through a reputable home rental network?"
    },
    additionalDetailsRequiredToBind: {
        id: "wmic-pe-gateway-capability-common-ho-react.DwellingAdditionalDetails.Required To Bind",
        defaultMessage: "This is required to bind the policy"
    },
});
