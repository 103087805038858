import React from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import metadata from './WMICPolicyPuWatercraftExposureForm.metadata.json5';

function WMICPolicyPuWatercraftExposureForm(props) {
    const { value: exposureVM } = props;

    const overrideProps = {
        '@field': {
            parentNode: _.get(exposureVM, 'watercraftExposure', {}),
            readOnly: true
        }
    }

    return <ViewModelForm
        uiProps={metadata.componentContent}
        model={_.get(exposureVM, 'watercraftExposure', {})}
        overrideProps={overrideProps}
    />
}

export default WMICPolicyPuWatercraftExposureForm;