/* eslint-disable no-secrets/no-secrets */
import React, { useCallback, useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale'; 
import { ViewModelForm, ViewModelServiceContext, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { HOConstants, WMICRPCUtil, WMICRichTextUtil } from 'wmic-pe-portals-utils-js';
import WMICAddRoofCoverageComponent from './WMICAddRoofCoverage/WMICAddRoofCoverageComponent';
import WMICAddPlumbingMaterialComponent from './WMICAddPlumbingMaterial/WMICAddPlumbingMaterialComponent';
import roofMessages from './WMICAddRoofCoverage/WMICAddRoofCoverageComponent.messages';
import plumbingMaterialMessages from './WMICAddPlumbingMaterial/WMICAddPlumbingMaterialComponent.messages';
import messages from './WMICHOConstructionRoofElectricalPlumbing.messages';
import metadata from './WMICHOConstructionRoofElectricalPlumbing.metadata.json5';

function WMICHOConstructionRoofElectricalPlumbing(props) {
    const {
        id,
        riskView,
        jobVM,
        updateDetails,
        showErrors,
        isReadOnlyUser,
        isEditMode,
        onValidate,
        selectedIndex
    } = props;

    const EMPTY_STRING = "";

    const translator = useContext(TranslatorContext);
    const ViewModelService = useContext(ViewModelServiceContext);
    const { refreshData } = useDataRefresh();
    const { onValidate: setComponentValidation, isComponentValid, registerComponentValidation } = useValidation(id);

    const dwelling = _.get(jobVM, `lobData.homeowners.coverables.dwellings.children[${selectedIndex}]`);
    const riskType = dwelling?.rating.hoPolicyType.value.code;
    const rateAsOfDate = _.get(jobVM, 'baseData.rateAsOfDate.value');

    const isHomeownerDwelling = riskType === HOConstants.homeownerPolicyType;
    const isRevenuePropertyRiskType = riskType === HOConstants.revenuePropertyPolicyType;
    const isPersonalPropertyExtension = riskType === HOConstants.extensionPolicyType;

    const riskLocation = dwelling?.yourHome.locationAddress.state.value.code;
    const ratingJurisdiction = dwelling?.rating.ratingJurisdiction.value.code;
    const is1219EffectiveHO = isHomeownerDwelling && WMICRPCUtil.getIsRPCEffective(riskLocation, rateAsOfDate, '1219')
    const is1219EffectivePPE = isPersonalPropertyExtension && WMICRPCUtil.getIsRPCEffective(riskLocation, rateAsOfDate, '1219')
    const is1222EffectiveRP = isRevenuePropertyRiskType && WMICRPCUtil.getIsRPCEffective(riskLocation, rateAsOfDate, '1222')
    const validateRoofFlag = is1219EffectiveHO || is1222EffectiveRP || is1219EffectivePPE;

    const [selectedRoofCoverage, updateSelectedRoofCoverage] = useState();
    const [addingRoofCoverage, setAddingRoofCoverage] = useState(false);

    const [selectedPlumbingMaterial, updateSelectedPlumbingMaterial] = useState();
    const [addingPlumbingMaterial, setAddingPlumbingMaterial] = useState(false);

    const [editingIndex, updateEditingIndex] = useState(-1);

    const [roofValid, updateRoofValid] = useState(true);
    const [showRoofPercentageError, updateRoofPercentageError] = useState(false);

    useEffect(() => {
        if(onValidate){
            onValidate(isComponentValid && !addingPlumbingMaterial && !addingRoofCoverage)
        }
    }, [onValidate, isComponentValid, addingPlumbingMaterial, addingRoofCoverage])

    useEffect(() => {
        registerComponentValidation(checkIfPlumbingIsValid);
        registerComponentValidation(checkIfRoofIsValid);
    }, [checkIfPlumbingIsValid, checkIfRoofIsValid, registerComponentValidation]);

    const updateDetailsData = useCallback(
        (data) => {
            refreshData();
            updateDetails(data);
        },
        [refreshData, updateDetails]
    );

    // ROOF COVERAGE
    
    const isRoofValidationApplicable = useCallback(() => {
        const isRPC1554Effective = WMICRPCUtil.getIsRPCEffectiveForRiskType(ratingJurisdiction, rateAsOfDate, riskType, '1554');

        return !_.get(dwelling, 'prevDwelling.value') && (isRPC1554Effective);
    }, [dwelling, rateAsOfDate, ratingJurisdiction, riskType]);

    const isAtLeastOneRoofCoveringRequired = useCallback(() => {
        const roofCoverings = _.get(riskView, 'constructionDetail.roofInfo.coverings', [])
        return roofCoverings.length === 0 && isRoofValidationApplicable();
    }, [riskView, isRoofValidationApplicable]);

    const showRoofInformationSuggestedMessage = useCallback(() => {
        const roofCoverings = _.get(riskView, 'constructionDetail.roofInfo.coverings', [])
        return roofCoverings.length === 0 && !isAtLeastOneRoofCoveringRequired();
    }, [riskView, isAtLeastOneRoofCoveringRequired]);

    const checkIfRoofIsValid = useCallback(() => !isAtLeastOneRoofCoveringRequired(), [isAtLeastOneRoofCoveringRequired]);

    const addNewRoofCoverage = () => {

        const editableRoofCoverage = ViewModelService.create(
            {},
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.wing.DWIGRoofCoveringDTO_WMIC'
        );

        updateEditingIndex(-1);
        updateSelectedRoofCoverage(editableRoofCoverage);
        updateDetails(riskView);
        setAddingRoofCoverage(true);
    };

    const saveRoofCoverage = (roofCoverage) => {
        if (editingIndex < 0) {
            riskView.constructionDetail.roofInfo.coverings.value.push(roofCoverage.value);
        } else {
            riskView.constructionDetail.roofInfo.coverings.setElement(editingIndex, roofCoverage.value);
        }

        updateDetails(riskView);
        setAddingRoofCoverage(false);
        validateRoofPercentage();
        checkErrorMessages();
    };

    const cancelRoofCoverage = () => {
        updateSelectedRoofCoverage(undefined);
        updateDetails(riskView);
        setAddingRoofCoverage(false);
    };

    const editRoofCoverage = (roofCoverage) => {
        setAddingRoofCoverage(true);

        const editableRoofCoverage = ViewModelService.create(
            roofCoverage.value,
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.wing.DWIGRoofCoveringDTO_WMIC'
        );

        updateSelectedRoofCoverage(editableRoofCoverage);
        updateDetails(riskView);
    };

    const isEffectiveForRPC1732 = [HOConstants.homeownerPolicyType, HOConstants.revenuePropertyPolicyType, HOConstants.mobileHomeownerPolicyType].includes(riskType)
        && WMICRPCUtil.getIsRPCEffectiveForRiskType(ratingJurisdiction, rateAsOfDate, riskType, '1732');


    const isPlumbingValidationApplicable = useCallback(() => {
        const isRPC1554Effective = WMICRPCUtil.getIsRPCEffectiveForRiskType(ratingJurisdiction, rateAsOfDate, riskType, '1554');

        return !_.get(dwelling, 'prevDwelling.value') && (isEffectiveForRPC1732 || isRPC1554Effective);
    }, [dwelling, rateAsOfDate, ratingJurisdiction, riskType]);

    const isAtLeastOnePlumbingRequired = useCallback(() => {
        const plumbingMaterials = _.get(riskView, 'constructionDetail.plumbingInfo.materialDetails', [])
        return plumbingMaterials.length === 0
            && isPlumbingValidationApplicable();
    }, [riskView, isPlumbingValidationApplicable]);

    const checkIfPlumbingIsValid = useCallback(() => !isAtLeastOnePlumbingRequired(), [isAtLeastOnePlumbingRequired]);

    const roofInfoCoverings = _.get(riskView, 'value.constructionDetail.roofInfo.coverings', []).length;
    const checkErrorMessages = useCallback(() =>{
        if (is1219EffectiveHO || is1219EffectivePPE) {
            updateRoofValid(!((roofInfoCoverings === 0) || showRoofPercentageError));
        } else {
            updateRoofValid(true);
        }
    }, [is1219EffectiveHO, is1219EffectivePPE, roofInfoCoverings, showRoofPercentageError])

    const validateRoofPercentage = () => {
        let total = 0;
        let count = 0;
        _.each(riskView.value.constructionDetail.roofInfo.coverings, (covering) => {
            if (covering.percentage) {
                total += parseInt(covering.percentage, 10);
                count += 1;
            }
        });

        updateRoofPercentageError((total !== 100 && count > 0) && validateRoofFlag);
    };

    const removeRoofCoverage = useCallback((item) => {
        const index = riskView.value.constructionDetail.roofInfo.coverings.indexOf(item.value);
        riskView.value.constructionDetail.roofInfo.coverings.splice(index, 1);
        checkErrorMessages();
        updateDetailsData(riskView);
    }, [checkErrorMessages, riskView, updateDetailsData]);

    // PLUMBING MATERIAL
    const addNewPlumbingMaterial = () => {

        const editablePlumbingMaterial = ViewModelService.create({},
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.wing.DWIGPlumbingMaterialDTO_WMIC'
        );

        updateEditingIndex(-1);
        updateSelectedPlumbingMaterial(editablePlumbingMaterial);
        updateDetails(riskView);
        setAddingPlumbingMaterial(true);
    };

    const savePlumbingMaterial = (plumbingMaterial) => {
        if (editingIndex < 0) {
            riskView.constructionDetail.plumbingInfo.materialDetails.value.push(plumbingMaterial.value);
        } else {
            riskView.constructionDetail.plumbingInfo.materialDetails.setElement(editingIndex, plumbingMaterial.value);
        }

        updateDetails(riskView);
        setAddingPlumbingMaterial(false);
    };

    const cancelPlumbingMaterial = () => {
        updateSelectedPlumbingMaterial(undefined);
        updateDetails(riskView);
        setAddingPlumbingMaterial(false);
    };

    const editPlumbingMaterial = (plumbingMaterial) => {
        setAddingPlumbingMaterial(true);

        const editablePlumbingMaterial = ViewModelService.create(
            plumbingMaterial.value,
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.wing.DWIGPlumbingMaterialDTO_WMIC'
        );

        updateSelectedPlumbingMaterial(editablePlumbingMaterial);
        updateDetails(riskView);
    };

    const removePlumbingMaterial = useCallback((item) => {
        const index = riskView.value.constructionDetail.plumbingInfo.materialDetails.indexOf(item.value);
        riskView.value.constructionDetail.plumbingInfo.materialDetails.splice(index, 1);
        updateDetailsData(riskView);
    }, [riskView, updateDetailsData]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => {
            if(onValidate){
                onValidate(true, id)
            }
        }
    }, [id, isComponentValid, onValidate]);

    const sharedValidationProps = {
        onValidate: setComponentValidation,
        showErrors
    }

    const getRequiredToBindLabelForElectricalInfo = (electricalProperty) => {
        const wiring = _.get(riskView, "constructionDetail.electricalInfo.wiring.value.code");
        if (_.get(riskView, "constructionDetail.electricalInfo.isElectricalInfoRequiredToBind.value")) {
            return _.get(riskView, electricalProperty) || wiring === "no_wiring" ? EMPTY_STRING : translator(messages.electricalInfoRequiredToBind);
        }
        return EMPTY_STRING;
    };

    const electricalInspectionCompletedDateChanged = (value, path) => {
        _.set(riskView, path, value);
        if (!value) {
            _.set(riskView, 'constructionDetail.electricalInfo.unknownElectricalCompleteDate.value', false);
        }
        updateDetailsData(riskView);
    };

    const overrideProps = {
        '@field': {
            parentNode: riskView,
            readOnly: !isEditMode || isReadOnlyUser
        },
        atLeastOneRoofSuggestedContainer: {
            visible: showRoofInformationSuggestedMessage()
        },
        atLeastOneRoofRequiredContainer: {
            visible: isAtLeastOneRoofCoveringRequired()
        },
        atLeastOneRoofText: {
            content: WMICRichTextUtil.translateRichText(translator(messages.atLeastOneRoof)),
        },
        showPlumbingWarningMsg: {
            visible: isAtLeastOnePlumbingRequired()
        },
        roofCoverageDataList: {
            VMList: _.get(riskView, 'constructionDetail.roofInfo.coverings.children', []),
            VMData: [
                {
                    headerText: translator(roofMessages.coveringType),
                    path: 'description'
                },
                {
                    headerText: translator(roofMessages.roofAdditionalDescription),
                    path: 'additionalDescription'
                },
                {
                    headerText: translator(roofMessages.roofPercentage),
                    path: 'percentage'
                }
            ],
            onEditAction: editRoofCoverage,
            onRemoveAction: removeRoofCoverage,
            flatCards: true,
            clickable: true,
            selectedCardIndex: editingIndex,
            updateSelectedCardIndex: updateEditingIndex,
            isEditing: !isEditMode || addingRoofCoverage,
            readOnly: !isEditMode || isReadOnlyUser,
            onClickAction: (data) => updateSelectedRoofCoverage(data)
        },
        roofCoverageForm: {
            ...sharedValidationProps,
            visible: addingRoofCoverage  || !_.isUndefined(selectedRoofCoverage),
            selectedRoofCoverage,
            updateSelectedRoofCoverage,
            saveRoofCoverage,
            riskView,
            updateDetails,
            cancelRoofCoverage,
            readOnly: !addingRoofCoverage
        },
        plumbingMaterialDataList: {
            VMList: _.get(riskView, 'constructionDetail.plumbingInfo.materialDetails.children', []),
            VMData: [
                {
                    headerText: translator(plumbingMaterialMessages.plumbingType),
                    path: 'description'
                },
                {
                    headerText: translator(plumbingMaterialMessages.plumbingAdditionalDescription),
                    path: 'additionalDescription'
                },
                {
                    headerText: translator(plumbingMaterialMessages.plumbingMaterialPercentage),
                    path: 'percentage'
                }
            ],
            onEditAction: editPlumbingMaterial,
            onRemoveAction: removePlumbingMaterial,
            flatCards: true,
            clickable: true,
            selectedCardIndex: editingIndex,
            updateSelectedCardIndex: updateEditingIndex,
            isEditing: !isEditMode || addingPlumbingMaterial,
            readOnly: !isEditMode || isReadOnlyUser,
            onClickAction: (data) => updateSelectedPlumbingMaterial(data)
        },
        plumbingMaterialForm: {
            ...sharedValidationProps,
            visible: addingPlumbingMaterial || !_.isUndefined(selectedPlumbingMaterial),
            selectedPlumbingMaterial,
            updateSelectedPlumbingMaterial,
            savePlumbingMaterial,
            riskView,
            updateDetails,
            cancelPlumbingMaterial,
            readOnly: !addingPlumbingMaterial
        },
        addRoofTypeButton: {
            disabled: !isEditMode,
            visible: isEditMode
        },
        addPlumbingTypeButton: {
            disabled: !isEditMode,
            visible: isEditMode
        },
        electricalWiring: {
            secondaryLabel: getRequiredToBindLabelForElectricalInfo('constructionDetail.electricalInfo.value.wiring')
        },
        electricalPanelType: {
            secondaryLabel: getRequiredToBindLabelForElectricalInfo('constructionDetail.electricalInfo.value.panelType')
        },
        electricalNumberAmps: {
            secondaryLabel: getRequiredToBindLabelForElectricalInfo('constructionDetail.electricalInfo.value.panelAmps')
        },
        electricalInspectionCompletedDate: {
            onValueChange: electricalInspectionCompletedDateChanged
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            addNewRoofCoverage,
            addNewPlumbingMaterial
        },
        resolveComponentMap: {
            WMICAddRoofCoverageComponent,
            WMICAddPlumbingMaterialComponent
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={riskView}
            overrideProps={overrideProps}
            onModelChange={updateDetailsData}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

WMICHOConstructionRoofElectricalPlumbing.propTypes = {
    riskView: PropTypes.shape({}),
    isEditMode: PropTypes.bool.isRequired,
    showErrors: PropTypes.bool.isRequired,
    onValidate: PropTypes.func.isRequired
};

WMICHOConstructionRoofElectricalPlumbing.defaultProps = {
    riskView: {},
};

export default WMICHOConstructionRoofElectricalPlumbing;
