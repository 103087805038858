/* Utilities for the aspect writers. */

export default {
    getDriverLicenseType: (licenseCountry) => {
        if (!licenseCountry || !licenseCountry.code) {
            return '';
        }
        if (['CA', 'US'].includes(licenseCountry.code)) {
            return 'DOMESTIC_LICENSE';
        }
        if (licenseCountry.typelist.getFilter('ReciprocalLicenses').allows(licenseCountry)) {
            return 'FOREIGN_LICENSE';
        }
        return 'INTERNATIONAL_DRIVER_PERMIT';
    },

    validateLicenseNumber: (licenseNumber, jurisdiction) => {
        let valid = false;
        let code = null;
        if (jurisdiction) {
            // eslint-disable-next-line prefer-destructuring
            code = jurisdiction.code;
        }
        switch (code) {
            case 'AB':
                valid = /^\d{1,6}-\d{3}$/.test(licenseNumber);
                break;
            case 'YT':
                valid = /^\d{1,6}$/.test(licenseNumber);
                break;
            case 'ON':
                valid = /^[a-zA-Z]\d{4}-\d{5}-\d{5}$/.test(licenseNumber);
                break;
            case 'NB':
                valid = /^[1-9][0-9]{3,6}$/.test(licenseNumber);
                break;
            case 'NS':
                valid = /^[a-zA-Z]{1,5} {0,4}\d{9}$/.test(licenseNumber);
                break;
            case 'PE':
                valid = /^[0-9]{4}[0-9]{6}\d\d$|^\d{1,6}$/.test(licenseNumber);
                break;
            case 'QC':
                valid = /^[a-zA-Z]\d{10}[a-zA-Z0-9]\d$/.test(licenseNumber);
                break;
            default:
                valid = licenseNumber.length <= 18;
                break;
        }
        return valid;
    },

    requiresTrainingCourse: (periodStartDate, driverClassHistories) => {
        let found = false;
        if (periodStartDate !== undefined) {
            const datePeriodStart = new Date(periodStartDate.year,
                periodStartDate.month,
                periodStartDate.day);
            if (driverClassHistories) {
                for (let counter = 0; !found && counter < driverClassHistories.length; counter++) {
                    const driverClassHistory = driverClassHistories[counter];
                    if (driverClassHistory) {
                        const dateLicensed = new Date(driverClassHistory.dateLicensed.year,
                            driverClassHistory.dateLicensed.month,
                            driverClassHistory.dateLicensed.day);
                        const yearsLicensed = moment(datePeriodStart).diff(dateLicensed, 'years', false);
                        found = (driverClassHistory.driverClassType_WMIC === '5' || driverClassHistory.driverClassType_WMIC === '5GDL')
                            && (yearsLicensed >= 0 && yearsLicensed < 1);
                    }
                }
            }
        }
        return found;
    },

    isAssignedToCommercialVehiclesOnly: (driverPublicId, vehicles) => {
        let numOfDrivers = 0;
        let drivers;
        let isAssignedToAVehicle = false;
        // if no vehicles
        if (vehicles === undefined || vehicles.length === 0) {
            return false;
        }
        for (let i = 0; i < vehicles.length; i++) {
            drivers = vehicles[i].drivers;
            numOfDrivers = (drivers !== undefined) ? drivers.length : 0;
            for (let j = 0; j < numOfDrivers; j++) {
                if (drivers[j].driverPublicID === driverPublicId) {
                    isAssignedToAVehicle = true;
                    if (vehicles[i].businessSegment_WMIC === 'Personal') {
                        return false;
                    }
                }
            }
        }
        // if driver is not assigned to any vehicle
        return isAssignedToAVehicle;

    },

    isAssignedToAnyCommercialVehicle: (driverPublicId, vehicles) => {
        let numOfDrivers = 0;
        let drivers;
        if (vehicles === undefined || vehicles.length === 0) {
            return false;
        }
        for (let i = 0; i < vehicles.length; i++) {
            drivers = vehicles[i].drivers;
            numOfDrivers = (drivers !== undefined) ? drivers.length : 0;
            for (let j = 0; j < numOfDrivers; j++) {
                if (drivers[j].driverPublicID === driverPublicId) {
                    if (vehicles[i].businessSegment_WMIC === 'Commercial') {
                        return true;
                    }
                }
            }
        }
        // if driver is not assigned to any vehicle OR driver is not assigned to any commercial vehicle
        return false;
    }
}
