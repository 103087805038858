/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import _ from 'lodash';


const PUPSubmissionLobDataExtension = function (submission) {
    const lobData = _.get(submission, 'lobData.personalUmbrella');

    if (lobData && _.isObject(lobData)) {
        this.preQualQuestionSets = lobData.preQualQuestionSets;
        this.coverables = lobData.coverables;
        this.liabilityCoverages = lobData.liabilityCoverages;
        this.linePublicID_WMIC = lobData.linePublicID_WMIC;
    } else {
        this.preQualQuestionSets = [];
        this.coverables = lobData.coverables || {};
        this.liabilityCoverages = lobData.liabilityCoverages || [];
    }
};


export default PUPSubmissionLobDataExtension;
