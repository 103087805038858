import { defineMessages } from 'react-intl';

export default defineMessages({
    summary: {
        id: 'agent.views.policy-detail-summary.Summary',
        defaultMessage: 'Summary'
    },
    contacts: {
        id: 'agent.views.policy-detail-summary.Contacts',
        defaultMessage: 'Contact Details'
    },
    billing: {
        id: 'agent.views.policy-detail-summary.Billing',
        defaultMessage: 'Billing'
    },
    transactions: {
        id: 'agent.views.policy-detail-summary.Transactions',
        defaultMessage: 'Transactions'
    },
    documents: {
        id: 'agent.views.policy-detail-summary.Documents',
        defaultMessage: 'Documents'
    },
    insured: {
        id: 'agent.views.policy-detail-summary.Insured',
        defaultMessage: 'Insured'
    },
    drivers: {
        id: 'agent.views.policy-detail-summary.Drivers',
        defaultMessage: 'Drivers'
    },
    vehicles: {
        id: 'agent.views.policy-detail-summary.Vehicles',
        defaultMessage: 'Vehicles'
    },
    coverage: {
        id: 'agent.views.policy-detail-summary.Coverage',
        defaultMessage: 'Coverages'
    },
    policyDetail: {
        id: 'agent.views.policy-detail-summary.PolicyDetail',
        defaultMessage: 'Policy Detail'
    },
    underlyingPolicies: {
        id: 'agent.views.policy-detail-summary.UnderlyingPolicies',
        defaultMessage: 'Underlying Policies'
    },
    householdMembers: {
        id: 'agent.views.policy-detail-summary.HouseHoldMembers',
        defaultMessage: 'Household Members'
    },
    insuranceHistory: {
        id: 'agent.views.policy-detail-summary.Insurance History',
        defaultMessage: 'Insurance History'
    },
    risk: {
        id: 'agent.views.policy-detail-summary.Risk',
        defaultMessage: 'Risk'
    },
    construction: {
        id: 'agent.views.policy-detail-summary.Construction',
        defaultMessage: 'Construction'
    },
    scheduledItems: {
        id: 'agent.views.policy-detail-summary.Scheduled Items',
        defaultMessage: 'Scheduled Items'
    },
    errorRetrievingPolicy: {
        id: 'agent.views.policy-detail-summary.Error retrieving policy',
        defaultMessage: 'Error retrieving policy'
    }
});
