import { defineMessages } from 'react-intl';

export default defineMessages({
    addAdditionalInterest: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterests.Add',
        defaultMessage: 'Add Additional Interest'
    },
    aiPerson: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterests.Person',
        defaultMessage: 'Person'
    },
    aiCompany: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterests.Company',
        defaultMessage: 'Company'
    },
    descriptionOfInterestOption: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterests.DescriptionOfInterestOther',
        defaultMessage: 'Describe Other Interest'
    },
    descriptionOfInterestOther: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterests.Additional Interest Description',
        defaultMessage: 'Describe Other Interest'
    },
    descriptionOfInterestOtherType: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterests.Additional Interest Description Type',
        defaultMessage: 'Describe Other Interest Type'
    },
    additionalInterestType: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterests.coverage.AdditionalInterestType',
        defaultMessage: 'Additional Interest Type'
    },
    companyContactDetails: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterests.CompanyContactDetails',
        defaultMessage: 'Company Contact Details'
    },
    addNewCompanyText: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterests.addNewCompany',
        defaultMessage: 'If you cannot find the company you are looking for you may add a new company below.'
    },
    removeAdditionalInterestShort: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterests.Remove Interest Short',
        defaultMessage: 'Remove Additional Interest'
    },
    removeAdditionalInterestLong: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOCovAdditionalInterests.Remove Interest Long',
        defaultMessage: 'Are you sure you want to remove the additional interest from the list?'
    },


});
