import React, { useCallback, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { useWizardModals, WMICWizardSubmissionPage } from 'wmic-pe-portals-wizard-components-ui';
import { TranslatorContext } from '@jutro/locale'
import { WMICUnderwritingIssues } from 'wmic-pe-components-platform-react';
import { DocumentService } from 'wmic-pe-capability-gateway-policycommon';
import { WMICDocuments } from 'wmic-pe-capability-gateway-common-react';
import { CONSTANTS } from 'wmic-pe-portals-utils-js';
import WMICQuoteUtil from 'wmic-pe-capability-quoteandbind-common-react/util/WMICQuoteUtil';

// eslint-disable-next-line import/no-unresolved
import { messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import { messages } from './WMICCPQuote.messages';
import metadata from './WMICCPQuote.metadata.json5';

function WMICCPQuote(props) {
    const { wizardData: submissionVM, updateWizardData, markStepSubmitted, stopErrorNavigation } = props;
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const { isComponentValid, onValidate, initialValidation, registerInitialComponentValidation } = useValidation('QuotePage');
    const translator = useContext(TranslatorContext);
    const [userDocuments, setUserDocuments] = useState([]);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { setWizardLoading } = useWizardModals();

    const [underwritingIssues, updateUnderwritingIssues] = useState([]);

    const [hasQuoteBlockingUnderwritingIssues, setHasQuoteBlockingUnderwritingIssues] = useState(false);
    const [hasBlockingUnderwritingIssues, setHasBlockingUnderwritingIssues] = useState(false);

    useEffect(() => {
        updateUnderwritingIssues(_.get(submissionVM, 'errorsAndWarnings.underwritingIssues.value', []));
        setHasBlockingUnderwritingIssues(WMICQuoteUtil.isBlockingUWIssuePresent(submissionVM))
    }, [submissionVM]);

    useEffect(() => {
        setHasQuoteBlockingUnderwritingIssues(WMICQuoteUtil.isQuoteBlockingUWIssuePresent(underwritingIssues))
    }, [underwritingIssues]);

    useEffect(() => {
        validateSubmission();
    }, [validateSubmission]);

    const validateSubmission = useCallback(async () => {
        setWizardLoading(true);
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.unset(newSubmissionVM.value, 'bindData');
        const result = await LoadSaveService.validateSubmissionForIssue(newSubmissionVM.value, authHeader);
        _.extend(submissionVM.value, result);
        updateWizardData(submissionVM);
        markStepSubmitted(); // the step needs to be marked as submitted for the errors associated with this step to show up
        setWizardLoading(false);
    }, [LoadSaveService, authHeader, submissionVM, updateWizardData, viewModelService, setWizardLoading, markStepSubmitted]);

    useEffect(() => {
        registerInitialComponentValidation(() => !WMICQuoteUtil.isBlockingUWIssuePresent(submissionVM));
        stopErrorNavigation()
    }, [registerInitialComponentValidation, stopErrorNavigation, submissionVM]);

    useEffect(() => {
        fetchDocuments()
    }, [fetchDocuments, userDocuments])

    const fetchDocuments = useCallback(async () => {
        try {
            const documents = await DocumentService.getDocumentsForJobNumber(submissionVM.quoteID.value, authHeader)
            
            if (userDocuments.length !== documents.length){
                setUserDocuments(documents);
            }
        } catch(err) {
            //TODO: BPN-3441
            return []
        }
    }, [authHeader, submissionVM, userDocuments]);

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const onNext = useCallback(async () => {
        try {
            setWizardLoading(true);

            if (!isComponentValid) {
                return false;
            }

            if (hasBlockingUnderwritingIssues || hasQuoteBlockingUnderwritingIssues
                || _.get(submissionVM, 'baseData.periodStatus.value.code') !== CONSTANTS.QUOTED) {
                scrollToTop();
                return false;
            }

            return submissionVM;
        } finally {
            setWizardLoading(false);
        }
    }, [setWizardLoading, submissionVM, isComponentValid, hasBlockingUnderwritingIssues, hasQuoteBlockingUnderwritingIssues]);

    const overrideProps = {
        wmicFlexAlertMessage: {
            jobVM: submissionVM,
        },
        wmicQuoteAlertMessage: {
            jobVM: submissionVM
        },
        quotePagePremiumCosts: {
            premium: _.get(submissionVM, 'quoteData.offeredQuotes.value[0].premium', {}),
            jobVM: submissionVM,
            updateJobVM: updateWizardData,
            enableFlex: false,
            enableBrokerFees: true,
            visible: !hasQuoteBlockingUnderwritingIssues
        },
        quotePageUWIssues: {
            underwritingIssues: underwritingIssues,
            quoteID: _.get(submissionVM, 'quoteID.value'),
            authHeader: authHeader,
            jobVM: submissionVM
        },
        quotePageSummary: {
            jobVM: submissionVM,
            visible: !hasQuoteBlockingUnderwritingIssues
        },
        quotePageUploadDocuments: {
            documents: userDocuments,
            updateDocuments: setUserDocuments,
            value: {
                documentDTOs: userDocuments,
                canUploadDocument: true,
                jobNumber: submissionVM.quoteID.value
            },
            visible: !hasQuoteBlockingUnderwritingIssues
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICUnderwritingIssues,
            WMICDocuments,
        }
    };

    return (
        <WMICWizardSubmissionPage
            onNext={onNext}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            cancelLabel={translator(commonMessages.saveAndExit)}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
            />
        </WMICWizardSubmissionPage>
    );
}

WMICCPQuote.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    ...wizardProps
};

export default WMICCPQuote;
