import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {IntlContext} from '@jutro/locale';

const WMICIntlFormattedDateCell = ({date}) => {
    const intl = useContext(IntlContext)
    const formattedDate = intl.formatDate(new Date(date), {year: 'numeric', month: 'short', day: 'numeric'});
    return <>{formattedDate}</>;
};

WMICIntlFormattedDateCell.propTypes = {
    date: PropTypes.string
};

export default WMICIntlFormattedDateCell;
