import { defineMessages } from 'react-intl';

export default defineMessages({
    flexProgram: {
        id: 'wmic-pe-capability-gateway-common-react.FlexProgramModal.Flex Program',
        defaultMessage: 'Flex Program'
    },
    cancelBtnLabel: {
        id: 'wmic-pe-capability-gateway-common-react.FlexProgramModal.Cancel',
        defaultMessage: 'Cancel'
    },
    recalculateBtnLabel: {
        id: 'wmic-pe-capability-gateway-common-react.FlexProgramModal.Recalculate',
        defaultMessage: 'Recalculate'
    },
    flexProgramNarrative: {
        id: 'wmic-pe-capability-gateway-common-react.FlexProgramModal.The Flex Program allows...',
        defaultMessage: 'The Flex Program allows you to modify the premium costs on some risks. Recalculate will quote the policy again using the estimated target premiums.'
    },
    flexRiskHeader: {
        id: 'wmic-pe-capability-gateway-common-react.FlexProgramModal.Risk #...',
        defaultMessage: 'Risk #{riskNumber}: {displayName}'
    },
    flexSaving: {
        id: 'wmic-pe-capability-gateway-common-react.Saving Quote Submission Details',
        defaultMessage: 'Saving Quote Submission Details'
    }
});
