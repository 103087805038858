import { defineMessages } from 'react-intl';

export default defineMessages({
    MVRDate: {
        id: 'policy.pa.views.pa-drivers-details.mvr.date',
        defaultMessage: 'Date'
    },
    MVRType: {
        id: 'policy.pa.views.pa-drivers-details.mvr.type',
        defaultMessage: 'Type'
    },
    MVRPredictorScore: {
        id: 'policy.pa.views.pa-drivers-details.mvr.predictor-score',
        defaultMessage: 'Predictor Score'
    },
    MVRViolationsSuspensions: {
        id: 'policy.pa.views.pa-drivers-details.mvr.violations-suspensions-table',
        defaultMessage: 'Violations / Suspensions'
    },
    yesIncidentLength: {
        id: 'policy.pa.views.pa-drivers-details.mvr.yesIncidentLength',
        defaultMessage: 'Yes ({ incidents })'
    },
    No: {
        id: 'policy.pa.views.pa-drivers-details.mvr.no',
        defaultMessage: 'No'
    },
})