import React, { useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { WMICRichTextUtil } from 'wmic-pe-portals-utils-js';
import _ from 'lodash';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './WMICRequiredText.module.scss';

const WMICRequiredText = (props) => {
    const { className, message } = props;
    const translator = useContext(TranslatorContext);

    const REQUIRED_MESSAGE = {
        "id": "wmic.agent.input.required-field",
        "defaultMessage": "Indicates a required field"
    };

    return _.isEmpty(message) ? (
        <div className={cx(styles.requiredField, "gw-mb-4", className)}>
            {WMICRichTextUtil.translateRichText(translator(REQUIRED_MESSAGE))}
        </div>
    ) : (
        <div className={cx("gw-mb-4", className)}>
            <span className="fa fa-fw fa-asterisk requiredIconColor gw-mr-1" />
            <span>{WMICRichTextUtil.translateRichText(translator(message))}</span>
        </div>
    );
}

WMICRequiredText.propTypes = {
    message: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    })
};

export default WMICRequiredText;
