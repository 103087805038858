/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalNext,
} from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { MetadataContent } from '@jutro/uiconfig';
import { WMICButton } from 'wmic-pe-components-platform-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { useDataRefresh } from '@xengage/gw-portals-viewmodel-react';

import messages from './WMICMakePaymentModal.messages';
import metadata from './WMICMakePaymentModal.metadata.json5';
import styles from './WMICMakePaymentModal.module.scss';
import policyBillingMessages from '../WMICPolicyBilling.messages';

const WMICMakePaymentModal = ({ isOpen, onResolve, onReject, amountDueVM }) => {
    const translator = useContext(TranslatorContext);
    const { refreshData } = useDataRefresh();
    const amount = _.get(amountDueVM, "amount.value");
    const validAmount = amount > 0;

    const handleSubmit = () => {
        onResolve(amountDueVM.value);
    };

    const updatePaymentAmount = (value)=> {
        _.set(amountDueVM, 'value', value);
        refreshData();
    };

    const overrideProps = {
        paymentAmount: {
            value: amountDueVM.value,
            onValueChange: updatePaymentAmount,
        },
        currencyRangeError: {
            visible: !validAmount
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={onReject}
            className={styles.appropriateDesktopWidth}
        >
            <ModalHeader
                title={translator(policyBillingMessages.makeAPayment)}
            />
            <ModalBody>
                <MetadataContent 
                    uiProps={metadata.pageContent} 
                    overrideProps={overrideProps} 
                    {...resolvers} 
                />
            </ModalBody>
            <ModalFooter>
                <WMICButton onClick={onReject} type="secondary">
                    {translator(commonMessages.no)}
                </WMICButton>
                <WMICButton 
                    onClick={handleSubmit} 
                    type="primary"
                    disabled={!validAmount}
                >
                    {translator(commonMessages.yes)}
                </WMICButton>
            </ModalFooter>
        </ModalNext>
    );
};

WMICMakePaymentModal.propTypes = {
    amountDueVM: PropTypes.shape({}).isRequired,
};

export default WMICMakePaymentModal;
