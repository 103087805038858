import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import metadata from './WMICAddCustomizationComponent.metadata.json5';

function WMICAddCustomizationComponent(props){
    const {
        id,
        selectedCustomization, 
        saveCustomization,
        cancelCustomization,
        onValidate
    } = props;

    const {onValidate: setComponentValidation, isComponentValid} = useValidation(id);

    const [currentCustomization, updateCurrentCustomization] = useState(selectedCustomization);
    const { refreshData } = useDataRefresh();
    const [showErrors, updateShowErrors] = useState(false);

    useEffect(() => {
        updateCurrentCustomization(selectedCustomization);
    }, [selectedCustomization])

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [isComponentValid, onValidate, currentCustomization, id]);

    const updateCustomization = (data) => {
        refreshData();
        updateCurrentCustomization(data);
    };

    const handleSaveCustomization = () => {
        if(isComponentValid) {
            saveCustomization(currentCustomization)
        } else {
            updateShowErrors(true)
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            handleSaveCustomization,
            cancelCustomization
        },
        resolveComponentMap: {},
        resolveClassNameMap: {}
    };

    const overrideProps = {
        '@field': {
            parentNode: currentCustomization,
            readOnly: false
        },
        attachedMachineryExclusionContainer: {
            visible: _.get(currentCustomization, 'showAttachedMachineryExclusionContainer.aspects.ocular', false)
        },
        vehicleCustomizationAttachedMachinery: {
            visible: _.get(currentCustomization, 'excludingOperationOfAttachedMachinery.aspects.ocular', false)
        }
    };


    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentCustomization}
            overrideProps={overrideProps}
            onModelChange={updateCustomization}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    )
}

WMICAddCustomizationComponent.propTypes = {
    id: PropTypes.string.isRequired,
    selectedCustomization: PropTypes.shape({}).isRequired,
    saveCustomization: PropTypes.func.isRequired,
    cancelCustomization: PropTypes.func.isRequired,
    updateSelectedCustomization: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired
};

export default WMICAddCustomizationComponent;