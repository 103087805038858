import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { TranslatorContext } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { Icon } from '@jutro/components';
import { AccountService } from 'wmic-pe-capability-gateway-policy';
import { WMICProgressModal } from 'wmic-pe-capability-quoteandbind-common-react';
import { withViewModelService, ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { DeviceBreakpoint, ICON_NAMES } from 'wmic-pe-portals-utils-js';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { Constants } from './AccountSearchConstants';
import messages from './AccountSearchComponents.messages';
import metadata from './AccountSearchComponents.metadata.json5';
import styles from './AccountSearchComponents.module.scss';

function AccountSearchComponent() {
    const viewModelService = useContext(ViewModelServiceContext);
    const [accountSearchVmObject, setAccountSearchVmObject] = useState(undefined);
    const [accountResults, setAccountResults] = useState([]);
    const [maxLimit, setMaxLimit] = useState(false);
    const [noResult, setNoResult] = useState(false);
    const [isLoading, setLoadingState] = useState(false);
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useContext(TranslatorContext);
    const { authHeader } = useAuthentication();
    const searchByCode = _.get(accountSearchVmObject, 'searchBy.value.code');
    const [showResultsNumber, setShowResultsNumber] = useState(false);
    const [modalProps, setModalProps] = useState({});
    const [searchedField, setSearchedField] = useState('');
    const { showError } = useWizardModals()

    useEffect(() => {
        const modalPropsRetrieveQuote = {
            modalTitle: messages.retrievingData,
            isOpen: true
        };
        setModalProps(modalPropsRetrieveQuote);
        const accountSearchCriteria = {
            searchBy: Constants.BY_ACCOUNT_NUMBER,
            firstName: '',
            lastName: '',
            accountNumber: '',
            companyName: ''
        };
        const accountSearchViewModel = viewModelService.create(
            accountSearchCriteria,
            'pc',
            'wmic.edge.ca.capabilities.gateway.account.search.dto.AccountSearchByCriteriaDTO_WMIC'
        );
        setAccountSearchVmObject(accountSearchViewModel);
    }, [viewModelService]);

    const transformAccountData = (account) => {
        const accountData = {
            name: account.accountHolderName,
            accountNumber: account.accountNumber,
            address: account.accountHolderAddress,
            policies: account.numberOfPolicies,
            broker: account.producerCodes[0],
            isPersonalAccount: account.isPersonalAccount
        };
        return accountData;
    };

    const clearFormValues = useCallback((newPolicySearchVM) => {
        newPolicySearchVM.accountNumber.value = '';
        newPolicySearchVM.firstName.value = '';
        newPolicySearchVM.lastName.value = '';
        newPolicySearchVM.companyName.value = '';
        setMaxLimit(false);
        setNoResult(false);
    }, []);

    const writeValue = useCallback((value, path) => {
        const newPolicySearchVM = viewModelService.clone(accountSearchVmObject);
        if (path === Constants.SEARCH_BY) {
            clearFormValues(newPolicySearchVM);
        }
        _.set(newPolicySearchVM, path, value);
        setAccountSearchVmObject(newPolicySearchVM);
    }, [clearFormValues, viewModelService, setAccountSearchVmObject, accountSearchVmObject]);

    const isAccountSearchDisabled = useCallback(() => {
        switch (searchByCode) {
            case Constants.BY_FIRST_LAST_NAME:
                return accountSearchVmObject.firstName.value === undefined
                    || accountSearchVmObject.lastName.value === undefined
                    || accountSearchVmObject.firstName.value?.length === 0
                    || accountSearchVmObject.lastName.value?.length === 0;
            case Constants.BY_ACCOUNT_NUMBER:
                return accountSearchVmObject.accountNumber.value === undefined
                    || accountSearchVmObject.accountNumber.value?.length === 0;
            case Constants.BY_COMPANY:
                return accountSearchVmObject.companyName.value === undefined
                    || accountSearchVmObject.companyName.value?.length === 0;
            default:
                return false;
        }
    }, [accountSearchVmObject, searchByCode]);

    const getSearchValue = useCallback(() => {
        switch (accountSearchVmObject.value.searchBy) {
            case Constants.BY_ACCOUNT_NUMBER:
                return accountSearchVmObject.value.accountNumber;
            case Constants.BY_FIRST_LAST_NAME:
                return `${accountSearchVmObject.value.firstName} ${accountSearchVmObject.value.lastName}`;
            case Constants.BY_COMPANY:
                return accountSearchVmObject.value.companyName;
            default:
                return '';
        }
    }, [accountSearchVmObject]);

    const clearAccountSearch = useCallback(() => {
        const newPolicySearchVM = viewModelService.clone(accountSearchVmObject);
        _.set(newPolicySearchVM, 'accountNumber.value', '');
        _.set(newPolicySearchVM, 'firstName.value', '');
        _.set(newPolicySearchVM, 'lastName.value', '');
        _.set(newPolicySearchVM, 'companyName.value', '');
        setAccountSearchVmObject(newPolicySearchVM);
        setAccountResults([]);
        setMaxLimit(false);
        setNoResult(false);
        setShowResultsNumber(false);
    }, [accountSearchVmObject, setAccountSearchVmObject, viewModelService]);

    const handleSearchClick = useCallback(() => {
        setLoadingState(true);
        // eslint-disable-next-line max-len
        AccountService.accountSearchAccessibleForCurrentUser_WMIC(accountSearchVmObject.value, authHeader).then((response) => {
            const simpleResponse = [];
            response.accounts.forEach((account) => {
                if (account.canUserView) {
                    simpleResponse.push(transformAccountData(account));
                }
            });
            setAccountResults(simpleResponse);
            setMaxLimit(response.maxResultLimitReached);
            setNoResult(response.accounts.length === 0);
            setLoadingState(false);
        }).catch(() => {
            setLoadingState(false);
            showError({
                title: messages.errorModalHeader,
                message: messages.errorModalMessage,
                status: 'error',
                icon: 'mi-error-outline'
            }).catch(_.noop);
            return false;
        });
        setSearchedField(getSearchValue);
        setShowResultsNumber(true);
    }, [accountSearchVmObject, authHeader, getSearchValue, showError]);

    if (isLoading) {
        return <WMICProgressModal {...modalProps} />;
    }

    const getIconCell = (data) => {
        // eslint-disable-next-line max-len
        const icon = data.isPersonalAccount ? ICON_NAMES.ICON_USER : ICON_NAMES.ICON_BUILDING;
        return (
            <div>
                <Icon icon={icon} />
                <span className="wmicTextAfterIcon">
                    {data.name}
                </span>
            </div>
        );
    };

    const getMobileOverrides = () => {
        const overrides = accountResults.map(
            (element, index) => {
                return {
                    [`mobileViewNameColumn${index}`]: {
                        content: getIconCell(element)
                    },
                    [`mobileViewAddressColumn${index}`]: {
                        content: element.address
                    },
                    [`mobileViewAccountNumberColumn${index}`]: {
                        content: element.accountNumber
                    },
                    [`mobileViewPoliciesColumn${index}`]: {
                        content: element.policies
                    },
                    [`mobileViewBrokerColumn${index}`]: {
                        content: element.broker
                    },
                };
            }
        );
        return Object.assign({}, ...overrides);
    };

    const overrides = {
        '@field': {
            // apply to all fields
            onValueChange: writeValue
        },
        accountNumberField: {
            visible: searchByCode === Constants.BY_ACCOUNT_NUMBER,
            placeholder: translator(messages.placeholderForAccountNumber)
        },
        firstlastNameContainer: {
            visible: searchByCode === Constants.BY_FIRST_LAST_NAME,
            placeholder: translator(messages.placeholderFirstName)
        },
        companyNameField: {
            visible: searchByCode === Constants.BY_COMPANY,
            content: translator(messages.placeholderCompanyName)
        },
        searchButton: {
            disabled: isAccountSearchDisabled(),
            onClick: handleSearchClick,
            content: translator(messages.landingSearch)
        },
        clearButton: {
            disabled: isAccountSearchDisabled(),
            onClick: clearAccountSearch,
            content: translator(messages.clearButtonText)
        },
        frstNameField: {
            content: translator(messages.placeholderFirstName)
        },
        lastNameField: {
            content: translator(messages.placeholderLastName)
        },
        AccountSearchResultsTable: {
            data: accountResults,
            // eslint-disable-next-line max-len
            visible: !!accountResults.length && breakpoint !== DeviceBreakpoint.PHONE && breakpoint !== DeviceBreakpoint.TABLET
        },
        maxLimitreachedContainer: {
            visible: maxLimit
        },
        showResultsId: {
            // eslint-disable-next-line max-len
            visible: !!accountResults.length && breakpoint !== DeviceBreakpoint.PHONE && breakpoint !== DeviceBreakpoint.TABLET
        },
        numberOfResults: {
            // eslint-disable-next-line max-len
            content: translator(messages.numberOfResults, { resultsNum: accountResults.length, searched: searchedField }),
            // eslint-disable-next-line max-len
            visible: showResultsNumber
        },
        invalidMessageSeparator: {
            visible: noResult || maxLimit
        },
        mobileTableIterableContainer: {
            // eslint-disable-next-line max-len
            visible: !!accountResults.length && (breakpoint === DeviceBreakpoint.PHONE || breakpoint === DeviceBreakpoint.TABLET),
            data: accountResults
        },
        nameColumn: {
            header: translator(messages.nameHeader)
        },
        addressColumn: {
            header: translator(messages.addressHeader)
        },
        accountNumberColumn: {
            header: translator(messages.accountIdHeader)
        },
        policiesIdColumn: {
            header: translator(messages.policiesHeader)
        },
        brokerColumn: {
            header: translator(messages.brokerHeader)
        },
        ...getMobileOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getCell: (item, index, { id: subNumber }) => {
                return item[subNumber];
            },
            getIconCell
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={accountSearchVmObject}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

export default withRouter(withViewModelService(AccountSearchComponent));
