import { defineMessages } from 'react-intl';

export default defineMessages({
    buildingTitle: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Building',
        defaultMessage: 'Building',
    },
    buildingNumber: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Building Title',
        defaultMessage: 'Building #'
    },
    primaryBuilding: {
        id: "quoteandbind.cp.views.LocationsBuildings.Is Primary",
        defaultMessage: "Primary Building",
    },
    yearBuilt: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Year built',
        defaultMessage: 'Year built',
    },
    storeys: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Storeys',
        defaultMessage: 'Storeys',
    },
    construction: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Construction',
        defaultMessage: 'Construction',
    },
    roofCovering: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Roof Covering',
        defaultMessage: 'Roof covering',
    },
    roofCoveringYear: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Roof Covering Year',
        defaultMessage: 'Roof covering year',
    },
    heatingType: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Heating Type',
        defaultMessage: 'Heating type',
    },
    heatingDistribution: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Heating Distribution',
        defaultMessage: 'Heating distribution',
    },
    heatingYear: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Heating Year',
        defaultMessage: 'Heating year',
    },
    electricalType: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Electrical Type',
        defaultMessage: 'Electrical type',
    },
    electricalYear: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Electrical Year',
        defaultMessage: 'Electrical year',
    },
    plumbingType: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Plumbing Type',
        defaultMessage: 'Plumbing type',
    },
    plumbingYear: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Plumbing Year',
        defaultMessage: 'Plumbing year',
    },
    alarm: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Alarm',
        defaultMessage: 'Alarm',
    },
    monitoring: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Monitoring',
        defaultMessage: 'Monitoring',
    },
    fuelStorage: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Fuel Storage',
        defaultMessage: 'Underground or above ground fuel storage units',
    },
    sprayPainting: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Spray Painting',
        defaultMessage: 'Spray painting',
    },
    approvedBooth: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Approved Booth',
        defaultMessage: 'ULC certified spray booth',
    },
    welding: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Welding',
        defaultMessage: 'Welding',
    },
    woodWorking: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Woodworking',
        defaultMessage: 'Woodworking',
    },
    dustCollectionSystem: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Dust Collection System',
        defaultMessage: 'ULC certified dust collection system',
    },
    name: {
        id: 'quoteandbind.cp.views.LocationsBuildings.AdditionalInterest.Name',
        defaultMessage: 'Name',
    },
    interestType: {
        id: 'quoteandbind.cp.views.LocationsBuildings.AdditionalInterest.InterestType',
        defaultMessage: 'Interest type',
    },
    removeAdditionalInterestShort: {
        id: 'quoteandbind.cp.views.LocationsBuildings.AdditionalInterest.Remove Additional Interest Short',
        defaultMessage: 'Remove Additional Interest'
    },
    removeAdditionalInterestLong: {
        id: 'quoteandbind.cp.views.LocationsBuildings.AdditionalInterest.Remove Additional Interest Long',
        defaultMessage: 'Are you sure you want to remove Additional Interest {displayName} from the list?'
    },
    additionalInterestHeading: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Additional Interests Heading',
        defaultMessage: 'Additional Interest (Optional)'
    },
    additionalInterestButton: {
        id: 'quoteandbind.cp.views.LocationsBuildings.Additional Interests Button',
        defaultMessage: 'Add Additional Interest'
    },
    aiPerson: {
        id: 'quoteandbind.cp.views.LocationsBuildings.AdditionalInterest.Person',
        defaultMessage: 'Person'
    },
    aiCompany: {
        id: 'quoteandbind.cp.views.LocationsBuildings.AdditionalInterest.Company',
        defaultMessage: 'Company'
    }
});
