import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './AdditionalInterestTable.metadata.json5';
import messages from './AdditionalInterestTable.messages';
import styles from './AdditionalInterestTable.module.scss';
import { TranslatorContext } from '@jutro/locale';
import _ from "lodash";

function AdditionalInterestTable(props) {
    const { additionalInterestList } = props;

    if (_.isEmpty(additionalInterestList) || _.isEmpty(additionalInterestList.children)) {
        return null;
    }

    const translator = useContext(TranslatorContext);

    const formatNameCell = (items, index, { path: property }) => {
        const additionalInterest = _.get(items, property );
        return `${additionalInterest.displayName.value} - ${additionalInterest.primaryAddress.displayName.value}`
    };

    const overrideProps = {
        additionalInterests: {
            VMList: additionalInterestList.children,
            VMData: [
                {
                    headerText: translator(messages.additionalInterests),
                    path: "policyAdditionalInterest",
                    renderCell: formatNameCell
                },
            ],
            readOnly: true
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={additionalInterestList}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

AdditionalInterestTable.propTypes = {
    additionalInterestList: PropTypes.shape({}).isRequired
};

export default AdditionalInterestTable;
