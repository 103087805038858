import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import {
    Loader,
    withModalContext
} from '@jutro/components';
import { JobUtil } from 'wmic-portals-utils-js';
import {
    TranslatorContext,
    FormattedMessage,
    FormattedDate
} from '@jutro/locale';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { RenewalService, UserService } from 'gw-capability-gateway';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import UnderwritingComponent from '../../Components/UnderwritingComponent/UnderwritingComponent';
import CancellationReason from '../WithDrawConfirmation/CancellationReason';
import gatewayMessages from '../../gateway.messages';
import metadata from './Summary.metadata.json5';
import messages from './Summary.messages';
import styles from '../Renewal.module.scss';

class RenewalsSummaryWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }).isRequired,
        fromAccountLanding: PropTypes.shape({
            quoteDetailsData: PropTypes.shape({
                jobNumber: PropTypes.string.isRequired,
                loadRenewalSummary: PropTypes.shape({}).isRequired,
                updateJobSummary: PropTypes.func.isRequired
            })
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired
        }).isRequired
    };

    state = {
        showContinueRenewalButton: false,
        canWithdraw: false,
        isLoading: true,
        hasOptions: false,
        options: []
    };

    customerRequestOption = {
        id: 'customerRequestOption',
        code: 'customerRequestOption',
        displayName: messages.customerRequest,
        secondaryLabel: messages.decidedToAllowRenewPolicy
    };

    insurerRequestOption = {
        id: 'insurerRequestOption',
        code: 'insurerRequestOption',
        displayName: messages.insurerRequest,
        secondaryLabel: messages.decidedToAllowRenewPolicy
    };

    componentDidMount = () => {
        this.canWithdraw();
        this.initCloseOptions();
        this.getShowContinueRenewal();
    };

    getNewPremium = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            }
        } = this.props;
        const totalPremium = _.get(renewal, 'latestPeriod.totalPremium');
        const taxesAndFees = _.get(renewal, 'latestPeriod.taxesAndFees');
        return (
            <FormattedMessage
                {...messages.totalPremiumAndTaxes}
                values={{
                    totalPremium: (
                        <CurrencyField
                            id="totalPremium"
                            value={totalPremium}
                            readOnly
                            hideLabel
                            datatype="object"
                            className={styles.taxesAndFees}
                        />
                    ),
                    taxesAndFees: (
                        <CurrencyField
                            id="taxesAndFees"
                            value={taxesAndFees}
                            readOnly
                            hideLabel
                            datatype="object"
                            className={styles.taxesAndFees}
                        />
                    )
                }}
            />
        );
    };

    getPremiumPercentage = () => {
        const translator = this.context;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            }
        } = this.props;
        return translator(
            messages.increaseOf,
            { value: _.get(renewal, 'changeInCostPercentage') }
        );
    };

    getShowContinueRenewal = async () => {
        const { authHeader } = this.props;
        const permissionDTO = {
            permission: 'createrenewal'
        };
        const showContinueRenewalButton = await UserService.hasUserSystemPermission(
            permissionDTO,
            authHeader
        );
        this.setState({ showContinueRenewalButton, isLoading: false });
    };

    canWithdraw = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            }
        } = this.props;

        if (!_.isEmpty(renewal)) {
            const translator = this.context;
            if (
                renewal.status
                    !== translator({
                        id: 'typekey.PolicyPeriodStatus.Bound',
                        defaultMessage: 'Bound'
                    })
                && !renewal.closeDate
            ) {
                this.setState({ canWithdraw: true });
            }
        }
    };

    initCloseOptions = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            }
        } = this.props;
        const options = [];

        if (renewal.canPendingNonRenew) {
            options.push(this.customerRequestOption);
            this.setState({ hasOptions: true, options });
        }

        if (renewal.canPendingNotTaken) {
            options.push(this.insurerRequestOption);
            this.setState({ hasOptions: true, options });
        }
    };

    onContinueTransaction = () => {
        const { lobRenewalURL } = appConfig;
        const { history } = this.props;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            },
        } = this.props;
        const productCode = _.get(renewal, 'policy.latestPeriod.policyLines[0].lineOfBusinessCode');
        if (!_.isEmpty(lobRenewalURL[productCode])) {
            const redirectLobPath = lobRenewalURL[productCode];
            history.push(redirectLobPath, {
                jobNumber: renewal.jobNumber,
                landingFrom: 'quoteSummary'
            });
        } else {
            JobUtil.openJobInXCenter(renewal.jobNumber);
        }
    };

    updateWithDrawRenewal = (jobStatusCode, renewal) => {
        const translator = this.context;
        const status = translator({
            id: `typekey.PolicyPeriodStatus.${jobStatusCode}`,
            defaultMessage: jobStatusCode
        });
        _.set(renewal, 'status', status);
        _.set(renewal, 'statusCode', jobStatusCode);
        this.updateJobSummary(renewal);
        this.setState({ hasOptions: false, options: [] });
    };

    withDrawRenewal = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            },
            authHeader
        } = this.props;

        RenewalService.withdrawRenewalByRenewalNumber(renewal.jobNumber, authHeader).then(
            () => {
                this.updateWithDrawRenewal('Withdrawn', renewal);
            },
            () => {
                this.props.modalContext.showAlert({
                    title: gatewayMessages.modalError,
                    message: messages.failedToRenewal,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
            }
        );
    };

    onWithdrawTransaction = () => {
        const translator = this.context;
        const { hasOptions, options } = this.state;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            },
            authHeader
        } = this.props;
        let jobStatusCode = '';
        if (hasOptions) {
            const componentProps = {
                title: translator(messages.reasonForCancellingRenew),
                actionBtnLabel: translator(messages.cancelCtrlRenewal),
                cancelBtnLabel: translator(messages.doNotCancel),
                reasons: options,
                withDrawRenewal: () => this.withDrawRenewal()
            };
            this.props.modalContext.showModal(
                <CancellationReason {...componentProps} />
            ).then((optionsModel) => {
                let jobPromise;
                if (optionsModel === this.customerRequestOption.code) {
                    jobPromise = RenewalService.markRenewalNotTaken(
                        renewal.jobNumber,
                        authHeader
                    );
                    jobStatusCode = 'NotTaking';
                } else if (optionsModel === this.insurerRequestOption.code) {
                    jobPromise = RenewalService.markRenewalNonRenew(
                        renewal.jobNumber,
                        authHeader
                    );
                    jobStatusCode = 'NonRenewing';
                }
                jobPromise.then(
                    () => {
                        this.updateWithDrawRenewal(jobStatusCode, renewal);
                    },
                    () => {
                        this.props.modalContext.showAlert({
                            title: gatewayMessages.modalError,
                            message: messages.failedToRenewal,
                            status: 'error',
                            icon: 'mi-error-outline',
                            confirmButtonText: commonMessages.ok
                        }).catch(_.noop);
                    }
                );
            }).catch(_.noop);
        } else {
            this.props.modalContext.showConfirm({
                title: messages.withdrawTheRenewal,
                message: messages.sureWithdrawRenew,
                status: 'warning',
                icon: 'mi-error-outline',
                cancelButtonText: messages.doNotWithdraw,
                confirmButtonText: messages.withdrawCtrlRenewal
            }).then((results) => {
                if (results === 'cancel') {
                    return _.noop();
                }
                return this.withDrawRenewal();
            }, _.noop);
        }
    };

    hasUwIssues = (uwIssues) => {
        return uwIssues.length > 0;
    };

    getPolicyFormattedInfo = (message) => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            }
        } = this.props;
        return (
            <FormattedMessage
                {...message}
                values={{
                    lob: renewal.policy.product.productName,
                    policyNumber: renewal.latestPeriod.policyNumber
                }}
            />
        );
    };

    getPolicyQuotedInfo = (message) => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            }
        } = this.props;
        return (
            <FormattedMessage
                {...message}
                values={{
                    lob: renewal.policy.product.productName,
                    policyNumber: renewal.latestPeriod.policyNumber,
                    effectiveDate: <FormattedDate value={new Date(renewal.effectiveDate)} />

                }}
            />
        );
    };

    getNotificationContent = () => {
        const translator = this.context;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            }
        } = this.props;
        const { showContinueRenewalButton, canWithdraw, hasOptions } = this.state;
        const content = {};
        if (
            renewal
            && !['Bound', 'Quoted', 'Withdrawn', 'NotTaking', 'NonRenewing'].includes(
                renewal.statusCode
            )
            && !this.hasUwIssues(renewal.underwritingIssues)
            && !renewal.isEditLocked
        ) {
            content.infoMessageTitle = this.getPolicyFormattedInfo(
                messages.policyStartedSuccessfully
            );
            content.infoMessageDescription = showContinueRenewalButton
                && !renewal.isEditLocked
                && translator(messages.clickEditRenewal);
            content.optionalMessageContent = canWithdraw
                && (hasOptions
                    ? translator(messages.selectReasonForCancellation)
                    : translator(messages.withdrawRenewalTransaction));
            content.withDrawContent = hasOptions
                ? messages.cancelRenewal
                : messages.withdrawRenewal;
            content.continueContent = messages.editRenewal;
        }
        if (renewal && renewal.statusCode === 'NonRenewing') {
            content.infoMessageTitle = translator(messages.renewalNonRendering);
            content.infoMessageDescription = showContinueRenewalButton
                && !renewal.isEditLocked
                && translator(messages.clickEditRenewal);
            content.optionalMessageContent = canWithdraw
                && (hasOptions
                    ? translator(messages.selectReasonForCancellation)
                    : translator(messages.withdrawRenewalTransaction));
            content.withDrawContent = hasOptions
                ? messages.cancelRenewal
                : messages.withdrawRenewal;
            content.continueContent = messages.editRenewal;
        }
        if (
            renewal
            && renewal.statusCode === 'Quoted'
            && !this.hasUwIssues(renewal.underwritingIssues)
            && !renewal.isEditLocked
        ) {
            content.infoMessageTitle = this.getPolicyQuotedInfo(messages.renewalPolicyQuoted);
            content.infoMessageDescription = showContinueRenewalButton
                && !renewal.isEditLocked
                && canWithdraw
                && translator(messages.clickEditRenewal);
            content.optionalMessageContent = canWithdraw
                && (hasOptions
                    ? translator(messages.selectReasonForCancellation)
                    : translator(messages.withdrawRenewalTransaction));
            content.withDrawContent = hasOptions
                ? messages.cancelRenewal
                : messages.withdrawRenewal;
            content.continueContent = messages.editRenewal;
        }
        if (renewal && renewal.statusCode === 'Bound') {
            content.infoMessageTitle = this.getPolicyFormattedInfo(messages.policyIsBound);
            content.infoMessageDescription = '';
        }
        if (renewal && renewal.statusCode === 'NotTaking') {
            content.infoMessageTitle = translator(messages.renewalNotTaken);
            content.infoMessageDescription = showContinueRenewalButton
                && !renewal.isEditLocked
                && translator(messages.clickEditRenewal);
            content.optionalMessageContent = canWithdraw
                && (hasOptions
                    ? translator(messages.selectReasonForCancellation)
                    : translator(messages.withdrawRenewalTransaction));
            content.withDrawContent = hasOptions
                ? messages.cancelRenewal
                : messages.withdrawRenewal;
            content.continueContent = messages.editRenewal;
        }
        if (renewal && renewal.statusCode === 'Withdrawn') {
            content.infoMessageTitle = translator(messages.renewalWithdrawn);
            content.infoMessageDescription = '';
        }
        return {
            infoMessageTitle: content.infoMessageTitle,
            infoMessageDescription: content.infoMessageDescription,
            optionalInfoMessageDescription: content.optionalMessageContent,
            withDrawContent: content.withDrawContent,
            continueContent: content.continueContent
        };
    };

    getSubmissionToProceed = () => {
        const { showContinueRenewalButton, canWithdraw } = this.state;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            }
        } = this.props;
        if (
            renewal
            && !['Bound', 'Quoted', 'Withdrawn', 'NotTaking', 'NonRenewing'].includes(
                renewal.statusCode
            )
            && !this.hasUwIssues(renewal.underwritingIssues)
            && !renewal.isEditLocked
        ) {
            return {
                isContinueTransaction:
                    showContinueRenewalButton && canWithdraw && !renewal.isEditLocked,
                isWithdrawTransaction: canWithdraw
            };
        }
        if (renewal && (renewal.statusCode === 'Withdrawn' || renewal.statusCode === 'Bound')) {
            return {
                isContinueTransaction: false,
                isWithdrawTransaction: false
            };
        }
        return {
            isContinueTransaction:
                showContinueRenewalButton && canWithdraw && !renewal.isEditLocked,
            isWithdrawTransaction: canWithdraw
        };
    };

    getPolicyLinkVisible = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            }
        } = this.props;

        return (
            !_.isEmpty(renewal.latestPeriod)
            && renewal.latestPeriod.policyNumber
            && renewal.latestPeriod.policyNumber !== 'Unassigned'
        );
    };

    updateJobSummary = (renewal) => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { updateJobSummary }
            }
        } = this.props;

        if (updateJobSummary) {
            updateJobSummary(renewal);
        }
    };

    render() {
        const { isLoading } = this.state;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            }
        } = this.props;
        const quotedState = _.get(renewal, 'statusCode') === 'Quoted';
        const policyNumber = _.get(renewal, 'latestPeriod.policyNumber');
        if (_.isEmpty(renewal)) {
            return <Loader loaded={!isLoading} />;
        }
        const overrides = {
            quoteNotification: {
                notificationContent: this.getNotificationContent(),
                transactionVisibleActions: this.getSubmissionToProceed()
            },
            underwritingTable: {
                job: renewal,
                continueJob: this.getSubmissionToProceed(),
                jobService: RenewalService,
                onUpdateJobSummary: this.updateJobSummary
            },
            policyInfoLink: {
                visible: this.getPolicyLinkVisible()
            },
            columnSpace: {
                visible: true,
                className:
                    (renewal.statusCode === 'Quoted' && renewal.changeInCost.amount === 0)
                    || (renewal.statusCode === 'Quoted'
                        && (renewal.changeInCost.amount > 0 || renewal.changeInCost.amount < 0))
                        ? ''
                        : styles.columnHorizontal
            },
            statusQuotedCost: {
                visible: renewal.statusCode === 'Quoted' && renewal.changeInCost.amount === 0
            },
            quotedChangeInCost: {
                visible:
                    renewal.statusCode === 'Quoted'
                    && (renewal.changeInCost.amount > 0 || renewal.changeInCost.amount < 0)
            },
            viewPolicyLink: {
                content: `${renewal.productCode} (${policyNumber})`,
                to: `/policies/${renewal.latestPeriod.policyNumber}/summary`
            },
            newPremiumContainer: {
                content: this.getNewPremium()
            },
            changeInCostPercentage: {
                message: this.getPremiumPercentage()
            },
            quotedCostContainer: {
                visible: quotedState
            }
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                underwritingcomponent: UnderwritingComponent
            },
            resolveCallbackMap: {
                onContinueTransaction: this.onContinueTransaction,
                onWithdrawTransaction: this.onWithdrawTransaction
            }
        };
        const readValue = (id, path) => {
            return readViewModelValue(metadata.pageContent, renewal, id, path, overrides);
        };
        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewal}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                resolveValue={readValue}
            />
        );
    }
}

const RenewalsSummary = withModalContext(RenewalsSummaryWithoutModalContext);

export default withAuthenticationContext(withRouter(RenewalsSummary));
