import { defineMessages } from 'react-intl';

export default defineMessages({
    underlyingPoliciesTitle: {
        id:'wmic-pe-capability-gateway-policychange-pup-react.WMICPUPPolicyChangeUnderlyingPoliciesPage.Underlying Policies',
        defaultMessage: 'Underlying Policies'
    },
    wmicUnderlyingPoliciesAtLeastOneHOPolicy: {
        id: 'wmic-pe-capability-gateway-policychange-pup-react.WMICPUPPolicyChangeUnderlyingPoliciesPage.AtLeastOneHOPolicy',
        defaultMessage: '&lt;span&gt;At least &lt;b&gt;one Personal Property Policy&lt;/b&gt; is required&lt;/span&gt;'
    },
    wmicUnderlyingPoliciesPolicy: {
        id: 'wmic-pe-capability-gateway-policychange-pup-react.WMICPUPPolicyChangeUnderlyingPoliciesPage.Policy',
        defaultMessage: 'Policy'
    },
    wmicUnderlyingPoliciesInsurer: {
        id: 'wmic-pe-capability-gateway-policychange-pup-react.WMICPUPPolicyChangeUnderlyingPoliciesPage.Insurer',
        defaultMessage: 'Insurer'
    },
    wmicUnderlyingPoliciesRisks: {
        id: 'wmic-pe-capability-gateway-policychange-pup-react.WMICPUPPolicyChangeUnderlyingPoliciesPage.Risks',
        defaultMessage: 'Risks'
    },
    wmicUnderlyingPoliciesPassengerVehicleRisk: {
        id: 'wmic-pe-capability-gateway-policychange-pup-react.WMICPUPPolicyChangeUnderlyingPoliciesPage.PassengerVehicleRisk',
        defaultMessage: '1 Passenger Vehicle'
    },
    wmicUnderlyingPoliciesExcessPolicy: {
        id: 'wmic-pe-capability-gateway-policychange-pup-react.WMICPUPPolicyChangeUnderlyingPoliciesPage.ExcessPolicy',
        defaultMessage: 'Excess Policy'
    },
    wmicUnderlyingPoliciesPassengerVehicleRisks: {
        id: 'wmic-pe-capability-gateway-policychange-pup-react.WMICPUPPolicyChangeUnderlyingPoliciesPage.PassengerVehicleRisks',
        defaultMessage: '{number} Passenger Vehicles'
    },
    wmicUnderlyingPoliciesPersonalPropertyRisk: {
        id: 'wmic-pe-capability-gateway-policychange-pup-react.WMICPUPPolicyChangeUnderlyingPoliciesPage.PersonalPropertyRisk',
        defaultMessage: '1 Personal Property'
    },
    wmicUnderlyingPoliciesPersonalPropertyRisks: {
        id: 'wmic-pe-capability-gateway-policychange-pup-react.WMICPUPPolicyChangeUnderlyingPoliciesPage.PersonalPropertyRisks',
        defaultMessage: '{number} Personal Properties'
    },
    wmicUnderlyingPoliciesWatercraftRisk: {
        id: 'wmic-pe-capability-gateway-policychange-pup-react.WMICPUPPolicyChangeUnderlyingPoliciesPage.WatercraftRisk',
        defaultMessage: '1 Watercraft'
    },
    wmicUnderlyingPoliciesWatercraftRisks: {
        id: 'wmic-pe-capability-gateway-policychange-pup-react.WMICPUPPolicyChangeUnderlyingPoliciesPage.WatercraftRisks',
        defaultMessage: '{number} Watercraft'
    },
    wmicUnderlyingPoliciesAddUnderlyingPolicy: {
        id: 'wmic-pe-capability-gateway-policychange-pup-react.WMICPUPPolicyChangeUnderlyingPoliciesPage.AddUnderlyingPolicy',
        defaultMessage: 'Add Underlying Policy'
    },
    wmicUnderlyingPoliciesInternalPolicy: {
        id: 'wmic-pe-capability-gateway-policychange-pup-react.WMICPUPPolicyChangeUnderlyingPoliciesPage.internalPolicy',
        defaultMessage: ' {policyNumber} - {carrierName}'
    },
    wmicUnderlyingPoliciesRemoveUnderlyingPolicyConfirmationMsg: {
        id: 'wmic-pe-capability-gateway-policychange-pup-react.WMICPUPPolicyChangeUnderlyingPoliciesPage.RemoveUnderlyingPolicyConfirmationMsg',
        defaultMessage: 'Are you sure you want to remove policy {policyNumber} from the list of underlying policies?'
    },
    wmicUnderlyingPoliciesRemoveUnderlyingPolicy: {
        id: 'wmic-pe-capability-gateway-policychange-pup-react.WMICPUPPolicyChangeUnderlyingPoliciesPage.RemoveUnderlyingPolicy',
        defaultMessage: 'Remove Underlying Policy?'
    },
    wmicUnderlyingPoliciesRemovingLiabilityCoverage: {
        id: 'wmic-pe-capability-gateway-policychange-pup-react.WMICPUPPolicyChangeUnderlyingPoliciesPage.RemovingLiabilityCoverage',
        defaultMessage: 'Removing Underlying Policy'
    },
    wmicUnderlyingPoliciesSavingLiabilityCoverage: {
        id: 'wmic-pe-capability-gateway-policychange-pup-react.WMICPUPPolicyChangeUnderlyingPoliciesPage.SavingLiabilityCoverage',
        defaultMessage: 'Saving Underlying Policies'
    },
    wmicUnderlyingPoliciesInternalPolicyName: {
        id: 'wmic-pe-capability-gateway-policychange-pup-react.WMICPUPPolicyChangeUnderlyingPoliciesPage.InternalPolicyName',
        defaultMessage: 'Wawanesa Mutual Insurance Company'
    },
    wmicUnderlyingPoliciesPersonalProperty: {
        id: 'wmic-pe-capability-gateway-policychange-pup-react.WMICPUPPolicyChangeUnderlyingPoliciesPage.PersonalProperty',
        defaultMessage: 'Personal Property'
    },
    wmicUnderlyingPoliciesAuto: {
        id: 'wmic-pe-capability-gateway-policychange-pup-react.WMICPUPPolicyChangeUnderlyingPoliciesPage.Automobile',
        defaultMessage: 'Automobile'
    },
    wmicUnderlyingPoliciesWatercraft: {
        id: 'wmic-pe-capability-gateway-policychange-pup-react.WMICPUPPolicyChangeUnderlyingPoliciesPage.Watercraft',
        defaultMessage: 'Watercraft'
    }
});