import React, { useMemo, useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { CONSTANTS, WMICBMSIUtil } from 'wmic-pe-portals-utils-js';
import metadata from './WMICDefaultedPropertyWarning.metadata.json5'
import styles from './WMICDefaultedPropertyWarning.module.scss'
import messages from './WMICDefaultedPropertyWarning.messages'

const accidentWaiverPath = "wmic.edge.ca.capabilities.policyjob.lob.personalauto.draft.dto.AccidentWaiverEndorsement_WMICDTO";
const additionalInterestPath = "wmic.edge.ca.capabilities.policyjob.lob.AdditionalInterestDTO_WMIC";
const vehicleAdditionalInterestPath = "wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.dto.VehicleAdditionalInterestsDTO_WMIC"
const wawaPolicyPath = "wmic.edge.ca.capabilities.policyjob.lob.common.draft.dto.insurancehistory.WawanesaPolicy_WMICDTO";

function WMICDefaultedPropertyWarning(props) {
    const { defaultedProperties } = props;
    const translator = useContext(TranslatorContext);

    const groupedDefaultedProperties = useMemo(() => {
        const defaultedPropertiesMap = {};

        for(const property of defaultedProperties){
            if (property.status !== CONSTANTS.REVIEWED && !WMICBMSIUtil.dtosNotToBeChecked.includes(property.className)) {
                const key = property.groupByText;

                if (!defaultedPropertiesMap[key]) {
                    defaultedPropertiesMap[key] = [];
                }

                defaultedPropertiesMap[key].push(property);
            }
        }

        const groupedDefaults = []
        const iterableProps = {}
        const propertyMapToArray = Object.entries(defaultedPropertiesMap)
        for (const [index, [key, value]] of Object.entries(propertyMapToArray)){
            groupedDefaults.push({
                title: key,
                issues: value
            })

            iterableProps[`defaultWarningMessage${index}`] = {
                title: key,
                issues: value
            }
        }

        return {issues: groupedDefaults, iterableProps};
    }, [defaultedProperties])

    const IssueMessages = (property) => {
        return (
            <div className={styles.gwIssuesMessagesContainer}>
                <span className={styles.gwIssuesMessagesHeading}> {property.title} </span>
                <ul> {property.issues.map((issue) => (<li key={`${issue.classPublicID}${issue.entityPropertyName}`}>
                    <span>{issue.propertyName !== undefined && issue.propertyDisplayName}</span>
                    <span>{(issue.className === additionalInterestPath || issue.className === vehicleAdditionalInterestPath) && translator(messages.additionalInterestDefault)}</span>
                    <span>{issue.className === accidentWaiverPath && translator(messages.accidentWaiverEndorsementDefault)}</span>
                    <span>{(issue.className === wawaPolicyPath) && translator(messages.wawanesaPolicyDefault)}</span>
                </li> ))} </ul>
            </div>
        )
    };

    const overrideProps = {
        defaultWarningsContainer: {
            visible: groupedDefaultedProperties.issues?.length > 0
        },
        defaultWarningsContent: {
            data: groupedDefaultedProperties.issues
        },
        ...groupedDefaultedProperties.iterableProps
    };

    const resolvers = {
        classNameMap: styles,
        callbackMap: {

        },
        componentMap: {
            IssueMessages
        }
    };

    return(
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={groupedDefaultedProperties}
            overrideProps={overrideProps}
            classNameMap={resolvers.classNameMap}
            componentMap={resolvers.componentMap}
        />
    )
};

WMICDefaultedPropertyWarning.propTypes = {
    defaultedProperties: PropTypes.array.isRequired
};

export default WMICDefaultedPropertyWarning;