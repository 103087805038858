import { defineMessages } from 'react-intl';

export default defineMessages({
    paDriversDetailsTitle: {
        id: 'quoteandbind.pa.views.pa-drivers-details.Drivers',
        defaultMessage: 'Drivers',
    },
    reenterMsg: {
        id: 'quoteandbind.pa.views.pa-drivers-details.ReenterLicenseInfo',
        defaultMessage: '&lt;span&gt;&lt;b&gt;License Info&lt;/b&gt; for {driversStr} must be re-entered&lt;/span&gt;'
    },
    atLeastOne: {
        id: 'quoteandbind.pa.views.pa-drivers-details.AtLeastOne',
        defaultMessage: '&lt;span&gt;Add a minimum of &lt;b&gt;one Driver&lt;/b&gt;&lt;/span&gt;',
    },
    driverName: {
        id: 'quoteandbind.pa.views.pa-drivers-details.driverName',
        defaultMessage: 'Name'
    },
    licenseNumber: {
        id: 'quoteandbind.pa.views.pa-drivers-details.licenseNumber',
        defaultMessage: 'License number'
    },
    licenseProvince: {
        id: 'quoteandbind.pa.views.pa-drivers-details.licenseProvince',
        defaultMessage: 'License province'
    },
    addNewDriver: {
        id: 'quoteandbind.pa.views.pa-drivers-details.addNewDriver',
        defaultMessage: 'Add New Driver'
    },
    removeDriverTitle: {
        id: 'quoteandbind.pa.views.pa-drivers-details.Remove driver?',
        defaultMessage: 'Remove driver?'
    },
    removeDriver: {
        id: 'quoteandbind.pa.views.pa-drivers-details.removeDriver',
        defaultMessage: 'Are you sure you want to remove {driver} from the list of drivers?'
    },
    saveDriverLabel: {
        id: 'quoteandbind.pa.views.pa-drivers-details.saveDriver',
        defaultMessage: 'Save Driver'
    },
    personalInfoTabHeading: {
        id: 'quoteandbind.pa.views.pa-drivers-details.personalInfo',
        defaultMessage: 'Personal Info'
    },
    licenseInfoTabHeading: {
        id: 'quoteandbind.pa.views.pa-drivers-details.licenseInfo',
        defaultMessage: 'License Info'
    },
    policyHistoryTabHeading: {
        id: 'quoteandbind.pa.views.pa-drivers-details.policyHistory',
        defaultMessage: 'Policy History'
    },
    commercialDriversTabHeading: {
        id: 'quoteandbind.pa.views.pa-drivers-details.commercialDrivers',
        defaultMessage: 'Commercial Drivers'
    },
    driverAgeValidation: {
        id: 'quoteandbind.pa.views.pa-drivers-details.ageValidationMessage',
        defaultMessage: 'Driver age must be at least 14 on the policy effective date. Please review.'
    },
    mvrMinDateValidation: {
        id: 'quoteandbind.pa.views.pa-drivers-details.minMVRDate',
        defaultMessage: 'Minimal MVR Date is {minDate}'
    },
    mvrMaxDateValidation: {
        id: 'quoteandbind.pa.views.pa-drivers-details.maxMVRDate',
        defaultMessage: 'Max MVR Date is {maxDate}'
    },
    savingDriver: {
        id: 'quoteandbind.pa.views.pa-driver-license.Saving driver...',
        defaultMessage: 'Saving driver...'
    },
    licenseProvinceOther: {
        id: "quoteandbind.pa.views.pa-driver-license.Other",
        defaultMessage : 'Other'
    },
    removingDriver: {
        id: 'quoteandbind.pa.views.pa-driver-license.Removing driver...',
        defaultMessage: 'Removing driver...'
    },
    ageValidationMessage: {
        id: "quoteandbind.pa.views.pa-driver-details.Age Validation Message",
        defaultMessage: "Driver age must be at least 14 on the policy effective date. Please review."
    },
    licenceMatchMsg: {
        id: 'quoteandbind.pa.views.pa-driver-details.LicenceMatchMsg',
        defaultMessage: 'Sorry, but this drivers license number matches that of {driver}. Please ensure that all licence numbers are unique.',
    },
    licenseNotUnique: {
        id: 'quoteandbind.pa.views.pa-driver-details.Licence number is not unique',
        defaultMessage: 'Licence number is not unique'
    },
    removingDriverErrorModalTitle: {
        id: 'quoteandbind.pa.views.pa-driver-details.removing-driver.errorModalTitle',
        defaultMessage: 'Error'
    }
});
