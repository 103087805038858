import React, { Fragment, useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import Tippy from '@tippyjs/react';
import { WMICButton } from 'wmic-components-platform-react';
import { Icon } from '@jutro/components';
import { useMediaQuery } from 'react-responsive';
import { Flex, FlexItem } from '@jutro/layout';
import { isIntlShape, useTranslator } from '@jutro/locale';
// eslint-disable-next-line import/no-extraneous-dependencies
import { intlMessageShape } from '@jutro/prop-types';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './WMICCustomTooltip.module.scss';

function shouldOverrideContent(content, tooltipTextOverride) {
    let toReturn = content;

    if (!_.isEmpty(tooltipTextOverride)) {
        toReturn = tooltipTextOverride;
    }

    return toReturn;
}

const WMICCustomTooltip = ({
    hideIcon,
    className,
    children,
    content,
    tooltipTextOverride,
    showOnMobile,
    showOnlyOnMobile,
    showCustomTooltip,
    alignTooltipWithInput,
    alignWithDatePickerMobileLabel
}) => {
    const translator = useTranslator();
    const fieldRef = useRef();
    const [isOpened, setIsOpened] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const overrideContent = shouldOverrideContent(content, tooltipTextOverride);

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [windowWidth]);

    const getMaxWidth = () => {
        if (windowWidth > 1199){
            return 270;
        }
        if (windowWidth <= 1199 && windowWidth > 970) {
            return 250;
        }
        if (windowWidth <= 970 && windowWidth > 920) {
            return 220;
        }
        if (windowWidth <= 920 && windowWidth > 767) {
            return 200;
        }
    }

    const tippyContent = (
        <Fragment>
            {!hideIcon && <Icon icon='cust-question-circle-solid' className={styles.icon} />}
            {isIntlShape(overrideContent) ? translator(overrideContent) : overrideContent}
        </Fragment>
    );

    const wrapperClassName = cx(
        styles.wrapper,
        className,
    );

    const containerClassName = cx(
        alignTooltipWithInput && styles.flexRightElement,
        alignWithDatePickerMobileLabel && styles.alignWithDatePickerMobile
    )

    if (showCustomTooltip) {
        return (
            <Fragment>
                <div className={wrapperClassName} ref={fieldRef}>
                    {isMobile && showOnMobile ? (
                        <Fragment>
                            <Flex wrap={false}>
                                <FlexItem
                                    grow={1}
                                    shrink={1}
                                    className={styles.flexLeftElement}
                                >
                                    {children}
                                </FlexItem>
                                <FlexItem
                                    shrink={1}
                                    alignSelf="top"
                                    className={containerClassName}
                                >
                                    <WMICButton
                                        type="outlined"
                                        className={styles.mobileButton}
                                        onClick={() => setIsOpened(!isOpened)}
                                        icon={isOpened ? 'cust-times-circle-solid' : 'cust-question-circle-solid'}
                                    />
                                </FlexItem>
                            </Flex>
                            {isOpened && (
                                <div className={styles.tippyContent}>
                                    {tippyContent}
                                </div>
                            )}
                        </Fragment>
                    ) : (
                        children
                    )}
                </div>
                {!isMobile && !showOnlyOnMobile && (
                    <Tippy
                        content={tippyContent}
                        offset={[0, 40]}
                        className={styles.customTooltip}
                        trigger="focusin"
                        maxWidth={getMaxWidth()}
                        placement="right-start"
                        hideOnClick={false}
                        reference={fieldRef}
                        arrow
                    />
                )}
            </Fragment>
        );
    }

    return (
        <div>
            {children}
        </div>
    );
};

WMICCustomTooltip.propTypes = {
    /**
     * If true, don't display question mark icon at the beginning of tooltip content
     */
    hideIcon: PropTypes.bool,
    /**
     * Optional class name applied to root element of tooltip
     */
    className: PropTypes.string,
    /**
     * Tooltip content
     */
    content: PropTypes.oneOfType([PropTypes.node, intlMessageShape]).isRequired,
    /**
     * Node to which tooltip is applied, some descendant of it must be focusable
     */
    children: PropTypes.node.isRequired,
    tooltipTextOverride: PropTypes.string,
    showOnMobile: PropTypes.bool,
    showOnlyOnMobile: PropTypes.bool,
    showCustomTooltip: PropTypes.bool,
    alignTooltipWithInput: PropTypes.bool,
    alignWithDatePickerMobileLabel: PropTypes.bool,
};

WMICCustomTooltip.defaultProps = {
    hideIcon: false,
    className: undefined,
    tooltipTextOverride: undefined,
    showOnMobile: true,
    showOnlyOnMobile: false,
    showCustomTooltip: true,
    alignTooltipWithInput: true,
    alignWithDatePickerMobileLabel: false
};

export default WMICCustomTooltip;