import { defineMessages } from 'react-intl';

export const messages = defineMessages({
    downloadFullQuote: {
        id: 'wmic-pe-components-platform-react.WMICHeading.Download Full Quote',
        defaultMessage: 'Download Full Quote'
    },
    print: {
        id: 'wmic-pe-components-platform-react.WMICHeading.Print',
        defaultMessage: 'Print'
    },
    documentGenerationError: {
        id: 'wmic-pe-components-platform-react.WMICHeading.Document generation error',
        defaultMessage: 'Document generation error'
    },
    documentFailed: {
        id: 'wmic-pe-components-platform-react.WMICHeading.Document generation failed. Please try again later.',
        defaultMessage: 'Document generation failed. Please try again later.'
    }
});
