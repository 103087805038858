import { defineMessages } from 'react-intl';

export default defineMessages({
    requiredMsg: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.commercial-driver.requiredMsg",
        defaultMessage: 'All fields below will become required if this driver is listed as a driver on a commercial vehicle'
    },
    driverAuthFormHeading: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.commercial-driver.driverAuthFormHeading",
        defaultMessage: "Driver Authorization Form"
    },
    isDriverAuthRequiredForMvr: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.commercial-driver.isDriverAuthRequiredForMvr",
        defaultMessage: "Is the driver authorization required?"
    },
    isDriverAuthFormSigned: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.commercial-driver.isDriverAuthFormSigned",
        defaultMessage: "Driver authorization form signed?"
    },
    haulingHeading: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.commercial-driver.haulingHeading",
        defaultMessage: "Hauling"
    },
    haulGoods: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.commercial-driver.haulGoods",
        defaultMessage: "Does driver haul goods?"
    },
    goodsHauledType: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.commercial-driver.goodsHauledType",
        defaultMessage: "Type of goods hauled"
    },
    otherGoodsHauledDesc: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.commercial-driver.otherGoodsHauledDesc",
        defaultMessage: "Description"
    },
    highHazardHeading: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.commercial-driver.highHazardHeading",
        defaultMessage: "High Hazard"
    },
    isInvolvedInHighHazardOps: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.commercial-driver.isInvolvedInHighHazardOps",
        defaultMessage: "Is driver in high hazard operations?"
    },
    winterIceRoadExposure: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.commercial-driver.winterIceRoadExposure",
        defaultMessage: "Winter ice road exposure?"
    },
    commercialDriverTrainingHeading: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.commercial-driver.commercialDriverTrainingHeading",
        defaultMessage: "Commercial Driver Training"
    },
    completedTrainingCourse: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.commercial-driver.completedTrainingCourse",
        defaultMessage: "Completed commercial driver training?"
    },
    trainingCourseCompletionDate: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.commercial-driver.trainingCourseCompletionDate",
        defaultMessage: "Date Completed Driver Training"
    },
    accreditedBy: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.commercial-driver.accreditedBy",
        defaultMessage: "Accredited by"
    },
    otherAccreditedByDesc: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.commercial-driver.otherAccreditedByDesc",
        defaultMessage: "Description"
    },
    heavyVehicleExperienceHeading: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.commercial-driver.heavyVehicleExperienceHeading",
        defaultMessage: "Heavy Vehicle Experience"
    },
    heavyVehicleExperience: {
        id: "wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.commercial-driver.heavyVehicleExperience",
        defaultMessage: "Since when Driver has operated a heavy vehicle?"
    }
});
