import React, {
    useState, useEffect, useContext, useCallback
} from 'react';
import { Icon } from '@jutro/components';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { WMICHeading, WMICIcon } from 'wmic-pe-components-platform-react';
import { TranslatorContext } from '@jutro/locale';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { ICON_NAMES, WMICVariousUtil, JobType, DateUtil, CONSTANTS, PRODUCT, WMICUserAccessUtil } from 'wmic-pe-portals-utils-js';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import WMICDocumentUtil from 'wmic-pe-capability-quoteandbind-common-react/util/WMICDocumentUtil';
import styles from './WMICWizardPageHeader.module.scss';
import messages from './WMICWizardPageHeader.messages';

const QUOTE_TRANSACTION = [
    'CommercialProperty',
    'WC7WorkersComp',
    'Homeowners',
    'BusinessOwners',
    'PersonalAuto',
    'HOPHomeowners',
    'PersonalUmbrella',
    'CommercialPackage'
];

const PRINTABLE_PAGES = ["/quote"];
const DOWNLOAD_QUOTE_PAGES = ["/quote-summary"];

const WMICWizardPageHeader = (props) => {
    const translator = useContext(TranslatorContext);
    const { currentStep } = useContext(WizardContext);
    const [wizardHeaderTemplate, setWizardHeaderTemplate] = useState('');
    const [routePath, setRoutePath] = useState('');
    const [accountName, setAccountName] = useState('');
    const [lobWithQuoteNumber, setLobWithQuoteNumber] = useState('');
    const { authHeader, authUserData } = useAuthentication();
    const {
        wizardSubmission, isSideHeader, lobFlow, displayWizardHeader
    } = props;
    const { showConfirm } = useWizardModals();
    const history = useHistory();
    const isLobPA = _.get(wizardSubmission.value, "baseData.productCode") === PRODUCT.PERSONAL_AUTO;
    const isCommercialPackage = _.get(wizardSubmission.value, "baseData.productCode") === PRODUCT.COMMERCIAL_PACKAGE;
    const isQuoted = _.get(wizardSubmission.value, 'baseData.periodStatus') === CONSTANTS.QUOTED;
    const canEditAccount = WMICUserAccessUtil.canEditAccount(authUserData.roles);

    useEffect(() => {
        const productCode = _.get(wizardSubmission.value, 'baseData.productCode');
        const jobType = _.get(wizardSubmission.value, 'baseData.jobType');
        const productAndJobType = (jobType === JobType.RENEWAL && `${productCode}${jobType}`)
            || (jobType === JobType.POLICY_CHANGE && `${productCode}${jobType}`)
            || productCode;
        const accountNumber = _.get(wizardSubmission.value, 'baseData.accountNumber');
        const getFirstName = _.get(wizardSubmission.value, 'baseData.accountHolder.firstName') || '';
        const getLastName = _.get(wizardSubmission.value, 'baseData.accountHolder.lastName') || '';
        const getDisplayName = _.get(wizardSubmission.value, 'baseData.accountHolder.displayName');
        const getAccountDisplayName = !getDisplayName
            ? `${getFirstName} ${getLastName}`
            : getDisplayName;
        const getAccountName = _.trim(getAccountDisplayName)
            || _.get(wizardSubmission.value, 'baseData.accountHolder.contactName')
            || _.get(wizardSubmission.value, 'baseData.accountName');

        if (productAndJobType && QUOTE_TRANSACTION.includes(productCode)) {
            setWizardHeaderTemplate('transaction');
        }
        /* eslint no-underscore-dangle: 0 */
        if (wizardSubmission._dtoName.indexOf('fnol') !== -1) {
            setWizardHeaderTemplate('claim');
        }

        setRoutePath(`/accounts/${accountNumber}/summary`);
        setAccountName(getAccountName);
        setLobWithQuoteNumber(
            translator(messages[_.camelCase(productAndJobType)], {
                quoteNumber:
                    _.get(wizardSubmission.value, 'quoteID')
                    || _.get(wizardSubmission.value, 'policyNumber'),
                jobId: _.get(wizardSubmission.value, 'jobID') || ''
            })
        );

    }, [currentStep, isLobPA, lobFlow, translator, wizardSubmission]);

    const getAddress = () => {
        const address = _.get(wizardSubmission.value, 'baseData.jobType') === JobType.POLICY_CHANGE
            ? _.get(wizardSubmission.value, 'baseData.primaryNamedInsured_WMIC.primaryAddress.displayName')
            : _.get(wizardSubmission.value, 'baseData.accountHolder.primaryAddress.displayName');

        return address || '';
    }

    const getWizardTitleIcon = () => {
        const productCode = _.get(wizardSubmission.value, 'baseData.productCode');
        const icon = WMICVariousUtil.getLobIcon(productCode)
        return <WMICIcon
            icon={icon}
            id="lobIcon"
            size="large"
            className={cx("lobIcon", "gw-mr-2", "gw-mt-1")}
        />
    }

    const showLeavePageConfirmModal = () => {
        showConfirm({
            title: translator(messages.editAccountModalTitle),
            message: translator(messages.editAccountModalMessage),
            confirmButtonText: translator(commonMessages.yes),
            cancelButtonText: translator(commonMessages.cancelModel),
        }).then(async (result) => {
            if (result === CONSTANTS.MODAL_RESULT.CANCEL || result === CONSTANTS.MODAL_RESULT.CLOSE) {
                return _.noop();
            }

            storeJobDataToSessionStorage();
            history.push(routePath);

        }, _.noop);
    }

    const isPrintablePage = useCallback(() => currentStep
        ? PRINTABLE_PAGES.includes(currentStep.path) && !isLobPA
        : false, [currentStep, isLobPA]);

    const isQuoteDownloadable = useCallback(() => currentStep
        ? isCommercialPackage && isQuoted && DOWNLOAD_QUOTE_PAGES.includes(currentStep.path)
        : false , [currentStep, isCommercialPackage, isQuoted]);

    const storeJobDataToSessionStorage = () => {
        const redirectURL = WMICVariousUtil.getRedirectURLForJob(wizardSubmission);
        sessionStorage.setItem('backButtonRedirectUrl', redirectURL )
    }

    const downloadQuoteDocument = () => {
        if (isQuoted) {
            return WMICDocumentUtil.downloadQuoteDocument(wizardSubmission.quoteID.value, authHeader);
        }
        return Promise.resolve(undefined);
    }

    const titleContainerClass = cx({
        [styles.gwWizardPageTitle]: !isSideHeader,
        [styles.gwWizardSidePageTitle]: isSideHeader
    });

    const categoryContainerClass = cx({
        [styles.gwWizardPageCategory]: !isSideHeader,
        [styles.gwWizardSidePageCategory]: isSideHeader
    });

    const associatedPageClass = cx({
        [styles.gwWizardPageAssociated]: !isSideHeader,
        [styles.gwWizardSidePageAssociated]: isSideHeader
    });

    const titleSeparator = isSideHeader && (
        <div className={styles.gwWizardSidePageTitleSeparator} />
    );

    const submissionQuote = wizardHeaderTemplate === 'transaction' && (
        <div className={titleContainerClass}>
            {titleSeparator}
            {displayWizardHeader ? (
                <div>
                    <div className={cx("wizardHeaderJobDetails", { [styles.gwWizardSidepageTitle]: isSideHeader }, 'gw-mb-5')}>
                        {getWizardTitleIcon()}
                        <div className={styles.jobDetailsTitle}>
                            <div>{lobWithQuoteNumber}</div>
                            <span className={styles.termDetails}>{translator(messages.termWithDates, {
                                periodStart: localStorage.getItem('selectedLocale') === "fr-CA" ? DateUtil.getDateFormatDDMMMMYYYY(_.get(wizardSubmission, 'baseData.periodStartDate.value')) : DateUtil.getDateFormatMMMMDDYYYY(_.get(wizardSubmission, 'baseData.periodStartDate.value')),
                                periodEnd: localStorage.getItem('selectedLocale') === "fr-CA" ? DateUtil.getDateFormatDDMMMMYYYY(_.get(wizardSubmission, 'baseData.periodEndDate.value')) : DateUtil.getDateFormatMMMMDDYYYY(_.get(wizardSubmission, 'baseData.periodEndDate.value'))
                            })}</span>
                        </div>
                    </div>
                    <div className={categoryContainerClass}>
                        <div className={cx(styles.accountHolderName)}>{accountName}</div>
                        <p className={styles.accountAddress}>
                            {getAddress()}
                            {canEditAccount && <span className={associatedPageClass} onClick={showLeavePageConfirmModal} role='link'>
                                <Icon
                                    icon={ICON_NAMES.ICON_EDIT}
                                    id="iconEdit"
                                    size="small"
                                    className="gw-ml-1"
                                />
                                <span className={styles.editAccount}>{translator(messages.editAccount)}</span>
                            </span>}
                        </p>
                        <WMICHeading
                            title={translator(currentStep.title)}
                            variant='h1'
                            isPrintablePage={isPrintablePage()}
                            isQuoteDownloadable={isQuoteDownloadable()}
                            downloadQuoteDocument={downloadQuoteDocument}
                            underlined
                        />
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );

    const claimHeader = useCallback(() => {
        const claimNumber = _.get(wizardSubmission.value, 'claimNumber', '');
        return wizardHeaderTemplate === 'claim' ? (
            <h1 className={styles.gwPageTitle}>
                {translator(messages.fnolNewClaim)}
                <span className={styles.gwClaimsWizardSpanNumber}>{claimNumber}</span>
            </h1>
        ) : null;
    }, [wizardHeaderTemplate, translator, wizardSubmission.value]);

    return (
        <>
            {submissionQuote}
            {claimHeader()}
        </>
    );
};

WMICWizardPageHeader.propTypes = {
    wizardSubmission: PropTypes.shape({
        value: PropTypes.shape({}),
        _dtoName: PropTypes.string
    }).isRequired,
    isSideHeader: PropTypes.bool,
    lobFlow: PropTypes.string,
    displayWizardHeader: PropTypes.bool
};

WMICWizardPageHeader.defaultProps = {
    isSideHeader: false,
    lobFlow: '',
    displayWizardHeader: true
};

export default WMICWizardPageHeader;
