/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import PropTypes from 'prop-types';
import metadata from './WaterHeaterInfo.metadata.json5';
import './WaterHeaterInfo.messages';

function WMICHOWaterHeaterDetails(props) {
    const {
        id,
        riskView,
        updateDetails,
        showErrors,
        isEditMode,
        onValidate
    } = props;
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    const overrideProps = {
        '@field': {
            parentNode: riskView,
            readOnly: !isEditMode
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={riskView}
            overrideProps={overrideProps}
            onModelChange={updateDetails}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

WMICHOWaterHeaterDetails.propTypes = {
    id: PropTypes.string.isRequired,
    riskView: PropTypes.shape({}).isRequired,
    updateDetails: PropTypes.func.isRequired,
    showErrors: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    onValidate: PropTypes.func.isRequired,
};

export default WMICHOWaterHeaterDetails;
