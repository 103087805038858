import { defineMessages } from 'react-intl';

export default defineMessages({
    residenceType: {
        id: 'wmic.policyview.ho.views.ho-your-home.Residence Type',
        defaultMessage: 'Residence Type'
    },
    riskType: {
        id: 'policyview.ho.views.ho-your-home.Risk Type',
        defaultMessage: 'Risk Type'
    },
    primaryIndicator: {
        id: 'policyview.ho.views.ho-your-home.Primary',
        defaultMessage: 'Set as primary location'
    },
    riskLocation: {
        id: 'policyview.ho.views.ho-your-home.Location Name',
        defaultMessage: 'Location Name'
    },
    unitNumber: {
        id: 'policyview.ho.views.ho-your-home.Unit Number',
        defaultMessage: 'Unit Number'
    },
    otherResidenceDescription: {
        id: 'policyview.ho.views.ho-your-home.OtherResidenceDescription',
        defaultMessage: 'Describe'
    },
    numberOfFamilies: {
        id: 'policyview.ho.views.ho-your-home.Number of Families',
        defaultMessage: 'Number of Families'
    },
    numberOfSuites: {
        id: 'policyview.ho.views.ho-your-home.Number of Self Contained Units',
        defaultMessage: 'Number of Self Contained Units'
    },
    numberOfSelfContainedUnits: {
        id: 'policyview.ho.views.ho-your-home.Number of Suites',
        defaultMessage: 'Number of Suites'
    },
    numberOfSmokers: {
        id: 'policyview.ho.views.ho-your-home.Number of Smokers',
        defaultMessage: 'Number of Smokers'
    },
    duplexOwnBothSides: {
        id: 'policyview.ho.views.ho-your-home.Do you own all units',
        defaultMessage: 'Do you own all units?'
    },
    csaLabel: {
        id: 'policyview.ho.views.ho-your-home.CSA Label',
        defaultMessage: 'CSA Label'
    },
    csaDescription: {
        id: 'policyview.ho.views.ho-your-home.CSA Description',
        defaultMessage: 'Details'
    },
    csaLabelType: {
        id: 'policyview.ho.views.ho-your-home.CSA Label Type',
        defaultMessage: 'CSA Label Type'
    },
    csaLabelTypeOther: {
        id: 'policyview.ho.views.ho-your-home.DescribeOtherCsaLabel',
        defaultMessage: 'Describe'
    },
    dwellingUsage: {
        id: 'policyview.ho.views.ho-your-home.Dwelling Use',
        defaultMessage: 'Dwelling Use'
    },
    occupancy: {
        id: 'policyview.ho.views.ho-your-home.Occupancy',
        defaultMessage: 'Occupancy'
    },
    timeOccupied: {
        id: 'policyview.ho.views.ho-your-home.Time Occupied',
        defaultMessage: 'Months Occupied'
    },
    propertyClass: {
        id: 'policyview.ho.views.ho-your-home.Class',
        defaultMessage: 'Class'
    },
    questionnaireReceived: {
        id: 'policyview.ho.views.ho-your-home.Questionnaire Received',
        defaultMessage: 'Rented Dwelling Questionnaire Received?'
    },
    questionnaireDate: {
        id: 'policyview.ho.views.ho-your-home.Questionnaire Date',
        defaultMessage: 'Rented Dwelling Questionnaire Last Update'
    },
    describeOtherOccupancy: {
        id: 'policyview.ho.views.ho-your-home.DescribeOtherOccupancy',
        defaultMessage: 'Describe'
    },
    immediateFamilyType: {
        id: 'policyview.ho.views.ho-your-home.Relationship',
        defaultMessage: 'Occupant&apos;s relationship to Primary Named Insured'
    },
    immediateFamilyDesc: {
        id: 'policyview.ho.views.ho-your-home.Relationship Description',
        defaultMessage: 'Details'
    },
    ownContractor: {
        id: 'policyview.ho.views.ho-your-home.Own Contractor',
        defaultMessage: 'Insured acts as own contractor'
    },
    coverageFor: {
        id: 'policyview.ho.views.ho-your-home.Coverage Applied To',
        defaultMessage: 'Coverage Applied To'
    },
    vacantDays: {
        id: 'policyview.ho.views.ho-your-home.Vacant Days',
        defaultMessage: 'Vacant Days'
    },
    absenteeLandlord: {
        id: 'policyview.ho.views.ho-your-home.Absentee Landlord',
        defaultMessage: 'Absentee Landlord'
    },
    propertyManagement: {
        id: 'policyview.ho.views.ho-your-home.Property Management',
        defaultMessage: 'Property Management'
    },
    coverageType: {
        id: 'policyview.ho.views.ho-your-home.Coverage Type',
        defaultMessage: 'Coverage Type'
    },
    distanceToFireHydrant: {
        id: 'policyview.ho.views.ho-your-home.Distance to Fire Hydrant',
        defaultMessage: 'Distance to Fire Hydrant'
    },
    distanceToFireStation: {
        id: 'policyview.ho.views.ho-your-home.Distance to Fully Staffed Fire Hall',
        defaultMessage: 'Distance to Fully Staffed Fire Hall'
    },
    shuttleTankProtected: {
        id: 'policyview.ho.views.ho-your-home.Superior shuttle tanker protection',
        defaultMessage: 'Superior shuttle tanker protection'
    },
    accessibleBy: {
        id: 'policyview.ho.views.ho-your-home.Risk Accessible by',
        defaultMessage: 'Risk Accessible by'
    },
    mobileMiniHomeQuestionnaireReceived: {
        id: 'policyview.ho.views.ho-your-home.Mobile Mini Home Questionnaire Received',
        defaultMessage: 'Mobile/Mini Home Questionnaire Received?'
    },
    mobileMiniHomeQuestionnaireDate: {
        id: 'policyview.ho.views.ho-your-home.Date Mobile Mini Home Questionnaire Received',
        defaultMessage: 'Rented Dwelling Questionnaire Last Update'
    },
    revenuePropertyQuestionnaireReceived: {
        id: 'policyview.ho.views.ho-your-home.Revenue Property Questionnaire Received',
        defaultMessage: 'Revenue Property Questionnaire Received?'
    },
    revenuePropertyQuestionnaireDate: {
        id: 'policyview.ho.views.ho-your-home.Revenue Property Questionnaire Date',
        defaultMessage: 'Date Revenue Property Questionnaire Received'
    },
    occupiedSince: {
        id: 'policyview.ho.views.ho-your-home.Occupied Since',
        defaultMessage: 'Occupied Since'
    },
    taxExemptionReason: {
        id: 'policyview.ho.views.ho-your-home.Tax Exemption',
        defaultMessage: 'Tax Exemption'
    },
    taxExemptionReason1: {
        id: 'policyview.ho.views.ho-your-home.Tax Exemption Reason',
        defaultMessage: 'Tax Exemption Reason'
    },
    lifeLeaseOrCooperative: {
        id: 'policyview.ho.views.ho-your-home.Life Lease or Cooperative',
        defaultMessage: 'Life Lease or Cooperative'
    }
});
