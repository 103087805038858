/* eslint-disable max-len */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export default class WMICTimezoneUtilService {
    static getDateInPolicyLocationTimeZone(datetime, format) {
        // if timestamp comes from PC it's in the format yyyy-MM-ddTHH:mm:ssZ (i.e. refers to UTC)
        // if timestamp is in a different format it was set on the local machine and is in the user's time zone
        if (typeof datetime === 'string' && datetime[datetime.length - 1] === 'Z') {
            return datetime.substring(0, datetime.indexOf('T'));
        }
        const policyLocationTimeZone = this.isDST(datetime) ? 'America/Los_Angeles' : 'America/Edmonton';

        return dayjs(datetime).tz(policyLocationTimeZone).format(format).toString();
    }

    static dateToUTC(datetime) {
        // adjust time to UTC without changing the date
        // if timestamp comes from PC it's in the format yyyy-MM-ddTHH:mm:ssZ (i.e. refers to UTC)
        // if timestamp is in a different format it was set on the local machine and is in the user's time zone
        let actualDate = (typeof datetime === 'string' && datetime[datetime.length - 1] === 'Z') ? datetime : dayjs(datetime).format('YYYY-MM-DDTHH:mm:ss');
        actualDate = actualDate.substring(0, actualDate.indexOf('T'));
        //const dateWithTz = dayjs.utc(`${actualDate}T00:00:00`, 'YYYY-MM-DDTHH:mm:ss');
        const dateWithTz = dayjs.utc(actualDate).format();
        return dateWithTz;
    }

    static toServerTimeZone(datetime, serverTimeOffset, serverInDST) {
        // adjust time to server time zone without changing the date (in regards to policy time zone)
        const dateTime = new Date(datetime); // make sure IE and Chrome/Firefox are using the same date format in case the time zone has changed since creating the passed in date object
        let UTCOffset = serverTimeOffset.indexOf('-') === 0 ? serverTimeOffset.substring(0, 3) : serverTimeOffset.substring(1, 3);
        // eslint-disable-next-line radix
        UTCOffset = parseInt(UTCOffset) - (serverInDST ? 1 : 0); // correct offset by 1 hour if server in DST, i.e. always assume standard time to be on the safe side
        UTCOffset = UTCOffset > 0 ? 0 : -UTCOffset;
        UTCOffset = UTCOffset > 9 ? `${UTCOffset}` : `0${UTCOffset}`;
        let serverTimeFormat = dayjs(dateTime).format('YYYY-MM-DD');
        serverTimeFormat = `${serverTimeFormat}T${UTCOffset}:00:00Z`;
        return serverTimeFormat;
    }

    static setTimeToLocalTimeZone(datetime) {
        // adjust time to current time zone without changing actual date
        // if timestamp comes from PC it's in the format yyyy-MM-ddTHH:mm:ssZ (i.e. refers to UTC)
        // if timestamp is in a different format it was set on the local machine and is in the user's time zone
        let actualDate = (typeof datetime === 'string' && datetime[datetime.length - 1] === 'Z') ? datetime : dayjs(datetime).format('YYYY-MM-DDTHH:mm:ss');
        actualDate = actualDate.substring(0, actualDate.indexOf('T'));
        // set time to midnight local time
        return dayjs(`${actualDate}T00:00:00`).format('YYYY-MM-DD HH:mm:ss');
    }

    static isDST(datetime) {
        // returns true if daylight saving time is in effect for given date
        const jan = new Date(datetime.getFullYear(), 0, 1);
        const jul = new Date(datetime.getFullYear(), 6, 1);
        return Math.min(jan.getTimezoneOffset(), jul.getTimezoneOffset()) === datetime.getTimezoneOffset();
    }

    static hasDST(datetime) {
        // returns true if the local time zone observes Daylight Saving Time
        const jan = new Date(datetime.getFullYear(), 0, 1);
        const jul = new Date(datetime.getFullYear(), 6, 1);
        return jan.getTimezoneOffset() !== jul.getTimezoneOffset();
    }

    static getDSTOffsetForDate(datetime, serverInDST) {
        // offset is -1 if server is in DST and target date (datetime) isn't
        // offset is 1 if server is not in DST and target date is
        // offset is 0 if both server and target date are in DST / not in DST
        const dateInDST = this.hasDST(datetime) ? this.isDST(datetime) : false; // set to false if local time zone doesn't observe DST
        if (dateInDST !== serverInDST) {
            return serverInDST ? -1 : 1;
        }
        return 0;
    }
}
