import { defineMessages } from 'react-intl';

export default defineMessages({
    accidentWaiverEndorsement: {
        id: 'wmic-pe-capability-gateway-common-pa-react.DriverEndorsementsCoverages.Accident Waiver Endorsement',
        defaultMessage: 'Accident Waiver Endorsement'
    },
    accidentWaiverEndorsementMotorcycle: {
        id: 'wmic-pe-capability-gateway-common-pa-react.DriverEndorsementsCoverages.Accident Waiver Endorsement Motorcycle',
        defaultMessage: 'Accident Waiver Endorsement - Motorcycle'
    },
    minorConvictionWaiverON: {
        id: 'wmic-pe-capability-gateway-common-pa-react.DriverEndorsementsCoverages.TrafficSafetyConvictionWaiver.Ontario',
        defaultMessage: 'Minor Conviction Waiver'
    },
    minorConvictionWaiverMaritimes: {
        id: 'wmic-pe-capability-gateway-common-pa-react.DriverEndorsementsCoverages.TrafficSafetyConvictionWaiver.Maritimes',
        defaultMessage: 'Minor Conviction Waiver Endorsement'
    },
    trafficSafetyConvictionWaiver: {
        id: 'wmic-pe-capability-gateway-common-pa-react.DriverEndorsementsCoverages.TrafficSafetyConvictionWaiver',
        defaultMessage: 'Traffic Safety Conviction Waiver'
    },
    reductionOfCoverageWithLimit: {
        id: 'wmic-pe-capability-gateway-common-pa-react.DriverEndorsementsCoverages.reductionOfCoverageWithLimit',
        defaultMessage: 'Reduction of Coverage - Limit of ${driverLimit}'
    },
    article6: {
        id: 'wmic-pe-capability-gateway-common-pa-react.DriverEndorsementsCoverages.Article6',
        defaultMessage: 'Article6 - Excluded Driver'
    },
    excludedDriver: {
        id: 'wmic-pe-capability-gateway-common-pa-react.DriverEndorsementsCoverages.Excluded Driver',
        defaultMessage: 'Excluded Driver'
    }
})