import React, { useContext } from 'react';
import { Flex, FlexItem } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import messages from '../WMICDocuments.messages';

const EmptyUploadAreaMessage = ({ maxNumberOfFiles, maxFileSizeKB }) => {
    const translator = useContext(TranslatorContext);
    const maxFileSizeMB = +maxFileSizeKB / 1000;

    return (
        <>
            <Flex justifyContent="center" className="gw-mb-4 fontSizeH2"><b>{translator(messages.documentUpload)}</b></Flex>
            <Flex gap="small" className="gw-mb-2">
                <Flex grow="1" justify="end" direction="column" gap="none">
                    <FlexItem textAlign="right" className="fontSizeSubtext"><b>{translator(messages.maxFileSizeLabel)}</b></FlexItem>
                    <FlexItem textAlign="right" className="fontSizeSubtext"><b>{translator(messages.filesPerUpload)}</b></FlexItem>
                    <FlexItem textAlign="right" className="fontSizeSubtext"><b>{translator(messages.fileTypesLabel)}</b></FlexItem>
                    <FlexItem textAlign="right" className="fontSizeSubtext"><b>{translator(messages.imageFileTypesLabel)}</b></FlexItem>

                </Flex>
                <Flex grow="1" direction="column" gap="none">
                    <FlexItem className="fontSizeSubtext">{translator(messages.maxFileSizeValue, {maxFileSizeMB})}</FlexItem>
                    <FlexItem className="fontSizeSubtext">{translator(messages.filesPerUploadValue, {maxNumberOfFiles})}</FlexItem>
                    <FlexItem className="fontSizeSubtext">{translator(messages.fileTypesValue)}</FlexItem>
                    <FlexItem className="fontSizeSubtext">{translator(messages.imageFileTypesValue)}</FlexItem>
                </Flex>
            </Flex>
        </>
    )
} 

export default EmptyUploadAreaMessage;