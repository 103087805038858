import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { TranslatorContext } from '@jutro/locale';
import { WMICDateTimeService, WMICRichTextUtil, CONSTANTS } from "wmic-pe-portals-utils-js";

import WMICPUPLegalHistoryComponent from './WMICPUPLegalHistoryComponent/WMICPUPLegalHistoryComponent';

import metadata from './WMICHouseholdMemberDetailsComponent.metadata.json5';
import messages from './WMICHouseholdMemberDetailsComponent.messages.js';
import incidentMessages from './WMICPUPLegalHistoryComponent/WMICPUPLegalHistoryComponent.messages.js';


function WMICHouseholdMemberDetailsComponent(props) {
    const {
        id,
        value: memberVM,
        isEditing,
        onValidate,
        showErrors,
        saveHouseHoldMember,
        cancelHouseHoldMember,
        updateModel: updateSelectedHouseholdMember,
        jobVM,
        setShowErrors,
        showButtons,
    } = props;

    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);

    const [selectedIncident, setSelectedIncident] = useState();
    const [isInitiallyPNI, setInitiallyPNI] = useState();

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => {
            if(onValidate){
                onValidate(true, id)
            }
        }
    }, [id, isComponentValid, onValidate]);

    useEffect(() => {
        setInitiallyPNI(_.get(memberVM, 'isPNI.value', false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onHasLegalHistoryChange = (value, path) => {
        const hasLegalHistory = _.get(memberVM, 'hasLegalHistory.value', undefined);

        _.set(memberVM, path, value);

        if (!hasLegalHistory) {
            setSelectedIncident(undefined);
        }

        updateSelectedHouseholdMember(memberVM);
    }

    const addIncident = () => {
        const incidentVM = viewModelService.create({},
            'pc',
            // eslint-disable-next-line no-secrets/no-secrets
            'wmic.edge.ca.capabilities.policyjob.lob.personalumbrella.coverables.dto.householdmember.MemberIncidentDTO_WMIC',
            {
                ...jobVM.aspects.context(),
                HasLegalHistory: _.get(memberVM, 'hasLegalHistory.value')
            }
        );

        setSelectedIncident(incidentVM);
    }

    const removeIncident = (incident, index) => {
        const memberIncidents = _.get(memberVM, 'memberIncidents.value');

        memberIncidents.splice(index, 1);
        updateSelectedHouseholdMember(memberVM);
    }

    const saveIncident = (data) => {
        const memberIncidents = _.get(memberVM, 'memberIncidents.value');

        memberIncidents.push(data.value);
        setSelectedIncident(undefined);
        updateSelectedHouseholdMember(memberVM);
    }

    const cancelIncident = () => {
        setSelectedIncident(undefined);
    }

    const displayDate = (data, path) => {
        const dateValue = _.get(data.value, path);

        return dateValue ? <span>{WMICDateTimeService.toMidnightDate(dateValue)}</span> : '';
    };

    const isProhibitionIncident = (incident) => _.get(incident, 'incidentType.value.code', '') === CONSTANTS.INCIDENT_TYPES.PROHIBITION

    const overrideProps = {
        "@field": {
            parentNode: memberVM,
            readOnly: !isEditing
        },
        householdMembersContainerButtons: {
            onCancel: cancelHouseHoldMember,
            onSave: saveHouseHoldMember,
            isEditing,
            visible: showButtons,
        },
        memberFirstName: {
            readOnly: (memberVM.isPNI.value && isInitiallyPNI) || !isEditing
        },
        memberMiddleName: {
            readOnly: (memberVM.isPNI.value && isInitiallyPNI) || !isEditing
        },
        memberLastName: {
            readOnly: (memberVM.isPNI.value && isInitiallyPNI) || !isEditing
        },
        memberDateOfBirth: {
            maxDate: WMICDateTimeService.getMaxDOB(),
            minDate: WMICDateTimeService.getMinDOB()
        },
        memberIsPNI: {
            visible: !isInitiallyPNI
        },
        legalHistoryFormContainer: {
            visible: _.get(memberVM, 'hasLegalHistory.value', undefined)
        },
        incidentDataList: {
            VMList: _.get(memberVM, 'memberIncidents.children', []),
            VMData: [
                {
                    headerText: translator(incidentMessages.incidentType),
                    path: 'incidentType'
                },
                {
                    headerText: translator(incidentMessages.incidentDateOccured),
                    path: "incidentDate",
                    getData: displayDate,
                    visibilityCondition: (row) => !isProhibitionIncident(row)
                },
                {
                    headerText: translator(incidentMessages.incidentEndDate),
                    path: "incidentDate",
                    getData: displayDate,
                    visibilityCondition: (row) => isProhibitionIncident(row)
                },
                {
                    headerText: translator(incidentMessages.incidentDescription),
                    path: 'incidentDescription',
                    visibilityCondition: (row) => !isProhibitionIncident(row)
                }
            ],
            onRemoveAction: removeIncident,
            updateSelectedCardIndex: (index) => setSelectedIncident(index),
            clickable: false,
            readOnly: !isEditing,
        },
        legalHistoryForm: {
            visible: !_.isUndefined(selectedIncident),
            incidentVM: selectedIncident,
            setIncidentVM: setSelectedIncident,
            saveIncident,
            cancelIncident,
            onValidate,
            showErrors,
            setShowErrors,
            isEditing
        },
        addIncidentButtonContainer: {
            visible: !_.isNil(_.get(memberVM, 'hasLegalHistory.value')) && isEditing
        },
        incidentMsgContainer: {
            visible: _.isEmpty(_.get(memberVM, 'memberIncidents.value', [])) && _.get(memberVM, 'hasLegalHistory.value', undefined)
        },
        incidentMsg: {
            content: WMICRichTextUtil.translateRichText(translator(messages.incidentMsg))
        }
    }

    const resolvers = {
        resolveComponentMap: {
            WMICPUPLegalHistoryComponent
        },
        resolveCallbackMap: {
            addIncident,
            onHasLegalHistoryChange
        }
    }

    return <ViewModelForm
        uiProps={metadata.componentContent}
        model={memberVM}
        overrideProps={overrideProps}
        onModelChange={updateSelectedHouseholdMember}
        onValidationChange={setComponentValidation}
        showErrors={showErrors}
        componentMap={resolvers.resolveComponentMap}
        callbackMap={resolvers.resolveCallbackMap}
    />
}

WMICHouseholdMemberDetailsComponent.propTypes = {
    id: PropTypes.string.isRequired,
    memberVM: PropTypes.PropTypes.shape({}),
    updateSelectedHouseholdMember: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    showErrors: PropTypes.bool.isRequired,
    setShowErrors: PropTypes.func.isRequired,
    saveHouseHoldMember: PropTypes.func.isRequired,
    cancelHouseHoldMember: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    jobVM: PropTypes.shape({}),
    showButtons: PropTypes.bool,
};

WMICHouseholdMemberDetailsComponent.defaultProps = {
    showButtons: true,
}

export default WMICHouseholdMemberDetailsComponent

