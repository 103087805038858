
import { setComponentMapOverrides } from '@jutro/uiconfig';
import { WMICQualificationPage } from 'wmic-pe-capability-quoteandbind-common-react';
import WMICPUPHouseholdMembersPage from './pages/WMICPUPHouseholdMembers/WMICPUPHouseholdMembersPage';
import WMICPUPPolicyDetailsPage from './pages/WMICPUPPolicyDetails/WMICPUPPolicyDetailsPage';
import WMICPUPUnderlyingPoliciesPage from './pages/WMICPUPUnderlyingPolicies/WMICPUPUnderlyingPoliciesPage';
import WMICPUPQuotePage from './pages/WMICPUPQuotePage/WMICPUPQuote';

setComponentMapOverrides(
    {
        WMICPUPHouseholdMembersPage: { component: 'WMICPUPHouseholdMembersPage' },
        WMICPUPPolicyDetailsPage: { component: 'WMICPUPPolicyDetailsPage' },
        WMICPUPQualificationsPage: { component: 'WMICPUPQualificationsPage' },
        WMICPUPUnderlyingPoliciesPage: { component: 'WMICPUPUnderlyingPoliciesPage' },
        WMICPUPQuotePage: { component: 'WMICPUPQuotePage' }
    },
    {
        WMICPUPHouseholdMembersPage,
        WMICPUPPolicyDetailsPage,
        WMICPUPQualificationsPage: WMICQualificationPage,
        WMICPUPUnderlyingPoliciesPage,
        WMICPUPQuotePage: WMICPUPQuotePage
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PEPUPWizard } from './PEPUPWizard';
export { default as PUPSubmissionLobDataExtension } from './extension/PUPSubmissionLobDataExtension';
export { default as WMICPUPUnderlyingPoliciesMessages } from './pages/WMICPUPUnderlyingPolicies/WMICPUPUnderlyingPoliciesPage.messages';