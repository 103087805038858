import { Augment } from '@xengage/gw-portals-viewmodel-utils-js';

const LABEL_METADATA = 'wmic.edge.common.aspects.validation.dto.LabelDTO';

/**
 * Creates a new "Field is required" aspect.
 * @param {Object} expressionLanguage
 * @returns {Object}
 */
function create(expressionLanguage) {
    function getAllRules(compilationContext, node, nodeMetadata, ancestorChain) {
        return Augment.collectRules(compilationContext, node, nodeMetadata, ancestorChain, LABEL_METADATA);
    }

    function compileJurisdictionExpr(compilationContext, node, parent, jExpr) {
        const expr = compilationContext.compile(jExpr);
        return function () {
            return expr(node, parent, node.aspects.context);
        };
    }

    return {
        'getAspectProperties': function (currentViewModelNode, currentMetadataNode, ancestorChain) {
            const compilationContext = expressionLanguage.getCompilationContext(currentMetadataNode.xCenter);
            const rules = getAllRules(compilationContext, currentViewModelNode, currentMetadataNode, ancestorChain);

            let jCompiledExpr;
            if (rules && rules.length > 0) {
                const parent = ancestorChain.parent || {};
                jCompiledExpr = compileJurisdictionExpr(compilationContext,
                    currentViewModelNode,
                    parent,
                    rules[0].data.expression);
            }

            return {
                exprDisplay: {
                    get: function () {
                        if (jCompiledExpr) {
                            const typelist = jCompiledExpr();
                            if (typelist === undefined) { return false; }

                            let label = rules[0].data.displayLabels[typelist.code];
                            if (label === undefined) {
                                label = rules[0].data.displayLabels.default;
                            }
                            return label;
                        }
                        return false;
                    }
                }
            };
        }
    };
}

export default {
    create
};
