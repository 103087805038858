import { defineMessages } from 'react-intl';

export default defineMessages({
    firstName: {
        id: "wmic-pe-components-platform-react.WMICContactDetails.First Name",
        defaultMessage: "First Name"
    },        
    middleName: {
        id: "wmic-pe-components-platform-react.WMICContactDetails.Middle Name",
        defaultMessage: "Middle Name"
    },     
    lastName: {
        id: "wmic-pe-components-platform-react.WMICContactDetails.Last Name",
        defaultMessage: "Last Name"
    },        
    contactInfo: {
        id: "quoteandbind.views.payment-details.ContactInfo",
        defaultMessage: "Contact Info"
    },    
    companyName: {
        id: "platform.inputs.company-details.Name",
        defaultMessage: "Company Name"
    },
});
