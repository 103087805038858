import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { WMICJsonRPCService as JsonRPCService } from 'wmic-pe-portals-transport-js';

export default class BillingService {
    static resendBillingDocuments(accountNumber, policyNumber, accountEmail, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBillingData'),
            'resendBillingDocuments', [accountNumber, policyNumber, accountEmail], additionalHeaders);
    }

    static getDocumentForEDelivery(accountNumber, policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBillingData'),
            'getDocumentForEDelivery', [accountNumber, policyNumber], additionalHeaders);
    }    
    
    static getPaymentPlanChangesWithInvoices(policyNumber, termNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBillingData'),
            'getPaymentPlanChangesWithInvoices', [policyNumber, termNumber], additionalHeaders);
    }

    static changePaymentPlanTo(policyNumber, termNumber, newPaymentPlanName, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBillingData'),
            'changePaymentPlanTo', [policyNumber, termNumber, newPaymentPlanName], additionalHeaders);
    }

    static changePaymentInstrumentTo(policyNumber, termNumber, newPaymentInstrumentPublicID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBillingData'),
            'changePaymentInstrumentTo', [policyNumber, termNumber, newPaymentInstrumentPublicID], additionalHeaders);
    }    
    
    static applyNewPaymentInstrumentForExistingContact(policyNumber, termNumber, contactPublicID, newEftData, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBillingData'),
            'applyNewPaymentInstrumentForExistingContact', [policyNumber, termNumber, contactPublicID, newEftData], additionalHeaders);
    }

    static applyNewPaymentInstrumentForNewContact(policyNumber, termNumber, newContact, newEftData, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBillingData'),
            'applyNewPaymentInstrumentForNewContact', [policyNumber, termNumber, newContact, newEftData], additionalHeaders);
    }    
    
    static getBankDetailsChangeRequest(policyNumber, termNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBillingData'),
            'getBankDetailsChangeRequest', [policyNumber, termNumber], additionalHeaders);
    }

    static submitBankDetailsChangeRequest(policyNumber, termNumber, newEftData, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBillingData'),
            'submitBankDetailsChangeRequest', [policyNumber, termNumber, newEftData], additionalHeaders);
    }    
    
    static getLastPaymentInfo(policyNumber, termNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBillingData'),
            'getLastPaymentInfo', [policyNumber, termNumber], additionalHeaders);
    }

    static getAmountPaid(policyNumber, termNumber, refNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBillingData'),
            'getAmountPaid', [policyNumber, termNumber, refNumber], additionalHeaders);
    }    
    
    static getPolicyBillingSummaryInfo(policyNumber, termNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBillingData'),
            'getPolicyBillingSummaryInfo', [policyNumber, termNumber], additionalHeaders);
    }
}