import { 
    WMICConstantsUtil,
} from 'wmic-pe-portals-utils-js';

export default { 
    isNoPreviousInsuranceValue: (jurisdiction, asOf, carrierName) => {
        if (jurisdiction && asOf && carrierName) {
            return [WMICConstantsUtil.getConstant(WMICConstantsUtil.NO_PREVIOUS_INSURANCE, jurisdiction, asOf),
                WMICConstantsUtil.getConstant(WMICConstantsUtil.NO_PREVIOUS_INSURANCE_FR, jurisdiction, asOf)].includes(carrierName);
        }
        return false;
    }
}