import React, { useState, useEffect } from 'react'
import { ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'

import metadata from './WMICDriverClassHistoryComponent.metadata.json5'

function WMICDriverClassHistoryComponent(props) {
    const {
        id,
        selectedDriverClass,
        saveClassHistory,
        licenseClasses,
        cancelClassHistory,
        onValidate,
        isEditMode,
        minDate,
        maxDate
    } = props

    const [currentDriverClass, updateCurrentDriverClass] = useState(selectedDriverClass);
    const [showErrors, updateShowErrors] = useState(false);
    const { refreshData } = useDataRefresh();
    const [isDisabled, setIsDisabled] = useState(true);


    const {onValidate: setComponentValidation, isComponentValid} = useValidation(id);
    
    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => {
            if(onValidate){
                onValidate(true, id)
            }
        }
    }, [isComponentValid, onValidate, currentDriverClass, id]);

    useEffect(() => {
        updateCurrentDriverClass(selectedDriverClass);
    }, [selectedDriverClass]);

    const handleSaveDriverClass = () => {
        if (isComponentValid) {
            saveClassHistory(currentDriverClass);
        } else {
            updateShowErrors(true);
        }
    };

    const areFieldsSet = () => {
        return (selectedDriverClass.driverClassType_WMIC.value !== undefined) && (selectedDriverClass.dateLicensed.value !== undefined);
    }

    const updateDriverClass = (data) => {
        setIsDisabled(!areFieldsSet());
        refreshData();
        updateCurrentDriverClass(data);
    };

    const resolvers = {
        resolveCallbackMap: {
            handleSaveDriverClass,
            cancelClassHistory
        }
    }

    const overrideProps = {
        '@field': {
            parentNode: currentDriverClass,
            readOnly: !isEditMode,
        },
        driverClassHistoryContainer: {
            disableSave: isDisabled
        },
        driverClassType: {
            availableValues: licenseClasses
        },
        driverClassDateObtained: {
            maxDate,
            minDate
        }
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={selectedDriverClass}
            overrideProps={overrideProps}
            onModelChange={updateDriverClass}
            callbackMap={resolvers.resolveCallbackMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    )
}

export default WMICDriverClassHistoryComponent;
