import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    ModalNext
} from '@jutro/components';
import styles from './WMICProgressModal.module.scss';

export function WMICProgressModal(props) {
    const {
        modalTitle,
        isOpen,
        onResolve,
        onReject,
        promise
    } = props;

    if (isOpen) document.body.classList.add('noscroll');
    
    useEffect(() => {
        promise && promise.then(
            (response) => onResolve(response)
        ).catch((error) => {
            onReject(error)
        });
    }, [onReject, onResolve, promise]);

    useEffect(() => {
        return document.body.classList.remove('noscroll');
    }, []);

    return (
        <ModalNext
            overlayClassName={styles.WMICModal__overlay}
            isOpen={isOpen}
            className={styles.WMICProgressModal}
            onAfterClose={() => document.body.classList.remove('noscroll')}
        >
            <div className="modal-header">
                <h3>{modalTitle}</h3>
            </div>
            <div className="modal-body">
                <div className="clearfix">
                    <div className="span2" />
                    <div className="span8">
                        <div className="progress progress-striped active">
                            <div className="bar" />
                        </div>
                    </div>
                    <div className="span2" />
                </div>
            </div>
        </ModalNext>
    );
}

WMICProgressModal.propTypes = {
    modalTitle: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    promise: PropTypes.shape({
        then: PropTypes.func
    }).isRequired,
    onResolve: PropTypes.func.isRequired
};

export default WMICProgressModal;