import React, { useContext } from 'react';
import _ from 'lodash';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICListView } from 'wmic-pe-components-platform-react';
import { WMICDateTimeService, CONSTANTS, PRODUCT } from 'wmic-pe-portals-utils-js';
import { WMICWizardViewPage } from 'wmic-pe-portals-wizard-components-ui';
import { TranslatorContext } from '@jutro/locale';

import WMICPolicyInsuredDetailView from '../../components/WMICPolicyInsuredDetailView/WMICPolicyInsuredDetailView.jsx';

import messages from './WMICPolicyInsuredPage.messages'
import metadata from './WMICPolicyInsuredPage.metadata.json5';

function WMICPolicyInsuredPage(props) {
    const translator = useContext(TranslatorContext);
    const {
        wizardData: { policyVM },
    } = props;

    const isCommercialPackage = _.get(policyVM, "product.productCode.value") === PRODUCT.COMMERCIAL_PACKAGE

    function getFullName(insured){
        return (_.get(insured, "subtype.value") === CONSTANTS.Person) ? `${_.get(insured, "firstName.value")} ${_.get(insured, "lastName.value")}` : _.get(insured, "displayName.value")
    }

    function formatDateOfBirth(insured){
        return (_.get(insured, "dateOfBirth.value")) ? WMICDateTimeService.toMidnightDate(_.get(insured, "dateOfBirth.value")) : "";
    }

    const overrideProps = {
        '@field': {
            parentNode: policyVM,
            readOnly: true
        },
        insuredDetailViewComponent : {
            VMData: [
                {
                    headerText: translator(messages.headerName),
                    path: "firstName",
                    getData: (insured) => getFullName(insured)
                },
                {
                    headerText: translator(messages.headerPNI),
                    path: "isPrimaryInsured"
                },
                {
                    headerText: translator(messages.headerRelationship),
                    path: "primaryInsuredRelation_WMIC",
                    visibilityCondition: () => { return !isCommercialPackage }
                },
                {
                    headerText: translator(messages.headerDOB),
                    getData: (insured) => formatDateOfBirth(insured),
                    visibilityCondition: () => { return !isCommercialPackage }
                }
            ],
            clickable: true,
            readOnly: true,
            detailViewComponent: WMICPolicyInsuredDetailView
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICListView
        }
    }

    return (
        <WMICWizardViewPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyVM}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
            />
        </WMICWizardViewPage>
    );
}

WMICPolicyInsuredPage.propTypes = wizardProps;
export default WMICPolicyInsuredPage;
