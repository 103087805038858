import React, { useState, useMemo } from 'react';
import _ from 'lodash';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICWizardViewPage } from 'wmic-pe-portals-wizard-components-ui';
import { WMICInsuranceHistoryPoliciesComponent } from 'wmic-pe-capability-gateway-common-react';
import { WMICPAPolicyLossHistory, WMICHOPolicyLossHistory } from 'wmic-pe-capability-policy-common-react';
import { WMICLobUtil, LOBConstants } from 'wmic-pe-portals-utils-js';

import metadata from './WMICPolicyInsuranceHistory.metadata.json5';

function WMICPolicyInsuranceHistory(props) {

    const {
        wizardData: { policyVM },
    } = props;
    const lobPath = WMICLobUtil.getProductPath(_.get(policyVM, "product.productCode.value"));
    const [insuranceHistoryVM] = useState(_.get(policyVM, `lobs.${lobPath}.insuranceHistory_WMIC`));
    const baseState = _.get(policyVM, `lobs.${lobPath}.baseState.value.code`);

    //  Used for functions that are mandatory on the edit mode, but completely useless in this page
    const noop = () => {};

    const getLossHistoryComponent = useMemo(() => {
        if (lobPath === LOBConstants.PA_PATH) {
            return (
                <WMICPAPolicyLossHistory 
                    insuranceHistoryVM={insuranceHistoryVM}
                    policyVM={policyVM}
                    baseState={baseState}
                />
            )
        } 

        return (
            <WMICHOPolicyLossHistory 
                insuranceHistoryVM={insuranceHistoryVM}
                policyVM={policyVM}
                baseState={baseState}
            />
        )
    }, [baseState, lobPath, insuranceHistoryVM, policyVM]);

    const commonAccordionContentProps = {
        submissionVM : policyVM,
        insuranceHistoryVM,
        readOnly: true,
        isEditMode: false,
        updateHistory: noop,
        onValidate: noop
    };

    const overrideProps = {
        policiesAccordionContent: {
            ...commonAccordionContentProps,
            jobVM: policyVM
        },
        lossHistoryAccordion: {
            content: getLossHistoryComponent
        }
    };

    const resolvers = {
        resolveComponentMap:{
            WMICInsuranceHistoryPoliciesComponent,
        }
    };

    return (
        <WMICWizardViewPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyVM}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
            />
        </WMICWizardViewPage>
    );
}

WMICPolicyInsuranceHistory.propTypes = wizardProps;
export default WMICPolicyInsuranceHistory;
