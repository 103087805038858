/* eslint-disable no-param-reassign */
import _ from 'lodash';

const PASubmissionDraftDataExtension = (submission) => {
    const lobData = _.get(submission, 'lobData.personalAuto');
    if (lobData) {
        lobData.drivers = lobData.drivers || [];
        lobData.vehicles = lobData.vehicles || [];
        lobData.insuranceHistory_WMIC = lobData.insuranceHistory_WMIC || [];
        lobData.endorsements_Ext = lobData.endorsements_Ext || undefined;
        lobData.insuranceHistory_WMIC = lobData.insuranceHistory_WMIC || [];
        lobData.linePublicID_WMIC = lobData.linePublicID_WMIC || undefined;
        lobData.pfrexists_WMIC = lobData.pfrexists_WMIC || undefined;
        lobData.availableAdditionalInterests_WMIC = lobData.availableAdditionalInterests_WMIC || [];
    }

    return lobData;
};


export default PASubmissionDraftDataExtension;
