import CONSTANTS from 'wmic-pe-portals-utils-js/StringConstants';

export default {
    getZipMask: (country, postalCode) => {
        if (country) {
            if (country.code === CONSTANTS.COUNTRY.CA) return CONSTANTS.POSTAL_CODE_MASKS.CA_CODE;
            if (country.code === CONSTANTS.COUNTRY.US) {
                if (postalCode.value && postalCode.value.indexOf('-') > -1) {
                    return CONSTANTS.POSTAL_CODE_MASKS.US_CODE_LONG;
                }
                return CONSTANTS.POSTAL_CODE_MASKS.US_CODE;
            }
        }
        return '';
    }
};
