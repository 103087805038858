import _ from 'lodash';
import { countriesAddressData } from "@jutro/address"
import { CONSTANTS } from 'wmic-pe-portals-utils-js';

const CANADIAN_POSTAL_CODE_REGEX_PATTERN = '[ABCEGHJKLMNPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z] \\d[ABCEGHJ-NPRSTV-Z]\\d';

function buildAddressLine(defaultDelimiter) {
    const fragments = [];

    return {
        append(fragment, delimiter = defaultDelimiter) {
            fragments.push({ fragment, delimiter });
            return this;
        },
        join() {
            return fragments
                .filter(({ fragment }) => fragment)
                .reduce((formatted, { fragment, delimiter }, index) => {
                    return formatted
                        + (index ? delimiter : '')
                        + fragment;
                }, '');
        }
    };
}

const caPostalCodeField = countriesAddressData[CONSTANTS.COUNTRY.CA].fields.find(e => e.name === 'postalCode');
// Product Bug: Setting a mask disables the pattern matching validation
// If fixed, this pattern can replace the onAddressValidation override in WMICAddressDetails
caPostalCodeField.pattern = CONSTANTS.POSTAL_CODE_REGEX.CA;
caPostalCodeField.mask = CONSTANTS.POSTAL_CODE_MASKS.CA_CODE;
Object.keys(countriesAddressData).forEach(pCountry => {
    const postalCode = countriesAddressData[pCountry].fields.find(e => e.name === 'postalCode')
    if(postalCode?.pattern) {
        postalCode.pattern = postalCode.pattern.concat('|^$')
    }
})

export default { 
    validatePostalCode: (postalCode) => {
        if (postalCode === undefined || postalCode === null || postalCode.trim() === '') {
            return true;
        }
        const value = postalCode.match(CANADIAN_POSTAL_CODE_REGEX_PATTERN);
        return value !== null && value[0] === postalCode.trim();
    },

    getDisplayName: (address) => {
        const displayName = _.get(address, 'displayName.value', '');
        if (_.isEmpty(displayName)) {
            try {
                return `${address.addressLine1.value}, ${address.city.value}, ${address.state.value.code} ${address.postalCode.value}, ${address.country.value.code}`;
            } catch(e) {
                return '';
            }
        }
        return displayName;
    },

    formatAddress: (address) => {
        const {
            addressLine1,
            addressLine2,
            addressLine3,
            city,
            country,
            postalCode
        } = address.value;
        const delimiter = ', ';
        const builder = buildAddressLine(delimiter);
    
        return builder
            .append(addressLine1)
            .append(addressLine2)
            .append(addressLine3)
            .append(city)
            .append(postalCode, ' ')
            .append(country)
            .join();
    },
    countriesAddressData
};
