import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import metadata from './WMICPolicyPaCommercialDriverComponent.metadata.json5';
import messages from './WMICPolicyPaCommercialDriverComponent.messages.js';

function WMICPolicyPaCommercialDriverComponent(props) {
    const {
        commercialDriverVM
    } = props;

    const translator = useContext(TranslatorContext);

    const overrideProps = {
        '@field': {
            parentNode: commercialDriverVM,
            readOnly: true
        },
        requiredMsg: {
            content: translator(messages.requiredMsg),
        },
        authSectionContainer: {
            visible: _.get(commercialDriverVM, 'authContainer.aspects.ocular')
        },
        trainingSectionContainer: {
            visible: _.get(commercialDriverVM, 'trainingContainer.aspects.ocular')
        },
        completedTrainingContainer: {
            visible: _.get(commercialDriverVM, 'completedTrainingContainer.aspects.ocular')
        },
        heavyVehicleExpSectionContainer: {
            visible:_.get(commercialDriverVM, 'heavyVehicleExpContainer.aspects.ocular')
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={commercialDriverVM}
            overrideProps={overrideProps}
        />
    );
}

WMICPolicyPaCommercialDriverComponent.propTypes = {
    commercialDriverVM: PropTypes.shape({}).isRequired
};

export default WMICPolicyPaCommercialDriverComponent;
