import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'
import { ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { TranslatorContext } from '@jutro/locale';
import { CONSTANTS } from 'wmic-pe-portals-utils-js'

import metadata from './WMICPUPLegalHistoryComponent.metadata.json5';
import messages from './WMICPUPLegalHistoryComponent.messages.js';

function WMICPUPLegalHistoryComponent(props) {
    const { 
        id,
        incidentVM,
        setIncidentVM,
        onValidate,
        showErrors,
        setShowErrors,
        saveIncident,
        cancelIncident,
        isEditing
    } = props;

    const translator = useContext(TranslatorContext);
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const { refreshData } = useDataRefresh();

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [isComponentValid, onValidate, id]);

    const handleSaveIncident = () => {
        if(isComponentValid) {
            saveIncident(incidentVM);
        } else {
            setShowErrors(true);
        }
    }

    const onIncidentTypeChange = (value, path) => {
        _.set(incidentVM, path, value);
        _.set(incidentVM, 'incidentDescription.value', undefined);
        updateIncident(incidentVM);
    }

    const updateIncident = (incident) => {
        setIncidentVM(incident);
        refreshData();
    };

    const overrideProps =  {
        '@field': {
            parentNode: incidentVM,
            readOnly: !isEditing
        },
        incidentDate: {
            label: _.get(incidentVM, 'incidentType.value.code') === CONSTANTS.INCIDENT_TYPES.PROHIBITION ? translator(messages.incidentEndDate) : translator(messages.incidentDateOccured) 
        }
    }


    const resolvers = {
        resolveCallbackMap: {
            handleSaveIncident,
            cancelIncident,
            onIncidentTypeChange
        }
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={incidentVM}
            onModelChange={updateIncident}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

WMICPUPLegalHistoryComponent.propTypes = {
    id: PropTypes.string.isRequired,
    incidentVM: PropTypes.PropTypes.shape({}),
    setIncidentVM: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    showErrors: PropTypes.bool.isRequired,
    setShowErrors: PropTypes.func.isRequired,
    saveIncident: PropTypes.func.isRequired,
    cancelIncident: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired
};

export default WMICPUPLegalHistoryComponent;