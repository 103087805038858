import { defineMessages } from 'react-intl';

export default defineMessages({
    everything: {
        id: 'gateway.directives.ProducerCodeSelectDirective.ProducerCodeSelect.Everything',
        defaultMessage: 'Everything'
    },
    workStartedByMe: {
        id: 'gateway.directives.ProducerCodeSelectDirective.ProducerCodeSelect.Work started by me',
        defaultMessage: 'Work Started By Me'
    }
});
