import { defineMessages } from 'react-intl';

export default defineMessages({
    savingTransactionDetails: {
        id: 'wmic-pe-capability-gateway-renewal-pup-react.WMICPEPUPRenewalWizard.Saving Transaction Details',
        defaultMessage: 'Saving Transaction Details'
    },
    addLocations: {
        id: 'wmic.renewal.pup.directives.templates.pup-wizard.addLocations',
        defaultMessage: 'Add Locations'
    },
    chooseEffectiveDate: {
        id: 'wmic.renewal.pup.directives.templates.pup-wizard.chooseEffectiveDate',
        defaultMessage: 'Choose Effective Date'
    },
    locations: {
        id: 'wmic.renewal.pup.directives.templates.pup-wizard.locations',
        defaultMessage: 'Locations'
    },
    yourPolicies: {
        id: 'wmic.renewal.pup.directives.templates.pup-wizard.yourPolicies',
        defaultMessage: 'Your Policies'
    },
    addPolicy: {
        id: 'wmic.renewal.pup.directives.templates.pup-wizard.addPolicy',
        defaultMessage: 'Add Policy'
    },
    employerAffiliation: {
        id: 'wmic.renewal.pup.directives.templates.pup-wizard.employerAffiliation',
        defaultMessage: 'Employer/Affiliation'
    },
    drivers: {
        id: 'wmic.renewal.pup.directives.templates.pup-wizard.Drivers',
        defaultMessage: 'Drivers'
    },
    excludedDrivers: {
        id: 'wmic.renewal.pup.directives.templates.pup-wizard.Excluded Drivers',
        defaultMessage: 'Excluded Drivers'
    },
    vehicles: {
        id: 'wmic.renewal.pup.directives.templates.pup-wizard.Vehicles',
        defaultMessage: 'Vehicles'
    },
    watercraft: {
        id: 'wmic.renewal.pup.directives.templates.pup-wizard.Watercraft',
        defaultMessage: 'Watercraft'
    },
    businessActivity: {
        id: 'wmic.renewal.pup.directives.templates.pup-wizard.Business Activity',
        defaultMessage: 'Business Activity'
    },
    additionalPolicyInfo: {
        id: 'wmic.renewal.pup.directives.templates.pup-wizard.Additional Policy Info',
        defaultMessage: 'Additional Policy Info'
    },
    wmicQuoteDetails: {
        id: 'wmic.renewal.pup.directives.templates.pup-wizard.Quote Details',
        defaultMessage: 'Quote Details'
    },
    anErrorOccurred: {
        id: 'wmic.-portals-renewal-pc-ho.wizard.error',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'wmic.-portals-renewal-pc-ho.wizard.error.title',
        defaultMessage: 'Error'
    },
    saveQuoteError: {
        id: 'wmic.-portals-renewal-pc-ho.Unable to quote',
        defaultMessage: 'Unable to quote'
    },
    saveQuoteErrorMessage: {
        id: 'wmic.-portals-renewal-pc-ho.An error occurred while attempting to quote.',
        defaultMessage: 'An error occurred while attempting to quote.'
    },
    progress: {
        id: 'wmic-pe-capability-gateway-policyRenewal-pup-react.WMICPEPUPRenewalWizard.Progress',
        defaultMessage: 'Progress'
    },
    policyDetails: {
        id: "wmic-pe-capability-gateway-policyrenewal-pup-react.WMICPUPPolicyRenewalPolicyDetailsPage.Policy Details",
        defaultMessage: "Policy Details"
    },
    underlyingPolicies: {
        id: "wmic-pe-capability-gateway-policyrenewal-pup-react.WMICPUPPolicyRenewalsUnderlyingPoliciesPage.Underlying Policies",
        defaultMessage: "Underlying Policies"
    }
});
