import React, { useContext, useMemo } from 'react';
import _ from 'lodash';
import { LocalDateUtil } from '@xengage/gw-portals-util-js'
import { WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICAddressDetails, WMICContactDetails, WMICDataList, WMICPhoneDetails, } from 'wmic-pe-components-platform-react';
import { LOBConstants, WMICCreditConsentUtil, WMICRPCUtil, JURISDICTIONS, CONSTANTS, PRODUCT } from 'wmic-pe-portals-utils-js';
import { TranslatorContext } from '@jutro/locale';

import WMICPolicyInsuredAfterRPCCreditConsent from '../WMICPolicyInsuredAfterRPCCreditConsent/WMICPolicyInsuredAfterRPCCreditConsent';
import WMICPolicyInsuredHOConsent from '../WMICPolicyInsuredHOConsent/WMICPolicyInsuredHOConsent';
import WMICPolicyInsuredPAConsent from '../WMICPolicyInsuredPAConsent/WMICPolicyInsuredPAConsent';

import messages from './WMICPolicyInsuredDetailView.messages'
import metadata from './WMICPolicyInsuredDetailView.metadata.json5';

function WMICPolicyInsuredDetailView(props) {
    const { value : selectedInsured } = props;
    const { wizardData: { policyVM } } = useContext(WizardContext);
    const translator = useContext(TranslatorContext);

    const selectedInsuredWithSubTypeCorrection = useMemo(() => {
        if( _.get(selectedInsured, "subtype.value").toLowerCase() === CONSTANTS.Person.toLowerCase()){
            _.set(selectedInsured, "subtype.value", CONSTANTS.Person)
            return selectedInsured;
        }

        return selectedInsured;
    }, [selectedInsured])

    const primaryInsured = _.filter( policyVM.value.latestPeriod.namedInsureds, (insured) => insured.isPrimaryInsured );
    const additionalInsured = _.filter( policyVM.value.latestPeriod.namedInsureds, (insured) => !insured.isPrimaryInsured );
    const isPUP = _.get(policyVM, 'product.productCode.value') === LOBConstants.PUP;
    const isPA =  _.get(policyVM, 'product.productCode.value') === LOBConstants.PA;
    const isPP = _.get(policyVM, 'product.productCode.value') === LOBConstants.PP;

    const isPNI = useMemo( () => _.get(selectedInsured, 'isPrimaryInsured.value'), [selectedInsured] );
    const isPerson = selectedInsuredWithSubTypeCorrection.subtype.value === CONSTANTS.Person

    const rateAsOfDate = _.get(policyVM, `latestPeriod.rateAsOfDate.value`);
    const effectiveDate = LocalDateUtil.toMidnightDate(_.get(policyVM, 'latestPeriod.editEffectiveDate_WMIC.value'));

    const addressHeader = useMemo(() => {
        return (isPerson) ? translator(messages.personAddressHeading) : translator(messages.companyAddressHeading)
    }, [selectedInsuredWithSubTypeCorrection, translator])

    const creditConsentFieldsVisible = useMemo(() => {
        return new Date(effectiveDate).getTime() >= WMICCreditConsentUtil.HO_CREDIT_CONSENT_ENHANCEMENT_DATE.getTime();
    }, [effectiveDate]);

    const showCreditInfo = useMemo(() => {
        if (isPP) {
            return WMICCreditConsentUtil.isHOCreditConsentTabVisibleOnInsuranceHistory( effectiveDate ) ? false : showCreditConsentInfo;
        }

        return showCreditConsentInfo;
    }, [isPP, showCreditConsentInfo, effectiveDate]);

    const showCreditConsentInfo = useMemo(() => {
        const isSelectedInsuredCreditConsentable = WMICCreditConsentUtil.isPniCreditConsentable(selectedInsured) || WMICCreditConsentUtil.isAniCreditConsentable(selectedInsured);

        if (!isSelectedInsuredCreditConsentable) {
            return false;
        }

        if ( (isPA && JURISDICTIONS.QUEBEC === _.get(selectedInsured, 'address.state.value.code')) || (WMICRPCUtil.getIsRPCEffective( _.get(selectedInsured, 'address.state.value.code'), rateAsOfDate, '1296' ) && !WMICRPCUtil.getIsRPCEffective( _.get(selectedInsured, 'address.state.value.code'), rateAsOfDate, '1576' )) ) {
            return true;
        }

        return isPP && isPNI && creditConsentFieldsVisible;
    }, [ isPA, isPNI, isPP, selectedInsured, creditConsentFieldsVisible, rateAsOfDate, ]);

    const showCreditConsentForAutoAfterRPC = useMemo(() => {
        if (!isPA) {
            return false;
        }
        const discountCreditConsentRPCIsEffectiveCodes = [ '1503', '1504', '1576', '1543', ];

        const discountCreditConsentRPCIsEffective = discountCreditConsentRPCIsEffectiveCodes.some( (rpc) => WMICRPCUtil.getIsRPCEffectiveForVehTypeAndBusSegment( _.get(selectedInsured, 'address.state.value.code'), rateAsOfDate, null, null, rpc ) );

        const paymentPlanCreditConsentRPCIsEffective = WMICRPCUtil.getIsRPCEffective( _.get(selectedInsured, 'address.state.value.code'), rateAsOfDate, '1296' );
        const hasPersonalVehicleOrMotorHome = WMICCreditConsentUtil.hasPersonalVehicleOrMotorHome( _.get(policyVM, 'lobs.personalAuto.vehicleDTOs.value') );
        const isPniCreditConsentable = WMICCreditConsentUtil.isPniCreditConsentable( primaryInsured[0] );
        const isAnyAniCreditConsentable = WMICCreditConsentUtil.isAnyAniCreditConsentable(additionalInsured);

        return ( discountCreditConsentRPCIsEffective && (paymentPlanCreditConsentRPCIsEffective || hasPersonalVehicleOrMotorHome) && (isPniCreditConsentable || isAnyAniCreditConsentable) );
    }, [ rateAsOfDate, selectedInsured, primaryInsured, additionalInsured, isPA, policyVM ]);

    const isCreditConsentAfterRPCVisible = useMemo( () => showCreditInfo && !showCreditConsentForAutoAfterRPC, [showCreditInfo, showCreditConsentForAutoAfterRPC]);
    const isCreditConsentHOVisible = useMemo(() => showCreditConsentInfo && isPP && WMICCreditConsentUtil.isHOCreditConsentTabVisibleOnInsuranceHistory( effectiveDate ), [isPP, showCreditConsentInfo, effectiveDate]);
    const isCreditConsentPAVisible = useMemo( () => showCreditConsentForAutoAfterRPC, [showCreditConsentForAutoAfterRPC] );

    const isCommercialPackage = _.get(policyVM, "product.productCode.value") === PRODUCT.COMMERCIAL_PACKAGE

    const overrideProps = {
        '@field': {
            parentNode: policyVM,
            readOnly: true,
        },
        addressDetailsComponent: {
            addressVM: _.get(selectedInsured, 'address', {}),
            hideBorder: true,
            hideButtons: true,
        },
        insuredAddressHeading: {
            title: addressHeader,
            visible: !isCommercialPackage
        },
        contactDetailsComponent: {
            contactVM: selectedInsuredWithSubTypeCorrection,
            showContactInfo: false,
            visible: isPerson
        },
        creditConsentDetailsAccordion: {
            visible: !isCommercialPackage && !isPUP && isPerson && (isCreditConsentHOVisible || isCreditConsentPAVisible || isCreditConsentAfterRPCVisible),
        },
        companyContactDetailsNumber: {
            visible: !isCommercialPackage
        },
        WMICPolicyInsuredAfterRPCCreditConsent: {
            policyVM,
            selectedInsured,
            visible: isCreditConsentAfterRPCVisible,
        },
        WMICPolicyInsuredHOConsent: {
            selectedInsured,
            visible: isCreditConsentHOVisible,
        },
        WMICPolicyInsuredPAConsent: {
            policyVM,
            selectedInsured,
            visible: isCreditConsentPAVisible,
        },
        companyContactDetailsFrame: {
            visible: !isPerson
        },
        companyContactDetailsName: {
            parentNode: selectedInsured,
            value: _.get(selectedInsured, "displayName.value")
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICDataList,
            WMICAddressDetails,
            WMICContactDetails,
            WMICPhoneDetails,
            WMICPolicyInsuredAfterRPCCreditConsent,
            WMICPolicyInsuredHOConsent,
            WMICPolicyInsuredPAConsent
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={policyVM}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default WMICPolicyInsuredDetailView;
