import React, {
    useEffect,
    useContext,
    useState, useMemo, useCallback
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { WMICJurisdictionUtil, WMICDriverUtil, JURISDICTIONS, POLICY_HISTORY_ACTIONS, WMICRichTextUtil } from 'wmic-pe-portals-utils-js';

import { TranslatorContext } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { UISupportingInfoLookupService } from 'wmic-pe-capability-supportinginfo';

import WMICAddPolicyComponent from './WMICAddPolicyComponent/WMICAddPolicyComponent'
import metadata from './WMICDriverPolicyHistoryComponent.metadata.json5';
import messages from './WMICDriverPolicyHistoryComponent.messages.js';

const PREVIOUS_INSURANCE_PATH = 'policyHistory_WMIC.previousInsurances';
const DEFAULT_CARRIER_COUNTRY_CODE = 'CA';

let carriersList;

function WMICDriverPolicyHistoryComponent(props) {
    const {
        id,
        driverVM,
        onValidate,
        isEditMode,
        baseData,
        updateDriver,
        authHeader,
        jobVM
    } = props;

    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const [editingIndex, updateEditingIndex] = useState(-1);
    const [selectedPolicy, updateSelectedPolicy] = useState(null);
    const [policyAction, updatePolicyAction] = useState();
    const [isActionBtnsDisabled, setActionButtonsDisabled] = useState(false);
    const [policyBackup, setPolicyBackup] = useState()
    const { onValidate: setComponentValidation, isComponentValid, registerComponentValidation } = useValidation(id);

    const isDriverPreviousInsuranceHistoryValid = useCallback(() => {
        const prevInsurances = _.get(driverVM, `${PREVIOUS_INSURANCE_PATH}.value`, []);
        return prevInsurances.length > 0
    }, [driverVM]);

    useEffect(() => {
        registerComponentValidation(isDriverPreviousInsuranceHistoryValid);
    }, [registerComponentValidation, isDriverPreviousInsuranceHistoryValid]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [isComponentValid, onValidate, driverVM, id]);

    const autoPlusWarningMessage = useMemo(() => {
        if (
            !driverVM.isExistingInPreviousPeriodDriver_WMIC.value &&
            !driverVM.dateAutoPlusOrdered_WMIC.value &&
            WMICDriverUtil.isAssignedDriver(driverVM)
        ) {
            return translator(messages.requiredToBind);
        }
        
        return undefined;
    }, [translator, _.get(driverVM, 'assignment_WMIC.value'), _.get(driverVM, 'isExistingInPreviousPeriodDriver_WMIC.value'), _.get(driverVM, 'dateAutoPlusOrdered_WMIC.value')]);

    useEffect(() => {
        UISupportingInfoLookupService.retrieveInsuranceCarriersList([baseData.baseState.value.code, baseData.rateAsOfDate.value], true, authHeader)
            .then((carriers) => {
                carriers.forEach((item) => {
                    item.name = item.carrier
                    item.code = item.carrier
                })
                carriersList = carriers;
            });
        if (_.get(driverVM, 'policyHistory_WMIC.value.previousInsurances') === undefined){
            _.set(driverVM, 'policyHistory_WMIC.value.previousInsurances', []);
            updateDriver(driverVM);
        }
    }, [])

    const claimsHistoryLetterOfExperienceHeader = useMemo(() => {
        const autoPlusLabelCondition = _.get(driverVM, `autoPlusLabelCondition_WMIC.aspects.ocular`)
        return translator(autoPlusLabelCondition ? messages.claimHistoryReportHeading : messages.autoPlusHeading);
    }, [driverVM, translator])

    const claimsHistoryLetterOfExperienceDateLabel = useMemo(() => {
        const autoPlusLabelCondition = _.get(driverVM, `autoPlusLabelCondition_WMIC.aspects.ocular`)
        return translator(autoPlusLabelCondition ? messages.claimHistoryReportDate : messages.autoPlusDate);
    }, [driverVM, translator])

    const getPreviousYearsInsuranceLabelNumber = () => {
        return WMICJurisdictionUtil.isBaseState(baseData, ...JURISDICTIONS.MARITIMES) ? 9 : 15;
    }

    const enableActionButtons = () => {
        updateSelectedPolicy(null);
        updatePolicyAction(undefined);
        setActionButtonsDisabled(false);
    }

    const disableActionButtons = (action) => {
        updatePolicyAction(action);
        setActionButtonsDisabled(false);
    }

    const removePolicyAt = (toRemoveIdx) => {
        const prevInsuranceArr = _.get(driverVM, `${PREVIOUS_INSURANCE_PATH}.value`, []);
        prevInsuranceArr.splice(toRemoveIdx, 1);
        _.set(driverVM, `${PREVIOUS_INSURANCE_PATH}.value`, prevInsuranceArr);
    };

    const isViewMode = () => {
        return ![POLICY_HISTORY_ACTIONS.ADD, POLICY_HISTORY_ACTIONS.EDIT].includes(policyAction);
    };

    const createVM = (data = {}) => {
        data.isAssignedDriver = WMICDriverUtil.isAssignedDriver(driverVM);
        _.set(data, 'carrierCountry', data.carrierCountry ?? DEFAULT_CARRIER_COUNTRY_CODE);

        return viewModelService.create(
            data,
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.dto.DriverPreviousInsuranceDTO_WMIC', 
            jobVM.aspects.context()
        );
    }

    const addPolicy = () => {
        disableActionButtons(POLICY_HISTORY_ACTIONS.ADD);
        setActionButtonsDisabled(true);

        const policyHistoryVM = createVM();
        updateSelectedPolicy(policyHistoryVM);
        updateDriver(driverVM);
    };

    const cancelPolicy = () => {
        updateDriver(driverVM);
        enableActionButtons();
    };

    const editPolicy = (policyVM, index) => {
        disableActionButtons(POLICY_HISTORY_ACTIONS.EDIT);
        setActionButtonsDisabled(true);
        const editCopy = createVM(policyVM.value);
        setPolicyBackup(policyVM)
        updateSelectedPolicy(editCopy);
        updateEditingIndex(index);
    };

    const savePolicy = (policyVM) => {
        if (policyAction === POLICY_HISTORY_ACTIONS.ADD) {
            _.get(driverVM, `${PREVIOUS_INSURANCE_PATH}.value`).push(policyVM.value);
        } else if (policyAction === POLICY_HISTORY_ACTIONS.EDIT) {
            const previousPolicyVM = _.get(driverVM, `${PREVIOUS_INSURANCE_PATH}.value`);
            previousPolicyVM.splice(editingIndex, 1, selectedPolicy.value);
        }

        updateDriver(driverVM);
        enableActionButtons();
    };

    const removePolicy = (_policy, policyIndex) => {
        removePolicyAt(policyIndex);
        updateDriver(driverVM);
        updateSelectedPolicy(null);
    };

    const handleSelectPolicy = (policyVM) => {
        if (isViewMode() && policyVM) {
            const aSelectedPolicy = createVM(policyVM.value);
            updateSelectedPolicy(aSelectedPolicy);
        }
    };

    const isAutoPlusAvailable = useMemo(() => {
        return _.get(driverVM, `dateAutoPlusOrdered_WMIC.aspects.ocular`)
    }, [baseData])

    const overrideProps = {
        '@field': {
            parentNode: driverVM,
            readOnly: !isEditMode
        },
        prevInsuranceInfoMsg: {
            content: WMICRichTextUtil.translateRichText(translator(messages.previousInsuranceInfoMsg, { years: getPreviousYearsInsuranceLabelNumber() }))
        },
        previousInsuranceInfoMsg:{
            visible: _.get(driverVM, `${PREVIOUS_INSURANCE_PATH}.value`, []).length === 0 && WMICDriverUtil.isAssignedDriver(driverVM)
        },
        policyDataList: {
            VMList: _.get(driverVM, `${PREVIOUS_INSURANCE_PATH}.children`, []),
            VMData: [
                {
                    headerText: translator(messages.insurerName),
                    path: 'carrierName'
                },
                {
                    headerText: translator(messages.policy),
                    path: 'policyNumber'
                },
                {
                    headerText: translator(messages.riskType),
                    path: 'riskType'
                }
            ],
            onEditAction: editPolicy,
            onRemoveAction: removePolicy,
            flatCards: true,
            clickable: true,
            selectedCardIndex: editingIndex,
            updateSelectedCardIndex: updateEditingIndex,
            isEditing: isActionBtnsDisabled || !isEditMode,
            readOnly: !isEditMode,
            onClickAction: handleSelectPolicy
        },
        policyAddButton: {
            disabled: isActionBtnsDisabled,
            visible: isEditMode
        },
        policyForm: {
            onValidate: setComponentValidation,
            visible: !!selectedPolicy && isEditMode,
            selectedPolicy,
            savePolicy,
            cancelPolicy,
            driverVM,
            baseData,
            updateSelectedPolicy,
            updateDriver,
            carriersList,
            policyViewMode: isViewMode(),
            isEditMode
        },
        autoPlusContainer: {
            visible: isAutoPlusAvailable
        },
        autoPlusHeading: {
            title: claimsHistoryLetterOfExperienceHeader
        },
        dateAutoPlusOrderedField: {
            label: claimsHistoryLetterOfExperienceDateLabel,
            secondaryLabel: autoPlusWarningMessage
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            addPolicy
        },
        resolveComponentMap: {
            WMICAddPolicyComponent
        }
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={driverVM}
            overrideProps={overrideProps}
            onModelChange={updateDriver}
            onValidationChange={setComponentValidation}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

WMICDriverPolicyHistoryComponent.propTypes = {
    driverVM: PropTypes.shape({}).isRequired,
    updateDriver: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    baseData: PropTypes.shape({}).isRequired,
    isEditMode: PropTypes.bool.isRequired
};

export default WMICDriverPolicyHistoryComponent;
