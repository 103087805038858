/* eslint-disable consistent-return */
import React from 'react';
import _ from 'lodash';
import { JobUtil } from '@xengage/gw-portals-util-js';
import { ViewModelUtil } from '@xengage/gw-portals-viewmodel-js';
import {ERROR_STATUS_CODES, getGlobal} from 'wmic-pe-portals-utils-js';

import WMICErrorModal from '../../components/WMICErrorModal/WMICErrorModal';
import messages from './WMICErrorHandler.messages';

const PAYMENT_PLAN_CHANGE_ERROR_CODE = 777; // this number is defined in the BC back end

const displayErrors = true;

// replacer for JSON.stringify that does not expose view model nodes at all
const vmReplacer = (k, v) => {
    if(ViewModelUtil.isViewModelNode(v)) {
        return null;
    }
    return v;
}

function getMessageKey(error) {
    const errorStr = getFirstErrorIfArray(error);
    const errorCode = getErrorCode(errorStr);

    switch (errorCode) {
        case 502:
            return messages.timeoutKey;
        case 500:
        case 503:
        case 504:
            return messages.serverErrorKey;
        case ERROR_STATUS_CODES.WMIC_NEGATIVE_INVOICE_STREAM:
            return messages.negativeInvoiceStreamErrorKey;
        case 601: // DTOError
            let appData = "";

            // if the BE sends over a list, we need to interrogate it
            if (error && error.data) {
                _.each(error.data.appData, (ad) => {
                    appData += `${ad.path}: ${ad.message}\n`
                });
            }

            return appData;
        case -32603:
            if (errorStr.baseError.data.error.message && hasErrorMessageSubstring(errorStr.baseError.data.error.message, 'is not formatted correctly')) {
                return messages.dtoErrorKey;
            }

            return messages.technicalErrorKey;
        case -32500:
            if (errorStr.baseError.data.error.message &&
                hasErrorMessageSubstring(errorStr.baseError.data.error.message, 'postalcode -> value must be a valid postal code') &&
                hasErrorMessageSubstring(getErrorMethod(errorStr), 'retrievesubsummary')) {
                return messages.invalidSubmissionPostalCodeErrorKey;
            }

            if (errorStr.baseError.data.error.message && hasErrorMessageSubstring(errorStr.baseError.data.error.message, 'method requires an authenticated internal gw user')) {
                return messages.authenticationKey;
            }

            if (isPaymentPlanChangeError(errorStr)) {
                return handlePaymentPlanChangeError(errorStr);
            }

            if (errorStr.baseError.data.error.message && hasErrorMessageSubstring(errorStr.baseError.data.error.message, 'dto validation error')) {
                return messages.dtoErrorKey;
            }

            break;
        case 10000:
            return messages.lockedErrorKey;
        default:
            return messages.technicalErrorKey;
    }
}


function getFirstErrorIfArray(error) {
    if (Array.isArray(error)) {
        return error[0];
    }

    return error;
}

function getErrorCode(error) {
    if (error && error.baseError && error.baseError.status) {
        if (error.baseError.status === 500) {
            if (_.get(error, 'error.baseError.data.error.code')) {
                return error.baseError.data.error.code;
            }
        }

        return error.baseError.status;
    }

    if (error && error.data && error.data.appData) {
        return error.data.appErrorCode;
    }

    if (error && error.appErrorCode) {
        return error.appErrorCode;
    }

    return error;
}

function showAdditionalInfo(error) {
    return getErrorCode(error) !== ERROR_STATUS_CODES.WMIC_NEGATIVE_INVOICE_STREAM;
}

function isPaymentPlanChangeError(error) {
    return _.get(error, 'baseError.data.error.data.appErrorCode') === PAYMENT_PLAN_CHANGE_ERROR_CODE;
}

function handlePaymentPlanChangeError(error) {
    if (error.baseError.data.error.message && hasErrorMessageSubstring(error.baseError.data.error.message, 'bank record already exists with this information')) {
        return messages.paymentPlanExistsErrorKey;
    }

    return messages.paymentPlanErrorKey;
}

function getHtmlErrorIfExists(error) {
    try {
        const html = new DOMParser().parseFromString(error, 'text/html');

        if (Array.from(html.body.childNodes).some((node) => node.nodeType === 1)) {
            const htmlTitle = html.getElementsByTagName('title');

            if (htmlTitle[0]) {
                return htmlTitle[0].text;
            }

            return html;
        }

        return error;

    } catch (e) {
        return error;
    }
}

function getTidyError(error) {
    const errorStr = getFirstErrorIfArray(error);

    if (!errorStr) {
        return error;
    }

    if (typeof errorStr === 'string') {
        return errorStr;
    }

    if (_.get(errorStr, 'baseError.data.error.message')) {
        return errorStr.baseError.data.error.message;
    }

    if (errorStr.baseError && errorStr.baseError.data) {
        return getHtmlErrorIfExists(errorStr.baseError.data);
    }

    if (errorStr.baseError) {
        return errorStr.baseError;
    }

    if (errorStr.errorCode) {
        return errorStr.errorCode;
    }

    // Generic errors might have a stack trace, which also includes the error message
    if (errorStr.stack) {
        return errorStr.stack;
    }

    // vmReplacer ensures view models are not exposed (they are not "stringify-able" due to circular references)
    return JSON.stringify(error, vmReplacer);
}

function getErrorStatus(error) {
    if (error) {
        const errorStr = getFirstErrorIfArray(error);

        if (!errorStr) {
            return error;
        }

        return errorStr.baseError ? errorStr.baseError.status : '';
    }
}

function getErrorMethod(error) {
    if (error) {
        const errorStr = getFirstErrorIfArray(error);

        if (!errorStr) {
            return error;
        }

        if (errorStr.baseError) {
            return _.get(errorStr, "baseError.config.data.method");
        }
        if (errorStr.gwInfo) {
            return _.get(errorStr, "gwInfo.method");
        }
        return '';
    }
}

function getErrorUrl(error) {
    if (error) {
        const errorStr = getFirstErrorIfArray(error);

        if (!errorStr) {
            return error;
        }

        if (errorStr.baseError) {
            return _.get(errorStr, "baseError.config.url");
        }

        if (errorStr.gwInfo) {
            return _.get(errorStr, "gwInfo.serviceEndPoint");
        }

        return '';
    }
}


function isErrorEntityValidation(error) {
    if (error) {
        // vmReplacer ensures view models are not exposed (they are not "stringify-able" due to circular references)
        const errorStr = JSON.stringify(error, vmReplacer);

        if (!errorStr) {
            return error;
        }

        return errorStr && hasErrorMessageSubstring(errorStr, 'entityvalidationexception');
    }

    return false;
}

function hasErrorMessageSubstring(error, searchString) {
    if (error && searchString) {
        // vmReplacer ensures view models are not exposed (they are not "stringify-able" due to circular references)
        const errorStr = JSON.stringify(error, vmReplacer);

        if (!errorStr) {
            return error;
        }

        return errorStr.toLowerCase().includes(searchString);
    }

    return false;
}

function showErrorWithLink(
    message,
    tidyError,
    status,
    method,
    url,
    isEntityValidation,
    jobID,
    type,
    showAdditionalErrorInfo
) {
    const isPrebind = type === 'Prebind';
    const isJob = (type !== 'PolicySummary') && !isPrebind;
    const ref = isJob
        ? JobUtil.getJobDetailURLByJobType(type, jobID)
        : `policies/${jobID}/summary`;
    const params = {
        jobID,
        ref,
        tidyError,
        status,
        method,
        url,
        isEntityValidation,
        isPrebind
    };

    return getGlobal("showCustom")(
        <WMICErrorModal
            message={message}
            description={params}
            iconClass="gw-messageErrorIcon"
            showAdditionalErrorInfo={showAdditionalErrorInfo}
        />
    );
}

export default {
    processAsModal(error, jobID = '', type = '') {
        if (displayErrors) {
            const messageKey = getMessageKey(error);

            return showErrorWithLink(
                messageKey,
                getTidyError(error),
                getErrorStatus(error),
                getErrorMethod(error),
                getErrorUrl(error),
                isErrorEntityValidation(error),
                jobID,
                type,
                showAdditionalInfo(error)
            );
        }

        return undefined;
    },

    extractUWIssuesFromErrorMessage(errorMessage) {
        const error = getTidyError(errorMessage.baseError);

        return String(error).split('\n').filter((uwIssue) => uwIssue
            .match(/uwissue/i))
            .map((issue) => issue.split(':').pop().trim());
    },

    getErrorCode
}
