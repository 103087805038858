import React, { useContext } from 'react';
import { Flex } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './WMICModalHeader.module.scss';

const WMICModalHeader = (props) => {
    const translator = useContext(TranslatorContext);

    const { title, headerLayout, className } = props;
    return (
        <Flex className={cx(styles.modalHeader, className)} {...headerLayout}>
            <h1>{translator(title)}</h1>
        </Flex>
    );
};

WMICModalHeader.propTypes = {
    title: PropTypes.shape({}).isRequired,
    headerLayout: PropTypes.shape({})
};

WMICModalHeader.defaultProps = {
    headerLayout: {
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'middle'
    }
};

export default WMICModalHeader;