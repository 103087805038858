import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import _ from 'lodash';

import { TranslatorContext, IntlContext } from '@jutro/locale';
import { ViewModelForm, useDataRefresh, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { CONSTANTS } from 'wmic-pe-portals-utils-js';
import WMICAddPayoutComponent from './WMICAddPayout/WMICAddPayoutComponent';
import addPayoutMessages from './WMICAddPayout/WMICAddPayoutComponent.messages';
import messages from './WMICAddPriorLossComponent.messages';
import metadata from './WMICAddPriorLossComponent.metadata.json5';

function WMICAddPriorLossComponent(props){
    const {
        id,
        insuranceHistoryVM,
        selectedPriorLoss, 
        savePriorLoss,
        cancelPriorLoss,
        onValidate,
        carriersList,
        showErrors: pShowErrors
    } = props;

    const intlContext = useContext(IntlContext);
    const translator = useContext(TranslatorContext);
    const {onValidate: setComponentValidation, isComponentValid} = useValidation(id);
    const viewModelService = useContext(ViewModelServiceContext);

    const [currentPriorLoss, updateCurrentPriorLoss] = useState(selectedPriorLoss);
    const { refreshData } = useDataRefresh();
    const { showConfirm } = useWizardModals();
    const [showErrors, updateShowErrors] = useState(false);

    const [selectedPayout, updateSelectedPayout] = useState();
    const [addingPayout, setAddingPayout] = useState(false);
    const [editingIndex, updateEditingIndex] = useState(-1);

    useEffect(() => {
        updateCurrentPriorLoss(selectedPriorLoss);
    }, [insuranceHistoryVM, selectedPriorLoss])

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => onValidate ? onValidate(true, id) : undefined;
    }, [isComponentValid, onValidate, currentPriorLoss, id]);

    const updatePriorLoss = (data) => {
        refreshData();
        updateCurrentPriorLoss(data);
    };

    const handleSavePriorLoss = () => {
        if(isComponentValid) {
            savePriorLoss(currentPriorLoss)
        } else {
            updateShowErrors(true)
        }
    };

    const isManualEntryLoss = () => {
        const sourceType = _.get(currentPriorLoss, 'sourceType.value', undefined);
        return  sourceType === undefined ||
            sourceType.typelist.getFilter('MANUAL_SOURCES').allows(sourceType);
    };

    // PAYOUT
    const newPayout = () => {
        updateEditingIndex(-1); 

        const readOnlyPayout = viewModelService.create(
            {}, 
            'pc', 
            'wmic.edge.ca.capabilities.policyjob.lob.common.draft.dto.insurancehistory.PolicyClaimPayment_WMICDTO',
            { ...currentPriorLoss.aspects.context(), IsReadOnly: _.get(selectedPriorLoss, "value.isReadOnly", false) }
        );
        readOnlyPayout.value.claimOpenReserves = {amount: undefined, currency: 'cad'};
        readOnlyPayout.value.lossTotalPaid = {amount: undefined, currency: 'cad'};
        
        updateSelectedPayout(readOnlyPayout);
        setAddingPayout(true);
    };

    const savePayout = (data) => {
        const claimOpenReservesAmount = _.get(data, "value.claimOpenReserves.amount");
        const lossTotalPaidAmount = _.get(data, "value.lossTotalPaid.amount");
        if (_.isNil(claimOpenReservesAmount)) {
            _.unset(data, "value.claimOpenReserves");
        }
        if (_.isNil(lossTotalPaidAmount)) {
            _.unset(data, "value.lossTotalPaid");
        }
        if(editingIndex < 0) {
            if (!currentPriorLoss.payments.value) {
                _.set(currentPriorLoss, 'payments.value', []);
            }
            currentPriorLoss.payments.value.push(data.value);
        } else {
            currentPriorLoss.payments.setElement(editingIndex, data.value);
        }

        updatePriorLoss(currentPriorLoss);
        setAddingPayout(false);
    };

    const cancelPayout = () => {
        updateSelectedPayout({});
        setAddingPayout(false);
    };

    const editPayout = (payout, index) => {
        payout = viewModelService.changeContext(payout, currentPriorLoss.aspects.context());
        updateEditingIndex(index); 
        updateSelectedPayout(payout);
        setAddingPayout(true);
        updatePriorLoss(currentPriorLoss);
    };

    const removePayout = async (payoutData, payoutIndex) => {
        setAddingPayout(false);

        const response = await showConfirm({
            title: translator(messages.removePayout),
            message: translator(messages.removePayoutConfirmation)
        });

        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            const newPriorLoss = viewModelService.clone(currentPriorLoss);
            newPriorLoss.payments.value.splice(payoutIndex, 1);
            newPriorLoss.isUnderEditing = true;
            updateCurrentPriorLoss(newPriorLoss);
        }
    };


    const getTypeDisplayName = (data) => {
        const manualLossType = _.get(currentPriorLoss, 'manualLossType.value');
        if (manualLossType?.name) {
            return translator({id: manualLossType.name})
        }

        return data?.value?.typeDescription ?? ""
    }

    const getStatusDisplayName = (data) => {
        const status = _.get(currentPriorLoss, 'status.value');
        if (data.status.value) {
            return translator({
                id: `typekey.ClaimStatus_WMIC.${data.status.value.code}`,
            });
        } 
        if (status?.name) {
            return translator({
                id: status.name,
            })
        }
        return "";
    };

    const getClaimOpenReservesDisplayName = (data) => {
        return intlContext.formatNumber(
            _.get(data, 'claimOpenReserves.amount.value', 0),
            {
                style: 'currency',
                currency: _.get(data, 'claimOpenReserves.currency.value.code'),
                currencyDisplay: 'symbol'
            }
        );
    };

    const getLossTotalPaidDisplayName = (data) => {
        return intlContext.formatNumber(
            _.get(data, 'lossTotalPaid.amount.value', 0),
            {
                style: 'currency',
                currency: _.get(data, 'claimOpenReserves.currency.value.code'),
                currencyDisplay: 'symbol'
            }
        );
    };

    const resolvers = {
        resolveCallbackMap: {
            handleSavePriorLoss,
            cancelPriorLoss,
            newPayout
        },
        resolveComponentMap: {
            WMICAddPayoutComponent
        },
        resolveClassNameMap: {}
    };

    const overrideProps = {
        '@field': {
            parentNode: currentPriorLoss,
            readOnly: !currentPriorLoss.isUnderEditing
        },
        WMICAddPriorLossForm: {
            disableSave: addingPayout,
            onSave: currentPriorLoss.isUnderEditing && handleSavePriorLoss
        },
        payoutDataList: {
            VMList: _.get(currentPriorLoss, 'payments.children', []),
            VMData: [
                {
                    headerText: translator(addPayoutMessages.causeOfLoss),
                    getData: getTypeDisplayName
                },
                {
                    headerText: translator(addPayoutMessages.payoutStatus),
                    getData: getStatusDisplayName
                },
                {
                    headerText: translator(addPayoutMessages.claimOpenReserves),
                    path: 'claimOpenReserves.amount',
                    getData: getClaimOpenReservesDisplayName
                },
                {
                    headerText: translator(addPayoutMessages.lossTotalPaid),
                    path: 'lossTotalPaid.amount',
                    getData: getLossTotalPaidDisplayName
                }
            ],
            onEditAction: editPayout,
            onRemoveAction: removePayout,
            flatCards: true,
            clickable: !addingPayout && currentPriorLoss.isUnderEditing,
            selectedCardIndex: editingIndex,
            updateSelectedCardIndex: updateEditingIndex,
            isEditing: addingPayout,
            readOnly: !currentPriorLoss.isUnderEditing
        },
        manualLossType: {
            visible: isManualEntryLoss()
        },
        insuranceCompany: {
            availableValues: carriersList
        },
        addPayoutButton: {
            disabled: addingPayout
                || _.get(currentPriorLoss, 'status.value.code') === undefined
                || _.get(currentPriorLoss, 'manualLossType.value.code') === undefined,
            visible: currentPriorLoss.isUnderEditing
        },
        payoutForm: {
            visible: addingPayout,
            selectedPayout, 
            updateSelectedPayout,
            savePayout,
            insuranceHistoryVM,
            cancelPayout,
            currentPriorLoss
        }
    };


    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentPriorLoss}
            overrideProps={overrideProps}
            onModelChange={updatePriorLoss}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors || pShowErrors}
        />
    )
}

WMICAddPriorLossComponent.propTypes = {
    id: PropTypes.string.isRequired,
    selectedPriorLoss: PropTypes.shape({}).isRequired,
    savePriorLoss: PropTypes.func.isRequired,
    cancelPriorLoss: PropTypes.func.isRequired,
    updateSelectedPriorLoss: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired
};

export default WMICAddPriorLossComponent;