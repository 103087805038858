import React, { useContext, useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { WizardPage, WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import {
    QUOTE_STATUS
} from 'wmic-pe-portals-utils-js';
import messages from '../Wizard/common/WMICWizardMessages/WMICWizardMessages.messages';

const WMICQuotePageRegex = /(QuotePage)$/;
/*
 * Used for Policy Change and Renewal; allows jumping on any page of the wizard
 */

function WMICWizardChangeOrRenewalPage({ children, ...props }) {

    const { steps, currentStepIndex, jumpTo, isSkipping, wizardData: jobVM } = useContext(WizardContext);
    const [backToFirst, setBackToFirst] = useState(false);
    const translator = useContext(TranslatorContext);

    const numberOfSteps = steps?.length || 0;
    const isLastPage = numberOfSteps-1 === currentStepIndex;
    const isNextStepQuote = numberOfSteps - 2 === currentStepIndex || (!isLastPage && WMICQuotePageRegex.test(steps[currentStepIndex+1].id));
    const jobStatus = _.get(jobVM, 'baseData.periodStatus.value.code') || _.get(jobVM, 'status.value.code');

    useEffect(() => {
        // This logic is triggered when page loads for the first time, and if it's the very last page in the wizard
        // then it signals that we want to go back to the first page. This is only done once, when 'isSkipping' is true.
        // After going back to the first page, the wizard is no longer skipping, so this logic will not do anything.
        if ((isLastPage || (isNextStepQuote && jobStatus !== QUOTE_STATUS.QUOTED)) && isSkipping && !backToFirst) {
            setBackToFirst(true);
        }

        // Once we detect that we just 'skipped' to the last page for the first time, then we want to go back to the first page.
        if (backToFirst && !isSkipping) {
            jumpTo(0);
        }
    }, [backToFirst, isLastPage, isNextStepQuote, isSkipping, jobStatus, jumpTo]);

    const checkSkipStep = useCallback(() => {
        if (isNextStepQuote) {
            // if the next step is the quote page then it should only be available iff the job is in quoted status
            return jobStatus === QUOTE_STATUS.QUOTED;
        }

        return true;
    }, [isNextStepQuote, jobStatus]);

    return (
        <WizardPage
            // skipWhen must always be provided, so the page can skip properly. However, last page should not skip,
            // otherwise wizard will never stop skipping.
            skipWhen={isLastPage? undefined : checkSkipStep}
            previousLabel={translator(messages.back)}
            // Note that we still allow parent component to pass props down to the wizard page. These will take
            // precedence and override the defaults above.
            {...props}
        >
            {children}
        </WizardPage>
    )
}

export default WMICWizardChangeOrRenewalPage;
