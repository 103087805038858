import { defineMessages } from 'react-intl';

export default defineMessages({
    quoteChanged: {
        id: 'quoteandbind.common.components.premium-box.quoteChanged',
        defaultMessage: 'Your quote has changed. Click recalculate to see your new quote'
    },
    quoteChangedPart1: {
        id: 'quoteandbind.common.components.premium-box.quoteChangedPart1',
        defaultMessage: 'Your quote has changed.'
    },
    quoteChangedPart2: {
        id: 'quoteandbind.common.components.premium-box.quoteChangedPart2',
        defaultMessage: 'Click recalculate to see your new quote.'
    },
    scrollDown: {
        id: 'quoteandbind.common.components.premium-box.scrollDown',
        defaultMessage: 'Scroll down to purchase'
    },
    recalculate: {
        id: 'quoteandbind.common.components.premium-box.recalculate',
        defaultMessage: 'Recalculate'
    },
    monthlyPayment: {
        id: 'quoteandbind.common.components.premium-box.mothlyPayment',
        defaultMessage: 'Monthly payment'
    },
    fullPayment: {
        id: 'quoteandbind.common.components.premium-box.fullPayment',
        defaultMessage: 'or {amount} if paid in full'
    },
    recalculating: {
        id: 'quoteandbind.common.components.premium-box.recalculating',
        defaultMessage: 'Recalculating'
    },
    yourQuoteChanged: {
        id: 'integration.quoteandbind.directives.custom-quote.Your quote has changed.',
        defaultMessage: 'Your quote has changed.'
    },
    clickRecalculate: {
        id: 'integration.quoteandbind.directives.custom-quote.Click recalculate to see your new quote.',
        defaultMessage: 'Click recalculate to see your new quote.'
    },
    acceptMessage: {
        id: 'quoteandbind.common.controllers.QuoteDetails.In order to verify the information you have provided and to process your request',
        defaultMessage: 'In order to verify the information you have provided and to process your request, Wawanesa General Insurance Company ("Wawanesa") will need to run third party consumer reports. By checking the "I accept" box, you are providing your consent for Wawanesa to run such reports.'
    },
    agree: {
        id: 'quoteandbind.common.controllers.QuoteDetails.I agree',
        defaultMessage: 'I agree'
    },
    buyPolicy: {
        id: 'quoteandbind.common.directives.QuotePolicyBox.Buy Policy Label',
        defaultMessage: 'Buy Policy'
    },
    yourTotalPremium: {
        id: 'quoteandbind.views.payment-details.Your total premium is:',
        defaultMessage: 'Your total premium is: '
    }
});
