import React, { useState, useContext, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { Grid } from '@jutro/layout';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { WMICFormButtons, WMICScrollToError } from 'wmic-pe-components-platform-react';
import { useWizardModals } from "wmic-pe-portals-wizard-components-ui";
import { WMICLogger, MODAL_CONSTANTS, CONSTANTS } from 'wmic-pe-portals-utils-js';
import WMICDataList from '../WMICDataList/WMICDataList';
import WMICButton from '../WMICButton/WMICButton';
import WMICCard from '../WMICCard/WMICCard';

import messages from './WMICListView.messages';

const __EDIT_MODES = {
    add: 1,
    edit: 2,
    none: 0
};

function WMICListView(props) {
    const {
        id,
        value,
        VMData,
        startOpen,
        clickable,
        readOnly,
        addButtonLabel,
        renderAddButton,
        detailViewComponent : DetailViewComponent,
        detailViewComponentProps,
        onValidate : parentOnValidate,
        toCreate,
        toUndoCreate,
        toEdit,
        onSave,
        onSaveMiddleware,
        onDelete,
        showErrors: parentShowErrors,
        onClickAction,
        canDelete,
        canEdit,
        onCancel,
        showCancelModal = false,
        flatCards,
    } = props;

    const [selectedIndex, setSelectedIndex] = useState(startOpen ? 0 : -1); // index of the item currenty being viewed/edited; -1 closes detail view
    const [editMode, setEditMode] = useState(); // 'edit' or 'add' have meaning, anything else counts as "not editing"
    const [itemSnapshot, setItemSnapshot] = useState(); // Item snapshop, used to revert item to the previous state when cancelling the edit
    const [showErrors, setShowErrors] = useState(false); // true if we want to show errors
    const [scrollToError, setScrollToError] = useState(); // we toggle this when we want to scroll to the first error on the page

    const translator = useContext(TranslatorContext);
    const { wizardData, updateWizardData } = useContext(WizardContext);
    const { showConfirm } = useWizardModals();
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    const selectedItem = value[selectedIndex];
    const isAdding = editMode === __EDIT_MODES.add;
    const isEditing = editMode === __EDIT_MODES.add || editMode === __EDIT_MODES.edit;
    
    // This const isolates the items to show in the header
    const filteredVMList = useMemo(() => {
        if (isEditing && isAdding && value.length > 0) {
            // if we're adding, don't show the "adding" element
            return value.slice(0, value.length-1);
        }

        return value;
    }, [isAdding, isEditing, value]);

    const handleConfirmCancel = useCallback(() => {
        if (isAdding && toUndoCreate) {
            // If we were adding a new item, ask parent to undo that
            Promise.resolve(toUndoCreate()).then(() => {
                // either focus on first item, or close detail view if startOpen is false
                setSelectedIndex(startOpen ? 0 : -1);
            });
        } else if (isEditing) {
            // If we were editing an existing item, simply revert it to the saved snapshot
            value[selectedIndex].value = itemSnapshot;
        }

        if (onCancel) {
            // adding parameters which allows toUndoCreate and onCancel to merge into one function in the future 
            onCancel(isAdding, isEditing, selectedIndex, itemSnapshot);
        }
        
        setEditMode(__EDIT_MODES.none);
        updateWizardData(wizardData);
    }, [isAdding, isEditing, itemSnapshot, selectedIndex, startOpen, toUndoCreate, updateWizardData, value, wizardData, onCancel]);

    const onCancelEditAction = useCallback(() => {
        if (showCancelModal) {
            showConfirm({
                title: translator(messages.cancelEdit),
                message: translator(messages.cancelMessage),
                icon: MODAL_CONSTANTS.ICON.WARNING
            }).then((result) => {
                if (result === CONSTANTS.MODAL_RESULT.CONFIRM) {
                    handleConfirmCancel();
                }
            });
        } else {
            handleConfirmCancel();
        }
    }, [showCancelModal, handleConfirmCancel, showConfirm, translator]);

    useEffect(() => {
        if (parentOnValidate) {
            // we're in a 'valid' state if the component is not in editing mode
            parentOnValidate(!isEditing, id);
        }
    }, [parentOnValidate, id, isEditing]);

    // reset show errors to false whenever the component is back to a valid state or we stop editing
    useEffect(() => {
        if (isComponentValid || !isEditing) {
            setShowErrors(false);
        }
    }, [isComponentValid, isEditing]);

   
    // stop editing if our component suddenly becomes read only
    useEffect(() => {
        if(readOnly && isEditing) {
            onCancelEditAction();
        }
    }, [readOnly, isEditing, onCancelEditAction]);

    const onUpdateItem = useCallback((newVM) => {
        if (isEditing) {
            _.set(value, `${selectedIndex}`, newVM);
            updateWizardData(wizardData);
        }
    }, [isEditing, value, selectedIndex, updateWizardData, wizardData]);

    const onStartEditingAction = useCallback((data, index) => {
        if (toEdit) {
            Promise.resolve(toEdit(data, index)).then(() => {
                if (!readOnly) {
                    setEditMode(__EDIT_MODES.edit);
                    setItemSnapshot(_.cloneDeep(value[index].value)); // backup row's value
                    setSelectedIndex(index);
                }
            });
        } else if (!readOnly) {
            setEditMode(__EDIT_MODES.edit);
            setItemSnapshot(_.cloneDeep(value[index].value)); // backup row's value
            setSelectedIndex(index);
        }
    }, [readOnly, value, toEdit]);

    const onSaveAction = useCallback(() => {
        if (onSaveMiddleware) {
            onSaveMiddleware(selectedItem); // execute the middleware logic before the save logic
        }

        if (!isComponentValid) {
            setShowErrors(true);
            setScrollToError(Date.now()); // force scroll to first error

            return;
        }

        Promise.resolve(onSave(selectedItem, selectedIndex))
            .then((result) => {
                if (result) {
                    setShowErrors(false);

                    if (isAdding) {
                        // either focus on last added item, or close detail view if startOpen is false
                        setSelectedIndex(startOpen ? value.length : -1);
                    } else if (!startOpen) {
                        // close detail view if startOpen is false
                        setSelectedIndex(-1);
                    }
                    
                    setEditMode(__EDIT_MODES.none);
                }
            })
            .catch((error) => {
                WMICLogger.error('*** WMICListView onSave error', error);
            });
    }, [isComponentValid, isAdding, onSave, onSaveMiddleware, selectedItem, selectedIndex, startOpen, value.length]);

    const onCreateAction = useCallback((...args) => {
        if (toCreate) {
            Promise.resolve(toCreate(...args))
                .then((result) => {
                    if (result) {
                        setEditMode(__EDIT_MODES.add);
                        setSelectedIndex(value.length);
                    }
                })
        }
    }, [toCreate, value]);

    const onDeleteAction = useCallback((...args) => {
        if (onDelete) {
            // leaving the detail view open during deletion leads to undesired behaviour for the item that takes the index 0, so better close it before
            setSelectedIndex(-1);
            Promise.resolve(onDelete(...args))
                .then(() => {
                    // either focus on first item, or close detail view if startOpen is false
                    setSelectedIndex(startOpen ? 0 : -1);
                });
        }
    }, [onDelete, startOpen]);

    const usingCustomAddButton = !!renderAddButton;
    const supportsAddItem = !!toCreate;
    const supportEditItem = !!onSave;
    const supportsDeleteItem = !!onDelete;

    return (
        <Grid gap="none" id={`${id}_Grid`}>
            <WMICScrollToError counter={scrollToError}/>
            <WMICDataList
                id={`${id}_DataList`}
                VMList={filteredVMList}
                VMData={VMData}
                onEditAction={supportEditItem ? onStartEditingAction : undefined}
                onRemoveAction={supportsDeleteItem ? onDeleteAction : undefined}
                canDelete={canDelete}
                canEdit={canEdit}
                selectedCardIndex={selectedIndex}
                updateSelectedCardIndex={setSelectedIndex}
                isEditing={isEditing}
                clickable={clickable}
                readOnly={readOnly}
                onClickAction={onClickAction}
                flatCards= {flatCards}
            />
            { // we either render the default button here, or the custom button below
                !usingCustomAddButton && !readOnly && supportsAddItem && (
                    <div className="gw-mb-4">
                        <WMICButton
                            id={`${id}_CreateButton`} 
                            type="secondary"
                            onClick={onCreateAction}
                            visible={!readOnly}
                            disabled={isEditing}
                        >
                            {translator(addButtonLabel)}
                        </WMICButton>
                    </div>
                )}
            { // render custom button, if provided
                usingCustomAddButton && !readOnly && supportsAddItem && 
                    <div className="gw-mb-4">
                        {renderAddButton({ isEditing, onClick: onCreateAction })}
                    </div>
            }
            {   // Condition below hides the list view when the list view is not clickable AND we're not editing any items
                selectedItem && (isEditing || clickable) && (
                    <WMICCard id={id} flat={flatCards}>
                        <DetailViewComponent
                            {...detailViewComponentProps}
                            key={`${id}_DetailView${selectedIndex}_${isEditing}`}
                            id={`${id}_DetailView${selectedIndex}`}
                            selectedIndex={selectedIndex}
                            value={selectedItem}
                            updateModel={onUpdateItem}
                            isEditing={isEditing}
                            readOnly={readOnly}
                            showErrors={showErrors || parentShowErrors}
                            setShowErrors={setShowErrors}
                            onValidate={setComponentValidation}
                        />
                        <WMICFormButtons
                            id={id}
                            onCancel={onCancelEditAction}
                            onSave={onSaveAction}
                            isEditing={isEditing}
                            disableSave={false}
                            disableCancel={false}

                        />
                    </WMICCard>
                )}
        </Grid>
    )
}

WMICListView.propTypes = {
    id: PropTypes.string.isRequired,
    // The current list of items that will be displayed in the list and detail views. ** Must be an array of ViewModel nodes **
    // If rendering this component from metadata, use 'path' prop to automatically map the value.
    value: PropTypes.array,
    // List of data elements to be displayed, per item, in the list view 
    VMData: PropTypes.array.isRequired,
    // Controls whether the detail view starts open or closed (if false, also closes detail view after editing or adding an item)
    startOpen: PropTypes.bool,
    // Whether the list view is clickable or not
    clickable: PropTypes.bool,
    // Whether the view is read only (different than editing enabled)
    readOnly: PropTypes.bool,
    // Label for the generic "Add new item" button
    addButtonLabel: PropTypes.bool,
    /* For advanced options, you can provide the function to render your own "add new item" button
     * Props passed to your function:
     *    isEditing - true if an item is currently being edited or added
     *    onClick - the function you need to call when the user clicks the button
     */
    renderAddButton: PropTypes.func,
    /* Component that renders the detail view (note: if no item is selected, the detail view is not rendered at all).
     * Props passed to your component: 
     *      id
     *      selectedIndex       index of selected item in data list (-1 if an item is being added, subject to change in the future)
     *      value               data for the item being displayed
     *      updateModel         function that allows you to update the data
     *      isEditing           whether the view should be rendered in read only or editable mode
     *      readOnly            whether the component is editable or not
     *      showErrors          whether we want to show errors or not, when the view is in editing mode
     *      setShowErrors       allows your component to manually set whether to show errors, if you need to
     *      onValidate          validation hook - you can use as is, or use your own validation and call this when needed
     * 
     * Additionally, you can also provide custom props in 'detailViewComponentProps' (see below).
     * Custom props are passed to the detail view component as is.
     */
    detailViewComponent: PropTypes.func.isRequired,
    // You can provide custom props to be passed to the detailViewComponent here; any props are passed as is.
    detailViewComponentProps: PropTypes.object,
    onValidate: PropTypes.func,
    // Function that is called when the user wants to add a new item; this function should return the data (view model node) for the new item.
    // Note: if toCreate is not provided, then adding new items is disabled
    toCreate: PropTypes.func,
    // Function that is called when the user cancels the creation of a new item; it should ensure the new item is removed from the respective list.
    toUndoCreate: PropTypes.func,
    // By default, when editing or otherwise copying VM elements, we use the ViewModelService clone() function. If this causes issues (example: losing context),
    // you can provide your own cloning function to handle that special case. If none if provided, the default clone() is used.
    toClone: PropTypes.func,
    // Function that is called when the user wants to edit an existing item;
    toEdit: PropTypes.func,
    // Function called when the user wants to save an item
    // Note: if onSave is not provided, then editing items is disabled
    onSave: PropTypes.func,
    // Optional middleware function to be called right before the user to save an item
    // Note: this can be useful to trigger a modal or any other logic needed before the onSave logic is executed
    onSaveMiddleware: PropTypes.func,
    // Function called when the user wants to delete an existing item
    // Note: if onDelete is not provided, then deleting items is disabled
    onDelete: PropTypes.func,
    // Function called when user either cancels the addition of a new item or cancels the editing of an exisiting item.
    onCancel: PropTypes.func,
    // Set to true to display a warning modal when the user clicks cancel 
    showCancelModal: PropTypes.bool,
    // Set to true to force the detail view to show errors
    showErrors: PropTypes.bool,
    // Function called when the user wants to enable delete (by showing the delete icon)
    canDelete: PropTypes.func,
    // Function called when the user wants to enable edit (by showing the edit icon)
    canEdit: PropTypes.func,
    // Boolean indicating if the cards should be flat e.g. no shadow
    flatCards: PropTypes.bool,
};

WMICListView.defaultProps = {
    value: [],
    startOpen: true,
    clickable: true,
    readOnly: true,
    addButtonLabel: undefined,
    renderAddButton: undefined,
    detailViewComponentProps: {},
    onValidate: _.noop,
    toCreate: undefined,
    toUndoCreate: undefined,
    toClone: undefined,
    onSave: undefined,
    onSaveMiddleware: undefined,
    onDelete: undefined,
    onCancel: undefined,
    showCancelModal: false,
    showErrors: false,
    canDelete: undefined,
    canEdit: undefined,
    flatCards: false,
};

export default WMICListView;
