import React, { useState, useContext, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TranslatorContext, IntlContext } from '@jutro/locale';
import { ViewModelForm, useDataRefresh, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { WMICAddPayoutComponent } from 'wmic-pe-capability-gateway-quoteandbind-pa-react';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { WMICRPCUtil, CONSTANTS, PAConstants } from 'wmic-pe-portals-utils-js';

import messages from './WMICAddLossHistoryComponent.messages';
import metadata from './WMICAddLossHistoryComponent.metadata.json5';

function WMICAddLossHistoryComponent(props){
    const {
        id,
        insuranceHistoryVM,
        selectedPriorLoss, 
        savePriorLoss,
        cancelPriorLoss,
        onValidate,
        isEditMode,
        baseData,
        carriersList,
        vehicleOperators,
        vehiclesChoices,
        availableVehicles,
        isViewMode,
    } = props;
    const intlContext = useContext(IntlContext);
    const translator = useContext(TranslatorContext);
    const { showConfirm } = useWizardModals();
    const {onValidate: setComponentValidation, isComponentValid} = useValidation(id);
    const viewModelService = useContext(ViewModelServiceContext);
    const { refreshData } = useDataRefresh();

    const [currentPriorLoss, updateCurrentPriorLoss] = useState(selectedPriorLoss);
    const [showErrors, updateShowErrors] = useState(false);
    const [editingPayoutIndex, updateEditingPayoutIndex] = useState(-1);
    const [readOnlyPayout, updateReadOnlyPayout] = useState();
    const [addingPayout, setAddingPayout] = useState(false);
    const prevVehicleIDRef = useRef();

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [isComponentValid, onValidate, currentPriorLoss, id]);

    useEffect(() => {
        updateCurrentPriorLoss(selectedPriorLoss);
    }, [selectedPriorLoss]);

    const updatePriorLoss = (data) => {
        refreshData();
        updateCurrentPriorLoss(data);
    }

    const handleSavePriorLoss = () => {
        if(isComponentValid) {
            savePriorLoss(currentPriorLoss);
        } else {
            updateShowErrors(true);
        }
    };

    const addPayout = () => {
        const newPayout = viewModelService.create(
            {}, 
            'pc', 
            'wmic.edge.ca.capabilities.policyjob.lob.common.draft.dto.insurancehistory.PolicyClaimPayment_WMICDTO',
            { ...currentPriorLoss.aspects.context(), IsReadOnly: _.get(selectedPriorLoss, "value.isReadOnly", false) }
        );
        newPayout.value.claimOpenReserves = {amount: undefined, currency: 'cad'};
        newPayout.value.lossTotalPaid = {amount: undefined, currency: 'cad'};
        updateEditingPayoutIndex(-1);
        updateReadOnlyPayout(newPayout);
        setAddingPayout(true);
    };

    const savePayout = (payout) => {
        const claimOpenReservesAmount = _.get(payout, "value.claimOpenReserves.amount");
        const lossTotalPaidAmount = _.get(payout, "value.lossTotalPaid.amount");
        if (_.isNil(claimOpenReservesAmount)) {
            _.unset(payout, "value.claimOpenReserves");
        }
        if (_.isNil(lossTotalPaidAmount)) {
            _.unset(payout, "value.lossTotalPaid");
        }
        const payments = _.get(currentPriorLoss, 'payments.value', []);
        if (editingPayoutIndex === -1) {
            payments.push(payout.value);
        } else {
            payments.splice(editingPayoutIndex, 1, readOnlyPayout.value);
        }

        _.set(currentPriorLoss, 'payments.value', payments);
        setAddingPayout(false);
        updateReadOnlyPayout(undefined);
        updatePriorLoss(currentPriorLoss);
    };

    const cancelPayout = () => {
        setAddingPayout(false);
        updateReadOnlyPayout(undefined);
    };

    const editPayout = (payout, index) => {
        payout = viewModelService.changeContext(payout, currentPriorLoss.aspects.context());
        updateReadOnlyPayout(payout);
        updateEditingPayoutIndex(index);
        setAddingPayout(true);
    };

    const removePayout = async (payoutData, payoutIndex) => {
        setAddingPayout(false);

        const response = await showConfirm({
            title: messages.removePayoutTitle,
            message: translator(messages.removePayoutMsg),
        });

        if(response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            const newPriorLoss = viewModelService.clone(currentPriorLoss);
            newPriorLoss.payments.value.splice(payoutIndex, 1);
            newPriorLoss.isUnderEditing = true;
            updateCurrentPriorLoss(newPriorLoss);
        }
    };

    const isVehicleValid = _.get(currentPriorLoss, 'paPriorLoss').aspects.valid
        && _.get(currentPriorLoss, 'paPriorLoss').aspects.subtreeValid;

    const getTypeDisplayName = (data) => {
        if (data?.value?.publicID) {
            return data.value?.typeDescription ?? ""
        }

        if (data?.type?.value?.name) {
            return translator({id: data.type.value.name});
        }

        return "";
    }

    const getStatusDisplayName = (data) => {
        const priorLossStatus = _.get(currentPriorLoss, 'priorLoss.status.value');
        if (_.get(data, "status.value.code")) {
            return translator({
                id: `typekey.ClaimStatus_WMIC.${_.get(data, "status.value.code")}`,
            });
        } 
        if (priorLossStatus?.name) {
            return translator({
                id: priorLossStatus.name,
            })
        }
        return "";
    };

    const getMonetaryAmountDisplayName = (amountValue, currencyType) => intlContext.formatNumber(
        amountValue,
        {
            style: 'currency',
            currency: currencyType,
            currencyDisplay: 'symbol'
        }
    )

    const getClaimOpenReservesDisplayName = (data) => getMonetaryAmountDisplayName( 
        _.get(data, 'claimOpenReserves.amount.value', 0),
        _.get(data, 'claimOpenReserves.currency.value.code'))

    const getLossTotalPaidDisplayName = (data) => getMonetaryAmountDisplayName(
        _.get(data, 'lossTotalPaid.amount.value', 0),
        _.get(data, 'lossTotalPaid.currency.value.code')
    )
    
    useEffect(()=> {
        prevVehicleIDRef.current = _.get(currentPriorLoss, 'paPriorLoss.vehiclePublicID.value');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=> {
        const vehicleID = _.get(currentPriorLoss, 'paPriorLoss.vehiclePublicID.value');
        
        if (isExistingVehicleSelected){
            prevVehicleIDRef.current = _.get(currentPriorLoss, 'paPriorLoss.vehiclePublicID.value');
            const selectedVehicle = availableVehicles.find((vehicle) => vehicle.publicID === vehicleID);
            _.set(currentPriorLoss, 'paPriorLoss.vehicleBusinessSegment.value', selectedVehicle.businessSegment_WMIC);
            _.set(currentPriorLoss, 'paPriorLoss.vehicleType.value', selectedVehicle.vehicleType);
            _.set(currentPriorLoss, 'paPriorLoss.vehicleYear.value', selectedVehicle.year);
            _.set(currentPriorLoss, 'paPriorLoss.vehicleMake.value', selectedVehicle.make);
            _.set(currentPriorLoss, 'paPriorLoss.vehicleModel.value', selectedVehicle.model);
            _.set(currentPriorLoss, 'paPriorLoss.vehicleVIN.value', selectedVehicle.vin);
        } 
        
        if (!_.isUndefined(prevVehicleIDRef.current) && (prevVehicleIDRef.current !== vehicleID)) {
            prevVehicleIDRef.current = _.get(currentPriorLoss, 'paPriorLoss.vehiclePublicID.value');
            _.set(currentPriorLoss, 'paPriorLoss.vehicleBusinessSegment.value', undefined);
            _.set(currentPriorLoss, 'paPriorLoss.vehicleType.value', undefined);
            _.set(currentPriorLoss, 'paPriorLoss.vehicleYear.value', undefined);
            _.set(currentPriorLoss, 'paPriorLoss.vehicleMake.value', undefined);
            _.set(currentPriorLoss, 'paPriorLoss.vehicleModel.value', undefined);
            _.set(currentPriorLoss, 'paPriorLoss.vehicleVIN.value', undefined);
        }
        
        updatePriorLoss(currentPriorLoss);
    }, [_.get(currentPriorLoss, 'paPriorLoss.vehiclePublicID.value')]);

    const isExistingVehicleSelected = _.get(currentPriorLoss, 'paPriorLoss.vehiclePublicID.value') !== 'other' && _.get(currentPriorLoss, 'paPriorLoss.vehiclePublicID.value') !== undefined;

    const isRPCEffective = (rpcNumber) => WMICRPCUtil.getIsRPCEffective(_.get(baseData, 'baseState.value.code'), _.get(baseData, 'rateAsOfDate.value'), rpcNumber);

    const isVehicleReadOnly = _.get(currentPriorLoss, 'value.isReadOnly') || !currentPriorLoss.isUnderEditing || isExistingVehicleSelected

    const shouldSetAtFaultValuesForRPC1611 = () => {
        const baseState = _.get(baseData, 'baseState.value.code');
        const periodStartDate = _.get(baseData, 'periodStartDate.value');
        const manualLossType = _.get(currentPriorLoss, 'manualLossType.value.code');

        return manualLossType
            && periodStartDate
            && baseState
            && manualLossType === PAConstants.directCompensationManualLossTypeCode
            && WMICRPCUtil.getIsRPCEffective(baseState, periodStartDate, '1611');
    };

    let refreshAtFaultOptionsFlag = true;
    
    const atFaultOptions = useMemo(() => {
        let atFaultValues = _.get(currentPriorLoss, 'paPriorLoss.atFault.aspects.availableValues', []);

        if (!currentPriorLoss.value.isReadOnly 
            && currentPriorLoss.isUnderEditing
            && shouldSetAtFaultValuesForRPC1611()) {
            atFaultValues = viewModelService.productMetadata.get('pc').types.getTypelist('AtFaultIndicator_WMIC').getFilter('DCPDAtRiskOptions').codes;
        }

        const atFaultAvailableValues = atFaultValues.map((item) => ({
            code: item.code,
            name: translator({id: item.name})
        }))

        return atFaultAvailableValues;
    }, [refreshAtFaultOptionsFlag]);

    const onManualLossTypeChanged = (value, path) => {
        _.set(currentPriorLoss, path, value);
        refreshAtFaultOptionsFlag = !refreshAtFaultOptionsFlag;
        updatePriorLoss(currentPriorLoss);
    };

    const resolvers = {
        resolveCallbackMap: {
            handleSavePriorLoss,
            cancelPriorLoss,
            addPayout,
            onManualLossTypeChanged
        },
        resolveComponentMap: {
            WMICAddPayoutComponent
        },
    };

    const overrideProps = {
        '@field': {
            parentNode: currentPriorLoss,
            readOnly: currentPriorLoss.value.isReadOnly && !currentPriorLoss.isUnderEditing
        },
        payoutDataList: {
            VMList: _.get(currentPriorLoss, 'payments.children', []),
            VMData: [
                {
                    headerText: translator(messages.causeOfLoss),
                    getData: getTypeDisplayName
                },
                {
                    headerText: translator(messages.payoutStatus),
                    getData: getStatusDisplayName
                },
                {
                    headerText: translator(messages.claimOpenReserves),
                    getData: getClaimOpenReservesDisplayName
                },
                {
                    headerText: translator(messages.lossTotalPaid),
                    getData: getLossTotalPaidDisplayName
                }
            ],
            onEditAction: editPayout,
            onRemoveAction: removePayout,
            flatCards: true,
            clickable: !addingPayout && currentPriorLoss.isUnderEditing,
            selectedCardIndex: editingPayoutIndex,
            updateSelectedCardIndex: updateEditingPayoutIndex,
            isEditing: addingPayout,
            readOnly: !currentPriorLoss.isUnderEditing,
        },
        payoutForm: {
            visible: addingPayout,
            selectedPayout: readOnlyPayout, 
            updateSelectedPayout: updateReadOnlyPayout,
            savePayout,
            insuranceHistoryVM,
            cancelPayout,
            isEditMode: isEditMode && !currentPriorLoss?.value.isReadOnly && currentPriorLoss?.isUnderEditing
        },
        insuranceCompany: {
            availableValues: carriersList,
            controlType: "dropdown"
        },
        vehicleOperatorName: {
            availableValues: vehicleOperators,
            controlType: "dropdown"
        },
        atFault: {
            availableValues: atFaultOptions,
        },
        vehiclePublicID: {
            availableValues: vehiclesChoices,
            controlType: "dropdown"
        },
        WMICAddLossHistoryForm: {
            disableSave: addingPayout,
            onSave: isViewMode() && handleSavePriorLoss,
        },
        addPayoutButtonContainer: {
            visible: isEditMode
        },
        addPayoutButton: {
            disabled: addingPayout || !isVehicleValid,
            visible: currentPriorLoss.isUnderEditing
        },
        hitAndRun: {
            visible: isRPCEffective('1265')
        },
        vehicleBusinessSegment: {
            readOnly: isVehicleReadOnly
        },
        vehicleType: {
            readOnly: isVehicleReadOnly
        },
        vehicleYear: {
            readOnly: isVehicleReadOnly
        },
        vehicleMake: {
            readOnly: isVehicleReadOnly
        },
        vehicleModel: {
            readOnly: isVehicleReadOnly
        },
        vehicleVIN:{
            readOnly: isVehicleReadOnly
        }
    };


    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentPriorLoss}
            overrideProps={overrideProps}
            onModelChange={updatePriorLoss}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    )
}

WMICAddLossHistoryComponent.propTypes = {
    id: PropTypes.string.isRequired,
    insuranceHistoryVM: PropTypes.shape({}).isRequired,
    selectedPriorLoss: PropTypes.shape({}).isRequired,
    savePriorLoss: PropTypes.func.isRequired,
    cancelPriorLoss: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    baseData: PropTypes.shape({}).isRequired,
    isEditMode: PropTypes.bool.isRequired,
    carriersList: PropTypes.shape([]).isRequired,
    vehicleOperators: PropTypes.shape([]).isRequired,
    vehiclesChoices: PropTypes.shape([]).isRequired,
    isViewMode: PropTypes.bool.isRequired,
};

export default WMICAddLossHistoryComponent;