import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import _ from "lodash";
import { Position } from 'wmic-pe-portals-utils-js';
import WMICBuildingCoveragesTable from "../WMICBuildingCoveragesTable/WMICBuildingCoveragesTable"
import metadata from './WMICIterableBuildingComponent.metadata.json5';
import messages from'./WMICIterableBuildingComponent.messages';


function WMICIterableBuildingComponent(props) {
    const { building, buildingCoverages, showCoveragesContainer, isPolicyChangeOrRenewal, submissionVM } = props;

    const categorizeCoverages = () => {
        return {
            buldingCoverages: _.filter(buildingCoverages.value, {'selected': true}) || [],
            ...building.value
        };
    };


    const overrides = {
        '@field': {
            parentNode: building,
            readOnly: true,
            labelPosition: Position.TOP
        },
        buildingCoveragesTable: {
            visible: showCoveragesContainer,
            buildingCoverages: categorizeCoverages(),
            isPolicyChangeOrRenewal,
            submissionVM
        },
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            WMICBuildingCoveragesTable
        }
    };

    return (
        <ViewModelForm
            model={building}
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

WMICIterableBuildingComponent.propTypes = {
    value: PropTypes.shape({}).isRequired
};

export default WMICIterableBuildingComponent;
