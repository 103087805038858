import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Icon, ToastProvider } from '@jutro/components';
import PropTypes from 'prop-types';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import {
    TranslatorContext, withIntl
} from '@jutro/locale';
import { withValidation } from '@xengage/gw-portals-validation-react'
import { UserService, SubmissionService } from 'gw-capability-gateway';
import { WMICProgressModal, WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';
import cx from 'classnames';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { AvailabilityService } from 'wmic-pe-capability-policycommon';
import { AccountService } from 'wmic-pe-capability-gateway-policy';
import { WMICAccountDetails } from 'wmic-pe-capability-gateway-react';
import { WMICAccordionCard, WMICLink, WMICHeading, WMICIcon } from 'wmic-pe-components-platform-react';
import { ICON_NAMES, LOBConstants, CONSTANTS, WMICVariousUtil, MODAL_CONSTANTS, getGlobal, WMICRichTextUtil, WMICLogger, parseErrorMessage } from 'wmic-pe-portals-utils-js';
import { DatatableUtil, LocalDateUtil } from '@xengage/gw-portals-util-js'
import appConfig from 'app-config';
import accountMessages from 'gw-capability-gateway-react';
import { Flex } from '@jutro/layout';
import metadata from './WMICAccountSummary.metadata.json5';
import summaryStyles from './WMICAccountSummary.module.scss';
import commonMessages from '../WMICNewQuote/WMICNewQuoteAccountSearch.messages'
import messages from './WMICAccountSummary.messages';


class WMICAccountSummary extends Component {
    static propTypes = {
        authHeader: PropTypes.shape({}).isRequired,
        viewModelService: PropTypes.shape({
            create: PropTypes.func.isRequired,
            clone: PropTypes.func.isRequired,
        }).isRequired,
        fromAccountLanding: PropTypes.shape({
            accountDetailsData: PropTypes.shape({}).isRequired
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        intl: PropTypes.shape({
            formatDate: PropTypes.func
        }).isRequired
    };

    static contextType = TranslatorContext;

    state = {
        accountData: '',
        showEditLink: true,
        showNewQuoteButton: false,
        isEditMode: false,
        accountHolderVM: {},
        editModeVM: {},
        showErrors: false,
        accountContacts: [],
        displayContact: {},
        isLoading: false,
        availableProducerCodes: []
    };

    phoneTypes = {
        [CONSTANTS.PHONE_TYPE_PATHS.WORK]: CONSTANTS.PHONE_TYPES.WORK,
        [CONSTANTS.PHONE_TYPE_PATHS.HOME]: CONSTANTS.PHONE_TYPES.HOME,
        [CONSTANTS.PHONE_TYPE_PATHS.MOBILE]: CONSTANTS.PHONE_TYPES.MOBILE,
    };

    componentDidMount() {
        this.getAccountData();
        this.getShowNewQuoteButton();
        this.hasUserAccesstoEditAccounts();
    };

    createVM = (accountData) => {
        const { viewModelService } = this.props;
        const accountHolderVM = viewModelService.create(
            accountData.accountHolder,
            'pc',
            'wmic.edge.ca.capabilities.policycommon.accountcontact.dto.AccountContactDTO'
        );
        
        return accountHolderVM;
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    createAccountSummaryVM = (accountData) => {
        const { viewModelService } = this.props;
        const accountSummaryVM = viewModelService.create(
            accountData.accountHolder,
            'pc',
            'wmic.edge.ca.capabilities.gateway.account.dto.AccountSummaryDTO'
        );
        
        return accountSummaryVM;
    }

    getAccountData = async () => {
        const {
            authHeader,
            match: {
                params: { accountNumber }
            },
            viewModelService
        } = this.props;
        const accountDetailsResponse = await AccountService.getAccountDetails(accountNumber, authHeader);
        const accountContacts = await AccountService.getAccount(accountNumber, authHeader);
        const availableProducerCodes = await UserService.getAvailableProducerCodesForCurrentUser(authHeader);
        const accountHolderVM = this.createVM(accountDetailsResponse);
        const accountHolderContact = {
            code: accountDetailsResponse.publicID,
            name: accountDetailsResponse.accountHolder.displayName
        }
        let accountContactsValues = [accountHolderContact];

        accountContactsValues = accountContactsValues.concat(accountContacts.activeAccountContacts_WMIC.map((contact) => ({
                code: contact.publicID,
                name: contact.name,
            })));

        const vmClone = viewModelService.clone(accountHolderVM);

        this.setState({
            accountData: accountDetailsResponse,
            accountHolderVM,
            editModeVM: vmClone,
            accountContacts: accountContactsValues,
            displayContact: accountHolderContact,
            availableProducerCodes
        });
    }

    hasUserAccesstoEditAccounts = async () => {
        const { authHeader } = this.props;
        const permissionDTO = {
            permission: 'editaccountsummary'
        };
        const editUserPermission = await UserService.hasUserSystemPermission(permissionDTO, authHeader);

        this.setState({
            showEditLink: editUserPermission
        });
    };

    goToNewQuote = async (producerCode, lobCode) => {
        const { history, authHeader } = this.props;
        const { productCode } = history.location.state || lobCode;
        const { lobQuoteURL } = appConfig;
        const { accountData } = this.state;

        const jurisdictionAvailabilityRequest = {
            effectiveDate_WMIC: LocalDateUtil.today(),
            productCode,
            jurisdictionCode: accountData.baseStateCode_WMIC,
        }
        const availabilityResponse = await AvailabilityService.productAvailableBasedOnJurisdiction(jurisdictionAvailabilityRequest, authHeader);

        if (availabilityResponse.isAvailable) {
            const newSubmissionRequest = {
                accountNumber: accountData.accountNumber,
                productCode,
                producerCode,
                state: accountData.accountHolder.primaryAddress.state,
                country: accountData.accountHolder.primaryAddress.country,
                effectiveDate: LocalDateUtil.today(),
            };
            SubmissionService.createSubmission(newSubmissionRequest, authHeader)
                .then((newSubmissionResponse) => {
                    if (newSubmissionResponse.jobNumber > 0) {
                        const nextLocation = {
                            quoteentry: {
                                postalCode: _.get(
                                    accountData,
                                    'accountHolder.primaryAddress.postalCode'
                                ),
                                quoteID: newSubmissionResponse.jobNumber,
                            },
                        };
                        const url = lobQuoteURL[productCode];

                        return history.push(url, nextLocation);
                    }
                    return undefined
                }).catch((error) => {
                    WMICLogger.error('Error creating new submission', error);
                    WMICErrorHandler.processAsModal(parseErrorMessage(error));
                })
        } else {
            const translator = this.context;
            const showWarning = getGlobal('showWarning');
            // Insert space before capital letters
            const productCodeDisplayName = productCode.replace(/([A-Z])/g, ' $1').trim();
            
            return showWarning({
                title: messages.alerts,
                message: translator(messages.jurisdictionError, {productCode: productCodeDisplayName}),
                status: MODAL_CONSTANTS.STATUS.WARNING,
                icon: MODAL_CONSTANTS.ICON.ERROR,
                confirmButtonText: messages.ok,
            })
        }

        return null;
    }

    getShowNewQuoteButton = async () => {
        const { authHeader } = this.props;
        const permissionDTO = {
            permission: 'createsubmission'
        };
        const showNewQuoteButton = await UserService.hasUserSystemPermission(
            permissionDTO,
            authHeader
        );
        
        this.setState({ showNewQuoteButton });
    };

    getPolicyTable = () => {
        const { accountData } = this.state;
        const { intl } = this.props;
        const policyArray = accountData.policySummaries;

        return policyArray.map((policyDetails) => ({
                policyTerm: policyDetails.effectiveTermNumber,
                productCode: policyDetails.product.productCode,
                productName: policyDetails.product.productName,
                insuredName: policyDetails.primaryInsuredName,
                insuredAddress: policyDetails.accountHolder.primaryAddress.displayName,
                displayStatus: policyDetails.displayStatus,
                policyNumber: policyDetails.policyNumber,
                effectiveDate: intl.formatDate(new Date(policyDetails.effective), { year: 'numeric', month: 'long', day: 'numeric' }),
                expirationDate: intl.formatDate(new Date(policyDetails.expiration), { year: 'numeric', month: 'long', day: 'numeric' })
            }));
    };

    getCell = (item, index, { id: property }) => {
        const translator = this.context;
        const toolTipMessage = {
            displayStatus: translator(accountMessages.displayStatus),
            insuredName: translator(messages.insuredName),
            insuredAddress: translator(messages.insuredAddress),
            effectiveDate: translator(accountMessages.effectiveDate),
            expirationDate: translator(accountMessages.expirationDate)
        };
        
        return <span title={toolTipMessage[property]}>{item[property]}</span>;
    };

    getPolicyNoLink = (item, index, { id: property }) => {
        const translator = this.context;
        const toolTipMessage = translator(accountMessages.policyNumber);
        const icon = WMICVariousUtil.getLobIcon(item.productCode);

        return (
            <Flex alignItems="center">
                <WMICIcon
                    icon={icon}
                    title={item.productCode}
                    className={summaryStyles.productIcon}
                />
                <WMICLink
                    to={`/policies/${item[property]}/${item.policyTerm}/summary`}
                    className={cx(summaryStyles.remove_link_style)}
                    title={toolTipMessage}
                >
                    {item[property]}
                </WMICLink>
            </Flex>
        );
    };

    handleEditButtonClick = () => {
        const { isEditMode } = this.state;

        this.setState({ isEditMode: !isEditMode });
    };

    handleSaveChangesButtonClick = async () => {
        const { accountData, accountHolderVM } = this.state;
        const { authHeader, viewModelService } = this.props;
        // eslint-disable-next-line no-secrets/no-secrets
        const blacklist = ['displayValue', 'brokerName_WMIC', 'branchName_WMIC', 'brokerJurisdiction_WMIC'];
        const requestVM = viewModelService.clone(_.omit(accountHolderVM, blacklist));
        const translator = this.context;
        let accountHolderDetailsVM;
        let messageType = 'success';
        let message = translator({
            id: 'gateway.views.account-detail.Saved Message',
            defaultMessage: 'Your changes have been saved.'
        });

        try {
            const accountDetailsResponse = await AccountService.updateAccountDetails(
                { accountNumber: accountData.accountNumber, accountHolder: requestVM.value }, authHeader
            );

            accountHolderDetailsVM = this.createVM(accountDetailsResponse);
        }
        catch (err) {
            messageType = 'error';
            message = "";
        }

        ToastProvider.toast({
            type: messageType,
            message,
            autoClose: true
        });
        this.setState({ showErrors: false, isEditMode: false, accountHolderVM: accountHolderDetailsVM });
    };

    getPhoneType = (accountHolderVM) => accountHolderVM?.primaryPhoneType?.value?.code

    handleCancelChangesButtonClick = () => {
        const { editModeVM } = this.state;
        const { viewModelService } = this.props;
        const copyEditModeVM = viewModelService.clone(editModeVM);

        this.setState({ accountHolderVM: copyEditModeVM, isEditMode: false, showErrors: false });
    }

    handleValidation = () => {
        this.setState({ showErrors: false }, () => this.setState({ showErrors: true }));
    };

    handleChangeAccountContact = async (value) => {
        const { authHeader } = this.props;
        const { accountContacts, accountData } = this.state;
        const newDisplayContact = accountContacts.find((contact) => value === contact.code);

        this.setState({ isLoading: true });

        try {
            await AccountService.changeAccountHolderTo(accountData.accountNumber, value, authHeader);
            this.setState({ displayContact: newDisplayContact });
            await Promise.all([this.getAccountData(), this.getShowNewQuoteButton(), this.hasUserAccesstoEditAccounts()]);
            this.setState({ isLoading: false });
        }
        catch (err) {
            this.setState({ isLoading: false });
            this.showErrorModal(err);
        }
    }

    showErrorModal = () => {
        const translator = this.context;
        const modalArgs = {
            message: translator(messages.changeAccountHolderError),
            status: MODAL_CONSTANTS.STATUS.ERROR,
            title: 'Error',
            icon: MODAL_CONSTANTS.ICON.ERROR
        };

        const showConfirm = getGlobal('showConfirm')
        
        showConfirm({modalArgs});
    }

    getMenuItemsFromProducerCodes = () => {
        let usableProducerCodes;
        const { accountData, availableProducerCodes } = this.state;

        if (accountData.producerCodes) {
            usableProducerCodes = accountData.producerCodes.filter(
                (producer) =>
                    availableProducerCodes.findIndex((availableProducer) => producer.code === availableProducer.code) > -1
            );

            return usableProducerCodes.map(((producerCode) => ({
                    code: producerCode.code,
                    id: producerCode.code,
                    text: producerCode.displayValue,
                    icon: false
                })));
        }

        return [];
    }

    getSingleProducerButtonContent = (producerCode) => {
        const translator = this.context;
        const nextMessage = { "id": "gateway.views.new-quote.Next", "defaultMessage": "Next" };

        return `${translator(nextMessage)} (${producerCode})`;
    }

    handleMenuItemClick = (producerCode) => {
        this.goToNewQuote(producerCode);
    }

    handleNextProducerButton = () => {
        const { accountData } = this.state;
        const producerCode = accountData.producerCodes[0];
        
        this.goToNewQuote(producerCode.code);
    }

    handleSearchAgain = () => {
        const { history } = this.props;
        const { productCode } = history.location.state;
        const nextLocation = {
            productCode
        };

        history.push('/wmic-new-quote-account-search', nextLocation);
    };

    handleRowClick = (rowData) => {
        const { history } = this.props;

        history.push(`/policies/${rowData.policyNumber}/${rowData.policyTerm}/summary`);
    }

    handleCancel = () => {
        const { history } = this.props;
        const showConfirm = getGlobal('showConfirm')
        
        showConfirm({
            title: commonMessages.cancelSubmission,
            message: commonMessages.cancelMessage,
            status: MODAL_CONSTANTS.STATUS.WARNING,
            icon: MODAL_CONSTANTS.ICON.WARNING,
            cancelButtonText: commonMessages.no,
            confirmButtonText: commonMessages.yes
        }).then((results) => {
            if (results === CONSTANTS.MODAL_RESULT.CANCEL || results === CONSTANTS.MODAL_RESULT.CLOSE) {
                return _.noop();
            }
            
            return history.push('/');
        }, _.noop);
    };

    getTitleIcon = (productCode) => {
        const icon = WMICVariousUtil.getLobIcon(productCode)

        return <Icon
            icon={icon}
            id="lobIcon"
            size="large"
            className={cx("lobIcon", "gw-mr-2")}
        />
    }

    getTitle = () => {
        const { history } = this.props;
        const { productCode } = history.location.state || "";
        const translator = this.context;

        return (
            <div className={cx("wizardHeaderJobDetails", 'gw-mb-5')}>
                {this.getTitleIcon(productCode)}
                <WMICHeading variant="h2" title={translator(messages[`${productCode}Title`])} />
            </div>
        )
    }

    writeValue = (value, path) => {
        const { accountHolderVM } = this.state;

        _.set(accountHolderVM, path, value);
        this.setState({ accountHolderVM });
    }

    handlePhoneTypeChange = (value, path) => {
        const { accountHolderVM } = this.state;

        _.set(accountHolderVM, path, value);

        this.setState({ accountHolderVM });
    }

    render() {
        const translator = this.context;
        const { accountData, showEditLink, showNewQuoteButton, isEditMode,
            accountHolderVM, showErrors, accountContacts, displayContact, isLoading } = this.state;
        const { setComponentValidation, isComponentValid, history } = this.props;
        const contactTypeValue = _.get(accountData, 'accountHolder.subtype');

        if (_.isEmpty(accountData)) {
            return null;
        }

        if (isLoading) {
            return <WMICProgressModal
                modalTitle={translator({ id: "agent.views.modal.Loading", defaultMessage: "Loading..." })}
                isOpen
            />
        }

        const { productCode } = history.location.state || "";

        const policySummaryArray = this.getPolicyTable();
        // eslint-disable-next-line no-secrets/no-secrets
        const producerInfoList = ["displayValue", "brokerName_WMIC", "branchName_WMIC", "brokerJurisdiction_WMIC"];
        const producerInfo = _.pick(accountData.producerCodes[0], producerInfoList);

        const mergedAccountHolderVM = { ...accountHolderVM, ...producerInfo };

        const handleNewQuoteClick = ((lob) => {
            const producerCode = accountData.producerCodes[0].code;

            this.goToNewQuote(producerCode, {productCode: lob});
        });

        const handleBackButtonClick = () => {
            const redirectUrl = sessionStorage.getItem('backButtonRedirectUrl');

            if (redirectUrl === null) {
                window.history.back();
            } else if (redirectUrl) {
                history.push(redirectUrl);
            }

            sessionStorage.removeItem('backButtonRedirectUrl');
        };

        const overrides = {
            '@field': {
                labelPosition: 'left',
                showRequired: true,
                readOnly: !isEditMode,
                parentNode: accountHolderVM
            },
            policyTable: {
                data: policySummaryArray,
                onRowClick: this.handleRowClick
            },
            noDataMessage: {
                visible: _.isEmpty(policySummaryArray)
            },
            newQuoteButton: {
                visible: showNewQuoteButton
            },
            brokerCode: {
                readOnly: true,
                path: "displayValue"
            },
            brokerName: {
                readOnly: true
            },
            branch: {
                readOnly: true
            },
            region: {
                readOnly: true
            },
            companyInputs: {
                visible: contactTypeValue === CONSTANTS.Company
            },
            personInputs: {
                visible: contactTypeValue === CONSTANTS.Person
            },
            homePhone: {
                visible: contactTypeValue === CONSTANTS.Person 
            },
            phoneType: {
                value: this.getPhoneType(accountHolderVM),
                onValueChange: this.handlePhoneTypeChange
            },
            showEditButton: {
                visible: productCode !== LOBConstants.CP && showEditLink,
                disabled: isEditMode
            },
            accountSummaryHolderName: {
                availableValues: accountContacts,
                onValueChange: this.handleChangeAccountContact,
                value: displayContact
            },
            accountSummaryLOBHeader: {
                content: this.getTitle()
            },
            warningMessageContainer: {
                visible: isEditMode
            },
            applyChangesWarningMessage: {
                visible: isEditMode,
                message: WMICRichTextUtil.translateRichText(translator(messages.applyChangesWarningMessage))
            },
            rightSideEditDetailsButtons: {
                visible: isEditMode
            },
            rightSideNonEditDetailsButtons: {
                visible: !isEditMode
            },
            cancelBtn : {
                visible : !isEditMode
            },
            nextProducerButtonSingle: {
                onClick: this.handleNextProducerButton,
                visible: accountData.producerCodes.length === 1 && !_.isEmpty(productCode),
                content: this.getSingleProducerButtonContent(accountData.producerCodes[0].code)
            },
            nextProducerButtonMultiple: {
                onItemClick: this.handleMenuItemClick,
                items: this.getMenuItemsFromProducerCodes(),
                visible: accountData.producerCodes.length > 1 && !_.isEmpty(productCode)
            },
            newSubmissionButton: {
                visible: false,
                onItemClick: handleNewQuoteClick,
                items: [
                    { id: 'newSubmissionPA', text: translator(messages.autoMobileLink), icon: ICON_NAMES.ICON_CAR, code: LOBConstants.PA },
                    { id: 'newSubmissionPP', text: translator(messages.personalPropertyLink), icon: ICON_NAMES.ICON_BUILDING, code: LOBConstants.PP },
                    { id: 'newSubmissionPUP', text: translator(messages.personalUmbrellaLink), icon: ICON_NAMES.ICON_UMBRELLA, code: LOBConstants.PUP },
                ]
            },
        };

        const resolvers = {
            resolveCallbackMap: {
                getCell: this.getCell,
                getPolicyNoLink: this.getPolicyNoLink,
                handleEditButtonClick: this.handleEditButtonClick,
                onCancel: this.handleCancel,
                sortDate: DatatableUtil.sortDate,
                sortString: DatatableUtil.sortString,
                sortNumber: DatatableUtil.sortNumber,
                handleCancelChangesButtonClick: this.handleCancelChangesButtonClick,
                handleSaveChangesButtonClick: isComponentValid
                    ? this.handleSaveChangesButtonClick
                    : this.handleValidation,
                onSearch: this.handleSearchAgain,
                handleBackButtonClick
            },
            resolveComponentMap: {
                WMICAccordionCard,
                WMICAccountDetails
            },
            resolveClassNameMap: summaryStyles
        };

        overrides.accountSummaryDetails = {
            parentNode: mergedAccountHolderVM,
            pOnValueChange: this.writeValue,
            pOverrides: overrides,
            pResolvers: resolvers,
            setComponentValidation,
            showErrors,
            contactTypeValue,
            readOnly: !this.state.isEditMode
        }

        return (
            <div className={cx(summaryStyles.summary)}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={mergedAccountHolderVM}
                    onValueChange={this.writeValue}
                    overrideProps={overrides}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                    onValidationChange={setComponentValidation}
                    showErrors={showErrors}
                />
            </div>
        );
    }
}
export default withRouter(withIntl(withAuthenticationContext(withValidation(withViewModelService(WMICAccountSummary)))));
