import { defineMessages } from 'react-intl';

export default defineMessages({
    brokerFees: {
        id: 'wmic-pe-capability-gateway-common-react.BrokerFeesModal.Broker Fees',
        defaultMessage: 'Broker Fees'
    },
    cancelBtnLabel: {
        id: 'wmic-pe-capability-gateway-common-react.BrokerFeesModal.Cancel',
        defaultMessage: 'Cancel'
    },
    recalculateBtnLabel: {
        id: 'wmic-pe-capability-gateway-common-react.BrokerFeesModal.Recalculate',
        defaultMessage: 'Recalculate'
    },
    waveBrokerFee: {
        id: 'wmic-pe-capability-gateway-common-react.BrokerFeesModal.Waive broker fee',
        defaultMessage: 'Waive broker fee'
    },
    brokerSaving: {
        id: 'wmic-pe-capability-gateway-common-react.BrokerFeesModal.Saving Quote Submission Details',
        defaultMessage: 'Saving Quote Submission Details'
    }
});
