import { defineMessages } from 'react-intl';

export default defineMessages({
    previewPayments: {
        id: 'quoteandbind.views.payment-details.PreviewPayments',
        defaultMessage: 'Preview Payments'
    },
    sortDate: {
        id: 'quoteandbind.views.payment-details.SortDate',
        defaultMessage: 'Sort Date'
    },
    type: {
        id: 'wmic.quoteandbind.views.payment-details.Type',
        defaultMessage: 'Type'
    },
    amount: {
        id: 'quoteandbind.views.payment-details.Amount',
        defaultMessage: 'Amount'
    },
});