import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'

import metadata from './WMICHOHomeBasedBusinessDetailView.metadata.json5';


function WMICHOHomeBasedBusinessDetailView(props) {

    const {
        id,
        businessVM,
        saveBusiness,
        cancelBusiness,
        onValidate: parentOnValidate,
        showErrors: parentShowErrors
    } = props;


    const { onValidate, isComponentValid } = useValidation(id);
    const [showErrors, updateShowErrors] = useState(false);
    const [currentBusiness, updateCurrentBusiness] = useState(businessVM);

    useEffect(()=> {
        if (parentOnValidate) {
            parentOnValidate(isComponentValid, id)
        }
    }, [parentOnValidate, id, isComponentValid])

    const handleSaveBusiness = () => {
        if (isComponentValid) {
            saveBusiness(currentBusiness)
        } else {
            updateShowErrors(true)
        }
    };

    const updateBusiness = (data) => {
        updateCurrentBusiness(_.clone(data));
    };

    const resolvers = {
        resolveCallbackMap: {
            handleSaveBusiness,
            cancelBusiness
        }
    };

    const overrideProps = {
        '@field': {
            parentNode: businessVM
        },
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={businessVM}
            overrideProps={overrideProps}
            onModelChange={updateBusiness}
            callbackMap={resolvers.resolveCallbackMap}
            onValidationChange={onValidate}
            showErrors={showErrors || parentShowErrors}
        />
    )
}

WMICHOHomeBasedBusinessDetailView.propTypes = {
    id: PropTypes.string.isRequired,
    businessVM: PropTypes.shape({}).isRequired,
    saveBusiness: PropTypes.func.isRequired,
    cancelBusiness: PropTypes.func.isRequired
};

export default WMICHOHomeBasedBusinessDetailView;

