import { defineMessages } from 'react-intl';

export default defineMessages({
    lookupAddressLabel: {
        id: 'wmic-pe-components-platform-react.AddressDetails.Address lookup',
        defaultMessage: 'Address lookup'
    },
    lookupPlaceholder:{
        id: 'wmic-pe-components-platform-react.AddressDetails.Address Placeholder',
        defaultMessage: 'Start typing your address to search'
    },
    postalCode: {
        id: 'wmic-pe-components-platform-react.AddressDetails.ZIP Code',
        defaultMessage: 'Postal code'
    },
    province: {
        id: 'wmic-pe-components-platform-react.AddressDetails.Province',
        defaultMessage: 'Province'
    },
    overrideAddressValidation: {
        id: 'wmic-pe-components-platform-react.AddressDetails.Override address validation',
        defaultMessage: 'Override address validation?'
    },
    invalidAddressMessaging: {
        id: 'wmic-pe-components-platform-react.AddressDetails.The address entered may contain errors',
        defaultMessage: 'The address entered may contain errors. Please edit or override before proceeding.'
    },
    validateAddressMessaging: {
        id: 'wmic-pe-components-platform-react.AddressDetails.Please validate address to proceed',
        defaultMessage: 'Please validate address to proceed.'
    },
    validateButton: {
        id: 'wmic-pe-components-platform-react.AddressDetails.Validate Button',
        defaultMessage: 'Validate'
    },
    loading: {
        id: 'wmic-pe-components-platform-react.AddressDetails.Loading',
        defaultMessage: 'Loading...'
    }    
});