import React, { useCallback } from 'react';
import { ToggleField } from '@jutro/components';

const WMICInputToggle = ({ onValueChange, availableValues, ...rest }) => {
    const { value } = rest;

    const toggleOnValueChange = useCallback((newValue, ...args) => {
        const toggledNewValue = newValue !== value ? newValue : !newValue;
        onValueChange(toggledNewValue, ...args);
    }, [value, onValueChange]);

    return (
        <ToggleField
            onValueChange={toggleOnValueChange}
            {...rest}
        />
    );
};

WMICInputToggle.propTypes = {
    ...ToggleField.propTypes
};

export default WMICInputToggle;
