/* eslint-disable unicorn/filename-case */
/* eslint-disable consistent-return */
/* eslint-disable no-secrets/no-secrets */
 
import React, { useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { WMICDateTimeService } from 'wmic-pe-portals-utils-js';
import { TranslatorContext } from '@jutro/locale';
import '@xengage/gw-platform-translations';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import WMICHOPolicyLossHistoryDetailView from './WMICHOPolicyLossHistoryDetailView/WMICHOPolicyLossHistoryDetailView';

import messages from './WMICHOPolicyLossHistory.messages';
import metadata from './WMICHOPolicyLossHistory.metadata.json5';

function WMICHOPolicyLossHistory(props) {
    const {
        insuranceHistoryVM,
    } = props;

    const translator = useContext(TranslatorContext);

    const localDate2Date = (data) => WMICDateTimeService.toMidnightDate(data.lossDate.value);

    const overrideProps = {
        '@field': {
            parentNode: insuranceHistoryVM,
            readOnly: true
        },
        noPriorLossesContainer: {
            visible: _.get(insuranceHistoryVM, 'priorLosses.value', []).length === 0
        },
        insuranceLossHistoryDataList: {
            VMData: [
                {
                    headerText: translator(messages.policyNumber),
                    path: 'policyNum'
                },
                {
                    headerText: translator(messages.claimStatus),
                    path: 'status'
                },
                {
                    headerText: translator(messages.lossDate),
                    getData: localDate2Date
                },
            ],
            clickable: true,
            readOnly: true,
            startOpen: false,
            detailViewComponent: WMICHOPolicyLossHistoryDetailView,
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={insuranceHistoryVM}
            overrideProps={overrideProps}
        />
    );
}

WMICHOPolicyLossHistory.propTypes = {
    insuranceHistoryVM: PropTypes.shape({}),
    policyVM: PropTypes.shape({}),
};

export default WMICHOPolicyLossHistory;
