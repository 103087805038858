import { defineMessages } from 'react-intl';

export default defineMessages({
    additionalInterestsTitle: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-info.Additional Interests',
        defaultMessage: 'Additional Interests'
    },
    customizationTitle: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-info.Customization',
        defaultMessage: 'Customization'
    },
    priorDamageTitle: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-info.PriorDamage',
        defaultMessage: 'Prior Damage'
    },
    customizationTotal: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-info.CustomizationTotal',
        defaultMessage: 'Total value of Customizations'
    },
    addCustomizationButton: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-info.AddCustomization',
        defaultMessage: 'Add Customization'
    },
    priorUnrepairedDamageQuestion: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-info.PriorUnrepairedDamage',
        defaultMessage: 'Does this vehicle have prior unrepaired damage?'
    },
    salvageVehicleQuestion: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-info.IsTheVehicleASalvageVehicle?',
        defaultMessage: 'Is the vehicle a salvage vehicle (previous total loss)?'
    },
    windshieldDamageQuestion: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-info.DoesThisVehicleHaveAnyGlassDamage?',
        defaultMessage: 'Does this vehicle have any glass damage?'
    },
    windshieldDamageType: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-info.GlassDamageType',
        defaultMessage: 'Glass Damage Type'
    },
    windshieldDamageDescription: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-info.LocationAndSeverityOfGlassDamage',
        defaultMessage: 'Location and severity of glass damage'
    },
    windshieldDamageConfirmation: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-info.WillTheGlassDamageBeRepaired?',
        defaultMessage: 'Will the glass damage be repaired/replaced within 30 days?'
    },
    bodyDamageQuestion: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-info.DoesThisVehicleHaveAnyBodyDamage?',
        defaultMessage: 'Does this vehicle have any body damage?'
    },
    bodyDamageDescription: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-info.BodyDamageDescription',
        defaultMessage: 'Body Damage Description'
    },
    bodyDamageConfirmation: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-info.WillTheBodyDamageBeRepaired?',
        defaultMessage: 'Will the body damage be repaired/replaced within 30 days?'
    },
    hailDamageQuestion: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-info.DoesThisVehicleHaveAnyHailDamage?',
        defaultMessage: 'Does this vehicle have any hail damage?'
    },
    hailDamageDescription: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-info.HailDamageDescription',
        defaultMessage: 'Hail Damage Description'
    },
    vandalismDamageQuestion: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-info.DoesThisVehicleHaveAnyVandalismDamage?',
        defaultMessage: 'Does this vehicle have any vandalism damage?'
    },
    vandalismDamageDescription: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-info.VandalismDamageDescription',
        defaultMessage: 'Vandalism Damage Description'
    },
    otherDamageQuestion: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-info.DoesThisVehicleHaveAnyOtherDamage?',
        defaultMessage: 'Does this vehicle have any other damage?'
    },
    otherDamageDescription: {
        id: 'wmic-pe-capability-gateway-common-pa-react.additional-info.OtherDamageDescription',
        defaultMessage: 'Other Damage Description'
    },
});
