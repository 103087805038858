/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { CONSTANTS, Position, WMICZipMaskUtil } from 'wmic-pe-portals-utils-js';
import { WMICAddressDetails } from 'wmic-pe-components-platform-react';
import WMICAdlIntGoldSearch from 'wmic-pe-capability-gateway-quoteandbind-ho-react/components/WMICAdlIntGoldSearch/WMICAdlIntGoldSearch';
import WMICAdditionalInterestUtil from "wmic-pe-portals-utils-js/WMICAdditionalInterestUtil";
import metadata from './WMICHOCovAdditionalInterestDetailComponent.metadata.json5';
import './WMICHOCovAdditionalInterestDetailComponent.messages';
import styles from '../WMICHOCovAdditionalInterests.module.scss';

function WMICHOCovAdditionalInterestDetailComponent(props) {
    const {
        value: additionalInterestVM,
        updateModel,
        showErrors,
        onValidate,
        isEditing,
        hideFields
    } = props;

    const [isAddNewCompanyOpen, setAddNewCompanyOpen] = useState(false);
    const [isAICompanyVisible, setAICompanyVisible] = useState(false);
    const [isGoldSearchVisible, setGoldSearchVisible] = useState(false);
    const [isAddNewCompanyButtonVisible, setAddNewCompanyButtonVisible] = useState(false);
    const [isGoldListContactSelected, setIsGoldListContactSelected] = useState(false);

    const aiContactSubtype = _.get(additionalInterestVM, 'policyAdditionalInterest.subtype.value');

    useEffect(() => {
        setGoldSearchVisible(aiContactSubtype === CONSTANTS.Company && isEditing && _.isNil(_.get(additionalInterestVM, 'policyAdditionalInterest.contactName.value')));
    }, [aiContactSubtype])

    const handleAddNewCompanyClick = useCallback((value) => {
        setAICompanyVisible(true);
        setAddNewCompanyOpen(true);
        setGoldSearchVisible(false);
    }, []);

    const handleCancelAddCompanyClick = (value) => {
        setAICompanyVisible(false);
        setAddNewCompanyOpen(false);
        setGoldSearchVisible(true);
        setIsGoldListContactSelected(false);
        clearContactInfo();
    }

    const handleGoldContactSelected = (goldContactInfo) => {
        goldContactInfo.address.displayName = goldContactInfo.displayAddress;

        const newAdditionalInterest = _.clone(additionalInterestVM.value);

        _.set(newAdditionalInterest, 'policyAdditionalInterest.primaryAddress', goldContactInfo.address);
        _.set(newAdditionalInterest, 'policyAdditionalInterest.contactName', goldContactInfo.companyName);
        _.set(newAdditionalInterest, 'policyAdditionalInterest.displayName', goldContactInfo.companyName);
        _.set(newAdditionalInterest, 'policyAdditionalInterest.addressBookUID_WMIC', goldContactInfo.contactAddressBookUID)
        additionalInterestVM.value = newAdditionalInterest;
        updateModel(additionalInterestVM)

        setAICompanyVisible(true);
        setAddNewCompanyOpen(false);
        setGoldSearchVisible(false);
        setIsGoldListContactSelected(true);
    }

    const handleGoldListSearched = () => {
        setAddNewCompanyButtonVisible(true);
    }

    const clearContactInfo = () => {
        WMICAdditionalInterestUtil.clearAdditionalInterestContactInfo(additionalInterestVM);
        updateModel(additionalInterestVM);  
    }

    const displayReadOnlyAI = useMemo(() => !isEditing, [isEditing]);

    const swapZipPlaceHolder = useCallback(() => {
        const country = _.get(additionalInterestVM, 'policyAdditionalInterest.primaryAddress.country.value');
        const postalCode = _.get(additionalInterestVM, 'policyAdditionalInterest.primaryAddress.postalCode');
        return WMICZipMaskUtil.getZipMask(country, postalCode);
    }, [additionalInterestVM]);

    const isNewContact = useMemo(()=> additionalInterestVM.publicID.value === undefined, [additionalInterestVM])

    const overrideProps = {
        '@field': {
            labelPosition: Position.LEFT,
            parentNode: additionalInterestVM,
            readOnly: !isEditing
        },
        goldSearchContainer: {
            visible: isGoldSearchVisible
        },
        goldSearch: {
            additionalInterestVM,
            onGoldContactSelected: handleGoldContactSelected,
            onGoldListSearched: handleGoldListSearched  
        },
        newAIPersonContainer: {
            visible: aiContactSubtype === CONSTANTS.Person
        },
        newAICompanyContainer: {
            visible: ((aiContactSubtype === CONSTANTS.Company && !isGoldSearchVisible) || (displayReadOnlyAI && isAICompanyVisible)) && !!additionalInterestVM.additionalInterestType.value
        },
        addNewCompanyButton: {
            visible: isGoldSearchVisible && isEditing && isAddNewCompanyButtonVisible
        },
        cancelAddCompanyButton: {
            visible: isAddNewCompanyOpen || isGoldListContactSelected
        },
        additionalInterestType: {
            readOnly: true
        },
        descriptionOfInterestOption: {
            readOnly: true
        },
        descriptionOfInterestForAIType: {
            readOnly: true,
        },
        personAddress: {
            addressVM : _.get(additionalInterestVM, 'policyAdditionalInterest.primaryAddress'),
            onValidate,
            hideButtons : true,
            hideBorder : true,
            hideFields,
            readOnly: !isNewContact
        },
        contactAddress: {
            addressVM : _.get(additionalInterestVM, 'policyAdditionalInterest.primaryAddress'),
            onValidate,
            hideButtons : true,
            hideBorder : true,
            hideFields,
            readOnly: !isNewContact
        },
        companyAddress: {
            addressVM : _.get(additionalInterestVM, 'policyAdditionalInterest.primaryAddress'),
            onValidate,
            hideButtons : true,
            hideBorder : true,
            hideFields,
            readOnly: !isAddNewCompanyOpen || !isNewContact
        },
        companyName : {
            readOnly: !isAddNewCompanyOpen || !isNewContact
        },
        primaryPhoneType: {
            readOnly: !isNewContact
        },
        homePhone: {
            required: _.get(additionalInterestVM, 'policyAdditionalInterest.primaryPhoneType.value.code') === CONSTANTS.PHONE_TYPES.HOME,
            readOnly: !isNewContact
        },
        workPhone: {
            required: _.get(additionalInterestVM, 'policyAdditionalInterest.primaryPhoneType.value.code') === CONSTANTS.PHONE_TYPES.WORK,
            readOnly: !isNewContact
        },
        firstName: {
            readOnly: !isNewContact
        },
        lastName: {
            readOnly: !isNewContact
        },
        dateOfBirth: {
            readOnly: !isNewContact
        },
        emailAddress: {
            readOnly: !isNewContact
        },
        mobilePhone: {
            required: _.get(additionalInterestVM, 'policyAdditionalInterest.primaryPhoneType.value.code') === CONSTANTS.PHONE_TYPES.MOBILE,
            readOnly: !isNewContact
        },
        companyZip: {
            mask: swapZipPlaceHolder(),
            readOnly: !isNewContact
        },
        additionalCoveragesContainer: {
            visible: additionalInterestVM.additionalInterestType.value?.code === CONSTANTS.ADDITIONAL_INTEREST_TYPES.ADDITIONAL_INSURED && !isGoldSearchVisible
        },
        additionalInterestContainer: {
            visible: !isGoldSearchVisible
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICAdlIntGoldSearch,
            WMICAddressDetails
        },
        resolveCallbackMap: {
            onAddNewCompanyClick: handleAddNewCompanyClick,
            onCancelAddCompanyClick: handleCancelAddCompanyClick,
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            model={additionalInterestVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            onModelChange={updateModel}
            showErrors={showErrors}
            onValidationChange={onValidate}
        />
    );
}

WMICHOCovAdditionalInterestDetailComponent.propTypes = {
    value: PropTypes.shape({}).isRequired,
    updateModel: PropTypes.func,
    showErrors: PropTypes.bool,
    onValidate: PropTypes.func,
    isEditing: PropTypes.bool,
    hideFields: PropTypes.arrayOf(PropTypes.string)
};

WMICHOCovAdditionalInterestDetailComponent.defaultProps = {
    updateModel: undefined,
    showErrors: false,
    onValidate: undefined,
    isEditing: false,
    hideFields: []
};

export default WMICHOCovAdditionalInterestDetailComponent;
