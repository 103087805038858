import { defineMessages } from 'react-intl';

export default defineMessages({
    cancelEdit: {
        id: "wmic-pe-components-platform-react.WMICListView.Cancel Edit",
        defaultMessage: "Cancel Edit"
    },    
    cancelMessage: {
        id: "wmic-pe-components-platform-react.WMICListView.Cancel Message",
        defaultMessage: "All changes made since you last saved will be lost. Do you want to continue?"
    },   
});