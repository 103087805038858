import { defineMessages } from 'react-intl';

export default defineMessages({
    landingSearch: {
        id: 'account.agent.views.account-landing.Search',
        defaultMessage: 'Search'
    },
    clearButtonText: {
        id: 'account.agent.views.account-landing.Clear',
        defaultMessage: 'Clear'
    },
    placeholderForAccountNumber: {
        id: 'account.agent.views.account-landing.AccountNumber',
        defaultMessage: 'Account Number'
    },
    placeholderFirstName: {
        id: 'account.agent.views.account-landing.FirstName',
        defaultMessage: 'First Name'
    },
    placeholderLastName: {
        id: 'account.agent.views.account-landing.LastName',
        defaultMessage: 'Last Name'
    },
    placeholderCompanyName: {
        id: 'account.agent.views.account-landing.CompanyName',
        defaultMessage: 'Company Name'
    },
    errorModalHeader: {
        id: 'account.agent.views.modal.Error',
        defaultMessage: 'Error'
    },
    errorModalMessage: {
        id: 'account.agent.views.modal.Error.Data',
        defaultMessage: 'Error retrieving information, Please contact your Administrator'
    },
    nameHeader: {
        id: 'account.agent.views.account-landing.Client Name',
        defaultMessage: 'NAME'
    },
    addressHeader: {
        id: 'account.agent.views.account-landing.Client Address',
        defaultMessage: 'ADDRESS'
    },
    accountIdHeader: {
        id: 'account.agent.views.account-landing.Account ID',
        defaultMessage: 'ACCOUNT'
    },
    policiesHeader: {
        id: 'account.agent.views.account-landing.Policies',
        defaultMessage: 'POLICIES'
    },
    brokerHeader: {
        id: 'account.agent.views.account-landing.Broker',
        defaultMessage: 'BROKER'
    },
    numberOfResults: {
        id: 'account.agent.views.number-results',
        defaultMessage: 'Found  {resultsNum} result(s) matching {searched}'
    }
});
