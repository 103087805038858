import CONSTANTS from 'wmic-pe-portals-utils-js/StringConstants';

/* eslint-disable prefer-template */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
export default {
    containsAnyMotors: (watercraftComponents) => {
        return Array.isArray(watercraftComponents)
            && watercraftComponents.some((comp) => comp.subtype === CONSTANTS.COVERAGE_NAMES.HO_WATERCRAFTMOTOR_WMIC);
    }
};
