import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import metadata from './WMICAddKitchenBathComponent.metadata.json5';

function WMICAddKitchenBathComponent(props){
    const {
        id,
        value: kitchenBathVM,
        updateModel,
        onValidate,
        readOnly,
        isEditing,
        showErrors: pShowErrors
    } = props;

    const {onValidate: setComponentValidation, isComponentValid} = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => {
            if(onValidate){
                onValidate(true, id)
            }
        }
    }, [id, isComponentValid, onValidate]);

    const resolvers = {
        resolveCallbackMap: {},
        resolveComponentMap: {},
        resolveClassNameMap: {}
    };

    const overrideProps = {
        '@field': {
            parentNode: kitchenBathVM,
            readOnly: readOnly || !isEditing,
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={kitchenBathVM}
            overrideProps={overrideProps}
            onModelChange={updateModel}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            onValidationChange={setComponentValidation}
            showErrors={pShowErrors}
        />
    )
}

WMICAddKitchenBathComponent.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.shape({}).isRequired,
    onValidate: PropTypes.func.isRequired,
    updateModel: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    showErrors: PropTypes.bool.isRequired,
};

export default WMICAddKitchenBathComponent;
