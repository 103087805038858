import { useContext } from 'react';
import _ from 'lodash';
import { WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { CONSTANTS, WMICLogger } from 'wmic-pe-portals-utils-js';

export default function useDefaultedProperties(VMnode) {
    
    const { wizardData: jobVM } = useContext(WizardContext);

    const defaultedProperties = _.get(jobVM, 'defaultedProperties_WMIC.value');
    
    const getParentDTOclassInstanceID = (node) => {
        const parentNode = _.get(node, '_ancestorChain.parent');
        if (parentNode) {
            try {
                if (parentNode.accidentWaiverDriverEndorsementPublicID) {
                    return parentNode.accidentWaiverDriverEndorsementPublicID.value;
                }
                if (parentNode.personalInfoConsentFormPublicId_WMIC && propertyName === CONSTANTS.PERSONAL_INFO_CONSENT_FORM) {
                    return parentNode.personalInfoConsentFormPublicId_WMIC.value;
                }
                if (parentNode.publicID) {
                    return parentNode.publicID.value;
                } 
                if (parentNode.publicId) {
                    return parentNode.publicId.value;
                } 
                if (parentNode.policyPublicID_WMIC) {
                    return parentNode.policyPublicID_WMIC.value;    
                } 
                if (parentNode.exteriorWallId) {
                    return parentNode.exteriorWallId;
                }
                if (propertyName === CONSTANTS.LEAK_DETECTION_MONITORING_DEFAULTED_PROPERTY_NAME && parentNode.ratingJurisdiction) {
                    const grandParentNode = _.get(parentNode, '_ancestorChain.parent');
                    if (grandParentNode && grandParentNode.publicID) {
                        return grandParentNode.publicID.value;
                    }
                }
            } catch(ex) {
                WMICLogger.error(ex);
            }
        }
    }

    const propertyName = _.get(VMnode, '_accessorCode')?.toLowerCase();
    const className = _.get(VMnode, '_ancestorChain.parent._dtoName');
    const parentDTOclassInstanceID = getParentDTOclassInstanceID(VMnode);
    
    const defaultedPropertiesArrayIndex = defaultedProperties?.findIndex((elem) =>
        elem.propertyName?.toLowerCase() === propertyName
        && elem.className === className
        && elem.classPublicID === parentDTOclassInstanceID
    )

    const defaultedPropertyValues = (defaultedPropertiesArrayIndex !== undefined && defaultedPropertiesArrayIndex !== -1) ? defaultedProperties[defaultedPropertiesArrayIndex] : undefined;
        
    return ({
        isDefaulted: defaultedPropertyValues !== undefined,
        isAccepted: defaultedPropertyValues && defaultedPropertyValues.status === CONSTANTS.REVIEWED,
        isVisible: defaultedPropertyValues && defaultedPropertyValues.visible === true,
        defaultedPropertiesArrayIndex: defaultedPropertiesArrayIndex
    })
}