import { defineMessages } from 'react-intl';

export default defineMessages({
    completedPolicyTransactions: {
        id: 'agent.views.policy-detail.Completed Policy Transactions',
        defaultMessage: 'Completed Policy Transactions'
    },
    effectiveDate: {
        id: 'agent.directives.policy-transaction.Effective Date',
        defaultMessage: 'Effective Date'
    },
    transactionEffectiveDate: {
        id: 'agent.directives.policy-transaction.Transaction Effective Date',
        defaultMessage: 'Transaction effective date'
    },
    transactionNumber: {
        id: 'agent.directives.policy-transaction.Job Number',
        defaultMessage: 'Transaction #'
    },
    type: {
        id: 'agent.directives.policy-transaction.Type',
        defaultMessage: 'Type'
    },
    transactionStatus: {
        id: 'agent.directives.policy-transaction.Transaction Status',
        defaultMessage: 'Transaction status'
    },
    documents: {
        id: 'agent.directives.policy-transaction.Documents',
        defaultMessage: 'Documents'
    },
    description: {
        id: 'agent.directives.policy-transaction.Description',
        defaultMessage: 'Description'
    },
    pendingPolicyTransactions: {
        id: 'agent.views.policy-detail.Pending Policy Transactions',
        defaultMessage: 'Pending'
    },
    view: {
        id: 'agent.directives.policy-transaction.View',
        defaultMessage: 'View'
    },
    policyChangeDescription: {
        id: 'agent.views.modal.Policy Change Description',
        defaultMessage: 'Policy Change Description'
    },
    noCompletedTransactions: {
        id: 'agent.directives.policy-transaction.There are no completed policy transactions',
        defaultMessage: 'There are no completed policy transactions'
    },
    noPendingTransactions: {
        id: 'agent.directives.policy-transaction.There are no pending policy transactions',
        defaultMessage: 'There are no pending policy transactions'
    },
    loading: {
        id: 'agent.directives.policy-transaction.Loading',
        defaultMessage: 'Loading...'
    },
    documentPdf: {
        id: 'agent.directives.policy-transaction.Document.pdf',
        defaultMessage: 'Document.pdf'
    },
    idCardPdf: {
        id: 'agent.directives.policy-transaction.Id Card.pdf',
        defaultMessage: 'ID CARD.pdf'
    },
    pendingPolicyTransactionByUW: {
        id: 'agent.views.policy-detail.PendingPolicyTransaction_With_UW',
        defaultMessage: '* Represents a pending policy transaction by an Underwriter'
    }
});
