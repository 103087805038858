import { defineMessages } from 'react-intl';

export default defineMessages({
    noPriorLossesMsg: {
        id: 'wmic-pe-capability-policy-common-react.WMICHOPolicyLossHistory.noPriorLosses',
        defaultMessage: 'No losses have been added'
    },
    policyNumber: {
        id: 'wmic-pe-capability-policy-common-react.WMICHOPolicyLossHistory.policyNumber',
        defaultMessage: 'Policy #'
    },
    lossDate: {
        id: 'wmic-pe-capability-policy-common-react.WMICHOPolicyLossHistory.lossDate',
        defaultMessage: 'Date of Loss'
    },
    claimStatus: {
        id: "wmic-pe-capability-policy-common-react.WMICHOPolicyLossHistory.ClaimStatus",
        defaultMessage: "Claim Status"
    }
});