import { setComponentMapOverrides } from '@jutro/uiconfig';
import WMICPUPCoverages from './components/WMICPUPCoverages/WMICPUPCoverages';

export { default as WMICPUPLegalHistoryComponent } from './components/WMICHouseholdMemberDetails/WMICPUPLegalHistoryComponent/WMICPUPLegalHistoryComponent';
export { default as WMICHouseholdMemberDetailsComponent } from './components/WMICHouseholdMemberDetails/WMICHouseholdMemberDetailsComponent';
export { default as WMICPUPPrintableAgreement } from './components/WMICPUPPrintableAgreement/WMICPUPPrintableAgreement';
export { default as WMICPUPUnderlyingPoliciesComponent } from './components/WMICPUPUnderlyingPoliciesComponent/WMICPUPUnderlyingPoliciesComponent';

export { default as WMICHouseholdMemberUtil } from './util/WMICHouseholdMemberUtil';

setComponentMapOverrides(
    {
        WMICPUPCoverages: { component: 'WMICPUPCoverages' },
    },
    {
        WMICPUPCoverages,
    }
);

export { default as WMICPUPUnderlyingPoliciesComponentMessages } from './components/WMICPUPUnderlyingPoliciesComponent/WMICPUPUnderlyingPoliciesComponent.messages';
export { default as WMICPUPLocationExposuresMessages } from './components/WMICPUPLocationExposures/WMICPUPLocationExposures.messages';
export { default as WMICPUPVehicleExposuresMessages } from './components/WMICPUPVehicleExposures/WMICPUPVehicleExposures.messages';
export { default as WMICPUPWatercraftExposuresMessages } from './components/WMICPUPWatercraftExposures/WMICPUPWatercraftExposures.messages';
