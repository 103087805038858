import _ from 'lodash';
import { MockUpUtil, WMICApplicationUtil } from 'wmic-portals-utils-js';

// path in the address dto object where the google view model search entry is saved
const GOOGLE_MODEL_PATH = '__google_search_property__';
// path in the addrss dto object where the google seach is saved
const GOOGLE_MODEL_VALUE = `${GOOGLE_MODEL_PATH}.value`;
const GOOGLE_MODEL_ASPECTS = `${GOOGLE_MODEL_PATH}.aspects`;
const GOOGLE_MAP_VALUE = `${GOOGLE_MODEL_PATH}.mapValue`;
const DEFAULT_COUNTRY = 'US';
const DEFAULT_GW_COUNTRY = 'USA';

function getMockUpData(mockDataKey) {
    return mockDataKey ? MockUpUtil.getMockData(mockDataKey) : undefined;
}

function getAddressLine1WithoutMockData(addressLine1, mockDataKey, mockDataPath) {
    const MOCK_DATA = getMockUpData(mockDataKey);
    if (!MOCK_DATA) { return addressLine1; }
    if (addressLine1 === _.get(MOCK_DATA, `${mockDataPath}.addressLine1`)) { return undefined; }
    return addressLine1;
}

function getCityWithoutMockData(city, mockDataKey, mockDataPath) {
    const MOCK_DATA = getMockUpData(mockDataKey);
    if (!MOCK_DATA) { return city; }
    if (city === _.get(MOCK_DATA, `${mockDataPath}.city`)) { return undefined; }
    return city;
}

/**
 * google api returns a full address string.
 * split the full address into parts for each address field
 * @param {string} address from the google api
 * @returns {object} insurance suite address object
 */
function parseAddress(address) {
    const splitAddress = address.split(',');

    const addressLine1 = splitAddress[0].trim();
    const stateAndZip = splitAddress[2].split(' ');

    return {
        addressLine1,
        city: splitAddress[1].trim(),
        state: stateAndZip[1].trim(),
        zipCode: stateAndZip[2],
        country: WMICApplicationUtil.isPE() ? 'CA' : DEFAULT_COUNTRY
    };
}

/**
 * on the page return we need to display the address as if google returned it.
 * if we don't have a street address, return a blank
 * @param {object} addressVM view model object
 * @param {string} mockDataKey view model object
 * @param {string} mockDataPath view model object
 * @returns {string} formatted address for google maps
 */
function createGoogleAddressSearchValue(addressVM, mockDataKey, mockDataPath) {
    let ret = '';
    if (!addressVM || !addressVM.value) { return ret; }
    const addr = addressVM.value;
    const googleAddr = getAddressLine1WithoutMockData(addr.addressLine1, mockDataKey, mockDataPath);
    if (!googleAddr) { return ret; }
    const googleCity = getCityWithoutMockData(addr.city, mockDataKey, mockDataPath);
    const googleCountry = addr.country === DEFAULT_COUNTRY ? DEFAULT_GW_COUNTRY : addr.country;
    if (googleAddr) {
        ret += googleAddr;
    }
    if (googleCity) {
        if (ret.length > 0) { ret += ', '; }
        ret += googleCity;
    }
    if (addr.state) {
        if (ret.length > 0) { ret += ', '; }
        ret += addr.state;
    }
    if (googleCountry) {
        if (ret.length > 0) { ret += ', '; }
        ret += googleCountry;
    }
    return ret;
}

/**
 * the google map needs an object to move to the selected map location
 * @param {string} address1
 * @param {string} city
 * @param {string} state
 * @param {string} zipCode
 * @param {string} country
 * @param {string} mockDataKey
 * @param {string} mockDataPath
 * @returns {*} google address object
 */
function createGoogleObject(
    address1,
    city,
    state,
    zipCode,
    country,
    mockDataKey,
    mockDataPath
) {
    const googleAddr = getAddressLine1WithoutMockData(address1, mockDataKey, mockDataPath);
    const googleCity = getCityWithoutMockData(city, mockDataKey, mockDataPath);
    const ret = {};
    if (googleAddr) { ret.addressLine1 = googleAddr; }
    if (googleCity) { ret.city = googleCity; }
    if (state) { ret.state = state; }
    if (zipCode) { ret.postalCode = zipCode; }
    if (country) { ret.countryCode = (country === DEFAULT_COUNTRY ? DEFAULT_GW_COUNTRY : country); }
    return ret;
}

/**
 * create the google map address from the current policyAddress
 * assigns google map address object
 * @param {object} addressVM - address view model object
 * @param {string} mockDataKey - optional key to mock data
 * @param {string} mockDataPath - optional path to mock data
 * @returns {object} googleMapObject - object for input to the map
 */
function setGoogleApiSearchValues(addressVM, mockDataKey, mockDataPath) {
    if (!addressVM || !addressVM.value) { return undefined; }
    const addr = addressVM.value;
    const googleAddr = createGoogleObject(
        addr.addressLine1,
        addr.city,
        addr.state,
        addr.postalCode,
        addr.country,
        mockDataKey,
        mockDataPath
    );
    const googleMapObject = googleAddr.addressLine1 ? googleAddr : undefined;
    _.set(addressVM, GOOGLE_MAP_VALUE, googleMapObject);
    return googleMapObject;
}

function getAddressValidationMessages(
    addressVM,
    extendedValidationFunction,
    extendedValidationErrorMessage
) {
    const msgs = [];
    if (extendedValidationFunction && !extendedValidationFunction()) {
        if (extendedValidationErrorMessage) {
            if (!msgs.includes(extendedValidationErrorMessage.defaultMessage)) {
                msgs.push(extendedValidationErrorMessage.defaultMessage);
            }
        }
    }
    if (!addressVM || !addressVM.value) { return undefined; }
    if (!addressVM.addressLine1.aspects
        || !addressVM.city.aspects
        || !addressVM.state.aspects
        || !addressVM.postalCode.aspects) {
        return msgs;
    }
    if (!addressVM.addressLine1.aspects.valid) {
        if (!msgs.includes(addressVM.addressLine1.aspects.validationMessage)) {
            msgs.push(addressVM.addressLine1.aspects.validationMessage);
        }
    }
    if (!addressVM.city.aspects.valid) {
        if (!msgs.includes(addressVM.city.aspects.validationMessage)) {
            msgs.push(addressVM.city.aspects.validationMessage);
        }
    }
    if (!addressVM.state.aspects.valid) {
        if (!msgs.includes(addressVM.state.aspects.validationMessage)) {
            msgs.push(addressVM.state.aspects.validationMessage);
        }
    }
    if (!addressVM.postalCode.aspects.valid) {
        if (!msgs.includes(addressVM.postalCode.aspects.validationMessage)) {
            msgs.push(addressVM.postalCode.aspects.validationMessage);
        }
    }
    return msgs.length === 0 ? undefined : msgs;
}

function initializeAddressSearchViewModel(
    viewModel,
    path,
    extendedValidationFunction,
    extendedValidationErrorMessage,
    mockDataKey,
    mockDataPath
) {
    const addressSearchPath = `${path}.${GOOGLE_MODEL_PATH}`;
    if (!viewModel || !viewModel.value) { return addressSearchPath; }
    const addressVM = _.get(viewModel, path);
    if (!addressVM || !addressVM.value) { return addressSearchPath; }
    const addressSearchVM = _.get(addressVM, GOOGLE_MODEL_PATH);
    if (addressSearchVM) { return addressSearchPath; }

    const addressSearchProperty = {
        value: createGoogleAddressSearchValue(addressVM, mockDataKey, mockDataPath),
        aspects: {
            visible: true,
            configurable: true
        },
        _propertyName: GOOGLE_MODEL_PATH,
        _parent: addressVM,
        _ancestorChain: {
            owners: [],
            parent: addressVM
        },
        _metadataInfo: _.cloneDeep(_.get(addressVM, '_metadataInfo')),
        _accessorCode: _.cloneDeep(_.get(addressVM, '_accessorCode'))
    };
    _.set(addressVM, GOOGLE_MODEL_PATH, addressSearchProperty);

    Object.defineProperty(addressSearchProperty.aspects, 'required', {
        // eslint-disable-next-line func-names
        get: function () {
            return (
                addressVM.addressLine1.aspects.required
                || addressVM.city.aspects.required
                || addressVM.state.aspects.required
                || addressVM.postalCode.aspects.required
            );
        },
        configurable: true
    });
    Object.defineProperty(addressSearchProperty.aspects, 'valid', {
        // eslint-disable-next-line func-names
        get: function () {
            if (extendedValidationFunction && !extendedValidationFunction()) { return false; }
            if (!addressVM.addressLine1.aspects
                || !addressVM.city.aspects
                || !addressVM.state.aspects
                || !addressVM.postalCode.aspects) {
                return false;
            }
            return (
                addressVM.addressLine1.aspects.valid
                && addressVM.city.aspects.valid
                && addressVM.state.aspects.valid
                && addressVM.postalCode.aspects.valid
            );
        }
    });
    Object.defineProperty(addressSearchProperty.aspects, 'validationMessage', {
        // eslint-disable-next-line func-names
        get: function () {
            // eslint-disable-next-line max-len
            const msgs = getAddressValidationMessages(addressVM, extendedValidationFunction, extendedValidationErrorMessage);
            if (!msgs) { return msgs; }
            return msgs.join(', ');
        }
    });
    Object.defineProperty(addressSearchProperty.aspects, 'validationMessages', {
        // eslint-disable-next-line func-names
        get: function () {
            // eslint-disable-next-line max-len
            return getAddressValidationMessages(addressVM, extendedValidationFunction, extendedValidationErrorMessage);
        }
    });
    _.set(addressSearchProperty, 'aspects.subtreeValid', addressSearchProperty.aspects.valid);
    _.set(addressSearchProperty, '_aspects', addressSearchProperty.aspects);
    return addressSearchPath;
}

export {
    GOOGLE_MODEL_PATH,
    GOOGLE_MODEL_VALUE,
    GOOGLE_MODEL_ASPECTS,
    DEFAULT_COUNTRY,
    DEFAULT_GW_COUNTRY,
    getAddressLine1WithoutMockData,
    getCityWithoutMockData,
    parseAddress,
    createGoogleObject,
    setGoogleApiSearchValues,
    initializeAddressSearchViewModel,
    createGoogleAddressSearchValue
};
