import _ from 'lodash';

const getLapseTermNumber = (periods) => {
    const termNumber = _.maxBy(periods, 'termNumber_WMIC')?.termNumber_WMIC;
    // forcing to return first term in case periods object is undefined
    return termNumber || 1;
}
const getLapsePeriod = (periods, lapseTermNumber) => {
    if (lapseTermNumber) {
        return periods?.find((e) => e.termNumber_WMIC === lapseTermNumber);
    }
    return periods?.find((e) => e.termNumber_WMIC === getLapseTermNumber(periods));
} 

export default {
    getLapseTermNumber,
    getLapsePeriod
}