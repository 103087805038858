import { defineMessages } from 'react-intl';

export default defineMessages({
    submit: {
        id: 'agent.directives.PaymentPlanChange.Submit',
        defaultMessage: 'Submit'
    },     
    newPaymentPlan: {
        id: 'agent.directives.PaymentPlanChange.NewPaymentPlan',
        defaultMessage: 'New Payment Plan'
    },            
    pleaseSelectOne: {
        id: 'agent.directives.PaymentPlanChange.-- Please select one --',
        defaultMessage: 'Please select one'
    },    
    dueDate: {
        id: 'agent.directives.PaymentPlanChange.PaymentSchedule.Due Date',
        defaultMessage: 'Due Date'
    },      
    amount: {
        id: 'agent.directives.PaymentPlanChange.PaymentSchedule.Amount',
        defaultMessage: 'Amount'
    },    
    paidAmount: {
        id: 'agent.directives.PaymentPlanChange.PaymentSchedule.Paid Amount',
        defaultMessage: 'Paid Amount'
    },    
    paymentPlan: {
        id: 'agent.directives.PolicyBillingSummaryDirective.policy-billing-summary.Payment Plan',
        defaultMessage: 'Payment Plan'
    },    
    premium: {
        id: 'agent.directives.PolicyBillingSummaryDirective.policy-billing-summary.Premium',
        defaultMessage: 'Premium'
    },    
    totalCost: {
        id: 'agent.directives.PolicyBillingSummaryDirective.policy-billing-summary.Total Cost',
        defaultMessage: 'Total Cost'
    },    
    taxes: {
        id: 'agent.views.policy-detail.Taxes',
        defaultMessage: 'Taxes'
    },    
    fees: {
        id: 'agent.views.policy-detail.Fees',
        defaultMessage: 'Fees'
    },    
    remainingBalance: {
        id: 'agent.views.policy-billing.Remaining balance for the policy term',
        defaultMessage: 'Remaining balance for the policy term'
    },    
    excludingAnyUnbilled: {
        id: 'agent.views.policy-billing.(excluding any unbilled installment fees)',
        defaultMessage: '(excluding any unbilled installment fees)'
    },       
    balanceDue: {
        id: 'agent.views.policy-billing.Balance due',
        defaultMessage: 'Balance due'
    },    
    paymentSchedule: {
        id: 'agent.directives.PolicyBillingSummaryDirective.policy-billing-summary.Payment Schedule',
        defaultMessage: 'Payment Schedule'
    },    
    status: {
        id: 'agent.directives.PolicyBillingSummaryDirective.policy-billing-summary.Status',
        defaultMessage: 'Status'
    },    
    amountDue: {
        id: 'agent.directives.PolicyBillingSummaryDirective.policy-billing-summary.Amount Due',
        defaultMessage: 'Amount Due'
    },    
    bankingDetails: {
        id: 'agent.directives.PolicyBillingSummaryDirective.policy-billing-summary.Banking Details',
        defaultMessage: 'Banking Details'
    },    
    addNewPayer: {
        id: 'agent.views.policy-billing.Add new payer',
        defaultMessage: 'Add new payer'
    },    
    lastPayment: {
        id: 'billing.directives.templates.account-billing-summary.Last Payment',
        defaultMessage: 'Last Payment'
    },    
    makeAPayment: {
        id: 'agent.directives.PolicyBillingSummaryDirective.policy-billing-summary.Make a Payment',
        defaultMessage: 'Make a Payment'
    },    
    unpaid: {
        id: 'agent.views.policy-billing.invoice-statues.Unpaid',
        defaultMessage: 'Unpaid'
    },    
    partiallyPaid: {
        id: 'agent.views.policy-billing.invoice-statues.Partially Paid',
        defaultMessage: 'Partially Paid'
    },    
    fullyPaid: {
        id: 'agent.views.policy-billing.invoice-statues.Fully Paid',
        defaultMessage: 'Fully Paid'
    },
    scheduled: {
        id: 'agent.views.policy-billing.invoice-statues.Scheduled',
        defaultMessage: 'Scheduled'
    },    
    paid: {
        id: 'agent.views.policy-billing.invoice-statues.Paid',
        defaultMessage: 'Paid'
    },    
    brokerBillMessage: {
        id: 'agent.directives.PolicyBillingSummaryDirective.policy-billing-summary.No billing data to display for Broker Billed policies',
        defaultMessage: 'No billing data to display for Broker Billed policies'
    },    
    lastDocumentSent: {
        id: 'agent.views.policy-billing.Last Document Sent',
        defaultMessage: 'Last Document Sent'
    },
    billingDataNotAvailableYet: {
        id: 'agent.directives.PolicyBillingSummaryDirective.policy-billing-summary.Billing data is not available yet',
        defaultMessage: 'Billing data is not available yet'
    },
    bankingInformation: {
        id: 'agent.views.policy-billing.Banking Information',
        defaultMessage: 'Banking Information'
    },
    payer: {
        id: 'agent.views.policy-billing.payer',
        defaultMessage: 'Payer'
    },
    loading: {
        id: "agent.views.policy-billing.Loading",
        defaultMessage: "Loading..."
    },
    bankRecordAlreadyExists: {
        id: "agent.views.policy-billing.Bank record already exists with this information",
        defaultMessage: 'A contact with that bank record already exists. Please select the existing contact from the "Select Payer" dropdown menu.'
    },
    amountsIncludeApplicableFees: {
        id: "agent.directives.PolicyBillingSummaryDirective.policy-billing-summary.Amounts include applicable fees.",
        defaultMessage: "Amounts include applicable fees."
    }
});
