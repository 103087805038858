import React, { useContext } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import WMICPolicyPuCoverages from '../WMICPolicyPuCoverages/WMICPolicyPuCoverages';
import WMICPolicyPuLocationExposures from '../WMICPolicyPuLocationExposures/WMICPolicyPuLocationExposures';
import WMICPolicyPuVehicleExposures from '../WMICPolicyPuVehicleExposures/WMICPolicyPuVehicleExposures';
import WMICPolicyPuWatercraftExposures from '../WMICPolicyPuWatercraftExposures/WMICPolicyPuWatercraftExposures';
import { PUP_LOB_CODES } from 'wmic-pe-portals-utils-js';

import { WMICPUPUnderlyingPoliciesComponentMessages as messages } from 'wmic-pe-capability-gateway-common-pup-react';
import metadata from './WMICPolicyPuUnderlyingPoliciesComponent.metadata.json5';

function WMICPolicyPuUnderlyingPoliciesComponent(props) {
    const { value: underlyingPolicyVM } = props;

    const translator = useContext(TranslatorContext);

    const getUnderlyingPoliciesTitle = () => {
        const policyType = _.get(underlyingPolicyVM, 'policyType.value.code')
        switch (policyType) {
            case PUP_LOB_CODES.PA_CODE:
                return translator(messages.wmicUnderlyingPoliciesAutomobilePolicy);
            case PUP_LOB_CODES.HO_CODE:
                return translator(messages.wmicUnderlyingPoliciesHomeownersPolicy);
            case PUP_LOB_CODES.PWL_CODE:
                return translator(messages.wmicUnderlyingPoliciesWatercraftPolicy);
            default:
                break;
        }
    }

    const getPolicyNumberOrPlate = () => {
        const policyType = _.get(underlyingPolicyVM, 'policyType.value.code')
        switch (policyType) {
            case PUP_LOB_CODES.PA_CODE:
                return translator(messages.wmicUnderlyingPoliciesPolicyPlateNumber);
            default:
                return translator(messages.wmicUnderlyingPoliciesPolicyNumber);
        }
    }

    const isExposureVisible = (policyType) => {
        return policyType === _.get(underlyingPolicyVM, 'policyType.value.code', '');
    }

    const exposureProps = {
        exposuresVM: _.get(underlyingPolicyVM, 'exposures', {})
    }

    const overrideProps = {
        '@field':{
            parentNode: underlyingPolicyVM,
            readOnly: true
        },
        underlyingPoliciesTitle: {
            title: getUnderlyingPoliciesTitle()
        },
        policyNumberOrPlate: {
            label: getPolicyNumberOrPlate()
        },
        hasCoverageRestrictions: {
            visible: !isExposureVisible(PUP_LOB_CODES.PA_CODE)
        },
        isExcessPolicy: {
            visible: isExposureVisible(PUP_LOB_CODES.PA_CODE)
        },
        pupCoverages: {
            coverageVM: underlyingPolicyVM,
            coveragePath: 'coverages',
            readOnly: true
        },
        vehicleExposures: {
            ...exposureProps,
            visible: isExposureVisible(PUP_LOB_CODES.PA_CODE) && !_.get(underlyingPolicyVM, 'isExcessPolicy.value', false),
        },
        watercraftExposures: {
            ...exposureProps,
            visible: isExposureVisible(PUP_LOB_CODES.PWL_CODE)
        },
        locationExposures: {
            ...exposureProps,
            visible: isExposureVisible(PUP_LOB_CODES.HO_CODE)
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICPolicyPuCoverages,
            WMICPolicyPuLocationExposures,
            WMICPolicyPuVehicleExposures,
            WMICPolicyPuWatercraftExposures
        }
    };

    return <ViewModelForm
        uiProps={metadata.componentContent}
        model={underlyingPolicyVM}
        overrideProps={overrideProps}
        componentMap={resolvers.resolveComponentMap}
    />
}

export default WMICPolicyPuUnderlyingPoliciesComponent;