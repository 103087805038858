import React, { useContext} from 'react';
import _ from 'lodash';
import { WMICMathUtil, HOConstants } from 'wmic-pe-portals-utils-js';
import { TranslatorContext } from '@jutro/locale';
import  messages from './WMICHOCoverageUtil.messages';

function getPersonalPropertyCalcInsuredLimit(coverage) {
    let baseAmount = 0;
    let adjustmentAmount = 0;
    let calculated = 0;

    _.each(coverage.terms, (term) => {
        switch (term.patternCode) {
            case HOConstants.personalPropertyCoverageLimitPatternCode:
                baseAmount = parseFloat(term.directValue);
                break;
            case HOConstants.personalPropertyCoverageLimitAdjustmentPatternCode:
                adjustmentAmount = parseFloat(term.directValue);
                break;
            default:
                break;
        }
    });

    if (Number.isNaN(baseAmount)) {
        baseAmount = '';
    }
    if (Number.isNaN(adjustmentAmount)) {
        adjustmentAmount = '';
    }
    calculated = baseAmount + adjustmentAmount;
    if (!Number.isNaN(calculated)) {
        return WMICMathUtil.delimitNumbers(parseFloat(calculated).toFixed(3));
    }
    return null;
}

function getDwellingCoveragesForRisk(homeOwnersData, riskPublicID) {
    const dwellingCoverages = homeOwnersData.offerings.children[0].coverages?.dwellingCoverages?.children.find(
        (dwellingCoverage) => {
            return dwellingCoverage.value.publicID === riskPublicID;
        }
    );

    return dwellingCoverages || [];
}

function getWatercraftCoveragesForRisk(homeOwnersData, riskPublicID) {
    const watercraftCoverages = homeOwnersData.offerings.children[0].coverages.watercraftCoverages.children.find(
        (watercraftCoverage) => {
            return watercraftCoverage.value.publicID === riskPublicID;
        }
    );

    return watercraftCoverages || [];
}

function getAssociatedCoveragesForRisk(includedCoverages, riskPublicID) {
    return includedCoverages.find((includedCoverage) => includedCoverage.value.publicID === riskPublicID);
}

function getPackageCoverageTermValue(coverages, patternCode, basedOnBoolean = false) {
    const translator = useContext(TranslatorContext);
    const packageCoverage = coverages.find((coverage) => coverage.publicID === HOConstants.packageCoveragePublicID);

    if (packageCoverage) {
        const packageTerm = packageCoverage.terms.find((term)=> term.patternCode === patternCode);
        if (packageTerm) {
            if (basedOnBoolean) {
                const shouldDisplayYesLabel = packageTerm.chosenTermValue && (packageTerm.chosenTermValue === true || ['Yes', 'Oui'].includes(packageTerm.chosenTermValue));
                return shouldDisplayYesLabel? translator(messages.yes) : translator(messages.no);
            }
            return packageTerm.chosenTermValue;
        }
    }

    return basedOnBoolean ? translator(messages.no) : translator(messages.standard);
}

function getLineCoverages(homeOwnersData) {
    return homeOwnersData.offerings.children[0].coverages.lineCoverages.value.filter((coverage) => coverage.selected)
}

function getCoverageForRisk(coverages, publicID) {
    if (!_.isEmpty(coverages) && _.isArray(coverages.value)) {
        return coverages.value.find((coverage) => {
            return coverage.publicID === publicID;
        })
    }
    return undefined;
}

const getCoverageTotalLimit = (coverage) => {
    let totalLimit = 0;

    if (coverage && coverage.terms) {
        const limit = coverage.terms.find((term) => {
            return term.patternCode === HOConstants.dwellingCoverageLimitPatternCode;
        });
        const adjustmentToLimit = coverage.terms.find((term) => {
            return term.patternCode === HOConstants.dwellingCoverageLimitAdjustmentPatternCode;
        });

        if (limit && limit.directValue) {
            totalLimit += limit.directValue;
        }

        if (adjustmentToLimit && adjustmentToLimit.directValue) {
            totalLimit += adjustmentToLimit.directValue;
        }
    }

    return totalLimit;
};

function categorizeLineCoverages(lineCoverages, onlySelected = false) {
    const translator = useContext(TranslatorContext);
    const selectedFilter = onlySelected ? {'selected': true} : null;
    const optionalPropertyCoverages = _.filter(lineCoverages, {'category_WMIC': 'HODW_Optional_HOE', ...selectedFilter});
    const optionalLiabilityCoverages = _.filter(lineCoverages, {'category_WMIC': 'HOLI_Optional_HOE', ...selectedFilter});

    return {
        optionalPropertyCoverages: {
            categoryID:'HODW_Optional_HOE',
            categoryName: translator(messages.optionalPropertyCoverages),
            coverages: optionalPropertyCoverages
        },
        optionalLiabilityCoverages: {
            categoryID: 'HOLI_Optional_HOE',
            categoryName: translator(messages.optionalLiabilityCoverages),
            coverages: optionalLiabilityCoverages
        }
    }
}

function categorizeDwellingCoverages(dwellingCoverages, onlySelected = false) {
    const translator = useContext(TranslatorContext);
    const allCoverages = {
        includedCoverages: {
            categoryID: 'includedCoverages',
            categoryName: translator(messages.includedCoverages) ,
            coverages: []
        },
        optionalLiabilityCoverages: {
            categoryID: 'optionalLiabilityCoverages',
            categoryName: translator(messages.optionalLiabilityCoverages),
            coverages: []
        },
        optionalPropertyCoverages: {
            categoryID: 'optionalPropertyCoverages',
            categoryName: translator(messages.optionalPropertyCoverages),
            coverages: []
        },
        scheduledItems: {
            categoryID: 'scheduledItems',
            categoryName: translator(messages.scheduledItems),
            coverages: []
        },
        exclusions: {
            categoryID: 'exclusions',
            categoryName: translator(messages.exclusions),
            coverages: []
        },
        conditions: {
            categoryID: 'conditions',
            categoryName: translator(messages.conditions),
            coverages: []
        },
    };

    const filteredCoverages = onlySelected? _.filter(dwellingCoverages.coverages, {'selected': true}): dwellingCoverages.coverages;

    filteredCoverages.forEach((coverage) => {
        switch (coverage.category_WMIC) {
            case 'HODW_SectionI_HOE':
            case 'HODW_SectionI_HOE_DwellingCov':
            case 'HODW_SectionI_HOE_PostDwellingCov':
                allCoverages.includedCoverages.coverages.push(coverage);
                break;
            case 'HOLI_Optional_HOE':
                allCoverages.optionalLiabilityCoverages.coverages.push(coverage);
                break;
            case 'HODW_Optional_HOE':
                allCoverages.optionalPropertyCoverages.coverages.push(coverage);
                break;
            case 'HO_ConditionAsCoverage_WMIC':
                allCoverages.conditions.coverages.push(coverage);
                break;
            case 'HO_ExclusionAsCoverage_WMIC':
                allCoverages.exclusions.coverages.push(coverage);
                break;
            case 'HOPScheduledItemCovCat':
            case 'HODW_SchedulesCov_HOE':
                if (coverage.specialLimits) {
                    coverage.specialLimits.forEach((coverageItem) => {
                        allCoverages.scheduledItems.coverages.push(coverageItem);
                    });
                } else if (coverage.scheduledProperty) {
                    coverage.scheduledProperty.forEach((coverageItem) => {
                        allCoverages.scheduledItems.coverages.push(coverageItem);
                    });
                }
                break;
            default:
                break;
        }
    });

    return allCoverages;
}

function isDwellingSupportingCoverageDataValid(submissionVM){
    const dwellingVMs = _.get(submissionVM, 'lobData.homeowners.coverables.dwellings.children');
    const homeOwnersData = _.get(submissionVM, 'lobData.homeowners');

    return dwellingVMs.every((dwellingVM) => {
        const dwellingCoverages = getDwellingCoveragesForRisk(homeOwnersData, dwellingVM.publicID.value);
        const homeBasedBusinessCoverage = _.find(dwellingCoverages.coverages.value, (coverage) => coverage.publicID === 'HO_HomeBusinessCov');
        if (homeBasedBusinessCoverage?.selected) {
            return isHomeBasedBusinessValid(dwellingVM)
        }
        return true;
    })
}

function isHomeBasedBusinessValid(dwellingVM) {
    return _.get(dwellingVM, 'homeBasedBusinessCovItems.value', []).length > 0
}

export default {
    isDwellingSupportingCoverageDataValid: isDwellingSupportingCoverageDataValid,
    isHomeBasedBusinessValid: isHomeBasedBusinessValid,
    getCoverageTotalLimit: getCoverageTotalLimit,
    getCoverageForRisk: getCoverageForRisk,
    getDwellingCoveragesForRisk: getDwellingCoveragesForRisk,
    getPersonalPropertyCalcInsuredLimit: getPersonalPropertyCalcInsuredLimit,
    getPackageCoverageTermValue: getPackageCoverageTermValue,
    getLineCoverages: getLineCoverages,
    getAssociatedCoveragesForRisk,
    getWatercraftCoveragesForRisk,
    categorizeDwellingCoverages,
    categorizeLineCoverages
};
