import { defineMessages } from 'react-intl';

export default defineMessages({
    anErrorOccurred: {
        id: 'gw-portals-policytransaction-pc-pa.wizard.error',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'gw-portals-policytransaction-pc-pa.wizard.error.title',
        defaultMessage: 'Error'
    },
    saveQuoteError: {
        id: 'gw-portals-policytransaction-pc-pa.Unable to quote',
        defaultMessage: 'Unable to quote'
    },
    saveQuoteErrorMessage: {
        id: 'gw-portals-policytransaction-pc-pa.An error occurred while attempting to quote.',
        defaultMessage: 'An error occurred while attempting to quote.'
    },
    savingTransactionDetails: {
        id: 'wmic-pe-capability-gateway-policychange-pa-react.WMICPEPAPolicyChangeWizard.Saving Transaction Details',
        defaultMessage: 'Saving Transaction Details'
    },
    transactionDetailsTitle: {
        id:'wmic-pe-capability-gateway-policychange-pa-react.wizard-config.Transaction Details',
        defaultMessage: 'Transaction Details'
    },
    policyDetailsTitle: {
        id:'wmic-pe-capability-gateway-policychange-pa-react.wizard-config.Policy Details',
        defaultMessage: 'Policy Details'
    },
    driversTitle: {
        id:'wmic-pe-capability-gateway-policychange-pa-react.wizard-config.Drivers',
        defaultMessage: 'Drivers'
    },
    vehiclesTitle: {
        id:'wmic-pe-capability-gateway-policychange-pa-react.wizard-config.Vehicles',
        defaultMessage: 'Vehicles'
    },
    coveragesTitle: {
        id:'wmic-pe-capability-gateway-policychange-pa-react.wizard-config.Coverages',
        defaultMessage: 'Coverages'
    },
    insuranceHistoryTitle: {
        id:'wmic-pe-capability-gateway-policychange-pa-react.wizard-config.Insurance History',
        defaultMessage: 'Insurance History'
    },
    contactDetailsTitle: {
        id:'wmic-pe-capability-gateway-policychange-pa-react.wizard-config.Contact Details',
        defaultMessage: 'Contact Details'
    },
    changeSummaryTitle: {
        id:'wmic-pe-capability-gateway-policychange-pa-react.wizard-config.Change Summary',
        defaultMessage: 'Change Summary'
    },
    quoteTitle: {
        id:'wmic-pe-capability-gateway-policychange-pa-react.wizard-config.Quote',
        defaultMessage: 'Quote'
    },
    paymentTitle: {
        id:'wmic-pe-capability-gateway-policychange-pa-react.wizard-config.Payment',
        defaultMessage: 'Payment'
    },
    progress: {
        id:'wmic-pe-capability-gateway-policychange-pa-react.wizard-config.Progress',
        defaultMessage: 'Progress'
    }
});
