import { defineMessages } from 'react-intl';

export default defineMessages({
    industryCode: {
        id: 'policy.cp.views.operations.Industry code',
        defaultMessage: 'Industry code'
    },
    classification: {
        id: 'policy.cp.views.operations.Industry Code Classification',
        defaultMessage: 'Classification'
    },
    basisType: {
        id: 'policy.cp.views.operations.Basis type',
        defaultMessage: 'Basis type'
    },
    basis: {
        id: 'policy.cp.views.operations.Basis',
        defaultMessage: 'Basis'
    },
});
