import React, { useContext } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import { WMICPUPLocationExposuresMessages as messages } from 'wmic-pe-capability-gateway-common-pup-react';
import WMICPolicyPuLocationExposureForm from './WMICPolicyPuLocationExposureForm/WMICPolicyPuLocationExposureForm';

import metadata from './WMICPolicyPuLocationExposures.metadata.json5';

function WMICPolicyPuLocationExposures(props) {
    const { exposuresVM } = props;

    const translator = useContext(TranslatorContext);

    const overrideProps =  {
        locationExposuresListView: {
            value: _.get(exposuresVM, 'children', []),
            VMData: [
                {
                    headerText: translator(messages.wmicUnderlyingPoliciesAddress),
                    path: 'locationExposure.addressDisplayName'
                },
                {
                    headerText: translator(messages.wmicUnderlyingPoliciesDwellingUse),
                    getData: (exposure) => translator({id: exposure.value.locationExposure.dwellingUsageDisplay}) 
                }
            ],
            readOnly: true,
            detailViewComponent: WMICPolicyPuLocationExposureForm
        }
    }

    return <ViewModelForm
        uiProps={metadata.componentContent}
        model={exposuresVM}
        overrideProps={overrideProps}
    />
}

export default WMICPolicyPuLocationExposures;