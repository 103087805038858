import { defineMessages } from 'react-intl';

export default defineMessages({
    followingQuotes: {
        id: 'pe-quoteandbind.common.views.quote-details.Underwriting issues have been raised for the following quotes:',
        defaultMessage: 'Underwriting issues have been raised for the following quotes:'
    },
    weCannotShow: {
        id: 'pe-quoteandbind.common.views.quote-details.We cannot show a quote because it has underwriting issues',
        defaultMessage: 'We cannot show a quote because it has underwriting issues'
    },
    youCanDoTheFollowing: {
        id: 'pe-quoteandbind.common.views.quote-details.You can do the following:',
        defaultMessage: 'You can do the following:'
    },
    editTheQuote: {
        id: 'pe-quoteandbind.common.views.quote-details.Edit the quote if the customer is willing to accept the change',
        defaultMessage: 'Edit the quote if the customer is willing to accept the change'
    },
    approveTheIssue: {
        id: 'pe-quoteandbind.common.views.quote-details.Approve the issue(s)',
        defaultMessage: 'Approve the issue(s)'
    },
    referTheQuote: {
        id: 'pe-quoteandbind.common.views.quote-details.Refer the quote to the underwriter',
        defaultMessage: 'Refer the quote to the underwriter'
    },
    withdrawQuote: {
        id: 'pe-quoteandbind.common.views.quote-details.Withdraw the quote',
        defaultMessage: 'Withdraw the quote'
    },
    approve: {
        id: 'pe-quoteandbind.common.views.quote-details.underwriting-issue.action.Approve',
        defaultMessage: 'Approve'
    },
    blocksBind: {
        id: 'pe-quoteandbind.directives.MultiQuoteView.Blocks bind',
        defaultMessage: 'Blocks bind'
    },
    blocksQuote: {
        id: 'pe-quoteandbind.directives.MultiQuoteView.Blocks quote',
        defaultMessage: 'Blocks quote'
    },
    blocksQuoteRelease: {
        id: 'pe-quoteandbind.directives.MultiQuoteView.Blocks quote release',
        defaultMessage: 'Blocks quote release'
    },
    nonBlocking: {
        id: 'pe-quoteandbind.directives.MultiQuoteView.Non-blocking',
        defaultMessage: 'Non-blocking'
    },
    uwIssues: {
        id: 'wmic.pe-quoteandbind.directives.MultiQuoteView.Underwriting Issues',
        defaultMessage: 'Underwriting Issues'
    },
    placeholder: {
        id: "wmic.pe-quoteandbind.directives.MultiQuoteView.Notes written here will be seen by the underwriter",
        defaultMessage: "Notes written here will be seen by the underwriter..."
    },
    noOutstandingIssues: {
        id: "wmic.gateway.views.quote-underwritingIssues.There are no outstanding underwriting issues",
        defaultMessage: "There are no outstanding underwriting issues."
    },
    yellowHeaderTitle: {
        id: "wmic.gateway.views.quote-underwritingIssues.The following will be auto-approved and may be subject to a compliance review",
        defaultMessage: "The following will be auto-approved and may be subject to a compliance review:"
    },
    issueApproved: {
        id: "wmic.gateway.views.quote-underwritingIssues.issueApproved",
        defaultMessage: "Approved"
    },
    issueDeclined: {
        id: "wmic.gateway.views.quote-underwritingIssues.issueDeclined",
        defaultMessage: "Declined"
    },
    quotePageAutoApprovedUWIssuesTitle: {
        id: "wmic.gateway.views.quote-underwritingIssues.The following will be auto-approved and may be subject to a compliance review",
        defaultMessage: "The following will be auto-approved and may be subject to a compliance review:"
    },
    quotePageUWIssuesSubTitle: {
        id: "wmic.gateway.views.quote-underwritingIssues.Underwriting issues have been raised for the following",
        defaultMessage: "Underwriting issues have been raised for the following:"
    },
    quotePageUWEditOrReferText: {
        id: "wmic.gateway.views.quote-underwritingIssues.You can either edit the quote with the customers permission, or refer it to the underwriter",
        defaultMessage: "You can either edit the quote with the customers permission, or refer it to the underwriter"
    },
    UWFormInput: {
        id: "wmic.gateway.views.quote-underwritingIssues.Notes to the underwriter",
        defaultMessage: "Notes to the underwriter"
    },
    referToUnderwriter: {
        id: "wmic.gateway.views.quote-underwritingIssues.Refer to Underwriter",
        defaultMessage: "Refer to Underwriter"
    }
});
