var api = require("!../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../../../../node_modules/react-scripts/node_modules/css-loader/dist/cjs.js??ref--5-oneOf-10-1!../../../../node_modules/postcss-loader/dist/cjs.js??ref--5-oneOf-10-2!../../../../node_modules/react-scripts/node_modules/resolve-url-loader/index.js??ref--5-oneOf-10-3!../../../../node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-10-4!./gateway-styles.scss");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {};

options.insert = element => {
            const parent = document.querySelector('head');
            const lastInsertedElement =
                // eslint-disable-next-line no-underscore-dangle
                window._lastElementInsertedByStyleLoader;

            if (!lastInsertedElement) {
                parent.insertBefore(element, parent.firstChild);
            } else if (lastInsertedElement.nextSibling) {
                parent.insertBefore(element, lastInsertedElement.nextSibling);
            } else {
                parent.appendChild(element);
            }

            // eslint-disable-next-line no-underscore-dangle
            window._lastElementInsertedByStyleLoader = element;
        };
options.singleton = false;

var update = api(content, options);



module.exports = content.locals || {};