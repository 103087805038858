import { useEffect, useContext } from 'react'
import { TranslatorContext } from '@jutro/locale';
import _ from 'lodash';
import { JobType } from 'wmic-pe-portals-utils-js';

function useDocumentTitle(title, jobVM = undefined) {
    const translator = useContext(TranslatorContext);
    const submissionTranslated = {
        id: 'submission.agent.views.policy-landing.Submission Number',
        defaultMessage: 'meta.Submission'
    };
    const policyTranslated = {
        id: 'issued.agent.directives.PolicySummary.Policy Number',
        defaultMessage: 'meta.Policy'
    }
    const gpaTitleTranslated = {
        id: 'portal.index.GPA Title',
        defaultMessage: 'meta.BluePass'
    }
    const policychangeTranslated = {
        id: 'gateway.views.endorsement-detail.Policy Change',
        defaultMessage: 'meta.Policy Change'
    }
    const renewalTranslated = {
        id: 'portal.components.transactionWizardTitle.transactionWizardTitle.Renewal',
        defaultMessage: 'meta.Renewal'
    }

    useEffect(() => {
        if (_.isUndefined(jobVM)) {
            const displayTitle = `${title} - ${translator(gpaTitleTranslated)}`;
            document.title = displayTitle;
            document.getElementById('bluepass-app-title').innerHTML = displayTitle;
        } else {
            let displayTitle = "";
            const lob = _.get(jobVM, 'lob.value.description', '');
            if (_.get(jobVM, 'baseData.jobType.value.code', '') === JobType.SUBMISSION) {
                const id = _.get(jobVM, 'quoteID.value', 0);
                displayTitle = `${translator(submissionTranslated)} #${id} - ${title} - ${lob} - ${translator(gpaTitleTranslated)}`;
            }
            else if (_.get(jobVM, 'baseData.jobType.value.code', '') === JobType.POLICY_CHANGE) {
                const id = _.get(jobVM, 'jobID.value', 0);
                displayTitle = `${translator(policychangeTranslated)} #${id} - ${title} - ${lob} - ${translator(gpaTitleTranslated)}`;
            }
            else if (_.get(jobVM, 'baseData.jobType.value.code', '') === JobType.RENEWAL) {
                const id = _.get(jobVM, 'jobID.value', 0);
                displayTitle = `${translator(renewalTranslated)} #${id} - ${title} - ${lob} - ${translator(gpaTitleTranslated)}`;
            }
            else {
                const id = _.get(jobVM, 'jobID.value', 0);
                displayTitle = `${translator(policyTranslated)} #${id} - ${title} - ${lob} - ${translator(gpaTitleTranslated)}`;
            }
            document.title = displayTitle;
            document.getElementById('bluepass-app-title').innerHTML = displayTitle;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title]);

}

export default useDocumentTitle