import { setComponentMapOverrides } from '@jutro/uiconfig';

import QuoteWizardPageTemplate from './components/QuoteWizardPageTemplate';
import WMICQuoteWizardPageTemplateQnB from './components/WMICQuoteWizardPageTemplateQnB';
import LineOfBusiness from './components/LineOfBusiness/LineOfBusiness';
import RetrieveQuote from './components/RetrieveQuote/RetrieveQuote';
import WMICCustomHeader from './components/WMICCustomHeader/WMICCustomHeader';
import WMICCustomInput from './components/WMICCustomInput/WMICCustomInput';
import WMICCustomFooter from './components/WMICCustomFooter/WMICCustomFooter';
import WMICCustomDropdownSelect from './components/WMICCustomDropdownSelect/WMICCustomDropdownSelect';
import WMICAddressLookupComponent from './components/WMICAddressLookupComponent/WMICAddressLookupComponent';
import WMICSwitchIconTooltip from './components/WMICSwitchIconTooltip/WMICSwitchIconTooltip';
import WMICCustomRadioButton from './components/WMICCustomRadioButton/WMICCustomRadioButton';
import WMICCustomInputToggle from './components/WMICCustomInputToggleComponent/WMICCustomInputToggle';
import WMICCustomTooltip from './components/WMICCustomTooltip/WMICCustomTooltip';
import WMICAnimalTable from './components/WMICAnimalTable/WMICAnimalTable';
import WMICDatePicker from './components/WMICDatePicker/WMICDatePicker';
import WMICPremiumBox from './components/WMICPremiumBox/WMICPremiumBox';
import WMICPremiumBoxFooter from './components/WMICPremiumBox/WMICPremiumBoxFooter';
import WMICPremiumBoxSummary from './components/WMICPremiumBox/WMICPremiumBoxSummary';
import WMICScrollToError from './components/WMICScrollToError/WMICScrollToError';
import WMICBankAccountDetails from './components/WMICBankAccountDetails/WMICBankAccountDetails';

export { default as commonMessages } from './quoteandbind.messages';
export { default as commonQuoteStyles } from './quoteandbind-common-styles.module.scss';
export { default as PaymentDetailsPage } from './pages/PaymentDetails/PaymentDetailsPage';
export { default as QuoteInfo } from './components/CommonQuoteInfo/CommonQuoteInfo';
export { default as QualificationPage } from './pages/Qualification/QualificationPage';
export { default as WMICQualificationPageQnB } from './pages/WMICQualificationPageQnB/WMICQualificationPageQnB';
export { default as QuickQuoteWizardPageTemplate } from './components/QuickQuoteWizardPageTemplate';
export { QuoteInfoComponentContext, withQuoteInfoComponent } from './components/QuoteInfoComponentContext';
export { WMICRetrieveInfoContext, withWMICRetrieveInfoContext } from './components/WMICRetrieveInfoContext';
export { QuoteWizardPageTemplate };
export { WMICQuoteWizardPageTemplateQnB };
export { default as WMICCustomHeader } from './components/WMICCustomHeader/WMICCustomHeader';
export { default as WMICCustomFooter } from './components/WMICCustomFooter/WMICCustomFooter';
export { default as WMICAddressLookupComponent } from './components/WMICAddressLookupComponent/WMICAddressLookupComponent';
export { default as WMICMessageModal } from './components/WMICMessageModal/WMICMessageModal';
export { default as WMICModal } from './components/WMICModal/WMICModal';
export { default as LoadingModal } from './components/WMICProgressModal/WMICProgressModal';
export { default as WMICProgressModal } from './components/WMICProgressModal/WMICProgressModal';
export { default as WMICTimezoneUtilService } from './services/WMICTimezoneUtilService';
export { default as WMICErrorHandlingService } from './services/WMICErrorHandlingService';
export { default as WMICESignatureCallbackPage } from './pages/WMICESignatureCallback/WMICESignatureCallbackPage';
export { default as WMICQuickRetrieveQuotePage } from './pages/WMICQuickRetrieveQuote/WMICQuickRetrieveQuotePage';
export { default as WMICRetrieveQuote } from './components/WMICRetrieveQuote/WMICRetrieveQuote';
export { default as WMICPaymentCallback } from './pages/WMICPaymentCallback/WMICPaymentCallback';

export { WMICCustomInput };
export { WMICCustomDropdownSelect };
export { WMICSwitchIconTooltip };
export { WMICCustomRadioButton };
export { WMICCustomInputToggle };
export { WMICCustomTooltip };
export { WMICAnimalTable };
export { WMICDatePicker };
export { WMICPremiumBox };
export { WMICPremiumBoxFooter };
export { WMICPremiumBoxSummary };
export { WMICScrollToError };
export { WMICBankAccountDetails };

setComponentMapOverrides(
    {
        QuoteWizardPageTemplate: { component: 'QuoteWizardPageTemplate' },
        WMICQuoteWizardPageTemplateQnB: { component: 'WMICQuoteWizardPageTemplateQnB' },
        LineOfBusiness: { component: 'LineOfBusiness' },
        RetrieveQuote: { component: 'RetrieveQuote' },
        WMICCustomHeader: { component: 'WMICCustomHeader' },
        WMICCustomInput: { component: 'WMICCustomInput' },
        WMICCustomDropdownSelect: { component: 'WMICCustomDropdownSelect' },
        WMICCustomFooter: { component: 'WMICCustomFooter' },
        WMICAddressLookupComponent: { component: 'WMICAddressLookupComponent' },
        WMICSwitchIconTooltip: { component: 'WMICSwitchIconTooltip' },
        WMICCustomRadioButton: { component: 'WMICCustomRadioButton' },
        WMICCustomInputToggle: { component: 'WMICCustomInputToggle' },
        WMICCustomTooltip: { component: 'WMICCustomTooltip' },
        WMICAnimalTable: { component: 'WMICAnimalTable' },
        WMICDatePicker: { component: 'WMICDatePicker' },
        WMICPremiumBox: { component: 'WMICPremiumBox' },
        WMICPremiumBoxFooter: { component: 'WMICPremiumBoxFooter' },
        WMICPremiumBoxSummary: { component: 'WMICPremiumBoxSummary' },       
        WMICScrollToError: { comonent: 'WMICScrollToError' },
        WMICBankAccountDetails: { comonent: 'WMICBankAccountDetails' }
    },
    {
        QuoteWizardPageTemplate,
        WMICQuoteWizardPageTemplateQnB,
        LineOfBusiness,
        RetrieveQuote,
        WMICCustomHeader,
        WMICCustomFooter,
        WMICCustomInput,
        WMICCustomDropdownSelect,
        WMICAddressLookupComponent,
        WMICSwitchIconTooltip,
        WMICCustomRadioButton,
        WMICCustomInputToggle,
        WMICCustomTooltip,
        WMICAnimalTable,
        WMICDatePicker,
        WMICPremiumBox,
        WMICPremiumBoxFooter,
        WMICPremiumBoxSummary,
        WMICScrollToError,
        WMICBankAccountDetails
    }
);
