import { defineMessages } from 'react-intl';

export default defineMessages({
    wizard: {
        id: 'quoteandbind.gl.wizard.step.Operations',
        defaultMessage: 'Operations'
    },
    title: {
        id: 'quoteandbind.gl.views.businessOperations.title',
        defaultMessage: 'Operation'
    },
    additionalBusinessOperation: {
        id: 'quoteandbind.views.quote-effective-date.Additional Business Operation',
        defaultMessage: 'Additional business operation'
    },
    industryCode: {
        id: 'quoteandbind.views.quote-effective-date.operations.Industry code',
        defaultMessage: 'Industry code'
    },
    classification: {
        id: 'quoteandbind.views.quote-effective-date.operations.Industry Code Classification',
        defaultMessage: 'Classification'
    },
    basisType: {
        id: 'quoteandbind.views.quote-effective-date.operations.Basis type',
        defaultMessage: 'Basis type'
    },
    basis: {
        id: 'quoteandbind.views.quote-effective-date.operations.Basis',
        defaultMessage: 'Basis'
    },
    addBusinessOperation: {
        id: 'quoteandbind.views.quote-effective-date.operations.Add Business Operation Button',
        defaultMessage: 'Add Business Operation'
    },
    removeExposureLong: {
        id: "quoteandbind.views.quote-effective-date.operations.Remove_Item_Long",
        defaultMessage: "Are you sure you want to remove the item from the list?"
    },
    removeExposureShort: {
        id: "quoteandbind.views.quote-effective-date.operations.Remove_Item_Short",
        defaultMessage: "Remove Item"
    },
    savingExposure: {
        id: 'quoteandbind.views.quote-effective-date.operations.Saving Exposure',
        defaultMessage: 'Saving Exposure'
    },
});
