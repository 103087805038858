/* eslint-disable no-secrets/no-secrets */
import React, { useContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'

import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'

import { TranslatorContext } from '@jutro/locale';

import { CONSTANTS, ACTION_TYPES, WMICAddressDetailsUtil } from 'wmic-pe-portals-utils-js';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import WMICPUPLocationExposureForm from './WMICPUPLocationExposureForm/WMICPUPLocationExposureForm';

import metadata from './WMICPUPLocationExposures.metadata.json5';
import messages from './WMICPUPLocationExposures.messages.js';

function WMICPUPLocationExposures(props) {
    const {
        id,
        exposuresVM,
        accountAddresses,
        hasWatercraft,
        isUnderEditing,
        jobVM,
        updateExposuresVM,
        showErrorsParent
    } = props;

    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { isComponentValid, onValidate } = useValidation(id);
    const { showConfirm } = useWizardModals();

    const [exposureVM, setExposureVM] = useState();
    const [exposureIndex, setExposureIndex] = useState(-1);
    const [accountAddressOptions, setAccountAddressOptions] = useState([]);
    const [backupExposure, setBackupExposure] = useState();
    const [exposureAction, setExposureAction] = useState(ACTION_TYPES.NONE);
    const [showErrors, setShowErrors] = useState(false);


    useEffect(() => {
        setAddressCodes();
    }, []);

    const setAddressCodes = useCallback(() => {
        accountAddressOptions.push(...accountAddresses)
        if (accountAddressOptions.some((address) => address.code === undefined || address.name === undefined)) {
            const allAccountAddressesDisplay = accountAddressOptions.map((address) => {
                if (address.code === undefined || address.name === undefined) {
                  return {
                    ...address,
                    code: address.publicID,
                    name: address.displayName,
                  };
                }
                return address;
              })
            setAccountAddressOptions(allAccountAddressesDisplay);
        }
    }, [accountAddressOptions, accountAddresses]);

    const selectExposure = (index, edit) => {
        const exposure = exposuresVM.children[index].locationExposure

        if(edit) {
            setExposureAction(ACTION_TYPES.EDIT);
            setBackupExposure(_.cloneDeep(exposure.value));
        }

        setExposureIndex(index);
        setExposureVM(exposure);
    };

    const addExposure = () => {
        const exposure = viewModelService.create(
            {},
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.personalumbrella.coverables.dto.exposure.LocationExposureDTO_WMIC',
            jobVM.aspects.context()
        );

        setExposureAction(ACTION_TYPES.ADD);
        setExposureVM(exposure);
    };

    const saveExposure = () => {
        if (isComponentValid) {
            const addressIndex = accountAddressOptions.findIndex((address) => address.publicID === exposureVM.accountLocationPublicID.value)

            const address = {value: accountAddressOptions[addressIndex]};

            const addressDisplayName = `${addressIndex + 1}: ${WMICAddressDetailsUtil.formatAddress(address)}`;

            _.set(exposureVM, 'value.addressDisplayName', addressDisplayName);
            if(exposureAction === ACTION_TYPES.ADD) {
                exposuresVM.value.push({ locationExposure: exposureVM.value});
            } else {
                _.set(exposuresVM, `value.[${exposureIndex}].locationExposure`, exposureVM.value);
                updateExposuresVM(exposuresVM);
            };
            setExposureVM(undefined);
            setExposureAction(ACTION_TYPES.NONE);
        } else {
            setShowErrors(true);
        }
    };

    const cancelExposure = () => {
        if(exposureAction === ACTION_TYPES.ADD) {
            setExposureVM(undefined);
        } else {
            _.set(exposuresVM, `value.[${exposureIndex}].locationExposure`, backupExposure);
            updateExposuresVM(exposuresVM);

            _.set(exposureVM, 'value', backupExposure);
            setExposureVM(exposureVM);
        };
        setExposureAction(ACTION_TYPES.NONE);
        setShowErrors(false);
    };

    const removeExposure = async (exposure, index) => {
        const response = await showConfirm({
            title: translator(messages.wmicUnderlyingPoliciesRemoveLocationExposure),
            message: translator(messages.wmicUnderlyingPoliciesRemoveLocationExposureConfirmationMsg,
                {address: _.get(exposuresVM, `value[${index}].locationExposure.addressDisplayName`)})
        })

        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            if(exposureIndex === index) {
                setExposureVM(undefined);
            }

            exposuresVM.value.splice(index, 1);
            updateExposuresVM(exposuresVM);
            setShowErrors(false);
        }
    };

    const overrideProps =  {
        locationExposureForm: {
            visible: !_.isUndefined(exposureVM),
            exposureVM,
            setExposureVM,
            accountAddressOptions,
            setAccountAddressOptions,
            cancelExposure,
            saveExposure,
            isEditing: exposureAction === ACTION_TYPES.ADD || exposureAction === ACTION_TYPES.EDIT,
            isParentUnderEditing: isUnderEditing,
            showErrors: showErrors || showErrorsParent,
            onValidate
        },
        locationExposuresDataList: {
            VMList: exposuresVM.children,
            VMData: [
                {
                    headerText: translator(messages.wmicUnderlyingPoliciesAddress),
                    path: 'locationExposure.addressDisplayName'

                },
                {
                    headerText: translator(messages.wmicUnderlyingPoliciesDwellingUse),
                    getData: (exposure) => exposure?.locationExposure?.dwellingUsage?.value ? translator({id: exposure.locationExposure.dwellingUsage.value.name}) : ''
                }
            ],
            onEditAction: (exposure, index) => selectExposure(index, true),
            onRemoveAction: removeExposure,
            updateSelectedCardIndex: (index) => selectExposure(index),
            selectedCardIndex: exposureIndex,
            isEditing: exposureAction === ACTION_TYPES.ADD || exposureAction === ACTION_TYPES.EDIT,
            readOnly: !isUnderEditing

        },
        hasWatercraftRisks: {
            visible: hasWatercraft === true
        },
        requiredOneExposure: {
            visible: exposuresVM.children.length === 0
        },
        addLocationExposureButton: {
            disabled: exposureAction === ACTION_TYPES.ADD || exposureAction === ACTION_TYPES.EDIT || !isUnderEditing
        }
    }


    const resolvers = {
        resolveCallbackMap: {
            addExposure
        },
        resolveComponentMap: {
            WMICPUPLocationExposureForm
        }
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={exposuresVM}
            onValidationChange={onValidate}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors || showErrorsParent}
        />
    );
}

WMICPUPLocationExposures.propTypes = {
    exposuresVM: PropTypes.object.isRequired,
    accountAddresses: PropTypes.array.isRequired,
    hasWatercraft: PropTypes.bool.isRequired,
    isUnderEditing: PropTypes.bool.isRequired,
    jurisdiction: PropTypes.string.isRequired,
    readOnly: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool,
};

WMICPUPLocationExposures.defaultProps = {
};

export default WMICPUPLocationExposures;
