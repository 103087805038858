import React, { useState, useContext, useCallback, useEffect } from 'react'
import _ from 'lodash';

import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';

import { TranslatorContext } from '@jutro/locale';

import { EMAIL_VALIDATION_STATUS, WMICDateTimeService } from 'wmic-pe-portals-utils-js';
import { AccountService } from 'wmic-pe-capability-gateway-policy';

import metadata from './WMICDriveChangeTelematicsComponent.metadata.json5';
import messages from './WMICDriveChangeTelematicsComponent.messages.js';

function WMICDriveChangeTelematicsComponent(props) {
    const {
        id,
        jobVM,
        driverVM,
        showErrors,
        updateDriver,
        authHeader,
        onValidate,
        isEditMode,
        isReadOnlyUser,
        hideButton = false
    } = props

    const { onValidate: onValidateComponent, isComponentValid } = useValidation(id);
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);

    const [emailValidationStatus, setEmailValidationStatus] = useState('');
    const [emailAddressHasChanged, setEmailAddressHasChanged] = useState(false);
    const [showEmailValidationMessage, setShowEmailValidationMessage] = useState(false);
    const [isEmailVerifying, setIsEmailVerifying] = useState(false);
    const [driverEmailAddress, setDriverEmailAddress] = useState(_.get(driverVM, 'emailAddress1.value', ''));
    const [showConfirmationEmail, setShowConfirmationEmail] = useState();

    const driveChangeEnrollmentStatusTypelist = viewModelService.productMetadata.get('pc').types.getTypelist('DriveChangeEnrollmentStatus_Ext');
    const driveChangeUnenrollmentReasonTypelist = viewModelService.productMetadata.get('pc').types.getTypelist('DriveChangeUnenrollRsn_Ext');

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [isComponentValid, onValidate, id]);

    const getValidationStatus = useCallback(() => {
        switch (emailValidationStatus) {
            case EMAIL_VALIDATION_STATUS.VALID:
                return messages.emailValid;
            case EMAIL_VALIDATION_STATUS.INVALID_TLS:
                return messages.invalidtls;
            case EMAIL_VALIDATION_STATUS.ERROR:
                return messages.unavailable;
            case EMAIL_VALIDATION_STATUS.INVALID:
                return messages.invalid;
            case EMAIL_VALIDATION_STATUS.EMPTY:
                return messages.emptyEmail;
            default:
                return messages.unavailable;
        }
    }, [emailValidationStatus]);

    const onEmailAddressChange = (value, path) => {
        _.set(driverVM, path, value)
        if (driverEmailAddress === driverVM.emailAddress1.value) {
            setShowEmailValidationMessage(emailValidationStatus !== EMAIL_VALIDATION_STATUS.DRAFT && emailValidationStatus !== '');
            setEmailAddressHasChanged(false);
            _.set(driverVM, 'confirmDriverEmailAddress_WMIC.value', driverEmailAddress);
        } else {
            setEmailValidationStatus('');
            setShowEmailValidationMessage(false);
            setEmailAddressHasChanged(true);
            _.set(driverVM, 'confirmDriverEmailAddress_WMIC.value', null);
        }
        updateDriver(driverVM)
    };

    const validateEmail = () => {
        if (showConfirmationEmail && (!_.get(driverVM, 'confirmDriverEmailAddress_WMIC.aspects.valid') || !_.get(driverVM, 'confirmDriverEmailAddress_WMIC.value'))) {
            return;
        }
        setEmailAddressHasChanged(false);
        setIsEmailVerifying(true);
        AccountService.validateEmailWithoutSettingEmailValidationStatus(_.get(driverVM, 'emailAddress1.value'), authHeader).then(res => {
            setIsEmailVerifying(false);
            setEmailValidationStatus(res.emailValidationStatus);
            setShowEmailValidationMessage(res.emailValidationStatus !== EMAIL_VALIDATION_STATUS.DRAFT && res.emailValidationStatus !== '');
        }).catch(() => {
            setIsEmailVerifying(false);
            setEmailValidationStatus(EMAIL_VALIDATION_STATUS.ERROR);
            setShowEmailValidationMessage(true);
        });
    };

    const displayDate = (dateValue) => {
        return dateValue ? <span>{WMICDateTimeService.toMidnightDate(dateValue)}</span> : '';
    };

    const overrideProps = {
        '@field': {
            parentNode: driverVM,
            readOnly: !isEditMode || isReadOnlyUser
        },
        emailValidationIcon: {
            visible: emailValidationStatus === EMAIL_VALIDATION_STATUS.VALID
        },
        emailValidationStatus: {
            visible: !_.isNil(getValidationStatus()),
            content: translator(getValidationStatus())
        },
        driveChangeContactInfoWarning: {
            visible: !(_.get(driverVM, 'emailAddress1.value', false) && _.get(driverVM, 'cellPhone_WMIC.value', false))
        },
        enrollmentDetailsDataList: {
            VMList: [driverVM],
            dataListTitle: {getData: () => messages.enrollmentDetails},
            VMData: [
                {
                    headerText: translator(messages.activationDate),
                    getData: () => displayDate(_.get(driverVM, 'driveChange_WMIC.activationDate.value')),
                    visibilityCondition: () => _.get(driverVM, 'driveChange_WMIC.isEnrolled.value', false)
                },
                {
                    headerText: translator(messages.enrollmentStatus),
                    getData: () => _.get(driverVM, 'driveChange_WMIC.enrollmentStatus.value.code')
                        ? translator({id: driveChangeEnrollmentStatusTypelist.getCode(_.get(driverVM, 'driveChange_WMIC.enrollmentStatus.value.code')).name})
                        : undefined,
                    visibilityCondition: () => _.get(driverVM, 'driveChange_WMIC.isEnrolled.value', false)
                },
                {
                    headerText: translator(messages.lastUpdatedOn),
                    getData: () => displayDate(_.get(driverVM, 'driveChange_WMIC.lastEnrollmentStatusUpdateDate.value')),
                    visibilityCondition: () => _.get(driverVM, 'driveChange_WMIC.isEnrolled.value', false)
                },
                {
                    headerText: translator(messages.unenrollmentReason),
                    getData: () => _.get(driverVM, 'driveChange_WMIC.unenrollmentReason.value.code')
                        ? translator({id: driveChangeUnenrollmentReasonTypelist.getCode(_.get(driverVM, 'driveChange_WMIC.unenrollmentReason.value.code')).name})
                        : undefined,
                    visibilityCondition: () => !_.get(driverVM, 'driveChange_WMIC.isEnrolled.value') && _.get(driverVM, 'driveChange_WMIC.unenrollmentDate.value') !== undefined

                },
                {
                    headerText: translator(messages.unenrollmentDate),
                    getData: () => displayDate(_.get(driverVM, 'driveChange_WMIC.unenrollmentDate.value')),
                    visibilityCondition: () => !_.get(driverVM, 'driveChange_WMIC.isEnrolled.value') && _.get(driverVM, 'driveChange_WMIC.unenrollmentDate.value') !== undefined
                }
            ],
            readOnly: true,
            clickable: false
        },
        drivingDetailsDataList: {
            VMList: [driverVM],
            dataListTitle: {getData: () => messages.drivingDetails},
            VMData: [
                {
                    headerText: translator(messages.driveChangeSafetyScore),
                    path: 'driveChange_WMIC.safetyScore'
                },
                {
                    headerText: translator(messages.totalKilometersDriven),
                    path: 'driveChange_WMIC.totalKilometersDriven'

                }
            ],
            readOnly: true,
            clickable: false,
            visible: _.get(driverVM, 'driveChange_WMIC.isEnrolled.value', false)
        },
        rewardPointsDataList: {
            VMList: [driverVM],
            dataListTitle: {getData: () => messages.rewardPoints},
            VMData: [
                {
                    headerText: translator(messages.availableToRedeem),
                    path: 'driveChange_WMIC.rewardPoints'
                },
                {
                    headerText: translator(messages.pointsLastUpdatedOn),
                    getData: () => displayDate(_.get(driverVM, 'driveChange_WMIC.lastRewardPointsUpdateDate.value'))
                }
            ],
            readOnly: true,
            clickable: false,
            visible: _.get(driverVM, 'driveChange_WMIC.isEnrolled.value', false)
        },
        driveChangeDetailsContainer: {
            visible: _.get(driverVM, 'driveChange_WMIC.driveChangeDetailsContainer.aspects.ocular', false)
        },
        driveChangeFieldset: {
            visible: _.get(driverVM, 'driveChange_WMIC.isEnrolled.value', false)
        },
        driverConfirmEmail: {
            visible: emailAddressHasChanged
        },
        acctEmailValidation: {
            visible: showEmailValidationMessage
        },
        previousDiscountPercentage: {
            readOnly: !_.get(driverVM, 'driveChange_WMIC.previousDiscountPercentage.aspects.editable') || !isEditMode || isReadOnlyUser
        },
        verifyEmailButton: {
            disabled: isEmailVerifying || !isEditMode || isReadOnlyUser,
            visible: !hideButton
        }
    }

    const resolvers = {
        resolveCallbackMap: {
            onEmailAddressChange,
            validateEmail
        },
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={driverVM}
            onModelChange={updateDriver}
            overrideProps={overrideProps}
            onValidationChange={onValidateComponent}
            callbackMap={resolvers.resolveCallbackMap}
            showErrors={showErrors}
        />
    );
}

export default WMICDriveChangeTelematicsComponent