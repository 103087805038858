/* eslint-disable no-secrets/no-secrets */
import React, {
    useCallback,
    useContext,
    useEffect,
    useState,
    useMemo,
} from 'react';
import _ from 'lodash';

import { TranslatorContext } from '@jutro/locale';
import { withViewModelService, ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { WMICWizardChangeOrRenewalPage, useWizardModals, useDocumentTitle } from 'wmic-pe-portals-wizard-components-ui';
import { WMICScrollToError } from 'wmic-pe-components-platform-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';

import { WMICLogger, WMICUserAccessUtil } from 'wmic-pe-portals-utils-js';
import { WMICHOConstructionDetailView, presentConstruction } from 'wmic-pe-capability-gateway-common-ho-react';
import { renderDwellingRiskNumberCell, renderPrimaryCell, renderDwellingAddressCell } from 'wmic-pe-capability-gateway-quoteandbind-ho-react';

import { messages as commonMessages } from 'wmic-pe-capability-gateway-policychange-common-react';
import metadata from './WMICHOPolicyRenewalConstructionPage.metadata.json5';
import messages from './WMICHOPolicyRenewalConstructionPage.messages';

const DWELLINGS_PATH = 'lobData.homeowners.coverables.dwellings';

function WMICHOPolicyRenewalConstructionPage(props) {
    const {
        wizardData: renewalVM,
        updateWizardData
    } = props;

    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);

    const { RenewalService } = useDependencies('RenewalService');
    const { authUserData: currentUser, authHeader } = useAuthentication();
    const { setWizardLoading } = useWizardModals();
    const { isComponentValid, initialValidation, onValidate, registerInitialComponentValidation } = useValidation(
        'WMICHOPolicyRenewalConstructionPage'
    );

    useDocumentTitle("Construction", renewalVM);

    const canEditPolicyRenewal = useMemo(
        () => WMICUserAccessUtil.canEditPolicyRenewal(currentUser.roles),
        [currentUser]
    );

    useEffect(() => {
        _.set(renewalVM, 'isEditingPage.value', false);
    }, [renewalVM]);

    const [showErrors, setShowErrors] = useState(false);
    const [scrollToError, setScrollToError] = useState(); // we toggle this when we want to scroll to the first error on the page
    const [hasDwellings, setHasDwellings] = useState(false);

    useEffect(() => {
        registerInitialComponentValidation(() => presentConstruction(renewalVM));
        setHasDwellings(_.get(renewalVM, `${DWELLINGS_PATH}.children`, []).length > 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            setShowErrors(true);
            setScrollToError(Date.now()); // force scroll to first error
            return false;
        }
        try {
            setWizardLoading(true);
            const newRenewalVM = viewModelService.clone(renewalVM);
            _.unset(newRenewalVM.value, 'bindData');

            renewalVM.value = await RenewalService.saveRenewal(
                [renewalVM.value],
                authHeader
            );

            return renewalVM;
        } finally {
            setWizardLoading(false);
        }
    }, [viewModelService, renewalVM, RenewalService, authHeader, setWizardLoading, isComponentValid]);

    const saveConstruction = useCallback(async (newRenewalVM) => {
        _.unset(newRenewalVM.value, 'bindData');
        try {
            setWizardLoading(true, translator(messages.savingConstruction));

            const result = await RenewalService.saveRenewal(
                [newRenewalVM.value],
                authHeader
            );
            _.extend(renewalVM.value, result);
            updateWizardData(renewalVM);
            setShowErrors(false);
            return true;
        } catch (error) {
            WMICLogger.error('Save construction failed', error);
            return false;
        } finally {
            setWizardLoading(false);
        };
    }, [setWizardLoading, translator, RenewalService, authHeader, renewalVM, updateWizardData]);

    const onSaveDwelling = useCallback((dwellingVM, index) => {
        _.set(dwellingVM, 'value.isUnderEditing', false);
        _.set(renewalVM, 'isEditingPage.value', false);

        // unset heaters' secondarySurveyDetail if not used, to get rid of validation errors
        dwellingVM.construction.heaters.value.forEach((heater, heaterIndex) => {
            const isPrimaryFuelSourceSecondarySurveyDetailNeeded = _.get(heater, 'primaryFuelSource.fuelSurvey.secondarySurveyDetail.oilTankType', false);
            const isSecondaryFuelSourceSecondarySurveyDetailNeeded = _.get(heater, 'secondaryFuelSource.fuelSurvey.secondarySurveyDetail.oilTankType', false);

            if (!isPrimaryFuelSourceSecondarySurveyDetailNeeded) {
                _.unset(dwellingVM.value, `construction.heaters[${heaterIndex}].primaryFuelSource.fuelSurvey.secondarySurveyDetail`)
            }

            if (!isSecondaryFuelSourceSecondarySurveyDetailNeeded) {
                _.unset(dwellingVM.value, `construction.heaters[${heaterIndex}].secondaryFuelSource.fuelSurvey.secondarySurveyDetail`)
            }
        })

        renewalVM.lobData.homeowners.coverables.dwellings.setElement(index, dwellingVM.value);
        // Third argument to saveSubmission is any actions to take in case of an error
        return saveConstruction(renewalVM);
    }, [saveConstruction, renewalVM]);

    const onEditDwelling = () => {
        _.set(renewalVM, 'isEditingPage.value', true);
        updateWizardData(renewalVM);
    }

    const onCancelDwelling = () => {
        _.set(renewalVM, 'isEditingPage.value', false);
        updateWizardData(renewalVM);
    }

    const overrideProps = {
        noRisksText: {
            visible: !hasDwellings
        },
        risksDataList: {
            clickable: true,
            startOpen: true,
            readOnly: !canEditPolicyRenewal,
            showErrors,
            editEnabled: true,
            value: _.get(renewalVM, `${DWELLINGS_PATH}.children`, []),
            VMData: [
                {
                    headerText: translator(messages.riskNumber),
                    path: 'yourHome.dwellingNumber_EXT',
                    getData: (item, path) => renderDwellingRiskNumberCell(item, path, renewalVM)
                },
                {
                    headerText: translator(messages.riskPrimary),
                    path: 'yourHome.primaryDwelling_EXT',
                    getData: renderPrimaryCell
                },
                {
                    headerText: translator(messages.riskDescription),
                    path: 'yourHome.locationAddress.displayName',
                    getData: (item) => renderDwellingAddressCell(item, translator)
                },
                {
                    headerText: translator(messages.riskType),
                    path: 'rating.hoPolicyType'
                }
            ],
            detailViewComponent: WMICHOConstructionDetailView,
            detailViewComponentProps: {
                jobVM: renewalVM
            },
            onValidate,
            onSave: onSaveDwelling,
            toEdit: onEditDwelling,
            onCancel: onCancelDwelling,
            showCancelModal: true,
            canDelete: () => false
        }
    };

    return (
        <React.Fragment>
            <WMICScrollToError counter={scrollToError}/>
            <WMICWizardChangeOrRenewalPage
                onNext={onNext}
                cancelLabel={translator(commonMessages.saveAndExit)}
                isSkipping={initialValidation}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={renewalVM}
                    overrideProps={overrideProps}
                    onModelChange={updateWizardData}
                    onValidationChange={onValidate}
                    showErrors={showErrors}
                />
            </WMICWizardChangeOrRenewalPage>
        </React.Fragment>
    );
}

WMICHOPolicyRenewalConstructionPage.propTypes = wizardProps;

export default withViewModelService(WMICHOPolicyRenewalConstructionPage);
