import { defineMessages } from 'react-intl';

export default defineMessages({
    wmicPaCoveragesTitle: {
        id: 'wmic.pe-capability-quoteandbind-pa-react.pages.pa-coverages-page.Vehicle Coverages',
        defaultMessage: 'Vehicle Coverages'
    },
    year: {
        id: 'wmic.pe-capability-quoteandbind-pa-react.pages.pa-coverages-page.Year',
        defaultMessage: 'Year'
    },
    make: {
        id: 'wmic.pe-capability-quoteandbind-pa-react.pages.pa-coverages-page.Make',
        defaultMessage: 'Make'
    },
    model: {
        id: 'wmic.pe-capability-quoteandbind-pa-react.pages.pa-coverages-page.Model',
        defaultMessage: 'Model'
    },
    coverages: {
        id: 'wmic.pe-capability-quoteandbind-pa-react.pages.pa-coverages-page.Coverages',
        defaultMessage: 'Coverages'
    },
    optionalProperty: {
        id: 'wmic.pe-capability-quoteandbind-pa-react.pages.pa-coverages-page.Optional Property Coverages',
        defaultMessage: 'Optional Property Coverages',
    },
    optionalLiability: {
        id: 'wmic.pe-capability-quoteandbind-pa-react.pages.pa-coverages-page.Optional Liability Coverages',
        defaultMessage: 'Optional Liability Coverages',
    },
    exclusionsConditions: {
        id: 'wmic.pe-capability-quoteandbind-pa-react.pages.pa-coverages-page.Exclusions & Conditions',
        defaultMessage: 'Exclusions & Conditions',
    },
    generalCoverages: {
        id: 'wmic.pe-capability-quoteandbind-pa-react.pages.pa-coverages-page.General Coverages - Apply to all vehicles',
        defaultMessage: 'General Coverages - Apply to all vehicles',
    },
    vehicleSpecificCoverage: {
        id: 'wmic.pe-capability-quoteandbind-pa-react.pages.pa-coverages-page.MultiQuoteView.Vehicle-',
        defaultMessage: 'Vehicle-Specific Coverages - ',
    },
    vehicleCoverage: {
        id: 'quoteandbind.pa.views.pa-coverages-page.vehicleCoveage',
        defaultMessage: 'Vehicle Coverages'
    },
    driverEndorsements: {
        id: 'quoteandbind.pa.views.pa-coverages-page.driverEndorsements',
        defaultMessage: 'Driver Endorsements'
    },
    thirdPartyLiabilityWarning: {
        id: 'quoteandbind.pa.views.pa-coverage-term.Third Party Liability Applies to All vehicles',
        defaultMessage: 'Third party liability limits must be the same for all vehicles. Any change will update all other vehicles'
    }
});
