/* eslint-disable indent */
import React, { Fragment, useContext, useState, useCallback } from 'react';
import _ from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from 'wmic-digital-auth-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import { CONSTANTS } from 'wmic-portals-utils-js';
import { RadioButtonField, InputMaskField, useModal } from '@jutro/components';
import { WMICButton } from 'wmic-components-platform-react';
import WMICCancelRemoveVehicleModal from '../WMICCancelRemoveVehicleModal/WMICCancelRemoveVehicleModal';
import WMICConfirmRemoveVehicleModal from '../WMICConfirmRemoveVehicleModal/WMICConfirmRemoveVehicleModal';
import { WMICControllerUtil, WMICPolicyChangeStateService} from 'wmic-capability-policychange';
import DeleteVehicleModel from 'wmic-capability-policychange/models/DeleteVehicleModel';
import VehicleModel from 'wmic-capability-policychange/models/VehicleModel';
import metadata from './WMICRemoveVehiclePAComponent.metadata.json5';
import messages from './WMICRemoveVehiclePAComponent.messages';
import styles from './WMICRemoveVehiclePAComponent.module.scss';

const VIEW_MODES = {
    VEHICLE_LIST_VIEW: 1,
    VEHICLE_REMOVAL_FORM: 2
};
const REMOVING_REASON_OPTIONS = [
    { code: 'SOLD', name: 'Sold' },
    { code: 'TRADE_IN', name: 'Traded' },
    { code: 'TOTAL_LOSS', name: 'Total Loss or Salvaged' }
];
const DATE_FORMAT = 'MM-DD-YYYY';
const CTRL_KEY = 'deleteVehicle';

const WMICRemoveVehiclePALegacyComponent = (props) => {
    const modalApi = useModal();
    const { policyVM, match } = props;

    const history = useHistory();
    const { authHeader, userInfo, authUserData } = useAuthentication();

    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const DEFAULT_VALUES = {
        REMOVING_REASON: REMOVING_REASON_OPTIONS[0].code,
        REMOVING_DATE: intl.formatDate(new Date(), { year: 'numeric', month: '2-digit', day: '2-digit' }).replaceAll('/', '-')
    };

    const policyNumber = _.get(match, 'params.policyNumber');
    const termNumber = _.get(match, 'params.termNumber');
    const policyType = _.get(match, 'params.policyType');
    const accountHolder = _.get(policyVM, 'currentPeriod.contacts').filter((contact) => { return contact.contact.accountHolder === true; })[0];

    const [vehicleNumberToRemove, setVehicleNumberToRemove] = useState(undefined);
    const [viewMode, setViewMode] = useState(VIEW_MODES.VEHICLE_LIST_VIEW);
    const [removingReason, setRemovingReason] = useState(DEFAULT_VALUES.REMOVING_REASON);
    const [removingDate, setRemovingDate] = useState(DEFAULT_VALUES.REMOVING_DATE);

    const getRemoveVehicleTitle = useCallback(() => (
        <Fragment>
            <i className={classNames("fa fa-file-text", styles['wmic-title-icon'])} />
            <h4 className={styles['wmic-title']}>{translator(messages.removeVehicle)}</h4>
        </Fragment>
    ), [translator]);

    const handleRemove = useCallback((vehicleNumber) => {
        setVehicleNumberToRemove(vehicleNumber);
        setViewMode(VIEW_MODES.VEHICLE_REMOVAL_FORM);
    }, [setVehicleNumberToRemove, setViewMode]);

    const handleCancel = useCallback(() => {
        history.push(`/account-policy-change/${policyNumber}/${termNumber}/auto/${CONSTANTS.POLICY_CHANGE_TYPE.SELECT_TYPE}`);
    }, [history, policyNumber, termNumber]);

    const handleConfirm = useCallback(() => {
        const id = `delete-vehicle-${vehicleNumberToRemove}`;
        const date = moment(removingDate, DATE_FORMAT);
        const vehicle = policyVM?.currentPeriod?.lobs?.personalAuto?.vehicleDTOs.find((v) => v.vehicleNumber === vehicleNumberToRemove);
        const model = {
            vehicleNumber: vehicle.vehicleNumber,
            changeType: 'delete-vehicle',
            objectType: 'DeleteVehicleModel',
            changeModel: new DeleteVehicleModel({
                reason: removingReason,
                date: date.toDate(),
                vehicle: new VehicleModel({
                    year: vehicle.year,
                    make: vehicle.make,
                    model: vehicle.model,
                    vin: vehicle.vin
                })
            })
        };
        WMICPolicyChangeStateService.addChangeRequest(CTRL_KEY, id, model);
        WMICControllerUtil.handleSubmit({
            history: history,
            policyNumber: policyNumber,
            termNumber: termNumber,
            policyData: policyVM,
            policyType: policyType,
            accountInfo: accountHolder,
            authHeader: authHeader,
            authUserData: authUserData,
            userInfo: userInfo
        },
        translator);
    }, [translator, history, policyNumber, termNumber, policyVM, policyType, accountHolder, authHeader, vehicleNumberToRemove, removingDate, removingReason]);

    const resetForm = useCallback(() => {
        setVehicleNumberToRemove(undefined);
        setRemovingReason(DEFAULT_VALUES.REMOVING_REASON);
        setRemovingDate(DEFAULT_VALUES.REMOVING_DATE);
    }, [DEFAULT_VALUES.REMOVING_REASON, DEFAULT_VALUES.REMOVING_DATE]);

    const switchViewMode = useCallback(() => {
        resetForm();
        setViewMode(VIEW_MODES.VEHICLE_LIST_VIEW);
    }, [resetForm, setViewMode]);

    const handleCancelForm = useCallback(() => {
        if (removingReason !== DEFAULT_VALUES.REMOVING_REASON || removingDate !== DEFAULT_VALUES.REMOVING_DATE) {
            modalApi.showModal(
                <WMICCancelRemoveVehicleModal onCancelEdit={switchViewMode} isOpen />
            ).catch(_.noop);
        } else {
            switchViewMode();
        }
    }, [removingReason, DEFAULT_VALUES.REMOVING_REASON, DEFAULT_VALUES.REMOVING_DATE, removingDate, switchViewMode]);

    const handleSave = useCallback(() => {
        const vehicle = policyVM?.currentPeriod?.lobs?.personalAuto?.vehicleDTOs.find((v) => v.vehicleNumber === vehicleNumberToRemove);
        modalApi.showModal(
            <WMICConfirmRemoveVehicleModal
                onConfirm={handleConfirm}
                vehicle={vehicle}
                reason={REMOVING_REASON_OPTIONS.find((o) => o.code === removingReason).name}
                date={removingDate}
                isOpen
            />
        ).catch(_.noop);
    }, [handleConfirm, policyVM?.currentPeriod?.lobs?.personalAuto?.vehicleDTOs, removingDate, removingReason, vehicleNumberToRemove]);

    const getRemoveVehicleListButtonSection = useCallback(() => (
        <WMICButton type="outlined" onClick={handleCancel}>
            {translator(messages.cancel)}
        </WMICButton>
    ), [handleCancel, translator]);


    const isRemovingDateValid = useCallback(() => {
        const numberOnly = removingDate?.replaceAll('_', '').replaceAll('-', '');
        const date = moment(removingDate,  DATE_FORMAT);
        return date.isValid() && numberOnly?.length === 8;
    }, [removingDate]);

    const getValidationMessages = useCallback(() => isRemovingDateValid() ? [] :  [translator(messages.enterAValidInformation)], [isRemovingDateValid, translator]);

    const getRemoveVehicleFormContent = useCallback(() => {
        const vehicle = policyVM?.currentPeriod?.lobs?.personalAuto?.vehicleDTOs.find((v) => v.vehicleNumber === vehicleNumberToRemove);
        return !!vehicle && (
            <Fragment>
                <RadioButtonField
                    availableValues={REMOVING_REASON_OPTIONS}
                    id="removingReasonRadioButtonField"
                    value={removingReason}
                    onValueChange={(v) => setRemovingReason(v)}
                    label={translator(messages.removingFromYourPolicy, { vehicle: `${vehicle.year} ${vehicle.make} ${vehicle.model}` })}
                    labelClassName={styles['wmic-radio-button-label']}
                    className={styles['wmic-radio-wrap']}
                />
                <InputMaskField
                    id="dateInputField"
                    value={removingDate}
                    label={translator(messages.whatDateDidThisOccur)}
                    onValueChange={(v) => setRemovingDate(v)}
                    className={styles['wmic-input-field']}
                    labelClassName={styles['wmic-input-field-label']}
                    mask="99-99-9999"
                    maskChar="_"
                    alwaysShowMask
                    showErrors
                    validationMessages={getValidationMessages()}
                    messageProps={{ incompleteInput: translator(messages.enterAValidInformation) }}
                />
            </Fragment>
        );
    }, [getValidationMessages, policyVM?.currentPeriod?.lobs?.personalAuto?.vehicleDTOs, removingDate, removingReason, translator, vehicleNumberToRemove]);

    const getRemoveVehicleListContent = useCallback(() => {
        const vehicles = _.orderBy(policyVM?.currentPeriod?.lobs?.personalAuto?.vehicleDTOs, 'vehicleNumber');
        const content = vehicles?.map((v, idx) => {
            return (
                <div key={`dv-${idx}`} className={styles['wmic-edit-row']}>
                    <p>
                        <strong>{v.year} {v.make} {v.model} ({v.vehicleNumber})</strong>
                    </p>
                    <div>
                        <WMICButton type="secondary" size="small" onClick={() => handleRemove(v.vehicleNumber)}>
                            {translator(messages.remove)}
                        </WMICButton>
                    </div>
                </div>

            );
        });

        return <Fragment>{content}</Fragment>;
    }, [handleRemove, policyVM?.currentPeriod?.lobs?.personalAuto?.vehicleDTOs, translator]);

    const getRemoveVehicleFormButtonSection = useCallback(() => (
        <Fragment>
            <WMICButton type="outlined" onClick={handleCancelForm}>
                {translator(messages.cancel)}
            </WMICButton>
            <WMICButton type="primary" onClick={handleSave} disabled={!isRemovingDateValid()}>
                {translator(messages.continue)}
            </WMICButton>
        </Fragment>
    ), [handleCancelForm, handleSave, isRemovingDateValid, translator]);

    const overrideProps = {
        removeVehicleTitle: {
            content: getRemoveVehicleTitle()
        },
        removeVehicleListSubpage: {
            visible: viewMode === VIEW_MODES.VEHICLE_LIST_VIEW
        },
        removeVehicleListContent: {
            content: getRemoveVehicleListContent()
        },
        removeVehicleListButtonSection: {
            content: getRemoveVehicleListButtonSection()
        },
        removeVehicleFormSubpage: {
            visible: viewMode === VIEW_MODES.VEHICLE_REMOVAL_FORM
        },
        removeVehicleFormContent: {
            content: getRemoveVehicleFormContent()
        },
        removeVehicleFormButtonSection: {
            content: getRemoveVehicleFormButtonSection()
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyVM}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

export default WMICRemoveVehiclePALegacyComponent;