import React, { useContext } from 'react';
import { ViewModelForm  } from '@xengage/gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { WMICRPCUtil } from "wmic-pe-portals-utils-js";
import _ from "lodash";
import messages from './WMICPAPolicyVehicleUsageCommercial.messages'
import metadata from './WMICPAPolicyVehicleUsageCommercial.metadata.json5';

function WMICPAPolicyVehicleUsageCommercial({ policyVM, vehicleVM, commercialVM }) {
    const translator = useContext(TranslatorContext);

    const getIsRPCEffectiveForVehTypeAndBusSegment = (rpcCode) => {
        return WMICRPCUtil.getIsRPCEffectiveForVehTypeAndBusSegment(
            _.get(vehicleVM, 'ratingJurisdiction.value.code'),
            _.get(policyVM, 'latestPeriod.rateAsOfDate.value'),
            _.get(vehicleVM, 'vehicleType.value.code'),
            _.get(vehicleVM, 'businessSegment_WMIC.value.code'),
            rpcCode);
    };

    const isRPC1777Effective = () => getIsRPCEffectiveForVehTypeAndBusSegment('1777');

    const overrideProps = {
        '@field': {
            parentNode: commercialVM,
            readOnly: true
        },
        policyHighHazardOperationsContainer: {
            visible: _.get(commercialVM, 'highHazardContainer.aspects.ocular', false)
        },
        policyAdditionalExposureContainer: {
            visible: _.get(commercialVM, 'additionalExposureContainer.aspects.ocular', false)
        },
        policyHighHazardHeading: {
            title: isRPC1777Effective() ? translator(messages.policyHighHazardAlternateHeading) : translator(messages.policyHighHazardHeading)
        },
        policyBulkLiquids: {
            label: isRPC1777Effective() ? translator(messages.policyBulkLiquidsAlternate) : translator(messages.policyBulkLiquids)
        }
    };

    return (
        <ViewModelForm
            model={commercialVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
        />
    );
}

export default WMICPAPolicyVehicleUsageCommercial;
