/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
import _ from 'lodash';
import { WMICRPCUtil } from 'wmic-pe-portals-utils-js';

export default {
    validateRPCDate: (jurisdiction, rateAsOfDate, rpcIdentifier) => {
        let result;
        if (jurisdiction && rateAsOfDate && rpcIdentifier) {
            result = WMICRPCUtil.getIsRPCEffective(jurisdiction.code, rateAsOfDate, rpcIdentifier);
        }
        return result;
    },
    validateRPCDateForBusSegment: (jurisdiction, rateAsOfDate, businessSegment, rpcIdentifier) => {
        return jurisdiction && rateAsOfDate && businessSegment && rpcIdentifier &&
            WMICRPCUtil.getIsRPCEffectiveForBusSegment(jurisdiction.code, rateAsOfDate, businessSegment.code, rpcIdentifier);
    },
    validateRPCDateForRiskType: (jurisdiction, rateAsOfDate, rpcIdentifier, hOPolicyType) => {
        let result;
        if (jurisdiction && rateAsOfDate && hOPolicyType && rpcIdentifier) {
            result = WMICRPCUtil.getIsRPCEffectiveForRiskType(jurisdiction.code, rateAsOfDate, hOPolicyType.code, rpcIdentifier);
        }
        return result;
    },
    validateRPCDateForRiskTypes: (jurisdiction, rateAsOfDate, rpcIdentifier, hoPolicyTypes) => {
        let result;
        if (jurisdiction && rateAsOfDate && !_.isEmpty(hoPolicyTypes) && rpcIdentifier) {
            result = hoPolicyTypes.some((riskType) => WMICRPCUtil.getIsRPCEffectiveForRiskType(jurisdiction.code, rateAsOfDate, riskType, rpcIdentifier));
        }
        return result;
    },
    validateRPCDateForRiskTypeAndIsFarmPackage: (jurisdiction, rateAsOfDate, rpcIdentifier, hOPolicyTypes) => {
        let result;
        if (jurisdiction && rateAsOfDate && !_.isEmpty(hOPolicyTypes) && rpcIdentifier) {
            result = WMICRPCUtil.getIsRPCDateForRiskTypeAndIsFarmPackage(jurisdiction.code, rateAsOfDate, hOPolicyTypes, rpcIdentifier);
        }
        return result;
    },
    validateRPCDateForVehTypeAndBusSegment: (jurisdiction, rateAsOfDate, vehicleType, businessSegment, rpcIdentifier) => {
        return jurisdiction
            && rateAsOfDate
            && vehicleType
            && businessSegment
            && rpcIdentifier
            && WMICRPCUtil.getIsRPCEffectiveForVehTypeAndBusSegment(jurisdiction.code, rateAsOfDate, vehicleType.code, businessSegment.code, rpcIdentifier);
    },
    validateAllRPCDateForVehTypeAndBusSegment: (jurisdiction, rateAsOfDate, vehicleType, businessSegment, rpcIdentifiers) => {
        return jurisdiction
            && rateAsOfDate
            && vehicleType
            && businessSegment
            && rpcIdentifiers
            && WMICRPCUtil.getAllRPCEffectiveForVehTypeAndBusSegment(jurisdiction.code, rateAsOfDate, vehicleType.code, businessSegment.code, rpcIdentifiers);
    },
    validateAnyRPCDateForVehTypeAndBusSegment: (jurisdiction, rateAsOfDate, vehicleType, businessSegment, rpcIdentifiers) => {
        return jurisdiction
            && rateAsOfDate
            && vehicleType
            && businessSegment
            && rpcIdentifiers
            && WMICRPCUtil.getAnyRPCEffectiveForVehTypeAndBusSegment(jurisdiction.code, rateAsOfDate, vehicleType.code, businessSegment.code, rpcIdentifiers);
    },
    /*
    * This method is used by the Portal Required Annotation to allow for the field to be required in the portal
    * but not required when the DTO validation is run.   This method will always return true but the corresponding
    * method in PC will always return false.
    */
    isRequiredInPortal: () => {
        return true;
    },
    addYears: (date, yearsToAdd) => {
        date.setFullYear(date.getFullYear() + yearsToAdd);
        return date;
    },
    addDays: (date, daysToAdd) => {
        date.setDate(date.getDate() + daysToAdd);
        return date;
    }
};
