import React, { useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { Chevron, Icon } from '@jutro/components';
import { WMICHeading } from 'wmic-pe-components-platform-react';

import styles from './WMICAccordionCard.module.scss';

const WMICAccordionCardHeader = (props) => {
    const {
        isOpen,
        chevron,
        chevronAlignment,
        errorState,
        title,
        isCollapsible,
        headerVariant,
        headerSize
    } = props;

    const className = styles.header;
    const translator = useContext(TranslatorContext);

    const chevronNode = chevron && isCollapsible && (
        <Chevron className={styles.chevron} isOpen={isOpen} />
    );

    return (
        <React.Fragment>
            {chevronAlignment === 'left' && chevronNode}
            <span className={className}>
                <WMICHeading
                    title={translator(title)}
                    variant={headerVariant}
                    size={headerSize}
                    className='gw-mb-0'
                />
            </span>
            {errorState && (
                <Icon
                    className={styles.errorIcon}
                    icon="gw-error-outline"
                    size="large"
                />
            )}
            {chevronAlignment === 'right' && chevronNode}
        </React.Fragment>
    );
};

export default WMICAccordionCardHeader;
