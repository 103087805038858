import React, {
    useCallback,
    useContext,
    useState,
    useEffect
} from 'react';
import _ from 'lodash';

import { TranslatorContext } from '@jutro/locale';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelServiceContext, ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';

import { WMICScrollToError } from 'wmic-pe-components-platform-react';
import { UISupportingInfoLookupService } from 'wmic-pe-capability-supportinginfo';
import { useWizardModals, useAccordionValidation, useDocumentTitle, WMICWizardSubmissionPage } from 'wmic-pe-portals-wizard-components-ui';
import { WMICInsuranceHistoryPoliciesComponent } from 'wmic-pe-capability-gateway-common-react';
import { WMICValidationUtil, QUOTE_STATUS, WMICVariousUtil, FlowStepId, WMICLogger } from 'wmic-pe-portals-utils-js';
import WMICQuoteUtil from 'wmic-pe-capability-quoteandbind-common-react/util/WMICQuoteUtil';

import { messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import metadata from './WMICCPInsuranceHistoryPage.metadata.json5';
import messages from './WMICCPInsuranceHistoryPage.messages';

const INSURANCE_HISTORY_PATH = 'lobData.commercialProperty.coverables.insuranceHistory';
const INSURANCE_HISTORY_VALUE_PATH = `${INSURANCE_HISTORY_PATH}.value`;

function WMICCPInsuranceHistoryPage(props) {
    const translator = useContext(TranslatorContext);
    const { wizardData: submissionVM, updateWizardData } = props;
    const { isComponentValid, onValidate, initialValidation, registerInitialComponentValidation } = useValidation('CPInsuranceHistoryPage');
    const { onValidateAccordion, isAccordionValid } = useAccordionValidation(onValidate);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const { refreshData } = useDataRefresh();
    const { setWizardLoading } = useWizardModals();
    const [scrollToError, setScrollToError] = useState(false); // we toggle this when we want to scroll to the first error on the page
    const [showErrors, setShowErrors] = useState(false);
    const [carriersList, setCarrierList] = useState(false);
    const [insuranceHistoryVM, setInsuranceHistoryVM] = useState();
    const [loading, setLoading] = useState(true);
    const baseData = _.get(submissionVM, 'baseData');

    useDocumentTitle(translator(messages.title), submissionVM);

    const underwritingIssues = _.get(submissionVM, 'errorsAndWarnings.underwritingIssues.value', []);
    const quoteStatus = _.get(submissionVM, 'baseData.periodStatus.value.code');;

    const validateInitialComponentValidation = useCallback(() =>
        (quoteStatus === QUOTE_STATUS.QUOTED || (quoteStatus === QUOTE_STATUS.DRAFT && WMICQuoteUtil.isQuoteBlockingUWIssuePresent(underwritingIssues)))
        && WMICValidationUtil.validatePageEntities(submissionVM, [`${INSURANCE_HISTORY_PATH}.anyUninsuredConcern`, `${INSURANCE_HISTORY_PATH}.causeForUninsured`])
    , []);

    useEffect(() => {
        registerInitialComponentValidation(validateInitialComponentValidation);
    }, [registerInitialComponentValidation, validateInitialComponentValidation]);

    useEffect(() => {
        submissionVM.value.flowStepId_WMIC = FlowStepId.CP_INSURANCE_HISTORY;
    }, [submissionVM.value]);

    useEffect(() => {
        initInsuranceHistory();
        // Keeping this lookup here to reduce network payload size for performance
        UISupportingInfoLookupService.retrieveInsuranceCarriersList([baseData.baseState.value.code, baseData.periodStartDate.value], true, authHeader)
        .then((carriers) => {
            const updatedCarriers = carriers.map((item) => ({
                ...item,
                name: item.carrier,
                code: item.carrier,
            }))

            setCarrierList(updatedCarriers);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initInsuranceHistory = () => {
        const createPriorPolicyInfoVM = () => viewModelService.create(
            {
                insuranceHistoryRecords: [],
                wawanesaPolicies: []
            },
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.common.draft.dto.insurancehistory.PriorPolicyInfo_WMICDTO',
            submissionVM.aspects.context()
        );

        let newInsuranceHistoryVM = _.get(submissionVM, INSURANCE_HISTORY_PATH);
        const priorPolicyInfo = _.get(newInsuranceHistoryVM, 'priorPolicyInfo');

        if (!newInsuranceHistoryVM.value) {
            newInsuranceHistoryVM = viewModelService.create(
                {
                    publicID: undefined,
                    anyUninsuredConcern: undefined,
                    causeForUninsured: undefined,
                    priorPolicyInfo: undefined
                },
                'pc',
                'wmic.edge.ca.capabilities.policyjob.lob.commercialproperty.coverables.dto.CIPInsuranceHistory_WMICDTO',
                submissionVM.aspects.context()
            );

            if (!priorPolicyInfo?.value) {
                const priorPolicyInfoVM = createPriorPolicyInfoVM();
                _.set(newInsuranceHistoryVM, 'priorPolicyInfo', priorPolicyInfoVM.value);
            }
        }

        const currentInsuranceHistory = _.get(submissionVM, INSURANCE_HISTORY_PATH);
        if (!_.isEqual(currentInsuranceHistory, newInsuranceHistoryVM)) {
            _.set(submissionVM, INSURANCE_HISTORY_PATH, newInsuranceHistoryVM);
            updateWizardData(submissionVM);
        }

        setLoading(false);
        updateInsuranceHistoryVM(newInsuranceHistoryVM);
    };

    const onNext = useCallback(async () => {
        try {

            if (!isComponentValid) {
                setShowErrors(true);
                setScrollToError(Date.now());
                return false;
            }

            setWizardLoading(true, translator(commonMessages.quoting));
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.unset(newSubmissionVM.value, 'bindData');

            submissionVM.value = await LoadSaveService.saveAndQuoteSubmission(newSubmissionVM.value, authHeader)
            updateWizardData(submissionVM);

            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.CP_INSURANCE_HISTORY)) {
                WMICVariousUtil.scrollToTop();
                return false;
            }

            return submissionVM;
        } finally {
            setShowErrors(true);
            setWizardLoading(false);
        }

    }, [LoadSaveService, authHeader, isComponentValid, setWizardLoading, submissionVM, viewModelService, translator, updateWizardData]);

    const updateInsuranceHistoryVM = (historyVM) => {
        refreshData();
        setInsuranceHistoryVM(historyVM);

        const currentInsuranceHistory = _.get(submissionVM, INSURANCE_HISTORY_VALUE_PATH);

        if (!_.isEqual(currentInsuranceHistory, historyVM.value)) {
            _.set(
                submissionVM,
                INSURANCE_HISTORY_VALUE_PATH,
                historyVM.value
            );
            updateWizardData(submissionVM);
        }
    }

    const onSaveInsuranceHistory = useCallback(async () => {

        if (!isComponentValid) {
            setShowErrors(true);
            return false;
        }

        try {
            setShowErrors(false);
            setWizardLoading(true, translator(messages.savingInsuranceHistory));

            submissionVM.value = await LoadSaveService.saveAndValidate_WMIC(
                submissionVM.value,
                authHeader
            );

            initInsuranceHistory();
            updateWizardData(submissionVM);
            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.CP_INSURANCE_HISTORY)) {
                WMICVariousUtil.scrollToTop();
            }
        } catch (err) {
            WMICLogger.error('WMICCPInsuranceHistoryPage onSaveInsuranceHistory ', err);
        } finally {
            setWizardLoading(false);
        }

    }, [submissionVM, translator, isComponentValid]);

    // props for the accordion components
    const commonAccordionContentProps = {
        showErrors,
        onValidate: onValidateAccordion,
        jobVM: submissionVM,
        updateWizardData,
        authHeader,
        baseData,
        updateHistory: updateInsuranceHistoryVM,
        insuranceHistoryVM,
    };

    const overrideProps = {
        '@field': {
            parentNode: insuranceHistoryVM
        },
        insuranceHistoryContainerButtons: {
            onSave: onSaveInsuranceHistory,
            saveLabel: translator(messages.saveInsuranceHistory)
        },
        policiesAccordion: {
            ...commonAccordionContentProps,
            isValid: isAccordionValid('policiesAccordionContent')
        },
        policiesAccordionContent: {
            ...commonAccordionContentProps,
            carriersList
        }
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            WMICInsuranceHistoryPoliciesComponent,
        }
    };

    if (loading) {
        return null;
    }

    return (
        <WMICWizardSubmissionPage
            onNext={onNext}
            skipWhen={initialValidation}
            registerInitialComponentValidation={initialValidation}
            cancelLabel={commonMessages.saveAndExit}
            disableNext={!isComponentValid}
            nextLabel={translator(commonMessages.quote)}
        >
            <WMICScrollToError counter={scrollToError}/>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={insuranceHistoryVM}
                overrideProps={overrideProps}
                onModelChange={updateInsuranceHistoryVM}
                onValidationChange={onValidate}
                componentMap={resolvers.resolveComponentMap}
            />
        </WMICWizardSubmissionPage>
    );
}

WMICCPInsuranceHistoryPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    ...wizardProps
};


export default WMICCPInsuranceHistoryPage;
