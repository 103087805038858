import React, { useEffect, useState, useCallback } from 'react';
import { Loader, ToastProvider, useModal } from '@jutro/components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { MetadataContent } from '@jutro/uiconfig';
import { useTranslator } from '@jutro/locale';
import { UserService, ActivitiesService } from 'gw-capability-gateway';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import AddActivityPopUp from './AddActivityPopUp';
import ActivityTableComponent from '../Activity/ActivityTableComponent/ActivityTableComponent';
import metadata from './OpenActivitiesComponent.metadata.json5';
import styles from './OpenActivitiesComponent.module.scss';
import messages from './OpenActivitiesComponent.messages';
import gatewayMessages from '../../gateway.messages';

const getNoOfDays = (data) => {
    const currentDate = new Date();
    const eDate = new Date(data.dueDate);
    const noOfDays = currentDate - eDate;
    const noOfDueDay = noOfDays / (1000 * 3600 * 24);
    return Math.floor(noOfDueDay);
};

const activitiesOverdueData = (data) => data.filter((activitiesInfo) => {
    return activitiesInfo.status !== 'complete' && getNoOfDays(activitiesInfo) > 0;
});

const activitiesThisWeekData = (data, status) => data.filter((activitiesInfo) => {
    const today = moment();
    const tomorrow = moment().add(1, 'days');
    return (
        !moment(activitiesInfo.dueDate).isSame(tomorrow, 'd')
        && !moment(activitiesInfo.dueDate).isSame(today, 'd')
        && activitiesInfo.status === status
        && (getNoOfDays(activitiesInfo) > 0
            ? false
            : getNoOfDays(activitiesInfo) * -1
            < moment(activitiesInfo.dueDate).isoWeekday())
    );
});

const activitiesTodayData = (data, status) => data.filter((activitiesInfo) => {
    const today = moment();
    return (
        activitiesInfo.dueDate !== undefined
        && activitiesInfo.status === status
        && moment(activitiesInfo.dueDate).isSame(today, 'd')
    );
});

const activitiesTomorrowData = (data, status) => data.filter((activitiesInfo) => {
    const tomorrow = moment().add(1, 'days');
    return (
        activitiesInfo.status === status
        && moment(activitiesInfo.dueDate).isSame(tomorrow, 'd')
    );
});

const activitiesFutureData = (data, status) => data.filter((activitiesInfo) => {
    return (
        activitiesInfo.status === status
        && !moment(activitiesInfo.dueDate).isBefore(moment().endOf('isoWeek'), 'd')
    );
});

const getActivitiesOverdue = (activityList) => {
    const today = moment();
    return activityList.filter((activity) => {
        return activity.status !== 'complete' && (_.isUndefined(activity.dueDate) || today.isAfter(activity.dueDate, 'day'));
    });
};

const filterActivitiesByValue = (activityArrayResult, filterValue) => {
    const lowerCaseFilterValue = filterValue.toLocaleLowerCase();
    return _.filter(activityArrayResult, (res) => {
        return Object.keys(res).some(
            (key) => typeof res[key] === 'string'
                && res[key].toLocaleLowerCase().includes(lowerCaseFilterValue)
        );
    });
};

const filterActivitiesByStatus = (filterValue, statusMap, openActivityData) => {
    let activityList = [];

    switch (filterValue) {
        case statusMap.allOpen:
            activityList = openActivityData.filter((act) => act.status === 'open');
            break;
        case statusMap.allClose:
            activityList = openActivityData.filter(
                (activitiesInfo) => {
                    return activitiesInfo.status === 'complete';
                }
            );
            break;
        case statusMap.overDueOnly:
            activityList = activitiesOverdueData(openActivityData);
            break;
        case statusMap.openAssignedToMe:
            activityList = openActivityData.filter((act) => act.status === 'open'
                && act.isAssignedToCurrentUser);
            break;
        default:
            break;
    }

    return activityList;
};

const splitActivitiesForTable = (activityList) => {
    return {
        activitiesComplete: activityList.filter(
            (activitiesInfo) => {
                return activitiesInfo.status === 'complete';
            }
        ),
        activitiesFuture: activitiesFutureData(activityList, 'open'),
        activitiesOverdue: getActivitiesOverdue(activityList),
        activitiesThisWeek: activitiesThisWeekData(
            activityList,
            'open'
        ),
        activitiesToday: activitiesTodayData(activityList, 'open'),
        activitiesTomorrow: activitiesTomorrowData(
            activityList,
            'open'
        )
    };
};

function OpenActivitiesComponent(props) {
    const modalApi = useModal();
    const {
        authHeader,
        getActivities,
        activityEntityId,
        activityEntity,
        createNewActivity,
        getPolicyDetails,
        getActivitiesTileUpdatedCount,
        viewModelService
    } = props;
    const translator = useTranslator();
    const [openActivityResponse, updateOpenActivityResponse] = useState([]);
    const [activityList, setActivityList] = useState([]);
    const [activityFilter, setActivityFilter] = useState({
        statusFilter: '',
        searchFilter: ''
    });
    const [displayNoActivities, updateDisplayNoActivities] = useState(false);
    const [activityOptions, updateActivityOptions] = useState();
    const [showActivityButton, updateShowActivityButton] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [publicId, setPublicId] = useState('');

    const getActivityOptions = useCallback(
        () => {
            const openActivityOptionsArray = [
                translator(messages.allOpen),
                translator(messages.allCompleted),
                translator(messages.overdue),
                translator(messages.openAssignedToMe)
            ];
            return openActivityOptionsArray.map((key) => {
                return {
                    code: key,
                    name: key
                };
            });
        },
        [translator]
    );

    const getActivityDataTable = useCallback(
        (activityResponseData, filter) => {
            let updatedActivityList = activityResponseData;
            const statusMap = {
                allOpen: translator(messages.allOpen),
                overDueOnly: translator(messages.overdue),
                allClose: translator(messages.allCompleted),
                openAssignedToMe: translator(messages.openAssignedToMe)
            };

            if (_.toString(filter.statusFilter) !== '') {
                updatedActivityList = filterActivitiesByStatus(
                    filter.statusFilter, statusMap, updatedActivityList
                );
            }

            if (_.toString(filter.searchFilter) !== '') {
                updatedActivityList = filterActivitiesByValue(
                    updatedActivityList, filter.searchFilter
                );
            }

            setActivityList(_.sortBy(updatedActivityList, 'dueDate'));
            setActivityFilter(filter);
            if (!updatedActivityList.length) {
                updateDisplayNoActivities(true);
            } else {
                updateDisplayNoActivities(false);
            }
        },
        [translator]
    );

    const responseData = useCallback(
        async () => {
            setIsLoading(true);
            const filter = _.cloneDeep(activityFilter);
            filter.statusFilter = translator(messages.allOpen);

            const response = await getActivities();
            const activities = _.map(response, (activity) => {
                return _.extend({}, activity, { expanded: false });
            });
            getActivityDataTable(activities, filter);
            updateOpenActivityResponse(activities);
            updateActivityOptions(getActivityOptions(activities));
            return true;
        },
        [activityFilter, getActivities, getActivityDataTable, getActivityOptions, translator]
    );

    const shouldShowAddActivityButton = useCallback(
        () => {
            const permissionDTO = {
                permission: 'actcreate'
            };
            UserService.hasUserSystemPermission(permissionDTO, authHeader).then((showActivity) => {
                updateShowActivityButton(showActivity);
                setIsLoading(false);
            });
        },
        [authHeader]
    );

    useEffect(() => {
        responseData();
        shouldShowAddActivityButton();
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createNote = async (data, publicID) => {
        const activityNoteData = await ActivitiesService.createNoteForActivity(
            publicID,
            data,
            authHeader
        );
        if (!_.isUndefined(activityNoteData)) {
            modalApi.showAlert({
                title: gatewayMessages.noteCreated,
                message: gatewayMessages.noteCreatedSuccessfully,
                status: 'success',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            }).then(() => {
                const activityObj = openActivityResponse.find((obj) => obj.publicID === publicID);
                activityObj.notes.push(activityNoteData);
                getActivityDataTable(openActivityResponse, activityFilter);
            }, _.noop);
        }
    };

    const handleFilterChange = (value, id) => {
        const filter = _.cloneDeep(activityFilter);
        if (id === 'statusFilter') {
            filter.statusFilter = value;
            getActivityDataTable(openActivityResponse, filter);
        }
        if (id === 'searchFilter') {
            filter.searchFilter = value;
            getActivityDataTable(openActivityResponse, filter);
        }
    };

    const handleError = useCallback(
        (title, message) => {
            return modalApi.showAlert({
                title: title,
                message: message,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: messages.noTypeSelected
            }).catch(_.noop);
        },
        []
    );

    const addNewActivityAddClick = useCallback(
        async (model) => {
            let newActivityData = {
                ..._.omit(model.value, 'assignedTo'),
                selectedAssignee: _.get(model.value, 'assignedTo'),
                activityPattern: {
                    code: _.get(model.value, 'code'),
                    priority: _.get(model.value, 'priority')
                }
            };
            newActivityData = _.omit(newActivityData, 'jobType');
            switch (activityEntity) {
                case 'account':
                    newActivityData.accountNumber = activityEntityId;
                    break;
                case 'policy':
                    newActivityData.policyNumber = activityEntityId;
                    break;
                case 'job':
                    newActivityData.jobNumber = activityEntityId;
                    break;
                default:
                    break;
            }
            if (!_.get(model.value, 'jobType')) {
                handleError(messages.noActivityType, messages.noActivityTypeMessage);
            } else {
                const addResponse = await createNewActivity(newActivityData);
                if (!_.isEmpty(addResponse) && !_.isUndefined(addResponse)) {
                    ToastProvider.toast({
                        type: 'success',
                        message: translator(messages.activityCreated),
                        autoClose: true
                    });
                    if (getActivitiesTileUpdatedCount) {
                        getActivitiesTileUpdatedCount();
                    }
                }
                const updatedActivityResponseArray = [...openActivityResponse, addResponse];
                updateOpenActivityResponse(updatedActivityResponseArray);
                const filter = _.cloneDeep(activityFilter);
                filter.statusFilter = translator(messages.allOpen);
                getActivityDataTable(updatedActivityResponseArray, filter);
                updateActivityOptions(getActivityOptions(updatedActivityResponseArray));
                if (!_.isUndefined(getPolicyDetails)) {
                    getPolicyDetails();
                }
            }
        },
        [
            openActivityResponse,
            activityEntity,
            activityEntityId,
            activityFilter,
            createNewActivity,
            getActivitiesTileUpdatedCount,
            getActivityDataTable,
            getActivityOptions,
            getPolicyDetails,
            handleError,
            translator
        ]
    );

    const addNewActivityButtonClick = useCallback(
        () => {

            modalApi.showModal(
                <AddActivityPopUp
                    title={translator(messages.addActivity)}
                    actionBtnLabel={messages.createActivity}
                    cancelBtnLabel={messages.cancel}
                    activityEntity={activityEntity}
                    activityEntityId={activityEntityId}
                    viewModelService={viewModelService}
                    authHeader={authHeader}
                />
            )
                .then(addNewActivityAddClick)
                .catch(_.noop);
        },
        [openActivityResponse, activityEntity, activityEntityId,viewModelService,authHeader,translator]
    );

    const onClickComplete = useCallback(
        (data) => {
            const activityObj = openActivityResponse.find((obj) => obj.publicID === data);
            ActivitiesService.markActivityAsCompleted(data, authHeader).then((completeActivity) => {
                if (activityObj) {
                    activityObj.status = completeActivity.status;
                    activityObj.closeDate = completeActivity.closeDate;
                    activityObj.completedDate = completeActivity.completedDate;
                    activityObj.canComplete = false;
                }
                setPublicId(data);
            });
        },
        [authHeader, openActivityResponse]
    );

    const {
        activitiesOverdue,
        activitiesComplete,
        activitiesFuture,
        activitiesThisWeek,
        activitiesToday,
        activitiesTomorrow
    } = splitActivitiesForTable(activityList);

    const overrideProps = {
        statusFilter: {
            availableValues: activityOptions,
            value: activityFilter.statusFilter,
        },
        searchFilter: {
            value: activityFilter.searchFilter,
        },
        noActivityFound: {
            visible: displayNoActivities !== false
        },
        noActivitySeparator: {
            visible: displayNoActivities !== false
        },
        activitiesDetailGrid: {
            visible: displayNoActivities === false
        },
        addActivityButton: {
            visible: showActivityButton
        },
        activitiesTableContainer: {
            activitiesOverdue,
            activitiesToday,
            activitiesTomorrow,
            activitiesThisWeek,
            activitiesFuture,
            activitiesComplete,
            displayNoActivities,
            createNote,
            onClickComplete,
            publicId
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            activitytablecomponent: ActivityTableComponent,
        },
        resolveCallbackMap: {
            handleStatusValueChange: (value) => handleFilterChange(value, 'statusFilter'),
            handleSearchValueChange: (value) => handleFilterChange(value, 'searchFilter'),
            addNewActivityClick: addNewActivityButtonClick,
        }
    };

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    return (
        <MetadataContent
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

OpenActivitiesComponent.propTypes = {
    authHeader: PropTypes.shape({}).isRequired,
    getActivities: PropTypes.func.isRequired,
    activityEntityId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]).isRequired,
    activityEntity: PropTypes.string.isRequired,
    createNewActivity: PropTypes.func.isRequired,
    getPolicyDetails: PropTypes.func,
    getActivitiesTileUpdatedCount: PropTypes.func
};

OpenActivitiesComponent.defaultProps = {
    getActivitiesTileUpdatedCount: undefined,
    getPolicyDetails: undefined
};

export default withAuthenticationContext(withViewModelService(OpenActivitiesComponent));
