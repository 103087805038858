import { defineMessages } from 'react-intl';

export default defineMessages({
    noDataText: {
        "id": "submission.agent.views.policy-landing.policy-search.No results found",
        "defaultMessage": "No results found."
    },
    loadingText: {
        "id": "submission.agent.views.policy-landing.policy-search.Loading...",
        "defaultMessage": "Loading..."
    },
    automobileFilter: {
        id: 'wmic.capabilities-react.gw-capabilities-gateway-react.LandingPage.Filter.Personal Auto',
        defaultMessage: 'Automobile',
    },
    personalPropertyFilter: {
        id: 'wmic.capabilities-react.gw-capabilities-gateway-react.LandingPage.Filter.Home Owners',
        defaultMessage: 'Personal Property',
    },
    personalUmbrellaFilter: {
        id: 'wmic.capabilities-react.gw-capabilities-gateway-react.LandingPage.Filter.PersonalUmbrella',
        defaultMessage: 'Personal Umbrella',
    },
    commercialSmallBusinessFilter: {
        id: 'wmic.capabilities-react.gw-capabilities-gateway-react.LandingPage.Filter.CommercialSmallBusiness',
        defaultMessage: "Commercial Lines Small Business",
    },
});
