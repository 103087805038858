import { defineMessages } from 'react-intl';

export default defineMessages({
    name: {
        id: 'quoteandbind.gl.views.additionalcoverages.name',
        defaultMessage: "Name"
    },
    interestType: {
        id: 'quoteandbind.gl.views.additionalcoverages.interestType',
        defaultMessage: "Interest Type"
    },
});
