import { JsonRPCService } from '@xengage/gw-portals-transport-js';
import { WMICLogger } from 'wmic-pe-portals-utils-js';

export default class WMICJsonRPCService {
    static send(url, method, data, additionalHeaders={}) {
        const endPointInfoForLogging = `url=${url}, method=${method}, data=${JSON.stringify(data)}`;
        WMICLogger.debug(`${endPointInfoForLogging} request=sent`);
        return JsonRPCService.send(url, method, data, additionalHeaders).then(
            (response) => {
                WMICLogger.debug(`${endPointInfoForLogging} request=received`);
                return response;
            },
            (error) => {
                const sanitizedError = WMICLogger.sanitizeError(error);
                WMICLogger.error(`url=${url}, method=${method}, data=${JSON.stringify(WMICLogger.sanitizeData(data))} request=error, error=${JSON.stringify(sanitizedError)}`, sanitizedError);
                throw error;
            }
        );
    }
}