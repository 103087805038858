import { defineMessages } from 'react-intl';

export default defineMessages({
    PaymentError: {
        id: 'quoteandbind.error.payment.Payment Details Failure',
        defaultMessage: 'Payment Details Failure'
    },
    AuthorizationError: {
        id: 'quoteandbind.error.payment.Authorization Failure',
        defaultMessage: 'Authorization Failure'
    },
    DayTransitionError: {
        id: 'quoteandbind.error.payment.We have updated your policy effective date',
        defaultMessage: "We've updated your policy effective date"
    }
});