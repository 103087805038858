import { defineMessages } from 'react-intl';

export default defineMessages({
    wmicPaInsuranceHistoryTitle: {
        id: 'gw.capability-gateway-quoteandbind-ho-react.pages.WMICHOScheduleItemsPage.Scheduled Items',
        defaultMessage: 'Scheduled Items'
    },
    wmicHoScheduledItemsOptional: {
        id: 'quoteandbind.ho.views.ho-scheduled-item.Scheduled Items are optional',
        defaultMessage: 'Scheduled Items are optional'
    },
    scheduledItemType: {
        id: "quoteandbind.ho.views.ho-scheduled-item.Item Type",
        defaultMessage: "Type"
    },
    scheduledItemDescription: {
        id: "quoteandbind.ho.views.ho-scheduled-item.Item Description",
        defaultMessage: "Description"
    },
    scheduledItemLimit: {
        id: "quoteandbind.ho.views.ho-scheduled-item.Item Limit",
        defaultMessage: "Limit"
    },
    removeScheduledItemLong: {
        id: "quoteandbind.ho.views.ho-scheduled-item.Remove Item Long",
        defaultMessage: "Are you sure you want to remove the item from the list?"
    },
    removeScheduledItemShort: {
        id: "quoteandbind.ho.views.ho-scheduled-item.Remove Item Short",
        defaultMessage: "Remove Item"
    },
    addScheduledItem: {
        id: "quoteandbind.ho.views.ho-scheduled-item.Add Item",
        defaultMessage: "Add Item"
    }
});
