import { defineMessages } from 'react-intl';

export default defineMessages({
    changeSummaryTitle: {
        id: 'wmic-pe-capability-gateway-policyrenewal-common-react.WMICRenewalChangeSummaryPage.Change Summary',
        defaultMessage: 'Change Summary'
    },
    noChange: {
        id: 'wmic-pe-capability-gateway-policyrenewal-common-react.WMICRenewalChangeSummaryPage.NoChange',
        defaultMessage: 'No details on file to display'
    }
});
