import { defineMessages } from 'react-intl';

export default defineMessages({
    motorType: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOMotorInfoDetails.outboardmotor.Motor Type',
        defaultMessage: 'Motor Type'
    },
    motorDescription: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOMotorInfoDetails.Describe',
        defaultMessage: 'Description'
    },
    motorFuel: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOMotorInfoDetails.outboardmotor.Fuel',
        defaultMessage: 'Fuel'
    },
    motorHorsepower: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOMotorInfoDetails.outboardmotor.Horsepower',
        defaultMessage: 'Horsepower',
    },
    motorValue: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHOMotorInfoDetails.Value',
        defaultMessage: 'Value',
    }
})