import { defineMessages } from 'react-intl';

export default defineMessages({
    incidentType: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPLegalHistoryComponent.incidentType',
        defaultMessage: 'Incident Type'
    },
    incidentDescription: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPLegalHistoryComponent.incidentDescription',
        defaultMessage: 'Description'
    },
    incidentDateOccured: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPLegalHistoryComponent.incidentDateOccured',
        defaultMessage: 'Date Occurred'
    },
    incidentEndDate: {
        id: 'wmic-pe-capability-gateway-common-pup-react.WMICPUPLegalHistoryComponent.incidentEndDate',
        defaultMessage: 'End Date'
    },
    incidentHeading: {
        id: "wmic-pe-capability-gateway-common-pup-react.WMICPUPLegalHistoryComponent.incidentHeading",
        defaultMessage: "Incident"
    }
});