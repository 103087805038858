import React, { useCallback, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon, IconButton } from '@jutro/components';
import { ErrorLevel } from 'wmic-pe-portals-edge-validation-js';
import { withTracking } from '@xengage/gw-portals-tracking-react';
import { WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import singleErrorStyles from './WMICWizardSingleErrorComponent.module.scss';

function WMICWizardSingleErrorComponent(props) {
    const wizardContext = useContext(WizardContext);
    const { acknowledgeError, jumpTo } = wizardContext;
    const { issuesList, customValidationMessages = [] } = props;
    const { wizardLoading } = useWizardModals();

    const hideWarnings = useCallback(
        (index) => {
            const selectedError = issuesList[index];
            acknowledgeError(selectedError.id);
        },
        [acknowledgeError, issuesList]
    );

    const jumpToErroredPage = (index) => {
        if (index !== -1 && index < wizardContext.currentStepIndex) {
            jumpTo(index)
        }
    }

    const issuesSection = issuesList.concat(customValidationMessages).map((issue, index) => {
        // This is workaround to clear the Jutro validation state when it changes from warning to error type
        const issueDescription = issue?.description?.replace(/-WMIC-\[\w*\]$/,'');
        const issueType = issue.level === ErrorLevel.LEVEL_ERROR ? 'gwIssuesError' : 'gwIssuesWarning';
        const issueIcon = issue.level === ErrorLevel.LEVEL_ERROR ? 'mi-warning' : 'mi-error';
        const issueTypeClass = classNames(
            singleErrorStyles.gwIssuesMessage,
            singleErrorStyles[issueType]
        );
        const closeIcon = issue.level === ErrorLevel.LEVEL_WARN ? (
            <IconButton
                icon="mi-close"
                onClick={() => hideWarnings(index)}
            />
        ) : null;

        const issueValidationStep = issue?.validationStep;
        const wizardSteps = wizardContext.steps;

        const stepWithErrorIndex = wizardSteps.findIndex((wizardStep) => {
            const pasFlowStepId = wizardStep?.stepToFieldMapping?.pasFlowStepId;
            if (pasFlowStepId) {
                if (_.isArray(pasFlowStepId)) {
                    return pasFlowStepId.indexOf(issueValidationStep) !== -1;
                }
                return pasFlowStepId === issueValidationStep;
            }
            return false;
        });

        return (
            <div className={singleErrorStyles.gwIssuesContainer} key={issueDescription} onClick={() => jumpToErroredPage(stepWithErrorIndex)}>
                <div className={issueTypeClass}>
                    <div className={singleErrorStyles.gwIssuesMessageContainer}>
                        <Icon icon={issueIcon} className={singleErrorStyles[`wmicIcon${issueType}`]} />
                        <span>{issueDescription}</span>
                    </div>
                    {closeIcon}
                </div>
            </div>
        );
    });

    return !wizardLoading && <div>{issuesSection}</div>;
}

WMICWizardSingleErrorComponent.propTypes = {
    acknowledgeError: PropTypes.func.isRequired,
    issuesList: PropTypes.shape([])
};
WMICWizardSingleErrorComponent.defaultProps = {
    issuesList: []
};
export default withTracking(WMICWizardSingleErrorComponent);
