/* eslint-disable no-secrets/no-secrets */

import React from 'react';
import _ from 'lodash';
import WMICMessageModal from 'gw-capability-quoteandbind-common-react/components/WMICMessageModal/WMICMessageModal';

import { LOBConstants, WMICRichTextUtil, WMICLogger } from 'wmic-pe-portals-utils-js';
import messages from './WMICPAValidationWarningUtil.messages';

function getNoHitPAInsuredsString(jobVM) {
    let noHitInsureds = '';

    if (_.get(jobVM, 'lob.value.code') === LOBConstants.PA_LINE) {
        if (_.get(jobVM, 'baseData.isNoHitCreditReportResponse.value')) {
            const pniFirstName = _.get(jobVM, 'baseData.primaryNamedInsured_WMIC.firstName.value');
            const pniLastName = _.get(jobVM, 'baseData.primaryNamedInsured_WMIC.lastName.value');
            noHitInsureds = `${noHitInsureds}&lt;li&gt;&lt;b&gt; ${pniFirstName} ${pniLastName} &lt;/b&gt;&lt;/li&gt;`;
        }

        const additionalNamedInsureds = _.get(jobVM, 'baseData.additionalNamedInsureds_WMIC', []);

        additionalNamedInsureds.forEach((ani) => {
            if (_.get(ani, 'isNoHitCreditReportResponse.value')) {
                const aniFirstName = _.get(ani, 'firstName.value');
                const aniLastName = _.get(ani, 'lastName.value');
                noHitInsureds = `${noHitInsureds}&lt;li&gt;&lt;b&gt; ${aniFirstName} ${aniLastName} &lt;/b&gt;&lt;/li&gt;`;
            }
        });
    }

    return noHitInsureds;
}

function showPAValidationWarningMessage(jobVM, translator, showCustom) {
    WMICLogger.info(`Credit Check No Hit Warning is displayed. \
    Transaction Type: ${_.get(jobVM, 'baseData.jobType.value.code')}; \
    JobID: ${_.get(jobVM, 'jobID.value')}; \
    BaseState: ${_.get(jobVM, 'baseData.baseState.value.code')}`);

    showCustom(
        <WMICMessageModal
            title={messages.creditCheckUnsuccessful}
            message={WMICRichTextUtil.translateRichText(translator(messages.verifyDateOfBirthAndHomeAddress, {insureds: getNoHitPAInsuredsString(jobVM)}))}
            iconClass="fa fa-fw fa-exclamation-triangle"
            actionBtnLabel={messages.close}
        />
    )
}

function isPAJobReviewRequired(jobVM) {
    return _.get(jobVM, 'lob.value.code') === LOBConstants.PA_LINE &&
        !_.get(jobVM, 'baseData.validateNoHitCreditReportResponseAcknowledged.value', true) &&
        getNoHitPAInsuredsString(jobVM).length > 0;
}

export default {
    checkPACreditScoreValidation: (jobVM, translator, showCustom) => {
        const recalculatedValidateHitCC = !_.get(jobVM, 'baseData.validateNoHitCreditReportResponseAcknowledged.value', true) && isPAJobReviewRequired(jobVM);
        if (recalculatedValidateHitCC) {
            showPAValidationWarningMessage(jobVM, translator, showCustom);
        }
        return recalculatedValidateHitCC;
    }
};
