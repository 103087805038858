import {defineMessages} from 'react-intl';

export default defineMessages({
    newSubmission: {
        id: 'wmic.agent.views.new-quote-account-search.new-submission-title',
        defaultMessage: 'New Submission',
    },
    createNewAccount: {
        id: 'wmic.agent.views.new-quote-account-search.new-submission-search-title',
        defaultMessage: 'Search or Create an Account',
    },
    toCreate: {
        id: 'wmic.agent.views.new-quote-account-search.new-submission-search-subtitle',
        defaultMessage: 'To search or create an account, start by filling in the information below.',
    },
    cancelBtn: {
        id: 'wmic.gateway.views.new-submission.cancel-button',
        defaultMessage: 'Cancel',
    },
    cancelSubmission: {
        id: 'wmic.gateway.views.new-submission.cancel-button-question',
        defaultMessage: 'Cancel Submission?',
    },
    cancelMessage: {
        id: 'wmic.gateway.views.new-submission.cancel-message',
        defaultMessage: 'Are you sure you want to cancel the new submission?',
    },
    yes: {
        id: 'wmic.gateway.views.new-submission.yes',
        defaultMessage: 'Yes',
    },
    no: {
        id: 'wmic.gateway.views.new-submission.no',
        defaultMessage: 'No',
    },
    accountNumber: {
        id: 'wmic.agent.views.new-quote-account-search.account-number',
        defaultMessage: 'Account number',
    },
    phoneNumber: {
        id: 'wmic.agent.views.new-quote-account-search.phone-number',
        defaultMessage: 'Phone number',
    },
    typeOfAccount: {
        id: 'wmic.agent.views.new-quote-account-search.type-account',
        defaultMessage: 'Type of account',
    },
    AccountAlreadyExists: {
        id: 'wmic.agent.views.new-quote-account-search.account-founds-title',
        defaultMessage: 'Existing Accounts',
    },
    AccountAlreadyInSystem: {
        id: 'wmic.agent.views.new-quote-account-search.account-founds-subtitle',
        defaultMessage: 'There may already be an account for this person in our system. Please see if any of the accounts below match your customer.',
    },
    AccountAlreadyInSystemCommercialPackage: {
        id: 'wmic.agent.views.new-quote-account-search.account-founds-subtitle-commercial-package',
        defaultMessage: 'There may already be an account for this customer in our system. Please see if any of the accounts below match your customer.',
    },
    Policies: {
        id: 'wmic.agent.views.new-quote-account-search.column-policies',
        defaultMessage: 'Policies',
    },
    Broker: {
        id: 'wmic.agent.views.new-quote-account-search.column-broker',
        defaultMessage: 'Broker',
    },
    searchResults: {
        id: 'wmic.agent.views.new-quote-account-search.search-result-text',
        defaultMessage: 'Results for "&lt;b&gt;{name}&lt;/b&gt;"'
    },
    createAccountButton: {
        id: 'wmic.agent.views.new-quote-account-search.create-account-button',
        defaultMessage: 'Search'
    },
    dropdownSelectPlaceHolder: {
        id: "jutro-components.widgets.inputs.DropdownSelectField.placeholder",
        defaultMessage: "Please Select"
    },
    cancelButton: {
        id: 'wmic.agent.views.new-quote-account-search.cancel-button',
        defaultMessage: 'Cancel'
    },
    companyNameCommercialLines: {
        id: 'wmic.agent.views.new-quote-account-search.CL.CompanyName',
        defaultMessage: 'Company Name'
    },
    phoneNumberCommercialLines: {
        id: 'wmic.agent.views.new-quote-account-search.CL.PhoneNumber',
        defaultMessage: 'Phone number'
    }
});
