import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { ReadOnlyCoverageTable } from 'wmic-pe-capability-quoteandbind-common-react';
import metadata from "./WMICLocationCoveragesTable.metadata.json5";
import messages from './WMICLocationCoveragesTable.messages';
import styles from './WMICLocationCoveragesTable.module.scss';

function WMICLocationCoveragesTable(props) {
    const { locationCoverages, isPolicyChangeOrRenewal, submissionVM } = props;
    const translator = useContext(TranslatorContext);

    const overrides = {
        '@field': {
            readOnly: true
        },
        locationStandardCoverages: {
            visible: !_.isEmpty(locationCoverages.businessIncomeCoverages),
            coverageList: locationCoverages.businessIncomeCoverages,
            isPolicyChangeOrRenewal,
            jobVM: submissionVM,
            coverageHeaderText: `${translator(messages.locationTitle, { displayName: locationCoverages.locationNum })}`
        },
    };

    const resolvers = {
        resolveComponentMap: {
            ReadOnlyCoverageTable
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            model={locationCoverages}
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICLocationCoveragesTable.propTypes = {
    value: PropTypes.shape({}).isRequired
};

export default WMICLocationCoveragesTable;
