import React from 'react';
import { Grid } from '@jutro/layout';
import cx from 'classnames';

import styles from './WMICEditForm.module.scss';
import FormButtons from '../WMICFormButtons/WMICFormButtons'

function WMICEditForm(props) {
    const { children: content, hideBorder, hideButtons } = props;

    return (
        <div className={cx(styles.wmicEditForm, props.className, {
            [styles.editFormBorder]: !hideBorder
        })}>
            {content}
            { !hideButtons && <FormButtons {...props} /> }
        </div>
    );
}

export default WMICEditForm;
