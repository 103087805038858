import { setComponentMapOverrides } from '@jutro/uiconfig';
import 'gw-capability-policychange-common-react';
import 'wmic-pe-capability-gateway-policyrenewal-common-react'
import { WMICPCPAInsuranceHistoryPage } from 'wmic-pe-capability-gateway-policychange-pa-react';
import WMICPRPAVehiclesPage from './pages/Vehicles/WMICPRPAVehiclesPage';
import WMICPAPolicyRenewalCoveragesPage from './pages/WMICPAPolicyRenewalCoverages/WMICPAPolicyRenewalCoveragesPage';
import WMICPAPolicyRenewalDriversDetailsPage from './pages/WMICPAPolicyRenewalDrivers/WMICPAPolicyRenewalDriversDetailsPage';
import WMICPAPolicyRenewalPolicyDetailsPage from './pages/PolicyDetails/WMICPAPolicyRenewalPolicyDetailsPage';
import { WMICPolicyRenewalQuote } from 'wmic-pe-capability-gateway-policyrenewal-common-react';
import WMICPARenewalInsuranceHistoryPage from './pages/InsuranceHistory/WMICPARenewalInsuranceHistoryPage';

setComponentMapOverrides(
    {
        WMICPAPolicyRenewalPolicyDetailsPage: { component: 'WMICPAPolicyRenewalPolicyDetailsPage' },
        WMICPAPolicyRenewalDriversDetailsPage: { component: 'WMICPAPolicyRenewalDriversDetailsPage' },
        WMICPRPAVehiclesPage: { component: 'WMICPRPAVehiclesPage' },
        WMICPAPolicyRenewalCoveragesPage: { component: 'WMICPAPolicyRenewalCoveragesPage' },
        WMICPCPAInsuranceHistoryPage: { component: 'WMICPCPAInsuranceHistoryPage' },
        WMICPARenewalInsuranceHistoryPage: { component: 'WMICPARenewalInsuranceHistoryPage' },
        WMICPolicyRenewalQuote: { component: 'WMICPolicyRenewalQuote'}
    },
    {
        WMICPAPolicyRenewalPolicyDetailsPage,
        WMICPAPolicyRenewalDriversDetailsPage,
        WMICPRPAVehiclesPage,
        WMICPAPolicyRenewalCoveragesPage,
        WMICPCPAInsuranceHistoryPage,
        WMICPARenewalInsuranceHistoryPage,
        WMICPolicyRenewalQuote
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PARenewalMessages } from './WMICPEPARenewalWizard.messages';
export { default as WMICPEPARenewalWizard } from './WMICPEPARenewalWizard';
