import { defineMessages } from 'react-intl';

export default defineMessages({
    currentPremium: {
        id: 'wmic-pe-capability-gateway-common-react.WMICIterableFlexRiskItem.Current Premium',
        defaultMessage: 'Current Premium'
    },
    eligiblePremium: {
        id: 'wmic-pe-capability-gateway-common-react.WMICIterableFlexRiskItem.Eligible Premium',
        defaultMessage: 'Eligible Flex Premium'
    },
    maxReduction: {
        id: 'wmic-pe-capability-gateway-common-react.WMICIterableFlexRiskItem.Max Reduction Allowed',
        defaultMessage: 'Maximum Flex Reduction Allowed'
    },
    flexReason: {
        id: 'wmic-pe-capability-gateway-common-react.WMICIterableFlexRiskItem.Flex reason',
        defaultMessage: 'Flex reason',
    },
    flexReasonDescription: {
        id: 'wmic-pe-capability-gateway-common-react.WMICIterableFlexRiskItem.Flex reason description',
        defaultMessage: 'Flex reason description',
    },
    inEligibleFlexPremium: {
        id: 'wmic-pe-capability-gateway-common-react.WMICIterableFlexRiskItem.ineligible for the flex program',
        defaultMessage: 'Ineligible for the flex program',
    },
    flexNotApplicable: {
        id: 'wmic-pe-capability-gateway-common-react.WMICIterableFlexRiskItem.Not applicable',
        defaultMessage: 'Not applicable',
    },
    flexErrorsMessageYes: {
        id: 'wmic-pe-capability-gateway-common-react.WMICIterableFlexRiskItem.flexErrorsMessage',
        defaultMessage: '{riskType} is ineligible for the flex program based on:',
    },
    flexErrorsMessageNo: {
        id: 'wmic-pe-capability-gateway-common-react.WMICIterableFlexRiskItem.flexErrorsMessageNo',
        defaultMessage: '{riskType} is ineligible for the flex program.',
    },
    estimatedTargetedPremium: {
        id: 'wmic-pe-capability-gateway-common-react.WMICIterableFlexRiskItem.Estimated target premium',
        defaultMessage: 'Estimated target premium amount',
    },
    targetDollarDifference: {
        id: 'wmic-pe-capability-gateway-common-react.WMICIterableFlexRiskItem.Target dollar difference',
        defaultMessage: 'Target dollar difference',
    },
    targetPercentageDifference: {
        id: 'wmic-pe-capability-gateway-common-react.WMICIterableFlexRiskItem.Target percentage difference',
        defaultMessage: 'Target percentage difference',
    }
});
