/* eslint-disable no-secrets/no-secrets */
import { defineMessages } from 'react-intl';

export default defineMessages({
    transactionDetailsTitle: {
        id: 'endorsement.common.view.Transaction Details',
        defaultMessage: 'Transaction Details'
    },
    effectiveDate: {
        id: 'endorsement.common.view.transaction-details-page.Effective date for policy change',
        defaultMessage: 'Effective date for policy change'
    },
    description: {
        id: 'endorsement.common.view.transaction-details-page.Description of policy change',
        defaultMessage: 'Description of policy change'
    },
    alerts: {
        id: 'wmic-pe-capability-gateway-common-react.WMICTransactionDetailsPage.Revalidation Required',
        defaultMessage: "Revalidation Required"
    },
    changeEffectiveDateWarning: {
        id: 'wmic-pe-capability-gateway-common-react.WMICTransactionDetailsPage.changeEffectiveDateWarning',
        defaultMessage: "You have changed the effective date of this transaction. Please note this will require you to go through all of the pages in the workflow and validate the information."
    },
    ok: {
        id: 'wmic-pe-capability-gateway-common-react.WMICTransactionDetailsPage.Ok',
        defaultMessage: "Ok"
    },
});
