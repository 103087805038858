import React, { useContext } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import { WMICPUPVehicleExposuresMessages as messages } from 'wmic-pe-capability-gateway-common-pup-react';
import metadata from './WMICPolicyPuVehicleExposures.metadata.json5';

function WMICPolicyPuVehicleExposures(props) {
    const { exposuresVM } = props;

    const translator = useContext(TranslatorContext);

    const overrideProps = {
        vehicleExposuresDataList: {
            VMList: _.get(exposuresVM, 'children', []),
            VMData: [
                {
                    headerText: translator(messages.vehicleTypeDisplayHeading),
                    path: 'vehicleExposure.vehicleType'
                }
            ],
            readOnly: true
        }
    }

    return <ViewModelForm
        uiProps={metadata.componentContent}
        model={exposuresVM}
        overrideProps={overrideProps}
    />
}

export default WMICPolicyPuVehicleExposures;