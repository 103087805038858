import { defineMessages } from 'react-intl';

export default defineMessages({
    year: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHODesignatedGardenTractorLiability.Year",
        defaultMessage: "Year"
    },
    make: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHODesignatedGardenTractorLiability.Make",
        defaultMessage: "Make"
    },
    model: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHODesignatedGardenTractorLiability.Model",
        defaultMessage: "Model"
    },
    serialNumber: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHODesignatedGardenTractorLiability.Serial Number",
        defaultMessage: "Serial Number"
    },
    horsepower: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHODesignatedGardenTractorLiability.Horsepower",
        defaultMessage: "Horsepower"
    },
    addItem: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHODesignatedGardenTractorLiability.Add Item",
        defaultMessage: "Add Item"
    },
});
