import { defineMessages } from 'react-intl';

export default defineMessages({
    aiType: {
        id: 'wmic-pe-capability-gateway-common-ho-react.additional-interest.Type',
        defaultMessage: 'Type'
    },
    aiPersonalInfoTitle: {
        id: 'wmic-pe-capability-gateway-common-ho-react.additional-interest.Personal Info',
        defaultMessage: 'Personal Info'
    },
    aiMailingAddressEqualPNI: {
        id: 'wmic-pe-capability-gateway-common-ho-react.additional-interest.Same Mailing Address',
        defaultMessage: 'Mailing Address same as Home Address of Primary Named Insured?'
    },
    aiOtherTypeDescription: {
        id: 'wmic-pe-capability-gateway-common-ho-react.additional-interest.Additional Interest Type Other',
        defaultMessage: 'Describe Other Interest Type'
    },
    newAICompanyHeading: {
        id: 'wmic-pe-capability-gateway-common-ho-react.additional-interest.CompanyContactDetails',
        defaultMessage: 'Company Contact Details'
    },
    companyName: {
        id: 'wmic-pe-capability-gateway-common-ho-react.additional-interest.Company Name',
        defaultMessage: 'Company Name'
    }
});
