import { defineMessages } from 'react-intl';

export default defineMessages({
    vehicleOwnership: {
        id: 'wmic-pe-capability-gateway-common-pa-react.ownership-and-assignment.VehicleOwnership',
        defaultMessage: 'Vehicle Ownership'
    },
    registredOwnersInfoMsg: {
        id: 'wmic-pe-capability-gateway-common-pa-react.ownership-and-assignment.registredOwnersInfoMsg',
        defaultMessage: '&lt;span&gt;At least &lt;b&gt;one Registered Owner&lt;/b&gt; is required&lt;/span&gt;'
    },
    commercialDriverWarningMsg: {
        id: 'wmic-pe-capability-gateway-common-pa-react.ownership-and-assignment.commercialDriverWarningMsg',
        defaultMessage: '&lt;span&gt;&lt;b&gt;Commercial Driver&lt;/b&gt; information found in the &lt;b&gt;Drivers&lt;/b&gt; section must be completed for each driver.&lt;/span&gt;'
    },
    driversInfoMsg: {
        id: 'wmic-pe-capability-gateway-common-pa-react.ownership-and-assignment.driversInfoMsg',
        defaultMessage: '&lt;span&gt;A &lt;b&gt;Principal Driver&lt;/b&gt; is required&lt;/span&gt;'
    },
    vehicleTypeOwned: {
        id: 'wmic-pe-capability-gateway-common-pa-react.ownership-and-assignment.vehicleTypeOwned',
        defaultMessage: 'Vehicle Type Owned Since'
    },
    ownershipType: {
        id: 'wmic-pe-capability-gateway-common-pa-react.ownership-and-assignment.ownershipType',
        defaultMessage: 'Ownership Type'
    },
    ownershipDate: {
        id: 'wmic-pe-capability-gateway-common-pa-react.ownership-and-assignment.ownershipDate',
        defaultMessage: 'Ownership Date'
    },
    purchaseType: {
        id: 'wmic-pe-capability-gateway-common-pa-react.ownership-and-assignment.purchaseType',
        defaultMessage: 'Purchased'
    },
    demoVehicle: {
        id: 'wmic-pe-capability-gateway-common-pa-react.ownership-and-assignment.demoVehicle',
        defaultMessage: 'Was it a demonstrator vehicle?'
    },
    purchaseOdometer: {
        id: 'wmic-pe-capability-gateway-common-pa-react.ownership-and-assignment.purchaseOdometer',
        defaultMessage: 'Odometer Reading'
    },
    leaseOrRent: {
        id: 'wmic-pe-capability-gateway-common-pa-react.ownership-and-assignment.leaseOrRent',
        defaultMessage: 'Lease or Financed?'
    },
    registredOwnersHeading: {
        id: 'wmic-pe-capability-gateway-common-pa-react.ownership-and-assignment.registredOwnersHeading',
        defaultMessage: 'Registered Owners'
    },
    registredOwnerName: {
        id: 'wmic-pe-capability-gateway-common-pa-react.ownership-and-assignment.registredOwnerName',
        defaultMessage: 'Name'
    },
    relationshipToInsured: {
        id: 'wmic-pe-capability-gateway-common-pa-react.ownership-and-assignment.relationshipToInsured',
        defaultMessage: 'Relationship to the Insured'
    },
    addRegisteredOwner: {
        id: 'wmic-pe-capability-gateway-common-pa-react.ownership-and-assignment.addRegisteredOwner',
        defaultMessage: 'Add Registered Owners'
    },
    driversHeading: {
        id: 'wmic-pe-capability-gateway-common-pa-react.ownership-and-assignment.driversHeading',
        defaultMessage: 'Drivers'
    },
    driver: {
        id: 'wmic-pe-capability-gateway-common-pa-react.ownership-and-assignment.driver',
        defaultMessage: 'Driver'
    },
    percentOfUse: {
        id: 'wmic-pe-capability-gateway-common-pa-react.ownership-and-assignment.percentOfUse',
        defaultMessage: '% of Use'
    },
    driverType: {
        id: 'wmic-pe-capability-gateway-common-pa-react.ownership-and-assignment.driverType',
        defaultMessage: 'Driver Type'
    },
    addDriver: {
        id: 'wmic-pe-capability-gateway-common-pa-react.ownership-and-assignment.addDriver',
        defaultMessage: 'Add Driver'
    },
    removeDriverTitle: {
        id: 'wmic-pe-capability-gateway-common-pa-react.ownership-and-assignment.Remove driver?',
        defaultMessage: 'Remove driver?'
    },
    removeDriverMsg: {
        id: 'wmic-pe-capability-gateway-common-pa-react.ownership-and-assignment.DriverRemovalMsg',
        defaultMessage: 'Are you sure you want to remove {driver} from the list of drivers?',
    },
    ANI: {
        id: 'wmic-pe-capability-gateway-common-pa-react.ownership-and-assignment.ANI',
        defaultMessage: '{insured} (ANI)'
    },
    PNI: {
        id: 'wmic-pe-capability-gateway-common-pa-react.ownership-and-assignment.PNI',
        defaultMessage: '{insured} (PNI)'
    }
});
