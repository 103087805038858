import React, { useMemo, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { POLICY_DETAILS, CONSTANTS, WMICRichTextUtil, WMICDateTimeService, WMICCreditConsentUtil, WMICRPCUtil } from 'wmic-pe-portals-utils-js';
import { PAConsentMessages } from 'wmic-pe-capability-gateway-quoteandbind-pa-react';
import { TranslatorContext } from '@jutro/locale';

import metadata from './WMICPolicyInsuredPAConsent.metadata.json5';
import styles from './WMICPolicyInsuredPAConsent.module.scss';

function WMICPolicyInsuredPAConsent(props) {
    const { selectedInsured, policyVM } = props;
    const translator = useContext(TranslatorContext);

    const creditConsentUtil = useMemo(() => new WMICCreditConsentUtil(WMICRPCUtil,
        _.get(selectedInsured, 'address.state.value.code'),
        _.get(policyVM, `latestPeriod.rateAsOfDate.value`),
        _.get(policyVM, 'lobs.personalAuto.vehicleDTOs.value')
    ), [selectedInsured, policyVM]);

    const discountCreditConsentLinkMessage = useMemo(() => {
        let labelKey = creditConsentUtil.getConsentAgreementLabelKey();
        if(_.isNil(labelKey)) {
            labelKey = 'quoteandbind.views.credit-consent.DiscountConsentAgreement';
        }
        const translatedKey = translator({id: labelKey});
        return translatedKey;
    }, []);
    
    const consentReceivedYes = useMemo(() => _.get(selectedInsured, 'creditConsentReceived.value.code') === POLICY_DETAILS.YES, [selectedInsured])
    const consentReceivedNo = useMemo(() => _.get(selectedInsured, 'creditConsentReceived.value.code') === CONSTANTS.CREDIT_CONSENT_CODES.NO, [selectedInsured])
    
    const overrideProps = {
        '@field': {
            parentNode: selectedInsured,
            readOnly: true,
        },
        creditConsentDiscountAvailableNonPCS: {
            visible: !creditConsentUtil.showPaymentPlanConsent
        },
        consentAgreementFileNonPCS: {
            visible: !creditConsentUtil.showPaymentPlanConsent,
            href: creditConsentUtil.getCreditConsentAgreement()
        },
        creditPleaseShareDetailsPCS:{
            visible: creditConsentUtil.showPaymentPlanConsent
        },
        consentAgreementFilePCS:{
            visible: creditConsentUtil.showPaymentPlanConsent,
            href: creditConsentUtil.getCreditConsentAgreement(),
            content: discountCreditConsentLinkMessage
        },
        consentPaymentFilePCS: {
            visible: creditConsentUtil.showPaymentPlanConsent && !creditConsentUtil.showTwoInOneForm,
            href: creditConsentUtil.getPaymentPlanAgreement()
        },
        agreeToCreditCheck: {
            visible: !creditConsentUtil.showPaymentPlanConsent
        },
        ratingConsent: {
            visible: creditConsentUtil.showPaymentPlanConsent
        },
        paymentPlanConsent:{
            visible: creditConsentUtil.showPaymentPlanConsent
        },
        creditConsentDeclinedDate: {
            visible: consentReceivedNo
        },
        creditConsentDate: {
            value: _.get(selectedInsured, 'creditConsentDate.value'),
            visible: consentReceivedYes,
        },
        creditConsentDOB:{
            label: WMICRichTextUtil.translateRichText(translator(PAConsentMessages.dateOfBirthFor, {name: selectedInsured.value.firstName})),
            value: WMICDateTimeService.toMidnightDate(_.get(selectedInsured, "dateOfBirth.value")),
            visible: consentReceivedYes
        },      
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={selectedInsured}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICPolicyInsuredPAConsent.propTypes = {
    selectedInsured: PropTypes.any.isRequired,
    policyVM: PropTypes.any.isRequired
};

export default WMICPolicyInsuredPAConsent;
