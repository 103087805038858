import { defineMessages } from 'react-intl';

export default defineMessages({
    riskNumber: {
        id: "wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalConstructionPage.Risk Number",
        defaultMessage: "Risk #"
    },
    riskPrimary: {
        id: "wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalConstructionPage.Primary",
        defaultMessage: "Primary"
    },
    riskDescription: {
        id: "wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalConstructionPage.Description",
        defaultMessage: "Description"
    },
    riskType: {
        id: "wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalConstructionPage.Risk Type",
        defaultMessage: "Risk Type"
    },
    noRisks: {
        id: "wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalConstructionPage.No Risks",
        defaultMessage: "A risk must be added prior to selecting Construction Details."
    },
    savingConstruction: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.WMICHOPolicyRenewalConstructionPage.Saving Construction',
        defaultMessage: 'Saving Construction'
    }
});
