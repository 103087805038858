import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { IntlContext, useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useModal } from '@jutro/components';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { CreditCardUtil } from 'wmic-portals-utils-js';
import metadata from './PaymentPage.metadata.json5';
import styles from './PaymentPage.module.scss';
import messages from './PaymentPage.messages';
import CommonMessages from '../../PolicyRenewal.messages';

const PAYMENT_METHOD = {
    bank: 'wire',
    credit: 'creditcard'
};

/* eslint-disable no-param-reassign */
function initialiseVM(submissionVM) {
    submissionVM.bindData.paymentDetails.value = submissionVM.bindData.paymentDetails.value || {};
}
/* eslint-enable no-param-reassign */
function PaymentPage(props) {
    const modalApi = useModal();
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { authHeader, authUserData } = useAuthentication();
    const { RenewalService } = useDependencies('RenewalService');
    const {
        wizardData: submissionVM, updateWizardData, history
    } = props;

    const [isVMInitialised, updateIsVMInitialised] = useState(false);
    const [isLoading, updateIsLoading] = useState(false);
    const [hasRetrievedPaymentPlans, updateHasRetrievedPaymentPlans] = useState(false);
    const [editingPaymentPlans, setEditingPaymentPlans] = useState(false);
    const [isReferUnderWriter, setIsReferUnderWriter] = useState(false);
    const [formData, updateFormData] = useState({});
    const [showValidtionErrors, setShowValidtionErrors] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');

    const { isComponentValid, onValidate, registerComponentValidation } = useValidation(
        'PaymentPage'
    );

    const editPaymentPlans = useCallback(() => {
        setEditingPaymentPlans(true);
    }, [setEditingPaymentPlans]);

    const openReferToUnderwriterForm = useCallback(() => {
        if (!isComponentValid) {
            setShowValidtionErrors(true);
            return;
        }
        setIsReferUnderWriter(true);
    }, [isComponentValid]);

    const closeReferToUnderwriterForm = useCallback(() => {
        setIsReferUnderWriter(false);
    }, [setIsReferUnderWriter]);

    const writeValue = useCallback(
        (value, path) => {
            if (path === 'uwNoteForUnderwriter') {
                const nextFormData = _.cloneDeep(formData);
                _.set(nextFormData, path, value);
                updateFormData(nextFormData);
                return;
            }
            _.set(submissionVM, path, value);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData, formData]
    );

    const onNext = useCallback(async () => {
        let isBound = true;
        if (!submissionVM.bindData.selectedPaymentPlan.value) {
            modalApi.showAlert({
                title: messages.noPaymentSelected,
                message: messages.selectPaymentPlan,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: platformMessages.ok
            }).catch(_.noop);
            return false;
        }
        updateIsLoading(true);
        setLoadingMessage(translator(messages.processingPayment));
        submissionVM.value = await RenewalService.bind([submissionVM.value], authHeader)
            .catch(() => {
                isBound = false;
                modalApi.showAlert({
                    title: messages.unableToPurchase,
                    message: messages.unableToBind,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: platformMessages.ok
                }).catch(_.noop);
            })
            .finally(() => {
                updateIsLoading(false);
            });
        if (!isBound) {
            return false;
        }
        updateWizardData(submissionVM.value);
        return submissionVM;
    }, [RenewalService, authHeader, submissionVM, translator, updateWizardData]);

    useEffect(() => {
        if (!hasRetrievedPaymentPlans) {
            const { jobID } = submissionVM.value;
            updateIsLoading(true);
            setLoadingMessage(translator(CommonMessages.retrievingPaymentPlan));
            RenewalService.retrievePaymentPlans([jobID], authHeader)
                .then((paymentPlans) => {
                    writeValue(paymentPlans, 'bindData.paymentPlans');
                    updateHasRetrievedPaymentPlans(true);
                })
                .finally(() => {
                    updateIsLoading(false);
                });
        }
        // It should call once in component mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        registerComponentValidation(() => !isLoading);
        initialiseVM(submissionVM);
        updateIsVMInitialised(true);
        // It should call once in component mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const referQuoteToUnderWriter = useCallback(async () => {
        if (!submissionVM.bindData.selectedPaymentPlan.value) {
            modalApi.showAlert({
                title: messages.noPaymentSelected,
                message: messages.selectPaymentPlan,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: platformMessages.ok
            }).catch(_.noop);
            return;
        }
        const uwNoteForUnderwriter = _.get(formData, 'uwNoteForUnderwriter') || null;
        const { jobID } = submissionVM.value;
        updateIsLoading(true);
        setLoadingMessage(translator(messages.processing));
        RenewalService.updatePaymentPlan([submissionVM.value], authHeader)
            .then(() => {
                RenewalService.referToUnderwriter(jobID, uwNoteForUnderwriter, authHeader).then(
                    () => {
                        history.push(`/renewal/${jobID}/summary`);
                    }
                );
            })
            .catch(() => {
                modalApi.showAlert({
                    title: messages.unableToReferUW,
                    message: messages.errorOccurWhileRenewal,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: platformMessages.ok
                }).catch(_.noop);
            })
            .finally(() => {
                updateIsLoading(false);
            });
    }, [authHeader, formData, history, RenewalService, submissionVM, translator]);

    const getPaymentPlanDescription = useCallback(() => {
        const selectedPaymentPlan = _.get(submissionVM, 'bindData.selectedPaymentPlan.value');
        if (!selectedPaymentPlan) {
            return translator(messages.paymentPlans);
        }
        const paymentPlansArray = _.get(submissionVM, 'bindData.paymentPlans.value', []);
        let paymentPlanObject;
        if (paymentPlansArray.length > 0) {
            paymentPlanObject = paymentPlansArray.find((paymentPlan) => {
                return paymentPlan.billingId === selectedPaymentPlan;
            });
        }
        return paymentPlanObject ? paymentPlanObject.name : translator(messages.paymentPlans);
    }, [submissionVM, translator]);

    const getEffectiveDate = useCallback(() => {
        const value = _.get(submissionVM.value, 'baseData.effectiveDate');
        const dateFormat = { year: 'numeric', month: 'short', day: 'numeric' };
        return value && intl.formatDate(new Date(value.year, value.month, value.day), dateFormat);
    }, [submissionVM, intl]);

    const periodStartDate = useCallback(() => {
        return _.get(submissionVM, 'baseData.periodStartDate.value');
    }, [submissionVM]);

    const periodEndDate = useCallback(() => {
        return _.get(submissionVM, 'baseData.periodEndDate.value');
    }, [submissionVM]);

    const handlePaymentOptionChange = useCallback(
        (value) => {
            let dataToOmit;
            if (value === PAYMENT_METHOD.bank) {
                dataToOmit = 'creditCardData';
                submissionVM.bindData.paymentDetails.bankAccountData.value = _.get(
                    submissionVM.value,
                    'bindData.paymentDetails.bankAccountData',
                    {}
                );
            } else {
                dataToOmit = 'bankAccountData';
                submissionVM.bindData.paymentDetails.creditCardData.value = _.get(
                    submissionVM.value,
                    'bindData.paymentDetails.creditCardData',
                    {}
                );
            }

            submissionVM.value = _.omit(
                submissionVM.value,
                `bindData.paymentDetails.${dataToOmit}`
            );

            _.set(submissionVM, 'bindData.paymentDetails.paymentMethod', value);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const paymentMethod = _.get(submissionVM, 'bindData.paymentDetails.paymentMethod.value');
    const creditCardIssuer = _.get(
        submissionVM,
        'bindData.paymentDetails.creditCardData.creditCardIssuer.code.value'
    );

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        loadingIndicator: {
            loaded: !isLoading,
            loadingMessage: loadingMessage
        },
        paymentOptions: {
            onValueChange: handlePaymentOptionChange
        },
        paymentContainer: {
            visible: !isLoading
        },
        bankAccountContainer: {
            visible: paymentMethod === PAYMENT_METHOD.bank
        },
        creditCardContainer: {
            visible: paymentMethod === PAYMENT_METHOD.credit
        },
        paymentPlansList: {
            visible: editingPaymentPlans
        },
        paymentPlanContainer: {
            visible: !editingPaymentPlans
        },
        referToUWPanel: {
            visible: isReferUnderWriter && isComponentValid
        },
        paymentPlanDescriptionID: {
            content: getPaymentPlanDescription()
        },
        uwNoteForUnderwriter: {
            value: _.get(formData, 'uwNoteForUnderwriter')
        },
        uwWarningContainerId: {
            visible: authUserData.userType === 'producer'
        },
        issueNowExceptAgent: {
            visible: authUserData.userType !== 'producer',
            notificationContent: {
                infoMessageTitle: translator(messages.infoExceptAgent, {
                    productName: _.get(submissionVM.value, 'baseData.productName'),
                    policyNumber: _.get(submissionVM.value, 'policyNumber'),
                    date: getEffectiveDate()
                }),
                infoMessageDescription: translator(messages.issueNow)
            }
        },
        policyPeriod: {
            labelPosition: 'top',
            value: {
                startDate: periodStartDate,
                endDate: periodEndDate 
            }
        },
        totalPremiumField: {
            labelPosition: 'top'
        },
        renewalEffectiveDate: {
            labelPosition: 'top'
        },
        creditCardNumber: {
            mask: CreditCardUtil.getInputMaskForIssuerCode(creditCardIssuer)
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            editPaymentPlans: editPaymentPlans,
            closeReferToUnderwriterForm: closeReferToUnderwriterForm,
            openReferToUnderwriterForm: openReferToUnderwriterForm,
            referQuoteToUnderWriter: referQuoteToUnderWriter
        }
    };
    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );

    if (!isVMInitialised) {
        return null;
    }
    return (
        <WizardPage
            showNext={authUserData.userType !== 'producer'}
            onNext={onNext}
            disableNext={!isComponentValid}
            nextLabel={translator(CommonMessages.issueNow)}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                showErrors={showValidtionErrors}
                onValueChange={writeValue}
                onValidationChange={onValidate}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

PaymentPage.propTypes = wizardProps;
export default withRouter(PaymentPage);
