import { defineMessages } from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.Cancel',
        defaultMessage: 'Cancel',
    },
    next: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.Next',
        defaultMessage: 'Next',
    },
    previous: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.Previous',
        defaultMessage: 'Previous',
    },
    loading: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.loading',
        defaultMessage: 'Loading...'
    },
});
