import { PhoneNumberUtil } from "google-libphonenumber";

export default {
    isPossibleNumber: (input, phoneCountry) => {
        var indexOfXInInput;
        var phoneNumber;
        var phoneNumberUtilInstance = PhoneNumberUtil.getInstance();
        var isPossibleNumber = true;
        if (input) {
            phoneNumber = (phoneNumber = (indexOfXInInput = input.indexOf(" x")) > 0 ? input.substring(0, indexOfXInInput) : input).replace(/[-_]/g, "");
            try {
                isPossibleNumber = phoneNumberUtilInstance.isPossibleNumber(phoneNumberUtilInstance.parse(phoneNumber, phoneCountry));
            } catch (error) {
                return false;
            }
        }
        return isPossibleNumber;
    }
};
