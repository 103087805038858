import { getProxiedUrl } from '@xengage/gw-portals-url-js';

const getDotcmsContent = (url) => {
    return fetch(url).then((preflightResponse) => {
        if (preflightResponse.ok) {
            return preflightResponse.json();
        }
        return Promise.reject(preflightResponse);
    }).then((actualResponse) => {
        return actualResponse.contentlets;
    }).catch((error) => {
        return Promise.reject(error);
    });
};

const spikeHandleService = (url) => {
    return fetch(getProxiedUrl(url));
};


export default {
    getDotcmsContent,
    spikeHandleService
};
