import React , { useContext } from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import metadata from './WMICPAPolicyVehicleInfo.metadata.json5';
import messages from './WMICPAPolicyVehicleInfo.messages';



function WMICPAPolicyVehicleInfo(props) {
    const {
        vehicleVM,
    } = props;

    const translator = useContext(TranslatorContext);

    const overrideProps = {
        '@field': {
            parentNode: vehicleVM,
            readOnly: true
        },
        policyTrailerTitle: {
            title: translator({id: vehicleVM.vehicleType.value.name}),
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleVM}
            overrideProps={overrideProps}
        />
    );
}

export default WMICPAPolicyVehicleInfo;
