/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import { ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { DateUtil } from 'wmic-pe-portals-utils-js';

import metadata from './WMICAddInsuranceHistoryRecordComponent.metadata.json5';
import messages from './WMICAddInsuranceHistoryRecordComponent.messages';

function WMICAddInsuranceHistoryRecordComponent(props) {
    const { id, insuranceHistoryRecord, readOnly, onValidate, saveInsuranceHistoryRecord, cancelInsuranceHistoryRecord, carriersList, showErrors: pShowErrors } = props;
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    const { refreshData } = useDataRefresh();
    const [currentInsuranceHistoryRecord, updateCurrentInsuranceHistoryRecord] = useState(insuranceHistoryRecord);
    const [showErrors, updateShowErrors] = useState(false);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => {
            if(onValidate){
                onValidate(true, id)
            }
        }
    }, [id, onValidate, isComponentValid])

    const handleSaveInsuranceHistoryRecord = useCallback(() => {
        if(isComponentValid) {
            saveInsuranceHistoryRecord(currentInsuranceHistoryRecord)
        } else {
            updateShowErrors(true)
        }
    }, [currentInsuranceHistoryRecord, isComponentValid, saveInsuranceHistoryRecord]);
    
    const updateInsuranceHistoryRecord = (data) => {
        refreshData();
        updateCurrentInsuranceHistoryRecord(data);
    };

    const getMaxDate = () => {
        return {
            year: 2200,
            month: 12,
            day: 31
        }
    }

    const getTodayDate = () => {
        return new Date();
    }

    const getMinDate = () => {
        return DateUtil.addYears(new Date(), -150);
    }
   

    const overrideProps = {
        '@field': {
            parentNode: insuranceHistoryRecord,
        },
        carrierName: {
            availableValues: carriersList
        },
        effectiveDate: {
            maxDate: getTodayDate(),
            minDate: getMinDate(),
        },
        expirationDate: {
            maxDate: getMaxDate(),
            minDate: getMinDate(),
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            handleSaveInsuranceHistoryRecord,
            cancelInsuranceHistoryRecord
        }
    };

    return (
        <ViewModelForm
            model={insuranceHistoryRecord}
            onModelChange={updateInsuranceHistoryRecord}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors || pShowErrors}
        />
    );
}

export default WMICAddInsuranceHistoryRecordComponent;
