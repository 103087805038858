import React, { useContext } from 'react';
import _ from 'lodash';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICWizardViewPage } from 'wmic-pe-portals-wizard-components-ui';
import { TranslatorContext } from '@jutro/locale';
import { WMICPolicyPaDriversDetailView } from 'wmic-pe-capability-policy-common-react';

import metadata from './WMICPolicyPaDriversPage.metadata.json5';
import messages from './WMICPolicyPaDriversPage.messages';

function WMICPolicyPaDriversPage(props) {
    const {
        wizardData: { policyVM },
    } = props;
    const translator = useContext(TranslatorContext);

    const overrideProps = {
        '@field': {
            parentNode: policyVM,
            showRequired: true
        },
        driversDetailViewComponent: {
            VMData: [{
                headerText: translator(messages.driverName), path: 'displayName'
            },
            {
                headerText: translator(messages.licenseNumber), path: 'licenseNumber'
            },
            {
                headerText: translator(messages.licenseProvince), path: 'licenseState', getData: (row, path) => {
                    return _.get(row.value, path) || translator(messages.licenseProvinceOther);
                }
            }],
            clickable: true,
            readOnly: true,
            detailViewComponent: WMICPolicyPaDriversDetailView
        }
    }

    return (
        <WMICWizardViewPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyVM}
                overrideProps={overrideProps}
            />
        </WMICWizardViewPage>
    );
}

WMICPolicyPaDriversPage.propTypes = wizardProps;
export default WMICPolicyPaDriversPage;
