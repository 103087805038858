/** Icons for policy types. */
/** Changed to FontAwesome classes for Wawanesa */
const policyTypeIcons = {
    'BusinessOwners': 'fa-briefcase',
    'BusinessAuto': 'fa-truck',
    'CommercialProperty': 'fa-building-o',
    'CommercialPackage': 'fa-shopping-basket',
    'GeneralLiability': 'general-liability',
    'HomeOwners': 'fa-home',
    'Homeowners': 'fa-home',
    'Home': 'fa-home',
    'Condo': 'fa-home',
    'FEC': 'fa-home',
    'Seasonal': 'fa-home',
    'MobileHome': 'fa-home',
    'Tenant': 'fa-home',
    'Auto': 'fa-automobile',
    'InlandMarine': 'inland-marine',
    'PersonalAuto': 'fa-automobile',
    'WorkersComp': 'fa-medkit',
    'PersonalUmbrella': 'fa-umbrella',

    'BOPLine': 'fa-briefcase',
    'CPLine': 'fa-building-o',
    'GLLine': 'general-liability',
    'IMLine': 'inland-marine',
    'PersonalAutoLine': 'fa-automobile',
    'BusinessAutoLine': 'fa-truck',
    'WorkersCompLine': 'fa-medkit',
    'HomeownersLine_HOE': 'fa-home',
    'Businessowners Line': 'fa-briefcase',
    'Commercial Auto Line': 'fa-truck',
    'Commercial Property Line': 'fa-building-o',
    'General Liability Line': 'general-liability',
    'Inland Marine Line': 'inland-marine',
    'Personal Auto Line': 'fa-automobile',
    'travel_perLine': 'fa-ship',
    'Personal Umbrella Line': 'fa-umbrella',
    'Workers\' Comp Line': 'fa-medkit'
};

/** Get a policy type icon by a policy type.
 * @param policyType policy type code.
 * @return string policy type icon name.
 */
function getPolicyTypeIconName(policyType) {
    if (policyTypeIcons.hasOwnProperty(policyType)) {
        return policyTypeIcons[policyType];
    }
    //Using question mark for unknown line of business
    return 'fa-question-circle';
}

/**
 * Get a policy icon or a policy type.
 * @param policyType policy type.
 * @return policy type icon path.
 */
function getPolicyTypeIcon(policyType) {
    //jfleming US33080 changed to FontAwesome class call
    return `${getPolicyTypeIconName(policyType)}`;
}

export default {
    getPolicyTypeIcon
}