import { defineMessages } from 'react-intl';

export default defineMessages({
    selectPayer: {
        id: 'agent.directives.PaymentPlanChange.SelectPayer',
        defaultMessage: 'Select Payer'
    },
    selectContact: {
        id: 'agent.directives.PaymentPlanChange.SelectContact',
        defaultMessage: 'Select Contact'
    }, 
    newPaymentInstrument: {
        id: 'agent.directives.PaymentPlanChange.NewPaymentInstrument',
        defaultMessage: 'New'
    },    
    bankAccountInfo: {
        id: 'agent.directives.PaymentPlanChange.BankAccountInfo',
        defaultMessage: 'Bank Account Information'
    },
    newPerson: {
        id: 'agent.directives.PaymentPlanChange.New Person',
        defaultMessage: 'New Person'
    },    
    newCompany: {
        id: 'agent.directives.PaymentPlanChange.New Company',
        defaultMessage: 'New Company'
    },
});
