import { defineMessages } from 'react-intl';

export default defineMessages({
    ratingInformationDescription: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.rating-information.description',
        defaultMessage: 'Description'
    },
    ratingInformationTitle: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.rating-information.title',
        defaultMessage: 'Rating Information'
    },
    ratingInfoText: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.rating-information',
        defaultMessage: 'Rating Information'
    },
    description: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.description',
        defaultMessage: 'Description'
    },
    vehicleTerritoryCode: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.territory',
        defaultMessage: 'Territory'
    },
    ratedGarageLocation: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.ratedGarageLocation',
        defaultMessage: 'Rated Garage Location'
    },
    annualMileage: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.annualMileage',
        defaultMessage: 'Kilometers - annually'
    },
    commutingMiles: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.commutingMiles',
        defaultMessage: 'Kilometers - to work one way'
    },
    primaryUse: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.primaryUse',
        defaultMessage: 'Use'
    },
    vehicleRateGroup: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.vehicleRateGroup',
        defaultMessage: 'Rate Group'
    },
    primaryDriverClass: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.primaryDriverClass',
        defaultMessage: 'Primary Driver Class'
    },
    occasionalDriverClass: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.occasionalDriverClass',
        defaultMessage: 'Occasional Driver Class' 
    },
    drivingRecordPrimaryDriver: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.drivingRecordPrimaryDriver',
        defaultMessage: 'Driving Record Primary Driver'
    },
    drivingRecordOccasionalDriver: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.drivingRecordOccasionalDriver',
        defaultMessage: 'Driving Record Occasional Driver'
    },
    vehicleDiscountsApplied: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.vehicleDiscountsApplied',
        defaultMessage: 'Discounts Applied'
    },
    vehicleSurchargesApplied: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.vehicleSurchargesApplied',
        defaultMessage: 'Surcharges Applied'
    },
    none: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.rating-information.none',
        defaultMessage: 'NONE'
    },
    vehicleType: {
        id: "wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.vehicle-type",
        defaultMessage: "Vehicle Type"
    },
    currentValue: {             
        id: "wmic.pe-capability-quoteandbind-common-react.pages.vehicle-coverages-table.current-value",
        defaultMessage: "Current value / purchase price"
    }
});
