import React, { useContext } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import { WMICPUPWatercraftExposuresMessages as messages } from 'wmic-pe-capability-gateway-common-pup-react';
import WMICPolicyPuWatercraftExposureForm from './WMICPolicyPuWatercraftExposureForm/WMICPolicyPuWatercraftExposureForm';

import metadata from './WMICPolicyPuWatercraftExposures.metadata.json5';

function WMICPolicyPuWatercraftExposures(props) {

    const { exposuresVM } = props;

    const translator = useContext(TranslatorContext);

    const overrideProps = {
        watercraftExposuresListView: {
            value: _.get(exposuresVM, 'children', []),
            VMData: [
                {
                    headerText: translator(messages.wmicMotorType),
                    getData: (exposure) => translator({id: exposure.value.watercraftExposure.watercraftMotorType_WMICDisplay}) 
                }
            ],
            readOnly: true,
            detailViewComponent: WMICPolicyPuWatercraftExposureForm
        }
    }

    return <ViewModelForm
        uiProps={metadata.componentContent}
        model={exposuresVM}
        overrideProps={overrideProps}
    />
}

export default WMICPolicyPuWatercraftExposures;