import _ from 'lodash';
import React, { useContext } from 'react';

import { TranslatorContext } from '@jutro/locale';

import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import { WMICWizardViewPage } from 'wmic-pe-portals-wizard-components-ui';
import { WMICGLBusinessOperationDetailView } from 'wmic-pe-capability-gateway-quoteandbind-gl-react';

import metadata from './WMICPolicyGLBusinessOperationPage.metadata.json5';
import messages from './WMICPolicyGLBusinessOperationPage.messages';

function WMICPolicyGLBusinessOperationsPage(props) {
    const {
        wizardData: { policyVM },
    } = props;

    const translator = useContext(TranslatorContext);

    const businessOperationsListVMData = [
        {
            headerText: translator(messages.industryCode),
            path: 'industryCode.code',
        },
        {
            headerText: translator(messages.classification),
            path: 'industryCode.classification',
        },
        {
            headerText: translator(messages.basisType),
            path: 'industryCode.basisType',
        },
        {
            headerText: translator(messages.basis),
            path: 'basisAmount',
        },
    ];

    const overrideProps = {
        '@field': {
            parentNode: policyVM,
            showRequired: true
        },
        businessOperationList: {
            startOpen: false,
            readOnly: true,
            value: _.get(policyVM, 'lobs.generalLiability.exposures.children', []),
            VMData: businessOperationsListVMData,
            detailViewComponentProps: { isBound: true },
            detailViewComponent: WMICGLBusinessOperationDetailView,
        },
    }

    // TODO: display readonly version of this page

    return (
        <WMICWizardViewPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyVM}
                overrideProps={overrideProps}
            />
        </WMICWizardViewPage>
    );
}

WMICPolicyGLBusinessOperationsPage.propTypes = wizardProps;
export default WMICPolicyGLBusinessOperationsPage;
