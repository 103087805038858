import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { withValidation, validationPropTypes } from '@xengage/gw-portals-validation-react'
import { TranslatorContext } from '@jutro/locale';
import { ServiceManager } from '@jutro/services';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { AccountService } from 'wmic-pe-capability-gateway-policy';
import { UserService } from 'gw-capability-gateway';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { CONSTANTS, WMICVariousUtil, MODAL_CONSTANTS, getGlobal, WMICLogger, parseErrorMessage, AddressType } from 'wmic-pe-portals-utils-js';
import { LocalDateUtil } from '@xengage/gw-portals-util-js'
import { Loader, withModalContext } from '@jutro/components';
import { WMICRequiredText, WMICHeading, WMICIcon } from 'wmic-pe-components-platform-react';
import { LOBmessages, WMICAccountDetails } from 'wmic-pe-capability-gateway-react';
import { AvailabilityService } from 'wmic-pe-capability-policycommon';
import { WMICErrorHandler } from "wmic-pe-capability-quoteandbind-common-react";
import cx from 'classnames';

import appConfig from 'app-config';
import styles from './WMICNewQuotePage.module.scss';
import metadata from './WMICNewQuotePage.metadata.json5';
import messages from './WMICNewQuotePage.messages';
import newAccountSearchMessages from '../WMICNewQuoteAccountSearch.messages';

class WMICNewQuotePage extends Component {
    static contextType = TranslatorContext;

    state = {
        submissionVM: {},
        producerCode: [],
        producerCodeResponse: [],
        accountHolderDetails: {},
        accountHolderViewVM: {},
        showErrors: false,
        isLoading: true
    };

    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        location: PropTypes.shape({
            state: PropTypes.shape({
                accountSearchCriteria: PropTypes.object
            })
        }).isRequired,
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }).isRequired,
        authUserData: PropTypes.shape({}).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        ...validationPropTypes
    };

    localeService = ServiceManager.getService('locale-service');

    componentDidMount() {
        let { submissionVM } = this.state;
        const { viewModelService, history } = this.props;
        const { productCode } = history.location.state;
        const model = {
            country: this.localeService.getDefaultCountryCode()
        };

        submissionVM = viewModelService.create(
            model,
            'pc',
            'wmic.edge.ca.capabilities.gateway.job.submission.dto.NewSubmissionDTO'
        );
        _.set(submissionVM, 'effectiveDate', LocalDateUtil.today());
        _.set(submissionVM, 'productCode', productCode);
        this.setState(
            {
                submissionVM,
                isLoading: false
            },
            () => {
                this.getAccountHolderDetails();
            }
        );
    };

    componentDidUpdate(prevProps) {
        const { authUserData } = this.props;

        if (_.get(prevProps, 'authUserData.userType') !== _.get(authUserData, 'userType')) {
            this.getAccountHolderDetails();
        }
    };

    updateAccountHolderVM = (accountHolderViewVM) => {
        this.setState({ accountHolderViewVM });
    };

    getPhoneType = (accountHolderViewVM) => accountHolderViewVM?.primaryPhoneType?.value?.code

    getAccountHolderDetails = () => {
        const { accountHolderDetails } = this.state;
        const accountHolder = {
            contactName: '',
            primaryAddress: {
                addrValidateStatus_Ext: CONSTANTS.ADDRESS_VALIDATION_STATUS.NEW,
                country: 'CA',
                city: '',
                state: '',
                postalCode: '',
            }
        };

        this.setState({
            isLoading: false
        });

        const { location, history } = this.props;
        const accountSearchCriteria = _.get(location, 'state.accountSearchCriteria');

        if (_.isNil(accountSearchCriteria)) {
            history.push('/');

            return;
        }

        const criteriaContactType = accountSearchCriteria.contactType;

        accountHolderDetails.contactType = _.upperFirst(criteriaContactType);

        if (accountHolderDetails.contactType === CONSTANTS.Person) {
            accountHolder.firstName = accountSearchCriteria.firstName;
            accountHolder.lastName = accountSearchCriteria.lastName;

            if (accountSearchCriteria.phone) {
                accountHolder.primaryPhoneType = 'work';
                accountHolder.workNumber = accountSearchCriteria.phone;
            } else {
                accountHolder.primaryPhoneType = CONSTANTS.PHONE_TYPES.MOBILE;
            }
        } else {
            accountHolder.contactName = accountSearchCriteria.companyName;

            if (accountSearchCriteria.phone) {
                accountHolder.companyPhone_WMIC = accountSearchCriteria.phone;
            }
        }

        this.setAccountHolderViewVM(accountHolder);

        accountSearchCriteria.contactName = '';
        accountSearchCriteria.lastName = '';
        accountSearchCriteria.firstName = '';
        accountSearchCriteria.phone = '';

        this.getAvailableProducerCodesForCurrentUser();
        this.setState({
            accountHolderDetails
        });
    };

    getAvailableProducerCodesForCurrentUser = () => {
        let { producerCode } = this.state;
        const { authHeader } = this.props;

        UserService.getAvailableProducerCodesForCurrentUser(authHeader).then(
            (producerCodeResponse) => {
                producerCode = producerCodeResponse.map((value) => ({
                    code: value.code,
                    name: value.displayValue
                }));
                this.setState({
                    producerCode,
                    producerCodeResponse
                });
            }
        );
    };

    setAccountHolderViewVM = (accountHolder) => {
        const { viewModelService } = this.props;
        const { accountHolderDetails } = this.state;
        const viewModel = viewModelService.create(
            accountHolder,
            'pc',
            'wmic.edge.ca.capabilities.policycommon.accountcontact.dto.AccountContactDTO',
            {
                ProducerCodeRequired: true
            }
        );

        if (accountHolderDetails.contactType === CONSTANTS.Person) {
            _.set(viewModel, 'subtype.value', CONSTANTS.Person);
        } else {
            _.set(viewModel, 'subtype.value', CONSTANTS.Company);
        }

        this.setState({
            accountHolderViewVM: viewModel
        });
    };

    createNewAccount = async () => {
        const {
            accountHolderDetails,
            accountHolderViewVM,
        } = this.state;

        const { authHeader, history } = this.props;
        const { productCode } = history.location.state;
        const { lobQuoteURL } = appConfig;
        const newAccount = {};

        newAccount.accountHolder = _.get(accountHolderViewVM, 'value');

        if (accountHolderDetails.contactType === CONSTANTS.Person) {
            newAccount.accountHolder.contactName = `${accountHolderViewVM.firstName.value} ${accountHolderViewVM.lastName.value}`;
            newAccount.accountHolder.primaryAddress.addressType = 'home';
            // newAccount.accountHolder.primaryAddress.country = accountHolderViewVM.primaryAddress.country;
        } else {
            newAccount.accountHolder.primaryAddress.addressType = AddressType.BUSINESS;
        }

        newAccount.accountHolder.primaryAddress.state = newAccount.accountHolder.primaryAddress?.province;
        newAccount.producerCodes = [
            {
                code: _.get(accountHolderViewVM, 'producerCode.value')
            }
        ];

        const selectedProducer = this.getSelectedProducer();
        const jurisdictionAvailabilityRequest = {
            effectiveDate_WMIC: LocalDateUtil.today(),
            productCode,
            jurisdictionCode: selectedProducer?.brokerJurisdictionCode_WMIC,
        }
        const availabilityResponse = await AvailabilityService.productAvailableBasedOnJurisdiction(jurisdictionAvailabilityRequest, authHeader);

        if (availabilityResponse.isAvailable) {
            AccountService.newAccountAndSubmission(newAccount, productCode, authHeader)
                .then((newSubmissionResponse) => {
                    if (newSubmissionResponse.jobNumber > 0) {
                        const nextLocation = {
                            quoteentry: {
                                postalCode: _.get(newAccount, 'accountHolder.primaryAddress.postalCode')
                                || _.get(
                                    accountHolderDetails.newAccount,
                                    'accountHolder.primaryAddress.postalCode'
                                ),
                                quoteID: newSubmissionResponse.jobNumber
                            }
                        };
                        const url = lobQuoteURL[productCode];
        
                        return history.push(url, nextLocation);
                    }
                    return undefined
                }).catch((error) => {
                    WMICLogger.error('Error creating new account and submission', error);
                    WMICErrorHandler.processAsModal(parseErrorMessage(error));
                })
        } else {
            const translator = this.context;
            const showWarning = getGlobal('showWarning');
            // Insert space before capital letters
            const productCodeDisplayName = productCode.replace(/([A-Z])/g, ' $1').trim();

            showWarning({
                title: messages.alerts,
                message: translator(messages.jurisdictionError, {productCode: productCodeDisplayName}),
                status: MODAL_CONSTANTS.STATUS.WARNING,
                icon: MODAL_CONSTANTS.ICON.ERROR,
                confirmButtonText: messages.ok,
            })
        }

        return null;
    };

    handleValidation = () => {
        this.setState({ showErrors: false }, () => this.setState({ showErrors: true }));
    };

    handleCancel = async () => {
        const { history } = this.props;
        this.props.modalContext.showConfirm({
            title: newAccountSearchMessages.cancelSubmission,
            message: newAccountSearchMessages.cancelMessage,
            status: 'warning',
            icon: 'mi-error-outline'
        }).then((results) => {
            if (results === CONSTANTS.MODAL_RESULT.CANCEL || results === CONSTANTS.MODAL_RESULT.CLOSE) {
                return _.noop();
            }

            return history.push('/');
        }, _.noop);
    };

    getSelectedProducer = () => {
        const { producerCodeResponse, accountHolderViewVM } = this.state;
        const selectedProducerCode = _.get(accountHolderViewVM, 'producerCode.value');

        if (!_.isNil(selectedProducerCode)) {
            const selectedProducer = producerCodeResponse.find(
                (producer) => producer.code === selectedProducerCode
            );

            return selectedProducer;
        }

        return null;
    }

    handleSearchAgain = () => {
        const { history } = this.props;
        const { productCode } = history.location.state;
        const nextLocation = {
            productCode
        };

        return history.push('/wmic-new-quote-account-search', nextLocation)
    };

    handlePhoneTypeChange = (value, path) => {
        const { accountHolderViewVM } = this.state;

        _.set(accountHolderViewVM, path, value);

        this.setState({ accountHolderViewVM });
    }

    getTitleIcon = (productCode) => {
        const icon = WMICVariousUtil.getLobIcon(productCode)

        return <WMICIcon
            icon={icon}
            id="lobIcon"
            size="large"
            className={cx("lobIcon", "gw-mr-2")}
        />
    }

    getTitle = () => {
        const { history } = this.props;
        const { productCode } = history.location.state;
        const translator = this.context;

        return (
            <div className={cx("wizardHeaderJobDetails", 'gw-mb-5')}>
                {this.getTitleIcon(productCode)}
                <WMICHeading variant="h2" title={translator(LOBmessages[`${productCode}Title`])}/>
            </div>
        )
    }

    onChangeZip = (value, path) => {
        const { accountHolderViewVM } = this.state;
        const newValue = value.toUpperCase();

        _.set(accountHolderViewVM, path, newValue);
        this.setState({ accountHolderViewVM });
    };

    writeValue = (value, path) => {
        const { accountHolderViewVM } = this.state;

        _.set(accountHolderViewVM, path, value);
        this.setState({ accountHolderViewVM });
    }

    render() {
        const {
            accountHolderViewVM,
            producerCode,
            showErrors,
            isLoading
        } = this.state;
        const selectedProducer = this.getSelectedProducer();
        // eslint-disable-next-line no-secrets/no-secrets
        const producerInfoList = ["brokerName_WMIC", "branchName_WMIC", "brokerJurisdiction_WMIC"];
        const producerInfo = _.pick(selectedProducer, producerInfoList);
        const mergedAccountHolderViewVM = {...accountHolderViewVM, ...producerInfo};

        const { setComponentValidation, isComponentValid } = this.props;
        const contactTypeValue = _.get(accountHolderViewVM, 'subtype.value');

        const overrideProps = {
            '@field': {
                labelPosition: 'left',
                showRequired: true,
                parentNode: mergedAccountHolderViewVM
            },
            companyInputs: {
                visible: contactTypeValue === CONSTANTS.Company
            },
            personInputs: {
                visible: contactTypeValue === CONSTANTS.Person
            },
            brokerCode: {
                availableValues: producerCode
            },
            brokerName: {
                readOnly: true
            },
            branch: {
                readOnly: true
            },
            region: {
                readOnly: true
            },
            country: {
                readOnly: true
            },
            phoneType: {
                value: this.getPhoneType(accountHolderViewVM),
                onValueChange: this.handlePhoneTypeChange
            },
            homePhone: {
                visible: contactTypeValue === CONSTANTS.Person
            },
            newQuoteTitle: {
                content: this.getTitle()
            },
            personZip: {
                onValueChange: this.onChangeZip
            },
            companyZip: {
                onValueChange: this.onChangeZip
            }
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                WMICRequiredText,
                WMICAccountDetails,
            },
            resolveCallbackMap: {
                nextCreateAccountForm: isComponentValid
                    ? this.createNewAccount
                    : this.handleValidation,
                onCancel: this.handleCancel,
                onSearchAgain: this.handleSearchAgain
            }
        };

        overrideProps.accountSummaryDetails = {
            parentNode: mergedAccountHolderViewVM,
            pOnValueChange: this.writeValue,
            pOverrides: overrideProps,
            pResolvers: resolvers,
            setComponentValidation,
            showErrors,
            contactTypeValue
        }

        if (isLoading) {
            return <Loader loaded={!isLoading} />;
        }

        const readValue = (id, path) => readViewModelValue(
                metadata.pageContent,
                mergedAccountHolderViewVM,
                id,
                path,
                overrideProps
            );

        return (
            <div>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={mergedAccountHolderViewVM}
                    onModelChange={this.updateAccountHolderVM}
                    overrideProps={overrideProps}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    onValidationChange={setComponentValidation}
                    resolveValue={readValue}
                    showErrors={showErrors}
                    onValueChange={this.writeValue}
                />
            </div>
        );
    }
}
export default withModalContext(withValidation(
    withViewModelService(withAuthenticationContext(withRouter(WMICNewQuotePage))
    ))
);
