import { defineMessages } from 'react-intl';

export default defineMessages({
    date: {
        id: 'agent.views.policy-billing.last-payment.Date',
        defaultMessage: 'Date'
    },
    amount: {
        id: 'agent.views.policy-billing.last-payment.Amount',
        defaultMessage: 'Amount'
    },
    paymentType: {
        id: 'agent.views.policy-billing.last-payment.Payment Type',
        defaultMessage: 'Payment Type'
    },
    amountDue: {
        id: 'agent.views.policy-billing.last-payment.Amount due',
        defaultMessage: 'Amount due'
    },
});
