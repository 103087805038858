import { defineMessages } from 'react-intl';

export default defineMessages({
    policyChangeHeader: {
        id: 'wmic-pe-capability-policy-common-react.components.PolicyChangeStartModal.Change Policy',
        defaultMessage: 'Change Policy'
    },
    dateLabel: {
        id: 'wmic-pe-capability-policy-common-react.components.PolicyChangeStartModal.Choose an effective date',
        defaultMessage: "Choose an effective date of change for the policy"
    },
    cancelButton: {
        id: 'wmic-pe-capability-policy-common-react.components.PolicyChangeStartModal.Cancel',
        defaultMessage: 'Cancel'
    },
    okButton: {
        id: 'wmic-pe-capability-policy-common-react.components.PolicyChangeStartModal.Start Change',
        defaultMessage: 'Start Change'
    },
});
