import { defineMessages } from 'react-intl';

export default defineMessages({
    policyVehicleTheftIdentifier: {
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyVehiclesPage.policyVehicleTheftIdentifier',
        defaultMessage: 'THEFT IDENTIFIER'
    },
    policyVehicleType: {
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyVehiclesPage.policyVehicleType',
        defaultMessage: 'VEHICLE TYPE'
    },
    policyVehicleDescription: {
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyVehiclesPage.policyVehicleDescription',
        defaultMessage: 'VEHICLE DESCRIPTION'
    },
    policyVehicleBusinessSegment: {
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyVehiclesPage.policyVehicleBusinessSegment',
        defaultMessage: 'BUSINESS SEGMENT'
    },
    policyVehicleNumber: {
        id: 'wmic-pe-capability-policy-common-react.WMICPolicyVehiclesPage.policyVehicleNumber',
        defaultMessage: '#'
    },
});

