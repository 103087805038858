import React, { useCallback, useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import {TranslatorContext} from '@jutro/locale'
import { useValidation } from '@xengage/gw-portals-validation-react'
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useWizardModals, WMICWizardSubmissionPage  } from 'wmic-pe-portals-wizard-components-ui';
import { LoadSaveService } from 'wmic-pe-capability-gateway-quoteandbind';
import { WMICPolicyDetailsComponent } from 'wmic-pe-capability-gateway-common-react';
import { WMICUserAccessUtil, FlowStepId, WMICLogger, WMICVariousUtil, WMICValidationUtil } from 'wmic-pe-portals-utils-js';
import messages
    from "wmic-pe-capability-gateway-quoteandbind-pup-react/pages/WMICPUPPolicyDetails/WMICPUPPolicyDetailsPage.messages";
import { messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';

function WMICPUPPolicyDetailsPage(props) {
    const { isComponentValid, onValidate: setComponentValidation, initialValidation, registerInitialComponentValidation } = useValidation('PolicyDetailsPage');
    const { wizardData: submissionVM, updateWizardData } = props;
    const { setWizardLoading } = useWizardModals();
    const { authHeader, authUserData } = useAuthentication();
    const [isReadOnlyUser, setReadOnlyUser] = useState(true);
    const [showErrors, setShowErrors] = useState(false);
    const translator = useContext(TranslatorContext);

    useEffect(() => {
        if (isComponentValid) {
            setShowErrors(false);
        }
    }, [isComponentValid]);

    const validateInitialTableForm = useCallback(() => {
        const answers = _.get(submissionVM, 'lobData.personalUmbrella.preQualQuestionSets.children[0].value.answers');
        // If the qualification questions were not answered, start wizard from page 1 (Policy Details page)
        return Object.values(answers).find((value) => !_.isNil(value));
    }, [submissionVM]);

    useEffect(() => {
        registerInitialComponentValidation(validateInitialTableForm);
    }, [registerInitialComponentValidation, validateInitialTableForm]);

    useEffect(() => {
        setReadOnlyUser(!WMICUserAccessUtil.canCreateSubmission(authUserData.roles));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        submissionVM.value.flowStepId_WMIC = FlowStepId.POLICY_DETAILS;
    }, [submissionVM.value]);

    const launchDefaultAnswers = useCallback(async () => {
        try {
            setWizardLoading(true, translator(messages.defaultAnswers))
            const newVM = _.clone(submissionVM);
            newVM.value = await LoadSaveService
                .createDefaultAnswers_WMIC(submissionVM.value, authHeader);
            updateWizardData(newVM);
        } catch(e) {
            WMICLogger.error('Error launching default answers', e);
        } finally {
            setWizardLoading(false);
        }
    }, [setWizardLoading, translator, submissionVM, authHeader, updateWizardData]);

    const onNext = useCallback(async () => {
        if (isComponentValid) {
            try {
                setWizardLoading(true);
                _.unset(submissionVM.value, 'bindData');

                // TODO - BE does not save all of the values correctly. E.g. date of birth
                submissionVM.value = await LoadSaveService.updateDraftSubmission(
                    submissionVM.value,
                    authHeader
                );

                updateWizardData(submissionVM);

                if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.POLICY_DETAILS)) {
                    WMICVariousUtil.scrollToTop();
                    return false;
                }

                return submissionVM;
            } finally {
                setWizardLoading(false);
            }
        } else {
            setShowErrors(true)
            return false;
        }
    }, [isComponentValid, setWizardLoading, submissionVM, authHeader, updateWizardData]);

    return (
        <WMICWizardSubmissionPage
            showPrevious={false}
            skipWhen={initialValidation}
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
            showRequired
        >
            <WMICPolicyDetailsComponent
                jobVM={submissionVM}
                updateWizardData={updateWizardData}
                onValidate={setComponentValidation}
                isReadOnly={isReadOnlyUser}
                authHeader={authHeader}
                launchDefaultAnswers={launchDefaultAnswers}
                showErrors={showErrors}
            />
        </WMICWizardSubmissionPage>
    );
}

WMICPUPPolicyDetailsPage.propTypes = wizardProps;
export default WMICPUPPolicyDetailsPage;
