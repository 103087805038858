import React, { useContext, useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { Loader } from '@jutro/components';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { CONSTANTS} from "wmic-pe-portals-utils-js";
import { useWizardModals } from "wmic-pe-portals-wizard-components-ui";
import WMICHOCoverageUtil from 'wmic-pe-capability-gateway-common-ho-react/utils/WMICHOCoverageUtil';
import WMICHOHomeBasedBusinessDetailView from './WMICHOHomeBasedBusinessDetailView/WMICHOHomeBasedBusinessDetailView';

import messages from './WMICHOHomeBasedBusinessCov.messages';
import metadata from './WMICHOHomeBasedBusinessCov.metadata.json5';

function WMICHOHomeBasedBusinessCov(props) {

    const {
        id,
        dwellingVM,
        dwellingCoverage,
        path,
        onUpdateJobAndCoverage,
        readOnly,
        isLoading,
        onValidationChange: parentOnValidate,
        showErrors: parentShowErrors
    } = props;


    const translator = useContext(TranslatorContext);
    const ViewModelService = useContext(ViewModelServiceContext);
    const { onValidate, isComponentValid, registerComponentValidation } = useValidation(id);
    const [showErrors, updateShowErrors] = useState(false);
    const [editingIndex, updateEditingIndex] = useState(-1);
    const [selectedBusiness, updateSelectedBusiness] = useState(undefined);
    const [addingBusiness, setAddingBusiness] = useState(false);
    const [selectedBusinessIndex, setSelectedBusinessIndex] = useState(-1);
    const { showConfirm } = useWizardModals();

    useEffect(()=> {
        if (parentOnValidate) {
            parentOnValidate(isComponentValid, id)
        }
        return () => parentOnValidate ? parentOnValidate(true, id) : undefined;
    }, [parentOnValidate, id, isComponentValid])

    useEffect(() => {
        minimumHomeBasedBusinessValidCheck();
    }, []);

    const updateJobAndCoverage = () => {
        const businesses = _.get(dwellingVM, 'homeBasedBusinessCovItems.value');
        const limitAmount = _.sum(businesses.map((b) => b.coverageLimit));

        const limitTerm = _.find(dwellingCoverage.terms, (t) => t.patternCode === 'HO_HomeBusinessCovLimit');
        limitTerm.directValue = limitAmount;

        onUpdateJobAndCoverage(limitAmount, `${path}.terms[0].directValue`);
    }

    const addEditBusiness = (businessVM, index) => {
        updateSelectedBusiness(businessVM);

        setSelectedBusinessIndex(index);
        setAddingBusiness(true);
    }

    const newBusiness = () => {
        const newBusinessVM = ViewModelService.create({}, 'pc', 'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.dwelling.HomeBasedBusinessCovItemDTO_WMIC');
        addEditBusiness(newBusinessVM, -1);
    }

    const editBusiness = (businessVM, index) => {
        addEditBusiness(businessVM, index);
    };

    const removeBusiness = async (businessCov, index) => {
        const businessDisplayName = _.get(businessCov, 'hoBusinessType_WMIC.value.description');

        const response = await showConfirm({
            title: translator(messages.removeBusinessConfirmationTitle, {business: businessDisplayName}),
            message: translator(messages.removeBusinessConfirmationMessage, { business: businessDisplayName }),
        });

        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            dwellingVM.homeBasedBusinessCovItems.value.splice(index, 1);

            updateJobAndCoverage()
            minimumHomeBasedBusinessValidCheck();
        }
    }

    const cancelBusiness = () => {
        updateSelectedBusiness(undefined);
        setSelectedBusinessIndex(-1);
        setAddingBusiness(false);
    };

    const saveBusiness = () => {
        if (selectedBusinessIndex >= 0) {
            dwellingVM.homeBasedBusinessCovItems.value[selectedBusinessIndex] = selectedBusiness.value;
        } else {
            dwellingVM.homeBasedBusinessCovItems.value.push(selectedBusiness.value);
        }
        updateJobAndCoverage()

        setSelectedBusinessIndex(-1);
        setAddingBusiness(false);
        minimumHomeBasedBusinessValidCheck();
    };

    const minimumHomeBasedBusinessValidCheck = useCallback (() => {
        return WMICHOCoverageUtil.isHomeBasedBusinessValid(dwellingVM);
    }, [dwellingVM]);

    useEffect(() => {
        registerComponentValidation(minimumHomeBasedBusinessValidCheck);
    }, [registerComponentValidation, minimumHomeBasedBusinessValidCheck]);

    const overrideProps = {
        '@field': {
            parentNode: dwellingVM,
            readOnly
        },
        hoHomeBasedBusinessCovList: {
            VMList: _.get(dwellingVM, 'homeBasedBusinessCovItems.children', []),
            VMData: [
                {
                    headerText: translator(messages.businessType),
                    path: 'hoBusinessType_WMIC'
                },
                {
                    headerText: translator(messages.businessDescription),
                    path: 'description',
                },
                {
                    headerText: translator(messages.businessCoverageLimit),
                    path: 'coverageLimit'
                },
                {
                    headerText: translator(messages.businessAnnualGrossReceipts),
                    path: 'annualGrossReceipts'
                }
            ],
            onEditAction: editBusiness,
            onRemoveAction: removeBusiness,
            flatCards: true,
            clickable: false,
            selectedCardIndex: editingIndex,
            updateSelectedCardIndex: updateEditingIndex,
            isEditing: addingBusiness,
            readOnly,
        },
        addHomeBasedBusinessCovButton: {
            disabled: addingBusiness,
            visible: !readOnly
        },
        businessForm: {
            visible: addingBusiness,
            businessVM: selectedBusiness,
            saveBusiness,
            cancelBusiness,
            onValidate,
            showErrors: showErrors || parentShowErrors
        },
        showBusinessMsg: {
            visible: !minimumHomeBasedBusinessValidCheck()
        }
    }

    const resolvers = {
        resolveCallbackMap: {
            newBusiness
        },
        resolveComponentMap: {
            WMICHOHomeBasedBusinessDetailView
        }
    };

    return (
        <Loader loaded={!isLoading}>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={dwellingVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors || parentShowErrors}
            />
        </Loader>
    )
}
export default WMICHOHomeBasedBusinessCov;
