import React, {
    useCallback,
    useState,
    useEffect,
    useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    CONSTANTS,
    WMICLogger,
    LINE_OF_BUSINESS,
    JobType,
    WMICVariousUtil
} from 'wmic-pe-portals-utils-js';
import { TranslatorContext } from '@jutro/locale';
import { WMICWizardChangeOrRenewalPage, useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { withViewModelService, ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { DocumentService } from 'wmic-pe-capability-gateway-policycommon';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WMICUnderwritingIssues } from 'wmic-pe-components-platform-react';
import { WMICHOPrintableAgreement } from 'wmic-pe-capability-gateway-common-ho-react';
import { WMICDocuments, WMICQuoteSummary } from 'wmic-pe-capability-gateway-common-react';
import { WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';
import WMICQuoteUtil from 'wmic-pe-capability-quoteandbind-common-react/util/WMICQuoteUtil';

import { messages as commonMessages } from 'wmic-pe-capability-gateway-policyrenewal-common-react';
import metadata from './WMICPolicyRenewalQuote.metadata.json5';
import messages from './WMICPolicyRenewalQuote.messages';

function WMICPolicyRenewalQuote(props) {
    const {
        wizardData: renewalVM,
        updateWizardData,
        markStepSubmitted,
        stopErrorNavigation
    } = props;
    const history = useHistory();
    const { RenewalService } = useDependencies('RenewalService');
    const { showConfirm } = useWizardModals();
    const { authHeader } = useAuthentication();
    const {
        isComponentValid,
        onValidate,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('WMICPolicyRenewalQuote');
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const [userDocuments, setUserDocuments] = useState([]);
    const { setWizardLoading } = useWizardModals();
    const [underwritingIssues, setUnderwritingIssues] = useState();
    const [basedOnPolicy, setBasedOnPolicy] = useState({});
    const [renewalSummaryVM, setRenewalSummaryVM] = useState({});
    const jobID = _.get(renewalVM.value, 'jobID');

    const hasBlockingValidationIssues = _.get(renewalVM, 'errorsAndWarnings.validationIssues.issues.value', []).length > 0;

    const hasBlockingIssues = WMICQuoteUtil.isBlockingUWIssuePresent(renewalVM) || hasBlockingValidationIssues;

    useEffect(() => {
        validateSubmission();
    }, [validateSubmission]);

    const validateSubmission = useCallback(async () => {
        try {
            const result = await RenewalService.validateRenewalForIssue(renewalVM.value, authHeader);

            _.extend(renewalVM.value, result);
            updateWizardData(renewalVM);
            markStepSubmitted(); // the step needs to be marked as submitted for the errors associated with this step to show up
        } catch(error) {
            WMICErrorHandler.processAsModal(error, jobID, JobType.RENEWAL);
        }
    }, [RenewalService, renewalVM, authHeader, updateWizardData, markStepSubmitted, jobID]);

    useEffect(() => {
        registerInitialComponentValidation(() => !WMICQuoteUtil.isBlockingUWIssuePresent(renewalVM));
        stopErrorNavigation();
    }, [registerInitialComponentValidation, renewalVM, stopErrorNavigation]);

    useEffect(() => {
        setWizardLoading(true, translator(messages.loading));

        try {
            fetchUWIssuesAndDocuments();
            fetchRenewalSummary();
        } catch (error) {
            WMICLogger.error(
                'Failed either request=findJobByJobNumber or request=getDocumentsForJobNumber or both',
                error
            );
        } finally {
            setWizardLoading(false);
        }
    }, [fetchUWIssuesAndDocuments, fetchRenewalSummary, translator, setWizardLoading]);

    const fetchUWIssuesAndDocuments = useCallback(async () => {
        const allPromises = Promise.all([
            RenewalService.findJobByJobNumber(jobID, authHeader),
            DocumentService.getDocumentsForJobNumber(jobID, authHeader)
        ]);

        const results = await allPromises;

        setBasedOnPolicy(results[0]);

        const uwIssues = results[0].underwritingIssues;
        const documents = results[1];

        setUnderwritingIssues(uwIssues);
        setUserDocuments(documents);
    }, [RenewalService, authHeader, jobID]);

    const fetchRenewalSummary = useCallback(async() => {
        const LOB = _.get(renewalVM,'value.lob');
        let vmType = '';
        let renewalResult = {};

        if(LOB === LINE_OF_BUSINESS.PERSONAL_AUTO) {
            vmType = 'wmic.edge.ca.capabilities.gateway.job.renewal.dto.PARenewalReviewDTO_WMIC';
            renewalResult = await RenewalService.getPARenewalSummary_WMIC(jobID, authHeader);
        } else if (LOB === LINE_OF_BUSINESS.HOME_OWNER) {
            vmType = 'wmic.edge.ca.capabilities.gateway.job.renewal.dto.HORenewalReviewDTO_WMIC';
            renewalResult = await RenewalService.getHORenewalSummary_WMIC(jobID, authHeader);
        } else if (LOB === LINE_OF_BUSINESS.PERSONAL_UMBRELLA) {
            vmType = 'wmic.edge.ca.capabilities.gateway.job.renewal.dto.PUPRenewalReviewDTO_WMIC';
            renewalResult = await RenewalService.getPUPRenewalSummary_WMIC(jobID, authHeader);
        }

        const renewalSummary = viewModelService.create(renewalResult, 'pc', vmType, renewalVM.aspects.context());

        setRenewalSummaryVM(renewalSummary);
    }, [renewalVM, viewModelService, RenewalService, jobID, authHeader]);

    const onNext = useCallback(() => {
        try {
            if (!isComponentValid || hasBlockingIssues) {
                WMICVariousUtil.scrollToTop();
                return false;
            }

            return showConfirm({
                title: messages.bindpolicytitle,
                message: messages.bindmessage,
                confirmButtonText: messages.bindpolicy,
                cancelButtonText: messages.cancelbutton,
            }).then(async (results) => {
                if (
                    results === CONSTANTS.MODAL_RESULT.CANCEL ||
                    results === CONSTANTS.MODAL_RESULT.CLOSE
                ) {
                    updateWizardData(renewalVM);

                    return false;
                }

                setWizardLoading(true, translator(messages.binding));

                const bindResponse = await RenewalService.bind_WMIC(
                    jobID,
                    authHeader
                );

                _.extend(renewalVM.value, bindResponse);

                updateWizardData(renewalVM);
                history.push(
                    `/policies/${bindResponse.policyNumber}/${renewalVM.value.termNumber}/summary`
                );

                return renewalVM;
            }, _.noop)
            .finally(() => {
                setWizardLoading(false);
            });
        } finally {
            setWizardLoading(false);
        }
    }, [isComponentValid, hasBlockingIssues, showConfirm, setWizardLoading, translator, RenewalService, jobID, authHeader, renewalVM, updateWizardData, history]);

    const getCurrentPremiumData = () => ({
            totalBeforeTaxes: _.get(basedOnPolicy, 'policy.latestPeriod.totalPremium'),
            taxes: _.get(basedOnPolicy, 'policy.latestPeriod.taxes'),
            fees: _.get(basedOnPolicy, 'policy.latestPeriod.fees'),
            total: _.get(basedOnPolicy, 'policy.latestPeriod.totalCost')
        })

    // This function is specifically used for Flex and is necessary because
    // the renewalSummaryVM is disconnected from the main renewalVM (jobVM)
    // so not making sure the renewalSummaryVM is updated causes UI issues in the Flex modal
    const updateWizardAndSummaryData = async (newRenewalVM) =>  {
        setWizardLoading(true);
        // Updating renewalVM with response from Flex
        _.extend(renewalVM.value, newRenewalVM.value);
        updateWizardData(renewalVM);
        // Updating renewalSummaryVM with latest data
        await fetchRenewalSummary();
        setWizardLoading(false);
    }

    const overrideProps = {
        wmicFlexAlertMessage: {
            jobVM: renewalSummaryVM,
        },
        quoteRenewalAlertMessage: {
            jobVM: renewalVM,
            underwritingIssues,
            // Hide alert message until underwriting issues are fetched
            visible: !!underwritingIssues,
        },
        quoteRenewalPagePremiumCosts: {
            premium: getCurrentPremiumData(),
            jobVM: basedOnPolicy,
            policyChange: renewalSummaryVM,
            enableFlex: true,
            updateJobVM: updateWizardAndSummaryData,
            enableBrokerFees: true
        },
        quoteRenewalPageUWIssues: {
            visible: !!underwritingIssues,
            underwritingIssues,
            quoteID: jobID,
            authHeader,
            jobVM: renewalVM.baseData,
            jobType: JobType.RENEWAL
        },
        quoteRenewalPageSummary: {
            jobVM: renewalSummaryVM
        },
        quoteRenewalPageUploadDocuments: {
            documents: userDocuments,
            updateDocuments: setUserDocuments,
            value: {
                documentDTOs: userDocuments,
                canUploadDocument: true,
                jobNumber: jobID
            }
        },
        quoteRenewalPageHOPrintableAgreement: {
            visible: renewalVM?.value?.lob === LINE_OF_BUSINESS.HOME_OWNER
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICQuoteSummary,
            WMICUnderwritingIssues,
            WMICDocuments,
            WMICHOPrintableAgreement
        }
    }

    return (
        <WMICWizardChangeOrRenewalPage
            onNext={onNext}
            nextLabel={translator(messages.renew)}
            cancelLabel={translator(commonMessages.saveAndExit)}
            isSkipping={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
            />
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICPolicyRenewalQuote.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    ...wizardProps
};

export default withViewModelService(WMICPolicyRenewalQuote);
