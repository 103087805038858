import productMetadata from 'product-metadata';

function getAddressMetadata(country) {
    // RAW metadata path
    // metadata.pc["wmic.edge.us.capabilities.address.dto.AddressDTO"].value.metadata[1].value.expression.value

    const suiteMetadata = window.wmic.viewmodel.metadata;
    const addressDTOContext = suiteMetadata.get('pc').types.get('wmic.edge.ca.capabilities.address.dto.AddressDTO').metadata._unnamedArray;
    const postalCodeRegExContext = addressDTOContext.find((contextValue) => contextValue.value.name === 'PostalCodeRegEx');
    return postalCodeRegExContext.value.expression.value.find((postCodeRegEx) => postCodeRegEx.country === country);
}

/* eslint-disable prefer-template */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
export default {
    usPostalCodeIsValid: (postalcode, country) => {
        if (postalcode === undefined) {
            return true;
        }
        let pattern;
        /** TODO: Need to get the patterns from the metadata using a better method like .find or .filter **/
        if (country && country.code === 'US') {
            const addressMetaData = getAddressMetadata(country.code)
            if (addressMetaData) {
                pattern = addressMetaData.regEx;
            } else {
                return true;
            }
        } else {
            return true;
        }

        const patternRegEx = new RegExp('^' + pattern + '$');
        return patternRegEx.test(postalcode);
    },
    canadaPostalCodeIsValid: (postalcode, country) => {
        if (postalcode === undefined) {
            return true;
        }
        let pattern;

        if (country && country.code === 'CA') {
            const addressMetaData = getAddressMetadata(country.code)
            if (addressMetaData) {
                pattern = addressMetaData.regEx;
            } else {
                return true;
            }
        } else {
            return true;
        }

        const patternRegEx = new RegExp('^' + pattern + '$');
        return patternRegEx.test(postalcode);
    },
    doesZipCodeMatchCity: (postalcode, zipCodeMatchesCity) => {
        return zipCodeMatchesCity;
    }
};
