import React, {
    useContext,
    useState,
    useCallback,
    useEffect
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { useDataRefresh, ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import {CONSTANTS} from "wmic-pe-portals-utils-js";
import {useWizardModals} from "wmic-pe-portals-wizard-components-ui";
import PropTypes from 'prop-types';
import styles from './WMICHOWaterUpdates.module.scss';
import metadata from './WMICHOWaterUpdates.metadata.json5';
import WMICHOWaterValvesDetails from './WaterValveDetails/WaterValvesDetails';
import WMICHOWaterHeaterDetails from './WaterHeaterInfo/WaterHeaterInfo';
import WMICAddMitigationTypeComponent from './WaterMitigationDetails/WaterMitigationDetails';
import waterMitigationMessages from './WaterMitigationDetails/WaterMitigationDetails.messages';
import './WMICHOWaterUpdates.messages';

function WMICHOWaterUpdates(props) {
    const {
        id,
        riskView,
        updateDetails,
        showErrors,
        isEditMode,
        onValidate
    } = props;

    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { refreshData } = useDataRefresh();
    const [editingIndex, updateEditingIndex] = useState(-1);
    const [selectedMitigationTypeVM, updateSelectedMitigationType] = useState();
    const [addingMitigationType, setAddingMitigationType] = useState(false);
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const { showConfirm } = useWizardModals();

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid])

    const updateDetailsData = useCallback(
        (data) => {
            refreshData();
            updateDetails(data);
        },
        [refreshData, updateDetails]
    );

    const addNewMitigationType = () => {
        const editableMitigationType = viewModelService.create({},
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.wing.DWIGWaterMitigationDTO_WMIC'
        );

        updateEditingIndex(-1);
        updateSelectedMitigationType(editableMitigationType);
        updateDetails(riskView);
        setAddingMitigationType(true);
    };

    const saveMitigationType = (mitigationType) => {
        if (!mitigationType.value.percentage) {
            // eslint-disable-next-line no-param-reassign
            mitigationType.value.percentage = null;
        }

        if (editingIndex >= 0) {
            riskView.constructionDetail.waterMitigationDetails.value[editingIndex] = mitigationType.value;
        } else {
            riskView.constructionDetail.waterMitigationDetails.value.push(mitigationType.value);
        }

        updateDetailsData(riskView);
        updateEditingIndex(-1);
        updateSelectedMitigationType(undefined);
        setAddingMitigationType(false);
    };

    const cancelMitigationType = () => {
        updateSelectedMitigationType(undefined);
        updateDetails(riskView);
        setAddingMitigationType(false);
    };

    const editMitigationType = (mitigationType) => {
        setAddingMitigationType(true);

        const editableMitigationType = viewModelService.create(
            mitigationType.value,
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.wing.DWIGWaterMitigationDTO_WMIC'
        );

        updateSelectedMitigationType(editableMitigationType);
        updateDetails(riskView);
    };

    const removeMitigationType = async (mitigationType, index) => {
        const response = await showConfirm({
            title: waterMitigationMessages.removeTitle,
            message: waterMitigationMessages.removeMessage
        })

        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            riskView.constructionDetail.waterMitigationDetails.value.splice(index, 1);
            updateDetailsData(riskView)
        }
    };

    const sharedFormProps = {
        onValidate: setComponentValidation,
        riskView,
        isEditMode,
        updateDetails: updateDetailsData,
        showErrors
    }
    const renderDescriptionWithOther = (item, path, index) => {
        const itemVM = _.get(riskView, `constructionDetail.waterMitigationDetails.children[${index}]`);

        // eslint-disable-next-line no-prototype-builtins
        if (itemVM.value.hasOwnProperty(path)) {
            const descriptionNode = _.get(itemVM, path);
            const selectedOption = _.find(descriptionNode.aspects.availableValues, {'code': descriptionNode.value.code});
            const SelectedOptionDescription = translator({id: selectedOption.name, defaultMessage: selectedOption.value})

            return descriptionNode.value.code === 'other' ? _.get(itemVM, `${path}Description.value`) : SelectedOptionDescription;
        }

        return '';
    };

    const overrideProps = {
        '@field': {
            parentNode: riskView,
            readOnly: !isEditMode
        },
        addMitigationTypeButton: {
            disabled: !isEditMode || addingMitigationType,
            visible: isEditMode
        },
        waterHeaterInfo: {
            ...sharedFormProps
        },
        mainWaterValve: {
            ...sharedFormProps
        },
        mitigationTypeDataList: {
            VMList: _.get(riskView, 'constructionDetail.waterMitigationDetails.children', []),
            VMData: [
                {
                    headerText: translator(waterMitigationMessages.mitigationType),
                    path: 'mitigationType',
                    getData: renderDescriptionWithOther
                },
                {
                    headerText: translator(waterMitigationMessages.sumpPumpType),
                    path: 'sumpPumpType',
                    getData: renderDescriptionWithOther
                },
                {
                    headerText: translator(waterMitigationMessages.sumpPumpAuxPower),
                    path: 'sumpPumpAuxPower',
                    getData: renderDescriptionWithOther
                },
                {
                    headerText: translator(waterMitigationMessages.backupValve),
                    path: 'backupValve',
                    getData: renderDescriptionWithOther
                }
            ],
            onEditAction: editMitigationType,
            onRemoveAction: removeMitigationType,
            flatCards: true,
            clickable: true,
            selectedCardIndex: editingIndex,
            updateSelectedCardIndex: updateEditingIndex,
            isEditing: false,
            readOnly: !isEditMode,
            onClickAction: (data) => updateSelectedMitigationType(data)
        },
        mitigationTypeForm: {
            ...sharedFormProps,
            visible: addingMitigationType || !_.isUndefined(selectedMitigationTypeVM),
            selectedMitigationTypeVM,
            updateSelectedMitigationType,
            saveMitigationType,
            riskView,
            updateDetails,
            cancelMitigationType,
            isEditMode: addingMitigationType
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            addNewMitigationType
        },
        resolveComponentMap: {
            WMICHOWaterValvesDetails,
            WMICHOWaterHeaterDetails,
            WMICAddMitigationTypeComponent
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={riskView}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            onModelChange={updateDetailsData}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

WMICHOWaterUpdates.propTypes = {
    id: PropTypes.string.isRequired,
    riskView: PropTypes.shape({}).isRequired,
    updateDetails: PropTypes.func.isRequired,
    showErrors: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    onValidate: PropTypes.func.isRequired,
};

export default WMICHOWaterUpdates;
