/* eslint-disable no-param-reassign */
import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import {
    Icon, useModal
} from '@jutro/components';
import { SearchService } from 'gw-capability-gateway';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { DeviceBreakpoint, LOBConstants } from 'wmic-portals-utils-js';
import { LoadingModal } from 'gw-capability-quoteandbind-common-react';
import { messages as platformMessages } from '@xengage/gw-platform-translations';

import PolicyLandingUtil from './PolicyLandingUtil';
import PolicySearchUtil from './PolicySearchUtil';
import Constants from './Constants';

import messages from './PolicySearchComponents.messages';
import metadata from './PolicySearchComponents.metadata.json5';
import styles from './PolicySearchComponents.module.scss';

function PolicySearchComponent(props) {
    const modalApi = useModal();
    const { viewModelService, history } = props;
    const [policySearchVmObject, setPolicySearchVmobject] = useState(undefined);
    const [policyResults, setPolicyResults] = useState([]);
    const [maxLimit, setMaxLimit] = useState(false);
    const [noResult, setNoResult] = useState(false);
    const [isLoading, setLoadingState] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const searchByCode = _.get(policySearchVmObject, 'searchBy.value.code');
    const loadingModalProps = {
        modalTitle: messages.searchLoader,
        isOpen: true
    };


    useEffect(() => {
        const policySearchCriteria = {
            searchBy: Constants.BY_POLICY,
            firstName: '',
            lastName: '',
            policyNumber: '',
            submissionNumber: '',
            companyName: ''
        };
        const policySearchViewModel = viewModelService.create(
            policySearchCriteria,
            'pc',
            'wmic.edge.ca.capabilities.gateway.search.policy.dto.PolicySearchCriteriaDTO_WMIC'
        );
        setPolicySearchVmobject(policySearchViewModel);
    }, [viewModelService]);

    const clearFormvalues = useCallback((newPolicySearchVM) => {
        newPolicySearchVM.policyNumber.value = '';
        newPolicySearchVM.submissionNumber.value = '';
        newPolicySearchVM.firstName.value = '';
        newPolicySearchVM.lastName.value = '';
        newPolicySearchVM.companyName.value = '';
        setShowResults(false);
        setMaxLimit(false);
        setNoResult(false);
    }, []);

    const writeValue = useCallback((value, path) => {
        const newPolicySearchVM = viewModelService.clone(policySearchVmObject);
        if (path === Constants.SEARCH_BY) {
            clearFormvalues(newPolicySearchVM);
        }
        _.set(newPolicySearchVM, path, value);
        setPolicySearchVmobject(newPolicySearchVM);
    }, [clearFormvalues, policySearchVmObject, viewModelService]);

    const getCell = useCallback((item, index, property) => {
        return item[property.id];
    }, []);

    const getLinkForPolicyNumber = useCallback((item) => {
        if (item.searchResultType === Constants.SEARCH_TYPE_POLICY) {
            const icon = PolicyLandingUtil.getPolicyTypeIcon(item.product.publicID);
            const policySummaryUrl = PolicySearchUtil.getURL(item);
            return (
                <div>
                    <Icon icon={icon} title={item.product.productName} />
                    {item.canUserView ? (
                        <span>
                            <a href={policySummaryUrl}>{item.policyNumber}</a>
                        </span>
                    ) : (<span>{item.policyNumber}</span>)}
                </div>
            );
        }
        return '';
    }, []);


    const getLinkForJobNumber = useCallback((item) => {
        if (item.searchResultType === Constants.SEARCH_TYPE_SUBMISSION) {
            const icon = PolicyLandingUtil.getPolicyTypeIcon(item.product.publicID);
            const jobSummaryUrl = PolicySearchUtil.getURL(item);
            return (
                <div>
                    <Icon icon={icon} title={item.product.productName} />
                    {item.canUserView ? (
                        <span>
                            <a href={jobSummaryUrl}>{item.jobNumber}</a>
                        </span>
                    ) : (<span>{item.jobNumber}</span>)}
                </div>
            );
        }
        return '';
    }, []);

    const isPolicySearchDisabled = useCallback(() => {
        if (_.isEmpty(policySearchVmObject)) {
            return false;
        }
        let isDisabled;
        switch (searchByCode) {
            case Constants.BY_FRSTLAST:
                isDisabled = policySearchVmObject.firstName.value.trim().length === 0
                    || policySearchVmObject.lastName.value.trim().length === 0;
                break;
            case Constants.BY_POLICY:
                isDisabled = policySearchVmObject.policyNumber.value.trim().length === 0;
                break;
            case Constants.BY_SUBMISSION:
                isDisabled = policySearchVmObject.submissionNumber.value.trim().length === 0;
                break;
            case Constants.BY_COMPANY:
                isDisabled = policySearchVmObject.companyName.value.trim().length === 0;
                break;
            default:
                isDisabled = false;
        }
        return isDisabled;
    }, [policySearchVmObject, searchByCode]);

    const clearPolicySearch = useCallback(() => {
        const newPolicySearchVM = viewModelService.clone(policySearchVmObject);
        newPolicySearchVM.policyNumber.value = '';
        newPolicySearchVM.submissionNumber.value = '';
        newPolicySearchVM.firstName.value = '';
        newPolicySearchVM.lastName.value = '';
        newPolicySearchVM.companyName.value = '';
        setPolicySearchVmobject(newPolicySearchVM);
        setPolicyResults([]);
        setMaxLimit(false);
        setNoResult(false);
    }, [policySearchVmObject, viewModelService]);

    const handleSearchClick = useCallback(() => {
        setLoadingState(true);
        SearchService.policySearch(policySearchVmObject.value, authHeader).then((response) => {
            const policySearchResults = response.policies.filter((policy) => policy.canUserView);
            setPolicyResults(policySearchResults);
            setMaxLimit(response.maxResultLimitReached);
            setNoResult(response.policies.length === 0);
            setShowResults(true);
            setLoadingState(false);
        }).catch(() => {
            setLoadingState(false);
            modalApi.showAlert({
                title: platformMessages.errorModalHeader,
                message: messages.errorModalMessage,
                status: 'error',
                icon: 'mi-error-outline'
            }).catch(_.noop);
            return false;
        });
    }, [policySearchVmObject, authHeader]);

    const getPolicySearchResultsMobileOverrides = useCallback(() => {
        const getContent = (policy) => {
            const icon = PolicyLandingUtil.getPolicyTypeIcon(policy.product.publicID);
            const url = PolicySearchUtil.getURL(policy);
            const linkValue = (policy.searchResultType === Constants.SEARCH_TYPE_POLICY)
                ? policy.policyNumber
                : policy.jobNumber;
            return (
                <div>
                    <Icon icon={icon} title={policy.product.productName} />
                    {policy.canUserView ? (
                        <span>
                            <a href={url} className={styles.noDecorLink}>{linkValue}</a>
                        </span>
                    ) : (<span>{linkValue}</span>)}
                </div>
            );
        };

        const overrides = policyResults.map(
            (policy, index) => {
                return {
                    [`mobileViewNameColumn${index}`]: {
                        content: policy.primaryInsuredName
                    },
                    [`mobileViewPolicyColumn${index}`]: {
                        content: getContent(policy),
                        visible: !!policy.policyNumber
                    },
                    [`mobileViewSubmissionColumn${index}`]: {
                        content: getContent(policy),
                        visible: !!policy.jobNumber
                    },
                    [`mobileViewStatusColumn${index}`]: {
                        content: policy.status
                    },
                };
            }
        );

        return Object.assign({}, ...overrides);
    }, [policyResults]);

    const renderNewSub = useCallback(() => {
        return (
            <div className="ww-btn-caret-primary">
                <div className="ww-btn-caret-inner">
                    <span className="fa fa-fw fa-plus" />
                    <span className={styles.dropdownMenuText}>
                        {translator(messages.newSubmissionLink)}
                    </span>
                </div>
                <div className="ww-caret-right">
                    <span className="fa fa-fw fa-caret-down" />
                </div>
            </div>
        );
    }, [translator]);

    const renderAuto = useCallback(() => {
        return (
            <div>
                <i className="fa fa-fw fa-car" />
                <span className={styles.dropdownIconMargin}>
                    {translator(messages.autoMobileLink)}
                </span>
            </div>
        );
    }, [translator]);

    const renderPP = useCallback(() => {
        return (
            <div>
                <i className="fa fa-fw fa-home" />
                <span className={styles.dropdownIconMargin}>
                    {translator(messages.personalPropertyLink)}
                </span>
            </div>
        );
    }, [translator]);

    const renderPUP = useCallback(() => {
        return (
            <div>
                <i className="fa fa-fw fa-umbrella" />
                <span className={styles.dropdownIconMargin}>
                    {translator(messages.personalUmbrellaLink)}
                </span>
            </div>
        );
    }, [translator]);

    const goToNewQuote = useCallback((lob) => {
        const nextLocation = {
            productCode: lob
        };
        return history.push('/wmic-new-quote-account-search', nextLocation);
    }, [history]);

    if (isLoading) {
        return <LoadingModal {...loadingModalProps} />;
    }

    const getTableColumnsProportion = () => {
        switch (searchByCode) {
            case Constants.BY_POLICY:
                return [629, 160, 109];
            case Constants.BY_SUBMISSION:
                return [629, 160, 109];
            case Constants.BY_FRSTLAST:
                return [629, 100, 91, 78];
            case Constants.BY_COMPANY:
                return [629, 100, 91, 78];
            default:
                return [629, 160, 109];
        }
    };

    const overrides = {
        '@field': {
            // apply to all fields
            onValueChange: writeValue
        },
        policyNumberContainer: {
            visible: searchByCode === Constants.BY_POLICY
        },
        submissionNumberContainer: {
            visible: searchByCode === Constants.BY_SUBMISSION
        },
        frstlastNameContainer: {
            visible: searchByCode === Constants.BY_FRSTLAST
        },
        companyNameContainer: {
            visible: searchByCode === Constants.BY_COMPANY
        },
        searchButton: {
            disabled: isPolicySearchDisabled(),
            onClick: handleSearchClick
        },
        clearButton: {
            disabled: isPolicySearchDisabled(),
            onClick: clearPolicySearch
        },
        policyResultsTableContainer: {
            data: policyResults,
            visible: !!policyResults.length
                && breakpoint !== DeviceBreakpoint.PHONE
                && breakpoint !== DeviceBreakpoint.TABLET,
            columnsProportion: getTableColumnsProportion()
        },
        jobNumberColumn: {
            visible: searchByCode !== Constants.BY_POLICY
        },
        policyNumberColumn: {
            visible: searchByCode !== Constants.BY_SUBMISSION
        },
        statusColumn: {
            visible: true
        },
        noResultsContainer: {
            visible: noResult
        },
        maxLimitreachedContainer: {
            visible: maxLimit
        },
        showResultsId: {
            visible: showResults
        },
        searchResultsForMobileViewContainer: {
            data: policyResults,
            visible: !!policyResults.length
                && (breakpoint === DeviceBreakpoint.PHONE
                    || breakpoint === DeviceBreakpoint.TABLET)
        },
        invalidMessageSeparator: {
            visible: noResult || maxLimit
        },
        newSub: {
            buttonText: renderNewSub()
        },
        auto: {
            content: renderAuto(),
            onClick: () => goToNewQuote(LOBConstants.PA)
        },
        pp: {
            content: renderPP(),
            onClick: () => goToNewQuote(LOBConstants.PP)
        },
        pup: {
            content: renderPUP(),
            onClick: () => goToNewQuote(LOBConstants.PUP)
        },
        ...getPolicySearchResultsMobileOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getCell,
            getLinkForPolicyNumber,
            getLinkForJobNumber
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={policySearchVmObject}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

PolicySearchComponent.propTypes = {
    viewModelService: PropTypes.shape({
        clone: PropTypes.func,
        create: PropTypes.func
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default withRouter(withViewModelService(PolicySearchComponent));
