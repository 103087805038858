import React, {
    useCallback,
    useContext,
    useState,
    useEffect,
    useMemo,
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { withViewModelService, ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WMICInsuranceHistoryPoliciesComponent } from 'wmic-pe-capability-gateway-common-react';
import { WMICCreditConsentUtil, WMICRPCUtil, WMICLobUtil, WMICLogger, WMICUserAccessUtil, JURISDICTIONS } from 'wmic-pe-portals-utils-js';
import { WMICPALossHistoryComponent, WMICPACreditConsentComponent } from 'wmic-pe-capability-gateway-quoteandbind-pa-react';
import { useAccordionValidation, WMICWizardChangeOrRenewalPage, useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { UISupportingInfoLookupService } from 'wmic-pe-capability-supportinginfo';
import { WMICPAValidationWarningUtil } from 'wmic-pe-capability-gateway-common-pa-react';

import { messages as commonMessages } from 'wmic-pe-capability-gateway-policychange-common-react';
import styles from '../../WMICPEPAPolicyChangeWizard.module.scss';
import metadata from './WMICPCPAInsuranceHistoryPage.metadata.json5';

function WMICPCPAInsuranceHistoryPage(props) {
    const {
        wizardData: policyChangeVM,
        updateWizardData
    } = props;

    const { EndorsementService } = useDependencies('EndorsementService');

    const translator = useContext(TranslatorContext);
    const { onValidate, initialValidation, isComponentValid } = useValidation(
        'WMICPCPAInsuranceHistoryPage'
    );
    const { onValidateAccordion, isAccordionValid } = useAccordionValidation(onValidate);
    const { refreshData } = useDataRefresh();
    const { authUserData: currentUser, authHeader } = useAuthentication();
    const { setWizardLoading, showCustom } = useWizardModals();
    const [carriersList, setCarrierList] = useState();
    const [showErrors, setShowErrors] = useState(false);
    const lobPath = WMICLobUtil.getProductPath(_.get(policyChangeVM, "lob.value.code"));
    const [insuranceHistoryVM, setInsuranceHistoryVM] = useState(_.get(policyChangeVM, `lobData.${lobPath}.insuranceHistory_WMIC`));
    const [isCreditConsentVisible, setCreditConsentVisible] = useState(false);
    const [isCreditScorevalidated, setIsCreditScorevalidated] = useState(false);
    const baseStateCode = _.get(policyChangeVM, 'baseData.baseState.value.code');
    const vehicles = _.get(policyChangeVM, 'lobData.personalAuto.coverables.vehicles.value');
    const anisVM = _.get(policyChangeVM, 'baseData.additionalNamedInsureds_WMIC');

    const canEditPolicyChange = useMemo(
        () => WMICUserAccessUtil.canEditPolicyChange(currentUser.roles),
        [currentUser]
    );

    useEffect(() => {
        const baseData = _.get(policyChangeVM, 'baseData');

        // Keeping this lookup here to reduce network payload size for performance
        UISupportingInfoLookupService.retrieveInsuranceCarriersList([baseData.baseState.value.code, baseData.periodStartDate.value], true, authHeader)
            .then((carriers) => {
                carriers.forEach((item) => {
                    item.name = item.carrier
                    item.code = item.carrier
                })
                setCarrierList(carriers);
            });
        const discountCreditConsentRPCIsEffective = WMICRPCUtil.getIsRPCEffective(
            _.get(baseData, 'baseState.value.code'),
            _.get(baseData, 'rateAsOfDate.value'),
            '1503', '1504', '1576', '1543'
        );
        const paymentPlanCreditConsentRPCIsEffective = WMICRPCUtil.getIsRPCEffective(
            _.get(baseData, 'baseState.value.code'),
            _.get(baseData, 'rateAsOfDate.value'),
            '1296'
        );
        const hasOnlyIRCAVehicles = WMICCreditConsentUtil.hasOnlyIRCAVehicles(vehicles);
        const hasPersonalVehicleOrMotorHome = WMICCreditConsentUtil.hasPersonalVehicleOrMotorHome(vehicles);
        const isPniCreditConsentable = WMICCreditConsentUtil.isPniCreditConsentable(_.get(baseData, 'primaryNamedInsured_WMIC'));
        const isAnyAniCreditConsentable = WMICCreditConsentUtil.isAnyAniCreditConsentable(_.get(baseData, 'additionalNamedInsureds_WMIC.value'));

        setCreditConsentVisible((!hasOnlyIRCAVehicles && (discountCreditConsentRPCIsEffective && (paymentPlanCreditConsentRPCIsEffective || hasPersonalVehicleOrMotorHome))
                && (isPniCreditConsentable || isAnyAniCreditConsentable)) || (isAnyAniCreditConsentable && _.get(baseData, 'baseState.value.code') === JURISDICTIONS.QUEBEC));
    }, [authHeader, policyChangeVM, vehicles]);

    const updateInsuranceHistoryVM = (historyVM) => {
        refreshData();
        setInsuranceHistoryVM(historyVM);
    }

    const updateDrivers = useCallback((namedInsured) => {
        const allDrivers = _.get(policyChangeVM, 'lobData.personalAuto.coverables.drivers.value');
        if (WMICCreditConsentUtil.isAniCreditConsentable(namedInsured) && allDrivers && allDrivers.length > 0) {
            let found = false;
            const ani = namedInsured.value;
            for (let driverCounter = 0; !found && driverCounter < allDrivers.length; driverCounter++) {
                const driver = allDrivers[driverCounter];
                if (driver.person.publicID === ani.contactPublicID || driver.person.publicID === ani.publicID) {
                    driver.dateOfBirth = _.get(ani, 'dateOfBirth');
                    driver.creditConsentReceived = _.get(ani, 'creditConsentReceived');
                    driver.creditConsentDate = _.get(ani, 'creditConsentDate');
                    driver.creditInfoWithdrawalConsent = _.get(ani, 'creditInfoWithdrawalConsent');
                    driver.personalInfoConsentForm = _.get(ani, 'personalInfoConsentForm');
                    found = true;
                }
            }
            updateWizardData(policyChangeVM);
        }
    }, [policyChangeVM, updateWizardData]);

    const onNext = useCallback(async () => {
        try {
            if(!isComponentValid)  {
                setShowErrors(true);
                return false;
            }


            setShowErrors(false);
            setWizardLoading(true, translator(commonMessages.savingTransactionDetails));

            if (baseStateCode === JURISDICTIONS.QUEBEC) {
                anisVM.forEach((ani) => updateDrivers(ani));
            }

            const result = await EndorsementService.saveEndorsement(
                [policyChangeVM.value],
                authHeader
            );
            _.extend(policyChangeVM.value, result);

            const isInvalid = !isCreditScorevalidated && WMICPAValidationWarningUtil.checkPACreditScoreValidation(policyChangeVM, translator, showCustom);
            setIsCreditScorevalidated(true);

            return isInvalid ? false : policyChangeVM;
        } catch (err) {
            WMICLogger.error('Save Insurance History failed', err);
        } finally {
            setShowErrors(true);
            setWizardLoading(false);
        }
        return false;
    }, [isComponentValid, setWizardLoading, translator, baseStateCode, EndorsementService, policyChangeVM, authHeader, isCreditScorevalidated, showCustom, anisVM, updateDrivers]);

    const commonAccordionProps = {
        showErrors
    }

    const commonAccordionContentProps = {
        showErrors,
        isReadOnlyUser: !canEditPolicyChange,
        isEditMode: true,
        onValidate: onValidateAccordion,
        submissionVM: policyChangeVM,
        updateWizardData,
        authHeader,
        baseData: _.get(policyChangeVM, 'baseData'),
        updateHistory: updateInsuranceHistoryVM,
        insuranceHistoryVM
    };

    const overrideProps = {
        policiesAccordion: {
            ...commonAccordionProps,
            isValid: isAccordionValid('policiesAccordionContent')
        },
        policiesAccordionContent: {
            ...commonAccordionContentProps,
            carriersList,
            jobVM: policyChangeVM,
            readOnly: !canEditPolicyChange
        },
        lossHistoryAccordion: {
            ...commonAccordionProps,
            isValid: isAccordionValid('lossHistoryAccordionComponent')
        },
        lossHistoryAccordionComponent: {
            ...commonAccordionContentProps
        },
        creditConsentAccordion: {
            ...commonAccordionProps,
            visible: isCreditConsentVisible,
            isValid: isAccordionValid('creditConsentAccordionComponent')
        },
        creditConsentAccordionComponent: {
            ...commonAccordionContentProps,
        }
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap:{
            WMICInsuranceHistoryPoliciesComponent,
            WMICPALossHistoryComponent,
            WMICPACreditConsentComponent,
        },
        resolveClassNameMap: styles
    };

    return (
        <WMICWizardChangeOrRenewalPage
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
            isSkipping={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onValidate}
            />
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICPCPAInsuranceHistoryPage.propTypes = wizardProps;

export default withViewModelService(WMICPCPAInsuranceHistoryPage);
