import { defineMessages } from 'react-intl';

export default defineMessages({
    wizard: {
        id: 'wmic.pe-capability-quoteandbind-common-react.wizard.step.PaymentAndBind',
        defaultMessage: 'Payments & Bind'
    },
    paymentTitle: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.pe-payment-page.Payment',
        defaultMessage: 'Payment'
    },
    billingMethod: {
        id: 'quoteandbind.views.payment-details.Billing Method',
        defaultMessage: 'Billing Method'
    },
    electronicDocumentDistribution: {
        id: 'quoteandbind.views.payment-details.Electronic document distribution is not available for Broker Billed policies',
        defaultMessage: '<div>Electronic document distribution is <b>not available</b> for broker billed policies. All documents will be mailed to the customer.</div>'
    },
    waivingBrokerFee : {
        id: 'quoteandbind.views.payment-details.WaivingBrokerFee',
        defaultMessage: '<div>Broker fees <b>waived automatically</b> when broker bill is payment method</div>'
    },
    details: {
        id: 'quoteandbind.views.payment-details.Details',
        defaultMessage: 'Details'
    },
    bindPolicy: {
        id: 'quoteandbind.views.payment-details.Bind Policy',
        defaultMessage: 'Bind Policy'
    },
    submitPayment: {
        id: 'quoteandbind.views.payment-details.Submit Payment',
        defaultMessage: 'Submit Payment'
    },
    paymentMethod: {
        id: 'wmic.quoteandbind.views.payment-details.Payment Method',
        defaultMessage: 'Payment Method'
    },
    cheque: {
        id: 'quoteandbind.views.payment-details.Cheque',
        defaultMessage: 'Cheque'
    },
    choosePaymentMethod: {
        id: 'quoteandbind.views.payment-details.ChoosePaymentMethod',
        defaultMessage: '-- Choose Payment Method --'
    },
    monthlyAutomatedChequing: {
        id: 'agent.views.policy-landing.Monthly Automated Chequing',
        defaultMessage: 'Monthly Automated Chequing'
    },
    monthlyAutomatedRecurring: {
        id: 'agent.views.policy-landing.Monthly Automated Recurring',
        defaultMessage: 'Monthly Automated Recurring'
    },
    creditCardLeavingNoteTitle: {
        id: 'quoteandbind.views.payment-details.CreditCardLeavingNoteTitle',
        defaultMessage: 'Payments will be processed on another website.'
    },
    creditCardLeavingNote: {
        id: 'quoteandbind.views.payment-details.CreditCardLeavingNote',
        defaultMessage: 'You are now leaving this website. You will be entering a third party website, which is not owned or operated by The Wawanesa Mutual Insurance Company. Although we carefully select the web links we place on our websites, this content, products and information contained on third party websites are not owned or controlled by The Wawanesa Mutual Insurance Company.'
    },
    chequeNote: {
        id: 'quoteandbind.views.payment-details.Note',
        defaultMessage: 'Note: Cheque payments will cause the policy to be issued as COD and the customer will receive an invoice.  Once the cheque is received and processed, the policy will be updated.'
    },
    error: {
        id: 'agent.directives.JobSummary.Error',
        defaultMessage: 'Error'
    },
    bindingFailure: {
        id: 'quoteandbind.views.payment-details.Binding Failure',
        defaultMessage: 'Cannot bind the policy.'
    },
});
