// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

const url = 'https://maps.googleapis.com/maps/api/js';
const libraryOption = 'libraries=places';

const googleApiService = {
    initialized: false
};

const searchCountry = 'CA'

function setUpGoogleApiService() {
    // Set autocomplete service
    googleApiService.autoComplete = new window.google.maps.places.AutocompleteService();

    // Set up getcoder
    // eslint-disable-next-line no-undef
    googleApiService.geocoder = new google.maps.Geocoder();

    // mark that the service has been initialized
    googleApiService.initialized = true;
}

function getApiUrl(apikey) {
    if (apikey) {
        return `${url}?key=${apikey}&${libraryOption}`;
    }
    return `${url}?key=${appConfig.credentials.GOOGLE_MAPS_API_KEY}&${libraryOption}`;
}

// dynamically load JavaScript files in our html with callback when finished
async function loadGoogleApiScript(apikey) {
    const script = document.createElement('script'); // create script tag
    script.type = 'text/javascript';

    // when script state is ready and loaded or complete we will call callback
    if (script.readyState) {
        // eslint-disable-next-line func-names
        script.onreadystatechange = function () {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
                script.onreadystatechange = null;
                setUpGoogleApiService();
            }
        };
    } else {
        script.onload = () => setUpGoogleApiService();
    }
    script.src = getApiUrl(apikey); // load by url
    document.getElementsByTagName('head')[0].appendChild(script); // append to head
}

// Build request to get addresses from Google Api
// Use location if enabled
function buildRequest(searchString) {
    const request = {
        types: ['address'],
        input: searchString,
        componentRestrictions: { country: searchCountry.toLowerCase() }
    };

    return request;
}

// Get a list of 5 addresses from google api
// Return a promise that resolves with returned data
async function getGoogleAddresses(searchString) {
    if (!googleApiService.initialized) {
        loadGoogleApiScript();
    }
    // const autoCom = GoogleApiService.setUpGoogleApiService;
    const request = buildRequest(searchString);
    const addresses = await new Promise((resolve) => {
        googleApiService.autoComplete.getPlacePredictions(request, (response) => {
            return response ? resolve(response) : resolve([]);
        });
    });
    return addresses;
}

// Get details of specific address using place_id from Google Api Address object
function getGoogleAddressDetails(placeId, callback) {
    if (!googleApiService.initialized) {
        loadGoogleApiScript();
    }
    googleApiService.geocoder.geocode({ placeId: placeId }, callback);
}

function getGoogleStateOrZip(searchStr, callback) {
    if (!googleApiService.initialized) {
        loadGoogleApiScript();
    }
    googleApiService.geocoder.geocode({ address: searchStr }, callback);
}
export {
    loadGoogleApiScript,
    getGoogleAddressDetails,
    getGoogleAddresses,
    getGoogleStateOrZip
};
