import { defineMessages } from 'react-intl';

export default defineMessages({
    changePrimaryInsuredTitle: {
        id: 'wmic-pe-capability-gateway-common-react.NamedInsuredDetailView.changePrimaryInsuredTitle',
        defaultMessage: 'Primary Named Insured Changed'
    },
    additionalInformationForPrimaryInsured: {
        id: 'wmic-pe-capability-gateway-common-react.NamedInsuredDetailView.additionalInformationForPrimaryInsured',
        defaultMessage: 'Additional information may be required.  Please review the personal and contact information.'
    },
    additionalInformationForPrimaryAndAdditionalInsured: {
        id: 'wmic-pe-capability-gateway-common-react.NamedInsuredDetailView.additionalInformationForPrimaryAndAdditionalInsured',
        defaultMessage: 'Additional information may be required.  Please review the personal and contact information. Please update the relationship to the new Primary Named Insured, on the other Name Insured(s).'
    }
})