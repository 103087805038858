import { defineMessages } from 'react-intl';

export default defineMessages({
    firstDateOfMonth: {
        id: 'quoteandbind.views.payment-details.WithdrawlDayOfMonth',
        defaultMessage: 'Withdrawl Day of Month'
    },
    paymentContact: {
        id: 'quoteandbind.views.payment-details.Payment Contact',
        defaultMessage: 'Payment Contact'
    },
    choosePaymentContact: {
        id: 'quoteandbind.views.payment-details.ChoosePaymentContact',
        defaultMessage: '-- Choose Payment Contact --'
    },    
    newPerson: {
        id: 'quoteandbind.views.payment-details.New Person',
        defaultMessage: 'New Person'
    },    
    newCompany: {
        id: 'quoteandbind.views.payment-details.New Company',
        defaultMessage: 'New Company'
    },    
    bankingInformation: {
        id: 'quoteandbind.views.payment-details.Banking Information',
        defaultMessage: 'Banking Information'
    },    
    newBankInformation: {
        id: 'quoteandbind.views.payment-details.New Bank Information',
        defaultMessage: 'New Bank Information'
    },
    financialInstitutionNumber: {
        id: 'quoteandbind.views.payment-details.Financial Institution Number',
        defaultMessage: 'Financial Institution Number'
    },    
    branchTransit: {
        id: 'quoteandbind.views.payment-details.Branch Transit',
        defaultMessage: 'Branch Transit'
    },
    bankAccountNumber: {
        id: 'quoteandbind.views.payment-details.Bank Account Number',
        defaultMessage: 'Bank Account Number'
    },
    bankAccountOwnerName: {
        id: 'quoteandbind.views.payment-details.Bank Account Owner Name',
        defaultMessage: 'Bank Account Owner Name'
    },    
    bankName: {
        id: 'quoteandbind.views.payment-details.Financial Institution Name',
        defaultMessage: 'Financial Institution Name'
    },    
    fiNameNotFoundFirstError: {
        id: 'quoteandbind.views.payment-details.Financial Institution Name not found first message',
        defaultMessage: 'Financial Institution Name not found. Verify the Branch Transit and Financial Institution numbers and try again.'
    },
    fiNameNotFoundSecondError: {
        id: 'quoteandbind.views.payment-details.Financial Institution Name not found second message',
        defaultMessage: 'Financial Institution Name not found. It may be invalid or unavailable at this time. Please select a different payment plan to proceed.'
    },    
    homeAddress: {
        id: 'quoteandbind.views.payment-details.HomeAddress',
        defaultMessage: 'Home Address'
    },
    businessAddress: {
        id: 'quoteandbind.views.payment-details.Business Address',
        defaultMessage: 'Business Address'
    },
    personalInfo: {
        id: 'quoteandbind.views.payment-details.PersonalInfo',
        defaultMessage: 'Personal Info'
    },
    companyInfo: {
        id: 'quoteandbind.views.payment-details.Company Info',
        defaultMessage: 'Company Info'
    },
});
