import { defineMessages } from 'react-intl';

export default defineMessages({
    accidentRatingWaiver: {
        id: 'wmic-pe-capability-gateway-common-pa-react.DriverEndorsementsCoverages.Accident Waiver Endorsement for Driver',
        defaultMessage: 'Accident Rating for &lt;b&gt;{name}&lt;/b&gt;'
    },
    reductionOfCoverage: {
        id: 'wmic-pe-capability-gateway-common-pa-react.DriverEndorsementsCoverages.Reduction of Coverage',
        defaultMessage: '28 - Reduction in coverage for &lt;b&gt;{name}&lt;/b&gt;'
    },
    excluded: {
        id: 'wmic-pe-capability-gateway-common-pa-react.DriverEndorsementsCoverages.PA_28A_ReducAmtForNamedCov',
        defaultMessage: '28A - Excluded &lt;b&gt;{name}&lt;/b&gt;'
    },
    article6Excluded: {
        id: 'wmic-pe-capability-gateway-common-pa-react.DriverEndorsementsCoverages.Article6_Excluded_Driver',
        defaultMessage: 'Article6 - Excluded &lt;b&gt;{name}&lt;/b&gt;'
    },
    minorConvictionWaiverON: {
        id: 'wmic-pe-capability-gateway-common-pa-react.DriverEndorsementsCoverages.PA_SEF39b_MinorConvictionWaiver.Ontario',
        defaultMessage: 'W39b - Minor Conviction Waiver for &lt;b&gt;{name}&lt;/b&gt;'
    },
    minorConvictionWaiverMaritimes: {
        id: 'wmic-pe-capability-gateway-common-pa-react.DriverEndorsementsCoverages.PA_SEF39b_MinorConvictionWaiver.Maritimes',
        defaultMessage: 'MCWE - Minor Conviction Waiver Endorsement for &lt;b&gt;{name}&lt;/b&gt;'
    },
    minorConvictionWaiver: {
        id: 'wmic-pe-capability-gateway-common-pa-react.DriverEndorsementsCoverages.PA_SEF39b_MinorConvictionWaiver',
        defaultMessage: '39b - Traffic Safety Conviction Waiver for &lt;b&gt;{name}&lt;/b&gt;'
    }
})