import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BreakpointTrackerContext } from '@jutro/layout';

import { ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { DeviceBreakpoint, Position } from 'wmic-pe-portals-utils-js';
import { useValidation } from '@xengage/gw-portals-validation-react'
import metadata from './WMICAddRoofCoverageComponent.metadata.json5';
import messages from './WMICAddRoofCoverageComponent.messages';

function WMICAddRoofCoverageComponent(props){
    const {
        id,
        riskView,
        selectedRoofCoverage, 
        saveRoofCoverage,
        cancelRoofCoverage,
        onValidate,
        readOnly,
        showErrors : pShowErrors
    } = props;

    const breakpoint = useContext(BreakpointTrackerContext);
    const {onValidate: setComponentValidation, isComponentValid} = useValidation(id);

    const [currentRoofCoverage, updateCurrentRoofCoverage] = useState(selectedRoofCoverage);
    const { refreshData } = useDataRefresh();
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        updateCurrentRoofCoverage(selectedRoofCoverage);
    }, [riskView, selectedRoofCoverage])

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => {
            if(onValidate){
                onValidate(true, id)
            }
        }
    }, [isComponentValid, onValidate, currentRoofCoverage, id]);

    const updateRoofCoverage = (data) => {
        refreshData();
        updateCurrentRoofCoverage(data);
    };

    const handleSaveRoofCoverage = () => {
        if(isComponentValid) {
            saveRoofCoverage(currentRoofCoverage)
        } else {
            setShowErrors(true);
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            handleSaveRoofCoverage,
            cancelRoofCoverage
        }
    };

    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === DeviceBreakpoint.DESKTOP ? Position.LEFT : Position.TOP,
            parentNode: currentRoofCoverage,
            readOnly
        },
        WMICAddRoofForm: {
            hideButtons: readOnly
        }
    };


    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentRoofCoverage}
            overrideProps={overrideProps}
            onModelChange={updateRoofCoverage}
            callbackMap={resolvers.resolveCallbackMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors || pShowErrors}
        />
    )
}

WMICAddRoofCoverageComponent.propTypes = {
    id: PropTypes.string.isRequired,
    selectedRoofCoverage: PropTypes.shape({}).isRequired,
    saveRoofCoverage: PropTypes.func.isRequired,
    cancelRoofCoverage: PropTypes.func.isRequired,
    updateSelectedRoofCoverage: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired
};

export default WMICAddRoofCoverageComponent;