import { defineMessages } from 'react-intl';

export default defineMessages({
    disappearingDeductibleIncluded: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.ho-coverages.disappearing-deductible.included',
        defaultMessage: 'The Disappearing Deductible benefit is included.'
    },
    disappearingDeductibleReduced: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.ho-coverages.disappearing-deductible.reduced',
        defaultMessage: 'The policy deductible will be reduced to ${disappearingDeductibleValue}.'
    }
})
