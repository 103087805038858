import { defineMessages } from 'react-intl';

export default defineMessages({
    activities: {
        id: 'app.html.index.Activities',
        defaultMessage: 'Activities',
    },
    claims: {
        id: 'app.html.index.Claims',
        defaultMessage: 'Claims',
    },
    commission: {
        id: 'app.html.index.Commission',
        defaultMessage: 'Commission',
    }
});
