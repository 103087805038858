import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import metadata from './WMICAddExteriorWallComponent.metadata.json5';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import messages from './WMICAddExteriorWallComponent.messages';

function WMICAddExteriorWallComponent(props){
    const {
        id,
        value: exteriorWallVM,
        updateModel,
        onValidate,
        readOnly,
        isEditing,
        showErrors: pShowErrors,
    } = props;

    const {onValidate: setComponentValidation, isComponentValid} = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => {
            if(onValidate){
                onValidate(true, id)
            }
        }
    }, [isComponentValid, onValidate, id]);
    

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => {
            if(onValidate){
                onValidate(true, id)
            }
        }
    }, [isComponentValid, onValidate, exteriorWallVM, id]);

    const materialTypeOnChange = (value, path) => {
        _.set(exteriorWallVM, 'description', undefined);
        _.set(exteriorWallVM.value, path, value);
        updateModel(exteriorWallVM);
    };
    
    const resolvers = {
        resolveCallbackMap: {},
        resolveComponentMap: {},
        resolveClassNameMap: {}
    };

    const overrideProps = {
        '@field': {
            parentNode: exteriorWallVM,
            readOnly: readOnly || !isEditing
        },
        materialType: {
            onValueChange: materialTypeOnChange,
        },
        exteriorWallDesc: {
            disabled: !exteriorWallVM.materialOrFeature.value,
        },
    };


    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={exteriorWallVM}
            overrideProps={overrideProps}
            onModelChange={updateModel}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            onValidationChange={setComponentValidation}
            showErrors={pShowErrors}
        />
    )
}

WMICAddExteriorWallComponent.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.shape({}).isRequired,
    onValidate: PropTypes.func.isRequired,
    updateModel: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    showErrors: PropTypes.bool.isRequired,
};

export default WMICAddExteriorWallComponent;
