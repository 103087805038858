import _ from 'lodash';
import { Augment } from '@xengage/gw-portals-viewmodel-utils-js';
import { LOADING } from "wmic-pe-portals-utils-js";

const METADATA_CLASS = 'wmic.edge.common.aspects.validation.dto.EditabilityRuleDTO';

/** Creates a new "Field is required" aspect. */
function create(expressionLanguage) {
    function getAllRules(compilationContext, node, nodeMetadata, ancestorChain) {
        return Augment.collectRules(compilationContext, node, nodeMetadata, ancestorChain, METADATA_CLASS);
    }

    function compileRule(compilationContext, rule) {
        const editabilityExpr = compilationContext.compile(rule.data.expression);

        return {
            'shouldApply': rule.shouldApply,
            'status': editabilityExpr
        };
    }

    function getRadiantData(currentViewModelNode) {
        const parentNode = currentViewModelNode._parent;
        const lowercasePath = _.toLower(currentViewModelNode._accessorCode)
        const radiantData = parentNode && parentNode.value.metaDataMap ? parentNode.value.metaDataMap.find((item) => _.toLower(item.propName) === lowercasePath) : undefined;
        return radiantData && radiantData.hasOwnProperty('isEditable') ? radiantData : undefined;
    }

    function isRadiantEditable(currentViewModelNode) {
        const radiantData = getRadiantData(currentViewModelNode);

        /* if no radiant data found for this field, default to editable. */
        // eslint-disable-next-line no-bitwise
        return radiantData && radiantData.isEditable !== undefined ?
            (LOADING.ACTIVE | LOADING.ON) === parseInt(radiantData.isEditable, 2) : true;
    }

    function combineRules(rules) {
        return {
            'editable': (v, pv, ctx) => {
                return rules.every((rule) => {
                    // console.log(`Editable for ${v._accessorCode} should Apply ${rule.shouldApply()} and status ${rule.status(v, pv, ctx) === 'EDITABLE'}`)
                    return rule.shouldApply() && rule.status(v, pv, ctx) === 'EDITABLE';
                });
            },
        };
    }

    return {
        'getAspectProperties': (currentViewModelNode, currentMetadataNode, ancestorChain) => {
            const compilationContext = expressionLanguage.getCompilationContext(currentMetadataNode.xCenter);
            const rules = getAllRules(compilationContext, currentViewModelNode, currentMetadataNode, ancestorChain);
            const compiledRules = rules.map(_.partial(compileRule, compilationContext));
            const descriptor = combineRules(compiledRules);
            return {
                'editable': {
                    'get': () => {
                        return isRadiantEditable(currentViewModelNode) && descriptor.editable(
                            currentViewModelNode,
                            ancestorChain.parent,
                            currentViewModelNode.aspects.context);
                    }
                },
                'editablePresent': {
                    'get': () => {
                        const ret = currentMetadataNode.elementMetadata.get(METADATA_CLASS);
                        let editablePresent = false;
                        const radiantData = getRadiantData(currentViewModelNode);
                        // eslint-disable-next-line no-bitwise
                        const present = (radiantData && radiantData.isEditable !== undefined && (radiantData.isEditable & LOADING.ACTIVE))
                        if (ret.length > 0 || present) {
                            editablePresent = true;
                        }
                        return editablePresent;
                    }
                }
            };
        }
    };
}

export default {
    create
};
