import { defineMessages } from 'react-intl';

export const messages = defineMessages({
    primaryPhone: {
        id: 'wmic-pe-components-platform-react.WMICPhoneDetails.Primary Phone',
        defaultMessage: 'Primary Phone',
    },
    mobilePhone: {
        id: 'wmic-pe-components-platform-react.WMICPhoneDetails.Mobile Phone',
        defaultMessage: 'Mobile Phone',
    },
    homePhone: {
        id: 'wmic-pe-components-platform-react.WMICPhoneDetails.Home Phone',
        defaultMessage: 'Home Phone',
    },
    workPhone: {
        id: 'wmic-pe-components-platform-react.WMICPhoneDetails.Work Phone',
        defaultMessage: 'Work Phone',
    }
});
