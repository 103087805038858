import { defineMessages } from 'react-intl';

export default defineMessages({
    defaultAnswers: {
        id: "quoteandbind.cp.views.cp-policy-details.Create Default Answer",
        defaultMessage : "Creating Default Answer"
    },
    wizard: {
        id: 'quoteandbind.cp.wizard.step.Policy Details',
        defaultMessage: 'Policy Details'
    },
    title: {
        id: 'quoteandbind.cp.views.cp-policy-details.Title',
        defaultMessage: 'Policy Details'
    }
});