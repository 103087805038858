import React, { createContext, useContext, useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { WMICProgressModal } from 'wmic-pe-capability-quoteandbind-common-react';
import { ModalNextContext } from '@jutro/components';
import { MODAL_CONSTANTS, setGlobal  } from 'wmic-pe-portals-utils-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

export const WizardModalsContext = createContext({});

export const WizardModalsProvider = ({children}) => {
    const { showAlert: showAlertModal, showConfirm: showConfirmModal, showModal: showCustomModal } = useContext(ModalNextContext);
    const [loading, setLoading] = useState(false);
    const [defaultMessage, setDefaultMessage] = useState('')
    const loadingMessage = useRef(defaultMessage);
    const [isSmallModal, setSmallModal] = useState(false);

    const setLoadingWithMessage = useCallback((loadingValue, message, smallModal) => {
        setSmallModal(false);
        if (typeof smallModal === 'boolean' ) {
            setSmallModal(smallModal);
        }
        if (typeof loadingValue === 'boolean' ) {
            if (message || message === "") {
                loadingMessage.current = message;
                setLoading(loadingValue);
            } else {
                loadingMessage.current = defaultMessage;
                setLoading(loadingValue);
            }
        } else {
            if (message || message === "") {
                loadingMessage.current = message;
            } else {
                loadingMessage.current = defaultMessage;
            }

            setLoading(true);
            Promise.resolve(loadingValue).finally(() => setLoading(false));
        }
    }, [defaultMessage])

    const showModal = useCallback((modalParams, status) => showAlertModal({
            ...modalParams,
            title: modalParams.title || '',
            message: modalParams.message || '',
            status,
            icon: modalParams.icon || MODAL_CONSTANTS.ICON.INFO
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        }).catch(() => {}), [showAlertModal])

    const showConfirm = useCallback((modalParams) => showConfirmModal({
            ...modalParams,
            title: modalParams.title || '',
            message: modalParams.message || '',
            status: MODAL_CONSTANTS.STATUS.WARNING,
            icon: modalParams.icon || MODAL_CONSTANTS.ICON.INFO,
            confirmButtonText: modalParams.confirmButtonText || commonMessages.yes,
            cancelButtonText: modalParams.cancelButtonText || commonMessages.noModel
        }), [showConfirmModal])

    const showError = useCallback((modalParams) => showModal(modalParams, MODAL_CONSTANTS.STATUS.ERROR), [showModal])

    const showWarning = useCallback((modalParams) => showModal(modalParams, MODAL_CONSTANTS.STATUS.WARNING), [showModal])

    const showInfo = useCallback((modalParams) => showModal(modalParams, MODAL_CONSTANTS.STATUS.INFO), [showModal])

    const showSuccess = useCallback((modalParams) => showModal(modalParams, MODAL_CONSTANTS.STATUS.SUCCESS), [showModal])

    const showCustom = useCallback((modalParams) => showCustomModal(modalParams), [showCustomModal])

    useEffect(() => {
        setGlobal("showConfirm", showConfirmModal);
        setGlobal("showError", showModal);
        setGlobal("showWarning", showWarning);
        setGlobal("showInfo", showModal);
        setGlobal("showSuccess", showModal);
        setGlobal("showCustom", showCustomModal);
    }, [showConfirmModal, showCustomModal, showModal, showWarning])

    return (
        <WizardModalsContext.Provider value={
            useMemo(() => ({
                wizardLoading: loading,
                setWizardLoading: setLoadingWithMessage,
                setDefaultMessage,
                showConfirm,
                showError,
                showWarning,
                showInfo,
                showSuccess,
                showCustom
            }), [loading, setLoadingWithMessage, showConfirm, showCustom, showError, showInfo, showSuccess, showWarning])
        }>
            {children}
            <WMICProgressModal
                modalTitle={loadingMessage.current}
                isOpen={loading}
                isSmallModal={isSmallModal}
            />
        </WizardModalsContext.Provider>
    )
};

/**
 * Retrieves the value of the WizardModalsContext
 *
 * @returns {object} an object with modal functions,
 *
 * wizardLoading - setWizardLoading.
 *
 * wizardLoading is boolean that indicates if the wizard loading modal open.
 * setWizardLoading is a function that takes either a boolean or a promise. If a boolean is passed
 * the loading modal will hide/show based on the value of the boolean. If a promise is passed
 * the loading modal will show until the promise has been resolved or rejected.
 *
 * showError - showWarning - showInfo - showSuccess
 *
 * Helper methods to show modal messages
 */

export const useWizardModals = () => useContext(WizardModalsContext);
