/* eslint-disable max-len */
/* eslint-disable no-throw-literal */
import _ from 'lodash';
import dayjs from 'dayjs';
import htmlParser from 'html-react-parser';
import { ERROR_CODE } from 'wmic-portals-utils-js';
import WMICTimezoneUtilService from './WMICTimezoneUtilService';

// TODO cleanup when implementing RQB-788
export default class WMICErrorHandlingService {
    static checkSessionUUID = (submissionDTO) => {
        if (submissionDTO === undefined || submissionDTO.sessionUUID === null || submissionDTO.sessionUUID === '') {
            // If a session UUID wasn't created, reject with an error message
            throw new Error('Error creating quote');
        }
    };

    static checkValidationError = (submissionDTO) => {
        if (submissionDTO !== undefined && !(submissionDTO.declineMessages_WMIC === undefined)) {
            let declineMessage = '';
            submissionDTO.declineMessages_WMIC.forEach((message) => {
                // don't show repeated messages
                const currentMessage = message.issueMessage;
                if (!declineMessage.includes(currentMessage)) {
                    declineMessage = `${declineMessage}${currentMessage}\n`;
                }
            });
            throw { code: ERROR_CODE.DECLINE, declineMessages: declineMessage, quoteID: submissionDTO.quoteID };
        }
        if (submissionDTO !== undefined && !(submissionDTO.underwritingMessages === undefined)) {
            throw { code: ERROR_CODE.UW_ISSUE, quoteID: submissionDTO.quoteID };
        }
        if (submissionDTO !== undefined && !(submissionDTO.systemMessages_WMIC === undefined)) {
            // CUSTOMIZE : sjayakumar
            // CSS-548 : Error messages/code for delayed CLUE Report retrieval
            let systemMessage = '';
            let clueMessage = '';
            let paymentErrors = '';
            let consentErrors = '';
            let authorizationError = '';
            let dayTransitionError = '';
            let availabilityMessage = '';
            let twelvePayBindError = '';
            let invalidSessionError = '';
            submissionDTO.systemMessages_WMIC.forEach((message) => {
                if (message.validationExceptionType === ERROR_CODE.CLUE_REPORT) {
                    clueMessage = `${clueMessage}${message.issueMessage}\n`;
                } else if (message.validationExceptionType === ERROR_CODE.PAYMENT) {
                    paymentErrors = `${paymentErrors}${message.issueMessage}\n`;
                } else if (message.validationExceptionType === ERROR_CODE.MARCONSENT || message.validationExceptionType === ERROR_CODE.OTWCONSENT) {
                    consentErrors  = `${paymentErrors}${message.issueMessage}\n`;
                } else if (message.validationExceptionType === ERROR_CODE.AUTHORIZATION) {
                    authorizationError = `${authorizationError}${message.issueMessage}\n`;
                } else if (message.validationExceptionType === ERROR_CODE.DAY_TRANSITION) {
                    let newDate = `${WMICTimezoneUtilService.getDateInPolicyLocationTimeZone(submissionDTO.draftData.periodStartDate, 'yyyy-MM-dd')}T00:00:00Z`;
                    newDate = dayjs(newDate, 'MMMM d, yyyy').tz('UTC');
                    const dayTransitionMessage = `Your selected policy effective date is no longer available. The date in your quote has been updated to <strong>${newDate}</strong>. You can select a different policy effective date by <a ng-click="toDoYourInfo()"">returning to the Your Info page</a>.`;
                    dayTransitionError = htmlParser(dayTransitionMessage);
                } else if (message.validationExceptionType === ERROR_CODE.AVAILABILITY) {
                    availabilityMessage = `${availabilityMessage}${message.issueMessage}`;
                    _.set(submissionDTO, 'availabilityMessage', availabilityMessage);
                } else if (message.validationExceptionType === ERROR_CODE.TWELVE_PAY_BIND) {
                    twelvePayBindError = message.issueMesssage;
                } else if (message.validationExceptionType === ERROR_CODE.INVALID_SESSION) {
                    invalidSessionError = `${invalidSessionError}${message.issueMessage}\n`;
                } else if (message.validationExceptionType === ERROR_CODE.INVALID_SUB_SESSION) {
                    invalidSessionError = `${invalidSessionError}${message.issueMessage}\n`;
                } else {
                    systemMessage = `${systemMessage}${message.issueMessage}\n`;
                }
            });
            if (systemMessage !== '') {
                // jmei - css370: Make error page aware of the quote ID
                throw { code: ERROR_CODE.PORTAL_VALIDATION, systemMessages: systemMessage, quoteID: submissionDTO.quoteID };
            }
            if (invalidSessionError !== '') {
                throw { code: ERROR_CODE.INVALID_SESSION, paymentErrors: paymentErrors, quoteID: submissionDTO.quoteID };
            }
            if (paymentErrors !== '') {
                throw { code: ERROR_CODE.PAYMENT, paymentErrors: paymentErrors, quoteID: submissionDTO.quoteID };
            }
            if (consentErrors !== '') {
                throw { code: ERROR_CODE.CONSENT, paymentErrors: consentErrors, quoteID: submissionDTO.quoteID };
            }
            if (authorizationError !== '') {
                throw { code: ERROR_CODE.AUTHORIZATION, paymentErrors: authorizationError, quoteID: submissionDTO.quoteID };
            }
            if (dayTransitionError !== '') {
                throw { code: ERROR_CODE.DAY_TRANSITION, paymentErrors: dayTransitionError, quoteID: submissionDTO.quoteID };
            }
            if (clueMessage !== '') {
                throw { code: ERROR_CODE.CLUE_REPORT, clueMessages: clueMessage, quoteID: submissionDTO.quoteID };
            }
            if (twelvePayBindError !== '') {
                throw { code: ERROR_CODE.TWELVE_PAY_BIND, paymentErrors: twelvePayBindError, quoteID: submissionDTO.quoteID };
            }
        }
    };

    static checkFailedRequestError = (errorResponse, quoteId) => {
        if (errorResponse?.baseError?.length > 0 && errorResponse?.baseError.toLowerCase().indexOf('invalid session') > -1) {
            throw { code: ERROR_CODE.INVALID_SESSION, paymentErrors: '', quoteID: quoteId };
        } else {
            throw { code: ERROR_CODE.TECHNICAL_ERROR, quoteID: quoteId };
        }
    };
}
