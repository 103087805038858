import React, {
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { TranslatorContext } from '@jutro/locale';
import messages from './WMICCoBrowseButton.messages';
import styles from './WMICCoBrowseButton.module.scss';

const WMICCoBrowseButton = () => {
    const [isCoBrowseModalVisible, setIsCoBrowseModalVisible] = useState(false);
    const translator = useContext(TranslatorContext);
    const cobrowseSDKAvailable = !!window.Genesys;

    const subscribeToGenesysModalEvents = useCallback(() => {
        if (cobrowseSDKAvailable) {
            window.Genesys("subscribe", "Messenger.opened", () => { setIsCoBrowseModalVisible(true) });
            window.Genesys("subscribe", "Messenger.closed", () => { setIsCoBrowseModalVisible(false) });
        }
    }, [cobrowseSDKAvailable]);

    useEffect(() => {
        subscribeToGenesysModalEvents();
    }, [cobrowseSDKAvailable, subscribeToGenesysModalEvents]);

    const handleOnClick = () => {
        if (cobrowseSDKAvailable) {
            if (isCoBrowseModalVisible) {
                setIsCoBrowseModalVisible(!isCoBrowseModalVisible)
                window.Genesys("command", "Messenger.close");
            }
            else {
                setIsCoBrowseModalVisible(!isCoBrowseModalVisible)
                window.Genesys("command", "Messenger.open");
            }
        }
    }

    return (<button className={styles.cobrowseSubHeaderBtn} onClick={handleOnClick}>{translator(messages.wmicCoBrowseButton)}</button>);
}

export default WMICCoBrowseButton;
