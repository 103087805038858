import { defineMessages } from 'react-intl';

export default defineMessages({
    noCoverage: {
        id: 'pe-quoteandbind.bop.directives.bop-building-directive.No Coverages',
        defaultMessage: 'No Coverages'
    },
    noneSelected: {
        id: 'pe-policycommon.directives.coverages.select.None Selected',
        defaultMessage: 'None Selected'
    },
    selectedValue: {
        id: 'pe-policycommon.The value must be between',
        defaultMessage: 'The value must be between {minValue} and {maxValue}'
    },
    minValue: {
        id: 'pe-policycommon.The minimum allowed value is',
        defaultMessage: 'The minimum allowed value is {minValue}'
    },
    maxValue: {
        id: 'pe-policycommon.The maximum allowed value is',
        defaultMessage: 'The maximum allowed value is {maxValue}'
    },
    updatingSelection: {
        id: 'pe-policycommon.directives.coverages.Updating selection...',
        defaultMessage: 'Updating selection...'
    }
});
