/* eslint-disable indent */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import { useTranslator } from '@jutro/locale';
import { DropdownSelectField } from '@jutro/components';
import styles from '../WMICRemoveVehicleWizard.module.scss';
import messages from '../WMICRemoveVehicleWizard.messages';
import { getVehicleName, getTomorrowDate, getTodayDate } from '../WMICRemoveVehicleWizardUtils';
import { REASONS_FOR_DELETING, CHANGES_TAKE_EFFECT_AFTER_MIDNIGHT, STATE_KEYS } from '../WMICRemoveVehicleWizardConsts';

export const getAndUpdateInitialStepReasonSelectionError = (modelState, modelStateDispatch, hasClickedContinue) => {
    modelState[STATE_KEYS.REASON_SELECTION_ERROR] = hasClickedContinue && (modelState[STATE_KEYS.REASON] === null || modelState[STATE_KEYS.REASON] === undefined);
    
    return modelState[STATE_KEYS.REASON_SELECTION_ERROR];
};

const WMICRemoveVehicleWizardInitialStep = (props) => {
    const { modelState, modelStateDispatch, focusRemoveVehicleWizardTitle, ...otherProps } = props; 
    const translator = useTranslator();
    const [firstLoad, setFirstLoad] = useState(true); //used so react redraw due to value change would not trigger reset of focus

    useEffect(() => {   
        if (firstLoad) {
            setFirstLoad(false);
            focusRemoveVehicleWizardTitle(translator(messages.removeVehicleAria));
        }
    });

    const shouldShowValidationMsg = () => modelState[STATE_KEYS.REASON_SELECTION_ERROR] === true;

    return (
        <div {...otherProps}>
            <p className="mb-5">
                <div className={styles['wmic-field-label-fit-to-content']} >
                    {WMICRichTextUtil.translateRichText(translator(messages.indicateTheReasonMessage, { vehicleName: getVehicleName(modelState[STATE_KEYS.VEHICLE_TO_REMOVE]) }))}
                </div>
            </p>
        
            <div className={`${styles['wmic-field-fit-to-content']} ${styles['wmic-input-box']}`}>
                <DropdownSelectField
                    id={`poc_remove_vehicle_reason${modelState[STATE_KEYS.JOB_ID]}`}
                    availableValues={REASONS_FOR_DELETING}
                    className={styles['wmic-field-fit-to-content']}
                    labelClassName={styles['wmic-field-label-fit-to-content']}
                    label={translator(messages.reasonForDeletionLabel)}
                    value={modelState[STATE_KEYS.REASON]}
                    placeholder={translator(messages.assignDriversPleaseSelect)}
                    onValueChange={(v) => {
                        modelStateDispatch({ 
                            value: {
                                [STATE_KEYS.REASON] : v, 
                                [STATE_KEYS.EFFECTIVE_DATE] : CHANGES_TAKE_EFFECT_AFTER_MIDNIGHT.includes(v) ? getTomorrowDate() : getTodayDate()
                            }});
                        modelState[STATE_KEYS.REASON] = v;
                        
                        getAndUpdateInitialStepReasonSelectionError(modelState, modelStateDispatch, true);
                    }}
                />
            </div>
            <div className={shouldShowValidationMsg() === true ? cx(styles.wmicInputBoxNotes, styles.errorRed) : styles['wmicHidden']} >
                {translator(messages.pleaseSelectTheReasonForRemoval)} 
            </div>
        </div>
    );
}

WMICRemoveVehicleWizardInitialStep.propTypes = {
    modelState: PropTypes.shape({}).isRequired,
    modelStateDispatch: PropTypes.func.isRequired,
    focusRemoveVehicleWizardTitle: PropTypes.func
};

export default WMICRemoveVehicleWizardInitialStep;
