import { defineMessages } from 'react-intl';

export default defineMessages({
    policyVehicleOwnership: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleOwnsershipAndAssignment.policyVehicleOwnership',
        defaultMessage: 'Vehicle Ownership' 
    },
    policyVehicleTypeOwned: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleOwnsershipAndAssignment.policyVehicleTypeOwned',
        defaultMessage: 'Vehicle Type Owned Since'
    },
    policyOwnershipType: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleOwnsershipAndAssignment.policyOwnershipType',
        defaultMessage: 'Ownership Type'
    },
    policyOwnershipDate: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleOwnsershipAndAssignment.policyOwnershipDate',
        defaultMessage: 'Ownership Date'
    },
    policyPurchaseType: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleOwnsershipAndAssignment.policyPurchaseType',
        defaultMessage: 'Purchased'
    },
    policyDemoVehicle: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleOwnsershipAndAssignment.policyDemoVehicle',
        defaultMessage: 'Was it a demonstrator vehicle?'
    },
    policyPurchaseOdometer: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleOwnsershipAndAssignment.policyPurchaseOdometer',
        defaultMessage: 'Odometer Reading'
    },
    policyLeaseOrRent: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleOwnsershipAndAssignment.policyLeaseOrRent',
        defaultMessage: 'Lease or Financed?'
    },
    policyRegistredOwnersHeading: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleOwnsershipAndAssignment.policyRegistredOwnersHeading',
        defaultMessage: 'Registered Owners'
    },
    policyRegistredOwnerName: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleOwnsershipAndAssignment.policyRegistredOwnerName',
        defaultMessage: 'Name'
    },
    policyRelationshipToInsured: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleOwnsershipAndAssignment.policyRelationshipToInsured',
        defaultMessage: 'Relationship to the Insured'
    },
    policyDriversHeading: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleOwnsershipAndAssignment.policyDriversHeading',
        defaultMessage: 'Drivers'
    },
    policyDriver: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleOwnsershipAndAssignment.policyDriver',
        defaultMessage: 'Driver'
    },
    policyPercentOfUse: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleOwnsershipAndAssignment.policyDercentOfUse',
        defaultMessage: '% of Use'
    },
    policyDriverType: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleOwnsershipAndAssignment.policyDriverType',
        defaultMessage: 'Driver Type'
    },

});
