/* eslint-disable no-secrets/no-secrets */
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('gatewayPolicyChange'), method, data, additionalHeaders);
}

/**
 * This is the server responsible for dealing with the Quote and Buy backend operations
 *
 * @export
 * @class EndorsementService
 */
export default class GatewayEndorsementService {
    /**
     * Updates, saves and performs a quote on an existing submission.
     * Generates quotes for all available product offerings.
     *
     * @param {Object} data tge submission that will be saved
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof EndorsementService
     */
    static loadEndorsement(data, additionalHeaders = {}) {
        return processSubmission('load', [data], additionalHeaders);
    }

    static loadEndorsementWithEffectiveDate(data, additionalHeaders = {}) {
        return processSubmission('loadWithEffectiveDate', data, additionalHeaders);
    }

    static loadEndorsementWithEffectiveDateAndDescription(data, additionalHeaders = {}) {
        return processSubmission('loadWithEffectiveDateAndDescription', data, additionalHeaders);
    }

    static saveEndorsement(data, additionalHeaders = {}) {
        return processSubmission('save', data, additionalHeaders);
    }

    /* Reused from Quote and Buy and Method overrided */
    static saveAndQuoteSubmission(data, additionalHeaders = {}) {
        return processSubmission('save', data, additionalHeaders);
    }

    static updateCoverages(quoteID, sessionUUID, clausesToUpdate, additionalHeaders = {}) {
        return processSubmission('updateCoverages', [quoteID, clausesToUpdate], additionalHeaders);
    }

    static updateCoveragesAndPolicyChangeData(sessionUUID, clausesToUpdate, quoteDataDTO, additionalHeaders = {}) {
        return processSubmission('updateCoveragesAndPolicyChangeData', [clausesToUpdate, quoteDataDTO], additionalHeaders);
    }

    static quoteEndorsement(data, additionalHeaders = {}) {
        return processSubmission('quote', data, additionalHeaders);
    }

    static quoteEndorsement_Ext(data, additionalHeaders = {}) {
        return processSubmission('quote_Ext', data, additionalHeaders);
    }

    static bind(data, additionalHeaders = {}) {
        return processSubmission('bind', data, additionalHeaders);
    }

    static withdrawEndorsement(data, additionalHeaders = {}) {
        return processSubmission('withdraw', data, additionalHeaders);
    }

    static getAvailablePolicy(data, additionalHeaders = {}) {
        return processSubmission('getAvailablePolicy', [data], additionalHeaders);
    }

    static getAvailablePolicies(data, additionalHeaders = {}) {
        return processSubmission('getAvailablePolicies', data, additionalHeaders);
    }

    static checkEffectiveDateIsValid(data, additionalHeaders = {}) {
        return processSubmission('checkEffectiveDateIsValid', data, additionalHeaders);
    }

    static isQuoted(data, additionalHeaders = {}) {
        return processSubmission('isQuoted', data, additionalHeaders);
    }

    static getSkeletonStructure(data, additionalHeaders = {}) {
        return processSubmission('getSkeletonStructure', [data], additionalHeaders);
    }

    static checkPolicyChangeCanBeStarted_WMIC(policyNumber, termNumber, additionalHeaders = {}) {
        return processSubmission('checkPolicyChangeCanBeStarted_WMIC', [policyNumber, termNumber], additionalHeaders);
    }

    static sendFRCDocuments(data, additionalHeaders = {}) {
        return processSubmission('sendFRCDocuments', [data], additionalHeaders);
    }

    static removeDriver(jobId, driverId, additionalHeaders = {}) {
        return processSubmission(
            'removeDriver',
            [jobId, driverId],
            additionalHeaders
        );
    }

    static getPolicyChangeDiff_WMIC(jobNumber, additionalHeaders = {}) {
        return processSubmission('getPolicyChangeDiff_WMIC', [jobNumber], additionalHeaders);
    }

    static postOnChange(policyChange, additionalHeaders = {}) {
        return processSubmission(
            'postOnChange',
            [policyChange],
            additionalHeaders
        )
    }

    static validatePolicyChangeForIssue(policyChange, additionalHeaders = {}) {
        return processSubmission(
            'validatePolicyChangeForIssue_WMIC',
            [policyChange],
            additionalHeaders
        );
    }

    static saveWithNoValidationRuleCheck(policyChange, additionalHeaders = {}) {
        return processSubmission(
            'saveWithNoValidationRuleCheck_WMIC',
            policyChange,
            additionalHeaders
        );
    }

    static bind_WMIC(data, additionalHeaders = {}) {
        return processSubmission('bind_WMIC', [data], additionalHeaders);
    }

    static saveAndRecalculateFlexForPolicyChange(
        quoteID,
        flexInfos,
        waiveBrokerFee,
        additionalHeaders = {}
    ) {
        return processSubmission('saveAndRecalculateFlexForPolicyChange',
            [quoteID, flexInfos, waiveBrokerFee], additionalHeaders);
    }

    static bindAndApplyChangesToFutureTerm(jobNumber, additionalHeaders = {}) {
        return processSubmission('bindAndApplyChangesToFutureTerm', [jobNumber], additionalHeaders);
    }
}
