import { defineMessages } from 'react-intl';

export default defineMessages({
    driverLicenseStatus: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.driverLicenseStatus',
        defaultMessage: 'Driver License Status'
    },
    licenseCountry: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.licenseCountry',
        defaultMessage: 'Country where current license was issued'
    },
    licenseState: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.licenseState',
        defaultMessage: 'Province/State where license was issued',
    },
    internationalPermitNumber: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.internationalPermitNumber',
        defaultMessage: 'Permit Number'
    },
    internationalPermitExpiryDate: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.internationalPermitExpiryDate',
        defaultMessage: 'Permit expiry date'
    },
    licenseWarning: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.toBind',
        defaultMessage: 'To bind this policy you will need to enter a valid Driver License Number'
    },
    licenseExpiryDate: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.licenseExpiryDate',
        defaultMessage: 'License expiry date'
    },
    licenseNumber: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.licenseNumber',
        defaultMessage: 'License Number'
    },
    licenseNumberPlaceHolder: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.licenseNumberPlaceHolder',
        defaultMessage: 'License format should be: 999999-999'
    },
    isABResidentLessThan3YrON: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.isABResidentLessThan3YrON',
        defaultMessage: 'Is the Driver new to Ontario?'
    },
    isABResidentLessThan3YrQC: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.pa-driver-license.isABResidentLessThan3YrQC',
        defaultMessage: 'Is the Driver licenced in Quebec?'
    },
    isABResidentLessThan3YrDefault: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.pa-driver-license.isABResidentLessThan3YrDefault',
        defaultMessage: 'Has the driver been living in {baseState} less than 3 years?'
    },
    uscanadaYearsLicensed: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.uscanadaYearsLicensed',
        defaultMessage: 'How many years has the driver been licensed in Canada and/or US?'
    },
    outsideLicCopyReceived: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.outsideLicCopyReceived',
        defaultMessage: 'Was the copy of out of province Driver\'s License received?'
    },
    experienceLetterReceived: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.experienceLetterReceived',
        defaultMessage: 'Letter of experience received?'
    },
    experienceLetterDate: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.experienceLetterDate',
        defaultMessage: 'Date of letter of experience'
    },
    outsideRecordReceived: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.outsideRecordReceived',
        defaultMessage: 'Was out-of-province/U.S. Driving Record/MVR received?'
    },
    getStateLicThirtyDay: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.getStateLicThirtyDay',
        defaultMessage: 'Will the driver get an {baseState} driver\'s license within {days} days?'
    },
    atLeastOneDriverClass: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.atLeastOneDriverClass',
        defaultMessage: '&lt;span&gt;Add a minimum of &lt;b&gt;one Driver Class&lt;/b&gt;&lt;/span&gt;'
    },
    driverClassTitle: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.driverClassTitle',
        defaultMessage: 'Driver Class'
    },
    driverClassType: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.driverClassType',
        defaultMessage: 'License class'
    },
    driverClassDateObtained: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.driverClassDateObtained',
        defaultMessage: 'Date obtained'
    },
    addClass: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.addClass',
        defaultMessage: 'Add Class'
    },
    priorGridStep: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.priorGridStep',
        defaultMessage: 'Prior Grid Step'
    },
    gridRelevantDate: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.gridRelevantDate',
        defaultMessage: 'GRID Relevant Date'
    },
    driverTraining: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.driverTraining',
        defaultMessage: 'Driver Training'
    },
    hasDriverCourse: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.hasDriverCourse',
        defaultMessage: 'Has this driver completed a certified driving training course?'
    },
    dateCourseCompleted: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.dateCourseCompleted',
        defaultMessage: 'Date Completed'
    },
    certificateIssuer: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.certificateIssuer',
        defaultMessage: 'Certificate issued by'
    },
    certificateIssuerDescribe: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.certificateIssuerDescribe',
        defaultMessage: 'Description'
    },
    hasMotorCycleCourse: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.hasMotorCycleCourse',
        defaultMessage: 'Has the driver completed a motorcycle drivers training course?'
    },
    motorcycleCourseDate: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.motorcycleCourseDate',
        defaultMessage: 'Date Completed'
    },
    driverDeclaration: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.driverDeclaration',
        defaultMessage: 'Driver Declaration'
    },
    hasDriverBeenSuspended: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.hasDriverBeenSuspended',
        defaultMessage: 'Has the driver\'s license ever been suspended, cancelled or lapsed?'
    },
    isCurrentlyRestricted: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.isCurrentlyRestricted',
        defaultMessage: 'Is the driver\'s license restricted?'
    },
    convictedOfAutoFraud: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.convictedOfAutoFraud',
        defaultMessage: 'Has the driver ever been convicted of fraud related to auto insurance?'
    },
    fraudConvictionDate: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.license-info.fraudConvictionDate',
        defaultMessage: 'Date of last conviction'
    }
});
