import { defineMessages } from 'react-intl';

export default defineMessages({
    select: {
        id: 'quoteandbind.views.payment-details.Select',
        defaultMessage: 'Select',
    },
    type: {
        id: 'quoteandbind.views.payment-details.Type',
        defaultMessage: 'Type',
    },
    downPayment: {
        id: 'quoteandbind.views.payment-details.Down Payment',
        defaultMessage: 'Down Payment'
    },
    installment: {
        id: 'quoteandbind.views.payment-details.Installment',
        defaultMessage: 'Installment'
    },
    total: {
        id: 'quoteandbind.views.payment-details.Total',
        defaultMessage: 'Total'
    },
    view: {
        id: 'quoteandbind.views.payment-details.View',
        defaultMessage: 'View'
    },
    schedule: {
        id: 'quoteandbind.views.payment-details.Schedule',
        defaultMessage: 'Schedule'
    }
});
