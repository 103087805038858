import { defineMessages } from 'react-intl';

export default defineMessages({
    solrDisabledMessage:{
        id: 'gateway.views.search.SOLR Disabled',
        defaultMessage: 'The search system is currently experiencing problems and will be restored soon. During this time search options will be limited. You can still use the search bar to find exact matches for existing Submissions, Policies, or Accounts by searching with the corresponding number. &lt;br/&gt;&lt;br/&gt; We apologize for the inconvenience, and full search functionality will be back soon.'
    },
    searchResults: {
        id: 'gateway.views.search.Search Results',
        defaultMessage: 'Search Results',
    },
    searching: {
        id: 'gateway.views.search.Searching...',
        defaultMessage: 'Searching...',
    },
    noResultsFoundFor: {
        id: 'gateway.views.search.No results found for',
        defaultMessage: 'No results found for "{query}". Please note that you can only search for valid policy information relating to the Insured',
    },
    accountResults: {
        id: 'gateway.views.search.Account Results',
        defaultMessage: 'Accounts ({accountResultsNumber})',
    },
    policyResults: {
        id: 'gateway.views.search.Policy Results',
        defaultMessage: 'Policies ({policyResultsNumber})',
    },
    accountPolicyCount: {
        id: 'gateway.views.search.Policy Count',
        defaultMessage: 'Policies: {policyResultsNumber}',
    },
    submissionResults: {
        id: 'gateway.views.search.Submission Results',
        defaultMessage: 'Submissions ({submissionResultsNumber})',
    },
    tooManyResults: {
        id: 'gateway.views.search.Too Many Results',
        defaultMessage: "Too many results? Try searching by: &lt;b&gt;&lt;i&gt;Insured's Name, Address, Transaction Number, Email Address, Phone Number&lt;/i&gt;&lt;/b&gt;"
    },
    resultStatus: {
        id: 'gateway.views.search.Result Status',
        defaultMessage: "Status: {resultStatus}"
    }
});
