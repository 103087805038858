import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { PAConstants, CONSTANTS } from 'wmic-pe-portals-utils-js';
import WMICPACoverageUtil from "wmic-pe-capability-gateway-common-pa-react/util/WMICPACoverageUtil";
import WMICDriverEndorsementsCheckbox from './WMICDriverEndorsementsCheckbox/WMICDriverEndorsementsCheckbox';

function WMICDriverEndorsementsCoverageComponent(props) {
    const {
        id,
        jobVM,
        driverEndorsement,
        onClauseChange,
        offeredCoverages,
        baseState,
        rateAsOfDate,
        selectedVehicleId,
        readOnly,
        hideUnchecked
    } = props;

    const paLobDataVM = _.get(jobVM, 'lobData.personalAuto');
    const policyDrivers = _.get(jobVM, 'lobData.personalAuto.coverables.drivers.value'); 
    const coverage = _.find(offeredCoverages.value, {publicID: driverEndorsement.publicID});

    const drivers = useMemo(() => {
        switch (driverEndorsement.publicID) {
            case PAConstants.article6ExcludedDriver_WMIC_PublicId:
                return policyDrivers.filter((policyDriver) => policyDriver.assignment_WMIC === CONSTANTS.ASSIGNMENT.RESTRICTED);
            case PAConstants.reducAmtForNamedCov_28A_PublicId:
                return policyDrivers.filter((policyDriver) => WMICPACoverageUtil.isAssignmentExcludedOrAssigned(policyDriver));
            case PAConstants.reducAmtForNamedCov_28_PublicId:
                return policyDrivers.filter((policyDriver) => WMICPACoverageUtil.isAssignmentRestrictedOrAssigned(policyDriver));
            default:
                return policyDrivers;
        }
    }, [driverEndorsement, policyDrivers]);

    const handleChange = (checked, path, driver, setChecked, setIsLoading) => {
        setChecked(checked);
        setIsLoading(true);

        switch (path) {
            case PAConstants.accidentWaiverCovPublicId: 
                updateAccidentWaiverEndorsement(checked, driver, `endorsements_Ext.${PAConstants.accidentWaiverEndorsementsWMIC}`);
                break;
            case PAConstants.accidentWaiverMtcCovPublicId: 
                updateAccidentWaiverEndorsement(checked, driver, `endorsements_Ext.${PAConstants.accidentWaiverMtcEndorsementsWMIC}`);
                break;
            case PAConstants.minorConvictionWaiver39bPublicId:
                updateMinorConvictionWaiverEndorsement(checked, driver, `endorsements_Ext.${PAConstants.minorConvictionWaiverEndorsementsWMIC}`);
                break;
            case PAConstants.reducAmtForNamedCov_28A_PublicId:
                updateVehicleDriverEndorsement(checked, driver, `endorsements_Ext.${PAConstants.excludedDriverEndorsementsWMIC}`);
                break;
            case PAConstants.reducAmtForNamedCov_28_PublicId:
                updateVehicleDriverEndorsement(checked, driver, `endorsements_Ext.${PAConstants.reductionDriverEndorsementsWMIC}`);
                break;
            case PAConstants.article6ExcludedDriver_WMIC_PublicId:
                updateVehicleDriverEndorsement(checked, driver, `endorsements_Ext.${PAConstants.articleDriverEndorsementsWMIC}`);
                break;
            default:
                break;
        }

        onClauseChange('', PAConstants.PACoveragesPaths.OFFERINGS_PATH, PAConstants.PACoveragesPaths.OFFERINGS_PATH)
            .then(() => {
                setIsLoading(false);
            });
    }

    const updateAccidentWaiverEndorsement = (checked, driver, path) => {
        const effectiveDate = _.get(jobVM, 'baseData.effectiveDate');
        const periodStartDate = _.get(jobVM, 'baseData.periodStartDate.value');
        const accidentWaiverEndorsements = _.get(paLobDataVM, path);

        if (checked) {
            const endorsement = {
                driverPublicID: driver.publicID,
                purchaseDate: effectiveDate ? effectiveDate.value : periodStartDate
            }
            addCoverage(accidentWaiverEndorsements, endorsement);
        } else {
            removeCoverage(accidentWaiverEndorsements, `${path}.value`, driver);
        }

    }

    const updateMinorConvictionWaiverEndorsement = (checked, driver, path) => {
        const minorConvictionWaiverEndorsements_WMIC = _.get(paLobDataVM, path);

        if (checked) {
            const endorsement = {
                driverPublicID: driver.publicID,
            }
            addCoverage(minorConvictionWaiverEndorsements_WMIC, endorsement);
        } else {
            removeCoverage(minorConvictionWaiverEndorsements_WMIC, `${path}.value`, driver);
        }
    }

    const updateVehicleDriverEndorsement = (checked, driver, path) => {
        const vehicleDriverEndorsement = _.get(paLobDataVM, path);

        if (checked) {
            const endorsement = {
                driverPublicID: driver.publicID,
                vehiclePublicID: selectedVehicleId,
                inUse: true,
                allVehicles: false
            }
            addCoverage(vehicleDriverEndorsement, endorsement);
        } else {
            removeCoverage(vehicleDriverEndorsement, `${path}.value`, driver);
        }
    }

    const removeCoverage = (endorsement, path, driver) => {
        let updatedEndorsements = [];

        if (selectedVehicleId) {
            const endorsementToRemove = _.find(_.get(paLobDataVM, path), {driverPublicID: driver.publicID, vehiclePublicID: selectedVehicleId});
            if (endorsementToRemove) {
                updatedEndorsements = _.without(endorsement.value, endorsementToRemove);
            }
        } else {
            updatedEndorsements = endorsement.value.filter((element) => element.driverPublicID !== driver.publicID);
        }

        _.set(paLobDataVM, path, updatedEndorsements);
        if (updatedEndorsements.length === 0 && coverage) {
            _.set(coverage, 'selected', false);
            _.set(coverage, 'updated', true);
        }
    };

    const addCoverage = (endorsement, value) => {
        endorsement.pushElement(value);
        if (coverage) {
            _.set(coverage, 'selected', true);
            _.set(coverage, 'updated', true);
        }
    }

    return (
        <>
            {drivers.map((driver) => {
                return (
                    <WMICDriverEndorsementsCheckbox 
                        id={id}
                        jobVM={jobVM}
                        driverEndorsement={driverEndorsement}
                        driver={driver}
                        baseState={baseState}
                        rateAsOfDate={rateAsOfDate}
                        selectedVehicleId={selectedVehicleId}
                        paLobDataVM={paLobDataVM}
                        handleChange={handleChange}
                        readOnly={readOnly}
                        hideUnchecked={hideUnchecked}
                    />
                )
            })}
        </>
    );
}

WMICDriverEndorsementsCoverageComponent.propTypes = {
    id: PropTypes.string.isRequired,
    jobVM: PropTypes.shape({}).isRequired,
    driverEndorsement: PropTypes.shape({}).isRequired,
    baseState: PropTypes.shape({}).isRequired,
    rateAsOfDate: PropTypes.shape({}).isRequired,
    onClauseChange: PropTypes.func.isRequired,
    selectedVehicleId: PropTypes.string
};

export default WMICDriverEndorsementsCoverageComponent;
