import React, { useState, useEffect } from 'react';
import { DateField } from '@jutro/components';
import _ from 'lodash';
import styles from './WMICDatePicker.module.scss';

const WMICDatePicker = (props) => {
    const [nameOfClass, setNameOfClass] = useState('wmicWizardInputLabel wmicInactive');
    const [isOnFocus, setIsOnFocus] = useState(false);

    useEffect(() => {
        if (isOnFocus) {
            setNameOfClass('wmicWizardInputLabel wmicActive');
        } else {
            setNameOfClass(`wmicWizardInputLabel ${_.isUndefined(props.value) ? 'wmicInactive' : 'wmicActiveNotFocused'}`);
        }
    }, [props, isOnFocus]);

    return (
        <div className={styles.WMICDatePicker}>
            <DateField
                {...props}
                labelClassName={`${props.labelClassName} ${nameOfClass}`}
                displayFormat='vshort'
                icon="cust-calendar-alt-solid"
                labelContainerClassName={styles.customLabel}
                onFocus={(value) => {
                    setIsOnFocus(true);
                    if (props.onFocus) {
                        props.onFocus(value);
                    }
                }
                }
                onBlur={(value) => {
                    setIsOnFocus(false);
                    if (props.onBlur) {
                        props.onBlur(value);
                    }
                }
                }
            />
        </div>
    );
};

WMICDatePicker.propTypes = {
    ...DateField.propTypes
};

export default WMICDatePicker;
