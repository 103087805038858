import React, { useState, useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { BreakpointTrackerContext } from '@jutro/layout';
import _ from 'lodash';

import { useTranslator } from '@jutro/locale';
import { ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { DeviceBreakpoint, Position } from 'wmic-pe-portals-utils-js';
import { useValidation } from '@xengage/gw-portals-validation-react'
import metadata from './WMICAddPlumbingMaterialComponent.metadata.json5';
import messages from './WMICAddPlumbingMaterialComponent.messages';

function WMICAddPlumbingMaterialComponent(props){
    const {
        id,
        riskView,
        selectedPlumbingMaterial, 
        savePlumbingMaterial,
        cancelPlumbingMaterial,
        onValidate,
        readOnly,
        showErrors: pShowErrors
    } = props;

    const translator = useTranslator();

    const breakpoint = useContext(BreakpointTrackerContext);
    const {onValidate: setComponentValidation, isComponentValid} = useValidation(id);

    const [currentPlumbingMaterial, updateCurrentPlumbingMaterial] = useState(selectedPlumbingMaterial);
    const { refreshData } = useDataRefresh();
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        updateCurrentPlumbingMaterial(selectedPlumbingMaterial);
    }, [riskView, selectedPlumbingMaterial])

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => {
            if(onValidate){
                onValidate(true, id)
            }
        }
    }, [isComponentValid, onValidate, currentPlumbingMaterial, id]);

    const updatePlumbingMaterial = (data) => {
        refreshData();
        updateCurrentPlumbingMaterial(data);
    };

    const handleSavePlumbingMaterial = () => {
        if(isComponentValid) {
            savePlumbingMaterial(currentPlumbingMaterial)
        } else {
            setShowErrors(true);
        }
    };

    const applicableMaterialTypes = useMemo(() => {
        return _.get(riskView, 'constructionDetail.plumbingInfo.materialForm.description.aspects.availableValues', [])
            .map((materialType) => {
                return {
                    code: materialType.code,
                    name: translator({
                        id: materialType.name,
                        defaultMessage: materialType.name
                    })
                };
            });
    }, [riskView, translator]);

    const resolvers = {
        resolveCallbackMap: {
            handleSavePlumbingMaterial,
            cancelPlumbingMaterial
        }
    };

    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === DeviceBreakpoint.DESKTOP ? Position.LEFT : Position.TOP,
            parentNode: currentPlumbingMaterial,
            readOnly
        },
        WMICAddPlumbingMaterialForm: {
            hideButtons: readOnly
        },
        plumbingMaterialType: {
            availableValues: applicableMaterialTypes
        }
    };


    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentPlumbingMaterial}
            overrideProps={overrideProps}
            onModelChange={updatePlumbingMaterial}
            callbackMap={resolvers.resolveCallbackMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors || pShowErrors}
        />
    )
}

WMICAddPlumbingMaterialComponent.propTypes = {
    id: PropTypes.string.isRequired,
    selectedPlumbingMaterial: PropTypes.shape({}).isRequired,
    savePlumbingMaterial: PropTypes.func.isRequired,
    cancelPlumbingMaterial: PropTypes.func.isRequired,
    updateSelectedPlumbingMaterial: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired
};

export default WMICAddPlumbingMaterialComponent;