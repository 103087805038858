import { defineMessages } from 'react-intl';

export default defineMessages({
    policySpecialVehicleTypeLabel: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.SpecialVehicleType',
        defaultMessage: 'Special Vehicle Type',
    },
    policyYearLabel: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.YearLabel',
        defaultMessage: 'Year',
    },
    policyMakeLabel: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.MakeLabel',
        defaultMessage: 'Make',
    },
    policyModelLabel: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.ModelLabel',
        defaultMessage: 'Model',
    },
    policyvinLabel: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.VinLabel',
        defaultMessage: 'Vin',
    },
    vinOverride: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.VINOverride',
        defaultMessage: 'VIN Override',
    },
    vinOverrideReason: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.OverrideReason',
        defaultMessage: 'Override Reason',
    },
    trailerSubtype: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.SubType',
        defaultMessage: 'Sub Type',
    },
    trailerHaulingType: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.Hauled separately or as train?',
        defaultMessage: 'Hauled separately or as train?',
    },
    vehicleLength: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.Length',
        defaultMessage: 'Length',
    },
    bodyStyle: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.BodyType',
        defaultMessage: 'Body Type',
    },
    EngineStroke: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.EngineStroke',
        defaultMessage: 'Engine Stroke',
    },
    CC: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.CC',
        defaultMessage: 'CC',
    },
    vehicleMsrp: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.vehicleMsrp',
        defaultMessage: 'Vehicle Purchase Price (MSRP)',
    },
    CostNew: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.CostNew',
        defaultMessage: 'Current Value/Purchase Price',
    },
    VehicleInspectionRequired: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.VehicleInspectionRequired',
        defaultMessage: 'Vehicle Inspection Required',
    },
    VehicleInspectionComplete: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.VehicleInspectionComplete',
        defaultMessage: 'Vehicle Inspection Complete?',
    },
    VehicleInspectionDate: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.VehicleInspectionDate',
        defaultMessage: 'Date Inspection Completed',
    },
    TypeOfAntiTheftDevice: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.TypeOfAntiTheftDevice',
        defaultMessage: 'Type Of anti-theft device',
    },
    DescriptionOfAntiTheftDevice: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.DescriptionOfAntiTheftDevice',
        defaultMessage: 'Description',
    },
    AntiTheftAndRecoveryDeviceManufacturer: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.AntiTheftAndRecoveryDeviceManufacturer',
        defaultMessage: 'Anti-theft and recovery device manufacturer',
    },
    AntiTheftAndRecoveryDeviceInstallationDate: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.AntiTheftAndRecoveryDeviceInstallationDate',
        defaultMessage: 'Anti-theft and recovery device installation date',
    },
    AutonomousEmergencyBrakingSys: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.AutonomousEmergencyBrakingSys',
        defaultMessage: 'Autonomous Emergency Braking System',
    },
    SearchAndSelect: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.SearchAndSelect',
        defaultMessage: 'Search and select a vehicle by clicking Vehicle Search',
    },
    Search: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.Search',
        defaultMessage: 'Vehicle Search',
    },
    notTrailerTitle: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.VehicleTypeSearch',
        defaultMessage: '{type} Search',
    },
    vehicleTypeInfo: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.VehicleTypeInfo',
        defaultMessage: '{type} Information',
    },
    VINRequiredSearchFields: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.VINRequiredSearchFields',
        defaultMessage: '&lt;span&gt;The vehicle\'s &lt;b&gt;Year&lt;/b&gt; along with either it\'s &lt;b&gt;Make&lt;/b&gt; or &lt;b&gt;VIN&lt;/b&gt; is required for a vehicle search&lt;/span&gt;',
    },
    NoSearchResults: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.NoSearchResults',
        defaultMessage: 'No vehicle match found. Try again or use VIN override.',
    },
    SearchingForVehicleInformation: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.SearchingForVehicleInformation',
        defaultMessage: 'Searching for vehicle information...'
    },
    Select: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.Select',
        defaultMessage: 'Select'
    },
    PleaseTryAgain: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.PleaseTryAgain',
        defaultMessage: 'Please try again later.'
    },
    engineForcedInduction: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.Engine Forced Induction',
        defaultMessage: 'Engine Forced Induction'
    },
    theftDeterrentSystem: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.Theft Deterrent System',
        defaultMessage: 'Theft Deterrent System'
    },
    MSRP: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.MSRP',
        defaultMessage: 'MSRP'
    },
    MSRPUnavailable: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.MSRP Unavailable',
        defaultMessage: 'Unavailable'
    },
    selectVehicle: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyVehicleInfo.SelectVehicle',
        defaultMessage: 'Select a vehicle'
    }

})