import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Loader } from '@jutro/components';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelForm, useDataRefresh, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { Position } from 'wmic-pe-portals-utils-js';
import WMICHODesignatedGardenTractorLiabilityDetailView from './WMICHODesignatedGardenTractorLiabilityDetailView/WMICHODesignatedGardenTractorLiabilityDetailView';
import messages from'./WMICHODesignatedGardenTractorLiability.messages';
import metadata from './WMICHODesignatedGardenTractorLiability.metadata.json5';
import styles from './WMICHODesignatedGardenTractorLiability.module.scss';

const GARDEN_TRACTOR_PATH = 'lobData.homeowners.coverables.designatedGardenTractorExtentionCovItems';
function WMICHODesignatedGardenTractorLiability(props) {
    const {
        jobVM,
        gardenTractorListVM,
        readOnly,
        updateJobAndCoverage,
        showErrors,
        isLoading,
        id,
        isEditMode,
        onValidate
    } = props;

    const translator = useContext(TranslatorContext);
    const ViewModelService = useContext(ViewModelServiceContext);
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const { refreshData } = useDataRefresh();

    const [editingIndex, updateEditingIndex] = useState(-1);
    const [selectedGardenTractor, updateSelectedGardenTractor] = useState(undefined);
    const [addingGardenTractor, setAddingGardenTractor] = useState(false);
    const [selectedGardenTractorIndex, setSelectedGardenTractorIndex] = useState(-1);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const updateDetailsData = useCallback(
        (data) => {
            refreshData();
        },
        [refreshData]
    );

    const addEditGardenTractor = (gardenTractorVM, index) => {
        updateSelectedGardenTractor(gardenTractorVM);

        setSelectedGardenTractorIndex(index);
        setAddingGardenTractor(true);
    }

    const newGardenTractor = () => {
        const newGardenTractorVM = ViewModelService.create({}, 'pc', 'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.dwelling.DesignatedGardenTractorExtentionCovItemDTO_WMIC');
        addEditGardenTractor(newGardenTractorVM, -1);
    }

    const editGardenTractor = (gardenTractorVM, index) => {
        addEditGardenTractor(gardenTractorVM, index);
    };

    const removeGardenTractor = async (gardenTractor, index) => {
        await updateJobAndCoverage();
        gardenTractorListVM.value.splice(index, 1);
    }

    const saveGardenTractor = async() => {
        if (selectedGardenTractorIndex >= 0) {
            gardenTractorListVM.value[selectedGardenTractorIndex] = selectedGardenTractor.value;
        } else {
            gardenTractorListVM.value.push(selectedGardenTractor.value);
        }
        await updateJobAndCoverage()

        setSelectedGardenTractorIndex(-1);
        setAddingGardenTractor(false);
    };

    const cancelGardenTractor = () => {
        updateSelectedGardenTractor(undefined);
        setSelectedGardenTractorIndex(-1);
        setAddingGardenTractor(false);
    };

    const overrideProps = {
        '@field': {
            labelPosition: Position.LEFT,
            parentNode: gardenTractorListVM,
            readOnly: !isEditMode || readOnly
        },
        designatedGardenTractorList: {
            VMList: _.get(jobVM, `${GARDEN_TRACTOR_PATH}.children`, []),
            VMData: [
                {
                    headerText: translator(messages.year),
                    path: 'year'
                },
                {
                    headerText: translator(messages.make),
                    path: 'make'
                },
                {
                    headerText: translator(messages.model),
                    path: 'model'
                },
                {
                    headerText: translator(messages.serialNumber),
                    path: 'serialNumber'
                },
                {
                    headerText: translator(messages.horsepower),
                    path: 'horsepower'
                },
            ],
            onEditAction: editGardenTractor,
            onRemoveAction: removeGardenTractor,
            flatCards: true,
            clickable: false,
            selectedCardIndex: editingIndex,
            updateSelectedCardIndex: updateEditingIndex,
            isEditing: addingGardenTractor,
            readOnly,
        },
        addDesignatedGardenTractorListButton: {
            disabled: addingGardenTractor,
            visible: !readOnly
        },
        gardenTractorForm: {
            visible: addingGardenTractor,
            designatedGardenTractorVM: selectedGardenTractor,
            saveGardenTractor,
            cancelGardenTractor,
            onValidate,
            showErrors
        },

    };

    const resolvers = {
        resolveComponentMap: {
            WMICHODesignatedGardenTractorLiabilityDetailView
        },
        resolveCallbackMap: {
            newGardenTractor
        },
        resolveClassNameMap: styles
    };

    return (
        <Loader loaded={!isLoading}>
            <ViewModelForm
                model={gardenTractorListVM}
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                onModelChange={updateDetailsData}
                onValidationChange={setComponentValidation}
                showErrors={!isComponentValid}
            />
        </Loader>
    );
}

WMICHODesignatedGardenTractorLiability.propTypes = {
};

WMICHODesignatedGardenTractorLiability.defaultProps = {
};

export default WMICHODesignatedGardenTractorLiability;
