import React, { useCallback, useContext } from 'react';

import { pageTemplateProps, WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { WMICWizardSingleErrorComponent } from 'wmic-pe-portals-wizard-components-ui'
import WMICWizardPageHeader from '../../components/WizardPageHeader/WMICWizardPageHeader'
import WMICDigitalWizardPageTemplate from '../WMICDigitalWizardPageTemplate';

import styles from './WMICDigitalWizardPageWithTitleTemplate.module.scss';

const WMICQuotePageRegex = new RegExp("(QuotePage|RenewalQuote)$");

function WMICDigitalWizardPageWithTitleTemplate(props) {
    const {
        children, acknowledgeError, errorsForStep, isSideHeader, customValidationMessages, ...otherProps
    } = props;
    const { currentStep } = useContext(WizardContext);
    const isNotQuotePage = !WMICQuotePageRegex.test(currentStep.id);

    const renderWizardHeader = useCallback(
        (wizardData) => {
            const getSubmissionVM = wizardData.submissionVM || wizardData;
            return (
                <WMICWizardPageHeader wizardSubmission={getSubmissionVM} isSideHeader={isSideHeader} />
            );
        },
        [isSideHeader]
    );

    return (
        <div className={styles.wizardContainer}>
            <WMICDigitalWizardPageTemplate
                renderContextComponent={renderWizardHeader}
                showContextComponent={false}
                showWizardHeader
                {...otherProps}
            >
                {isNotQuotePage && <WMICWizardSingleErrorComponent
                    acknowledgeError={acknowledgeError}
                    issuesList={errorsForStep}
                    customValidationMessages={customValidationMessages}
                />}
                {children}
            </WMICDigitalWizardPageTemplate>
        </div>
    );
}

WMICDigitalWizardPageWithTitleTemplate.propTypes = pageTemplateProps;
export default WMICDigitalWizardPageWithTitleTemplate;
