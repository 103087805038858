import { defineMessages } from 'react-intl';

export default defineMessages({
    contactUnderwriter: {
        id: 'agent.views.policy-landing.Contact Underwriter',
        defaultMessage: 'Please contact an underwriter.'
    },    
    underwriterIssues: {
        id: 'agent.views.policy-landing.Underwriter Issues',
        defaultMessage: 'The policy cannot be issued at this time:'
    },
});