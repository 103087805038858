import _ from 'lodash';

function formatAvailableValue(availableValue) {
    if (availableValue && availableValue.hasOwnProperty('displayName') && availableValue.hasOwnProperty('id')) {
        return availableValue;
    }
    return {
        ...availableValue,
        displayName: {
            defaultMessage: availableValue.name || availableValue.code,
            id: availableValue.name
        },
        id: availableValue.code
    };
}

function translateMessages(translator, messages = []) {
    return messages.map((message) => translator(message));
}

export function getDataPropsResolver({ model, translator }) {
    const translate = _.partial(translateMessages, translator);

    return function resolveDataProps(_id, path) {
        const vmNode = _.get(model, path, {}) || {};
        const { aspects = {} } = vmNode;
        const {
            required,
            availableValues,
            validationMessages,
            visible,
            inputCtrlType
        } = aspects;

        return {
            datatype: inputCtrlType,
            componentProps: {
                availableValues: availableValues ? availableValues.map(formatAvailableValue) : [],
                validationMessages: translate(validationMessages),
                visible,
                schemaRequired: required
            }
        };
    }

}
