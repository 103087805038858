import React  from 'react';
import { AccountService } from 'wmic-pe-capability-gateway-policy';
import {
    RENEWAL_DISPLAY_STATUSES
} from 'wmic-pe-portals-utils-js';
import WMICTabsCommonUtil from '../components/WMICTabsCommonUtil';
import messages from '../WMICLanding.messages';
import constants from '../../constants';
import styles from '../WMICLandingPage.module.scss';

const getPolicyNumberLink = (element, translator) => WMICTabsCommonUtil.getPolicyNumberLink(element, translator);

const getFormattedDateCell = (rowData) =>
    WMICTabsCommonUtil.getFormattedDateCell(new Date(rowData.renewalDate.year, rowData.renewalDate.month, rowData.renewalDate.day));

const formatRemainingTime = (element, translator) => {
    let timeUntilRenewalIssuedMsg = '';

    if (!element.disableLink) {
        timeUntilRenewalIssuedMsg = element.timeUntilRenewalIssued === 1
            ? translator(messages.dayUntilRenewalIssued)
            : translator(messages.daysUntilRenewalIssued ,
            {numberOfDays: element.timeUntilRenewalIssued});

        if (element.timeUntilRenewalIssued <= 5) {
            timeUntilRenewalIssuedMsg = (
                <span className={styles.timeUntilRenewalIssued5daysOrLess}>
                    { timeUntilRenewalIssuedMsg }
                </span>
            );
        }
    } else {
        timeUntilRenewalIssuedMsg = translator(messages.notAvailable);
    }

    return timeUntilRenewalIssuedMsg;
}


const displayColumns = (translator) => [
        {
            id: "jobNumber",
            path: "jobNumber",
            headerClassName: "headerText",
            textAlign: "left",
            renderCell: (rowData) => getPolicyNumberLink(rowData, translator),
            header: {
                id: 'upcoming.agent.directives.PolicySummary.Policy Number',
                defaultMessage: 'meta.Policy'
            }
        },
        {
            id: "insured",
            headerClassName: "headerText",
            textAlign: "left",
            renderCell: WMICTabsCommonUtil.getFormattedNameAddressCell,
            header: {
                id: 'upcoming.agent.directives.PolicySummary.Insured',
                defaultMessage: 'meta.Insured'
            }
        },
        {
            id: "renewalDate",
            path: "renewalDate",
            headerClassName: "headerText",
            textAlign: "left",
            renderCell: getFormattedDateCell,
            header: {
                id: 'upcoming.agent.directives.PolicySummary.Renewal Date',
                defaultMessage: 'meta.Renewal Date'
            }
        },
        {
            id: "status",
            path: "status",
            headerClassName: "headerText",
            textAlign: "left",
            header: {
                id: 'upcoming.agent.directives.PolicySummary.Status',
                defaultMessage: 'meta.Status'
            }
        },
        {
            id: "policyRenewalDate",
            path: "policyRenewalDate",
            headerClassName: "headerText",
            textAlign: "left",
            renderCell: (rowData) => formatRemainingTime(rowData, translator),
            header: {
                id: 'upcoming.agent.directives.PolicySummary.Remaining Time',
                defaultMessage: 'meta.Remaining Time'
            }
        },
    ]

const transformData = (data) => {
    const AVAILABLE_FOR_CHANGES_DISPLAY_STATUSES = [RENEWAL_DISPLAY_STATUSES.AVAILABLE_FOR_CHANGES, RENEWAL_DISPLAY_STATUSES.AVAILABLE_FOR_CHANGES_FR];
    const disableLink = !AVAILABLE_FOR_CHANGES_DISPLAY_STATUSES.includes(data.renewalDisplayStatus);

    const policyDataToReturn = {
        jobNumber: data.jobNumber,
        policyNumber: data.policyNumber,
        insured: data.accountHolderName,
        policyAddress: data.policyAddress,
        renewalDate: data.policyEffectiveDate,
        status: data.renewalDisplayStatus,
        policyRenewalDate: data.policyEffectiveDate,
        policyLines: data.policyLines,
        productCode: data.product.productCode,
        timeUntilRenewalIssued: data.timeUntilRenewalIssued,
        rowClickDestination: constants.DESTINATION_RENEWAL,
        disableLink,
    };

    return policyDataToReturn;
};

const loadDataAsyncSubmission = (page, pageSize, queryParams, authHeader) =>
    AccountService.getAccountRenewalJobsForCurrentUser(queryParams, page, pageSize, authHeader).then((response) => {
        const { jobs } = response;
        const simpleResponse = [];

        if (jobs) {
            jobs.forEach((policy) => {
                simpleResponse.push(transformData(policy));
            });
        }

        return {
            rows: simpleResponse,
            numberOfRows: response.maxNumberOfResults,
        }
    });

export default {
    tabHeading: messages.upcomingRenewalsPoliciesTab,
    displayColumns,
    loadDataAsyncSubmission,
    hideFilters: true,
}
