import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { CancellationService } from 'gw-capability-gateway';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { DependencyProvider } from '@xengage/gw-portals-dependency-react';
import { GatewayDocumentService } from 'gw-capability-gateway-document';
import { isCapabilityEnabled } from '@xengage/gw-portals-config-js';
import { DocumentsPage } from 'gw-capability-document-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { withRouter } from 'react-router-dom';
import { PageHeaderComponent } from '../Components/FormattedHeaderComponent/FormattedHeaderComponent';
import CommonRoutes from '../Common/CommonRouting';
import metadata from './Cancellation.metadata.json5';
import pageRouting from '../Common/Cancellation-config.json5';

const { capabilitiesConfig } = appConfig;

const DocumentPageService = {
    getDocuments: CancellationService.getDocumentsForCancellation,
    generateUploadToken: GatewayDocumentService.generateUploadToken,
    uploadDocument: GatewayDocumentService.uploadDocument,
    removeDocument: GatewayDocumentService.removeDocument,
    downloadDocument: GatewayDocumentService.downloadDocument
};

function getRoutes() {
    const documentRoute = {
        path: '/documents',
        component: DocumentsPage
    };
    if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'document' })) {
        return [...pageRouting, documentRoute];
    }
    return pageRouting;
}

class Cancellation extends Component {
    static propTypes = {
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }).isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                jobNumber: PropTypes.string
            }),
            url: PropTypes.string
        }).isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string
        }).isRequired
    };

    state = {
        currentView: 'summary',
        tilesCountCancellationData: {},
        cancellation: {}
    };

    componentDidMount() {
        this.getCancellationSummaryCount();
        this.getJobByJobNumber();
    }

    handleTilesOnClick = (id) => {
        this.setState({ currentView: id });
    };

    getCancellationSummaryCount = () => {
        const {
            authHeader,
            match: {
                params: { jobNumber }
            },
            location: { pathname }
        } = this.props;

        const routePath = pathname && (pathname.trim().split('/') || []);
        const selectedPageFromPath = routePath[routePath.length - 1];
        const activeTile = selectedPageFromPath || 'summary';

        CancellationService.getCancellationSummary(jobNumber, authHeader).then((tilesData) => {
            this.setState({
                currentView: activeTile,
                tilesCountCancellationData: tilesData
            });
        });
    };

    updateCancellationSummary = (cancellation) => {
        this.setState({ cancellation });
    };

    getJobByJobNumber = async () => {
        const {
            authHeader,
            match: {
                params: { jobNumber }
            }
        } = this.props;

        const cancellation = await CancellationService.findJobByJobNumber(jobNumber, authHeader);
        this.updateCancellationSummary(cancellation);
    };

    render() {
        const { currentView, tilesCountCancellationData, cancellation } = this.state;

        if (_.isEmpty(cancellation)) {
            return null;
        }

        const overrides = {
            [currentView]: {
                active: true
            }
        };
        const cancellationVM = { cancellation, tilesCountCancellationData };
        const resolvers = {
            resolveCallbackMap: {
                handleTilesOnClick: this.handleTilesOnClick
            },
            resolveComponentMap: {
                pageheadercomponent: PageHeaderComponent
            }
        };
        const {
            match: {
                url,
                params: { jobNumber }
            }
        } = this.props;
        const quoteData = {
            jobNumber: jobNumber,
            loadCancellationSummary: cancellation,
            updateJobSummary: this.updateCancellationSummary,
            getCancellationSummaryCount: this.getCancellationSummaryCount
        };
        return (
            <DependencyProvider value={{ DocumentPageService }}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={cancellationVM}
                    overrideProps={overrides}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
                <CommonRoutes
                    steps={getRoutes()}
                    basePath={url}
                    setDocumentCount={this.getCancellationSummaryCount}
                    jobNumber={jobNumber}
                    quoteDetailsData={quoteData}
                />
            </DependencyProvider>
        );
    }
}

export default withRouter(withAuthenticationContext(Cancellation));
