import { defineMessages } from 'react-intl';

export default defineMessages({
    deductibleColumnHeading: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-terms-table.deductible-heading',
        defaultMessage: 'Deductible'
    },
    limitColumnHeading: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-terms-table.limit-heading',
        defaultMessage: 'Limit'
    },
    premiumColumnHeading: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-terms-table.premium-heading',
        defaultMessage: 'Premium'
    },
    annualPremiumColumnHeading: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-terms-table.annual-premium-heading',
        defaultMessage: 'Annual Premium'
    },
    totalInsuredLimitLabel: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-terms-table.total-insured-limit-label',
        defaultMessage: 'Total Insured Limit'
    },
    proratedDifference: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.coverage-terms-table.prorated-difference',
        defaultMessage: 'Prorated Difference'
    }
});
