import { PolicyService } from 'gw-capability-gateway';
import WMICTabsCommonUtil from '../components/WMICTabsCommonUtil';
import constants from '../../constants.js';
import messages from '../WMICLanding.messages';

const getPolicyNumberLink = (element, translator) => WMICTabsCommonUtil.getPolicyNumberLink(element, translator);

const displayColumns = (translator) =>
    [
        {
            id: "clientName",
            path: "clientName",
            headerClassName: "headerText",
            textAlign: "left",
            header: {
                id: "viewed.agent.directives.PolicySummary.Client Name",
                defaultMessage: 'meta.Name'
            }
        },
        {

            id: "address",
            path: "address",
            headerClassName: "headerText",
            textAlign: "left",
            columnProportion: 2,
            header: {
                id: "viewed.agent.directives.PolicySummary.Policy Address",
                defaultMessage: "meta.Address"
            }

        },
        {

            id: "jobNumber",
            path: "jobNumber",
            renderCell: (rowData) => getPolicyNumberLink(rowData, translator),
            headerClassName: "headerText",
            textAlign: "left",
            header: {
                id: "viewed.agent.directives.PolicySummary.Policy Number",
                defaultMessage: "meta.Policy"
            }
        },
        {

            id: "prior",
            path: "prior",
            headerClassName: "headerText",
            textAlign: "left",
            header: {
                id: "viewed.agent.directives.PolicySummary.Previous Policy Number",
                defaultMessage: "meta.Prior"
            }

        },
        {

            id: "broker",
            path: "broker",
            headerClassName: "headerText",
            textAlign: "left",
            header: {
                id: "viewed.agent.directives.PolicySummary.Broker #",
                defaultMessage: "meta.Broker"
            }

        }
    ];

const transformData = (data) => {
    const policyDataToReturn = {
        jobNumber: `${data.policyNumber}/${data.effectiveTermNumber}`,
        address: data.primaryInsuredAddress,
        clientName: data.primaryInsuredName,
        prior: data.pMSPolicyNumber_WMIC,
        broker: data.brokerNumber,
        productCode: data.product.productCode,
        policyNumber: data.policyNumber,
        rowClickDestination: constants.DESTINATION_POLICIES
    };

    return policyDataToReturn;
};

const loadDataAsyncSubmission = (page, pageSize, queryParams, authHeader) => {
    const searchCriteria = {
        searchBy: WMICTabsCommonUtil.formatSearchBy(queryParams.producerCode),
        daysLimit: constants.POLICIES_CREATED_IN_LAST_DAYS,
        resultLimit: constants.MAX_NUBER_RECENTLY_VIEWED_POLICIES,
        producerCodes: WMICTabsCommonUtil.formatProducerCodes(queryParams.producerCode)
    };
    return PolicyService.getRecentlyViewedPolicies(searchCriteria, authHeader).then((response) => {
        const simpleResponse = [];

        response.forEach((policy) => {
            simpleResponse.push(transformData(policy));
        });

        return {
            rows: simpleResponse,
            numberOfRows: simpleResponse.length,
        }
    });
}

export default {
    tabHeading: messages.openRecentlyViewedPoliciesTab,
    displayColumns,
    loadDataAsyncSubmission,
    hideFilters: true,
}
