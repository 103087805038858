import React, { useEffect } from 'react'
import _ from 'lodash'
import { ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'


import metadata from './WMICPUPWatercraftExposureForm.metadata.json5';
import messages from '../WMICPUPWatercraftExposures.messages.js'

function WMICPUPWatercraftExposureForm(props) {
    const {
        id,
        exposureVM,
        setExposureVM,
        cancelExposure,
        saveExposure,
        isEditing,
        isParentUnderEditing,
        onValidate,
        showErrors
    } = props;

    const { refreshData } = useDataRefresh();
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [isComponentValid, onValidate, id]);

    const updateExposure = (exposure) => {
        setExposureVM(exposure);
        refreshData();
    };

    const resolvers = {
        resolveComponentMap: {},
        resolveCallbackMap: {}
    }

    const overrideProps = {
        '@field': {
            parentNode: exposureVM,
            readOnly: !isEditing || !isParentUnderEditing
        },
        watercraftExposureContainerButtons: {
            onCancel: cancelExposure,
            onSave: saveExposure,
            isEditing: isEditing
        }
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={exposureVM}
            onModelChange={updateExposure}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

export default WMICPUPWatercraftExposureForm;