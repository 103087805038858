import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    ModalNext,
    Loader
} from '@jutro/components';
import cx from 'classnames';
import styles from './WMICProgressModal.module.scss';

export function WMICProgressModal(props) {
    const {
        modalTitle,
        isOpen,
        onResolve,
        onReject,
        promise,
        isSmallModal
    } = props;

    useEffect(() => {
        promise && promise.then(
            (response) => onResolve(response)
        ).catch((error) => {
            onReject(error)
        });
    }, [onReject, onResolve, promise]);

    const modalStyle = cx({
        [styles.wmicPeProgressModalSmall]: isSmallModal,
        [styles.wmicPeProgressModal]: !isSmallModal
    });

    return (
        <ModalNext className={modalStyle} isOpen={isOpen}>
            <div>
                <span className={styles.wmicPeProgressModalTitle}>{modalTitle}</span>
            </div>
            <div>
                <Loader loaded={!isOpen} />
            </div>
        </ModalNext>
    );
}

WMICProgressModal.propTypes = {
    modalTitle: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    promise: PropTypes.shape({
        then: PropTypes.func
    }),
    onResolve: PropTypes.func,
    onReject: PropTypes.func,
    isSmallModal: PropTypes.bool
};

WMICProgressModal.defaultProps = {
    isSmallModal: false,
};

export default WMICProgressModal;