import React, { useContext, useCallback } from 'react';
import _ from 'lodash';

import { BreakpointTrackerContext } from '@jutro/layout';

import { pageTemplateProps } from '@xengage/gw-portals-wizard-react';
import { MediaSideBar } from 'gw-components-platform-react';
import { WMICDigitalWizardPageTemplateQnB, WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { ERROR_CODE } from 'wmic-portals-utils-js';
import { useModal } from '@jutro/components';
import { WMICModal } from 'gw-capability-quoteandbind-common-react';
import WizardSidebarButtons from './WizardSidebarButtons/WizardSidebarButtons';
import { QuoteInfoComponentContext } from './QuoteInfoComponentContext';
import CommonQuoteInfo from './CommonQuoteInfo/CommonQuoteInfo';

import messages from './WMICQuoteWizardPageTemplateQnB.messages';

function WMICQuoteWizardPageTemplateQnB(props) {
    const modalApi = useModal();
    const quoteInfoComponent = useContext(QuoteInfoComponentContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const isPhone = breakpoint === 'phone';
    const {
        children, acknowledgeError, errorsForStep, ...otherProps
    } = props;

    const renderMediaSidebar = useCallback(() => {
        const isMobile = breakpoint === 'tablet' || isPhone;
        return (
            <React.Fragment>
                <WizardSidebarButtons />
                {isMobile ? null : <MediaSideBar />}
            </React.Fragment>
        );
    }, [breakpoint, isPhone]);

    const renderQuoteInfoBar = useCallback(
        (submissionVM) => {
            if (isPhone) {
                return <CommonQuoteInfo submissionVM={submissionVM} />;
            }
            // We need a variable with Pascal case in order to render it with React
            // however if we named the variable to respect casing
            // the hooks linter would fail to recognize it as a dependency.
            // To not disable the rule, and circumvent the issue, we alias the variable;
            const QuoteInfo = quoteInfoComponent;
            return <QuoteInfo submissionVM={submissionVM} />;
        },
        [isPhone, quoteInfoComponent]
    );

    const handleError = useCallback(
        (error) => {
            const errorCode = error.code
            if (errorCode === ERROR_CODE.PAYMENT || errorCode === ERROR_CODE.DAY_TRANSITION || errorCode === ERROR_CODE.AUTHORIZATION) {
                modalApi.showModal(
                    <WMICModal
                        title={messages[error.code]}
                        message={error.paymentErrors}
                        iconClass="error-text fa-3x fa fa-exclamation-circle"
                    />
                ).then(() => {
                    return _.noop();
                }, _.noop);
            } else {
                throw error;
            }
        },
        []
    );

    return (
        <ErrorBoundary onError={handleError}>
            <WMICDigitalWizardPageTemplateQnB
                renderContextComponent={renderQuoteInfoBar}
                renderWidgets={renderMediaSidebar}
                errorsForStep={errorsForStep}
                {...otherProps}
            >
                <WizardSingleErrorComponent
                    acknowledgeError={acknowledgeError}
                    issuesList={errorsForStep}
                />
                {children}
            </WMICDigitalWizardPageTemplateQnB>
        </ErrorBoundary>
    );
}

WMICQuoteWizardPageTemplateQnB.propTypes = pageTemplateProps;
export default WMICQuoteWizardPageTemplateQnB;
