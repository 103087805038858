import { defineMessages } from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'quoteandbind.views.quote-effective-date.Cancel',
        defaultMessage: 'Cancel'
    },
    save: {
        id: 'quoteandbind.views.quote-effective-date.Save',
        defaultMessage: 'Save'
    },
    liabilityCov: {
        id: 'agent.views.account-detail.LiabilityCoverage',
        defaultMessage: 'Policy Limits'
    },
    invalidConsent: {
        id: 'quoteandbind.views.quote-effective-date.Invalid Credit Consent',
        defaultMessage: 'Consent has not been received for the credit score factor'
    },
    addIns: {
        id: 'quoteandbind.views.quote-effective-date.Add Insured',
        defaultMessage: 'Add Insured'
    },
    // TODO: get french translation
    notRelated: {
        id: 'quoteandbind.views.quote-effective-date.Not Related',
        defaultMessage: 'Not Related'
    },
    savingQuoteSubmissionDetails: {
        id: 'quoteandbind.views.quote-effective-date.Saving Quote Submission Details',
        defaultMessage: 'Saving Quote Submission Details'
    },
    anErrorOccurred: {
        id: 'wmic.quoteandbind.pa.wizard.error',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'wmic.quoteandbind.pa.wizard.error.title',
        defaultMessage: 'Error'
    },
    saveQuoteError: {
        id: 'wmic.quoteandbind.pa.wizard.Unable to quote',
        defaultMessage: 'Unable to quote'
    },
    saveQuoteErrorMessage: {
        id: 'wmic.quoteandbind.pa.wizard.An error occurred while attempting to quote.',
        defaultMessage: 'An error occurred while attempting to quote.'
    }
});
