//TODO This function is defined this way in V8, I guess they are planning to change the hasFeature Fn in the future, otherwise this can be substituted for true in every call
function hasFeatureFn () {
    return true;
}  

function addFeatures (featureCategories, has) {
    for (const featureCategory in featureCategories) {
        if (featureCategories.hasOwnProperty(featureCategory)) {
            if (Array.isArray(featureCategories[featureCategory])) {
                for (let i = 0; i < featureCategories[featureCategory].length; i++) {
                    if (!has.add(featureCategory + '.' + featureCategories[featureCategory][i])) {
                        has.add(featureCategory + '.' + featureCategories[featureCategory][i], hasFeatureFn());
                    }
                }
            } else if (!has.add(featureCategory)) {
                has.add(featureCategory, hasFeatureFn());
            }
            
        }
    }
    return has;
};

export default class HasFeatureService {

    static getApplicationFeatures(has, appFeatures) {
        const featureCategories = (appFeatures) ? JSON.parse(appFeatures) : {};
        has = addFeatures(featureCategories, has);
        return featureCategories
    }

}