import { defineMessages } from 'react-intl';

export default defineMessages({
    saveAndExit: {
        id: 'wmic-pe-capability-gateway-quoteandbind-common-react.QuoteAndBindCommon.Save and exit',
        defaultMessage: 'Save and exit'
    },
    quoting: {
        id: 'wmic-pe-capability-gateway-quoteandbind-common-react.QuoteAndBindCommon.Quoting',
        defaultMessage: 'Quoting...'
    },
    quote: {
        id: 'wmic-pe-capability-gateway-quoteandbind-common-react.QuoteAndBindCommon.Quote',
        defaultMessage: 'Quote'
    },
    next: {
        id: 'wmic-pe-capability-gateway-quoteandbind-common-react.QuoteAndBindCommon.Next',
        defaultMessage: 'Next'
    },
    wantToJump: {
        id: 'wmic-pe-capability-gateway-quoteandbind-common-react.Leave this page',
        defaultMessage: 'Leave this page?'
    },
    wantToJumpMessage: {
        id: 'wmic-pe-capability-gateway-quoteandbind-common-react.Changes not saved will be lost',
        defaultMessage: 'Changes not saved will be lost, do you want to continue?'
    },
    savingQuoteSubmissionDetails: {
        id: 'wmic-pe-capability-gateway-quoteandbind-common-react.QuoteAndBindCommon.Saving Transaction Details',
        defaultMessage: 'Saving Transaction Details'
    },
    revalidationRequiredTitle: {
        id: 'wmic-pe-capability-gateway-quoteandbind-common-react.QuoteAndBindCommon.Revalidation Required',
        defaultMessage: 'Revalidation Required'
    },
    revalidationRequiredBody: {
        id: 'wmic-pe-capability-gateway-quoteandbind-common-react.QuoteAndBindCommon.Adding this item will require',
        defaultMessage: 'Adding this item will require you to revalidate the information on the current and subsequent pages. Would you like to continue with this change?'
    },
});
