import { React, useState, useEffect } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import {
    WMICIcon,
    WMICHeading,
    WMICLink,
} from 'wmic-pe-components-platform-react';
import { ICON_NAMES, WMICVariousUtil, WMICUserAccessUtil } from 'wmic-pe-portals-utils-js';
import { Icon } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import metadata from './WMICTransactionDetailsHeader.metadata.json5';
import messages from './WMICTransactionDetailsHeader.messages';
import styles from './WMICTransactionDetailsHeader.module.scss';

const WMICTransactionDetailsHeader = props => {
    const { jobVM, lobWithJobNumber, history } = props;
    const { authUserData } = useAuthentication();

    const [jobAccountName, setAccountName] = useState('');
    const [routePath, setRoutePath] = useState('');
    const canEditAccount = WMICUserAccessUtil.canEditAccount(authUserData.roles);

    const translator = useTranslator();

    const getTitleIcon = () => {
        const productCode = _.get(jobVM, 'productCode');
        const icon = WMICVariousUtil.getLobIcon(productCode);
        return (
            <WMICIcon
                icon={icon}
                id="lobIcon"
                size="large"
                className={cx(styles.lobIcon, 'gw-mr-2', 'gw-mt-1')} />
        );
    };

    /**
      @key (displayName) is responsible for the @key (accountDisplayName)
      if the displayName is not available, we check individually for the accountName. so, we are trying to get it from firstName and lastName.
      @key contactName comes from the db column 'name' from the file contact.en in the contact.en table. The name refers to pc_contact table.
      @key accountName refers to name column of contact entity particularly accountHoldersContact instance.
      if accountDisplayName is not valid then we are obtaining data from either @key (accountHolder.contactName) or @key (accountName)
     */

    const getAccountName = () => {
        const accountDisplayName = _.get(jobVM, 'policy.account.accountHolder.displayName')
            ?? `${_.get(jobVM, 'policy.account.accountHolder.firstName', '')} ${_.get(jobVM, 'policy.account.accountHolder.lastName', '')}`;

        return _.trim(accountDisplayName)
            || _.get(jobVM, 'policy.account.accountHolder.contactName')
            || _.get(jobVM, 'policy.account.accountName');
    };

    useEffect(() => {
        setAccountName(getAccountName());
        const accountNumber = _.get(jobVM, 'policy.account.accountNumber');
        setRoutePath(`/accounts/${accountNumber}/summary`);
    }, [jobVM]);

    const getAddress = () => {
        return _.get(jobVM, 'policy.account.accountHolder.primaryAddress.displayName', '');
    };

    const handleBackToPolicySummary = () => {
        const policyNumber = _.get(jobVM, 'policy.policyNumber');
        const selectedTerm = _.get(jobVM, 'policy.latestPeriod.termNumber_WMIC');
        const redirectPath = selectedTerm && policyNumber
            ? `/policies/${policyNumber}/${selectedTerm}/summary`
            : '/';
        history.push(redirectPath);
    };

    const jobAccountDetails = () => (
        <div>
            <div className={cx('wizardHeaderJobDetails', 'gw-mb-5')}>
                {getTitleIcon()}
                <WMICHeading variant="h2" title={lobWithJobNumber} />
            </div>
            <div>
                <div className={styles.accountHolderName}>
                    {jobAccountName}
                </div>
                <p>
                    {getAddress()}
                    {canEditAccount && <WMICLink to={routePath}>
                        <Icon
                            icon={ICON_NAMES.ICON_EDIT}
                            id="iconEdit"
                            size="small"
                            className="gw-ml-1" />
                        <span className={styles.editAccount}>
                            {translator(messages.editAccount)}
                        </span>
                    </WMICLink>}
                </p>
            </div>
        </div>
    );

    const overrideProps = {
        pageHeaderContainer: {
            content: jobAccountDetails()
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onBackToPolicySummary: handleBackToPolicySummary
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap} />
    );
};
export default WMICTransactionDetailsHeader;
