import React, { useState, useEffect } from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react'

import metadata from './WMICHODesignatedGardenTractorLiabilityDetailView.metadata.json5';
import messages from '../WMICHODesignatedGardenTractorLiability.messages'

function WMICHODesignatedGardenTractorLiabilityDetailView(props) {

    const {
        id,
        designatedGardenTractorVM,
        saveGardenTractor,
        cancelGardenTractor,
        onValidate: parentOnValidate,
        showErrors: parentShowErrors
    } = props;


    const { onValidate, isComponentValid } = useValidation(id);
    const [showErrors, updateShowErrors] = useState(false);
    const [currentGardenTractor, updateCurrentGardenTractor] = useState(designatedGardenTractorVM);

    useEffect(()=> {
        if (parentOnValidate) {
            parentOnValidate(isComponentValid, id)
        }
    }, [parentOnValidate, id, isComponentValid])

    const handleSaveGardenTractor = () => {
        if (isComponentValid) {
            saveGardenTractor(currentGardenTractor)
        } else {
            updateShowErrors(true)
        }
    };

    const updateGardenTractor = (data) => {
        updateCurrentGardenTractor(_.clone(data));
    };

    const resolvers = {
        resolveCallbackMap: {
            handleSaveGardenTractor,
            cancelGardenTractor
        }
    };

    const overrideProps = {
        '@field': {
            parentNode: designatedGardenTractorVM
        },
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={designatedGardenTractorVM}
            overrideProps={overrideProps}
            onModelChange={updateGardenTractor}
            callbackMap={resolvers.resolveCallbackMap}
            onValidationChange={onValidate}
            showErrors={showErrors || parentShowErrors}
        />
    )
}

WMICHODesignatedGardenTractorLiabilityDetailView.propTypes = {
};

export default WMICHODesignatedGardenTractorLiabilityDetailView;

