import React, {} from 'react';
import { withRouter } from 'react-router-dom';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import constants from '../constants';
import metadata from './WMICAccounts.metadata.json5';
import AccountSearchComponents from './Components/AccountSearch/AccountSearchComponent';


function WMICAccounts() {
    const searchByValue = constants.SEARCH_BY_VALUE_MY_WORK;

    const override = {
        wmicOpenSubmissionsTable: {
            data: searchByValue,
        },
        wmicOpenPolicyTransactionsTable: {
            data: searchByValue,
        }
    };

    const resolvers = {
        resolveComponentMap: {
            accountsearchcomponent: AccountSearchComponents
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            callbackMap={resolvers.resolveCallbackMap}
            overrideProps={override}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default withRouter(WMICAccounts);
