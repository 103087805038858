/* eslint-disable max-len */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button, Icon } from '@jutro/components';
import { WMICCheckbox } from 'wmic-components-platform-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import _ from 'lodash';
import styles from './WMICPremiumBoxSummary.module.scss';
import messages from './WMICPremiumBox.messages';

const WMICPremiumBoxSummary = ({
    fullAmount
}) => {
    const translator = useTranslator();
    const intl = useContext(IntlContext);

    const formattedAmount = (currAmount) => intl.formatNumber(
        _.get(currAmount, 'amount'),
        {
            style: 'currency',
            currency: _.get(currAmount, 'currency'),
            currencyDisplay: 'symbol'
        }
    );

    return (
        <div className={styles.WMICPremiumBox}>
            <div className={styles.key}>{translator(messages.yourTotalPremium)}</div>
            <div className={styles.value}>{formattedAmount(fullAmount)}</div>
        </div>
    );
};

WMICPremiumBoxSummary.propTypes = {
    fullAmount: PropTypes.string
};

WMICPremiumBoxSummary.defaultProps = {
    fullAmount: undefined
};

export default WMICPremiumBoxSummary;