import { defineMessages } from 'react-intl';

export default defineMessages({
    anErrorOccurred: {
        id: 'quoteandbind.ho.wizard.error.cancel',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'quoteandbind.ho.wizard.error.cancel.title',
        defaultMessage: 'Error'
    },
    home: {
        id: 'quoteandbind.wizard.step.Home',
        defaultMessage: 'Home'
    },
    saveQuoteError: {
        id: 'quoteandbind.ho.wizard.Unable to quote',
        defaultMessage: 'Unable to quote'
    },
    saveQuoteErrorMessage: {
        id: 'quoteandbind.ho.wizard.An error occurred while attempting to quote.',
        defaultMessage: 'An error occurred while attempting to quote.'
    },
    qualifications: {
        id: 'quoteandbind.wizard.step.Qualifications',
        defaultMessage: 'Qualifications'
    },
    risk: {
        id: 'quoteandbind.wizard.step.Risk',
        defaultMessage: 'Risk'
    },
    coverages: {
        id: 'quoteandbind.wizard.step.Coverages',
        defaultMessage: 'Coverages'
    },
    scheduledItems: {
        id: 'quoteandbind.wizard.step.Scheduled Items',
        defaultMessage: 'Scheduled Items'
    },
    insuranceHistory: {
        id: 'quoteandbind.wizard.step.Insurance History',
        defaultMessage: 'Insurance History'
    },
    progress: {
        id: 'quoteandbind.views.qnb.Progress',
        defaultMessage: 'Progress'
    }
});
