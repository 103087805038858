const today = new Date();

// Adding date suffix to force browser cache to override previous versions of these files
const wmicPEDefaultTheme = {
    "name": "sampleTheme",
    "baseTheme": "Enterprise",
    "variableOverrides": `/styles/wmicPEDefaultTheme/themeVariableOverrides.css?${today.getTime()}`,
    "styleOverrides": `/styles/wmicPEDefaultTheme/themeStyleOverrides.css?${today.getTime()}`,
}

export const themes = {
    "wmicPEDefault": wmicPEDefaultTheme,
};
 
export const defaultTheme = 'wmicPEDefault';