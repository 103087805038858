import React, { useContext } from 'react';
import classNames from 'classnames';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import {
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { CONSTANTS, WMICRichTextUtil } from 'wmic-pe-portals-utils-js';
import { WMICButton } from 'wmic-pe-components-platform-react';

import messages from './WMICFutureRenewalModal.messages';
import metadata from './WMICFutureRenewalModal.metadata.json5';
import styles from './WMICFutureRenewalModal.module.scss';

function WMICFutureRenewalModal(props) {
    const {
        isOpen,
        onResolve,
        policyChangeVM
    } = props;
    const translator = useContext(TranslatorContext);

    const resolveCurrentAndRenewing = () => {
        onResolve(CONSTANTS.FUTURE_RENEWAL_MODAL_RESULT.CURRENT_AND_RENEWING);
    };

    const resolveCurrentOnly = () => {
        onResolve(CONSTANTS.FUTURE_RENEWAL_MODAL_RESULT.CURRENT_ONLY);
    };

    const overrideProps = {
        changesBroughtForwardMsg: {
            content: WMICRichTextUtil.translateRichText(translator(messages.changesBroughtForward))
        },
        currentTermOnlyMsg: {
            content: WMICRichTextUtil.translateRichText(translator(messages.bindCurrentTermOnly))
        },
        orMessage: {
            content: WMICRichTextUtil.translateRichText(translator(messages.or))
        },
        currentAndRenewingButton: {
            onClick: resolveCurrentAndRenewing,
        },
        currentButton: {
            onClick: resolveCurrentOnly
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICButton
        },
        resolveClassNameMap: styles
    };

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={onResolve}
            className={classNames('gw-modal gw-fade', styles.futureRenewalModal)}
            contentLayout={{
                component: 'flex',
                componentProps: {
                    direction: 'column',
                    alignContent: 'stretch'
                },
            }}
        >
            <ModalHeader
                icon='mi-warning'
                title={messages.warningRenewalIssued}
                status='warning'
                onClose={onResolve}
                ariaLabel={messages.warningRenewalIssued}
            />
            <ModalBody
                className="gw-modal-body">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={policyChangeVM}
                    overrideProps={overrideProps}
                    componentMap={resolvers.resolveComponentMap}
                    classNameMap={resolvers.resolveClassNameMap}
                />
            </ModalBody>
            <ModalFooter className="gw-modal-footer">
                <WMICButton type='primary-outlined' onClick={onResolve}>
                    {messages.cancel}
                </WMICButton>
            </ModalFooter>
        </ModalNext>
    )
}

export default WMICFutureRenewalModal;
