import { defineMessages } from 'react-intl';

export default defineMessages({
    firstName: {
        id: 'quoteandbind.cp.views.LocationsBuildings.AdditionalInterest.First name',
        defaultMessage: 'First name',
    },
    lastName: {
        id: 'quoteandbind.cp.views.LocationsBuildings.AdditionalInterest.Last name',
        defaultMessage: 'Last name',
    },
    interestType: {
        id: 'quoteandbind.cp.views.LocationsBuildings.AdditionalInterest.Interest type',
        defaultMessage: 'Interest type',
    },
    mortgageeNumber: {
        id: 'quoteandbind.cp.views.LocationsBuildings.AdditionalInterest.Mortgagee number',
        defaultMessage: 'Mortgagee number',
    },
    interest: {
        id: 'quoteandbind.cp.views.LocationsBuildings.AdditionalInterest.Interest',
        defaultMessage: 'Interest',
    },
    address: {
        id: 'quoteandbind.cp.views.LocationsBuildings.AdditionalInterest.Address',
        defaultMessage: 'Address',
    },
    sameAddressAs: {
        id: 'quoteandbind.cp.views.LocationsBuildings.AdditionalInterest.Same address as',
        defaultMessage: 'Same address as',
    },
    companyName: {
        id: 'quoteandbind.cp.views.LocationsBuildings.AdditionalInterest.Company name',
        defaultMessage: 'Company name',
    },
    none: {
        id: 'quoteandbind.cp.views.LocationsBuildings.AdditionalInterest.None',
        defaultMessage: 'None',
    },
    numberOfDaysNotice: {
        id: 'quoteandbind.cp.views.LocationsBuildings.AdditionalInterest.Number of days notice',
        defaultMessage: 'Number of days notice',
    }
});
