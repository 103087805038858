import React, { useContext, useEffect, useState } from 'react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { CONSTANTS, DeviceBreakpoint, Position } from 'wmic-pe-portals-utils-js';
import { WMICAddressDetails } from 'wmic-pe-components-platform-react';
import _ from 'lodash';

import metadata from './WMICAdditionalInterestComponent.metadata.json5';
import './WMICAdditionalInterestComponent.messages';

function WMICAdditionalInterestComponent (props) {
    const {
        id,
        additionalInterestVM,
        setAdditionalInterestVM,
        showErrors,
        setShowErrors,
        readOnly: pReadOnly,
        saveNewAdditionalInterest,
        cancelAdditionalInterest,
        onValidate,
        jobVM,
        isPolicyView
    } = props;

    const { onValidate: setComponentValidation , isComponentValid} = useValidation(id);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { refreshData } = useDataRefresh();

    const [isPNIAddressChosen, setPNIAddressChosen] = useState(false);

    useEffect(() => {
        // Ensures parent component will not consider this component in validation onCancel
        return () => {
            onValidate(true, id);
        }
    }, []);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const handleAddressEqualsChange = (value) => {
        setPNIAddressChosen(value);
        if (value) {
            const primaryAddress = _.get(jobVM, 'baseData.policyAddress');
            _.set(additionalInterestVM, 'policyAdditionalInterest.primaryAddress.value', primaryAddress.value);
        }
        else {
            _.set(additionalInterestVM, 'policyAdditionalInterest.primaryAddress.value', { country: CONSTANTS.COUNTRY.CA });
        }
        setAdditionalInterestVM(additionalInterestVM);
        refreshData();
    }

    const handleValidation = () => {
        setShowErrors(true);
    }

    const overrideProps = {
        '@field': {
            parentNode: additionalInterestVM,
            readOnly: pReadOnly
        },
        additionalInterestEditForm: {
            onSave: isComponentValid ? () => saveNewAdditionalInterest(additionalInterestVM) : handleValidation,
            onCancel: cancelAdditionalInterest,
            hideButtons: isPolicyView || pReadOnly
        },
        personAdditionalInterestContainer: {
            visible: !_.isEmpty(_.get(additionalInterestVM, 'additionalInterestType.value', {}))
                && _.get(additionalInterestVM, 'policyAdditionalInterest.subtype.value', {}) === CONSTANTS.Person
        },
        companyAdditionalInterestContainer: {
            visible: !_.isEmpty(_.get(additionalInterestVM, 'additionalInterestType.value', {}))
                && _.get(additionalInterestVM, 'policyAdditionalInterest.subtype.value', {}) === CONSTANTS.Company
        },
        additionalInterestAddressEqualsPNIAddress: {
            labelPosition: breakpoint === DeviceBreakpoint.DESKTOP ? Position.LEFT : Position.TOP,
            value: isPNIAddressChosen
        },
        addressDetailsComponent: {
            visible: !_.isEmpty(_.get(additionalInterestVM, 'additionalInterestType.value', {})),
            addressVM: _.get(additionalInterestVM, 'policyAdditionalInterest.primaryAddress', {}),
            onValidate: setComponentValidation,
            showErrors,
            hideBorder: true,
            hideButtons: true,
            readOnly: pReadOnly,
        }
    }

    const resolvers = {
        resolveComponentMap: {
            WMICAddressDetails
        },
        resolveCallbackMap: {
            onAddressEqualsChange: handleAddressEqualsChange
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={additionalInterestVM}
            onModelChange={setAdditionalInterestVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
            showErrors={showErrors}
        />
    );

}
WMICAdditionalInterestComponent.defaultProps = {
    isPolicyView: false
};

export default WMICAdditionalInterestComponent;
