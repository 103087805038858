import { WMICDateTimeService, LOBConstants } from 'wmic-pe-portals-utils-js';
import { UISupportingInfoLookupService } from 'wmic-pe-capability-supportinginfo';
import _ from 'lodash';

const DATES_KEY = 'dates';
let lineOfBusiness;

function generateKey(asOf) {
    if (typeof asOf === 'object') {
        return JSON.stringify(asOf, Object.keys(asOf).sort());
    }
    return JSON.stringify(asOf);
}

function getMapKeysForLineOfBusiness (rpcNumber, vehicleType, businessSegment, riskType, booleanOption) {
    switch (lineOfBusiness) {
        case LOBConstants.PA_LINE:
            return [`${rpcNumber}_${vehicleType}_${businessSegment}`, `${rpcNumber}_${businessSegment}`, rpcNumber];
        case LOBConstants.PP_LINE:
            return [`${rpcNumber}_${riskType}`, `${rpcNumber}_${riskType}_${booleanOption}`, rpcNumber];
        default:
            return [rpcNumber];
    }
}

function getIsRPCEffective (jurisdiction, asOf, rpcNumber, riskType = null, vehicleType = null,
    businessSegment = null, booleanOption = false, mapKeys = null) {
    let isEffective = false;

    if (jurisdiction && rpcNumber && asOf) {

        const key = generateKey(asOf);
        const map = JSON.parse(sessionStorage.getItem(key))?.Value;

        if(map) {
            if (map.hasOwnProperty(jurisdiction)) {
                const xMap = map[jurisdiction];

                rpcNumber = Array.isArray(rpcNumber) ? rpcNumber.find((rpcNum) => xMap.hasOwnProperty(rpcNum)) : rpcNumber;

                if (mapKeys === null) {
                    mapKeys = getMapKeysForLineOfBusiness(rpcNumber, vehicleType, businessSegment, riskType, booleanOption);
                }

                isEffective = mapKeys.some((mapKey) => {
                    if (xMap.hasOwnProperty(mapKey)) {
                        return xMap[mapKey];
                    }
                    return false;
                });
            }
        } else {
            return false;
            // throw "RPCUtil has not been initialized for " + key;
        }
    }

    return isEffective;
}

const getIsRPCEffectiveByKeys = (jurisdiction, asOf, rpcNumber, keys) =>
    getIsRPCEffective(jurisdiction, asOf, rpcNumber, null, null, null, null, keys);

const getRPCDateCached = (rpcKey) => {
    const dates = sessionStorage.getItem(DATES_KEY);

    return dates && dates[rpcKey];
};

export default {
    initialize(asOf, lob, authHeader) {
        lineOfBusiness = lob;

        return new Promise((resolve, reject) => {
            if(!WMICDateTimeService.isDateDTOValid(asOf)){
                resolve();
            }

            const cacheKey = generateKey(asOf);
            const cachedValue = sessionStorage.getItem(cacheKey);

            if(cachedValue && cachedValue.Expiry > new Date()) {
                resolve();
            } else {
                UISupportingInfoLookupService.retrieveUserEffectiveRPCsForDate(asOf, authHeader)
                    .then(
                        (rpcEffectiveDateMap)=> {
                            const expiryDate = new Date();
                            expiryDate.setHours(0, 0, 0, 0);
                            expiryDate.setDate(expiryDate.getDate() + 1);

                            sessionStorage.setItem(cacheKey, JSON.stringify({ Expiry: expiryDate, Value: rpcEffectiveDateMap}));
                            resolve(rpcEffectiveDateMap);
                        },
                        (error, status, headers) => reject([error, status, headers])
                    );
            }

        })
    },

    initializeDates(authHeader) {
        return new Promise((resolve, reject) => {
            const dates = sessionStorage.getItem(DATES_KEY);

            if (dates) {
                resolve(dates);
            }

            UISupportingInfoLookupService.retrieveRPCDates(authHeader).then(
                (rpcDates) => {
                    sessionStorage.setItem(DATES_KEY, JSON.stringify(rpcDates));
                    resolve(rpcDates);
                },
                (error, status, headers) => {
                    reject([error, status, headers]);
                }
            );
        })
    },

    getRPCDate(rpcKey) {
        return getRPCDateCached(rpcKey);
    },

    getIsRPCEffective(jurisdiction, asOf, ...rpcNumbers) {
        return rpcNumbers.some((rpcNumber) => getIsRPCEffective(jurisdiction,
            asOf, rpcNumber, null, null, null));
    },

    getIsRPCEffectiveForRiskType(jurisdiction, asOf, riskType, rpcNumber) {
        return getIsRPCEffectiveByKeys(jurisdiction, asOf, rpcNumber, [`${rpcNumber}_${riskType}`]);
    },

    getIsRPCDateForRiskTypeAndIsFarmPackage(jurisdiction, asOf, riskTypes, rpcNumber) {
        const keys = [];
        if (!_.isEmpty(riskTypes)) {
            riskTypes.forEach((risk) => keys.push(`${rpcNumber}_${risk}_false`));
        }

        return getIsRPCEffectiveByKeys(jurisdiction, asOf, rpcNumber, keys);
    },

    getIsAnyOfRPCsEffectiveForRiskType(jurisdiction, asOf, riskType, ...rpcNumbers) {
        return rpcNumbers.some((rpcNumber) => getIsRPCEffectiveByKeys(jurisdiction, asOf, rpcNumber, [`${rpcNumber}_${riskType}`]));
    },

    getIsRPCEffectiveForBusSegment(jurisdiction, asOf, businessSegment, rpcNumber) {
        return getIsRPCEffectiveByKeys(jurisdiction, asOf, rpcNumber, [`${rpcNumber}_${businessSegment}`]);
    },

    getIsRPCEffectiveForVehType(jurisdiction, asOf, vehicleType, rpcNumber) {
        return getIsRPCEffectiveByKeys(jurisdiction, asOf, rpcNumber, [`${rpcNumber}_${vehicleType}`]);
    },

    getIsRPCEffectiveForVehTypeAndBusSegment(
        jurisdiction,
        asOf,
        vehicleType,
        businessSegment,
        rpcNumber
    ) {
        const parsedVehicleType = () => vehicleType ? `_${vehicleType}` : '';
        const parsedBusinessSegment = () => businessSegment ? `_${businessSegment}` : '';

        return getIsRPCEffectiveByKeys(jurisdiction, asOf, rpcNumber, [rpcNumber + parsedVehicleType() + parsedBusinessSegment()]);
    },

    getAnyRPCEffectiveForVehTypeAndBusSegment(
        jurisdiction,
        asOf,
        vehicleType,
        businessSegment,
        rpcNumbers
    ) {
        return rpcNumbers.some((rpcNumber) =>  getIsRPCEffectiveByKeys(jurisdiction, asOf, rpcNumber, [`${rpcNumber}_${vehicleType}_${businessSegment}`]))
    },

    getAllRPCEffectiveForVehTypeAndBusSegment(
        jurisdiction,
        asOf,
        vehicleType,
        businessSegment,
        rpcNumbers
    ) {
        return rpcNumbers.every((rpcNumber) =>  getIsRPCEffectiveByKeys(jurisdiction, asOf, rpcNumber, [`${rpcNumber}_${vehicleType}_${businessSegment}`]))
    },

    firstYearEligibleRPC1378RenewalDefaulting(jurisdiction, asOf, prevAsOf, hoPolicyType) {
        return getIsRPCEffectiveByKeys(jurisdiction, asOf, '1378', [`1378_${hoPolicyType}`])
            && !getIsRPCEffectiveByKeys(jurisdiction, prevAsOf, '1378', [`1378_${hoPolicyType}`]);
    },

    firstYearEligibleRPC1379RenewalDefaulting(jurisdiction, asOf, prevAsOf, hoPolicyType) {
        return getIsRPCEffectiveByKeys(jurisdiction, asOf, '1379', [`1379_${hoPolicyType}`])
            && !getIsRPCEffectiveByKeys(jurisdiction, prevAsOf, '1379', [`1379_${hoPolicyType}`]);
    }
};