import { defineMessages } from 'react-intl';

export default defineMessages({
    heatingUse: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHeatingDetailView.Heating Use",
        defaultMessage: "Heating Use"
    },
    thermostat: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHeatingDetailView.Thermostat",
        defaultMessage: "Thermostat"
    },
    sparkArrestor: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHeatingDetailView.Spark Arrestor",
        defaultMessage: "Spark Arrestor"
    },
    heatingDistribution: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHeatingDetailView.Heating Distribution",
        defaultMessage: "Heating Distribution"
    },
    heaterType: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHeatingDetailView.Type",
        defaultMessage: "Type"
    },
    heaterTypeDescription: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHeatingDetailView.Describe Other",
        defaultMessage: "Describe Other"
    },
    heatLocation: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHeatingDetailView.Heat Location",
        defaultMessage: "Heat Location"
    },
    primaryFuel: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHeatingDetailView.Primary Fuel",
        defaultMessage: "Primary Fuel"
    },
    secondaryFuel: {
        id: "wmic-pe-capability-gateway-common-ho-react.WMICHeatingDetailView.Secondary Fuel",
        defaultMessage: "Secondary Fuel"
    }
});
