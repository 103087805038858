import { getProxiedServiceUrl } from 'wmic-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class CommisionAccountService {
    static getPoliciesInForceByAccount(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewaycommissionCommission'),
            'getPoliciesInForceByAccount', [accountNumber], additionalHeaders);
    }

    static getCommissionsTransactionsByAccount(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewaycommissionCommission'),
            'getCommissionsTransactionsByAccount', [accountNumber], additionalHeaders);
    }
}
