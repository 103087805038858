import { defineMessages } from 'react-intl';

export default defineMessages({
    householdMemberTitle: {
        "id":"endorsement.common.view.Household Members",
        "defaultMessage": "Household Members"
    },
    wmicPUPHouseholdMembersTitle: {
        id: 'wmic-pe-capability-gateway-common-pup-react.householdMemberDetails.AddMember',
        defaultMessage: 'Add Household Member'
    },
    wmicPUPHouseholdMembersPrimaryNamedInsured: {
        id: 'wmic-pe-capability-gateway-common-pup-react.householdMemberDetails.PrimaryNamedInsured',
        defaultMessage: 'Primary Named Insured'
    },
    wmicPUPHouseholdMembersName: {
        id: 'wmic-pe-capability-gateway-common-pup-react.householdMemberDetails.Name',
        defaultMessage: 'Name'
    },
    wmicPUPHouseholdMembersRelationship: {
        id: 'wmic-pe-capability-gateway-common-pup-react.householdMemberDetails.Relationship',
        defaultMessage: 'Relationship'
    },
    wmicPUPHouseholdMembersDateOfBirth: {
        id: 'wmic-pe-capability-gateway-common-pup-react.householdMemberDetails.Date of Birth',
        defaultMessage: 'Date of Birth'
    },
    wmicPUPHouseholdMembersRemovingHouseholds: {
        id: 'wmic-pe-capability-gateway-common-pup-react.householdMemberDetails.RemovingHouseholds',
        defaultMessage: 'Are you sure you want to remove a household member?'
    },
    savingHouseholdMembers: {
        id: 'wmic-pe-capability-gateway-common-pup-react.householdMemberDetails.Saving Household Members',
        defaultMessage: 'Saving Household Members'
    },
    wmicPUPHouseholdMembersPrimaryNamedInsuredCannotBeRemoved: {
        id: 'wmic-pe-capability-gateway-common-pup-react.householdMemberDetails.PrimaryNamedInsuredCannotBeRemoved',
        defaultMessage: 'Primary Named Insured cannot be removed from household members.'
    }
});
