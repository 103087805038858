/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { JobType, CONSTANTS, WMICDateTimeService } from 'wmic-pe-portals-utils-js';
import WMICAddPriorLossComponent from './WMICAddPriorLoss/WMICAddPriorLossComponent';
import addPriorLossMessages from './WMICAddPriorLoss/WMICAddPriorLossComponent.messages';
import messages from './WMICHOLossHistory.messages';
import metadata from './WMICHOLossHistory.metadata.json5';

function WMICHOLossHistory(props) {
    const {
        id,
        insuranceHistoryVM,
        jobVM,
        updateHistory,
        onValidate,
        showErrors,
        isEditMode,
        carriersList,
        readOnly
    } = props;

    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { showConfirm } = useWizardModals();
    const { refreshData } = useDataRefresh();
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
   
    const [selectedPriorLoss, updateSelectedPriorLoss] = useState();
    const [addingPriorLoss, setAddingPriorLoss] = useState(false);
    const [editingIndex, updateEditingIndex] = useState(-1);
    const isPolicyChangeOrRenewal =
        _.get(jobVM, 'baseData.jobType.value.code') === JobType.POLICY_CHANGE ||
        _.get(jobVM, 'baseData.jobType.value.code') === JobType.RENEWAL;

    const updateHistoryData = useCallback(
        (data) => {
            refreshData();
            updateHistory(data);
        },
        [refreshData, updateHistory]
    );

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [isComponentValid, onValidate, id]);

    // PRIOR LOSS
    const newPriorLoss = () => {       
        updateEditingIndex(-1); 
        const readOnlyPriorLoss = viewModelService.create(
            {}, 
            'pc', 
            'wmic.edge.ca.capabilities.policyjob.lob.common.draft.dto.insurancehistory.PriorLoss_WMICDTO',
            jobVM.aspects.context()
        );
        readOnlyPriorLoss.value.isReadOnly = false;
        readOnlyPriorLoss.isUnderEditing = true;

        updateSelectedPriorLoss(readOnlyPriorLoss);
        setAddingPriorLoss(true);
    };

    const savePriorLoss = (data) => {
        if(editingIndex < 0) {
            insuranceHistoryVM.priorLosses.value.push(data.value);
        } else {
            insuranceHistoryVM.priorLosses.setElement(editingIndex, data.value);
        }

        updateHistoryData(insuranceHistoryVM);
        setAddingPriorLoss(false);
    };

    const cancelPriorLoss = () => {
        updateSelectedPriorLoss({});
        setAddingPriorLoss(false);
    };

    const handleSelectPriorLoss = (data, index) => {
        editPriorLoss(data, index, true);
    };

    const editPriorLoss = (priorLoss, index, readOnlyMode = false) => {
        priorLoss = viewModelService.changeContext(priorLoss, jobVM.aspects.context());
        priorLoss.isUnderEditing = !readOnlyMode;
        updateSelectedPriorLoss(priorLoss);
        setAddingPriorLoss(true);
        updateEditingIndex(index); 
        updateHistoryData(insuranceHistoryVM);
    };

    const removePriorLoss = async (priorLoss, index) => {
        setAddingPriorLoss(false);

        const response = await showConfirm({
            title: translator(messages.removeLossHistory),
            message: translator(messages.removeLossHistoryConfirmation)
        });

        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            updateEditingIndex(-1); 
            const priorLosses = _.get(insuranceHistoryVM, 'priorLosses.value')
            if (index >= 0) {
                priorLosses.splice(index, 1);
            }
            cancelPriorLoss();
            const newInsuranceHistoryVM = viewModelService.clone(insuranceHistoryVM);
            _.set(newInsuranceHistoryVM, 'priorLosses', priorLosses);
            updateHistoryData(insuranceHistoryVM);
        }
    };

    const localDate2Date = (data) => {
        return WMICDateTimeService.toMidnightDate(data.lossDate.value)
    };


    const overrideProps = {
        '@field': {
            parentNode: insuranceHistoryVM
        },
        noPriorLossesContainer: {
            visible: _.get(insuranceHistoryVM, 'priorLosses', []).length === 0
        },
        addLossButtonContainer: {
            visible: !isPolicyChangeOrRenewal
        },
        addLossButton: {
            disabled: addingPriorLoss,
            visible: isEditMode
        },
        priorLossesDataList: {
            VMList: _.get(insuranceHistoryVM, 'priorLosses.children', []),
            VMData: [
                {
                    headerText: translator(addPriorLossMessages.policyNumber),
                    path: 'policyNum'
                },
                {
                    headerText: translator(addPriorLossMessages.claimStatus),
                    path: 'status'
                },
                {
                    headerText: translator(addPriorLossMessages.lossDate),
                    getData: localDate2Date
                },
            ],
            onEditAction: editPriorLoss,
            onRemoveAction: removePriorLoss,
            flatCards: true,
            clickable: true,
            selectedCardIndex: editingIndex,
            updateSelectedCardIndex: updateEditingIndex,
            isEditing: addingPriorLoss && selectedPriorLoss.isUnderEditing,
            onClickAction: handleSelectPriorLoss,
            readOnly
        },
        lossForm: {
            visible: addingPriorLoss,
            selectedPriorLoss, 
            updateSelectedPriorLoss,
            savePriorLoss,
            insuranceHistoryVM,
            cancelPriorLoss,
            carriersList,
            onValidate: setComponentValidation,
            showErrors
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            newPriorLoss
        },
        resolveComponentMap: {
            WMICAddPriorLossComponent
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={insuranceHistoryVM}
            overrideProps={overrideProps}
            onModelChange={updateHistoryData}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

WMICHOLossHistory.propTypes = {
    insuranceHistoryVM: PropTypes.shape({}),
    isEditMode: PropTypes.bool.isRequired,
};

WMICHOLossHistory.defaultProps = {
    insuranceHistoryVM: {},
    readOnly: false
};

export default WMICHOLossHistory;