import _ from 'lodash';
import { Augment } from '@xengage/gw-portals-viewmodel-utils-js';

const FILTER_METADATA = 'wmic.edge.common.aspects.validation.dto.TypelistFilterDTO';
const CATEGORY_METADATA = 'edge.aspects.validation.dto.CategoryFilterDTO';
const EXPLICIT_LISTS = 'wmic.edge.common.aspects.validation.dto.TypekeyListFilterDTO';

/** This aspect defines a list of available values for the field based on the field type
 * and property metadata.
 *
 * @param {Object} expressionLanguage
 *
 * @returns {Object}
 */
function create(expressionLanguage) {

    function compileCategory(compilationContext, node, parent, category) {
        const expr = compilationContext.compile(category.categoryExpression);
        return () => {
            return expr(node, parent, node.aspects.context);
        };
    }

    function compileFilter(compilationContext, node, parent, filter) {
        //  Wawanesa - just an FYI, data was needed because that is what Augments packs information into
        const expr = compilationContext.compile(filter.data.filterExpression);
        return () => {
            return expr(node, parent, node.aspects.context);
        };
    }

    function applyFilterRules(filterRules, compileFilterFn, compilationContext, currentViewModelNode, parent, typeInfo) {
        // This has been modified by Wawanesa to accommodate retired flag on typecodes
        const currentlySelectedCode = (currentViewModelNode.value !== undefined && currentViewModelNode.value !== null) ? currentViewModelNode.value : undefined;

        /* Filter out the retired codes, unless it's currently being selected. */
        let baseValues = _.filter(typeInfo.codes, (code) => {
            return code.retired !== true || (currentlySelectedCode && code.code === currentlySelectedCode.code && code.retired);
        });

        if (filterRules.length > 0) {
            filterRules.forEach((filterRule) => {
                const filterData = filterRule.data;
                if (filterData.filterExpression !== undefined) {

                    const parser = _.partial(compileFilterFn, compilationContext, currentViewModelNode, parent);
                    const filterExpr = parser(filterRule);
                    const filterEvalResult = filterExpr(); // result of the expression evaluation

                    /* both filter name and expression are present. */
                    if (filterEvalResult !== undefined && filterData.filterName !== undefined) {
                        if (filterEvalResult) { // filterEvalResult is a boolean
                            const filter = typeInfo.getFilter(filterData.filterName);
                            baseValues = baseValues.filter(filter.allows);
                        }
                        /* only expression is present. */
                    } else if (filterEvalResult !== undefined && filterEvalResult.length > 0) {
                        /* If an array of available values are returned. */
                        if (filterEvalResult instanceof Array) {
                            baseValues = _.filter(baseValues, (v) => {
                                return filterEvalResult.indexOf(v.code) >= 0;
                            });
                        } else {
                            /* Else, the name of the filter is returned. */
                            const filter = typeInfo.getFilter(filterEvalResult); // filterEvalResult is a string (name of the filter)
                            baseValues = baseValues.filter(filter.allows);
                        }
                    }
                    /* only filter name is present (OOTB style). */
                } else if (filterData.filterName !== undefined) {
                    const filter = typeInfo.getFilter(filterData.filterName);
                    baseValues = baseValues.filter(filter.allows);
                }
            });
        }
        return baseValues;
    }

    /* Take into account the available values from radiant® metadata as well. By default, radiant (if present) will override any existing annotation, but this behavior can be parameterized if needed.
     * Need to differentiate between no radian data vs. an empty radiant list. In the first case we will return undefined, while in the latter we will return an empty array. */
    function getRadiantList(currentViewModelNode, typeInfo) {

        const accessorName = _.toLower(currentViewModelNode._accessorCode);
        const parentNode = currentViewModelNode._parent;
        const currentlySelectedCode = (currentViewModelNode.value !== undefined && currentViewModelNode.value !== null) ? currentViewModelNode.value : undefined;

        if (!parentNode || !parentNode.value || !parentNode.value.metaDataMap || !accessorName) {
            return undefined;
        }
        const radiantData = parentNode.value.metaDataMap.find((item) => _.toLower(item.propName) === accessorName);
        if (!radiantData || !radiantData.availableValues) {
            return undefined;
        }
        // check to see if this is a simple filter name
        if (radiantData.availableValues.length === 1 && radiantData.availableValues[0].startsWith('filter:')) {
            return radiantData.availableValues[0];
        }
        const radiantList = _.filter(typeInfo.codes, (code) => {
            return _.includes(radiantData.availableValues, code.code);
        });
        // in the event that a transformation set a value not in the current filter, we need to honor that.
        // but only if that value is retired
        if (currentlySelectedCode && currentlySelectedCode.retired && !_.includes(radiantList, currentlySelectedCode)) {
            radiantList.push(currentlySelectedCode);
        }
        return radiantList;
    }

    /**
     * Checks if two lists matches each other.
     *
     * @param {Array|*} arr1
     * @param {Array|*} arr2
     *
     * @returns {Boolean}
     */
    function matches(arr1, arr2) {
        if ((arr1 === null) !== (arr2 === null)) {
            return false;
        }
        if (arr1.length !== arr2.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }
        return true;
    }

    return {
        getAspectProperties: (currentViewModelNode, currentMetadataNode, ancestorChain) => {
            if (currentMetadataNode.valueType.kind !== 'class') {
                return {};
            }

            const typeInfo = currentMetadataNode.valueType.typeInfo;
            if (!typeInfo.metaType.isTypelist) {
                return {};
            }


            const categoryRules = currentMetadataNode.elementMetadata.get(CATEGORY_METADATA);
            const compilationContext = expressionLanguage.getCompilationContext(currentMetadataNode.xCenter);
            const parent = ancestorChain.parent || {};
            let categoryExprs = categoryRules.map(_.partial(compileCategory, compilationContext, currentViewModelNode, parent));

            // This has been modified by Wawanesa to include both string filters and expression filters
            const filterRules = Augment.collectRules(compilationContext, currentViewModelNode, currentMetadataNode, ancestorChain, FILTER_METADATA);
            let baseValues = applyFilterRules(filterRules, compileFilter, compilationContext, currentViewModelNode, parent, typeInfo);

            // if we have radiant data for this control, that trumps everything
            const getRadiantValues = () => {
                const newValues = getRadiantList(currentViewModelNode, typeInfo);
                if (newValues && (radiantList && !matches(newValues, radiantList) || !radiantList)) {
                    radiantList = newValues;
                }
                return radiantList;
            };


            const getCategoryValues = () => {
                const currentlySelectedCode = (currentViewModelNode.value !== undefined && currentViewModelNode.value !== null) ? currentViewModelNode.value : undefined;
                let res = null;
                const radiantValues = getRadiantValues();
                if (radiantValues) {
                    return radiantValues;
                }
                const newValues = baseValues.filter((typekey) => {
                    return categoryExprs.every((categoryExpr) => {
                        const cat = categoryExpr();
                        return !cat || typekey.belongsToCategory(cat);
                    });
                });

                /** This is a work around for Angular. It does not have a propagation/dependency tracking.
                 * At the same time it does not consider two arrays with the same content equivalent. So we have
                 * to return the same array to prevent infinite update loops.
                 */
                if (!matches(newValues, res)) {
                    res = newValues;
                }

                if (currentlySelectedCode) {
                    if (!_.includes(res, currentlySelectedCode)) {
                        const newNode = _.cloneDeep(currentlySelectedCode);
                        newNode.name = {id: `${currentlySelectedCode.typelist.typeName}.${currentlySelectedCode.code}`}
                        return [...res, newNode];
                    }
                }
                
                return res;
            }

            /* If radiant list exists, it will override any existing filter, and we will simply return that list. */
            let radiantList = getRadiantList(currentViewModelNode, typeInfo);
            if (radiantList !== undefined) {
                return {
                    availableValues: {
                        get: () => {
                            const newValues = getRadiantList(currentViewModelNode, typeInfo);
                            // it is just a filter name so grab the filter
                            if (!Array.isArray(newValues) && newValues?.startsWith("filter:")) {
                                radiantList = baseValues.filter(typeInfo.getFilter(newValues.substring(newValues.indexOf(':') + 1)).allows)
                            } else if (newValues && !matches(newValues, radiantList)) {
                                radiantList = newValues;
                            }
                            return radiantList;
                        }
                    }
                };
            }

            /* Return a get method for filter rules, so that the available values can be calculated again when the page digests. (same as the category rules below) */
            if (filterRules.length > 0) {
                return {
                    availableValues: {
                        get: () => {
                            baseValues = applyFilterRules(filterRules, compileFilter, compilationContext, currentViewModelNode, parent, typeInfo);
                            categoryExprs = categoryRules.map(_.partial(compileCategory, compilationContext, currentViewModelNode, parent));

                            const radiantValues = getRadiantValues();
                            const categoryValues = getCategoryValues();
                            if (radiantValues) {
                                return radiantValues;
                            }
                            
                            return categoryValues;
                        }
                    }
                };
            }

            const explicitLists = Augment.collectRules(compilationContext, currentViewModelNode, currentMetadataNode, ancestorChain, EXPLICIT_LISTS);
            explicitLists.forEach((explicitList) => {
                baseValues = baseValues.filter((value) => {
                    return explicitList.data.codes.indexOf(value.code) >= 0;
                });
            });
            // end Wawanesa

            return {
                availableValues: {
                    get: () => {
                        categoryExprs = categoryRules.map(_.partial(compileCategory, compilationContext, currentViewModelNode, parent));
                        return getCategoryValues();
                    }
                }
            };
        }
    };
}

export default {
    create
};
