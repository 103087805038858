
import WMICPAPolicyDetailsPage from './pages/WMICPolicyDetails/WMICPolicyDetailsPage';
import WMICPAInsuranceHistoryPage from './pages/WMICPAInsuranceHistory/WMICPAInsuranceHistoryPage';
import 'wmic-pe-capability-quoteandbind-pa-react';
import 'wmic-pe-capability-quoteandbind-common-react';

import { setComponentMapOverrides } from '../../../../../../platform/@jutro/uiconfig';

export { default as WMICPALossHistoryComponent } from './components/WMICPALossHistory/WMICPALossHistoryComponent';
export { default as WMICAddLossHistoryComponent } from './components/WMICPALossHistory/WMICAddLossHistoryComponent/WMICAddLossHistoryComponent';
export { default as WMICAddPayoutComponent } from './components/WMICPALossHistory/WMICAddLossHistoryComponent/WMICAddPayoutComponent/WMICAddPayoutComponent';
export { default as PAConsentMessages } from './components/WMICPACreditConsent/WMICPACreditConsentComponent.messages';

setComponentMapOverrides(
    {
        WMICPAPolicyDetailsPage: { component: 'WMICPAPolicyDetailsPage' },
        WMICPAInsuranceHistoryPage: { component: 'WMICPAInsuranceHistoryPage' }
    },
    {
        WMICPAPolicyDetailsPage,
        WMICPAInsuranceHistoryPage
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PASubmissionDraftDataExtension } from './extensions/PASubmissionDraftDataExtension';
export { default as WMICPAWizard } from './WMICPAWizard';
export { default as WMICPACreditConsentComponent } from './components/WMICPACreditConsent/WMICPACreditConsentComponent'
