import _ from 'lodash';
import React from 'react';

import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import { WMICWizardViewPage } from 'wmic-pe-portals-wizard-components-ui';
import { WMICInsuranceHistoryPoliciesComponent } from 'wmic-pe-capability-gateway-common-react';
import metadata from './WMICPolicyCPInsuranceHistory.metadata.json5';

function WMICPolicyCPInsuranceHistory(props) {
    const {
        wizardData: { policyVM },
    } = props;

    // eslint-disable-next-line no-secrets/no-secrets
    const insuranceHistoryVM = _.get(policyVM, 'lobs.commercialProperty.coverables.insuranceHistory');

    const commonAccordionContentProps = {
        jobVM : policyVM,
        insuranceHistoryVM,
        readOnly: true,
        isEditMode: false,
        updateHistory: _.noop,
        onValidate: _.noop
    };

    const overrideProps = {
        '@field': {
            parentNode: insuranceHistoryVM,
            readOnly: true
        },
        policiesAccordion: {
            ...commonAccordionContentProps,
        },
        policiesAccordionContent: {
            ...commonAccordionContentProps,
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICInsuranceHistoryPoliciesComponent
        }
    };

    return (
        <WMICWizardViewPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyVM}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
            />
        </WMICWizardViewPage>
    );
}

WMICPolicyCPInsuranceHistory.propTypes = wizardProps;
export default WMICPolicyCPInsuranceHistory;
