import { defineMessages } from 'react-intl';

export default defineMessages({
    saveAndExit: {
        id: 'wmic-pe-capability-gateway-policyrenewal-common-react.RenewalCommon.Save and exit',
        defaultMessage: 'Save and exit'
    },
    policyDetailsTitle: {
        id: 'wmic-pe-capability-gateway-policyrenewal-common-react.RenewalCommon.Policy Details',
        defaultMessage: "Policy Details"
    },
    quote: {
        id: 'wmic-pe-capability-gateway-policyrenewal-common-react.RenewalCommon.Quote',
        defaultMessage: 'Quote'
    },
    wantToJump: {
        id: 'wmic-pe-capability-gateway-policyrenewal-common-react.Leave this page',
        defaultMessage: 'Leave this page?'
    },
    wantToJumpMessage: {
        id: 'wmic-pe-capability-gateway-policyrenewal-common-react.Changes not saved will be lost',
        defaultMessage: 'Changes not saved will be lost, do you want to continue?'
    },
    savingTransactionDetails: {
        id: 'wmic-pe-capability-gateway-policyrenewal-common-react.Saving Transaction Details',
        defaultMessage: 'Saving Transaction Details'
    },
    quoting: {
        id: 'wmic-pe-capability-gateway-policyrenewal-common-react.Quoting',
        defaultMessage: 'Quoting...'
    },
    leavingThisPage: {
        id: 'wmic-pe-capability-gateway-policyrenewal-common-react.Leaving This Page',
        defaultMessage: 'Leaving This Page'
    },
    aboutToLeaveThisPage: {
        id: 'wmic-pe-capability-gateway-policyrenewal-common-react.You are about to leave this page...',
        defaultMessage: 'You are about to leave this page. Changes not saved will be lost. Would  you like to continue?'
    },
    cancel: {
        id: 'wmic-pe-capability-gateway-policyrenewal-common-react.Cancel',
        defaultMessage: 'Cancel'
    },
    revalidationRequiredTitle: {
        id: 'wmic-pe-capability-gateway-policyrenewal-common-react.Revalidation Required',
        defaultMessage: 'Revalidation Required'
    },
    revalidationRequiredBody: {
        id: 'wmic-pe-capability-gateway-policyrenewal-common-react.Adding this item will require',
        defaultMessage: 'Adding this item will require you to revalidate the information on the current and subsequent pages. Would you like to continue with this change?'
    },
    unableToDraftRenewal: {
        id: 'wmic-pe-capability-gateway-policyrenewal-common-react.Unable to save draft renewal',
        defaultMessage: 'Unable to save draft renewal'
    },
    anErrorOccurred: {
        id: 'wmic-pe-capability-gateway-policyrenewal-common-react.An error occurred while attempting to save the renewal',
        defaultMessage: 'Something went wrong during this process. Please try again.'
    },
});
