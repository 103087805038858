import { DocumentService } from 'wmic-pe-capability-gateway-policycommon';
import { GatewayDocumentService } from 'wmic-pe-capability-gateway-document';
import { SUITES } from 'wmic-pe-portals-utils-js';
import _ from 'lodash';

const docRetrievalTimeoutMs = 6000;
const maxDownloadAttempts = 10;
const initialDownloadAttempts = 0;
let downloadAttempts = initialDownloadAttempts;

const getDocumentDownloadLink = ({ publicID, sessionID }, suite) => GatewayDocumentService.downloadDocument(publicID, sessionID, suite);

const retrieveQuoteDocument = (document, authHeader) => DocumentService.retrieveQuoteDocument(document.publicID, authHeader)
.then((retrievedDocument) => {
    if (!_.isEmpty(retrievedDocument)) {
        return retrievedDocument;
    }
    throw new Error();
}).catch(() => {
    throw new Error();
});

const documentReadyForDownload = (doc, authHeader) => new Promise((resolve) => {
    if (doc?.isViewContentAvailable) {
        resolve(true);
    } else if (downloadAttempts < maxDownloadAttempts) {
        let retrievedDocument;
        let hasErrors = false;
        setTimeout(() => {
            retrieveQuoteDocument(doc, authHeader)
                .then(refreshedDoc => {
                    retrievedDocument = refreshedDoc;
                })
                .catch(() => {
                    hasErrors = true;
                }).finally(() => {
                    if (!hasErrors) {
                        downloadAttempts += 1;
                        resolve(documentReadyForDownload(retrievedDocument, authHeader));
                    } else {
                        downloadAttempts = initialDownloadAttempts;
                        resolve(false);
                    }
                });
        }, docRetrievalTimeoutMs);
    } else {
        downloadAttempts = initialDownloadAttempts;
        resolve(false);
    }
});

// eslint-disable-next-line arrow-body-style
const downloadQuoteDocument = (jobNumber, authHeader) => {
    return DocumentService.generateQuoteDocument(jobNumber, authHeader)
        .then(document => {
            if (!_.isEmpty(document)) {
                // eslint-disable-next-line no-param-reassign
                document.link = getDocumentDownloadLink(document, SUITES.PC);
                return document;
            }
            throw new Error();
        })
        .catch(() => {
            throw new Error();
        });
};

export default {
    retrieveQuoteDocument,
    documentReadyForDownload,
    downloadQuoteDocument
}
