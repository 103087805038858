import React from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import metadata from './WMICPolicyPuLocationExposureForm.metadata.json5';
import messages from '../WMICPolicyPuLocationExposures.messages.js';

function WMICPolicyPuLocationExposureForm(props) {
    const { value: exposureVM } = props;

    const overrideProps = {
        '@field': {
            parentNode: _.get(exposureVM, 'locationExposure', {}),
            readOnly: true
        }
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={_.get(exposureVM, 'locationExposure', {})}
            overrideProps={overrideProps}
        />
    );
}

export default WMICPolicyPuLocationExposureForm;