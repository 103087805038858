import React, {
    useContext,
} from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import metadata from './WMICPAPolicyVehicleOwnsershipAndAssignment.metadata.json5';
import messages from './WMICPAPolicyVehicleOwnsershipAndAssignment.messages';

function WMICPAPolicyVehicleOwnsershipAndAssignment(props) {
    const {
        vehicleVM,
    } = props;

    const translator = useContext(TranslatorContext);
    const overrideProps = {
        '@field': {
            parentNode: vehicleVM, 
            readOnly: true
        },
        policyRegistredOwnersListView: {
            VMData: [
                {
                    headerText: translator(messages.policyRegistredOwnerName),
                    path: 'contactName'
                },
                {
                    headerText: translator(messages.policyRelationshipToInsured),
                    path: 'priInsuredRelationName_wmic'
                }
            ],
            clickable: false,
            readOnly: true,
            startOpen: false
        },
        policyDriversDataList: {
            VMData: [
                {
                    headerText: translator(messages.policyDriver),
                    path: 'driverName_WMIC'
                },
                {
                    headerText: translator(messages.policyPercentOfUse),
                    path: 'percentUsed_WMIC'
                },
                {
                    headerText: translator(messages.policyDriverType),
                    path: 'driverType_WMIC'
                }
            ],
            readOnly: true,
            clickable:false,
            startOpen:false
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleVM}
            overrideProps={overrideProps}
        />
    );
}

export default WMICPAPolicyVehicleOwnsershipAndAssignment;
