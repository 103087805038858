/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import { ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { WATERCRAFT_TYPES } from 'wmic-pe-portals-utils-js';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { WatercraftContext } from 'wmic-pe-capability-gateway-quoteandbind-ho-react';
import WMICHOWatercraftOutboardMotorDetails from './Components/WMICHOWatercraftOutboardMotorDetails/WMICHOWatercraftOutboardMotorDetails';
import WMICHOWatercraftBoatEquipmentDetails from './Components/WMICHOWatercraftBoatEquipmentTrailerDetails/WMICHOWatercraftBoatEquipmentTrailerDetails';
import WMICHOWatercraftPersonalDetails from './Components/WMICHOWatercraftPersonalDetails/WMICHOWatercraftPersonalDetails';
import metadata from './WMICHOWatercraftDetails.metadata.json5';
import messages from './WMICHOWatercraftDetails.messages';

function WMICHOWatercraftDetails(props) {
    const {
        id,
        isEditMode,
        isFromBms,
        onValidate,
        watercraftRiskView,
        updateWatercraftRiskView,
        jobVM,
        showErrors
    } = props;
    const { refreshData } = useDataRefresh();
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        // if component is unmounted, report as valid so it doesn't break the rest of the form
        return () => {
            if (onValidate) {
                onValidate(true, id);
            }
        }
    }, [id, onValidate, isComponentValid]);

    // Workaround: since VM updates are done in place and use this function in different ways (for instance,
    // one component uses it to update a different VM), we simply force the state to update with the base oject itself.
    const updateWatercraftRiskViewFixed = useCallback(() => {
        updateWatercraftRiskView(watercraftRiskView);
    }, [updateWatercraftRiskView, watercraftRiskView]);


    const watercraftTypeDefined = useCallback(() => {
        return (!_.isUndefined(_.get(watercraftRiskView, WATERCRAFT_TYPES.PATH))
            && !_.isEmpty(_.get(watercraftRiskView, WATERCRAFT_TYPES.PATH)));
    }, [watercraftRiskView]);

    const isOutboardMotor = useCallback(() => {
        return watercraftTypeDefined()
            && _.isEqual(_.get(watercraftRiskView, WATERCRAFT_TYPES.PATH), WATERCRAFT_TYPES.OUTBOARDMOTOR);
    }, [watercraftRiskView, watercraftTypeDefined]);

    const isPersonalWatercraft = useCallback(() => {
        return watercraftTypeDefined()
            && _.isEqual(_.get(watercraftRiskView, WATERCRAFT_TYPES.PATH), WATERCRAFT_TYPES.PERSONALWATERCRAFT);
    }, [watercraftRiskView, watercraftTypeDefined]);

    const isBoatEquipmentTrailer = useCallback(() => {
        return watercraftTypeDefined()
            && _.isEqual(_.get(watercraftRiskView, WATERCRAFT_TYPES.PATH), WATERCRAFT_TYPES.BOATEQUIPMENTTRAILER);
    }, [watercraftRiskView, watercraftTypeDefined]);

    const onRiskTypeChange = useCallback((value, path) => {
        const oldRiskTypeValue = _.get(watercraftRiskView, `${path}.value.code`);
        if (oldRiskTypeValue !== value) {
            _.set(watercraftRiskView, path, value);
            _.set(watercraftRiskView, 'watercraftComponents.value', []);
            updateWatercraftRiskView(watercraftRiskView);
        }
    }, [updateWatercraftRiskView, watercraftRiskView]);

    const sharedProps = {
        isEditMode,
        onValidate: setComponentValidation,
        isFromBms,
        showErrors
    }

    const overrideProps = {
        '@field': {
            parentNode: watercraftRiskView,
        },
        watercraftDetailsTab: {
            header: {
                id: "quoteandbind.ho.views.construction.Details",
                defaultMessage: "Details"
            },
            watercraftRiskView
        },
        wmicHoWatercraftBoatEquipmentDetails: {
            ...sharedProps,
            visible: isBoatEquipmentTrailer(),
        },
        wmicHOWatercraftOutboardMotorDetails: {
            ...sharedProps,
            visible: isOutboardMotor(),
        },
        wmicHOWatercraftPersonalDetails: {
            ...sharedProps,
            visible: isPersonalWatercraft(),
        },
        selectorWatercraftType: {
            readOnly: !isEditMode,
            onValueChange: onRiskTypeChange
        }
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            WMICHOWatercraftBoatEquipmentDetails,
            WMICHOWatercraftOutboardMotorDetails,
            WMICHOWatercraftPersonalDetails
        }
    };

    return (
        <WatercraftContext.Provider value={{watercraftRiskView, updateWatercraftRiskView: updateWatercraftRiskViewFixed, jobVM}}>
            <ViewModelForm
                model={watercraftRiskView}
                onModelChange={updateWatercraftRiskView}
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={setComponentValidation}
            />
        </WatercraftContext.Provider>
    );
}

WMICHOWatercraftDetails.propTypes = {
    watercraftRiskView: PropTypes.shape({}).isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isFromBms: PropTypes.bool.isRequired,
};

WMICHOWatercraftDetails.defaultProps = {
};

export default WMICHOWatercraftDetails;
