import { defineMessages } from 'react-intl';

export default defineMessages({
    liabilityDetails: {
        id: 'wmic-pe-capability-gateway-quoteandbind-gl-react.WMICGLLiabilityDetails.liabilityDetails',
        defaultMessage: 'Liability Details'
    },
    totalAnnualRevenue: {
        id: 'wmic-pe-capability-gateway-quoteandbind-gl-react.WMICGLLiabilityDetails.totalAnnualRevenue',
        defaultMessage: 'Total annual revenue'
    },
    totalAnnualPayroll: {
        id: 'wmic-pe-capability-gateway-quoteandbind-gl-react.WMICGLLiabilityDetails.totalAnnualPayroll',
        defaultMessage: 'Total annual payroll'
    },
    manufacturingOrWholesaleOps: {
        id: 'wmic-pe-capability-gateway-quoteandbind-gl-react.WMICGLLiabilityDetails.manufacturingOrWholesaleOps',
        defaultMessage: 'Manufacturing or wholesale operations'
    },
    importedProductsOrRawMaterials: {
        id: 'wmic-pe-capability-gateway-quoteandbind-gl-react.WMICGLLiabilityDetails.importedProductsOrRawMaterials',
        defaultMessage: 'Imported products or raw materials'
    },
    qualityControlProcedures: {
        id: 'wmic-pe-capability-gateway-quoteandbind-gl-react.WMICGLLiabilityDetails.qualityControlProcedures',
        defaultMessage: 'Quality control procedures'
    },
    equipRentedOrLeasedToOthers: {
        id: 'wmic-pe-capability-gateway-quoteandbind-gl-react.WMICGLLiabilityDetails.equipRentedOrLeasedToOthers',
        defaultMessage: 'Equipment rented or leased to others'
    },
    percentage: {
        id: 'wmic-pe-capability-gateway-quoteandbind-gl-react.WMICGLLiabilityDetails.percentage',
        defaultMessage: 'Percentage'
    },
    withoutOperator: {
        id: 'wmic-pe-capability-gateway-quoteandbind-gl-react.WMICGLLiabilityDetails.withoutOperator',
        defaultMessage: 'Without operator'
    },
    workSubcontractedToOthers: {
        id: 'wmic-pe-capability-gateway-quoteandbind-gl-react.WMICGLLiabilityDetails.workSubcontractedToOthers',
        defaultMessage: 'Work subcontracted to others'
    },
    subcontractorsReqCarryLiabilityIns: {
        id: 'wmic-pe-capability-gateway-quoteandbind-gl-react.WMICGLLiabilityDetails.subcontractorsReqCarryLiabilityIns',
        defaultMessage: 'Subcontractors required to carry liability insurance'
    },
    salesOfFirearms: {
        id: 'wmic-pe-capability-gateway-quoteandbind-gl-react.WMICGLLiabilityDetails.salesOfFirearms',
        defaultMessage: 'Sales of firearms, ammunition, and/or explosives'
    }
});
