import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react'

import { MVR_INCIDENT_CODE } from 'wmic-pe-portals-utils-js';

import metadata from './WMICAddIncidentComponent.metadata.json5';
import messages from './WMICAddIncidentComponent.messages.js';
import styles from '../WMICMVRComponent.module.scss';

function WMICAddIncidentComponent(props) {
    const {
        id,
        selectedIncident,
        cancelIncident,
        saveIncident,
        maxMvrDate,
        minDate,
        convictionList,
        onValidate
    } = props;

    const translator = useContext(TranslatorContext);
    const {onValidate: setComponentValidation, isComponentValid} = useValidation(id);

    const [currentIncident, updateCurrentIncident] = useState(selectedIncident);
    const [toggle, forceRender] = useState(false);
    const [showErrors, updateShowErrors] = useState(false);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => {
            if(onValidate) {
                onValidate(true, id)
            }
        }
    }, [isComponentValid, onValidate, currentIncident, id]);

    useEffect(() => {
        updateCurrentIncident(selectedIncident);
    }, [selectedIncident])

    const resetReasonForSuspensionDependentFields = (value, path) => {
        _.set(currentIncident, path, value);
        _.set(currentIncident, 'otherTypeDescribeOther.value', undefined);
        updateIncident(currentIncident);
    };

    const getIncidentTypeCode = () => {
        return _.get(currentIncident, 'incidentType.value.code', '');
    };

    const isConviction = () => {
        return getIncidentTypeCode() === MVR_INCIDENT_CODE.CONVICTION;
    };
    const isSuspension = () => {
        return getIncidentTypeCode() === MVR_INCIDENT_CODE.SUSPENSION;
    };

    const isProhibition = () => {
        return getIncidentTypeCode() === MVR_INCIDENT_CODE.PROHIBITION;
    };

    const isRestriction = () => {
        return getIncidentTypeCode() === MVR_INCIDENT_CODE.RESTRICTION;
    };

    const getViolationDateLabel = () => {
        if (isConviction()) {
            return translator(messages.offenceDateLabel);
        }
        if (isRestriction()) {
            return  translator(messages.restrictionDateLabel);
        }
        if (isProhibition()) {
            return  translator(messages.prohibitionDateLabel);
        }
        if (isSuspension()) {
            return  translator(messages.suspensionDateLabel);
        }
        return '';
    };

    const getConvictionDateLabel = () => {
        if (isConviction()) {
            return translator(messages.convictionDateLabel);
        }
        if (isRestriction() || isSuspension()) {
            return translator(messages.reinstatementDateLabel);
        }
        return '';
    };

    const updateIncident = (data) => {
        updateCurrentIncident(data);
        forceRender(!toggle);
    };

    const incidentTypeChanged = (value, path) => {
        _.set(currentIncident, 'convictionPublicID.value', undefined);
        _.set(currentIncident, 'convictionDescription.value', undefined);
        _.set(currentIncident, 'restrictionType.value', undefined);
        _.set(currentIncident, 'driverSuspendReason_wmic.value', undefined);
        _.set(currentIncident, 'violationDate.value', undefined);
        _.set(currentIncident, 'convictionDate.value', undefined);
        _.set(currentIncident, 'violationCode.value', undefined);
        _.set(currentIncident, 'otherTypeDescribeOther.value', undefined);
        _.set(currentIncident, 'suspensionTerm.value', undefined);
        _.set(currentIncident, path, value);
        updateIncident(currentIncident);
    };

    const handleSaveIncident = () => {
        if(isComponentValid) {
            saveIncident()
        } else {
            updateShowErrors(true)
        }
    }


    const resolvers = {
        resolveCallbackMap: {
            cancelIncident,
            onSaveIncident: handleSaveIncident,
            resetReasonForSuspensionDependentFields,
            incidentTypeChanged
        },
        resolveClassNameMap: styles
    }
    const overrideProps = {
        '@field': {
            parentNode: currentIncident,
        },
        violationDate: {
            label: getViolationDateLabel(),
            maxDate: maxMvrDate,
            minDate: minDate
        },
        convictionDate: {
            label: getConvictionDateLabel(),
            maxDate: maxMvrDate,
            minDate: minDate
        },
        convictionPublicID: {
            availableValues: convictionList
        }
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentIncident}
            overrideProps={overrideProps}
            onModelChange={updateIncident}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    )
}

WMICAddIncidentComponent.propTypes = {
    id: PropTypes.string.isRequired,
    selectedIncident: PropTypes.shape({}).isRequired,
    cancelIncident: PropTypes.func.isRequired,
    saveIncident: PropTypes.func.isRequired,
    maxMvrDate: PropTypes.object.isRequired,
    minDate: PropTypes.object.isRequired,
    convictionList: PropTypes.shape([]).isRequired, 
    onValidate: PropTypes.func.isRequired,
    incidentAction: PropTypes.string.isRequired
}

export default WMICAddIncidentComponent;