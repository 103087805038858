import { defineMessages } from 'react-intl';

export default defineMessages({
    quoteRequest: {
        id: 'quoteandbind.views.qnb.Quote Request',
        defaultMessage: 'Quote Request'
    },
    rentersQuote: {
        id: 'quoteandbind.views.qnb.Renters Quote',
        defaultMessage: 'Renters Quote'
    },
    quoteNumber: {
        id: 'quoteandbind.views.qnb.Quote Number',
        defaultMessage: 'Quote Number'
    },
    yourInfo: {
        id: 'quoteandbind.wizard.step.Your Info',
        defaultMessage: 'Your Info'
    },
    qualification: {
        id: 'quoteandbind.wizard.step.Qualification',
        defaultMessage: 'Qualification'
    },
    quote: {
        id: 'quoteandbind.wizard.step.Quote',
        defaultMessage: 'Quote'
    },
    additionalInformation: {
        id: 'quoteandbind.wizard.step.Additional Information',
        defaultMessage: 'Additional Information'
    },
    policyInformation: {
        id: 'quoteandbind.wizard.step.Policy Information',
        defaultMessage: 'Policy Information'
    },
    paymentDetails: {
        id: 'quoteandbind.wizard.step.Payment Details',
        defaultMessage: 'Payment Details'
    },
    success: {
        id: 'quoteandbind.wizard.step.Success',
        defaultMessage: 'Success'
    },
    error: {
        id: 'quoteandbind.common.flow.error.Error',
        defaultMessage: 'Error',
    },
    unableToGenerateAQuote: {
        id: 'quoteandbind.common.config.FlowConfig.Unable to generate a quote',
        defaultMessage: 'Unable to Generate a Quote',
    },
    somethingWentWrong: {
        id: 'quoteandbind.common.config.FlowConfig.An error occurred while attempting to calculate a quote.',
        defaultMessage: 'Something went wrong during this process. Please try again.',
    },
    unableToGenerateAQuoteUWIssue: {
        id: 'quoteandbind.common.config.FlowConfig.An error occurred while attempting to calculate a quote because of an UW issue.',
        defaultMessage: 'Sorry, we cannot generate a quote online. Please contact one of our agents.',
    },
    unableToSaveQuoteID: {
        id: 'quoteandbind.common.config.FlowConfig.Unable to save submission',
        defaultMessage: 'Unable to Save Quote'
    },
    unableToSaveQuoteMessage: {
        id: 'quoteandbind.common.config.FlowConfig.An error occurred while attempting to save quote submission.',
        defaultMessage: 'Something went wrong during this process. Please try again.'
    },
    homeAddress: {
        id: 'quoteandbind.views.contact-details.Home Address',
        defaultMessage: 'Home Address',
    },
    forYourQuote: {
        id: 'quoteandbind.views.yourinfo.For Your Quote',
        defaultMessage: 'For Your Quote',
    },
    // Addition Interest
    removeAdditionalInterest: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.Remove Additional Interest?',
        defaultMessage: 'Remove Additional Interest?',
    },
    removeAdditionalInterestDesc: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.AddIntRemovalMsg',
        defaultMessage: 'Are you sure you want to remove this additional interest from the list?',
    },
    additionalInterestsSubtypePerson: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.Person',
        defaultMessage: 'Person',
    },
    additionalInterestsSubtypeCompany: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.Company',
        defaultMessage: 'Company',
    },
    additionalInterestsSubtypeCompanyName: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.Company Name',
        defaultMessage: 'Company Name'
    },
    addAdditionalInterests: {
        id: 'quoteandbind.views.ho-your-home.additional-interest.Add Additional Interests',
        defaultMessage: 'Add Additional Interests',
    },
    /* Success Details Quote And Bind */
    paymentSuccessful: {
        id: 'quoteandbind.views.confirmation.Payment Successful',
        defaultMessage: 'Payment Successful'
    },
    confirmationText: {
        id: 'quoteandbind.views.confirmation.Your new policy has been approved. You will receive an email confirmation shortly.',
        defaultMessage: 'Your new policy has been approved. You will receive an email confirmation shortly.'
    },
    confirmationMessage: {
        id: 'quoteandbind.views.confirmation.gateway.This policy has been approved.',
        defaultMessage: 'This policy has been approved. Your customer will receive an email confirmation shortly.'
    },
    policySummary: {
        id: 'quoteandbind.views.confirmation.Policy Summary',
        defaultMessage: 'Policy Summary'
    },
    accountNumber: {
        id: 'quoteandbind.views.confirmation.Account Number',
        defaultMessage: 'Account Number'
    },
    policyNumber: {
        id: 'quoteandbind.views.confirmation.Policy Number',
        defaultMessage: 'Policy Number'
    },
    policyEffectiveDate: {
        id: 'quoteandbind.views.confirmation.Policy Effective Date',
        defaultMessage: 'Policy Effective Date'
    },
    policyTotalAmount: {
        id: 'quoteandbind.views.confirmation.Policy Total Amount',
        defaultMessage: 'Policy Total Amount'
    },
    effectiveDate: {
        id: 'quoteandbind.views.confirmation.Effective Date',
        defaultMessage: 'Effective Date'
    },
    totalAmount: {
        id: 'quoteandbind.views.confirmation.Total Amount',
        defaultMessage: 'Total Amount'
    },
    paymentPlanName: {
        id: 'quoteandbind.views.confirmation.Payment Plan Name',
        defaultMessage: 'Payment Plan Name'
    },
    currentPayment: {
        id: 'quoteandbind.views.confirmation.Current Payment',
        defaultMessage: 'Current Payment'
    },
    /* Your Info Page */
    yourInformation: {
        id: 'quoteandbind.views.contact-details.Your Information',
        defaultMessage: 'Your Information'
    },
    // Quote Page
    quoteTitlePage: {
        id: 'quoteandbind.views.quote-details.Quote',
        defaultMessage: 'Quote'
    },
    additionalCoverages: {
        id: 'policycommon.ho.directives.ho-additional-coverages.Additional Coverages',
        defaultMessage: 'Additional Coverages'
    },
    mainCoveragesPremium: {
        id: 'policycommon.ho.directives.ho-main-coverages.Main Coverages Premium',
        defaultMessage: 'Main Coverages Premium : '
    },
    policyPeriod: {
        id: 'quoteandbind.views.confirmation.Policy Period',
        defaultMessage: 'Policy Period'
    }
});
