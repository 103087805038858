import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ContextConsumer, generateComponentId } from '@jutro/platform';
import { TranslatorContext } from '@jutro/locale';
import { isEmptyValue, isNilValue } from '@jutro/data';

import styles from './RadioButton.module.scss';

import { getOptionCode, getOptionName } from '@jutro/components/widgets/inputs/availableValues';

const contexts = [TranslatorContext];

export class RadioButton extends Component {
    static propTypes = {
        /**
         * Radio Button options
         */
        option: PropTypes.object,
        /**
         * Determines whether the field is disabled
         */
        disabled: PropTypes.bool,
        /**
         * Value to determine whether the button is selected or not
         */
        value: PropTypes.string,
        /**
         * Determines whether the field is required
         */
        required: PropTypes.bool,
        /**
         * Id of the radio button
         */
        id: PropTypes.string,
        /**
         * Callback when the value is changed
         */
        onValueChange: PropTypes.func,
        /**
         * TabIndex of the Radio Button
         */
        tabIndex: PropTypes.number,
        /**
         * className passed to the top element of RadioButton
         */
        className: PropTypes.string,
        /**
         * An additional className passed to the label
         */
        labelClassName: PropTypes.string,
    };

    inputRef = React.createRef();

    constructor(props, context) {
        super(props, context);

        const { id } = props;

        this.controlUniqueId = generateComponentId(id);
    }

    handleValueChange = evt => {
        const { onValueChange, model, path, disabled } = this.props;

        if (disabled) {
            return;
        }

        if (onValueChange) {
            onValueChange(evt.target.value, model || path);
        }
    };

    handleClick = evt => {
        const { onValueChange, model, path, disabled } = this.props;

        if (disabled) {
            return;
        }

        if (onValueChange && this.inputRef.current.value !== this.props.value) {
            onValueChange(this.inputRef.current.value, model || path);
        }

        evt.preventDefault();
    };

    handleKeyDown = evt => {
        const { onValueChange, model, path, disabled } = this.props;

        if (disabled) {
            return;
        }

        if (evt.key === ' ' || evt.key === 'Enter') {
            // spacebar
            if (onValueChange) {
                onValueChange(this.inputRef.current.value, model || path);
            }

            evt.preventDefault(); // disable scrolling with spacebar
        }
    };

    renderPrimaryText(translator) {
        const { option } = this.props;
        const name = getOptionName(option);

        if (!name) {
            return null;
        }

        return translator(name);
    }

    renderSecondaryText(translator, id) {
        if (!this.props.secondaryLabel) {
            return null;
        }

        return (
            <span
                className={styles.secondaryText}
                data-testid="radio-button-secondary-label"
                id={id}
            >
                {translator(this.props.secondaryLabel)}
            </span>
        );
    }

    renderComponent = translator => {
        const {
            id,
            option,
            value,
            disabled,
            required,
            onValueChange,
            textAlign,
            className,
            labelClassName,
            dangerouslySetInnerHTML,
            tabIndex,
            testId,
            secondaryLabel,
            'aria-label': ariaLabel,
            'aria-labelledby': ariaLabeledBy,
            ...other
        } = this.props;

        const classes = cx(styles.radioButton, className);
        const labelClasses = cx(styles.label, labelClassName);

        let code = getOptionCode(option);
        let checked = value?.toString() === code?.toString();

        if (isNilValue(code)) {
            code = 'empty';
            checked = isEmptyValue(value);
        }

        const { controlUniqueId } = this;
        const valueId = `${controlUniqueId}_${code}`;
        const labelText = this.renderPrimaryText(translator);
        const labelId = `${valueId}-label`;
        const secondaryLabelId = `${valueId}-secondary-label`;

        return (
            <div
                className={classes}
                aria-checked={checked}
                aria-disabled={disabled}
                data-testid="radio-button-control"
                role="radio"
                onClick={this.handleClick}
                onKeyDown={this.handleKeyDown}
                tabIndex={tabIndex}
                data-value={code}
                aria-labelledby={ariaLabeledBy ?? labelId}
                aria-label={ariaLabel}
                aria-describedby={secondaryLabel ? secondaryLabelId : undefined}
            >
                <input
                    type="radio"
                    aria-hidden
                    id={valueId}
                    value={code || ''}
                    onChange={this.handleValueChange}
                    checked={checked}
                    disabled={disabled}
                    required={required}
                    ref={this.inputRef}
                    {...other}
                    data-testid={testId ? `${testId}_${code}` : `${id}_${code}`}
                    tabIndex={-1}
                />
                <span
                    className={labelClasses}
                    data-testid="radio-button-label"
                    id={labelId}
                >
                    <span className={styles.primaryText}>{labelText}</span>
                    {secondaryLabel &&
                        this.renderSecondaryText(translator, secondaryLabelId)}
                </span>
            </div>
        );
    };

    render() {
        return (
            <ContextConsumer contexts={contexts}>
                {this.renderComponent}
            </ContextConsumer>
        );
    }
}
