import React, { useContext } from 'react';
import { Flex } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { LobIconUtil } from '@xengage/gw-portals-util-js'
import { PUP_LOB_CODES, LOBConstants, ICON_NAMES } from 'wmic-pe-portals-utils-js';
import { WMICWizardViewPage } from 'wmic-pe-portals-wizard-components-ui';
import { WMICIcon } from 'wmic-pe-components-platform-react';
import { WMICPolicyPuUnderlyingPoliciesComponent } from 'wmic-pe-capability-policy-common-react';

import { WMICPUPUnderlyingPoliciesMessages as messages } from 'wmic-pe-capability-gateway-quoteandbind-pup-react';
import metadata from './WMICPolicyPuUnderlyingPolicies.metadata.json5'

const UNDERLYING_POLICY_PATH = 'lobs.personalUmbrellaLine_PUE.coverables.underlyingPolicies';

function WMICPolicyPuUnderlyingPolicies(props) {

    const {
        wizardData: { policyVM },
    } = props;

    const translator = useContext(TranslatorContext);

    const getPolicyCell = (item) => {
        const icon =
            item.policyType === PUP_LOB_CODES.PWL_CODE ? ICON_NAMES.ICON_SHIP :
                LobIconUtil.getMaterialIcon(formatLOBCode(item.policyType));

        return  <Flex
            direction="row"
            alignItems="center"
            gap="none"
        >
            <WMICIcon icon={icon}/>
            {item.policyNumber}
        </Flex>
    };

    const getRiskCell = (item) => {
        switch (item.policyType){
            case PUP_LOB_CODES.PA_CODE:
                if (item.exposures.length === 1) {
                    return translator(messages.wmicUnderlyingPoliciesPassengerVehicleRisk)
                }
                if (item.isExcessPolicy) {
                    return translator(messages.wmicUnderlyingPoliciesExcessPolicy)
                }
                return translator(messages.wmicUnderlyingPoliciesPassengerVehicleRisks, { number: item.exposures.length} )
            case PUP_LOB_CODES.HO_CODE:
                if (item.exposures.length === 1) {
                    return translator(messages.wmicUnderlyingPoliciesPersonalPropertyRisk)
                }
                return translator(messages.wmicUnderlyingPoliciesPersonalPropertyRisks, { number: item.exposures.length})
            case PUP_LOB_CODES.PWL_CODE:
                if (item.exposures.length === 1) {
                    return translator(messages.wmicUnderlyingPoliciesWatercraftRisk)
                }
                return translator(messages.wmicUnderlyingPoliciesWatercraftRisks, { number: item.exposures.length})
        }
    };

    const formatLOBCode = (code) => {
        if (code === PUP_LOB_CODES.PA_CODE) { return LOBConstants.PA };
        if (code === PUP_LOB_CODES.HO_CODE) { return LOBConstants.PP };
        return code;
    };

    const getReadableLOBCode = (code) => {
        switch(code) {
            case PUP_LOB_CODES.PA_CODE:
                return messages.wmicUnderlyingPoliciesAuto
            case PUP_LOB_CODES.HO_CODE:
                return messages.wmicUnderlyingPoliciesPersonalProperty
            case PUP_LOB_CODES.PWL_CODE:
                return messages.wmicUnderlyingPoliciesWatercraft
            default:
                return "";
        }
    }

    const overrideProps = {
        underlyingPoliciesDataList: {
            value: _.get(policyVM, `${UNDERLYING_POLICY_PATH}.children`, []),
            VMData: [
                {
                    headerText: translator(messages.wmicUnderlyingPoliciesPolicy),
                    getData: (item) => getPolicyCell(item.value)
                },
                {
                    headerText: translator(messages.wmicUnderlyingPoliciesInsurer),
                    path: 'carrierNameDisplay'
                },
                {
                    headerText: translator(messages.wmicUnderlyingPoliciesRisks),
                    getData: (item) => getRiskCell(item.value)
                }
            ],
            readOnly: true,
            detailViewComponent: WMICPolicyPuUnderlyingPoliciesComponent
        }
    }

    return (
        <WMICWizardViewPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyVM}
                overrideProps={overrideProps}
            />
        </WMICWizardViewPage>
    );
}

WMICPolicyPuUnderlyingPolicies.propTypes = wizardProps;
export default WMICPolicyPuUnderlyingPolicies;
