import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'

import metadata from './WMICAddPayoutComponent.metadata.json5';
import messages from './WMICAddPayoutComponent.messages';

function WMICAddPayoutComponent(props){
    const {
        id,
        selectedPayout, 
        savePayout,
        cancelPayout,
        onValidate,
        isEditMode
    } = props;

    const {onValidate: setComponentValidation, isComponentValid} = useValidation(id);
    const { refreshData } = useDataRefresh();

    const [currentPayout, updateCurrentPayout] = useState(selectedPayout);
    const [showErrors, updateShowErrors] = useState(false);

    useEffect(() => {
        updateCurrentPayout(selectedPayout);
    }, [selectedPayout]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [isComponentValid, onValidate, currentPayout, id]);

    const updatePayout = (data) => {
        refreshData();
        updateCurrentPayout(data);
    };

    const handleSavePayout = () => {
        if(isComponentValid) {
            savePayout(currentPayout);
        } else {
            updateShowErrors(true);
        }
    };
    
    const resolvers = {
        resolveCallbackMap: {
            handleSavePayout,
            cancelPayout
        }
    };

    const overrideProps = {
        '@field': {
            parentNode: currentPayout,
            readOnly: false
        }
    };


    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentPayout}
            overrideProps={overrideProps}
            onModelChange={updatePayout}
            callbackMap={resolvers.resolveCallbackMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    )
}

WMICAddPayoutComponent.propTypes = {
    id: PropTypes.string.isRequired,
    selectedPayout: PropTypes.shape({}).isRequired,
    savePayout: PropTypes.func.isRequired,
    cancelPayout: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool.isRequired,
};

export default WMICAddPayoutComponent;