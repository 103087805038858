import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useAuthentication } from 'wmic-digital-auth-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useHistory } from 'react-router-dom';
import { CONSTANTS } from 'wmic-portals-utils-js';
import {
    WMICControllerUtil,
    WMICPolicyChangeUtilsService,
    WMICPolicyChangeStateService,
    DesignatePersonModel,
    DesignateAdditionalPersonModel
} from 'wmic-capability-policychange';
import { useModal } from '@jutro/components';
import { WMICModal } from 'gw-capability-policy-react';
import _ from 'lodash';
import classNames from 'classnames';
import { WMICButton } from 'wmic-components-platform-react';

import metadata from './WMICChangeDesignatedPersonActionComponent.metadata.json5';
import messages from './WMICChangeDesignatedPersonActionComponent.messages';
import styles from './WMICChangeDesignatedPersonActionComponent.module.scss';

function WMICChangeDesignatedPersonActionComponent(props) {
    const modalApi = useModal();
    const {
        policyVM,
        match,
        setActiveStep,
        designatedPersonData,
        handleChangeLoading,
        isLoading
    } = props;

    const translator = useTranslator();
    const history = useHistory();
    const { authHeader, userInfo, authUserData } = useAuthentication();
    const policyNumber = _.get(match, 'params.policyNumber');
    const termNumber = _.get(match, 'params.termNumber');
    const policyType = _.get(match, 'params.policyType');
    const accountHolder = _.get(policyVM, 'currentPeriod.contacts')
        .filter((contact) => { return contact.contact.accountHolder === true; })[0];

    const hasDesignatedPerson = designatedPersonData.contact !== undefined;
    const personData = {
        name: hasDesignatedPerson ? designatedPersonData.contact.displayName : '',
        street: hasDesignatedPerson ? designatedPersonData.contact.primaryAddress.addressLine1 : '',
        city: hasDesignatedPerson ? designatedPersonData.contact.primaryAddress.city : '',
        state: hasDesignatedPerson ? designatedPersonData.contact.primaryAddress.state : '',
        zip: hasDesignatedPerson ? designatedPersonData.contact.primaryAddress.postalCode : '',
        country: hasDesignatedPerson ? designatedPersonData.contact.primaryAddress.country : '',
        permission: undefined
    };


    const handleCancel = () => {
        history.push(`/account-policy-change/${policyNumber}/${termNumber}/${policyType}/${CONSTANTS.POLICY_CHANGE_TYPE.SELECT_TYPE}`);
    };

    const addContactButtonAction = () => {
        setActiveStep(CONSTANTS.POLICY_CHANGE_TYPE.DESIGNATED_PERSON_ADD);
    };

    const handleEditForm = () => {
        setActiveStep(CONSTANTS.POLICY_CHANGE_TYPE.DESIGNATED_PERSON_ADD);
    };

    const handleRemove = () => {
        const changeType = 'DELETE';
        const designatePerson = new DesignatePersonModel(personData);
        const designateAdditionalPersonModel = new DesignateAdditionalPersonModel({ changeType, designatePerson });
        const modalBody = WMICPolicyChangeUtilsService.getModifiedBody(designateAdditionalPersonModel, messages.pleaseConfirmTheDeletion, translator)
        modalApi.showModal(
            <WMICModal
                id="changeDesignatedPersonRemoveModal"
                modalHeader={translator(messages.removeContact)}
                modalBody={modalBody}
                onConfirmCallback={() => {
                    handleChangeLoading(true);
                    WMICPolicyChangeStateService.addChangeRequest('designated-person', changeType, {
                        changeType: 'designated-person-delete',
                        objectType: 'DesignateAdditionalPersonModel',
                        changeModel: designateAdditionalPersonModel
                    });
                    WMICControllerUtil.handleSubmit({
                        history: history,
                        policyNumber: policyNumber,
                        termNumber: termNumber,
                        policyData: policyVM,
                        policyType: policyType,
                        accountInfo: accountHolder,
                        authHeader: authHeader,
                        authUserData: authUserData,
                        userInfo: userInfo
                    },
                    translator);
                }}
                onCancelCallback={() => _.noop()}
                confirmButtonText={translator(messages.yesRemove)}
                cancelButtonText={translator(messages.cancel)}
            />
        );
    };

    const getAddPersonContent = () => {
        return (
            <Fragment>
                <h3 className={styles.description}>{ translator(messages.addDesignatedPersonInfo) }</h3>
                <WMICButton type="secondary" onClick={addContactButtonAction}>
                    {translator(messages.addContactButton)}
                </WMICButton>
            </Fragment>
        );
    };

    const getEditDesignatedPersonContent = () => {
        return (
            <Fragment>
                <p className={styles.description}>{translator(messages.editDesignatedPersonInfo)}</p>
                <div>
                    <p><strong>{translator(messages.currentContactOnRecord)}</strong></p>
                    <p>
                        {personData.name} <br />
                        {personData.street} <br />
                        {personData.city}, {personData.state}, {personData.zip} <br />
                        {personData.country}
                    </p>
                    <div className={styles.editRemoveBtnsWrapper}>
                        <WMICButton type="primary" size="small" onClick={handleEditForm}>
                            {translator(messages.edit)}
                        </WMICButton>

                        <WMICButton type="primary" size="small" onClick={handleRemove}>
                            {translator(messages.remove)}
                        </WMICButton>
                    </div>
                </div>
            </Fragment>
        );
    };

    const getActionButtonsPanelContent = () => {
        return (
            <Fragment>
                <WMICButton type="outlined" className={classNames(styles['wmic-action-button'])} onClick={handleCancel}>
                    { translator(messages.cancel) }
                </WMICButton>
            </Fragment>
        );
    };

    const overrideProps = {
        addDesignatedPersonContent: {
            content: getAddPersonContent(),
            visible: !hasDesignatedPerson
        },

        editDesignatedPersonContent: {
            content: getEditDesignatedPersonContent(),
            visible: hasDesignatedPerson
        },

        designatedPersonActionButtonsPanelContent: {
            content: getActionButtonsPanelContent()
        }

    };

    const resolvers = {
        resolveComponentMap: {
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyVM}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICChangeDesignatedPersonActionComponent.propTypes = {
    policyVM: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({}).isRequired,
    setActiveStep: PropTypes.func.isRequired,
    designatedPersonData: PropTypes.shape({
        contactRole: PropTypes.string,
        contact: PropTypes.shape({
            displayName: PropTypes.string,
            primaryAddress: PropTypes.shape({
                addressLine1: PropTypes.string,
                city: PropTypes.string,
                state: PropTypes.string,
                postalCode: PropTypes.string,
                country: PropTypes.string
            })
        })
    }),
};

WMICChangeDesignatedPersonActionComponent.defaultProps = {
    designatedPersonData: {}
};

export default WMICChangeDesignatedPersonActionComponent;