import { defineMessages } from 'react-intl';

export default defineMessages({

    mitigationType: {
        id: "wmic-pe-capability-gateway-common-ho-react.WaterMitigationDetails.Type",
        defaultMessage: "Type"
    },
    sumpPumpType: {
        id: "wmic-pe-capability-gateway-common-ho-react.WaterMitigationDetails.Sump Pump Type",
        defaultMessage: "Sump Pump Type"
    },
    sumpPumpAuxPower: {
        id: "wmic-pe-capability-gateway-common-ho-react.WaterMitigationDetails.Backup Power Supply",
        defaultMessage: "Backup Power Supply"
    },
    backupValve: {
        id: "wmic-pe-capability-gateway-common-ho-react.WaterMitigationDetails.Backup Valve",
        defaultMessage: "Backup Valve"
    },
    removeTitle: {
        id: "wmic-pe-capability-gateway-common-ho-react.WaterMitigationDetails.Removing water mitigation",
        defaultMessage: "Removing water mitigation"
    },
    removeMessage: {
        id: "wmic-pe-capability-gateway-common-ho-react.WaterMitigationDetails.Are you sure water mitigation",
        defaultMessage: "Are you sure you want to remove this water mitigation?"
    },
    description: {
        id: "wmic-pe-capability-gateway-common-ho-react.WaterMitigationDetails.Description",
        defaultMessage: "Description"
    }

});
