import { setComponentMapOverrides } from '@jutro/uiconfig';
import { PaymentDetailsPage } from 'gw-capability-quoteandbind-common-react';
import 'wmic-pe-capability-quoteandbind-ho-react';
import WMICHOYourHomePage from './pages/WMICHOYourHome/WMICHOYourHomePage';
import WMICHOConstructionPage from './pages/WMICHOConstruction/WMICHOConstructionPage';
import WMICQuoteHOPolicyDetailsPage from './pages/WMICPolicyDetails/WMICPolicyDetailsPage';
import WMICHOInsuranceHistoryPage from './pages/WMICHOInsuranceHistory/WMICHOInsuranceHistoryPage';
import WMICHOScheduledItemsPage from './pages/WMICHOScheduledItems/WMICHOScheduledItemsPage';
import WMICAdlIntGoldSearch from './components/WMICAdlIntGoldSearch/WMICAdlIntGoldSearch';

setComponentMapOverrides(
    {
        WMICQuoteHOPolicyDetailsPage: { component: 'WMICQuoteHOPolicyDetailsPage' },
        GWQuoteHOPaymentDetailsPage: { component: 'GWQuoteHOPaymentDetailsPage' },
        WMICHOInsuranceHistoryPage: { component: 'WMICHOInsuranceHistoryPage' },
        WMICHOScheduledItemsPage: { component: 'WMICHOScheduledItemsPage' },
        WMICHOYourHomePage: { component: 'WMICHOYourHomePage' },
        WMICHOConstructionPage: { component: 'WMICHOConstructionPage'},
        WMICAdlIntGoldSearch: { component: 'WMICAdlIntGoldSearch' }
    },
    {
        WMICQuoteHOPolicyDetailsPage,
        GWQuoteHOPaymentDetailsPage: PaymentDetailsPage,
        WMICHOInsuranceHistoryPage,
        WMICHOScheduledItemsPage,
        WMICHOYourHomePage,
        WMICHOConstructionPage,
        WMICAdlIntGoldSearch
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as WatercraftContext } from './contexts/WatercraftContext';
export { default as WMICPEHOWizard } from './WMICPEHOWizard';
export { default as HOSubmissionLobDataExtension } from './extension/HOSubmissionLobDataExtension';
export { default as WMICValidationWarningUtil } from './util/WMICValidationWarningUtil';
export { isResidenceTypeLaneway, renderDwellingRiskNumberCell, renderPrimaryCell, renderDwellingAddressCell } from './util/WMICHOYourHomeUtil';
export { default as WMICAdlIntGoldSearch } from './components/WMICAdlIntGoldSearch/WMICAdlIntGoldSearch';
