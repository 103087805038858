import React, { useContext } from 'react';
import cx from 'classnames';
import { ModalNext, Icon, ModalBody, ModalFooter, ModalHeader } from '@jutro/components';
import { WMICButton } from 'wmic-pe-components-platform-react';
import { Flex } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import { WMICRichTextUtil } from 'wmic-pe-portals-utils-js';
import PropTypes from 'prop-types';

import messages from './WizardBMSIModal.messages';
import styles from './WizardBMSIModal.module.scss';

function WizardBMSIModal(props) {
    const { isOpen, onResolve, validationIssues, defaultedProperties } = props;
    const translator = useContext(TranslatorContext);

    return (
        <ModalNext isOpen={isOpen}>
            <Flex direction="column" id="modalRoot">
                <ModalHeader status="error" title={translator(messages.attentionHeader)} />
                <ModalBody>
                    <Flex direction="column" alignItems="space-evenly">
                        {validationIssues?.length > 0 && (
                            <>
                                <Flex direction="row" alignItems="center" justifyContent="flex-start" >
                                    <Icon className={styles.error} id="iconForBMSIErrors" icon="gw-error" />
                                    <span className={cx( styles.sectionHeader, styles.error )} > {WMICRichTextUtil.translateRichText( translator(messages.errorHeader) )} </span>
                                </Flex>
                                <span className={styles.sectionBody}>
                                    {WMICRichTextUtil.translateRichText( translator(messages.errorBody) )}
                                </span>
                                <hr />
                            </>
                        )}
                        {defaultedProperties?.length > 0 && (
                            <>
                                <Flex direction="row" alignItems="center" justifyContent="flex-start" >
                                    <Icon className={styles.warning} id="iconForBMSIWarnings" icon="gw-error" />
                                    <span className={cx( styles.sectionHeader, styles.warning )} >
                                        {WMICRichTextUtil.translateRichText( translator(messages.defaultHeader) )}
                                    </span>
                                </Flex>
                                <span className={styles.sectionBody}>
                                    {WMICRichTextUtil.translateRichText(
                                        translator(messages.defaultBody)
                                    )}
                                </span>
                            </>
                        )}
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    <WMICButton
                        id="bmsi-modal-confirm"
                        onClick={onResolve}
                        type="primary"
                    >
                        {translator(messages.okButton)}
                    </WMICButton>
                </ModalFooter>
            </Flex>
        </ModalNext>
    );
}

WizardBMSIModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired
};

export default WizardBMSIModal;
