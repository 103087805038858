import _ from 'lodash';
import { LOBConstants } from 'wmic-pe-portals-utils-js';
import { PASubmissionDraftDataExtension } from 'wmic-pe-capability-gateway-quoteandbind-pa-react';
import { HOSubmissionLobDataExtension } from 'wmic-pe-capability-gateway-quoteandbind-ho-react';
import { PUPSubmissionLobDataExtension } from 'wmic-pe-capability-gateway-quoteandbind-pup-react';
import { CPSubmissionLobDataExtension } from 'wmic-pe-capability-gateway-quoteandbind-cp-react';
import { GLSubmissionLobDataExtension } from 'wmic-pe-capability-gateway-quoteandbind-gl-react';
import { IMSubmissionLobDataExtension } from 'wmic-pe-capability-gateway-quoteandbind-im-react';

export default {
    mixinLobDraftDataExtensions: (submissionVM) => {
        const productCode = _.get(submissionVM, 'baseData.productCode');
        if (_.isUndefined(productCode)) {
            return;
        }
        switch (productCode) {
            case LOBConstants.PA:
                _.set(submissionVM, 'lobData.personalAuto', new PASubmissionDraftDataExtension(submissionVM));
                break;
            case LOBConstants.PP:
                _.set(submissionVM, 'lobData.homeowners', new HOSubmissionLobDataExtension(submissionVM));
                break;
            case LOBConstants.PUP:
                _.set(submissionVM, 'lobData.personalUmbrella', new PUPSubmissionLobDataExtension(submissionVM));
                break;
            case LOBConstants.CP:
                _.set(submissionVM, 'lobData.commercialProperty', new CPSubmissionLobDataExtension(submissionVM));
                _.set(submissionVM, 'lobData.generalLiability', new GLSubmissionLobDataExtension(submissionVM));
                _.set(submissionVM, 'lobData.inlandMarine', new IMSubmissionLobDataExtension(submissionVM));
                break;
            default:
                break;
        }
    }
};
