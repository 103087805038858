import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import _ from "lodash";
import { Position } from 'wmic-pe-portals-utils-js';
import WMICLocationCoveragesTable from "../WMICLocationCoveragesTable/WMICLocationCoveragesTable"
import WMICIterableBuildingComponent from '../WMICIterableBuildingComponent/WMICIterableBuildingComponent'
import metadata from './WMICIterableLocationComponent.metadata.json5';
import messages from'./WMICIterableLocationComponent.messages';
import styles from './WMICIterableLocationComponent.module.scss';


function WMICIterableLocationComponent(props) {
    const { jobVM, location, locationCoverages, rateAsOfDate, buildings, isPolicyChangeOrRenewal, submissionVM } = props;
    const [showCoveragesContainer, setShowCoveragesContainer] = useState( false);

    const categorizeCoverages = () => {
        return {
            businessIncomeCoverages: _.filter(locationCoverages.value, {'selected': true}) || [],
            ...location.value
        };
    };

    const getBuildingCoverages = (cpData, buildingPublicID) => {
        const buildingCoverages = jobVM.offerings.children[0].coverages.buildingCoverages.children.find(
            (buildingCoverage) => {
                return buildingCoverage.value.publicID === buildingPublicID;
            }
        );

        return buildingCoverages ? buildingCoverages.coverages : [];
    }

    const _iterableProps = () => {
        const _buildingOverrides = buildings.children.map((bldg, index) => {
            const _buildingCoverages = getBuildingCoverages(jobVM, bldg.publicID.value);

            const _buildingOverride = {
                [`iterableBuildingComponent${index}`]: {
                    building: bldg,
                    buildingCoverages: _buildingCoverages,
                    showCoveragesContainer: showCoveragesContainer,
                    isPolicyChangeOrRenewal,
                    submissionVM
                }
            };
            return Object.assign({}, _buildingOverride);
        })

        return Object.assign({}, ..._buildingOverrides);
    }


    const toggleShowCoverages = () => {
        setShowCoveragesContainer(!showCoveragesContainer);
    }

    const overrides = {
        '@field': {
            parentNode: location,
            readOnly: true,
            labelPosition: Position.TOP
        },
        expandIcon: {
            icon: showCoveragesContainer ? 'mi-expand-less' : 'mi-expand-more',
        },
        locationCoveragesTable: {
            visible: showCoveragesContainer,
            locationCoverages: categorizeCoverages(),
            isPolicyChangeOrRenewal,
            submissionVM
        },
        buildingTableIterableContainer: {
            data: buildings.children,
            submissionVM
        },
        ..._iterableProps()
    };

    const resolvers = {
        resolveCallbackMap: {
            toggleShowCoverages
        },
        resolveComponentMap: {
            WMICLocationCoveragesTable,
            WMICIterableBuildingComponent
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            model={location}
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICIterableLocationComponent.propTypes = {
    value: PropTypes.shape({}).isRequired
};

export default WMICIterableLocationComponent;
