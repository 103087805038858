import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import _ from 'lodash';
import metadata from './WaterMitigationDetails.metadata.json5';
import messages from './WaterMitigationDetails.messages';

function WMICAddMitigationTypeComponent(props){
    const {
        id,
        riskView,
        selectedMitigationTypeVM, 
        saveMitigationType,
        cancelMitigationType,
        onValidate,
        isEditMode
    } = props;

    const {onValidate: setComponentValidation, isComponentValid} = useValidation(id);

    const [currentMitigationTypeVM, updateCurrentMitigationType] = useState(selectedMitigationTypeVM);
    const { refreshData } = useDataRefresh();
    const [showErrors, updateShowErrors] = useState(false);

    useEffect(() => {
        updateCurrentMitigationType(selectedMitigationTypeVM);
    }, [riskView, selectedMitigationTypeVM])

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        return () => {
            if(onValidate){
                onValidate(true, id)
            }
        }
    }, [isComponentValid, onValidate, currentMitigationTypeVM, id]);

    const updateMitigationType = (data) => {
        refreshData();
        updateCurrentMitigationType(data);
    };

    const handleSaveMitigationType = () => {
        if(isComponentValid) {
            saveMitigationType(currentMitigationTypeVM)
        } else {
            updateShowErrors(true)
        }
    };
    
    const resolvers = {
        resolveCallbackMap: {
            handleSaveMitigationType,
            cancelMitigationType
        }
    };

    const overrideProps = {
        '@field': {
            parentNode: currentMitigationTypeVM,
            readOnly: !isEditMode
        },
        WMICAddMitigationTypeForm: {
            hideButtons: !isEditMode
        }
    };


    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={currentMitigationTypeVM}
            overrideProps={overrideProps}
            onModelChange={updateMitigationType}
            callbackMap={resolvers.resolveCallbackMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    )
}

WMICAddMitigationTypeComponent.propTypes = {
    id: PropTypes.string.isRequired,
    selectedMitigationType: PropTypes.shape({}).isRequired,
    saveMitigationType: PropTypes.func.isRequired,
    cancelMitigationType: PropTypes.func.isRequired,
    updateSelectedMitigationType: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired
};

export default WMICAddMitigationTypeComponent;