/* eslint-disable unicorn/filename-case */
/* eslint-disable consistent-return */
/* eslint-disable no-secrets/no-secrets */

import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { CheckboxField , DropdownMenuLink } from '@jutro/components';

import '@xengage/gw-platform-translations';
import { ViewModelForm, ViewModelServiceContext, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { WMICRichTextUtil, WMICJurisdictionUtil, WMICDateTimeService, JURISDICTIONS, CONSTANTS, MODAL_CONSTANTS, JobType, WMICLogger } from 'wmic-pe-portals-utils-js';
import PropTypes from 'prop-types';
import { useValidation } from '@xengage/gw-portals-validation-react'

import { WMICAddLossHistoryComponent } from 'wmic-pe-capability-gateway-quoteandbind-pa-react'
import cx from 'classnames';
import { UISupportingInfoLookupService } from 'wmic-pe-capability-supportinginfo';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';

import messages from './WMICPALossHistoryComponent.messages';
import metadata from './WMICPALossHistoryComponent.metadata.json5';
import styles from './WMICPALossHistoryComponent.module.scss'
import { WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';

const DRIVERS_PATH = 'lobData.personalAuto.coverables.drivers';
const VEHICLES_PATH = 'lobData.personalAuto.coverables.vehicles';

let carriersList;

function WMICPALossHistoryComponent(props) {
    const {
        id,
        insuranceHistoryVM,
        updateWizardData,
        submissionVM,
        updateHistory,
        showErrors,
        isReadOnlyUser,
        isEditMode,
        baseData,
        onValidate,
        authHeader
    } = props;

    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { setWizardLoading, showConfirm } = useWizardModals();
    const { refreshData } = useDataRefresh();

    const [addingLossHistory, setAddingLossHistory] = useState(false);
    const [editLossVehicle, setEditLossVehicle] = useState(false);
    const [readOnlyPriorLoss, updateReadOnlyPriorLoss] = useState();
    const [editingPriorLossIndex, setEditingPriorLossIndex] = useState(-1);
    const [shouldWait, setShouldWait] = useState(false);
    const [editingIndex, updateEditingIndex] = useState(-1);
    const [selectedDriversForFCSA, setSelectedDriversForFCSA] = useState([]);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [vehiclesChoices, setVehiclesChoices] = useState();
    const [vehicleOperators, setVehicleOperators] = useState([]);
    const [isPriorLossReadOnly, setPriorLossReadOnly] = useState(false);
    const [isSourceFCSA, setSourceFCSA] = useState(false);

    const availableDrivers = _.get(submissionVM, `${DRIVERS_PATH}.value`);
    const availableVehicles = _.get(submissionVM, `${VEHICLES_PATH}.value`);
    const isPolicyChangeOrRenewal =
        _.get(submissionVM, 'baseData.jobType.value.code') === JobType.POLICY_CHANGE ||
        _.get(submissionVM, 'baseData.jobType.value.code') === JobType.RENEWAL;

    const updateHistoryData = (data) => {
        refreshData();
        updateHistory(data);
    };

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [isComponentValid, onValidate, id]);

    useEffect(() => {
        let driversForVehicle;

        initializeVehicleList();

        if(isPolicyChange() && hasNewDrivers){

            driversForVehicle = _.filter(availableDrivers, (driver) => {
                if(!driver.isExistingInPreviousPeriodDriver) {
                    return driver;
                }
            });
        }
        else{
            driversForVehicle = availableDrivers;
        }

        _.each(driversForVehicle, (driver) => {
            vehicleOperators.push({
                code: driver.displayName,
                name: driver.displayName
            });
        });

        vehicleOperators.push({
            code: 'reportedDriver',
            name: translator(messages.reportedDriver)
        });

        UISupportingInfoLookupService.retrieveInsuranceCarriersList([baseData.baseState.value.code, baseData.periodStartDate.value], true, authHeader)
            .then((carriers) => {
                carriers.forEach((item) => {
                    // eslint-disable-next-line no-param-reassign
                    item.name = item.carrier
                    // eslint-disable-next-line no-param-reassign
                    item.code = item.carrier
                })
                carriersList = carriers;
            });

        setVehicleOperators(vehicleOperators)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hasNewDrivers = _.some(availableDrivers, (driver) => !driver.isExistingInPreviousPeriodDriver_WMIC);

    const initializeVehicleList = () => {
        const vehicles = [];

        _.each(availableVehicles, (vehicle) => {
            vehicles.push({
                code: vehicle.publicID,
                name: vehicle.displayName
            });
        });

        const vehicleList = addOther(vehicles);

        setVehiclesChoices(vehicleList);
    };

    const addOther = (choices) => {
        const newVehiclesList = choices.slice(0);

        newVehiclesList.push({
            code: 'other',
            name: translator(messages.vehicleOther)
        });

        return newVehiclesList;
    };

    const addNewPriorLoss = () => {
        const priorLoss = viewModelService.create(
            {},
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.common.draft.dto.insurancehistory.PriorLoss_WMICDTO',
            submissionVM.aspects.context()
        );

        priorLoss.value.paPriorLoss = viewModelService.create(
            {vehicleOperator: 'reportedDriver'},
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.common.draft.dto.insurancehistory.PaPriorLoss_WMICDTO',
            submissionVM.aspects.context()
        ).value;
        priorLoss.value.isReadOnly = false;
        priorLoss.isUnderEditing = true;
        setEditingPriorLossIndex(-1);
        setAddingLossHistory(true);
        setEditLossVehicle(true);
        _.set(insuranceHistoryVM, 'isAddingPriorLoss.value', true);
        updateReadOnlyPriorLoss(priorLoss);
        updateHistoryData(insuranceHistoryVM);
    };

    const savePriorLoss = (priorLoss) => {
        resetChargeableAndUseForRatingData(priorLoss);

        if (editingPriorLossIndex === -1) {
            insuranceHistoryVM.priorLosses.value.push(priorLoss.value);
        } else {
            insuranceHistoryVM.priorLosses.setElement(editingPriorLossIndex, priorLoss.value);
        }

        setAddingLossHistory(false);
        _.set(insuranceHistoryVM, 'isAddingPriorLoss.value', false);
        updateReadOnlyPriorLoss(undefined);
        setEditLossVehicle(false);
        updateHistoryData(insuranceHistoryVM);
    };

    const cancelPriorLoss = () => {
        setAddingLossHistory(false);
        setEditLossVehicle(false);
        _.set(insuranceHistoryVM, 'isAddingPriorLoss.value', false);
        updateReadOnlyPriorLoss(undefined);
        updateHistoryData(insuranceHistoryVM);
    };

    const isManualEntryLoss = (priorLoss) => {
        const sourceType = _.get(priorLoss, 'sourceType.value', undefined);

        return  sourceType === undefined ||
            sourceType.typelist.getFilter('MANUAL_SOURCES').allows(sourceType);
    };

    const canEditLossVehicle = (priorLoss, index) => {
        let lossVehicleEditable = false;

        if (index !== -1 && priorLoss) {
            lossVehicleEditable = isManualEntryLoss(priorLoss);
        }

        return lossVehicleEditable;
    };

    const handleSelectPriorLoss = (data, index) => {
        const isFCSAType = _.get(data, 'sourceType.value.code') === 'fcsa';

        // eslint-disable-next-line no-unused-expressions
        data.isReadOnly && setPriorLossReadOnly(true);
        setSourceFCSA(isFCSAType);
        editPriorLoss(data, index, true);
    };

    const editPriorLoss = (priorLoss, index, viewMode = false) => {
        // eslint-disable-next-line no-param-reassign
        priorLoss = viewModelService.changeContext(priorLoss, submissionVM.aspects.context());
        setAddingLossHistory(true);
        setEditLossVehicle(canEditLossVehicle(priorLoss, index));
        // eslint-disable-next-line no-param-reassign
        priorLoss.isUnderEditing = !viewMode;
        setEditingPriorLossIndex(index);
        updateReadOnlyPriorLoss(priorLoss);
        _.set(insuranceHistoryVM, 'isAddingPriorLoss.value', true);
        updateHistoryData(insuranceHistoryVM);
    };

    const removePriorLoss = (item, index) => {
        showConfirm({
            title: messages.removeLossHistoryTitle,
            message: translator(messages.removeLossHistoryMsg),
            status: MODAL_CONSTANTS.STATUS.WARNING,
            icon: MODAL_CONSTANTS.ICON.WARNING,
            confirmButtonText: messages.yes,
            cancelButtonText: messages.no
        }).then((result)=> {
            if (result === CONSTANTS.MODAL_RESULT.CONFIRM) {
                const priorLosses = _.get(insuranceHistoryVM, 'priorLosses.value');

                priorLosses.splice(index, 1);
                cancelPriorLoss();
            }
        });
    };

    const isFCSAOrderSuccessForAllDrivers = () => {
        const driversCount = _.get(submissionVM, `${DRIVERS_PATH}.value`, []).length;
        const successCount = _.countBy(_.get(insuranceHistoryVM, 'fcsaOrderedLossHistory.value'), {fcsaOrderStatus: 'Received'}).true;

        return driversCount === successCount;
    };

    const isPolicyChange = () => {
        const transactionType = _.get(baseData, 'jobType.code');

        return transactionType === 'PolicyChange';
    };

    const isPartialFailure = () => {
        const driversCount = _.get(submissionVM, `${DRIVERS_PATH}.value`, []).length;
        const errorCount = _.countBy(_.get(insuranceHistoryVM, 'fcsaOrderedLossHistory.value'), {fcsaOrderStatus: 'Error'}).true;

        return driversCount > errorCount;
    };

    const isFullFailure = () => {
        const driversCount = _.get(submissionVM, `${DRIVERS_PATH}.value`, []).length;
        const errorCount = _.countBy(_.get(insuranceHistoryVM, 'fcsaOrderedLossHistory.value'), {fcsaOrderStatus: 'Error'}).true;

        return driversCount === errorCount;
    };

    const getOrderedLossHistoryFCSA = (driver) => {
        //need middle name to match pc data, currently driver.displayName does not include the middle name
        const driverIncMiddleName = driver.person.displayName;
        return _.find(insuranceHistoryVM.fcsaOrderedLossHistory?.value, {requestedDriver: driverIncMiddleName});
    };

    const isSuccess = (driver) => {
        const fcsaOrderedLossHistory = getOrderedLossHistoryFCSA(driver);

        return fcsaOrderedLossHistory?.fcsaOrderStatus === 'Received';

    };

    const isError = (driver) => {
        const fcsaOrderedLossHistory = getOrderedLossHistoryFCSA(driver);

        if (fcsaOrderedLossHistory?.fcsaOrderStatus === 'Error') {
            return true;
        }

        return false;
    };

    const isRequired = (driver) => {
        const fcsaOrderedLossHistory = getOrderedLossHistoryFCSA(driver);

        return _.isUndefined(fcsaOrderedLossHistory);
    };

    const getDriver = (data) => {
        const driverName = data.value.paPriorLoss.vehicleOperator;
        const drivers = _.get(submissionVM, `${DRIVERS_PATH}.value`);

        const driver = _.find(drivers, {displayName: driverName});

        return driver ? `#${driver.driverNumber_WMIC}: ${driver.displayName}` : `#:${translator(messages.reportedDriver)}`;
    };

    const getAtFaultDisplayName = (data) => translator({
            id: `typekey.AtFaultIndicator_WMIC.${data.value.paPriorLoss.atFault}`
        })

    const localDate2Date = (data) => WMICDateTimeService.toMidnightDate(data.lossDate.value);

    const getClaimNumber = (data) => data.value.claimNumber;

    const getPolicyNumber = (data) => data.value.policyNum;

    const getDriverName = (driver) => {
        const fcsaOrderedLossHistory = getOrderedLossHistoryFCSA(driver);

        if (fcsaOrderedLossHistory?.fcsaOrderStatus) {
            if (fcsaOrderedLossHistory.fcsaOrderStatus === 'Error') {
                return translator(messages.FCSADriverNameError, {driverName: driver.displayName, date: WMICDateTimeService.toMidnightDate(fcsaOrderedLossHistory.dateFCSAOrdered)});
            }

 if (fcsaOrderedLossHistory.fcsaOrderStatus === 'Received') {
                return translator(messages.FCSADriverNameSuccess, {driverName: driver.displayName, date: WMICDateTimeService.toMidnightDate(fcsaOrderedLossHistory.dateFCSAOrdered)});
            }
        } else if (isPolicyChange()){
            return translator(messages.FCSADriverNameSuccess, {driverName: driver.displayName});
        }

        return driver.displayName;
    };
    
    const isBaseStateQC = () => WMICJurisdictionUtil.isBaseState(_.get(submissionVM, 'baseData'), JURISDICTIONS.QUEBEC);

    const getFCSAmsg = () => {
        if (!isPolicyChange()) {
            if (!isPartialFailure() && !isFullFailure()) {
                return translator(messages.FCSAFailure);
            }

 if (isPartialFailure()) {
                return translator(messages.FCSAPartialFailure);
            }

 if (isFullFailure()) {
                return WMICRichTextUtil.translateRichText(translator(messages.FCSAFullFailure));
            }
        } else {
            return translator(messages.FCSAFailurePolicyChanged);
        }
    };

    const getFCSAdrivers = () => {
        const drivers = _.get(submissionVM, `${DRIVERS_PATH}.value`);
        const fcsaProvided = _.get(insuranceHistoryVM, 'fcsaProvided.value');

        return drivers?.map((driver) => (
                <div className={styles.fcsaDriverContainer}>
                    {isSuccess(driver) && !isError(driver) && !fcsaProvided && (
                        <div className={cx(styles.badge, styles.badgeApproved)}>
                            <i className="mir mi-check" />
                            {translator(messages.FCSASuccess)}
                        </div>
                    )}
                    {isRequired(driver) && !shouldWait && !fcsaProvided && (
                        <div className={cx(styles.badge, styles.badgeRequired)}>
                            <i className="fa fa-fw fa-asterisk" />
                            {translator(messages.FCSARequired)}
                        </div>
                    )}
                    {fcsaProvided && (
                        <div className={cx(styles.badge, styles.badgeNotRequested)}>
                            <i className="mir mi-remove" />
                            {translator(messages.FCSANotRequested)}
                        </div>
                    )}
                    {shouldWait && (
                        <div className={cx(styles.badge, styles.badgeWait)}>
                            <i className="fa fa-fw fa-spinner" />
                            {translator(messages.FCSAWait)}
                        </div>
                    )}
                    {!isSuccess(driver) && isError(driver) && !fcsaProvided && (
                        <div className={cx(styles.badge, styles.badgeError)}>
                            <i className="mir mi-close" />
                            {translator(messages.FCSAError)}
                        </div>
                    )}
                    <span className={isError(driver) && styles.fcsaError}>{getDriverName(driver)}</span>
                </div>
            ))
    };

    const getSelectedDriverID = (driver) => _.find(selectedDriversForFCSA, (selectedDriver) => selectedDriver.publicID === driver.publicID);

    const getAvailableDrivers = () => {
        const drivers = _.get(submissionVM, `${DRIVERS_PATH}.value`, []);

        return drivers.map((driver) => (
                <DropdownMenuLink
                    className={styles.driversForFCSA}
                    onMenuItemClick={() => handleDriverClick(driver)}
                >
                    <CheckboxField
                        controlClassName={styles.driversCheckbox}
                        onValueChange={() => handleDriverClick(driver)}
                        label={driver.displayName}
                        showInlineLabel
                        value={
                            driver.publicID === getSelectedDriverID(driver)?.publicID
                        }
                    />

                </DropdownMenuLink>
            ));
    };

    const handleDriverClick = (driver) => {
        setDropdownOpen(true);

        if (selectedDriversForFCSA.includes(driver)) {
            const driverIndex = selectedDriversForFCSA.indexOf(driver);

            setSelectedDriversForFCSA((prevState) => _.filter(prevState, (selectedDriver, selectedDriverIndex) => selectedDriverIndex !== driverIndex));
        } else {
            setSelectedDriversForFCSA((prevState) => [...prevState, driver]);
        }
    };

    const orderFCSAForSelectedDrivers = async () => {
        if (selectedDriversForFCSA.length > 0) {
            setShouldWait(true);

            const transactionID = _.get(baseData, 'jobType.value.code') === JobType.SUBMISSION
                    ? submissionVM.quoteID.value
                    : submissionVM.jobID.value;
            const listOfSelectedPublicId = selectedDriversForFCSA.map(
                driver => driver.publicID
            );
            try {
                setWizardLoading(true);
                const result = await LoadSaveService.orderFCSAForDrivers(
                    transactionID,
                    listOfSelectedPublicId,
                    authHeader
                );
                if (result) {
                    _.set(
                        insuranceHistoryVM,
                        'fcsaOrderedLossHistory.value',
                        result.fcsaOrderedLossHistory
                    );
                    _.set(
                        insuranceHistoryVM,
                        'priorLosses.value',
                        result.priorLosses
                    );
                }

                updateWizardData(submissionVM);
            } catch (err) {
                WMICLogger.error('Error ordering loss history', err);
                WMICErrorHandler.processAsModal(err);
            } finally {
                setWizardLoading(false);
            }

            setShouldWait(false);
        }
    };

    const fcsaDropDownToggled = () => {
        let duplicateCallDrivers = '';

        setDropdownOpen(false);

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < insuranceHistoryVM.fcsaOrderedLossHistory?.value.length; i++) {
            // eslint-disable-next-line no-plusplus
            for (let j = 0; j < selectedDriversForFCSA.length; j++ ){
                if(selectedDriversForFCSA[j].displayName === insuranceHistoryVM.fcsaOrderedLossHistory?.value[i].requestedDriver
                && insuranceHistoryVM.fcsaOrderedLossHistory?.value[i].fcsaOrderStatus === 'Received'){
                    duplicateCallDrivers = duplicateCallDrivers === '' ? selectedDriversForFCSA[j].displayName
                        : `${duplicateCallDrivers  }, ${  selectedDriversForFCSA[j].displayName}`;
                }

            }
        }

  ;

        if (duplicateCallDrivers) {
            const modalMsg = translator(messages.fcsaConfirmationMsg, { driverName: duplicateCallDrivers });

            showConfirm({
                title: messages.fcsaConfirmationTitle,
                message: modalMsg
            }).then((res) => {
                if (res === CONSTANTS.MODAL_RESULT.CONFIRM) {
                    orderFCSAForSelectedDrivers();
                }
            }).catch(_.noop);
        } else {
            orderFCSAForSelectedDrivers();
        }
    };

    const isViewMode = () => (!readOnlyPriorLoss?.value.isReadOnly || editLossVehicle) && readOnlyPriorLoss?.isUnderEditing;

    const handleFCSADriverBtnClick = (e) => {
        e.stopPropagation();
        setDropdownOpen(!isDropdownOpen);
    };

    const resetChargeableAndUseForRatingData = (data) => {
        _.set(data, "paPriorLoss.value.chargeable", null);
        _.set(data, "paPriorLoss.value.useForRating", null);
    };

    const getChargeableMessage = (data) => {
        const chargeable = _.get(data, 'paPriorLoss.value.chargeable');

        if (_.isNil(chargeable)) {
            return translator(messages.pending);
        }

        return chargeable ? translator(messages.yes) : translator(messages.no);
    };

    const getUsedForRatingMessage = (data) => {
        const useForRating = _.get(data, 'paPriorLoss.value.useForRating');

        if (_.isNil(useForRating)) {
            return translator(messages.pending);
        }

        return useForRating ? translator(messages.yes) : translator(messages.no);
    };

    const isChargeableOrUseForRatingVisible = () => JURISDICTIONS.CHARGEABLE_USE_FOR_RATING_STATES.includes(_.get(baseData, 'baseState.value.code'))

    const canShowEditButton = (index, data) => {
        const isFCSASource = _.get(data, 'sourceType.value.code') === 'fcsa';
        return ((readOnlyPriorLoss && readOnlyPriorLoss.value && !readOnlyPriorLoss.value.isReadOnly) || canEditLossVehicle(data, index)) && !isFCSASource;
    }

    const overrideProps = {
        '@field': {
            parentNode: insuranceHistoryVM,
            readOnly: !isEditMode || isReadOnlyUser
        },
        noPriorLossesContainer: {
            visible: _.get(insuranceHistoryVM, 'priorLosses.value', []).length === 0
        },
        insuranceLossHistoryDataList: {
            VMList: _.get(insuranceHistoryVM, 'priorLosses.children', []),
            VMData: [
                {
                    headerText: translator(messages.driverName),
                    getData: getDriver
                },
                {
                    headerText: isBaseStateQC() ? translator(messages.claimNumber) : translator(messages.policyNumber),
                    getData: isBaseStateQC() ? getClaimNumber : getPolicyNumber
                },
                {
                    headerText: translator(messages.atFault),
                    getData: getAtFaultDisplayName
                },
                {
                    headerText: translator(messages.lossDate),
                    getData: localDate2Date
                },
                {
                    headerText: translator(messages.vehicleChargeable),
                    getData: getChargeableMessage,
                    visibilityCondition: isChargeableOrUseForRatingVisible
                },
                {
                    headerText: translator(messages.vehicleUseForRating),
                    getData: getUsedForRatingMessage,
                    visibilityCondition: isChargeableOrUseForRatingVisible
                },
            ],
            onEditAction: editPriorLoss,
            onRemoveAction: removePriorLoss,
            flatCards: true,
            clickable: true,
            selectedCardIndex: editingIndex,
            updateSelectedCardIndex: updateEditingIndex,
            canEdit: canShowEditButton,
            isEditDisabled: readOnlyPriorLoss?.isUnderEditing && addingLossHistory && editLossVehicle,
            isDeleteDisabled: readOnlyPriorLoss?.isUnderEditing && addingLossHistory,
            readOnly: (!isEditMode && isPriorLossReadOnly && isSourceFCSA) || isReadOnlyUser,
            onClickAction: handleSelectPriorLoss
        },
        lossHistoryForm: {
            insuranceHistoryVM,
            selectedPriorLoss: readOnlyPriorLoss,
            savePriorLoss,
            cancelPriorLoss,
            baseData,
            isEditMode,
            carriersList,
            vehicleOperators,
            vehiclesChoices,
            availableVehicles,
            isViewMode,
            visible: addingLossHistory || editLossVehicle
        },
        addLossButtonContainer: {
            visible: !isPolicyChangeOrRenewal || hasNewDrivers
        },
        addLossButton: {
            disabled: addingLossHistory
        },
        FCSALossHistoryQCSectionContainer: {
            visible: isBaseStateQC()
        },
        fcsaFailureMsg: {
            content: getFCSAmsg()
        },
        fcsaFailureMsgContainer: {
            visible: !isFCSAOrderSuccessForAllDrivers() && !_.get(insuranceHistoryVM, 'fcsaProvided.value')
        },
        fcsaDriversContainer: {
            content: getFCSAdrivers()
        },
        driversFCSACheck: {
            data: availableDrivers
        },
        availableDriversForFCSA: {
            content: getAvailableDrivers()
        },
        selectedDriversForFCSA: {
            menuClassName: isDropdownOpen && styles.driversDropdownMenu,
        },
        driversForFCSAContainer: {
            onClick: (event) => handleFCSADriverBtnClick(event)
        },
        getDriversButton: {
            disabled: selectedDriversForFCSA.length === 0
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            addNewPriorLoss,
            fcsaDropDownToggled
        },
        resolveComponentMap: {
            WMICAddLossHistoryComponent
        },
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={insuranceHistoryVM}
            overrideProps={overrideProps}
            onModelChange={updateHistoryData}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

WMICPALossHistoryComponent.propTypes = {
    id: PropTypes.string.isRequired,
    insuranceHistoryVM: PropTypes.shape({}),
    submissionVM: PropTypes.shape({}),
    isEditMode: PropTypes.bool.isRequired,
    updateHistory: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    baseData: PropTypes.shape({}).isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string.isRequired
    }).isRequired,
    isReadOnlyUser: PropTypes.bool.isRequired,
    showErrors: PropTypes.bool.isRequired,
};

WMICPALossHistoryComponent.defaultProps = {
    insuranceHistoryVM: {},
};

export default WMICPALossHistoryComponent;
