import { defineMessages } from 'react-intl';

export default defineMessages({
    retrievingSubTypesOfUseForCommercialVehicle: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.RetrievingSubTypesOfUseForCommercialVehicle',
        defaultMessage: 'Retrieving sub types of use for commercial vehicle...'
    },
    retrievingClassCodesForCommercialVehicle: {
        id: 'wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.RetrievingClassCodesForCommercialVehicle',
        defaultMessage: 'Retrieving class codes for commercial vehicle...'
    },
    commercialVehicleHeading: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Commercial Vehicle Details",
        defaultMessage: "Commercial Vehicle Details"
    },
    bodyType: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.BodyType",
        defaultMessage: "Body Type"
    },
    otherBodyTypeDesc: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Commercial Vehicle Body Type Other",
        defaultMessage: "Describe Other"
    },
    typeOfUse: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Type of Use",
        defaultMessage: "Type of Use"
    },
    otherTypeOfUseDesc: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Describe Other",
        defaultMessage: "Describe Other"
    },
    subTypeOfUse: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Sub Type of Use",
        defaultMessage: "Sub Type of Use"
    },
    commercialVehicleClass: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Commercial Vehicle Class",
        defaultMessage: "Commercial Vehicle Class"
    },
    usedForDelivery: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Used for Delivery",
        defaultMessage: "Used for Delivery"
    },
    deliveryType: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Delivery Type",
        defaultMessage: "Delivery Type"
    },
    grossVehicleWeight: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Gross Vehicle Weight",
        defaultMessage: "Gross Vehicle Weight"
    },
    radiusOfOperation: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Radius of Operation",
        defaultMessage: "Radius of Operation"
    },
    tripsPerMonth: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.TripsPerMonth",
        defaultMessage: "How many trips per month extend beyond the normal radius"
    },
    nonOwnedTrailer: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Does this vehicle tow a non-owned trailer",
        defaultMessage: "Does this vehicle tow a non-owned trailer?"
    },
    passengersCarried: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Number of passengers carried per service",
        defaultMessage: "Number of passengers carried per service"
    },
    towingVehicle: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Towing Vehicle",
        defaultMessage: "Towing Vehicle"
    },
    highHazardHeading: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.High Hazard Operations",
        defaultMessage: "High Hazard Operations"
    },
    highHazardAlternateHeading: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Additional Exposure Questions",
        defaultMessage: "Additional Exposure Questions"
    },
    bulkLiquids: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Vehicle haul bulk liquids?",
        defaultMessage: "Does this vehicle haul Bulk Liquids?"
    },
    bulkLiquidsAlternate: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Vehicle haul non-hazardous Bulk Liquids?",
        defaultMessage: "Does this vehicle haul non-hazardous Bulk Liquids (i.e. Dairy, Water)?"
    },
    dangerousGoods: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Vehicle haul Dangerous Goods?",
        defaultMessage: "Does this vehicle haul Dangerous Goods?"
    },
    dangerousGoodsType: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Type of Dangerous Goods?",
        defaultMessage: "Type of Dangerous Goods?"
    },
    otherDangerousGoodsDesc: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Describe Other",
        defaultMessage: "Describe Other"
    },
    oilFields: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Vehicle operate in Oil Fields?",
        defaultMessage: "Does this vehicle operate in Oil Fields?"
    },
    oversizedLoads: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Vehicle haul Oversized Loads?",
        defaultMessage: "Does this vehicles haul Oversized Loads?"
    },
    sandStoneGravelEarth: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Vehicle haul Sand, Stone, Gravel and Earth?",
        defaultMessage: "Does this vehicle haul Sand, Stone, Gravel and Earth?"
    },
    winterRoads: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Vehicle operate on Winter / Ice Roads?",
        defaultMessage: "Does this vehicle operate on Winter/ Ice Roads?"
    },
    haulLogs: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Haul Logs?",
        defaultMessage: "Does this vehicle haul Logs?"
    },
    haulLumber: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Haul Lumber?",
        defaultMessage: "Does this vehicle haul Lumber?"
    },
    haulLivestock: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Haul Livestock?",
        defaultMessage: "Does this vehicle haul Livestock?"
    },
    performMovingStorage: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Perform Moving Storage?",
        defaultMessage: "Does this vehicle perform any Moving or Storage?"
    },
    maxCarryableVehicles: {
        id: "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.Maximum number of vehicles the tow truck/trailer can carry",
        defaultMessage: "Maximum number of vehicles the tow truck/trailer can carry"
    },
    highHazardOperationsQuestions_Ext: {
        "id": "wmic-pe-capability-gateway-common-pa-react.VehicleUsageCommercial.HighHazardOperationsQuestions_Ext",
        "defaultMessage": "Are any of these high hazard operations applicable to the insured?"
    }

})