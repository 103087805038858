import { defineMessages } from 'react-intl';

export default defineMessages({
    dwellingHeader: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHODwellingCoverages.Dwelling Header',
        defaultMessage: 'Dwelling #{riskID}: {riskLocation}'
    },
    riskType: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHODwellingCoverages.Risk Type',
        defaultMessage: 'Risk Type'
    },
    dwellingUsage: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHODwellingCoverages.Dwelling Usage',
        defaultMessage: 'Dwelling Usage'
    },
    deductibleLabel: {
        id: 'wmic-pe-capability-gateway-common-ho-react.WMICHODwellingCoverages.Deductible',
        defaultMessage: 'Deductible'
    }
});
