import React from 'react';
import {Icon} from "@jutro/components";
import cx from 'classnames';

import styles from './WMICIcon.module.scss';

const WMICIcon = (props) => {
    const getIcon = () => {
        if(props.icon && props.icon.startsWith('fa')){
            return(
                <i 
                    className={cx(
                        props.icon,
                        props.className,
                        {
                            [styles.wmicIconLarge]: props.size === 'large',
                            [styles.wmicIconMedium]: props.size === 'medium',
                            [styles.wmicIconSmall]: props.size === 'small',
                        }
                    )} 
                    aria-label={props['aria-label']}
                />
            )

        }
        return <Icon
            {...props}
        />
    }
    return getIcon()

}
export default WMICIcon;
