import { defineMessages } from 'react-intl';

export default defineMessages({
    wmicUnderlyingPoliciesLocationDetails: {
        id: 'policy.pup.views.exposures.LocationDetails',
        defaultMessage: 'Property Details'
    },
    wmicUnderlyingPoliciesResidenceType: {
        id: 'policy.pup.views.exposures.ResidenceType',
        defaultMessage: 'Residence Type'
    },
    wmicUnderlyingPoliciesDwellingUsage: {
        id: 'policy.pup.views.exposures.DwellingUsage',
        defaultMessage: 'Dwelling Usage'
    },
    wmicUnderlyingPoliciesOccupancy: {
        id: 'policy.pup.views.exposures.Occupancy',
        defaultMessage: 'Occupancy'
    },
    wmicUnderlyingPoliciesAddressOfResidence: {
        id: 'policy.pup.views.exposures.AddressOfResidence',
        defaultMessage: 'Address of Residence'
    },
    wmicUnderlyingPoliciesAddress: {
        id: 'policy.pup.views.exposures.Address',
        defaultMessage: 'Address'
    },
    wmicUnderlyingPoliciesDwellingUse: {
        id: 'policy.pup.views.exposures.DwellingUse',
        defaultMessage: 'Dwelling Use'
    }
});