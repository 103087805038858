import { defineMessages } from 'react-intl';

export default defineMessages({
    riskNumber: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.iterable-vehicle-component.risk-number',
        defaultMessage: 'Risk #'
    },
    year: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.iterable-vehicle-component.year',
        defaultMessage: 'Year'
    },
    makeAndModel: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.iterable-vehicle-component.make-and-model',
        defaultMessage: 'Make and Model'
    },
    vin: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.iterable-vehicle-component.vin',
        defaultMessage: 'VIN'
    },
    totalPremium: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.iterable-vehicle-component.premium',
        defaultMessage: 'Premium'
    },
    removed: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.iterable-vehicle-component.removed',
        defaultMessage: 'Removed'
    }
})
