import { defineMessages } from 'react-intl';

export default defineMessages({
    locationNumber:{
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.iterable-location-component.locatioNum',
        defaultMessage: 'Location'
    },
    locationAddress:{
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.iterable-location-component.address',
        defaultMessage: 'Address'
    },
})
