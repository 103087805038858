import { defineMessages } from 'react-intl';

export default defineMessages({
    broker: {
        id: "wmic-pe-capability-gateway-common-react.QuoteSummary.Broker",
        defaultMessage: "Broker"
    },
    policyStatus : {
        id: "wmic-pe-capability-gateway-common-react.QuoteSummary.Status",
        defaultMessage: "Status"
    },
    policyTerm : {
        id: "wmic-pe-capability-gateway-common-react.QuoteSummary.PolicyTerm",
        defaultMessage : "Policy term"
    },
    issuedStatus : {
        id: "wmic-pe-capability-gateway-common-react.QuoteSummary.Issued",
        defaultMessage : "Issued"
    },
    documentDelivery: {
        id: "wmic-pe-capability-gateway-common-react.QuoteSummary.Delivery-of-Documents",
        defaultMessage: "Delivery of Documents"
    },
    quoteSummaryCard: {
        id: "wmic-pe-capability-gateway-common-react.QuoteSummary.Quote Summary",
        defaultMessage: "Quote Summary"
    },
    epoiEnrolled: {
        id: "wmic-pe-capability-gateway-common-react.QuoteSummary.Electronic Proof of Insurance",
        defaultMessage: "Electronic Proof of Insurance"
    },
    epoiEnrolledYes: {
        id: "wmic-pe-capability-gateway-common-react.QuoteSummary.Yes",
        defaultMessage: "Yes"
    },
    epoiEnrolledNo: {
        id: "wmic-pe-capability-gateway-common-react.QuoteSummary.No",
        defaultMessage: "No"
    },
    policyNumber: {
        id: "wmic-pe-capability-gateway-common-react.QuoteSummary.Policy number",
        defaultMessage: "Policy Number"
    },
    policyChangeEffectiveDate: {
        id: "wmic-pe-capability-gateway-common-react.QuoteSummary.Policy change effective date",
        defaultMessage: "Policy Change Effective Date"
    },
    additionalNamedInsured: {
        id: "wmic-pe-capability-gateway-common-react.QuoteSummary.Additional named insured",
        defaultMessage: "Additional Named Insured"
    }
})
