import { defineMessages } from 'react-intl';

export default defineMessages({
    fullDisclosurePUPQuebec1: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Quebec Full Disclosure 1',
        defaultMessage: 'I, the Applicant, and the Insured if the Insurer has requested information from it, have reviewed all parts of and attachments to this application and declare that all of the information is true and correct even if the information has been entered or suggested by the representative of the Insurer or by the insurance broker.  I understand that acceptance of this application for insurance is based on the truth and completeness of this information, and that if I falsely describe the property to the prejudice of the Insurer, or misrepresent or fraudulently omit to communicate any circumstance that is material to be made known to the Insurer in order to enable it to judge of the risk to be undertaken, the contract may be void in whole or as to any property in relation to which the misrepresentation or omission is material.'
    },
    fullDisclosurePUPQuebec2: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Quebec Full Disclosure 2',
        defaultMessage: 'Any fraud or willfully false statement in statutory declaration in relation to any of the particulars required by applicable conditions, statutory or otherwise, to be specified in relation to a claim, vitiates the claim of the person making the declaration.'
    },
    fullDisclosureIntro: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Full Disclosure Intro',
        defaultMessage: 'I, the Applicant, and the Insured if the Insurer has requested information from it, have reviewed all parts of and attachments to this application and declare that all of the information is true and correct even if the information has been entered or suggested by the representative of the Insurer or by the insurance broker. I understand that acceptance of this application for insurance is based on the truth and completeness of this information, and that:'
    },
    fullDisclosureExQuebec: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Full Disclosure Exclude Quebec',
        defaultMessage: '&lt;span&gt;&lt;b&gt;For all provinces and territories except Quebec:&lt;/b&gt; If I falsely describe the property to the prejudice of the Insurer, or misrepresent or fraudulently omit to communicate any circumstance that is material to be made known to the Insurer in order to enable it to judge of the risk to be undertaken, the contract may be void in whole or as to any property in relation to which the misrepresentation or omission is material.&lt;/span&gt;'
    },
    fullDisclosureQuebec: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Full Disclosure Quebec',
        defaultMessage: '&lt;span&gt;&lt;b&gt;For Quebec:&lt;/b&gt; I am bound to represent all the facts known to me which are likely to materially influence an insurer in the setting of the premium, the appraisal of the risk or the decision to cover it. The same applies to the Insured if the Insurer requires it. Any misrepresentation or concealment of relevant facts by me or the Insured nullifies the contract, even in respect of losses not connected with the risk so misrepresented or concealed.&lt;/span&gt;'
    },
    fullDisclosureAllProvinces: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Full Disclosure All Provinces',
        defaultMessage: '&lt;span&gt;&lt;b&gt;For all provinces and territories:&lt;/b&gt; Any fraud or willfully false statement in a statutory declaration in relation to any of the particulars required by applicable conditions, statutory or otherwise, to be specified in relation to a claim, vitiates the claim of the person making the declaration.&lt;/span&gt;'
    },
    personalConsentPUPQuebec1: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Quebec Personal Information Consent 1',
        defaultMessage: 'I am providing personal information of individuals in this form to apply for insurance.  The personal information collected will be used for the purpose of this application or any renewal or change in coverage.  I consent and authorize my broker, agent or insurer to the following:'
    },
    personalConsentPUPQuebec2: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Quebec Personal Information Consent 2',
        defaultMessage: 'i) To collect, use and disclose personal information on this form to, from and between insurers and other appropriate parties, subject to my broker’s, agent’s and the insurer’s policy regarding personal information. Such personal information will include policy history, loss history and rating information.'
    },
    personalConsentPUPQuebec3: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Quebec Personal Information Consent 3',
        defaultMessage: 'ii) That these collections, uses and disclosures are for the purpose necessary to communicate with me and the listed applicants, assess, manage and underwrite risk, determine a premium, determine eligibility and conditions for a premium payment plan, investigate and settle claims, analyze business results, detect and prevent fraud, as permitted by law.'
    },
    personalConsentPUPQuebec4: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Quebec Personal Information Consent 4',
        defaultMessage: 'iii) To collect only my personal credit information including my credit score from consumer reporting agencies, as permitted by law for the purposes identified above. I understand that my consent for the use of credit information remains valid until withdrawn by me in writing. By withdrawing or failing to provide my consent to the use of credit information, I understand that I may not benefit from the best rate available to me.'
    },
    personalConsentPUPQuebec5: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Quebec Personal Information Consent 5',
        defaultMessage: 'I declare that all individuals whose personal information is contained in this form have authorized me to consent to i) and ii) above on their behalf.'
    },
    personalConsentPUPQuebec6: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Quebec Personal Information Consent 6',
        defaultMessage: 'If any other individuals wish to provide their consent with respect to the use of their credit information, they may provide their consent by signing below.'
    },
    personalConsentPUPQuebec7: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Quebec Personal Information Consent 7',
        defaultMessage: 'I may obtain a copy of or ask questions about my broker’s agent’s or insurer’s personal information policies by contacting their respective privacy officers.'
    },
    personalConsentExcludeNL: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Personal Information Consent Exclude NL',
        defaultMessage: 'For all provinces and territories except Newfoundland and Labrador:'
    },
    personalConsentExcludeNLStatement: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Personal Information Consent Exclude NL Statement',
        defaultMessage: 'I am providing personal information of individuals in this form to apply for insurance. The personal information collected will be used for the purpose of this application or any renewal or change in coverage. I consent and authorize my broker, agent or insurer to the following:'
    },
    personalConsentExcludeNLListItem1: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Personal Information Consent Exclude NL List Item 1',
        defaultMessage: 'i) To collect, use and disclose personal information on this form to, from and between insurers and other appropriate parties, subject to my broker’s, agent’s and the insurer’s policy regarding personal information. Such personal information will include policy history, loss history and rating information.'
    },
    personalConsentExcludeNLListItem2: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Personal Information Consent Exclude NL List Item 2',
        defaultMessage: 'ii) That these collections, uses and disclosures are for the purposes necessary to communicate with me and the listed applicants, assess, manage and underwrite risk, determine a premium, determine eligibility and conditions for a premium payment plan, investigate and settle claims, analyze business results, detect and prevent fraud, as permitted by law.'
    },
    personalConsentExcludeNLListItem3: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Personal Information Consent Exclude NL List Item 3',
        defaultMessage: 'iii) To collect only my personal credit information including my credit score from consumer reporting agencies, as permitted by law for the purposes identified above. I understand that my consent for the use of credit information remains valid until withdrawn by me in writing. By withdrawing or failing to provide my consent to the use of credit information, I understand that I may not benefit from the best rate available to me.'
    },
    personalConsentExcludeNLDeclare: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Personal Information Consent Exclude NL I Declare',
        defaultMessage: 'I declare that all individuals whose personal information is contained in this form have authorized me to consent to i) and ii) above on their behalf.'
    },
    personalConsentExcludeNLOtherDeclare: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Personal Information Consent Exclude NL Other Declare',
        defaultMessage: 'If any other individuals wish to provide their consent with respect to the use of their credit information, they may provide their consent by also signing below.'
    },
    personalConsentExcludeNLMayObtain: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Personal Information Consent Exclude NL I May Obtain',
        defaultMessage: 'I may obtain a copy of or ask questions about my broker’s, agent’s, or insurer’s personal information policies by contacting their respective privacy officers.'
    },
    personalConsentNL: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Personal Information Consent NL',
        defaultMessage: 'For Newfoundland and Labrador:'
    },
    personalConsentNLStatement: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Personal Information Consent NL Statement',
        defaultMessage: 'I am providing personal information of individuals in this form to apply for insurance. The personal information collected will be used for the purpose of this application or any renewal or change in coverage. I consent and authorize my broker, agent or insurer to the following:'
    },
    personalConsentNLListItem1: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Personal Information Consent NL List Item 1',
        defaultMessage: 'i) To collect, use and disclose personal information on this form to, from and between insurers and other appropriate parties, subject to my broker’s, agent’s and the insurer’s policy regarding personal information. Such personal information will include policy history, loss history and rating information;'
    },
    personalConsentNLListItem2: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Personal Information Consent NL List Item 2',
        defaultMessage: 'ii) That these collections, uses and disclosures are for the purposes necessary to communicate with me and the listed applicants, assess, manage and underwrite risk, determine a premium, determine eligibility and conditions for a premium payment plan, investigate and settle claims, analyze business results, detect and prevent fraud, as permitted by law.'
    },
    personalConsentNLListItem3: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Personal Information Consent NL List Item 3',
        defaultMessage: 'iii) To collect only my personal credit information including my credit score from consumer reporting agencies, as permitted by law for the purpose of determining eligibility and conditions for a premium payment plan. I understand that my consent for the use of credit information remains valid until withdrawn by me in writing.'
    },
    personalConsentNLDeclare: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Personal Information Consent NL I Declare',
        defaultMessage: 'I declare that all individuals whose personal information is contained in this form have authorized me to consent to i) and ii) above on their behalf.'
    },
    personalConsentNLMayObtain: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Personal Information Consent NL I May Obtain',
        defaultMessage: 'I may obtain a copy of or ask questions about my broker’s, agent’s or insurer’s personal information policies by contacting their respective privacy officers.'
    },
    partiesAgreedFR: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Parties have agreed FR',
        defaultMessage: 'Les Parties ont convenu que cette proposition et les documents connexes soient rédigés en anglais.'
    },
    partiesAgreedEN: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Parties have agreed',
        defaultMessage: 'The Parties have specifically agreed that this application and any attachments to this application be drawn in the English language.'
    },
    applicantSignature: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Applicants Signature',
        defaultMessage: 'Applicant\'s Signature (Authorized for this purpose)'
    },
    brokerName: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Brokers Name',
        defaultMessage: 'Broker / Agent Name (Please Print)'
    },
    brokerSignature: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.PUP Brokers Signature',
        defaultMessage: 'Broker / Agent Signature'
    },
    remarks: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.Remarks',
        defaultMessage: 'Remarks'
    },
    fullDisclosure: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.Full Disclosure',
        defaultMessage: 'Full Disclosure'
    },
    personalInformationConsent: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.Personal Information Consent',
        defaultMessage: 'Personal Information Consent'
    },
    date: {
        id: 'wmic.pe-capability-quoteandbind-common-react.components.quote-summary.Date',
        defaultMessage: 'Date'
    }
});
