/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-warning-comments */
/* eslint-disable react/jsx-no-target-blank */
import React, { useContext } from 'react';
import footerLogo from 'wmic-qb-digital-theme-styles/images/Wawanesa_Insurance_grey.svg';
import bestLogo from 'wmic-qb-digital-theme-styles/sass/qbpv4ootb/images/demo/AMBest-Ap-Rating.png';
import { CONSTANTS } from 'wmic-portals-utils-js';
import { TranslatorContext } from '@jutro/locale';
import messages from './WMICCustomFooter.messages';

function WMICCustomFooter(props) {
    const translator = useContext(TranslatorContext);
    const currentYear = new Date().getFullYear();
    const WAWANESA_DOMAIN = CONSTANTS.WAWANESA_URL;
    
    const { regionLongName } = props;

    return (
        <footer className="wawanesa-web noprint footer ww-footer">
            <div className="footer-one-container">
                <div className=" ww-footer-one">
                    <div className="column1">
                        <h4>About Us</h4>
                        <ul className="wmicFooterNoMargin">
                            <li>
                                <a
                                    href={`${WAWANESA_DOMAIN}/us/about-us/customer-service.html?language_id=1&amp;state=${regionLongName}`}
                                    target="_blank"
                                >
                                    Contact Us
                                </a>
                            </li>
                            <li>
                                <a
                                    href={`${WAWANESA_DOMAIN}/us/legal/privacy-policy.html?language_id=1&amp;state=${regionLongName}`}
                                    target="_blank"
                                >
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a
                                    href={`${WAWANESA_DOMAIN}/us/legal/privacy-notice.html`}
                                    target="_blank"
                                >
                                    Privacy Notice
                                </a>
                            </li>
                            <li>
                                <a
                                    href={`${WAWANESA_DOMAIN}/us/legal/terms-and-conditions.html?state=${regionLongName}`}
                                    target="_blank"
                                >
                                    Terms of Use
                                </a>
                            </li>
                            <li>
                                <a
                                    href={`${WAWANESA_DOMAIN}/us/about-us/company-information.html?state=${regionLongName}`}
                                    target="_blank"
                                >
                                    Company Information
                                </a>
                            </li>
                            <li>
                                <a
                                    href={`${WAWANESA_DOMAIN}/us/about-us/accessibility.html?state=${regionLongName}`}
                                    target="_blank"
                                >
                                    Accessibility
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="column2">
                        For information about the categories of personal information collected, the purposes for which it is collected 
                        or used and whether that information is sold or shared, visit our <a
                            className="ww-footer-privacy"
                            href={`${WAWANESA_DOMAIN}/us/legal/privacy-policy.html`}
                            target="_blank"
                        >
                                Privacy Policy.
                        </a>
                    </div>

                    <div className="column3 ww-footer-logo is-half">
                        <img alt="Wawanesa Insurance" src={footerLogo} />
                    </div>
                </div>
            </div>

            <div className="ww-footer-two">
                <div className="column copy">
                    <p>
                        Copyright 1996-
                        {currentYear} Wawanesa Insurance
                    </p>
                    <p>
                        &quot;Wawanesa Insurance&quot; is a trademark
                        <br />
                        of Wawanesa General Insurance Company
                    </p>
                </div>
                <div className="column is-half ww-footer-links col-right-wrapper">
                    <div className="ww-social-icons">
                        <div className="facebook">
                            <a href="https://www.facebook.com/wawanesaus" title="Wawanesa on Facebook">
                                <i className="fab fa-facebook-square" />
                            </a>
                        </div>
                        <div className="instagram">
                            <a href="https://www.instagram.com/wawanesaus/" title="Wawanesa on Instagram">
                                <i className="fab fa-instagram-square" />
                            </a>
                        </div>
                        <div className="twitter">
                            <a href="https://twitter.com/WawanesaUS" title="Wawanesa on Twitter">
                                <i className="fab fa-twitter-square" />
                            </a>
                        </div>
                    </div>
                    <div>
                        <a
                            className="ww-ambest"
                            target="_blank"
                            href={`${WAWANESA_DOMAIN}/us/about-us/company-information.html#financialRatings`}
                        >
                            <img id="logoAMBest" alt={translator(messages.amBestImgAltText)} src={bestLogo} />
                        </a>
                    </div>
                    <div>
                        <p>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default WMICCustomFooter;
