import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { WMICQuestionSetsParser as QuestionSetsParser } from 'wmic-pe-portals-questionsets-js';
import { QuestionSetUtils, LOBConstants } from 'wmic-pe-portals-utils-js';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICCustomRadioButton } from 'gw-capability-quoteandbind-common-react';
import messages from './WMICQuestionSetComponent.messages';

function WMICQuestionSetComponent(props) {
    const translator = useContext(TranslatorContext);
    const {
        id,
        onValidate,
        onValueChange,
        value: aQuestionSetAnswersValue,
        path: pathToAnswers,
        labelPosition,
        showErrors,
        usesRadioButtons,
        baseState,
        lobCode
    } = props;
    const {
        isComponentValid,
        onValidate: setComponentValidation,
        registerComponentValidation
    } = useValidation(id);
    const [questionSetsMetadata, setQuestionSetsMetadata] = useState(null);

    useEffect(() => {
        import(
            /* webpackChunkName: "question-sets-metadata" */
            'question-sets-metadata'
        ).then((data) => {
            const { default: result } = data;
            // hxu -- US31749 pre-quote question set
            // always set required as true, no matter what backend returns.
            const orderedQuestions = _.get(result, `${aQuestionSetAnswersValue.code}.orderedQuestions`);

            orderedQuestions.forEach((q) => {
                const question = q;

                question.required = true;
            });
            setQuestionSetsMetadata(result);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkComponentValidation = useCallback(() =>
        questionSetsMetadata ? true : null
    , [questionSetsMetadata]);

    useEffect(() => {
        registerComponentValidation(checkComponentValidation);
    }, [checkComponentValidation, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    const resolvers = {
        resolveComponentMap: {
            wmicCustomRadioButton: WMICCustomRadioButton
        }
    };

    const handleValueChange = useCallback(
        (value, path) => {
            if (value === undefined) {
                return;
            }

            const { code: questionSetCode, answers: questionSetAnswer } = aQuestionSetAnswersValue;
            const qsOrderedQuestions = questionSetsMetadata[questionSetCode].orderedQuestions;

            QuestionSetUtils.cleanDependantQuestions(
                qsOrderedQuestions,
                value,
                path,
                questionSetAnswer
            );

            let val = value;

            if (value.code) {
                val = value.code;
            }

            if (val.length === 0) {
                val = null;
            }

            _.set(aQuestionSetAnswersValue, `answers.${path}`, val);

            if (onValueChange) {
                onValueChange(aQuestionSetAnswersValue, pathToAnswers);
            }
        },
        [aQuestionSetAnswersValue, onValueChange, pathToAnswers, questionSetsMetadata]
    );

    const overrideProps = {
        '@field': {
            labelPosition
        }
    };

    if (!questionSetsMetadata) {
        return null;
    }

    const PupPropertySet = new Set(['PUPOwnProperty', 'PUPOwnPropertyLocation', 'PUPOwnAircraft', 'PUPLicensedOperatorDuration']);
    // eslint-disable-next-line no-secrets/no-secrets
    const PupLiabilitySet = new Set(['PUPLiabilityCovNotListed', 'PUPExperiencedLiabilityLoss', 'PUPHasBeenSued', 'PUPExperiencedLitigations']);
    const PupOccupationSet = new Set(['PUPHomeBusinessOrFarm', 'BPASSPUPPrequalProfession']);

    const lobSpecificConfigMap = {
        [LOBConstants.PP]: {
            groupBySets: null,
            headingsMap: null
        },
        [LOBConstants.PUP]: {
            groupBySets: [PupPropertySet, PupLiabilitySet, PupOccupationSet],
            headingsMap: new Map([
                ['PUPOwnProperty_WMIC', translator(messages.headingPropertyQuestions)],
                // eslint-disable-next-line no-secrets/no-secrets
                ['PUPLiabilityCovNotListed_WMIC', translator(messages.headingLiabilityQuestions)],
                // eslint-disable-next-line no-secrets/no-secrets
                ['PUPHomeBusinessOrFarm_WMIC', translator(messages.headingOcupationQuestions)],
            ])
        },
        [LOBConstants.PA]: {
            groupBySets: null,
            headingsMap: null
        },
        [LOBConstants.CP]: {
            groupBySets: null,
            headingsMap: null
        },
    }

    const {
        viewModel: qsViewModel,
        presentationMetadata: qsMetadata,
    } = new QuestionSetsParser(
        aQuestionSetAnswersValue,
        questionSetsMetadata,
        translator,
        usesRadioButtons,
        undefined,
        lobSpecificConfigMap[lobCode].headingsMap,
        lobSpecificConfigMap[lobCode].groupBySets,
        baseState
    );

    return (
        <div>
            <ViewModelForm
                uiProps={qsMetadata}
                model={qsViewModel}
                overrideProps={overrideProps}
                onValueChange={handleValueChange}
                onValidationChange={setComponentValidation}
                showErrors={showErrors}
                componentMap={resolvers.resolveComponentMap}
            />
        </div>
    );
}

/**
 * @memberof gw-components-platform-react.QuestionSetComponent
 * @property {Object} propTypes.value - value of the property being rendered
 * @property {string} propTypes.path - the path where this component is attached
 * @property {function(newAnswers, pathToAnswers)} propTypes.onValueChange - the
 *                                      function invoked when the value is changed
 */
WMICQuestionSetComponent.propTypes = {
    value: PropTypes.shape({
        code: PropTypes.string
    }).isRequired,
    path: PropTypes.string.isRequired,
    onValueChange: PropTypes.func,
    onValidate: PropTypes.func,
    labelPosition: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    }),
    showErrors: PropTypes.bool,
    usesRadioButtons: PropTypes.bool
};
WMICQuestionSetComponent.defaultProps = {
    onValueChange: undefined,
    onValidate: undefined,
    labelPosition: 'left',
    id: undefined,
    title: messages.pageTitle,
    showErrors: false,
    usesRadioButtons: false
};
export default WMICQuestionSetComponent;
