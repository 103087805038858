import React, { useContext } from 'react';
import _  from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICWizardViewPage } from 'wmic-pe-portals-wizard-components-ui';
import { WMICScheduledItemComponent } from 'wmic-pe-capability-gateway-common-ho-react';

import metadata from './WMICPolicyScheduledItemsPage.metadata.json5'
import messages from './WMICPolicyScheduledItemsPage.messages';

const MAX_NUMBER_DESCRIPTION_CHARS = 100;

function WMICPolicyScheduledItemsPage(props) {
    const translator = useContext(TranslatorContext);

    const {
        wizardData: { policyVM },
    } = props;

    const getScheduledItemDescription = (item) => {
        const description = _.get(item, 'description.value', '');
        return description.length > MAX_NUMBER_DESCRIPTION_CHARS ? description.substring(0, MAX_NUMBER_DESCRIPTION_CHARS) : description;
    }

    const overrideProps = {
        scheduledItemsDataList: {
            startOpen: false,
            clickable: true,
            readOnly: true,
            VMData: [
                {
                    headerText: translator(messages.scheduledItemType),
                    path: 'scheduledItemTypeCode'
                },
                {
                    headerText: translator(messages.scheduledItemDescription),
                    path: 'description',
                    getData: (item) => getScheduledItemDescription(item)
                },
                {
                    headerText: translator(messages.scheduledItemLimit),
                    path: 'limit'
                }
            ],
            detailViewComponent: WMICScheduledItemComponent,
            detailViewComponentProps: {
                jobVM: policyVM
            }
        }
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            WMICScheduledItemComponent
        }
    };

    return (
        <WMICWizardViewPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyVM}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
            />
        </WMICWizardViewPage>
    );
}

WMICPolicyScheduledItemsPage.propTypes = wizardProps;
export default WMICPolicyScheduledItemsPage;
