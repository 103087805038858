import { defineMessages } from 'react-intl';

export default defineMessages({
    paymentHeader: {
        id: 'wmic.portal.components.transactionWizardTitle.transactionWizardTitle.Renewal - Homeowners',
        defaultMessage: 'Renewal - Homeowners'
    },
    accountTitleHo: {
        id: 'wmic.renewal.directives.templates.bank-account-details-quote.Account:',
        defaultMessage: 'Account:'
    },
    hoRenewalCoverages: {
        id: 'wmic.renewal.ho.directives.templates.ho-coverages.Coverages',
        defaultMessage: 'Coverages'
    },
    hoRenewalValuables: {
        id: 'wmic.renewal.ho.directives.templates.ho-valuables.Valuables',
        defaultMessage: 'Valuables'
    },
    hoRenewalMortgagee: {
        id: 'wmic.renewal.ho.directives.templates.ho-mortgagee.Mortgagee',
        defaultMessage: 'Mortgagee'
    },
    hoRenewal: {
        id: 'wmic.renewal.ho.directives.templates.ho-policy-changes.Renewals',
        defaultMessage: 'Renewals'
    },
    hoRenewalQuotes: {
        id: 'wmic.renewal.common.views.history.Quote',
        defaultMessage: 'Quote'
    },
    hoRenewalPayments: {
        id: 'wmic.renewal.ho.directives.templates.ho-coverages.Payments',
        defaultMessage: 'Payments'
    },
    anErrorOccurred: {
        id: 'wmic.renewal.gw-portals-policytransaction-pc-ho.wizard.error',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'wmic.renewal.gw-portals-policytransaction-pc-ho.wizard.error.title',
        defaultMessage: 'Error'
    },
    saveQuoteError: {
        id: 'wmic.renewal.gw-portals-policytransaction-pc-ho.Unable to quote',
        defaultMessage: 'Unable to quote'
    },
    saveQuoteErrorMessage: {
        id: 'wmic.renewal.gw-portals-policytransaction-pc-ho.An error occurred while attempting to quote.',
        defaultMessage: 'An error occurred while attempting to quote.'
    },
    unableToLoadDraftPolicy: {
        id: 'wmic.renewal.wizard.Unable to load draft renewal',
        defaultMessage: 'Unable to Load Draft Renewal'
    },
    somethingWentWrong: {
        id: 'wmic.renewal.wizard.An error occurred while attempting to load the renewal.',
        defaultMessage: 'Something went wrong during this process. Please try again.'
    },
    cancel: {
        id: 'wmic.renewal.common.view.Cancel',
        defaultMessage: 'Cancel'
    },
    savingTransactionDetails: {
        id: 'wmic-pe-capability-gateway-renewal-ho-react.WMICPEHORenewalWizard.Saving Transaction Details',
        defaultMessage: 'Saving Transaction Details'
    },
    transactionDetailsTitle: {
        id: 'wmic-pe-capability-gateway-renewal-ho-react.wizard-config.Transaction Details',
        defaultMessage: 'Transaction Details'
    },
    policyDetailsTitle: {
        id: 'wmic-pe-capability-gateway-renewal-ho-react.wizard-config.Policy Details',
        defaultMessage: 'Policy Details'
    },
    risksTitle: {
        id: 'wmic-pe-capability-gateway-renewal-ho-react.wizard-config.Risk',
        defaultMessage: 'Risk'
    },
    constructionTitle: {
        id: 'wmic-pe-capability-gateway-renewal-ho-react.wizard-config.Construction',
        defaultMessage: 'Construction'
    },
    coveragesTitle: {
        id: 'wmic-pe-capability-gateway-renewal-ho-react.wizard-config.Coverages',
        defaultMessage: 'Coverages'
    },
    scheduledItemsTitle: {
        id: 'wmic-pe-capability-gateway-renewal-ho-react.wizard-config.Scheduled Items',
        defaultMessage: 'Scheduled Items'
    },
    insuranceHistoryTitle: {
        id: 'wmic-pe-capability-gateway-renewal-ho-react.wizard-config.Insurance History',
        defaultMessage: 'Insurance History'
    },
    contactDetailsTitle: {
        id: 'wmic-pe-capability-gateway-renewal-ho-react.wizard-config.Contact Details',
        defaultMessage: 'Contact Details'
    },
    changeSummaryTitle: {
        id: 'wmic-pe-capability-gateway-renewal-ho-react.wizard-config.Change Summary',
        defaultMessage: 'Change Summary'
    },
    quoteTitle: {
        id: 'wmic-pe-capability-gateway-renewal-ho-react.wizard-config.Quote',
        defaultMessage: 'Quote'
    },
    progress: {
        id: 'wmic-pe-capability-gateway-policyRenewal-ho-react.WMICPEHORenewalWizard.Progress',
        defaultMessage: 'Progress'
    },
});
