/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FieldComponent } from '@jutro/components';
import { withTranslator } from '@jutro/locale';
import { withValidation, validationPropTypes } from '@xengage/gw-portals-validation-react';
import { withAuthenticationContext } from 'wmic-digital-auth-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { GoogleAddressLookupComponent, initializeAddressSearchViewModel } from 'gw-ext-google-tools';
import { WizardContext } from '@xengage/gw-portals-wizard-react';
import { CONSTANTS, WMICVariousUtil, WMICApplicationUtil } from 'wmic-portals-utils-js';
import { WMICSwitchIconTooltip, WMICCustomTooltip } from 'gw-capability-quoteandbind-common-react';
import WMICCustomInput from '../WMICCustomInput/WMICCustomInput';
import WMICCustomDropdownSelect from '../WMICCustomDropdownSelect/WMICCustomDropdownSelect';
import metadata from './WMICAddressLookupComponent.metadata.json5';
import policyAdressLookupMetadata from './WMICPolicyDetailsAddressLookup.metadata.json5';
import styles from './WMICAddressLookupComponent.module.scss';
import messages from './WMICAddressLookupComponent.messages';


class WMICAddressLookupComponent extends FieldComponent {

    static propTypes = {
        value: PropTypes.shape({ postalCode: PropTypes.string }),
        model: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        onValidate: PropTypes.func,
        onValueChange: PropTypes.func,
        label: PropTypes.string,
        labelPosition: PropTypes.string,
        required: PropTypes.bool,
        showErrors: PropTypes.bool,
        authHeader: PropTypes.shape({}).isRequired,
        stateField: PropTypes.shape({ readOnly: PropTypes.bool.isRequired }),
        streetAddressPlaceHolder: PropTypes.String,
        ...FieldComponent.propTypes,
        ...validationPropTypes
    };

    static defaultProps = {
        onValidate: undefined,
        value: undefined,
        onValueChange: undefined,
        layout: 'full-width',
        hideLabel: true,
        label: undefined,
        labelPosition: undefined,
        required: false,
        showErrors: false,
        stateField: {
            readOnly: true
        }
    };

    state = {
        errorText: '',
        matches: [],
        formData: {},
        emptiedSearchField: false,
        manualAddressOverride: true,
        hintVisibility: false,
        stateField: {
            readOnly: true
        },
        showAddressErrors: false
    };

    componentDidMount() {
        const {
            id, onValidate, isComponentValid, registerComponentValidation, stateField
        } = this.props;

        registerComponentValidation(this.isFieldsValid);

        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        if (this.isFieldsValid()) {
            this.setState({ manualAddressOverride: true });
        }
        this.setState( { stateField: stateField });
    }

    componentDidUpdate(prevProps) {
        const {
            id,
            onValidate,
            isComponentValid,
            hasValidationChanged,
            value: newAddressData
        } = this.props;
        const { value: oldAddressData } = prevProps;
        const isSameAddress = _.isEqual(newAddressData.value, oldAddressData.value);
        if ((!isSameAddress || hasValidationChanged) && onValidate) {
            onValidate(isComponentValid, id);
        }
    }

    isFieldsValid = () => {
        const { value: address } = this.props;

        return address.aspects.valid && address.aspects.subtreeValid;
    };

    getStateFieldLabel = (address) => {
        const { state } = address;
        const emptyLabel = {
            defaultMessage: ' '
        };
        const stateFieldLabel = {
            id: 'platform.inputs.address-details.State',
            defaultMessage: 'State'
        };

        return !state ? emptyLabel : stateFieldLabel;
    }

    handleChange = (value, changedPath) => {
        const { path, onValueChange } = this.props;
        const { matches, formData } = this.state;

        _.set(formData, changedPath, value);

        if (onValueChange && changedPath === CONSTANTS.SELECTED_ADDRESS) {
            onValueChange(_.get(matches, value).address, `${path}`);
        } else {
            onValueChange(value, `${path}.${changedPath}`);
        }

        this.setState({ formData });
    };

    writeValue = (value, path) => {
        const { formData, emptiedSearchField } = this.state;
        const clonedFormData = _.cloneDeep(formData);
        _.set(clonedFormData, path, value);

        this.setState({
            formData: clonedFormData,
            emptiedSearchField: path === CONSTANTS.SEARCH_TEXT && _.isEmpty(value),
            enableSearchButton: path === CONSTANTS.SEARCH_TEXT && emptiedSearchField
        });
    };

    clearTextField = () => {
        const { value: address, path, onValueChange } = this.props;
        const clonedValues = _.clone(address.value);
        clonedValues.addressLine1 = undefined;
        clonedValues.addressLine2 = undefined;
        clonedValues.city = undefined;
        onValueChange(clonedValues, path);
        this.setState({
            formData: {
                searchText: '',
                selectedAddress: undefined
            },
            matches: [],
            errorText: ''
        });
    };

    setShowAddressErrors = (value) => {
        this.setState( { showAddressErrors: value });
    }

    renderErrorsMessage = () => {
        const { errorText } = this.state;
        return [errorText];
    };

    enterManualAddress = () => {
        this.setState({ manualAddressOverride: true });
    };

    renderControl() {
        const {
            formData,
            matches,
            errorText,
            manualAddressOverride,
            stateField,
            showAddressErrors
        } = this.state;
        const {
            value: address,
            label,
            labelPosition,
            required,
            isComponentValid,
            model,
            showErrors,
            streetAddressPlaceHolder,
            translator
        } = this.props;

        const metadataForLookup = WMICApplicationUtil.isPE()
            ? policyAdressLookupMetadata : metadata;

        // const dataForComponent = _.merge(formData, addressVM);
        const dataForComponent = {
            ...formData,
            ...address
        };

        const showManualAddress = !!manualAddressOverride
            || (isComponentValid && _.isEmpty(matches) && manualAddressOverride === undefined);

        const overrideProps = {
            '@field': {
                labelPosition: labelPosition,
                showRequired: required
            },
            googleAddressLookup: {
                model: model,
                path: initializeAddressSearchViewModel(model, this.props.path),
                streetAddressPlaceHolder: streetAddressPlaceHolder,
                setShowAddressErrors: this.setShowAddressErrors
            },
            county: {
                visible: (_.get(address, 'state.value.code') === CONSTANTS.JURISDICTIONS.OR),
            },
            addressManualContainer: {
                visible: showManualAddress
            },
            stateHintButton: {
                className: 'wmicShowSmallerThanTabletPortrait',
                setTooltipVisibility: (val) => { this.setState({ hintVisibility: val }); }
            },
            stateHintMobile: {
                visible: this.state.hintVisibility,
                content: WMICVariousUtil.getTextWithIcon('fa fa-question-circle wmicIconBig', translator(messages.emailHint))
            },
            stateField: {
                disabled: stateField?.readOnly,
                label: this.getStateFieldLabel(address.value),
                onValueChange: (value) => this.handleChange(value, 'state'),
                value: dataForComponent.state.value?.code,
                availableValues: dataForComponent.state.aspects.availableValues,
                alwaysShowPlaceholder: false
        
            }
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                onCleartextField: this.clearTextField,
                enterManualAddress: this.enterManualAddress,
            },
            resolveComponentMap: {
                wmicCustomInput: WMICCustomInput,
                wmicCustomDropdownSelect: WMICCustomDropdownSelect,
                googleAddressLookupComponent: GoogleAddressLookupComponent,
                wmicSwitchIconTooltip: WMICSwitchIconTooltip,
                wmicCustomTooltip: WMICCustomTooltip
            },
        };

        return (
            <ViewModelForm
                uiProps={metadataForLookup.componentContent}
                model={dataForComponent}
                overrideProps={overrideProps}
                onValueChange={this.handleChange}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors || showAddressErrors}
            />
        );
    }

    render() {
        return super.render();
    }
}

export default withAuthenticationContext(withValidation(withTranslator(WMICAddressLookupComponent)));